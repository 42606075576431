import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {EstablishmentDepartment} from '../../shared/entity/establishment-department';
import {Establishment} from '../models/establishment';
import {ActivatedRoute} from '@angular/router';
import {EstablishmentService} from '../services/establishment.service';
import {SharedService} from '../../shared/services/shared.service';
import {ServiceEditFormComponent} from '../service-edit-form/service-edit-form.component';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {DataService} from '../../shared/services/data-service';
import {EstablishmentDepartmentService} from '../services/establishment-department.service';
import {Permission} from '../../helpers/permission';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {EstablishmentAppData} from '../establishment-app-data';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import { EstablishmentInfoService } from '../services/establishment-info.service';

@Component({
  selector: 'ih-information-and-services',
  templateUrl: './information-and-services.component.html',
  styleUrls: ['./information-and-services.component.css']
})
export class InformationAndServicesComponent extends PaginatorTableComponent<EstablishmentDepartment> implements OnInit {

  target = DynamicDefinitionEnum.ESTABLISHMENT_INFORMATION_FORM;
  targetTable = DynamicDefinitionEnum.ESTABLISHMENT_LIST_TABLE;
  formConfig: FormConfig = new FormConfig();
  tableConfig: TableConfig;
  headers: DynamicTableHeader[] = [];

  @Input() establishment: Establishment;
  serviceName: string;
  entityType: string;
  disabledInputServiceName: boolean;
  submitted: boolean;

  constructor(
      private cfr: ComponentFactoryResolver,
      private dataService: DataService,
      private sharedService: SharedService,
      private route: ActivatedRoute,
      private establishmentInfoService: EstablishmentInfoService,
      private establishmentDepartmentService: EstablishmentDepartmentService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    this.entityType = this.route.snapshot.params.entityType;
    this.values = this.establishment.services;
    this.initFormConfig();
    this.initTableConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, EstablishmentAppData.formConfig).subscribe(
        config => {
          this.formConfig = config;
        }
    );
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.targetTable, EstablishmentAppData.tableConfig).subscribe(
        config => {
          this.tableConfig = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('name')
        .build());
    this.headers = this.tableConfig.buildTable(availableHeaders);
  }

  getUpdateTitle(): string {
    return 'MODULE_ESTABLISHMENT_DEPARTMENTS_GENERAL_EDIT';
  }

  getDeleteMessage(): string {
    return 'MODULE_ESTABLISHMENT_DEPARTMENTS_DIALOG_WARNING_ESTABLISHMENT_DEPARTMENT_WILL_BE_DELETED';
  }

  getService(): any {
    return this.establishmentDepartmentService;
  }

  editEstablishment() {
    this.establishmentInfoService.updateEstablishment(this.establishment).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  displayFormDialogue(service: EstablishmentDepartment) {
    const formComponent = ServiceEditFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.service = service;
    formComponent.establishmentEditRight = !this.hasWritePermission();
  }

  addService() {
    this.disabledInputServiceName = true;
    if (!this.serviceName || this.serviceName.trim() === '') {
      this.disabledInputServiceName = false;
    } else {
      this.establishmentDepartmentService.addEstablishmentDepartment(this.serviceName, this.establishment.hhhId).subscribe(res => {
        this.sharedService.showSuccess();
        this.sharedService.setInformationInLocalStorage('Services', 'Ajouter', this.serviceName);
        const service: EstablishmentDepartment = new EstablishmentDepartment();
        service.name = this.serviceName;
        this.serviceName = undefined;
        service.hhhId = res;
        this.values.push(service);
        this.values.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        this.disabledInputServiceName = false;
      }, error => {
        this.disabledInputServiceName = false;
        console.log(error.error);
        this.sharedService.showFailure(error.error);
        console.error(error);
      });
    }
  }

  refreshAfterDelete(deletedEntity: any): void {
    const index = this.values.findIndex((service: EstablishmentDepartment) => deletedEntity.hhhId === service.hhhId);
    this.sharedService.setInformationInLocalStorage('Services', 'Supprimer', deletedEntity.name);
    this.values.splice(index, 1);
  }

  hasWritePermission() {
    return localStorage.getItem('Establishment_READ_WRITE') === Permission.READ_WRITE;
  }

  getFieldConfig = (fieldName: string) => this.formConfig.getFieldConfig(fieldName);
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminConsoleModules} from '../../entity/admin-console-modules';
import {DynamicDialogComponent} from 'primeng/dynamicdialog';
import {ConfigDetailComponent} from '../config-detail/config-detail.component';
import {Router} from '@angular/router';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {PropertyEnum} from '../../entity/property-enum';

@Component({
  selector: 'ih-admin-console',
  templateUrl: './admin-console.component.html',
  styleUrls: ['./admin-console.component.css']
})
export class AdminConsoleComponent implements OnInit {

  isAdministrationConsoleEnabled = false;


  @ViewChild('dialog') dialog: DynamicDialogComponent;
  @ViewChild('details') details: ConfigDetailComponent;
  configurations = AdminConsoleModules.configurations;
  selectedConfig: any;
  display = false;

  constructor(
    private router: Router,
    private dynamicConfigService: DynamicConfigService
  ) {}

  ngOnInit() {
    this.initProperties();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAdministrationConsoleEnabled = this.dynamicConfigService.getProperty(PropertyEnum.administrationConsoleEnabled);
    });
  }


  selectConfig(config: any) {
    this.selectedConfig = config;
    this.display = true;
  }

  close(): void {
    if (!this.display) {
      this.selectedConfig = undefined;
      this.details.destroy();
    }
  }

  navigateToSuggestedFieldsConsole() {
    this.router.navigate(['/data-management']).then();
  }

}

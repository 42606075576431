import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {DetailedTrialAct} from '../../../entity/trial-additionnal-costs/detailed-trial-act';
import {Router} from '@angular/router';
import {SelectItem} from 'primeng/api';
import {Data} from '../../../../shared/entity/data';
import {TrialActService} from '../../../service/trial-act.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {TheoreticalVisitService} from '../../../service/theoretical-visit.service';
import {NgForm} from '@angular/forms';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';

@Component({
  selector: 'ih-trial-act-form',
  templateUrl: './trial-act-form.component.html',
  styleUrls: ['./trial-act-form.component.css']
})
export class TrialActFormComponent extends FormComponent<DetailedTrialAct> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_ACT_FORM;
  config: FormConfig = new FormConfig();
  trialSecondUfEnabled = false;
  optionalEnabled = false;

  @ViewChild('trialActForm') trialActForm: NgForm;


  trialHhhId: number;
  detailedTrialAct: DetailedTrialAct;
  noYesOptions: SelectItem[] = Data.yesOrNo;
  oldVisitHhhIds: number[];
  other: SelectItem = {value: 'OTHER', label: 'Autres'};
  displayDelete = false;
  yesOrNoOptions = Data.yesOrNo;

  constructor(
    private router: Router,
    private trialActService: TrialActService,
    private theoreticalVisitService: TheoreticalVisitService,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super();
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(TrialActFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.getVisitsHhhIds();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.trialSecondUfEnabled = this.dynamicConfigService.getProperty(PropertyEnum.trialSecondUfEnabled);
      this.optionalEnabled = this.dynamicConfigService.getProperty(PropertyEnum.optionalEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  editTrialAct() {
    this.submitted = true;
    if (this.trialActForm.invalid || !this.detailedTrialAct.isValid(this.config)) {
      return;
    }
    if (!this.oldVisitHhhIds.every(visitHhhId => this.detailedTrialAct.visitsHhhIds.includes(visitHhhId))) {
      this.displayDelete = true;
    } else {
      this.updateDetailedTrialAct();
    }
  }

  navigateToCategory() {
    this.router.navigate(
      ['/additional-costs-container/reference-acts'],
      {queryParams: {actCategory: this.detailedTrialAct.actCategory}}
    ).then();
  }

  getVisitsHhhIds() {
    this.theoreticalVisitService.getTheoreticalVisitsByTrialActHhhId(this.detailedTrialAct.hhhId)
      .subscribe(hhhIds => {
        this.detailedTrialAct.visitsHhhIds = hhhIds;
        this.oldVisitHhhIds = hhhIds;
      });
  }

  updateDetailedTrialAct() {
    this.trialActService.updateTrialAct(this.detailedTrialAct).subscribe(res => {
      if (this.detailedTrialAct.hhhId) {
        this.callback(this.detailedTrialAct);
      } else {
        this.callback(res);
      }
      this.sharedService.showSuccess();
    });
    this.display = false;
    this.displayDelete = false;
  }
}

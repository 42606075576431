export class Fax {
  hhhId: any;
  type: any;
  value: any;
  position: any;
  contactSetHhhId: any;
  humanResourceHhhId: any;
  constructor() {
    this.position = 1;
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TrackingTableService} from '../../../service/tracking-table.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {Router} from '@angular/router';
import {DataService} from '../../../../shared/services/data-service';
import {FR} from '../../../../shared/entity/calendar-language';
import {VisitService} from '../../../service/visit.service';
import {Visit} from '../../../entity/visit';
import {VisitActService} from '../../../service/visit-act.service';
import {VisitState} from '../../../entity/inclusion/visit-state';
import {CalendarService} from '../../../service/calendar.service';
import {DataItemService} from '../../../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-visit-tracking-form',
  templateUrl: './visit-tracking-form.component.html',
  styleUrls: ['./visit-tracking-form.component.css']
})
export class VisitTrackingFormComponent implements OnInit {

  @ViewChild('visitStateUpdateConfirmationPopup', {read: ViewContainerRef}) visitStateUpdateContainer: ViewContainerRef;

  public inclusionHhhId: number;
  public visit: Visit;
  public displayEditVisitDialog = true;
  oldVisitStateHhhId: number;
  public visitStateList: VisitState [];
  public locale = FR;

  callback: (visit: Visit) => void;

  constructor(
              private cfr: ComponentFactoryResolver,
              private dataItemService: DataItemService,
              private visitActService: VisitActService,
              private router: Router
  ) {
  }

  ngOnInit() {
    this.oldVisitStateHhhId = this.visit.visitStateHhhId;
    this.getVisitActs();
    this.getVisitState();
  }

  getVisitActs() {
    this.visitActService.getVisitActs(this.visit.hhhId).subscribe(res => {
      this.visit.visitActs = res;
    });
  }

  getVisitState() {
    this.dataItemService.getItems('visit-state').subscribe(res => {
      this.visitStateList = res.map((item) => {
        return new VisitState(item.value, item.label);
      });
    });
  }

  updatedVisit(visit: Visit) {
    this.callback(visit);
  }

  navigateToInclusionDetailsCalendar() {
    const url = this.router.parseUrl(this.router.url);
    const trialHhhId = url.queryParams.hhhId;
    this.router.navigate(['/trial-details/inclusion-details/calendar'],
      {
        queryParams: {
          hhhId: trialHhhId,
          inclusionId: this.inclusionHhhId
        }
      }).then();
    this.displayEditVisitDialog = false;
  }

  onUpdate(callback: (visit: Visit) => void) {
    this.callback = callback;
  }
}

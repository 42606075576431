import {Component, Inject, Injectable, OnInit} from '@angular/core';

@Component({
  selector: 'ih-trial-information-generic',
  templateUrl: './trial-information-generic.component.html',
  styleUrls: ['./trial-information-generic.component.css']
})

export class TrialInformationGenericComponent<T> implements OnInit {

  trialInformation: T;

  constructor(@Inject('trialInformationInstance') trialInformationInstance: T) {
    this.trialInformation = trialInformationInstance;
  }

  ngOnInit() {
  }

  getService() {
    return null;
  }

  getUnsavedTrialInformationGeneral() {
    this.getService().trialIdentificationInfoValue.subscribe((identificationInformation: T) => {
      if (identificationInformation != null) {
        this.trialInformation = identificationInformation;
      }
    });
  }
}

export class PatientGlobalFilter {
  public hhhId: number;
  public sex: string;
  public firstName: string;
  public lastName: string;
  public fullName: string;
  public birthName: string;
  public internalIdentifier: string;
  public establishmentHhhId: number;
}

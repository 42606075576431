import {Component} from '@angular/core';

@Component({
  selector: 'ih-admin-console-icon',
  templateUrl: './admin-console-icon.component.html',
  styleUrls: ['./admin-console-icon.component.css']
})
export class AdminConsoleIconComponent {

  constructor() { }

}

import {NgModule} from '@angular/core';
import {SiteListComponent} from './site-list/site-list.component';
import {SiteFormComponent} from './site-form/site-form.component';
import {SharedModule} from '../shared/shared.module';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';

@NgModule({
  imports: [
    SharedModule,
    DynamicConfigModule
  ],
  declarations: [
    SiteFormComponent,
    SiteListComponent
  ],
  entryComponents: [
    SiteFormComponent
  ]
})
export class SiteModule {
}

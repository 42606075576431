import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {TrialFixedCost} from '../entity/trial-additionnal-costs/trial-fixed-cost';
import {IsDeletable} from '../../is-deletable';
import {VisitAdditionalCost} from '../entity/trial-additionnal-costs/visit-additional-cost';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class TrialFixedCostService {
  host = new Properties().host + '/trial-fixed-cost';
  trialHhhId: number;
  trialFixedCostList: TrialFixedCost[] = [];
  localIdentifier: string;
  promoter1: string;
  protocolNumber: string;

  constructor(private httpClient: HttpClient) {
  }

  getTrialFixedCosts(trialHhhId: number): Observable<TrialFixedCost[]> {
    return this.httpClient.get<TrialFixedCost[]>(this.host + '/load-list/' + trialHhhId);
  }

  getAttachedTrialFixedCosts(trialHhhId: number): Observable<VisitAdditionalCost[]> {
    return this.httpClient.get<VisitAdditionalCost[]>(this.host + '/attached/' + trialHhhId);
  }

  addFixedCostToTrial(trialHhhId: number, fixedCostIds: number[]) {
    return this.httpClient.get(this.host + '/add/' + trialHhhId + '/' + fixedCostIds);
  }

  isDeletable(hhhIds: number): Observable<IsDeletable> {
    return this.httpClient.post<IsDeletable>(this.host + '/deletable' , hhhIds);
  }

  delete(hhhIds: number): Observable<any> {
    return this.httpClient.post<any>(this.host + '/delete' , hhhIds);
  }

  editTrialFixedCost(trialFixedCost: TrialFixedCost): Observable<TrialFixedCost> {
    return this.httpClient.put<TrialFixedCost>(this.host + '/update', trialFixedCost);
  }

  setIdentificationInformation(localIdentifier, promoter1, protocolNumber) {
    this.localIdentifier = localIdentifier;
    this.promoter1 = promoter1;
    this.protocolNumber = protocolNumber;
  }

  exportTrialFixedCosts(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TherapeuticClassForDrugsService {
  public host = new Properties().host + '/therapeutic-class-for-drugs';

  constructor(private httpClient: HttpClient) {
  }

  getTherapeuticClassAndSubclassForDrugs(): Observable<{ label, value }[]> {
    return this.httpClient.get<{ label, value }[]>(this.host + '/load-selected-items-with-sub-class');
  }
}

import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'dynamicTranslator'
})
export class DynamicTranslatorPipe implements PipeTransform {

  private readonly labelPattern = /fr\s*=[^;]*;\s*en\s*=.*|en\s*=[^;]|fr\s*=[^;]/;
  _translatePipe: TranslatePipe;
  _value: string;

  constructor(
      private translateService: TranslateService,
      private cdr: ChangeDetectorRef
  ) {
    this._translatePipe = new TranslatePipe(translateService, cdr);
  }

  transform(value: string): string {
    if (value === undefined) {
      return '';
    }
    this._value = value;
    if (!this.labelPattern.test(value)) {
      return this._translatePipe.transform(value);
    }
    let labelsOptions = value.split(/[,;]/);
    let length = labelsOptions.length;
    if (length > 1 && labelsOptions[length - 1] === '') {
      value.substring(length - 1);
      labelsOptions = value.split(/[,;]/);
      length = labelsOptions.length;
    }
    if (length === 1) {
      const label = labelsOptions[0].split('=');
      return this._translatePipe.transform(label[1]);
    } else if (length > 1) {
      const labelsMap = new Map<string, string>();
      for (const item of labelsOptions) {
        const label = item.split('=');
        labelsMap.set(label[0], label[1]);
      }
      const lang = this.translateService.currentLang === undefined ?
          this.translateService.getDefaultLang() :
          this.translateService.currentLang;
      const op = Array.from(labelsMap.keys());
      const l = labelsMap.has(lang) ? labelsMap.get(lang) : labelsMap.get(op[0]);
      return this._translatePipe.transform(l);
    } else {
      return '';
    }
  }
}

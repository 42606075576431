import {
  Component,
  ComponentFactoryResolver, EventEmitter,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {DropdownComponent} from '../../../../../shared/component/dropdown/dropdown.component';
import {SelectItem} from 'primeng/api';
import {Data} from '../../../../../shared/entity/data';
import {Router} from '@angular/router';
import {SharedService} from '../../../../../shared/services/shared.service';
import {DataService} from '../../../../../shared/services/data-service';
import {CanDeactivateService} from '../../../../../shared/services/can-deactivate.service';
import {PromoterType} from '../../../../../provider/models/promoter-type';
import {Provider} from '../../../../../provider/models/provider';
import {ProviderService} from '../../../../../provider/services/provider.service';
import {TrialHumanResource} from '../../../../models/trial-human-resource';
import {TrialPersonalInformationService} from '../../../../../shared/services/trial-personal-information.service';
import {TrialInformationGenericComponent} from '../../trial-information-generic/trial-information-generic.component';
import {TrialAppData} from '../../../../trial-app-data';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../dynamic-config/entity/form-config';
import {TrialHumanResourceService} from 'src/app/human-resources/services/trial-human-resource.service';
import {DataCommentInfo} from '../../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../../data-comment/entity/DataCommentEvent';
import {HumanResourcesService} from '../../../../../human-resources/services/human-resources.service';
import {Util} from '../../../../../helpers/util';
import {PropertyEnum} from '../../../../../dynamic-config/entity/property-enum';
import {DetailedHumanResource} from '../../../../../human-resources/models/detailed-human-resource';
import {ContactGlobalFilter} from '../../../../../shared/entity/contact-global-filter';
import {ContactService} from '../../../../../shared/services/contact.service';
import {Contact} from '../../../../../dynamic-config/exported/contact-list/contact';
import {ContactFormComponent} from '../../../../../modals/contact-form/contact-form.component';
import {Country} from '../../../../../shared/entity/country';

@Component({
  selector: 'ih-trial-personnel-provider-information',
  templateUrl: './trial-personnel-provider-information.component.html',
  styleUrls: ['./trial-personnel-provider-information.component.css']
})
export class TrialPersonnelProviderInformationComponent extends TrialInformationGenericComponent<TrialHumanResource> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_PERSONNEL_PROVIDER_INFORMATION_FORM;
  config = new FormConfig();

  @Input() mode: string;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('addHumanResourceDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;

  @ViewChild('promoterDropdown') promoterDropdown: DropdownComponent<Provider>;
  @ViewChild('providerDropdown') providerDropdown: DropdownComponent<Provider>;
  @ViewChild('provider2Dropdown') provider2Dropdown: DropdownComponent<Provider>;

  noYesOptions: SelectItem[] = Data.yesOrNo;
  trialSiteHhhId: number;
  trialHhhId: number;
  investigatorSpecialityOther = false;

  selectedProviders: number[] = [];
  selectedMonitorArcs: number[] = [];

  ghtMontBlancCustomizationEnabled = false;
  humanResource: DetailedHumanResource;
  arcMonitors: DetailedHumanResource[] = [];
  arcMonitorBackups: DetailedHumanResource[] = [];


  showAllProviderPhones: { [key: number]: boolean } = {};
  showAllProviderMails: { [key: number]: boolean } = {};

  showAllProvider2Phones: { [key: number]: boolean } = {};
  showAllProvider2Mails: { [key: number]: boolean } = {};

  showAllArcMonitorPhones: { [key: number]: boolean } = {};
  showAllArcMonitorMails: { [key: number]: boolean } = {};

  showAllArcMonitorBackupPhones: { [key: number]: boolean } = {};
  showAllArcMonitorBackupMails: { [key: number]: boolean } = {};

  showAllArcMonitorDetails = true;
  showAllProviderDetails = false;
  showAllProvider2Details = false;
  showAllArcMonitorBackupDetails = false;
  showPPtManagerDetails = false;
  showCoordinatingInvestigatorDetails = true;
  contactGlobalFilter: ContactGlobalFilter = new ContactGlobalFilter();
  contacts: Contact[] = [];
  contacts2: Contact[] = [];
  countries: Country[] = [];
  values: any[] = [];
  configReady = new EventEmitter<boolean>(false);

  constructor(
      private trialPersonalInformationService: TrialPersonalInformationService,
      private trialHumanResourceService: TrialHumanResourceService,
      private router: Router,
      private sharedService: SharedService,
      private dataService: DataService,
      private cfr: ComponentFactoryResolver,
      private canDeactivateService: CanDeactivateService,
      private providerService: ProviderService,
      private dynamicConfigService: DynamicConfigService,
      private humanResourceService: HumanResourcesService,
      private contactService: ContactService
  ) {
    super(new TrialHumanResource());
    this.canDeactivateService.canBeDeactivated = true;
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.mode = 'edit';
      this.trialSiteHhhId = url.queryParams.trialSiteLiaisonHhhId;
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    if (this.trialSiteHhhId) {
      this.trialPersonalInformationService.setTrialSiteLiaisonHhhId(this.trialSiteHhhId);
    }
    if (this.trialHhhId) {
      this.getTrialIdentificationInformation();
    } else {
      super.getUnsavedTrialInformationGeneral();
    }
    this.initializeIndexes();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.ghtMontBlancCustomizationEnabled = this.dynamicConfigService.getProperty(PropertyEnum.ghtMontBlancCustomizationEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
        config => {
          this.config = config;
          this.configReady.emit(true);
        }
    );
  }

  getService() {
    return this.trialPersonalInformationService;
  }

  getTrialIdentificationInformation() {
    this.trialHumanResourceService.load(this.trialHhhId, this.trialSiteHhhId).subscribe((res: TrialHumanResource) => {
      this.trialInformation = res;
      this.trialInformation.trialSiteHhhId = this.trialSiteHhhId;
      if (!Util.isNullOrUndefined(res.providerHhhId)) {
        this.selectedProviders.push(res.providerHhhId);
      }
      if (!Util.isNullOrUndefined(res.provider2HhhId)) {
        this.selectedProviders.push(res.provider2HhhId);
      }
      this.getContactList(res.providerHhhId, this.contacts);
      this.getContactList(res.provider2HhhId, this.contacts2);
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while loading trialIdentificationInformation.' + error);
    });
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.trialInformation.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialHumanResource, this.trialInformation.hhhId , event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.trialInformation.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  saveIdentificationInformation() {
    if (!this.trialInformation.isValid(this.config, this.investigatorSpecialityOther, false, false)) {
      return;
    }
    this.saveHumanResource();
    this.saveProviders();
    this.trialInformation.trialHhhId = this.trialHhhId;
    this.trialHumanResourceService.updatePersonalInformation(this.trialHhhId, this.trialSiteHhhId, this.trialInformation).subscribe(() => {
          this.sharedService.showSuccess();
          this.canDeactivateService.canBeDeactivated = true;
        }, error => {
          console.error('An error occurred while update trial identification.' + error);
          this.sharedService.showFailure();
        }
    );
  }

  saveHumanResource() {
    const allHumanResources: DetailedHumanResource[] = [...this.arcMonitors, ...this.arcMonitorBackups];

    allHumanResources.forEach(humanResource => {
      this.humanResourceService.update(humanResource).subscribe(() => {
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    });
  }

  saveProviders() {
    this.contacts.forEach(contact => {
      this.contactService.updateContact(contact).subscribe(() => {
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    });
    this.contacts2.forEach(contact => {
      this.contactService.updateContact(contact).subscribe(() => {
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    });
  }

  sendNewTrialIdentificationInfo() {
    this.trialPersonalInformationService.setTrialIdentificationInfo(this.trialInformation);
  }

  isEditMode() {
    return this.mode === 'edit';
  }

  formHasBeenChanged() {
    this.sendNewTrialIdentificationInfo();
    this.canDeactivateService.canBeDeactivated = false;
  }

  changeTrialPromoter() {
    if (this.trialInformation.promoterHhhId == null) {
      return;
    }
    this.providerService.getPromoterTypeByProviderHhhId(this.trialInformation.promoterHhhId).subscribe((res: PromoterType) => {
      this.trialInformation.promoterTypeHhhId = res.hhhId;
    });
    this.formHasBeenChanged();
  }

  displaySelectedProviders(selectedValues) {
    if (selectedValues && selectedValues.length > 0) {
      this.selectedProviders = [selectedValues[1]];
    } else {
      this.selectedProviders = [];
    }
  }

    displaySelectedMonitorArcs(selectedValues) {
    this.selectedMonitorArcs = selectedValues;
  }

  getHumanResource(id: number) {
    this.selectedMonitorArcs = [];
    this.arcMonitors = [];
    if (!Util.isNullOrUndefined(id)) {
      if (!this.arcMonitors.some(rh => rh.hhhId === id)) {
        this.humanResourceService.loadDetailed(id).subscribe(humanResource => {
          this.arcMonitors.push(humanResource);
        });
      }
    }

  }

  getHumanResources(ids: number[]) {
    this.arcMonitorBackups = [];
    this.selectedMonitorArcs = [];
    ids.forEach(id => {
      if (!Util.isNullOrUndefined(id)) {
        if (!this.arcMonitorBackups.some(rh => rh.hhhId === id)) {
          this.humanResourceService.loadDetailed(id).subscribe(humanResource => {
            this.arcMonitorBackups.push(humanResource);
          });
        }
      }
    });
  }

  getContactList(providerId: number, contactsArray: any[], filters?: any) {
    contactsArray.length = 0;
    if (filters) {
      this.contactGlobalFilter = Object.assign({}, filters);
    }
    if (this.selectedProviders.some(pr => pr === providerId)) {
      this.contactService.getContacts(providerId, 'Provider', 0, 50, 'name', 'desc', this.contactGlobalFilter).subscribe((res) => {
        contactsArray.push(...res.content);
      });
    }
  }

  displayFormDialogue(selection: string, contact?: Contact) {
    const formComponent = ContactFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.countries = this.countries;
    let providerId = null;
    if (selection === 'provider') {
      providerId = this.trialInformation.providerHhhId;
    }
    if (selection === 'provider2') {
      providerId = this.trialInformation.provider2HhhId;
    }
    const isEdit: boolean = !Util.isNullOrUndefined(contact);
    formComponent.formHeader = 'MODULE_CONTACTABLE_MEMBERS_GENERAL_ADD_NEW';
    formComponent.contact = new Contact();
    formComponent.onAdd((newContact: Contact) => {
      const index = this.values.indexOf(contact);
      if (index !== -1) {
        newContact = new Contact().clone(newContact);
        this.values[index] = newContact;
      } else {
        this.values.push(newContact);
      }
      newContact.initTotalInformation();
      if (selection === 'provider') {
        this.getContactList(providerId, this.contacts);
      }
      if (selection === 'provider2') {
        this.getContactList(providerId, this.contacts2);
      }
    });
  }

  toggleShowAllItems(array: { [key: number]: boolean }, index: number) {
    array[index] = !array[index];
  }
  toggleShowAllArcMonitorDetails() {
    this.showAllArcMonitorDetails = !this.showAllArcMonitorDetails;
  }
  toggleShowAllArcMonitorBackupDetails() {
    this.showAllArcMonitorBackupDetails = !this.showAllArcMonitorBackupDetails;
  }
  initializeIndexes() {
    this.selectedProviders.forEach((_, index) => {
      this.showAllProviderMails[index] = false;
      this.showAllProviderPhones[index] = false;
    });
    this.arcMonitors.forEach((_, index) => {
      this.showAllArcMonitorMails[index] = false;
      this.showAllArcMonitorPhones[index] = false;
    });
    this.arcMonitorBackups.forEach((_, index) => {
      this.showAllArcMonitorBackupMails[index] = false;
      this.showAllArcMonitorBackupPhones[index] = false;
    });
  }

  toggleShowCoordinatingInvestigatorDetails(): void {
    this.showCoordinatingInvestigatorDetails = !this.showCoordinatingInvestigatorDetails;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  displayField = (fieldName: string) => this.config.getFieldConfig(fieldName).display;

}

import {Component} from '@angular/core';

@Component({
  selector: 'ih-provider-icon',
  templateUrl: './provider-icon.component.html',
  styleUrls: ['./provider-icon.component.css']
})
export class ProviderIconComponent {

  constructor() {}
}

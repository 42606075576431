import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FR} from '../../entity/calendar-language';
import {isUndefined} from 'util';
import {Calendar} from 'primeng/calendar';

export const CALENDAR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CalendarComponent),
  multi: true
};

@Component({
  selector: 'ih-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
  providers: [CALENDAR_VALUE_ACCESSOR]
})
export class CalendarComponent implements OnInit, ControlValueAccessor {
  @ViewChild('calendarComponent') calendarComponent: Calendar;

  @Input() locale = FR;
  @Input() checkedCalendar: boolean;
  @Input() disabled: boolean;
  @Input() important = false;
  @Input() hasClearButton: boolean;
  @Input() selectionMode = 'single';
  @Input() appendTo: 'body';
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Output() onSelect: EventEmitter<void> = new EventEmitter<void>();
  @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClearEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() defaultDate: Date = null;
  @Input() showIcon = false;

  constructor() {
  }

  private _value: any;

  get dateValue(): Date {
    return (this.checkedCalendar && !isUndefined(this._value)) ? this._value.date : this._value;
  }

  set dateValue(v: Date) {
    if (this.checkedCalendar) {
      if (this._value && v !== this._value.date) {
        this._value.date = v;
        this.onChange(this._value);
      }
    } else {
      if (v !== this._value) {
        this._value = v;
        this.onChange(v);
      }
    }
  }

  get checkedValue(): boolean {
    return (this.checkedCalendar && !isUndefined(this._value)) ? this._value.checked : false;
  }

  set checkedValue(v: boolean) {
    if (this._value && v !== this._value.checked) {
      this._value.checked = v;
      this.onChange(this._value);
    }
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  ngOnInit(): void {
  }

  writeValue(v: any) {
    if (v) {
      this._value = v;
      this.onChange(v);
    }
  }

  onClearEventDo() {
    this.onClearEvent.emit();
  }

  onSelectDo() {
    this.dateValue.setHours(12, 0, 0, 0);
    this.onSelect.emit();
  }

  onBlurDo() {
    if (this.dateValue) {
      this.dateValue.setHours(12, 0, 0, 0);
    }
    this.onBlur.emit();
  }

  onCloseDo() {
    if (this.dateValue) {
      this.dateValue.setHours(12, 0, 0, 0);
    }
    this.onClose.emit();
  }

  onChange(v: any) {
  }

  onTouched(v: any) {
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (_: any) => void): void {
    this.onTouched = fn;
  }

  reset() {
    this.calendarComponent.updateModel(null);
    this.calendarComponent.updateInputfield();
  }

  clearDate() {
    this.dateValue = null;
    this.onClearEvent.emit();
  }
}

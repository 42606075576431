import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../shared/component/form/form.component';
import {Trial} from '../models/trial';
import {SharedService} from '../../shared/services/shared.service';
import {TrialsManagementService} from '../services/trials-management.service';
import {FR} from '../../shared/entity/calendar-language';
import {TrialFile} from '../models/trial-file';
import {Data} from '../../shared/entity/data';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {Router} from '@angular/router';
import {DataDocumentService} from '../../data-document/service/data-document.service';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {Util} from '../../helpers/util';


@Component({
  selector: 'ih-edit-trial-form',
  templateUrl: './edit-trial-form.component.html',
  styleUrls: ['./edit-trial-form.component.css']
})
export class EditTrialFormComponent extends FormComponent<Trial> implements OnInit {

  readonly isStringNullOrEmpty = Util.isStringNullOrEmpty;

  trial: Trial = new Trial();
  locale = FR;
  trialSchemaFile: TrialFile;
  deleteCallback: (trialHhhId: number) => void;
  yesOrNo = Data.yesOrNoOrNa;

  latestTrialProtocolName = '';

  constructor(private trialsManagementService: TrialsManagementService,
              private sharedService: SharedService,
              private translateService: InnohealthTranslateService,
              private dataDocumentService: DataDocumentService,
              private router: Router) {
    super();
  }

  public static display(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(EditTrialFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  static getUpdateTitle(): string {
    return 'MODULE_MOBILE_APPLICATION_UPDATE_TRIAL';
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.trial.lastProtocolHhhId > 0) {
      this.latestTrialProtocolName = this.translateService.getTranslationString('MODULE_MOBILE_APPLICATION_ACCOUNT_LAST_USED_PROTOCOL') + this.trial.lastProtocolName;
    }
  }

  setTrial(trial: Trial) {
    this.trial = new Trial().copy(trial);
  }

  submitTrial() {
    this.trialsManagementService.updateTrial(this.trial).subscribe(res => {
      this.callback(this.trial);
      this.sharedService.showSuccess();
      this.display = false;
    }, error => {
      this.sharedService.showFailure();
    });
  }

  uploadFile(event: any) {
    this.dataDocumentService.uploadDocument(AttachedToEntityEnum.TrialSchema, this.trial.hhhId, event.files[0]).subscribe(res => {
      this.trial.trialSchema = res;
      this.sharedService.showSuccess();
    });
  }

  deleteFile(hhhId: number) {
    this.dataDocumentService.deleteDocument(hhhId).subscribe(() => {
      this.trial.trialSchema = undefined;
      this.sharedService.showSuccess();
      this.deleteCallback(this.trial.hhhId);
    }, error => {
      this.sharedService.showFailure();
    });
  }

  onDeleteFile(callback: (trialHhhId: number) => void) {
    this.deleteCallback = callback;
  }

  isMobileTrialEditForVisualisationOnly(): boolean {
    return true;
  }

  goToTrialEdit() {
    this.router.navigate(['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId: this.trial.hhhId}});
  }

  downloadFile(uuid: string, fileName: string): void {
    this.dataDocumentService.downloadDocument(uuid, fileName).subscribe();
  }

}

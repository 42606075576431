import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {AdverseEffect} from '../../../../entity/inclusion/adverse-effect';
import {Router} from '@angular/router';
import {AdverseEffectFormComponent} from '../adverse-effect-form/adverse-effect-form.component';
import {AdverseEffectService} from '../../../../service/adverse-effect.service';
import {PaginatorTableComponent} from '../../../../../shared/component/paginator-table/paginator-table.component';
import {DataService} from '../../../../../shared/services/data-service';
import {DynamicTableHeader} from '../../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {TrialAppData} from '../../../../trial-app-data';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../../dynamic-config/entity/table-config';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {AttachedToEntityEnum} from '../../../../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-adverse-effect',
  templateUrl: './adverse-effect.component.html',
  styleUrls: ['./adverse-effect.component.css']
})
export class AdverseEffectComponent extends PaginatorTableComponent<AdverseEffect> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.AdverseEffect;
  readonly target = DynamicDefinitionEnum.TRIAL_ADVERSE_EFFECT_TABLE;
  config: TableConfig;

  headers: DynamicTableHeader[];
  inclusionHhhId: number;

  constructor(
      private adverseEffectService: AdverseEffectService,
      private dataService: DataService,
      private cfr: ComponentFactoryResolver,
      private router: Router,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.inclusionHhhId = url.queryParams.inclusionId;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.initTableConfig();
    this.getInclusionAdverseEffect();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('visit')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('name')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('occurrenceDate')
        .sortable()
        .withDateFormatter()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('declarationDate')
        .sortable()
        .withDateFormatter()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('resolutionDate')
        .sortable()
        .withDateFormatter()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('severity')
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getUpdateTitle(): string {
    return 'MODULE_TRIALS_PATIENTS_ADVERSE_EFFECTS_GENERAL_UPDATE';
  }

  getDeleteMessage(): string {
    return 'MODULE_TRIALS_PATIENTS_ADVERSE_EFFECTS_DIALOG_WARNING_PATIENT_TRIAL_INCLUSION_WILL_BE_DELETED';
  }

  getService(): any {
    return this.adverseEffectService;
  }

  getInclusionAdverseEffect() {
    this.adverseEffectService.loadList(this.inclusionHhhId).subscribe(res => {
      this.values = res;
      this.totalNumber = this.values.length;
    });
  }

  displayFormDialogue(adverseEffect?: AdverseEffect) {
    const formComponent = AdverseEffectFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (adverseEffect) {
      if (adverseEffect.declarationDate !== null) {
        adverseEffect.declarationDate = new Date(adverseEffect.declarationDate);
      } else {
        adverseEffect.declarationDate = null;
      }
      if (adverseEffect.occurrenceDate !== null) {
        adverseEffect.occurrenceDate = new Date(adverseEffect.occurrenceDate);
      } else {
        adverseEffect.occurrenceDate = null;
      }
      if (adverseEffect.resolutionDate !== null) {
        adverseEffect.resolutionDate = new Date(adverseEffect.resolutionDate);
      } else {
        adverseEffect.resolutionDate = null;
      }
      formComponent.adverseEffect = (new AdverseEffect()).init(adverseEffect);
      formComponent.formHeader = this.getUpdateTitle();
    }
    formComponent.onAdd(this.updateList(formComponent.adverseEffect));
  }

  refreshAfterDelete(entity?: any): void {
    const index = this.values.findIndex((adverseEffect: AdverseEffect) => adverseEffect.hhhId === entity.hhhId);
    this.values.splice(index, 1);
  }

  updateList(newEntity: AdverseEffect) {
    return (c: AdverseEffect) => {
      const index = this.values.findIndex(e => e.hhhId === newEntity.hhhId);
      if (index !== -1) {
        this.values[index] = newEntity;
      }
    };
  }
}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ConventionType} from '../../../models/convention-type';
import {TrialService} from '../../../services/trial.service';
import {FR} from '../../../../shared/entity/calendar-language';
import {Data} from '../../../../shared/entity/data';
import {DataService} from '../../../../shared/services/data-service';
import {SharedService} from '../../../../shared/services/shared.service';
import {Router} from '@angular/router';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {OtherAmendedFormComponent} from '../other-amended-form/other-amended-form.component';
import {TrialHead} from '../../../models/trial-head';
import {TrialAdministrativeDatesInformation} from '../../../models/trial-administrative-dates-information';
import {TrialAdministrativeDatesService} from '../../../services/trial-administrative-dates.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {OtherTheoreticalInvoiceDateComponent} from '../../../../modals/other-theoretical-invoice-date/other-theoretical-invoice-date.component';
import {AppData} from '../../../../helpers/app-data';
import {TrialAdministrativeInformationService} from '../../../services/trial-administrative-information.service';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';
import {Util} from '../../../../helpers/util';

@Component({
  selector: 'ih-trial-administrative-dates-information',
  templateUrl: './trial-administrative-dates-information.component.html',
  styleUrls: ['./trial-administrative-dates-information.component.css']
})
export class TrialAdministrativeDatesInformationComponent implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_DATES_INFORMATION_FORM;
  config: FormConfig = new FormConfig();
  multipleTheoreticalInvoiceDateEnabled = true;
  isAmendmentEnabled = false;
  ghtMontBlancCustomizationEnabled = false;

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  dates: TrialAdministrativeDatesInformation = new TrialAdministrativeDatesInformation();
  trialId: number;
  conventionTypes: ConventionType[] = [];
  locale = FR;
  yesOrNoOrNoAnswerEnum: any;
  yesOrNoEnum: any;
  yesOrNoOrNoAnswer: any;
  yesNoWithEmptyOption: Array<any>;
  trialSiteLiaisonHhhId = 0;
  trialHead: TrialHead = new TrialHead(null);
  noYesOptions = Data.yesOrNo;
  submitted = false;
  selectedStatus: number;

  constructor(
    public trialService: TrialService,
    private trialAdministrativeDatesService: TrialAdministrativeDatesService,
    private dataService: DataService,
    private sharedService: SharedService,
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private dynamicConfigService: DynamicConfigService,
    private trialAdministrativeInformationService: TrialAdministrativeInformationService,
    private canDeactivateService: CanDeactivateService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
      this.trialSiteLiaisonHhhId = url.queryParams.trialSiteLiaisonHhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    if (this.trialSiteLiaisonHhhId) {
      this.trialService.setTrialSiteLiaisonHhhId(this.trialSiteLiaisonHhhId);
    }
    this.getTrialAdministrativeDatesInformation();
    this.getTrialHead();
  }

  initProperties(): void {
    this.yesNoWithEmptyOption = Data.yesOrNoOrNull;
    this.yesOrNoOrNoAnswer = Data.yesOrNoOrNoAnswer;
    this.yesOrNoOrNoAnswerEnum = Data.yesOrNoOrNoAnswerEnum;
    this.yesOrNoEnum = Data.yesOrNoEnum;

    this.dynamicConfigService.initProperties().subscribe(() => {
      this.multipleTheoreticalInvoiceDateEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multipleTheoreticalInvoiceDateEnabled);
      this.isAmendmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.ghtMontBlancCustomizationEnabled = this.dynamicConfigService.getProperty(PropertyEnum.ghtMontBlancCustomizationEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  displayField = (fieldName: string) => this.config.displayField(fieldName);
  verifyClient = (clients: string[]) => this.dynamicConfigService.verifyClient(clients);

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.dates.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialAdministrativeDate, this.dates.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.dates.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }


  updateAdministrativeInformation() {
    if (!this.dates.isValid(this.config, this.isAmendmentEnabled)) {
      return;
    }
    if (typeof this.dates.conventionStatus === 'number') {
      this.dates.conventionStatus = [this.dates.conventionStatus];
    }
    localStorage.setItem('modificationSaved', 'true');
    this.dates.trialHhhId = this.trialId;
    this.dates.trialSiteHhhId = (this.trialSiteLiaisonHhhId || 0);
    this.trialAdministrativeDatesService.updateAdministrativeDatesInformation(this.dates).subscribe(() => {
      this.sharedService.showSuccess();
      this.sharedService.setInformationInLocalStorage('Études', 'Éditer', this.trialHead.localIdentifier);
      this.canDeactivateService.canBeDeactivated = true;
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  addOtherAmendedAgreement() {
    const showPopupFactory = this.cfr.resolveComponentFactory(OtherAmendedFormComponent);
    const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
    const otherAmendedFormComponent = showPopupComponentRef.instance;
    otherAmendedFormComponent.setTrialId(this.trialId);
    otherAmendedFormComponent.trialSiteLiaisonId = this.trialSiteLiaisonHhhId;
    otherAmendedFormComponent.onAdd((callbackValue: {value: string, count: number}) => {
      this.dates.amendedAgreementStream = callbackValue.value;
      this.dates.countOtherAmendedAgreement = callbackValue.count;
    });
  }

  addOtherTheoreticalInvoiceDate() {
    const showPopupFactory = this.cfr.resolveComponentFactory(OtherTheoreticalInvoiceDateComponent);
    const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
    const otherAmendedFormComponent = showPopupComponentRef.instance;
    otherAmendedFormComponent.setTrialId(this.trialId);

  }

  onConventionTypeChange() {
    this.dates.conventionTypesHhhId = [];
    if (this.dates.conventionType != null) {
      this.dates.conventionTypesHhhId.push(this.dates.conventionType);
    }
  }

  getTrialHead() {
    this.trialService.getTrialHead(this.trialId).subscribe(trialHead => {
      this.trialHead = trialHead;
    });
  }

  private getTrialAdministrativeDatesInformation() {
    this.trialAdministrativeDatesService.load(this.trialId, this.trialSiteLiaisonHhhId).subscribe(
      res => {
        this.dates = res;
        if (!Util.isNullOrUndefinedOrEmpty(this.dates.conventionStatus)) {
          this.selectedStatus = this.dates.conventionStatus[0];
        }
        if (this.dates.conventionTypesHhhId && this.dates.conventionTypesHhhId.length > 0) {
          this.dates.conventionType = this.dates.conventionTypesHhhId[0];
        }
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred in trialAdministrative.' + error);
      });
  }

  export(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.trialId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .build();
    this.trialAdministrativeDatesService.exportAdministrativeDates(request, this.trialSiteLiaisonHhhId).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }

  updateConventionStatus(selectedValue: number) {
    this.dates.conventionStatus = [selectedValue];
  }
}

import {CalendarCheckbox} from './calendar-checkbox';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {DataCommentCount} from '../../data-comment/entity/data-comment-count';

export class TrialAdministrativeDatesInformation extends DataCommentCount {

  hhhId: number;
  trialHhhId: number;
  localIdentifier: string;
  trialSiteHhhId: number;
  multiSite: boolean;
  siteName: string;
  amendedAgreement: boolean;
  amendedAgreementStream: string;
  countOtherAmendedAgreement: number;
  archiveDuration: string;
  inclusionDate: Date;
  completeSigningDate: Date;
  finalizationCuhAgreementDate: Date;
  receivedConventionDate: Date;
  revisedConventionDate: Date;
  conventionStatus: number[] = [];
  coordinatorName: string;
  conventionAdditionalCosts: 'YES' | 'NO' | 'NO_ANSWER';
  agreementSignatoryDoctor: string;
  publicationYear1: number;
  publicationYear2: number;
  havingTheoreticalInvoiceDate: 'YES' | 'NO' | 'NO_ANSWER';
  theoreticalInvoiceDate: Date;
  havingTheoreticalInvoiceDateComment: string;
  financialClose: boolean;
  preArchiveBoxNumber: string;
  preArchiveRadius: string;
  preArchiveInvoicementIsValid: 'YES' | 'NO' | 'NO_ANSWER';
  preArchiveLastInvoicementDate: Date;
  archiveBoxNumber: string;
  archiveNumber: string;
  contratExportName: string;
  archiveClosingDate: Date;
  destructionYear: string;
  archiveRadius: string;
  archivePharmacy: boolean;
  reportDestructed: boolean;
  promoterDestructionRequestDate: Date;
  destructionValidation: boolean;
  conventionComment: string;
  crcNumber: string;
  financingDrug: boolean;
  studyCRC: any;
  archivePlace: string;
  sendingConventionDate: Date;
  pharmacyMagazine: string;
  destructionDate: Date;
  conventionTypesHhhId: number[];
  preArchiveDate: Date;
  obtainingYear: number;
  financeHhhId: number[];
  siteHhhId: number;
  ghdcssHhhId: number;
  theoreticalTrialDuration: string;
  actualTrialDuration: string;
  provisionalClosingForInclusions2: string;
  definitiveClosingYear: string;
  invoiceFrequencyHhhId: number;
  participationCenterAgreement: boolean;
  commentary: string;
  amendmentNumber: string;
  invoiceMethod: string;
  invoiceContact: string;
  invoiceAddress: string;
  archivingLocation: string;
  administrativeContact: string;
  invoiceable: boolean;
  lastInvoicingDate: Date;
  projectedArchivingEndDate: Date;
  finalInvoiceDateComment: string;
  conventionAmount: string;
  followupToDeath: boolean;

  amendedAgreementCalendar: CalendarCheckbox;
  analysis: CalendarCheckbox;
  completeReceipt: CalendarCheckbox;
  conventionSignatureByPromoter: CalendarCheckbox;
  provisionalProductionLaunch: CalendarCheckbox;
  provisionalClosingForInclusions: CalendarCheckbox;
  trialEnd: CalendarCheckbox;
  lastPatientVisit: CalendarCheckbox;
  financialCloseDate: CalendarCheckbox;
  audit: CalendarCheckbox;
  inspection: CalendarCheckbox;
  biologicalCollectionAuthorization: CalendarCheckbox;
  reception: CalendarCheckbox;
  conventionSignatureByCentre: CalendarCheckbox;
  directionInformationCourierDate: CalendarCheckbox;
  insuranceAuthorization: CalendarCheckbox;
  provisionalOpening: CalendarCheckbox;
  actualTrialEndDate: CalendarCheckbox;
  firstPatientConsentSignatureDate: CalendarCheckbox;
  archive: CalendarCheckbox;
  checklistValidationDate: CalendarCheckbox;
  checklistValidationDate1: CalendarCheckbox;
  realClosingDate: CalendarCheckbox;
  receivedAgreementSignedByAllPartiesDate: CalendarCheckbox;
  sentAgreementToPromoter: CalendarCheckbox;
  actualProductionLaunch: CalendarCheckbox;
  actualOpening: CalendarCheckbox;
  centerActivation: CalendarCheckbox;
  actualClosingForInclusions: CalendarCheckbox;
  actualFollowupEnd: CalendarCheckbox;
  trialClosing: CalendarCheckbox;
  finalSigningDate: CalendarCheckbox;
  finalInvoiceDate: CalendarCheckbox;
  prematureTerminationDate: CalendarCheckbox;
  temporarySuspensionOfInclusions: CalendarCheckbox;
  trialStartClosingDate: CalendarCheckbox;
  conventionType: number;

  init(obj: any): TrialAdministrativeDatesInformation {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  isValid(config: FormConfig, isAmendmentEnabled: boolean): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'invoiceable') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.havingTheoreticalInvoiceDate)) {
          return false;
        }
      } else if (key === 'theoreticalInvoiceDate') {
        if (this.havingTheoreticalInvoiceDate === 'YES' && required && Util.isNullOrUndefinedOrEmpty(this.theoreticalInvoiceDate)) {
          return false;
        }
      } else if (key === 'theoreticalInvoiceDateComment') {
        if (this.havingTheoreticalInvoiceDate === 'YES' && required && Util.isNullOrUndefinedOrEmpty(this.havingTheoreticalInvoiceDateComment)) {
          return false;
        }
      } else if (key === 'financialClose') {
        if (this.multiSite && required && Util.isNullOrUndefinedOrEmpty(this.havingTheoreticalInvoiceDateComment)) {
          return false;
        }
      } else if (key === 'financialCloseDate') {
        if (this.multiSite && this.financialClose && required && Util.isNullOrUndefinedOrEmpty(this.financialCloseDate.date)) {
          return false;
        }
      } else if (key === 'checklistValidationDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.checklistValidationDate.date)) {
          return false;
        }
      } else if (key === 'checklistValidationDate1') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.checklistValidationDate1.date)) {
          return false;
        }
      } else if (key === 'realClosingDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.realClosingDate.date)) {
          return false;
        }
      } else if (key === 'conventionSignatureByPromoter') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.conventionSignatureByPromoter.date)) {
          return false;
        }
      } else if (key === 'receivedAgreementSignedByAllPartiesDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.receivedAgreementSignedByAllPartiesDate.date)) {
          return false;
        }
      } else if (key === 'sentAgreementToPromoter') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.sentAgreementToPromoter.date)) {
          return false;
        }
      } else if (key === 'amendedAgreementCalendar') {
        if (((!isAmendmentEnabled && !this.multiSite) || (this.multiSite && this.amendedAgreement)) && required && Util.isNullOrUndefinedOrEmpty(this.amendedAgreementCalendar.date)) {
          return false;
        }
      } else if (key === 'amendedAgreement') {
        if (this.multiSite && required && Util.isNullOrUndefinedOrEmpty(this.sentAgreementToPromoter.date)) {
          return false;
        }
      } else if (key === 'amendedAgreementStream') {
        if (isAmendmentEnabled && required && Util.isNullOrUndefinedOrEmpty(this.amendedAgreementStream)) {
          return false;
        }
      } else if (key === 'biologicalCollectionAuthorization') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.biologicalCollectionAuthorization.date)) {
          return false;
        }
      } else if (key === 'site') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.siteHhhId)) {
          return false;
        }
      } else if (key === 'reception') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.reception.date)) {
          return false;
        }
      } else if (key === 'completeReceipt') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.completeReceipt.date)) {
          return false;
        }
      } else if (key === 'conventionSignatureByCentre') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.conventionSignatureByCentre.date)) {
          return false;
        }
      } else if (key === 'analysis') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.analysis.date)) {
          return false;
        }
      } else if (key === 'directionInformationCourierDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.directionInformationCourierDate.date)) {
          return false;
        }
      } else if (key === 'insuranceAuthorization') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.insuranceAuthorization.date)) {
          return false;
        }
      } else if (key === 'provisionalProductionLaunch') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.provisionalProductionLaunch.date)) {
          return false;
        }
      } else if (key === 'provisionalOpening') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.provisionalOpening.date)) {
          return false;
        }
      } else if (key === 'provisionalClosingForInclusions') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.provisionalClosingForInclusions.date)) {
          return false;
        }
      } else if (key === 'trialEnd') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.trialEnd.date)) {
          return false;
        }
      } else if (key === 'actualFollowupEnd') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.actualFollowupEnd.date)) {
          return false;
        }
      } else if (key === 'trialClosing') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.trialClosing.date)) {
          return false;
        }
      } else if (key === 'audit') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.audit.date)) {
          return false;
        }
      } else if (key === 'actualProductionLaunch') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.actualProductionLaunch.date)) {
          return false;
        }
      } else if (key === 'centerActivation') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.centerActivation.date)) {
          return false;
        }
      } else if (key === 'actualOpening') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.actualOpening.date)) {
          return false;
        }
      } else if (key === 'actualTrialEndDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.actualTrialEndDate.date)) {
          return false;
        }
      } else if (key === 'actualClosingForInclusions') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.actualClosingForInclusions.date)) {
          return false;
        }
      } else if (key === 'firstPatientConsentSignatureDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.firstPatientConsentSignatureDate.date)) {
          return false;
        }
      } else if (key === 'theoreticalInvoiceDateComment') {
        if (this.havingTheoreticalInvoiceDate === 'YES' && required && Util.isNullOrUndefinedOrEmpty(this.havingTheoreticalInvoiceDateComment)) {
          return false;
        }
      } else if (key === 'lastPatientVisit') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.lastPatientVisit.date)) {
          return false;
        }
      } else if (key === 'inspection') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.inspection.date)) {
          return false;
        }
      } else if (key === 'financialClose') {
        if (this.multiSite && required && Util.isNullOrUndefinedOrEmpty(this.financialClose)) {
          return false;
        }
      } else if (key === 'financialCloseDate') {
        if (this.multiSite && this.financialClose && required && Util.isNullOrUndefinedOrEmpty(this.financialCloseDate.date)) {
          return false;
        }
      } else if (key === 'archive') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.archive.date)) {
          return false;
        }
      } else if (key === 'conventionStatusList' || key === 'conventionStatus') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.conventionStatus)) {
          return false;
        }
      } else if (key === 'destructionDate_archivingSection') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.destructionDate)) {
          return false;
        }
      } else if (key === 'archiveClosingDate_archivingSection') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.archiveClosingDate)) {
          return false;
        }
      } else if (key === 'archiveNumber_archivingSection') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.archiveNumber)) {
          return false;
        }
      } else if (key === 'archiveBoxNumber_archivingSection') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.archiveBoxNumber)) {
          return false;
        }
      } else if (key === 'promoterDestructionRequestDate_archivingSection') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.promoterDestructionRequestDate)) {
          return false;
        }
      } else if (key === 'finalSigningDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.finalSigningDate.date)) {
          return false;
        }
      } else if (key === 'finalInvoiceDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.finalInvoiceDate.date)) {
          return false;
        }
      } else if (key === 'finalInvoiceDateComment') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.finalInvoiceDateComment)) {
          return false;
        } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
          return false;
        }
      }
    }
    return true;
  }

}

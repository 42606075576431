import {Injectable} from '@angular/core';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {InnohealthTranslateService} from './innohealth-translate.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {LabelValue} from '../entity/label-value';
import {CustomExport} from '../../trial/models/custom-export';
import {LocalStorageService} from '../../local-storage-service.service';
import {PersonalHistory} from '../../home/models/personal-history';

@Injectable({
  providedIn: 'root'
})

export class SharedService {
  public static rh = [
    {field: 'title', rh: 'title'},
    {field: 'lastName', rh: 'lastName'},
    {field: 'firstName', rh: 'firstName'},
    {field: 'establishment', rh: 'establishment'},
    {field: 'service', rh: 'servicesHhhId'},
    {field: 'site', rh: 'siteHhhId'},
    {field: 'roles', rh: 'responsibilitiesHhhId'},
    {field: 'speciality', rh: 'specialitiesHhhId'},
    {field: 'registrationNumber', rh: 'registrationNumber'},
    {field: 'rpps', rh: 'rpps'},
    {field: 'department', rh: 'departmentsHhhId'},
    {field: 'committee', rh: 'committeesHhhId'},
    {field: 'acronymDepartment', rh: 'acronymDepartmentHhhId'},
    {field: 'acronymSimbad', rh: 'acronymSimbad'},
    {field: 'entryDate', rh: 'entryDate'},
    {field: 'releaseDate', rh: 'releaseDate'},
    {field: 'cnomNumber', rh: 'cnom'},
    {field: 'fieldAdded1', rh: 'fieldAdded1'},
    {field: 'fieldAdded2', rh: 'fieldAdded2'},
    {field: 'fieldAdded3', rh: 'fieldAdded3'},
    {field: 'adeliNumber', rh: 'adeli'}
  ];
  public static establishment = [
    {field: 'finessNumber', rh: 'finessNumber'},
    {field: 'siretCode', rh: 'siretCode'},
    {field: 'address', rh: 'address'},
    {field: 'legalRepresentative', rh: 'legalRepresentative'},
    {field: 'postalCode', rh: 'postalCode'},
    {field: 'city', rh: 'city'},
    {field: 'country', rh: 'country'},
    {field: 'number', rh: 'number'},
    {field: 'fieldAdded1', rh: 'fieldAdded1'},
    {field: 'fieldAdded2', rh: 'fieldAdded2'},
    {field: 'fieldAdded3', rh: 'fieldAdded3'}
  ];
  public static patient = [
    {field: 'sex', rh: 'sex'},
    {field: 'lastName', rh: 'lastName'},
    {field: 'firstName', rh: 'firstName'},
    {field: 'establishment', rh: 'externalDoctorEstablishment'},
    {field: 'birthName', rh: 'birthName'},
    {field: 'internalIdentifier', rh: 'internalIdentifier'},
    {field: 'birthDate', rh: 'birthDate'},
    {field: 'lifeState', rh: 'lifeStateHhhId'},
    {field: 'deathDate', rh: 'deathDate'},
    {field: 'addressedByExternalDoctor', rh: 'addressedByExternalDoctor'},
    {field: 'externalDoctorName', rh: 'externalDoctorName'},
    {field: 'geneList', rh: 'geneHhhIdList'},
    {field: 'hasMutation', rh: 'hasMutation'},
    {field: 'forebodingTrials', rh: 'forebodingTrialsHhhIds'},
    {field: 'hasForebodingTrial', rh: 'hasForebodingTrial'},
    {field: 'fieldAdded1', rh: 'fieldAdded1'},
    {field: 'fieldAdded2', rh: 'fieldAdded2'},
    {field: 'fieldAdded3', rh: 'fieldAdded3'}
  ];
  public static actCategory = [
    {field: 'name', rh: 'nameFr'},
    {field: 'code', rh: 'code'},
    {field: 'fieldAdded1', rh: 'fieldAdded1'},
    {field: 'fieldAdded2', rh: 'fieldAdded2'},
    {field: 'fieldAdded3', rh: 'fieldAdded3'}
  ];
  public static referenceActs = [
    {field: 'actCategoryName', rh: 'actCategoryName'},
    {field: 'description', rh: 'description'},
    {field: 'onPrescription', rh: 'onPrescription'},
    {field: 'invoiceable', rh: 'invoiceable'},
    {field: 'fieldAdded1', rh: 'fieldAdded1'},
    {field: 'fieldAdded2', rh: 'fieldAdded2'},
    {field: 'fieldAdded3', rh: 'fieldAdded3'}
  ];

  public static fixedCostReference = [
    {field: 'contractType', rh: 'contractType'},
    {field: 'actType', rh: 'type'},
    {field: 'fieldAdded1', rh: 'fieldAdded1'},
    {field: 'fieldAdded2', rh: 'fieldAdded2'},
    {field: 'fieldAdded3', rh: 'fieldAdded3'},
    {field: 'fixedCostCategoryName', rh: 'fixedCostCategoryHhhId'},
    {field: 'description', rh: 'description'},
    {field: 'attachedToVisit', rh: 'attachedToVisit'},
    {field: 'invoiceable', rh: 'invoiceable'},
    {field: 'pricePerUnit', rh: 'pricePerUnit'},
    {field: 'internalAccountNumber', rh: 'internalAccountNumber'},
    {field: 'secondInternalAccountNumber', rh: 'secondInternalAccountNumber'},
    {field: 'firstSegment', rh: 'firstSegment'},
    {field: 'secondSegment', rh: 'secondSegment'},
    {field: 'annualInvoicing', rh: 'annualInvoicing'}
  ];

  public static provider = [
    {field: 'name', rh: 'name'},
    {field: 'promoterType', rh: 'promoterType'},
    {field: 'fieldAdded1', rh: 'fieldAdded1'},
    {field: 'fieldAdded2', rh: 'fieldAdded2'},
    {field: 'fieldAdded3', rh: 'fieldAdded3'},
    {field: 'providerType', rh: 'providerType'},
    {field: 'financialEntity', rh: 'financialEntity'}
  ];

  public static sites = [
    {field: 'name', rh: 'name'},
    {field: 'establishment', rh: 'establishmentHhhId'},
    {field: 'fieldAdded1', rh: 'fieldAdded1'},
    {field: 'fieldAdded2', rh: 'fieldAdded2'},
    {field: 'fieldAdded3', rh: 'fieldAdded3'},
  ];
  public properties: Properties = new Properties();
  public host = this.properties.host;
  private history = [];
  private isPopup = false;

  constructor(private router: Router,
              private httpClient: HttpClient,
              private messageService: MessageService,
              private translateService: InnohealthTranslateService,
              private localStorageService: LocalStorageService) {
  }

  getUserCustomExport(userHhhId: number): Observable<CustomExport> {
    return this.httpClient.get<CustomExport>(this.host + '/user-custom-export/' + userHhhId);
  }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history.filter((el, i, a) => i === a.lastIndexOf(el))[this.history.filter((el, i, a) => i === a.indexOf(el))
      .length - 2] || '/index';
  }

  public getValues(module: string) {
    if (module.includes('/human-resource/')) {
      return SharedService.rh;
    } else if (module.includes('/act-category/')) {
      return SharedService.actCategory;
    } else if (module.includes('/rest/patient/')) {
      return SharedService.patient;
    } else if (module.includes('/browse/load-establishment?establishmentHhhId=')) {
      return SharedService.establishment;
    } else if (module.includes('/fixed-cost-category/')) {
      return SharedService.actCategory;
    } else if (module.includes('reference-acts')) {
      return SharedService.referenceActs;
    } else if (module.includes('/fixed-cost-reference/')) {
      return SharedService.fixedCostReference;
    } else if (module.includes('/provider/')) {
      return SharedService.provider;
    } else if (module.includes('/site/')) {
      return SharedService.sites;
    }
  }

  showSuccess(key?: string) {
    this.messageService.add({
      severity: 'success',
      life: 2000,
      summary: this.translateService.getTranslationString('GENERAL_LINGUISTICS_SUCCESS_UFCASE'),
      detail: this.translateService.getTranslationString(key ? key : 'GENERAL_LINGUISTICS_OPERATION_SUCCESS'),
      data: {key: null}
    });
  }

  showFailure(key?: string) {
    this.messageService.add({
      severity: 'error',
      life: 5000,
      summary: this.translateService.getTranslationString('GENERAL_LINGUISTICS_FAILURE_UFCASE'),
      detail: this.translateService.getTranslationString(key ? key : 'GENERAL_LINGUISTICS_FAILURE_OPERATION'),
      data: {key: null}
    });
  }

  showWarning(key?: string) {
    this.messageService.add({
      severity: 'warn',
      life: 5000,
      summary: this.translateService.getTranslationString('GENERAL_LINGUISTICS_CAUTION'),
      detail: this.translateService.getTranslationString(key ? key : 'GENERAL_LINGUISTICS_FAILURE_OPERATION'),
      data: {key: null}
    });
  }

  showDeleteFailure(key?: string, interpolationParams?: any, reason?: string, fn?: any) {
    this.messageService.add({
      severity: 'error',
      life: 2000,
      summary: this.translateService.getTranslationString('GENERAL_LINGUISTICS_FAILURE_UFCASE'),
      detail: this.translateService.getTranslationString(key, interpolationParams),
      data: {reason, fn, key: 'withHtml'}
    });
  }

  showTrialActDeleteFailure(key?: string, interpolationParams?: any, reason?: any[], fn?: any) {
    this.messageService.add({
      severity: 'error',
      life: 2000,
      summary: this.translateService.getTranslationString('GENERAL_LINGUISTICS_FAILURE_UFCASE'),
      detail: this.translateService.getTranslationString(key, interpolationParams),
      data: {reason, fn, key: 'trialActDeletion'}
    });
  }

  showFileLoadingSuccess() {
    this.messageService.add({
      severity: 'success',
      life: 5000,
      summary: this.translateService.getTranslationString('GENERAL_LINGUISTICS_SUCCESS_UFCASE'),
      detail: this.translateService.getTranslationString('MODULE_IMPORT_DIALOG_MESSAGE_IMPORT_SUCCESSFUL'),
      data: {key: null}
    });
  }


  delete(type: string, hhhId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(this.host + '/' + type + '/delete/' + hhhId);
  }

  isEmpty(varElement: string) {
    return !varElement || varElement.length === 0;
  }

  setInformationInLocalStorage(module: string, action: string, item: string) {
    const d = new Date();
    const date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    const hours = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    const fullDate = date + ' ' + hours;
    const personalHistory = new PersonalHistory();
    personalHistory.module = module;
    personalHistory.action = action;
    personalHistory.item = item;
    personalHistory.date = fullDate;
    this.localStorageService.setDataHistory(personalHistory);
  }

  getInformationInLocalStorage(): PersonalHistory[] {
    return this.localStorageService.getDataHistory();
  }


  getTargetOfTreatmentExperimentalNames(): Observable<string[]> {
    return this.httpClient.get<string[]>(this.host + '/target-of-treatment-experimental/load-names');
  }

  isFirstDateGreaterThanSecondDate(date1: Date, date2: Date): boolean {
    if (date1.getFullYear() > date2.getFullYear()) {
      return true;
    } else if (date1.getMonth() > date2.getMonth()) {
      return true;
    } else if (date1.getDate() > date2.getDate()) {
      return true;
    }
    return false;
  }

  areDateEquals(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  setIsPopup(isPopup: boolean) {
    this.isPopup = isPopup;
  }

  getIsPopup(): boolean {
    return this.isPopup;
  }

}

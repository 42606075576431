import {NgModule} from '@angular/core';
import {DataDocumentComponent} from './components/data-document/data-document.component';
import {SharedModule} from '../shared/shared.module';
import {DataCommentComponent} from '../data-comment/component/data-comment/data-comment.component';
import {DragDropModule} from 'primeng/dragdrop';

@NgModule({
    imports: [
        SharedModule,
        DragDropModule
    ],
    declarations: [
        DataDocumentComponent
    ],
    exports: [
        DataDocumentComponent
    ],
    entryComponents: [
        DataCommentComponent
    ]
})
export class DataDocumentModule { }

import {Component} from '@angular/core';

@Component({
  selector: 'ih-establishment-icon',
  templateUrl: './establishment-icon.component.html',
  styleUrls: ['./establishment-icon.component.css']
})
export class EstablishmentIconComponent {

  constructor() {}

}

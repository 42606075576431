import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {SharedService} from '../../shared/services/shared.service';
import {Router} from '@angular/router';
import {DataDocumentService} from '../../data-document/service/data-document.service';
import {DataFolder} from '../../data-document/entity/data-folder';

@Component({
  selector: 'ih-folder-form',
  templateUrl: './folder-form.component.html',
  styleUrls: ['./folder-form.component.css']
})
export class FolderFormComponent implements OnInit {

  @ViewChild('folderForm') folderForm: NgForm;

  @Input()
  public hhhId: number;
  @Input()
  public root: any;

  public pharmacyHhhId: number;
  public displayAddFolderDialog: boolean;

  public folderName: string;
  public submitted: boolean;

  public callback: () => void;
  @Input()
  public attachedTo: string;
  folder = new DataFolder();
  trialHhhId: number;

  constructor(private sharedService: SharedService,
              private dataDocumentService: DataDocumentService,
              private router: Router) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.hhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.displayAddFolderDialog = true;
  }

  addFolder() {
    this.submitted = true;
    if (this.folderForm.invalid) {
      return;
    }
    this.folder.attachedTo = this.attachedTo;
    this.folder.name = this.folderName;
    this.folder.attachedToId = this.hhhId;
    this.folder.parent = this.root;
    this.dataDocumentService.addFolder(this.folder).subscribe((res) => {
      this.sharedService.showSuccess();
      this.callback();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
    this.displayAddFolderDialog = false;
  }

  onAdd(callback: () => void) {
    this.callback = callback;
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(FolderFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }
}

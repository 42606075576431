import {TreatmentAdministrationRouteDto} from './treatment-administration-route-dto';
import {TreatmentStudiedMoleculeDto} from './treatment-studied-molecule-dto';
import {TreatmentDoseDto} from './treatment-dose-dto';

export class AbstractDesignLevelDto {
  hhhId: number;
  level: number;
  fieldName: string;
  fieldValue: string;
  active: boolean;
  administrationRoute: TreatmentAdministrationRouteDto;
  studiedMolecule: TreatmentStudiedMoleculeDto;
  dose: TreatmentDoseDto;
  type: 'TREATMENT' | 'LIAISON';
  isNew: boolean;
}

import {Patient} from "./patient";

export class PatientData {

  public hhhId: number;
  public sex: string;
  public firstName: string;
  public lastName: string;
  public birthName: string;
  public internalIdentifier: string;
  public birthDate: Date;
  public deathDate: Date;
  public addressedByExternalDoctor: boolean;
  public externalDoctorName: string;
  public externalDoctorEstablishment: string;
  public lifeStateHhhId: number;
  public establishmentHhhId: number;

  public constructor(p: Patient) {
    this.hhhId = p.hhhId;
    this.sex = p.sex;
    this.firstName = p.firstName;
    this.lastName = p.lastName;
    this.birthName = p.birthName;
    this.internalIdentifier = p.internalIdentifier;
    this.birthDate = p.birthDate;
    this.deathDate = p.deathDate;
    this.addressedByExternalDoctor = p.addressedByExternalDoctor;
    this.externalDoctorName = p.externalDoctorName;
    this.externalDoctorEstablishment = p.externalDoctorEstablishment;
    this.lifeStateHhhId = p.lifeStateHhhId;
    this.establishmentHhhId = p.establishmentHhhId;
  }

}

import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS} from '@angular/forms';

@Directive({
  selector: '[startAfterLastDateValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: StartAfterLastDateValidatorDirective,
    multi: true
  }]
})
export class StartAfterLastDateValidatorDirective {
  @Input() startAfterLastDateValidator: Date[];

  validate(control: AbstractControl): { [key: string]: any } | null {
    for (const comparatorValue of this.startAfterLastDateValidator) {
      if (comparatorValue && comparatorValue < control.value) {
        return {notBefore: true};
      }
    }
    return null;
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {IsDeletable} from '../../is-deletable';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {FixedCostCategory} from '../models/fixed-cost-category';


@Injectable({
  providedIn: 'root'
})
export class FixedCostCategoryService {

  host = new Properties().host + '/fixed-cost-category';
  private fixedCostCategoryListtAllPage: FixedCostCategory[] = [];
  private fixedCostCategoryListSinglePage: FixedCostCategory[] = [];

  constructor(private httpClient: HttpClient) {
  }

  setFixedCostCategoryListToExportAllPage(fixedCostCategoryList: FixedCostCategory[]) {
    this.fixedCostCategoryListtAllPage = fixedCostCategoryList;
  }

  setFixedCostCategoryListToExportSinglePage(fixedCostCategoryList: FixedCostCategory[]) {
    this.fixedCostCategoryListSinglePage = fixedCostCategoryList;
  }

  public getOrderedColumns(target: string): string[] {
    return localStorage.getItem(target).split(',');
  }

  loadList(): Observable<FixedCostCategory[]> {
    return this.httpClient.get<FixedCostCategory[]>(this.host + '/load-list');
  }

  addFixedCostCat(newFixedCostCat: FixedCostCategory): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', newFixedCostCat);
  }

  updateFixedCostCatId(fixedCostCat: FixedCostCategory) {
    return this.httpClient.put(this.host + `/update/`, fixedCostCat);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(fixedCostCategoryId: number) {
    return this.httpClient.get(this.host + `/delete/${fixedCostCategoryId}`);
  }

  exportFixedCostCategories(request: ExportRequest<any>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

}

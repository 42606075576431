import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {ContainerCardConfig} from '../../entity/container-card-config';
import {Util} from '../../../helpers/util';

@Component({
  selector: 'ih-dynamic-card',
  templateUrl: './dynamic-card.component.html',
  styleUrls: ['./dynamic-card.component.css']
})
export class DynamicCardComponent {

  @Input() config: ContainerCardConfig;
  @Input() title: string;
  @Input() active: boolean;
  @Input() disabled = false;
  @Input() showWarning = false;
  @Output() click: EventEmitter<any> = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) { }

  emitClick() {
    this.click.emit();
  }

  getTitle(): string {
    this.cdr.markForCheck();
    return Util.isStringNullOrEmpty(this.config.title) ? this.title : this.config.title ;
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {PatientService} from '../../patient/services/patient.service';
import {PatientGlobalFilter} from '../../patient/models/patient-global-filter';
import {InclusionService} from '../../trial/service/inclusion.service';
import {Router} from '@angular/router';
import {SharedService} from '../../shared/services/shared.service';
import {PatientInclusion} from '../../trial/entity/inclusion/patient-inclusion';
import {DataService} from '../../shared/services/data-service';
import {NgForm} from '@angular/forms';
import {FormComponent} from '../../shared/component/form/form.component';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';


@Component({
  selector: 'ih-inclusion-form',
  templateUrl: './inclusion-form.component.html',
  styleUrls: ['./inclusion-form.component.css']
})
export class InclusionFormComponent extends FormComponent<string> implements OnInit {
  @ViewChild('addPatientToTrial') addPatientToTrial: NgForm;

  amendmentsEnabled = false;
  inclusionInformationArmEnabled = false;

  trialHhhId: number;
  displayAddPatientDialog = false;
  displayAddInclusionConfirmationDialog = false;
  patients: PatientGlobalFilter[] = [];
  selectedPatient: PatientGlobalFilter = new PatientGlobalFilter();
  patientInclusion: PatientInclusion = new PatientInclusion();
  submitted: boolean;
  disableAddInclusionButton = false;

  constructor(private translateService: InnohealthTranslateService,
              private patientService: PatientService,
              private inclusionService: InclusionService,
              private sharedService: SharedService,
              private dataService: DataService,
              private dynamicConfigService: DynamicConfigService,
              private router: Router
  ) {
    super();
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(InclusionFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.displayAddPatientDialog = true;
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.inclusionInformationArmEnabled = this.dynamicConfigService.getProperty(PropertyEnum.inclusionInformationArmEnabled);
    });
  }

  search(event) {
    const query = event.query;
    this.patientService.getPatientWithQuery(query, this.trialHhhId).subscribe(res => {
      this.patients = res;
    });
  }

  isPatientInTrial() {
    this.submitted = true;
    if (this.addPatientToTrial.invalid) {
      return;
    }
    if (!(this.selectedPatient instanceof Object)) {
      this.displayAddPatientDialog = false;
      this.callback(this.selectedPatient);
    } else {
      this.inclusionService.isPatientInTrial(this.trialHhhId, this.selectedPatient.hhhId).subscribe((res: boolean) => {
        if (!res) {
          this.addInclusion();
        } else {
          this.displayAddPatientDialog = false;
          this.displayAddInclusionConfirmationDialog = true;
        }
      });
    }
  }

  addInclusion() {
    this.disableAddInclusionButton = true;
    this.patientInclusion.trialHhhId = this.trialHhhId;
    this.patientInclusion.patientHhhId = this.selectedPatient.hhhId;
    this.inclusionService.addInclusion(this.patientInclusion).subscribe(res => {
      this.sharedService.showSuccess();
      this.dataService.changeInclusionMenu('information');
      const routeToInclusionInformationVersionProtocol = this.amendmentsEnabled && !this.inclusionInformationArmEnabled;
      const targetUrl = routeToInclusionInformationVersionProtocol ?
          '/trial-details/inclusion-details/inclusion-information-version-protocol' :
          '/trial-details/inclusion-details/inclusion-information-arm';
      this.router.navigate([targetUrl], {
          queryParams: {
            hhhId: this.trialHhhId,
            inclusionId: res
          }
        }).then();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    },
      () => {
        this.disableAddInclusionButton = true;
      });
    this.displayAddPatientDialog = false;
  }
}

import {Phone} from './phone';
import {Email} from './email';
import {Fax} from './fax';
import {Address} from './address';

export class Contact {

  public hhhId: number;
  public name: string;
  public function: string;
  public phones: Phone[] = [];
  public emails: Email[] = [];
  public faxes: Fax[] = [];
  public addresses: Address[] = [];
}

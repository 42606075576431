import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {ConcomitantTreatment} from '../../../../entity/inclusion/concomitant-treatment';
import {VisitService} from '../../../../service/visit.service';
import {SharedService} from '../../../../../shared/services/shared.service';
import {SelectItem} from 'primeng/api';
import {ConcomitantTreatmentService} from '../../../../service/concomitant-treatment.service';
import {FormComponent} from '../../../../../shared/component/form/form.component';
import {FR} from '../../../../../shared/entity/calendar-language';
import {Data} from '../../../../../shared/entity/data';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../dynamic-config/entity/form-config';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../../trial-app-data';

@Component({
  selector: 'ih-concomitant-treatment-form',
  templateUrl: './concomitant-treatment-form.component.html',
  styleUrls: ['./concomitant-treatment-form.component.css']
})
export class ConcomitantTreatmentFormComponent extends FormComponent<ConcomitantTreatment> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_CONCOMITANT_TREATMENT_FORM;
  config: FormConfig = new FormConfig();

  concomitantTreatment: ConcomitantTreatment = new ConcomitantTreatment();
  noYesOptions: SelectItem[] = Data.yesOrNo;
  locale = FR;

  constructor(
      private visitService: VisitService,
      private concomitantTreatmentService: ConcomitantTreatmentService,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ConcomitantTreatmentFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  submit() {
    this.submitted = true;
    if (!this.concomitantTreatment.isValid(this.config)) {
      return;
    }
    if (!this.concomitantTreatment.hhhId) {
      this.addConcomitantTreatment();
    } else {
      this.updateConcomitantTreatment();
    }
    this.callback(this.concomitantTreatment);
    this.display = false;
  }

  private updateConcomitantTreatment() {
    this.concomitantTreatmentService.updateConcomitantTreatment(this.concomitantTreatment).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  private addConcomitantTreatment() {
    this.concomitantTreatmentService.addConcomitantTreatment(this.concomitantTreatment).subscribe(() => {
      this.sharedService.showSuccess();
    });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

}

export class VisitAction {

  public data: any;
  public selectedPeriod: string;
  public action: string;
  public additionalData: string;


  constructor(data: any,
              selectedPeriod: string,
              action: string,
              additionalData?: string) {
    this.data = data;
    this.selectedPeriod = selectedPeriod;
    this.action = action;
    if (additionalData)
      this.additionalData = additionalData;
  }
}

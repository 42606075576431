import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {TrialConsent} from '../models/trial-consent';
import {IsDeletable} from '../../is-deletable';
import {LabelValue} from '../../shared/entity/label-value';
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TrialConsentService {

  public host = new Properties().host + '/trial-consent';
  public dataItemHost = new Properties().host + '/data/items';

  constructor(private httpClient: HttpClient) {
  }

  getList(trialHhhId: number): Observable<TrialConsent[]> {
    return this.httpClient.get<TrialConsent[]>(this.host + '/load-by-trial/' + trialHhhId);
  }

  save(consent: TrialConsent): Observable<TrialConsent> {
    return this.httpClient.post<TrialConsent>(`${this.host}/save`, consent)
      .pipe(
        catchError(this.handleError)
      );
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(trialConsentHhhId: number): Observable<{}> {
    return this.httpClient.get(this.host + '/delete/' + trialConsentHhhId);
  }

  getLabelValuesByTrial(trialId: number): Observable<LabelValue[]> {
    return this.httpClient.get<LabelValue[]>(this.dataItemHost + '/consent|' + trialId);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.error('Error response:', error.error);
    const errorMessage = error.error ? error.error : 'An error occurred';
    return throwError(errorMessage);
  }

}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AdverseEffect} from '../entity/inclusion/adverse-effect';

@Injectable({
  providedIn: 'root'
})
export class AdverseEffectService {

  public host = new Properties().host + '/inclusion/adverse-effect';

  constructor(private httpClient: HttpClient) {
  }

  loadList(hhhId: number): Observable<any> {
    return this.httpClient.get<any>(this.host + '/load-list/' + hhhId)
      .pipe();
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  updateAdverseEffect(inclusion: any): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update', inclusion);
  }

  addAdverseEffect(adverseEffect: AdverseEffect): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', adverseEffect);
  }

}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'ih-trial-arm-icon',
  templateUrl: './trial-arm-icon.component.html',
  styleUrls: ['./trial-arm-icon.component.css']
})
export class TrialArmIconComponent {

  @Input() title: string;

  constructor() { }

}

import {VisitTracking} from './visit-tracking';
import {Consent} from '../../models/consent';

export class InclusionTracking {
  public hhhId: number;
  public patientName: string;
  public inclusionIdentifier1: string;
  public inclusionIdentifier2: string;
  public patientBirthDate: Date;
  public trialTreatmentEndDate: Date;
  public armName: string;
  public siteName: string;
  public visitTrackingDtoList: VisitTracking[] = [];
  public inclusionScreeningNumber: string;
  sex: string;
  patientInternalIdentifier: string;
  internalIdentifier: string;

  consents: Consent [] = [];
  init(obj: any): InclusionTracking {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConcomitantTreatment} from '../entity/inclusion/concomitant-treatment';

@Injectable({
  providedIn: 'root'
})
export class ConcomitantTreatmentService {

  public host = new Properties().host + '/inclusion/concomitant-treatment';

  constructor(private httpClient: HttpClient) {
  }

  loadList(hhhId: number): Observable<any> {
    return this.httpClient.get<any>(this.host + '/load-list/' + hhhId)
      .pipe();
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  updateConcomitantTreatment(concomitantTreatment: ConcomitantTreatment) {
    return this.httpClient.post(this.host + '/update', concomitantTreatment);
  }

  addConcomitantTreatment(concomitantTreatment: ConcomitantTreatment) {
    return this.httpClient.post(this.host + '/add', concomitantTreatment);
  }

}

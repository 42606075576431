import {State} from '../models/state';
import {Site} from '../../site/models/site';
import {OrganFamily} from '../../statistics/follow-inclusions-by-trial/models/organ-family';
import {Organ} from '../../statistics/follow-inclusions-by-trial/models/organ';
import {RoleValue} from '../../human-resources/models/role-value';

export class TrialInDashboard {
  public hhhId: number;
  public state: State;
  public multiSite: boolean;
  public siteList: Site[];
  public organFamilies: OrganFamily[];
  public organList: Organ[];
  public arc1: RoleValue;
  public tec1: RoleValue;
  public principalInvestigator: RoleValue;
  public theoreticalNumberOfInclusions: number;
  public forecastInclusions: number;
}

export class DataCommentEvent {
  public attachedToId: number;
  public attachedToField: string;
  public publicCommentCount: number;

  constructor(attachedToId: number, attachedToField: string, publicCommentCount: number) {
    this.attachedToId = attachedToId;
    this.attachedToField = attachedToField;
    this.publicCommentCount = publicCommentCount;
  }
}

import {Component} from '@angular/core';

@Component({
  selector: 'ih-inclusion-adverse-effect-icon',
  templateUrl: './inclusion-adverse-effect-icon.component.html',
  styleUrls: ['./inclusion-adverse-effect-icon.component.css']
})
export class InclusionAdverseEffectIconComponent {

  constructor() { }
}

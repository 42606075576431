import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {HumanResource} from '../../human-resources/models/human-resource';
import {User} from '../models/user';
import {Data} from '../../shared/entity/data';
import {UsersService} from '../services/users.service';
import {HumanResourcesService} from '../../human-resources/services/human-resources.service';
import {SharedService} from '../../shared/services/shared.service';
import {Router} from '@angular/router';
import {EstablishmentService} from '../../establishment/services/establishment.service';
import {EstablishmentSelection} from '../../shared/entity/establishment-selection';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {LabelValue} from '../../shared/entity/label-value';
import {SelectItem} from 'primeng/components/common/api';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DataItemService} from '../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-current-user-form',
  templateUrl: './current-user-form.component.html',
  styleUrls: ['./current-user-form.component.css']
})
export class CurrentUserFormComponent implements OnInit {

  linkResponsibilityWithRole = false;
  humanResourceMandatory = false;

  @Output() username = new EventEmitter<string>();
  titles = Data.userTitles;
  user: User = new User();
  active = Data.activeOptions;
  id: any;
  roles: LabelValue[] = [];
  editorId = 0;
  humanResources: HumanResource[] = [];
  humanResource: HumanResource = new HumanResource();
  establishments: EstablishmentSelection[] = [];
  shownRoles: Array<SelectItem> = new Array<SelectItem>();
  allRoles: Array<SelectItem> = new Array<SelectItem>();

  constructor(
      private router: Router,
      private userService: UsersService,
      private sharedService: SharedService,
      private humanResourcesService: HumanResourcesService,
      private establishmentService: EstablishmentService,
      private translateService: InnohealthTranslateService,
      private dataItemService: DataItemService,
      private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.id = url.queryParams.id;
    }
  }

  ngOnInit() {
    this.initProperties();
    this.getHumanResources();
    this.getAllRoles();
    this.getChildEstablishments();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.linkResponsibilityWithRole = this.dynamicConfigService.getProperty(PropertyEnum.linkResponsibilityWithRole);
      this.humanResourceMandatory = this.dynamicConfigService.getProperty(PropertyEnum.humanResourceMandatory);
    });
  }

  updateUser() {
    this.user.hhhId = this.id;
    this.userService.updateUser(this.user).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  getUser() {
    this.userService.getUser(this.id).subscribe(res => {
      this.user = res;
      const currentUser = this.getUsername(this.user.lastName, this.user.firstName);
      this.username.emit(currentUser);
      this.humanResource = this.humanResources.find(hr => hr.hhhId === this.user.humanResourceHhhId);
      this.user.role = this.translateService.getTranslationString('MODULE_USER_ADMIN_ROLE_' + this.user.role);
      this.updateRoles();
    });
  }

  getHumanResources() {
    this.humanResourcesService.loadAll().subscribe(res => {
      this.humanResources = res;
      this.humanResources.splice(0, 0, new HumanResource());
    });
  }

  getUsername(lastName: string, firstName: string) {
    if (lastName !== null && firstName !== null) {
      return lastName + ' ' + firstName;
    } else if (lastName !== null && firstName === null) {
      return lastName;
    } else if (lastName === null && firstName !== null) {
      return firstName;
    } else {
      return this.user.userName;
    }
  }

  isParent() {
    return this.dynamicConfigService.parent;
  }

  private getChildEstablishments() {
    this.establishmentService.getChildEstablishments().subscribe(res => {
      this.establishments = res;
      this.establishments.splice(0, 0, {hhhId: null, name: ''});

    }, error => {
      console.error('An error occurred while loading child establishments.' + error);
    });
  }

  updateRoles() {
    if (this.linkResponsibilityWithRole) {
      this.userService.getRolesForResponsibilities(this.user.responsibilitiesHhhId).subscribe(res => {
        this.shownRoles = this.allRoles.filter(r => res.indexOf(r.value) >= 0);
      });
    }
  }

  getAllRoles() {
    this.dataItemService.getItems('role').subscribe(res => {
      this.allRoles = res;
      this.editorId = res.find(r => r.label.toLowerCase() === 'éditeur' || r.label.toLowerCase() === 'editor').value;
      if (!this.linkResponsibilityWithRole) {
        this.shownRoles = res;
      }
      this.getUser();
    });
  }
}

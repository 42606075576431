import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {LabelValue} from '../../shared/entity/label-value';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';

@Injectable({
  providedIn: 'root'
})
export class ConventionTypeService {

  public host = new Properties().host + '/conventionTypes';

  constructor(private httpClient: HttpClient) { }

  getItemById(conventionTypehhhId: number): Observable<LabelValue> {
    return this.httpClient.get<LabelValue>(this.host + '/' + conventionTypehhhId);
  }
}

export class InvoiceFixedCost {
  public hhhId: number;
  public localIdentifier: any;
  public promoter: any;
  public promoterHhhId: number;
  public numberProtocol: any;
  public category: any;
  public description: any;
  public unitPrice: any;
  public unitNumber: any;
  public total: any;
  public invoiceable: boolean;
  public checked: boolean;
  public isVisitAct: boolean;
  public inclusionNumber?: string;
  public date?: string;
  public actVisit?: string;
  public typeHhhId: number;
  public typeName: string;
  public patientInitials?: string;
  public internalAccountNumber?: string;
  public firstSegment?: string;
  public secondSegment?: string;
  public visitName?: string;
  public patientLastName?: string;
  public visitDate?: string;
  public trialHhhId: number;
  public inclusionHhhId: number;
  public annualInvoicing: boolean;
  public inclusionPreScreeningIdentifier: string;
  public inclusionScreeningIdentifier: string;

  init(obj: any): InvoiceFixedCost {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}

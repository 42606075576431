import {Component, OnInit} from '@angular/core';
import {DetailedVisit} from '../../../../../entity/inclusion/detailed-visit';
import {Arm} from '../../../../../entity/inclusion/arm';
import {Router} from '@angular/router';
import {FR} from '../../../../../../shared/entity/calendar-language';
import {VisitState} from '../../../../../entity/inclusion/visit-state';
import {Data} from '../../../../../../shared/entity/data';
import {DynamicDefinitionEnum} from '../../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../../../trial-app-data';
import {DynamicConfigService} from '../../../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../../../dynamic-config/entity/property-enum';
import {VisitService} from '../../../../../service/visit.service';
import {DataItemService} from '../../../../../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-visit-form',
  templateUrl: './visit-form.component.html',
  styleUrls: ['./visit-form.component.css']
})
export class VisitFormComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_INCLUSION_CALENDAR_VISIT_FORM;
  config: FormConfig = new FormConfig();

  public calendarHhhId: number;
  public displayVisitForm = false;
  public arms: Arm[] = [];
  public visitStates: VisitState[];
  public detailedVisit: DetailedVisit = new DetailedVisit();
  public arm = 'BEFORE_INCLUSION';
  public locale = FR;
  public callback: (visit: DetailedVisit) => void;
  public trialHhhId: number;
  public inclusionHhhId: number;
  public noYesNaOptions = Data.yesOrNo;
  public additionalVisitsEnabled: boolean;
  public submitted = false;
  public isInclusionCalendarSavingButtonApart = false;
  isRetroCessionInvoiceEnabled = false;

  constructor(
    private router: Router,
    private dataItemService: DataItemService,
    private dynamicConfigService: DynamicConfigService,
    private visitService: VisitService
  ) {
    const url = this.router.parseUrl(this.router.url);
    this.trialHhhId = url.queryParams.hhhId;
    this.inclusionHhhId = url.queryParams.inclusionId;
  }

  ngOnInit() {
    this.displayVisitForm = true;
    this.getVisitState();
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isInclusionCalendarSavingButtonApart = this.dynamicConfigService.getProperty(PropertyEnum.inclusionCalendarSavingButtonApart);
      this.isRetroCessionInvoiceEnabled = this.dynamicConfigService.getProperty(PropertyEnum.retroCessionInvoiceEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  addVisit() {
    this.submitted = true;
    if (!this.detailedVisit.isValid(this.config)) {
      return;
    }
    this.detailedVisit.clonedStateOfInclusion = this.arm;
    this.detailedVisit.calendarHhhId = this.calendarHhhId;
    if (!this.detailedVisit.clonedStateOfInclusion) {
      this.detailedVisit.clonedStateOfInclusion = 'BEFORE_INCLUSION';
    }
    if (!this.detailedVisit.visitState) {
      this.detailedVisit.visitState = this.visitStates[0];
    }
    if (!this.detailedVisit.visitProviderState) {
      this.detailedVisit.visitProviderState = this.visitStates[0];
    }
    if (this.isInclusionCalendarSavingButtonApart) {
      this.detailedVisit.visitDate = this.detailedVisit.visitDate != null ? new Date(this.detailedVisit.visitDate) : null;
      this.detailedVisit.isNotSavedYet = true;
      this.callback(this.detailedVisit);
      this.displayVisitForm = false;
      return;
    }
    this.visitService.addVisit(this.detailedVisit).subscribe(res => {
      this.detailedVisit.hhhId = res;
      this.detailedVisit.visitDate = this.detailedVisit.visitDate != null ? new Date(this.detailedVisit.visitDate) : null;
      this.callback(this.detailedVisit);
    });
    this.displayVisitForm = false;
  }

  getVisitState() {
    this.dataItemService.getItems('visit-state').subscribe(res => {
      this.visitStates = res.map((item) => {
        return new VisitState(item.value, item.label);
      });
    });
  }

  onAdd(callback: (visit: DetailedVisit) => void) {
    this.callback = callback;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);
}

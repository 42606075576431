import {PharmacyDispensationKit} from './PharmacyDispensationKit';

export class PharmacyStock {
  hhhId: number;
  state: string;
  lotNumber: string;
  wording: string;
  trialName?: string;
  receptionDate: Date | string;
  receptionAuthorName?: string;
  receptionAuthorHhhId: number;
  treatmentNumber: string;
  receptionNumber: string;
  dosageName?: string;
  dosageHhhId: number;
  dluoDate: Date | string;
  expirationDate: Date | string;
  dispensationDates: string;
  events: string;
  pharmacyHhhId?: number;
  trialHhhId?: number;
  eventOtherValue?: string;
  treatmentHhhId?: number;
  treatmentName?: string;
  pharmaceuticalFormName?: string;
  pharmaceuticalFormHhhId?: number;
  kits: PharmacyDispensationKit[];

}

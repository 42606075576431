import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ReferenceAct} from '../models/reference-act';
import {Properties} from '../../helpers/properties';
import {IsDeletable} from '../../is-deletable';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {FormatterService} from '../../shared/services/formatter.service';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class ReferenceActService {
  public host = new Properties().host + '/reference-act';
  private referenceActList: ReferenceAct[] = [];

  constructor(
    private httpClient: HttpClient
  ) {
  }

  setReferenceActListToExport(referenceActList: ReferenceAct[]) {
    this.referenceActList = referenceActList;
  }

  getReferenceActList(): Observable<ReferenceAct[]> {
    return this.httpClient.get<ReferenceAct[]>(this.host + '/load-list');
  }

  getReferenceActListForTrial(trialId: number): Observable<ReferenceAct[]> {
    return this.httpClient.get<ReferenceAct[]>(this.host + '/load/for-trial/' + trialId);
  }

  addReferenceAct(referenceAct: ReferenceAct): Observable<ReferenceAct> {
    return this.httpClient.post<ReferenceAct>(this.host + '/add', referenceAct);
  }

  updateReferenceAct(referenceAct: ReferenceAct): Observable<ReferenceAct> {
    return this.httpClient.put<ReferenceAct>(this.host + `/update`, referenceAct);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(referenceActId: number) {
    return this.httpClient.get(this.host + `/delete/${referenceActId}`);
  }

  exportReferenceActs(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

}

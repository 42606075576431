import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../shared/component/form/form.component';
import {Establishment} from '../models/establishment';
import {EstablishmentSelection} from '../../shared/entity/establishment-selection';
import {EstablishmentService} from '../services/establishment.service';
import {SharedService} from '../../shared/services/shared.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {EstablishmentAppData} from '../establishment-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {FormConfig} from '../../dynamic-config/entity/form-config';

@Component({
  selector: 'ih-establishment-edit-form',
  templateUrl: './establishment-edit-form.component.html',
  styleUrls: ['./establishment-edit-form.component.css']
})
export class EstablishmentEditFormComponent extends FormComponent<Establishment> implements OnInit {

  target = DynamicDefinitionEnum.ESTABLISHMENT_OTHER_FORM;
  config: FormConfig = new FormConfig();

  constructor(
      private establishmentService: EstablishmentService,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super();
  }

  private _establishmentToEdit: EstablishmentSelection = new EstablishmentSelection();

  get establishmentToEdit(): EstablishmentSelection {
    return this._establishmentToEdit;
  }

  set establishmentToEdit(value: EstablishmentSelection) {
    this._establishmentToEdit = value;
  }

  public static display(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(EstablishmentEditFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, EstablishmentAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  submitEstablishment() {
    this.establishmentService.renameEstablishment(this.establishmentToEdit).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
    this.display = false;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
}

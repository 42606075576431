import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Contact} from 'src/app/dynamic-config/exported/contact-list/contact';
import {Observable, throwError} from 'rxjs';
import {Country} from '../entity/country';
import {Properties} from '../../helpers/properties';
import {IsDeletable} from '../../is-deletable';
import {ResponsePage} from '../entity/response-page';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {ContactGlobalFilter} from '../entity/contact-global-filter';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  public host = new Properties().host + '/contactable-member';

  constructor(private httpClient: HttpClient) {
  }

  getContacts(hhhId: number, entityType: string, page: number, limit: number, orderBy: string, sortBy: string, filter: ContactGlobalFilter): Observable<ResponsePage<Contact>> {
    if (filter.name === undefined) {
      filter.name = '';
    }
    if (filter.function === undefined) {
      filter.function = '';
    }
    return this.httpClient.post<ResponsePage<Contact>>(
      this.host + `/load-list`,
      new RequestPageBuilder<ContactGlobalFilter>()
        .objectId(hhhId)
        .filters(filter)
        .type(entityType)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(contact => new Contact().init(contact));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  addContact(newContact: Contact): Observable<any> {
    return this.httpClient.post<any>(this.host + '/add', newContact);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(contactId: number) {
    return this.httpClient.get(this.host + `/delete/${contactId}`);
  }

  updateContact(contact: Contact) {
    return this.httpClient.post(this.host + '/update', contact);
  }

  exportContactList(request: ExportRequest<ContactGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

}

import {JsonObject, JsonProperty} from 'json2typescript';
import {TableHeaderConfig} from './table-header-config';

@JsonObject('DynamicTableDefinitionHeader')
export class DynamicTableDefinitionHeader {

  @JsonProperty('field', String)
  field: string = undefined;

  @JsonProperty('defaultHeader', String)
  defaultHeader: string = undefined;

  @JsonProperty('defaultWidth', String)
  defaultWidth: string = undefined;

  @JsonProperty('filterable', Boolean)
  filterable: boolean = undefined;

  @JsonProperty('config', TableHeaderConfig)
  config: TableHeaderConfig = new TableHeaderConfig();

  color = '';

  displayContent(field: string): string {
    switch (field) {
      case 'field':
        return this.field;
      default:
        return this.config[field];
    }
  }


}

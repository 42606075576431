import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../shared/component/form/form.component';
import {NotificationNote} from '../models/notification-note';
import {NgForm} from '@angular/forms';
import {PushNotificationManagementService} from '../services/push-notification-management.service';
import {SharedService} from '../../shared/services/shared.service';

@Component({
  selector: 'app-custom-notification',
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.css']
})
export class CustomNotificationComponent extends FormComponent<NotificationNote> implements OnInit {

  @ViewChild('pushNotification') pushNotification: NgForm;
  notification: NotificationNote = new NotificationNote();

  constructor(private pushNotificationManagementService: PushNotificationManagementService,
              private sharedService: SharedService) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(CustomNotificationComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onSubmit() {
    if (this.pushNotification.invalid) {
      return;
    }
    this.pushNotificationManagementService.sendCustomNotification(this.notification).subscribe(() => {
      this.sharedService.showSuccess();
      this.display = false;
    }, () => {
      this.sharedService.showFailure();
    });
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StaticInvoiceItem} from '../../models/static-invoice-item';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../../helpers/properties';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class StaticInvoiceActService {

  public properties: Properties = new Properties();
  public host = this.properties.host + '/static-invoice-act';

  constructor(private httpClient: HttpClient, private datePipe: DatePipe) {
  }

  getStaticInvoiceInvoicedActs(invoiceHhhId: number, versionHhhId: number, fromDate: Date, toDate: Date): Observable<StaticInvoiceItem> {
    const request = {
      invoiceId: invoiceHhhId,
      versionId: versionHhhId,
      fromDate: fromDate != null ? this.datePipe.transform(fromDate, 'dd/MM/yyyy') : '',
      toDate: toDate != null ? this.datePipe.transform(toDate, 'dd/MM/yyyy') : ''
    };
    return this.httpClient.post<StaticInvoiceItem>(this.host + '/load-list', request);
  }

  saveStaticInvoiceInvoicedActs(invoiceHhhId: number, staticInvoiceAct: StaticInvoiceItem): Observable<number> {
    return this.httpClient.post<number>(this.host + '/save/' + invoiceHhhId, staticInvoiceAct);
  }
}

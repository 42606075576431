import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {IsDeletable} from '../../is-deletable';
import {SharedService} from '../../shared/services/shared.service';
import {Router} from '@angular/router';
import {DataService} from '../../shared/services/data-service';
import {TrialService} from '../../trial/services/trial.service';
import {Deletable} from './deletable';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {Observable} from 'rxjs';

@Component({
  selector: 'ih-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit {

  display = false;
  hhhId: number;
  entityParentHhhId: number;
  name: string;
  popupMessage: string;
  params: any;
  isDeletable: IsDeletable = new IsDeletable();
  trialHhhId: number;
  dontDeleteWhenDeletable: boolean;
  notModal: boolean;
  isInclusionCalendarSavingButtonApart = false;
  amendmentsEnabled = false;
  inclusionInformationArmEnabled = false;
  private callback: () => void;
  private cancelCallback: () => void;

  constructor(
      private router: Router,
      private trialService: TrialService,
      private sharedService: SharedService,
      private dataService: DataService,
      private dynamicConfigService: DynamicConfigService
  ) {
  }

  private _entityName: string;

  get entityName(): string {
    return this._entityName;
  }

  set entityName(value: string) {
    this._entityName = value;
  }

  private _service: Deletable;

  get service(): any {
    return this._service;
  }

  set service(value: any) {
    this._service = value;
  }

  public static displayDeleteConfirmationDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const deletePopupFactory = cfr.resolveComponentFactory(DeleteConfirmationComponent);
    const deletePopupComponentRef = viewRef.createComponent(deletePopupFactory);
    return deletePopupComponentRef.instance;
  }

  ngOnInit() {
    this.display = true;
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
    });
  }

  isEntityDeletable() {
    if (this.hhhId === undefined) {
      this.callback();
      this.display = false;
      return;
    }
    this._service.isDeletable(this.hhhId).subscribe(res => {
      this.display = false;
      this.isDeletable = res;
      if (this.isDeletable.deletable) {
        this.deleteEntity();
        return;
      } else {
        this.dataService.changeFilterVar(this.name);
      }
      if (!this.isDeletable.object && !this.isDeletable.why) {
        this.sharedService.showFailure();
      } else {
        if (this.isDeletable.why.indexOf('FIXED_COST') !== -1) {
          this.isDeletable.object.fullName = this.name;
        }
        if (this.isDeletable.why.indexOf('HUMAN_RESOURCES') !== -1) {
          this.isDeletable.object.fullName = this.isDeletable.object.firstName;
        }
        if (this.isDeletable.why.indexOf('DEPENDENCY_INVOICE_TRIAL') !== -1) {
          this.isDeletable.object.fullName = this.isDeletable.object.number;
        }
        if (this.isDeletable.why.indexOf('MODULE_TRIALS_THEORETICAL_INVOICES_VISIT_ISNOTDELETABLE') !== -1) {
          this.isDeletable.object.fullName = this.isDeletable.object.name;
          this.sharedService.showDeleteFailure(this.isDeletable.why,
              {name: this.name, name2: this.isDeletable.object.fullName}
          );
          return;
        }
        this.sharedService.showDeleteFailure(this.isDeletable.why,
            {name: this.name},
            this.isDeletable.object ? this.isDeletable.object.fullName : null,
          () => this.displayReason()
        );
      }
    });
    this.display = false;
  }

  async displayReason() {
    this.params = {hhhId: this.isDeletable.object.hhhId};
    let path = '/human-resource-details';

    const objectLinkedToTheoreticalVisit = this.isDeletable.why.indexOf('DEPENDENCY_THEORETICAL_VISIT') !== -1;
    const HRLinkedToInclusion = this.isDeletable.why.indexOf('DEPENDENCY_INCLUSION_REFERER_INVESTIGATOR') !== -1 ||
        this.isDeletable.why.indexOf('DEPENDENCY_INCLUSION_CO_INVESTIGATOR') !== -1 ||
        this.isDeletable.why.indexOf('DEPENDENCY_INCLUSION') !== -1;
    const objectLinkedToTrial = this.isDeletable.object.localIdentifier || this.isDeletable.why.indexOf('DEPENDENCY_PROVIDER_TRIAL_INCLUSION') !== -1;
    const objectLinkedToInclusion = this.isDeletable.object.className === 'Inclusion' || this.isDeletable.why.indexOf('DEPENDENCY_PATIENT_TRIAL_INCLUSION') !== -1;
    const HRLinkedToTrial = objectLinkedToTrial && (
        this.isDeletable.why.indexOf('INVESTIGATOR') !== -1 ||
        this.isDeletable.why.indexOf('TRIAL_VIGILANCE') !== -1 ||
        this.isDeletable.why.indexOf('CLINICAl_RESEARCH') !== -1 ||
        this.isDeletable.why.indexOf('DATA_MANAGER') !== -1);
    const objectLinkedToTrialAct = this.isDeletable.why.indexOf('TRIAL_ACT') !== -1;
    const objectLinkedToFixedCost = this.isDeletable.why.indexOf('FIXED_COST') !== -1;
    const siteLinkedToTrial = this.isDeletable.why.indexOf('SITE_TRIAL') !== -1;
    const objectLinkedToHR = this.isDeletable.why.indexOf('HUMAN_RESOURCES') !== -1;
    const trialLinkedToInvoice = this.isDeletable.why.indexOf('DEPENDENCY_INVOICE_TRIAL') !== -1;
    const objectLinkedToCounterPart = this.isDeletable.why.indexOf('COUNTER_PART') !== -1;
    const objectLinkedToProtocol = this.isDeletable.why.indexOf('DEPENDENCY_TRIAL_PROTOCOL') !== -1;
    if (objectLinkedToTheoreticalVisit) {
      this.params = {hhhId: this.trialHhhId};
      path = '/trial-details/theoretical-calendar';
    } else if (HRLinkedToInclusion) {
      this.params = {hhhId: this.isDeletable.object.trialHhhId, inclusionId: this.isDeletable.object.hhhId};
      this.dynamicConfigService.initProperties().subscribe(() => {
        this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
        path = this.amendmentsEnabled ? '/trial-details/inclusion-details/inclusion-information-version-protocol' : '/trial-details/inclusion-details/inclusion-information-arm';
      });
    } else if (HRLinkedToTrial) {
      path = 'trial-details/trial-identification-information-container/personnel-identification-information';
    } else if (objectLinkedToProtocol) {
      this.params = {hhhId: this.isDeletable.object.trialHhhId};
      path = '/trial-details/trial-identification-information-container/amendment-identification-information/protocols';
    } else if (objectLinkedToInclusion) {
      this.trialHhhId = await this.getTrialByInclusion(this.isDeletable.object.inclusionHhhId).toPromise();
      this.params = {hhhId: this.trialHhhId, inclusionId: this.isDeletable.object.inclusionHhhId};
      this.dynamicConfigService.initProperties().subscribe(() => {
        this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
        this.inclusionInformationArmEnabled = this.dynamicConfigService.getProperty(PropertyEnum.inclusionInformationArmEnabled);
        path = this.amendmentsEnabled && !this.inclusionInformationArmEnabled ? '/inclusion-information-version-protocol' : '/trial-details/inclusion-details/inclusion-information-arm';
      });
    } else if (objectLinkedToTrialAct) {
      path = './additional-costs-container/reference-acts';
    } else if (objectLinkedToFixedCost) {
      this.isDeletable.object.fullName = this.name;
      path = './additional-costs-container/fixed-cost-reference';
    } else if (objectLinkedToCounterPart) {
      this.isDeletable.object.fullName = this.name;
      path = './additional-costs-container/counter-parts';
    } else if (siteLinkedToTrial) {
      this.isDeletable.object.fullName = this.name;
      path = '/trial-details/trial-site-list';
    } else if (objectLinkedToHR) {
      this.isDeletable.object.fullName = this.isDeletable.object.firstName;
      path = '/human-resource-details';
    } else if (trialLinkedToInvoice) {
      this.params = {invoiceHhhId: this.isDeletable.object.hhhId};
      this.isDeletable.object.fullName = this.isDeletable.object.number;
      path = '/invoice-details/invoice-information';
    } else if (objectLinkedToTrial) {
      path = '/trial-details/trial-identification-information-container/trial-identification-information';
    }
    this.router.navigate([path], {queryParams: this.params}).then();
  }

  deleteEntity() {
    if (this.dontDeleteWhenDeletable || ['TheoreticalVisit', 'VisitAct'].indexOf(this._entityName) !== -1) {
      this.callback();
    } else {
      if (this._entityName === 'TrialContactableMember') {
        this._service.delete(this.hhhId, this.entityParentHhhId).subscribe(() => {
          this.sharedService.showSuccess();
          this.callback();
        }, error => {
          console.error(error);
        });
      } else if (this.isInclusionCalendarSavingButtonApart) {
        this.sharedService.showSuccess('MODULE_TRIALS_INCLUSION_DELETE_VISIT');
        this.callback();
      } else {
        this._service.delete(this.hhhId).subscribe(() => {
          this.sharedService.showSuccess();
          this.callback();
        }, error => {
          console.error(error);
        });
      }
    }
    this.display = false;
  }

  getTrialByInclusion(inclusionHhhId: number): Observable<number> {
    return this.trialService.getTrialByInclusion(inclusionHhhId);
  }

  onConfirmationCancel() {
    this.display = false;
    this.cancelCallback();
  }

  onCancel(callback: () => void) {
    this.cancelCallback = callback;
  }

  onDelete(callback: () => void) {
    this.callback = callback;
  }
}

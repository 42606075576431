import {CalendarCheckbox} from './calendar-checkbox';
import {LabelValue} from '../../shared/entity/label-value';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {DataCommentCount} from '../../data-comment/entity/data-comment-count';

export class TrialAdministrativeFeasibilityInformation extends DataCommentCount {

  hhhId: number = 0;
  trialHhhId: number;
  trialSiteHhhId: number;
  multiSite: boolean;
  siteName: string;
  priorityNumber: number;
  siteInclusionGoal: number;
  proposalOfTheTrialDate: Date;
  agreementConfidentialityDispatchDate: Date;
  feasibilityRetained: string;
  receiptFeasibilityQuestionDate: Date;
  preSelectionDate: Date;
  centerSelected: boolean;
  feasibilityComment: string;
  committeeDecision: LabelValue;
  committeeDecisionOtherValue: any;
  numberCTTIRS: string;
  financeHhhIds: number[];
  obtainingYear: number;
  submissionComexDitepDate: Date;
  comexDitepDate: Date;
  comexDitepDecision: LabelValue;
  comexDitepDecisionOtherValue: any;
  comexDitepDecisionRefusalReason: any;
  cs2bDate: Date;
  cs2bDecision: LabelValue;
  cs2bDecisionsOtherValue: any;
  cs2bDecisionsRefusalReason: any;
  csetDate: Date;
  csetDecision: LabelValue;
  csetDecisionsOtherValue: any;
  csetDecisionsRefusalReason: any;
  coordoPrcDate: Date;
  coordoPrcDecision: LabelValue;
  coordoPrcDecisionOtherValue: any;
  coordoPrcDecisionRefusalReason: any;
  ditepDrcDate: Date;
  ditepDrcDecision: LabelValue;
  ditepDrcOtherValue: any;
  ditepDrcRefusalReason: any;
  csetReceptionDate: Date;
  committeeDecisionRefusalReason: any;
  fillingFeasibilityQuestionDate: Date;
  feasibilityMeeting: string;
  feasibilityMeetingDate: Date;
  feasibilityCenterSelected: boolean;
  feasibilityCenterSelectedDate: Date;
  selectionCenterSelected: boolean;
  selectionCenterSelectedDate: Date;
  committeeMeetingDate: Date;
  additionalCostReceivedDate: Date;
  additionalCostValidationDate: Date;
  agreementReceiptDate: Date;
  agreementSigningDate: Date;
  promoterConvantion: boolean;
  clinicalIntervention: boolean;
  cardioIntervention: boolean;
  laboIntervention: boolean;
  medicalImageryIntervention: boolean;
  medicalNuclearIntervention: boolean;
  anapathIntevention: boolean;
  ophtalmoIntervention: boolean;
  pneumoIntevention: boolean;
  listInvistigators: boolean;
  tableOvercostTransmitted: boolean;
  resumeProtocol: boolean;
  trakingFile: boolean;
  tableValidation: boolean;
  protocol: boolean;
  labMaual: boolean;
  idePresentation: boolean;
  kits: string;
  invistigatorBinder: string;
  labRemovalRequest: string;
  requestCarboIce: string;
  airwaybillRC: string;
  removalRequestRC: string;
  tablet: string;
  airwaybillLab: string;
  clinicalInterventionDate: Date;
  cardioInterventionDate: Date;
  laboInterventionDate: Date;
  medicalImageryInterventionDate: Date;
  medicalNuclearInterventionDate: Date;
  anapathInteventionDate: Date;
  ophtalmoInterventionDate: Date;
  pneumoInteventionDate: Date;
  ditepCareRequestDate: Date;
  ditepCareAgreementDate: Date;
  feasibilityQuestionnaireResponse: CalendarCheckbox;
  feasibilityQuestionnaireDespatch: CalendarCheckbox;
  internalCommittee1: CalendarCheckbox;
  internalCommittee2: CalendarCheckbox;
  selectionVisit: CalendarCheckbox;
  trialObjective: string;

  init(obj: any): TrialAdministrativeFeasibilityInformation {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }


  isValid(config: FormConfig, showDitepDrcFields: boolean): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'feasibilityMeetingDate') {
        if (!Util.isNullOrUndefinedOrEmpty(this.feasibilityMeeting) && required && Util.isNullOrUndefinedOrEmpty(this.feasibilityMeetingDate)) {
          return false;
        }
      } else if (key === 'committeeDecisionOtherValue') {
        if (!Util.isNullOrUndefinedOrEmpty(this.committeeDecision) && this.committeeDecision.label === 'Autre' && required && Util.isNullOrUndefinedOrEmpty(this.committeeDecisionOtherValue)) {
          return false;
        }
      } else if (key === 'committeeDecisionRefusalReason') {
        if (!Util.isNullOrUndefinedOrEmpty(this.committeeDecision) && this.committeeDecision.label === 'Refusé' && required && Util.isNullOrUndefinedOrEmpty(this.committeeDecisionRefusalReason)) {
          return false;
        }
      } else if (key === 'finance') {
        if (required && (Util.isNullOrUndefinedOrEmpty(this.financeHhhIds) || this.financeHhhIds.length === 0)) {
          return false;
        }
      } else if (key === 'siteInclusionGoal') {
        if (this.multiSite && required && Util.isNullOrUndefinedOrEmpty(this.siteInclusionGoal)) {
          return false;
        }
      } else if (key === 'comexDitepDecision') {
        if (showDitepDrcFields && !Util.isNullOrUndefinedOrEmpty(this.comexDitepDate) && required && Util.isNullOrUndefinedOrEmpty(this.comexDitepDecision)) {
          return false;
        }
      } else if (key === 'comexDitepDecisionOtherValue') {
        if (showDitepDrcFields && this.comexDitepDecision && this.comexDitepDecision.label === 'Autre' && required && Util.isNullOrUndefinedOrEmpty(this.comexDitepDecisionOtherValue)) {
          return false;
        }
      } else if (key === 'comexDitepDecisionRefusalReason') {
        if (showDitepDrcFields && this.comexDitepDecision && this.comexDitepDecision.label === 'Refusé' && required && Util.isNullOrUndefinedOrEmpty(this.comexDitepDecisionRefusalReason)) {
          return false;
        }
      } else if (key === 'csetDecision') {
        if (this.csetDate && required && Util.isNullOrUndefinedOrEmpty(this.csetDecision)) {
          return false;
        }
      } else if (key === 'csetDecisionsOtherValue') {
        if (this.csetDecision && this.csetDecision.label === 'Autre' && required && Util.isNullOrUndefinedOrEmpty(this.csetDecisionsOtherValue)) {
          return false;
        }
      } else if (key === 'csetDecisionsRefusalReason') {
        if (this.csetDecision && this.csetDecision.label === 'Refusé' && required && Util.isNullOrUndefinedOrEmpty(this.csetDecisionsRefusalReason)) {
          return false;
        }
      } else if (key === 'cs2bDecision') {
        if (!Util.isNullOrUndefined(this.cs2bDate) && required && Util.isNullOrUndefinedOrEmpty(this.cs2bDecision)) {
          return false;
        }
      } else if (key === 'cs2bDecisionsOtherValue') {
        if (this.cs2bDecision && this.cs2bDecision.label === 'Autre' && required && Util.isNullOrUndefinedOrEmpty(this.cs2bDecisionsOtherValue)) {
          return false;
        }
      } else if (key === 'cs2bDecisionsRefusalReason') {
        if (this.csetDecision && this.csetDecision.label === 'Refusé' && required && Util.isNullOrUndefinedOrEmpty(this.cs2bDecisionsRefusalReason)) {
          return false;
        }
      } else if (key === 'coordoPrcDecision') {
        if (this.coordoPrcDate && required && Util.isNullOrUndefinedOrEmpty(this.cs2bDecisionsRefusalReason)) {
          return false;
        }
      } else if (key === 'coordoPrcDecisionOtherValue') {
        if (this.coordoPrcDecision && this.coordoPrcDecision.label === 'Autre' && required && Util.isNullOrUndefinedOrEmpty(this.coordoPrcDecisionOtherValue)) {
          return false;
        }
      } else if (key === 'coordoPrcDecisionRefusalReason') {
        if (this.coordoPrcDecision && this.coordoPrcDecision.label === 'Refusé' && required && Util.isNullOrUndefinedOrEmpty(this.coordoPrcDecisionRefusalReason)) {
          return false;
        }
      } else if (key === 'ditepDrcDecision') {
        if (this.ditepDrcDate && this.coordoPrcDecision.label === 'Refusé' && required && Util.isNullOrUndefinedOrEmpty(this.ditepDrcDecision)) {
          return false;
        }
      } else if (key === 'ditepDrcDecisionOtherValue') {
        if (this.ditepDrcDecision && this.ditepDrcDecision.label === 'Autre' && this.coordoPrcDecision.label === 'Refusé' && required && Util.isNullOrUndefinedOrEmpty(this.ditepDrcOtherValue)) {
          return false;
        }
      } else if (key === 'ditepDrcRefusalReason') {
        if (this.ditepDrcDecision && this.ditepDrcDecision.label === 'Refusé' && this.coordoPrcDecision.label === 'Refusé' && required && Util.isNullOrUndefinedOrEmpty(this.ditepDrcRefusalReason)) {
          return false;
        }
      } else if (key === 'feasibilityQuestionnaireDespatch') {
        if (required && !this.feasibilityQuestionnaireDespatch.date) {
          return false;
        }
      } else if (key === 'internalCommittee1') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.internalCommittee1.date)) {
          return false;
        }
      } else if (key === 'internalCommittee2') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.internalCommittee2.date)) {
          return false;
        }
      } else if (key === 'selectionVisit') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.selectionVisit.date)) {
          return false;
        }
      } else if (key === 'feasibilityQuestionnaireResponse') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.feasibilityQuestionnaireResponse.date)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {DoctorWithMessages} from '../models/doctor-with-messages';
import {MenuItem} from 'primeng/api';
import {Doctor} from '../models/doctor';
import {Message} from '../models/message';
import {TrialsManagementService} from '../services/trials-management.service';

@Component({
  selector: 'ih-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.css']
})
export class ChatDialogComponent implements OnInit {

  display = false;
  submitted = false;
  formHeader: string;
  trialHhhId: number;
  doctorsWithMessages: DoctorWithMessages[];
  chatItems: MenuItem[] = [];
  whomDiscussionWith: string;

  currentDoctor: Doctor;
  currentDoctorMessages: Message[];

  activeChatItemIndex = 0;

  constructor(public trialsManagementService: TrialsManagementService) {
  }

  public static display(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ChatDialogComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  static getUpdateTitle(): string {
    return 'MODULE_MOBILE_APPLICATION_UPDATE_TRIAL';
  }

  ngOnInit() {
    this.initDoctors();
    this.submitted = false;
    this.display = true;
  }

  activeMenu(event) {
    let node;
    if (event.target.tagName === 'A') {
      node = event.target;
    } else {
      node = event.target.parentNode;
    }
    const menuitem = document.getElementsByClassName('ui-menuitem-link');
    for (let i = 0; i < menuitem.length; i++) {
      menuitem[i].classList.remove('active');
    }
    node.classList.add('active');
  }

  private initDoctors() {
    this.trialsManagementService.conversations.subscribe(conversations => {
      this.doctorsWithMessages = conversations;
      this.chatItems = [];
      conversations.forEach((doctor) => {
        this.chatItems.push({
          label: doctor.doctor.fullName,
          id: doctor.doctor.hhhId.toString(),
          icon: (doctor.lastMessageSeen ? 'fa fa-fw fa-circle' : 'fa fa-fw fa-circle-o'),
          command: (event) => this.showChat(event.item, doctor.doctor.hhhId)
        });
      });
      this.showChat(this.chatItems[this.activeChatItemIndex], -1);
    });

  }

  private showChat(item, doctorId) {
    this.whomDiscussionWith = '|' + item.label;
    const currentDoctorIndex = this.doctorsWithMessages.findIndex(doctor => doctor.doctor.hhhId == item.id);
    this.currentDoctor = this.doctorsWithMessages[currentDoctorIndex].doctor;
    this.currentDoctorMessages = this.doctorsWithMessages[currentDoctorIndex].messages;
    this.trialsManagementService.updateMessagesToSeen(this.trialHhhId, doctorId).subscribe(() => {
      this.doctorsWithMessages[currentDoctorIndex].lastMessageSeen = true;
    });
    if (doctorId !== -1) {
      this.activeChatItemIndex = currentDoctorIndex;
    }
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ih-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.css']
})
export class ListHeaderComponent implements OnInit {

  @Input() buttonLabel: string;
  @Input() extraButtonLabel: string;
  @Input() displayButton = true;
  @Input() displayExtraButton = false;
  @Input() moduleName: string;
  @Input() iconName: string;
  @Input() disabled = false;
  @Input() disabledForExtraButton = false;
  @Output() addEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() extraFunctionEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  add() {
    this.disabled = true;
    this.addEvent.emit();
    this.disabled = false;
  }

  extraFunction() {
    this.disabledForExtraButton = true;
    this.extraFunctionEvent.emit();
    this.disabledForExtraButton = false;
  }

}

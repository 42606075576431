import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MinimizedTrialProtocol} from '../../../../models/minimized-trial-protocol';
import {DeleteDeletableEntityConfirmationComponent} from '../../../../../modals/delete-deletable-entity-confirmation/delete-deletable-entity-confirmation.component';
import {InnohealthTranslateService} from '../../../../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {AmendmentConsentFormComponent} from '../amendment-consent-form/amendment-consent-form.component';
import {TrialConsentService} from '../../../../services/trial-consent.service';
import {Router} from '@angular/router';
import {TrialConsent} from '../../../../models/trial-consent';
import {TrialService} from '../../../../services/trial.service';
import {TrialPageLockService} from '../../../../../dynamic-config/exported/page-lock/trial-page-lock.service';

@Component({
  selector: 'ih-amendment-consents',
  templateUrl: './amendment-consents.component.html',
  styleUrls: ['./amendment-consents.component.css']
})
export class AmendmentConsentsComponent implements OnInit {

  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;
  @ViewChild('displayDeleteDialog', {read: ViewContainerRef}) deleteDialogContainer: ViewContainerRef;

  trialId: number;

  trialConsentList: TrialConsent[] = [];

  constructor(
    private cfr: ComponentFactoryResolver,
    private translateService: InnohealthTranslateService,
    private datePipe: DatePipe,
    public trialService: TrialService,
    private trialConsentService: TrialConsentService,
    private router: Router,
    public trialPageLockService: TrialPageLockService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.getTrialConsentList();
  }

  getAddTitle(): string {
    return this.translateService.getTranslationString('MODULE_TRIALS_AMENDMENT_CONSENT_ADD_BTN_LABEL');
  }

  getUpdateTitle(): string {
    return this.translateService.getTranslationString('MODULE_TRIALS_AMENDMENT_CONSENTS');
  }

  displayAddTrialConsentDialogue(consent?: TrialConsent) {
    const formComponent = AmendmentConsentFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.trialConsentList = this.trialConsentList;
    if (consent) {
      formComponent.consent = (new TrialConsent()).init(consent);
      formComponent.formHeader = this.getUpdateTitle();
      formComponent.onAdd((trialConsent: TrialConsent) => {
        const index = this.trialConsentList.findIndex((consent1: TrialConsent) => consent1.hhhId === trialConsent.hhhId);
        this.trialConsentList[index] = trialConsent;
      });
    } else {
      formComponent.formHeader = this.getAddTitle();
      formComponent.onAdd((trialConsent: TrialConsent) => {
        this.trialConsentList.push(trialConsent);
      });
    }
  }

  displayDeleteDialogueOfDeletableEntity(amendment: MinimizedTrialProtocol) {
    const deleteConfirmationComponent = DeleteDeletableEntityConfirmationComponent.displayDeleteConfirmationDialogue(this.deleteDialogContainer, this.cfr);
    deleteConfirmationComponent.hhhId = amendment.hhhId;
    deleteConfirmationComponent.name = amendment.version;
    deleteConfirmationComponent.popupMessage = this.translateService.getTranslationString('MODULE_TRIALS_AMENDMENT_CONSENT_DELETE_MESSAGE');
    deleteConfirmationComponent.service = this.trialConsentService;
    deleteConfirmationComponent.onDelete(() => {
      this.refreshAfterDelete(amendment);
    });
  }

  refreshAfterDelete(entity?: any): void {
    const index = this.trialConsentList.findIndex((trialConsent: TrialConsent) => trialConsent.hhhId === entity.hhhId);
    this.trialConsentList.splice(index, 1);
  }

  transformDate(date: Date): string {
    if (date !== null) {
      return this.datePipe.transform(date, 'dd/MM/yyyy');
    } else {
      return null;
    }
  }

  private getTrialConsentList() {
    this.trialConsentService.getList(this.trialId).subscribe(res => {
      this.trialConsentList = res;
    }, error => {
      console.log('An error occurred while loading amendment list');
    });
  }
}

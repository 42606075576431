import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EstablishmentContainerComponent} from './establishment-container/establishment-container.component';
import {ActiveFileByYearComponent} from './active-file-by-year/active-file-by-year.component';
import {InformationAndServicesComponent} from './information-and-services/information-and-services.component';
import {EstablishmentRoutingModule} from './establishment-routing.module';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import {
  ButtonModule,
  DropdownModule,
  FieldsetModule,
  InputTextModule,
  PaginatorModule,
  TabViewModule,
  ToolbarModule
} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {ToastModule} from 'primeng/toast';
import {MatBadgeModule, MatTabsModule} from '@angular/material';
import {ServiceEditFormComponent} from './service-edit-form/service-edit-form.component';
import {ActiveFileByYearFormComponent} from './active-file-by-year-form/active-file-by-year-form.component';
import {SharedModule} from '../shared/shared.module';
import {EstablishmentsComponent} from './establishments/establishments.component';
import {EstablishmentFormComponent} from './establishment-form/establishment-form.component';
import {RouterModule} from '@angular/router';
import {MyEstablishmentComponent} from './my-establishment/my-establishment.component';
import {EstablishmentEditFormComponent} from './establishment-edit-form/establishment-edit-form.component';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';

@NgModule({
  imports: [
    EstablishmentRoutingModule,
    SharedModule,
    DynamicConfigModule
  ],
  declarations: [
    EstablishmentContainerComponent,
    ActiveFileByYearComponent,
    ActiveFileByYearFormComponent,
    InformationAndServicesComponent,
    EstablishmentsComponent,
    EstablishmentFormComponent,
    MyEstablishmentComponent,
    EstablishmentEditFormComponent,
    ServiceEditFormComponent
  ],
  entryComponents: [
    ServiceEditFormComponent,
    ActiveFileByYearFormComponent,
    EstablishmentFormComponent,
    EstablishmentEditFormComponent
  ]
})
export class EstablishmentModule {
}

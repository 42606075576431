import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {SharedService} from '../../../../shared/services/shared.service';
import {InvoiceMonitorService} from '../../../service/invoice-monitor.service';
import {TrialService} from '../../../services/trial.service';
import {FR} from 'src/app/shared/entity/calendar-language';
import {TrialFollowUpFilter} from 'src/app/trial/entity/trial-track/trial-follow-up-filter';
import {InvoiceMonitor} from '../../../entity/invoice-monitor/invoice-monitor';

@Component({
  selector: 'ih-invoice-monitor',
  templateUrl: './invoice-monitor.component.html',
  styleUrls: ['./invoice-monitor.component.css']
})
export class InvoiceMonitorComponent implements OnInit {

  invoiceHhhId: number;
  invoicesList: InvoiceMonitor[] = [];
  trialId: number;
  public invoice = new InvoiceMonitor();
  page = 0;
  limit = 10;
  locale = FR;
  trialFollowUpFilter: TrialFollowUpFilter = new TrialFollowUpFilter();
  trialSiteHhhId: number;
  invoicedBudget: number;
  realisedBudget: number;
  toBeInvoicedBudget: number;
  nonRealisedBudget: number;
  notApplicableBudget: number;
  payedBudget: number;
  public displayEditVisitDialog = true;

  constructor(private router: Router,
              private sharedService: SharedService,
              public invoiceMonitorService: InvoiceMonitorService,
              public trialService: TrialService) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit(): void {
    this.getInvoicesByTrialHhhId();
    this.initialiseBudget();
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  getInvoicesByTrialHhhId() {
    if (!(this.trialFollowUpFilter.trialSiteHhhId)) {
      this.trialFollowUpFilter.trialSiteHhhId = -1;
    }
    this.invoiceMonitorService.loadList(this.trialId).subscribe(res => {
        this.invoicesList = res;
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading invoices. ' + error);
      }
    );
  }


  totalPayed(): number {
    let res = 0;
    this.invoicesList.forEach(invoice => {
      if (invoice.state.nameFr === 'Payée') {
        res += invoice.amount;
      }
    });
    return (res);
  }

  totalValid(): number {
    let res = 0;
    this.invoicesList.forEach(invoice => {
      if (invoice.state.nameFr === 'Validée') {
        res += invoice.amount;
      }
    });
    return (res);
  }

  totalIssued(): number {
    let res = 0;
    this.invoicesList.forEach(invoice => {
      if (invoice.state.nameFr === 'Emise') {
        res += invoice.amount;
      }
    });
    return (res);
  }

  totalDespatched(): number {
    let res = 0;
    this.invoicesList.forEach(invoice => {
      if (invoice.state.nameFr === 'En cours de préparation') {
        res += invoice.amount;
      }
    });
    return (res);

  }

  initialiseBudget() {
    this.invoiceMonitorService.getBudgetPerState(this.trialId).subscribe(res => {
        this.payedBudget = res.payedBudget;
        this.invoicedBudget = res.invoicedBudget;
        this.realisedBudget = res.realisedBudget;
        this.toBeInvoicedBudget = res.toBeInvoicedBudget;
        this.nonRealisedBudget = res.nonRealisedBudget;
        this.notApplicableBudget = res.notApplicableBudget;
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading budgets. ' + error);
      }
    );
  }

  navigateToInvoiceDetails(invoiceId: number) {
    const url = this.router.parseUrl(this.router.url);
    const invoicehhhId = url.queryParams.invoicehhhId;
    this.router.navigate(['/invoice-details/invoice-information'],
      {
        queryParams: {
          invoiceHhhId: invoiceId
        }
      }).then();
    this.displayEditVisitDialog = false;
  }
}

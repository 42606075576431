import {InvoiceAct} from '../invoice-acts/models/invoice-act';
import {InvoiceFixedCost} from './invoice-fixed-cost';
import {InvoiceCounterPart} from '../invoice-counter-parts/models/invoice-counter-part';

export class StaticInvoiceItem {
  currentVersionHhhId: number;
  nbrOfHistoryVersions: number;
  modificationDate: string;
  modifiedBy: string;
  versionHhhIds: number[];
  invoiceActs?: InvoiceAct[];
  invoiceFixedCosts?: InvoiceFixedCost[];
  invoiceCounterParts?: InvoiceCounterPart[];

  constructor() {
    this.versionHhhIds = [];
    this.invoiceActs = [];
  }
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ConfirmationService} from 'primeng/api';
import {InnohealthTranslateService} from '../shared/services/innohealth-translate.service';
import {AuthenticationService} from '../login/services/authentication.service';
import {Router} from '@angular/router';
import {SharedService} from '../shared/services/shared.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
      private router: Router,
      private sharedService: SharedService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401, 403].indexOf(err.status) !== -1 && localStorage.getItem('token')) {
        this.sharedService.showWarning('USER_UNAUTHORIZED_TO_MAKE_ACTION');
      }
/*      if ([400].indexOf(err.status) !== -1 && localStorage.getItem('token')) {
        this.router.navigate(['error-page']);
      }*/
      const error = err.error != null ? err : err.statusText;
      return throwError(error);
    }));
  }

}

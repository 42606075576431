export class FinancialEntity {
  hhhId: number;
  financialManageName: string;
  precisionPostalInvoicingAddress: string;
  postalInvoicingAddressNumber: string;
  postalInvoicingAddressStreet: string;
  postalInvoicingAddressCode: string;
  postalInvoicingAddressCity: string;
  postalInvoicingAddressCedex: string;
  postalInvoicingAddressCountry: string;
  postalInvoiceSendingAddress: string;
  mail: string;
  phone: string;
  promoterAccountantNumber: string;
  tva: string;
  tvaIntraCommunity: string;
  providerId: number;
}

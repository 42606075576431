import {Injectable, OnDestroy} from '@angular/core';
import {AuthenticationService, SESSION_ID, WEBSOCKET_SESSION_ID} from './login/services/authentication.service';
import {interval, Subscription} from 'rxjs';
import {StompService} from './shared/services/stomp.service';
import {Heartbeat} from './heartbeat';
import {StompSubscription} from '@stomp/stompjs/src/stomp-subscription';

@Injectable({
  providedIn: 'root'
})
export class HeartbeatService implements OnDestroy {

  private heartbeatInterval: Subscription;
  private readonly HEARTBEAT_INTERVAL = 60000; // send heartbeat every minute
  private heartbeatSubscription: StompSubscription;

  constructor(private stompService: StompService, private authService: AuthenticationService) {
  }

  startHeartbeat() {
    const websocketSessionId = localStorage.getItem(WEBSOCKET_SESSION_ID);
    this.stompService.subscribe('/topic/heartbeat/' + websocketSessionId, (message) => {
    }, (subscription) => this.heartbeatSubscription = subscription);
    if (this.heartbeatInterval) {
      this.heartbeatInterval.unsubscribe();
    }

    this.heartbeatInterval = interval(this.HEARTBEAT_INTERVAL).subscribe(() => { // Send every 30 seconds
      this.sendHeartbeat();
    });
  }

  sendHeartbeat(): void {
    if (!this.authService.isUserAuthenticated()) {
      this.heartbeatInterval.unsubscribe();
      return;
    }
    const websocketSessionId = localStorage.getItem(WEBSOCKET_SESSION_ID);
    const sessionId = parseInt(localStorage.getItem(SESSION_ID), 10);
    const heartBeat = new Heartbeat(sessionId);
    this.stompService.send('/heartbeat/' + websocketSessionId, JSON.stringify(heartBeat));
  }

  stopHeartbeats(): void {
    if (this.heartbeatInterval) {
      this.heartbeatInterval.unsubscribe();
    }
    if (this.heartbeatSubscription) {
      this.stompService.unsubscribe(this.heartbeatSubscription);
    }
  }

  ngOnDestroy(): void {
    this.stopHeartbeats();
  }



}

import {TrialExportFilter} from './trial-export-filter';
import {PatientExportFilter} from './patient-export-filter';
import {TrialCustomExportFilter} from './TrialCustomExportFilter';

export class CustomExport {
  hhhId: string;
  trialExportFilter: TrialCustomExportFilter;
  patientExportFilter: PatientExportFilter;
  trialFields: string[];
  PatientFields: string[];



  CustomExport() {
    this.trialExportFilter = new TrialCustomExportFilter();
    this.patientExportFilter = new PatientExportFilter();
    this.trialFields = [];
    this.PatientFields = [];

    return this;
  }
}

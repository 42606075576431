import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeContainerComponent} from './home-container/home-container.component';
import {BlocNoteComponent} from './bloc-note/bloc-note.component';
import {ClinicalResearchActivityComponent} from './clinical-research-activity/clinical-research-activity.component';
import {CurrentUserIdentityComponent} from './current-user-identity/current-user-identity.component';
import {CardModule} from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {ChartModule, CheckboxModule, DropdownModule, InputTextareaModule, OverlayPanelModule, ProgressBarModule, ToolbarModule} from 'primeng/primeng';
import {TableModule} from 'primeng/table';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {PersonalHistoryComponent} from './personal-history/personal-history.component';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

@NgModule({
  declarations: [HomeContainerComponent, BlocNoteComponent, ClinicalResearchActivityComponent, CurrentUserIdentityComponent, PersonalHistoryComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    CommonModule,
    ButtonModule,
    DropdownModule,
    ToolbarModule,
    TableModule,
    CheckboxModule,
    OverlayPanelModule,
    InputTextareaModule,
    FormsModule,
    ChartModule,
    ProgressBarModule,
    CardModule,
    VirtualScrollerModule,
    HttpClientModule,
    TranslateModule,
  ]
})
export class HomeModule { }

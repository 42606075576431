import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenubarModule} from 'primeng/menubar';
import {MenuModule} from 'primeng/menu';
import {AccordionModule, AutoCompleteModule, ButtonModule, CalendarModule, ChartModule, CheckboxModule, ChipsModule, ColorPickerModule, ConfirmDialogModule, ContextMenuModule, DialogModule, DropdownModule, FieldsetModule, FileUploadModule, GalleriaModule, InputSwitchModule, InputTextareaModule, InputTextModule, LightboxModule, MegaMenuModule, MessageModule, MessageService, MessagesModule, MultiSelectModule, OrganizationChartModule, OverlayPanelModule, PaginatorModule, PanelModule, PasswordModule, PickListModule, ProgressBarModule, ProgressSpinnerModule, RadioButtonModule, RatingModule, ScrollPanelModule, SelectButtonModule, SidebarModule, SlideMenuModule, SpinnerModule, SplitButtonModule, TabMenuModule, TabViewModule, ToolbarModule, TooltipModule, TreeTableModule} from 'primeng/primeng';
import {TableModule} from 'primeng/table';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {MatBadgeModule, MatButtonModule, MatCardModule, MatChipsModule, MatDialogModule, MatDividerModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSortModule, MatStepperModule, MatTableModule, MatToolbarModule, MatTooltipModule, MatTreeModule} from '@angular/material';
import {ListHeaderComponent} from '../modals/list-header/list-header.component';
import {CalendarComponent} from './component/calendar/calendar.component';
import {DropdownComponent} from './component/dropdown/dropdown.component';
import {LoaderComponent} from './component/loader/loader.component';
import {PaginatorTableComponent} from './component/paginator-table/paginator-table.component';
import {MultiSelectComponent} from './component/multi-select/multi-select.component';
import {TableFilterDropdownComponent} from './component/table-filter-dropdown/table-filter-dropdown.component';
import {CurrencyDirective} from '../helpers/currency.directive';
import {WarningDialogComponent} from './component/warning-dialog/warning-dialog.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {CardModule} from 'primeng/card';
import {MatTabsModule} from '@angular/material/tabs';
import {FormComponent} from './component/form/form.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastComponent} from './component/toast/toast.component';
import {GroupBy} from './pipe/group-by.pipe';
import {NonZero} from './pipe/non-zero.pipe';
import {EliminateNumbersPipe} from './pipe/eliminate-numbers.pipe';
import {CommaSplitPipe} from './pipe/comma-split.pipe';
import {UnderScoreSplitPipe} from './pipe/under-score-split.pipe';
import {FormatTimePipe} from './pipe/format-time.pipe';
import {ConfigurationLabelPipe} from './pipe/configurationLabelPipe';
import {RouterModule} from '@angular/router';
import {CarouselModule} from 'primeng/carousel';
import {DataViewModule} from 'primeng/dataview';
import {DynamicTranslatorPipe} from '../dynamic-config/pipe/dynamic-translator.pipe';
import {InitialFocusDirective} from '../dynamic-config/directive/initial-focus.directive';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    TranslateModule,
    PdfViewerModule,

    // @angular-material Modules
    MatIconModule,
    MatBadgeModule,
    MatTabsModule,
    MatMenuModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSliderModule,
    MatSidenavModule,
    MatSortModule,
    MatStepperModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,

    // @PrimeNg Modules
    AutoCompleteModule,
    FieldsetModule,
    PickListModule,
    CheckboxModule,
    CalendarModule,
    MenuModule,
    MenubarModule,
    SidebarModule,
    ToolbarModule,
    TableModule,
    TreeTableModule,
    DialogModule,
    MultiSelectModule,
    DropdownModule,
    ButtonModule,
    TabViewModule,
    TabMenuModule,
    InputTextModule,
    ToastModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    SpinnerModule,
    ProgressBarModule,
    CardModule,
    TooltipModule,
    InputTextareaModule,
    ScrollPanelModule,
    InputSwitchModule,
    ColorPickerModule,
    SelectButtonModule,
    SplitButtonModule,
    RadioButtonModule,
    AccordionModule,
    ConfirmDialogModule,
    ChipsModule,
    ContextMenuModule,
    RatingModule,
    MegaMenuModule,
    PaginatorModule,
    PanelModule,
    LightboxModule,
    ChartModule,
    OrganizationChartModule,
    MessagesModule,
    MessageModule,
    CarouselModule,
    GalleriaModule,
    SlideMenuModule,
    FileUploadModule,
    DataViewModule,
    PasswordModule,
  ],
  declarations: [
    // Components
    ListHeaderComponent,
    CalendarComponent,
    DropdownComponent,
    MultiSelectComponent,
    LoaderComponent,
    PaginatorTableComponent,
    FormComponent,
    TableFilterDropdownComponent,
    CurrencyDirective,
    InitialFocusDirective,
    WarningDialogComponent,
    ToastComponent,
    // Pipes
    GroupBy,
    NonZero,
    EliminateNumbersPipe,
    CommaSplitPipe,
    UnderScoreSplitPipe,
    FormatTimePipe,
    ConfigurationLabelPipe,
  ],
  exports: [
    // components
    CalendarComponent,
    DropdownComponent,
    MultiSelectComponent,
    ListHeaderComponent,
    TranslateModule,
    TableFilterDropdownComponent,
    LoaderComponent,
    PaginatorTableComponent,
    CurrencyDirective,
    InitialFocusDirective,
    FormComponent,
    ToastComponent,

    // Pipes
    GroupBy,
    NonZero,
    EliminateNumbersPipe,
    CommaSplitPipe,
    UnderScoreSplitPipe,
    FormatTimePipe,
    ConfigurationLabelPipe,

    // Modules
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    TranslateModule,
    PdfViewerModule,

    // @angular-material Modules
    MatIconModule,
    MatBadgeModule,
    MatTabsModule,
    MatMenuModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSliderModule,
    MatSidenavModule,
    MatSortModule,
    MatStepperModule,
    MatStepperModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,

    // @PrimeNg Modules
    AutoCompleteModule,
    FieldsetModule,
    PickListModule,
    CheckboxModule,
    CalendarModule,
    MenuModule,
    MenubarModule,
    SidebarModule,
    ToolbarModule,
    TableModule,
    TreeTableModule,
    DialogModule,
    MultiSelectModule,
    DropdownModule,
    ButtonModule,
    TabViewModule,
    TabMenuModule,
    InputTextModule,
    ToastModule,
    OverlayPanelModule,
    ProgressSpinnerModule,
    SpinnerModule,
    ProgressBarModule,
    CardModule,
    TooltipModule,
    InputTextareaModule,
    ScrollPanelModule,
    InputSwitchModule,
    ColorPickerModule,
    SelectButtonModule,
    SplitButtonModule,
    RadioButtonModule,
    AccordionModule,
    ConfirmDialogModule,
    ChipsModule,
    ContextMenuModule,
    RatingModule,
    MegaMenuModule,
    PaginatorModule,
    PanelModule,
    LightboxModule,
    ChartModule,
    OrganizationChartModule,
    MessagesModule,
    MessageModule,
    CarouselModule,
    GalleriaModule,
    SlideMenuModule,
    FileUploadModule,
    DataViewModule,
    PasswordModule
  ],
  entryComponents: [
    WarningDialogComponent,
  ],
  providers: [
    MessageService,
    TranslatePipe,
    DynamicTranslatorPipe
  ]
})
export class SharedModule {
}

import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {Permission} from '../../helpers/permission';
import {INVOICE_READ_WRITE} from '../../login/services/authentication.service';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-retrocession-fees-container',
  templateUrl: './retrocession-fees-container.component.html',
  styleUrls: ['./retrocession-fees-container.component.css']
})
export class RetrocessionFeesContainerComponent implements OnInit {

  items: MenuItem[] = [];
  preInvoiceEnabled = true;

  constructor(
      private translate: TranslateService,
      private translateService: InnohealthTranslateService,
      private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.preInvoiceEnabled = this.dynamicConfigService.getProperty(PropertyEnum.preInvoiceEnabled);
      this.initializeMenuItem();
    });
  }

  isRetrocessionFeesListDisplayed() {
    return localStorage.getItem(INVOICE_READ_WRITE) !== Permission.NO_RIGHT;
  }

  private initializeMenuItem() {
    if (this.isRetrocessionFeesListDisplayed() && this.preInvoiceEnabled) {
      this.items.push(
          {
            label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_PRE_INVOICES'),
            routerLink: ['/retrocession-fees-container/retrocession-fees-list']
          }
      );
    }
  }

}

import {JsonConvert, JsonConverter, JsonCustomConvert} from 'json2typescript';
import {FormInputConfig} from './form-input-config';
import {TableHeaderConfig} from './table-header-config';
import {TableButtonConfig} from './table-button-config';
import {ModuleConfig} from './module-config';
import {ModuleHeaderConfig} from './module-header-config';
import {ContainerCardConfig} from './container-card-config';


@JsonConverter
export class StringConfigMapConverter<T> implements JsonCustomConvert<Map<string, T>> {

  type: string;

  constructor(type?: string) {
    this.type = type;
  }

  serialize(map: Map<string, T>): any {
    const result = {};
    map.forEach((value, key) => result[key] = value);
    return result;
  }

  deserialize(map: any): Map<string, T> {
    const result = new Map<string, T>();
    for (const key in map) {
      if (!map.hasOwnProperty(key)) {
        continue;
      }
      const jsonConvert: JsonConvert = new JsonConvert();
      let value = map[key];
      if (this.type === 'FormInputConfig') {
        value = jsonConvert.deserialize(value, FormInputConfig) as FormInputConfig;
      } else if (this.type === 'TableHeaderConfig') {
        value = jsonConvert.deserialize(value, TableHeaderConfig) as TableHeaderConfig;
      } else if (this.type === 'TableButtonConfig') {
        value = jsonConvert.deserialize(value, TableButtonConfig) as TableButtonConfig;
      } else if (this.type === 'ContainerCardConfig') {
        value = new ContainerCardConfig().init(value);
      } else if (this.type === 'ModuleConfig') {
        value = new ModuleConfig(
            jsonConvert.deserialize(value.header, ModuleHeaderConfig) as ModuleHeaderConfig,
            new StringConfigMapConverter<any>().deserialize(value.properties));
      }
      result.set(key, value);
    }
    return result;
  }
}

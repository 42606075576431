import {Component, ComponentFactoryResolver, Inject, InjectionToken, OnInit, ViewContainerRef} from '@angular/core';
import {TrialService} from '../../services/trial.service';
import {Properties} from '../../../helpers/properties';
import {DynamicFilesService} from '../../../shared/services/dynamic-files.service';
import {Router} from '@angular/router';
import {IgCsvExport} from '../../../shared/entity/ig-csv-export';
import {Util} from '../../../helpers/util';

@Component({
  selector: 'ih-trial-import',
  templateUrl: './trial-import.component.html',
  styleUrls: ['./trial-import.component.css']
})
export class TrialImportComponent implements OnInit {
  importTrialsDialogueVisible = false;
  file: File;
  host = new Properties().host;
  private rowSeparator = '\r\n';

  constructor(public dynamicFiles: DynamicFilesService,
              private trialService: TrialService,
              private router: Router,
              @Inject('LOCATION_TOKEN') private location: Location) {
    console.log(location);
  }

  public static display(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(TrialImportComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
  }

  importTrials() {
    this.trialService.importTrials(this.file).subscribe(res => {
      console.log(res);
      let csvData = '';
      for (const re of res) {
        csvData += (re + this.rowSeparator);
      }
      this.importTrialsDialogueVisible = false;
      const BOM = '\uFEFF';
      const blob = new Blob([BOM + csvData], {type: 'text/csv;charset=utf-8'});
      Util.downloadFile(blob, 'rapport_import_étude.csv');
      this.router.navigate(['']).then(() => {
        this.router.navigate(['/trial-list']);
      });
    });
  }

  downloadModal() {
    location.assign(this.host + '/' + this.dynamicFiles.importTrialsFileName);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }
}

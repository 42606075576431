import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {TrialAdministrativeCecInformation} from '../../../models/trial-administrative-cec-information';
import {Data} from '../../../../shared/entity/data';
import {FR} from '../../../../shared/entity/calendar-language';
import {TrialAdministrativeCecService} from '../../../services/trial-administrative-cec.service';
import {DataService} from '../../../../shared/services/data-service';
import {SharedService} from '../../../../shared/services/shared.service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {AppData} from '../../../../helpers/app-data';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {TrialAdministrativeInformationService} from '../../../services/trial-administrative-information.service';

@Component({
  selector: 'ih-trial-administrative-logistic-information',
  templateUrl: './trial-administrative-logistic-information.component.html',
  styleUrls: ['./trial-administrative-logistic-information.component.css']
})
export class TrialAdministrativeLogisticInformationComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_LOGISTIC_INFORMATION_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  trialAdministrativeCecInformation: TrialAdministrativeCecInformation = new TrialAdministrativeCecInformation();
  locale = FR;

  noYesOptions = Data.yesOrNo;
  trialHhhId: number;

  constructor(
    private trialAdministrativeCecService: TrialAdministrativeCecService,
    private dataService: DataService,
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private sharedService: SharedService,
    private canDeactivateService: CanDeactivateService,
    private dynamicConfigService: DynamicConfigService,
    private trialAdministrativeInformationService: TrialAdministrativeInformationService
  ) {
    const url = this.router.parseUrl(this.router.url);
    this.trialHhhId = url.queryParams.hhhId;
  }

  ngOnInit() {
    this.initProperties();
    this.getTrialAdministrativeCecInformation();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.trialAdministrativeCecInformation.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialAdministrativeRegulatory, this.trialAdministrativeCecInformation.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.trialAdministrativeCecInformation.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  updateAdministrativeInformation(): void {
    if (!this.trialAdministrativeCecInformation.isValid(this.config)) {
      return;
    }
    this.trialAdministrativeCecInformation.trialHhhId = this.trialHhhId;
    this.trialAdministrativeCecService.updateAdministrativeCecInformation(this.trialAdministrativeCecInformation).subscribe((res) => {
      this.sharedService.showSuccess();
      this.canDeactivateService.canBeDeactivated = true;
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred in trialAdministrative$.' + error);
    });
  }

  getTrialAdministrativeCecInformation() {
    this.trialAdministrativeCecService.getAdministrativeCecInformation(this.trialHhhId).subscribe(
      res => {
        this.trialAdministrativeCecInformation = res;
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred in trialAdministrative$.' + error);
      });
  }

  export(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.trialHhhId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .build();

    this.trialAdministrativeInformationService.exportedAdministrativeInformation(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }
}

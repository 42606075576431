import {JsonObject, JsonProperty} from 'json2typescript';
import {DynamicTableDefinitionHeader} from './dynamic-table-definition-header';
import {DynamicTableDefinitionButton} from './dynamic-table-definition-button';

@JsonObject('DynamicTableDefinition')
export class DynamicTableDefinition {

  @JsonProperty('definition', String)
  definition: string = undefined;

  @JsonProperty('headers', [DynamicTableDefinitionHeader])
  headers: DynamicTableDefinitionHeader[] = [];

  @JsonProperty('buttons', [DynamicTableDefinitionButton])
  buttons: DynamicTableDefinitionButton[] = [];
}

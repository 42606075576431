import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {DynamicTableHeader} from '../dynamic-lazy-table/dynamic-table-header';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DynamicTranslatorPipe} from '../../pipe/dynamic-translator.pipe';
import {Table} from 'primeng/table';

@Component({
  selector: 'ih-dynamic-table-overlay',
  templateUrl: './dynamic-table-overlay.component.html',
  styleUrls: ['./dynamic-table-overlay.component.css'],
  providers: [DynamicTranslatorPipe]
})

export class DynamicTableOverlayComponent implements OnInit, OnChanges {

  @Input() possibleHeaders: DynamicTableHeader[] = [];
  @Input() effectiveHeaders: DynamicTableHeader[] = [];
  @Input() headersReady: boolean;
  @Input() target: string;

  allSelected: boolean;

  @ViewChild('overlayTable') overlayTable: Table;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() effectiveHeadersChange = new EventEmitter<DynamicTableHeader[]>();
  @Output() possibleHeadersChange = new EventEmitter<DynamicTableHeader[]>();

  constructor(private translatorPipe: DynamicTranslatorPipe) {}

  ngOnInit(): void {
    const storedValue = localStorage.getItem('allSelectedInTrialTable');
    this.allSelected = storedValue !== null ? JSON.parse(storedValue) : false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.headersReady && (changes.headersReady.previousValue === false) && Boolean(changes.headersReady.currentValue)) {
        this.updateHeadersOrder();
    }
  }

  private updateHeadersOrder() {
    const savedHeaders = JSON.parse(localStorage.getItem(this.target));
    console.warn(savedHeaders);
    if (!savedHeaders) {
      this.saveHeadersLayout();
      return;
    }
    const orderedEffectiveHeaders = [];
    for (const header of savedHeaders) {
      let index = this.effectiveHeaders.findIndex(h => header.field === h.field);
      if (index !== -1) {
        orderedEffectiveHeaders.push(this.effectiveHeaders[index]);
      }
      index = this.possibleHeaders.findIndex(h => header.field === h.field);
      if (index !== -1) {
        this.possibleHeaders[index].checked = true;
        orderedEffectiveHeaders.push(this.possibleHeaders[index]);
      }
    }
    for (const header of this.effectiveHeaders) {
      const index = orderedEffectiveHeaders.findIndex(h => header.field === h.field);
      if (index === -1) {
        header.checked = false;
        this.possibleHeaders.unshift(header);
      }
    }
    this.allSelected = (this.possibleHeaders.findIndex(h => h.checked === false) === -1);
    localStorage.setItem('allSelectedInTrialTable', JSON.stringify(this.allSelected));
    this.effectiveHeaders = orderedEffectiveHeaders;
    this.effectiveHeadersChange.emit(this.effectiveHeaders);
    this.possibleHeadersChange.emit(this.possibleHeaders);
    this.saveHeadersLayout();

  }

  get filteredHeaders() {
    return this.overlayTable.filteredValue || this.possibleHeaders;
  }

  filterHeaders(event: string): void {
    this.overlayTable.filteredValue = this.possibleHeaders.filter(header => this.translatorPipe.transform(header.header).toLowerCase().includes(event.toLowerCase()));
  }

  onSelectAllChange() {
    if (this.allSelected) {
      for (const col of this.filteredHeaders) {
        if (col.checked) {
          col.checked = false;
          this.effectiveHeaders.splice(this.effectiveHeaders.indexOf(col), 1);
        }
        col.checked = true;
        this.effectiveHeaders.push(col);
      }
    } else {
      for (const col of this.filteredHeaders) {
        col.checked = false;
      }
      this.effectiveHeaders = this.effectiveHeaders.splice(0, 9);
    }
    this.effectiveHeadersChange.emit(this.effectiveHeaders);
    this.possibleHeadersChange.emit(this.possibleHeaders);
    this.saveHeadersLayout();
  }

  changeCheck(col: any) {
    this.allSelected = false;
    if (col.checked) {
      this.effectiveHeaders.push(col);
    } else {
      this.effectiveHeaders.splice(this.effectiveHeaders.indexOf(col), 1);
    }
    this.effectiveHeadersChange.emit(this.effectiveHeaders);
    this.possibleHeadersChange.emit(this.possibleHeaders);
    this.saveHeadersLayout();
  }

  saveHeadersLayout() {
    localStorage.setItem(this.target, JSON.stringify(this.effectiveHeaders));
  }

}

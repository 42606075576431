import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ExportedTrialContactableMember, TrialContactableMember} from '../models/trial-contactable-member';
import {Contact} from '../../shared/entity/contact';
import {RoleValue} from '../../human-resources/models/role-value';
import {IsDeletable} from '../../is-deletable';
import {DatePipe} from '@angular/common';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {Deletable} from '../../modals/delete-confirmation/deletable';
import {IgCsvExport} from '../../shared/entity/ig-csv-export';
import {ProviderTypeDto} from 'src/app/provider/models/provider-type-dto';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrialContactsService implements Deletable {

  public host = new Properties().host + '/trial-contactable-member';
  public trialHhhId: number;
  public trialContactableMembers: TrialContactableMember [] = [];

  constructor(
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private translateService: InnohealthTranslateService
  ) {
  }

  getTrialProviderTypesAndOthers(): Observable<ProviderTypeDto[]> {
    return this.httpClient.get<ProviderTypeDto[]>(this.host + '/load-provider-types');
  }

  getTrialContactableMembers(hhhId: number): Observable<TrialContactableMember[]> {
    return this.httpClient.get<TrialContactableMember[]>(this.host + '/load-list/' + hhhId)
      .pipe(map(result => {
        try {
          result = result.map(contact => new TrialContactableMember().init(contact));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );;
  }
  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

  getContacts(hhhId: number): Observable<Contact> {
    return this.httpClient.get<Contact>(this.host + '/contact-info/' + hhhId);
  }

  getContactsByPromoter(hhhId: number): Observable<RoleValue[]> {
    return this.httpClient.get<RoleValue[]>(this.host + '/load-list-by-provider/' + hhhId);
  }

  isContactableMemberInTrial(trialHhhId: number, contactableMemberHhhId: number): Observable<any> {
    return this.httpClient.get<any>(this.host + '/is-in-trial/' + trialHhhId + '/' + contactableMemberHhhId);
  }

  addContactToTrial(trialHhhId: number, contactableMemberHhhId: number, trialContactableMember: TrialContactableMember): Observable<TrialContactableMember> {
    return this.httpClient.post<TrialContactableMember>(this.host + '/save/' + trialHhhId + '/' + contactableMemberHhhId, trialContactableMember);
  }

  updateContact(trialHhhId: number, contactableMemberHhhId: number, trialContactableMember: TrialContactableMember): Observable<TrialContactableMember> {
    return this.httpClient.put<TrialContactableMember>(this.host + '/update/' + trialHhhId + '/' + contactableMemberHhhId, trialContactableMember);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(contactableMemberHhhId: number, trialHhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + trialHhhId + '/' + contactableMemberHhhId);
  }

  exportTrialContactList() {
    const trialConTactList = [];
    for (const trialContactableMember of this.trialContactableMembers) {
      const trialContactableMemberItem = new ExportedTrialContactableMember();
      trialContactableMemberItem.providerType = trialContactableMember.providerType;
      trialContactableMemberItem.provider = trialContactableMember.provider;
      trialContactableMemberItem.contact = trialContactableMember.contact;
      trialContactableMemberItem.function = trialContactableMember.function;
      trialConTactList.push(trialContactableMemberItem);
    }
    const date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const headers = [
      this.translateService.getTranslationString('MODULE_TRIALS_CONTACTABLE_MEMBERS_DIALOG_ADD_CONTACTABLE_MEMBER_TYPE_PROVIDER'),
      this.translateService.getTranslationString('MODULE_TRIALS_CONTACTABLE_MEMBERS_DIALOG_ADD_CONTACTABLE_MEMBER_PROVIDER'),
      this.translateService.getTranslationString('MODULE_TRIALS_CONTACTABLE_MEMBERS_DIALOG_ADD_CONTACTABLE_MEMBER'),
      this.translateService.getTranslationString('MODULE_CONTACTABLE_MEMBERS_LIST_FUNCTION')
    ];
    new IgCsvExport(trialConTactList, headers, 'sites-' + date).convertToCsvAndDownload();
  }
}

import {ExportRequest} from './export-request';
import {ExportTypesEnum} from '../exported/data-export/ExportTypesEnum';

export class ExportRequestBuilder<T> {

  _request: ExportRequest<T>;


  constructor() {
    this._request = new ExportRequest<T>();
  }

  page(page: number): ExportRequestBuilder<T> {
    this._request.page = page;
    return this;
  }

  limit(limit: number): ExportRequestBuilder<T> {
    this._request.limit = limit;
    return this;
  }

  sortBy(sortBy: string): ExportRequestBuilder<T> {
    this._request.sortBy = sortBy;
    return this;
  }

  secondSortBy(secondSortBy: string): ExportRequestBuilder<T> {
    this._request.secondSortBy = secondSortBy;
    return this;
  }

  orderBy(orderBy: string): ExportRequestBuilder<T> {
    this._request.orderBy = orderBy;
    return this;
  }

  filters(filters: T): ExportRequestBuilder<T> {
    this._request.filters = filters;
    return this;
  }

  objectId(hhhId: number): ExportRequestBuilder<T> {
    this._request.objectId = hhhId;
    return this;
  }

  condition(c: boolean): ExportRequestBuilder<T> {
    this._request.condition = c;
    return this;
  }

  secondCondition(secondCondition: boolean): ExportRequestBuilder<T> {
    this._request.secondCondition = secondCondition;
    return this;
  }

  type(type: string): ExportRequestBuilder<T> {
    this._request.type = type;
    return this;
  }

  fromDate(fromDate: string): ExportRequestBuilder<T> {
    this._request.fromDate = fromDate;
    return this;
  }

  toDate(toDate: string): ExportRequestBuilder<T> {
    this._request.toDate = toDate;
    return this;
  }

  contextId(contextId: number): ExportRequestBuilder<T> {
    this._request.contextId = contextId;
    return this;
  }

  socketId(socketId: string): ExportRequestBuilder<T> {
    this._request.socketId = socketId;
    return this;
  }

  target(target: string): ExportRequestBuilder<T> {
    this._request.target = target;
    return this;
  }

  exportType(type: ExportTypesEnum): ExportRequestBuilder<T> {
    this._request.exportType = type;
    return this;
  }

  headers(headers: string[]): ExportRequestBuilder<T> {
    this._request.headers = headers;
    return this;
  }

  paginationCriteria(event, headers: string[], limit: number, page: number): ExportRequestBuilder<T> {
    switch (event.exportType) {
      case ExportTypesEnum.FULL_EXPORT:
        this.headers(headers);
        this.page(0);
        this.limit(null);
        return this;
      case ExportTypesEnum.SINGLE_PAGE_EXPORT:
        this.headers(headers);
        this.limit(limit);
        this.page(page);
        return this;
      case ExportTypesEnum.CUSTOM_EXPORT:
      case ExportTypesEnum.GLOBAL_EXPORT:
      case ExportTypesEnum.SIGREC_EXPORT:
      case ExportTypesEnum.PIRAMIG_EXPORT:
        this.headers(event.headers);
        this.page(0);
        this.limit(null);
        return this;
      case ExportTypesEnum.GLOBAL_SINGLE_PAGE_EXPORT:
        this.headers(event.headers);
        this.page(page);
        this.limit(limit);
        return this;
    }
  }

  build(): ExportRequest<T> {
    return this._request;
  }

}

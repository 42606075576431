import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {VisitStatesService} from '../../trial/service/visit-states.service';
import {SelectItem} from 'primeng/api';
import {VisitService} from '../../trial/service/visit.service';
import {SharedService} from '../../shared/services/shared.service';
import {Data} from '../../shared/entity/data';
import {
  VisitStateUpdateConfirmationComponent
} from '../../trial/component/inclusions/inclusion-details/calendar-details/visit-state-update-confirmation/visit-state-update-confirmation.component';
import {Visit} from '../../trial/entity/visit';
import {VisitActService} from '../../trial/service/visit-act.service';
import {StateType} from '../../shared/entity/payment-type.enum';
import {SwitchState} from '../../trial/entity/inclusion/switch-state';
import {DataItemService} from '../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-modify-status-form',
  templateUrl: './modify-status-form.component.html',
  styleUrls: ['./modify-status-form.component.css']
})
export class ModifyStatusFormComponent implements OnInit {
  @ViewChild('visitStateUpdateConfirmationPopup', {read: ViewContainerRef}) visitStateUpdateContainer: ViewContainerRef;

  displayModifyVisitStatusDialog: boolean;
  callback: () => void;
  allVisitStates: SelectItem[] = [];
  visitStateHhhId: number;
  visitActState: { id: number, type: StateType };
  visitActProviderState: { id: number, type: StateType };
  oldVisitStateHhhId: number;
  from: string;

  constructor(
              private visitService: VisitService,
              private sharedService: SharedService,
              private cfr: ComponentFactoryResolver,
              private visitStatesService: VisitStatesService,
              private visitActService: VisitActService) {
  }

  ngOnInit() {
    this.displayModifyVisitStatusDialog = true;
    this.initVisitStates();
  }

  updateStatus() {
    this.visitService.getVisitsByVisitHhhId().subscribe(visits => {
      this.displayModifyVisitStatusDialog = false;
      visits.forEach(visit => {
        this.tryVisitActStateChange(visit);
      });
      this.visitService.initVisitListToBeUpdated();
    });
  }

  tryVisitActStateChange(visit: Visit) {
    this.oldVisitStateHhhId = visit.visitStateHhhId;
    visit.visitStateHhhId = this.visitStateHhhId;
    const visitActHhhIds = [];
    const stateSwitch = new SwitchState(visit.hhhId, StateType.PROVIDER_PAYMENT, this.oldVisitStateHhhId, this.visitStateHhhId);
    this.visitService.isVisitStateSwitchable(stateSwitch).subscribe(isStateSwitchable => {
      if (isStateSwitchable.switchable) {
        if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_PAID') {
          this.visitActState = {id: Data.visitActPaymentStates.PAID, type: StateType.PROMOTER_PAYMENT};
          this.visitActProviderState = {id: Data.visitActPaymentStates.PAID, type: StateType.PROVIDER_PAYMENT};
          visit.visitActs.forEach(visitAct => {
            visitActHhhIds.push(visitAct.hhhId);
          });
          this.updateVisitActState(StateType.PROMOTER_PAYMENT, visit, visitActHhhIds);
          this.updateVisitActState(StateType.PROVIDER_PAYMENT, visit, visitActHhhIds);
        }
        this.updateVisitState(visit);
      } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_COMPLETED') {
        this.createVisitStateUpdateConfirmationComponent('COMPLETED', visit);
      } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED') {
        this.createVisitStateUpdateConfirmationComponent('INVOICED', visit);
      } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_PAID_VISIT') {
        this.createVisitStateUpdateConfirmationComponent('INVOICED_FROM_PAID_VISIT', visit);
      } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_INVOICED_VISIT') {
        this.createVisitStateUpdateConfirmationComponent('INVOICED_FROM_INVOICED_VISIT', visit);
      } else if (isStateSwitchable.why === 'CANT_SWITCH_TO_UNINVOICED') {
        this.createVisitStateUpdateConfirmationComponent('CANT_SWITCH_TO_UNINVOICED', visit);
      } else if (isStateSwitchable.why === 'ONLY_FINANCE_USERS_CAN_SWITCH_STATE') {
        this.sharedService.showFailure('MODULE_TRIAL_PATIENT_CALENDAR_ONLY_FINANCE_USERS_CAN_SWITCH_STATE');
      } else if (isStateSwitchable.why === 'AOP_EMPTY') {
        this.sharedService.showFailure('MODULE_TRIAL_PATIENT_CALENDAR_ONLY_FOR_NO_EMPTY_AOP_STATE_IS_SWITCHED');
      }
    });
  }

  createVisitStateUpdateConfirmationComponent(reason: string, visit: Visit) {
    const popupDialogFactory = this.cfr.resolveComponentFactory(VisitStateUpdateConfirmationComponent);
    const popupDialogComponentRef = this.visitStateUpdateContainer.createComponent(popupDialogFactory);
    const visitStateUpdateConfirmationComponent = popupDialogComponentRef.instance;
    visitStateUpdateConfirmationComponent.visit = visit;
    visitStateUpdateConfirmationComponent.reason = reason;
    visitStateUpdateConfirmationComponent.from = 'TRACKING_TABLE';
    visitStateUpdateConfirmationComponent.oldVisitStateHhhId = this.oldVisitStateHhhId;
    visitStateUpdateConfirmationComponent.from = this.from;
    visitStateUpdateConfirmationComponent.updatedVisitActsRealisationStateEvent.subscribe(actRealisationStateHhhId => {
      visit.visitActs.forEach(visitAct => visitAct.realisationStateHhhId = actRealisationStateHhhId);
    });
    visitStateUpdateConfirmationComponent.updatedVisitActsPaymentStateEvent.subscribe(actPaymentStateHhhId => {
      visit.visitActs.forEach(visitAct => {
        if (visitAct.invoiceable) {
          visitAct.paymentStateHhhId = actPaymentStateHhhId;
        }
      });
    });
    visitStateUpdateConfirmationComponent.visitStateUpdatedEvent.subscribe(() => {
      this.visitService.initVisitListToBeUpdated();
      this.callback();
    });
  }

  updateVisitState(visit: Visit) {
    const switchState = new SwitchState(visit.hhhId, null, this.oldVisitStateHhhId, this.visitStateHhhId);
    this.visitService.updateVisitAndActsState(switchState)
      .subscribe(res => {
        visit.visitStateName = res;
        this.callback();
      });
  }

  updateVisitActState(stateType: StateType, visit: Visit, visitActHhhIds: any[]) {
    this.visitActService.updateVisitActsState(visitActHhhIds, stateType, this.visitActState.id)
      .subscribe(() => {
        visit.visitActs.forEach(visitAct => {
          if (visitActHhhIds.indexOf(visitAct.hhhId) !== -1) {
            if (this.visitActState.type === StateType.REALISATION) {
              if (this.canSwitch(visitAct)) {
                visitAct.realisationStateHhhId = this.visitActState.id;
              }
            } else {
              visitAct.paymentStateHhhId = this.visitActState.id;
            }
          }
          visitAct.checked = false;
        });
        this.visitActState = null;
      });
  }

  initVisitStates() {
    this.visitStatesService.getVisitStates(result => {
      // remove the partially paid and the partially invoiced
      result.filter(res => res.value !== 4 && res.value !== 2).forEach(s => {
        this.allVisitStates.push({
          label: s.label,
          value: s.value,
          disabled: false
        });
      });
    });
  }

  onEdit(callback: () => void) {
    this.callback = callback;
  }

  private canSwitch(visitAct) {
    const pending = 1;
    const canSwitchToUnrealised: boolean = visitAct.paymentStateHhhId === pending;
    const canSwitchToRealised: boolean = this.visitActState.id === 2;
    return canSwitchToUnrealised || canSwitchToRealised;
  }
}

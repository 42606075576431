import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {EstablishmentDepartment} from '../../shared/entity/establishment-department';
import {EstablishmentService} from '../services/establishment.service';
import {SharedService} from '../../shared/services/shared.service';
import {EstablishmentObservablesService} from '../services/establishment-observables.service';
import { EstablishmentDepartmentService } from '../services/establishment-department.service';

@Component({
  selector: 'ih-service-edit-form',
  templateUrl: './service-edit-form.component.html',
  styleUrls: ['./service-edit-form.component.css']
})
export class ServiceEditFormComponent implements OnInit {

  @ViewChild('serviceValidationForm') serviceValidationForm: NgForm;

  public service: EstablishmentDepartment = new EstablishmentDepartment();
  public submitted: boolean;
  public displayService: boolean;
  public establishmentEditRight;

  constructor(
    private sharedService: SharedService,
    private establishmentObservablesService: EstablishmentObservablesService,
    private establishmentDepartmentService:EstablishmentDepartmentService
  ) {
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ServiceEditFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.submitted = false;
    this.displayService = true;
  }

  updateService() {
    this.submitted = true;
    if (this.serviceValidationForm.invalid) {
      return;
    }
    this.establishmentDepartmentService.updateEstablishmentDepartment(this.service).subscribe(() => {
      this.sharedService.showSuccess();
      this.sharedService.setInformationInLocalStorage('Services', 'Éditer', this.service.name);
      this.establishmentObservablesService.onEstablishmentDepartmentEdit.emit();
    }, error => {
      this.sharedService.showFailure(error.error);
      console.error(error);
    });
    this.displayService = false;
  }

}

import {DynamicFormDefinitionField} from './dynamic-form-definition-field';

export class DynamicFormDefinitionFieldset {

  parent: DynamicFormDefinitionField = new DynamicFormDefinitionField();
  children: DynamicFormDefinitionField [] = [];


  constructor(parent: DynamicFormDefinitionField) {
    this.parent = parent;
  }
}

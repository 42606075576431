export class Email {
  hhhId: any;
  type: string;
  value: string;
  position: any;
  contactSetHhhId: any;
  humanResourceHhhId: any;
  constructor() {
    this.position = 1;
  }
}

import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {DocumentViewer} from '../../entity/document-viewer';
import {DataDocumentService} from '../../service/data-document.service';
import {TranslateService} from '@ngx-translate/core';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {FolderFormComponent} from '../../../modals/folder-form/folder-form.component';
import {AttachedToEntityEnum} from '../../../data-comment/entity/attached-to-entity.enum';
import * as Util from 'util';
import {isNullOrUndefined} from 'util';
import {DataDocumentList} from '../../entity/data-document-list';
import {TreeNode} from 'primeng/api';
import {FileFormComponent} from '../../../modals/file-form/file-form.component';
import {SharedService} from '../../../shared/services/shared.service';
import {DeleteFileOrFolderConfirmationComponent} from '../../../trial/component/modals/delete-file-or-folder-confirmation/delete-file-or-folder-confirmation.component';
import {DynamicTableHeader} from '../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'ih-data-document',
  templateUrl: './data-document.component.html',
  styleUrls: ['./data-document.component.css']
})
export class DataDocumentComponent implements OnInit {

  @ViewChild('addFolderDialog', {read: ViewContainerRef}) addFolderDialogueContainer: ViewContainerRef;
  @ViewChild('fileViewerDialog', {read: ViewContainerRef}) fileViewerDialog: ViewContainerRef;
  @ViewChild('deleteFolderOrFileDialog', {read: ViewContainerRef}) deleteFolderOrFileDialog: ViewContainerRef;

  readonly AttachedToEntityEnum = AttachedToEntityEnum;
  protected readonly isNullOrUndefined = Util.isNullOrUndefined;
  public cols: any[];
  public displayShowFileDialog = false;
  public documentId: number;
  public parentFolder: number;
  public targetFolder: number;
  public dataDocumentList: DataDocumentList;
  public treeFileList: TreeNode[] = [];

  _attachedTo: AttachedToEntityEnum;
  @Input() attachedToId: number;


  @Input() condition: boolean;

  type: string;
  fileName: string;
  imageToShow: any;
  pdfReader: string;
  documentType: string;
  docFile: string | ArrayBuffer;

  showSchemaSection = false;
  trialSchemaDocument: any;

  constructor(
    public dataDocumentService: DataDocumentService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private translateService: InnohealthTranslateService,
    private datePipe: DatePipe,
    private cfr: ComponentFactoryResolver
  ) {
  }
  ngOnInit() {
    this.translate.stream('MODULE_TRIALS_FIELD_ORGAN').subscribe(() => {
        this.extractFileColumnList();
      }
    );
    this.loadTrialSchemaDocument();
  }
  @Input('attachedTo')
  set attachedTo(v: AttachedToEntityEnum) {
    if (isNullOrUndefined(v)) {
      return;
    }
    this._attachedTo = v;
    if (v === AttachedToEntityEnum.Trial) {
      this.showSchemaSection = true;
    }
  }

  get attachedTo(): AttachedToEntityEnum {
    return this._attachedTo;
  }

  loadTrialSchemaDocument() {
    if (this.attachedTo === AttachedToEntityEnum.Trial) {
      this.dataDocumentService.loadTrialSchemaDocument(AttachedToEntityEnum.TrialSchema, this.attachedToId).subscribe((res) => {
        this.trialSchemaDocument = res;
      });
    }
  }

  extractFileColumnList() {
    this.cols = [
      {
        field: 'name', header: this.translateService.getTranslationString(
          'MODULE_TRIALS_FILES_FSTABLE_HEADER_NAME')
      },
      {
        field: 'ownerName', header: this.translateService.getTranslationString(
          'MODULE_TRIALS_FILES_FSTABLE_HEADER_AUTHOR')
      },
      {
        field: 'creationDate', header: this.translateService.getTranslationString(
          'MODULE_TRIALS_FILES_FSTABLE_HEADER_DATE')
      }
    ];
  }

  displayContent(colField: any, rowData: any): string {
    if (colField === 'creationDate') {
      return this.datePipe.transform(rowData[colField], 'dd/MM/yyyy HH:mm');
    }
    return rowData[colField];
  }

  loadNodes(event: any) {
    this.loadList(null, event);
  }

  onNodeExpand(event: any) {
    const root = event.node.data.hhhId;
    this.loadList(root, event);
  }

  dragStart(event, rowData: any) {
    if (rowData.type === 'folder') {
      this.documentId = null;
    } else {
      this.documentId = rowData.hhhId;
      if (rowData.parent) {
        this.parentFolder = rowData.parent.hhhId;
      }
    }
  }

  dragEnd(rowData: any) {
    this.documentId = null;
    this.parentFolder = null;
    this.targetFolder = null;
  }

  drop(event, rowData) {
    if (rowData.type !== 'folder' || this.documentId === null) {
      return;
    } else {
      this.dataDocumentService.moveDocument(this.attachedToId, this.documentId, rowData.hhhId).subscribe(() => {
        this.sharedService.showSuccess();
        this.loadList(null, event);
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  loadList(root: any, event: any) {
    this.dataDocumentService.loadList(this.attachedTo, this.attachedToId, root).subscribe(res => {
      this.dataDocumentList = res;
      if (root === null) {
        this.treeFileList = [];
        if (this.dataDocumentList.folders) {
          event.first = this.dataDocumentList.folders[0];
          for (const folder of this.dataDocumentList.folders) {
            const node = {
              data: {
                hhhId: folder.hhhId,
                name: !isNullOrUndefined(folder.name) ? folder.name : '',
                parent: null,
                ownerName: folder.ownerName,
                creationDate: folder.creationDate,
                deletable: folder.deletable,
                type: 'folder',
                draggable: true,
                droppable: true
              },
              children: [],
              leaf: false
            };
            this.treeFileList.push(node);
          }
        }
        if (this.dataDocumentList.documents) {
          event.first = this.dataDocumentList.documents[0];
          for (const document of this.dataDocumentList.documents) {
            const node = {
              data: {
                hhhId: document.hhhId,
                name: !isNullOrUndefined(document.name) ? document.name : '',
                parent: null,
                uuid: document.uuid,
                ownerName: document.ownerName,
                creationDate: document.creationDate,
                deletable: document.deletable,
                type: 'file',
                draggable: true,
                droppable: true
              },
              children: [],
              leaf: false
            };
            this.treeFileList.push(node);
          }
        }
      } else {
        if (!event.node.children || event.node.children.length === 0) {
          if (this.dataDocumentList.folders) {
            event.first = this.dataDocumentList.folders[0];
            for (const folder of this.dataDocumentList.folders) {
              const node = {
                data: {
                  hhhId: folder.hhhId,
                  name: !isNullOrUndefined(folder.name) ? folder.name : '',
                  parent: folder.parent,
                  ownerName: folder.ownerName,
                  creationDate: folder.creationDate,
                  deletable: folder.deletable,
                  type: 'folder',
                  draggable: true,
                  droppable: true
                },
                children: [],
                leaf: false
              };
              event.node.children.push(node);
            }
          }
          if (this.dataDocumentList.documents) {
            event.first = this.dataDocumentList.documents[0];
            for (const document of this.dataDocumentList.documents) {
              const node = {
                data: {
                  hhhId: document.hhhId,
                  name: !isNullOrUndefined(document.name) ? document.name : '',
                  parent: document.parent,
                  uuid: document.uuid,
                  ownerName: document.ownerName,
                  creationDate: document.creationDate,
                  deletable: document.deletable,
                  type: 'file',
                  draggable: true,
                  droppable: true
                },
                children: [],
                leaf: false
              };
              event.node.children.push(node);
            }
          }
        }
      }
      this.treeFileList = [...this.treeFileList];
    });
  }

  openDocument(document: any): void {
    const type = DocumentViewer.getDocumentType(document.name);
    this.dataDocumentService.previewDocument(document.uuid).subscribe(
      blob => {
        const file = new File([blob], document.name, {type: DocumentViewer.getMimeType(document.name, type)});
        this.readDocument(file, type);
      });
  }

  private readDocument(file: File, type: string): void {
    const reader = new FileReader();
    this.fileName = file.name;
    let doc: any;
    if (type === 'pdf') {
      doc = new Blob([file], {type: DocumentViewer.getMimeType(file.name, type)});
      this.pdfReader = window.URL.createObjectURL(doc);
      this.documentType = 'pdf';
    } else {
      if (type === 'image') {
        this.documentType = 'image';
        reader.readAsDataURL(file);
      } else {
        this.documentType = '';
        reader.readAsText(file);
      }
      reader.onload = () => {
        this.imageToShow = reader.result;
        this.docFile = reader.result;
      };
    }
    this.displayShowFileDialog = true;
  }

  displayFolderDialogue(root?: any) {
    const addPopupFactory = this.cfr.resolveComponentFactory(FolderFormComponent);
    const addPopupComponentRef = this.addFolderDialogueContainer.createComponent(addPopupFactory);
    const folderFormComponent = addPopupComponentRef.instance;
    folderFormComponent.root = root;
    folderFormComponent.hhhId = this.attachedToId;
    folderFormComponent.attachedTo = this.attachedTo;
    folderFormComponent.onAdd(() => {
      this.loadList(isNullOrUndefined(root) ? null : root.hhhId, event);
    });
  }

  displayFileDialogue(root?: any) {
    const addPopupFactory = this.cfr.resolveComponentFactory(FileFormComponent);
    const addPopupComponentRef = this.addFolderDialogueContainer.createComponent(addPopupFactory);
    const fileFormComponent = addPopupComponentRef.instance;
    fileFormComponent.attachedTo = this.attachedTo;
    fileFormComponent.hhhId = this.attachedToId;
    fileFormComponent.onAdd(() => {
      this.loadList(null, event);
    });
  }

  downloadFile(uuid: string, fileName: string) {
    this.dataDocumentService.downloadDocument(uuid, fileName).subscribe();
  }

  displayDeleteFileOrFolderDialog(hhhId: number, type: string) {
    const deleteConfirmationComponent = DeleteFileOrFolderConfirmationComponent.displayFormDialogue(this.deleteFolderOrFileDialog, this.cfr);
    deleteConfirmationComponent.documentId = hhhId;
    deleteConfirmationComponent.type = type;
    deleteConfirmationComponent.attachedToId = this.attachedToId;
    deleteConfirmationComponent.onAdd((event) => {
      this.loadList(null, event);
    });
  }

  initialize() {
    this.displayShowFileDialog = false;
    this.documentType = '';
    this.imageToShow = '';
    this.pdfReader = '';
  }
  uploadDocument(event: any) {
    this.dataDocumentService.uploadDocument(AttachedToEntityEnum.TrialSchema, this.attachedToId, event.files[0]).subscribe(res => {
      this.loadTrialSchemaDocument();
      this.sharedService.showSuccess();
    });
  }

  deleteDocument(documentId: number) {
    this.dataDocumentService.deleteDocument(documentId).subscribe(() => {
      this.trialSchemaDocument = undefined;
    });
  }

}

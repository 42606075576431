import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'ih-pharmacy-informations-add-dose-form',
  templateUrl: './pharmacy-add-one-field-form.component.html',
  styleUrls: ['./pharmacy-add-one-field-form.component.css']
})

export class PharmacyAddOneFieldFormComponent extends FormComponent<string> implements OnInit {


  constructor() {
    super();
  }

  @ViewChild('doseForm') doseForm: NgForm;

  doseValueValue: any;


  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(PharmacyAddOneFieldFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  submitDose(doseValue) {
    this.submitted = true;
    if (this.doseForm.invalid) {
      return;
    }
    this.addDose(doseValue.value);
    this.display = false;
  }

  addDose(value) {
    this.callback(value);
  }
}

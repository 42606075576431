import {DataDocument} from '../../data-document/entity/data-document';

export class Trial {
  hhhId: number;
  name: string;
  state: string;
  protocolNumber: string;
  promoter: string;
  principalInvestigatorHhhId: number;
  principalInvestigator: string;
  principalInvestigatorEmail: string;
  phase: string;
  phaseHhhId: number;
  organs: string;
  organsOnly: string;
  organFamiliesOnly: string;
  organHhhIds: number[];
  disabled: boolean;
  pv: string;
  treatmentLine: string;
  title: string;
  actualProductionLaunch: Date;
  clinicalTrialNumber: string;
  numberOfUnseenMessages: number;
  trialObjectives: string;
  inclusionExclusionCriteria: string;
  trialSchema: DataDocument;
  lastProtocolName: string;
  lastProtocolHhhId: number;
  trialInclusionExclusionCriteriaHhhId: number;
  minimalAge: number;
  maximalAge: number;
  omsEcog: Array<number> = new Array<number>();
  illnessStates: Array<number> = new Array<number>();
  numberOfPreviousTreatmentLines: string;
  inclusionOrganList: Array<string> = new Array<string>();
  biopsiesRequired: boolean;
  biopsies: Array<number> = new Array<number>();
  inclusionHistologists: Array<number> = new Array<number>();
  otherSpecificCriteria: string;
  inclusionBiomarkers: Array<number> = new Array<number>();
  molecularAnomalyType: Array<number> = new Array<number>();
  molecularAnomalyTypeStringified: string;
  preTreatmentNotAuthorized: string;
  otherExclusionCriteria: Array<number> = new Array<number>();
  otherSpecificCriteriaSingle: string;
  inclusionExclusionComments: string;
  cohorts: string ;

  copy(trial: Trial): Trial {
    this.hhhId = trial.hhhId;
    this.protocolNumber = trial.protocolNumber;
    this.principalInvestigatorHhhId = trial.principalInvestigatorHhhId;
    this.principalInvestigator = trial.principalInvestigator;
    this.principalInvestigatorEmail = trial.principalInvestigatorEmail;
    this.organHhhIds = trial.organHhhIds;
    this.title = trial.title;
    if (trial.actualProductionLaunch) {
      this.actualProductionLaunch = new Date(trial.actualProductionLaunch);
    }
    this.clinicalTrialNumber = trial.clinicalTrialNumber;
    this.trialObjectives = trial.trialObjectives;
    this.inclusionExclusionCriteria = trial.inclusionExclusionCriteria;
    this.phase = trial.phase;
    this.phaseHhhId = trial.phaseHhhId;
    this.trialSchema = trial.trialSchema;
    this.lastProtocolName = trial.lastProtocolName;
    this.lastProtocolHhhId = trial.lastProtocolHhhId;
    this.trialInclusionExclusionCriteriaHhhId = trial.trialInclusionExclusionCriteriaHhhId;
    this.minimalAge = trial.minimalAge;
    this.maximalAge = trial.maximalAge;
    this.omsEcog = trial.omsEcog;
    this.illnessStates = trial.illnessStates;
    this.numberOfPreviousTreatmentLines = trial.numberOfPreviousTreatmentLines;
    this.inclusionOrganList = trial.inclusionOrganList;
    this.biopsiesRequired = trial.biopsiesRequired;
    this.biopsies = trial.biopsies;
    this.inclusionHistologists = trial.inclusionHistologists;
    this.otherSpecificCriteria = trial.otherSpecificCriteria;
    this.inclusionBiomarkers = trial.inclusionBiomarkers;
    this.molecularAnomalyType = trial.molecularAnomalyType;
    this.molecularAnomalyTypeStringified = trial.molecularAnomalyTypeStringified;
    this.preTreatmentNotAuthorized = trial.preTreatmentNotAuthorized;
    this.otherExclusionCriteria = trial.otherExclusionCriteria;
    this.otherSpecificCriteriaSingle = trial.otherSpecificCriteriaSingle;
    this.inclusionExclusionComments = trial.inclusionExclusionComments;
    this.cohorts = trial.cohorts;
    this.treatmentLine = trial.treatmentLine;
    this.organs = trial.organs;
    this.organsOnly = trial.organsOnly;
    this.organFamiliesOnly = trial.organFamiliesOnly;
    return this;
  }
  init(obj: any): Trial {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}

import {ChangeDetectorRef, Component, ComponentFactoryResolver, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Data} from '../../../../shared/entity/data';
import {Inclusion} from '../../../entity/inclusion/inclusion';
import {TrialHead} from '../../../models/trial-head';
import {FR} from '../../../../shared/entity/calendar-language';
import {SelectItem} from 'primeng/api';
import {InclusionDetailService} from '../../../service/inclusion-detail.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {OrganService} from '../../../services/organ.service';
import {DataService} from '../../../../shared/services/data-service';
import {TrialService} from '../../../services/trial.service';
import {Router} from '@angular/router';
import {ArmService} from '../../../services/arm.service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {isNullOrUndefined} from 'util';
import {LabelValue} from '../../../../shared/entity/label-value';
import {ConsentmentFormComponent} from '../consentment-form/consentment-form.component';
import {OtherConsent} from '../../../../other-consent';
import {DatePipe} from '@angular/common';
import {BlocFieldsComponent} from '../../../../modals/bloc-date-form/bloc-fields.component';
import {BlocFields} from '../../../../bloc-fields';
import {InclusionCrossover} from '../../../entity/inclusion/InclusionCrossover';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {AppData} from '../../../../helpers/app-data';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {DataItemService} from '../../../../custom-field/service/data-item.service';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';
import {forkJoin, Observable, of} from 'rxjs';
import {Arm} from '../../../entity/inclusion/arm';

@Component({
  selector: 'ih-inclusion-information-version-protocol',
  templateUrl: './inclusion-information-version-protocol.component.html',
  styleUrls: ['./inclusion-information-version-protocol.component.css']
})
export class InclusionInformationVersionProtocolComponent implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.TRIAL_INCLUSION_INFORMATION_PROTOCOL_VERSION_FORM;
  config: FormConfig = new FormConfig();
  isDependsOnMolecularPreScreening = false;
  isMultipleCrossoversEnabled = false;
  ghtMontBlancCustomizationEnabled= false;

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('inclusionForm') inclusionForm: NgForm;
  @ViewChild('displayWarningDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;

  escalationExtensions = Data.escalationExtensionForDesign;

  submitted = false;
  inclusion: Inclusion;
  trialHhhId: number;
  trialHead: TrialHead = new TrialHead(null);
  inclusionId: number;
  locale = FR;
  selectedDoctors: number[] = [];
  countOtherConsent: number;
  public organsList: { label, value }[] = [];
  originalOrganList: string[] = [];
  maxSelectedLabels: number;
  countBlocDates: number;
  noYesOptions: SelectItem[] = Data.yesOrNo;
  disableRelatedDesignFields = false;
  disableRelatedDesignCrossoverFields = false;
  isTrialHasNotMolecularPreScreening: boolean;
  designs: Array<SelectItem> = new Array<SelectItem>();
  negatifPositifOptions: SelectItem[] = Data.positiveOrNegative;
  isOtherCstDisabled: boolean;
  followUpMotifs: SelectItem[] = Data.followUpMotif;
  allTecInIncludedTec = false;

  crossovers: InclusionCrossover[] = [];
  public caseManagements: LabelValue[];
  constructor(
    private inclusionService: InclusionDetailService,
    private cfr: ComponentFactoryResolver,
    private sharedService: SharedService,
    private organService: OrganService,
    private dataService: DataService,
    public trialService: TrialService,
    private router: Router,
    private armService: ArmService,
    private dataItemService: DataItemService,
    private canDeactivateService: CanDeactivateService,
    public dynamicConfigService: DynamicConfigService,
    private _cdr: ChangeDetectorRef
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
      this.inclusionId = url.queryParams.inclusionId;
    }
    this.canDeactivateService.canBeDeactivated = true;
  }

  get form() {
    return this.inclusionForm.controls;
  }

  ngOnInit() {
    this.trialService.setTrialReadonlyMode(this.trialHhhId);
    this.inclusionService.getTrialHasMolecularPreScreening(this.trialHhhId).subscribe(
      res => {
        if (res === false) {
          this.isTrialHasNotMolecularPreScreening = true;
        }
      }
    );
    this.initProperties();
    this.getTrialHead();
    this.dataService.setExportPageModule('src/app/trial/inclusions/inclusion-details/inclusion-information/inclusion-information.component');
    this.inclusionService.setTrialAndInclusionId(this.trialHhhId);
    this.getInclusionInformation();
    this.getOrganList();

  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isDependsOnMolecularPreScreening = this.dynamicConfigService.getProperty(PropertyEnum.dependsOnMolecularPreScreening);
      this.isMultipleCrossoversEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multipleCrossoversEnabled);
      this.ghtMontBlancCustomizationEnabled = this.dynamicConfigService.getProperty(PropertyEnum.ghtMontBlancCustomizationEnabled);
      this.allTecInIncludedTec = this.dynamicConfigService.getProperty(PropertyEnum.allTecInIncludedTec)
      this._cdr.detectChanges();
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
    this.trialService.readOnlyMode = false;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  getInclusionInformation() {
    this.inclusionService.getInclusionInformation(this.inclusionId).subscribe(res => {
      this.inclusion = res;
      this.getCaseManagementSelectedByTrial();
      if (this.inclusion.patientDeathDate != null && this.inclusion.inclusionTrialEndDate.date === null) {
        this.inclusion.inclusionTrialEndDate.date =this.inclusion.patientDeathDate;
        this.inclusion.inclusionTrialEndReasonHhhId =2;
      }
      this.isOtherCstDisabled = isNullOrUndefined(this.inclusion.consentHhhId);
      this.originalOrganList = this.inclusion.organList;
      if (this.inclusion.trialSiteHhhId === 0) {
        this.inclusion.trialSiteHhhId = null;
      }
      // if (this.inclusion.patientDeathDate) {
      //   this.inclusion.inclusionTrialEndDate.date = this.inclusion.patientDeathDate;
      // }
      this.crossovers = [...this.inclusion.crossovers];
      this.crossovers.push(new InclusionCrossover());

      this.inclusionService.setInclusionInformationToExport(res);
      this.getDesigns();

    });
  }

  getDesigns() {
    forkJoin([
      this.dataItemService.getItems('active-arm-having-theoretical-calendar-by-consent|' + this.trialHhhId + '|' + (this.inclusion.consentHhhId ? this.inclusion.consentHhhId : 0)),
      (this.inclusion.armHhhId && this.inclusion.armHhhId !== 0) ? this.armService.getArmById(this.inclusion.armHhhId) : of<Arm>(void 0),
      this.inclusion.arm2HhhId ? this.armService.getArmById(this.inclusion.arm2HhhId) : of<Arm>(void 0)
    ]).subscribe( ([arms, inclusionArm1, inclusionArm2]) => {
      if (inclusionArm1 && !arms.some(arm => arm.value === inclusionArm1.hhhId)) {
        arms.push(new LabelValue(inclusionArm1.getNameWithProtocolVersion(), inclusionArm1.hhhId));
      }
      if (inclusionArm2 && !arms.some(arm => arm.value === inclusionArm2.hhhId)) {
        arms.push(new LabelValue(inclusionArm2.getNameWithProtocolVersion(), inclusionArm2.hhhId));
      }
      this.mergeDesigns(arms);
      if (this.inclusion.armHhhId && !this.designs.find(lb => lb.value === this.inclusion.armHhhId)) {
        this.sharedService.showDeleteFailure('INCOMPATIBLE_DESIGN_VERSION');
        this.inclusion.armHhhId = null;
      }
      if (this.inclusion.arm2HhhId && !this.designs.find(lb => lb.value === this.inclusion.arm2HhhId)) {
        this.sharedService.showDeleteFailure('INCOMPATIBLE_DESIGN_VERSION');
        this.inclusion.arm2HhhId = null;
      }
    });
  }

  getOrganList() {
    this.organService.getTrialOrgansAndOrganFamilies(this.trialHhhId).subscribe(
      res => {
        this.organsList = res;
        this.maxSelectedLabels = res.length;
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading organ list.' + error);
      }
    );
  }

  getTrialHead() {
    this.trialService.getTrialHead(this.trialHhhId).subscribe(res => {
      this.trialHead = res;
    });
  }

  updateInclusion() {

    if (!this.areConsentsValid()) {
      this.sharedService.showWarning('CANNOT_SAVE_WITHOUT_INITIAL_CONSENT');
      return;
    }
    if ((this.inclusion.trialTreatmentStart != null && this.inclusion.trialTreatmentStart.date != null
      || this.inclusion.randomization != null && this.inclusion.randomization.date != null) && (this.inclusion.armHhhId == null || this.inclusion.armHhhId === 0)) {
      this.sharedService.showWarning('CANNOT_SAVE_WITH_TREATMENT_START_DATE_AND_WITHOUT_DESIGN');
      return;
    }

    this.submitted = true;
    if (this.trialHead.multiSite) {
      if (this.inclusion.trialSiteHhhId === 0) {
        return;
      }
    }
    if (!this.inclusion.isValid(this.config,this.trialHead.multiSite)) {
      return;
    }
    this.inclusion.trialHhhId = this.trialHhhId;
    this.inclusion.hhhId = this.inclusionId;
    this.updateInclusionOrganInfos();
    this.updateScreeningFailureInfos();
    this.inclusion.crossovers = this.crossovers.filter((crossover: InclusionCrossover) => crossover.hasCrossoverDate);
    this.inclusionService.updateInclusion(this.inclusion).subscribe(updatedStateHhhId => {
      this.isOtherCstDisabled = isNullOrUndefined(this.inclusion.consentHhhId);
      this.inclusion.stateHhhId = updatedStateHhhId;
      if (this.crossovers.filter((crossover: InclusionCrossover) => !crossover.hasCrossoverDate).length === 0) {
        this.crossovers.push(new InclusionCrossover());
      }
      this.sharedService.showSuccess();
      this.canDeactivateService.canBeDeactivated = true;
      this.dataService.changeInclusionMenu('information-calendar-patientTracking');
    }, error => {
      if (error.status === 409) {
        this.sharedService.showFailure('GENERAL_LINGUISTICS_CHANGING_ARM_FAIL');
      } else {
        this.sharedService.showFailure();
        console.error(error);
      }
    });
  }

  areConsentsValid(): boolean {
    return !(this.inclusion.otherConsentDates && this.inclusion.otherConsentDates.length > 0
      && (this.inclusion.consentHhhId == null || this.inclusion.initialConsentSignature == null
        || this.inclusion.initialConsentSignature.date == null));
  }

  updateScreeningFailureInfos(): void {
    if (!this.inclusion.preScreenFailure) {
      this.inclusion.preScreeningFailureDate.date = null;
      this.inclusion.preScreeningFailureDate.checked = false;
      this.inclusion.preScreeningFailureReason = null;
    }
  }

  displayConsentDates() {
    if (this.isOtherCstDisabled) {
      this.sharedService.showWarning('MODULE_TRIALS_NO_INITIAL_CONSENT_FOUND');
    } else {
      const showPopupFactory = this.cfr.resolveComponentFactory(ConsentmentFormComponent);
      const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
      const consentComponent = showPopupComponentRef.instance;
      consentComponent.trialHhhId = this.trialHhhId;
      consentComponent.inclusionHhhId = this.inclusion.hhhId;
      consentComponent.initialConsentId = this.inclusion.consentHhhId;
      consentComponent.onAdd((newConsent: OtherConsent) => {
        this.countOtherConsent = consentComponent.countOtherConsent;
        const otherConsents = consentComponent.amendmentListOfOtherConsent;
        const datePipe = new DatePipe('fr');
        let otherConsentDates = '';
        for (let i = 0; i < otherConsents.length; i++) {
          if (i > 0) {
            otherConsentDates += ' - ';
          }
          otherConsentDates += datePipe.transform(otherConsents[i].date, 'dd/MM/yyyy');
        }
        this.inclusion.otherConsentDates = otherConsentDates;
      });
    }
  }

  displayBlocDates() {
    const showPopupFactory = this.cfr.resolveComponentFactory(BlocFieldsComponent);
    const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
    const consentComponent = showPopupComponentRef.instance;
    consentComponent.inclusionHhhId = this.inclusion.hhhId;
    consentComponent.onAdd((newblocDate: BlocFields) => {
      this.countBlocDates = consentComponent.countBlocFields;
    });
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.inclusion.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.Inclusion, this.inclusion.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  goToPatientPage() {
    if (!isNullOrUndefined(this.inclusion.patientInternalIdentifier)) {
      this.router.navigateByUrl('/patient-details?hhhId=' + this.inclusion.patientHhhId).then();
    }
  }

  displaySelectedItems(selectedValues) {
    this.selectedDoctors = selectedValues;
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.inclusion.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  getTrialDesignCalendarAndSetData(armId: number) {
    if (armId !== null && armId !== 0) {
      this.armService.getArmById(armId).subscribe(result => {
        if (result.phase && result.phase.phase) {
          this.inclusion.phase = result.phase.phase.hhhId;
        }

        if (result.escaladeExtension && result.escaladeExtension.escaladeExtension) {
          this.inclusion.escalationExtension = Data.escalationExtensionNumberValues[result.escaladeExtension.escaladeExtension];
        }
        if (result.cohort && result.cohort.cohort) {
          this.inclusion.cohort = result.cohort.cohort.id;
        }
        if (result.inclusionArm && result.inclusionArm.inclusionArm) {
          this.inclusion.inclusionArmHhhId = result.inclusionArm.inclusionArm.hhhId;
        }
        this.disableRelatedDesignFields = true;
      });
    } else {
      this.inclusion.phase = 0;
      this.inclusion.escalationExtension = null;
      this.inclusion.cohort = 0;
      this.disableRelatedDesignFields = false;
    }
  }

  getTrialDesignCalendarAndSetCrossoverFieldsData(armId: number) {
    if (armId !== null && armId !== 0) {
      this.armService.getArmById(armId).subscribe(result => {
        if (result.phase && result.phase.phase) {
          this.inclusion.crossoverPhase = result.phase.phase.hhhId;
        }
        if (result.escaladeExtension && result.escaladeExtension.escaladeExtension) {
          this.inclusion.crossoverEscalationExtension = Data.escalationExtensionNumberValues[result.escaladeExtension.escaladeExtension];
        }
        if (result.cohort && result.cohort.cohort) {
          this.inclusion.crossoverCohort = result.cohort.cohort.id;
        }
        if (result.inclusionArm && result.inclusionArm.inclusionArm) {
          this.inclusion.inclusionArm2HhhId = result.inclusionArm.inclusionArm.hhhId;
        }
        if (result.designLevels && result.designLevels.length > 0 && result.designLevels[0].type === 'TREATMENT'
          && result.designLevels[0].dose) {
          this.inclusion.crossoverDose = result.designLevels[0].hhhId;
        }
        this.disableRelatedDesignCrossoverFields = true;
      });
    } else {
      this.inclusion.crossoverPhase = 0;
      this.inclusion.crossoverEscalationExtension = null;
      this.inclusion.crossoverCohort = 0;
      this.inclusion.arm2HhhId = 0;
      this.inclusion.crossoverDose = 0;
      this.disableRelatedDesignCrossoverFields = false;
    }
  }

  onCrossOverClick(crossoverForHiddenFields: any, i: number) {
    if (crossoverForHiddenFields.checked === true && this.crossovers.length - 1 === i) {
      this.crossovers.push(new InclusionCrossover());
    }
  }

  exportInclusionInformation(event: any) {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.inclusionId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .build();
    this.inclusionService.exportInclusionInformation(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  displayField = (fieldName: string) => this.config.displayField(fieldName);

  private mergeDesigns(result: Array<LabelValue>): void {
    this.designs = this.designs.filter(design => result.find(element => element.value === design.value));
    const remaining = result.filter(element => !this.designs.find(d => element.value = d.value));
    for (const val of remaining) {
      this.designs.push({
        label: val.label,
        value: val.value
      });
    }
  }

  private updateInclusionOrganInfos() {
    this.inclusion.removedOrganList = this.originalOrganList;
    this.inclusion.addedOrganList = this.inclusion.organList;
    for (let i = 0; i < this.originalOrganList.length; i++) {
      const index = this.inclusion.organList.indexOf(this.originalOrganList[i]);
      let a = 0;
      if (index !== -1) {
        this.inclusion.removedOrganList.splice(i - a, 1);
        this.inclusion.addedOrganList.splice(index - a, 1);
        a += 1;
      }
    }
  }

  getCaseManagementSelectedByTrial(){
    this.inclusionService.getCaseManagementSelectedByTrial(this.inclusion.trialHhhId).subscribe(res => {
        this.caseManagements = res;
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading committee decision list.' + error);
      }
    );
  }
  verifyClient = (clients: string[]) => this.dynamicConfigService.verifyClient(clients);
}

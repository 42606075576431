import {Component, Input, OnInit} from '@angular/core';
import {DynamicContainerDefinition} from '../../entity/dynamic-container-definition';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {Util} from '../../../helpers/util';
import {ContainerCardConfig} from '../../entity/container-card-config';
import {DynamicContainerDefinitionCard} from '../../entity/dynamic-container-definition-card';
import {SelectItem} from 'primeng/api';
import {SvgIcons} from '../../entity/module-icon';
import {DynamicDefinitionEnum} from '../../entity/dynamic-definition-enum';

@Component({
  selector: 'ih-container-config-editor',
  templateUrl: './container-config-editor.component.html',
  styleUrls: ['./container-config-editor.component.css']
})
export class ContainerConfigEditorComponent implements OnInit {

  @Input() name: string;
  _target: string;

  containerDefinition: DynamicContainerDefinition;
  dbContainerDefinition: DynamicContainerDefinition;
  cols: any[] = [];

  iconOptions: SelectItem[] = SvgIcons.trialIdentificationIcons;
  containerType: string;

  constructor(private dynamicConfigService: DynamicConfigService) {
  }

  @Input()
  set target(v: string) {
    this._target = v;
    this.getContainerDefinition();
    if (this._target === DynamicDefinitionEnum.SIDEBAR_MENU_CONTAINER) {
      this.containerType = 'sidebar';
      this.iconOptions = SvgIcons.sidebarIcons;
    } else if (this._target === DynamicDefinitionEnum.PAGE_HEADER_CONTAINER) {
      this.containerType = 'top-page';
      this.iconOptions = SvgIcons.sidebarIcons;
    } else if (this._target === DynamicDefinitionEnum.TRIAL_IDENTIFICATION_INFORMATION_CONTAINER) {
      this.containerType = 'trial-identification';
      this.iconOptions = SvgIcons.trialIdentificationIcons;
    } else if (this._target === DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_INFORMATION_CONTAINER) {
      this.containerType = 'trial-administrative';
      this.iconOptions = SvgIcons.trialAdministrativeIcons;
    } else if (this._target === DynamicDefinitionEnum.TRIAL_INCLUSION_PATIENT_FOLLOWUP_CONTAINER) {
      this.containerType = 'inclusion-followup';
      this.iconOptions = SvgIcons.inclusionFollowupIcons;
    } else if (this._target === DynamicDefinitionEnum.TRIAL_SITE_CONTAINER) {
      this.containerType = 'site-container';
      this.iconOptions = SvgIcons.siteIcons;
    }
  }

  get target(): string {
    return this._target;
  }

  ngOnInit() {
    if (this.containerType === 'sidebar') {
      this.cols = [
        {field: 'card', header: 'Module', width: '100px'},
        {field: 'display', header: 'Activé', width: '80px', type: 'display'},
        {field: 'title', header: 'Intitulé', width: '200px', type: 'input'},
        {field: 'icon', header: 'Icone', width: '150px', type: 'icon'},
      ];
    } else if (this.containerType === 'top-page') {
      this.cols = [
        {field: 'card', header: 'Fonctionnalité', width: '100px'},
        {field: 'display', header: 'Activé', width: '80px', type: 'display'},
        {field: 'title', header: 'Intitulé', width: '200px', type: 'input'},
      ];
    } else {
      this.cols = [
        {field: 'card', header: 'Onglet', width: '100px'},
        {field: 'display', header: 'Activé', width: '80px', type: 'display'},
        {field: 'title', header: 'Intitulé', width: '200px', type: 'input'},
        {field: 'icon', header: 'Icone', width: '150px', type: 'icon'},
      ];
    }
  }

  getContainerDefinition(): void {
    this.dynamicConfigService.getAdminConsoleContainerConfig(this._target).subscribe(
        container => {
          container.sortCards();
          this.containerDefinition = Util.clone(container);
          this.dbContainerDefinition = Util.clone(container);
        }, error => console.log(error.message)
    );
  }

  disableField(config: DynamicContainerDefinitionCard) {
    if (config.config.display) {
      return;
    }
    config.config.title = undefined;
    config.config.icon = config.defaultIcon;
  }

  dataChanged(): boolean {
    return !Util.equal(this.dbContainerDefinition, this.containerDefinition);
  }

  save(): void {
    this.dynamicConfigService.saveContainerConfig(this.containerDefinition).subscribe(
        (container) => {
          container.sortCards();
          this.containerDefinition = Util.clone(container);
          this.dbContainerDefinition = Util.clone(container);
        }, error => console.log(error.message)
    );
  }

  reset(): void {
    this.containerDefinition = Util.clone(this.dbContainerDefinition);
  }

  isDisplayed(config: ContainerCardConfig): boolean {
    return config.display;
  }

  getPlaceholder(card: DynamicContainerDefinitionCard): string {
    if (Util.isStringNullOrEmpty(card.config.title)) {
      return card.defaultTitle;
    }
    return 'Default Title';
  }

  showTitleEditor(card: DynamicContainerDefinitionCard): boolean {
    return !Util.isStringNullOrEmpty(card.defaultTitle);
  }

}

import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContainerCardConfig} from '../../entity/container-card-config';
import {Util} from '../../../helpers/util';

@Component({
  selector: 'ih-dynamic-menu',
  templateUrl: './dynamic-menu.component.html',
  styleUrls: ['./dynamic-menu.component.css']
})
export class DynamicMenuComponent implements OnInit {

  @Input() config: ContainerCardConfig;
  @Input() title: string;
  @Input() displayTitle: boolean;
  @Input() active: boolean;
  @Input() routerLinkActive = 'active';
  @Input() href: string;
  @Input() showExpend = false;

  @Output() click: EventEmitter<any> = new EventEmitter();
  @Output() expendClick: EventEmitter<any> = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.setTitle();
  }


  emitClick() {
    this.click.emit();
  }

  emitExpendClick() {
    this.expendClick.emit();
  }

  setTitle() {
    if (this.config && !Util.isStringNullOrEmpty(this.config.title)) {
      this.title = this.config.title;
    }
  }

  setMargin(): string {
    if (this.config === undefined) {
      return '50px';
    } else {
      switch (this.config.title) {
        case 'UI_MENU_MAIN_ENTRY_INVOICE_FOLLOWUP':
          return '50px';
        default:
          return '';
      }
    }
  }

}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {InvoiceInformationService} from './services/invoice-information.service';
import {InvoiceState} from '../invoice-fixed-costs/models/invoice-state';
import {FR} from '../../shared/entity/calendar-language';
import {SharedService} from '../../shared/services/shared.service';
import {DataService} from '../../shared/services/data-service';
import {NgForm} from '@angular/forms';
import {InvoiceInformation} from './models/invoice-information';
import {AdditionalCostState} from '../../additional-cost-state.enum';
import {InvoiceService} from '../services/invoice.service';
import {TranslateService} from '@ngx-translate/core';
import {Data} from '../../shared/entity/data';
import {FinancialEntityService} from '../../provider/services/financial-entity.service';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {InvoiceAppData} from '../Invoice-app-data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {AppData} from '../../helpers/app-data';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {HumanResourceGlobalFilter} from '../../human-resources/models/human-resource-global-filter';
import {ExportTypesEnum} from '../../dynamic-config/exported/data-export/ExportTypesEnum';
import {ExportButtonStatus} from '../../dynamic-config/service/data-export.service';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {DataCommentInfo} from '../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../data-comment/entity/DataCommentEvent';

@Component({
  selector: 'ih-invoice-information',
  templateUrl: './invoice-information.component.html',
  styleUrls: ['./invoice-information.component.css']
})
export class InvoiceInformationComponent implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.INVOICE_INFORMATION_FORM;
  config: FormConfig = new FormConfig();

  isCounterPartEnabled = true;
  useTVAForTotalEnabled = false;

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('invoiceInformationForm') invoiceInformationForm: NgForm;


  invoiceHhhId: number;
  invoiceStatus: InvoiceState[] = [];
  invoiceInformation: InvoiceInformation;
  submitted = false;
  public locale = FR;
  tvaOptions = AppData.client === 'col' ? Data.TvaOptionsForCOL : Data.TvaOptions;
  tvaOptionsForCHRO = Data.TvaOptionsForCHRO;
  imputationAccountOptions = Data.ImputationAccountOptions;
  tvaOptionsDisabled = true;

  constructor(
      private dataService: DataService,
      private sharedService: SharedService,
      private invoiceService: InvoiceService,
      private invoiceInformationService: InvoiceInformationService,
      private cfr: ComponentFactoryResolver,
      private router: Router,
      private translate: TranslateService,
      private financialEntityService: FinancialEntityService,
      private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.invoiceHhhId = url.queryParams.invoiceHhhId;
    }

    this.translate.stream('UI_GLOBAL_PLACEHOLDER_SELECT_FROM_LIST').subscribe((res: string) => {
      this.invoiceStatus = [{
        hhhId: null,
        code: null,
        nameFr: res
      }];
    });
  }

  get additionalCostState() {
    return AdditionalCostState;
  }

  ngOnInit() {
    this.initProperties();
    this.dataService.setInvoiceTypeExportPage('pre-invoice');
    this.invoiceInformationService.setInvoiceId(this.invoiceHhhId);
    this.getInvoiceInformation();
    this.getInvoiceStates();
    this.invoiceService.updateInvoiceHhhId(this.invoiceHhhId);
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isCounterPartEnabled = this.dynamicConfigService.getProperty(PropertyEnum.counterPartEnabled);
      this.useTVAForTotalEnabled = this.dynamicConfigService.getProperty(PropertyEnum.useTVAForTotalEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, InvoiceAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  getInvoiceInformation() {
    this.invoiceInformation = new InvoiceInformation();
    this.invoiceInformationService.getInvoiceInformation(this.invoiceHhhId).subscribe(res => {
      this.invoiceInformation = res;
      this.dataService.setInvoiceState(this.invoiceInformation.invoiceState.code);
    });
  }

  getInvoiceStates() {
    this.invoiceService.getInvoiceStates().subscribe(res => {
      this.invoiceStatus = this.invoiceStatus.concat(res);
    });
  }

  updateInvoiceInformation() {
    this.submitted = true;
    this.invoiceInformation.hhhId = this.invoiceHhhId;
    if (this.invoiceInformationForm.invalid) {
      return;
    }
    if (!(this.invoiceInformation.isValid(this.config))) {
      return;
    }
    this.invoiceInformationService.updateInvoiceInformation(this.invoiceInformation).subscribe(invoiceState => {
      this.invoiceService.updateTotal();
      this.sharedService.showSuccess();
      this.sharedService.setInformationInLocalStorage('Pré-facturation', 'Éditer', this.invoiceInformation.number);
      if (!this.invoiceInformation.manualState) {
        this.invoiceInformation.invoiceState = invoiceState;
      }
    });
  }

  displayDataCommentDialog(event: {attachedToField: string, isPrivate: boolean}) {
    if (!this.invoiceInformation || this.invoiceInformation.hhhId === undefined) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.Invoice, this.invoiceInformation.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }


  displayTrial() {
    this.router.navigate(
        ['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId: this.invoiceInformation.trialHhhId}}
    ).then();
  }

  getFinancialEntityInformation() {
    if (this.invoiceInformation.financialEntityHhhId != null) {
      this.financialEntityService.getFinancialEntityById(this.invoiceInformation.financialEntityHhhId).subscribe(
          (res) => {
            this.invoiceInformation.tva = res.tva;
            this.invoiceInformation.tvaIntraCommunity = res.tvaIntraCommunity;
          }
      );
    } else {
      this.invoiceInformation.tva = '';
      this.invoiceInformation.tvaIntraCommunity = '';
    }
  }

  exportInvoice(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.invoiceHhhId)
      .type('pre-invoice')
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .build();

    this.invoiceService.exportSingleInvoice(request, this.translate.currentLang).subscribe(
        (res) => {},
        (err) => {
          console.error(err);
        });
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.invoiceInformation.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  onImputationAccountChange() {
    switch (this.invoiceInformation.imputationAccount) {
      case '754815':
      case '754816':
      case '754817':
        this.invoiceInformation.tvaRate = '0';
        this.tvaOptionsDisabled = true;
        break;

      case '754811':
        this.invoiceInformation.tvaRate = '20';
        this.tvaOptionsDisabled = true;
        break;

      case '7721':
        this.invoiceInformation.tvaRate = '';
        this.tvaOptionsDisabled = false;
        break;

      default:
        this.invoiceInformation.tvaRate = '';
        this.tvaOptionsDisabled = true;
        break;
    }
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../shared/services/data-service';
import {InvoiceFixedCostService} from './services/invoice-fixed-cost.service';
import {Router} from '@angular/router';
import {AdditionalCostState} from '../../additional-cost-state.enum';
import {InvoiceFixedCost} from "../models/invoice-fixed-cost";

@Component({
  selector: 'app-invoice-fixed-costs',
  templateUrl: './invoice-fixed-costs.component.html',
  styleUrls: ['./invoice-fixed-costs.component.css']
})
export class InvoiceFixedCostsComponent implements OnInit, OnDestroy {

  public invoiceHhhId: number;
  refreshInvoiced: InvoiceFixedCost[] = [];
  refreshPending: InvoiceFixedCost[] = [];

  constructor(private dataService: DataService,
              private invoiceFixedCostService: InvoiceFixedCostService,
              private router: Router) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.invoiceHhhId = url.queryParams.invoiceHhhId;
    }
  }

  get additionalCostState() {
    return AdditionalCostState;
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  ngOnInit() {
    this.dataService.setExportPageModule('src/app/invoice/invoice-fixed-costs/invoice-fixed-costs.component');
    this.invoiceFixedCostService.setInvoiceId(this.invoiceHhhId);
  }

  refresh(obj) {
    if (obj.list.length > 0) {
      if (obj.state == AdditionalCostState.Pending) {
        this.refreshInvoiced = obj.list;
      } else {
        this.refreshPending = obj.list;
      }
    }
  }

}

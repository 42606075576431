export class SubInvoiceGlobalFilter {
  inclusionNumber: any;
  inclusionPreScreeningIdentifier: any;
  inclusionScreeningIdentifier: any;
  visitDate: any;
  visitName: any;
  type: number;
  category: any;
  actCategory: any;
  description: any;
  unitPrice: any;
  unitNumber: any;
  total: any;
  internalAccountNumber: any;
  firstSegment: any;
  secondSegment: any;
  localIdentifier: any;
  promoterHhhId: any;
  numberProtocol: any;
  siteName: any;
  patientNumber: string;
  annualInvoicing: boolean;

  of(subInvoiceGlobalFilter: SubInvoiceGlobalFilter): SubInvoiceGlobalFilter {
    return this;
  }
}

import {Injectable} from '@angular/core';
import {Role} from '../../login/models/role';
import {Patient} from '../models/patient';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Properties} from '../../helpers/properties';
import {Observable} from 'rxjs';
import {PatientData} from '../models/short-patient';
import {PatientListImportDto} from '../models/patient-list-import-dto';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  private host = new Properties().host;
  private _canTransferPatientData: boolean;

  private _transferBaseUrl: string;

  constructor(private http: HttpClient) {
  }

  persistPatientList() {
    console.log('persistPatientList');
    console.log(this._canTransferPatientData);
    console.log(this.canUserReadPersonalData());
    if (this._canTransferPatientData === true && this.canUserReadPersonalData()) {
      console.log('enter method');
      this.getAllPatients().subscribe(
        importDto => {
          console.log(this._transferBaseUrl + environment.persistListUrl);
          this.http.post<PatientListImportDto>(this._transferBaseUrl + environment.persistListUrl, importDto).subscribe();
        }
      );
    }
  }

  private getAllPatients(): Observable<PatientListImportDto> {
    return this.http.get<PatientListImportDto>(this.host + '/patient/full-list');
  }

  addPatient(patient: Patient) {
    console.log('addPatient method');
    console.log(this._canTransferPatientData === true);
    console.log(this.canUserReadPersonalData());
    if (this._canTransferPatientData === true && this.canUserReadPersonalData()) {
      this.http.post<Patient>(this._transferBaseUrl + environment.addUrl, new PatientData(patient)).subscribe();
    }
  }

  private canUserReadPersonalData() {
    console.log('canUserReadPersonalData');
    console.log(localStorage.getItem('authenticatedUserRole'));
    console.log(localStorage.getItem('authenticatedUserRole').indexOf(Role.Editor) === -1);
    return localStorage.getItem('authenticatedUserRole').indexOf(Role.Editor) === -1;
  }

  updatePatient(patient: Patient) {
    if (this._canTransferPatientData === true && this.canUserReadPersonalData()) {
      this.http.post<PatientData>(this._transferBaseUrl + environment.updateUrl, new PatientData(patient)).subscribe();
    }
  }

  deletePatientById(id: number) {
    if (this._canTransferPatientData === true && this.canUserReadPersonalData()) {
      this.http.delete(this._transferBaseUrl + environment.deleteUrl + '/' + id).subscribe();
    }
  }

  getTransferredPatientsCount() {
    if (this._canTransferPatientData === true) {
      this.http.get<PatientData[]>(this.host + '/patient/count').subscribe(
        originalCount => {
          this.http.get<number>(this._transferBaseUrl + environment.countUrl).subscribe(
            count => {
              console.log('--------------------------------------------------');
              console.log('Original count = ' + originalCount + '  //  Transferred count = ' + count);
              console.log('--------------------------------------------------');
            }
          );
        }
      );
    }
  }

}

import {ContactableMember} from '../../shared/entity/contactable-member';
import {LabelValue} from '../../shared/entity/label-value';
import {Util} from '../../helpers/util';
import {FormConfig} from '../../dynamic-config/entity/form-config';

export class Site {
  hhhId: any;
  publicCommentCount: number;
  name: any;
  contactableMembers: ContactableMember[];
  totalContactableMemberNames?: string;
  totalContactableMemberFunctions?: string;
  establishment?: LabelValue = new LabelValue();
  currentEstablismentName?: string;


  init(obj: any): Site {
    if (!obj) {
      return new Site();
    }
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig, parentEstablishment: boolean): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'siteName') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.name)) {
          return false;
        }
      } else if (key === 'establishment') {
        if (parentEstablishment && required && Util.isNullOrUndefinedOrEmpty(this.establishment.value)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

export class ExportedSite {
  name = '';
}

import {Component, Input, OnInit} from '@angular/core';
import {InvoiceMonitorService} from '../../../service/invoice-monitor.service';
import {InInclusionBudget} from '../../../entity/invoice-monitor/in-inclusion-budget';
import {ProvisionalBudget} from '../../../entity/invoice-monitor/provisional-budget';
import {
  TrialIdentificationInformationGeneralService
} from '../../../services/trial-identification-information-general.service';

@Component({
  selector: 'ih-budget-tracking',
  templateUrl: './budget-tracking.component.html',
  styleUrls: ['./budget-tracking.component.css']
})
export class BudgetTrackingComponent implements OnInit {
  @Input() trialHhhId: number;
  amount: number[] = [];
  inInclusionList: InInclusionBudget[] = [];
  budgetList: ProvisionalBudget[] = [];
  inclusionObjective: any;

  constructor(private trialInvoiceMonitorService: InvoiceMonitorService,
              private trialIdentificationInformationService: TrialIdentificationInformationGeneralService) {
  }

  ngOnInit() {
    this.getEstimatedBudget();
    this.getInclusionObjectif();

  }

  getEstimatedBudget() {
    this.trialInvoiceMonitorService.getEstimatedBudget(this.trialHhhId).subscribe(
      res => {
        this.budgetList = [
          new ProvisionalBudget('Coûts / Surcoûts', res.fixedCosts),
          new ProvisionalBudget('Avant Inclusion', res.beforeInclusionBudget)
        ];
        this.inInclusionList = res.inInclusionBudget;
        for (const inInclusionBudget of this.inInclusionList) {
          const name = inInclusionBudget.name;
          const value = inInclusionBudget.amount;
          const previsionnelBudget = new ProvisionalBudget(name, value);
          this.budgetList.push(previsionnelBudget);
        }
        this.budgetList.push(new ProvisionalBudget('Suivi', res.afterInclusionBudget));
        console.log(this.budgetList);
      });

  }

  getInclusionObjectif() {
    this.trialIdentificationInformationService.load(this.trialHhhId).subscribe(res => {
      this.inclusionObjective = res.inclusionObjective;
    });
  }
}



import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {FormComponent} from '../../../shared/component/form/form.component';
import {TableHeaderConfig} from '../../entity/table-header-config';
import {ExportTypesEnum} from '../data-export/ExportTypesEnum';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {DynamicTableDefinitionHeader} from '../../entity/dynamic-table-definition-header';
import {DynamicTranslatorPipe} from '../../pipe/dynamic-translator.pipe';
import {DynamicDefinitionEnum} from '../../entity/dynamic-definition-enum';
import {CustomExport} from '../../../trial/models/custom-export';
import {PropertyEnum} from '../../entity/property-enum';
import {LabelValue} from '../../../shared/entity/label-value';
import {Util} from '../../../helpers/util';
import {GlobalConfigAppData} from '../../gloabl-config-app-data';
import {HumanResourceAppData} from '../../../human-resources/human-resource-app-data';
import {PatientAppData} from '../../../patient/patient-app-data';
import {InvoiceAppData} from '../../../invoice/Invoice-app-data';
import {ProviderAppData} from '../../../provider/provider-app-data';
import {SiteAppData} from '../../../site/site-app-data';
import {AdditionalCostsAppData} from '../../../additional-costs/additional-costs-app-data';
import {EstablishmentAppData} from '../../../establishment/establishment-app-data';
import {TrialAppData} from '../../../trial/trial-app-data';
import {PharmacyAppData} from '../../../pharmacy/pharmacy-app-data';
import {StatisticsAppData} from '../../../statistics/statistics-app-data';
import {AgendaAppData} from '../../../agenda/agenda-app-data';


@Component({
  selector: 'ih-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.css'],
  providers: [DynamicTranslatorPipe]
})
export class ExportDialogComponent extends FormComponent<any> implements OnInit {

  public sigrecExportEnabled = true;
  public piramigExportEnabled = true;
  public isMultiSiteEnabled = false;
  public additionalTestExportEnabled = false;
  public recipesExportEnabled = false;
  public isAnnexExportEnabled = false;
  public visible = true;
  public showSingleExportOption: boolean;

  public sourceList: SelectItem[] = [];
  public targetList: SelectItem[] = [];
  public target: string;
  public exportTypes = ExportTypesEnum;
  public exportType = -1;

  public availableHeaders: Map<string, TableHeaderConfig> = new Map();

  private customExportFilter: CustomExport = new CustomExport();

  selectedSite = '-1';
  sites: LabelValue[] = [];


  callback: (list: any) => void;

  constructor(
      private dynamicConfigService: DynamicConfigService,
      private translator: DynamicTranslatorPipe
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ExportDialogComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.initProperties();
    this.getAllHeaders();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.sigrecExportEnabled = this.dynamicConfigService.getProperty(PropertyEnum.sigrecExportEnabled);
      this.piramigExportEnabled = this.dynamicConfigService.getProperty(PropertyEnum.piramigExportEnabled);
      this.isMultiSiteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
      this.additionalTestExportEnabled = this.dynamicConfigService.getProperty(PropertyEnum.additionalTestExportEnabled);
      this.recipesExportEnabled = this.dynamicConfigService.getProperty(PropertyEnum.recipesExportEnabled);
      this.isAnnexExportEnabled = this.dynamicConfigService.getProperty(PropertyEnum.AnnexExportEnabled);
    });
  }

  getAllHeaders() {
    if (this.target === DynamicDefinitionEnum.TRIAL_LIST_TABLE || this.isFormExport()) {
      return;
    }
    if (this.isTrackingTableExport()) {
      return;
    }
    this.dynamicConfigService.loadTableConfig(this.target, this.getTableConfigCache(this.target).tableConfig).subscribe(
      (tableConfig) => {
        this.initPickList(tableConfig.headers);
      });
  }
  getTableConfigCache(target: string): any {
    const pipeIndex = target.indexOf('|');
    if (!(pipeIndex === -1)) {
      const firstString = target.substring(0, pipeIndex);

      switch (firstString) {
        case 'global-config':
          return GlobalConfigAppData;
        case 'human-resource':
          return HumanResourceAppData;
        case 'patient':
          return PatientAppData;
        case 'invoice':
          return InvoiceAppData;
        case 'provider':
          return ProviderAppData;
        case 'site':
          return SiteAppData;
        case 'additional-costs':
          return AdditionalCostsAppData;
        case 'establishment':
          return EstablishmentAppData;
        case 'trial':
          return TrialAppData;
        case 'pharmacy':
          return PharmacyAppData;
        case 'statistics':
          return StatisticsAppData;
        case 'agenda':
          return AgendaAppData;
      }
    }
    return null;

  }

  initPickList(tableHeaders: Map<string, TableHeaderConfig>) {
    tableHeaders.forEach((headerConfig, field) => {
      const item = {
        label: field,
        value: headerConfig,
        title: headerConfig.header
      } as SelectItem;
      this.sourceList.push(item);
    });
  }

  export() {
    this.visible = false;
    switch (this.exportType) {
      case this.exportTypes.FULL_EXPORT : // COMMON
        this.callback({
          exportType: this.exportType,
          headers: null
        });
        break;
      case this.exportTypes.SINGLE_PAGE_EXPORT: // COMMON
        this.callback({
          exportType: this.exportType,
          headers: null
        });
        break;
      case this.exportTypes.CUSTOM_EXPORT: // COMMON
        this.callback({
          exportType: this.exportType,
          headers: (this.isTrialExport() || this.isPatientExport()) ? this.targetList.map(field => field.value) : this.targetList.map(item => item.label),
          filter: this.isTrialExport() ? this.customExportFilter.trialExportFilter : (this.isPatientExport() ? this.customExportFilter.patientExportFilter : null),
          siteId: (this.isTrialExport() || this.isPatientExport()) ? this.selectedSite : null
        });
        break;
      case this.exportTypes.SIGREC_EXPORT: // TRIAL
        this.callback({
          exportType: this.exportType,
          headers: null,
          siteId: this.selectedSite
        });
        break;
      case this.exportTypes.PIRAMIG_EXPORT: // TRIAL
        this.callback({
          exportType: this.exportType,
          headers: null,
          siteId: this.selectedSite
        });
        break;
      case this.exportTypes.GLOBAL_EXPORT: // TRIAL OR PATIENT
        this.callback({
          exportType: this.exportType,
          headers: null,
        });
        break;
      case this.exportTypes.GLOBAL_SINGLE_PAGE_EXPORT: // TRIAL
        this.callback({
          exportType: this.exportType,
          headers: null,
        });
        break;
      case this.exportTypes.TRACKING_TABLE_EXPORT: // TRACKING TABLE
        this.callback({
          exportType: this.exportType,
        });
        break;
      case this.exportTypes.TRACKING_TABLE_ADDITIONAL_TEST_EXPORT: // TRACKING TABLE
        this.callback({
          exportType: this.exportType,
        });
        break;
      case this.exportTypes.SINGLE_INVOICE_EXPORT : // INVOICE
        this.callback({
          exportType: this.exportType,
          headers: null
        });
        break;
      case this.exportTypes.CUMULATIVE_EXPORT: // INVOICE
        this.callback({
          exportType: this.exportType,
          headers: null
        });
        break;
      case this.exportTypes.ANNEX_EXPORT: // INVOICE
        this.callback({
          exportType: this.exportType,
          headers: null
        });
        break;
      case this.exportTypes.RECIPES_EXPORT: // INVOICE
        this.callback({
          exportType: this.exportType,
          headers: null
        });
        break;
      case this.exportTypes.FORM_EXPORT: // FORM
        this.callback({
          exportType: this.exportType,
          headers: null
        });
        break;
    }
  }

  close() {
    this.visible = false;
    this.callback(null);
  }

  isTrialExport(): boolean {
    return this.target === DynamicDefinitionEnum.TRIAL_LIST_TABLE;
  }

  isPatientExport(): boolean {
    return this.target === DynamicDefinitionEnum.PATIENT_LIST_TABLE;
  }

  isInvoiceExport(): boolean {
    return this.target === DynamicDefinitionEnum.INVOICE_INFORMATION_FORM || this.target === DynamicDefinitionEnum.INVOICE_STATIC_INFORMATION_FORM;
  }

  isTrackingTableExport(): boolean {
    return this.target === DynamicDefinitionEnum.TRIAL_TRACKING_TABLE_FILTER_FORM;
  }


  isStaticInvoiceExport(): boolean  {
    return this.target === DynamicDefinitionEnum.INVOICE_STATIC_INFORMATION_FORM;
  }

  updatePicklists(event: any): void {
    this.sourceList = event.sourceList;
    this.targetList = event.targetList;
  }

  updateCustomExportFilter(filter: any) {
    if (this.isTrialExport()) {
      this.customExportFilter.trialExportFilter = filter;
    } else if (this.isPatientExport()) {
      this.customExportFilter.patientExportFilter = filter;
    }
  }

  isFormExport() {
    return Util.isNullOrUndefinedOrEmpty(this.target) || this.target.toLowerCase().includes('form');
  }


}

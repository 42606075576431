import {Component, ComponentFactoryResolver, HostListener, OnInit} from '@angular/core';
import {TableHeader} from '../../../../shared/entity/table-header';
import {SharedService} from '../../../../shared/services/shared.service';
import {DataService} from '../../../../shared/services/data-service';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {PharmacyDispensation} from '../../../entity/trial-pharmacy/pharmacyDispensation';
import {DatePipe} from '@angular/common';
import {FR} from '../../../../shared/entity/calendar-language';
import {PharmacyDispensationService} from '../../../service/pharmacy-dispensation.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {PharmacyDispensationGlobalFilter} from '../../../../pharmacy/model/pharmacyDispensationGlobalFilter';
import {PharmacyDispensationKitService} from '../../../service/pharmacy-dispensation-kit.service';
import {PharmacyService} from '../../../service/pharmacy.service';
import {PharmacyDispensationKit} from '../../../entity/trial-pharmacy/PharmacyDispensationKit';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {Data} from '../../../../shared/entity/data';
import {SelectItem} from 'primeng/components/common/api';
import {AUTHENTICATED_USER_ROLE} from '../../../../login/services/authentication.service';
import {Role} from '../../../../login/models/role';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {
  DynamicTableHeaderBuilder
} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {isNullOrUndefined} from "util";


@Component({
  selector: 'ih-trial-pharmacy-dispensation',
  templateUrl: './trial-pharmacy-dispensation.component.html',
  styleUrls: ['./trial-pharmacy-dispensation.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
// To do
export class TrialPharmacyDispensationComponent extends PaginatorTableComponent<PharmacyDispensation> implements OnInit {

  readonly target = DynamicDefinitionEnum.TRIAL_PHARMACY_DISPENSATION_TABLE;
  config: TableConfig;

  dispensationColumnListHeader: DynamicTableHeader[];
  kitsColumnListHeader: DynamicTableHeader[];
  pharmacyHhhId: number;
  locale = FR;
  first = 0;
  trialHhhId: any;
  filters: PharmacyDispensationGlobalFilter = new PharmacyDispensationGlobalFilter();
  pharmacyDispensationKitsMap: Map<number, Array<PharmacyDispensationKit>> = new Map();
  monthsSelectItems: SelectItem[] = [];
  yearsSelectItems: SelectItem[] = [];

  constructor(
    private datePipe: DatePipe,
    private cfr: ComponentFactoryResolver,
    private sharedService: SharedService,
    private dataService: DataService,
    private pharmacyDispensationService: PharmacyDispensationService,
    private pharmacyDispensationKitService: PharmacyDispensationKitService,
    public pharmacyService: PharmacyService,
    private router: Router,
    private canDeactivateService: CanDeactivateService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
      this.pharmacyHhhId = url.queryParams.pharmacyHhhId;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.monthsSelectItems = Data.monthsList;
    for (let i = new Date().getFullYear() + 20; i >= 1900; i--) {
      this.yearsSelectItems.push({value: i, label: String(i)});
    }
    this.pharmacyService.setPharmacyReadonlyMode();
    this.initTableConfig();
    this.getFilteredList();
  }
  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }
  buildTableHeaders(): void {
    const dispensationListHeader = [];
    dispensationListHeader.push(new DynamicTableHeaderBuilder()
      .field('lotNumber')
      .filterable()
      .filter(this.filters.lotNumber)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    dispensationListHeader.push(new DynamicTableHeaderBuilder()
      .field('treatmentName')
      .filterable()
      .filter(this.filters.treatmentName)
      .filterType(FilterType.IH_DROPDOWN)
      .sortable()
      .sortField('receptionAuthorName')
      .type('pharmacy-treatment-type|' + this.pharmacyHhhId)
      .build());
    dispensationListHeader.push(new DynamicTableHeaderBuilder()
      .field('pharmaceuticalFormName')
      .filterable()
      .filter(this.filters.pharmaceuticalFormName)
      .filterType(FilterType.IH_DROPDOWN)
      .type('pharmaceutical-form-selected')
      .sortable()
      .build());
    this.dispensationColumnListHeader = this.config.buildTable(dispensationListHeader);
    const kitsListHeader = [];
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('kit')
      .filterable()
      .filter(this.filters.kit.kit)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('prescriberName')
      .filterable()
      .filter(this.filters.prescriberName)
      .filterType(FilterType.IH_DROPDOWN)
      .type('pharmacist-name')
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('schedulerNumber')
      .filterable()
      .filter(this.filters.schedulerNumber)
      .filterType(FilterType.INPUT_TEXT)
      .build());

    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('dispensationDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.dispensationDate)
      .sortable()
      .sortField('dispensationDate')
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('patientName')
      .filterable()
      .filter(this.filters.patientName)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('visitName')
      .filterable()
      .filter(this.filters.visitName)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('processingUnit')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('initialQuantity')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('dispensedQuantity')
      .filterable()
      .filter(this.filters.dispensedQuantity)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('returnedQuantity')
      .filterable()
      .filter(this.filters.returnedQuantity)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('destructionDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.kit.destructionDate)
      .sortable()
      .sortField('destructionDate')
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .build());
    this.kitsColumnListHeader = this.config.buildTable(kitsListHeader);
  }

  getList() {
    this.pharmacyDispensationService.loadList(this.page, this.limit, this.sortBy, this.orderBy, this.pharmacyHhhId, this.filters).subscribe(res => {
      this.values = res.content;
      this.totalNumber = res.totalElements;
      this.pharmacyDispensationService.setPaginationConfiguration(res, this.page, this.limit, this.sortBy, this.orderBy, this.filters, this.pharmacyHhhId);
    }, error => {
      this.sharedService.showFailure();
      console.log(error);
    });
  }


  getExportPageModule(): string {
    return 'src/app/trial/trial-pharmacy/trial-pharmacy-dispensation/trial-pharmacy-dispensation.component';
  }

  onRowEditDispensationSave(dispensation) {
    this.pharmacyDispensationService.update(dispensation).subscribe(
      () => {
        this.getList();
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.log(error);
      }
    );
  }
  displayContent(col: any, colField: any, rowData: any): string {
    let content = colField;
    if (isNullOrUndefined(rowData[col.field]) && !col.displayContent) {
      content = '';
    } else if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (col.dateFormatter) {
      content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
    }
    return content;
  }

  onRowEditKitSave(kit: PharmacyDispensationKit, dispensationRowIndex, lotHhhId) {
    kit.lotHhhId = lotHhhId;
    let pharmacyDispensationKitList = this.pharmacyDispensationKitsMap.get(dispensationRowIndex);
    if (pharmacyDispensationKitList === undefined) {
      pharmacyDispensationKitList = [];
      pharmacyDispensationKitList.push(kit);
      this.pharmacyDispensationKitsMap.set(dispensationRowIndex, pharmacyDispensationKitList);
    } else {
      if (pharmacyDispensationKitList.find(value => value.hhhId === kit.hhhId)) {
        pharmacyDispensationKitList.splice(pharmacyDispensationKitList.findIndex(value => value.hhhId === kit.hhhId), 1);
      }
      pharmacyDispensationKitList.push(kit);
      this.pharmacyDispensationKitsMap.set(dispensationRowIndex, pharmacyDispensationKitList);
    }
  }

  get filterType() {
    return FilterType;
  }

  filterUpdated() {
    this.page = 0;
    this.first = 0;
    this.filters = Object.assign(this.filters, TableHeader.buildFilterData(this.dispensationColumnListHeader), TableHeader.buildFilterData(this.kitsColumnListHeader));
    this.dispensationColumnListHeader.forEach(value => {
      if (value.composedFilter) {
        this.filters[value.field + 'Month'] = value.composedFilter[0];
        this.filters[value.field + 'Year'] = value.composedFilter[1];
      }
    });
    this.getList();
  }

  saveKits(dispensationRowIndex: number) {
    this.pharmacyDispensationKitService.updateList(this.pharmacyDispensationKitsMap.get(dispensationRowIndex)).subscribe(
      () => {
        this.getList();
        this.sharedService.showSuccess();
        this.pharmacyDispensationKitsMap.delete(dispensationRowIndex);
        if (this.pharmacyDispensationKitsMap.size === 0) {
          this.canDeactivateService.canBeDeactivated = true;
        }
      }, error => {
        this.sharedService.showFailure();
        console.log(error);
      }
    );
  }
  saveHeadersLayout() {
    localStorage.setItem(this.target, JSON.stringify(this.dispensationColumnListHeader));
  }
  // getTdClass(): string {
  //   return this.kitsColumnListHeader.length === 0 ? 'empty-header' : '';
  // }
  getFilteredList(filter?) {
    this.first = 0;
    this.page = 0;
    if (filter) {
      Object.assign(this.filters, DynamicTableHeader.buildFilterData(this.dispensationColumnListHeader));
    } else {
      this.filters = new PharmacyDispensationGlobalFilter();
    }
    this.getList();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  displayUpdatesPatientField(patient, dispensationRowIndex, kitRowIndex) {
    patient.items.find(v => v.value === patient.value) == null
      ? this.values[dispensationRowIndex].kits[kitRowIndex].patientName = null
      : this.values[dispensationRowIndex].kits[kitRowIndex].patientName = patient.items.find(v => v.value === patient.value).label;

    this.values[dispensationRowIndex].kits[kitRowIndex].visitHhhId = null;
    this.values[dispensationRowIndex].kits[kitRowIndex].visitName = null;
  }

  displayUpdatesVisitField(visit, dispensationRowIndex, kitRowIndex) {
    visit.items.find(v => v.value === visit.value) == null
      ? this.values[dispensationRowIndex].kits[kitRowIndex].visitName = null
      : this.values[dispensationRowIndex].kits[kitRowIndex].visitName = visit.items.find(v => v.value === visit.value).label;
  }

  displayUpdatesPrescriberField(prescriber, dispensationRowIndex, kitRowIndex) {
    prescriber.items.find(v => v.value === prescriber.value) == null
      ? this.values[dispensationRowIndex].kits[kitRowIndex].prescriberName = null
      : this.values[dispensationRowIndex].kits[kitRowIndex].prescriberName = prescriber.items.find(v => v.value === prescriber.value).label;
  }

  resetFilter() {
    this.filters = new PharmacyDispensationGlobalFilter();
    this.page = 0;
    this.first = 0;
    this.ngOnInit();
    this.getList();
  }

  isAdmin() {
    return localStorage.getItem(AUTHENTICATED_USER_ROLE) === Role.Admin;
  }

  displayBtn(btnId: string): boolean {
    if (!this.config || !this.config.buttons) {
      return false;
    }
    return this.config.displayBtn(btnId);
  }

  getBtnClass(): string {
    return this.config ? this.config.getBtnClass() : '';
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HumanResourceListComponent} from './human-resources/human-resource-list/human-resource-list.component';
import {ContactListComponent} from './dynamic-config/exported/contact-list/contact-list.component';
import {SiteListComponent} from './site/site-list/site-list.component';
import {StatisticsComponent} from './statistics/statistics/statistics.component';
import {TrialListComponent} from './trial/component/trial-list/trial-list.component';
import {HomeContainerComponent} from './home/home-container/home-container.component';
import {AgendaContainerComponent} from './agenda/agenda-container/agenda-container.component';
import {PatientListComponent} from './patient/patient-list/patient-list.component';
import {ProviderListComponent} from './provider/provider-list/provider-list.component';
import {AdditionalCostsContainerComponent} from './additional-costs/additional-costs-container/additional-costs-container.component';
import {EstablishmentContainerComponent} from './establishment/establishment-container/establishment-container.component';
import {InvoiceListComponent} from './invoice/invoice-list/invoice-list.component';
import {DataImportComponent} from './data-import/data-import/data-import.component';
import {AccountListComponent} from './admin/account-list/account-list.component';
import {NewUserComponent} from './admin/new-user/new-user.component';
import {AccessRightsComponent} from './admin/access-rights/access-rights.component';
import {ActsCategoriesComponent} from './additional-costs/acts-categories/acts-categories.component';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {LoginComponent} from './login/login-component/login.component';
import {CurrentUserComponent} from './admin/current-user/current-user.component';
import {AuthGuard} from './helpers/auth-guard.service';
import {Role} from './login/models/role';
import {ErrorPageComponent} from './error-page/error-page.component';
import {MyEstablishmentComponent} from './establishment/my-establishment/my-establishment.component';
import {DataManagementComponent} from './dynamic-config/exported/data-management/data-management.component';
import {UserAttemptListComponent} from './user-attempts/user-attempt-list/user-attempt-list.component';
import {ForgetPasswordComponent} from './login/forget-password/forget-password.component';
import {WorkloadComponent} from './admin/workload/workload.component';
import {DisciplineIdentificationInformationComponent} from './trial/component/trial-identification/discipline-identification-information/discipline-identification-information.component';
import {DesignIdentificationInformationComponent} from './trial/component/trial-identification/design/design-identification-information/design-identification-information.component';
import {AmendmentIdentificationInformationComponent} from './trial/component/trial-identification/version-protocol/amendment-identification-information/amendment-identification-information.component';
import {TrialAdministrativeFeasibilityInformationComponent} from './trial/component/trial-administrative/trial-administrative-feasibility-information/trial-administrative-feasibility-information.component';
import {CustomExportManagementComponent} from './dynamic-config/exported/custom-export-management/custom-export-management.component';
import {PharmacyContainerComponent} from './pharmacy/pharmacy-container/pharmacy-container.component';
import {CanDeactivateGuard} from './helpers/can-deactivate-guard';
import {PharmacyStockComponent} from './pharmacy/pharmacy-stock/pharmacy-stock.component';
import {PharmacyDispensationComponent} from './pharmacy/pharmacy-dispensation/pharmacy-dispensation.component';
import {PageDownComponent} from './page-down/page-down.component';
import {MobileApplicationHeaderComponent} from './mobile/mobile-application-header/mobile-application-header.component';
import {TrialsComponent} from './mobile/trials/trials.component';
import {AdminConsoleComponent} from './dynamic-config/component/admin-console/admin-console.component';
import {InclusionDetailsContainerComponent} from './trial/component/inclusions/inclusion-details/inclusion-details-container/inclusion-details-container.component';
import {TrialAdministrativeInformationContainerComponent} from './trial/component/trial-administrative/trial-administrative-information-container/trial-administrative-information-container.component';
import {TrialIdentificationInformationContainerComponent} from './trial/component/trial-identification/trial-identification-information-container/trial-identification-information-container.component';
import {StatisticsContainerComponent} from './statistics/statistics-container/statistics-container.component';
import {PowerBiReportComponent} from './statistics/powerBi/power-bi-report/power-bi-report.component';
import {InvoiceContainerComponent} from './invoice/invoice-container/invoice-container.component';
import {AuditTrailComponent} from './admin/audit-trail/audit-trail.component';
import {RequirePasswordUpdateGuard} from './helpers/require-password-update-guard';


const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'request-password-reset', component: ForgetPasswordComponent},
  {path: 'login', component: LoginComponent},
  {path: 'home', component: HomeContainerComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'agenda', component: AgendaContainerComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'human-resource-list', component: HumanResourceListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'contact/:invoiceHhhId/:entityType', component: ContactListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'site-list', component: SiteListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'provider-list', component: ProviderListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {
    path: 'additional-costs-container', component: AdditionalCostsContainerComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard],
    children: [
      {path: '', redirectTo: 'acts-categories', pathMatch: 'full'},
      {path: 'acts-categories', component: ActsCategoriesComponent, canActivate: [AuthGuard]}
    ]
  },
  {
    path: 'patient-list', component: PatientListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]
  },
  {
    path: 'establishment', component: EstablishmentContainerComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard],
    children: [
      {path: '', redirectTo: 'details', pathMatch: 'full'},
      {path: 'details', component: MyEstablishmentComponent, canActivate: [AuthGuard]}
    ]
  },
  {path: 'invoice-list', component: InvoiceListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'retrocession-fees-list', component: InvoiceListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},

  {path: 'statistics', component: StatisticsContainerComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'inclusion-details', component: InclusionDetailsContainerComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'invoice-container-details', component: InvoiceContainerComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {
    path: 'configuration', component: AdminConsoleComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]
  },
  {
    path: 'data-management', component: DataManagementComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard], data: {roles: [Role.Admin, Role.Editor]}
  },
  {path: 'trial-list', component: TrialListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {
    path: 'trial-identification-information-container',
    component: TrialIdentificationInformationContainerComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'trial-administrative-information-container',
    component: TrialAdministrativeInformationContainerComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {path: 'new-user', component: NewUserComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard], data: {roles: [Role.Admin, Role.Editor]}},
  {path: 'access-rights', component: AccessRightsComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard], data: {roles: [Role.Admin, Role.Editor]}},
  {path: 'data-import', component: DataImportComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'account-list', component: AccountListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard], data: {roles: [Role.Admin, Role.Editor]}},
  {path: 'audit-trail', component: AuditTrailComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard], data: {roles: [Role.Admin, Role.Editor,Role.Manager]}},
  {path: 'workload', component: WorkloadComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard], data: {roles: [Role.Admin,Role.Manager]}},
  {path: 'auth/edit', component: CurrentUserComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'error-page', component: ErrorPageComponent, canActivate: [AuthGuard]},
  {path: 'page-down', component: PageDownComponent},
  {path: 'account-list', component: AccountListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'audit-trail', component: AuditTrailComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'auth/edit', component: CurrentUserComponent, canActivate: [AuthGuard]},
  {path: 'user/attempts', component: UserAttemptListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard] },
  {path: 'custom-export-management', component: CustomExportManagementComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {
    path: 'mobile-application', component: MobileApplicationHeaderComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard],
    children: [
      {path: '', redirectTo: 'trials', pathMatch: 'full'},
      {path: 'trials', component: TrialsComponent, canActivate: [AuthGuard]}
    ]
  },
  {
    path: 'pharmacy', component: PharmacyContainerComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard], canDeactivate: [CanDeactivateGuard],
    children: [
      {path: '', redirectTo: 'stock', pathMatch: 'full'},
      {path: 'stock', component: PharmacyStockComponent, canActivate: [AuthGuard]},
      {path: 'dispensation', component: PharmacyDispensationComponent, canActivate: [AuthGuard]},
    ]
  }
  ];

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule,
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [RouterModule],
  entryComponents: [
    DisciplineIdentificationInformationComponent,
    DesignIdentificationInformationComponent,
    AmendmentIdentificationInformationComponent,
    TrialAdministrativeFeasibilityInformationComponent
  ]
})
export class AppRoutingModule {
}

import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {StatisticsComponent} from './statistics/statistics.component';
import {FollowTrialsComponent} from './follow-trials/follow-trials.component';
import {FollowInclusionsComponent} from './follow-inclusions/follow-inclusions.component';
import {FollowActiveFileComponent} from './follow-active-file/follow-active-file.component';
import {FollowInclusionsByTrialComponent} from './follow-inclusions-by-trial/follow-inclusions-by-trial.component';
import {AuthGuard} from '../helpers/auth-guard.service';
import {FollowIsoStatsComponent} from './follow-other-stats/follow-iso-stats.component';
import {RequirePasswordUpdateGuard} from '../helpers/require-password-update-guard';

const routes: Routes = [
  {
    path: 'statistics', component: StatisticsComponent, canActivate: [RequirePasswordUpdateGuard],
    children: [{path: '', redirectTo: 'follow-trials', pathMatch: 'full'},
      {path: 'follow-trials', component: FollowTrialsComponent, canActivate: [AuthGuard]},
      {path: 'follow-inclusions', component: FollowInclusionsComponent, canActivate: [AuthGuard]},
      {path: 'follow-active-file', component: FollowActiveFileComponent, canActivate: [AuthGuard]},
      {path: 'follow-inclusions-by-trial', component: FollowInclusionsByTrialComponent, canActivate: [AuthGuard]},
      {path: 'follow-iso-stats', component: FollowIsoStatsComponent, canActivate: [AuthGuard]}]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    HttpClientModule,
    TranslateModule],
  exports: [RouterModule]
})


export class StatisticsRoutingModule {
}

import {PharmacyDispensationKit} from '../../trial/entity/trial-pharmacy/PharmacyDispensationKit';

export class PharmacyStockGlobalFilter {
  trialName: string;
  hhhId: number;
  state: string;
  wording: string;
  receptionDate: Date | string;
  receptionAuthorName: number;
  treatmentNumber: string;
  receptionNumber: string;
  dosageName: number;
  lotNumber: string;
  dluoDate: Date | string;
  expirationDate: Date | string;
  dispensationDate: Date;
  destructionDate: Date | string;
  events: string;
  authorName: number;
  kit: PharmacyDispensationKit = new PharmacyDispensationKit();
  treatmentName: number;
  receptionDateMonth?: any;
  receptionDateYear?: number;
  dluoDateMonth?: any;
  dluoDateYear?: any;
  expirationDateMonth?: any;
  expirationDateYear?: any;
  dispensationDateMonth?: any;
  dispensationDateYear?: any;
  destructionDateMonth?: any;
  destructionDateYear?: any;
  fromDate: string;
  toDate: string;
  pharmaceuticalFormName: number;
  pharmaceuticalFormHhhId: number;

  constructor() {
  }
}


import {PatientPerYear} from './patient-per-year';
import {EstablishmentDepartmentPerYear} from './establishment-department-per-year';

export class ReportsRateToActiveInpatients {
  public includedPatientPerYear: PatientPerYear[] = [];
  public screenedPatientPerYear: PatientPerYear[] = [];
  public establishmentDepartmentPerYear: EstablishmentDepartmentPerYear[] = [];
}

export class ExportedReportsRateToActiveInpatients {
  public year = '';
  public nbEstablishmentDepartment = 0;
  public nbPatient = 0;
}

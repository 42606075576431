import {Component, ComponentFactoryResolver, Input, OnInit, ViewContainerRef} from '@angular/core';
import {SharedService} from '../../shared/services/shared.service';
import {Router} from '@angular/router';
import {DataDocumentService} from '../../data-document/service/data-document.service';
import {DataDocument} from '../../data-document/entity/data-document';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-file-form',
  templateUrl: './file-form.component.html',
  styleUrls: ['./file-form.component.css']
})
export class FileFormComponent implements OnInit {

  public path = '';
  public selectedFiles: FileList;
  public file: File;
  public displayFileDialog = false;
  public invalidFileSize: boolean;
  public maxFileSize = 15000000;
  public callback: () => void;

  @Input()
  public hhhId: number;
  @Input()
  public attachedTo: AttachedToEntityEnum;

  trialHhhId: number;
  pharmacyHhhId: number;
  document = new DataDocument();

  constructor(private dataDocumentService: DataDocumentService,
              private sharedService: SharedService,
              private router: Router) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.hhhId = url.queryParams.hhhId;
    }
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(FileFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.displayFileDialog = true;
  }

  uploadDocument() {
    for (let i = 0; i < this.selectedFiles.length; i++) {
      if (this.selectedFiles.item(i).size === 0 || this.selectedFiles.item(i).size <= this.maxFileSize) {
        this.file = this.selectedFiles.item(i);
      } else {
        this.invalidFileSize = true;
      }
    }
    if (!this.invalidFileSize) {
        this.dataDocumentService.uploadDocuments(this.attachedTo, this.hhhId, this.selectedFiles).subscribe(() => {
          this.sharedService.showSuccess();
          this.callback();
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        });

    }
    this.displayFileDialog = false;
  }

  selectFile(event) {
    this.invalidFileSize = false;
    this.selectedFiles = event.target.files;
  }

  onAdd(callback: () => void) {
    this.callback = callback;
  }

}

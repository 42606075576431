import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {VitalSign} from '../../../../entity/inclusion/vital-sign';
import {VisitService} from '../../../../service/visit.service';
import {SharedService} from '../../../../../shared/services/shared.service';
import {VitalSignsService} from '../../../../service/vital-signs.service';
import {FormComponent} from '../../../../../shared/component/form/form.component';
import {Data} from '../../../../../shared/entity/data';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../dynamic-config/entity/form-config';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../../trial-app-data';

@Component({
  selector: 'ih-vital-signs-form',
  templateUrl: './vital-signs-form.component.html',
  styleUrls: ['./vital-signs-form.component.css']
})
export class VitalSignsFormComponent extends FormComponent<VitalSign> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_VITAL_SIGNS_FORM;
  config: FormConfig = new FormConfig();

  vitalSign: VitalSign = new VitalSign();
  noYesOptions: any;

  constructor(
      private visitService: VisitService,
      private vitalSignsService: VitalSignsService,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(VitalSignsFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormConfig();
    this.noYesOptions = Data.yesOrNoOrNeither;
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  submit() {
    this.submitted = true;
    if (!this.vitalSign.isValid(this.config)) {
      return;
    }
    if (!this.vitalSign.hhhId) {
      this.addVitalSign();
    } else {
      this.updateVitalSign();
    }
    this.callback(this.vitalSign);
    this.display = false;
  }

  private updateVitalSign() {
    this.vitalSignsService.updateVitalSigns(this.vitalSign).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  private addVitalSign() {
    this.vitalSignsService.addVitalSign(this.vitalSign).subscribe(() => {
      this.sharedService.showSuccess();
    });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
}

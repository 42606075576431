import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TrialContactableMember} from '../../../models/trial-contactable-member';
import {TrialContactsService} from '../../../service/trial-contacts.service';
import {Router} from '@angular/router';
import {Contact} from '../../../../shared/entity/contact';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {DataService} from '../../../../shared/services/data-service';
import {TrialContactFormComponent} from '../trial-contact-form/trial-contact-form.component';
import {TrialService} from '../../../services/trial.service';
import {TrialContactUpdateFormComponent} from '../trial-contact-update-form/trial-contact-update-form.component';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {TableButtonConfig} from '../../../../dynamic-config/entity/table-button-config';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-contact-list-information',
  templateUrl: './contact-list-information.component.html',
  styleUrls: ['./contact-list-information.component.css']
})
export class ContactListInformationComponent extends PaginatorTableComponent<TrialContactableMember> implements OnInit, OnDestroy {


  _entity = AttachedToEntityEnum.TrialContact;
    config: TableConfig = new TableConfig(new Map(), new Map());

  @ViewChild('addTrialContactDialog', {read: ViewContainerRef}) addDialogueContainer: ViewContainerRef;

  headers: DynamicTableHeader[] = [];
  contactClicked = false;
  trialId: number;
  contact: Contact = new Contact();

  constructor(
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private trialContactService: TrialContactsService,
    public trialService: TrialService,
    private dataService: DataService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.dataService.setExportPageModule('src/app/trial/contacts-information/contact-list-information/contact-list-information.component');
    this.trialContactService.trialHhhId = this.trialId;
    this.getTrialContactableMembers();
    this.initProperties();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.buildTableHeaders();
  }

  buildTableHeaders() {
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('providerType')
      .header('MODULE_TRIALS_CONTACTABLE_MEMBERS_DIALOG_ADD_CONTACTABLE_MEMBER_TYPE_PROVIDER')
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('provider')
      .header('MODULE_TRIALS_CONTACTABLE_MEMBERS_DIALOG_ADD_CONTACTABLE_MEMBER_PROVIDER')
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('contact')
      .header('MODULE_TRIALS_CONTACTABLE_MEMBERS_DIALOG_ADD_CONTACTABLE_MEMBER')
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('function')
      .header('MODULE_CONTACTABLE_MEMBERS_LIST_FUNCTION')
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('confidentialityAgreementSigned')
      .header('MODULE_TRIALS_CONTACTS_CONFIDENTIALITY_AGREEMENT_SIGNED')
      .isTranslated()
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('confidentialityAgreementDate')
      .header('MODULE_TRIALS_CONTACTS_CONFIDENTIALITY_AGREEMENT_DATE')
      .withDateFormatter()
      .build());
    const editBtnConfig = new TableButtonConfig();
    const deleteBtnConfig = new TableButtonConfig();
    const publicCommentBtnConfig = new TableButtonConfig();
    const privateCommentBtnConfig = new TableButtonConfig();
    editBtnConfig.display = true;
    deleteBtnConfig.display = true;
    publicCommentBtnConfig.display = true;
    privateCommentBtnConfig.display = true;
    this.config.buttons.set('edit-btn', editBtnConfig);
    this.config.buttons.set('delete-btn', deleteBtnConfig);
    this.config.buttons.set('public-comment-btn', publicCommentBtnConfig);
    this.config.buttons.set('private-comment-btn', privateCommentBtnConfig);
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  getAddTitle(): string {
    return 'MODULE_TRIALS_CONTACTABLE_MEMBERS_FIELD_PROVIDER_TYPE';
  }

  getDeleteMessage(): string {
    return 'MODULE_TRIALS_CONTACTABLE_MEMBERS_DIALOG_WARNING_TRIAL_CONTACTABLE_MEMBER_LIAISON_WILL_BE_DELETED';
  }

  getService(): any {
    return this.trialContactService;
  }

  getEntityName(): any {
    return 'TrialContactableMember';
  }

  getTrialContactableMembers() {
    this.trialContactService.getTrialContactableMembers(this.trialId).subscribe(res => {
      this.values = res;
      this.totalNumber = this.values.length;
      this.trialContactService.trialContactableMembers = this.values;
    });
  }

  getContact(hhhId: number) {
    this.contactClicked = true;
    this.trialContactService.getContacts(hhhId).subscribe(res => {
      this.contact = res;
    });
  }

  displayFormDialogue(trialContactableMember ?: TrialContactableMember) {

    if (trialContactableMember) {
      const addPopupFactory = this.cfr.resolveComponentFactory(TrialContactUpdateFormComponent);
      const addPopupComponentRef = this.addDialogueContainer.createComponent(addPopupFactory);
      const trialContactFormComponent = addPopupComponentRef.instance;
      trialContactFormComponent.trialContactableMember = trialContactableMember;
      trialContactFormComponent.onAdd((member: TrialContactableMember) => {
        const index = this.values.findIndex((item) => item.hhhId === member.hhhId);
        this.values[index] = member;
      });
    } else {
      const addPopupFactory = this.cfr.resolveComponentFactory(TrialContactFormComponent);
      const addPopupComponentRef = this.addDialogueContainer.createComponent(addPopupFactory);
      const trialContactFormComponent = addPopupComponentRef.instance;
      trialContactFormComponent.onAdd((member: TrialContactableMember) => {
        this.values.push(member);
        this.totalNumber++;
      });
    }

  }

  displayDeleteTrialContactDialogue(contact: TrialContactableMember) {
    this.displayDeleteDialogue(contact, this.trialId);
  }

  refreshAfterDelete(entity?: any): void {
    const index = this.values.findIndex((contact: TrialContactableMember) => contact.hhhId === entity.hhhId);
    this.values.splice(index, 1);
  }


}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {CounterpartReference} from '../models/counterpart-reference';
import {DataService} from '../../shared/services/data-service';
import {ReferenceFixedCost} from '../models/referenceFixedCost';
import {SelectItem} from 'primeng/api';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CounterpartCategoryService} from '../services/counterpart-category.service';
import {NoSelectedItemFormComponent} from '../../modals/no-selected-item-form/no-selected-item-form.component';
import {DeleteMultipleComponent} from '../../modals/delete-multiple/delete-multiple.component';
import {CounterPartReferenceFormComponent} from '../counter-part-reference-form/counter-part-reference-form.component';
import {Data} from '../../shared/entity/data';
import {CounterpartReferenceService} from '../services/counterpart-reference.service';
import {TrialCounterpartService} from '../../trial/service/trial-counterpart.service';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {UsersService} from '../../admin/services/users.service';
import {SharedService} from '../../shared/services/shared.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {AppData} from '../../helpers/app-data';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-counter-part-reference',
  templateUrl: './counter-part-reference.component.html',
  styleUrls: ['./counter-part-reference.component.css']
})
export class CounterPartReferenceComponent extends PaginatorTableComponent<CounterpartReference> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.CounterpartReference;
  target = DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_COUNTER_PART_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  @ViewChild('popupDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  trialHhhId: number;
  counterPartReferenceCheckedList: ReferenceFixedCost[] = [];
  descriptionFilterList: SelectItem[] = [];
  noYesOptions: SelectItem[];
  filterVar: string;
  counterPartCategoryFilterList: SelectItem[] = [];
  invoiceableFilterList: SelectItem[] = [];
  attachedToVisitFilterList: SelectItem[] = [];


  constructor(
      private cfr: ComponentFactoryResolver,
      private dataService: DataService,
      private useService: UsersService,
      private router: Router,
      private translate: TranslateService,
      private translateService: InnohealthTranslateService,
      private counterPartsCategoriesService: CounterpartCategoryService,
      private counterPartsReferencesService: CounterpartReferenceService,
      private trialCounterPartService: TrialCounterpartService,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getCounterParts();
    this.getNoYesOptions();
    this.trialHhhId = +localStorage.getItem('trialHhhId');
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, AdditionalCostsAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('counterPartCategoryName')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.counterPartCategoryFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('description')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.descriptionFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('attachedToVisit')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.attachedToVisitFilterList)
        .optionFilterable()
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('invoiceable')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.invoiceableFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('pricePerUnit')
        .withDisplayCssClassContent()
        .cssClass((() => {
          return 'right-align';
        }))
        .displayContent((data: ReferenceFixedCost) => {
          return Number(data.pricePerUnit).toFixed(2) + ' €';
        })
        .sortable()
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('internalAccountNumber')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('firstSegment')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('secondSegment')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('freeSS')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.invoiceableFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('secondInternalAccountNumber')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/additional-costs/counter-parts.component';
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.setItem('trialHhhId', '0');
  }

  addCounterPart() {
    if (this.trialHhhId === 0) {
      this.displayFormDialogue();
    } else {
      this.addCounterPartToTrial();
    }
  }

  getNoYesOptions() {
    this.translate.stream('GENERAL_LINGUISTICS_YES_UFCASE').subscribe(() => {
      this.noYesOptions = Data.yesOrNoOrNeither;
    });
  }

  addCounterPartToTrial() {
    const counterPartsHhhId = [];
    for (const counterPart of this.counterPartReferenceCheckedList) {
      counterPartsHhhId.push(counterPart.hhhId);
    }
    this.trialCounterPartService.addCounterPartToTrial(this.trialHhhId, counterPartsHhhId).subscribe(() => {
      this.router.navigate(
          ['/trial-details/counter-parts'],
          {queryParams: {hhhId: this.trialHhhId}}
      ).then();
    });
  }

  getCounterParts() {
    this.counterPartsReferencesService.loadList().subscribe(res => {
          this.counterPartsReferencesService.setCounterPartReferenceListToExport(res);
          if (!this.filterVar || this.filterVar === '') {
            this.values = res;
          } else {
            this.values = res.filter((a: CounterpartReference) => (a.counterPartCategoryName === this.filterVar));
            this.dataService.changeFilterVar(null);
          }
          this.updateDropdownOptions(res);
        }, error => {
          console.error('An error occurred while loading fixed cost ref reference.' + error);
        }
    );
  }

  updateDropdownOptions(event) {
    for (const counterPart of event) {
      const counterPartCategoryItem = {
        label: counterPart.counterPartCategoryName,
        value: counterPart.counterPartCategoryName
      };
      const descItem = {label: counterPart.description, value: counterPart.description};
      const invoiceableItem = {
        label: counterPart.invoiceable !== null ? this.translateService.getTranslationString(counterPart.invoiceable.toString()) : '',
        value: counterPart.invoiceable
      };
      const attachedToVisitItem = {
        label: counterPart.attachedToVisit !== null ? this.translateService.getTranslationString(counterPart.attachedToVisit.toString()) : '',
        value: counterPart.attachedToVisit
      };
      if (counterPart.counterPartCategoryName !== null &&
          this.counterPartCategoryFilterList.findIndex((item: SelectItem) => item.value === counterPartCategoryItem.value) === -1) {
        this.counterPartCategoryFilterList.push(counterPartCategoryItem);
      }
      if (counterPart.description !== null &&
          this.descriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
        this.descriptionFilterList.push(descItem);
      }
      if (counterPart.invoiceable !== null &&
          this.invoiceableFilterList.findIndex((item: SelectItem) => item.value === invoiceableItem.value) === -1) {
        this.invoiceableFilterList.push(invoiceableItem);
      }
      if (counterPart.attachedToVisit !== null &&
          this.attachedToVisitFilterList.findIndex((item: SelectItem) => item.value === attachedToVisitItem.value) === -1) {
        this.attachedToVisitFilterList.push(attachedToVisitItem);
      }
    }
    this.counterPartCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.descriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.invoiceableFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.attachedToVisitFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  updateSelectedList(selectedList: ReferenceFixedCost[]) {
    this.counterPartReferenceCheckedList = selectedList;
  }

  openDeleteCounterPartReferenceDialog() {
    if (this.counterPartReferenceCheckedList.length === 0) {
      this.displayNoCounterPartSelected();
    } else {
      this.displayDeleteMultiple();
    }
  }

  displayDeleteMultiple() {
    const formComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    formComponent.service = this.counterPartsReferencesService;
    formComponent.popupMessage = 'MODULE_TRIALS_FIXED_COSTS_WARNING_SELECTED_COUNTER_PARTS_WILL_BE_DELETED';
    formComponent.selectedList = this.counterPartReferenceCheckedList;
    formComponent.navigationRoute = '/trial-details/counter-parts';
    formComponent.onDelete((deletedItem: number[]) => {
      for (const hhhId of deletedItem) {
        const index = this.values.findIndex((counterPartReference: CounterpartReference) => counterPartReference.hhhId === hhhId);
        this.sharedService.setInformationInLocalStorage('Grille budgétaire: Contreparties', 'Supprimer', this.values[index].description);
        this.values.splice(index, 1);
      }
    });
  }

  displayNoCounterPartSelected() {
    const formComponent = NoSelectedItemFormComponent.displayFormDialogue(this.popupContainer, this.cfr);
    formComponent.header = 'UI_DIALOG_HEADER_NO_COUNTER_PART_SELECTED';
  }

  displayFormDialogue(referenceCounterPart?: CounterpartReference) {
    const formComponent = CounterPartReferenceFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.noYesOptions = this.noYesOptions;
    if (referenceCounterPart) {
      const referenceCounterPartToEdit = new CounterpartReference().init(referenceCounterPart);
      formComponent.oldReferenceCounterPart = referenceCounterPartToEdit;
      formComponent.reference = new CounterpartReference().init(referenceCounterPart);
      formComponent.onAdd(this.updateList(referenceCounterPart));
    } else {
      formComponent.onAdd(this.updateList());
    }
  }

  updateList(referenceCounterPartToEdit?: CounterpartReference) {
    if (referenceCounterPartToEdit) {
      return (newReferenceCounterPart: CounterpartReference) => {
        const index = this.values.indexOf(referenceCounterPartToEdit);
        this.values[index] = newReferenceCounterPart;
        this.getCounterParts();
      };
    } else {
      return (newReferenceCounterPart: CounterpartReference) => {
        this.values.push(newReferenceCounterPart);
        this.values.sort((a: CounterpartReference, b: CounterpartReference) => a.counterPartCategoryName.toLowerCase().localeCompare(b.counterPartCategoryName.toLowerCase()));
        const item = {label: newReferenceCounterPart.description, value: newReferenceCounterPart.description};
        this.descriptionFilterList.push(item);
        this.descriptionFilterList.sort((a, b) => (a.label.toLowerCase().localeCompare(b.label.toLowerCase())));
        this.getCounterParts();
      };
    }
  }

  exportCounterPartReferences(event: any): void {
    const request = new ExportRequestBuilder<void>()
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
        .build();
    this.counterPartsReferencesService.exportCounterPartReference(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ih-administrative-feasibility-icon',
  templateUrl: './administrative-feasibility-icon.component.html',
  styleUrls: ['./administrative-feasibility-icon.component.css']
})
export class AdministrativeFeasibilityIconComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }

}

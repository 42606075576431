import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MinimizedTrialProtocol} from '../../../../models/minimized-trial-protocol';
import {TrialProtocol} from '../../../../models/trialProtocol';
import {Router} from '@angular/router';
import {InnohealthTranslateService} from '../../../../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {TrialProtocolService} from '../../../../services/trial-protocol.service';
import {AmendmentProtocolFormComponent} from '../amendment-protocol-form/amendment-protocol-form.component';
import {DeleteConfirmationComponent} from '../../../../../modals/delete-confirmation/delete-confirmation.component';
import {TrialObservablesService} from '../../../../services/trial-observables.service';
import {TrialService} from '../../../../services/trial.service';
import {TrialPageLockService} from '../../../../../dynamic-config/exported/page-lock/trial-page-lock.service';

@Component({
  selector: 'ih-amendment-protocols',
  templateUrl: './amendment-protocols.component.html',
  styleUrls: ['./amendment-protocols.component.css']
})
export class AmendmentProtocolsComponent implements OnInit {

  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;
  @ViewChild('displayDeleteDialog', {read: ViewContainerRef}) deleteDialogContainer: ViewContainerRef;

  trialId: number;
  trialAmendmentList: MinimizedTrialProtocol[] = [];
  amendment: TrialProtocol = new TrialProtocol();

  constructor(
    private cfr: ComponentFactoryResolver,
    private router: Router,
    private translateService: InnohealthTranslateService,
    private datePipe: DatePipe,
    public trialService: TrialService,
    private observablesService: TrialObservablesService,
    private trialProtocolService: TrialProtocolService,
    public trialPageLockService: TrialPageLockService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.getTrialProtocolList();
  }

  getAddTitle(): string {
    return this.translateService.getTranslationString('MODULE_TRIALS_AMENDMENT_PROTOCOL_ADD_BTN_LABEL');
  }

  getUpdateTitle(): string {
    return this.translateService.getTranslationString('MODULE_TRIALS_AMENDMENT_PROTOCOL_AMENDMENT');
  }

  getTrialProtocolList() {
    this.trialProtocolService.getTrialProtocolList(this.trialId).subscribe(res => {
      this.trialAmendmentList = res;
    }, error => {
      console.log('An error occurred while loading amendment list');
    });
  }

  displayAddTrialAmendmentDialogue(amendment?: MinimizedTrialProtocol) {
    const formComponent = AmendmentProtocolFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (amendment) {
      formComponent.trialProtocol = amendment;
      formComponent.formHeader = this.getUpdateTitle();
      formComponent.onAdd((trialAmendment: MinimizedTrialProtocol) => {
        const index = this.trialAmendmentList.findIndex((amendment1: MinimizedTrialProtocol) => amendment1.hhhId === trialAmendment.hhhId);
        this.trialAmendmentList[index] = trialAmendment;
      });
    } else {
      formComponent.formHeader = this.getAddTitle();
      formComponent.onAdd((trialAmendment: MinimizedTrialProtocol) => {
        this.trialAmendmentList.push(trialAmendment);
      });
    }
    formComponent.trialProtocols = this.trialAmendmentList;
  }

  displayDeleteDialogueOfDeletableEntity(amendment: MinimizedTrialProtocol) {
    const deleteConfirmationComponent = DeleteConfirmationComponent.displayDeleteConfirmationDialogue(this.deleteDialogContainer, this.cfr);
    deleteConfirmationComponent.hhhId = amendment.hhhId;
    deleteConfirmationComponent.name = amendment.version;
    deleteConfirmationComponent.popupMessage = this.translateService.getTranslationString('MODULE_TRIALS_AMENDMENT_PROTOCOL_DELETE_MESSAGE');
    deleteConfirmationComponent.service = this.trialProtocolService;
    deleteConfirmationComponent.onDelete(() => {
      this.observablesService.onProtocolSave.emit();
      this.refreshAfterDelete(amendment);
    });
  }

  refreshAfterDelete(entity?: any): void {
    const index = this.trialAmendmentList.findIndex((trialAmendment: MinimizedTrialProtocol) => trialAmendment.hhhId === entity.hhhId);
    this.trialAmendmentList.splice(index, 1);
  }

  transformDate(date: Date): string {
    if (date !== null) {
      return this.datePipe.transform(date, 'dd/MM/yyyy');
    } else {
      return null;
    }
  }

  checkDescriptionlength(description: string): string {
    if (description.length > 50) {
      return description.slice(0, 50).concat('...');
    } else {
      return description;
    }

  }

}

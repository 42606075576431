import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {FR} from '../../../../../shared/entity/calendar-language';
import {Router} from '@angular/router';
import {TrialConsent} from '../../../../models/trial-consent';
import {TrialConsentService} from '../../../../services/trial-consent.service';
import {SharedService} from '../../../../../shared/services/shared.service';
import {LabelValue} from '../../../../../shared/entity/label-value';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../../trial-app-data';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {FormComponent} from '../../../../../shared/component/form/form.component';

@Component({
  selector: 'ih-amendment-consent-form',
  templateUrl: './amendment-consent-form.component.html',
  styleUrls: ['./amendment-consent-form.component.css']
})
export class AmendmentConsentFormComponent extends FormComponent<TrialConsent> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_AMENDMENT_CONSENT_FORM;
  config: FormConfig = new FormConfig();

  trialId: number;
  display = true;
  consent: TrialConsent = new TrialConsent();
  trialConsentList: TrialConsent[];
  locale = FR;
  formHeader: string;
  protocols: LabelValue[];
  submitted: boolean;

  constructor(
    private router: Router,
    private trialConsentService: TrialConsentService,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super();
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;

    }
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(AmendmentConsentFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.consent.trialHhhId = this.trialId
    if (this.consent.trialProtocol === null) {
      this.consent.trialProtocol = new LabelValue();
    }
    if (this.consent.trialAmendment == null) {
      this.consent.trialAmendment = new LabelValue();
    }
    if (this.consent.documentType === null) {
      this.consent.documentType = new LabelValue();
    }
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  saveConsent() {
    this.submitted = true;
    if (!this.consent.isValid(this.config)) {
      return;
    }
    if (!this.consent.validate()) {
      this.handleValidationFailure();
      return;
    }
    if (this.consentAlreadyExist()) {
      this.sharedService.showWarning('MODULE_TRIAL_TRIAL_CONSENT_ALREADY_EXIST');
      return;
    }

    this.trialConsentService.save(this.consent).subscribe(
      res => {
        this.handleSaveSuccess(res);
      },
      error => {
        this.handleSaveError(error);
      }
    );
  }

  private handleValidationFailure() {
    this.sharedService.showWarning('MODULE_TRIAL_TRIAL_CONSENT_EMPTY');
  }
  private handleSaveSuccess(res: any) {
    this.callback(res);
    this.sharedService.showSuccess();
    this.display = false;
  }
  private handleSaveError(error: any) {
    let message: string;
    switch (error.toString()) {
      case 'Trial consent does not exist for update.':
        message = 'MODULE_TRIAL_TRIAL_CONSENT_NOT_EXIST';
        break;
      case 'Trial consent already exists.':
        message = 'MODULE_TRIAL_TRIAL_CONSENT_ALREADY_EXIST';
        break;
      case 'Document type, version, and name cannot be null or empty.':
        message = 'MODULE_TRIAL_TRIAL_CONSENT_EMPTY';
        break;
      default:
        message = 'An error occurred';
        break;
    }
    this.sharedService.showFailure(message);
  }

  initProtocol(type: string) {
    if (type === 'AMENDMENT') {
      this.consent.trialProtocol = new LabelValue();
    } else {
      this.consent.trialAmendment = new LabelValue();
    }
  }

  private consentAlreadyExist(): boolean {
    if (this.consent.hhhId) {
      return false;
    }
    return this.trialConsentList.some(consent => this.isConsentDuplicate(consent));
  }

  private isConsentDuplicate(consent: TrialConsent): boolean {
    const documentTypeMatch =
      (
        (consent.documentType == null || consent.documentType.value == null) &&
        (this.consent.documentType == null || this.consent.documentType.value == null)
      ) ||
      (
        consent.documentType &&
        this.consent.documentType &&
        consent.documentType.value === this.consent.documentType.value
      );

    const documentVersionMatch =
      (!consent.documentVersion && !this.consent.documentVersion) ||
      (consent.documentVersion && this.consent.documentVersion &&
        consent.documentVersion.trim() === this.consent.documentVersion.trim());

    const documentNameMatch =
      (!consent.documentName && !this.consent.documentName) ||
      (consent.documentName && this.consent.documentName &&
        consent.documentName.trim() === this.consent.documentName.trim());

    return documentTypeMatch && documentVersionMatch && documentNameMatch;
  }


  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);
}

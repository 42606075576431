import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {TrialSite} from '../../trial/models/trial-site';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {IsDeletable} from '../../is-deletable';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {catchError, map} from 'rxjs/operators';
import {InvoiceAct} from '../../invoice/invoice-acts/models/invoice-act';

@Injectable({
  providedIn: 'root'
})
export class TrialSiteService {

  public properties: Properties = new Properties();
  public host = this.properties.host + '/trial/site';
  public trialHhhId: number;

  constructor(private httpClient: HttpClient) {
  }

  getTrialSites(hhhId: number): Observable<TrialSite[]> {
    return this.httpClient.get<TrialSite[]>(this.host + '/load-list/' + hhhId)
      .pipe(map(result => {
        try {
          result = result.map(trialSite => new TrialSite().init(trialSite));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  addSiteToTrial(trialHhhId: number, siteHhhId: number): Observable<number> {
    return this.httpClient.get<number>(this.host + '/save/' + trialHhhId + '/' + siteHhhId);
  }

  addNewSiteToTrial(trialHhhId: number, name: string): Observable<number> {
    return this.httpClient.get<number>(this.host + '/add-new/' + trialHhhId + '/' + name);
  }

  deleteSiteToTrial(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  exportTrialSites(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>( this.host + '/export-csv', request).pipe();
  }
  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {TrialMethodologyIdentificationInformation} from '../models/trial-methodology-identification-information';
import {Value} from '../../shared/entity/value';
import {MinimizedTrialProtocol} from '../models/minimized-trial-protocol';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrialTreatmentMethodologyService {

  private host = new Properties().host + '/trial-treatment-methodology';

  private trialIdentificationInfo: Subject<TrialMethodologyIdentificationInformation> = new BehaviorSubject<TrialMethodologyIdentificationInformation>(null);
  trialIdentificationInfoValue = this.trialIdentificationInfo.asObservable();

  constructor(private httpClient: HttpClient) {
  }

  setTrialIdentificationInfo(value) {
    this.trialIdentificationInfo.next(value);
  }

  load(trialId: number, protocolId: number): Observable<TrialMethodologyIdentificationInformation> {
    if (protocolId === undefined) {
      protocolId = -1;
    }
    return this.httpClient.get<TrialMethodologyIdentificationInformation>(this.host + '/load/' + trialId + '/' + protocolId).pipe(
      map(res => {
          return (new TrialMethodologyIdentificationInformation()).init(res);
        }
      ),
      catchError(this.handleError));
  }

  save(criteria: TrialMethodologyIdentificationInformation): Observable<TrialMethodologyIdentificationInformation> {
    return this.httpClient.post<TrialMethodologyIdentificationInformation>(this.host + '/save', criteria);
  }

  existsByTrialId(trialId: number): Observable<Value<boolean>> {
    return this.httpClient.get<Value<boolean>>(this.host + '/exists-for-last-protocol-by-trial/' + trialId);
  }

  getRelatedProtocolsByTrial(trialId: number): Observable<MinimizedTrialProtocol[]> {
    return this.httpClient.get<MinimizedTrialProtocol[]>(this.host + '/load-used-protocols/' + trialId);
  }

  getBlinded(trialId: number): Observable<boolean>{
    return this.httpClient.get<boolean>(this.host + '/load-blinded/' + trialId);
  }

  getTargetOfTreatmentPrincipalHhhIdByTreatmentPrincipal(targetOfTreatmentExperimentalPrincipalHhhId: number): Observable<number> {
    return this.httpClient.get<number>(this.host + '/target-of-trial-treatment/' + targetOfTreatmentExperimentalPrincipalHhhId);
  }

  getTargetOfTreatmentHhhIdByTreatmentPrincipal(targetOfTreatmentExperimentalHhhIds: number[]): Observable<number[]> {
    return this.httpClient.put<number[]>(this.host + '/target-of-trial-treatment-list', targetOfTreatmentExperimentalHhhIds);
  }
  getNAValues(): Observable<Map<string, number>> {
    return this.httpClient.get<Map<string, number>>(this.host + '/loadNA');
  }
  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

export class InvoiceHeader {
  public hhhId: number;
  public publicCommentCount: number;
  public invoiceNumber: string;
  public amountTotal: number;
  public fees: number;
  public totalWithManagementFees: number;
  public totalWithTVA: number;
  public manualStaticInvoiceTotalAmount: number;
}

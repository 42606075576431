import {Arm} from '../entity/inclusion/arm';
import {DesignStructureConfig} from './design-structure-config';
import {AbstractDesignLevelDto} from './abstract-design-level-dto';
import {TreeNode} from 'primeng/api';

type NodeAddingFunction = (node: ArmTreeNode) => void;

export class ArmTreeNode {
  hhhId: any;
  fieldName: string;
  value: string;
  level: number;
  active: boolean;
  armDto: Arm;
  nodes: Array<ArmTreeNode> = new Array<ArmTreeNode>();

  private nextLevel = -1;

  public addNode(arm: Arm, config: DesignStructureConfig) {
    if (this.nextLevel < 0) {
      this.nextLevel = this.level + 1;
    }
    if (config.phase.level === this.nextLevel) {
      const targetValue = (arm.phase && arm.phase.enabled && arm.phase.phase) ? arm.phase.phase.nameFr : '';
      this.processAddingFlatNode(targetValue, arm, config);
    } else if (config.cohort.level === this.nextLevel) {
      const targetValue = (arm.cohort && arm.cohort.enabled && arm.cohort.cohort) ? arm.cohort.cohort.name : '';
      this.processAddingFlatNode(targetValue, arm, config);
    } else if (config.escaladeExtension.level === this.nextLevel) {
      const targetValue = (arm.escaladeExtension && arm.escaladeExtension.enabled) ? arm.escaladeExtension.escaladeExtension : '';
      this.processAddingFlatNode(targetValue, arm, config);
    } else if (config.inclusionArm.level === this.nextLevel) {
      const targetValue = (arm.inclusionArm && arm.inclusionArm.enabled && arm.inclusionArm.inclusionArm) ? arm.inclusionArm.inclusionArm.name : '';
      this.processAddingFlatNode(targetValue, arm, config);
    } else {
      this.addNodeFromAbstractDesignLevels(arm, 0);
    }
  }

  private processAddingFlatNode(targetValue: string, arm: Arm, config: DesignStructureConfig) {
    if (!targetValue || targetValue.length === 0) {
      this.nextLevel++;
      this.addNode(arm, config);
      return;
    }
    const targetNode = this.nodes.find(n => n.value === targetValue);
    const newTreeNode = this.constructTreeNode(targetValue, arm, node => node.addNode(arm, config));
    if (targetNode && !(targetNode.nodes.length === 0 || newTreeNode.nodes.length === 0)) {
      targetNode.addNode(arm, config);
    } else {
      this.nodes.push(newTreeNode);
    }
  }

  private addNodeFromAbstractDesignLevels(arm: Arm, designLevelsIndex: number) {
    if (designLevelsIndex >= arm.designLevels.length) {
      return;
    }
    if (this.nextLevel < 0) {
      this.nextLevel = this.level + 1;
    }
    const nextDesignLevel = arm.designLevels[designLevelsIndex];
    if (nextDesignLevel.type === 'TREATMENT') {
      this.addNodeFromArmTreatment(arm, designLevelsIndex, 0);

    } else if (nextDesignLevel.type === 'LIAISON') {
      this.addNodeFromSimpleLiaison(nextDesignLevel, arm, designLevelsIndex);
    }
  }

  private addNodeFromSimpleLiaison(nextDesignLevel: AbstractDesignLevelDto, arm: Arm, designLevelsIndex: number) {
    const targetValue = nextDesignLevel.fieldValue;
    if (!targetValue || targetValue.length === 0) {
      this.nextLevel++;
      this.addNodeFromAbstractDesignLevels(arm, designLevelsIndex + 1);
      return;
    }
    const targetNode = this.nodes.find(n => n.value === targetValue);
    const newNode = this.constructTreeNode(targetValue, arm, node => node.addNodeFromAbstractDesignLevels(arm, designLevelsIndex + 1));
    if (targetNode && !(targetNode.nodes.length === 0 || newNode.nodes.length === 0)) {
      targetNode.addNodeFromAbstractDesignLevels(arm, designLevelsIndex + 1);
    } else {
      this.nodes.push(newNode);
    }
  }

  private addNodeFromArmTreatment(arm: Arm, designLevelsIndex: number, treatmentIndex: number) {
    if (this.nextLevel < 0) {
      this.nextLevel = this.level + 1;
    }
    const designTreatment = arm.designLevels[designLevelsIndex];
    if (treatmentIndex === 0) {
      const targetValue = (designTreatment.studiedMolecule && designTreatment.studiedMolecule.studiedMolecule && designTreatment.studiedMolecule.enabled)
        ? designTreatment.studiedMolecule.studiedMolecule.name : '';
      this.processAddingSubTreatment(targetValue, arm, designLevelsIndex, treatmentIndex);
    } else if (treatmentIndex === 1) {
      const targetValue = (designTreatment.administrationRoute && designTreatment.administrationRoute.administrationRoute && designTreatment.administrationRoute.enabled)
        ? designTreatment.administrationRoute.administrationRoute.name : '';
      this.processAddingSubTreatment(targetValue, arm, designLevelsIndex, treatmentIndex);
    } else if (treatmentIndex === 2) {
      const targetValue = (designTreatment.dose && designTreatment.dose.enabled)
        ? designTreatment.dose.dose : '';
      this.processAddingSubTreatment(targetValue, arm, designLevelsIndex, treatmentIndex);
    } else {
      this.addNodeFromAbstractDesignLevels(arm, designLevelsIndex + 1);
    }
  }

  private processAddingSubTreatment(targetValue: string, arm: Arm, designLevelsIndex: number, treatmentIndex: number) {
    if (!targetValue || targetValue.length === 0) {
      this.nextLevel++;
      this.addNodeFromArmTreatment(arm, designLevelsIndex, treatmentIndex + 1);
      return;
    }
    const targetNode = this.nodes.find(n => n.value === targetValue);
    const newNode = this.constructTreeNode(targetValue, arm, node => node.addNodeFromArmTreatment(arm, designLevelsIndex, treatmentIndex + 1));
    if (targetNode && !(targetNode.nodes.length === 0 || newNode.nodes.length === 0)) {
      targetNode.addNodeFromArmTreatment(arm, designLevelsIndex, treatmentIndex + 1);
    } else {
      this.nodes.push(newNode);
    }
  }

  private constructTreeNode(targetValue: string, arm: Arm, f: NodeAddingFunction): ArmTreeNode {
    const newNode = new ArmTreeNode();
    newNode.value = targetValue;
    newNode.level = this.nextLevel;
    newNode.armDto = arm;
    f(newNode);
    return newNode;
  }


  public toTreeNode(): TreeNode {
    const node: TreeNode = {
      data: {
        level: this.level,
        value: this.value,
        arm: this.armDto,
        leaf: false
      },
      children: [],
      expanded: true
    };
    for (const child of this.nodes) {
      const childNode = child.toTreeNode();
      node.children.push(childNode);
    }
    if (node.children.length === 0) {
      node.data.value = node.data.arm.name;
      node.data.leaf = true;
    }
    return node;
  }

}

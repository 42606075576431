import {Directive, DoCheck, ElementRef, Input, Renderer2} from '@angular/core';
import {FormInputConfig} from '../entity/form-input-config';
import {Util} from '../../helpers/util';

@Directive({
  selector: '[ihDynamicForm]'
})
export class DynamicFormDirective implements DoCheck {

  @Input() ihDynamicForm: Map<string, FormInputConfig>;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngDoCheck() {
    this.updateDisplay();
  }

  private updateDisplay() {
    if (!this.ihDynamicForm) {
      return;
    }
    const divs: HTMLDivElement[] = this.elementRef.nativeElement.querySelectorAll('div');
    for (let div of divs) {
      if (div.accessKey.includes('_appended')) continue;
      const id = div.id;
      if (!Util.isStringNullOrEmpty(id) && id[0] === '_') {
        let field = id.substring(1);
        if (field[0] === '%') {
          field = field.substring(1);
        }
        if (!this.displayField(field)) {
          div.remove();
        } else {
          const attachedTo = this.getAttachedTo(field);
          const group = this.getFieldGroup(field);
          this.appendElement(attachedTo, group, div);
          this.setElementOrder(field, div);
          this.setCssClass(field, div);
        }
      }
    }
  }

  private displayField(fieldName: string): boolean {
    if (!this.ihDynamicForm || !this.ihDynamicForm.has(fieldName)) {
      return false;
    }
    return this.ihDynamicForm.get(fieldName).display;
  }

  private setElementOrder(fieldId: string, div: HTMLDivElement): string {
    if (!div) {
      return;
    }
    if (!this.ihDynamicForm || !this.ihDynamicForm.has(fieldId)) {
      return;
    }
    this.renderer.setStyle(div, 'order', this.ihDynamicForm.get(fieldId).order);
  }

  private getAttachedTo(fieldName: string): string {
    if (!this.ihDynamicForm || !this.ihDynamicForm.has(fieldName)) {
      return '';
    }
    return this.ihDynamicForm.get(fieldName).attachedTo;
  }

  private getFieldGroup(fieldName: string): string {
    if (!this.ihDynamicForm || !this.ihDynamicForm.has(fieldName)) {
      return undefined;
    }
    return this.ihDynamicForm.get(fieldName).group;
  }

  private setCssClass(fieldId: string, div: HTMLDivElement): string {
    if (!div) {
      return;
    }
    if (!this.ihDynamicForm || !this.ihDynamicForm.has(fieldId)) {
      return;
    }
    this.renderer.addClass(div, this.ihDynamicForm.get(fieldId).width);
  }

  appendElement(attachedTo: string = null, group: string, div: HTMLDivElement): void {
    if (div.accessKey.includes('_appended')) {
      return;
    }
    if (!Util.isStringNullOrEmpty(attachedTo) && !Util.isStringNullOrEmpty(group)) {
      const attachedToElement = this.elementRef.nativeElement.querySelector(`#_${attachedTo}`);
      const groupElement = this.elementRef.nativeElement.querySelector(`#${attachedTo}_${group}`);
      if (attachedToElement && groupElement) {
        const grid = groupElement.getElementsByClassName('p-grid')[0];
        if (grid) {
          this.renderer.appendChild(grid, div);
          this.renderer.setProperty(div, 'accessKey', `${div.accessKey}_appended`);
        }
      }
    }
    if (!Util.isStringNullOrEmpty(attachedTo) && Util.isStringNullOrEmpty(group)) {
      const attachedToElement = this.elementRef.nativeElement.querySelector(`#_${attachedTo}`);
      if (attachedToElement) {
        const fieldset = attachedToElement.getElementsByClassName('ui-fieldset-content')[0];
        const grid = fieldset ? fieldset.getElementsByClassName('p-grid')[0] : undefined;
        if (grid) {
          this.renderer.appendChild(grid, div);
          this.renderer.setProperty(div, 'accessKey', `${div.accessKey}_appended`);
        }
      }
    }
    if (Util.isStringNullOrEmpty(attachedTo) && !Util.isStringNullOrEmpty(group)) {
      const groupElement = this.elementRef.nativeElement.querySelector(`#${group}`);
      if (groupElement) {
        const grid = groupElement.getElementsByClassName('p-grid')[0];
        if (grid) {
          this.renderer.appendChild(grid, div);
          this.renderer.setProperty(div, 'accessKey', `${div.accessKey}_appended`);
        }
      }
    }
  }

}

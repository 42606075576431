import {Component, Inject, InjectionToken, OnInit} from '@angular/core';
import {Papa} from 'ngx-papaparse';
import {SharedService} from '../../shared/services/shared.service';
import {TrialService} from '../../trial/services/trial.service';
import {Properties} from '../../helpers/properties';
import {InclusionService} from '../../trial/service/inclusion.service';
import {saveAs} from 'file-saver';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {Util} from '../../helpers/util';

export const LOCATION_TOKEN = new InjectionToken<Location>('Window location object');

@Component({
  selector: 'ih-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.css'],
  providers: [
    {provide: LOCATION_TOKEN, useValue: window.location}
  ]
})
export class DataImportComponent implements OnInit {

  inclusionImportIGRMode = false;
  public selectedFiles: FileList;
  public pdfSrc = '';
  public file: File;
  inclusionFile: File;
  host = new Properties().host;
  private rowSeparator = '\r\n';
  submit = false;

  constructor(
      private papa: Papa,
      private trialService: TrialService,
      private dynamicConfigService: DynamicConfigService,
      private inclusionService: InclusionService,
      @Inject(LOCATION_TOKEN) private location: Location,
      private sharedService: SharedService
  ) {
  }

  ngOnInit() {
    this.initProperties();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.inclusionImportIGRMode = this.dynamicConfigService.getProperty(PropertyEnum.inclusionImportIGRMode);
    });
  }

  upload() {
    this.trialService.importGlobal(this.file).subscribe(comments => {
      this.trialService.createImportReport(comments);
      this.sharedService.showFileLoadingSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  uploadInclusions() {
    this.inclusionService.inclusionsImportGeneral(this.file).subscribe(
        (res) => {
          const excelExtension = '.xlsx';
          saveAs(res, 'rapport' + excelExtension);
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure();
          console.log(error);
        }
    );
  }

  onInclusionsFileSelect(event) {
    if (event.target.files.length > 0) {
      this.inclusionFile = event.target.files[0];
    }
  }

  uploadInclusionFile() {
    if (this.inclusionFile) {
      this.inclusionService.patientFileImportIgrMode(this.inclusionFile).subscribe(res => {
        let csvData = '';
        for (const re of res) {
          csvData += (re + this.rowSeparator);
        }
        const BOM = '\uFEFF';
        const blob = new Blob([BOM + csvData], {type: 'text/csv;charset=utf-8'});
        Util.downloadFile(blob, 'Rapport import inclusion');
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  importFile() {
    this.location.assign(this.host + '/' + 'import_inclusion_IGR.csv');
  }
}

export class InvoiceGlobalFilter {
  promoter1HhhId?: number;
  siteHhhId?: number;
  protocolNumber?: string;
  specialityHhhId?: number;
  principalInvestigator?: number;
  state = -1;
  number = '';
  trial = -1;
  amountTotal = '';
  inclusionsNumbers = '';
  visitNames = '';
  issueDate: Date;
  validationDate: Date;
  despatchDate: Date;
  paymentDate: Date;
  establishmentHhhId: number;
  financialEntityHhhId: number;
  inclusionIdentifier2 = '';
  inclusionPreScreeningIdentifier = '';
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LabelValue} from '../../shared/entity/label-value';
import {Field} from '../../admin/models/field';
import {DropdownField} from '../../admin/models/dropdown-field';
import {StompService} from '../../shared/services/stomp.service';
import {map, publishReplay, refCount, tap} from 'rxjs/operators';
import {StompSubscription} from '@stomp/stompjs/src/stomp-subscription';
import {Util} from '../../helpers/util';

@Injectable({
  providedIn: 'root'
})
export class DataItemService {

  public host = new Properties().host + '/data';
  public cache: Map<string, Observable<LabelValue[]>> = new Map();
  dataUpdateSubscription: StompSubscription;

  constructor(
    private httpClient: HttpClient,
    private stompService: StompService
  ) {
    this.subscribeToDataUpdateWebsocket();
  }

  getItems(type: string, sort?: boolean): Observable<LabelValue[]> {
    let items = this.cache.get(type);
    if (items) {
      return items;
    }
    items =  this.httpClient.get<LabelValue[]>(this.host + '/items/' + type).pipe(
      map(res => sort ? Util.sortItems(res) : res),
      publishReplay(1),
      refCount()
    );
    this.setDataCache(type, items);
    return items;
  }

  getCustomFieldList(): Observable<Field[]> {
    return this.httpClient.get<Field[]>(this.host + '/get-custom-fields');
  }
  loadItems(type: string): Observable<LabelValue[]> {
    const cachedItems = this.cache.get(type);
    if (cachedItems) {
      return cachedItems;
    }

    const items = this.httpClient.get<LabelValue[]>(`${this.host}/items/${type}`).pipe(
      publishReplay(1),
      refCount()
    );

    this.setDataCache(type, items);
    return items;
  }


  addField(type: string, newField: DropdownField): Observable<LabelValue[]> {
    return this.httpClient.post<LabelValue[]>(this.host + '/add/' + type, newField).pipe(
      tap(() => this.resetDataCache(type))
    );
  }
  updateSelectedList(type: string, selectedList: number[], subclassSelectedList?: number[]): Observable<void> {
    return this.httpClient.put<void>(this.host + '/update-selection/' + type, { selectedList, subclassSelectedList }).pipe(
      tap(() => this.resetDataCache(type))
    );
  }

  delete(type: string, hhhId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(this.host + '/delete/' + type + '/' + hhhId).pipe(
      tap(() => this.resetDataCache(type))
    );
  }

  private subscribeToDataUpdateWebsocket(): void {
    this.stompService.subscribe('/topic/items/data-update', (type) => {
      try {
        if (type) {
            this.resetDataCache(type);
            console.warn('type: ' + type + ' has been reset');
        }
      } catch (e) {
        console.log(e);
      }
    }, subscription => this.dataUpdateSubscription = subscription);
  }

  resetDataCache(type: string): void {
    if (this.cache.has(type)) {
      this.cache.delete(type);
    }
    if (this.cache.has(type + '-selected')) {
      this.cache.delete(type + '-selected');
    }
  }

  setDataCache(type: string, items: Observable<LabelValue[]>): void {
    if (type.includes('|')) {
      return;
    } else {
      this.cache.set(type, items);
    }
  }


}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TrialService} from '../../../services/trial.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {ContainerConfig} from '../../../../dynamic-config/entity/container-config';
import {TrialAdministrativeInformationService} from '../../../services/trial-administrative-information.service';

@Component({
  selector: 'ih-trial-site-details-container',
  templateUrl: './trial-site-details-container.component.html',
  styleUrls: ['./trial-site-details-container.component.css']
})
export class TrialSiteDetailsContainerComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_SITE_CONTAINER;
  config: ContainerConfig = new ContainerConfig();

  public trialId: number;
  public trialSiteLiaisonHhhId: number;
  currentPath: string;

  constructor(
      private router: Router,
      private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
      this.trialSiteLiaisonHhhId = url.queryParams.trialSiteLiaisonHhhId;
    }
  }

  ngOnInit() {
    this.initContainerConfig();
  }

  initContainerConfig(): void {
    this.dynamicConfigService.getContainerConfig(this.target, TrialAppData.containerConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getCardConfig = (cardName: string) => this.config.getCardConfig(cardName);
  displayCard = (cardName: string) => this.config.displayCard(cardName);
  getCardOrder = (cardName: string) => this.config.getCardOrder(cardName);

  openModule(path: string): void {
    this.currentPath = path;
    this.router.navigate(
        ['/trial-details/trial-site-details-container/' + path],
        {
          queryParams: {
            hhhId: this.trialId,
            trialSiteLiaisonHhhId: this.trialSiteLiaisonHhhId
          }
        }
    ).then();
  }

  isModuleActive(path): boolean {
    return this.currentPath === path;
  }
}

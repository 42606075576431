import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {InclusionInfo} from '../models/inclusion-info';
import {DataService} from '../../shared/services/data-service';
import {PatientService} from '../services/patient.service';
import {Router} from '@angular/router';
import {PatientTrialFormComponent} from '../../modals/patient-add-to-trial-form/patient-trial-form.component';
import {TrialService} from '../../trial/services/trial.service';
import {TRIAL_READ_WRITE} from '../../login/services/authentication.service';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PatientAppData} from '../patient-app-data';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-patient-inclusion-list',
  templateUrl: './patient-inclusion-list.component.html',
  styleUrls: ['./patient-inclusion-list.component.css']
})
export class PatientInclusionListComponent extends PaginatorTableComponent<InclusionInfo> implements OnInit {

  target = DynamicDefinitionEnum.PATIENT_INCLUSION_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  amendmentsEnabled = false;
  inclusionInformationArmEnabled = false;

  @ViewChild('displayAddToTrialForm', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;

  patientId: number;
  disableEdit = false;

  constructor(
      private dataService: DataService,
      private patientService: PatientService,
      private trialService: TrialService,
      private cfr: ComponentFactoryResolver,
      private router: Router,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.patientId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    super.ngOnInit();
    this.getList();
    this.disableEdit = localStorage.getItem(TRIAL_READ_WRITE) !== 'READ_WRITE';
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.inclusionInformationArmEnabled = this.dynamicConfigService.getProperty(PropertyEnum.inclusionInformationArmEnabled);
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, PatientAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('inclusionPreScreeningIdentifier')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('inclusionNumber')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('localIdentifier')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('promoter')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('protocolNumber')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('trialCommittees')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('trialPhase')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('piLastName')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('craLastName')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('trialState')
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getAddTitle(): string {
    return 'MODULE_PATIENTS_TRIALS_DIALOG_ADD_PATIENT_TITLE';
  }

  getService(): any {
    return this.patientService;
  }

  getList() {
    this.patientService.getPatientInfo(this.patientId).subscribe((res: InclusionInfo[]) => {
      this.values = res;
      this.totalNumber = this.values.length;
    }, error => {
      console.error('An error occurred while loading Act Categories.' + error);
    });
  }

  displayFormDialogue() {
    const addDialogFactory = this.cfr.resolveComponentFactory(PatientTrialFormComponent);
    const addDialogComponentRef = this.dialogContainer.createComponent(addDialogFactory);
    const patientTrialFormComponent = addDialogComponentRef.instance;
    patientTrialFormComponent.patientHhhId = this.patientId;
    patientTrialFormComponent.onAdd(() => {
      this.getList();
    });
  }

  getPatientInclusionInfo(inclusionInfo: InclusionInfo) {
    this.dataService.changeInclusionMenu('information-patientTracking-form-patient-inclusion');
    this.trialService.getTrialByInclusion(inclusionInfo.inclusionId).subscribe(trialHhhId => {
      if (this.amendmentsEnabled && !this.inclusionInformationArmEnabled) {
        this.router.navigate(
            ['/patient-inclusion-details/inclusion-information-version-protocol'],
            {queryParams: {inclusionId: inclusionInfo.inclusionId, hhhId: trialHhhId}}
        ).then();
      } else {
        this.router.navigate(
            ['/patient-inclusion-details/inclusion-information-arm'],
            {queryParams: {inclusionId: inclusionInfo.inclusionId, hhhId: trialHhhId}}
        ).then();
      }

    });
  }
}

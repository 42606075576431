export class DataCommentCount {

  commentCount = new Map<string, number>();


  constructor() {
  }

  init(map: any): void {
    for (const key in map) {
      const value = map[key];
      this.commentCount.set(key.replace('PublicCommentCount', ''), value);
    }
  }

  getCommentCount(field: string): number {
    if (this.commentCount.has(field)) {
      return this.commentCount.get(field);
    }
    return 0;
  }

}

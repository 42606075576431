import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';

export class TrialFollowUp {
  hhhId: number;
  date: Date;
  comment: string;
  trialAdministrativeCecInformationHhhId: number;
  countOfPublicComment: number;
  constructor(trialAdministrativeCecHhhId: number) {
    this.trialAdministrativeCecInformationHhhId = trialAdministrativeCecHhhId;
  }
  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'trialFollowUpDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.date)) {
          return false;
        }
      } else if (key === 'trialFollowUpComment') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.comment)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

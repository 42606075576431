import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {ContainerConfig} from '../../../../../dynamic-config/entity/container-config';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../../trial-app-data';

@Component({
  selector: 'ih-follow-up-patient-container',
  templateUrl: './follow-up-patient-container.component.html',
  styleUrls: ['./follow-up-patient-container.component.css']
})
export class FollowUpPatientContainerComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_INCLUSION_PATIENT_FOLLOWUP_CONTAINER;
  config: ContainerConfig = new ContainerConfig();

  trialHhhId: number;
  inclusionHhhId: number;
  actualRoute = '';
  currentPath: string;

  constructor(
      private router: Router,
      private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
      this.inclusionHhhId = url.queryParams.inclusionId;
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const currentUrl = val.url;
        if (currentUrl.indexOf('?') > 0) {
          this.actualRoute = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.indexOf('?'));
        } else {
          this.actualRoute = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.length);
        }
      }
    });
  }

  ngOnInit() {
    this.initContainerConfig();
    this.currentPath = this.actualRoute.substring(this.actualRoute.lastIndexOf('/') + 1, this.actualRoute.length);
  }

  initContainerConfig(): void {
    this.dynamicConfigService.getContainerConfig(this.target, TrialAppData.containerConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getCardConfig = (cardName: string) => this.config.getCardConfig(cardName);
  displayCard = (cardName: string) => this.config.displayCard(cardName);
  getCardOrder = (cardName: string) => this.config.getCardOrder(cardName);


  openPatientFollowUp(path: string) {
    this.currentPath = path;
    const firstPath = this.actualRoute.substring(0, this.actualRoute.indexOf('/'));
    if (firstPath === 'patient-inclusion-details') {
      this.router.navigate(
          ['/patient-inclusion-details/patient-follow-up-container/' + path],
          {queryParams: {hhhId: this.trialHhhId, inclusionId: this.inclusionHhhId}}
      ).then();
    } else {
      this.router.navigate(
          ['/trial-details/inclusion-details/patient-follow-up-container/' + path],
          {queryParams: {hhhId: this.trialHhhId, inclusionId: this.inclusionHhhId}}
      ).then();
    }
  }

  isModuleActive(path) {
    return this.currentPath === path
        || (path === 'adverse-effects' && (this.currentPath === 'patient-follow-up-container'));
  }
}

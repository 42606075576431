import {Component} from '@angular/core';

@Component({
  selector: 'ih-user-acces-right-icon',
  templateUrl: './user-acces-right-icon.component.html',
  styleUrls: ['./user-acces-right-icon.component.css']
})
export class UserAccesRightIconComponent {

  constructor() { }
}

import {ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PatientService} from '../services/patient.service';
import {SharedService} from '../../shared/services/shared.service';
import {Role} from '../../login/models/role';
import {AuthenticationService, PATIENT_READ_WRITE} from '../../login/services/authentication.service';
import {Patient} from '../models/patient';
import {SelectItem} from 'primeng/api';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {TranslateService} from '@ngx-translate/core';
import {FR} from '../../shared/entity/calendar-language';
import {Phone} from '../../shared/entity/phone';
import {Email} from '../../shared/entity/email';
import {Fax} from '../../shared/entity/fax';
import {Address} from '../../shared/entity/address';
import {ContactSet} from '../../shared/entity/contact-set';
import {Data} from '../../shared/entity/data';
import {Router} from '@angular/router';
import {Permission} from '../../helpers/permission';
import {DataService} from '../../shared/services/data-service';
import {CanDeactivateService} from '../../shared/services/can-deactivate.service';
import {CanComponentDeactivate} from '../../helpers/can-deactivate-guard';
import {DataTransferService} from '../services/data-transfer.service';
import {Properties} from '../../helpers/properties';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PatientAppData} from '../patient-app-data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {Util} from '../../helpers/util';

@Component({
  selector: 'ih-patient-details-information',
  templateUrl: './patient-details-information.component.html',
  styleUrls: ['./patient-details-information.component.css']
})
export class PatientDetailsInformationComponent implements OnInit, CanComponentDeactivate {

  informationFormTarget = DynamicDefinitionEnum.PATIENT_INFORMATION_FORM;
  contactFormTarget = DynamicDefinitionEnum.PATIENT_CONTACT_FORM;
  isAmendmentEnabled = false;

  informationConfig: FormConfig = new FormConfig();
  contactConfig: FormConfig = new FormConfig();
  patientFilesEnabled = true;
  patientModificationRestrictedOnAdminsOnly = false;

  @ViewChild('addFolderDialog', {read: ViewContainerRef}) addFolderDialogueContainer: ViewContainerRef;
  @ViewChild('patientForm') patientForm: NgForm;
  @Input() patient: Patient;
  locale = FR;
  submitted;
  isCurrentRoleEditor: boolean;
  isPatientEditable: boolean;
  sexList: SelectItem[] = [];
  noYesOptions: SelectItem[];
  contactSet: ContactSet = new ContactSet();
  currentDate = new Date();
  contacts: SelectItem[] = Data.contacts;
  faxEmailAddressContactTypes: SelectItem[] = Data.faxEmailAddressContactTypes;
  editPhone = true;
  editFax = true;
  editEmail = true;
  editAddress = true;
  phoneEmpty = false;
  faxEmpty = false;
  emailEmpty = false;
  addressEmpty = false;
  patientHhhId: number;
  invalidDeathState = false;
  isLoaderVisible = false;
  isNipExist = false;
  externalDoctors: SelectItem[] = this.getExternalDoctorsList();
  externalEstablishments: SelectItem[] = this.getExternalDoctorEstablishmentsList();

  public hhhId: number;
  public fileHhhId: string;
  public type: string;
  public targetFolder: string;
  public parentFolder: string;
  public cols: any[];
  host: string;
  invalid = [];
  protected readonly AttachedToEntityEnum = AttachedToEntityEnum;
  readOnlyMode: boolean;

  constructor(
    public patientService: PatientService,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private translateService: InnohealthTranslateService,
    private router: Router,
    private dataService: DataService,
    private canDeactivateService: CanDeactivateService,
    private dataTransferService: DataTransferService,
    private dynamicConfigService: DynamicConfigService,
    private _cdr: ChangeDetectorRef
  ) {
    const url = this.router.parseUrl(this.router.url);
    this.host = new Properties().host;
    if (url.queryParams) {
      this.patientHhhId = url.queryParams.hhhId;
    }
  }


  ngOnInit() {
    this.initProperties();
    this.readOnlyMode = localStorage.getItem(PATIENT_READ_WRITE) !== Permission.READ_WRITE;
    this.submitted = false;
    this.isCurrentRoleEditor = this.authService.currentUserRole === Role.Editor;
    this.translate.stream('GENERAL_LINGUISTICS_YES_UFCASE').subscribe(() => {
      this.initNoYesOptions();
      this.initSexList();
    });
    this.getContactSet();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAmendmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.patientFilesEnabled = this.dynamicConfigService.getProperty(PropertyEnum.patientFilesEnabled);
      this.patientModificationRestrictedOnAdminsOnly = this.dynamicConfigService.getProperty(PropertyEnum.patientModificationRestrictedOnAdminsOnly);
      this.isPatientEditable = (localStorage.getItem('authenticatedUserRole') === Role.Admin && this.patientModificationRestrictedOnAdminsOnly) || !this.patientModificationRestrictedOnAdminsOnly;
      this._cdr.detectChanges();
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.informationFormTarget, PatientAppData.formConfig).subscribe(
      config => {
        this.informationConfig = config;
      }
    );
    this.dynamicConfigService.getFormConfig(this.contactFormTarget, PatientAppData.formConfig).subscribe(
      config => {
        this.contactConfig = config;
      }
    );
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  updatePatient() {
    this.submitted = true;
    if (!(this.patient as Patient).isValid(this.informationConfig)) {
      return;
    }
    if (this.patient.lifeStateHhhId === 2 && !this.patient.deathDate) {
      this.invalidDeathState = true;
      return;
    } else {
      this.invalidDeathState = false;
    }
    if (this.patient.internalIdentifier.trim() === '') {
      this.patient.internalIdentifier = null;
    }
    this.patient.hhhId = this.patientHhhId;
    if (this.patient.lifeStateHhhId !== 2) {
      this.patient.deathDate = null;
    }
    this.patientService.updatePatient(this.patient).subscribe(
      res => {
        // Transfer data
        this.dataTransferService.updatePatient(this.patient);
        this.dataService.setPageTopName(this.getPatientFullName());
        this.patient.hhhId = res;
        this.canDeactivateService.canBeDeactivated = true;
        this.sharedService.showSuccess('UI_FORM_SUCCESS_SAVING_SUCCEEDED');
        this.sharedService.setInformationInLocalStorage('Patients', 'Éditer', this.patient.internalIdentifier);
      },
      error => {
        this.sharedService.showFailure();
        console.error(error);
      }
    );
  }

  savePhone(item: Phone) {
    if (item.value) {
      item.contactSetHhhId = this.contactSet ? this.contactSet.hhhId : null;
      this.patientService.addPhone(item, this.patientHhhId).subscribe(res => {
          item.hhhId = res;
          this.phoneEmpty = false;
          this.editPhone = true;
          this.canDeactivateService.canBeDeactivated = true;
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        }
      );
    }
  }

  isPatientMan(patient: Patient): string {
    if (patient.sex === this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MR')) {
      return 'M.';
    } else if (patient.sex === this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MME')) {
      return 'MME';
    }
  }

  initNoYesOptions() {
    this.noYesOptions = [
      {label: '', value: null},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE'), value: true},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE'), value: false}];
  }

  initSexList() {
    const male = this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MR');
    const female = this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MME');
    this.sexList.push({label: '', value: null},
      {label: male, value: male},
      {label: female, value: female},
      {label: 'NA', value: 'NA'});
  }

  getContactSet() {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.patientHhhId = url.queryParams.hhhId;
    }
    this.patientService.getContactsOfPatient(this.patientHhhId).subscribe(res => {
      this.contactSet = res;
      if (this.contactSet) {
        this.displayContactSet();
      } else {
        this.displayNewContactSet();
      }
    });
  }

  addPhone() {
    this.contactSet.phones.push(new Phone());
    this.editPhone = false;
    this.phoneEmpty = true;
  }

  updatePhone(item: Phone) {
    this.patientService.updatePhone(item).subscribe(() => {
      this.canDeactivateService.canBeDeactivated = true;
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  deletePhone(i, item: Phone) {
    if (item.hhhId) {
      this.patientService.deletePhone(item.hhhId).subscribe(() => {
        this.contactSet.phones.splice(i, 1);
        this.canDeactivateService.canBeDeactivated = true;
        this.sharedService.showSuccess();
        if (this.contactSet.phones.length === 0) {
          this.addPhone();
        }
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    } else {
      this.contactSet.phones.splice(i, 1);
      if (this.contactSet.phones.length === 0) {
        this.addPhone();
      } else {
        this.editPhone = true;
        this.phoneEmpty = false;
      }
    }
  }

  saveFax(item: Fax) {
    if (item.value) {
      item.contactSetHhhId = this.contactSet ? this.contactSet.hhhId : null;
      this.patientService.addFax(item, this.patientHhhId).subscribe(res => {
          item.hhhId = res;
          this.faxEmpty = false;
          this.editFax = true;
          this.canDeactivateService.canBeDeactivated = true;
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        }
      );
    }
  }

  addFax() {
    this.contactSet.faxes.push(new Fax());
    this.editFax = false;
    this.faxEmpty = true;
  }

  updateFax(item: Fax) {
    this.patientService.updateFax(item).subscribe(() => {
      this.canDeactivateService.canBeDeactivated = true;
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  deleteFax(i, item: Fax) {
    if (item.hhhId) {
      this.patientService.deleteFax(item.hhhId).subscribe(() => {
        this.contactSet.faxes.splice(i, 1);
        this.canDeactivateService.canBeDeactivated = true;
        this.sharedService.showSuccess();
        if (this.contactSet.faxes.length === 0) {
          this.addFax();
        }
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    } else {
      this.contactSet.faxes.splice(i, 1);
      if (this.contactSet.faxes.length === 0) {
        this.addFax();
      } else {
        this.editFax = true;
        this.faxEmpty = false;
      }
    }
  }

  saveEmail(item) {
    if (item.value) {
      item.contactSetHhhId = this.contactSet ? this.contactSet.hhhId : null;
      this.patientService.addEmail(item, this.patientHhhId).subscribe(res => {
          item.hhhId = res;
          this.emailEmpty = false;
          this.editEmail = true;
          this.canDeactivateService.canBeDeactivated = true;
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        }
      );
    }
  }

  addEmail() {
    this.contactSet.emails.push(new Email());
    this.editEmail = false;
    this.emailEmpty = true;
  }

  updateEmail(item: Email) {
    this.patientService.updateEmail(item).subscribe(() => {
      this.canDeactivateService.canBeDeactivated = true;
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  deleteEmail(i, item: Email) {
    if (item.hhhId) {
      this.patientService.deleteEmail(item.hhhId).subscribe(() => {
        this.contactSet.emails.splice(i, 1);
        this.canDeactivateService.canBeDeactivated = true;
        this.sharedService.showSuccess();
        if (this.contactSet.emails.length === 0) {
          this.addEmail();
        }
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    } else {
      this.contactSet.emails.splice(i, 1);
      if (this.contactSet.emails.length === 0) {
        this.addEmail();
      } else {
        this.editEmail = true;
        this.emailEmpty = false;
      }
    }
  }

  saveAddress(item: Address) {
    if (item.line1 || item.line2 || item.line3 || item.city || item.postCode) {
      item.contactSetHhhId = this.contactSet != null ? this.contactSet.hhhId : null;
      this.patientService.addAddress(item, this.patientHhhId).subscribe(res => {
          item.hhhId = res;
          this.addressEmpty = false;
          this.editAddress = true;
          this.canDeactivateService.canBeDeactivated = true;
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        }
      );
    }
  }

  addAddress() {
    this.contactSet.addresses.push(new Address());
    this.editAddress = false;
    this.addressEmpty = true;
  }

  updateAddress(item: Address) {
    this.patientService.updateAddress(item).subscribe(() => {
      this.canDeactivateService.canBeDeactivated = true;
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  checkNipExistence() {
    this.isLoaderVisible = true;
    if (this.patient.internalIdentifier === '') {
      this.isNipExist = false;
      this.isLoaderVisible = false;
      return;
    }
    this.patientService.checkNipExistence(this.patient.internalIdentifier).subscribe(res => {
      this.isNipExist = res.value;
      this.isLoaderVisible = false;
    }, error => {
      this.isLoaderVisible = false;
      console.log(error);
    });
  }

  deleteAddress(i, item: Address) {
    if (item.hhhId) {
      this.patientService.deleteAddress(item.hhhId).subscribe(() => {
        this.contactSet.addresses.splice(i, 1);
        this.sharedService.showSuccess();
        if (this.contactSet.addresses.length === 0) {
          this.addAddress();
        }
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    } else {
      this.contactSet.addresses.splice(i, 1);
      if (this.contactSet.addresses.length === 0) {
        this.addAddress();
      } else {
        this.editAddress = true;
        this.addressEmpty = false;
      }
    }
  }

  private displayNewContactSet() {
    this.addPhone();
    this.addFax();
    this.addEmail();
    this.addAddress();
  }

  private displayContactSet() {
    if (this.contactSet.phones.length === 0) {
      this.addPhone();
    }
    if (this.contactSet.faxes.length === 0) {
      this.addFax();
    }
    if (this.contactSet.emails.length === 0) {
      this.addEmail();
    }
    if (this.contactSet.addresses.length === 0) {
      this.addAddress();
    }
  }

  hasWritePermission() {
    return localStorage.getItem('Patient_READ_WRITE') === Permission.READ_WRITE;
  }

  private getPatientFullName() {
    return this.isPatientMan(this.patient) + ' ' + this.patient.firstName + ' ' + (this.displayField(this.informationConfig, 'lastName') ? this.patient.lastName : this.patient.birthName);
  }

  getFieldConfig = (config: FormConfig, fieldName: string) => config.getFieldConfig(fieldName);
  displayField = (config: FormConfig, fieldName: string) => config.displayField(fieldName);


  getExternalDoctorsList(): SelectItem[] {
    return Array.from(Data.externalDoctorToExternalEstablishments.keys()).map(doctor => {
      return {label: doctor, value: doctor};
    });
  }

  getExternalDoctorEstablishmentsList(): SelectItem[] {
    return Array.from(Data.externalEstablishmentsToExternalDoctors.keys()).map(establishment => {
      return {label: establishment, value: establishment};
    });
  }

  externalDoctorChanged(): void {
    if (Util.isNullOrUndefinedOrEmpty(this.patient.externalDoctorName)) {
      this.externalDoctors = this.getExternalDoctorsList();
      this.externalEstablishments = this.getExternalDoctorEstablishmentsList();
      this.patient.externalDoctorEstablishment = null;
      return;
    }
    this.patient.externalDoctorEstablishment = Data.externalDoctorToExternalEstablishments.get(this.patient.externalDoctorName).value;
    this.externalEstablishments = [Data.externalDoctorToExternalEstablishments.get(this.patient.externalDoctorName)];
  }

  externalDoctorEstablishmentChanged(): void {
    if (Util.isNullOrUndefinedOrEmpty(this.patient.externalDoctorEstablishment)) {
      this.externalDoctors = this.getExternalDoctorsList();
      this.externalEstablishments = this.getExternalDoctorEstablishmentsList();
      this.patient.externalDoctorName = null;
      return;
    }
    this.externalDoctors = Data.externalEstablishmentsToExternalDoctors.get(this.patient.externalDoctorEstablishment);
    this.patient.externalDoctorName = null;
  }
}

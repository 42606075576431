import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {CustomNotificationComponent} from '../custom-notification/custom-notification.component';
import {DataService} from '../../shared/services/data-service';
import {SharedService} from '../../shared/services/shared.service';
import {NotificationNote} from '../models/notification-note';
import {PushNotificationManagementService} from '../services/push-notification-management.service';

@Component({
  selector: 'app-push-notification-management',
  templateUrl: './push-notification-management.component.html',
  styleUrls: ['./push-notification-management.component.css']
})
export class PushNotificationManagementComponent implements OnInit {
  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;

  trialAddedFrenchHeader: string;
  trialAddedFrenchBody: string;
  trialAddedEnglishHeader: string;
  trialAddedEnglishBody = '';

  trialDeletedFrenchHeader: string;
  trialDeletedFrenchBody: string;
  trialDeletedEnglishHeader: string;
  trialDeletedEnglishBody: string;

  cohortAddedFrenchBody: string;
  cohortAddedFrenchHeader: string;
  cohortAddedEnglishHeader: string;
  cohortAddedEnglishBody: string;

  cohortDeletedFrenchHeader: string;
  cohortDeletedFrenchBody: string;
  cohortDeletedEnglishHeader: string;
  cohortDeletedEnglishBody: string;

  endOfInclusionFrenchBody: string;
  endOfInclusionFrenchHeader: string;
  endOfInclusionEnglishHeader: string;
  endOfInclusionEnglishBody: string;

  constructor(private dataService: DataService,
              private cfr: ComponentFactoryResolver,
              private pushNotificationManagementService: PushNotificationManagementService,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    this.getNotifications();
  }

  sendNewNotification() {
    CustomNotificationComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
  }

  updatePushNotificationText(header: string, body: string, enHeader: string, enBody: string, type: string) {
    const pushNotification = new NotificationNote().of(header, body, enHeader, enBody, type);
    this.pushNotificationManagementService.updatePushNotification(pushNotification).subscribe(() => {
      this.sharedService.showSuccess();
    }, () => {
      this.sharedService.showFailure();
    });
  }

  private getNotifications() {
    this.pushNotificationManagementService.getPushNotifications().subscribe(pushNotifications => {
      const emptyString = '';
      const trialAddedIndex = pushNotifications.findIndex(notification => notification.type === 'TRIAL_ADDED');
      this.trialAddedFrenchHeader = trialAddedIndex !== -1 ? pushNotifications[trialAddedIndex].header : emptyString;
      this.trialAddedFrenchBody = trialAddedIndex !== -1 ? pushNotifications[trialAddedIndex].body : emptyString;
      this.trialAddedEnglishHeader = trialAddedIndex !== -1 ? pushNotifications[trialAddedIndex].enHeader : emptyString;
      this.trialAddedEnglishBody = trialAddedIndex !== -1 ? pushNotifications[trialAddedIndex].enBody : emptyString;

      const trialDeletedIndex = pushNotifications.findIndex(notification => notification.type === 'TRIAL_DELETED');
      this.trialDeletedFrenchHeader = trialDeletedIndex !== -1 ? pushNotifications[trialDeletedIndex].header : emptyString;
      this.trialDeletedFrenchBody = trialDeletedIndex !== -1 ? pushNotifications[trialDeletedIndex].body : emptyString;
      this.trialDeletedEnglishHeader = trialDeletedIndex !== -1 ? pushNotifications[trialDeletedIndex].enHeader : emptyString;
      this.trialDeletedEnglishBody = trialDeletedIndex !== -1 ? pushNotifications[trialDeletedIndex].enBody : emptyString;

      const cohortAddedIndex = pushNotifications.findIndex(notification => notification.type === 'COHORT_ADDED');
      this.cohortAddedFrenchHeader = cohortAddedIndex !== -1 ? pushNotifications[cohortAddedIndex].header : emptyString;
      this.cohortAddedFrenchBody = cohortAddedIndex !== -1 ? pushNotifications[cohortAddedIndex].body : emptyString;
      this.cohortAddedEnglishHeader = cohortAddedIndex !== -1 ? pushNotifications[cohortAddedIndex].enHeader : emptyString;
      this.cohortAddedEnglishBody = cohortAddedIndex !== -1 ? pushNotifications[cohortAddedIndex].enBody : emptyString;

      const cohortDeletedIndex = pushNotifications.findIndex(notification => notification.type === 'COHORT_DELETED');
      this.cohortDeletedFrenchHeader = cohortDeletedIndex !== -1 ? pushNotifications[cohortDeletedIndex].header : emptyString;
      this.cohortDeletedFrenchBody = cohortDeletedIndex !== -1 ? pushNotifications[cohortDeletedIndex].body : emptyString;
      this.cohortDeletedEnglishHeader = cohortDeletedIndex !== -1 ? pushNotifications[cohortDeletedIndex].enHeader : emptyString;
      this.cohortDeletedEnglishBody = cohortDeletedIndex !== -1 ? pushNotifications[cohortDeletedIndex].enBody : emptyString;

      const endOfInclusionIndex = pushNotifications.findIndex(notification => notification.type === 'END_OF_INCLUSION');
      this.endOfInclusionFrenchHeader = endOfInclusionIndex !== -1 ? pushNotifications[endOfInclusionIndex].header : emptyString;
      this.endOfInclusionFrenchBody = endOfInclusionIndex !== -1 ? pushNotifications[endOfInclusionIndex].body : emptyString;
      this.endOfInclusionEnglishHeader = endOfInclusionIndex !== -1 ? pushNotifications[endOfInclusionIndex].enHeader : emptyString;
      this.endOfInclusionEnglishBody = endOfInclusionIndex !== -1 ? pushNotifications[endOfInclusionIndex].enBody : emptyString;
    });
  }
}

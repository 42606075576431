import {TrialCountState} from '../../statistics/follow-trials/models/trial-count-state';
import {InclusionPerState} from './inclusion-per-state';
import {InclusionsPerMonth} from './inclusions-per-month';

export class ClinicalResearchActivity {
  public inclusionStates: InclusionPerState[];
  public inclusionsProgressMap: InclusionPerState[] = [];
  public trialStates: TrialCountState[];
  public inclusionsGoal: InclusionsPerMonth[];
  public inclusionsTotal: number;
  public trialsTotal: number;
  public totalTheoreticalNumberOfInclusions: number;
}

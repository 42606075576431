import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared/services/shared.service';
import {PersonalHistory} from '../models/personal-history';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';

@Component({
  selector: 'app-personal-history',
  templateUrl: './personal-history.component.html',
  styleUrls: ['./personal-history.component.css']
})
export class PersonalHistoryComponent implements OnInit {
  historyList: PersonalHistory[];
  currentDate: string;

  constructor(private sharedService: SharedService,
              private datePipe: DatePipe,
              private router: Router) {
  }

  ngOnInit() {
    this.currentDate = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    this.historyList = this.sharedService.getInformationInLocalStorage();
    console.log(this.historyList);
  }

  NavigateToModule(module: string) {
    let path = '';
    switch (module) {
      case 'Etudes': {
        path = '/trial-list';
        break;
      }
      case 'Prestataires' : {
        path = '/provider-list';
        break;
      }
      case 'Ressources humaines': {
        path = '';
        break;
      }
      case 'Services': {
        path = '/establishment';
        break;
      }
      case 'Patients': {
        path = '/patient-list';
        break;
      }
      case 'Facturation': {
        path = '/invoice-container/static-invoice-list';
        break;
      }
      case 'Sites': {
        path = '/site-list';
        break;
      }
      case 'Pré-facturation': {
        path = '/invoice-container/invoice-list';
        break;
      }
      case 'Grille budgétaire: Contreparties': {
        path = '/additional-costs-container/counter-parts';
        break;
      }
      case 'Grille budgétaire: Catégories des contreparties': {
        path = '/additional-costs-container/counter-part-categories';
        break;
      }
      case 'Grille budgétaire: Actes opérationnels': {
        path = '/additional-costs-container/operational-acts';
        break;
      }
      case 'Grille budgétaire: Grille des actes': {
        path = '/additional-costs-container/reference-acts';
        break;
      }
      case 'Grille budgétaire: Catégories des actes': {
        path = '/additional-costs-container/acts-categories';
        break;
      }
      case 'Grille budgétaire: Coûts / Surcoûts': {
        path = '/additional-costs-container/fixed-cost-reference';
        break;
      }
      case 'Grille budgétaire: Catégories des Coûts / Surcoûts': {
        path = '/additional-costs-container/fixed-cost-categories';
        break;
      }
    }
    this.router.navigate([path]);
  }
}

import {Util} from '../../helpers/util';
import {FormConfig} from '../../dynamic-config/entity/form-config';

export class InvoiceAdd {
  public invoiceNumber: string;
  public state: number;
  public issueDate: Date;
  public validationDate: Date;
  public despatchDate: Date;
  public paymentDate: Date;
  public address: string;
  public trial: number;
  public managementFeesPercentage: any;
  public establishmentHhhId;
  public debtorLocalCode: string;
  public factureDescription: string;
  public tvaRate: string;
  public imputationAccount: string;
  type: string;


  init(obj: any): InvoiceAdd {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }


  isValid(config: FormConfig, isParent: boolean): boolean {
    const valid = true;
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'number') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.invoiceNumber)) {
          return false;
        }
      } else if (key === 'establishment') {
        if (isParent && required && Util.isNullOrUndefinedOrEmpty(this.establishmentHhhId)) {
          return false;
        }
      } else if (key === 'state') {
        if (required && !this.state) {
          return false;
        }
      } else if (key === 'feesPercentage') {
        if (required && (!this.managementFeesPercentage || this.managementFeesPercentage > 100 || this.managementFeesPercentage < 0)) {
          return false;
        }
      } else if (key === 'manualState') {
        continue;
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return valid;

  }
}

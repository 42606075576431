import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {TrialSite} from '../../../models/trial-site';
import {TrialSiteService} from '../../../../site/services/trial-site.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {Router} from '@angular/router';
import {SiteService} from '../../../../site/services/site.service';
import {Site} from '../../../../site/models/site';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {Util} from "../../../../helpers/util";

@Component({
  selector: 'ih-trial-site-form',
  templateUrl: './trial-site-form.component.html',
  styleUrls: ['./trial-site-form.component.css']
})
export class TrialSiteFormComponent extends FormComponent<TrialSite> implements OnInit {

  public trialHhhId: number;
  public siteList: Site[];
  public displayAddSite = false;
  public displayAddNewSite: boolean;
  public trialSite: TrialSite = new TrialSite();

  trialSites: TrialSite[];

  constructor(private trialSiteService: TrialSiteService,
              private sharedService: SharedService,
              private router: Router,
              private siteService: SiteService) {
    super();
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(TrialSiteFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.displayAddSite = true;
    this.getOptionsSite();
  }

  addSite() {
    if (this.siteAlreadyExist()) {
      this.sharedService.showFailure('TRIAL_SITES_TRIAL_SITE_ALREADY_EXIST_MESSAGE');
      return;
    }
    this.trialSiteService.addSiteToTrial(this.trialHhhId, this.trialSite.hhhId).subscribe(res => {
      this.sharedService.showSuccess();
      this.trialSite.hhhId = res;
      this.callback(this.trialSite);
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
    this.displayAddSite = false;
  }

  getOptionsSite() {
    this.siteService.loadList().subscribe(res => {
      this.siteList = res;
    });
  }

  openAddNewSite() {
    this.trialSite = new TrialSite();
    this.displayAddSite = false;
    this.displayAddNewSite = true;
  }

  addNewSite() {
    this.trialSiteService.addNewSiteToTrial(this.trialHhhId, this.trialSite.name).subscribe(res => {
      this.sharedService.showSuccess();
      this.trialSite.hhhId = res;
      this.callback(this.trialSite);
      this.displayAddNewSite = false;
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  siteAlreadyExist() {
    return !Util.isNullOrUndefined(this.trialSites) && this.trialSites.filter((trialSite: TrialSite) => (trialSite.name === this.trialSite.name)).length > 0;
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {StaticInvoiceItem} from '../models/static-invoice-item';
import {Router} from '@angular/router';
import {SharedService} from '../../shared/services/shared.service';
import {AdditionalCostState} from '../../additional-cost-state.enum';
import {InvoiceAct} from '../invoice-acts/models/invoice-act';
import {HiddenFields} from '../../admin/models/HiddenFields';
import {UsersService} from '../../admin/services/users.service';
import {InvoiceService} from '../services/invoice.service';
import {InvoiceAppData} from '../Invoice-app-data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {StaticInvoiceService} from '../services/static-invoice.service';
import {StaticInvoiceFixedCostService} from '../invoice-fixed-costs/services/static-invoice-fixed-cost.service';
import {Data} from '../../shared/entity/data';
import {InvoiceFixedCost} from '../models/invoice-fixed-cost';

@Component({
  selector: 'ih-static-invoice-fixed-cost',
  templateUrl: './static-invoice-fixed-cost.component.html',
  styleUrls: ['./static-invoice-fixed-cost.component.css']
})
export class StaticInvoiceFixedCostComponent implements OnInit {

  target = DynamicDefinitionEnum.INVOICE_STATIC_FIXED_COST_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  staticInvoiceFixedCost = new StaticInvoiceItem();
  originalBeforeUpdateStaticInvoiceFixedCost = new StaticInvoiceItem();
  invoiceHhhId: number;
  totalInvoiceFixedCostList = 0;
  isDisplaySaveButton = false;

  @Input() actState: string;

  constructor(
    private staticInvoiceFixedCostService: StaticInvoiceFixedCostService,
    private staticInvoiceService: StaticInvoiceService,
    private useService: UsersService,
    private router: Router,
    private invoiceService: InvoiceService,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService,
  ) {
    const url = this.router.parseUrl(this.router.url);
    this.invoiceHhhId = url.queryParams.invoiceHhhId;
  }

  ngOnInit() {
    this.staticInvoiceFixedCost.invoiceActs = [];
    this.initTableConfig();
    this.getInvoiceFixedCosts(-1);
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, InvoiceAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable(this.actState === AdditionalCostState.Pending)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionPreScreeningIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionScreeningIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitDate')
      .filterable((this.actState === AdditionalCostState.Pending || this.actState === AdditionalCostState.Invoiced))
      .filterType(FilterType.INPUT_TEXT)
      .sortable(this.actState === AdditionalCostState.Pending)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitName')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable(this.actState === AdditionalCostState.Pending)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('type')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter('typeName')
      .sortable()
      .displayContent((data: InvoiceAct) => data.typeName)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('category')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('fixed-cost-category')
      .sortable(this.actState === AdditionalCostState.Pending)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('description')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientInitials')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('unitPrice')
      .accessible(this.useService.canAccessField(HiddenFields.FIXED_COST_PRICE))
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filterMatchType('equals')
      .sortable()
      .cssClass(() => {
        return 'right-align';
      })
      .withDisplayCssClassContent()
      .displayContent((data: InvoiceAct) => {
        return Number(data.unitPrice).toFixed(2) + '€';
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('unitNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filterMatchType('equals')
      .sortable()
      .cssClass(() => {
        return 'right-align';
      })
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.useService.canAccessField(HiddenFields.FIXED_COST_PRICE))
      .field('total')
      .sortable(this.actState === AdditionalCostState.Pending)
      .filterMatchType('equals')
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .displayContent(((data: InvoiceAct) => {
        return data.total.toFixed(2) + '€';
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('internalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('annualInvoicing')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .options(Data.yesOrNoOrNull)
      .isTranslated()
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getInvoiceFixedCosts(versionHhhId: number) {
    this.staticInvoiceFixedCostService.getStaticInvoiceInvoicedFixedCost(this.invoiceHhhId, versionHhhId).subscribe(res => {
      this.staticInvoiceFixedCost = res;
      this.staticInvoiceService.updateStaticCurrentVersionHhhId(res.currentVersionHhhId);
      this.originalBeforeUpdateStaticInvoiceFixedCost = JSON.parse(JSON.stringify(res));
      this.displayTotalInvoiceActs();
      this.invoiceService.invoiceTotal.next();
    });
  }

  displayTotalInvoiceActs() {
    this.totalInvoiceFixedCostList = 0;
    for (const invoiceAct of this.staticInvoiceFixedCost.invoiceFixedCosts) {
      this.totalInvoiceFixedCostList += invoiceAct.total;
    }
    this.staticInvoiceService.updateStaticFixedCostsTotal(this.totalInvoiceFixedCostList);
  }

  onEditComplete() {
    this.isDisplaySaveButton = true;
  }

  resetTable() {
    this.staticInvoiceFixedCost = JSON.parse(JSON.stringify(this.originalBeforeUpdateStaticInvoiceFixedCost));
    this.isDisplaySaveButton = false;
  }

  saveTable() {
    this.staticInvoiceFixedCostService.saveStaticInvoiceInvoicedFixedCosts(this.invoiceHhhId, this.staticInvoiceFixedCost).subscribe(hhhId => {
      this.sharedService.showSuccess();
      this.getInvoiceFixedCosts(-1);
      this.isDisplaySaveButton = false;
    }, error => {
      this.sharedService.showFailure();
    });
  }

  tableUpdated() {
    this.isDisplaySaveButton = true;
  }

  isHasNext() {
    if (this.staticInvoiceFixedCost.nbrOfHistoryVersions < 2) {
      return false;
    }
    return (this.staticInvoiceFixedCost.versionHhhIds[this.currentVersionIndex() + 1]) !== undefined;
  }

  isHasPrevious(): boolean {
    if (this.staticInvoiceFixedCost.nbrOfHistoryVersions < 2) {
      return false;
    }
    return (this.staticInvoiceFixedCost.versionHhhIds[this.currentVersionIndex() - 1]) !== undefined;
  }

  currentVersionIndex() {
    return this.staticInvoiceFixedCost.versionHhhIds.indexOf(this.staticInvoiceFixedCost.currentVersionHhhId);
  }

  updateTotal(filteredFixedCosts: InvoiceFixedCost[]) {
    this.totalInvoiceFixedCostList = 0;
    for (const invoiceAct of filteredFixedCosts) {
      this.totalInvoiceFixedCostList += invoiceAct.total;
    }
    this.staticInvoiceService.updateStaticFixedCostsTotal(this.totalInvoiceFixedCostList);
  }

  getPrevious() {
    if (this.isHasPrevious()) {
      this.getInvoiceFixedCosts(this.staticInvoiceFixedCost.versionHhhIds[this.currentVersionIndex() - 1]);
    }
  }

  getNext() {
    if (this.isHasNext()) {
      this.getInvoiceFixedCosts(this.staticInvoiceFixedCost.versionHhhIds[this.currentVersionIndex() + 1]);
    }
  }

}

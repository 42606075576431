import {Component, OnInit} from '@angular/core';
import {LastDateList} from '../../../models/LastDateList';
import {FR} from '../../../../shared/entity/calendar-language';
import {LastDateService} from '../../../services/last-date.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {TrialProtocolService} from '../../../services/trial-protocol.service';

@Component({
  selector: 'ih-date-last-amendment-cpp-form',
  templateUrl: './date-last-amendment-cpp-form.component.html',
  styleUrls: ['./date-last-amendment-cpp-form.component.css']
})
export class DateLastAmendmentCPPFormComponent implements OnInit {

  displayDateLastAmendmentCpp = true;
  lastDateCPPList: LastDateList[] = [];
  locale = FR;
  private trialId: number;
  callback: (dateLastAmendmentStream: string) => void;

  constructor(private lastDateService: LastDateService, private sharedService: SharedService, private trialProtocolService: TrialProtocolService) {}

  ngOnInit() {
    this.getOtherLastDate();
    this.addDate();
  }

  setTrialId(trialId: number) {
    this.trialId = trialId;
  }

  saveLastDateANSMList() {
    this.lastDateService.saveModificationsCPP(this.lastDateCPPList, this.trialId ? this.trialId : 0).subscribe((res) => {
      this.displayDateLastAmendmentCpp = false;
      this.sharedService.showSuccess();
      const listofvalue = res.value.split(';');
      const c = listofvalue[0];
      this.callback(c);
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  addDate() {
    const lastDateANSM = new LastDateList();
    lastDateANSM.trialHhhId = this.trialId;
    this.lastDateCPPList.splice(0, 0, lastDateANSM);
    this.refreshOtherAmendmentIndexes();
  }

  refreshOtherAmendmentIndexes() {
    for (let i = 0; i < this.lastDateCPPList.length; i++) {
      this.lastDateCPPList[i].index = i;
    }
  }

  deleteDate(i: number) {
    this.lastDateCPPList.splice(i, 1);
    this.refreshOtherAmendmentIndexes();
  }

  onAdd(callback: (dateLastAmendmentStream: string) => void) {
    this.callback = callback;
  }

  private getOtherLastDate() {
    this.lastDateService.getCPP(this.trialId).subscribe(res => {
      this.lastDateCPPList = res;
      this.addDate();
    }, error => {
      console.error(error);
    });
  }
}

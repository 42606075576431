export class Phone {
  hhhId: any ;
  type: any ;
  value: any ;
  position: number;
  contactSetHhhId: number;
  humanResourceHhhId: number;

  constructor() {
    this.type = '';
    this.position = 1;
  }
}

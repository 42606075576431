import {isUndefined} from 'util';

export class BlocFields {
  public hhhId: number;
  public inclusionId: number;
  public blocRequestDate: Date;
  public dispatchBlockDate: Date;
  public result: string;
  public resultDate: Date;
  public isNew: boolean; // this attribute is used only in front

  constructor() {
    if (!isUndefined(this.blocRequestDate && this.dispatchBlockDate && this.resultDate) ) {
      this.blocRequestDate = new Date(this.blocRequestDate);
      this.dispatchBlockDate = new Date(this.dispatchBlockDate);
      this.resultDate = new Date(this.resultDate);
      this.result = '';
    } else {
      this.blocRequestDate = null;
      this.dispatchBlockDate = null;
      this.resultDate = null;
      this.result = '';
    }
    this.isNew = true;
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Column} from 'primeng/shared';
import {EstablishmentDepartment} from '../../shared/entity/establishment-department';
import {YearRecord} from '../models/year-record';
import {DataService} from '../../shared/services/data-service';
import {Establishment} from '../models/establishment';
import {ContactService} from '../../shared/services/contact.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EstablishmentService} from '../services/establishment.service';
import {SharedService} from '../../shared/services/shared.service';
import {InnohealthTranslateService} from 'src/app/shared/services/innohealth-translate.service';
import * as moment from 'moment';
import {ESTABLISHMENT_READ_WRITE} from '../../login/services/authentication.service';
import {ActiveFileByYearFormComponent} from '../active-file-by-year-form/active-file-by-year-form.component';
import {Permission} from '../../helpers/permission';
import {EstablishmentObservablesService} from '../services/establishment-observables.service';
import { EstablishmentDepartmentService } from '../services/establishment-department.service';


@Component({
  selector: 'ih-active-file-by-year',
  templateUrl: './active-file-by-year.component.html',
  styleUrls: ['./active-file-by-year.component.css']
})
export class ActiveFileByYearComponent implements OnInit {

  @ViewChild('addActiveFileDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;


  establishmentHhhId: number;
  currentActiveListColumns: Column[] = [];
  years: any = [];
  totals: any = [];
  yearList: YearRecord[] = [];
  year: YearRecord = new YearRecord();
  establishment: Establishment = new Establishment();
  ready = false;
  serviceForUpdate: any;
  yearForUpdate: any;
  currentYearValue: any;
  isEditMode = false;

  constructor(
    private sharedService: SharedService,
    private cfr: ComponentFactoryResolver,
    private router: Router,
    private establishmentService: EstablishmentService,
    private establishmentObservablesService: EstablishmentObservablesService,
    private establishmentDepartmentService: EstablishmentDepartmentService
  ) {
    }

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.establishmentHhhId = url.queryParams && url.queryParams.hhhId ? url.queryParams.hhhId : -1;
    this.getEstablishment();
    this.getYears();
    this.establishmentObservablesService.onEstablishmentDepartmentEdit.subscribe(() => {
      this.getEstablishment();
    });
  }

  sendUpdateRecord(value: number, hhhId: number) {
    this.year.value = value;
    this.year.hhhId = hhhId;
    const index = this.establishment.services.findIndex((service: EstablishmentDepartment) => service.name === this.serviceForUpdate);
    this.year.service = String(this.establishment.services[index].hhhId);
    this.establishmentDepartmentService.updateYearRecord(this.year).subscribe(() => {
      this.getEstablishment();
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  calculateTotalActiveFileByYear() {
    const totals = [];
    let i = 0;
    for (const year of this.currentActiveListColumns) {
      totals[i] = 0;
      for (const yearRecord of this.establishment.numberInpatientsCurrentActive.yearRecords) {
        if (yearRecord.year === +year.header) {
          totals[i] = yearRecord.value + totals[i];
        }
      }
      i++;
    }
    totals.splice(0, 1);
    this.totals = totals;
  }

  updateRecord(rowData: any, head: any) {
    this.serviceForUpdate = rowData.key;
    this.yearForUpdate = head;
    this.year.year = this.yearForUpdate;
    this.year.hhhId = rowData.value.hhhId;
  }

  getYears() {
    for (let i = 2029; i > 1899; i--) {
      const year = new YearRecord();
      year.year = i;
      this.yearList.push(year);
      this.currentYearValue = moment(new Date()).format('YYYY');
    }
  }

  getEstablishment() {
    this.establishmentService.getEstablishment(this.establishmentHhhId).subscribe(res => {
      this.establishment = res;
      if (this.establishment.numberInpatientsCurrentActive.yearRecords !== null) {
        this.establishment.numberInpatientsCurrentActive.yearRecords.sort(
          (a, b) => a.service.toLowerCase().localeCompare(b.service.toLowerCase())
        );
        this.ready = true;
        this.getYearList();
        this.currentActiveListColumns = [];
        const column: Column = new Column();
        column.field = 'service';
        column.header = '';
        column.colspan = 100;
        column.resizable = true;
        column.headerStyle = '150px;';
        this.currentActiveListColumns.push(column);
        for (const year of this.years) {
          const columnItem: Column = new Column();
          columnItem.field = 'value';
          columnItem.header = year;
          column.headerStyle = '150px;';
          this.currentActiveListColumns.push(columnItem);
        }
        this.calculateTotalActiveFileByYear();
      }
    });
  }

  getYearList() {
    for (const yearRecord of this.establishment.numberInpatientsCurrentActive.yearRecords) {
      if (!this.years.includes(yearRecord.year)) {
        this.years.push(yearRecord.year);
      }
    }
    this.years.sort((one, two) => (one > two ? -1 : 1));
  }

  displayActiveFileAddDialog() {
    const addDialogFactory = this.cfr.resolveComponentFactory(ActiveFileByYearFormComponent);
    const addDialogComponentRef = this.dialogContainer.createComponent(addDialogFactory);
    const activeFileByYearFormComponent = addDialogComponentRef.instance;
    activeFileByYearFormComponent.establishmentEditRight = this.establishmentEditRight();
    activeFileByYearFormComponent.services = this.establishment.services;
    activeFileByYearFormComponent.currentYearValue = this.currentYearValue;
    activeFileByYearFormComponent.establishmentHhhId = this.establishment.hhhId;
    activeFileByYearFormComponent.yearList = this.yearList;
    activeFileByYearFormComponent.onAdd(() => {
      this.getEstablishment();
    });
  }

  establishmentEditRight() {
    return localStorage.getItem(ESTABLISHMENT_READ_WRITE) !== Permission.READ_WRITE;
  }
}

import {OperationalAdditionalCost} from './operational-additional-cost';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';

export class OperationalAct {
  hhhId: number;
  publicCommentCount: number;
  description: string;
  rdvDescription: string;
  invoiceable: boolean;
  actCategoryName: string;
  actCategoryHhhId: number;
  operationalAdditionalCostList: OperationalAdditionalCost[] = [];
  trialActHhhIds: number[] = [];
  trialFixedCostHhhIds: number[] = [];

  init(obj: any): OperationalAct {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'actCategoryName') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.actCategoryHhhId)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nonZero'
})
export class NonZero implements PipeTransform {

  transform(value: number): string {
    return value ? '' + value : '';
  }

}

import {NgModule} from '@angular/core';
import {PanelMenuModule} from 'primeng/primeng';
import {InvoiceRoutingModule} from './invoice-routing.module';
import {InvoiceDetailsComponent} from './invoice-details/invoice-details.component';
import {InvoiceInformationComponent} from './invoice-information/invoice-information.component';
import {InvoiceFixedCostsComponent} from './invoice-fixed-costs/invoice-fixed-costs.component';
import {InvoiceActsComponent} from './invoice-acts/invoice-acts.component';
import {InvoiceActListComponent} from './invoice-act-list/invoice-act-list.component';

import {InvoiceFixedCostListComponent} from './invoice-fixed-cost-list/invoice-fixed-cost-list.component';
import {InvoiceListComponent} from './invoice-list/invoice-list.component';
import {FollowInvoiceComponent} from './follow-invoice/follow-invoice/follow-invoice.component';
import {InvoiceAddFormComponent} from './invoice-add-form/invoice-add-form.component';
import {SharedModule} from '../shared/shared.module';
import {InvoiceCounterPartsComponent} from './invoice-counter-parts/invoice-counter-parts.component';
import {InvoiceCounterPartListComponent} from './invoice-counter-part-list/invoice-counter-part-list.component';
import {InvoiceContainerComponent} from './invoice-container/invoice-container.component';
import {StaticInvoiceActListComponent} from './static-invoice-act-list/static-invoice-act-list.component';
import {StaticInvoiceListComponent} from './static-invoice-list/static-invoice-list.component';
import {StaticInvoiceInformationComponent} from './static-invoice-information/static-invoice-information.component';
import {StaticInvoiceDetailsComponent} from './static-invoice-details/static-invoice-details.component';
import {StaticInvoiceFixedCostComponent} from './static-invoice-fixed-cost/static-invoice-fixed-cost.component';
import {StaticInvoiceCounterPartComponent} from './static-invoice-counter-part/static-invoice-counter-part.component';
import {OngoingInvoiceDetailsComponent} from './ongoing-invoice/ongoing-invoice-details/ongoing-invoice-details.component';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';

@NgModule({
  imports: [
    InvoiceRoutingModule,
    SharedModule,
    PanelMenuModule,
    DynamicConfigModule
  ],
  declarations: [
    InvoiceAddFormComponent,
    InvoiceDetailsComponent,
    InvoiceInformationComponent,
    InvoiceFixedCostsComponent,
    InvoiceActsComponent,
    InvoiceFixedCostListComponent,
    InvoiceActListComponent,
    FollowInvoiceComponent,
    InvoiceListComponent,
    InvoiceCounterPartsComponent,
    InvoiceCounterPartListComponent,
    InvoiceContainerComponent,
    StaticInvoiceListComponent,
    StaticInvoiceActListComponent,
    StaticInvoiceInformationComponent,
    StaticInvoiceDetailsComponent,
    StaticInvoiceFixedCostComponent,
    StaticInvoiceCounterPartComponent,
    OngoingInvoiceDetailsComponent
  ],
  entryComponents: [
    InvoiceAddFormComponent
  ]

})
export class InvoiceModule {
}

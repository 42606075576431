import {Properties} from '../../helpers/properties';
import {saveAs} from 'file-saver';

export class IgCsvExport {

  private columnSeparator = new Properties().csvSeparator;
  private rowSeparator = '\r\n';
  private stringQuote = '"';

  private readonly data: object[];
  private readonly headers: string[];
  private readonly filename: string;

  constructor(data: object[], headers: string[], filename: string) {
    this.data = data;
    this.headers = headers;
    this.filename = filename;
  }

  public convertToCsvAndDownload() {
    const csvData = this.convertToCsv(this.data, this.headers);
    this.downloadCsv(csvData, this.filename);
  }

  private convertToCsv(data: object[], headers: string[]): string {
    let csvData = '';
    const p = /\"/gi;
    // Append headers
    for (let i = 0; i < headers.length; i++) {
      if (i < headers.length - 1) {
        csvData += this.stringQuote + headers[i].replace(p, '""') + this.stringQuote + this.columnSeparator;
      } else {
        csvData += this.stringQuote + headers[i].replace(p, '""') + this.stringQuote;
      }
    }
    csvData += this.rowSeparator;
    // Append data
    for (const item of data) {
      csvData += Object.values(item)
          .map(v => this.stringQuote + (v != null ? v.toString().replace(p, '""') : '') + this.stringQuote)
          .join(this.columnSeparator)
        + this.rowSeparator;
    }
    // Escape prohibited csv characters
    return csvData;
  }

  private downloadCsv(csvArray: string, filename: string) {
    const BOM = '\uFEFF';
    // Convert csv to blob
    const blob = new Blob([BOM + csvArray], {type: 'text/csv;charset=utf-8'});
    // Download csv file
    saveAs(blob, filename + '.csv');
  }

}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Site} from '../models/site';
import {SiteService} from '../services/site.service';
import {SharedService} from '../../shared/services/shared.service';
import {FormComponent} from '../../shared/component/form/form.component';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {SiteAppData} from '../site-app-data';
import {FormConfig} from '../../dynamic-config/entity/form-config';

@Component({
  selector: 'ih-site-form',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.css']
})
export class SiteFormComponent extends FormComponent<Site> implements OnInit {

  target = DynamicDefinitionEnum.SITE_ADD_FORM;
  config: FormConfig = new FormConfig();
  parentEstablishment = false;

  @ViewChild('siteForm') siteForm: NgForm;
  site: Site = new Site();

  constructor(
      private siteService: SiteService,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(SiteFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.parentEstablishment = this.dynamicConfigService.parent;
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, SiteAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  submitSite() {
    this.submitted = true;

    if (!(this.site).isValid(this.config, this.parentEstablishment)) {
      return;
    }
    if (!this.site.hhhId) {
      this.addSite();
    } else {
      this.updateSite();
    }
    this.display = false;
  }

  private updateSite() {

    this.siteService.updateSite(this.site).subscribe((res: Site) => {
      this.sharedService.showSuccess('UI_FORM_SUCCESS_SAVING_SUCCEEDED');
      this.sharedService.setInformationInLocalStorage('Sites', 'Éditer', this.site.name);
      this.callback(res);
    }, error => {
      this.sharedService.showFailure('An error has occurred when trying to update the site.' + error);
    });
  }


  private addSite() {
    this.siteService.addSite(this.site.name, this.site.establishment.value).subscribe(
        res => {
          this.site = res;
          this.sharedService.showSuccess('UI_FORM_SUCCESS_SAVING_SUCCEEDED');
          this.sharedService.setInformationInLocalStorage('Sites', 'Ajouter', this.site.name);
          this.callback(this.site);
        }, error => {
          this.sharedService.showFailure('An error has occurred when trying to add the site.' + error);
        });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

}

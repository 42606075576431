import {Component, OnInit} from '@angular/core';
import {TrackingTableService} from '../../trial/service/tracking-table.service';
import {VisitTracking} from '../../trial/entity/trial-track/visit-tracking';
import {VisitService} from '../../trial/service/visit.service';

@Component({
  selector: 'ih-invoice-creation-warning-form',
  templateUrl: './invoice-creation-warning-form.component.html',
  styleUrls: ['./invoice-creation-warning-form.component.css']
})
export class InvoiceCreationWarningFormComponent implements OnInit {

  public displayCreationInvoiceWarning: boolean;
  public visit: VisitTracking;

  constructor(private trackingTableService: TrackingTableService, private visitService: VisitService) {
  }

  ngOnInit() {
    this.displayCreationInvoiceWarning = true;
  }

  saveVisitSituationAndModifyActs() {
    this.trackingTableService.editVisit(this.visit).subscribe(() => {
      }, error => {
        console.error('An error occurred while saving visit.' + error);
      }
    );
    this.visitService.generateInvoice(this.visit.hhhId).subscribe(() => {
      this.displayCreationInvoiceWarning = false;
    });
  }

}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PharmacyService} from '../../../service/pharmacy.service';
import {AUTHENTICATED_USER_ROLE} from '../../../../login/services/authentication.service';
import {Role} from '../../../../login/models/role';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-trial-pharmacy-files',
  templateUrl: './trial-pharmacy-files.component.html',
  styleUrls: ['./trial-pharmacy-files.component.css']
})

export class TrialPharmacyFilesComponent implements OnInit {

  readonly AttachedToEntityEnum = AttachedToEntityEnum;
  public trialHhhId: number;
  pharmacyHhhId: any;

  constructor(public pharmacyService: PharmacyService,
              private router: Router) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
      this.pharmacyHhhId = url.queryParams.pharmacyHhhId;
    }
  }

  ngOnInit() {
    this.pharmacyService.setPharmacyReadonlyMode();
  }

  isAdmin() {
    return localStorage.getItem(AUTHENTICATED_USER_ROLE) === Role.Admin;
  }

}

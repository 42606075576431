import {VisitAdditionalCost} from '../trial-additionnal-costs/visit-additional-cost';
import {DataCommentCount} from '../../../data-comment/entity/data-comment-count';

export class TheoreticalVisitAct extends DataCommentCount {
  hhhId: number;
  description: string;
  actCategory: string;
  modificationType: string; // modification exists only in front
  onPrescription: boolean;
  externalFromCenter: boolean;
  invoiceable: boolean;
  optional: boolean;
  pricePerUnit: number;
  numberOfUnits: number;
  visitHhhId: number;
  amendmentModificationType: string; // modification coming from back (  saved in entity theoreticalVisitAct)
  trialActHhhId: number;
  parentOperationalActHhhId: number;
  operational: boolean;
  type: string;
  details: TheoreticalVisitAct[];
  countOfPublicComment:number;
  public isOpChecked: boolean;


  static generateTheoreticalVisitActForOperationalAct(operationalAct: VisitAdditionalCost, unitNumber): TheoreticalVisitAct {
    const visitAct: TheoreticalVisitAct = new TheoreticalVisitAct();
    visitAct.trialActHhhId = operationalAct.hhhId;
    visitAct.description = operationalAct.additionalCostName;
    visitAct.invoiceable = operationalAct.invoiceable;
    visitAct.actCategory = operationalAct.additionalCostCategoryName;
    visitAct.numberOfUnits = Number(unitNumber);
    visitAct.modificationType = 'add';
    visitAct.type = operationalAct.additionalCostType;
    visitAct.operational = true;
    visitAct.optional = operationalAct.optional;
    visitAct.details = [];
    return visitAct;
  }


  static generateTheoreticalVisitActForSubAct(additionalCost: VisitAdditionalCost, unitNumber): TheoreticalVisitAct {
    const visitAct = new TheoreticalVisitAct();
    visitAct.trialActHhhId = additionalCost.hhhId;
    visitAct.description = additionalCost.additionalCostName;
    visitAct.pricePerUnit = additionalCost.pricePerUnit;
    visitAct.invoiceable = additionalCost.invoiceable;
    visitAct.optional = additionalCost.optional;
    visitAct.actCategory = additionalCost.additionalCostCategoryName;
    visitAct.numberOfUnits = Number(unitNumber);
    visitAct.modificationType = 'add';
    visitAct.type = additionalCost.additionalCostType;
    visitAct.operational = false;
    visitAct.optional = additionalCost.optional;
    return visitAct;
  }

  static generateTheoreticalVisitActFromAdditionalCost(additionalCost: VisitAdditionalCost): TheoreticalVisitAct {
    const visitAct = new TheoreticalVisitAct();
    visitAct.trialActHhhId = additionalCost.hhhId;
    visitAct.description = additionalCost.additionalCostName;
    visitAct.pricePerUnit = additionalCost.pricePerUnit;
    visitAct.invoiceable = additionalCost.invoiceable;
    visitAct.optional = additionalCost.optional;
    visitAct.actCategory = additionalCost.additionalCostCategoryName;
    visitAct.numberOfUnits = 1;
    visitAct.modificationType = 'add';
    visitAct.type = additionalCost.additionalCostType;
    visitAct.parentOperationalActHhhId = additionalCost.trialOperationalActHhhId;
    visitAct.optional = additionalCost.optional;
    return visitAct;
  }
}

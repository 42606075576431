import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {GlobalInvoicesTotalAmountByMonthFilter} from '../models/GlobalInvoicesTotalAmountByMonthFilter';
import {Observable} from 'rxjs';
import {InvoicesTotalAmountByMonth} from '../models/invoices-total-amount-by-month';
import {Properties} from '../../../helpers/properties';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {TrialRow} from '../models/trial-row';
import {AdditionalCostCategory} from '../models/additional-cost-category';
import {IgCsvExport} from '../../../shared/entity/ig-csv-export';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FollowInvoiceService {

  public host = new Properties().host + '/follow-invoice';
  public invoicesTotalsAmountByMonth: InvoicesTotalAmountByMonth = new InvoicesTotalAmountByMonth();
  public globalFilter: GlobalInvoicesTotalAmountByMonthFilter = new GlobalInvoicesTotalAmountByMonthFilter();

  constructor(private httpClient: HttpClient,
              private datePipe: DatePipe,
              private translateService: InnohealthTranslateService) {
  }

  getInvoicesTotalAmountByMonth(retrocession: boolean, globalFilter: GlobalInvoicesTotalAmountByMonthFilter): Observable<InvoicesTotalAmountByMonth> {
    return this.httpClient.post<InvoicesTotalAmountByMonth>(this.host + '/total-amount-by-month/summary' + '?retrocession=' + retrocession, globalFilter);
  }

  getAdditionalCosts(): Observable<AdditionalCostCategory[]> {
    return this.httpClient.get<AdditionalCostCategory[]>(this.host + '/total-amount-by-month/additional-costs');
  }

  exportFollowInvoice(exportType: string) {
    if (exportType === 'singlePage') {
      this.createCSVFile(this.invoicesTotalsAmountByMonth);
    } else if (exportType === 'allPage') {
      this.getInvoicesTotalAmountByMonth(false, this.globalFilter).subscribe(allTotalsByMonth => {
        this.createCSVFile(allTotalsByMonth);
        }
      );
    }
  }

  createCSVFile(invoicesTotalsAmountByMonth: InvoicesTotalAmountByMonth) {
    const totalAmountByTrialAndByMonthList = [];
    const trialInvoiceNumber = invoicesTotalsAmountByMonth.trialRows.length;
    for (let i = 0; i < trialInvoiceNumber; i++) {
      const amountByTrialByMonthList = [];
      const trialRow = invoicesTotalsAmountByMonth.trialRows[i];
      amountByTrialByMonthList.push(this.affectIfPossible(trialRow));
      for (const month of trialRow.monthColumns) {
        month.total ? amountByTrialByMonthList.push(month.total + '€') : amountByTrialByMonthList.push(0.00 + '€');
      }
      trialRow.total ? amountByTrialByMonthList.push(trialRow.total + '€') : amountByTrialByMonthList.push(0.00 + '€');
      totalAmountByTrialAndByMonthList.push(amountByTrialByMonthList);
    }

    let totalAmountOfTrialByMonthList = [];
    totalAmountOfTrialByMonthList.push(this.translateService.getTranslationString('GENERAL_LINGUISTICS_UNAVAILABLE_TOTAL'));
    for (const totalAmountByMonth of invoicesTotalsAmountByMonth.totalAmountByMonth) {
      totalAmountByMonth ? totalAmountOfTrialByMonthList.push(totalAmountByMonth + '€') : totalAmountOfTrialByMonthList.push(0.00 + '€');
    }
    totalAmountOfTrialByMonthList.push(invoicesTotalsAmountByMonth.totalAmount + '€');
    totalAmountByTrialAndByMonthList.push(totalAmountOfTrialByMonthList);
    const date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const headers = [
      this.translateService.getTranslationString('REPORT_INCLUSIONS_TOTAL_BY_MONTH_TRIAL'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_1'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_2'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_3'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_4'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_5'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_6'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_7'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_8'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_9'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_10'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_11'),
      this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_12'),
      this.translateService.getTranslationString('REPORT_INVOICES_TOTAL_AMOUNT_BY_MONTH_LEGEND_TOTAL')
    ];
    new IgCsvExport(totalAmountByTrialAndByMonthList, headers, 'follow-invoice-' + date).convertToCsvAndDownload();

  }

  affectIfPossible(trialRow: TrialRow) {
    let promoterName = '';
    let protocolNumber = '';
    let localId = '';
    if (trialRow) {
      if (trialRow.trialDto.localIdentifier) {
        localId = trialRow.trialDto.localIdentifier;
      }
      if (trialRow.promoterName) {
        promoterName = trialRow.promoterName;
      }
      if (trialRow.trialDto.protocolNumber) {
        protocolNumber = trialRow.trialDto.protocolNumber;
      }
    }
    return localId + '/' + promoterName + '/' + '/' + protocolNumber;
  }

}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../../../../shared/component/form/form.component';
import {NgForm} from '@angular/forms';
import {Data} from '../../../../../shared/entity/data';
import {SelectItem} from 'primeng/api';
import {Router} from '@angular/router';
import {Arm} from '../../../../entity/inclusion/arm';
import {TreatmentStudiedMoleculeDto} from '../../../../models/treatment-studied-molecule-dto';
import {MoleculeStudiedDto} from '../../../../models/molecule-studied-dto';
import {AbstractDesignLevelDto} from '../../../../models/abstract-design-level-dto';
import {TreatmentAdministrationRouteDto} from '../../../../models/treatment-administration-route-dto';
import {AdministrationRouteDto} from '../../../../models/administration-route-dto';
import {TreatmentDoseDto} from '../../../../models/treatment-dose-dto';
import {ArmEscalationExtensionDto} from '../../../../models/arm-escalation-extension-dto';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../dynamic-config/entity/form-config';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../../trial-app-data';
import {DataItemService} from '../../../../../custom-field/service/data-item.service';
import {PropertyEnum} from "../../../../../dynamic-config/entity/property-enum";
import {SharedService} from "../../../../../shared/services/shared.service";
import {
  TrialIdentificationInformationGeneralService
} from "../../../../services/trial-identification-information-general.service";
import {TrialIdentificationInformationGeneral} from "../../../../models/trial-identification-information-general";
import {Util} from "../../../../../helpers/util";

@Component({
  selector: 'ih-trial-design-form',
  templateUrl: './trial-design-form.component.html',
  styleUrls: ['./trial-design-form.component.css']
})
export class TrialDesignFormComponent extends FormComponent<Arm> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_DESIGN_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('designForm') designForm: NgForm;

  trialHhhId: number;
  arm: Arm = new Arm();
  escalationExtension: SelectItem[] = Data.escaladeExtensionStringFormatForDesignMap['OTHERS'];
  submitted = false;
  phases: Array<SelectItem> = new Array<SelectItem>();
  phasesWithEscaladeExtension: number[] = [];
  designArmInputText = false;

  constructor(
    private router: Router,
    private dataItemService: DataItemService,
    private dynamicConfigService: DynamicConfigService,
    private trialIdentificationSerivce: TrialIdentificationInformationGeneralService,
    private sharedService: SharedService
  ) {
    super();
    const url = this.router.parseUrl(this.router.url);
    this.trialHhhId = url.queryParams.hhhId;
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(TrialDesignFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.getEscaladeExtensionList();
    this.initProperties();
    this.initPhasesWithoutEscalation();
    this.getPhases();
    console.log(this.arm);
  }
  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.designArmInputText = this.dynamicConfigService.getProperty(PropertyEnum.designArmInputText);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  initializeEscaladeExtension() {
/*    this.escalationExtension = JSON.parse(JSON.stringify(this.escalationExtension));*/
    if (this.arm.phase && this.arm.phase.phase && this.arm.phase.phase.hhhId) {
      if (!this.phasesWithEscaladeExtension.includes(this.arm.phase.phase.hhhId)) {
        this.arm.escaladeExtension = new ArmEscalationExtensionDto();
      } else {
        this.arm.escaladeExtension.enabled = true;
      }
    } else {
      let found = false;
      for (const phase of this.phases) {
        if (this.phasesWithEscaladeExtension.includes(phase.value)) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.escalationExtension = [];
        this.arm.escaladeExtension = new ArmEscalationExtensionDto();
      }
    }
  }

  getPhases(): void {
    this.dataItemService.getItems('phase-by-trial|' + this.trialHhhId + '|' + (this.arm.hhhId || 0)).subscribe(result => {
      this.phases = result;
      this.initializeEscaladeExtension();
    });
  }

  // getPhases(): void {
  //   this.dataItemService.getItems('phase').subscribe(result => {
  //     this.phases = result;
  //     this.initializeEscaladeExtension();
  //   });
  // }

  onSubmit() {
    this.submitted = true;
    if (this.designForm.invalid) {
      return;
    }

    this.callback(this.arm);
    this.display = false;
  }
  phaseChanged() {
    if (this.arm.phase && this.arm.phase.phase && this.arm.phase.phase.hhhId) {
      if (!this.phasesWithEscaladeExtension.includes(this.arm.phase.phase.hhhId)) {
        this.arm.escaladeExtension = new ArmEscalationExtensionDto();
      }else {
        this.arm.escaladeExtension.enabled = true;
      }
    }
  }

  addSimpleDesignLevel() {
    const liaison = new AbstractDesignLevelDto();
    liaison.level = this.arm.designLevels.length;
    liaison.type = 'LIAISON';
    liaison.fieldName = '';
    liaison.isNew = true;
    this.arm.designLevels.push(liaison);
  }

  addTreatmentDesignLevel() {
    const armTreatment = new AbstractDesignLevelDto();
    armTreatment.type = 'TREATMENT';
    const studiedMolecule = new TreatmentStudiedMoleculeDto();
    studiedMolecule.enabled = true;
    studiedMolecule.studiedMolecule = new MoleculeStudiedDto();
    armTreatment.studiedMolecule = studiedMolecule;

    const administrationRoute = new TreatmentAdministrationRouteDto();
    administrationRoute.enabled = true;
    administrationRoute.administrationRoute = new AdministrationRouteDto();
    armTreatment.administrationRoute = administrationRoute;

    const dose = new TreatmentDoseDto();
    dose.enabled = true;
    armTreatment.dose = dose;

    armTreatment.level = this.arm.designLevels.length;
    this.arm.designLevels.push(armTreatment);
  }

  initPhasesWithoutEscalation() {
    this.dataItemService.getItems('phase-selected').subscribe(res => {
      this.phasesWithEscaladeExtension = [];
      const phaseOneLabels = ['Phase I', 'Phase Ia', 'Phase Ib', 'Phase I/II'];
      for (const phase of res) {
        if (phaseOneLabels.indexOf(phase.label) >= 0) {
          this.phasesWithEscaladeExtension.push(phase.value);
        }
      }
    });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);


  getEscaladeExtensionList() {

    this.trialIdentificationSerivce.load(this.trialHhhId).subscribe(
      (res: TrialIdentificationInformationGeneral) => {
        if (res && !Util.isNullOrUndefinedOrEmpty(res.escalationExtension)) {


          switch (res.escalationExtension) {
            case 'Escalade':
              this.escalationExtension = [];
              this.escalationExtension.push({label: 'Escalade', value: 'ESCALADE'});
              break;

            case 'Extension':
              this.escalationExtension = [];
              this.escalationExtension.push({label: 'Extension', value: 'EXTENSION'});
              break;

            case 'Escalade/Extension':
              this.escalationExtension = [];
              this.escalationExtension.push(
                {label: 'Escalade', value: 'ESCALADE'},
                {label: 'Extension', value: 'EXTENSION'}
              );
              break;

            default:
              console.warn('Unknown escalationExtension value:', res.escalationExtension);
          }
        } else {
          this.escalationExtension = Data.escaladeExtensionStringFormatForDesign;
        }
      },
      (error) => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading TrialIdentification.' + error);
      }
    );
  }


}

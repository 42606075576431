import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('Heartbeat')
export class Heartbeat {
  @JsonProperty('sessionId', Number)
  public sessionId: number;
  @JsonProperty('timestamp', Number)
  public timestamp: number;

  constructor(sessionId: number) {
    this.sessionId = sessionId;
    this.timestamp = new Date().getTime();
  }
}

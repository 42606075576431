import {Component, OnInit, ViewChild} from '@angular/core';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import {AgendaService} from '../services/agendaService';
import {Schedule} from '../modals/schedule';
import {ScheduledItem} from '../modals/scheduled-item';
import {ScheduleHumanResourcesColor} from '../modals/schedule-human-resources-color';
import {CalendarEvent} from '../modals/calendar-event';
import {Router} from '@angular/router';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../login/services/authentication.service';
import {Role} from '../../login/models/role';
import {isNullOrUndefined} from 'util';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {AgendaAppData} from '../agenda-app-data';
import {FullCalendarComponent} from "@fullcalendar/angular";


@Component({
  selector: 'ih-agenda-container',
  templateUrl: './agenda-container.component.html',
  styleUrls: ['./agenda-container.component.css']
})
export class AgendaContainerComponent implements OnInit {
  target = DynamicDefinitionEnum.AGENDA_FILTER_FORM;
  config: FormConfig = new FormConfig();
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  selectedDate: string;
  amendmentsEnabled = true;
  inclusionInformationArmEnabled = false;

  allDayText: string;
  schedule: Schedule = new Schedule();
  scheduledHR: ScheduledItem = new ScheduledItem();
  scheduledTrial: ScheduledItem = new ScheduledItem();
  scheduledTrials: ScheduledItem[];
  headerConfig: any;
  buttonText: any;
  locale = 'fr';
  id: number;
  eventTitle: string;
  title: string;
  calendarEventList: CalendarEvent[] = [];
  currentYear: number;
  currentDay: number;
  currentDayString: string;
  currentMonth: string;
  agendaYearDisplayed: number;
  calendarPlugins = [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, 'list'];
  canAddHr = false;
  private isYearCalendar = false;
  calendarDataValue: any;
  inputColorDisabled = false;
  isDateClicked = false;
  eventsOfDaySelected: CalendarEvent[] = [];
  readonly monthNames = ['MODULE_AGENDA_JANUARY',
    'MODULE_AGENDA_FEBRUARY',
    'MODULE_AGENDA_MARS',
    'MODULE_AGENDA_APRIL',
    'MODULE_AGENDA_MAY',
    'MODULE_AGENDA_JUNE',
    'MODULE_AGENDA_JULY',
    'MODULE_AGENDA_AUGUST',
    'MODULE_AGENDA_SEPTEMBER',
    'MODULE_AGENDA_OCTOBER',
    'MODULE_AGENDA_NOVEMBER',
    'MODULE_AGENDA_DECEMBER'];
  readonly daysNames = {
    Mon: 'MODULE_AGENDA_MONDAY',
    Tue: 'MODULE_AGENDA_TUESDAY',
    Wed: 'MODULE_AGENDA_WEDNESDAY',
    Thu: 'MODULE_AGENDA_THURSDAY',
    Fri: 'MODULE_AGENDA_FRIDAY',
    Sat: 'MODULE_AGENDA_SATURDAY',
    Sun: 'MODULE_AGENDA_SUNDAY',
  };

  yearOfDateSelected: number;
  dayOfDateSelected: number;
  dayStringOfDateSelected: string;
  monthOfDateSelected: string;


  constructor(private agendaService: AgendaService,
              private translateService: InnohealthTranslateService,
              private translate: TranslateService,
              private router: Router,
              private authService: AuthenticationService,
              private dynamicConfigService: DynamicConfigService) {
  }

  ngOnInit() {
    this.allDayText = 'Toute la journée';
    this.checkCanAddHr();
    this.currentYear = new Date().getFullYear();
    this.getAllEvents(this.currentYear);
    this.headerConfig = {
      left: 'prev,next,today',
      center: 'title',
      right: 'timeGridDay ,dayGridWeek, dayGridMonth, listYear'
    };
    this.translate.stream('GENERAL_LINGUISTICS_LANGUAGE_CODE').subscribe(res => {
        this.locale = res;
        this.buttonText = {
          next: this.translateService.getTranslationString('MODULE_SCHEDULE_NEXT'),
          prev: this.translateService.getTranslationString('MODULE_SCHEDULE_PREVIOUS'),
          today: this.translateService.getTranslationString('GENERAL_LINGUISTICS_TODAY'),
          day: this.translateService.getTranslationString('GENERAL_LINGUISTICS_DAY_LCASE'),
          dayGridMonth: this.translateService.getTranslationString('MODULE_SCHEDULE_MONTH'),
          listYear: this.translateService.getTranslationString('MODULE_SCHEDULE_YEAR'),
          dayGridWeek: this.translateService.getTranslationString('MODULE_SCHEDULE_WEEK'),
        };
        this.getCurrentDate();
      }
    );
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.inclusionInformationArmEnabled = this.dynamicConfigService.getProperty(PropertyEnum.inclusionInformationArmEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, AgendaAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  checkCanAddHr() {
    const role: string = this.authService.currentUserRole;
    if (this.dynamicConfigService.getProperty(PropertyEnum.agendaAllUserAccessRHList)) {
      this.canAddHr = true;
    } else {
      if (role === Role.Editor || role === Role.Admin || role === Role.Manager) {
        this.canAddHr = true;
      }
    }
  }

  getCurrentDate() {
    this.currentYear = new Date().getFullYear();
    this.currentDay = new Date().getDate();
    const currentDayStringAbbrev = new Date().toDateString().substring(0, 3);
    this.currentDayString = this.translateService.getTranslationString(this.daysNames[currentDayStringAbbrev]);
    const currentMonthNumber = new Date().getMonth() + 1;
    this.currentMonth = this.translateService.getTranslationString(this.monthNames[currentMonthNumber - 1]);
  }

  show(event: any) {
    if (event.view.title.length !== 4) {
      this.eventTitle = event.event.title;
      let index = -1;
      const list: any = document.getElementsByClassName('fc-event');
      for (let i = 0; i < list.length; i++) {
        if (this.eventTitle.trim() === list[i].innerText.trim()) {
          index = i;
          break;
        }
      }
      if (index !== -1) {
        document.getElementsByClassName('fc-event').item(index).setAttribute('title', this.eventTitle);
      }
    }
  }


  detectYearChangeByMonth(event: any) {
    this.agendaYearDisplayed = +(event.view.title.toString()
      .substring(event.view.title.toString().length - 4, event.view.title.toString().length));
    if (this.agendaYearDisplayed !== this.currentYear) {
      this.isYearCalendar = false;
      this.currentYear = this.agendaYearDisplayed;
      this.getAllEvents(this.currentYear);
    } else if (event.view.type === 'dayGridWeek' && this.isYearCalendar === true) {
      this.isYearCalendar = false;
      this.getAllEvents(this.currentYear);
    }
  }
  detectYearChangeByYear(event) {
    if (event.view.title.length === 4) {
      this.isYearCalendar = true;
      this.agendaYearDisplayed = +(event.view.title);
      this.currentYear = this.agendaYearDisplayed;
      this.getAllEvents(this.currentYear);
    }
  }
  updateTodayEvents(event) {
    const selectedDate = event.dateStr;
    console.log('Selected Date:', selectedDate);

    const selectedDateObj = new Date(selectedDate);
    selectedDateObj.setHours(0, 0, 0, 0);
    if (isNaN(selectedDateObj.getTime())) {
      console.error('Invalid date:', selectedDate);
      return;
    }

    this.currentDay = selectedDateObj.getDate();
    this.currentMonth = selectedDateObj.toLocaleString('default', { month: 'long' });
    this.currentYear = selectedDateObj.getFullYear();
    this.currentDayString = selectedDateObj.toLocaleDateString();
    this.schedule.todayEvents = this.calendarEventList.filter(evt => {
      const eventStartDate = new Date(evt.start);
      eventStartDate.setHours(0, 0, 0, 0);

      return eventStartDate.getTime() === selectedDateObj.getTime();
    });

    console.log('Events for the selected day:', this.schedule.todayEvents);
  }
  getAllEvents(year: number) {
    this.calendarEventList = [];
    this.agendaService.getAllEvents(year).subscribe(res => {
        this.inputColorDisabled = false;
        this.schedule = res;
        const scheduleHumanResourcesColor = new ScheduleHumanResourcesColor();
        scheduleHumanResourcesColor.color = '#fff99';
        scheduleHumanResourcesColor.humanResourceName = localStorage.getItem('authenticatedUser');
        this.schedule.hrColor.splice(0, 0, scheduleHumanResourcesColor);
        const scheduleHr = new ScheduledItem();
        scheduleHr.name = '';
        scheduleHr.hhhId = 0;
        this.schedule.scheduledHRS.splice(0, 0, scheduleHr);
        this.getTrialItem();
        for (const event of res.yearEvents) {
          const item = {
            title: event.title,
            start: event.start ? new Date(event.start) : null,
            end: event.end ? new Date(event.end) : null,
            backgroundColor: this.isYearCalendar === true ? event.backgroundColor : 'transparent',
            id: event.id,
            url: '',
            borderColor: event.borderColor,
            textColor: event.backgroundColor,
            className: this.getBorderClassName(event.borderColor) ?
              this.getBorderClassName(event.borderColor) + this.getTextColorClass(this.isYearCalendar === true ? event.backgroundColor : 'transparent') :
              this.getTextColorClass(this.isYearCalendar === true ? event.backgroundColor : 'transparent'), // 4 classes for each event type
          };
          this.calendarEventList.push(item);
        }
      }, error => {
        console.error('An error occurred while loading schedule events and hr.' + error);
      }
    );
  }

  getBorderClassName(color: string): string {
    if (this.isYearCalendar === true) {
      switch (color) {
        case '#337ab7': {
          return 'inclusion-border';
        }
        case '#f0ad4e': {
          return 'visit-border';
        }
        case '#5cb85c': {
          return 'invoice-border';
        }
        case '#800080': {
          return 'trial-border';
        }
        default: {
          return null;
        }
      }
    } else {
      return null;
    }
  }

  redirect(event: any) {
    this.id = event.event.id;
    this.title = event.event.title;
    const index = this.schedule.yearEvents.findIndex((calendarEvent: CalendarEvent) => calendarEvent.id === +this.id && calendarEvent.title == this.title  );
    const calendarEventItem = this.schedule.yearEvents[index];
    if (calendarEventItem.url.indexOf('+') === -1) {
      this.calendarDataValue = calendarEventItem.url.split('=').join(',').split('&').join(',').split(',');
      this.calendarDataValue[2] = this.calendarDataValue[3];
    } else {
      this.calendarDataValue = calendarEventItem.url.split('+');
    }
    const calendarData = this.calendarDataValue;
    if (calendarEventItem.url.indexOf('identification') !== -1) {
      this.router.navigate(
        ['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId: calendarData[1]}}
      ).then();
    } else if (calendarEventItem.url.indexOf('inclusions') !== -1) {
      this.router.navigate([this.getInclusionInformationPath()],
        {
          queryParams: {
            hhhId: calendarData[1],
            inclusionId: calendarData[2]
          }
        }
      ).then();
    } else if (calendarEventItem.url.indexOf('invoices') !== -1) {
      this.router.navigate(
        ['/invoice-details'],
        {queryParams: {invoiceHhhId: calendarData[1]}}
      ).then();
    } else {
      this.router.navigate(['/trial-details/inclusion-details/calendar'], {
        queryParams: {
          hhhId: calendarData[1],
          inclusionId: calendarData[2]
        }
      }).then();
    }
  }

  addHumanResource() {
    this.agendaService.addHumanResource(this.scheduledHR.hhhId).subscribe(() => {
      this.getAllEvents(this.currentYear);
    });
  }

  getInclusionInformationPath(): string {
    const path = this.amendmentsEnabled && !this.inclusionInformationArmEnabled ? 'inclusion-information-version-protocol' : 'inclusion-information-arm';
    return `/trial-details/inclusion-details/${path}/`;
  }

  addTrial() {
    this.agendaService.addTrial(this.scheduledTrial.hhhId).subscribe(() => {
      this.getAllEvents(this.currentYear);
    });
  }

  editHRColor(id: number, color: string) {
    this.inputColorDisabled = true;
    this.agendaService.updateHumanResourceColor(id, color).subscribe(() => {
      this.getAllEvents(this.currentYear);
    });
  }

  removeHumanResource(id: number) {
    this.agendaService.removeHumanResource(id).subscribe(() => {
      const index = this.schedule.hrColor.findIndex(
        (scheduleColor: ScheduleHumanResourcesColor) => scheduleColor.humanResourceHhhId === id);
      this.schedule.hrColor.splice(index, 1);
      this.getAllEvents(this.currentYear);
    });
  }

  removeTrial(id: number) {
    this.agendaService.removeTrial(id).subscribe(() => {
      const index = this.scheduledTrials.findIndex(
        (trial: ScheduledItem) => trial.hhhId === id);
      this.scheduledTrials.splice(index, 1);
      this.getAllEvents(this.currentYear);
      this.getTrialItem();
      this.scheduledTrial = new ScheduledItem();
    });
  }

  getTextColorClass(color: string): string {
    if (color === 'transparent' || isNullOrUndefined(color)) {
      return '';
    }
    const match = color.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i);
    if (match) {
      const r = parseInt(match[1], 16);
      const g = parseInt(match[2], 16);
      const b = parseInt(match[3], 16);
      const sum = r + g + b;
      return sum < 383 ? ' white' : '';
    }
    return '';
  }


  getTrialItem() {
    this.agendaService.getTrialItem(this.schedule.hhhId).subscribe(res => {
      this.scheduledTrials = res;
    });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
}

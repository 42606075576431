import {NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {AgendaContainerComponent} from './agenda-container/agenda-container.component';
import {AgendaRoutingModule} from './agenda-routing.module';
import {CardModule} from 'primeng/card';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import localeFr from '@angular/common/locales/fr';
import {TableModule} from 'primeng/table';
import {ButtonModule, DropdownModule, InputTextModule, MessageModule, OverlayPanelModule, TooltipModule} from 'primeng/primeng';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {FullCalendarModule} from '@fullcalendar/angular';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {DynamicConfigModule} from "../dynamic-config/dynamic-config.module";

registerLocaleData(localeFr);


@NgModule({
  declarations: [AgendaContainerComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    TooltipModule,
    ButtonModule,
    DropdownModule,
    CardModule,
    FullCalendarModule,
    OverlayPanelModule,
    MessageModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    AgendaRoutingModule,
    HttpClientModule,
    TranslateModule,
    DynamicConfigModule,
  ], bootstrap: [AgendaContainerComponent]
})
export class AgendaModule { }

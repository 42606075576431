import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('ModuleHeaderConfig')
export class ModuleHeaderConfig {

  @JsonProperty('title', String, true)
  title: string = undefined;

  @JsonProperty('icon', String, true)
  icon: string = undefined;

  @JsonProperty('privateComment', Boolean)
  privateComment: boolean = undefined;

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {StatisticsService} from '../services/statistics.service';
import {ReportsRateToActiveInPatientFilter} from './models/reports-rate-to-active-in-patient-filter';
import {DataService} from '../../shared/services/data-service';
import {InnohealthTranslateService} from 'src/app/shared/services/innohealth-translate.service';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {StatisticsAppData} from '../statistics-app-data';

@Component({
  selector: 'ih-follow-active-file',
  templateUrl: './follow-active-file.component.html',
  styleUrls: ['./follow-active-file.component.css']
})
export class FollowActiveFileComponent implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.STATISTICS_FOLLOW_ACTIVE_FILE_FILTER_FORM;
  config: FormConfig = new FormConfig();

  includedPatientData: any;
  screenedPatientData: any;
  years: SelectItem[] = [];
  reportsRateToActiveInPatientFilter: ReportsRateToActiveInPatientFilter = new ReportsRateToActiveInPatientFilter();
  barChartOptions: any;

  constructor(private statisticsService: StatisticsService,
              private translate: TranslateService,
              private translateService: InnohealthTranslateService,
              private dataService: DataService,
              private dynamicConfigService: DynamicConfigService) {
    this.barChartOptions = {
      title: {
        display: false
      },
      legend: {
        display: true,
        position: 'bottom'
      },
      plugins: {
        datalabels: {
          display: false
        }
      }
    };
  }

  ngOnInit() {
    this.initProperties();
    this.dataService.setExportPageModule('src/app/statistics/follow-active-file/follow-active-file.component');
    this.getYears();
    this.translate.stream('REPORT_INCLUSIONS_BY_TRIALS_CHART_TITLE').subscribe(() => {
      this.getReportsRateToActiveInpatients();
    });
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, StatisticsAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  getYears() {
    for (let i = 1900; i < 2050; i += 10) {
      this.years.push({label: i.toString(), value: i});
    }
    this.reportsRateToActiveInPatientFilter.year = 2020;
  }

  getReportsRateToActiveInpatients() {
    const includedPatientPerYearLabels = [];
    const tab1labels = [];
    const includedPatientPerYearDataTab = [];
    const screenedPatientPerYearDataTab = [];

    let establishmentLabels = [];
    const establishmentData = [];
    this.statisticsService.getReportsRateToActiveInpatients(this.reportsRateToActiveInPatientFilter).subscribe(report => {
      this.statisticsService.setReportsRateToActiveInpatients(report);
      for (const establishmentDepartmentPerYear of report.establishmentDepartmentPerYear) {
        establishmentLabels.push(establishmentDepartmentPerYear.year.substring(0, 4));
        establishmentData.push(establishmentDepartmentPerYear.nbEstablishmentDepartment);
      }
      establishmentLabels = this.arrayUnique(establishmentLabels.concat(includedPatientPerYearLabels));
      establishmentLabels.sort();
      for (let index = 0; index < report.establishmentDepartmentPerYear.length; index++) {
        tab1labels.push(report.establishmentDepartmentPerYear[index].year.substring(0, 4));
        includedPatientPerYearDataTab.push({x: report.establishmentDepartmentPerYear[index].year, y: 0});
        screenedPatientPerYearDataTab.push({x: report.establishmentDepartmentPerYear[index].year, y: 0});
      }
      if (report.includedPatientPerYear.length !== 0) {
        for (let i = 0; i < report.includedPatientPerYear.length; i++) {
          for (let index = 0; index < establishmentLabels.length; index++) {
            if (report.includedPatientPerYear[i].year.substring(0, 4) ===
              establishmentLabels[index]) {
              includedPatientPerYearDataTab.splice(index, 1, {
                x: report.includedPatientPerYear[i].year,
                y: report.includedPatientPerYear[i].nbPatient
              });
            }
          }
        }
      }
      if (report.screenedPatientPerYear.length !== 0) {
        for (let i = 0; i < report.screenedPatientPerYear.length; i++) {
          for (let index = 0; index < establishmentLabels.length; index++) {
            if (report.screenedPatientPerYear[i].year.substring(0, 4) ===
              establishmentLabels[index]) {
              screenedPatientPerYearDataTab.splice(index, 1, {
                x: report.screenedPatientPerYear[i].year,
                y: report.screenedPatientPerYear[i].nbPatient
              });
            }
          }
        }
      }
      this.includedPatientData = {
        labels: establishmentLabels,
        datasets: [
          {
            type: 'line',
            label: this.translateService.getTranslationString('REPORT_INCLUSIONS_BY_TRIALS_CHART_TITLE'),
            borderColor: '#397CC3',
            backgroundColor: '#397CC3',
            borderWidth: 2,
            fill: false,
            data: includedPatientPerYearDataTab,
          },
          {
            type: 'bar',
            label: this.translateService.getTranslationString('REPORT_RATE_TO_ACTIVE_IN_PATIENTS_ALL_SERVICES'),
            backgroundColor: '#93E7F2',
            borderColor: '#93E7F2',
            data: establishmentData,
            borderWidth: 2
          }
        ],
        options: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontSize: 11
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              autoSkip: true
            }]
          }
        }
      };

      this.screenedPatientData = {
        labels: establishmentLabels,
        datasets: [
          {
            type: 'line',
            label: this.translateService.getTranslationString('REPORT_RATE_TO_ACTIVE_IN_PATIENTS_SCREENED'),
            borderColor: '#397CC3',
            backgroundColor: '#397CC3',
            borderWidth: 2,
            fill: false,
            data: screenedPatientPerYearDataTab,
          },
          {
            type: 'bar',
            label: this.translateService.getTranslationString('REPORT_RATE_TO_ACTIVE_IN_PATIENTS_ALL_SERVICES'),
            backgroundColor: '#93E7F2',
            borderColor: '#93E7F2',
            data: establishmentData,
            borderWidth: 2
          }
        ],
        options: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontSize: 11
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              autoSkip: true
            }]
          }
        }
      };
    });
  }

  arrayUnique(array) {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) {
          a.splice(j--, 1);
        }
      }
    }
    return a;
  }

  isParent() {
    return this.dynamicConfigService.parent;
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {IsDeletable} from '../../is-deletable';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {ActCategory} from '../models/act-category';


@Injectable({
  providedIn: 'root'
})
export class ActCategoryService {
  host = new Properties().host + '/act-category';
  private actCategoryListAllPage: ActCategory[] = [];
  private actCategoryListSinglePage: ActCategory[] = [];


  constructor(private httpClient: HttpClient) {
  }

  setActCategoryToExportAllPage(actCategoryList: ActCategory[]) {
    this.actCategoryListAllPage = actCategoryList;
  }

  setActCategoryListToExportSinglePage(actCategoryList: ActCategory[]) {
    this.actCategoryListSinglePage = actCategoryList;
  }

  loadList(): Observable<ActCategory[]> {
    return this.httpClient.get<ActCategory[]>(this.host + '/load-list');
  }


  addActCategory(act: ActCategory): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', act);
  }

  updateAct(act: ActCategory) {
    return this.httpClient.put(this.host + `/update`, act);
  }

  exportActsCategories(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(actId: number) {
    return this.httpClient.get(this.host + `/delete/${actId}`);
  }

}

import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {SharedService} from '../../services/shared.service';
import {DataItemService} from '../../../custom-field/service/data-item.service';

export const COMBOBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TableFilterDropdownComponent),
  multi: true
};

@Component({
  selector: 'ih-table-filter-dropdown',
  templateUrl: './table-filter-dropdown.component.html',
  styleUrls: ['./table-filter-dropdown.component.css'],
  providers: [COMBOBOX_VALUE_ACCESSOR]
})
export class TableFilterDropdownComponent implements OnInit, ControlValueAccessor {

  @Input() items: SelectItem[] = [];
  @Input() disabled: boolean;
  @Input() editable = false;
  @Input() showClear = true;
  @Input() optionLabel: string;
  @Input() name: string;
  @Input() placeHolderValue: string;
  @Output() change: EventEmitter<number> = new EventEmitter();
  @Output() valueChange: EventEmitter<string> = new EventEmitter();
  @Output() valueInput: EventEmitter<string> = new EventEmitter();
  @Output() onShow: EventEmitter<number> = new EventEmitter();
  @Output() onHide: EventEmitter<number> = new EventEmitter();
  @Output() onFocus: EventEmitter<void> = new EventEmitter();
  @Output() onBlur: EventEmitter<void> = new EventEmitter();
  @Output() temsUpdated: EventEmitter<number> = new EventEmitter();

  onChange = (_) => {
  };
  onTouched = () => {
  };
  private initialValue: string;
  private overlayVisible = false;

  constructor(private sharedService: SharedService, private dataItemService: DataItemService) {
  }

  private _value: string;

  get value(): string {
    return this._value;
  }

  set value(v: string) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
      this.valueChange.emit(this._value);
      console.log(this._value);
      if (!this.overlayVisible) {
        this.valueInput.emit(this._value);
      }
    }
  }

  private _type: string;

  get type(): string {
    return this._type;
  }

  @Input()
  set type(v: string) {
    if (v !== this._type) {
      this._type = v;
      this.loadItems(v);
    }
  }

  ngOnInit() {
  }

  writeValue(v: string) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(this._value);
    }
  }

  loadItems(v: string): void {
    this.dataItemService.getItems(v).subscribe(res => {
      for (const item of res) {
        const itemChild = {label: item.label, value: item.label};
        this.items.push(itemChild);
      }
    });
    this.items.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  onFocusDo(): void {
    this.onFocus.emit();
  }

  onBlurDo(): void {
    this.onBlur.emit();
  }

  onShowDo() {
    this.onShow.emit();
    this.initialValue = this._value;
    this.overlayVisible = true;
  }

  onHideDo() {
    this.onHide.emit();
    this.overlayVisible = false;
    if (this.value !== this.initialValue) {
      this.valueInput.emit(this._value);
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}

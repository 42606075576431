export class InvoiceCounterPart {
  public hhhId: number;
  public localIdentifier: any;
  public promoter: any;
  public promoterHhhId: number;
  public numberProtocol: any;
  public category: any;
  public description: any;
  public unitPrice: any;
  public unitNumber: any;
  public total: any;
  public invoiceable: boolean;
  public checked: boolean;
  public visitAct: boolean;
  public visitName?: string;
  public visitDate?: string;
  public patientLastName?: string;
  public patientInitials?: string;
  public inclusionNumber: string;
  public inclusionPreScreeningIdentifier: string;
  public inclusionScreeningIdentifier: string;

  init(obj: any): InvoiceCounterPart {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

}

export class InvoiceCounterPartToExport {
  public localIdentifier: string;
  public promoter: string;
  public numberProtocol: string;
  public category: string;
  public description: string;
  public invoiceable: string;
  public unitPrice: number;
  public unitNumber: number;
  public total: number;

  public constructor(invoiceFixedCost: InvoiceCounterPart) {
    this.localIdentifier = invoiceFixedCost.localIdentifier;
    this.promoter = invoiceFixedCost.promoter;
    this.numberProtocol = invoiceFixedCost.numberProtocol;
    this.category = invoiceFixedCost.category;
    this.description = invoiceFixedCost.description;
    this.invoiceable = invoiceFixedCost.invoiceable ? 'Oui' : 'Non';
    this.unitPrice = invoiceFixedCost.unitPrice;
    this.unitNumber = invoiceFixedCost.unitNumber;
    this.total = invoiceFixedCost.total;
  }
}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TrialAdministrativeRegulatoryInformation} from '../../../models/trial-administrative-regulatory-information';
import {FR} from '../../../../shared/entity/calendar-language';
import {SelectItem} from 'primeng/api';
import {Data} from '../../../../shared/entity/data';
import {TrialService} from '../../../services/trial.service';
import {DataService} from '../../../../shared/services/data-service';
import {Router} from '@angular/router';
import {SharedService} from '../../../../shared/services/shared.service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {DateLastAmendmentFormComponent} from '../date-last-amendment-form/date-last-amendment-form.component';
import {DateLastAmendmentCPPFormComponent} from '../date-last-amendment-cpp-form/date-last-amendment-cpp-form.component';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {AppData} from '../../../../helpers/app-data';
import {Util} from '../../../../helpers/util';
import {TrialAdministrativeRegulatoryService} from '../../../services/trial-administrative-regulatory.service';
import {TrialAdministrativeInformationService} from '../../../services/trial-administrative-information.service';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {
  DateLastDrcAgreementFormComponent
} from '../date-last-drc-agreement-form/date-last-drc-agreement-form.component';
import {
  DateLastCsetAgreementFormComponent
} from '../date-last-cset-agreement-form/date-last-cset-agreement-form.component';

@Component({
  selector: 'ih-trial-administrative-regulatory-information',
  templateUrl: './trial-administrative-regulatory-information.component.html',
  styleUrls: ['./trial-administrative-regulatory-information.component.css']
})
export class TrialAdministrativeRegulatoryInformationComponent implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_REGULATORY_INFORMATION_FORM;
  config: FormConfig = new FormConfig();

  ghtMontBlancCustomizationEnabled= false;

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;


  regulatoryInformation: TrialAdministrativeRegulatoryInformation = new TrialAdministrativeRegulatoryInformation();
  trialId: number;
  locale = FR;
  submitted = false;
  noYesOptions: SelectItem[] = Data.yesOrNoOrNa;
  noYesNaOptions: SelectItem[] = this.noYesOptions.map(item => ({
    label: item.label,
    value: String(item.value)
  }));
  numberCNIL: SelectItem ;


  constructor(
      public trialService: TrialService,
      private dataService: DataService,
      private router: Router,
      private cfr: ComponentFactoryResolver,
      private sharedService: SharedService,
      private trialAdministrativeRegulatoryService: TrialAdministrativeRegulatoryService,
      private trialAdministrativeInformationService: TrialAdministrativeInformationService,
      private canDeactivateService: CanDeactivateService,
      private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    this.initProperties();
    const url = this.router.parseUrl(this.router.url);
    this.trialId = url.queryParams.hhhId;
    this.trialService.trialHhhId = url.queryParams.hhhId;
    this.getTrialAdministrativeRegulatoryInformation();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.ghtMontBlancCustomizationEnabled = this.dynamicConfigService.getProperty(PropertyEnum.ghtMontBlancCustomizationEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.regulatoryInformation.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialAdministrativeRegulatory, this.regulatoryInformation.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.regulatoryInformation.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  save() {
    this.submitted = true;
    if (!this.regulatoryInformation.isValid(this.config)) {
      return;
    }
    this.regulatoryInformation.trialHhhId = this.trialService.trialHhhId;
    this.trialAdministrativeRegulatoryService.saveTrialAdministrativeRegulatoryInformation(this.trialService.trialHhhId, this.regulatoryInformation).subscribe(res => {
      this.sharedService.showSuccess();
      this.canDeactivateService.canBeDeactivated = true;
    }, error => {
      console.error(error);
      this.sharedService.showFailure();
    });
  }

  private getTrialAdministrativeRegulatoryInformation() {
    this.trialAdministrativeRegulatoryService.getTrialAdministrativeRegulatoryInformation(this.trialService.trialHhhId).subscribe(res => {
    this.regulatoryInformation = res;
    const cnil = this.noYesOptions.find(item => String(item.value) === String(this.regulatoryInformation.numberCNIL));
    // this.numberCNIL = cnil.value;
    }, error => {
      console.error(error);
      this.sharedService.showFailure();
    });
  }

  addLastDateAmendment() {
    const showPopupFactory = this.cfr.resolveComponentFactory(DateLastAmendmentFormComponent);
    const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
    const dateLastAmendmentFormComponent = showPopupComponentRef.instance;
    dateLastAmendmentFormComponent.setTrialId(this.trialId);
    dateLastAmendmentFormComponent.onAdd((dateLastAmendmentStream: string) => {
      this.regulatoryInformation.dateLastAmendmentStream = dateLastAmendmentStream;
    });

  }


  addLastDateCpp() {
    const showPopupFactory = this.cfr.resolveComponentFactory(DateLastAmendmentCPPFormComponent);
    const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
    const dateLastAmendmentCppFormComponent = showPopupComponentRef.instance;
    dateLastAmendmentCppFormComponent.setTrialId(this.trialId);
    dateLastAmendmentCppFormComponent.onAdd((dateCPPAmendmentStream: string) => {
      this.regulatoryInformation.dateCPPAmendmentStream = dateCPPAmendmentStream;
      this.regulatoryInformation.lastCPPAmendment.date = Util.convertStringToDate(dateCPPAmendmentStream);
    });
  }

  addLastDrcAgreement() {
    const showPopupFactory = this.cfr.resolveComponentFactory(DateLastDrcAgreementFormComponent);
    const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
    const dateLastDrcAgreementFormComponent = showPopupComponentRef.instance;
    dateLastDrcAgreementFormComponent.setTrialId(this.trialId);
    dateLastDrcAgreementFormComponent.onAdd((dateDrcAgreementStream: string) => {
      this.regulatoryInformation.drcAgreementDateStream = dateDrcAgreementStream;
    });
  }

  addLastCsetAgreement() {
    const showPopupFactory = this.cfr.resolveComponentFactory(DateLastCsetAgreementFormComponent);
    const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
    const dateLastCsetAgreementFormComponent = showPopupComponentRef.instance;
    dateLastCsetAgreementFormComponent.setTrialId(this.trialId);
    dateLastCsetAgreementFormComponent.onAdd((dateCsetAgreementStream: string) => {
      this.regulatoryInformation.csetAgreementDateStream = dateCsetAgreementStream;
    });
  }

  export(event: any): void {
    const request = new ExportRequestBuilder<void>()
        .objectId(this.trialId)
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .build();

    this.trialAdministrativeInformationService.exportedAdministrativeInformation(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);

}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DetailedTrialAct} from '../entity/trial-additionnal-costs/detailed-trial-act';
import {TrialAct} from '../entity/theoretical-calendar/trial-act';
import {ReferenceAct} from '../../additional-costs/models/reference-act';
import {SyntheticTrialAct} from '../entity/theoretical-calendar/synthetic-trial-act';
import {VisitAdditionalCost} from '../entity/trial-additionnal-costs/visit-additional-cost';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class TrialActService {

  public host = new Properties().host + '/trial-act';
  public trialHhhId: number;
  public trialActList: DetailedTrialAct[] = [];

  constructor(private httpClient: HttpClient) {
  }

  importActs(file: File, trialHhhId): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.httpClient.post<any>(this.host + '/import/trial/acts/csv/' + trialHhhId, formdata);
  }

  getTrialActsByHhhIds(hhhIds: number[]): Observable<TrialAct[]> {
    return this.httpClient.post<TrialAct[]>(this.host + '/load-by-id', hhhIds);
  }

  getReferenceActByTrialAct(hhhId: number): Observable<ReferenceAct> {
    return this.httpClient.get<ReferenceAct>(this.host + '/reference-act/' + hhhId);
  }

  getTrialActList(hhhId: number): Observable<DetailedTrialAct[]> {
    return this.httpClient.get<DetailedTrialAct[]>(this.host + '/load-detailed-act-list/' + hhhId);
  }

  getTrialTrialActList(hhhId: number): Observable<VisitAdditionalCost[]> {
    return this.httpClient.get<VisitAdditionalCost[]>(this.host + '/load-list/' + hhhId);
  }

  getTrialAdditionalCostList(trialHhhId: number, trialProtocolHhhId?: number, isArcViewEnabled?: boolean): Observable<VisitAdditionalCost[]> {
    const request = {trialHhhId, trialProtocolHhhId, isArcViewEnabled: true};
    return this.httpClient.post<VisitAdditionalCost[]>(this.host + '/load-additional-costs', request);
  }

  addActsToTrial(trialHhhId: number, actsIds: number[]) {
    return this.httpClient.post(this.host + '/add/' + trialHhhId, actsIds);
  }

  addDetailedTrialActs(trialHhhId: number, syntheticTrialAct: SyntheticTrialAct): Observable<VisitAdditionalCost[]> {
    return this.httpClient.post<VisitAdditionalCost[]>(this.host + '/add-detailed-trial-act/' + trialHhhId, syntheticTrialAct);
  }

  isDeletable(hhhId: number): Observable<any> {
    return this.httpClient.get<any>(this.host + '/deletable/' + hhhId);
  }

  delete(hhhId: number): Observable<any> {
    return this.httpClient.post<any>(this.host + '/delete', hhhId);
  }

  updateTrialAct(detailedTrialAct: DetailedTrialAct): Observable<DetailedTrialAct> {
    return this.httpClient.post<DetailedTrialAct>(this.host + '/update', detailedTrialAct);
  }

  exportTrialActList(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Contact} from './contact';
import {ContactService} from 'src/app/shared/services/contact.service';
import {Router} from '@angular/router';
import {DataService} from '../../../shared/services/data-service';
import {ContactFormComponent} from '../../../modals/contact-form/contact-form.component';
import {ESTABLISHMENT_READ_WRITE, PROVIDER_READ_WRITE, SITE_READ_WRITE} from '../../../login/services/authentication.service';
import {PaginatorTableComponent} from '../../../shared/component/paginator-table/paginator-table.component';
import {ContactGlobalFilter} from '../../../shared/entity/contact-global-filter';
import {isNullOrUndefined} from 'util';
import {Permission} from '../../../helpers/permission';
import {PropertyEnum} from '../../entity/property-enum';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {DynamicTableHeader} from '../dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../dynamic-lazy-table/filter-type';
import {TableConfig} from '../../entity/table-config';
import {DynamicDefinitionEnum} from '../../entity/dynamic-definition-enum';
import {AppData} from '../../../helpers/app-data';
import {EstablishmentAppData} from '../../../establishment/establishment-app-data';
import {ExportRequestBuilder} from '../../entity/export-request-builder';
import {DataCommentInfo} from '../../../data-comment/entity/data-comment';
import {DataCommentComponent} from '../../../data-comment/component/data-comment/data-comment.component';


@Component({
  selector: 'ih-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css']
})


export class ContactListComponent extends PaginatorTableComponent<Contact> implements OnInit {

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  _entity;
  target = DynamicDefinitionEnum.ESTABLISHMENT_CONTACT_LIST_TABLE;

  config: TableConfig = new TableConfig(new Map(), new Map());
  privatePostItEnabled = false;

  headers: DynamicTableHeader[] = [];
  entityName: string;
  icon: string;
  publicCommentCount: number;
  entityType: string;
  entityHhhId: any;
  filters: ContactGlobalFilter = new ContactGlobalFilter();
  writeAccess: boolean;

  constructor(
      private contactService: ContactService,
      private cfr: ComponentFactoryResolver,
      private router: Router,
      private dataService: DataService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.privatePostItEnabled = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled);
    });
    this.sortBy = this.getDefaultSortField();
    this.icon = localStorage.getItem('icon');
    this.entityName = localStorage.getItem('entityName');
    this._entity = localStorage.getItem('entityType');
    this.entityHhhId = localStorage.getItem('entityHhhId');
    this.setWriteAccessValue();
    this.initTableConfig();
    if (this.icon === 'null') {
      this.getList();
    }
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, EstablishmentAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders() {
    const availableHeaders: DynamicTableHeader[] = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('name')
        .filter(this.filters.name)
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('function')
        .filter(this.filters.function)
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .sortField('memberFunctionOtherValue')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('totalPhones')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('totalEmails')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('totalFaxes')
        .accessible(this.icon === 'null')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('totalAddresses')
        .accessible(this.icon === 'null')
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }


  private setWriteAccessValue() {
    this.entityType = localStorage.getItem('entityType');
    this.publicCommentCount = +localStorage.getItem('publicCommentCount');
    if (this.entityType === 'Establishment') {
      const establishmentEditRight = localStorage.getItem(ESTABLISHMENT_READ_WRITE);
      this.writeAccess = establishmentEditRight !== Permission.READ_WRITE;
    } else if (this.entityType === 'Provider') {
      const providerEditRight = localStorage.getItem(PROVIDER_READ_WRITE);
      this.writeAccess = providerEditRight !== Permission.READ_WRITE;
    } else if (this.entityType === 'Site') {
      const siteEditRight = localStorage.getItem(SITE_READ_WRITE);
      this.writeAccess = siteEditRight !== Permission.READ_WRITE;
    }
  }

  getDefaultSortField(): string {
    return 'name';
  }

  getAddTitle(): string {
    return 'MODULE_CONTACTABLE_MEMBERS_GENERAL_ADD_NEW';
  }

  getUpdateTitle(): string {
    return 'MODULE_CONTACTABLE_MEMBERS_GENERAL_EDIT';
  }

  getDeleteMessage(): string {
    return 'MODULE_CONTACTABLE_MEMBERS_DIALOG_WARNING_CRO_MEMBER_WILL_BE_DELETED';
  }

  getService(): any {
    return this.contactService;
  }

  displayFormDialogue(contact?: Contact) {
    const formComponent = ContactFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    const isEdit: boolean = !isNullOrUndefined(contact);
    formComponent.formHeader = isEdit ? this.getUpdateTitle() : this.getAddTitle();
    formComponent.contact = isEdit ? JSON.parse(JSON.stringify(contact)) : new Contact();
    formComponent.onAdd((newContact: Contact) => {
      const index = this.values.indexOf(contact);
      if (index !== -1) {
        newContact = new Contact().clone(newContact);
        this.values[index] = newContact;
      } else {
        this.values.push(newContact);
      }
      newContact.initTotalInformation();
    });
  }

  getList(filters?: any) {
    if (filters) {
      Object.assign(this.filters, DynamicTableHeader.buildFilterData(this.headers));
    }
    this.contactService.getContacts(this.entityHhhId, this.entityType, this.page, this.limit, this.orderBy, this.sortBy, this.filters).subscribe((res) => {
      this.values = res.content;
      this.totalNumber = res.totalElements;
    });
  }

  refreshAfterDelete(contact: Contact): void {
    const index = this.values.findIndex((contactDeleted: Contact) => contactDeleted.hhhId === contact.hhhId);
    this.values.splice(index, 1);
  }

  returnBack() {
    if (this.entityType === 'Site') {
      this.router.navigate(['site-list']).then();
    } else if (this.entityType === 'Provider') {
      this.router.navigate(['provider-list']).then();
    }
  }

  exportContactList(event: any): void {
    const request = new ExportRequestBuilder<ContactGlobalFilter>()
        .objectId(this.entityHhhId)
        .type(this.entityType)
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
        .sortBy(this.sortBy)
        .orderBy(this.orderBy)
        .filters(this.filters)
        .build();
    this.contactService.exportContactList(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }

  displayDataComment(isPrivate: boolean) {
    if (!this.entityHhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(this._entity, this.entityHhhId, 'hhhId', isPrivate);
    DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
  }

}

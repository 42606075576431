import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';

export class TrialOperationalAct {
  hhhId: number;
  publicCommentCount: number;
  description: string;
  rdvDescription: string;
  invoiceable: boolean;
  actCategoryHhhId: number;
  actCategoryName: string;
  operationalActHhhId: number;

  init(obj: any): TrialOperationalAct {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'categoryName') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.actCategoryHhhId)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService, CHANGED_PASSWORD} from '../login/services/authentication.service';
import {SharedService} from '../shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
/** force new users to update their initial password **/
export class RequirePasswordUpdateGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router, private sharedService: SharedService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const passwordMustBeUpdated = this.authenticationService.isUserAuthenticated() && !(localStorage.getItem(CHANGED_PASSWORD) === 'true');

    if (passwordMustBeUpdated) {
      const currentUrl = state.url;
      if (currentUrl !== '/auth/edit') {
        this.sharedService.showWarning('MODULE_USER_ADMIN_CHANGE_PASSWORD_SECURITY');
        this.router.navigate(['/auth/edit']);
        return false;
      }
    }

    return true;
  }

}

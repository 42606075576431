import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ReferenceFixedCost} from '../models/referenceFixedCost';
import {SelectItem} from 'primeng/api';
import {ReferenceFixedCostService} from '../services/reference-fixed-cost.service';
import {FixedCostCategoryService} from '../services/fixed-cost-category.service';
import {Router} from '@angular/router';
import {DataService} from '../../shared/services/data-service';
import {TrialFixedCostService} from '../../trial/service/trial-fixed-cost.service';
import {FixedCostReferenceFormComponent} from '../fixed-cost-reference-form/fixed-cost-reference-form.component';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {DeleteMultipleComponent} from '../../modals/delete-multiple/delete-multiple.component';
import {NoSelectedItemFormComponent} from '../../modals/no-selected-item-form/no-selected-item-form.component';
import {Data} from '../../shared/entity/data';
import {TranslateService} from '@ngx-translate/core';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {SharedService} from '../../shared/services/shared.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {AppData} from '../../helpers/app-data';
import {DataItemService} from '../../custom-field/service/data-item.service';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-fixed-cost-reference',
  templateUrl: './fixed-cost-reference.component.html',
  styleUrls: ['./fixed-cost-reference.component.css']
})
export class FixedCostReferenceComponent extends PaginatorTableComponent<ReferenceFixedCost> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.ReferenceFixedCost;
  target = DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_FIXED_COST_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];
  @ViewChild('popupDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  trialHhhId: number;
  fixedCostReferenceCheckedList: ReferenceFixedCost[] = [];
  descriptionFilterList: SelectItem[] = [];
  noYesOptions: SelectItem[];
  filterVar: string;
  contractTypeFilterList: SelectItem[] = [];
  typeFilterList: SelectItem[] = [];
  fixedCostCategoryFilterList: SelectItem[] = [];
  attachedToVisitFilterList: SelectItem[] = [];
  invoiceableFilterList: SelectItem[] = [];
  actOptionalFilterList: SelectItem[] = [];
  annualInvoicingFilterList: SelectItem[] = [];

  contractTypes: SelectItem[] = [];

  constructor(
    private cfr: ComponentFactoryResolver,
    private fixedCostCategoryService: FixedCostCategoryService,
    private translate: TranslateService,
    private translateService: InnohealthTranslateService,
    private fixedCostReferenceService: ReferenceFixedCostService,
    private router: Router,
    private trialFixedCostService: TrialFixedCostService,
    private dataService: DataService,
    private dataItemService: DataItemService,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  async ngOnInit() {
    super.ngOnInit();
    await this.getContractType().then(contractTypes => this.contractTypes = contractTypes).catch();
    this.trialHhhId = +localStorage.getItem('trialHhhId');
    this.getFixedReference();
    this.initFilterVar();
    this.getNoYesOptions();
    this.initTableConfig();
  }

  getContractType(): Promise<SelectItem[]> {
    return this.dataItemService.getItems('contract-type-selected').toPromise();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, AdditionalCostsAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('contractType')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.contractTypes)
      .optionFilterable()
      .filter('contractTypeHhhId')
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('fixedCostCategoryName')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.fixedCostCategoryFilterList)
      .optionFilterable()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('description')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.descriptionFilterList)
      .optionFilterable()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('attachedToVisit')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.attachedToVisitFilterList)
      .optionFilterable()
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('invoiceable')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.invoiceableFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('pricePerUnit')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .withDisplayCssClassContent()
      .cssClass((() => {
        return 'right-align';
      }))
      .displayContent((data: ReferenceFixedCost) => {
        return Number(data.pricePerUnit).toFixed(2) + ' €';
      })
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('internalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('freeSS')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.invoiceableFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('type')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter('typeHhhId')
      .options(this.typeFilterList)
      .sortable()
      .displayContent((act: ReferenceFixedCost) => act.typeName)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('optional')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.actOptionalFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondInternalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('annualInvoicing')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.annualInvoicingFilterList)
      .sortable()
      .isTranslated()
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.setItem('trialHhhId', '0');
  }

  getExportPageModule(): string {
    return 'src/app/additional-costs/fixed-cost-reference/fixed-cost-reference.component';
  }

  getAddTitle(): string {
    return 'MODULE_REFERENCE_FIXED_COSTS_GENERAL_ADD_NEW';
  }

  getUpdateTitle(): string {
    return 'MODULE_REFERENCE_FIXED_COSTS_GENERAL_UPDATE';
  }

  getDeleteMessage(): string {
    return 'MODULE_REFERENCE_FIXED_COSTS_DIALOG_WARNING_SITE_WILL_BE_DELETED';
  }

  getService(): any {
    return this.fixedCostCategoryService;
  }

  getNoYesOptions() {
    this.translate.stream('GENERAL_LINGUISTICS_YES_UFCASE').subscribe(() => {
      this.noYesOptions = Data.yesOrNoOrNull;
    });
  }

  getFixedReference() {
    if (this.trialHhhId) {
      this.fixedCostReferenceService.getAttachedFixedCostReferenceForTrial(this.trialHhhId, false).subscribe(res => {
          this.extractResultset(res);
        },
        error => {
          console.error('An error occurred while loading fixed cost ref reference.' + error);
        }
      );
    } else {
      this.fixedCostReferenceService.loadList().subscribe(res => {
          this.extractResultset(res);
        },
        error => {
          console.error('An error occurred while loading fixed cost ref reference.' + error);
        }
      );
    }

  }

  private extractResultset(res: ReferenceFixedCost[]) {
    this.fixedCostReferenceService.setFixedCostReferenceListToExport(res);
    if (!this.filterVar || this.filterVar === '') {
      this.values = res;
    } else {
      this.values = res.filter((a: ReferenceFixedCost) => (a.fixedCostCategoryName === this.filterVar));
      this.dataService.changeFilterVar(null);
    }
    this.buildFiltersOptions(res);
  }

  displayFormDialogue(fixedCostReference?: ReferenceFixedCost) {
    const formComponent = FixedCostReferenceFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.noYesOptions = this.noYesOptions;
    if (fixedCostReference) {
      fixedCostReference = new ReferenceFixedCost().init(fixedCostReference);
      const referenceFixedCostToEdit: ReferenceFixedCost = Object.assign({}, fixedCostReference);
      formComponent.oldFixedCostReference = referenceFixedCostToEdit;
      formComponent.fixedCostReference = fixedCostReference;
      formComponent.onAdd(this.updateList(fixedCostReference));
    } else {
      formComponent.onAdd(this.updateList());
    }
  }

  updateList(referenceFixedCostToEdit?: ReferenceFixedCost) {
    if (referenceFixedCostToEdit) {
      return (newReferenceFixedCost: ReferenceFixedCost) => {
        const index = this.values.indexOf(referenceFixedCostToEdit);
        this.values[index] = newReferenceFixedCost;
        this.getFixedReference();
      };
    } else {
      return (newFixedCostReference: ReferenceFixedCost) => {
        this.values.push(newFixedCostReference);
        this.values.sort((a: any, b: any) => a.fixedCostCategoryName.toLowerCase().localeCompare(b.fixedCostCategoryName.toLowerCase()));
        const item = {label: newFixedCostReference.description, value: newFixedCostReference.description};
        this.descriptionFilterList.push(item);
        this.descriptionFilterList.sort((a, b) => (a.label.toLowerCase().localeCompare(b.label.toLowerCase())));
        this.buildFiltersOptions(this.values);
        this.getFixedReference();
      };
    }
  }

  addAct() {
    if (this.trialHhhId === 0) {
      this.displayFormDialogue();
    } else {
      this.addFixedCostToTrial();
    }
  }

  addFixedCostToTrial() {
    const fixedCostHhhIds = [];
    for (const fixedCost of this.fixedCostReferenceCheckedList) {
      fixedCostHhhIds.push(fixedCost.hhhId);
    }
    this.trialFixedCostService.addFixedCostToTrial(this.trialHhhId, fixedCostHhhIds).subscribe(() => {
      this.router.navigate(
        ['/trial-details/fixed-costs'],
        {queryParams: {hhhId: this.trialHhhId}}
      ).then();
    });
  }

  updateSelectedList(selectedList: ReferenceFixedCost[]) {
    this.fixedCostReferenceCheckedList = selectedList;
  }

  openDeleteFixedCostReferenceDialog() {
    if (this.fixedCostReferenceCheckedList.length === 0) {
      this.displayNoActSelected();
    } else {
      this.displayDeleteMultiple();
    }
  }

  displayDeleteMultiple() {
    const formComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    formComponent.service = this.fixedCostReferenceService;
    formComponent.popupMessage = 'MODULE_TRIALS_FIXED_COSTS_WARNING_SELECTED_FIXED_COSTS_WILL_BE_DELETED';
    formComponent.selectedList = this.fixedCostReferenceCheckedList;
    formComponent.navigationRoute = '/trial-details/fixed-costs';
    formComponent.onDelete((deletedItem: number[]) => {
      for (const hhhId of deletedItem) {
        const index = this.values.findIndex((fixedCostReference: ReferenceFixedCost) => fixedCostReference.hhhId === hhhId);
        this.sharedService.setInformationInLocalStorage('Grille budgétaire: Coûts / Surcoûts', 'Supprimer', this.values[index].description);
        this.values.splice(index, 1);
      }
    });
  }

  displayNoActSelected() {
    const formComponent = NoSelectedItemFormComponent.displayFormDialogue(this.popupContainer, this.cfr);
    formComponent.header = 'UI_DIALOG_HEADER_NO_FIXED_COST_SELECTED';
  }

  buildFiltersOptions(event) {
    for (const fixedCostReference of event) {
      const descItem = {label: fixedCostReference.description, value: fixedCostReference.description};
      const contractTypeItem = {label: fixedCostReference.contractType, value: fixedCostReference.contractTypeHhhId};
      const typeItem = {label: fixedCostReference.typeName, value: fixedCostReference.typeHhhId};
      const fixedCostCategoryItem = {
        label: fixedCostReference.fixedCostCategoryName,
        value: fixedCostReference.fixedCostCategoryName
      };
      const attachedToVisitItem = {
        label: fixedCostReference.attachedToVisit !== null ? this.translateService.getTranslationString(fixedCostReference.attachedToVisit.toString()) : '',
        value: fixedCostReference.attachedToVisit
      };
      const invoiceableItem = {
        label: fixedCostReference.invoiceable !== null ? this.translateService.getTranslationString(fixedCostReference.invoiceable.toString()) : '',
        value: fixedCostReference.invoiceable
      };
      const optionalItem = {
        label: fixedCostReference.optional != null ? this.translateService.getTranslationString(fixedCostReference.optional.toString()) : '',
        value: fixedCostReference.optional
      };
      const annualInvoicingItem = {
        label: fixedCostReference.annualInvoicing !== null ? this.translateService.getTranslationString(fixedCostReference.annualInvoicing.toString()) : '',
        value: fixedCostReference.annualInvoicing
      };
      if (fixedCostReference.contractType !== null &&
        this.contractTypeFilterList.findIndex((item: SelectItem) => item.label === contractTypeItem.label) === -1) {
        this.contractTypeFilterList.push(contractTypeItem);
      }
      if (fixedCostReference.typeHhhId !== null &&
        this.typeFilterList.findIndex((item: SelectItem) => item.value === typeItem.value) === -1) {
        this.typeFilterList.push(typeItem);
      }
      if (fixedCostReference.description !== null &&
        this.descriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
        this.descriptionFilterList.push(descItem);
      }
      if (fixedCostReference.actCategory !== null &&
        this.fixedCostCategoryFilterList.findIndex((item: SelectItem) => item.value === fixedCostCategoryItem.value) === -1) {
        this.fixedCostCategoryFilterList.push(fixedCostCategoryItem);
      }
      if (fixedCostReference.attachedToVisit !== null &&
        this.attachedToVisitFilterList.findIndex((item: SelectItem) => item.value === attachedToVisitItem.value) === -1) {
        this.attachedToVisitFilterList.push(attachedToVisitItem);
      }
      if (fixedCostReference.invoiceable !== null &&
        this.invoiceableFilterList.findIndex((item: SelectItem) => item.value === invoiceableItem.value) === -1) {
        this.invoiceableFilterList.push(invoiceableItem);
      }
      if (fixedCostReference.optional != null &&
        this.actOptionalFilterList.findIndex((item: SelectItem) => item.value === optionalItem.value) === -1) {
        this.actOptionalFilterList.push(optionalItem);
      }
      if (fixedCostReference.annualInvoicing !== null &&
        this.annualInvoicingFilterList.findIndex((item: SelectItem) => item.value === annualInvoicingItem.value) === -1) {
        this.annualInvoicingFilterList.push(annualInvoicingItem);
      }
    }
    this.descriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.contractTypeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.typeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.fixedCostCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.attachedToVisitFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.invoiceableFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.actOptionalFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.annualInvoicingFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  private initFilterVar() {
    this.dataService.currentFilterVar.subscribe(res => {
      this.filterVar = res;
    });
  }

  exportFixedCostReferences(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .build();
    this.fixedCostReferenceService.exportFixedCostReference(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }

}

import {AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {Router} from '@angular/router';
import {HumanResourcesService} from '../services/human-resources.service';
import {SharedService} from '../../shared/services/shared.service';
import {DetailedHumanResource} from '../models/detailed-human-resource';
import {Data} from '../../shared/entity/data';
import {DataService} from '../../shared/services/data-service';
import {FR} from '../../shared/entity/calendar-language';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {HumanResourceAppData} from '../human-resource-app-data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {DataItemService} from '../../custom-field/service/data-item.service';
import {DataCommentInfo} from '../../data-comment/entity/data-comment';
import {DataCommentComponent} from '../../data-comment/component/data-comment/data-comment.component';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {DataCommentEvent} from '../../data-comment/entity/DataCommentEvent';

@Component({
  selector: 'ih-human-resource-informations-form',
  templateUrl: './human-resource-informations-form.component.html',
  styleUrls: ['./human-resource-informations-form.component.css']
})
export class HumanResourceInformationsFormComponent implements OnInit, AfterViewInit {

  target = DynamicDefinitionEnum.HUMAN_RESOURCE_INFORMATION_FORM;
  config: FormConfig = new FormConfig();
  committeeToPole = false;

  @ViewChild('humanResourceDetailsForm') humanResourceDetailsForm: NgForm;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @Input() humanResource: DetailedHumanResource;
  submitted;
  titles: SelectItem[];
  public isDoctorSelected = false;
  locale = FR;
  hrExists = false;
  @Input() disableEdit = false;
  public isNursesSelected = false;
  nurseValue: number;
  maleNurseValue: number;

  constructor(
      private router: Router,
      private humanResourceService: HumanResourcesService,
      private sharedService: SharedService,
      private cfr: ComponentFactoryResolver,
      private dataService: DataService,
      private dataItemService: DataItemService,
      private dynamicConfigService: DynamicConfigService,
      private _cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.initProperties();
    this.submitted = false;
    this.titles = Data.titles;
    this.getNurseValue();
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.committeeToPole = this.dynamicConfigService.getProperty(PropertyEnum.committeeToPole);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, HumanResourceAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  saveHumanResource() {
    this.submitted = true;
    this.disableEdit = true;
    if (this.humanResourceDetailsForm.invalid || !this.humanResource.isValid(this.config, this.isParent(), this.isDoctorSelected)) {
      this.disableEdit = false;
      return;
    }
    const url = this.router.parseUrl(this.router.url);
    this.humanResource.hhhId = url.queryParams.hhhId;
    this.humanResourceService.detailedExists(this.humanResource).subscribe(res => {
      if (res) {
        this.hrExists = true;
        this.disableEdit = false;
      } else {
        this.hrExists = false;
        this.humanResourceService.update(this.humanResource).subscribe((hr) => {
              this.dataService.setPageTopName(this.getHumanResourcesFullName());
              const name = this.humanResource.firstName + ' ' + this.humanResource.lastName;
              this.sharedService.setInformationInLocalStorage('Ressources humaines', 'Éditer', name);
              this.humanResource.contactSetDto = hr.contactSetDto;
              this._cdr.detectChanges();
              this.sharedService.showSuccess();
              this.disableEdit = false;
            },
            error => {
              this.sharedService.showFailure();
              this.disableEdit = false;
              console.error(error);
            }
        );
      }
    });
  }

  displayDataCommentDialog(event: {attachedToField: string, isPrivate: boolean}) {
    if (!this.humanResource || this.humanResource.hhhId === undefined) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.HumanResource, this.humanResource.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }


  isParent() {
    return this.dynamicConfigService.parent;
  }

  private getHumanResourcesFullName() {
    return '| ' + (this.humanResource.title ? this.humanResource.title : '') + ' '
        + (this.humanResource.firstName ? this.humanResource.firstName : '') + ' ' + this.humanResource.lastName;
  }

  notifyWhenWantedValueSelected(isSelected: boolean) {
    const changed = this.isDoctorSelected !== isSelected;
    this.isDoctorSelected = isSelected;
    if (changed) {
      this._cdr.detectChanges();
    }
  }

  private getNurseValue() {
    this.dataItemService.getItems('responsibility').subscribe(
        (res) => {
          const nurse = res.find((value) => value.label.trim().toLowerCase() === 'infirmière');
          this.nurseValue = nurse ? nurse.value : undefined;
          const maleNurse = res.find((value) => value.label.trim().toLowerCase() === 'infirmier');
          this.maleNurseValue = maleNurse ? maleNurse.value : undefined;
          this.notifyWhenNursesIsSelected();
        }
    );
  }

  notifyWhenNursesIsSelected() {
    if (!this.humanResource || !this.humanResource.responsibilitiesHhhId) {
      return;
    }
    if (this.humanResource.responsibilitiesHhhId.includes(this.nurseValue)) {
      this.isNursesSelected = true;
    } else {
      if (this.humanResource.responsibilitiesHhhId.includes(this.maleNurseValue)) {
        this.isNursesSelected = true;
      } else {
        this.isNursesSelected = false;
      }

    }
  }

  hrServicePoleList() {
    return this.committeeToPole ? 'trial-committee-selected' : 'service';
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.humanResource.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Deletable} from '../../modals/delete-confirmation/deletable';
import {ReferenceAct} from '../../additional-costs/models/reference-act';

@Injectable({
  providedIn: 'root'
})
export class TheoreticalVisitActService implements Deletable {

  public host = new Properties().host + '/theoretical-visit-act';

  constructor(private httpClient: HttpClient) {
  }

  getReferenceActByTheoreticalVisitAct(hhhId: number): Observable<ReferenceAct> {
    return this.httpClient.get<ReferenceAct>(this.host + '/reference-act/' + hhhId);
  }

  isDeletable(hhhId: number) {
    return this.httpClient.get(this.host + '/deletable/' + hhhId);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  isEditable(hhhId: number): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.httpClient.get<string>(this.host + '/editable/' + hhhId, requestOptions);
  }


}

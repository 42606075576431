import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {PersonalHistory} from './home/models/personal-history';
import {isNullOrUndefined} from 'util';

@Injectable()
export class LocalStorageService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
  }

  public setAuthenticated(auth: boolean) {

    this.storage.set('authenticated', auth);
  }

  public getAuthenticated(): boolean {
    return this.storage.get('authenticated');
  }

  public setDataHistory(data: PersonalHistory) {
    const x = this.getDataHistory();
    if (x.length >= 10) {
      x.splice(9, 1);
    }
    x.unshift(data);
    console.log(JSON.stringify(x));
    this.storage.set('dataHistory', JSON.stringify(x));
  }

  public getDataHistory(): PersonalHistory[] {
    if (!isNullOrUndefined(this.storage.get('dataHistory'))) {
      return JSON.parse(this.storage.get('dataHistory'));
    } else {
      const x: PersonalHistory[] = [];
      return x;
    }
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {InvoiceService} from '../services/invoice.service';
import {Router} from '@angular/router';
import {InvoiceHeader} from '../models/invoice-header';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {InvoiceInformationService} from '../invoice-information/services/invoice-information.service';
import {InvoiceActService} from '../invoice-acts/services/invoice-act.service';
import {InvoiceFixedCostService} from '../invoice-fixed-costs/services/invoice-fixed-cost.service';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {DataCommentInfo} from '../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../data-comment/entity/DataCommentEvent';

@Component({
  selector: 'ih-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.css']
})
export class InvoiceDetailsComponent implements OnInit {
  counterPartEnabled = true;
  isPrivatePostItEnabled = true;
  preInvoiceEnabled = true;
  useTVAForTotalEnabled = false;

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  public invoiceHhhId: number;
  public invoiceMenu: MenuItem[];
  public invoiceHeader: InvoiceHeader = new InvoiceHeader();

  constructor(
      private cfr: ComponentFactoryResolver,
      private translateService: InnohealthTranslateService,
      private translate: TranslateService,
      private invoiceInformationService: InvoiceInformationService,
      private invoiceService: InvoiceService,
      private invoiceActService: InvoiceActService,
      private invoiceFixedCostService: InvoiceFixedCostService,
      private router: Router,
      private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.invoiceHhhId = url.queryParams.invoiceHhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    this.getInvoiceHeader();
    this.invoiceInformationService.setInvoiceId(this.invoiceHhhId);
    this.updateTotal();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.counterPartEnabled = this.dynamicConfigService.getProperty(PropertyEnum.counterPartEnabled);
      this.isPrivatePostItEnabled = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled);
      this.preInvoiceEnabled = this.dynamicConfigService.getProperty(PropertyEnum.preInvoiceEnabled);
      this.useTVAForTotalEnabled = this.dynamicConfigService.getProperty(PropertyEnum.useTVAForTotalEnabled);
      this.translate.stream('key').subscribe(() => {
        this.setMenuItems();
      });
    });
  }

  updateTotal() {
    this.invoiceService.invoiceTotal.subscribe(() => {
      this.getInvoiceHeader();
    });
  }

  getInvoiceHeader() {
    this.invoiceService.getInvoiceHeader(this.invoiceHhhId).subscribe(res => {
      this.invoiceHeader = res;
      this.invoiceActService.setInvoiceName(this.invoiceHeader.invoiceNumber);
      this.invoiceFixedCostService.setInvoiceName(this.invoiceHeader.invoiceNumber);
    });
  }

  displayDataCommentDialog(isPrivate: boolean) {
    if (!this.invoiceHeader || this.invoiceHeader.hhhId === undefined) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.Invoice, this.invoiceHeader.hhhId, 'hhhId', isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  returnBack() {
    this.router.navigate(['/invoice-container/invoice-list']).then();
  }

  private setMenuItems() {
    this.invoiceMenu = [
      {
        label: this.translateService.getTranslationString('MODULE_INVOICES_HORIZONTAL_MENU_ENTRY_HOME'),
        routerLink: ['/invoice-details/invoice-information'], queryParams: {invoiceHhhId: this.invoiceHhhId}
      },
      {
        label: this.translateService.getTranslationString('MODULE_INVOICES_NAVIGATION_ADD_ACTS'),
        routerLink: ['/invoice-details/invoice-acts'], queryParams: {invoiceHhhId: this.invoiceHhhId}
      },
      {
        label: this.translateService.getTranslationString('MODULE_INVOICES_NAVIGATION_ADD_FIXED_COSTS'),
        routerLink: ['/invoice-details/invoice-fixed-costs'], queryParams: {invoiceHhhId: this.invoiceHhhId}
      },
    ];
    if (this.counterPartEnabled === true) {
      this.invoiceMenu.push({
        label: this.translateService.getTranslationString('MODULE_INVOICES_NAVIGATION_ADD_COUNTER_PARTS'),
        routerLink: ['/invoice-details/invoice-counter-parts'], queryParams: {invoiceHhhId: this.invoiceHhhId}
      });
    }
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.invoiceHeader.publicCommentCount = dataCommentEvent.publicCommentCount;
  }

}


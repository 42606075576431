import {Country} from './country';

export class Address {
  hhhId: any;
  type: any;
  value: any;
  country: Country;
  line1: any;
  line2: any;
  line3: any;
  postCode: any;
  city: any;
  position: any;
  contactSetHhhId: any;
  humanResourceHhhId: any;

  constructor() {
    this.type = '';
    this.value = '';
    this.country = new Country();
    this.line2 = '';
    this.line1 = '';
    this.line3 = '';
    this.postCode = '';
    this.city = '';
    this.position = 1;
    this.contactSetHhhId = '';
    this.humanResourceHhhId = null;
  }
}

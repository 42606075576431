import {Component, OnInit} from '@angular/core';
import {TermsAndConditionsService} from '../services/terms-and-conditions.service';
import {SharedService} from '../../shared/services/shared.service';
import {MobileStaticData} from '../models/mobile-static-data';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {
  termsAndConditions: MobileStaticData = new MobileStaticData();
  isFrenchText = true;

  constructor(private termsAndConditionsService: TermsAndConditionsService,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    this.getTermsAndConditions();
  }

  submitTermsAndConditions() {
    this.termsAndConditionsService.editTermsAndConditions(this.termsAndConditions).subscribe(res => {
      this.termsAndConditions = res;
      this.sharedService.showSuccess();
    }, error => {
      console.error(error);
      this.sharedService.showFailure();
    });
  }

  private getTermsAndConditions() {
    this.termsAndConditionsService.getTermsAndConditions().subscribe(res => {
      if (res !== null) {
        this.termsAndConditions = res;
      }
    });
  }
}

import {InclusionDate} from '../../statistics/follow-inclusions/models/inclusion-date';
import {InclusionState} from '../../inclusion-state';
import {ActualAndProvisionalDate} from './actual-and-provisional-date';
import {TrialInDashboard} from './trialInDashboard';

export class TrialDashboard {
  public perMonthObjective: number;
  public inclusionTotal: any;
  public trial: TrialInDashboard = new TrialInDashboard();
  public progress: number;
  public actualAndProvisionalDates: ActualAndProvisionalDate[];
  public inclusionState: InclusionState[] = [];
  public inclusionPerMonth: InclusionDate[] = [];
  public trialEndDate: Date;

  constructor() {
    this.inclusionState[0] = new InclusionState();
    this.inclusionState[1] = new InclusionState();
    this.inclusionState[2] = new InclusionState();
    this.inclusionState[3] = new InclusionState();
    this.inclusionState[4] = new InclusionState();
    this.inclusionState[5] = new InclusionState();
    this.inclusionState[6] = new InclusionState();
    this.inclusionState[7] = new InclusionState();
    this.inclusionState[8] = new InclusionState();
    this.inclusionState[9] = new InclusionState();
    this.inclusionState[10] = new InclusionState();
    this.inclusionState[11] = new InclusionState();
    this.inclusionState[12] = new InclusionState();
    this.inclusionState[13] = new InclusionState();
    this.inclusionState[14] = new InclusionState();
    this.inclusionState[15] = new InclusionState();
    this.inclusionState[16] = new InclusionState();
  }
}

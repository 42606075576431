import { Injectable } from '@angular/core';
import {Properties} from '../../../helpers/properties';
import {Observable} from 'rxjs';
import {StaticInvoiceItem} from '../../models/static-invoice-item';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StaticInvoiceFixedCostService {

  public properties: Properties = new Properties();
  public host = this.properties.host + '/static-invoice-fixed-cost';

  constructor(private httpClient: HttpClient) { }

  getStaticInvoiceInvoicedFixedCost(invoiceHhhId: number, versionHhhId: number): Observable<StaticInvoiceItem> {
    return this.httpClient.get<StaticInvoiceItem>(this.host + '/load-list/' + invoiceHhhId + '/' + versionHhhId);
  }

  saveStaticInvoiceInvoicedFixedCosts(invoiceHhhId: number, staticInvoiceAct: StaticInvoiceItem): Observable<number> {
    return this.httpClient.post<number>(this.host + '/save/' + invoiceHhhId, staticInvoiceAct);
  }
}

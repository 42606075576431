import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ih-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.css']
})
export class SvgIconComponent  {

  @Input() title: string;
  constructor() { }


}

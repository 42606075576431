import {YearRecord} from '../../../establishment/models/year-record';
import {AdditionalCostCategory} from './additional-cost-category';

export class GlobalInvoicesTotalAmountByMonthFilter {
  public filterInvoiceIssueYear: YearRecord;
  public filterInvoiceState: number;
  public filterSpeciality: number;
  public filterSite: number;
  public filterCategory: AdditionalCostCategory;
  public filterProvider: number;
  public filterInternalAccountNumber: string;
}

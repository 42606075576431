import {Component, Input, OnInit} from '@angular/core';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {DynamicTableDefinition} from '../../entity/dynamic-table-definition';
import {Util} from '../../../helpers/util';
import {TableHeaderConfig} from '../../entity/table-header-config';
import {DynamicTableDefinitionHeader} from '../../entity/dynamic-table-definition-header';
import {DynamicTranslatorPipe} from '../../pipe/dynamic-translator.pipe';
import {DynamicFormDefinition} from '../../entity/dynamic-form-definition';
import {SharedService} from '../../../shared/services/shared.service';

@Component({
  selector: 'ih-table-config-editor',
  templateUrl: './table-config-editor.component.html',
  styleUrls: ['./table-config-editor.component.css'],
  providers: [DynamicTranslatorPipe]
})
export class TableConfigEditorComponent implements OnInit {

  @Input() name: string;
  _target: string;

  tableDefinition: DynamicTableDefinition;
  dbTableDefinition: DynamicTableDefinition;
  cols: any[] = [];

  checked = false;
  fieldFilter = '';
  headerFilter = '';
  preview = false;


  constructor(
      private dynamicConfigService: DynamicConfigService,
      private dynamicTranslator: DynamicTranslatorPipe,
      private sharedService: SharedService
  ) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'field', header: 'Colonne', width: '20%'},
      {field: 'header', header: 'Intitulé', width: '20%', type: 'input'},
      {field: 'width', header: 'Taille', width: '10%', type: 'input'},
      {field: 'display', header: 'Activé', width: '10%', type: 'display'},
      {field: 'filterable', header: 'Filtrable', width: '10%', type: 'filter'},
      {field: 'sortable', header: 'Ordre 🡱/🡳', width: '10%', type: 'boolean'},
      {field: 'importe', header: 'Importer', width: '10%', type: 'boolean'},
      {field: 'export', header: 'Exportable', width: '10%', type: 'boolean'}
    ];
  }

  @Input()
  set target(v: string) {
    this._target = v;
    this.getTableDefinition();
  }

  get target(): string {
    return this._target;
  }


  getTableDefinition(): void {
    this.dynamicConfigService.getAdminConsoleTableConfig(this._target).subscribe(
        table => {
          this.tableDefinition = Util.clone(table);
          this.dbTableDefinition = Util.clone(table);
        }, error => console.log(error.message)
    );
  }

  disableField(config: TableHeaderConfig) {
    if (!config.display) {
      config.filterable = false;
      config.sortable = false;
      config.importe = false;
      config.export = false;
      config.header = undefined;
      config.width = undefined;
    }
  }

  dataChanged(): boolean {
    return !Util.equal(this.dbTableDefinition, this.tableDefinition);
  }

  save(): void {
    this.dynamicConfigService.saveTableConfig(this.tableDefinition).subscribe(
        table => {
          this.tableDefinition = Util.clone(table);
          this.dbTableDefinition = Util.clone(table);
          this.sharedService.showSuccess();
        }, error => console.log(error.message)
    );
  }

  reset(): void {
    this.tableDefinition = Util.clone(this.dbTableDefinition);
  }

  getPlaceholder(definition: DynamicTableDefinitionHeader, field: string): string {
    if (field === 'header' && Util.isStringNullOrEmpty(definition.config.header)) {
      return definition.defaultHeader;
    }
    if (field === 'width' && Util.isStringNullOrEmpty(definition.config.width)) {
      return definition.defaultWidth;
    }
    return 'Default ' + field;
  }

  isDisplayed(config: TableHeaderConfig): boolean {
    return config.display;
  }

  filter(): void {
    if (Util.isStringNullOrEmpty(this.fieldFilter) && Util.isStringNullOrEmpty(this.headerFilter)) {
      this.tableDefinition.headers.forEach(h => h.color = '');
      return;
    }
    this.tableDefinition.headers.forEach(h => {
      const translatedHeader = this.dynamicTranslator.transform(Util.isStringNullOrEmpty(h.config.header) ? h.defaultHeader : h.config.header).toLowerCase();
      if (!Util.isStringNullOrEmpty(this.fieldFilter) && !Util.isStringNullOrEmpty(this.headerFilter)) {
        h.color = (h.field).toLowerCase().includes(this.fieldFilter.toLowerCase()) || translatedHeader.toLowerCase().includes(this.headerFilter) ? '#D6EAF5' : '';
      } else if (!Util.isStringNullOrEmpty(this.fieldFilter)) {
        h.color = (h.field).toLowerCase().includes(this.fieldFilter.toLowerCase()) ? '#D6EAF5' : '';

      } else if (!Util.isStringNullOrEmpty(this.headerFilter)) {
        h.color = translatedHeader.toLowerCase().includes(this.headerFilter) ? '#D6EAF5' : '';
      }
    });
  }

  getBtnTitle(btnId: string): string {
    switch (btnId) {
      case 'edit-btn':
        return 'Éditer';
        break;
      case 'chat-btn':
        return 'Chatter';
        break;
      case 'suggest-patients-btn':
        return 'Suggérer un patient';
        break;
      case 'public-comment-btn':
        return 'Commentaire Public';
        break;
      case 'private-comment-btn':
        return 'Commentaire Privé';
        break;
      case 'delete-btn':
        return 'Supprimer';
      case 'export-btn':
        return 'Export';
        break;
    }
  }

  endPreview(): void {
    this.preview = false;
  }

  startPreview(): void {
    this.preview = true;
  }

}

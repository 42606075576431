import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {OtherAmendment} from '../models/other-amendment';
import {Observable} from 'rxjs';
import {Value} from '../../shared/entity/value';

@Injectable({
  providedIn: 'root'
})
export class OtherAmendedService {
  public host = new Properties().host + '/other-amendment-agreement';

  constructor(private httpClient: HttpClient) {
  }

  saveModifications(otherAmendmentList: OtherAmendment[], trialId: number, trialSiteId: number): Observable<Value<string>> {
    return this.httpClient.post<Value<string>>(this.host + '/save/' + trialId + '/' + trialSiteId, otherAmendmentList);
  }

  loadList(trialId: number, trialSiteLiaisonId: number): Observable<OtherAmendment[]> {
    if (trialSiteLiaisonId === undefined) {
      trialSiteLiaisonId = 0;
    }
    return this.httpClient.get<OtherAmendment[]>(this.host + '/load-list/' + trialId + '/' + trialSiteLiaisonId);
  }

  delete(trialId, trialSiteLiaisonId, otherAmendmentAgreementHhhId: number): Observable<Value<string>> {
    if (trialSiteLiaisonId === undefined) {
      trialSiteLiaisonId = 0;
    }
    return this.httpClient.get<Value<string>>(this.host + '/delete/' + trialId + '/' + trialSiteLiaisonId + '/' + otherAmendmentAgreementHhhId);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatisticsComponent} from './statistics/statistics.component';
import {FollowTrialsComponent} from './follow-trials/follow-trials.component';
import {FollowInclusionsComponent} from './follow-inclusions/follow-inclusions.component';
import {FollowActiveFileComponent} from './follow-active-file/follow-active-file.component';
import {FollowInclusionsByTrialComponent} from './follow-inclusions-by-trial/follow-inclusions-by-trial.component';
import {
  AccordionModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  ChartModule,
  ChipsModule,
  ConfirmDialogModule,
  DialogModule,
  DropdownModule,
  FieldsetModule,
  InputTextModule,
  MenubarModule,
  MenuModule,
  MultiSelectModule,
  OverlayPanelModule,
  PanelMenuModule,
  PanelModule,
  TabViewModule,
  ToolbarModule
} from 'primeng/primeng';
import {TableModule} from 'primeng/table';
import {FormsModule} from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateModule} from '@ngx-translate/core';
import {StatisticsRoutingModule} from './statistics-routing.module';
import {MatTabsModule} from '@angular/material';
import {SharedModule} from '../shared/shared.module';
import {FollowIsoStatsComponent} from './follow-other-stats/follow-iso-stats.component';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';
import { PowerBiReportComponent } from './powerBi/power-bi-report/power-bi-report.component';
import { StatisticsContainerComponent } from './statistics-container/statistics-container.component';

@NgModule({
  declarations: [StatisticsComponent,
    FollowTrialsComponent,
    FollowInclusionsComponent,
    FollowActiveFileComponent,
    FollowInclusionsByTrialComponent,
    FollowIsoStatsComponent,
    PowerBiReportComponent,
    StatisticsContainerComponent],
  imports: [
    StatisticsRoutingModule,
    SharedModule,
    MatTabsModule,
    TabViewModule,
    TableModule,
    DropdownModule,
    OverlayPanelModule,
    FormsModule,
    MenubarModule,
    ToolbarModule,
    AccordionModule,
    CardModule,
    ChartModule,
    PanelMenuModule,
    PanelModule,
    CalendarModule,
    ToastModule,
    BrowserAnimationsModule,
    BrowserModule,
    ChipsModule,
    ConfirmDialogModule,
    DialogModule,
    ButtonModule,
    MultiSelectModule,
    FieldsetModule,
    InputTextModule,
    MenuModule,
    CommonModule,
    TranslateModule,
    DynamicConfigModule
  ]
})
export class StatisticsModule {
}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {PharmacyStock} from '../../trial/entity/trial-pharmacy/pharmacyStock';
import {TableHeader} from '../../shared/entity/table-header';
import {SharedService} from '../../shared/services/shared.service';
import {PharmacyStockService} from '../../trial/service/pharmacy-stock.service';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {DataService} from '../../shared/services/data-service';
import {PharmacyStockGlobalFilter} from '../model/pharmacyStockGlobalFilter';
import {Data} from '../../shared/entity/data';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {PharmacyService} from '../../trial/service/pharmacy.service';
import {isNullOrUndefined} from 'util';
import {FR} from '../../shared/entity/calendar-language';
import {SelectItem} from 'primeng/components/common/api';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {PharmacyAppData} from '../pharmacy-app-data';
import {AppData} from '../../helpers/app-data';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';


declare var $: any;


@Component({
  selector: 'ih-pharmacy-stock',
  templateUrl: './pharmacy-stock.component.html',
  styleUrls: ['./pharmacy-stock.component.css']
})
export class PharmacyStockComponent extends PaginatorTableComponent<PharmacyStock> implements OnInit, OnDestroy {

  readonly target = DynamicDefinitionEnum.PHARMACY_STOCK_TABLE;
  config: TableConfig;

  stockColumnListHeader: DynamicTableHeader[] = [];
  kitsColumnListHeader: DynamicTableHeader[] = [];
  trialHhhId: number;
  pharmacyHhhId: number;
  locale = FR;
  first = 0;
  filters = new PharmacyStockGlobalFilter();
  monthsSelectItems: SelectItem[] = [];
  yearsSelectItems: SelectItem[] = [];
  fromDate: Date;
  toDate: Date;
  years: SelectItem[] = Data.getYears();
  dateTypesSelected: SelectItem[] = [
    {label: 'date de réception ', value: 'date de réception'},
    {label: 'date de péremption', value: 'date de péremption'},
    {label: 'date de dluo', value: 'date de dluo'}];
  dateType = '';

  constructor(
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private sharedService: SharedService,
    public pharmacyService: PharmacyService,
    private pharmacyStockService: PharmacyStockService,
    private translateService: InnohealthTranslateService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.dateType = '';
    this.monthsSelectItems = Data.monthsList;
    for (let i = new Date().getFullYear() + 20; i >= 1900; i--) {
      this.yearsSelectItems.push({value: i, label: String(i)});
    }

    this.pharmacyService.setPharmacyReadonlyMode();
    this.initTableConfig();
    this.getFilteredList(); }
  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, PharmacyAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const stockListHeader = [];
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('lotNumber')
      .filterable()
      .filter(this.filters.lotNumber)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('lotNumber')
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('trialName')
      .filterable()
      .filter(this.filters.trialName)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('trialName')
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('state')
      .filterable()
      .filter(this.filters.state)
      .filterType(FilterType.DROPDOWN)
      .options(Data.states)
      .sortable()
      .sortField('state')
      .displayContent((data: PharmacyStock) => {
        if (data.state !== null) {
          return this.translateService.getTranslationString(data.state);
        }
      })
      .build());

    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('wording')
      .filterable()
      .filter(this.filters.wording)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('wording')
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('receptionDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.receptionDateYear)
      .sortable()
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .sortField('receptionDate')
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('receptionAuthorName')
      .filterable()
      .filter(this.filters.receptionAuthorName)
      .filterType(FilterType.IH_DROPDOWN)
      .type('pharmacist-name')
      .sortField('receptionAuthorName')
      .sortable()
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('treatmentName')
      .filterable()
      .filter(this.filters.treatmentName)
      .filterType(FilterType.IH_DROPDOWN)
      .type('trial-treatment-experimental-selected')
      .sortable()
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('dosageName')
      .filterable()
      .filter(this.filters.dosageName)
      .filterType(FilterType.IH_DROPDOWN)
      .type('pharmacy-dose')
      .sortable()
      .sortField('dosageName')
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('pharmaceuticalFormName')
      .filterable()
      .filter(this.filters.pharmaceuticalFormName)
      .filterType(FilterType.IH_DROPDOWN)
      .type('pharmaceutical-form-selected')
      .sortable()
      .sortField('pharmaceuticalFormName')
      .build());

    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('dluoDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.dluoDateYear)
      .sortable()
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .sortField('dluoDate')
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('receptionNumber')
      .filterable()
      .filter(this.filters.receptionNumber)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('expirationDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.destructionDateYear)
      .sortable()
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .sortField('expirationDate')
      .build());


    this.stockColumnListHeader = this.config.buildTable(stockListHeader);
    const kitsListHeader = [];
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('authorName')
      .filterable()
      .filter(this.filters.authorName)
      .filterType(FilterType.IH_DROPDOWN)
      .type('pharmacist-name')
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('processingUnit')
      .filterable()
      .filter(this.filters.kit.processingUnit)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('initialQuantity')
      .filterable()
      .filter(this.filters.kit.initialQuantity)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('destructionDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.destructionDate)
      .sortable()
      .sortField('destructionDate')
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .build());

    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('events')
      .filterable()
      .filter(this.filters.events)
      .filterType(FilterType.DROPDOWN)
      .options(Data.events)
      .sortable()
      .sortField('events')
      .displayContent((data: PharmacyStock) => {
        if (data.events !== null) {
          return this.translateService.getTranslationString(data.events);
        }
      })
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('dispensationDates')
      .filterable()
      .filter(this.filters.dispensationDate)
      .filterType(FilterType.DROPDOWN)
      .options(this.yearsSelectItems)
      .sortable()
      .sortField('dispensationDates')
      .build());

    this.kitsColumnListHeader = this.config.buildTable(kitsListHeader);
  }

  getList() {
    this.pharmacyStockService.loadList(-1, this.dateType, this.fromDate, this.toDate, this.page, this.limit, this.sortBy, this.orderBy, this.filters).subscribe(data => {
      this.values = data.content;
      this.totalNumber = data.totalElements;
      if (this.values == null || this.values.length === 0) {
        $('div >  p-table > div > div > div > div.ui-table-scrollable-header.ui-widget-header').css('overflow', 'auto');
      } else {
        $('div > p-table > div > div > div > div.ui-table-scrollable-header.ui-widget-header').css('overflow', 'visible');
      }
      this.pharmacyStockService.setPaginationConfiguration(data, this.dateType, this.limit, this.sortBy, this.orderBy, this.filters, -1);
    }, error => {
      this.sharedService.showFailure();
      console.log(error);
    });
  }

  navigateToStock(pharmacyStock: PharmacyStock) {
    this.router.navigate(['/trial-details/pharmacy/stock'], {
      queryParams: {
        hhhId: pharmacyStock.trialHhhId,
        pharmacyHhhId: pharmacyStock.pharmacyHhhId
      }
    }).then();
  }

  getExportPageModule(): string {
    return 'src/app/pharmacy/pharmacy-stock/pharmacy-stock.component';
  }

  filterUpdated() {
    this.page = 0;
    this.first = 0;
    this.filters = Object.assign(TableHeader.buildFilterData(this.stockColumnListHeader), TableHeader.buildFilterData(this.kitsColumnListHeader));
    this.stockColumnListHeader.forEach(value => {
      if (value.composedFilter) {
        this.filters[value.field + 'Month'] = value.composedFilter[0];
        this.filters[value.field + 'Year'] = value.composedFilter[1];
      }
    });
    this.getList();
  }

  get filterType() {
    return FilterType;
  }

  displayContent(col: any, colField: any, rowData: any): string {
    let content = colField;
    if (isNullOrUndefined(rowData[col.field]) && !col.displayContent) {
      content = '';
    } else if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (col.dateFormatter) {
      content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
    }
    return content;
  }

  exportPharmacyStock(event: any) {
    const request = new ExportRequestBuilder<PharmacyStockGlobalFilter>()
      .objectId(this.pharmacyHhhId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .filters(this.filters)
      .build();
    this.pharmacyStockService.exportPharmacyStocks(request).subscribe();
  }

  getFilteredList(filter?) {
    this.first = 0;
    this.page = 0;
    if (filter) {
      Object.assign(this.filters, DynamicTableHeader.buildFilterData(this.stockColumnListHeader));
    } else {
      this.filters = new PharmacyStockGlobalFilter();
    }
    this.getList();
  }

  public getOrderedColumns(target: string): string[] {
    return JSON.parse(localStorage.getItem(target)).map(header => header.field);
  }

  resetFilter() {
    this.filters = new PharmacyStockGlobalFilter();
    this.page = 0;
    this.first = 0;
    this.ngOnInit();
    this.getList();
  }
  saveHeadersLayout() {
    localStorage.setItem(this.target, JSON.stringify(this.stockColumnListHeader));
  }

  getBtnClass(): string {
    return this.config ? this.config.getBtnClass() : '';
  }

  displayBtn(btnId: string): boolean {
    if (!this.config || !this.config.buttons) {
      return false;
    }
    return this.config.displayBtn(btnId);
  }

}


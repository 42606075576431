import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdditionalCostsContainerComponent} from './additional-costs-container/additional-costs-container.component';
import {ActsCategoriesComponent} from './acts-categories/acts-categories.component';
import {ReferenceActsComponent} from './reference-acts/reference-acts.component';
import {FixedCostCategoriesComponent} from './fixed-cost-categories/fixed-cost-categories.component';
import {FixedCostReferenceComponent} from './fixed-cost-reference/fixed-cost-reference.component';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {AuthGuard} from '../helpers/auth-guard.service';
import {CounterPartCategoriesComponent} from './counter-part-categories/counter-part-categories.component';
import {CounterPartReferenceComponent} from './counter-part-reference/counter-part-reference.component';
import {OperationalActsComponent} from './operational-acts/operational-acts.component';
import {RequirePasswordUpdateGuard} from '../helpers/require-password-update-guard';

const routes: Routes = [
  {
    path: 'additional-costs-container', component: AdditionalCostsContainerComponent, canActivate: [RequirePasswordUpdateGuard],
    children: [
      {path: '', redirectTo: 'acts-categories', pathMatch: 'full'},
      {path: 'acts-categories', component: ActsCategoriesComponent, canActivate: [AuthGuard]},
      {path: 'reference-acts', component: ReferenceActsComponent, canActivate: [AuthGuard]},
      {path: 'fixed-cost-categories', component: FixedCostCategoriesComponent, canActivate: [AuthGuard]},
      {path: 'fixed-cost-reference', component: FixedCostReferenceComponent, canActivate: [AuthGuard]},
      {path: 'counter-part-categories', component: CounterPartCategoriesComponent, canActivate: [AuthGuard]},
      {path: 'counter-parts', component: CounterPartReferenceComponent, canActivate: [AuthGuard]},
      {path: 'operational-acts', component: OperationalActsComponent, canActivate: [AuthGuard]},
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    HttpClientModule,
    TranslateModule
  ],
  exports: [RouterModule]
})


export class AdditionalCostsRoutingModule {
}

import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('ContainerCardConfig')
export class ContainerCardConfig {

  @JsonProperty('display', Boolean)
  display = false;

  @JsonProperty('title', String, true)
  title: string = undefined;

  @JsonProperty('icon', String, true)
  icon: string = undefined;

  @JsonProperty('order', Number)
  order: number = undefined;

  init(obj: any): ContainerCardConfig {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}

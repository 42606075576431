export class Invoice {
  hhhId: number;
  publicCommentCount: number;
  manualState: boolean;
  state: string;
  number: string;
  promoter: string;
  trialLocalIdentifier: string;
  trial: string;
  amountTotal: any;
  visitNames: string;
  issueDate: Date;
  validationDate: Date;
  despatchDate: Date;
  paymentDate: Date;
  address: string;
  description: string;
  establishment: string;
  inclusionIdentifier2: string;
  inclusionPreScreeningIdentifier: string;
  constructor() {
  }
  init(obj: any): Invoice {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {Observable, throwError} from 'rxjs';
import {TrialInclusionExclusionCriteria} from '../models/trial-inclusion-exclusion-criteria';
import {Value} from '../../shared/entity/value';
import {MinimizedTrialProtocol} from '../models/minimized-trial-protocol';
import {catchError, map} from 'rxjs/operators';
import {HumanResource} from '../../human-resources/models/human-resource';

@Injectable({
  providedIn: 'root'
})
export class TrialInclusionExclusionCriteriaService {

  private host = new Properties().host + '/trial-inclusion-exclusion-criteria';

  constructor(private http: HttpClient) {
  }

  load(trial: number, protocol: number): Observable<TrialInclusionExclusionCriteria> {
    return this.http.get<TrialInclusionExclusionCriteria>(this.host + '/load/' + trial + '/' + protocol)
      .pipe(
        map(res => {
            return (new TrialInclusionExclusionCriteria()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  save(criteria: TrialInclusionExclusionCriteria): Observable<TrialInclusionExclusionCriteria> {
    return this.http.post<TrialInclusionExclusionCriteria>(this.host + '/save', criteria)
      .pipe(
        map(res => {
            return (new TrialInclusionExclusionCriteria()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  existsByTrialId(trialId: number): Observable<Value<boolean>> {
    return this.http.get<Value<boolean>>(this.host + '/exists-for-last-protocol-by-trial/' + trialId);
  }

  getRelatedProtocolsByTrial(trialId: number): Observable<MinimizedTrialProtocol[]> {
    return this.http.get<MinimizedTrialProtocol[]>(this.host + '/load-used-protocols/' + trialId);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

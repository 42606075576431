import {Component, OnInit} from '@angular/core';
import {TheoreticalVisitAct} from '../../../entity/theoretical-calendar/theoretical-visit-act';
import {Data} from '../../../../shared/entity/data';
import {SharedService} from '../../../../shared/services/shared.service';
import {ReferenceAct} from '../../../../additional-costs/models/reference-act';
import {Router} from '@angular/router';
import {TheoreticalVisitActService} from '../../../service/theoretical-visit-act.service';
import {TrialActService} from '../../../service/trial-act.service';
import {UsersService} from '../../../../admin/services/users.service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-update-theoretical-visit-act-form',
  templateUrl: './update-theoretical-visit-act-form.component.html',
  styleUrls: ['./update-theoretical-visit-act-form.component.css']
})
export class TheoreticalVisitActUpdateFormComponent implements OnInit {

  public amendmentsEnabled = true;

  public displayTheoreticalVisitActUpdate = false;
  public theoreticalVisitAct: TheoreticalVisitAct = new TheoreticalVisitAct();
  public referenceAct: ReferenceAct = new ReferenceAct();
  public noYesOptions = Data.yesOrNo;
  public trialHhhId: number;
  private callback: (theoreticalVisitAct: TheoreticalVisitAct) => void;

  constructor(
    private sharedService: SharedService,
    private useService: UsersService,
    private router: Router,
    private theoreticalVisitActService: TheoreticalVisitActService,
    private trialActService: TrialActService,
    private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    this.displayTheoreticalVisitActUpdate = true;
    this.getReferenceAct();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
    });
  }

  getReferenceAct() {
    if (this.theoreticalVisitAct.modificationType === 'add') {
      this.trialActService.getReferenceActByTrialAct(this.theoreticalVisitAct.hhhId).subscribe(res => {
        this.referenceAct.actCategoryName = this.theoreticalVisitAct.actCategory;
        this.referenceAct.description = res.description;
        this.referenceAct.onPrescription = res.onPrescription;
        this.referenceAct.externalFromCentre = res.externalFromCentre;
        this.referenceAct.invoiceable = res.invoiceable;
        this.referenceAct.pricePerUnit = res.pricePerUnit;
      });
    } else {
      this.theoreticalVisitActService.getReferenceActByTheoreticalVisitAct(this.theoreticalVisitAct.hhhId)
        .subscribe(res => {
          this.referenceAct = res;
        });
    }
  }

  updateTheoreticalVisitAct() {
    this.theoreticalVisitActService.isEditable(this.theoreticalVisitAct.hhhId).subscribe(res => {
        if (res === 'IS_EDITABLE' || res === 'HAS_COMPLETED') {
          this.theoreticalVisitAct.modificationType = 'edit';
          this.sharedService.showSuccess();
          this.callback(this.theoreticalVisitAct);
          this.displayTheoreticalVisitActUpdate = false;
        } else {
          this.sharedService.showFailure('MODULE_TRIAL_THEORETICAL_ACTS_' + res);
        }
      }, error1 => {
        console.error(error1);
      }
    );
  }


  onUpdate(callback: (theoreticalVisitAct: TheoreticalVisitAct) => void) {
    this.callback = callback;
  }

  displayUnitPrice(theoreticalVisitAct: any): string {
    if (!this.useService.canAccessFieldByValue(theoreticalVisitAct.type)) {
      return '-';
    }
    return (theoreticalVisitAct.invoiceable ? theoreticalVisitAct.pricePerUnit + '€' : '-');
  }

  displayTotalPrice(theoreticalVisitAct: TheoreticalVisitAct) {
    if (!this.useService.canAccessFieldByValue(theoreticalVisitAct.type)) {
      return '-';
    }
    return theoreticalVisitAct.pricePerUnit * theoreticalVisitAct.numberOfUnits;
  }

  isPriceAccessed(theoreticalVisitAct: TheoreticalVisitAct) {
    return this.useService.canAccessFieldByValue(theoreticalVisitAct.type);
  }

}


import {DataCommentCount} from '../../data-comment/entity/data-comment-count';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {CalendarCheckbox} from './calendar-checkbox';

export class TrialAdministrativeComopInformation extends DataCommentCount {

  // numbers
  public hhhId: number;
  public trialHhhId: number;
  public trialProtocolHhhId: number;
  public cardiologyConsultationInstitutionContractStatusHhhId: number;
  public respiratoryFunctionTestInstitutionContractStatusHhhId: number;
  public ophthalmologyInstitutionContractStatusHhhId: number;
  public cardiacEchoInstitutionContractStatusHhhId: number;
  public otherInstitutionContractStatusHhhId: number;

  // string
  public cardiologyConsultationInstitutionName: string;
  public respiratoryFunctionTestInstitutionName: string;
  public ophthalmologyInstitutionName: string;
  public cardiacEchoInstitutionName: string;
  public otherInstitutionName: string;
  public comment: string;

  // calendarCheckboxDto
  public crbRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public crbAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public etExtraRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public etExtraAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public intratumoralCircuitRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public intratumoralCircuitAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public imageryRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public imageryAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public nuclearMedicineRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public nuclearMedicineAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public ditepCareRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public ditepCareAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public petraRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public petraAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public photographyRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public photographyAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public interventionalRadiologyRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public interventionalRadiologyAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public hdjDmoTreatmentRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public hdjDmoTreatmentAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public hdsDmoTreatmentRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public hdsDmoTreatmentAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public comopAgreementDate: CalendarCheckbox = new CalendarCheckbox();
  public coordoPrcDate: CalendarCheckbox = new CalendarCheckbox();
  public hdsDmjPediatricCareRequestDate: CalendarCheckbox = new CalendarCheckbox();
  public hdsDmjPediatricCareAgreementDate: CalendarCheckbox = new CalendarCheckbox();

  init(obj: any): TrialAdministrativeComopInformation {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

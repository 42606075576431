import {NgModule} from '@angular/core';
import {DatePipe} from '@angular/common';
import {PatientListComponent} from './patient-list/patient-list.component';
import {PatientDetailsComponent} from './patient-details/patient-details.component';
import {PatientRoutingModule} from './patient-routing.module';
import {PatientFormComponent} from './patient-form/patient-form.component';
import {SharedModule} from '../shared/shared.module';
import {PatientDetailsInformationComponent} from './patient-details-informations/patient-details-information.component';
import {PatientInclusionListComponent} from './patient-inclusion-list/patient-inclusion-list.component';
import {PatientTrialFormComponent} from '../modals/patient-add-to-trial-form/patient-trial-form.component';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';
import {DataDocumentModule} from '../data-document/data-document.module';

@NgModule({
  imports: [
    PatientRoutingModule,
    DynamicConfigModule,
    SharedModule,
    DataDocumentModule
  ],
  declarations: [
    PatientFormComponent,
    PatientListComponent,
    PatientDetailsComponent,
    PatientDetailsInformationComponent,
    PatientInclusionListComponent,
    PatientDetailsInformationComponent
  ],
  providers: [DatePipe],
  entryComponents: [PatientFormComponent, PatientTrialFormComponent]
})
export class PatientModule {
}

import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {TheoreticalCalendarService} from '../../../service/theoretical-calendar.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {AmendmentVerificationComponent} from '../../../../modals/amendment-verification/amendment-verification.component';
import {TrialProtocolService} from '../../../services/trial-protocol.service';
import {TrialObservablesService} from '../../../services/trial-observables.service';
import {LabelValue} from '../../../../shared/entity/label-value';
import {ArmService} from '../../../services/arm.service';
import {TheoreticalCalendar} from '../../../entity/theoretical-calendar/theoretical-calendar';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DataItemService} from '../../../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-amendment-dialog',
  templateUrl: './amendment-dialog.component.html',
  styleUrls: ['./amendment-dialog.component.css']
})
export class AmendmentDialogComponent implements OnInit {

  completedAndInvoiceableVisitsAndActsEnabled = false;
  amendmentsEnabled = true;

  @ViewChild('amendmentVerificationDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  updateType = 'saveNow';
  displayAmendmentDialog = true;
  @Input() theoreticalCalendar: TheoreticalCalendar;
  @Input() trialId;
  @Output() amendmentSaveEvent: EventEmitter<any> = new EventEmitter<any>();
  disableAll = false;
  disableSaveButton = false;

  private callback: (trialProtocol?: number) => void;
  availableTrialProtocols: LabelValue[] = [];
  disableUpdateNowRadioButton = false;

  constructor(private theoreticalCalendarService: TheoreticalCalendarService,
              private sharedService: SharedService,
              private cfr: ComponentFactoryResolver,
              private trialProtocolService: TrialProtocolService,
              private trialObservablesService: TrialObservablesService,
              private dynamicConfigService: DynamicConfigService,
              private dataItemService: DataItemService,
              private armService: ArmService) {
  }

  public static displayDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const deletePopupFactory = cfr.resolveComponentFactory(AmendmentDialogComponent);
    const deletePopupComponentRef = viewRef.createComponent(deletePopupFactory);
    return deletePopupComponentRef.instance;
  }

  ngOnInit() {
    this.initProperties();
    this.initAvailableTrialProtocols();
    this.getIsThereAnyUsedProtocol();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.completedAndInvoiceableVisitsAndActsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.completedAndInvoiceableVisitsAndActsEnabled);
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
    });
  }

  saveAmendment() {
    this.disableSaveButton = true;
    if (this.amendmentsEnabled && this.updateType === 'saveAfter') {
      const usedArmsIds: number[] = this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.filter(arm => {
        return arm.theoreticalVisits && arm.theoreticalVisits.length > 0 &&
          arm.theoreticalVisits.filter(v => !this.theoreticalCalendar.theoreticalVisitToDeleteDtoList.find(id => id === v.hhhId)).length > 0;
      }).map(arm => arm.armHhhId);
      if (usedArmsIds.length > 0) {
        this.armService.armsNamesRemainByProtocol(this.theoreticalCalendar.trialProtocolHhhId, usedArmsIds).subscribe(result => {
          if (result.value) {
            this.processSavingAmendment();
          } else {
            this.sharedService.showWarning('ARMS_NOT_EXISTING_IN_TC_PROTOCOL');
          }
        });
      } else {
        this.processSavingAmendment();
      }
    } else {
      this.processSavingAmendment();
    }
  }

  processSavingAmendment() {
    this.displayAmendmentDialog = false;
    if (!this.amendmentsEnabled) {
      if (this.updateType === 'saveAfter'
        && (!this.theoreticalCalendar.amendment
          || this.theoreticalCalendar.amendment.trim() === '')) {
        this.displayAmendmentVerificationDialog();
      } else {
        this.theoreticalCalendarService.updateAmendmentVersion(this.updateType, this.theoreticalCalendar).subscribe(() => {
          this.callback();
          this.disableSaveButton = false;
          this.sharedService.showSuccess();
        }, error => {
          this.disableSaveButton = false;
          this.sharedService.showFailure();
          console.error(error);
        });
      }
    } else {
      if (this.updateType === 'saveNow') {
        this.theoreticalCalendar.trialProtocolHhhId = this.trialProtocolService.selectedTheoreticalCalendarProtocolHhhId;
      } else {
        this.theoreticalCalendar.theoreticalVisitDtoList = [];
        this.theoreticalCalendar.theoreticalVisitBeforeInclusionList.forEach(v => {
          v.parentId = v.hhhId;
          if (this.theoreticalCalendar.theoreticalVisitToDeleteDtoList.find(id => id === v.hhhId)) {
            return;
          }
          if (v.dateReferenceVisit && v.dateReferenceVisit.hhhId) {
            v.dateReferenceVisit.hhhId = null;
          }
          this.theoreticalCalendar.theoreticalVisitDtoList.push(v);
          v.visitActsToAdd = [];
          if (v.visitActs && v.visitActs.length > 0) {
            v.visitActs
              .filter(visitAct => this.theoreticalCalendar.theoreticalVisitActsToDeleteHhhIds.indexOf(visitAct.hhhId) < 0)
              .forEach(a => {
                a.hhhId = null;
                a.details = [];
                v.visitActsToAdd.push(a);
              });
          }
        });
        this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.forEach(arm => arm.theoreticalVisits
          .filter(v => !this.theoreticalCalendar.theoreticalVisitToDeleteDtoList.find(id => id === v.hhhId))
          .forEach(visit => {
            visit.parentId = visit.hhhId;
            if (visit.dateReferenceVisit && visit.dateReferenceVisit.hhhId) {
              visit.dateReferenceVisit.hhhId = null;
            }
            this.theoreticalCalendar.theoreticalVisitDtoList.push(visit);
            visit.visitActsToAdd = [];
            if (visit.visitActs && visit.visitActs.length > 0) {
              visit.visitActs
                .filter(visitAct => this.theoreticalCalendar.theoreticalVisitActsToDeleteHhhIds.indexOf(visitAct.hhhId) < 0)
                .forEach(a => {
                  a.hhhId = null;
                  a.details = [];
                  visit.visitActsToAdd.push(a);
                });
            }
          }));
        this.theoreticalCalendar.theoreticalVisitAfterInclusionList.forEach(v => {
          v.parentId = v.hhhId;
          if (this.theoreticalCalendar.theoreticalVisitToDeleteDtoList.find(id => id === v.hhhId)) {
            return;
          }
          if (v.dateReferenceVisit && v.dateReferenceVisit.hhhId) {
            v.dateReferenceVisit.hhhId = null;
          }
          this.theoreticalCalendar.theoreticalVisitDtoList.push(v);
          v.visitActsToAdd = [];
          if (v.visitActs && v.visitActs.length > 0) {
            v.visitActs
              .filter(visitAct => this.theoreticalCalendar.theoreticalVisitActsToDeleteHhhIds.indexOf(visitAct.hhhId) < 0)
              .forEach(a => {
                a.hhhId = null;
                a.details = [];
                v.visitActsToAdd.push(a);
              });
          }
        });
      }
      this.theoreticalCalendarService.updateTheoreticalCalendarByProtocolHhhId(this.theoreticalCalendar, this.trialId, this.updateType).subscribe((res) => {
        this.trialObservablesService.onTheoreticalCalendarSave.emit();
        this.callback(this.theoreticalCalendar.trialProtocolHhhId);
        this.disableSaveButton = false;
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
        this.disableSaveButton = false;
      });
    }
  }

  displayAmendmentVerificationDialog() {
    const addDialogFactory = this.cfr.resolveComponentFactory(AmendmentVerificationComponent);
    this.dialogContainer.createComponent(addDialogFactory);
  }

  onSave(callback: (trialProtocol?: number) => void) {
    this.callback = callback;
  }

  private initAvailableTrialProtocols() {
    this.dataItemService.getItems('protocol-unused-in-tc|' + this.trialId).subscribe((res) => {
      this.availableTrialProtocols = res;
    });
  }

  private getIsThereAnyUsedProtocol() {
    this.theoreticalCalendarService.getRelatedProtocolsByTrial(this.trialId).subscribe(res => {
      this.disableUpdateNowRadioButton = this.amendmentsEnabled && res.length === 0;
    });
  }

  private cleanDuplicateActs(trialActs: Array<any>) {
    for (const trialAct of trialActs) {

    }
  }
}

import {Component, ComponentFactoryResolver, Inject, InjectionToken, OnInit, ViewContainerRef} from '@angular/core';
import {PatientFileImport} from '../../../entity/inclusion/patient-file-import';
import {Router} from '@angular/router';
import {InclusionService} from '../../../service/inclusion.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {Properties} from '../../../../helpers/properties';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';

export const LOCATION_TOKEN = new InjectionToken<Location>('Window location object');

@Component({
  selector: 'ih-import-patient-form',
  templateUrl: './import-patient-form.component.html',
  styleUrls: ['./import-patient-form.component.css'],
  providers: [
    {provide: LOCATION_TOKEN, useValue: window.location}
  ]
})
export class ImportPatientFormComponent implements OnInit {

  inclusionImportColMode = false;
  inclusionImportCJPMode = false;
  inclusionImportSaintLucMode = false;
  inclusionImportHEMode = false;
  inclusionImportGHPSJMode = false;
  inclusionImportIGRJMode = false;
  importPatientsFileName: string;

  public displayImportPatientListDialog: boolean;
  public patientFileImport: PatientFileImport = new PatientFileImport();
  host = new Properties().host;
  callback: () => void;
  comments: string[][];

  constructor(
    private router: Router,
    private inclusionService: InclusionService,
    private dynamicConfigService: DynamicConfigService,
    private sharedService: SharedService,
    @Inject(LOCATION_TOKEN) private location: Location
  ) {
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ImportPatientFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.displayImportPatientListDialog = true;
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.inclusionImportColMode = this.dynamicConfigService.getProperty(PropertyEnum.inclusionImportColMode);
      this.inclusionImportCJPMode = this.dynamicConfigService.getProperty(PropertyEnum.inclusionImportCJPMode);
      this.inclusionImportHEMode = this.dynamicConfigService.getProperty(PropertyEnum.inclusionImportHEMode);
      this.inclusionImportGHPSJMode = this.dynamicConfigService.getProperty(PropertyEnum.inclusionImportGHPSJMode);
      this.inclusionImportIGRJMode = this.dynamicConfigService.getProperty(PropertyEnum.inclusionImportIGRJMode);
      this.inclusionImportSaintLucMode = this.dynamicConfigService.getProperty(PropertyEnum.inclusionImportSaintLucMode);
      this.importPatientsFileName = this.dynamicConfigService.getProperty(PropertyEnum.importPatientsFileName);
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.patientFileImport.file = event.target.files[0];
    }
  }

  saveInclusion() {
    let trialHhhId;
    const url = this.router.parseUrl(this.router.url);
    trialHhhId = url.queryParams.hhhId;
    this.patientFileImport.trialHhhId = trialHhhId;
    this.inclusionService.patientFileImport(this.patientFileImport).subscribe(res => {
      this.comments = res;
      if (this.inclusionImportColMode) {
        this.inclusionService.createReportImportCSVFileCol(this.comments);
      } else if (this.inclusionImportCJPMode) {
        this.inclusionService.createReportImportCSVFileForCjp(this.comments);
      } else if (this.inclusionImportSaintLucMode) {
        this.inclusionService.createReportImportCSVFileForSaintLuc(this.comments);
      } else if (this.inclusionImportHEMode) {
        this.inclusionService.createReportImportCSVFileForHe(this.comments);
      } else if (this.inclusionImportGHPSJMode) {
        this.inclusionService.createReportImportCSVFileForGHPSJ(this.comments);
      } else if (this.inclusionImportIGRJMode) {
        this.inclusionService.createReportImportCSVFileIGR(this.comments);
      } else {
        this.inclusionService.createReportImportCSVFile(this.comments);
      }
      this.sharedService.showSuccess();
      this.callback();
    }, error => {
      this.sharedService.showFailure(error.error);
      console.error(error);
    });
    this.displayImportPatientListDialog = false;
  }

  importFile() {
    this.location.assign(this.host + '/' + this.importPatientsFileName);
  }

  closeImportPatientListDialog() {
    this.displayImportPatientListDialog = false;
  }

  onImport(callback: () => void) {
    this.callback = callback;
  }

}

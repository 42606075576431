import { Component, OnInit } from '@angular/core';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {Route, Router} from '@angular/router';

@Component({
  selector: 'ih-statistics-container',
  templateUrl: './statistics-container.component.html',
  styleUrls: ['./statistics-container.component.css']
})
export class StatisticsContainerComponent implements OnInit {
  powerBiEnabled = false;
  constructor(private dynamicConfigService: DynamicConfigService, private router: Router) { }

  ngOnInit() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.powerBiEnabled = this.dynamicConfigService.getProperty(PropertyEnum.powerBiEnabled);
      if (this.powerBiEnabled) {
        this.router.navigate(['/statistics/power-bi']).then();
      } else {
        this.router.navigate(['/statistics/follow-trials']).then();
      }
    });
  }

}

import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TrialActService} from '../../../trial/service/trial-act.service';
import {SharedService} from '../../services/shared.service';
import {isNullOrUndefined, isUndefined} from 'util';
import {SelectItem} from 'primeng/components/common/api';
import {InnohealthTranslateService} from '../../services/innohealth-translate.service';
import {DataItemService} from '../../../custom-field/service/data-item.service';

export const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true
};

@Component({
  selector: 'ih-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css'],
  providers: [MULTISELECT_VALUE_ACCESSOR]
})
export class MultiSelectComponent<T> implements OnInit, ControlValueAccessor {

  @Input() items: SelectItem[] = [];
  @Input() selectedValues: T[];
  @Input() maxSelectedLabels = 10;
  @Input() defaultLabel: string;
  @Input() disableWhenSelected: boolean;
  @Input() isSpecificValue: boolean;
  @Input() specificValue: string;
  @Input() addToDropDown: boolean;
  @Input() buttonText: boolean;
  @Input() disabled = false;
  @Input() appendTo: string;

  @Output() valueInput: EventEmitter<T[]> = new EventEmitter();
  @Output() valueChange: EventEmitter<T[]> = new EventEmitter();
  @Output() itemValuesChange: EventEmitter<boolean> = new EventEmitter();
  @Output() itemValuesInput: EventEmitter<T[]> = new EventEmitter();
  @Output() onShow: EventEmitter<T> = new EventEmitter();
  @Output() onHide: EventEmitter<T> = new EventEmitter();
  @Output() displaySelectedItems: EventEmitter<any> = new EventEmitter();
  @Output() notifyWhenSpecificValueSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() onAddToDropDown: EventEmitter<any> = new EventEmitter();

  overlayVisible = false;
  lastDisabledItems: T[] = [];
  initialValues: T[];
  private _values: T[];
  private _type: string;

  onChange = (_) => {
  }
  onTouched = () => {
  }

  constructor(private trialActService: TrialActService,
              private sharedService: SharedService,
              private translateService: InnohealthTranslateService,
              private dataItemService: DataItemService) {
  }


  get values(): T[] {

    if (!isNullOrUndefined(this._values)) {
      if (this.isSpecificValue) {
        const translateService = this.translateService;
        const wantedItem: SelectItem = this.items.find(item => item.label === translateService.getTranslationString(this.specificValue));
        this.notifyWhenSpecificValueSelected.emit(wantedItem && this._values.indexOf(wantedItem.value) !== -1);
      }
      if (this.disableWhenSelected) {
        let exists = null;
        if (!isUndefined(this.selectedValues)) {
          exists = this.selectedValues.find(item => this._values.indexOf(item) !== -1);
        }
        if (isNullOrUndefined(exists)) {
          for (const item of this._values) {
            this.lastDisabledItems.push(item);
            this.selectedValues.push(item);
          }
          this.displaySelectedItems.emit(this.selectedValues);
        }
      }
    }
    return this._values;
  }

  set values(v: T[]) {
    if (v !== this._values) {
      this._values = v;
      this.onChange(v);
      if (this.disableWhenSelected) {
        if (!isNullOrUndefined(this.lastDisabledItems)) {
          for (const lastDisabledItem of this.lastDisabledItems) {
            const index = this.selectedValues.findIndex(item => item === lastDisabledItem);
            this.selectedValues.splice(index, 1);
          }
        }
        for (const item of this._values) {
          this.lastDisabledItems.push(item);
          this.selectedValues.push(item);
        }
        this.displaySelectedItems.emit(this.selectedValues);
      }
      this.valueChange.emit(this._values);
      if (!this.overlayVisible) {
        this.valueInput.emit(this._values);
      }
    }
  }


  get type(): string {
    return this._type;
  }

  @Input()
  set type(v: string) {
    if (v === undefined) {
      return;
    }
    if (v !== this._type) {
      this._type = v;
      this.loadItems(v);
    }
  }

  onShowDo() {
    this.onShow.emit();
    if (this.disableWhenSelected && !isNullOrUndefined(this.items) && !isNullOrUndefined(this.selectedValues)) {
      for (const item of this.items) {
        if (item.disabled) {
          item.disabled = false;
        }
      }
      for (const selectedValue of this.selectedValues) {
        if (!this._values || (this._values && this._values.indexOf(selectedValue) === -1)) {
          const index = this.items.findIndex(item => item.value === selectedValue);
          if (index > -1) {
            this.items[index].disabled = true;
          }
        }
      }
    }
    this.initialValues = this._values;
    this.overlayVisible = true;
  }

  onHideDo() {
    this.onHide.emit();
    this.overlayVisible = false;
    if (this.values !== this.initialValues) {
      this.valueInput.emit(this._values);
    }
  }

  ngOnInit() {
  }

  writeValue(v: T[]) {
    if (v !== this._values) {
      this._values = v;
      this.onChange(this._values);
      this.valueChange.emit(this._values);
    }
  }

  loadItems(v: string, refreshCash?: boolean): void {
    this.dataItemService.getItems(v).subscribe(res => {
      this.items = res;
      const translateService = this.translateService;
      if (this.specificValue) {
        const wantedItem: SelectItem = this.items.find(item => item.label === translateService.getTranslationString(this.specificValue));
        this.notifyWhenSpecificValueSelected.emit(wantedItem && this._values.indexOf(wantedItem.value) !== -1);
      }
    });
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  selectedValuesChanged() {
    this.itemValuesChange.emit(true);
  }

  addToDropDownEvent() {
    this.onAddToDropDown.emit();
  }

  onRefreshDrop(refreshCash?: boolean) {
    this.loadItems(this._type, !isUndefined(refreshCash) ? refreshCash : true);
  }
}

export class Properties {

  // use remoteHost to connect to remote backend
  public remoteHost = 'https://preprod.innogec.fr';
  public AA = 'https://antoine-lacassagne.innogec.fr';

  public remote = '';

  public local = 'http://localhost:8080';
  public host = this.remote;
  public version = '4.3.51';
  public csvSeparator = ';';
  public webSocketEndPoint = this.host + '/ws';

}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IsDeletable} from '../../is-deletable';
import {Deletable} from '../../modals/delete-confirmation/deletable';

@Injectable({
  providedIn: 'root'
})
export class TheoreticalVisitService implements Deletable {

  public properties: Properties = new Properties();
  public host = this.properties.host + '/theoretical-visit';

  constructor(private httpClient: HttpClient) {
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  getTheoreticalVisitsByTrialActHhhId(hhhId: number): Observable<number[]> {
    return this.httpClient.get<number[]>(this.host + '/trial-act/' + hhhId);
  }
}

export class ReportInclusionByTrialFilter {

  public organ: number;
  public promoterHhhId: number;
  public siteHhhId: number;
  public establishmentDepartmentHhhId: number;
  public year: number;
  public establishmentHhhId: number;
  public trialHhhId: number;
  public phaseHhhIds: number[];
  public trialStateHhhIds: number[];
  public trialCommitteesHhhIds: number[];


}

import {Value} from '../../../shared/entity/value';
import {StateType} from '../../../shared/entity/payment-type.enum';

export class VisitUpdate {
  visitId: number;
  updatedField: string[] = [];
  realDate: Date;
  registrationDate: Date;
  state: number;
  providerState: number;

  updateVisitActState: boolean;
  visitActStateId: number;
  visitActStateTypeEnum: StateType;

  setVisitActsAsCompleted: boolean=false;
  setVisitActsAsNotRealised: boolean =false;
  olVisitStateId: number;
  createInvoiceForUninvoicedActs: boolean;
  createInvoiceFromInvoicedOrPaidVisit: boolean;
  visitActPaymentStateHhhId: number;
  invoiceStateHhhId: number;

  shiftVisit: boolean;

  visitListNotToBeShifted: Value<number>[] = [];


}

import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InnohealthTranslateService} from './shared/services/innohealth-translate.service';
import {AuthenticationService, SESSION_ID, TOKEN, WEBSOCKET_SESSION_ID} from './login/services/authentication.service';
import {Route, Router, Routes} from '@angular/router';
import {AuthGuard} from './helpers/auth-guard.service';
import {DisciplineIdentificationInformationComponent} from './trial/component/trial-identification/discipline-identification-information/discipline-identification-information.component';
import {ArmIdentificationInformationComponent} from './trial/component/trial-identification/arm/arm-identification-information/arm-identification-information.component';
import {DesignIdentificationInformationComponent} from './trial/component/trial-identification/design/design-identification-information/design-identification-information.component';
import {AmendmentIdentificationInformationComponent} from './trial/component/trial-identification/version-protocol/amendment-identification-information/amendment-identification-information.component';
import {CalendarInclusionComponent} from './trial/component/inclusions/inclusion-details/calendar-details/calendar/calendar-inclusion.component';
import {CanDeactivateGuard} from './helpers/can-deactivate-guard';
import {FollowUpPatientContainerComponent} from './trial/component/inclusions/patient-follow-up/follow-up-patient-container/follow-up-patient-container.component';
import {AdverseEffectComponent} from './trial/component/inclusions/patient-follow-up/adverse-effect/adverse-effect.component';
import {ConcomitantTreatmentComponent} from './trial/component/inclusions/patient-follow-up/concomitant-treatment/concomitant-treatment.component';
import {VitalSignsComponent} from './trial/component/inclusions/patient-follow-up/vital-signs/vital-signs.component';
import {VisitDetailComponent} from './trial/component/inclusions/inclusion-details/calendar-details/visit-detail/visit-detail.component';
import {AmendmentConsentsComponent} from './trial/component/trial-identification/version-protocol/amendment-consents/amendment-consents.component';
import {TrialAdministrativeCecInformationComponent} from './trial/component/trial-administrative/trial-administrative-cec-information/trial-administrative-cec-information.component';
import {TrialAdministrativeConventionInformationComponent} from './trial/component/trial-administrative/trial-administrative-convention-informations/trial-administrative-convention-information.component';
import {DynamicConfigService} from './dynamic-config/service/dynamic-config.service';
import {MessageService} from 'primeng/api';
import {InclusionExclusionCriteriaComponent} from './trial/component/trial-identification/inclusion-exclusion/inclusion-exclusion-criteria/inclusion-exclusion-criteria.component';
import {InclusionExclusionCriteriaDisciplineComponent} from './trial/component/trial-identification/inclusion-exclusion/inclusion-exclusion-criteria-discipline/inclusion-exclusion-criteria-discipline.component';
import {TrialIdentificationInformationComponent} from './trial/component/trial-identification/trial-identification-information/trial-identification-information.component';
import {TrialAdministrativeRegulatoryInformationComponent} from './trial/component/trial-administrative/trial-administrative-regulatory-information/trial-administrative-regulatory-information.component';
import {TrialPersonnelInformationComponent} from './trial/component/trial-identification/personnel/trial-personnel-information/trial-personnel-information.component';
import {TrialPersonnelProviderInformationComponent} from './trial/component/trial-identification/personnel/trial-personnel-provider-information/trial-personnel-provider-information.component';
import {TrialTreatmentMethodologieComponent} from './trial/component/trial-identification/trial-treatment-methodologie/trial-treatment-methodologie.component';
import {TrialAdministrativeFeasibilityInformationComponent} from './trial/component/trial-administrative/trial-administrative-feasibility-information/trial-administrative-feasibility-information.component';
import {TrialAdministrativeDatesInformationComponent} from './trial/component/trial-administrative/trial-administrative-dates-information/trial-administrative-dates-information.component';
import {InclusionInformationVersionProtocolComponent} from './trial/component/inclusions/inclusion-information-version-protocol/inclusion-information-version-protocol.component';
import {InclusionInformationArmComponent} from './trial/component/inclusions/inclusion-information-arm/inclusion-information-arm.component';
import {AmendmentProtocolsComponent} from './trial/component/trial-identification/version-protocol/amendment-protocols/amendment-protocols.component';
import {StompSubscription} from '@stomp/stompjs/src/stomp-subscription';
import {StompService} from './shared/services/stomp.service';
import {DynamicFilesService} from "./shared/services/dynamic-files.service";
import {
  TrialAdministrativeLogisticInformationComponent
} from './trial/component/trial-administrative/trial-administrative-logistic-information/trial-administrative-logistic-information.component';
import {
  TrialAdministrativeFollowupInformationComponent
} from './trial/component/trial-administrative/trial-administrative-follow-up-information/trial-administrative-follow-up-information.component';
import {
  TrialAdministrativeComopInformationComponent
} from './trial/component/trial-administrative/trial-administrative-comop-information/trial-administrative-comop-information.component';
import {HeartbeatService} from './heartbeat.service';
import {RequirePasswordUpdateGuard} from './helpers/require-password-update-guard';

@Component({
  selector: 'ih-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {
  title = this.translateService.getTranslationString('UI_GLOBAL_TITLE');
  public message = true;
  public currentUser;
  public firstStarting = true;

  private configUpdateSubscription: StompSubscription;

  constructor(
      private translate: TranslateService,
      private router: Router,
      private dynamicConfigService: DynamicConfigService,
      private stompService: StompService,
      private translateService: InnohealthTranslateService,
      private authService: AuthenticationService,
      private messageService: MessageService,
      private _cdr: ChangeDetectorRef,
      private dynamicFilesService: DynamicFilesService,
      private heartbeatService: HeartbeatService
  ) {
    this.initialize().then(() => {
      console.log('Initialization completed.');
    }).catch(error => {
      console.error('Initialization error:', error);
    });
  }

  private async initialize(): Promise<void> {
    try {
      this.authService.currentUser.subscribe(currentUser => {
        this.currentUser = currentUser;
      });
      await this.refreshTokenAndInitApplication();
      this.configureApplication();
    } catch (error) {
      console.error('Initialization error:', error);
      throw error;
    }
  }

  private async refreshTokenAndInitApplication(): Promise<void> {
    try {
      await this.dynamicConfigService.initApplication().then();
      await this.dynamicFilesService.initDynamicFilesProperties().then();

    } catch (error) {
      console.error('Error refreshing token or initializing application:', error);
      throw error; // Re-throw the error for handling in the initialize function
    }
  }

  private configureApplication(): void {
    this.translate.setDefaultLang('fr');
    this.translate.currentLang = 'fr';
    this.stompService.connect();
    this.heartbeatService.startHeartbeat();
    this.dynamicConfigService.started.subscribe(res => {
      if (this.firstStarting && res) {
        this.initRoutes();
        this.subscribeToDynamicConfigWebSocket();
      }
    });
  }

  ngOnDestroy() {
    this.dynamicConfigService.appStarted.next(false);
    if (this.configUpdateSubscription) {
      this.stompService.unsubscribe(this.configUpdateSubscription);
    }
    this.stompService.disconnect();
    this.heartbeatService.stopHeartbeats();
  }

  initRoutes() {
    this.firstStarting = false;
    const appRoutes: Routes = [...this.router.config];
    const trialIdentificationInfoRoute: Route[] = [
      {path: '', redirectTo: 'trial-identification-information', pathMatch: 'full'},
      {
        path: 'trial-identification-information',
        component: TrialIdentificationInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'personnel-identification-information',
        component: TrialPersonnelInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'personnel-provider-identification-information',
        component: TrialPersonnelProviderInformationComponent,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'methodology-identification-information',
        component: TrialTreatmentMethodologieComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'discipline-identification-information',
        component: DisciplineIdentificationInformationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'inclusion-exclusion-criteria',
        component: InclusionExclusionCriteriaComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'inclusion-exclusion-criteria-discipline',
        component: InclusionExclusionCriteriaDisciplineComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'arm-identification-information',
        component: ArmIdentificationInformationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'design-identification-information',
        component: DesignIdentificationInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: 'amendment-identification-information',
        component: AmendmentIdentificationInformationComponent,
        canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'protocols', pathMatch: 'full'},
          {
            path: 'consents',
            component: AmendmentConsentsComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'protocols',
            component: AmendmentProtocolsComponent,
            canActivate: [AuthGuard]
          }
        ]
      }
    ];

    appRoutes.find((routes => routes.path === 'trial-identification-information-container')).children = trialIdentificationInfoRoute;

    appRoutes.find((routes => routes.path === 'trial-details')).children.splice(1, 1);

    appRoutes.find((routes => routes.path === 'trial-details')).children.push(appRoutes.find((routes => routes.path === 'trial-identification-information-container')));

    const trialAdministrativeInfoRoute: Route[] = [
      {path: '', redirectTo: 'trial-administrative-regulatory-information', pathMatch: 'full', canActivate: [RequirePasswordUpdateGuard]},
      {
        path: 'trial-administrative-regulatory-information',
        component: TrialAdministrativeRegulatoryInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'trial-administrative-dates-information',
        component: TrialAdministrativeDatesInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'trial-administrative-feasibility-information',
        component: TrialAdministrativeFeasibilityInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'trial-administrative-convention-information',
        component: TrialAdministrativeConventionInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'trial-administrative-cec-information',
        component: TrialAdministrativeCecInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'trial-administrative-logistic-information',
        component: TrialAdministrativeLogisticInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'trial-administrative-follow-up-information',
        component: TrialAdministrativeFollowupInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'trial-administrative-comop-information',
        component: TrialAdministrativeComopInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      }
    ];

    appRoutes.find((routes => routes.path === 'trial-administrative-information-container')).children = trialAdministrativeInfoRoute;

    appRoutes.find((routes => routes.path === 'trial-details')).children.splice(1, 1);

    appRoutes.find((routes => routes.path === 'trial-details')).children.push(appRoutes.find((routes => routes.path === 'trial-administrative-information-container')));


    const trialSiteDetailRoute: Route[] = [
      {path: '', redirectTo: 'personnel-identification-information', pathMatch: 'full'},
      {
        path: 'personnel-identification-information',
        component: TrialPersonnelInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'personnel-provider-identification-information',
        component: TrialPersonnelProviderInformationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'trial-administrative-dates-information',
        component: TrialAdministrativeDatesInformationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'trial-administrative-feasibility-information',
        component: TrialAdministrativeFeasibilityInformationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'trial-administrative-convention-information',
        component: TrialAdministrativeConventionInformationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'trial-administrative-cec-information',
        component: TrialAdministrativeCecInformationComponent,
        canActivate: [AuthGuard],
      }
    ];

    appRoutes.find((routes => routes.path === 'trial-details')).children.find((routes => routes.path === 'trial-site-details-container')).children = trialSiteDetailRoute;

    const inclusionDetailRoute: Route[] = [
      {path: '', redirectTo: 'inclusion-information', pathMatch: 'full'},
      {
        path: 'calendar', component: CalendarInclusionComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'inclusion-information-arm',
        component: InclusionInformationArmComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'inclusion-information-version-protocol',
        component: InclusionInformationVersionProtocolComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'patient-follow-up-container', component: FollowUpPatientContainerComponent, canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'adverse-effects', pathMatch: 'full'},
          {
            path: 'adverse-effects', component: AdverseEffectComponent, canActivate: [AuthGuard]
          },
          {
            path: 'concomitant-treatment', component: ConcomitantTreatmentComponent, canActivate: [AuthGuard]
          },
          {
            path: 'vital-signs', component: VitalSignsComponent, canActivate: [AuthGuard]
          }
        ]
      },
      {
        path: 'visit-detail', component: VisitDetailComponent, canActivate: [AuthGuard]
      }
    ];

    appRoutes.find((routes => routes.path === 'inclusion-details')).children = inclusionDetailRoute;

    appRoutes.find((routes => routes.path === 'trial-details')).children.push(appRoutes.find((routes => routes.path === 'inclusion-details')));

    const patientInclusionDetailRoute: Route[] = [
      {path: '', redirectTo: 'inclusion-information', pathMatch: 'full'},
      {
        path: 'inclusion-information-arm',
        component: InclusionInformationArmComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'inclusion-information-version-protocol',
        component: InclusionInformationVersionProtocolComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'calendar', component: CalendarInclusionComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'patient-follow-up-container', component: FollowUpPatientContainerComponent, canActivate: [AuthGuard],
        children: [
          {path: '', redirectTo: 'adverse-effects', pathMatch: 'full'},
          {
            path: 'adverse-effects', component: AdverseEffectComponent, canActivate: [AuthGuard]
          },
          {
            path: 'concomitant-treatment', component: ConcomitantTreatmentComponent, canActivate: [AuthGuard]
          },
          {
            path: 'vital-signs', component: VitalSignsComponent, canActivate: [AuthGuard]
          }
        ]
      }
    ];

    appRoutes.find((routes => routes.path === 'patient-inclusion-details')).children = patientInclusionDetailRoute;

    this.router.resetConfig(appRoutes);
  }

  subscribeToDynamicConfigWebSocket(): void {
    this.stompService.subscribe('/topic/dynamic-config/update', (type) => {
      try {
        if (type) {
          this.dynamicConfigService.removeCache(type);
        }
      } catch (e) {
        console.log(e as Error);
      }
    }, subscription => this.configUpdateSubscription = subscription);

  }

  receiveMessage($event) {
    this.message = $event;
    this._cdr.detectChanges();
  }
}

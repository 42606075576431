import {TrialAmendmentService} from './trial-amendment-service';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';

export class TrialProtocol {
  public hhhId: number;
  public trialHhhId: number;
  public version: string;
  public amendmentVersion: string;
  public amendmentVersionDate: Date;
  public protocolVersionDate: Date;
  public ansmAuthorizationDate: Date;
  public favorableOpinionCppDate: Date;
  public coordoPPRCDate: Date;
  public service: string[] = [];
  public serviceList: TrialAmendmentService[] = [];
  public coordoPPRCAgreementDate: Date;
  public cnilHhhId: number;
  public type: 'AMENDMENT' | 'PROTOCOL';
  public description: string;
  public amendmentDescription: string;

  init(obj: any): TrialProtocol {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    const valid = true;
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'version') {
        if (this.type === 'PROTOCOL' && required && Util.isNullOrUndefinedOrEmpty(this.version)) {
          return false;
        }
      } else if (key === 'description') {
        if (this.type === 'PROTOCOL' && required && Util.isNullOrUndefinedOrEmpty(this.description)) {
          return false;
        }
      } else if (key === 'protocolVersionDate') {
        if (this.type === 'PROTOCOL' && required && Util.isNullOrUndefinedOrEmpty(this.protocolVersionDate)) {
          return false;
        }
      } else if (key === 'amendmentVersion') {
        if (this.type === 'AMENDMENT' && required && Util.isNullOrUndefinedOrEmpty(this.amendmentVersion)) {
          return false;
        }
      } else if (key === 'amendmentDescription') {
        if (this.type === 'AMENDMENT' && required && Util.isNullOrUndefinedOrEmpty(this.amendmentDescription)) {
          return false;
        }
      } else if (key === 'amendmentVersionDate') {
        if (this.type === 'AMENDMENT' && required && Util.isNullOrUndefinedOrEmpty(this.amendmentVersionDate)) {
          return false;
        }
      } else if (key === 'cnil') {
        if (required && !this.cnilHhhId) {
          return false;
        }
      } else if (key === 'coordoPPRCAgreementDate') {
        if (this.coordoPPRCDate && required && Util.isNullOrUndefinedOrEmpty(this.coordoPPRCAgreementDate)) {
          return false;
        }
      } else if (key !== 'serviceInformation' && required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return valid;
  }
}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {ConcomitantTreatment} from '../../../../entity/inclusion/concomitant-treatment';
import {Router} from '@angular/router';
import {ConcomitantTreatmentFormComponent} from '../concomitant-treatment-form/concomitant-treatment-form.component';
import {ConcomitantTreatmentService} from '../../../../service/concomitant-treatment.service';
import {PaginatorTableComponent} from '../../../../../shared/component/paginator-table/paginator-table.component';
import {DataService} from '../../../../../shared/services/data-service';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {TrialAppData} from '../../../../trial-app-data';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {AttachedToEntityEnum} from '../../../../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-concomitant-treatment',
  templateUrl: './concomitant-treatment.component.html',
  styleUrls: ['./concomitant-treatment.component.css']
})
export class ConcomitantTreatmentComponent extends PaginatorTableComponent<ConcomitantTreatment> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.ConcomitantTreatment;
  readonly target = DynamicDefinitionEnum.TRIAL_CONCOMITANT_TREATMENT_TABLE;
  config: TableConfig;

  headers: DynamicTableHeader[];
  inclusionHhhId: number;

  constructor(
      private concomitantTreatmentService: ConcomitantTreatmentService,
      private dataService: DataService,
      private cfr: ComponentFactoryResolver,
      private router: Router,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.inclusionHhhId = url.queryParams.inclusionId;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.getInclusionConcomitantTreatment();
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('visit')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('name')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('dosage')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('firstTakingDate')
        .withDateFormatter()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('lastTakingDate')
        .withDateFormatter()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('administered')
        .isTranslated()
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getUpdateTitle(): string {
    return 'MODULE_TRIALS_PATIENTS_CONCOMITANT_TREATMENT_GENERAL_EDIT';
  }

  getDeleteMessage(): string {
    return 'MODULE_TRIALS_PATIENTS_CONCOMITANT_TREATMENT_DIALOG_WARNING_PATIENT_TRIAL_INCLUSION_WILL_BE_DELETED';
  }

  getService(): any {
    return this.concomitantTreatmentService;
  }

  getInclusionConcomitantTreatment() {
    this.concomitantTreatmentService.loadList(this.inclusionHhhId).subscribe(res => {
      this.values = res;
      this.totalNumber = this.values.length;
    });
  }

  displayFormDialogue(concomitantTreatment?: ConcomitantTreatment) {
    const formComponent = ConcomitantTreatmentFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (concomitantTreatment) {
      formComponent.concomitantTreatment = (new ConcomitantTreatment()).init(concomitantTreatment);
      formComponent.formHeader = this.getUpdateTitle();
    }
    formComponent.onAdd(this.updateList(formComponent.concomitantTreatment));
  }

  refreshAfterDelete(entity?: any): void {
    const index = this.values.findIndex((concomitantTreatment: ConcomitantTreatment) => concomitantTreatment.hhhId === entity.hhhId);
    this.values.splice(index, 1);
  }

  updateList(newEntity: ConcomitantTreatment) {
    return (c: ConcomitantTreatment) => {
      const index = this.values.findIndex(e => e.hhhId === newEntity.hhhId);
      if (index !== -1) {
        this.values[index] = newEntity;
      }
    };
  }
}

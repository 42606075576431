import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {TrialCounterpart} from '../../../entity/trial-additionnal-costs/trial-counterpart';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {Router} from '@angular/router';
import {TrialCounterpartService} from '../../../service/trial-counterpart.service';
import {SelectItem} from 'primeng/api';
import {NgForm} from '@angular/forms';
import {Data} from '../../../../shared/entity/data';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-trial-counter-part-form',
  templateUrl: './trial-counterpart-form.component.html',
  styleUrls: ['./trial-counterpart-form.component.css']
})
export class TrialCounterpartFormComponent extends FormComponent<TrialCounterpart> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_COUNTERPART_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('trialCounterPartReferenceForm') trialCounterPartReferenceForm: NgForm;


  trialCounterPart: TrialCounterpart;
  displayCounterPartRefDialog: boolean;
  submitted: boolean;
  status: SelectItem[];
  noYesOptions = Data.yesOrNoOrNeither;
  public accountNumberLabel: string;
  yesOrNoOptions = Data.yesOrNo;

  constructor(private translateService: InnohealthTranslateService,
              private trialCounterPartService: TrialCounterpartService,
              private sharedService: SharedService,
              private router: Router,
              private dynamicConfigService: DynamicConfigService) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(TrialCounterpartFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.status = [
      {label: '', value: null},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE'), value: true},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE'), value: false}];
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {});
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  onSubmit() {
    this.submitted = true;
    if (!this.trialCounterPart.isValid(this.config) || this.trialCounterPartReferenceForm.invalid) {
      return;
    } else {
      this.trialCounterPartService.editTrialCounterPart(this.trialCounterPart).subscribe(total => {
          this.trialCounterPart.total = total;
          this.sharedService.showSuccess();
          this.callback(this.trialCounterPart);
        }, error => {
          console.error('An error occurred while saving counter part reference' + error);
        }
      );
      this.display = false;
    }
  }

  navigateToCounterPart() {
    this.router.navigate(['/additional-costs-container/counter-parts']).then();
  }
}

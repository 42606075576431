import {TrialCoInvestigator} from './TrialCoInvestigator';
import {PromoterType} from '../../provider/models/promoter-type';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {DataCommentCount} from '../../data-comment/entity/data-comment-count';

export class TrialHumanResource extends DataCommentCount {
  hhhId: number;
  trialHhhId: number;
  arc1HhhId: number;
  arc2HhhId: number[] = [];
  arcReferentHhhId: number[] = [];
  dataManager1HhhId: number;
  dataManager2HhhId: number[] = [];
  amrHhhId: number;
  ircHhhId: number;
  ircBackupHhhIds: number[] = [];
  medicalResearchAssistantHhhId: number[] = [];
  ircsHhhId: number[] = [];
  sampleManagerHhhId: number[] = [];
  samplingManagersHhhId: number;
  arcManagerHhhId: number;
  arcManager2HhhId: number[];
  researchClinicTec1HhhId: number[];
  referentDoctorHhhId: number;
  tec1HhhId: number;
  tec2HhhId: number[] = [];
  investigatorSpecialityHhhId: number;
  specialityOtherValue: string;
  principalInvestigatorHhhId: number;
  coordinator: boolean;
  sampleManagement: boolean;
  vigilanceResponsibleHhhId: number;
  qualityResponsibleHhhId: number;
  methodologistHhhId: number;
  projectManagerHhhId: number[] = [];
  coInvestigatorsHhhId: number[] = [];
  assistantsHhhId: number[] = [];
  promoterArc1: string;
  promoterArc2: string;
  arcTelephone: string;
  nationalCoordinator: string;
  referentPharmacistHhhIds: number[] = [];
  referentPharmacistBackupHhhIds: number[] = [];
  pharmacoVigilanceHhhId: number;
  referentLaboratoryHhhId: number;
  promoterHhhId: number;
  promoterNaChecked:boolean;
  promoterTypeHhhId: number;
  siteName: string;

  trialSiteHhhId: number;
  promoter2HhhId: number;
  providerHhhId: number;
  provider2HhhId: number;
  promoterType: PromoterType;
  multiSite: boolean;
  numberOfCenterAllocatedByPromoter: string;
  promoterArc1confidentialityAgreementSigned: boolean;
  promoterArc2confidentialityAgreementSigned: boolean;
  promoterProjectManager: string;
  poleHhhId: number;
  trialCommitteesHhhIds: number[] = [];
  arcCoordinatorHhhId: number;
  monitorArc: string;
  monitorArcMail: string;
  monitorArcPhone: string;
  monitorArcBackup: string;
  monitorArcBackupMail: string;
  monitorArcBackupPhone: string;
  trialCoInvestigatorDtos: TrialCoInvestigator[] = [];

  locationHhhId: number;
  projectManagerFrontDeskHHhIds: number[] = [];
  contractManagersHhhIds: number[] = [];
  invoicingManagersHhhIds: number[] = [];
  organHhhIds: number[];
  promoterProjectManagerMail: string;
  promoterProjectManagerPhone: string;
  coordinatingInvestigator: string;
  coordinatingInvestigatorMail: string;
  coordinatingInvestigatorPhone: string;
  genericMail: string;
  promoterProjectManagerDropDownHhhId: number;

  init(obj: any): TrialHumanResource {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  isValid(config: FormConfig, investigatorSpecialityOther: boolean, isTrialCoInvestigatorsEnabled: boolean, displayTrialCoInvestigatorsDtos: boolean): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'promoter') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.promoterHhhId)) {
          return false;
        }
      } else if (key === 'promoterType') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.promoterTypeHhhId)) {
          return false;
        }
      } else if (key === 'promoterProjectManagerDropDown') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.promoterProjectManagerDropDownHhhId)) {
          return false;
        }
      } else if (key === 'promoter2') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.promoter2HhhId)) {
          return false;
        }
      } else if (key === 'trialCommittee') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.trialCommitteesHhhIds)) {
          return false;
        }
      } else if (key === 'organs') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.organHhhIds)) {
          return false;
        }
      } else if (key === 'arcCoordinator') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.arcCoordinatorHhhId)) {
          return false;
        }
      } else if (key === 'provider') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.providerHhhId)) {
          return false;
        }
      } else if (key === 'numberOfCenterAllocatedByPromoter') {
        if (!this.multiSite && required && Util.isNullOrUndefinedOrEmpty(this.numberOfCenterAllocatedByPromoter)) {
          return false;
        }
      } else if (key === 'trialPole') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.poleHhhId)) {
          return false;
        }
      } else if (key === 'investigatorSpeciality') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.investigatorSpecialityHhhId)) {
          return false;
        }
      } else if (key === 'specialityOtherValue') {
        if (investigatorSpecialityOther && required && Util.isNullOrUndefinedOrEmpty(this.specialityOtherValue)) {
          return false;
        }
      } else if (key === 'location') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.locationHhhId)) {
          return false;
        }
      } else if (key === 'principalInvestigators') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.principalInvestigatorHhhId)) {
          return false;
        }
      } else if (key === 'isCoordinator') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.coordinator)) {
          return false;
        }
      } else if (key === 'nationalCoordinator') {
        if (!this.coordinator && required && Util.isNullOrUndefinedOrEmpty(this.nationalCoordinator)) {
          return false;
        }
      } else if (key === 'tec1') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.tec1HhhId)) {
          return false;
        }
      } else if (key === 'tec2') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.tec1HhhId)) {
          return false;
        }
      } else if (key === 'vigilanceManager') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.vigilanceResponsibleHhhId)) {
          return false;
        }
      } else if (key === 'qualityResponsible') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.qualityResponsibleHhhId)) {
          return false;
        }
      } else if (key === 'arc1') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.arc1HhhId)) {
          return false;
        }
      } else if (key === 'arc2') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.arc2HhhId)) {
          return false;
        }
      } else if (key === 'promoterArc1confidentialityAgreementSigned') {
        if ((Util.isNullOrUndefinedOrEmpty(this.promoterArc1) && !Util.isNullOrUndefinedOrEmpty(this.promoterArc1confidentialityAgreementSigned)) || (required && Util.isNullOrUndefinedOrEmpty(this.promoterArc1confidentialityAgreementSigned))) {
          return false;
        }
      } else if (key === 'promoterArc2confidentialityAgreementSigned') {
        if ((Util.isNullOrUndefinedOrEmpty(this.promoterArc2) && !Util.isNullOrUndefinedOrEmpty(this.promoterArc2confidentialityAgreementSigned)) || (required && Util.isNullOrUndefinedOrEmpty(this.promoterArc2confidentialityAgreementSigned))) {
          return false;
        }
      } else if (key === 'dataManager1') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.dataManager1HhhId)) {
          return false;
        }
      } else if (key === 'dataManager2') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.dataManager2HhhId)) {
          return false;
        }
      } else if (key === 'arcManager') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.arcManagerHhhId)) {
          return false;
        }
      } else if (key === 'referentDoctor') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.referentDoctorHhhId)) {
          return false;
        }
      } else if (key === 'coInvestigators' && !isTrialCoInvestigatorsEnabled) {
        if (required && Util.isNullOrUndefinedOrEmpty(this.coInvestigatorsHhhId)) {
          return false;
        }
      } else if (key === 'coInvestigators' && isTrialCoInvestigatorsEnabled) {
        if (required && (Util.isNullOrUndefinedOrEmpty(this.trialCoInvestigatorDtos) || this.containsEmptyOrUndefinedValues(this.trialCoInvestigatorDtos.map(c => c.coInvestigatorHhhId)))) {
          return false;
        }
      } else if (key === 'delegationOfTasks' && isTrialCoInvestigatorsEnabled) {
        if (required && displayTrialCoInvestigatorsDtos && (Util.isNullOrUndefinedOrEmpty(this.trialCoInvestigatorDtos) || this.containsEmptyOrUndefinedValues(this.trialCoInvestigatorDtos.map(c => c.delegationOfTasks && c.coInvestigatorHhhId)))) {
          return false;
        }
      } else if (key === 'delegationOfTasks' && !isTrialCoInvestigatorsEnabled) {
        continue;
      } else if (key === 'endDate' && isTrialCoInvestigatorsEnabled) {
        if (required && displayTrialCoInvestigatorsDtos && (Util.isNullOrUndefinedOrEmpty(this.trialCoInvestigatorDtos) || this.containsEmptyOrUndefinedValues(this.trialCoInvestigatorDtos.map(c => c.endDate && c.coInvestigatorHhhId)))) {
          return false;
        }
      } else if (key === 'endDate' && !isTrialCoInvestigatorsEnabled) {
        continue;
      } else if (key === 'amr') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.amrHhhId)) {
          return false;
        }
      } else if (key === 'irc') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.ircHhhId)) {
          return false;
        }
      } else if (key === 'ircBackup') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.ircBackupHhhIds)) {
          return false;
        }
      } else if (key === 'gp') {
        if (this.sampleManagement && required && Util.isNullOrUndefinedOrEmpty(this.samplingManagersHhhId)) {
          return false;
        }
      } else if (key === 'projectManager') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.projectManagerHhhId)) {
          return false;
        }
      } else if (key === 'assistant') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.assistantsHhhId)) {
          return false;
        }
      } else if (key === 'referentPharmacist') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.referentPharmacistHhhIds)) {
          return false;
        }
      } else if (key === 'referentPharmacistBackups') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.referentPharmacistBackupHhhIds)) {
          return false;
        }
      } else if (key === 'pharmacoVigilance') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.pharmacoVigilanceHhhId)) {
          return false;
        }
      } else if (key === 'principalInvestigators') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.principalInvestigatorHhhId)) {
          return false;
        }
      } else if (key === 'arc1') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.arc1HhhId)) {
          return false;
        }
      } else if (key === 'referentLaboratory') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.referentLaboratoryHhhId)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }

  containsEmptyOrUndefinedValues(arr: any[]): boolean {
    return arr.includes(undefined) || arr.includes(null);
  }

}

export class TrialGlobalFilter {
  protocolNumber: string;
  state: number;
  promoter: number;
  phase: number;
  principalInvestigator: number;
  organs: string;
  organsOnly: string;
  organFamiliesOnly: string;
  treatmentLine: number;
  pv: string;
}

import {EventEmitter, Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ClonedVisit} from '../entity/inclusion/cloned-visit';
import {Observable, Subject, throwError} from 'rxjs';
import {VisitForUpdate} from '../entity/inclusion/visit-for-update';
import {IsStateSwitchable} from '../entity/inclusion/is-state-switchable';
import {Deletable} from '../../modals/delete-confirmation/deletable';
import {TOKEN} from '../../login/services/authentication.service';
import {Visit} from '../entity/visit';
import {LabelValue} from '../../shared/entity/label-value';
import {Value} from '../../shared/entity/value';
import {catchError, map} from 'rxjs/operators';
import {DetailedVisit} from '../entity/inclusion/detailed-visit';
import {SwitchState} from '../entity/inclusion/switch-state';
import {SelectItem} from 'primeng/components/common/api';

@Injectable({
  providedIn: 'root'
})
export class VisitService implements Deletable {
  get visitListNotToBeShifted(): Value<number>[] {
    return this._visitListNotToBeShifted;
  }

  public host = new Properties().host + '/visit';
  private _visitListNotToBeShifted: Value<number>[];
  private _visitListToBeUpdated: Value<number>[];
  public dataItemHost = new Properties().host + '/data/items';

  public changesSaved: EventEmitter<void> = new EventEmitter<void>();

  constructor(private httpClient: HttpClient) {
  }

  cloneVisit(clonedVisit: ClonedVisit): Observable<ClonedVisit[]> {
    return this.httpClient.post<ClonedVisit[]>(this.host + '/clone', clonedVisit);
  }

  getVisit(hhhId: number): Observable<VisitForUpdate> {
    return this.httpClient.get<VisitForUpdate>(this.host + '/load/' + hhhId)
      .pipe(
        map(res => {
            return (new VisitForUpdate()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  getVisitDetails(hhhId: number): Observable<Visit> {
    return this.httpClient.get<Visit>(this.host + '/details/' + hhhId);
  }

  addVisit(detailedVisit: DetailedVisit): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', detailedVisit);
  }

  updateVisit(visit: VisitForUpdate) {
    return this.httpClient.put(this.host + '/update', visit);
  }

  downloadVisitDetails(visitHhhId: number, trialHhhId: number, inclusionHhhId: number) {
    window.location.assign(this.host + '/download/' + visitHhhId + '/' + trialHhhId + '/' + inclusionHhhId + '/' + localStorage.getItem(TOKEN));
  }

  isDeletable(hhhId: number) {
    return this.httpClient.get(this.host + '/deletable/' + hhhId);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  updateVisitStateAfterChangeActs(visitHhhId: number): Observable<number> {
    return this.httpClient.get<number>(this.host + '/visit-state/act-change/' + visitHhhId);
  }

  updateExpectedVisitDate(hhhId: number, actualDate: Date): Observable<boolean> {
    return this.httpClient.put<boolean>(this.host + '/update-date/' + hhhId , actualDate);
  }

  deleteExpectedVisitDate(hhhId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(this.host + '/delete-date/' + hhhId);
  }

  updateVisitRegistrationDate(hhhId: number, actualDate: Date) {
    actualDate.setHours(12, 0 , 0, 0);
    return this.httpClient.put<void>(this.host + '/update-registration-date/' + hhhId, actualDate);
  }

  deleteVisitRegistrationDate(hhhId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(this.host + '/delete-registration-date/' + hhhId);
  }

  updateExpectedShiftedVisitsDate(visitHhhId: number) {
    if (!this._visitListNotToBeShifted) {
      this._visitListNotToBeShifted = [];
    }
    return this.httpClient.post(this.host + '/shift-visits/' + visitHhhId, this._visitListNotToBeShifted);
  }

  isVisitStateSwitchable(switchState: SwitchState) {
    return this.httpClient.post<IsStateSwitchable>(this.host + '/switch-status/is-switchable', switchState);
  }

  generateInvoice(visitHhhId: number) {
    return this.httpClient.get(this.host + '/create-invoice-from-invoiced-visit/' + visitHhhId);
  }

  updateVisitAndActsState(switchState: SwitchState): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.httpClient.post<string>(this.host + '/switch-state' , switchState, requestOptions);
  }

  updateVisitState(visitHhhId: number, stateName: string, stateHhhIdFrom, stateHhhIdTo: number): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.httpClient.get<string>(this.host + '/' + visitHhhId + '/' + stateName + '/' + stateHhhIdFrom + '/' + stateHhhIdTo, requestOptions);
  }


  initVisitListNotToBeShifted() {
    this._visitListNotToBeShifted = [];
  }

  addVisitToVisitListNotToBeShifted(visitHhhId: number) {
    this._visitListNotToBeShifted.push(new Value<number>(visitHhhId));
  }

  removeVisitFromVisitListNotToBeShifted(visitHhhId: number) {
    this._visitListNotToBeShifted = this._visitListNotToBeShifted.filter(hhhId => hhhId.value !== visitHhhId);
  }

  get visitListToBeUpdated(): Value<number>[] {
    return this._visitListToBeUpdated;
  }

  initVisitListToBeUpdated() {
    this._visitListToBeUpdated = [];
  }

  addVisitToVisitListToBeUpdated(visitHhhId: number) {
    this._visitListToBeUpdated.push(new Value(visitHhhId));
  }

  removeVisitFromVisitListToBeUpdated(visitHhhId: number) {
    this._visitListToBeUpdated = this._visitListToBeUpdated.filter(hhhId => hhhId.value !== visitHhhId);
  }

  getVisitsByVisitHhhId(): Observable<Visit[]> {
    return this.httpClient.post<Visit[]>(this.host + '/load-list', this.visitListToBeUpdated);
  }

  getVisitNameOptions(invoiceId: number, fixedCostState: string, isFixedCosts: boolean): Observable<SelectItem[]> {
    const fcs =  fixedCostState === 'pending' ? 1 :  fixedCostState === 'all' || fixedCostState === 'invoiced' ? 2 : 0;
    return this.httpClient.get<SelectItem[]>(this.dataItemHost + '/invoice-visit|' + invoiceId + '|' + fcs + '|' + isFixedCosts);
  }

  getVisitStateName(stateId: number): string {
    switch (stateId) {
      case 2: {
        return 'Facturée partiellement';
      }
      case 3: {
        return 'Facturée';
      }
      case 4: {
        return 'Payée partiellement';
      }
      case 5: {
        return 'Payée';
      }
      case 10: {
        return 'Visite saisie incomplète';
      }
      case 11: {
        return 'Visite à programmer';
      }
      case 12: {
        return 'Visite programmée';
      }
      case 13: {
        return 'Visite réalisée à saisir';
      }
      case 14: {
        return 'Visite saisie';
      }
      case 15: {
        return 'Visite monitorée';
      }
      case 16: {
        return 'Visite facturable';
      }
      case 18: {
        return 'Visite non réalisée';
      }
      case 19: {
        return 'Non Applicable';
      }
    }
  }

  private shiftingUpdateTriggered = new Subject<void>();
  shiftingCompleted = this.shiftingUpdateTriggered.asObservable();


  notifyUpdateCompleted() {
    this.shiftingUpdateTriggered.next();
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

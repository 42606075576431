import {NgModule} from '@angular/core';
import {PharmacyDispensationComponent} from './pharmacy-dispensation/pharmacy-dispensation.component';
import {PharmacyStockComponent} from './pharmacy-stock/pharmacy-stock.component';
import {PharmacyContainerComponent} from './pharmacy-container/pharmacy-container.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    DynamicConfigModule,
  ],
  declarations: [
    PharmacyContainerComponent,
    PharmacyStockComponent,
    PharmacyDispensationComponent
  ],
})
export class PharmacyModule {}

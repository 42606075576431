import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {Site} from '../models/site';
import {IsSiteDeletable} from '../../IsSiteDeletable';
import {UpdateSite} from '../models/update-site';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  public host = new Properties().host + '/site';

  constructor(private httpClient: HttpClient) {}

  loadList(): Observable<Site []> {
    return this.httpClient.get<Site []>(this.host + '/load-list');
  }

  addSite(name: string, establishmentHhhId: number): Observable<Site> {
    return this.httpClient.post<any>(this.host + '/add', {name, establishmentHhhId});
  }

  updateSite(site: Site): Observable<Site> {
    const updateSite = new UpdateSite(site.hhhId, site.name, site.establishment.value);
    return this.httpClient.put<Site>(this.host + '/update', updateSite);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  isDeletable(hhhId: number): Observable<IsSiteDeletable> {
    return this.httpClient.get<IsSiteDeletable>(this.host + '/deletable/' + hhhId);
  }

}

import {Component} from '@angular/core';

@Component({
  selector: 'ih-trial-icon',
  templateUrl: './trial-icon.component.html',
  styleUrls: ['./trial-icon.component.css']
})
export class TrialIconComponent {

  constructor() {}
}

import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {EstablishmentsTrialHhhIds} from '../../../models/establishments-trial-hhh-ids';
import {TrialService} from '../../../services/trial.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';

@Component({
  selector: 'ih-send-trial-to-establishment-form',
  templateUrl: './send-trial-to-establishment-form.component.html',
  styleUrls: ['./send-trial-to-establishment-form.component.css']
})
export class SendTrialToEstablishmentFormComponent extends FormComponent<number[]> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_SEND_TRIAL_TO_ESTABLISHMENT_FORM;
  config: FormConfig = new FormConfig();

  establishmentsTrialHhhIds: EstablishmentsTrialHhhIds = new EstablishmentsTrialHhhIds();

  constructor(
    private trialService: TrialService,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(SendTrialToEstablishmentFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (!this.establishmentsTrialHhhIds.isValid(this.config)) {
      this.display = false;
      this.sharedService.showFailure();
      return;
    }
    this.trialService.cloneTrialToEstablishments(this.establishmentsTrialHhhIds).subscribe(res => {
        this.display = false;
        this.sharedService.showSuccess();
      }, error => {
      this.display = false;
        this.sharedService.showFailure();
        console.error(error);
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
}

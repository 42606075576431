import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SiteService} from '../services/site.service';
import {Site} from '../models/site';
import {DataService} from '../../shared/services/data-service';
import {SharedService} from '../../shared/services/shared.service';
import {SiteFormComponent} from '../site-form/site-form.component';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {Permission} from '../../helpers/permission';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {AdditionalCostsAppData} from '../../additional-costs/additional-costs-app-data';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-site',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.css']
})
export class SiteListComponent extends PaginatorTableComponent<Site> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.Site;
  target = DynamicDefinitionEnum.SITE_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];
  parentEstablishment = false;

  constructor(
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private sharedService: SharedService,
    private siteService: SiteService,
    private dataService: DataService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.getList();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.parentEstablishment = this.dynamicConfigService.parent;
      this.initTableConfig();
    });
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, AdditionalCostsAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.parentEstablishment)
      .field('establishment')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter('currentEstablismentName')
      .type('establishment-children')
      .sortable()
      .displayContent(((data: Site) => {
        return data.currentEstablismentName ? data.currentEstablismentName : '';
      }))
      .build()
    );
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('name')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build()
    );
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('totalContactableMemberNames')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build()
    );
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('totalContactableMemberFunctions')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build()
    );
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/site/site-list/site-list.component';
  }

  getAddTitle(): string {
    return 'MODULE_SITES_GENERAL_ADD_NEW';
  }

  getUpdateTitle(): string {
    return 'MODULE_SITES_GENERAL_EDIT';
  }

  getDeleteMessage(): string {
    return 'MODULE_SITES_DIALOG_WARNING_SITE_WILL_BE_DELETED';
  }

  getService(): any {
    return this.siteService;
  }

  getList() {
    this.siteService.loadList().subscribe((sites: Site[]) => {
      this.values = sites;
      this.totalNumber = this.values.length;
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while loading sites. ' + error);
    });
  }

  displayFormDialogue(site?: Site) {
    const formComponent = SiteFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (site != null) {
      // formComponent.site = Object.assign({}, site);
      formComponent.site = (new Site()).init(site);
    }
    formComponent.formHeader = (site != null) ? this.getUpdateTitle() : this.getAddTitle();
    formComponent.onAdd((newSite: Site) => {
      const index = this.values.indexOf(site);
      if (index !== -1) {
        this.values[index].name = newSite.name;
        this.values[index].establishment = newSite.establishment;
      } else {
        this.values.push(newSite);
      }
      this.values.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    });
  }

  refreshAfterDelete(entity?: any): void {
    const index = this.values.findIndex((site: Site) => site.hhhId === entity.hhhId);
    this.sharedService.setInformationInLocalStorage('Sites', 'Supprimer', entity.name);
    this.values.splice(index, 1);
  }

  getContacts(site: Site) {
    this.dataService
      .setContactData(
        site.name,
        'Site', '/assets/images/site.png',
        site.hhhId,
        site.publicCommentCount
      );
    this.router.navigate(['/contact/' + site.hhhId + '/Site']).then();
  }

  hasWritePermission() {
    return localStorage.getItem('Site_READ_WRITE') === Permission.READ_WRITE;
  }

  getPageDisplayed(event) {}
}

import * as _ from 'lodash';
import {isNullOrUndefined} from 'util';
import {CalendarCheckbox} from '../trial/models/calendar-checkbox';
import {LabelValue} from '../shared/entity/label-value';
import {SelectItem} from 'primeng/components/common/api';

export class Util {
  public static isStringNullOrEmpty(s: string): boolean {
    return s == null || s === undefined || s.length === 0;
  }

  public static isNullOrUndefined(s: any): boolean {
    return s == null || s === undefined;
  }

  public static isNullOrUndefinedOrEmpty(s: any): boolean {
    if (typeof s === 'string') {
      return this.isStringNullOrEmpty(s);
    }
    if (s instanceof Array) {
      return s.length === 0;
    }
    if (s instanceof CalendarCheckbox && isNullOrUndefined(s.date)) {
      return true;
    }
    return isNullOrUndefined(s);
  }


  public static multiply(val1: number, val2: number) {
    const commonMultiplier = 1000000;
    val1 *= commonMultiplier;
    val2 *= commonMultiplier;
    return (val1 * val2) / (commonMultiplier * commonMultiplier);
  }

  public static clone(o: any): any {
    return _.cloneDeep(o);
  }

  public static equal(o1: any, o2: any): boolean {
    return _.isEqual(o1, o2);
  }

  public static convertStringToDate(dateString: string): Date {
    const dateParts = dateString.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const year = parseInt(dateParts[2], 10);

    return new Date(year, month, day);
  }

  public static convertTimestampToString(timestamp: Date): string {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  public static downloadFile(blob: Blob, fileName: string): void {
    const newBlob = new Blob([blob], {type: 'blob' as 'json'});
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
  }

  public static sortItems(items: LabelValue[]): LabelValue[] {
    if (this.isNullOrUndefinedOrEmpty(items)) {
      return [];
    }
    const sortedArray = items.sort((a, b) => (a.label || '').localeCompare((b.label || '')));
    const index = sortedArray.findIndex(item => (item.label || '').toLowerCase() === 'autre');
    if (index !== -1) {
      const otherItem = sortedArray.splice(index, 1)[0];
      sortedArray.push(otherItem);
    }
    return sortedArray;
  }

  public static parseItem(item: LabelValue, delimiter: string): { mainTitle: string, subtitle: string } {
    if (!item) {
      return {mainTitle: '', subtitle: ''};
    }
    if (this.isNullOrUndefinedOrEmpty(delimiter)) {
      return {
        mainTitle: item.label || '',
        subtitle: ''
      };
    }
    const parts = (item.label || '').split(delimiter);
    return {
      mainTitle: parts[0] || '',
      subtitle: parts[1] || ''
    };
  }

  public static areDateEquals(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  public static isDateGreaterThan(date1: Date, date2: Date): boolean {
    if (date1.getFullYear() > date2.getFullYear()) return true;
    if (date1.getFullYear() < date2.getFullYear()) return false;

    if (date1.getMonth() > date2.getMonth()) return true;
    if (date1.getMonth() < date2.getMonth()) return false;

    if (date1.getDate() > date2.getDate()) return true;
    return false;
  }

}

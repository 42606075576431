import {InclusionEstablishmentDepartment} from './inclusion-establishment-department';
import {RateInclusionPerTrial} from './rate-inclusion-per-trial';
import {InclusionDate} from './inclusion-date';

export class ReportInclusionsTotalByMonth {
  public inclusionEstablishmentDepartment: InclusionEstablishmentDepartment[] = [];
  public rateInclusionPerTrial: RateInclusionPerTrial[] = [];
  public inclusionsDate: InclusionDate[] = [];
  public inclusionsNumber: number;
}

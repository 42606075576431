import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {MenuItem} from 'primeng/api';
import {INVOICE_READ_WRITE, STATIC_INVOICE_READ_WRITE} from '../../login/services/authentication.service';
import {Permission} from '../../helpers/permission';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-invoice-container',
  templateUrl: './invoice-container.component.html',
  styleUrls: ['./invoice-container.component.css']
})
export class InvoiceContainerComponent implements OnInit {
  preInvoiceEnabled = true;
  items: MenuItem[] = [];

  constructor(
      private translate: TranslateService,
      private translateService: InnohealthTranslateService,
      private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.preInvoiceEnabled = this.dynamicConfigService.getProperty(PropertyEnum.preInvoiceEnabled);
      this.initializeMenuItem();
    });
  }

  private initializeMenuItem() {
    if (this.preInvoiceEnabled) {
      if (this.isInvoiceListDisplayed()) {
        this.items.push(
          {
            label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_PRE_INVOICES'),
            routerLink: ['/invoice-container/invoice-list']
          }
        );
      }

      if (this.isStaticInvoiceListDisplayed()) {
        this.items.push(
          {
            label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_INVOICES'),
            routerLink: ['/invoice-container/static-invoice-list']
          }
        );
      }
    } else {
      if (this.isInvoiceListDisplayed()) {
        this.items.push(
          {
            label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_INVOICES'),
            routerLink: ['/invoice-container/invoice-list']
          }
        );
      }
    }
  }

  isInvoiceListDisplayed() {
    return localStorage.getItem(INVOICE_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isStaticInvoiceListDisplayed() {
    return localStorage.getItem(STATIC_INVOICE_READ_WRITE) !== Permission.NO_RIGHT;
  }


}

import {LabelValue} from '../../shared/entity/label-value';

export class Consent {
  hhhId: number;
  documentType: LabelValue;
  documentVersion: string;
  documentVersionDate: Date;
  trialProtocolHhhId: number;
  documentName: string;
  trialProtocol: LabelValue;
  trialAmendment: LabelValue;

  init(obj: any): Consent {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  displayName(): string {
    const parts = [
      this.documentType && this.documentType.label ? this.documentType.label : '',
      this.documentVersion || '',
      this.documentName || ''
    ];
    return parts.filter(part => typeof part === 'string' && part.length > 0).join(' - ');
  }

}

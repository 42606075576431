import {Provider} from './provider';
import {ProviderExtraInfos} from './provider-extra-infos';
import {Util} from '../../helpers/util';
import {FormConfig} from '../../dynamic-config/entity/form-config';

export class ProviderBasicInformation {
  public hhhId: number;
  public name: string;
  public providerTypeHhhId: number;
  public promoterTypeHhhId: number;
  public providerTypeValue: string;
  public providerOtherTypeValue: string;

  public providerExtraInfos = new ProviderExtraInfos();

  fromProvider(provider: Provider): ProviderBasicInformation {
    this.hhhId = provider.hhhId;
    this.name = provider.name;
    this.providerTypeHhhId = provider.providerType ? provider.providerType.hhhId : null;
    this.promoterTypeHhhId = provider.promoterType ? provider.promoterType.hhhId : null;
    this.providerTypeValue = provider.providerTypeValue;
    this.providerOtherTypeValue = provider.providerTypeOtherValue;
    return this;
  }

  isValid(config: FormConfig, promoteurOptionSelected?: boolean, otherOptionSelected?: boolean): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'providerType') {
        if (required && !(this.providerTypeHhhId)) {
          return false;
        }
      } else if (key === 'promoterType') {
        if (required && promoteurOptionSelected && !(this.promoterTypeHhhId)) {
          return false;
        }
      } else if (key === 'providerOtherType') {
        if (required && otherOptionSelected && Util.isNullOrUndefinedOrEmpty(this.providerOtherTypeValue)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {TrialAdministrativeCecInformation} from '../models/trial-administrative-cec-information';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrialAdministrativeCecService {

  public host = new Properties().host + '/trial-administrative-cec';

  constructor(private httpClient: HttpClient) {
  }

  getAdministrativeCecInformation(trialId: number): Observable<TrialAdministrativeCecInformation> {
    return this.httpClient.get<TrialAdministrativeCecInformation>(this.host + '/load/' + trialId)
      .pipe(
        map(res => {
            return (new TrialAdministrativeCecInformation()).init(res);
          }
        ),
        catchError(this.handleError));;
  }

  updateAdministrativeCecInformation(trialAdministrativeCecInformation: TrialAdministrativeCecInformation): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update', trialAdministrativeCecInformation);
  }

  deleteAdministrativeTrialFollowUp(hhhId: number): Observable<void> {
    return this.httpClient.get<void>(this.host + '/trial-follow-up/delete/' + hhhId);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

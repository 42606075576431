import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {TrialProtocol} from '../../../../models/trialProtocol';
import {FormComponent} from '../../../../../shared/component/form/form.component';
import {MinimizedTrialProtocol} from '../../../../models/minimized-trial-protocol';
import {FR} from '../../../../../shared/entity/calendar-language';
import {MessageService, SelectItem} from 'primeng/api';
import {SharedService} from '../../../../../shared/services/shared.service';
import {Router} from '@angular/router';
import {TrialProtocolService} from '../../../../services/trial-protocol.service';
import {TrialAmendmentService} from '../../../../models/trial-amendment-service';
import {isNullOrUndefined} from 'util';
import {TrialObservablesService} from '../../../../services/trial-observables.service';
import {TrialService} from '../../../../services/trial.service';
import {CanDeactivateService} from '../../../../../shared/services/can-deactivate.service';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../dynamic-config/entity/form-config';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../../trial-app-data';

@Component({
  selector: 'ih-amendment-protocol-form',
  templateUrl: './amendment-protocol-form.component.html',
  styleUrls: ['./amendment-protocol-form.component.css']
})
export class AmendmentProtocolFormComponent extends FormComponent<MinimizedTrialProtocol> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_AMENDMENT_PROTOCOL_FORM;
  config: FormConfig = new FormConfig();

  trialId: number;

  trialProtocols: MinimizedTrialProtocol[];

  trialProtocol: MinimizedTrialProtocol;
  amendment: TrialProtocol = new TrialProtocol();

  formHeader: string;
  isUnique: boolean;

  locale = FR;
  emptyService: boolean;
  typeOptions: Array<SelectItem> = [{
    label: 'Protocole',
    value: 'PROTOCOL'
  }, {
    label: 'Amendement',
    value: 'AMENDMENT'
  }];

  callback: (trialAmendment: MinimizedTrialProtocol) => void;

  constructor(
    private sharedService: SharedService,
    private amendmentService: TrialProtocolService,
    private observablesService: TrialObservablesService,
    public trialService: TrialService,
    private canDeactivateService: CanDeactivateService,
    private router: Router,
    private dynamicConfigService: DynamicConfigService
  ) {
    super();
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(AmendmentProtocolFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormConfig();
    this.isUnique = true;
    if (this.trialProtocol) {
      this.getTrialAmendment();
    } else {
      this.amendment.type = 'PROTOCOL';
      this.amendment.serviceList.push(new TrialAmendmentService());
      this.amendment.trialHhhId = this.trialId;
    }
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getTrialAmendment() {
    this.amendmentService.getTrialProtocolById(this.trialProtocol.hhhId).subscribe(
      res => {
        this.amendment = res;
        if (this.amendment.serviceList.length === 0) {
          this.amendment.serviceList.push(new TrialAmendmentService());
        }
        this.amendment.protocolVersionDate = this.affectDate(this.amendment.protocolVersionDate);
        this.amendment.ansmAuthorizationDate = this.affectDate(this.amendment.ansmAuthorizationDate);
        this.amendment.coordoPPRCAgreementDate = this.affectDate(this.amendment.coordoPPRCAgreementDate);
        this.amendment.coordoPPRCDate = this.affectDate(this.amendment.coordoPPRCDate);
        this.amendment.favorableOpinionCppDate = this.affectDate(this.amendment.favorableOpinionCppDate);
      }, error => {
        console.log('An error occurred while loading amendment');
      }
    );
  }

  saveTrialAmendment() {
    this.amendment.trialHhhId = this.trialId;
    this.submitted = true;
    if (!this.amendment.isValid(this.config)) {
      return;
    }
    if (this.amendment.type === 'AMENDMENT' && (this.isNullOrEmptyOrUndefined(this.amendment.amendmentVersion)
      || this.isNullOrEmptyOrUndefined(this.amendment.amendmentVersionDate))) {
      return;
    }
    if (this.amendment.type === 'PROTOCOL' && (this.isNullOrEmptyOrUndefined(this.amendment.protocolVersionDate)
      || this.isNullOrEmptyOrUndefined(this.amendment.version))) {
      return;
    }
    if (this.amendment.type === 'AMENDMENT') {
      this.amendment.version = null;
      this.amendment.protocolVersionDate = null;
      this.amendment.description = null;
    } else {
      this.amendment.amendmentVersion = null;
      this.amendment.amendmentVersionDate = null;
      this.amendment.amendmentDescription = null;
    }
    if (!this.amendment.hhhId) {
      for (const trialAmendment of this.trialProtocols) {
        if (this.amendment.version && this.amendment.version === trialAmendment.version) {
          this.isUnique = false;
          return;
        }
      }
      this.amendmentService.addTrialProtocol(this.amendment).subscribe(res => {
        this.callback(res);
        this.observablesService.onProtocolSave.emit();
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
      });
    } else {
      this.amendmentService.editTrialProtocol(this.amendment).subscribe(res => {
        this.callback(res);
        this.sharedService.showSuccess();
      }, error1 => {
        this.sharedService.showFailure();
      });
    }
    this.display = false;
  }

  onAdd(callback: (trialAmendment: MinimizedTrialProtocol) => void) {
    this.callback = callback;
  }

  affectDate(date: Date): Date {
    if (date !== null) {
      return new Date(date);
    } else {
      return null;
    }
  }

  addService() {
    this.amendment.serviceList.push(new TrialAmendmentService());
    this.emptyService = false;
  }

  isNullOrEmptyOrUndefined(value): boolean {
    if (!isNullOrUndefined(value)) {
      return value === '';
    }
    return true;
  }

  onProtocolTypeChange() {
    if (this.amendment.type === 'AMENDMENT') {
      this.amendment.amendmentVersion = this.amendment.version;
      this.amendment.amendmentDescription = this.amendment.description;
      this.amendment.amendmentVersionDate = this.amendment.protocolVersionDate;
    } else if (this.amendment.type === 'PROTOCOL') {
      this.amendment.version = this.amendment.amendmentVersion;
      this.amendment.description = this.amendment.amendmentDescription;
      this.amendment.protocolVersionDate = this.amendment.amendmentVersionDate;
    }
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);
}

import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {HumanResourcesService} from '../../human-resources/services/human-resources.service';
import {SharedService} from '../../shared/services/shared.service';
import {Deletable} from '../delete-confirmation/deletable';

@Component({
  selector: 'app-delete-deletable-entity-confirmation',
  templateUrl: './delete-deletable-entity-confirmation.component.html',
  styleUrls: ['./delete-deletable-entity-confirmation.component.css']
})
export class DeleteDeletableEntityConfirmationComponent implements OnInit {
  display = false;
  hhhId: number;
  entityParentHhhId: number;
  name: string;
  popupMessage: string;
  private callback: () => void;

  constructor(private router: Router,
              private humanResourceService: HumanResourcesService,
              private sharedService: SharedService) {
  }

  private _entityName: string;

  get entityName(): string {
    return this._entityName;
  }

  set entityName(value: string) {
    this._entityName = value;
  }

  private _service: Deletable;

  get service(): Deletable {
    return this._service;
  }

  set service(value: Deletable) {
    this._service = value;
  }

  public static displayDeleteConfirmationDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const deletePopupFactory = cfr.resolveComponentFactory(DeleteDeletableEntityConfirmationComponent);
    const deletePopupComponentRef = viewRef.createComponent(deletePopupFactory);
    return deletePopupComponentRef.instance;
  }

  ngOnInit() {
    this.display = true;
  }

  deleteDeletableEntity() {
    if (['TheoreticalVisit', 'VisitAct'].indexOf(this._entityName) === -1) {
      if (this._entityName === 'TrialContactableMember') {
        this._service.delete(this.entityParentHhhId, this.hhhId).subscribe(() => {
          this.sharedService.showSuccess();
          this.callback();
        }, error => {
          console.error(error);
        });
      } else {
        this._service.delete(this.hhhId).subscribe(() => {
          this.sharedService.showSuccess();
          this.callback();
        }, error => {
          console.error(error);
        });
      }
    } else {
      this.callback();
    }
    this.display = false;
  }

  onDelete(callback: () => void) {
    this.callback = callback;
  }
}

import {PharmacyDispensationKit} from "../../trial/entity/trial-pharmacy/PharmacyDispensationKit";

export class PharmacyDispensationGlobalFilter {
  trialName: string;
  lotNumber: string;
  wording: string;
  treatmentName: number;
  pharmaceuticalFormName: number;
  kit: PharmacyDispensationKit = new PharmacyDispensationKit();
  schedulerNumber: number;
  dispensationDate: Date;
  treatmentNumber: string;
  prescriberName: number;
  patientName: string;
  receptionAuthorHhhId: number;
  destructionDate?: any;
  visitName: string;
  dispensedQuantity: string;
  returnedQuantity: string;
  dispensationDateMonth?: any;
  dispensationDateYear?: any;
  processingUnit?: string;

  constructor() {
  }
}


import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {InvoiceMonitor} from '../../../entity/invoice-monitor/invoice-monitor';

@Component({
  selector: 'ih-invoice-tracking',
  templateUrl: './invoice-tracking.component.html',
  styleUrls: ['./invoice-tracking.component.css']
})
export class InvoiceTrackingComponent implements OnInit {
  @Input() invoice: InvoiceMonitor;
  @ViewChild('popupDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  constructor() {
  }

  ngOnInit() {
  }

}

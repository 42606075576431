import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Establishment } from '../models/establishment';
import { Properties } from 'src/app/helpers/properties';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentInfoService {
  properties: Properties = new Properties();
  host = this.properties.host + '/establishment-info';

  constructor(private httpClient: HttpClient) {
  }

  addEstablishment(establishment: Establishment): Observable<any> {
    return this.httpClient.post(this.host + '/add', establishment);
  }

  updateEstablishment(establishment: Establishment): Observable<any> {
    return this.httpClient.put(this.host + '/save', establishment);
  }

  

}

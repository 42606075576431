import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Calendar} from '../../../../../entity/inclusion/calendar';
import {Router} from '@angular/router';
import {CalendarService} from '../../../../../service/calendar.service';
import {DataService} from '../../../../../../shared/services/data-service';
import {Visit} from '../../../../../entity/visit';
import {VisitInInclusion} from '../../../../../entity/inclusion/visit-in-inclusion';
import {VisitFormComponent} from '../visit-form/visit-form.component';
import {DetailedVisit} from '../../../../../entity/inclusion/detailed-visit';
import {ArmService} from '../../../../../services/arm.service';
import {InnohealthTranslateService} from '../../../../../../shared/services/innohealth-translate.service';
import {VisitService} from '../../../../../service/visit.service';
import {TrialService} from '../../../../../services/trial.service';
import {VisitAction} from '../../../../../../visit-action';
import {SharedService} from '../../../../../../shared/services/shared.service';
import {TrialInclusionCalendar} from '../../../../../entity/inclusion/trial-inclusion-calendar';
import {VisitUpdate} from '../../../../../entity/inclusion/visit-update';
import {VisitActUpdate} from '../../../../../entity/inclusion/visit-act-update';
import {CanDeactivateService} from '../../../../../../shared/services/can-deactivate.service';
import {InclusionDetailService} from '../../../../../service/inclusion-detail.service';
import {TrialPageLockService} from '../../../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {PropertyEnum} from '../../../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../../../dynamic-config/service/dynamic-config.service';
import {AppData} from '../../../../../../helpers/app-data';
import {InclusionService} from '../../../../../service/inclusion.service';
import {ExportRequestBuilder} from '../../../../../../dynamic-config/entity/export-request-builder';
import {DataItemService} from '../../../../../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-inclusion-calendar',
  templateUrl: './calendar-inclusion.component.html',
  styleUrls: ['./calendar-inclusion.component.css']
})
export class CalendarInclusionComponent implements OnInit, OnDestroy {

  @ViewChild('addVisitDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  public trialHhhId: number;
  public calendar: Calendar = new Calendar();
  public state: string;
  public inclusionId: number;
  public arms: { label, value }[];
  public inclusionArms: { label, value }[];

  visitActions: VisitAction[] = [];
  toBeAddedVisits: DetailedVisit[] = [];
  updatedVisits: VisitUpdate[] = [];
  updatedVisitActs: VisitActUpdate[] = [];
  visitIdInInclusion = [];
  isInclusionCalendarSavingButtonApart = false;
  isAdditionalVisitsEnabled = false;

  constructor(
    private translateService: InnohealthTranslateService,
    private calendarService: CalendarService,
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    private canDeactivateService: CanDeactivateService,
    public trialService: TrialService,
    private router: Router,
    private visitService: VisitService,
    private sharedService: SharedService,
    private inclusionDetailService: InclusionDetailService,
    public trialPageLockService: TrialPageLockService,
    private dynamicConfigService: DynamicConfigService,
    private dataItemService: DataItemService,
    private inclusionService: InclusionService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.inclusionId = url.queryParams.inclusionId;
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.dataService.setExportPageModule('src/app/trial/inclusions/inclusion-details/calendar-details/calendar/calendar.component');
    this.calendarService.setTrialAndInclusionId(this.trialHhhId, this.inclusionId);
    this.getCalendar();
    this.getArms();
    this.getInclusionArms();
    this.visitService.initVisitListNotToBeShifted();
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isInclusionCalendarSavingButtonApart = this.dynamicConfigService.getProperty(PropertyEnum.inclusionCalendarSavingButtonApart);
      this.isAdditionalVisitsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.additionalVisitsEnabled);
    });
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  getInclusionArms(): void {
    this.dataItemService.getItems('arm-by-inclusion|' + this.inclusionId).subscribe(res => {
      this.inclusionArms = res;
      this.inclusionArms = this.inclusionArms.filter(arm => arm.value !== 'AFTER_INCLUSION' && arm.value !== 'BEFORE_INCLUSION');
    });
  }

  getCalendar() {
    this.calendarService.getCalendar(this.inclusionId).subscribe(res => {
      this.calendar = res;
      this.calendar.visitsInInclusion.forEach(e => {
        e.visits.forEach(value => {
          this.visitIdInInclusion.push(value.hhhId);
        });
      });
    }, error => {
      console.error(error);
    });
  }

  displayVisitForm() {
    const popupDialogFactory = this.cfr.resolveComponentFactory(VisitFormComponent);
    const popupDialogComponentRef = this.popupContainer.createComponent(popupDialogFactory);
    const visitFormComponent = popupDialogComponentRef.instance;
    visitFormComponent.calendarHhhId = this.calendar.hhhId;
    visitFormComponent.onAdd((visit: DetailedVisit) => {
      this.toBeAddedVisits.push(visit);
      this.positionVisit(visit);
    });
  }

  getArms() {
    this.dataItemService.getItems('arm-by-visit-position|' + this.trialHhhId).subscribe(arms => {
      this.arms = arms;
    });
  }

  updateVisitState(updated: boolean) {
    if (updated && !this.isInclusionCalendarSavingButtonApart) {
      this.getCalendar();
    }
  }

  saveCalendar() {
    const trialInclusionCalendar: TrialInclusionCalendar = new TrialInclusionCalendar();
    trialInclusionCalendar.detailedVisitDto = this.toBeAddedVisits;
    trialInclusionCalendar.clonedVisits = [];
    trialInclusionCalendar.deletedVisits = [];
    console.log(this.visitActions);
    this.visitActions.filter(visitAction => visitAction.action === 'clone')
      .forEach(visitAction => {
        trialInclusionCalendar.clonedVisits.push(visitAction.data);
      });
    this.visitActions.filter(visitAction => visitAction.action === 'delete')
      .forEach(visitAction => {
        trialInclusionCalendar.deletedVisits.push(visitAction.data.hhhId);
      });
    trialInclusionCalendar.visitsToEdit = this.updatedVisits;
    trialInclusionCalendar.visitActsToEdit = this.updatedVisitActs;
    trialInclusionCalendar.trialHhhId = this.trialHhhId;
    trialInclusionCalendar.inclusionHhhId = this.inclusionId;
    this.calendarService.saveCalendar(trialInclusionCalendar).subscribe(() => {
      this.sharedService.showSuccess();
      this.getCalendar();
      this.canDeactivateService.canBeDeactivated = true;
      this.visitService.changesSaved.emit();
      this.visitActions = [];
      this.updatedVisits.forEach(e => {
        if (this.visitIdInInclusion.find(x => x === e.visitId)) {
          this.inclusionDetailService.updateChangeDesign(this.inclusionId).subscribe();
        }
      });
      this.updatedVisits = [];
      this.toBeAddedVisits = [];
    });
  }

  updatedVisitEvent(visit: VisitAction) {
    this.visitActions.push(visit);
    if (visit.action === 'clone') {
      const data = Array.from({length: visit.data.cloneNumber}, i => new Visit().fromClonedVisit(visit.additionalData));
      switch (visit.selectedPeriod) {
        case 'BEFORE_INCLUSION': {
          this.calendar.visitsBeforeInclusion = this.calendar.visitsBeforeInclusion.concat(data);
          break;
        }
        case 'AFTER_INCLUSION': {
          this.calendar.visitsAfterInclusion = this.calendar.visitsAfterInclusion.concat(data);
          break;
        }
        default:
          const armIndex = this.calendar.visitsInInclusion.findIndex(visitArm => visitArm.armHhhId.toString() === visit.selectedPeriod);
          this.calendar.visitsInInclusion[armIndex].visits = this.calendar.visitsInInclusion[armIndex].visits.concat(data);
          break;
      }
    }
  }

  private positionVisit(visit: DetailedVisit) {
    if (visit.name === undefined || visit.name === '') {
      visit.name = this.translateService.getTranslationString('MODULE_TRIALS_PATIENTS_VISITS_FIELD_DISPLAY_NAME_UNSCHEDULED');
    }
    if (visit.clonedStateOfInclusion === 'BEFORE_INCLUSION') {
      this.calendar.visitsBeforeInclusion.push(new Visit(visit));
    } else if (visit.clonedStateOfInclusion === 'AFTER_INCLUSION') {
      this.calendar.visitsAfterInclusion.push(new Visit(visit));
    } else {
      const index = this.calendar.visitsInInclusion.findIndex((visitInInclusion: VisitInInclusion) => visitInInclusion.armHhhId === +visit.clonedStateOfInclusion);
      this.calendar.visitsInInclusion[index].visits.push(new Visit(visit));
    }
  }

  async onVisitChangeHandler(visitUpdate) {
    const existingVisitUpdate = this.updatedVisits.find(vu => vu.visitId === visitUpdate.visitId);
    if (existingVisitUpdate) {
      visitUpdate = await this.mergeVisits(visitUpdate, existingVisitUpdate);
      this.updatedVisits.splice(this.updatedVisits.indexOf(existingVisitUpdate), 1);
    }
    this.updatedVisits.push(visitUpdate);
  }

  mergeVisits(visitUpdate, existingVisitUpdate) {
    return new Promise((resolve, reject) => {
      visitUpdate.updatedField.push(...existingVisitUpdate.updatedField);
      resolve(Object.assign({}, existingVisitUpdate, visitUpdate));
    });
  }

  onVisitActChangeHandler(visitActUpdate: VisitActUpdate) {
    if (!this.updatedVisitActs.find(va => va.visitActId === visitActUpdate.visitActId)) {
      this.updatedVisitActs.push(visitActUpdate);
    } else {
      const oldVisitActUpdate = this.updatedVisitActs.find(va => va.visitActId === visitActUpdate.visitActId);
      oldVisitActUpdate.visitActDate = visitActUpdate.visitActDate;
    }
  }

  exportSingleInclusionVisits(event: any) {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.inclusionId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .exportType(event.exportType)
      .build();
    this.inclusionService.exportSingleInclusionVisits(request, this.trialHhhId).subscribe(
        (res) => {},
        (err) => {
          console.error(err);
        });
  }

}

import {Pipe, PipeTransform} from '@angular/core';
import {InnohealthTranslateService} from '../services/innohealth-translate.service';

@Pipe({
  name: 'fieldLabel'
})
export class ConfigurationLabelPipe implements PipeTransform {

  keys = [
    'lastName', 'title', 'entryDate', 'releaseDate',
    'cnomNumber', 'establishment', 'acronymDepartment',
    'firstName', 'speciality', 'committee', 'site',
    'roles', 'acronymSimbad', 'rpps', 'service',
    'registrationNumber', 'adeliNumber', 'department',
    'telephone', 'adress', 'fax', 'email',
    'providerType', 'internalIdentifier', 'contact', 'membersListFunctions',
    'manager', 'postalAddress', 'addressNumber', 'addressStreet', 'addressCode', 'addressCity', 'addressCountry',
    'addressCedex', 'sendingAddress', 'mail', 'phone', 'tva', 'accountantNumber', 'community', 'inclusionPreScreeningIdentifier',
    'providerType', 'promoterType', 'financialEntity', 'name', 'postalCode', 'country', 'legalRepresentative', 'siretCode', 'address', 'city', 'finessNumber',
    'establishment', 'state', 'number', 'trial', 'amountTotal', 'inclusionsNumbers', 'visitNames', 'issueDate', 'validationDate', 'despatchDate', 'paymentDate',
    'fieldAdded1', 'fieldAdded2', 'fieldAdded3'
  ];

  constructor(private innohealthTranslateService: InnohealthTranslateService) {
  }


  transform(value: any, args?: string, keyInput?: string): any {
    const index = this.separeValueWithKey(value, keyInput);
    const staticWorld = value.substr(0, index);
    let dynamicWorld = value.substr(index + 1) ? value.substr(index + 1) : '';
    dynamicWorld = dynamicWorld.replaceAll('_', ' ').toLowerCase();
    if (args === 'dynamic') {
      return (!dynamicWorld && dynamicWorld === '') ? this.innohealthTranslateService.getTranslationString(staticWorld) : dynamicWorld;
    } else if (args === 'static') {
      return this.innohealthTranslateService.getTranslationString(staticWorld);
    } else {
      return 'error';
    }
  }

  inverse(code: string, value: any) {
    value = value.replaceAll(' ', '_');
    value = value.toUpperCase();
    value = code + '_' + value;
    return value;
  }

  separeValueWithKey(value: string, key: string): number {
    let j = 0;
    let i = 0;
    while (j < key.length) {
      if (value[i].toLowerCase() === key[j].toLowerCase()) {
        j++;
      } else if ((value[i] !== '_') && (j !== 0)) {
        j = 0;
        i--;
      }
      i++;
    }
    return i;
  }

  getKeyFromCode(code: any) {
    let result = '';
    this.keys.forEach(value => {
      if (code.replaceAll('_', '').toLowerCase().includes(value.toLowerCase())) {
        result = value;
      }
    });
    return result;
  }

  getCode(value: string) {
    const key = this.getKeyFromCode(value);
    const index = this.separeValueWithKey(value, key);
    return value.substr(0, index);
  }

  updateCode(code: string, key: string, newValue: string): string {
    const index = this.separeValueWithKey(code, key);
    return this.inverse(code.substr(0, index), newValue);
  }
}

import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {InvoiceFixedCost} from '../../models/invoice-fixed-cost';
import {InvoiceFixedCosts} from '../models/invoice-fixed-costs';

import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Properties} from '../../../helpers/properties';
import {AdditionalCostState} from '../../../additional-cost-state.enum';
import {SubInvoiceGlobalFilter} from '../../models/sub-invoice-global-filter';
import {ResponsePage} from '../../../shared/entity/response-page';
import {ExportRequest} from '../../../dynamic-config/entity/export-request';
import {catchError, map} from 'rxjs/operators';
import {RequestPageBuilder} from '../../../dynamic-config/entity/request-page-builder';
import {RequestInvoiceAct} from '../../models/RequestInvoiceAct';

@Injectable({
  providedIn: 'root'
})
export class InvoiceFixedCostService {

  public host = new Properties().host + '/invoice-fixed-cost';
  public invoicedInvoiceFixedCost: InvoiceFixedCost[];
  public pendingInvoiceFixedCost: InvoiceFixedCost[];
  public invoiceId: number;
  public invoiceName: string;

  constructor(private httpClient: HttpClient) {
  }

  InvoicePendingFixedCostGetSortBy(sortBy: string) {
    if (sortBy === 'description') {
      sortBy = 'fc.description';
    }
    if (sortBy === 'freeSS') {
      sortBy = 'fc.freeSS';
    }
    if (sortBy === 'pricePerUnit') {
      sortBy = 'va.pricePerUnit';
    }
    return sortBy;
  }

  getSortBy(sortBy: string) {
    switch (sortBy) {
      case 'type':
        sortBy = 'fc.type';
        break;
      case 'description':
        sortBy = 'fc.description';
        break;
      case 'internalAccountNumber':
        sortBy = 'fc.internalAccountNumber';
        break;
      case 'firstSegment':
        sortBy = 'fc.firstSegment';
        break;
      case 'secondSegment':
        sortBy = 'fc.secondSegment';
        break;
      case 'freeSS':
        sortBy = 'fc.freeSS';
        break;
      case 'pricePerUnit':
        sortBy = 'fc.pricePerUnit';
        break;
      case 'numberOfUnits':
        sortBy = 'fc.numberOfUnits';
        break;
      case 'priceTotal':
        sortBy = 'fc.priceTotal';
        break;
      default:
        sortBy = 'hhhId'; // Default case if none of the above matches
        break;
    }
    return sortBy;
  }

  getSecondSortBy(sortBy: string) {
    let secondSortBy = '';

    switch (sortBy) {
      case 'pricePerUnit':
        secondSortBy = 'fc.pricePerUnit';
        break;
      case 'numberOfUnits':
        secondSortBy = 'fc.numberOfUnits';
        break;
      case 'priceTotal':
        secondSortBy = 'fc.priceTotal';
        break;
      default:
        secondSortBy = '';
        // Default case if none of the above matches
        break;
    }
    return secondSortBy;
  }

  loadInvoicedList(invoiceHhhId: number, page: number, limit: number, sortBy: string, orderBy: string, subInvoiceGlobalFilter: SubInvoiceGlobalFilter): Observable<ResponsePage<InvoiceFixedCost>> {
    sortBy = sortBy === undefined ? 'hhhId' : this.getSortBy(sortBy);
    const secondSortBy = this.getSecondSortBy(sortBy);

    return this.httpClient.post<ResponsePage<InvoiceFixedCost>>(
      this.host + '/invoiced/load-list',
      new RequestPageBuilder<SubInvoiceGlobalFilter>()
        .objectId(invoiceHhhId)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .secondSortBy(secondSortBy)
        .orderBy(orderBy)
        .filters(subInvoiceGlobalFilter)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(invoiceFixedCost => new InvoiceFixedCost().init(invoiceFixedCost));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  loadPendingFixedCostList(hhhId: number, page: number, limit: number, sortBy: string, orderBy: string, retrocession: boolean, subInvoiceGlobalFilter: SubInvoiceGlobalFilter): Observable<ResponsePage<InvoiceFixedCost>> {
    const secondSortBy = '';
    sortBy = this.InvoicePendingFixedCostGetSortBy(sortBy);
    return this.httpClient.post<ResponsePage<InvoiceFixedCost>>(
      this.host + '/pending/load-list',
      new RequestPageBuilder<SubInvoiceGlobalFilter>()
        .objectId(hhhId)
        .condition(retrocession)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .secondSortBy(secondSortBy)
        .orderBy(orderBy)
        .filters(subInvoiceGlobalFilter)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(invoiceFixedCost => new InvoiceFixedCost().init(invoiceFixedCost));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  addFixedCostsToInvoice(fixedCostsToInvoice: InvoiceFixedCosts, retrocession: boolean) {
    return this.httpClient.post(this.host + '/add/' + retrocession, fixedCostsToInvoice);
  }

  deleteFixedCostsFromInvoice(fixedCostsToInvoice: InvoiceFixedCosts, retrocession: boolean): Observable<void> {
    return this.httpClient.post<void>(this.host + '/delete/' + retrocession, fixedCostsToInvoice);
  }

  setInvoiceId(id: number) {
    this.invoiceId = id;
  }

  setExportConfiguration(state: string, invoiceFixedCostList: InvoiceFixedCost[]) {
    if (state === AdditionalCostState.Invoiced) {
      this.invoicedInvoiceFixedCost = invoiceFixedCostList;
    } else {
      this.pendingInvoiceFixedCost = invoiceFixedCostList;
    }
  }

  setInvoiceName(invoiceName: string) {
    this.invoiceName = invoiceName;
  }

  addAllFixedCostsToInvoice(invoiceHhhId: number, unselectedFixedCosts: number[], retrocession: boolean, filters: SubInvoiceGlobalFilter): Observable<void> {
    const request = new RequestInvoiceAct<SubInvoiceGlobalFilter>(invoiceHhhId, unselectedFixedCosts, retrocession, '', '', filters);
    return this.httpClient.post<void>(this.host + '/add-all', request);
  }

  deleteAllFixedCostsFromInvoice(invoiceHhhId: number, unselectedFixedCosts: number[], retrocession: boolean, filters: SubInvoiceGlobalFilter): Observable<void> {
    const request = new RequestInvoiceAct<SubInvoiceGlobalFilter>(invoiceHhhId, unselectedFixedCosts, retrocession, '', '', filters);
    return this.httpClient.put<void>(this.host + '/delete-all', request);
  }

  exportInvoicedFixedCostList(request: ExportRequest<SubInvoiceGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/invoiced/export-csv', request);
  }

  exportPendingFixedCostList(request: ExportRequest<SubInvoiceGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/invoices/pendingFixedCosts/export-csv', request);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

}

import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {TrackingTableService} from '../../../service/tracking-table.service';
import {Router} from '@angular/router';
import {DataService} from '../../../../shared/services/data-service';
import {FR} from '../../../../shared/entity/calendar-language';
import {InclusionTracking} from '../../../entity/trial-track/inclusion-tracking';
import {TrackingBlocComponent} from '../tracking-bloc/tracking-bloc.component';
import {isNullOrUndefined, isUndefined} from 'util';
import {SelectItem} from 'primeng/api';
import {TrialFollowUpFilter} from '../../../entity/trial-track/trial-follow-up-filter';
import {VisitService} from '../../../service/visit.service';
import {ModifyStatusFormComponent} from '../../../../modals/modify-status-form/modify-status-form.component';
import {SharedService} from '../../../../shared/services/shared.service';
import {TrialHead} from '../../../models/trial-head';
import {TrialService} from '../../../services/trial.service';
import {Data} from '../../../../shared/entity/data';
import {ImportRealDatesVisitsPatientsFormComponent} from '../import-real-dates-visits-patients-form/import-real-dates-visits-patients-form.component';
import {RealDatesVisitsPatientsFileImport} from '../../../entity/RealDatesVisitsPatientsFileImport';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {saveAs} from 'file-saver';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {ExportRequest} from '../../../../dynamic-config/entity/export-request';
import {AppData} from '../../../../helpers/app-data';
import {RequestPageBuilder} from '../../../../dynamic-config/entity/request-page-builder';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';

declare var $: any;

@Component({
  selector: 'ih-tracking-table',
  templateUrl: './tracking-table.component.html',
  styleUrls: ['./tracking-table.component.css']
})
export class TrackingTableComponent implements OnInit, OnDestroy, AfterContentChecked {

  target = DynamicDefinitionEnum.TRIAL_TRACKING_TABLE_FILTER_FORM;
  filterConfig = new FormConfig();
  visitStateList = ['PENDING', 'SCHEDULED', 'COMPLETED', 'NOT_COMPLETED', 'INVOICEABLE', 'PAYMENT_INVOICED_PARTIALLY', 'INVOICED', 'PAYMENT_PAID_PARTIALLY', 'PAYMENT_PAID', 'UNREALIZED','NOT_APPLICABLE'];
  nipLabelKey = 'MODULE_TRIALS_FIELD_PATIENT_NUMBER_MEDICAL_FILE';
  updateStatusForMultipleVisits = false;

  @ViewChild('trackingBlocComponent', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  @ViewChild('trackingBlocComponentRight', {read: ViewContainerRef}) dialogContainerRight: ViewContainerRef;
  @ViewChild('modifyStatusComponent', {read: ViewContainerRef}) modifyStatusComponent: ViewContainerRef;
  @ViewChild('importRealDates', {read: ViewContainerRef}) importRealDates: ViewContainerRef;
  trackingBlocComponentRef: ComponentRef<TrackingBlocComponent>;
  sortBy = 'inclusionDate';
  orderBy = 'desc';
  totalNumber: number;
  page = 0;
  limit = 10;
  trialId: number;
  inclusionTrackingList: InclusionTracking[] = [];
  locale = FR;
  trackingDate: Date;
  inclusionSortList: SelectItem[];
  filters: TrialFollowUpFilter = new TrialFollowUpFilter();
  trialHead: TrialHead = new TrialHead(null);
  trialSiteHhhId: number;
  noYesOptions = Data.yesOrNoOrNa;

  constructor(private trackingTableService: TrackingTableService,
              private data: DataService,
              private router: Router,
              private cfr: ComponentFactoryResolver,
              private visitService: VisitService,
              private sharedService: SharedService,
              public trialService: TrialService,
              private translateService: InnohealthTranslateService,
              private dynamicConfigService: DynamicConfigService,
              private _cdr: ChangeDetectorRef) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
    this.visitService.shiftingCompleted.subscribe(() => {
      this.updateShiftedInclusionTrackingList();
      console.log(this.inclusionTrackingList);});
  }

  ngOnInit() {
    this.initProperties();
    $('.filter-inclusion').on('click', function() {
      $('.filter-mobile').toggleClass('active-filter-inclusion');
    });
    this.getTrialHead();
    this.visitService.initVisitListToBeUpdated();
    this.inclusionSortList = [
      {label: 'Date de consentement initial', value: 'initialConsentSignatureDate'},
      {label: 'Date d\'inclusion', value: 'inclusionDate'},
      {label: 'Bras', value: 'arm.name'},
      {label: 'État du patient', value: 'calculatedState'},
      {label: 'N° d\'inclusion', value: 'inclusionIdentifier1'},
      {label: 'N° de screening', value: 'inclusionIdentifier2'},
    ];
    this.data.setExportPageModule('src/app/trial/tracking-table/tracking-table.component');
    this.trackingTableService.setTrialHhhId(this.trialId);
    this.getTrackingTable();
  }

  ngAfterContentChecked() {
    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.data.setExportPageModule('');
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.visitStateList = this.dynamicConfigService.getProperty(PropertyEnum.visitStateList);
      this.nipLabelKey = this.dynamicConfigService.getProperty(PropertyEnum.nipLabelKey);
      this.updateStatusForMultipleVisits = this.dynamicConfigService.getProperty(PropertyEnum.updateStatusForMultipleVisits);
    });
    this.initFormConfig();
  }

  initFormConfig() {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.filterConfig = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.filterConfig.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.filterConfig.displayField(fieldName);

  filter() {
    if (this.dialogContainer) {
      this.dialogContainer.clear();
    }
    if (this.dialogContainerRight) {
      this.dialogContainerRight.clear();
    }
    if (isNullOrUndefined(this.trackingDate)) {
      this.createTrackingBlocComponent(false, this.dialogContainer, true);
    } else {
      for (const inclusion of this.inclusionTrackingList) {
        for (const visit of inclusion.visitTrackingDtoList) {
          if (isNullOrUndefined(visit.visitDate) && isNullOrUndefined(visit.expectedVisitDate) ||
            (visit.visitDate != null && visit.visitDate > this.trackingDate) ||
            (visit.visitDate == null && visit.expectedVisitDate != null && visit.expectedVisitDate > this.trackingDate)) {
            visit.beforeDate = false;
          } else if ((visit.visitDate != null && visit.visitDate <= this.trackingDate) ||
            (visit.visitDate == null && visit.expectedVisitDate != null && visit.expectedVisitDate <= this.trackingDate)) {
            visit.beforeDate = true;
          }
        }
      }
      this.createTrackingBlocComponent(true, this.dialogContainer, true);
      this.createTrackingBlocComponent(false, this.dialogContainerRight, false);
    }
  }

  createTrackingBlocComponent(beforeDate: boolean, viewContainer: ViewContainerRef, isPatientDetails?: boolean) {
    const addDialogFactory = this.cfr.resolveComponentFactory(TrackingBlocComponent);
    this.trackingBlocComponentRef = viewContainer.createComponent(addDialogFactory);
    const trackingBlocComponent = this.trackingBlocComponentRef.instance;
    trackingBlocComponent.trialId = this.trialId;
    trackingBlocComponent.beforeDate = beforeDate;
    trackingBlocComponent.trackingDate = this.trackingDate;
    trackingBlocComponent.trackingList = this.inclusionTrackingList;
    trackingBlocComponent.isPatientDetails = isPatientDetails;
  }

  getTrackingTable(orderBy?: string) {
    if (!isUndefined(orderBy)) {
      this.orderBy = orderBy;
    }
    if (!(this.filters.trialSiteHhhId)) {
      this.filters.trialSiteHhhId = -1;
    }
    this.trackingTableService.getTrialTrackingPage(this.trialId, this.page, this.limit, this.sortBy, this.orderBy, this.filters).subscribe(res => {
      this.inclusionTrackingList = res.content;
      this.totalNumber = res.totalElements;

      this.filter();
    }, error => {
      console.error('An error occurred while loading tracking table.' + error);
    });
  }

  paginate(event) {
    this.page = event.page;
    this.limit = event.rows;

    this.getTrackingTable();

  }


  displayUpdateVisitState() {
    if (this.visitService.visitListToBeUpdated.length === 0) {
      this.sharedService.showWarning('MODULE_TRIALS_DASHBOARD_TRACKING_TABLE_UPDATE_VISITS_WARNING');
    } else {
      const addPopupFactory = this.cfr.resolveComponentFactory(ModifyStatusFormComponent);
      const addPopupComponentRef = this.modifyStatusComponent.createComponent(addPopupFactory);
      const folderFormComponent = addPopupComponentRef.instance;
      folderFormComponent.from = 'TRACKING_TABLE';
      folderFormComponent.onEdit(() => {
        this.visitService.initVisitListToBeUpdated();
        this.getTrackingTable();
      });
    }
  }

  clearPatientInclusionFilter() {
    this.filters.patientInclusionFilter = null;
    this.getTrackingTable();
  }

  goToBottom() {
    const objDiv = document.getElementById('ScrolDiv');
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  goToTop() {
    const objDiv = document.getElementById('ScrolDiv');
    objDiv.scrollTop = 0;
  }

  getTrialHead() {
    this.trialService.getTrialHead(this.trialId).subscribe(res => {
      this.trialHead = res;
    });
  }

  displayImportRealDatesDialogue() {
    const formComponent = ImportRealDatesVisitsPatientsFormComponent.displayFormDialogue(this.importRealDates, this.cfr);
    formComponent.formHeader = 'MODULE_TRIALS_DASHBOARD_TRACKING_IMPORT_REAL_DATES_VISITS_PATIENTS';
    formComponent.realDatesVisitsPatientsFileImport.trialHhhId = this.trialId;
    formComponent.onAdd((realDatesVisitsPatientsFileImport: RealDatesVisitsPatientsFileImport) => {
      console.error(realDatesVisitsPatientsFileImport.file.type);
      if (realDatesVisitsPatientsFileImport.file.type === 'text/csv' || realDatesVisitsPatientsFileImport.file.type === 'application/vnd.ms-excel') {
        formComponent.display = false;
        this.trackingTableService.importRealDatesVisitsPatients(realDatesVisitsPatientsFileImport).subscribe(
          (res) => {
            const excelExtension = '.xls';
            const date = new Date();
            saveAs(res, this.translateService.getTranslationString('MODULE_TRIAL_FOLLOW_UP_IMPORT_REPORT_SHEET_NAME')
              + date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear() + excelExtension);
            this.sharedService.showSuccess();
            this.getTrackingTable();
          }, error => {
            this.sharedService.showFailure();
            console.log(error);
          }
        );
      } else {
        this.sharedService.showFailure(this.translateService.getTranslationString('UI_FORM_ERROR_FILE_INVALID_FORMAT_CSV'));
      }
    });
  }

  exportTrackingTable(event: any): void {
    const request = new ExportRequestBuilder<TrialFollowUpFilter>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .exportType(event.exportType)
      .limit(this.limit)
      .page(this.page)
      .objectId(this.trialId)
      .filters(this.filters)
      .build();

    this.trackingTableService.export(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }
  updateInclusionTrackingListExpectedVisitDate() {
    if (this.trackingBlocComponentRef) {
      const trackingBlocComponent = this.trackingBlocComponentRef.instance;
      for (let i = 0; i < trackingBlocComponent.trackingList.length; i++) {
        for (let j = 0; j < trackingBlocComponent.trackingList[i].visitTrackingDtoList.length; j++) {
          if (this.inclusionTrackingList[i] && this.inclusionTrackingList[i].visitTrackingDtoList[j]) {
            trackingBlocComponent.trackingList[i].visitTrackingDtoList[j].expectedVisitDate = this.inclusionTrackingList[i].visitTrackingDtoList[j].expectedVisitDate;
            console.log(trackingBlocComponent.trackingList[i].visitTrackingDtoList[j].expectedVisitDate, this.inclusionTrackingList[i].visitTrackingDtoList[j].expectedVisitDate);
          } else {
            console.warn(`InclusionTrackingList missing data at index ${i}, ${j}`);
          }
        }
      }
    }
  }

  updateShiftedInclusionTrackingList(orderBy?: string) {
    if (!isUndefined(orderBy)) {
      this.orderBy = orderBy;
    }
    if (!(this.filters.trialSiteHhhId)) {
      this.filters.trialSiteHhhId = -1;
    }
    this.trackingTableService.getTrialTrackingPage(this.trialId, this.page, this.limit, this.sortBy, this.orderBy, this.filters).subscribe(res => {
      this.inclusionTrackingList = res.content;
      this.totalNumber = res.totalElements;
      this.updateInclusionTrackingListExpectedVisitDate();
    }, error => {
      console.error('An error occurred while loading tracking table.' + error);
    });
  }
}

import {NumberInpatientsCurrentActive} from './number-inpatients-current-active';
import {EstablishmentDepartment} from '../../shared/entity/establishment-department';

export class Establishment {
  hhhId: number;
  name: string;
  number: any;
  finessNumber: any;
  siretCode: any;
  address: string;
  postalCode: any;
  country: string;
  city: string;
  legalRepresentative: any;
  numberInpatientsCurrentActive: NumberInpatientsCurrentActive;
  services: EstablishmentDepartment[] = [];
}

export class TrialContactableMember {
  public hhhId: number;
  public publicCommentCount: number;
  public providerType: string;
  public provider: string;
  public contact: string;
  public function: string;
  confidentialityAgreementSigned: boolean;
  confidentialityAgreementDate: Date;
  providerHhhId: number;
  providerTypeCode: string;
  contactHhhId: number;


  init(obj: any): TrialContactableMember {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}


export class ExportedTrialContactableMember {
  public providerType: string;
  public provider: string;
  public contact: string;
  public function: string;

}

import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {InvoiceState} from '../invoice-fixed-costs/models/invoice-state';
import {InvoiceGlobalFilter} from '../models/invoice-global-filter';
import {Invoice} from '../models/invoice';
import {InvoiceService} from '../services/invoice.service';
import {DataService} from '../../shared/services/data-service';
import {Router} from '@angular/router';
import {InvoiceAddFormComponent} from '../invoice-add-form/invoice-add-form.component';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {RollbackService} from '../../shared/services/rollback.service';
import {SharedService} from '../../shared/services/shared.service';
import {Observable} from 'rxjs';
import {ResponsePage} from '../../shared/entity/response-page';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {InvoiceAppData} from '../Invoice-app-data';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {AppData} from '../../helpers/app-data';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {LabelValue} from '../../shared/entity/label-value';

declare var $: any;

@Component({
  selector: 'ih-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent extends PaginatorTableComponent<Invoice> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.Invoice;
  target = DynamicDefinitionEnum.INVOICE_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];
  isPreInvoiceEnabled = true;
  isMultiSiteEnabled = false;
  financialEntitiesEnabled = true;
  invoiceTrialByProtocolNumber = true;
  invoiceProtocolNumberEnabled = false;
  isCsetEnabled = true;

  invoiceStates: InvoiceState[];
  filters: InvoiceGlobalFilter = new InvoiceGlobalFilter();
  retrocessionFees = false;
  invoiceObservable: Observable<ResponsePage<Invoice>> = null;
  visitOptions: LabelValue[] = [];
  parentEstablishment = false;

  constructor(
    private invoiceService: InvoiceService,
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    private router: Router,
    private rollbackService: RollbackService<InvoiceGlobalFilter>,
    private dynamicConfigService: DynamicConfigService,
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    this.retrocessionFees = url.toString().indexOf('retrocession-fees') !== -1;
  }

  ngOnInit() {
    $('.filter').on('click', () => {
      $('.filter-mobile').toggleClass('active-filter-inclusion');
    });
    super.ngOnInit();
    this.initProperties();
    if (this.rollbackService.hasGlobalFilter('invoiceGlobalFilter')) {
      this.filters = this.rollbackService.getGlobalFilter('invoiceGlobalFilter');
    } else {
      this.filters = new InvoiceGlobalFilter();
    }
    this.getInvoiceStates();

  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isPreInvoiceEnabled = this.dynamicConfigService.getProperty(PropertyEnum.preInvoiceEnabled);
      this.isMultiSiteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
      this.financialEntitiesEnabled = this.dynamicConfigService.getProperty(PropertyEnum.financialEntitiesEnabled);
      this.invoiceTrialByProtocolNumber = this.dynamicConfigService.getProperty(PropertyEnum.invoiceTrialByProtocolNumber);
      this.invoiceProtocolNumberEnabled = this.dynamicConfigService.getProperty(PropertyEnum.invoiceProtocolNumberEnabled);
      this.isCsetEnabled = this.dynamicConfigService.getProperty(PropertyEnum.csetEnabled);
      this.parentEstablishment = this.dynamicConfigService.parent;
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, InvoiceAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('state')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('invoice-state')
      .sortable()
      .sortField('invsNameFR')
      .cssClass((data: Invoice) => this.getInvoiceStateCssClass(data))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('establishment')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.filters.establishmentHhhId)
      .accessible(this.parentEstablishment)
      .type('establishment-children')
      .sortable()
      .sortField('establishmentName')
      .displayContent(((data: Invoice) => {
        return data.establishment;
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('number')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.number)
      .sortable()
      .sortField('number')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('trial')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type(this.getTrialType())
      .sortable()
      .sortField(this.invoiceTrialByProtocolNumber ? 'trialInfoGeneral.protocolNumber' : 'trialInfoGeneral.localIdentifier')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('amountTotal')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.amountTotal)
      .sortable()
      .sortField('amountTotal')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      // TODO: refactor verifyClient condition, implement functionality to allow user to choose filterType from console.
      .field('inclusionsNumbers')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
      .type(this.dynamicConfigService.verifyClient(['igr']) ? 'inclusion-identifier-1' : null)
      .filter(this.filters.inclusionsNumbers)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      // TODO: refactor verifyClient condition, implement functionality to allow user to choose filterType from console.
      .field('visitNames')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
      .type(this.dynamicConfigService.verifyClient(['igr']) ? 'visit-included-in-invoices' : null)
      .filter(this.filters.visitNames)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('issueDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.issueDate)
      .sortable()
      .sortField('issueDate')
      .withDateFormatter()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('validationDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.validationDate)
      .sortable()
      .sortField('validationDate')
      .withDateFormatter()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('despatchDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.despatchDate)
      .sortable()
      .sortField('despatchDate')
      .withDateFormatter()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('paymentDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.paymentDate)
      .sortable()
      .sortField('paymentDate')
      .withDateFormatter()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionIdentifier2')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('inclusion-identifier-2')
      .filter(this.filters.inclusionIdentifier2)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionPreScreeningIdentifier')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('inclusion-pre-screening-identifier')
      .filter(this.filters.inclusionPreScreeningIdentifier)
      .build());
    this.headers = this.config.buildTable(availableHeaders);
    localStorage.getItem(this.target).split(',');

  }


  getDeleteMessage(): string {
    return 'MODULE_INVOICES_DIALOG_WARNING_INVOICE_WILL_BE_DELETED';
  }

  getService(): any {
    return this.invoiceService;
  }

  getList(filters?: any) {
    Object.assign(this.filters, DynamicTableHeader.buildFilterData(this.headers));
    this.rollbackService.saveGlobalFilter('invoiceGlobalFilter', this.filters);
    this.rollbackService.isRollBack = true;
    this.invoiceObservable = this.invoiceService.loadList(this.retrocessionFees, false, this.page, this.limit, this.sortBy, this.orderBy, this.filters);
    this.invoiceObservable
      .subscribe(res => {
        this.values = res.content;
        this.totalNumber = res.totalElements;
        this.invoiceService.setPaginationConfiguration(res, this.limit, this.sortBy, this.orderBy, filters);
        // this.buildVisitOptions();
      });
    this.cdr.detectChanges();
  }

  getInvoiceStates() {
    this.invoiceService.getInvoiceStates().subscribe(res => {
      this.invoiceStates = res;
    });
  }

  edit(invoice: Invoice) {
    if (this.retrocessionFees) {
      this.router.navigate(['/retrocession-fees-details'], {queryParams: {invoiceHhhId: invoice.hhhId}}).then();
    } else {
      this.router.navigate(['/invoice-details'], {queryParams: {invoiceHhhId: invoice.hhhId}}).then();
    }
  }

  displayFormDialogue() {
    const formComponent = InvoiceAddFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.invoiceStates = this.invoiceStates;
    formComponent.formHeader = this.getAddTitle();
    formComponent.onAdd((newInvoice: Invoice) => {
      this.values.push(newInvoice);
    });
  }

  displayDeleteDialogue(entity: any) {
    this.displayDeleteDialogueFromInfos(entity.hhhId, entity.number, entity);
  }

  refreshAfterDelete(entity: any): void {
    const index = this.values.findIndex((value: Invoice) => value.hhhId === entity.hhhId);
    this.sharedService.setInformationInLocalStorage('Pré-facturation', 'supprimer', entity.number);
    this.values.splice(index, 1);
    this.totalNumber -= 1;
  }

  getInvoiceStateCssClass(data: Invoice) {
    return data.manualState ? 'manual-state' : '';
  }

  getTrialType(): string {
    return this.invoiceTrialByProtocolNumber
      ? 'protocol-number'
      : (this.isCsetEnabled
        ? 'cset-protocol-number'
        : (this.invoiceProtocolNumberEnabled
          ? 'protocol-number'
          : 'local-identifier'));
  }

  exportInvoiceList(event: any): void {
    const request = new ExportRequestBuilder<InvoiceGlobalFilter>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .exportType(event.exportType)
      .target(this.target)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .condition(false)
      .secondCondition(this.retrocessionFees)
      .sortBy(this.sortBy)
      .orderBy(this.orderBy)
      .filters(this.filters)
      .build();

    this.invoiceService.exportInvoiceList(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }


  buildVisitOptions(): LabelValue[] {
    const visitedIds = new Set();
    this.visitOptions = [];
    for (const invoice of this.values) {
      const visitNames = invoice.visitNames.split(',');
      if (!visitedIds.has(invoice.visitNames)) {
        visitedIds.add(invoice);
        this.visitOptions.push(new LabelValue(invoice.visitNames, invoice.visitNames));
      }
    }
    return this.visitOptions;
  }

}

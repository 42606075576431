import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TrialOperationalAct} from '../entity/trial-additionnal-costs/trial-operational-act';
import {IsDeletable} from '../../is-deletable';
import {VisitAdditionalCost} from '../entity/trial-additionnal-costs/visit-additional-cost';
import {DetailedTrialAct} from '../entity/trial-additionnal-costs/detailed-trial-act';
import {OperationalActEditDto} from '../entity/trial-additionnal-costs/operational-act-edit-dto';
import {OperationalAct} from '../../additional-costs/models/operational-act';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class TrialOperationalActService {

  public host = new Properties().host + '/trial-operational-act';

  constructor(private httpClient: HttpClient) {
  }

  loadList(trialHhhId: number): Observable<TrialOperationalAct[]> {
    return this.httpClient.get<TrialOperationalAct[]>(this.host + '/load-list/' + trialHhhId);
  }

  getTrialOperationalActById(hhhId: number): Observable<TrialOperationalAct> {
    return this.httpClient.get<TrialOperationalAct>(this.host + '/load/' + hhhId);
  }

  getTrialOperationalSubActList(trialOperationalActHhhId: number): Observable<DetailedTrialAct[]> {
    return this.httpClient.get<DetailedTrialAct[]>(this.host + '/load-sub-list/' + trialOperationalActHhhId);
  }

  getVisitAdditionalCostList(trialOperationalActHhhId: number): Observable<VisitAdditionalCost[]> {
    return this.httpClient.get<VisitAdditionalCost[]>(this.host + '/load-visit-additional-cost-list/' + trialOperationalActHhhId);
  }

  getAttachedTrialOperationalActList(trialHhhId: number): Observable<VisitAdditionalCost[]> {
    return this.httpClient.get<VisitAdditionalCost[]>(this.host + '/load-attached-list/' + trialHhhId);
  }

  createNew(trialHhhId: number, operationalAct: OperationalAct): Observable<TrialOperationalAct> {
    return this.httpClient.post<TrialOperationalAct>(this.host + '/create/' + trialHhhId, operationalAct);
  }

  addActsToTrial(trialHhhId: number, hhhIDs: number[], trialActHhhIds: number[], trialFixedCostHhhIds: number[]) {
    const request = {
      trialHhhId,
      hhhIDs,
      trialActHhhIds,
      trialFixedCostHhhIds
    };
    return this.httpClient.post(this.host + '/add', request);
  }

  updateOperationalTrialAct(detailedTrialAct: DetailedTrialAct): Observable<DetailedTrialAct> {
    return this.httpClient.post<DetailedTrialAct>(this.host + '/sub-act/update', detailedTrialAct);
  }

  updateTrialOperationalAct(operationalActEdit: OperationalActEditDto): Observable<void> {
    return this.httpClient.put<void>(this.host + '/update', operationalActEdit);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(trialOperationalActId: number) {
    return this.httpClient.get(this.host + `/delete/${trialOperationalActId}`);
  }

  deleteOperationalSubActs(trialOperationalActId: number, operationalSubActs: number[]): Observable<void> {
    return this.httpClient.post<void>(this.host + '/sub/delete/' + trialOperationalActId, operationalSubActs);
  }

  exportTrialOperationalActs(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }


}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Properties } from 'src/app/helpers/properties';
import { PromoterType } from '../models/promoter-type';
import { Observable } from 'rxjs';
import {LabelValue} from '../../shared/entity/label-value';

@Injectable({
  providedIn: 'root'
})
export class PromoterService {
  public host = new Properties().host + '/data/items/promoter-type-selected';

  constructor(  private httpClient: HttpClient) {
  }

  getPromoterTypeDtosList(): Observable<LabelValue[]> {
    return this.httpClient.get<LabelValue[]>(this.host);
  }
}

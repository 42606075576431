import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {Observable} from 'rxjs';
import {Calendar} from '../entity/inclusion/calendar';
import {TrialInclusionCalendar} from '../entity/inclusion/trial-inclusion-calendar';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  public host = new Properties().host + '/inclusion-calendar';
  public trialId: number;
  public inclusionId: number;

  constructor(private httpClient: HttpClient) {
  }

  getCalendar(hhhId: number): Observable<Calendar> {
    return this.httpClient.get<Calendar>(this.host + '/' + hhhId);
  }

  saveCalendar(trialInclusionCalendar: TrialInclusionCalendar): Observable<any> {
    return this.httpClient.post<any>(this.host + '/save', trialInclusionCalendar);
  }

  setTrialAndInclusionId(trialId, inclusionId) {
    this.trialId = trialId;
    this.inclusionId = inclusionId;
  }

}

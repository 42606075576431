import {Component, OnInit, ViewChild} from '@angular/core';
import {ClonedTheoreticalVisit} from '../../../entity/cloned-theoretical-visit';
import {TrialAct} from '../../../entity/theoretical-calendar/trial-act';
import {SelectItem} from 'primeng/api';
import {Data} from '../../../../shared/entity/data';
import {SharedService} from '../../../../shared/services/shared.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'ih-theoretical-visit-duplication-form',
  templateUrl: './theoretical-visit-duplication-form.component.html',
  styleUrls: ['./theoretical-visit-duplication-form.component.css']
})
export class TheoreticalVisitDuplicationFormComponent implements OnInit {

  @ViewChild('theoreticalVisitDuplicationForm') theoreticalVisitDuplicationForm: NgForm;

  public trialActs: TrialAct[] = [];
  public trialHhhId: number;
  public shiftedUnits: SelectItem[] = Data.shiftedUnits;
  public clonedTheoreticalVisit: ClonedTheoreticalVisit = new ClonedTheoreticalVisit();
  public displayDuplicateTheoreticalVisitDialog = false;
  public submitted = false;
  private callback: (theoreticalVisit: ClonedTheoreticalVisit) => void;
  public originalTheoreticalVisit;
  public canVisitBeCloned = true;
  public protocolId: number;

  constructor(private sharedService: SharedService) {

  }

  ngOnInit() {
    this.clonedTheoreticalVisit.duplicateNumber = 1;
    this.clonedTheoreticalVisit.period = 0;
    this.clonedTheoreticalVisit.firstPeriod = 0;
    this.clonedTheoreticalVisit.periodMargin = this.originalTheoreticalVisit.periodMargin;
  }

  closeDuplicateTheoreticalVisitDialog() {
    this.displayDuplicateTheoreticalVisitDialog = false;
  }

  cloneTheoreticalVisit() {
    this.submitted = true;
    if (this.theoreticalVisitDuplicationForm.invalid) {
      return;
    }
    this.sharedService.showSuccess();
    this.callback(this.clonedTheoreticalVisit);
    this.displayDuplicateTheoreticalVisitDialog = false;
  }

  onClone(callback: (theoreticalVisit: ClonedTheoreticalVisit) => void) {
    this.callback = callback;
  }

  checkIfFormCanBeSaved() {
    // if (this.originalTheoreticalVisit.type === 'BEFORE_INCLUSION') {
    //   this.canVisitBeCloned = this.originalTheoreticalVisit.period + (this.clonedTheoreticalVisit.firstPeriod + ((this.clonedTheoreticalVisit.duplicateNumber - 1) * this.clonedTheoreticalVisit.period)) < 0;
    // } else if (this.originalTheoreticalVisit.type === 'AFTER_INCLUSION') {
    //   this.canVisitBeCloned = this.originalTheoreticalVisit.period + (this.clonedTheoreticalVisit.firstPeriod + ((this.clonedTheoreticalVisit.duplicateNumber - 1) * this.clonedTheoreticalVisit.period)) > 0;
    // } else {
    //   this.canVisitBeCloned = true;
    // }
    this.canVisitBeCloned = true;
  }
}

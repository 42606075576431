import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {DataService} from '../../shared/services/data-service';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {GlobalConfigAppData} from '../../dynamic-config/gloabl-config-app-data';
import {AppData} from '../../helpers/app-data';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AuditLog} from '../models/audit-log';
import {AuditLogService} from '../services/audit-log.service';
import {AuditLogGlobalFilter} from '../models/audit-log-global-filter';
import {ResponsePage} from '../../shared/entity/response-page';
import {Util} from '../../helpers/util';
import {FR} from '../../shared/entity/calendar-language';
import {TrialService} from '../../trial/services/trial.service';
import {SharedService} from '../../shared/services/shared.service';
import {DatePipe} from '@angular/common';
import {Data} from '../../shared/entity/data';
import {TrialInputType} from "../../trial/component/trial-list/trial-input-type";
import {LabelValue} from "../../shared/entity/label-value";


@Component({
  selector: 'ih-audit-trail',
  templateUrl: './audit-trail.component.html',
  styleUrls: ['./audit-trail.component.css']
})
export class AuditTrailComponent extends PaginatorTableComponent<AuditLog> implements OnInit {

  target = DynamicDefinitionEnum.AUDIT_TRAIL_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  filters: AuditLogGlobalFilter = new AuditLogGlobalFilter();
  fromDate: Date;
  toDate: Date;
  trials:LabelValue[]=[];
  contents:LabelValue[]=[];
  public readonly locale = FR;

  constructor(
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    private dynamicConfigService: DynamicConfigService,
    private auditLogService: AuditLogService,
    private router: Router,
    private trialService: TrialService,
    private sharedService: SharedService,
    private datePipe: DatePipe
  ) {
    super(dataService, cfr);

  }

  ngOnInit() {
    super.ngOnInit();
    this.orderBy = 'desc';
    this.initTableConfig();

  }

  getDefaultSortField(): string {
    return 'timestamp';
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, GlobalConfigAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders() {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialIdentifier')
      .optionFilterable()
      .filterable()
      .filterType(FilterType.IH_MULTISELECT)
      .type('audit-trial')
      .sortable()
      .displayContent((log: AuditLog) => log.trialIdentifier)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('module')
      .filterable()
      .filterType(FilterType.IH_MULTISELECT)
      .type('audit-concerned-module')
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('action')
      .filterable()
      .filterType(FilterType.IH_MULTISELECT)
      .type('audit-action')
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('content')
      .filterable()
      .filterType(FilterType.IH_MULTISELECT)
      .type('audit-content')
      .displayContent((log: AuditLog) => log.content.replace(/\n/g,  '<br>'))
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('timestamp')
      .sortable()
      .filterable()
      .filterType(FilterType.IH_MULTISELECT)
      .type('audit-Dates')
      .filter('timestamp')
      .displayContent((log: AuditLog) => {
        return Util.convertTimestampToString(log.timestamp);
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('owner')
      .filterable()
      .filterType(FilterType.IH_MULTISELECT)
      .filter('owner')
      .type('audit-owner')
      .sortable()
      .displayContent((log: AuditLog) => log.username)
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getList(filters: any) {
    if (filters) {
      this.filters = Object.assign({}, DynamicTableHeader.buildFilterData(this.headers));
    }
    this.auditLogService
      .loadList(this.page, this.limit, this.sortBy, this.orderBy, this.filters, this.fromDate, this.toDate)
      .subscribe((hrPage: ResponsePage<AuditLog>) => {
        this.values = hrPage.content;
        this.totalNumber = hrPage.totalElements;
        this.auditLogService.setPaginationConfiguration(hrPage);
      });
  }


  edit(log: AuditLog) {
    const trialHhhId = log.trialHhhId;
    if (!trialHhhId) {
      this.sharedService.showFailure('MODULE_AUDIT_TRIAL_TRIAL_NOT_FOUND');
      return;
    }
    this.router.navigate(['/trial-details'], {queryParams: {hhhId: log.trialHhhId}}).then();
    this.trialService.trialHhhId = log.trialHhhId;
  }

  exportLogs(event: any): void {
    const request = new ExportRequestBuilder<AuditLogGlobalFilter>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .sortBy(this.sortBy)
      .orderBy(this.orderBy)
      .filters(this.filters)
      .fromDate(this.fromDate != null ? this.datePipe.transform(this.fromDate, 'dd/MM/yyyy') : '')
      .toDate(this.fromDate != null ? this.datePipe.transform(this.fromDate, 'dd/MM/yyyy') : '')
      .build();
    this.auditLogService.exportLogs(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }
}

import {JsonObject, JsonProperty} from 'json2typescript';
import {ContainerCardConfig} from './container-card-config';

@JsonObject('DynamicContainerDefinitionCard')
export class DynamicContainerDefinitionCard {

  @JsonProperty('card', String)
  card: string = undefined;

  @JsonProperty('defaultTitle', String)
  defaultTitle: string = undefined;

  @JsonProperty('defaultIcon', String)
  defaultIcon: string = undefined;

  @JsonProperty('config', ContainerCardConfig)
  config: ContainerCardConfig = new ContainerCardConfig();

  displayContent(field: string): string {
    switch (field) {
      case 'card':
        return this.card;
      default:
        return this.config[field];
    }
  }
}

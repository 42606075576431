import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FinancialEntity} from '../models/financial-entity';
import {Observable} from 'rxjs';
import {Properties} from '../../helpers/properties';

@Injectable({
  providedIn: 'root'
})
export class FinancialEntityService {
  public host = new Properties().host + '/financial-entity';

  constructor(private httpClient: HttpClient) {
  }

  getFinancialEntitiesByProvider(providerId): Observable<FinancialEntity[]> {
    return this.httpClient.get<FinancialEntity[]>(this.host + '/provider/' + providerId);
  }

  getFinancialEntityById(hhhId): Observable<FinancialEntity> {
    return this.httpClient.get<FinancialEntity>(this.host + '/' + hhhId);
  }

  createFinancialEntity(financialEntity: FinancialEntity): Observable<FinancialEntity> {
    return this.httpClient.post<FinancialEntity>(this.host, financialEntity);

  }

  updateFinancialEntity(financialEntity: FinancialEntity): Observable<FinancialEntity> {
    return this.httpClient.put<FinancialEntity>(this.host, financialEntity);

  }
}

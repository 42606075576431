import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {DataComment} from '../entity/data-comment';
import {DataCommentRequest, DataCommentRequestBuilder} from '../entity/data-comment-request';


@Injectable({
  providedIn: 'root'
})
export class DataCommentService {

  public host = new Properties().host + '/rest/data-comment';

  constructor(private httpClient: HttpClient) {
  }

  loadList(request: DataCommentRequest): Observable<DataComment[]> {
    return this.httpClient.post<DataComment[]>(this.host + '/load-list', request);
  }

  add(comment: DataComment): Observable<DataComment> {
    return this.httpClient.post<DataComment>(this.host + '/add', comment);
  }

  update(commentId: number, content: string): Observable<number> {
    return this.httpClient.post<number>(this.host + '/update',
        new DataCommentRequestBuilder()
            .setCommentId(commentId)
            .setContent(content)
            .build());
  }

  delete(commentId: number): Observable<void> {
    return this.httpClient.get<void>(this.host + '/delete/' + commentId);
  }

}

import {Component, ComponentFactoryResolver, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TrialInformation} from '../../../models/trial-information';
import {TrialService} from '../../../services/trial.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {isNumeric} from 'rxjs/internal-compatibility';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {CanComponentDeactivate} from '../../../../helpers/can-deactivate-guard';
import {TrialProtocolService} from '../../../services/trial-protocol.service';
import {TrialInclusionExclusionCriteriaService} from '../../../services/trial-inclusion-exclusion-criteria.service';
import {TrialTreatmentMethodologyService} from '../../../services/trial-treatment-methodology.service';
import {ArmService} from '../../../services/arm.service';
import {TrialObservablesService} from '../../../services/trial-observables.service';
import {AuthenticationService} from '../../../../login/services/authentication.service';
import {SendTrialToEstablishmentFormComponent} from '../send-trial-to-establishment-form/send-trial-to-establishment-form.component';
import {TrialIdentificationInformationGeneralService} from '../../../services/trial-identification-information-general.service';
import {TrialPersonalInformationService} from '../../../../shared/services/trial-personal-information.service';
import {TrialDisciplineInformationService} from '../../../services/trial-discipline-information.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {ContainerConfig} from '../../../../dynamic-config/entity/container-config';
import {TrialAppData} from '../../../trial-app-data';
import {FormDefinitionFieldsEnum} from '../../../../dynamic-config/entity/form-definition-fields-enum';
import {TrialInformationService} from '../../../services/trial-information.service';
import {TrialRevisionService} from '../../../services/trial-revision.service';

@Component({
  selector: 'ih-trial-identification-information-container',
  templateUrl: './trial-identification-information-container.component.html',
  styleUrls: ['./trial-identification-information-container.component.css']
})
export class TrialIdentificationInformationContainerComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  target = DynamicDefinitionEnum.TRIAL_IDENTIFICATION_INFORMATION_CONTAINER;
  config: ContainerConfig = new ContainerConfig();

  isAmendmentsEnabled = false;
  isCsetEnabled = true;
  idrcbEnabled = false;
  protocolNumberMandatory = false;
  localIdentifierMandatory = false;
  isDisciplineEnabled = false;
  isDesignEnabled = true;

  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;
  trial: TrialInformation = new TrialInformation();
  trialId: number;
  actualRoute = '';

  currentPath: string;
  displaySaveButton = false;
  isVersioningEnabled: boolean;
  isTrialCommon: boolean;

  revisionHhhId: number;
  disabledProtocolRelatedTabs = false;
  isRouteMethodologyTreatment = false;
  linkMethodologyWithTrial = false;

  armExists = false;
  inclusionExclusionExists = false;
  treatmentMethodologyExists = false;
  readonlyMode = false;

  constructor(
      private router: Router,
      public trialService: TrialService,
      public trialIdentificationInformationService: TrialIdentificationInformationGeneralService,
      private trialTreatmentMethodologyService: TrialTreatmentMethodologyService,
      private trialPersonalInformationService: TrialPersonalInformationService,
      private trialDisciplineInformationService: TrialDisciplineInformationService,
      private sharedService: SharedService,
      private cfr: ComponentFactoryResolver,
      public translateService: InnohealthTranslateService,
      private canDeactivateService: CanDeactivateService,
      private protocolService: TrialProtocolService,
      private inclusionExclusionService: TrialInclusionExclusionCriteriaService,
      private treatmentMethodologyService: TrialTreatmentMethodologyService,
      private trialIdentificationSerivce: TrialInformationService,
      private trialObservablesService: TrialObservablesService,
      private authenticationService: AuthenticationService,
      private trialRevisionService: TrialRevisionService,
      private dynamicConfigService: DynamicConfigService
  ) {
    const listOfSubModulesNotHavingUniqueSaveButton = [
      'amendment-identification-information',
      'design-identification-information',
      'arm-identification-information',
      'inclusion-exclusion-criteria',
      'inclusion-exclusion-criteria-discipline',
      'research-type', 'consents',
      'personnel-identification-information',
      'personnel-provider-identification-information',
      'protocols',
      'trial-identification-information',
      'methodology-identification-information',
      'discipline-identification-information',
      'trial-details',
      'trial-identification-information-container'];
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
    this.router.events.subscribe((val) => {
      if (!(val instanceof NavigationEnd)) {
        return;
      }
      const currentUrl = (val as NavigationEnd).url;
      if (currentUrl.indexOf('?') <= 0) {
        this.actualRoute = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.length);
        if (['amendment-identification-information', 'design-identification-information'].includes(this.actualRoute.substring(this.actualRoute.lastIndexOf('/') + 1, this.actualRoute.length))) {
          this.displaySaveButton = true;
        } else {
          this.displaySaveButton = false;
        }
      } else {
        this.actualRoute = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.indexOf('?'));
        if (listOfSubModulesNotHavingUniqueSaveButton.includes(this.actualRoute.substring(this.actualRoute.lastIndexOf('/') + 1, this.actualRoute.length))) {
          this.displaySaveButton = true;
        } else {
          this.displaySaveButton = false;
        }

        if (this.actualRoute.substring(this.actualRoute.lastIndexOf('/') + 1, this.actualRoute.length) === 'methodology-identification-information') {
          this.displaySaveButton = true;
        }

        this.isRouteMethodologyTreatment = ['methodology-identification-information'].includes(this.actualRoute.substring(this.actualRoute.lastIndexOf('/') + 1, this.actualRoute.length));
        if (this.actualRoute === 'trial-details/trial-identification-information-container') {
          this.currentPath = 'trial-identification-information-container';
        }
      }
    });
  }

  ngOnInit() {
    this.initProperties();
    const isLocalIdentifierNotEmpty = !!this.trial.trialIdentificationInformationGeneral.localIdentifier;
    this.trialIdentificationInformationService.addIsLocalIdentifierInvalid(isLocalIdentifierNotEmpty);
    this.currentPath = this.actualRoute.substring(this.actualRoute.lastIndexOf('/') + 1, this.actualRoute.length);
    this.trialService.trialHhhId = this.trialId;
    this.trialObservablesService.onProtocolSave.subscribe(() => {
      this.getLatestProtocol();
    });
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAmendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.isDisciplineEnabled = this.dynamicConfigService.getProperty(PropertyEnum.disciplineEnabled);
      this.isDesignEnabled = this.dynamicConfigService.getProperty(PropertyEnum.designEnabled);
      this.isCsetEnabled = this.dynamicConfigService.getProperty(PropertyEnum.csetEnabled);
      this.linkMethodologyWithTrial = this.dynamicConfigService.getProperty(PropertyEnum.linkMethodologyWithTrial);
      this.isVersioningEnabled = this.dynamicConfigService.getProperty(PropertyEnum.versioningEnabled);
      this.isTrialCommon = this.dynamicConfigService.getProperty(PropertyEnum.trialCommon);
      if (this.isRouteMethodologyTreatment) {
        this.displaySaveButton = this.isAmendmentsEnabled;
      }
      this.initContainerConfig();
      this.getTrialIdentificationInformation();
    });
    this.dynamicConfigService.getFormConfig(DynamicDefinitionEnum.TRIAL_IDENTIFICATION_INFORMATION_FORM, TrialAppData.formConfig).subscribe(
        (config) => {
      this.localIdentifierMandatory = config.isFieldRequired(FormDefinitionFieldsEnum.localIdentifier);
      this.protocolNumberMandatory = config.isFieldRequired(FormDefinitionFieldsEnum.protocolNumber);
      this.idrcbEnabled = config.displayField(FormDefinitionFieldsEnum.idRcb);
    });
  }

  initContainerConfig(): void {
    this.dynamicConfigService.getContainerConfig(this.target, TrialAppData.containerConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getCardConfig = (cardName: string) => this.config.getCardConfig(cardName);
  displayCard = (cardName: string) => this.config.displayCard(cardName);
  getCardOrder = (cardName: string) => this.config.getCardOrder(cardName);

  ngOnDestroy(): void {
    this.trialService.addTrialIdentification(new TrialInformation());
    this.trialTreatmentMethodologyService.setTrialIdentificationInfo(null);
    this.trialIdentificationInformationService.setTrialIdentificationInfo(null);
    this.trialPersonalInformationService.setTrialIdentificationInfo(null);
    this.trialDisciplineInformationService.setTrialIdentificationInfo(null);
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  isModuleActive(path): boolean {
    return this.currentPath === path
        || (path === 'trial-identification-information'
            && (this.currentPath === 'trial-identification-information-container' || this.currentPath === 'trial-details'));
  }

  getTrialIdentificationInformation() {
    if (this.isVersioningEnabled && this.revisionHhhId !== undefined) {
      this.trialRevisionService.getTrialIdentificationByRevisionHhhId(this.trialId, this.revisionHhhId).subscribe((res: TrialInformation) => {
            this.setTrial(res);
            if (res.hhhId && this.isAmendmentsEnabled) {
              this.getLatestProtocol();
            }
          }, error => {
            this.sharedService.showFailure();
            console.error('An error occurred while loading TrialIdentification.' + error);
          }
      );
    } else {
      this.trialIdentificationSerivce.getTrialIdentification(this.trialId).subscribe((res: TrialInformation) => {
            this.setTrial(res);
            if (res.hhhId && this.isAmendmentsEnabled) {
              this.getLatestProtocol();
            }
          }, error => {
            this.sharedService.showFailure();
            console.error('An error occurred while loading TrialIdentification.' + error);
          }
      );
    }
  }

  setTrial(res) {
    this.trial = res;
    this.trialService.addTrialIdentification(this.trial);
    const isCsetEnabled = this.trial.trialIdentificationInformationGeneral.localIdentifier !== null && this.isCsetEnabled && this.authenticationService.currentUserRole === 'ADMIN';
    this.trialIdentificationInformationService.changeIsCsetEnabled(isCsetEnabled);
  }

  openInformationIdentification(path: string, disabled: boolean = false) {
    if (disabled) {
      return;
    }
    this.currentPath = path;
    this.router.navigate(
        ['/trial-details/trial-identification-information-container/' + path],
        {queryParams: {hhhId: this.trialId}}
    ).then();
  }

  saveIdentificationInformation() {
    this.trialIdentificationInformationService.changeSubmit(true);
    const isPromoterNull: boolean = !this.trial.trialIdentificationInformationGeneral.promoterHhhId;
    this.trialService.addIsPromoterNull(isPromoterNull);
    if (((this.isCsetEnabled && this.checkCsetValidity(this.trial.trialIdentificationInformationGeneral.localIdentifier) && !this.idrcbEnabled)
            || (!this.isCsetEnabled && !!this.trial.trialIdentificationInformationGeneral.localIdentifier) && this.trial.trialIdentificationInformationGeneral.promoterHhhId && !this.localIdentifierMandatory)
        || (this.trial.trialIdentificationInformationGeneral.protocolNumber && this.protocolNumberMandatory)
        || (this.localIdentifierMandatory && this.trial.trialIdentificationInformationGeneral.localIdentifier)
        || (this.idrcbEnabled && this.trial.trialIdentificationInformationGeneral.idRcb && this.trial.trialIdentificationInformationGeneral.promoterHhhId && this.trial.trialIdentificationInformationGeneral.promoterTypeHhhId)
    ) {
      this.trialIdentificationInformationService.addIsLocalIdentifierInvalid(true);
      this.trialIdentificationSerivce.updateIdentificationInformation(this.trial).subscribe(() => {
            this.canDeactivateService.canBeDeactivated = true;
            const isCsetEnabled = this.trial.trialIdentificationInformationGeneral.localIdentifier !== null && this.isCsetEnabled;
            this.trialIdentificationInformationService.changeIsCsetEnabled(isCsetEnabled);
            this.trialIdentificationInformationService.changeSubmit(false);
            this.sharedService.setInformationInLocalStorage('Études', 'Éditer', this.trial.trialIdentificationInformationGeneral.localIdentifier);
            this.sharedService.showSuccess();
          }, error => {
            this.sharedService.showFailure();
            console.error('An error occurred while saving trial identification.' + error);
          }
      );
    }
  }

  displaySendTrialToEstablishmentForm() {
    const component = SendTrialToEstablishmentFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    component.formHeader = this.translateService.getTranslationString('MODULE_TRIALS_SEND_TRIAL_FORM_HEADER', {name: this.trial.trialIdentificationInformationGeneral.localIdentifier});
    component.establishmentsTrialHhhIds.trialHhhId = this.trialId;

  }

  checkCsetValidity(cset: string) {
    const localIdentifierLength = 9;
    return this.isEmpty(cset) || (cset.length === localIdentifierLength && isNumeric(cset));
  }

  isEmpty(value: string): boolean {
    return !value || value.trim() === '';
  }

  getLatestProtocol() {
    this.protocolService.getLatestByTrialId(this.trial.hhhId).subscribe(result => {
      this.disabledProtocolRelatedTabs = false;
      this.verifyDesignExists();
      this.verifyTreatmentExists();
      this.verifyInclusionExclusionExists();
      this.trialObservablesService.onArmSave.subscribe(() => {
        this.verifyDesignExists();
      });
      this.trialObservablesService.onTreatmentMethodologySave.subscribe(() => {
        this.verifyTreatmentExists();
      });
      this.trialObservablesService.onInclusionExclusionSave.subscribe(() => {
        this.verifyInclusionExclusionExists();
      });
    }, error => {
      this.disabledProtocolRelatedTabs = true;
    });
  }

  verifyDesignExists() {
    this.protocolService.existsNotHavingDesignByTrial(this.trial.hhhId).subscribe(result => {
      this.armExists = !result.value;
    });
  }

  verifyTreatmentExists() {
    this.treatmentMethodologyService.existsByTrialId(this.trial.hhhId).subscribe(result => {
      this.treatmentMethodologyExists = result.value;
    });
  }

  verifyInclusionExclusionExists() {
    this.inclusionExclusionService.existsByTrialId(this.trial.hhhId).subscribe(result => {
      this.inclusionExclusionExists = result.value;
    });
  }

}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TrialAdministrativeInformation} from '../models/trial-administrative-information';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class TrialAdministrativeInformationService {

  public host = new Properties().host + '/trial-administrative-information';

  constructor(private httpClient: HttpClient) { }

  getAdministrativeInformation(hhhId: number): Observable<TrialAdministrativeInformation> {
    return this.httpClient.get<TrialAdministrativeInformation>(this.host + '/load/' + hhhId);
  }

  exportedAdministrativeInformation(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request);
  }
}

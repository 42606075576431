import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {InvoiceAdditionalCost} from '../../invoice/models/invoice-additional-cost';
import {isUndefined} from 'util';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private visitSource = new BehaviorSubject<number>(0);

  private showDitepDrcFields = new BehaviorSubject<boolean>(false);
  currentShowDitepDrcFields = this.showDitepDrcFields.asObservable();

  private filterVar = new BehaviorSubject<string>('');
  currentFilterVar = this.filterVar.asObservable();

  private modification = new BehaviorSubject<boolean>(true);
  public modificationSaved = this.modification.asObservable();

  private entityName = new BehaviorSubject<string>('');
  name = this.entityName.asObservable();

  private inclusionMenu = new BehaviorSubject<string>('information-calendar-patientTracking');
  currentInclusionMenu = this.inclusionMenu.asObservable();

  private stateOfInvoice = new BehaviorSubject<string>('');

  private amendment = new BehaviorSubject<boolean>(false);
  amendmentAction = this.amendment.asObservable();

  private invoicedAdditionalCostList = new BehaviorSubject<InvoiceAdditionalCost>(null);
  invoiceAdditionalCost = this.invoicedAdditionalCostList.asObservable();

  private entityNameExportPage = '';

  private invoiceTypeExportPage = '';


  private pageTopName = new BehaviorSubject<string>('');
  currentPageTopName = this.pageTopName.asObservable();

  private fromSyntheticCalendar = new BehaviorSubject<boolean>(false);
  public fromSyntheticCalendarAction = this.fromSyntheticCalendar.asObservable();

  private fromOperationalActs = new BehaviorSubject<boolean>(false);
  public fromOperationalActsAction = this.fromOperationalActs.asObservable();

  constructor() {
  }

  setPageTopName(v: string) {
    this.pageTopName.next(v);
  }

  changeVisit(visit: number) {
    this.visitSource.next(visit);
  }

  changeFilterVar(filterVar: any) {
    this.filterVar.next(filterVar);
  }

  changeInclusionMenu(value: string) {
    this.inclusionMenu.next(value);
  }

  setInvoiceAdditionalCosts(additionalCost: InvoiceAdditionalCost) {
    this.invoicedAdditionalCostList.next(additionalCost);
  }

  changeFromModule(hhhId: number) {
    localStorage.setItem('trialHhhId', String(hhhId));
  }

  setContactData(name: string, entityType, icon: string, entityHhhId, publicCommentCount ?: number, invoiceContactButton ?: any) {
    localStorage.setItem('entityName', name);
    localStorage.setItem('entityType', entityType);
    localStorage.setItem('icon', icon);
    localStorage.setItem('entityHhhId', entityHhhId);
    localStorage.setItem('publicCommentCount', !isUndefined(publicCommentCount) ? publicCommentCount.toString() : undefined);
    localStorage.setItem('invoiceContactButton', invoiceContactButton);
  }

  setExportPageModule(name: string) {
    this.entityNameExportPage = name;
  }

  getExportPageModule(): string {
    return this.entityNameExportPage;
  }

  setInvoiceTypeExportPage(name: string) {
    this.invoiceTypeExportPage = name;
  }

  getInvoiceTypeExportPage(): string {
    return this.invoiceTypeExportPage;
  }

  setInvoiceState(state: string) {
    this.stateOfInvoice.next(state);
  }

  setShowDitepDrcFields(showDitepDrcFields: boolean) {
    this.showDitepDrcFields.next(showDitepDrcFields);
  }

  saveAmendment(isSaveButtonClicked: boolean) {
    this.amendment.next(isSaveButtonClicked);
  }

  changeFromSyntheticCalendar(value: boolean) {
    this.fromSyntheticCalendar.next(value);
  }

  changeFromOperationalActs(value: boolean) {
    this.fromOperationalActs.next(value);
  }
}

import {Component, ComponentFactoryResolver, EventEmitter, OnInit, ViewContainerRef} from '@angular/core';
import {MinimizedTrialProtocol} from '../../../models/minimized-trial-protocol';
import {SelectItem} from 'primeng/api';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-choose-protocol-version',
  templateUrl: './choose-protocol-version.component.html',
  styleUrls: ['./choose-protocol-version.component.css']
})
export class ChooseProtocolVersionComponent implements OnInit {

  protocol: MinimizedTrialProtocol = new MinimizedTrialProtocol();
  protocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  protocolsLabelValues: Array<SelectItem> = new Array<SelectItem>();
  onSave: EventEmitter<MinimizedTrialProtocol> = new EventEmitter<MinimizedTrialProtocol>();
  onCancel: EventEmitter<void> = new EventEmitter<void>();
  disableUpdateCurrentVersion = false;
  display = false;
  useCurrentProtocol = true;
  isAmendmentEnabled: boolean;

  constructor(private dynamicConfigService: DynamicConfigService) {
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ChooseProtocolVersionComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAmendmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.display = true;
    });
  }

  initializeProtocols(allProtocols: Array<MinimizedTrialProtocol>, usedProtocols: Array<MinimizedTrialProtocol>, currentProtocol: MinimizedTrialProtocol) {
    if (currentProtocol && currentProtocol.hhhId) {
      this.protocol = JSON.parse(JSON.stringify(currentProtocol));
    }
    const nonInitializedProtocols = allProtocols.filter(p => (usedProtocols.find(p2 => p.hhhId === p2.hhhId) === undefined));
    this.protocols = JSON.parse(JSON.stringify(nonInitializedProtocols));
    for (const pr of this.protocols) {
      this.protocolsLabelValues.push({
        label: pr.version != null ? 'Protocole-' + pr.version : 'Amendement-' + pr.amendmentVersion,
        value: pr.hhhId
      });
    }
    if (!this.protocol || !this.protocol.hhhId) {
      this.protocol = this.protocols[this.protocols.length - 1];
    }
  }

  onSaveClick() {
    this.display = false;
    this.onSave.emit(this.protocol);
  }

  onCancelClick() {
    this.display = false;
    this.onCancel.emit();
  }

  onProtocolChangeEvent() {
    this.protocol = this.protocols.find(p => this.protocol.hhhId === p.hhhId);
  }

  doesItExistInProtocolDropdownList(): boolean {
    return this.protocolsLabelValues.filter((pl) => pl.value === this.protocol.hhhId).length !== 0;
  }
}

import {Component, EventEmitter, Inject, InjectionToken, Input, OnInit, Output} from '@angular/core';
import {CustomExport} from '../../../trial/models/custom-export';
import {SelectItem} from 'primeng/api';
import {FR} from '../../../shared/entity/calendar-language';
import {LabelValue} from '../../../shared/entity/label-value';
import {OrganService} from '../../../trial/services/organ.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {SharedService} from '../../../shared/services/shared.service';
import {TranslateService} from '@ngx-translate/core';
import {Data} from '../../../shared/entity/data';
import {PatientExportFilter} from '../../../trial/models/patient-export-filter';
import {DynamicDefinitionEnum} from '../../entity/dynamic-definition-enum';
import {FormConfig} from '../../entity/form-config';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {GlobalConfigAppData} from '../../gloabl-config-app-data';
import {TrialCustomExportFilter} from '../../../trial/models/TrialCustomExportFilter';
import {PromoterService} from 'src/app/provider/services/promoter.service';
import {DataExportService} from '../../service/data-export.service';

export const LOCATION_TOKEN = new InjectionToken<Location>('Window location object');

@Component({
  providers: [
    {provide: LOCATION_TOKEN, useValue: window.location}
  ],
  selector: 'ih-custom-export-management',
  templateUrl: './custom-export-management.component.html',
  styleUrls: ['./custom-export-management.component.css']
})
export class CustomExportManagementComponent implements OnInit {

  target = DynamicDefinitionEnum.CUSTOM_EXPORT_FORM;
  config: FormConfig = new FormConfig();

  public customExport: CustomExport = new CustomExport().CustomExport();
  // public allFieldsList: string[] = [];
  public promoterTypes: LabelValue[] = [];
  public organsList: SelectItem[] = [];
  public locale = FR;
  public sourceList: SelectItem[] = [];
  public targetList: SelectItem[] = [];
  public years: LabelValue[] = [];
  public sexList: SelectItem[] = [];
  public showRequiredAlert = false;
  @Input() module: string;
  public noYesOptions: SelectItem[] = Data.yesOrNoOrNull;
  @Output() picklistModified: EventEmitter<any> = new EventEmitter();
  @Output() filterUpdated: EventEmitter<any> = new EventEmitter();

  public trialHeaders: Map<string, string>;
  public inclusionHeaders: Map<string, string>;


  constructor(
    @Inject(LOCATION_TOKEN) private location: Location, private route: ActivatedRoute,
    private organService: OrganService,
    private translateService: InnohealthTranslateService,
    private promoterService: PromoterService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private router: Router,
    private dataExportService: DataExportService,
    private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
  }

  ngOnInit() {
    this.initFormConfig();
    this.getPromoterType();
    this.getOrganList();
    this.getYears();
    this.initSexList();
    this.translate.stream('label').subscribe(() => {
      // this.getTrialExportFieldsTest();
      this.getExportFields();
    });
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, GlobalConfigAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getUserCustomExport() {
    const userHhhId = +localStorage.getItem('authenticatedUserID');
    this.sharedService.getUserCustomExport(userHhhId).subscribe(res => {
      this.customExport = res;
    });
  }

  initSexList() {
    const male = this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MR');
    const female = this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MME');
    this.sexList.push(
      {label: male, value: male},
      {label: female, value: female},
      {label: 'NA', value: 'NA'});
  }

  isParent() {
    return this.dynamicConfigService.parent;
  }

  getPromoterType() {
    this.promoterService.getPromoterTypeDtosList().subscribe(res => {
      this.promoterTypes = res;
      this.promoterTypes.splice(0, 0, {value: 0, label: null});
    });
  }

  getOrganList() {
    this.organService.getOrgansAndOrganFamilies().subscribe(
      res => {
        this.organsList = res;
        this.organsList.splice(0, 0, {label: ' ', value: -1});
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading organ list.' + error);
      }
    );
  }

  getYears() {
    this.years.push({label: '', value: 0});
    for (let i = 1949; i < 2049; i++) {
      this.years.push({label: i.toString(), value: i});
    }
  }

  setOrganAndOrganFamilyFilter() {
    if (this.module === 'trial') {
      if (this.customExport.trialExportFilter.trialOrgan.indexOf('OrganFamily') === -1) {
        this.customExport.trialExportFilter.organFamily = -1;
        this.customExport.trialExportFilter.organ = Number(this.customExport.trialExportFilter.trialOrgan);
      } else {
        this.customExport.trialExportFilter.organFamily = this.customExport.trialExportFilter.trialOrgan.slice(12, this.customExport.trialExportFilter.trialOrgan.length);
        this.customExport.trialExportFilter.organ = -1;
      }
    } else {
      if (this.customExport.patientExportFilter.patientOrgan.indexOf('OrganFamily') === -1) {
        this.customExport.patientExportFilter.organ = this.customExport.patientExportFilter.patientOrgan;
      } else {
        this.customExport.patientExportFilter.organFamily = this.customExport.patientExportFilter.patientOrgan.slice(12,
          this.customExport.patientExportFilter.patientOrgan.length);
      }
    }
  }

  getExportFields(): void {
    switch (this.module) {
      case 'trial':
        this.dataExportService.getListExportFields('/trial/export-fields').subscribe(res => {
          this.trialHeaders = res;
          const keys = Array.from(this.trialHeaders.keys());
          for (const field of keys) {
            this.sourceList.push({
              label: this.trialHeaders.get(field),
              value: field
            });
          }
        });
        break;
      case 'patient':
        this.dataExportService.getListExportFields('/patient/export-fields').subscribe(res => {
          this.inclusionHeaders = res;
          console.log(res);
          const keys = Array.from(this.inclusionHeaders.keys());
          for (const field of keys) {
            this.sourceList.push({
              label: this.inclusionHeaders.get(field),
              value: field
            });
          }
        });
        break;
      default:
        // Handle default case if needed
        break;
    }
  }


  resetFilter() {
    if (this.module === 'trial') {
      this.customExport.trialExportFilter = new TrialCustomExportFilter();
    } else if (this.module === 'patient') {
      this.customExport.patientExportFilter = new PatientExportFilter().PatientExportFilter();
    }
  }

  emitChange(): void {
    this.picklistModified.emit({sourceList: this.sourceList, targetList: this.targetList});
  }

  emitFilterUpdated() {
    if (this.module === 'trial') {
      this.filterUpdated.emit(this.customExport.trialExportFilter);
    } else if (this.module === 'patient') {
      this.filterUpdated.emit(this.customExport.patientExportFilter);
    }
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

}

import {VisitAdditionalCost} from '../trial-additionnal-costs/visit-additional-cost';

export class SyntheticTrialAct {
  actsToAdd: VisitAdditionalCost[];
  actsToEdit: VisitAdditionalCost[];
  actsToRemoveHhhIds: any[];
  operationalSubActsToRemove: VisitAdditionalCost[];

    constructor() {
        this.actsToAdd = [];
        this.actsToEdit = [];
        this.actsToRemoveHhhIds = [];
    }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {TrialDisciplineIdentification} from '../models/trial-discipline-identification';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrialDisciplineInformationService {

  public trialSiteHhhId: number;
  host = new Properties().host + '/trial-discipline';
  private trialIdentificationInfo: Subject<TrialDisciplineIdentification> = new BehaviorSubject<TrialDisciplineIdentification>(null);
  trialIdentificationInfoValue = this.trialIdentificationInfo.asObservable();

  constructor(private httpClient: HttpClient) {
  }

  setTrialIdentificationInfo(value) {
    this.trialIdentificationInfo.next(value);
  }

  load(trialId: number): Observable<TrialDisciplineIdentification> {
    return this.httpClient.get<TrialDisciplineIdentification>(this.host + '/load/' + trialId)
      .pipe(
        map(res => {
            return (new TrialDisciplineIdentification()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  updateDisciplineInformation(trialId: number, trialDiscipline: TrialDisciplineIdentification): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update/' + trialId, trialDiscipline);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

}

import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Router} from '@angular/router';
import {InvoiceFixedCost} from '../models/invoice-fixed-cost';
import {InvoiceFixedCosts} from '../invoice-fixed-costs/models/invoice-fixed-costs';
import {InvoiceFixedCostService} from '../invoice-fixed-costs/services/invoice-fixed-cost.service';
import {DataService} from '../../shared/services/data-service';
import {TrialService} from '../../trial/services/trial.service';
import {InvoiceAct} from '../invoice-acts/models/invoice-act';
import {InvoiceInformationService} from '../invoice-information/services/invoice-information.service';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {AdditionalCostState} from '../../additional-cost-state.enum';
import {InvoiceState} from '../../invoice-state.enum';
import {InvoiceService} from '../services/invoice.service';
import {VisitActive} from '../../shared/entity/visit-active';
import {HiddenFields} from '../../admin/models/HiddenFields';
import {UsersService} from '../../admin/services/users.service';
import {AuthenticationService} from '../../login/services/authentication.service';
import {VisitAct} from '../../trial/entity/inclusion/visit-act';
import {SharedService} from '../../shared/services/shared.service';
import {isDate, isNullOrUndefined} from 'util';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {SubInvoiceGlobalFilter} from '../models/sub-invoice-global-filter';
import {AdditionalCostType} from '../../shared/entity/additional-cost-type.enum';
import {FR} from '../../shared/entity/calendar-language';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {InvoiceAppData} from '../Invoice-app-data';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {Data} from '../../shared/entity/data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {AppData} from '../../helpers/app-data';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {LabelValue} from '../../shared/entity/label-value';
import {Util} from '../../helpers/util';
import {VisitService} from '../../trial/service/visit.service';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'ih-invoice-fixed-cost-list',
  templateUrl: './invoice-fixed-cost-list.component.html',
  styleUrls: ['./invoice-fixed-cost-list.component.css']
})
export class InvoiceFixedCostListComponent extends PaginatorTableComponent<InvoiceFixedCost> implements OnInit {

  target = DynamicDefinitionEnum.INVOICE_FIXED_COST_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];
  enablePreInvoicePriceEditing = false;
  enableAddActFromValidatedInvoice = true;
  trialSecondUfEnabled = false;

  @Input() fixedCostState;
  @Input() selectable = true;
  @Input() showExportButton = true;
  @Output() changeInvoiceFixedCostList = new EventEmitter<any>();

  @Input() set fixedCostList(refreshList) {
    if (refreshList.length > 0) {
      this.getList();
    }
  }

  invoiceHhhId: number;
  invoiceFixedCosts: InvoiceFixedCost[] = [];
  selectedCosts: InvoiceFixedCost[] = [];
  unselectedFixedCosts: number[] = [];
  fixedCostsToInvoice: InvoiceFixedCosts = new InvoiceFixedCosts();
  actionDisabled: boolean;
  displayDeleteFixedCostDialog: boolean;
  totalInvoiceFixedCost = 0;
  isUserAdmin = false;
  isDisplaySaveButton = false;
  updateConfirmationDialogVisible = false;
  visitActsToUpdate: Array<VisitAct> = new Array<VisitAct>();
  first = 0;
  locale = FR;
  isAllSelected = false;
  retrocessionFees = false;

  inclusionIdentifier1Items: LabelValue[] = [];
  inclusionIdentifier2Items: LabelValue[] = [];
  inclusionPreScreeningIdentifierItems: LabelValue[] = [];

  private filters: SubInvoiceGlobalFilter = new SubInvoiceGlobalFilter();
  public visitNameOptions: SelectItem[] = [];

  constructor(
      private cfr: ComponentFactoryResolver,
      private dataService: DataService,
      private useService: UsersService,
      private trialService: TrialService,
      private invoiceService: InvoiceService,
      private invoiceInformationService: InvoiceInformationService,
      private sharedService: SharedService,
      private authenticationService: AuthenticationService,
      private invoiceFixedCostService: InvoiceFixedCostService,
      private router: Router,
      private translateService: InnohealthTranslateService,
      private dynamicConfigService: DynamicConfigService,
      private datePipe: DatePipe,
      private cdr: ChangeDetectorRef,
      private visitService: VisitService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.invoiceHhhId = url.queryParams.invoiceHhhId;
      this.retrocessionFees = url.toString().indexOf('retrocession-fees') !== -1;
    }
    this.limit = 50;
    this.sortBy = 'hhhId';
    this.orderBy = 'asc';
  }

  get AdditionalCostState() {
    return AdditionalCostState;
  }

  async ngOnInit() {
    this.visitNameOptions = await this.getVisitNameOptions().then();
    this.initProperties();
    this.isUserAdmin = this.authenticationService.currentUserRole === 'ADMIN';
    if (this.fixedCostState === AdditionalCostState.Pending || this.fixedCostState === AdditionalCostState.Invoiced) {
      this.dataService.setExportPageModule(this.getExportPageModule());
    }
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.enablePreInvoicePriceEditing = this.dynamicConfigService.getProperty(PropertyEnum.enablePreInvoicePriceEditing);
      this.enableAddActFromValidatedInvoice = this.dynamicConfigService.getProperty(PropertyEnum.enableAddActFromValidatedInvoice);
      this.trialSecondUfEnabled = this.dynamicConfigService.getProperty(PropertyEnum.trialSecondUfEnabled);
      this.getInvoiceStateCode();
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, InvoiceAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      // TODO: refactor verifyClient condition, implement functionality to allow user to choose filterType from console.
      .field('inclusionNumber')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
      .options(this.dynamicConfigService.verifyClient(['igr']) ? this.inclusionIdentifier1Items : null)
      .filter(this.dynamicConfigService.verifyClient(['igr']) ? this.filters.inclusionNumber : this.filters.localIdentifier)
      .sortable()
      .sortField('inclusion.inclusionIdentifier1')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionPreScreeningIdentifier')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
      .options(this.dynamicConfigService.verifyClient(['igr']) ? this.inclusionPreScreeningIdentifierItems : null)
      .filter(this.filters.inclusionPreScreeningIdentifier)
      .sortable()
      .sortField('inclusion.inclusionPreScreeningIdentifier')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionScreeningIdentifier')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
      .options(this.dynamicConfigService.verifyClient(['igr']) ? this.inclusionIdentifier2Items : null)
      .filter(this.filters.inclusionScreeningIdentifier)
      .sortable()
      .sortField('inclusion.inclusionIdentifier2')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.visitDate)
      .sortable()
      .sortField('visit.visitDate')
      .linkClickEvent((data: InvoiceAct) => {
        this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId);
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('visitName')
        .filterable()
        .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
        .options(this.dynamicConfigService.verifyClient(['igr']) ? this.visitNameOptions : null)
        .filter(this.filters.visitName)
        .sortable()
        .sortField('theoreticalVisit.name')
        .linkClickEvent((data: InvoiceAct) => {
          this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId);
        })
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('category')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['ccgm']) ? FilterType.INPUT_TEXT : FilterType.IH_DROPDOWN)
      .type(this.dynamicConfigService.verifyClient(['ccgm']) ? null : 'fixed-cost-category')
      .filter(this.filters.actCategory)
      .sortable(this.dynamicConfigService.verifyClient(['col', 'ccgm']) ? this.fixedCostState !== AdditionalCostState.Invoiced : true)
      .sortField('fixedCostCategory.nameFr')
      .linkClickEvent(() => {
        this.displayFixedCostCategories();
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('description')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.description)
      .sortable(this.dynamicConfigService.verifyClient(['col']) ? this.fixedCostState !== AdditionalCostState.Invoiced : true)
      .sortField('description')
      .linkClickEvent((data: InvoiceFixedCost) => {
        this.displayFixedCost(data.localIdentifier);
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientInitials')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('inclusion.patient.firstName')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(!this.dynamicConfigService.verifyClient(['col', 'igr'])
        || (this.dynamicConfigService.verifyClient(['col', 'igr']) && this.useService.canAccessField(HiddenFields.COUNTER_PART_PRICE)))
      .field('unitPrice')
      .filter(this.filters.unitPrice)
      .cssClass(() => {
        return 'right-align';
      })
      .withDisplayCssClassContent()
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortField('pricePerUnit')
      .sortable(this.dynamicConfigService.verifyClient(['vivalto']) ? this.fixedCostState !== AdditionalCostState.Invoiced : true)
      .displayContent((data: InvoiceAct) => {
        return data.unitPrice.toFixed(2) + '€';
      })
      .linkClickEvent(() => {
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('unitNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.unitNumber)
      .sortable()
      .sortField('numberOfUnits')
      .linkClickEvent(() => {
      })
      .cssClass(() => {
        return 'right-align';
      })
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible((this.dynamicConfigService.verifyClient(['igr']) && this.useService.canAccessField(HiddenFields.COUNTER_PART_PRICE)) || (!this.dynamicConfigService.verifyClient(['igr'])))
      .field('total')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.total)
      .sortable(this.dynamicConfigService.verifyClient(['vivalto']) ? this.fixedCostState !== AdditionalCostState.Invoiced : true)
      .sortField('priceTotal')
      .linkClickEvent(() => {
      })
      .displayContent((data: InvoiceFixedCost) => {
        return data.total.toFixed(2) + '€';
      })
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('internalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.internalAccountNumber)
      .sortable()
      .sortField('internalAccountNumber')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.firstSegment)
      .sortable()
      .sortField('firstSegment')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.secondSegment)
      .sortable()
      .sortField('secondSegment')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('freeSS')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .options(Data.yesOrNoOrNull)
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('annualInvoicing')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .options(Data.yesOrNo)
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('localIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.localIdentifier)
      .sortable()
      .sortField('trialInfoGeneral.localIdentifier')
      .linkClickEvent((data: InvoiceFixedCost) => {
        this.displayTrialByLocalIdentifier(data.localIdentifier);
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('promoter')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.filters.promoterHhhId)
      .type('provider-promoter')
      .sortable()
      .sortField('promoter.name')
      .linkClickEvent((data: InvoiceFixedCost) => {
        this.displayProvider(data);
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('numberProtocol')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.numberProtocol)
      .sortable()
      .sortField('trialInfoGeneral.protocolNumber')
      .linkClickEvent((data: InvoiceFixedCost) => {
        this.displayTrialByProtocolNumber(data.numberProtocol);
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('type')
        .filterable()
        .filterType(FilterType.IH_DROPDOWN)
        .filter(this.filters.type)
        .type('additional-cost-type-selected')
        .sortable()
        .sortField('type')
        .displayContent((fixedCost: InvoiceAct) => fixedCost.typeName)
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientLastName')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondInternalAccountNumber')
      .accessible(this.trialSecondUfEnabled)
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('fc.secondInternalAccountNumber')
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }
  private getVisitNameOptions(): Promise<SelectItem[]>  {
    return this.visitService.getVisitNameOptions(this.invoiceHhhId, this.fixedCostState, true).toPromise();
  }

  getExportPageModule(): string {
    return 'src/app/invoice/invoice-fixed-costs/invoice-fixed-costs.component';
  }

  getFilteredList(filter?) {
    this.isAllSelected = false;  // Réinitialise la sélection complète
    if (filter) {
      Object.assign(this.filters, DynamicTableHeader.buildFilterData(this.headers));
    } else {
      this.filters = new SubInvoiceGlobalFilter();
    }
    this.getList();
  }

  getList() {
    this.invoiceFixedCosts = [];
    if (this.fixedCostState === AdditionalCostState.Invoiced || this.fixedCostState === AdditionalCostState.All) {
      this.invoiceFixedCostService.loadInvoicedList(this.invoiceHhhId, this.page, this.limit, this.sortBy, this.orderBy, this.filters).subscribe(res => {
        this.invoiceFixedCosts = res.content;
        this.totalNumber = res.totalElements;
        this.totalInvoiceFixedCost = res.extraValue;
        this.invoiceFixedCostService.setExportConfiguration(AdditionalCostState.Invoiced, this.invoiceFixedCosts);
        this.buildFilterOptions();
      });
    } else if (this.fixedCostState === AdditionalCostState.Pending) {
      this.invoiceFixedCostService.loadPendingFixedCostList(this.invoiceHhhId, this.page, this.limit, this.sortBy, this.orderBy, this.retrocessionFees, this.filters).subscribe(res => {
        this.invoiceFixedCosts = res.content;
        this.totalNumber = res.totalElements;
        this.totalInvoiceFixedCost = res.extraValue;
        this.invoiceFixedCostService.setExportConfiguration(AdditionalCostState.Pending, this.invoiceFixedCosts);
        this.buildFilterOptions();
      });
    }
  }

  submitAddFixedCosts() {
    if (this.isAllSelected) {
      this.invoiceFixedCostService.addAllFixedCostsToInvoice(this.invoiceHhhId, this.unselectedFixedCosts, this.retrocessionFees, this.filters).subscribe(() => {
        this.updateList();
      });
    } else {
      this.fixedCostsToInvoice.invoiceHhhId = this.invoiceHhhId;
      for (const fixedCost of this.selectedCosts) {
        this.fixedCostsToInvoice.additionalCostsHhhIds.push(new VisitActive(fixedCost.hhhId, fixedCost.isVisitAct));
      }
      this.invoiceFixedCostService.addFixedCostsToInvoice(this.fixedCostsToInvoice, this.retrocessionFees).subscribe(() => {
        this.updateList();
      });
    }
  }

  deleteActs() {
    this.displayDeleteFixedCostDialog = false;
    if (this.isAllSelected) {
      this.invoiceFixedCostService.deleteAllFixedCostsFromInvoice(this.invoiceHhhId, this.unselectedFixedCosts, this.retrocessionFees, this.filters).subscribe(() => {
        this.updateList();
      });
    } else {
      this.fixedCostsToInvoice.invoiceHhhId = this.invoiceHhhId;
      for (const act of this.selectedCosts) {
        this.fixedCostsToInvoice.additionalCostsHhhIds.push(new VisitActive(act.hhhId, act.isVisitAct));
      }
      this.invoiceFixedCostService.deleteFixedCostsFromInvoice(this.fixedCostsToInvoice, this.retrocessionFees).subscribe(() => {
        this.updateList();
      });
    }
  }

  updateList() {
    this.changeInvoiceFixedCostList.emit({state: this.fixedCostState, list: this.selectedCosts});
    this.getList();
    this.selectedCosts = [];
    this.fixedCostsToInvoice.additionalCostsHhhIds = [];
    this.isAllSelected = false;
    this.invoiceService.updateTotal();
  }

  updateSelectedList(selectedList: any) {
    this.selectedCosts = selectedList;
  }

  openDeleteFixedCostDialog() {
    if (this.selectedCosts.length > 0) {
      this.displayDeleteFixedCostDialog = true;
    }
  }

  resetTable() {
    this.getList();
  }

  saveTable() {
    this.updateConfirmationDialogVisible = true;
  }

  tableUpdated(visitAct: VisitAct) {
    this.isDisplaySaveButton = true;
    if (this.visitActsToUpdate.indexOf(visitAct) < 0) {
      this.visitActsToUpdate.push(visitAct);
    }
  }

  confirmUpdate() {
    this.updateConfirmationDialogVisible = false;
    this.invoiceService.updateVisitActsFromInvoice(AdditionalCostType.FIXED_COST, this.visitActsToUpdate, this.invoiceHhhId).subscribe(() => {
      this.getList();
      this.isDisplaySaveButton = false;
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
    });
  }

  displayTrialByLocalIdentifier(localIdentifier: string) {
    this.trialService.getTrialByLocalIdentifier(localIdentifier).subscribe((trialId: number) => {
      this.router.navigate(
        ['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId: trialId}}
      ).then();
    });
  }

  displayTrialByProtocolNumber(protocolNumber: string) {
    this.trialService.getTrialByProtocolNumber(protocolNumber).subscribe((trialId: number) => {
      this.router.navigate(
        ['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId: trialId}}
      ).then();
    });
  }

  displayProvider(invoiceFixedCost: InvoiceFixedCost) {
    this.dataService.setContactData(invoiceFixedCost.promoter, 'Provider', '/assets/images/providers.png', invoiceFixedCost.promoterHhhId, 0);
    this.router.navigate(['/contact/' + invoiceFixedCost.promoterHhhId + '/Provider']).then();
  }

  displayFixedCostCategories() {
    this.router.navigate(['/additional-costs-container/fixed-cost-categories']).then();
  }

  displayFixedCost(localIdentifier: string) {
    this.trialService.getTrialByLocalIdentifier(localIdentifier).subscribe((trialId: number) => {
      this.router.navigate(
        ['/trial-details/fixed-costs'],
        {queryParams: {hhhId: trialId}}
      ).then();
    });
  }

  onUnselect(fixedCost) {
    this.unselectedFixedCosts.push(fixedCost.data.hhhId);
  }

  getInvoiceStateCode() {
    this.invoiceInformationService.getInvoiceStateCode(this.invoiceHhhId).subscribe(res => {
      if (res) {
        this.dataService.setInvoiceState(res);
        this.actionDisabled = ((res === InvoiceState.Payed || res === InvoiceState.Despatched || res === InvoiceState.Validate)
            && this.enableAddActFromValidatedInvoice) ||
          ((res === InvoiceState.Despatched || res === InvoiceState.Payed) && !this.enableAddActFromValidatedInvoice);
      }
    });
    this.updateList();
  }

  displayContent(col: any, colField: any, rowData: any): string {
    let content = colField;
    if (isNullOrUndefined(rowData[col.field])) {
      content = '';
    } else if (col.translated) {
      content = colField ? this.translateService.getTranslationString('UI_DIALOG_BUTTON_YES') : this.translateService.getTranslationString('UI_DIALOG_BUTTON_NO');
    } else if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (col.dateFormatter) {
      content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
    } else if (col.stringToDate) {
      if (!isNullOrUndefined(colField)) {
        const date1 = colField.toString().split('/');
        if (!isDate(rowData[col.field]) && date1.length === 3) {
          rowData[col.field] = new Date(parseInt(date1[2], 10), parseInt(date1[1], 10), parseInt(date1[0], 10));
        }
        content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
      }
    }
    return content;
  }

  onAllSelected(event: any) {
    this.isAllSelected = event.checked;
  }

  displayInclusionCalendarByNumber(inclusionHhhId: number, trialHhhId: number) {
    this.router.navigate(['/trial-details/inclusion-details/calendar'],
      {
        queryParams: {
          hhhId: trialHhhId,
          inclusionId: inclusionHhhId
        }
      }).then();
  }

  getSortBy(sortBy: string) {
    switch (sortBy) {
      case 'type':
        sortBy = 'fc.type';
        break;
      case 'description':
        sortBy = 'fc.description';
        break;
      case 'internalAccountNumber':
        sortBy = 'fc.internalAccountNumber';
        break;
      case 'firstSegment':
        sortBy = 'fc.firstSegment';
        break;
      case 'secondSegment':
        sortBy = 'fc.secondSegment';
        break;
      case 'freeSS':
        sortBy = 'fc.freeSS';
        break;
      case 'pricePerUnit':
        sortBy = 'fc.pricePerUnit';
        break;
      case 'numberOfUnits':
        sortBy = 'fc.numberOfUnits';
        break;
      case 'priceTotal':
        sortBy = 'fc.priceTotal';
        break;
      default:
        sortBy = 'hhhId'; // Default case if none of the above matches
        break;
    }
    return sortBy;
  }

  getSecondSortBy(sortBy) {
    switch (sortBy) {
      case 'pricePerUnit':
        return 'fc.pricePerUnit';
      case 'numberOfUnits':
        return 'fc.numberOfUnits';
      case 'priceTotal':
        return 'fc.priceTotal';
      default:
        return '';
    }
  }

  exportInvoiceFixedCosts(event: any): void {
    const request = new ExportRequestBuilder<SubInvoiceGlobalFilter>()
      .objectId(this.invoiceHhhId)
      .condition(this.retrocessionFees)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .sortBy(this.getSortBy(this.sortBy))
      .secondSortBy(this.getSecondSortBy(this.sortBy))
      .orderBy(this.sortBy)
      .filters(this.filters)
      .build();
    if (this.fixedCostState === AdditionalCostState.Invoiced || this.fixedCostState === AdditionalCostState.All) {
      this.invoiceFixedCostService.exportInvoicedFixedCostList(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
    } else if (this.fixedCostState === AdditionalCostState.Pending) {
      this.invoiceFixedCostService.exportPendingFixedCostList(request).subscribe(
        () => {
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }

  buildFilterOptions() {
    if (Util.isNullOrUndefinedOrEmpty(this.invoiceFixedCosts)) return;
    const set1 = new Set(this.inclusionIdentifier1Items.map(item => item.value));
    const set2 = new Set(this.inclusionIdentifier2Items.map(item => item.value));
    const set3 = new Set(this.inclusionPreScreeningIdentifierItems.map(item => item.value));
    this.invoiceFixedCosts.forEach(ff => {
      if (ff.inclusionNumber) {
        set1.add(ff.inclusionNumber);
      }
      if (ff.inclusionScreeningIdentifier) {
        set2.add(ff.inclusionScreeningIdentifier);
      }
      if (ff.inclusionPreScreeningIdentifier) {
        set3.add(ff.inclusionPreScreeningIdentifier);
      }
    });
    this.inclusionIdentifier1Items = Array.from(set1).map(elt => new LabelValue(elt, elt, false, false));
    this.inclusionIdentifier2Items = Array.from(set2).map(elt => new LabelValue(elt, elt, false, false));
    this.inclusionPreScreeningIdentifierItems = Array.from(set3).map(elt => new LabelValue(elt, elt, false, false));
    if (Util.isNullOrUndefinedOrEmpty(this.headers)) {
      return;
    }
    this.headers.find(header => header.field === 'inclusionPreScreeningIdentifier').options = this.inclusionPreScreeningIdentifierItems;
    this.headers.find(header => header.field === 'inclusionNumber').options = this.inclusionIdentifier1Items;
    this.headers.find(header => header.field === 'inclusionScreeningIdentifier').options = this.inclusionIdentifier2Items;
  }

}



import {AdditionalCostOaImage} from './additional-cost-oa-image';

export class OperationalAdditionalCost {
  operationalActHhhId: number;
  description: string;
  contractType: string;
  type: string;
  pricePerUnit: number;
  hhhId: number;
  additionalCostType: string;
  actCategoryName: string;
  typeHhhId: number;
  contractTypeHhhId: number;

  public init(additionalCost: AdditionalCostOaImage): OperationalAdditionalCost {
    this.description = additionalCost.description;
    this.contractType = additionalCost.contractType;
    this.pricePerUnit = additionalCost.pricePerUnit;
    this.type = additionalCost.typeName;
    this.hhhId = additionalCost.hhhId;
    this.additionalCostType = additionalCost.additionalCostType;
    this.actCategoryName = additionalCost.actCategoryName;
    this.typeHhhId = additionalCost.typeHhhId;
    this.contractTypeHhhId = additionalCost.contractTypeHhhId;
    return this;
  }
}

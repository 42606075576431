export class RequestInvoiceAct<T> {
  objectId: number;
  toExcludeHhhIds: number[];
  condition: boolean;
  fromDate: string;
  toDate: string;
  filters: T;

  constructor(objectId: number, toExcludeHhhIds: number[], condition: boolean, fromDate: string, toDate: string, filters: T) {
    this.objectId = objectId;
    this.toExcludeHhhIds = toExcludeHhhIds;
    this.condition = condition;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.filters = filters;
  }
}

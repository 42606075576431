import {Injectable} from '@angular/core';
import {Properties} from '../../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TrialPageLock} from '../../../shared/entity/trial-page-lock';
import {Value} from '../../../shared/entity/value';
import * as Util from "util";

@Injectable({
  providedIn: 'root'
})
export class TrialPageLockService {
  public host = new Properties().host;

  constructor(private httpClient: HttpClient) {
  }

  private _otherId: number;

  get otherId(): number {
    return this._otherId;
  }

  set otherId(value: number) {
    this._otherId = value;
  }

  private _history: Value<string>[];

  get history(): Value<string>[] {
    return this._history;
  }

  set history(value: Value<string>[]) {
    this._history = value;
  }

  private _hasHistory = false;

  get hasHistory(): boolean {
    return this._hasHistory;
  }

  set hasHistory(value: boolean) {
    this._hasHistory = value;
  }

  private _trialId: number;

  get trialId(): number {
    return this._trialId;
  }

  set trialId(value: number) {
    this._trialId = value;
  }

  private _isLocked: boolean;

  get isLocked(): boolean {
    return this._isLocked;
  }

  set isLocked(value: boolean) {
    this._isLocked = value;
  }

  lock(trialLockPage: TrialPageLock): Observable<any> {
    return this.httpClient.put(this.host + '/trial-lock-page/lock', trialLockPage);
  }

  unlock(trialLockPage: TrialPageLock): Observable<any> {
    return this.httpClient.put(this.host + '/trial-lock-page/unlock', trialLockPage);
  }

  getHistory(): Observable<Value<string>[]> {
    if (!Util.isNullOrUndefined(this.trialId)) {
      return this.httpClient.get<Value<string>[]>(this.host + '/trial-lock-page/history/' + this.trialId);
    }
  }

  getIsLocked(): Observable<Value<boolean>> {
    if (!Util.isNullOrUndefined(this.trialId)) {
      return this.httpClient.get<Value<boolean>>(this.host + '/trial-lock-page/' + this.trialId);
    }
  }

  getOtherInCommentId(): Observable<Value<number>> {
    return this.httpClient.get<Value<number>>(this.host + '/trial-lock-page/other-id');
  }
}

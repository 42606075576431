import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';

import {Observable} from 'rxjs';
import {Value} from '../../shared/entity/value';
import {LastDateList} from '../models/LastDateList';

@Injectable({
  providedIn: 'root'
})
export class LastDateService {
  public host = new Properties().host + '/last-date-list/';

  constructor(private httpClient: HttpClient) {
  }

  saveModificationsANSM(lastDateList: LastDateList[], trialId: number): Observable<Value<string>> {
    return this.httpClient.post<Value<string>>(this.host + 'ANS/' + trialId , lastDateList);
  }
  saveModificationsCPP(lastDateList: LastDateList[], trialId: number): Observable<Value<string>> {
    return this.httpClient.post<Value<string>>(this.host + 'CPP/' + trialId , lastDateList);
  }
  saveModificationCSET(lastDateList: LastDateList[], trialId: number): Observable<Value<string>> {
    return this.httpClient.post<Value<string>>(this.host + 'CSET/' + trialId , lastDateList);
  }
  saveModificationDRC(lastDateList: LastDateList[], trialId: number): Observable<Value<string>> {
    return this.httpClient.post<Value<string>>(this.host + 'DRC/' + trialId , lastDateList);
  }
  getANSM(trialId: number): Observable<LastDateList[]> {
    return this.httpClient.get<LastDateList[]>(this.host + 'ANSM/' + trialId);
  }
  getCPP(trialId: number): Observable<LastDateList[]> {
    return this.httpClient.get<LastDateList[]>(this.host + 'CPP/' + trialId);
  }
  getCSET(trialId: number): Observable<LastDateList[]> {
    return this.httpClient.get<LastDateList[]>(this.host + 'CSET/' + trialId);
  }
  getDRC(trialId: number): Observable<LastDateList[]> {
    return this.httpClient.get<LastDateList[]>(this.host + 'DRC/' + trialId);
  }

  delete(trialId, lastDateListHhhId: number): Observable<Value<string>> {
    return this.httpClient.delete<Value<string>>(this.host + trialId + '/' + lastDateListHhhId);
  }
}

import {JsonObject, JsonProperty} from 'json2typescript';
import {DataFolder} from './data-folder';

@JsonObject('DataDocument')
export class DataDocument {

  @JsonProperty('hhhId', Number)
  hhhId: number = undefined;

  @JsonProperty('attachedTo', String)
  attachedTo: string = undefined;

  @JsonProperty('attachedToId', Number)
  attachedToId: number = undefined;

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('uuid', String)
  uuid: string = undefined;

  @JsonProperty('creationDate', Boolean)
  creationDate: Date = undefined;

  @JsonProperty('ownerHhhId', Number)
  ownerHhhId: number = undefined;

  @JsonProperty('ownerName', Number)
  ownerName: number = undefined;

  @JsonProperty('parent', Date)
  parent: DataFolder = undefined;

  @JsonProperty('deletable', Boolean)
  deletable: boolean = undefined;



  constructor() {
  }

}

export class DataCommentInfo {
  attachedTo: string;
  attachedToId: number;
  attachedToField: string;
  isPrivate: boolean;

  constructor(attachedTo: string, attachedToId: number, attachedToField: string, isPrivate: boolean) {
    this.attachedTo = attachedTo;
    this.attachedToId = attachedToId;
    this.attachedToField = attachedToField;
    this.isPrivate = isPrivate;
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {User} from '../models/user';
import {UsersService} from '../services/users.service';
import {Data} from '../../shared/entity/data';
import {NgForm} from '@angular/forms';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {NewPassword} from '../models/newPassword';
import {SharedService} from '../../shared/services/shared.service';
import {Router} from '@angular/router';

@Component({
  selector: 'ih-current-user',
  templateUrl: './current-user.component.html',
  styleUrls: ['./current-user.component.css']
})
export class CurrentUserComponent implements OnInit {
  @ViewChild('changePasswordForm') changePasswordForm: NgForm;
  public displayInformation = true;
  public displayPasswordChange = false;
  public id;
  public user: User = new User();
  public titles: any;
  public newPassword: NewPassword = new NewPassword();
  passwordScore = 0;
  isPasswordContainsUppercase = false;
  isPasswordContainsLowercase = false;
  isPasswordContainsNumeric = false;
  isPasswordContainsSpecialCharacter = false;
  isPasswordLengthValid = false;


  constructor(private userService: UsersService,
              private translateService: InnohealthTranslateService,
              private sharedService: SharedService,
              private router: Router) {
    this.id = localStorage.getItem('authenticatedUserID');
  }

  ngOnInit() {
    this.getUser();
    this.titles = Data.userTitles;
  }

  changePassword() {
    this.newPassword.userHhhId = this.id;
    if (!this.isPasswordValid()) {
      return;
    }
    this.userService.changeCurrentUserPassword(this.newPassword).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      let errorMessage: string;
      if (error.error === 'old password incorrect') {
        errorMessage = this.translateService.getTranslationString('MODULE_USER_ADMIN_CHANGE_PASSWORD_OLD_INCORRECT');
      } else if (error.error === 'old password contains new one') {
        errorMessage = this.translateService.getTranslationString('MODULE_USER_ADMIN_CHANGE_PASSWORD_NEW_PASSWORD_CONTAINS_OLD_ONE');
      }
      this.sharedService.showFailure(errorMessage);
    });
  }

  getUser() {
    this.userService.getCurrentUser().subscribe(res => {
      this.user = res;
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while loading user.' + error);
    });
  }

  updateUser() {
    this.user.hhhId = this.id;
    this.userService.updateCurrentUser(this.user).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure('');
      console.error('An error occurred while loading user.' + error);
    });
  }

  goToHomePage() {
    this.router.navigate(['/home']).then();
  }

  passwordUpdated() {
    this.passwordScore = this.userService.calculatePasswordStrength(this.newPassword.password1);
    this.isPasswordContainsUppercase = this.userService.isPasswordContainsUppercase(this.newPassword.password1);
    this.isPasswordContainsLowercase = this.userService.isPasswordContainsLowercase(this.newPassword.password1);
    this.isPasswordContainsNumeric = this.userService.isPasswordContainsNumeric(this.newPassword.password1);
    this.isPasswordContainsSpecialCharacter = this.userService.isPasswordContainsSpecialCharacter(this.newPassword.password1);
    this.isPasswordLengthValid = this.userService.isPasswordLengthValid(this.newPassword.password1);
  }

  private isPasswordValid() {
    return this.newPassword.password1 && this.newPassword.password2 && this.newPassword.password1 === this.newPassword.password2 && this.passwordScore === 100;
  }
}

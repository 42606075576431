import {Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {DataExportService, ExportButtonStatus, ExportStatusEnum} from '../../service/data-export.service';
import {AppData} from '../../../helpers/app-data';
import {SharedService} from '../../../shared/services/shared.service';
import {ExportDialogComponent} from '../export-dialog/export-dialog.component';
import {TableHeaderConfig} from '../../entity/table-header-config';
import {isNullOrUndefined} from 'util';
import {Util} from '../../../helpers/util';
import {StompService} from '../../../shared/services/stomp.service';
import {StompSubscription} from '@stomp/stompjs/src/stomp-subscription';


@Component({
  selector: 'ih-data-export',
  templateUrl: './data-export.component.html',
  styleUrls: ['./data-export.component.css']
})

export class DataExportComponent implements OnInit, OnDestroy {
  @ViewChild('customExportDialog', {read: ViewContainerRef}) customExportDialog: ViewContainerRef;

  private contextId: number;
  status = ExportButtonStatus.READY_TO_EXPORT;
  fileUUID: string;
  fileExtension = '.xlsx';

  private dataExportSubscription: StompSubscription;

  items = [];

  @Input() dialogTitle: string;
  @Input() target: string;
  @Input() filename: string;
  @Input() availableHeaders: Map<string, TableHeaderConfig> = new Map();
  @Input() showSingleExportOption = true; // hide single export option for non-lazy tables
  @Output() exportFunction = new EventEmitter<{ contextId: number, exportType: number, header: string[] }>();


  constructor(
      private sharedService: SharedService,
      private dataExportService: DataExportService,
      private stompService: StompService,
      private cfr: ComponentFactoryResolver) {
  }

  ngOnInit() {
    this.contextId = ++AppData.contextId;
    this.stompService.isConnected.subscribe(connected => {
      if (connected) {
        this.subscribeToExportDataWebSocket();
      }
    });
  }

  subscribeToExportDataWebSocket(): void {
    this.stompService.subscribe('/topic/data-export/' + AppData.socketId + '/context/' + this.contextId, (res) => {
      const response = JSON.parse(res);
      if (response.status === ExportStatusEnum.SUCCESS) {
        this.fileUUID = response.fileUUID;
        this.status = ExportButtonStatus.READY_TO_DOWNLOAD;
        this.fileExtension = response.fileExtension;
      } else if (response.status === ExportStatusEnum.FAILED) {
        this.status = ExportButtonStatus.READY_TO_EXPORT;
      }
    }, subscription => {
      this.dataExportSubscription = subscription;
    });
  }

  download() {
    if (Util.isStringNullOrEmpty(this.fileUUID)) {
      return;
    }
    const fileExtension = this.getFileExtension();
    this.dataExportService.downloadFile(this.fileUUID, fileExtension).subscribe((data: Blob) => {
          saveAs(data, `${this.filename}-${new Date().toISOString().slice(0, 10)}.${fileExtension}`);
          this.status = ExportButtonStatus.READY_TO_EXPORT;
          this.dataExportService.deleteFile(this.fileUUID).subscribe();
        },
        error => this.sharedService.showFailure());
  }

  ngOnDestroy() {
    if (this.dataExportSubscription) {
      this.stompService.unsubscribe(this.dataExportSubscription);
    }
  }


  public displayCustomExportDialog(): void {
    const customExportDialog = ExportDialogComponent.displayFormDialogue(this.customExportDialog, this.cfr);
    customExportDialog.target = this.target;
    customExportDialog.formHeader = this.dialogTitle;
    customExportDialog.showSingleExportOption = this.showSingleExportOption;
    customExportDialog.onAdd(exportEvent => {
      if (isNullOrUndefined(exportEvent)) {
        return;
      }
      this.emitExportEvent(exportEvent);
    });
  }

  emitExportEvent(event: any): void {
    event.contextId = this.contextId;
    this.exportFunction.emit(event);
    this.status = ExportButtonStatus.EXPORTING;
  }

  getFileExtension(): string  {
    return this.fileExtension === '.csv' ? 'csv' : 'xlsx';
  }


}

import {Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {ReferenceAct} from '../models/reference-act';
import {SelectItem} from 'primeng/api';
import {ReferenceActService} from '../services/reference-act.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../shared/services/data-service';
import {SharedService} from '../../shared/services/shared.service';
import {ReferenceActFormComponent} from '../reference-act-form/reference-act-form.component';
import {TranslateService} from '@ngx-translate/core';
import {TrialActService} from '../../trial/service/trial-act.service';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {DeleteMultipleComponent} from '../../modals/delete-multiple/delete-multiple.component';
import {NoSelectedItemFormComponent} from '../../modals/no-selected-item-form/no-selected-item-form.component';
import {Data} from '../../shared/entity/data';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {UsersService} from '../../admin/services/users.service';
import {HiddenFields} from '../../admin/models/HiddenFields';
import {OperationalActService} from '../services/operational-act.service';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {AppData} from '../../helpers/app-data';
import {DataItemService} from '../../custom-field/service/data-item.service';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-reference-acts',
  templateUrl: './reference-acts.component.html',
  styleUrls: ['./reference-acts.component.css']
})
export class ReferenceActsComponent extends PaginatorTableComponent<ReferenceAct> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.ReferenceAct;
  target = DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_ACTS_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  @ViewChild('popupDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @Input() scrollable: boolean;
  _fromOptionalActs: boolean;
  @Input() rowClickable = false;
  @Input() showCheckbox = false;
  @Input() displayButtons = true;
  @Input() selectable = true;
  @Output() displaySelectedActs: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClick: EventEmitter<any> = new EventEmitter<any>();

  trialHhhId: number;
  selectedReferenceActList: ReferenceAct [] = [];
  actDescriptionFilterList: SelectItem[] = [];
  noYesOptions: SelectItem[];
  filterVar: string;
  userRole: string;
  contractTypeFilterList: SelectItem[] = [];
  typeFilterList: SelectItem[] = [];
  externalFromCenterFilterList: SelectItem[] = [];
  invoiceableFilterList: SelectItem[] = [];
  actOptionalFilterList: SelectItem[] = [];
  onPrescriptionFilterList: SelectItem[] = [];
  actCategoryFilterList: SelectItem[] = [];
  freeSSFilterList: SelectItem[] = [];

  contractTypes: SelectItem[] = [];

  constructor(
      private referenceActService: ReferenceActService,
      private sharedService: SharedService,
      private useService: UsersService,
      private translate: TranslateService,
      private translateService: InnohealthTranslateService,
      private operationalActService: OperationalActService,
      private router: Router,
      private trialActService: TrialActService,
      private cfr: ComponentFactoryResolver,
      private dataService: DataService,
      private route: ActivatedRoute,
      private dataItemService: DataItemService,
      private dynamicConfigService: DynamicConfigService,
  ) {
    super(dataService, cfr);
    this.userRole = localStorage.getItem('authenticatedUserRole');
  }

  get fromOptionalActs(): boolean {
    return this._fromOptionalActs;
  }

  @Input()
  set fromOptionalActs(value: boolean) {
    if (value) {
      this.target = DynamicDefinitionEnum.ADDITIONAL_COSTS_INCLUSION_ACTS_LIST_TABLE;
    }
    this._fromOptionalActs = value;
  }

  getContractType(): Promise<SelectItem[]> {
    return this.dataItemService.getItems('contract-type-selected').toPromise();
  }


  ngOnInit() {
    super.ngOnInit();
    this.getNoYesOptions();
    this.trialHhhId = +localStorage.getItem('trialHhhId');
    if (this._fromOptionalActs) {
      this.getReferenceActsAndFixedCosts();
    } else {
      this.getReferenceActList();
    }
    this.initFilterVar();
    this.initTableConfig();
  }


  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, AdditionalCostsAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  async buildTableHeaders(): Promise<void> {
    const availableHeaders = [];
    await this.getContractType().then(res => {
      this.contractTypes = res;
    });
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('contractType')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .filter('contractTypeHhhId')
        .options(this.contractTypes)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('actCategoryName')
        .displayContent((referenceAct: ReferenceAct) => {
          return referenceAct.actCategoryName;
        })
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actCategoryFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('description')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actDescriptionFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('onPrescription')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.onPrescriptionFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('externalFromCentre')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.externalFromCenterFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('invoiceable')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.invoiceableFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .accessible(
            this.dynamicConfigService.verifyClient(['ccgm', 'col', 'ghtmb', 'igr']) ?
                this.useService.canAccessField(HiddenFields.ACTE_PRICE)
                : true)
        .field('pricePerUnit')
        .withDisplayCssClassContent()
        .cssClass((() => {
          return 'right-align';
        }))
        .displayContent((data: ReferenceAct) => {
          return Number(data.pricePerUnit).toFixed(2) + ' €';
        })
        .sortable()
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('internalAccountNumber')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('firstSegment')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('secondSegment')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('freeSS')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.freeSSFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('nomenclature')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('type')
        .filterable()
        .filter('typeHhhId')
        .filterType(FilterType.DROPDOWN)
        .options(this.typeFilterList)
        .displayContent((act: ReferenceAct) => act.typeName)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('optional')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actOptionalFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('secondInternalAccountNumber')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers = this.config.buildTable(availableHeaders);
    console.log(this.typeFilterList);
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.setItem('trialHhhId', '0');
  }

  getExportPageModule(): string {
    return 'src/app/additional-costs/reference-acts/reference-acts.component';
  }

  getAddTitle(): string {
    return 'MODULE_REFERENCE_ACTS_GENERAL_ADD_NEW';
  }

  getUpdateTitle(): string {
    return 'MODULE_REFERENCE_ACTS_GENERAL_UPDATE';
  }

  getDeleteMessage(): string {
    return 'MODULE_REFERENCE_ACTS_DIALOG_WARNING_SITE_WILL_BE_DELETED';
  }

  getService(): any {
    return this.referenceActService;
  }

  getNoYesOptions() {
    this.translate.stream('GENERAL_LINGUISTICS_YES_UFCASE').subscribe(() => {
      this.noYesOptions = Data.yesOrNoOrNeither;
    });
  }

  updateSelectedList(selectedList: ReferenceAct[]) {
    this.selectedReferenceActList = selectedList;
    if (this._fromOptionalActs) {
      this.displaySelectedActs.emit(selectedList);
    }
  }

  getReferenceActList() {
    this.route.queryParams.subscribe(
        params => {
          this.filterVar = params.actCategory || '';
        });
    if (this.trialHhhId) {
      this.referenceActService.getReferenceActListForTrial(this.trialHhhId).subscribe(
          res => {
            this.referenceActService.setReferenceActListToExport(res);
            if (!this.filterVar) {
              this.values = res;
            } else {
              this.values = res.filter((a: ReferenceAct) => (a.actCategoryName === this.filterVar));
              this.dataService.changeFilterVar(null);
            }
            this.totalNumber = this.values.length;
            this.buildFiltersOptions(res);
          }, error => {
            console.error('An error occurred while loading reference Act.' + error);
          });
    } else {
      this.referenceActService.getReferenceActList().subscribe(
          res => {
            console.log(res);
            this.referenceActService.setReferenceActListToExport(res);
            if (!this.filterVar) {
              this.values = res;
            } else {
              this.values = res.filter((a: ReferenceAct) => (a.actCategoryName === this.filterVar));
              this.dataService.changeFilterVar(null);
            }
            this.totalNumber = this.values.length;
            this.buildFiltersOptions(res);
          }, error => {
            console.error('An error occurred while loading reference Act.' + error);
          });
    }
  }

  getReferenceActsAndFixedCosts() {
    this.operationalActService.getReferenceActsAndFixedCosts(this.trialHhhId).subscribe(res => {
      this.totalNumber = res.length;
      this.values = res.map(act => new ReferenceAct().buildReferenceAct(act));
      this.buildFiltersOptions(res);
    });
  }

  addAct() {
    if (this.trialHhhId === 0) {
      this.displayAddActFormDialog();
    } else {
      this.addActToTrial();
    }
  }

  displayAddActFormDialog(referenceAct?: ReferenceAct) {
    const formComponent = ReferenceActFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.noYesOptions = this.noYesOptions;
    if (referenceAct) {
      referenceAct = new ReferenceAct().init(referenceAct);
      const referenceActToEdit: ReferenceAct = Object.assign({}, referenceAct);
      formComponent.dialogReferenceActHeader = this.getUpdateTitle();
      formComponent.oldReferenceAct = referenceActToEdit;
      formComponent.referenceAct = referenceAct;
      formComponent.onAdd(this.updateList(referenceAct));
    } else {
      formComponent.dialogReferenceActHeader = this.getAddTitle();
      formComponent.onAdd(this.updateList());
    }
  }

  updateList(referenceActToEdit?: ReferenceAct) {
    if (referenceActToEdit) {
      return (newReferenceAct: ReferenceAct) => {
        const index = this.values.indexOf(this.values.find(val => val.hhhId === referenceActToEdit.hhhId));
        this.values[index] = newReferenceAct;
      };
    } else {
      return (act: ReferenceAct) => {
        this.values.push(act);
        this.values.sort((a: any, b: any) => a.actCategoryName.toLowerCase().localeCompare(b.actCategoryName.toLowerCase()));
        const itemDescription = {
          label: act.description,
          value: act.description
        };
        this.actDescriptionFilterList.push(itemDescription);
        this.buildFiltersOptions(this.values);
      };
    }
  }

  addActToTrial() {
    const referenceActHhhIds = [];
    for (const referenceAct of this.selectedReferenceActList) {
      referenceActHhhIds.push(referenceAct.hhhId);
    }
    if (referenceActHhhIds.length === 0) {
      this.displayNoActSelected();
    } else {
      this.trialActService.addActsToTrial(this.trialHhhId, referenceActHhhIds).subscribe(() => {
        this.sharedService.showSuccess();
        this.router
            .navigate(['/trial-details/acts-grid'], {queryParams: {hhhId: this.trialHhhId}})
            .then();
      });
    }
  }

  openDeleteReferenceActDialog() {
    if (this.selectedReferenceActList.length === 0) {
      this.displayNoActSelected();
    } else {
      this.displayDeleteMultiple();
    }
  }

  displayDeleteMultiple() {
    const formComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    formComponent.service = this.referenceActService;
    formComponent.popupMessage = 'MODULE_REFERENCE_ACTS_DIALOG_WARNING_SELECTED_REFERENCE_ACT_WILL_BE_DELETED';
    formComponent.selectedList = this.selectedReferenceActList;
    formComponent.navigationRoute = '/trial-details/acts-grid';
    formComponent.onDelete((deletedItem: number[]) => {
      for (const hhhId of deletedItem) {
        const index = this.values.findIndex((referenceAct: ReferenceAct) => referenceAct.hhhId === hhhId);
        this.sharedService.setInformationInLocalStorage('Grille budgétaire: Grille des actes', 'Supprimer', this.values[index].description);
        this.values.splice(index, 1);
      }

      this.updateFilterOptions(this.values);
      this.buildTableHeaders();
    });
  }

  displayNoActSelected() {
    const formComponent = NoSelectedItemFormComponent.displayFormDialogue(this.popupContainer, this.cfr);
    formComponent.header = 'UI_DIALOG_HEADER_NO_ACT_SELECTED';
  }

  initFilterVar() {
    this.dataService.currentFilterVar.subscribe(res => {
      this.filterVar = res;
    });
  }

  buildFiltersOptions(event) {
    for (const act of event) {
      const descItem = {label: act.description, value: act.description};
      const contractTypeItem = {label: act.contractType, value: act.contractTypeHhhId};
      const typeItem = {label: act.typeName, value: act.typeHhhId};
      const actCategItem = {label: act.actCategoryName, value: act.actCategoryName};
      const externalFromCenterItem = {
        label: act.externalFromCentre != null ? this.translateService.getTranslationString(act.externalFromCentre.toString()) : '',
        value: act.externalFromCentre
      };
      const invoiceableItem = {
        label: act.invoiceable != null ? this.translateService.getTranslationString(act.invoiceable.toString()) : '',
        value: act.invoiceable
      };
      const optionalItem = {
        label: act.optional != null ? this.translateService.getTranslationString(act.optional.toString()) : '',
        value: act.optional
      };
      const onPrescriptionItem = {
        label: (act.onPrescription !== null && act.onPrescription !== undefined) ? this.translateService.getTranslationString(act.onPrescription.toString()) : '',
        value: act.onPrescription
      };
      const freeSSItem = {
        label: (act.freeSS !== null && act.freeSS !== undefined) ? this.translateService.getTranslationString(act.freeSS.toString()) : '',
        value: act.freeSS
      };
      if (act.contractType != null &&
          this.contractTypeFilterList.findIndex((item: SelectItem) => item.label === contractTypeItem.label) === -1) {
        this.contractTypeFilterList.push(contractTypeItem);
      }
      if (act.typeHhhId != null &&
          this.typeFilterList.findIndex((item: SelectItem) => item.value === typeItem.value) === -1) {
        this.typeFilterList.push(typeItem);
      }
      if (act.description != null &&
          this.actDescriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
        this.actDescriptionFilterList.push(descItem);
      }
      if (act.actCategoryName != null &&
          this.actCategoryFilterList.findIndex((item: SelectItem) => item.value === actCategItem.value) === -1) {
        this.actCategoryFilterList.push(actCategItem);
      }
      if (act.externalFromCentre != null &&
          this.externalFromCenterFilterList.findIndex((item: SelectItem) => item.value === externalFromCenterItem.value) === -1) {
        this.externalFromCenterFilterList.push(externalFromCenterItem);
      }
      if (act.invoiceable != null &&
          this.invoiceableFilterList.findIndex((item: SelectItem) => item.value === invoiceableItem.value) === -1) {
        this.invoiceableFilterList.push(invoiceableItem);
      }
      if (act.optional != null &&
          this.actOptionalFilterList.findIndex((item: SelectItem) => item.value === optionalItem.value) === -1) {
        this.actOptionalFilterList.push(optionalItem);
      }
      if (act.onPrescription != null &&
          this.onPrescriptionFilterList.findIndex((item: SelectItem) => item.value === onPrescriptionItem.value) === -1) {
        this.onPrescriptionFilterList.push(onPrescriptionItem);
      }
      if (act.freeSS != null && this.freeSSFilterList.findIndex((item: SelectItem) => item.value === freeSSItem.value) === -1) {
        this.freeSSFilterList.push(freeSSItem);
      }
    }
    this.actDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.contractTypeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.typeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.actCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.actOptionalFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.externalFromCenterFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.invoiceableFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.onPrescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.freeSSFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  updateFilterOptions(event) {
    this.actDescriptionFilterList = [];
    for (const act of event) {
      const descItem = {label: act.description, value: act.description};
      if (act.description != null) {
      this.actDescriptionFilterList.push(descItem);
    }
    }
    this.actDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  onActClickEvent(data: any) {
    if (this.rowClickable) {
      this.rowClick.emit(data);
    }
  }

  exportReferenceActList(event: any): void {
    const request = new ExportRequestBuilder<void>()
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
        .build();
    this.referenceActService.exportReferenceActs(request).subscribe(
        (res) => {},
        (err) => {
          console.error(err);
        });
  }

}

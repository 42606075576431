import {TableConfig} from '../dynamic-config/entity/table-config';
import {FormConfig} from '../dynamic-config/entity/form-config';

export class PatientAppData {

  public static formConfig: Map<string, FormConfig> = new Map<string, FormConfig>();
  public static tableConfig: Map<string, TableConfig> = new Map<string, TableConfig>();

  public static removeCache(type: string, target: string) {
    if (type === 'form') {
      PatientAppData.formConfig.delete(target);
    } else if (type === 'table') {
      PatientAppData.tableConfig.delete(target);
    }
  }
}

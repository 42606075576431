import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {TheoreticalCalendarActsComponent} from '../theoretical-calendar-acts/theoretical-calendar-acts.component';
import {MenuItem, SelectItem, SelectItemGroup} from 'primeng/api';
import {TrialActService} from '../../../service/trial-act.service';
import {TheoreticalCalendar} from '../../../entity/theoretical-calendar/theoretical-calendar';
import {TheoreticalCalendarService} from '../../../service/theoretical-calendar.service';
import {DataService} from '../../../../shared/services/data-service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {AmendmentDialogComponent} from '../amendment-dialog/amendment-dialog.component';
import {SharedService} from '../../../../shared/services/shared.service';
import {TheoreticalCalendarActsAddComponent} from '../theoretical-calendar-acts-add/theoretical-calendar-acts-add.component';
import {VisitAdditionalCost} from '../../../entity/trial-additionnal-costs/visit-additional-cost';
import {MinimizedTrialProtocol} from '../../../models/minimized-trial-protocol';
import {TrialProtocolService} from '../../../services/trial-protocol.service';
import {isNullOrUndefined} from 'util';
import {TheoreticalVisitInInclusion} from '../../../entity/theoretical-calendar/theoretical-visit-in-inclusion';
import {ArmService} from '../../../services/arm.service';
import {Arm} from '../../../entity/inclusion/arm';
import {TrialService} from '../../../services/trial.service';
import {TranslateService} from '@ngx-translate/core';
import {ACT_GRID_READ_WRITE, COUNTER_PART_REFERENCE_READ_WRITE, FIXED_COST_READ_WRITE, OPERATIONAL_ACT_READ_WRITE} from '../../../../login/services/authentication.service';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-synthetic-theoretical-calendar',
  templateUrl: './synthetic-theoretical-calendar.component.html',
  styleUrls: ['./synthetic-theoretical-calendar.component.css']
})
export class SyntheticTheoreticalCalendarComponent implements OnInit {

  // properties
  trialActIsUsedOnlyOnce = false;
  amendmentsEnabled = true;
  operationalActEnabled = true;
  financeViewEnabled = false;

  @ViewChild('calendarActs') calendarActs: TheoreticalCalendarActsComponent;
  @ViewChild('amendmentDialog', {read: ViewContainerRef}) amendmentDialogContainer: ViewContainerRef;

  trialHhhId: number;
  additionalCosts: VisitAdditionalCost[];
  actDescriptionFilterList: SelectItem[] = [];
  actCategoryFilterList: SelectItem[] = [];
  additionalCostTypes: SelectItem[] = [];

  theoreticalCalendar: TheoreticalCalendar;
  updateActs = true;
  protocol: MinimizedTrialProtocol = new MinimizedTrialProtocol();
  usedProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  usedProtocolsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  allProtocolsGrouped: Array<SelectItemGroup> = new Array<SelectItemGroup>();
  unusedProtocolsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  allProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  showEmptyProtocolWarning = false;
  selectedProtocol: number;
  targetProtocol: number;
  disabledArms: Array<number> = new Array<number>();
  hasAnyActPermission = false;
  arcFinanceMenuItems: MenuItem[];
  isArcViewActive = true;

  constructor(private router: Router,
              private trialActService: TrialActService,
              private theoreticalCalendarService: TheoreticalCalendarService,
              private dataService: DataService,
              private canDeactivateService: CanDeactivateService,
              private cfr: ComponentFactoryResolver,
              private sharedService: SharedService,
              private translate: TranslateService,
              public trialService: TrialService,
              private armService: ArmService,
              private trialProtocolService: TrialProtocolService,
              private dynamicConfigService: DynamicConfigService,
              public trialPageLockService: TrialPageLockService) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.arcFinanceMenuItems = [
      {
        label: 'Vue Finance', command: (event) => {
          this.isArcViewActive = false;
          this.getTrialActs();
        }
      },
      {
        label: 'Vue ARC', command: (event) => {
          this.isArcViewActive = true;
          this.getTrialActs();
        }
      }
    ];
    this.hasAnyActPermission = ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(ACT_GRID_READ_WRITE)) >= 0 ||
      ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(FIXED_COST_READ_WRITE)) >= 0 ||
      ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(COUNTER_PART_REFERENCE_READ_WRITE)) >= 0 ||
      ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(OPERATIONAL_ACT_READ_WRITE)) >= 0;
    this.initProperties().then(() => {
      this.initializeProtocolVersionGroups();
      this.getUnusedProtocols();
      this.initTheoreticalCalendar();
    });
    this.actDescriptionFilterList = [{label: '', value: null}];
    this.actCategoryFilterList = [{label: '', value: null}];
    this.additionalCostTypes = [{label: '', value: null}];
  }

  initProperties(): Promise<any> {
    return new Promise((resolver: any) => {
      this.dynamicConfigService.initProperties().subscribe(() => {
        this.trialActIsUsedOnlyOnce = this.dynamicConfigService.getProperty(PropertyEnum.trialActIsUsedOnlyOnce);
        this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
        this.operationalActEnabled = this.dynamicConfigService.getProperty(PropertyEnum.operationalActEnabled);
        this.financeViewEnabled = this.dynamicConfigService.getProperty(PropertyEnum.financeViewEnabled);
        resolver();
      });
    });
  }

  getTheoreticalCalendar(trialProtocolHhhId?: number, callback?: () => void) {
    trialProtocolHhhId = trialProtocolHhhId === undefined ? -1 : trialProtocolHhhId;
    this.theoreticalCalendarService.getTheoreticalCalendarByTrial(this.trialHhhId, trialProtocolHhhId, true).subscribe((theoreticalCalendar: TheoreticalCalendar) => {
      theoreticalCalendar.theoreticalVisitBeforeInclusionList.sort((theoreticalVisit1, theoreticalVisit2) => {
        return (+theoreticalVisit1.displayHowManyDays.substring(1) < +theoreticalVisit2.displayHowManyDays.substring(1)
          || (theoreticalVisit2.displayHowManyDays.substring(2) === 'Aucun')) ? -1 : 1;
      });
      theoreticalCalendar.theoreticalVisitAfterInclusionList.sort((theoreticalVisit1, theoreticalVisit2) => {
        return (+theoreticalVisit1.displayHowManyDays.substring(1) >= +theoreticalVisit2.displayHowManyDays.substring(1)
          || (theoreticalVisit2.displayHowManyDays.substring(2) === 'Aucun')) ? 1 : -1;
      });
      for (const theoreticalVisitInInclusionDto of theoreticalCalendar.theoreticalVisitInInclusionDtoList) {
        theoreticalVisitInInclusionDto.theoreticalVisits.sort((theoreticalVisit1, theoreticalVisit2) => {
          return (+theoreticalVisit1.displayHowManyDays.substring(1) >= +theoreticalVisit2.displayHowManyDays.substring(1)
            || (theoreticalVisit2.displayHowManyDays.substring(2) === 'Aucun')) ? 1 : -1;
        });
      }
      this.extractVisitsFromCalendar(theoreticalCalendar);
      this.getTrialActs();
      this.dataService.fromOperationalActsAction.subscribe(res => {
        if (res) {
          this.AddNewAct();
        }
      });
      if (!isNullOrUndefined(this.calendarActs)) {
        this.calendarActs.theoreticalCalendar = this.theoreticalCalendar;
        this.calendarActs.setVisitTypes();
      }
      if (callback) {
        callback();
      }
    });
  }

  extractVisitsFromCalendar(theoreticalCalendar: TheoreticalCalendar) {
    this.theoreticalCalendar = theoreticalCalendar;
    this.theoreticalCalendar.theoreticalVisitDtoList = [];
    this.theoreticalCalendar.theoreticalVisitToEditDtoList = [];
    this.theoreticalCalendar.theoreticalVisitActsToDeleteHhhIds = [];
    this.theoreticalCalendar.theoreticalVisitToDeleteDtoList = [];
    this.theoreticalCalendar.theoreticalVisitActsToEdit = [];
    this.theoreticalCalendar.theoreticalVisitActsToAdd = [];
    this.theoreticalCalendar.theoreticalVisitToCloneDtoList = [];
    this.theoreticalCalendar.trialActsToRemoveHhhIds = [];
    this.theoreticalCalendar.trialActsToEdit = [];
    this.theoreticalCalendar.armsToEdit = [];
    this.theoreticalCalendar.armsHhhIdsToRemove = [];
  }

  getTrialActs() {
    this.trialActService.getTrialAdditionalCostList(this.trialHhhId, this.protocol.hhhId, this.isArcViewActive).subscribe(
      res => {
        this.additionalCosts = res;
        for (const act of this.additionalCosts) {
          if (!this.actDescriptionFilterList.find(item => item.value === act.additionalCostName)) {
            this.actDescriptionFilterList.push({label: act.additionalCostName, value: act.additionalCostName});
          }
          if (!this.actCategoryFilterList.find(item => item.value === act.additionalCostCategoryName)) {
            this.actCategoryFilterList.push({
              label: act.additionalCostCategoryName,
              value: act.additionalCostCategoryName
            });
          }

          const getAdditionalCostLabel: (val: string) => string = val => {
            if (val === 'ACT') {
              return 'AC';
            }
            if (val === 'FIXED_COST') {
              return 'C/S';
            }
            if (val === 'OPERATIONAL_ACT') {
              return 'AO';
            }
            if (val === 'COUNTER_PART') {
              return 'CP';
            }
            return 'NA';
          };
          if (act.additionalCostType != null && this.additionalCostTypes.findIndex((item: SelectItem) => item.value === act.additionalCostType) < 0) {
            this.additionalCostTypes.push({
              label: getAdditionalCostLabel(act.additionalCostType),
              value: act.additionalCostType
            });
          }

        }
      }
    );
  }

  saveAmendment() {
    this.canDeactivateService.canBeDeactivated = true;
    this.displayAmendmentDialogue();
  }

  displayAmendmentDialogue() {
    const amendmentComponent = AmendmentDialogComponent.displayDialogue(this.amendmentDialogContainer, this.cfr);
    amendmentComponent.theoreticalCalendar = this.theoreticalCalendar;
    amendmentComponent.trialId = this.trialHhhId;
    if (this.targetProtocol) {
      amendmentComponent.disableAll = true;
      this.theoreticalCalendar.trialProtocolHhhId = this.targetProtocol;
      amendmentComponent.updateType = 'saveAfter';
    }
    amendmentComponent.onSave((trialProtocolHhhId) => {
      this.updateActs = false;
      this.initializeProtocolVersionGroups();
      this.getTheoreticalCalendar(trialProtocolHhhId);
      this.getUsedProtocols();
      this.getUnusedProtocols();
      this.protocol.hhhId = trialProtocolHhhId;
    });
  }

  AddNewAct() {
    const actsComponent = TheoreticalCalendarActsAddComponent.displayDialogue(this.amendmentDialogContainer, this.cfr);
    actsComponent.trialHhhId = this.trialHhhId;
    actsComponent.allTrialActs = this.additionalCosts;
    actsComponent.isArcViewActive = this.isArcViewActive;
    actsComponent.trialActIsUsedOnlyOnce = this.trialActIsUsedOnlyOnce;
    actsComponent.onSave((trialActs: VisitAdditionalCost[], allTrialActs: VisitAdditionalCost[]) => {
      this.calendarActs.acts = trialActs;
      this.getTrialActs();
      if (this.trialActIsUsedOnlyOnce) {
        this.getTheoreticalCalendar(this.selectedProtocol);
      }
      this.calendarActs.actDescriptionFilterList = this.actDescriptionFilterList;
    });
  }

  getUsedProtocols(targetProtocol?: MinimizedTrialProtocol) {
    this.theoreticalCalendarService.getRelatedProtocolsByTrial(this.trialHhhId).subscribe(result => {
      this.usedProtocols = result;
      if (this.usedProtocolsSelectItems.length > 0) {
        this.usedProtocolsSelectItems.splice(0, this.usedProtocolsSelectItems.length);
      }
      this.usedProtocols.forEach(up => this.usedProtocolsSelectItems.push({
        label: up.version ? ('Version du protocole : ' + up.version) : ('Version de l\'amendement : ' + up.amendmentVersion),
        value: up.hhhId
      }));
      this.allProtocolsGrouped[0].items = this.usedProtocolsSelectItems;
      this.checkIfLastProtocolExists();
      if (this.usedProtocols.length > 0) {
        if (!targetProtocol) {
          this.initProtocolToLatest();
        }
        this.getTheoreticalCalendar(this.protocol.hhhId);
      } else {
        this.getTheoreticalCalendar();
      }
    }, error => {
      this.sharedService.showFailure();
    });
  }

  getUnusedProtocols() {
    this.trialProtocolService.getNonUnusedInTheoreticalCalendar(this.trialHhhId).subscribe(res => {
      if (this.usedProtocolsSelectItems.length > 0) {
        this.unusedProtocolsSelectItems.splice(0, this.usedProtocolsSelectItems.length);
      }
      for (const pr of res) {
        this.unusedProtocolsSelectItems.push({
          label: pr.version ? ('Version du protocole : ' + pr.version) : ('Version de l\'amendement : ' + pr.amendmentVersion),
          value: pr.hhhId
        });
      }
      this.allProtocolsGrouped[1].items = this.unusedProtocolsSelectItems;
      if (this.allProtocolsGrouped[1].items.length === 0) {
        this.allProtocolsGrouped.splice(1, 1);
      }
    });
  }

  getAllProtocols() {
    this.trialProtocolService.getTrialProtocolList(this.trialHhhId).subscribe(result => {
      this.allProtocols = result;
      this.checkIfLastProtocolExists();
    });
  }

  checkIfLastProtocolExists() {
    this.showEmptyProtocolWarning = this.trialProtocolService.isLastProtocolNotUsed(this.allProtocols, this.usedProtocols);
  }

  initProtocolToLatest() {
    this.protocol = this.usedProtocols[this.usedProtocols.length - 1];
    this.selectedProtocol = this.protocol.hhhId;
    this.protocolValueHasChanged();
  }

  onTrialProtocolChange(callback?: () => void) {
    this.protocol = this.usedProtocols.find(p => p.hhhId === this.protocol.hhhId);
    this.protocolValueHasChanged();
    this.getTheoreticalCalendar(this.protocol.hhhId, callback);
  }

  protocolValueHasChanged() {
    this.trialProtocolService.selectedTheoreticalCalendarProtocolHhhId = this.protocol.hhhId;
  }

  onSelectedProtocolChange() {
    this.protocolValueHasChanged();
    const isProtocolVersionInitialized = this.usedProtocolsSelectItems.find(p => p.value === this.selectedProtocol);
    if (isProtocolVersionInitialized) {
      this.protocol = new MinimizedTrialProtocol(this.selectedProtocol);
      this.onTrialProtocolChange();
    } else {
      const isSelectedProtocolNotLatestInitialized = this.protocol.hhhId !== this.usedProtocols[this.usedProtocols.length - 1].hhhId;
      if (isSelectedProtocolNotLatestInitialized) {
        this.protocol = this.usedProtocols[this.usedProtocols.length - 1];
        this.onTrialProtocolChange(() => {
          this.targetProtocol = this.selectedProtocol;
          this.onTargetProtocolChange();
        });
      } else {
        this.targetProtocol = this.selectedProtocol;
        this.onTargetProtocolChange();
      }
    }
  }

  onTargetProtocolChange() {
    this.disabledArms = new Array<number>();
    this.armService.loadListByTrialAndProtocolId('' + this.trialHhhId, this.targetProtocol).subscribe(res => {
      const armsToRemove: Array<TheoreticalVisitInInclusion> = new Array<TheoreticalVisitInInclusion>();
      for (const theoreticalVisitInInclusion of this.theoreticalCalendar.theoreticalVisitInInclusionDtoList) {
        if (!this.armNameExists(res, theoreticalVisitInInclusion.arm)) {
          armsToRemove.push(theoreticalVisitInInclusion);
          this.disabledArms.push(theoreticalVisitInInclusion.armHhhId);
        }
      }
      for (const theoreticalVisitInInclusion of armsToRemove) {
        for (const theoreticalVisit of theoreticalVisitInInclusion.theoreticalVisits) {
          theoreticalVisit.modificationType = 'delete';
          this.theoreticalCalendar.theoreticalVisitToDeleteDtoList.push(theoreticalVisit.hhhId);
        }
      }
      const existingArmNames: Array<string> = this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.map(a => a.arm);
      for (const arm of res) {
        if (existingArmNames.indexOf(arm.name) < 0) {
          const newArm: TheoreticalVisitInInclusion = new TheoreticalVisitInInclusion();
          newArm.arm = arm.name;
          newArm.armHhhId = arm.hhhId;
          newArm.theoreticalVisits = [];
          this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.push(newArm);
        }
      }
      this.theoreticalCalendar = Object.assign({}, this.theoreticalCalendar);
    });
  }

  armNameExists(arms: Array<Arm>, armName: string): boolean {
    const matchingArm = arms.find(a => a.name === armName);
    return matchingArm !== undefined && matchingArm != null;
  }

  private initializeProtocolVersionGroups() {
    if (this.allProtocolsGrouped.length > 0) {
      return;
    }
    this.allProtocolsGrouped.push({
      label: 'Version(s) Initialisée(s)',
      value: '',
      items: []
    });
    this.allProtocolsGrouped.push({
      label: 'Version(s) Non Initialisé(s)',
      value: '',
      items: []
    });
  }

  private initTheoreticalCalendar() {
    if (this.amendmentsEnabled) {
      this.getUsedProtocols();
      this.getAllProtocols();
    } else {
      this.getTheoreticalCalendar();
    }
  }

  update() {
    this.getTheoreticalCalendar(this.selectedProtocol);
  }
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {FormInputConfig} from '../entity/form-input-config';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {StringConfigMapConverter} from '../entity/string-config-map-converter';
import {DynamicFormDefinition} from '../entity/dynamic-form-definition';
import {catchError, map} from 'rxjs/operators';
import {TableHeaderConfig} from '../entity/table-header-config';
import {TableConfig} from '../entity/table-config';
import {TableButtonConfig} from '../entity/table-button-config';
import {JsonConvert} from 'json2typescript';
import {DynamicTableDefinition} from '../entity/dynamic-table-definition';
import {HumanResourceAppData} from '../../human-resources/human-resource-app-data';
import {PatientAppData} from '../../patient/patient-app-data';
import {ModuleConfig} from '../entity/module-config';
import {DynamicModuleDefinition} from '../entity/dynamic-module-definition';
import {InvoiceAppData} from '../../invoice/Invoice-app-data';
import {ModuleHeaderConfig} from '../entity/module-header-config';
import {ProviderAppData} from '../../provider/provider-app-data';
import {AdditionalCostsAppData} from '../../additional-costs/additional-costs-app-data';
import {InitConfig} from '../entity/init-config';
import {EstablishmentAppData} from '../../establishment/establishment-app-data';
import {SiteAppData} from '../../site/site-app-data';
import {TrialAppData} from '../../trial/trial-app-data';
import {FormConfig} from '../entity/form-config';
import {FormTitleConfig} from '../entity/form-title-config';
import {GlobalConfigAppData} from '../gloabl-config-app-data';
import {ContainerConfig} from '../entity/container-config';
import {ContainerCardConfig} from '../entity/container-card-config';
import {DynamicContainerDefinition} from '../entity/dynamic-container-definition';
import {PharmacyAppData} from '../../pharmacy/pharmacy-app-data';
import {StatisticsAppData} from '../../statistics/statistics-app-data';
import {AgendaAppData} from "../../agenda/agenda-app-data";

@Injectable({
  providedIn: 'root'
})
export class DynamicConfigService {

  private url = new Properties().host + '/dynamic-config';

  client = 'igr';
  private _parent = false;
  moduleConfigs: Map<string, ModuleConfig> = new Map<string, ModuleConfig>();
  globalProperties: Map<string, any> = new Map<string, any>();
  cacheReady: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  appStarted: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  started = this.appStarted.asObservable();
  public currentUser: Observable<any>;
  private currentUserSubject: BehaviorSubject<any>;


  constructor(private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('authenticatedUser'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  initApplication(): Promise<any> {
    return !this.userLoggedIn()
        ? Promise.resolve()
        : this.httpClient.get<InitConfig>(this.url + '/init-application')
            .toPromise()
            .then(configs => {
              this.globalProperties = new StringConfigMapConverter<any>().deserialize(configs.globalProperties);
              this.client = configs.client;
              this.parent = configs.parent;
              this.cacheReady.next(true);
              this.appStarted.next(true);
            }).catch(reason => {
              console.error(reason);
              this.currentUserSubject.next(null);
            });
  }

  initGlobalProperties(): Promise<any> {
    return this.httpClient.get<Map<string, any>>(this.url + '/global-properties')
        .toPromise()
        .then(properties => {
          this.globalProperties = new StringConfigMapConverter<any>().deserialize(properties);
          this.cacheReady.next(true);
        });
  }

  public initProperties(): Observable<void> {
    if (this.cacheReady.value) {
      return of(void 0);
    } else {
      return this.loadGlobalProperties();
    }
  }

  public getProperty(property: string): any {
    if (!this.globalProperties.has(property)) {
      console.error('property: [' + property + '] undefined');
    }
    return this.globalProperties.get(property);
  }


  get parent(): boolean {
    return this._parent;
  }

  set parent(value: boolean) {
    this._parent = value;
  }

  public getModuleConfig(target: string): Observable<ModuleConfig> {
    const config = this.moduleConfigs.get(target);
    if (config) {
      return of(config);
    } else {
      return this.loadModuleConfig(target);
    }
  }

  public getFormConfig(target: string, cache: Map<string, FormConfig>): Observable<FormConfig> {
    const config = cache.get(target);
    if (config) {
      return of(config);
    } else {
      return this.loadFormConfig(target, cache);
    }
  }

  public getTableConfig(target: string, cache: Map<string, TableConfig>): Observable<TableConfig> {
    const config = cache.get(target);
    if (config) {
      return of(config);
    } else {
      return this.loadTableConfig(target, cache);
    }
  }

  public getContainerConfig(target: string, cache: Map<string, ContainerConfig>): Observable<ContainerConfig> {
    const config = cache.get(target);
    if (config) {
      return of(config);
    } else {
      return this.loadContainerConfig(target, cache);
    }
  }

  loadGlobalProperties(): Observable<void> {
    return this.httpClient.get<Map<string, any>>(this.url + '/global-properties').pipe(
        map(properties => {
          try {
            this.globalProperties = new StringConfigMapConverter<any>().deserialize(properties);
            this.cacheReady.next(true);
          } catch (e) {
            console.log(e as Error);
          }
        }),
        catchError(this.handleError)
    );
  }

  loadModuleConfig(target: string): Observable<ModuleConfig> {
    return this.httpClient.get<ModuleConfig>(this.url + '/module-config/' + target).pipe(
        map(res => {
          try {
            const jsonConvert: JsonConvert = new JsonConvert();
            const cf = new ModuleConfig(
                jsonConvert.deserialize(res.header, ModuleHeaderConfig) as ModuleHeaderConfig,
                new StringConfigMapConverter<any>().deserialize(res.properties));
            this.moduleConfigs.set(target, cf);
            return cf;
          } catch (e) {
            console.log(e as Error);
          }
        }),
        catchError(this.handleError)
    );
  }

  loadFormConfig(target: string, cache: Map<string, FormConfig>): Observable<FormConfig> {
    return this.httpClient.get<FormConfig>(this.url + '/form-config/' + target).pipe(
        map(
            res => {
              const jsonConvert: JsonConvert = new JsonConvert();
              const cf = new FormConfig(
                  jsonConvert.deserialize(res.title, FormTitleConfig) as FormTitleConfig,
                  new StringConfigMapConverter<FormInputConfig>('FormInputConfig').deserialize(res.fields));
              cache.set(target, cf);
              return cf;
            })
    );
  }

  loadTableConfig(target: string, cache: Map<string, TableConfig>): Observable<TableConfig> {
    return this.httpClient.get<TableConfig>(this.url + '/table-config/' + target).pipe(
        map(
            res => {
              const config = new TableConfig(
                  new StringConfigMapConverter<TableHeaderConfig>('TableHeaderConfig').deserialize(res.headers),
                  new StringConfigMapConverter<TableButtonConfig>('TableButtonConfig').deserialize(res.buttons));
              cache.set(target, config);
              return config;
            })
    );
  }

  loadContainerConfig(target: string, cache: Map<string, ContainerConfig>): Observable<ContainerConfig> {
    return this.httpClient.get<ContainerConfig>(this.url + '/container-config/' + target).pipe(
        map(
            res => {
              const config = new ContainerConfig(
                  new StringConfigMapConverter<ContainerCardConfig>('ContainerCardConfig').deserialize(res.cards));
              cache.set(target, config);
              return config;
            })
    );
  }

  getAdminConsoleModuleConfig(target: string): Observable<DynamicModuleDefinition> {
    return this.httpClient.get<DynamicModuleDefinition>(this.url + '/admin/module-config/' + target)
        .pipe(
            map(res => {
              try {
                const jsonConvert: JsonConvert = new JsonConvert();
                return jsonConvert.deserialize(res, DynamicModuleDefinition);
              } catch (e) {
                console.log(e as Error);
              }
            }),
            catchError(this.handleError)
        );
  }

  getAdminConsoleFormConfig(target: string): Observable<DynamicFormDefinition> {
    return this.httpClient.get<DynamicFormDefinition>(this.url + '/admin/form-config/' + target)
        .pipe(
            map(res => {
              try {
                const jsonConvert: JsonConvert = new JsonConvert();
                return jsonConvert.deserialize(res, DynamicFormDefinition);
              } catch (e) {
                console.log(e as Error);
              }
            }),
            catchError(this.handleError)
        );
  }

  getAdminConsoleTableConfig(target: string): Observable<DynamicTableDefinition> {
    return this.httpClient.get<DynamicTableDefinition>(this.url + '/admin/table-config/' + target)
        .pipe(
            map(res => {
              try {
                const jsonConvert: JsonConvert = new JsonConvert();
                return jsonConvert.deserialize(res, DynamicTableDefinition);
              } catch (e) {
                console.log(e as Error);
              }
            }),
            catchError(this.handleError)
        );
  }

  getAdminConsoleContainerConfig(target: string): Observable<DynamicContainerDefinition> {
    return this.httpClient.get<DynamicContainerDefinition>(this.url + '/admin/container-config/' + target)
        .pipe(
            map(res => {
              try {
                const jsonConvert: JsonConvert = new JsonConvert();
                return jsonConvert.deserialize(res, DynamicContainerDefinition);
              } catch (e) {
                console.log(e as Error);
              }
            }),
            catchError(this.handleError)
        );
  }

  saveModuleConfig(moduleDefinition: DynamicModuleDefinition): Observable<DynamicModuleDefinition> {
    return this.httpClient.post<DynamicModuleDefinition>(this.url + '/admin/save-module-config', moduleDefinition)
        .pipe(
            map(res => {
              try {
                const jsonConvert: JsonConvert = new JsonConvert();
                return jsonConvert.deserialize(res, DynamicModuleDefinition);
              } catch (e) {
                console.log(e as Error);
              }
            }),
            catchError(this.handleError)
        );
  }

  saveFormConfig(formDefinition: DynamicFormDefinition): Observable<DynamicFormDefinition> {
    return this.httpClient.post<DynamicFormDefinition>(this.url + '/admin/save-form-config', formDefinition)
        .pipe(
            map(res => {
              try {
                const jsonConvert: JsonConvert = new JsonConvert();
                return jsonConvert.deserialize(res, DynamicFormDefinition);
              } catch (e) {
                console.log(e as Error);
              }
            }),
            catchError(this.handleError)
        );
  }

  saveTableConfig(tableDefinition: DynamicTableDefinition): Observable<DynamicTableDefinition> {
    return this.httpClient.post<DynamicTableDefinition>(this.url + '/admin/save-table-config', tableDefinition)
        .pipe(
            map(res => {
              try {
                const jsonConvert: JsonConvert = new JsonConvert();
                return jsonConvert.deserialize(res, DynamicTableDefinition);
              } catch (e) {
                console.log(e as Error);
              }
            }),
            catchError(this.handleError)
        );
  }

  saveContainerConfig(containerDefinition: DynamicContainerDefinition): Observable<DynamicContainerDefinition> {
    return this.httpClient.post<DynamicContainerDefinition>(this.url + '/admin/save-container-config', containerDefinition)
        .pipe(
            map(res => {
              try {
                const jsonConvert: JsonConvert = new JsonConvert();
                return jsonConvert.deserialize(res, DynamicContainerDefinition);
              } catch (e) {
                console.log(e as Error);
              }
            }),
            catchError(this.handleError)
        );
  }

  verifyClient(clients: string[]): boolean {
    return clients.includes(this.client);
  }

  removeCache(target: string): void {
    const sgts = target.split('|');
    if (!sgts || sgts.length !== 2) {
      return;
    }
    const type = sgts[1].substring((sgts[1].lastIndexOf('-') + 1));
    if (type === 'module') {
      if (sgts[0] !== 'global-config') {
        this.moduleConfigs.delete(target);
      }
      this.cacheReady.next(false);
      this.initGlobalProperties().then();
      return;
    }
    switch (sgts[0]) {
      case 'global-config':
        GlobalConfigAppData.removeCache(type, target);
        break;
      case 'human-resource':
        HumanResourceAppData.removeCache(type, target);
        break;
      case 'patient':
        PatientAppData.removeCache(type, target);
        break;
      case 'invoice':
        InvoiceAppData.removeCache(type, target);
        break;
      case 'provider':
        ProviderAppData.removeCache(type, target);
        break;
      case 'additional-costs':
        AdditionalCostsAppData.removeCache(type, target);
        break;
      case 'establishment':
        EstablishmentAppData.removeCache(type, target);
        break;
      case 'site':
        SiteAppData.removeCache(type, target);
        break;
      case 'trial':
        TrialAppData.removeCache(type, target);
        break;
      case 'statistics':
        StatisticsAppData.removeCache(type, target);
        break;
      case 'pharmacy':
        PharmacyAppData.removeCache(type, target);
        break;
      case 'agenda':
        AgendaAppData.removeCache(type, target);
        break;
      default:
        return;
    }
  }


  userLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

  getOrderedColumns(target: string): string[] {
    return localStorage.getItem(target).split(',');
  }


}

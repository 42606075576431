import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Establishment} from '../models/establishment';
import {SharedService} from '../../shared/services/shared.service';
import {EstablishmentService} from '../services/establishment.service';
import {FormComponent} from '../../shared/component/form/form.component';
import { EstablishmentInfoService } from '../services/establishment-info.service';

@Component({
  selector: 'ih-establishment-form',
  templateUrl: './establishment-form.component.html',
  styleUrls: ['./establishment-form.component.css']
})
export class EstablishmentFormComponent extends FormComponent<Establishment> implements OnInit {
  @ViewChild('establishmentForm') establishmentForm: NgForm;
  establishment: Establishment = new Establishment();

  constructor(
    private establishmentService: EstablishmentService,
    private sharedService: SharedService,
    private establishmentInfoService: EstablishmentInfoService
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(EstablishmentFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  addEstablishment() {
    this.establishmentInfoService.addEstablishment(this.establishment).subscribe(
      res => {
        this.establishment = res;
        this.sharedService.showSuccess('UI_FORM_SUCCESS_SAVING_SUCCEEDED');
        this.callback(this.establishment);
      }, error => {
        this.sharedService.showFailure('An error has occurred when trying to add the site.' + error);
      });
    this.display = false;
  }
}

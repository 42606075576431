import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';

@Injectable({
  providedIn: 'root'
})
export class SpecialityService {
  public properties: Properties = new Properties();
  public host = this.properties.host + '/speciality';

  constructor(private httpClient: HttpClient) {
  }

  getPiSpecialityId(piHhhId: number): Observable<number> {
    return this.httpClient.get<number>(this.host + '/pi/' + piHhhId);

  }
}

import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormInputConfig} from '../../entity/form-input-config';
import {SelectItem} from 'primeng/api';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Country} from '../../../shared/entity/country';
import {DataItemService} from '../../../custom-field/service/data-item.service';


export const CONTACTS_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicContactComponent),
  multi: true
};

@Component({
  selector: 'ih-dynamic-contact',
  templateUrl: './dynamic-contact.component.html',
  styleUrls: ['./dynamic-contact.component.css'],
  providers: [CONTACTS_VALUE_ACCESSOR]
})
export class DynamicContactComponent implements OnInit {


  @ViewChild('email', {read: ElementRef}) email: ElementRef;
  @ViewChild('phone', {read: ElementRef}) phone: ElementRef;
  @ViewChild('fax', {read: ElementRef}) fax: ElementRef;
  @ViewChild('address', {read: ElementRef}) address: ElementRef;

  @Input() config: FormInputConfig;
  @Input() index: number;
  @Input() pattern: string;
  @Input() phoneError: string;
  @Input() emailError: string;
  @Input() faxError: string;
  @Input() disabled = false;

  @Input() contactTypes: SelectItem[];
  @Input() showFavorite: boolean;
  @Input() placeholder = '';
  @Input() multiFieldsContact = true;

  @Output() deleteContact: EventEmitter<void> = new EventEmitter();
  @Output() setContactValue: EventEmitter<void> = new EventEmitter();
  @Output() saveContact: EventEmitter<void> = new EventEmitter();
  @Output() updateContact: EventEmitter<void> = new EventEmitter();
  @Output() favoriteContact: EventEmitter<void> = new EventEmitter();
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter();

  showEmailError: boolean;
  showPhoneError: boolean;
  showFaxError: boolean;

  private _contact: any;
  countries: Country[] = [];
  _type: string;

  constructor(private dataItemService: DataItemService) {

  }

  ngOnInit() {
    this.showEmailError = false;
    this.showPhoneError = false;
  }

  get contact(): any {
    return this._contact;
  }

  set contact(v: any) {
    if (v !== this._contact) {
      this._contact = v;
      this.onChange(v);
    }
  }

  get type(): string {
    return this._type;
  }

  @Input()
  set type(v: string) {
    if (v !== this._type) {
      this._type = v;
      if (this._type === 'address') {
        this.getCountries();
      }
    }
  }

  getCountries() {
    this.dataItemService.getItems('country').subscribe(res => {
      this.countries = res.map(country => new Country().convert(country));
    });
  }

  save() {
    if (this._type === 'email') {
      if (this.email.nativeElement.validity.valid) {
        this.showEmailError = false;
        this.saveContact.emit();
      } else {
        this.showEmailError = true;
        return;
      }
    } else if (this._type === 'fax') {
      if (this.fax.nativeElement.validity.valid) {
        this.showFaxError = false;
        this.saveContact.emit();
      } else {
        this.showFaxError = true;
        return;
      }
    } else if (this._type === 'phone') {
      if (this.phone.nativeElement.validity.valid) {
        this.showPhoneError = false;
        this.saveContact.emit();
      } else {
        this.showPhoneError = true;
        return;
      }
    } else if (this._type === 'address') {
      if (this.address.nativeElement.validity.valid) {
        this.saveContact.emit();
      } else {
        return;
      }
    } else {
      this.saveContact.emit();
    }
  }

  update() {
    switch (this._type) {
      case 'email':
        if (this.email.nativeElement.validity.valid) {
          this.showEmailError = false;
          this.updateContact.emit();
        } else {
          this.showEmailError = true;
          return;
        }
        break;
      case 'phone':
        if (this.phone.nativeElement.validity.valid) {
          this.showPhoneError = false;
          this.updateContact.emit();
        } else {
          this.showPhoneError = true;
          return;
        }
        break;
      case 'fax':
        if (this.fax.nativeElement.validity.valid) {
          this.showFaxError = false;
          this.updateContact.emit();
        } else {
          this.showFaxError = true;
          return;
        }
        break;
      case 'address':
        if (this.address.nativeElement.validity.valid) {
          this.updateContact.emit();
        } else {
          return;
        }
        break;
      default :
        this.updateContact.emit();
    }
  }

  delete() {
    this.deleteContact.emit();
  }

  setFavoriteContact() {
    this.favoriteContact.emit();
  }

  writeValue(v: any): void {
    if (v) {
      this._contact = v;
      this.onChange(v);
    }
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  onChange = (v: any) => {
  };


  valueChanged($event: any) {
    this.valueChange.emit(true);
  }


}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {HumanResource} from '../models/human-resource';
import {DetailedHumanResource} from '../models/detailed-human-resource';
import {Properties} from '../../helpers/properties';
import {IsDeletable} from '../../is-deletable';
import {HumanResourceGlobalFilter} from '../models/human-resource-global-filter';
import {ResponsePage} from '../../shared/entity/response-page';
import {HumanResourceAddDto} from '../models/human-resource-add-dto';
import {Value} from '../../shared/entity/value';
import {catchError, map} from 'rxjs/operators';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {RequestPage} from '../../dynamic-config/entity/request-page';
import {Patient} from '../../patient/models/patient';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {PatientGlobalFilter} from '../../patient/models/patient-global-filter';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';


@Injectable({
  providedIn: 'root'
})
export class HumanResourcesService {
  host = new Properties().host + '/human-resource';
  public dataPage: ResponsePage<HumanResource>;
  public sortBy = 'hhhId';
  public orderBy = 'desc';
  public humanResourceGlobalFilter: HumanResourceGlobalFilter = new HumanResourceGlobalFilter();

  constructor(private httpClient: HttpClient) {
  }

  loadDetailed(hhhId: any): Observable<DetailedHumanResource> {
    return this.httpClient.get<DetailedHumanResource>(this.host + '/load-detailed/' + hhhId)
      .pipe(
        map(res => {
            return (new DetailedHumanResource()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  loadAll(): Observable<HumanResource[]> {
    return this.httpClient.get<HumanResource[]>(this.host + '/load-all');
  }

  loadList(page: number, limit: number, sortBy: string, orderBy: string, filters: HumanResourceGlobalFilter): Observable<ResponsePage<HumanResource>> {
    return this.httpClient.post<ResponsePage<HumanResource>>(
      this.host + '/load-list',
      new RequestPageBuilder<HumanResourceGlobalFilter>()
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(filters)
        .build())
      .pipe(map(result => {
          try {
            result.content = result.content.map(hr => new HumanResource().init(hr));
            return result;
          } catch (e) {
            console.log(e);
          }
        }),
        catchError(this.handleError)
      );
  }

  add(humanResource: HumanResource): Observable<HumanResourceAddDto> {
    return this.httpClient.post<HumanResourceAddDto>(this.host + '/add', humanResource);
  }

  update(humanResource: DetailedHumanResource): Observable<DetailedHumanResource> {
    return this.httpClient.put<DetailedHumanResource>(this.host + '/update', humanResource)
      .pipe(
        map(res => {
            return (new DetailedHumanResource()).init(res);
          }
        ),
        catchError(this.handleError));;
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(hhhId: number): Observable<void> {
    return this.httpClient.delete<void>(this.host + '/delete/' + hhhId);
  }

  exists(humanResource: HumanResource): Observable<Value<boolean>> {
    return this.httpClient.post<Value<boolean>>(this.host + '/already-exists', humanResource);
  }

  detailedExists(humanResource: DetailedHumanResource): Observable<boolean> {
    return this.httpClient.post<boolean>(this.host + '/already-exists', humanResource);
  }

  setPaginationConfiguration(dataPage: ResponsePage<HumanResource>, sortBy, orderBy, filters?) {
    this.dataPage = dataPage;
    this.sortBy = sortBy;
    this.orderBy = orderBy;
    if (filters) {
      this.humanResourceGlobalFilter = filters;
    }
  }

  exportHumanResources(request: ExportRequest<HumanResourceGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

  importRhs(file: File): Observable<any> {
    const data: FormData = new FormData();
    data.append('file', file);
    return this.httpClient.post<any>(this.host + '/import', data);
  }

  getHumanResourceIdWithFirstnameNA(): Observable<number> {
    return this.httpClient.get<number>(this.host +'/loadNA');
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

}

export class WorkloadGlobalFilter {
  public arcName: number;
  public trialName: string;
  public patientName: string;
  public startDate: Date;
  public endDate: Date;
  public expectedVisitDate: Date;
  public realVisitDate: Date;
  public visitName: string;
  public visitType: string;
}

import {Component, OnInit} from '@angular/core';
import {RoleValue} from '../../../../human-resources/models/role-value';
import {TrialContactsService} from '../../../service/trial-contacts.service';
import {SelectItem} from 'primeng/api';
import {SharedService} from '../../../../shared/services/shared.service';
import {Router} from '@angular/router';
import {TrialContactableMember} from '../../../models/trial-contactable-member';
import {ProviderService} from '../../../../provider/services/provider.service';
import {ProviderTypeDto} from '../../../../provider/models/provider-type-dto';
import {LabelValue} from '../../../../shared/entity/label-value';
import {Data} from '../../../../shared/entity/data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../trial-app-data';

@Component({
  selector: 'ih-trial-contact-form',
  templateUrl: './trial-contact-form.component.html',
  styleUrls: ['./trial-contact-form.component.css']
})
export class TrialContactFormComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_CONTACT_ADD_FORM;
  config: FormConfig = new FormConfig();

  trialId: number;
  displayAddContact: boolean;
  displayContactExistDialog: boolean;
  providerSelected: boolean;
  isButtonNotDisabled: boolean;
  typeSelected: boolean;
  providerType: string;
  provider: LabelValue;
  contactSelected: RoleValue = new RoleValue();
  providers: LabelValue[];
  contacts: RoleValue[] = [];
  providerTypes: SelectItem[] = [];
  trialContactableMember: TrialContactableMember = new TrialContactableMember();
  private callback: (TrialContactableMember: TrialContactableMember) => void;
  yesOrNoOptions: SelectItem[] = Data.yesOrNoOrNull;


  constructor(
      private trialContactService: TrialContactsService,
      private providerService: ProviderService,
      private sharedService: SharedService,
      private router: Router,
      private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.displayAddContact = true;
    this.getProviderTypes();
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.initFormConfig();
  }


  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  selectType() {
    this.typeSelected = true;
    this.isButtonNotDisabled = false;
    this.providerSelected = false;
  }

  selectProvider() {
    this.providerSelected = true;
    this.getContactsByProvider();
  }

  getContactsByProvider() {
    this.trialContactService.getContactsByPromoter(this.provider.value).subscribe(res => {
      this.contacts = res;
      this.contacts.splice(0, 0, null);
    });
  }

  getProviderTypes() {
    this.trialContactService.getTrialProviderTypesAndOthers().subscribe((res: ProviderTypeDto[]) => {
      for (const providerType of res) {
        const providerTypeItem: SelectItem = {label: '', value: ''};
        providerTypeItem.value = providerType.code;
        providerTypeItem.label = providerType.nameFr;
        this.providerTypes.push(providerTypeItem);
      }
    });
  }

  addContactToTrial() {
    this.trialContactService.isContactableMemberInTrial(this.trialId, this.contactSelected.hhhId).subscribe(isContactable => {
      if (!isContactable) {
        this.trialContactService.addContactToTrial(this.trialId, this.contactSelected.hhhId, this.trialContactableMember).subscribe(result => {
          this.sharedService.showSuccess();
          this.callback(result);
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        });
      } else {
        this.displayContactExistDialog = true;
      }
    });

    this.displayAddContact = false;
  }

  selectContact(contactSelected: RoleValue) {
    this.isButtonNotDisabled = contactSelected && this.providerSelected && this.typeSelected;
  }

  onAdd(callback: (trialContactableMember: TrialContactableMember) => void) {
    this.callback = callback;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

}

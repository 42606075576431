export class Note {
  public hhhId: number;
  public content: string;
  public date: Date;
  public checked: boolean;
  public ownerHhhId: number;

  constructor() {
    this.hhhId = null;
  }
}

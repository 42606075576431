export class DocumentViewer {

  public static readonly imageReg = new RegExp(/[\/.](gif|jpg|jpeg|tiff|png)$/i);
  public static readonly textReg = new RegExp(/[\/.](TXT|txt|html|HTML|XML|xml|CSV|csv)$/i);
  public static readonly pdfReg = new RegExp(/[\/.](PDF|pdf)$/i);

  public static getMimeType(filename: string, type: string): string {
    if (type === 'pdf') {
      return 'application/pdf';
    } else {
      const extension = filename.substr(filename.lastIndexOf('.') + 1, filename.length);
      return type + '/' + extension;
    }
  }

  public static getDocumentType(filename: string): string {
    let type = '';
    if (this.pdfReg.test(filename)) {
      type = 'pdf';
    } else if (this.textReg.test(filename)) {
      type = 'text';
    } else if (this.imageReg.test(filename)) {
      type = 'image';
    }
    return type;
  }

  public static isAllowedType(fileName: string): boolean {
    return this.imageReg.test(fileName) || this.textReg.test(fileName) || this.pdfReg.test(fileName);
  }

  public static isPdfType(fileName: string): boolean {
    return this.pdfReg.test(fileName);
  }

}

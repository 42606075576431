import {TrialListData} from './trial-list-data';

export class TrialHead {

  public localIdentifier: string;
  public promoter1: string;
  public protocolNumber: string;
  public publicCommentCount: number;
  public hhhId: number;
  public multiSite: boolean;

  constructor(trial: TrialListData) {
    if (trial) {
      this.localIdentifier = trial.localIdentifier;
      this.promoter1 = trial.promoter1;
      this.protocolNumber = trial.protocolNumber;
      this.publicCommentCount = trial.publicCommentCount;
      this.hhhId = trial.hhhId;
      this.multiSite = trial.multiSite;
    }
  }
}

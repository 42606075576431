import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {Establishment} from '../models/establishment';
import {Router} from '@angular/router';
import {SharedService} from '../../shared/services/shared.service';
import {DataService} from '../../shared/services/data-service';
import {EstablishmentService} from '../services/establishment.service';
import {EstablishmentFormComponent} from '../establishment-form/establishment-form.component';
import {EstablishmentSelection} from '../../shared/entity/establishment-selection';
import {EstablishmentEditFormComponent} from '../establishment-edit-form/establishment-edit-form.component';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {EstablishmentAppData} from '../establishment-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';

@Component({
  selector: 'ih-establishments',
  templateUrl: './establishments.component.html',
  styleUrls: ['./establishments.component.css']
})
export class EstablishmentsComponent extends PaginatorTableComponent<Establishment> implements OnInit {

  target = DynamicDefinitionEnum.ESTABLISHMENT_OTHER_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  isParentEstablishment: boolean;

  constructor(
      private router: Router,
      private cfr: ComponentFactoryResolver,
      private sharedService: SharedService,
      private dataService: DataService,
      private establishmentService: EstablishmentService,
      public dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    this.isParentEstablishment = true;
    this.initTableConfig();
    this.getList();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, EstablishmentAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('name')
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  displayFormDialogue() {
    const formComponent = EstablishmentFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.formHeader = this.getAddTitle();
    formComponent.onAdd(this.updateList);
  }

  getAddTitle(): string {
    return 'MODULE_ESTABLISHMENT_DEPARTMENTS_ADD_NEW';
  }

  getDeleteMessage(): string {
    return 'MODULE_ESTABLISHMENT_DEPARTMENTS_DIALOG_WARNING_ESTABLISHMENT_WILL_BE_DELETED';
  }

  getService(): any {
    return this.establishmentService;
  }

  getList() {
    this.establishmentService.getChildEstablishments().subscribe((establishments: Establishment[]) => {
      this.values = establishments;
      this.totalNumber = this.values.length;
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while loading establishments. ' + error);
    });
  }

  getEstablishment(establishment: Establishment) {
    this.router.navigate(['/establishment'], {queryParams: {hhhId: establishment.hhhId}}).then();
  }

  displayEditFormDialogue(establishment: EstablishmentSelection) {
    const formComponent = EstablishmentEditFormComponent.display(this.formDialogContainer, this.cfr);
    formComponent.establishmentToEdit = establishment;
  }
}

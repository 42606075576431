import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {TrialCounterpart} from '../../../entity/trial-additionnal-costs/trial-counterpart';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {DataService} from '../../../../shared/services/data-service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {TrialCounterpartService} from '../../../service/trial-counterpart.service';
import {SelectItem} from 'primeng/api';
import {DeleteMultipleComponent} from '../../../../modals/delete-multiple/delete-multiple.component';
import {TrialCounterpartFormComponent} from '../trial-counter-part-form/trial-counterpart-form.component';
import {HiddenFields} from '../../../../admin/models/HiddenFields';
import {UsersService} from '../../../../admin/services/users.service';
import {TrialService} from '../../../services/trial.service';
import {COUNTER_PART_REFERENCE_READ_WRITE} from '../../../../login/services/authentication.service';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicTableHeaderBuilder} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {AppData} from '../../../../helpers/app-data';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';


@Component({
  selector: 'ih-trial-counter-parts',
  templateUrl: './trial-counterpart.component.html',
  styleUrls: ['./trial-counterpart.component.css']
})
export class TrialCounterpartComponent extends PaginatorTableComponent<TrialCounterpart> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.TrialCounterpart;
  target = DynamicDefinitionEnum.TRIAL_ADDITIONAL_COSTS_COUNTERPART_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  trialHhhId: number;
  status: SelectItem[];
  trialCounterPartCheckedList: TrialCounterpart[] = [];
  displayNoSelectedCounterPartDialog: boolean;
  userCanAccessActs = false;

  counterPartCategoryFilterList: SelectItem[] = [];
  invoiceableFilterList: SelectItem[] = [];
  attachedToVisitFilterList: SelectItem[] = [];
  descriptionFilterList: SelectItem[] = [];
  freeSSFilterList: SelectItem[] = [];

  constructor(
      private cfr: ComponentFactoryResolver,
      private translateService: InnohealthTranslateService,
      private useService: UsersService,
      private dataService: DataService,
      private translate: TranslateService,
      private router: Router,
      public trialService: TrialService,
      private trialCounterPartService: TrialCounterpartService,
      public trialPageLockService: TrialPageLockService,
      private dynamicConfigService: DynamicConfigService,
      private cdr: ChangeDetectorRef
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.userCanAccessActs = ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(COUNTER_PART_REFERENCE_READ_WRITE)) >= 0;
    this.getTrialCounterParts();
    this.initTableConfig();
    this.translate.stream('UI_GLOBAL_PLACEHOLDER_SELECT_FROM_LIST').subscribe(() => {
      this.status = [
        {label: '', value: null},
        {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE'), value: true},
        {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE'), value: false}];
    });
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('counterPartCategory')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.counterPartCategoryFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('internalAccountNumber')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('secondInternalAccountNumber')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('description')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.descriptionFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('attachedToVisit')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.attachedToVisitFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('invoiceable')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.invoiceableFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('pricePerUnit')
        .accessible(this.dynamicConfigService.verifyClient(['chartres', 'col', 'ghtmb', 'igr']) ? this.useService.canAccessField(HiddenFields.COUNTER_PART_PRICE) : true)
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .withDisplayCssClassContent()
        .cssClass(() => {
          return 'pull-right';
        })
        .displayContent((data: TrialCounterpart) => {
          return Number(data.pricePerUnit).toFixed(2) + ' €';
        })
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('unitNumber')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .withDisplayCssClassContent()
        .cssClass(() => {
          return 'pull-right';
        })
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('firstSegment')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('secondSegment')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('freeSS')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.freeSSFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('total')
        .accessible(this.useService.canAccessField(HiddenFields.COUNTER_PART_PRICE)) // col + igr
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/trial/additional-costs/counter-parts/counter-parts.component';
  }

  getUpdateTitle(): string {
    return 'MODULE_COUNTER_PARTS_GENERAL_UPDATE';
  }

  getDeleteMessage(): string {
    return 'MODULE_TRIALS_FIXED_COSTS_WARNING_SELECTED_COUNTER_PARTS_WILL_BE_DELETED';
  }

  getService(): any {
    return this.trialCounterPartService;
  }

  updateSelectedList(selectedList: any) {
    this.trialCounterPartCheckedList = selectedList;
  }

  navigateToCounterParts() {
    this.dataService.changeFromModule(this.trialHhhId);
    this.router.navigate(
        ['/additional-costs-container/counter-parts'],
        {queryParams: {counterPartSelected: true}}
    ).then();
  }

  displayFormDialogue(trialCounterPart?: TrialCounterpart) {
    const formComponent = TrialCounterpartFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (trialCounterPart) {
      formComponent.trialCounterPart = new TrialCounterpart().init(trialCounterPart);
      formComponent.formHeader = this.getUpdateTitle();
    }
    formComponent.onAdd((cp) => this.updateList(cp));
  }

  openDeleteCounterPartDialog() {
    if (this.trialCounterPartCheckedList.length === 0) {
      this.displayNoSelectedCounterPartDialog = true;
    } else {
      this.displayDeleteMultiple();
    }
  }

  displayDeleteMultiple() {
    const selectedList = [];
    const formComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    formComponent.service = this.trialCounterPartService;
    formComponent.popupMessage = 'MODULE_TRIALS_FIXED_COSTS_WARNING_SELECTED_COUNTER_PARTS_WILL_BE_DELETED';
    for (const item of this.trialCounterPartCheckedList) {
      if (this.values.indexOf(item) > -1) {
        selectedList.push(item);
      }
    }
    formComponent.selectedList = selectedList;
    formComponent.onDelete((deletedItem: number[]) => {
      for (const hhhId of deletedItem) {
        const index = this.values.findIndex(
            (trialCounterPart: TrialCounterpart) => trialCounterPart.hhhId === hhhId);
        this.values.splice(index, 1);
      }
    });
  }

  updateDropdownOptions(event) {
    for (const counterPart of event) {
      const counterPartCategoryItem = {label: counterPart.counterPartCategory, value: counterPart.counterPartCategory};
      const descItem = {label: counterPart.description, value: counterPart.description};
      const invoiceableItem = {
        label: counterPart.invoiceable !== null ? this.translateService.getTranslationString(counterPart.invoiceable.toString()) : '',
        value: counterPart.invoiceable
      };
      const attachedToVisitItem = {
        label: counterPart.attachedToVisit !== null ? this.translateService.getTranslationString(counterPart.attachedToVisit.toString()) : '',
        value: counterPart.attachedToVisit
      };
      const freeSSItem = {
        label: counterPart.freeSS !== null ? this.translateService.getTranslationString(counterPart.freeSS.toString()) : '',
        value: counterPart.freeSS
      };

      if (counterPart.counterPartCategory !== null &&
          this.counterPartCategoryFilterList.findIndex((item: SelectItem) => item.value === counterPartCategoryItem.value) === -1) {
        this.counterPartCategoryFilterList.push(counterPartCategoryItem);
      }

      if (counterPart.description !== null &&
          this.descriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
        this.descriptionFilterList.push(descItem);
      }
      if (counterPart.invoiceable !== null &&
          this.invoiceableFilterList.findIndex((item: SelectItem) => item.value === invoiceableItem.value) === -1) {
        this.invoiceableFilterList.push(invoiceableItem);
      }
      if (counterPart.attachedToVisit !== null &&
          this.attachedToVisitFilterList.findIndex((item: SelectItem) => item.value === attachedToVisitItem.value) === -1) {
        this.attachedToVisitFilterList.push(attachedToVisitItem);
      }
      if (counterPart.freeSS !== null &&
          this.freeSSFilterList.findIndex((item: SelectItem) => item.value === freeSSItem.value) === -1) {
        this.freeSSFilterList.push(freeSSItem);
      }

    }

    this.counterPartCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.descriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.invoiceableFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.attachedToVisitFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  private getTrialCounterParts() {
    this.trialCounterPartService.loadList(this.trialHhhId).subscribe(
        res => {
          this.values = res;
          this.trialCounterPartService.trialCounterPartList = this.values;
          this.trialCounterPartService.trialHhhId = this.trialHhhId;
          this.updateDropdownOptions(res);
        });
  }

  exportTrialCounterParts(event: any): void {
    const request = new ExportRequestBuilder<void>()
        .objectId(this.trialHhhId)
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
        .build();
    this.trialCounterPartService.exportTrialCounterPart(request).subscribe(
        (res) => {},
        (err) => {
          console.error(err);
        });
  }


}

import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('TableButtonConfig')
export class TableButtonConfig {

  @JsonProperty('display', Boolean)
  display = false;

  constructor(display?: boolean) {
    this.display = display;
  }
}

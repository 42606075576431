import {VisitState} from './visit-state';
import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';

export class DetailedVisit {
  public hhhId: number;
  public name: string;
  public visitDate: Date;
  public registrationDate: Date;
  public visitState: VisitState;
  public visitProviderState: VisitState;
  public imagingDespatchStateHhhId: number;
  public samplesDespatchStateHhhId: number;
  public transportRefundStateHhhId: number;
  public calendarHhhId: number;
  public clonedStateOfInclusion: string;
  public comment: string;
  visitIsAdditionalTest: boolean;
  public isNotSavedYet: boolean;

  constructor() {
    if (this.visitDate) {
      this.visitDate = new Date(this.visitDate);
    }
    if (this.registrationDate) {
      this.registrationDate = new Date(this.registrationDate);
    }
  }

  init(obj: any): DetailedVisit {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    const valid = true;
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return valid;
  }
}

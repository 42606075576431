import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from '../../shared/services/data-service';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-establishment-container',
  templateUrl: './establishment-container.component.html',
  styleUrls: ['./establishment-container.component.css']
})
export class EstablishmentContainerComponent implements OnInit {

  parent: boolean;
  public items: MenuItem[];

  constructor(
    private translateService: InnohealthTranslateService,
    private translate: TranslateService,
    private dataService: DataService,
    private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    this.dataService.setExportPageModule('src/app/establishment/my-establishment/my-establishment.component');

    this.parent = this.dynamicConfigService.parent;
    this.translate.stream('key').subscribe(() => {
      this.extractItems();
    });
  }

  private extractItems() {
    this.items = [
      {
        label: this.translateService.getTranslationString('MODULE_ESTABLISHMENTS_MY_ESTABLISHMENT'),
        routerLink: ['/establishment/details'],
      },
      {
        label: this.translateService.getTranslationString('MODULE_ESTABLISHMENTS_OTHER_ESTABLISHMENTS'),
        routerLink: ['/establishment/establishments']
      },
    ];
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TrialInformation} from '../models/trial-information';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';

@Injectable({
  providedIn: 'root'
})
export class TrialRevisionService {

  public host = new Properties().host + '/revision/trial';

  constructor(private httpClient: HttpClient) {}

  getTrialIdentificationByRevisionHhhId(hhhId: number, revisionHhhId: number, trialSiteLiaisonHhhId?: number): Observable<TrialInformation> {
    const params = hhhId + '/' + revisionHhhId;
    return this.httpClient.get<TrialInformation>(this.host + '/' + params);
  }

}

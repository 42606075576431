import {Component, Input, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Data} from '../../../../shared/entity/data';

@Component({
  selector: 'ih-theoretical-calendar-act-visit-cell',
  templateUrl: './theoretical-calendar-act-visit-cell.component.html',
  styleUrls: ['./theoretical-calendar-act-visit-cell.component.css']
})
export class TheoreticalCalendarActVisitCellComponent implements OnInit {

  @Input() visit: any;
  shiftedUnits: SelectItem[] = Data.shiftedUnits;

  constructor() {
  }

  ngOnInit() {
  }

}

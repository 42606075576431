import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {Observable} from 'rxjs';
import {InvoiceMonitor} from '../entity/invoice-monitor/invoice-monitor';
import {TrialBudget} from '../entity/invoice-monitor/trial-budget';
import {EstimatedBudget} from '../entity/invoice-monitor/estimated-budget';

@Injectable({
  providedIn: 'root'
})
export class InvoiceMonitorService {

  public host = new Properties().host + '/invoice-monitor';

  constructor(private httpClient: HttpClient) {
  }

  loadList(hhhId: number): Observable<InvoiceMonitor[]> {
    return this.httpClient.get<InvoiceMonitor[]>(this.host + '/load-list/' + hhhId);
  }

  getBudgetPerState(hhhId: number): Observable<TrialBudget> {
    return this.httpClient.get<TrialBudget>(this.host + '/budget-per-state/' + hhhId);
  }

  getEstimatedBudget(hhhId: number): Observable<EstimatedBudget> {
    return this.httpClient.get<EstimatedBudget>(this.host + '/estimated-budget/' + hhhId);
  }

}

export class TrialFollowUpFilter {
  patientInclusionFilter: string;
  trialSiteHhhId = -1;
  visitIsAdditionalTest: boolean;
  patientStateHhhId: number;

  TrialFollowUpFilter() {
    this.patientInclusionFilter = '';
    this.trialSiteHhhId = -1;

  }
}

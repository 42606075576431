import {Component, OnInit} from '@angular/core';
import {VisitForUpdate} from '../../../../../entity/inclusion/visit-for-update';
import {Router} from '@angular/router';
import {VisitAct} from '../../../../../entity/inclusion/visit-act';
import {VisitService} from '../../../../../service/visit.service';
import {SharedService} from '../../../../../../shared/services/shared.service';
import {FR} from '../../../../../../shared/entity/calendar-language';
import {VisitState} from '../../../../../entity/inclusion/visit-state';
import {UsersService} from '../../../../../../admin/services/users.service';
import {TrialService} from '../../../../../services/trial.service';
import {Data} from '../../../../../../shared/entity/data';
import {TrialPageLockService} from '../../../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {DynamicDefinitionEnum} from '../../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../../../trial-app-data';
import {DynamicConfigService} from '../../../../../../dynamic-config/service/dynamic-config.service';
import {VisitActService} from '../../../../../service/visit-act.service';
import {DataItemService} from '../../../../../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-visit-detail',
  templateUrl: './visit-detail.component.html',
  styleUrls: ['./visit-detail.component.css']
})
export class VisitDetailComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_INCLUSION_CALENDAR_VISIT_DETAIL_FORM;
  config: FormConfig = new FormConfig();

  visitStates: VisitState[] = [];
  visitActs: VisitAct[] = [];
  visit: VisitForUpdate = new VisitForUpdate();
  visitHhhId: number;
  trialHhhId: number;
  locale = FR;
  inclusionId: number;
  noYesNaOptions = Data.yesOrNo;
  submitted = false;

  constructor(
    private router: Router,
    private useService: UsersService,
    private dataItemService: DataItemService,
    public trialService: TrialService,
    private sharedService: SharedService,
    private visitService: VisitService,
    public trialPageLockService: TrialPageLockService,
    private dynamicConfigService: DynamicConfigService,
    private visitActService: VisitActService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.visitHhhId = url.queryParams.visitHhhId;
      this.trialHhhId = url.queryParams.trialHhhId;
      this.inclusionId = url.queryParams.inclusionId;
    }
  }

  ngOnInit() {
    this.getVisit();
    this.getVisitState();
    this.getVisitActs();
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getVisit() {
    this.visitService.getVisit(this.visitHhhId).subscribe(res => {
      this.visit = res;
      this.getDaysBetween(this.visit.visitDate, this.visit.expectedVisitDate);
    }, error => {
      console.error(error);
    });
  }

  getVisitState() {
    this.dataItemService.getItems('visit-state').subscribe(res => {
      this.visitStates = res.map((item) => {
        return new VisitState(item.value, item.label);
      });
    }, error => {
      console.error(error);
    });
  }

  updateVisit() {
    this.submitted = true;
    if (!this.visit.isValid(this.config)) {
      return;
    }
    this.visitService.updateVisit(this.visit).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  getVisitActs() {
    this.visitActService.getVisitActs(this.visitHhhId).subscribe(res => {
      this.visitActs = res;
    }, error => {
      console.error(error);
    });
  }

  displayPricePerUnit(act: VisitAct) {
    if (!this.useService.canAccessFieldByValue(act.type)) {
      return '-';
    }
    return act.pricePerUnit;
  }


  displayTotalPrice(act: VisitAct) {
    if (!this.useService.canAccessFieldByValue(act.type)) {
      return '-';
    }
    return act.priceTotal;
  }

  public isVisitLocked(): boolean {
    const isPaidId = this.visitStates.findIndex(s => s.name === 'Payée');
    const isFacturedId = this.visitStates.findIndex(s => s.name === 'Facturée');
    return this.trialPageLockService.isLocked && (this.visit.visitState.hhhId === this.visitStates[isPaidId].hhhId ||
      this.visit.visitState.hhhId === this.visitStates[isFacturedId].hhhId);
  }

  getDaysBetween(date1, date2): void {
    if (!date1 || !date2) {
      return;
    }
    const realVisit = new Date(date1).getTime();
    const expectedVisit = new Date(date2).getTime();
    this.visit.difference = Math.round((realVisit - expectedVisit) / (24 * 3600 * 1000));
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);

}

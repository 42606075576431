export enum DynamicDefinitionEnum {
  // Global-Properties
  GLOBAL_PROPERTIES = 'global-config|properties-config-module',
  SIDEBAR_MENU_CONTAINER = 'global-config|sidebar-menu-container',
  PAGE_HEADER_CONTAINER = 'global-config|page-header-container',
  ADMIN_WORKLOAD_TABLE = 'global-config|workload-table',
  ADMIN_ACCOUNT_TABLE = 'global-config|account-table',
  ADMIN_NEW_USER_FORM = 'global-config|new-user-form',
  CUSTOM_EXPORT_FORM = 'global-config|custom-export-form',
  USER_ATTEMPTS_LIST = 'global-config|user-attempts-table',
  AUDIT_TRAIL_TABLE = 'global-config|audit-trail-table',

  // AGENDA MODULE
  AGENDA_MODULE = 'agenda|config-module',
  AGENDA_FILTER_FORM= 'agenda|filter-form',


  // HUMAN-RESOURCES MODULE
  HUMAN_RESOURCE_MODULE = 'human-resource|config-module',
  HUMAN_RESOURCE_ADD_FORM = 'human-resource|add-form',
  HUMAN_RESOURCE_INFORMATION_FORM = 'human-resource|information-form',
  HUMAN_RESOURCE_CONTACT_FORM = 'human-resource|contact-form',
  HUMAN_RESOURCE_LIST_TABLE = 'human-resource|list-table',

  // PATIENT MODULE
  PATIENT_MODULE = 'patient|config-module',
  PATIENT_ADD_FORM = 'patient|add-form',
  PATIENT_CONTACT_FORM = 'patient|contact-form',
  PATIENT_INCLUSION_TABLE = 'patient|inclusion-table',
  PATIENT_INFORMATION_FORM = 'patient|information-form',
  PATIENT_LIST_TABLE = 'patient|list-table',


  // INVOICE MODULE
  INVOICE_MODULE = 'invoice|config-module',
  INVOICE_ADD_FORM = 'invoice|add-form',
  INVOICE_INFORMATION_FORM = 'invoice|information-form',
  INVOICE_STATIC_INFORMATION_FORM = 'invoice|static-information-form',
  INVOICE_LIST_TABLE = 'invoice|list-table',
  INVOICE_STATIC_LIST_TABLE = 'invoice|static-list-table',
  INVOICE_ACT_LIST_TABLE = 'invoice|act-list-table',
  INVOICE_STATIC_ACT_LIST_TABLE = 'invoice|static-act-list-table',
  INVOICE_STATIC_COUNTERPART_LIST_TABLE = 'invoice|static-counterpart-list-table',
  INVOICE_COUNTERPART_LIST_TABLE = 'invoice|counterpart-list-table',
  INVOICE_FIXED_COST_LIST_TABLE = 'invoice|fixed-cost-list-table',
  INVOICE_STATIC_FIXED_COST_LIST_TABLE = 'invoice|static-invoice-fixed-cost-list-table',

  // PROVIDER MODULE
  PROVIDER_MODULE = 'provider|config-module',
  PROVIDER_ADD_FORM = 'provider|add-form',
  PROVIDER_LIST_TABLE = 'provider|list-table',

  // SITE MODULE
  SITE_MODULE = 'site|config-module',
  SITE_LIST_TABLE = 'site|list-table',
  SITE_ADD_FORM = 'site|add-form',

  // ADDITIONAL COSTs MODULE
  ADDITIONAL_COSTS_MODULE = 'additional-costs|config-module',

  ADDITIONAL_COSTS_ACT_CATEGORY_LIST_TABLE = 'additional-costs|act-category-list-table',
  ADDITIONAL_COSTS_ACT_CATEGORY_ADD_FORM = 'additional-costs|act-category-add-form',

  ADDITIONAL_COSTS_REFERENCE_ACTS_LIST_TABLE = 'additional-costs|reference-acts-list-table',
  ADDITIONAL_COSTS_REFERENCE_ACTS_ADD_FORM = 'additional-costs|reference-acts-add-form',

  ADDITIONAL_COSTS_FIXED_COST_CATEGORY_LIST_TABLE = 'additional-costs|fixed-cost-category-list-table',
  ADDITIONAL_COSTS_FIXED_COST_CATEGORY_ADD_FORM = 'additional-costs|fixed-cost-category-add-form',

  ADDITIONAL_COSTS_REFERENCE_FIXED_COST_LIST_TABLE = 'additional-costs|reference-fixed-cost-list-table',
  ADDITIONAL_COSTS_REFERENCE_FIXED_COST_ADD_FORM = 'additional-costs|reference-fixed-cost-add-form',

  ADDITIONAL_COSTS_COUNTER_PART_CATEGORY_LIST_TABLE = 'additional-costs|counter-part-category-list-table',
  ADDITIONAL_COSTS_COUNTER_PART_CATEGORY_ADD_FORM = 'additional-costs|counter-part-category-add-form',

  ADDITIONAL_COSTS_REFERENCE_COUNTER_PART_LIST_TABLE = 'additional-costs|reference-counter-part-list-table',
  ADDITIONAL_COSTS_REFERENCE_COUNTER_PART_ADD_FORM = 'additional-costs|reference-counter-part-add-form',

  ADDITIONAL_COSTS_OPERATIONAL_ACTS_LIST_TABLE = 'additional-costs|operational-acts-list-table',
  ADDITIONAL_COSTS_OPERATIONAL_ACTS_ADD_FORM = 'additional-costs|operational-acts-add-form',

  ADDITIONAL_COSTS_OPERATIONAL_TRIAL_ACTS_LIST_TABLE = 'additional-costs|operational-trial-acts-list-table',
  ADDITIONAL_COSTS_INCLUSION_OPERATIONAL_ACTS_LIST_TABLE = 'additional-costs|inclusion-operational-acts-list-table',
  ADDITIONAL_COSTS_INCLUSION_ACTS_LIST_TABLE = 'additional-costs|inclusion-acts-list-table',

  // ESTABLISHMENT MODULE
  ESTABLISHMENT_MODULE = 'establishment|config-module',
  ESTABLISHMENT_INFORMATION_FORM = 'establishment|information-form',
  ESTABLISHMENT_ACTIVE_FILE_BY_YEAR_FORM = 'establishment|active-file-by-year-form',
  ESTABLISHMENT_OTHER_TABLE = 'establishment|other-establishments-table',
  ESTABLISHMENT_LIST_TABLE = 'establishment|list-table',
  ESTABLISHMENT_OTHER_FORM = 'establishment|other-establishment-form',
  ESTABLISHMENT_CONTACT_LIST_TABLE = 'establishment|contact-list-table',

  // TRIAL MODULE
  TRIAL_MODULE = 'trial|config-module',
  TRIAL_LIST_TABLE = 'trial|list-table',
  TRIAL_IDENTIFICATION_INFORMATION_CONTAINER = 'trial|identification-information-container',
  TRIAL_IDENTIFICATION_INFORMATION_FORM = 'trial|identification-information-form',
  TRIAL_PERSONNEL_INFORMATION_FORM = 'trial|personnel-information-form',
  TRIAL_TREATMENT_METHODOLOGIE_FORM = 'trial|treatment-methodologie-form',
  TRIAL_PERSONNEL_PROVIDER_INFORMATION_FORM = 'trial|personnel-provider-information-form',
  TRIAL_AMENDMENT_PROTOCOL_FORM = 'trial|amendment-protocol-form',
  TRIAL_AMENDMENT_CONSENT_FORM = 'trial|amendment-consent-form',
  TRIAL_INCLUSION_EXCLUSION_CRITERIA_FORM = 'trial|inclusion-exclusion-criteria-form',
  TRIAL_INCLUSION_EXCLUSION_CRITERIA_DISCIPLINE_FORM = 'trial|inclusion-exclusion-criteria-discipline-form',
  TRIAL_DISCIPLINE_INFORMATION_FORM = 'trial|discipline-information-form',
  TRIAL_ARM_LIST_TABLE = 'trial|arm-list-table',
  TRIAL_DESIGN_FORM = 'trial|trial-design-form',
  TRIAL_SEND_TRIAL_TO_ESTABLISHMENT_FORM = 'trial|send-trial-to-establishment-form',
  TRIAL_SITE_CONTAINER = 'trial|site-container',
  TRIAL_SITE_LIST_TABLE = 'trial|site-list-table',
  TRIAL_ADMINISTRATIVE_INFORMATION_CONTAINER = 'trial|administrative-information-container',
  TRIAL_ADMINISTRATIVE_REGULATORY_INFORMATION_FORM = 'trial|administrative-regulatory-information-form',
  TRIAL_ADMINISTRATIVE_DATES_INFORMATION_FORM = 'trial|administrative-dates-information-form',
  TRIAL_ADMINISTRATIVE_FEASIBILITY_INFORMATION_FORM = 'trial|administrative-feasibility-information-form',
  TRIAL_ADMINISTRATIVE_CEC_INFORMATION_FORM = 'trial|administrative-cec-information-form',
  TRIAL_ADMINISTRATIVE_CONVENTION_INFORMATION_FORM = 'trial|administrative-convention-information-form',
  TRIAL_ADMINISTRATIVE_LOGISTIC_INFORMATION_FORM = 'trial|administrative-logistic-information-form',
  TRIAL_ADMINISTRATIVE_TRIAL_FOLLOWUP_INFORMATION_FORM = 'trial|administrative-trial-followup-information-form',
  TRIAL_ADMINISTRATIVE_COMOP_INFORMATION_FORM = 'trial|administrative-comop-information-form',
  TRIAL_DASHBORD_FORM = 'trial|dashbord-form',
  TRIAL_INCLUSION_LIST_TABLE = 'trial|inclusion-list-table',
  TRIAL_INCLUSION_INFORMATION_ARM_FORM = 'trial|inclusion-information-arm-form',
  TRIAL_INCLUSION_INFORMATION_PROTOCOL_VERSION_FORM = 'trial|inclusion-information-protocol-version-form',
  TRIAL_INCLUSION_CALENDAR_VISIT_FORM = 'trial|inclusion-calendar-visit-form',
  TRIAL_INCLUSION_CALENDAR_VISIT_DETAIL_FORM = 'trial|inclusion-calendar-visit-detail-form',
  TRIAL_INCLUSION_PATIENT_FOLLOWUP_CONTAINER = 'trial|inclusion-patient-followup-container',
  TRIAL_ADVERSE_EFFECT_TABLE = 'trial|adverse-effect-table',
  TRIAL_ADVERSE_EFFECT_FORM = 'trial|adverse-effect-form',
  TRIAL_CONCOMITANT_TREATMENT_TABLE = 'trial|concomitant-treatment-table',
  TRIAL_CONCOMITANT_TREATMENT_FORM = 'trial|concomitant-treatment-form',
  TRIAL_VITAL_SIGNS_TABLE = 'trial|vital-signs-table',
  TRIAL_VITAL_SIGNS_FORM = 'trial|vital-signs-form',
  TRIAL_ADDITIONAL_COSTS_ACTS_LIST_TABLE = 'trial|additional-costs-acts-list-table',
  TRIAL_ADDITIONAL_COSTS_FIXED_COST_LIST_TABLE = 'trial|additional-costs-fixed-cost-list-table',
  TRIAL_ADDITIONAL_COSTS_COUNTERPART_LIST_TABLE = 'trial|additional-costs-counter-part-list-table',
  TRIAL_ADDITIONAL_COSTS_OPERATIONAL_ACTS_LIST_TABLE = 'trial|additional-costs-operational-acts-list-table',
  TRIAL_ACT_FORM = 'trial|act-form',
  TRIAL_FIXED_COST_FORM = 'trial|fixed-cost-form',
  TRIAL_COUNTERPART_FORM = 'trial|counterpart-form',
  TRIAL_OPERATIONAL_ACT_FORM = 'trial|operational-act-form',
  TRIAL_THEORETICAL_CALENDAR_REFERENCE_ACT_LIST_TABLE = 'trial|theoretical-calendar-reference-act-list-table',
  TRIAL_THEORETICAL_CALENDAR_ADDITIONAL_COST_LIST_TABLE = 'trial|theoretical-calendar-additional-cost-list-table',
  TRIAL_THEORETICAL_CALENDAR_VISIT_ADD_FORM = 'trial|theoretical-calendar-visit-add-form',
  TRIAL_TRACKING_TABLE_FILTER_FORM = 'trial|tracking-table-filter-form',
  TRIAL_THEORETICAL_VISIT_ACTS_TABLE = 'trial|theoretical-visit-acts-table',
  TRIAL_PHARMACY_INFORMATION_FORM = 'trial|pharmacy-information-form',
  TRIAL_PHARMACY_STOCK_TABLE = 'trial|pharmacy-stock-table',
  TRIAL_PHARMACY_STOCK_FORM = 'trial|pharmacy-stock-form',
  TRIAL_PHARMACY_DISPENSATION_TABLE = 'trial|pharmacy-dispensation-table',
  TRIAL_CONTACT_UPDATE_FORM = 'trial|contact-edit-form',
  TRIAL_CONTACT_ADD_FORM = 'trial|contact-add-form',

  // PHARMACY_MODULE
  PHARMACY_MODULE = 'pharmacy|config-module',
  PHARMACY_STOCK_TABLE = 'pharmacy|pharmacy-stock-table',
  PHARMACY_DISPENSATION_TABLE = 'pharmacy|pharmacy-dispensation-table',

  // STATISTICS MODULE
  STATISTICS_MODULE = 'statistics|config-module',
  STATISTICS_FOLLOW_ACTIVE_FILE_FILTER_FORM = 'statistics|follow-active-file-filter-form',
  STATISTICS_FOLLOW_INCLUSIONS_FILTER_FORM = 'statistics|follow-inclusions-filter-form',
  STATISTICS_FOLLOW_INCLUSIONS_BY_TRIAL_FILTER_FORM = 'statistics|follow-inclusions-by-trial-filter-form',
  STATISTICS_FOLLOW_TRIALS_FILTER_FORM = 'statistics|follow-trials-filter-form',
  STATISTICS_FOLLOW_INCLUSIONS_BY_TRIAL_LIST_TABLE = 'statistics|follow-inclusions-by-trial-list-table'

}


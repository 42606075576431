import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {VisitActService} from '../../../../../service/visit-act.service';
import {SharedService} from '../../../../../../shared/services/shared.service';
import {InvoiceService} from '../../../../../../invoice/services/invoice.service';
import {Data} from '../../../../../../shared/entity/data';
import {StateType} from '../../../../../../shared/entity/payment-type.enum';

@Component({
  selector: 'ih-visit-act-state-update-confirmation',
  templateUrl: './visit-act-state-update-confirmation.component.html',
  styleUrls: ['./visit-act-state-update-confirmation.component.css']
})
export class VisitActStateUpdateConfirmationComponent implements OnInit {

  public displayUpdateVisitActState: boolean;
  public visitActHhhIds: number[];
  public paymentStateHhhId: number;
  public state: { id: number, type: StateType };
  public reason: string;
  public message: string;
  public header: string;
  public visitHhhId: number;

  @Output() updatedSelectedVisitActsRealisationStateEvent = new EventEmitter<number>();
  @Output() updatedSelectedVisitActsPaymentStateEvent = new EventEmitter<number>();

  callback: (visitStateHhhId: number) => void;

  constructor(
    private visitActService: VisitActService,
    private sharedService: SharedService,
    private invoiceService: InvoiceService
  ) {
  }

  ngOnInit() {
    this.displayUpdateVisitActState = true;
    this.header = 'MODULE_VISITS_DIALOG_TITLE_SAVE_VISIT_MORE_ACTION_NECESSARY';
    this.setMessage();
  }

  setSelectedActsAsCompleted() {
    this.displayUpdateVisitActState = false;
    this.visitActService.setSelectedActsAsCompleted(this.visitActHhhIds, this.paymentStateHhhId)
      .subscribe(isStateSwitchable => {
        this.updatedSelectedVisitActsRealisationStateEvent.emit(2);
        this.reason = isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_INVOICED_ACTS'
          ? 'INVOICED_FROM_INVOICED_ACTS'
          : 'INVOICED_FROM_PAID_ACTS';
        this.setMessage();
        this.displayUpdateVisitActState = true;
      });
  }


  save() {
    if (this.reason === 'COMPLETED') {
      this.setSelectedActsAsCompleted();
    } else if (this.reason === 'INVOICED_FROM_INVOICED_ACTS') {
      this.createInvoiceFromInvoicedOrPaidActs(Data.invoiceStates.CREATED, Data.visitActPaymentStates.INVOICED);
    } else {
      this.createInvoiceFromInvoicedOrPaidActs(Data.invoiceStates.PAID, Data.visitActPaymentStates.PAID);
    }
  }

  createInvoiceFromInvoicedOrPaidActs(invoiceStateHhhId: number, visitActPaymentStateHhhId: number) {
    const  request = {
      visitHhhId: this.visitHhhId,
      invoiceStateId: invoiceStateHhhId,
      stateType:  this.state.type,
      visitActPaymentStateId: visitActPaymentStateHhhId,
      visitActIds: this.visitActHhhIds
    };
    this.invoiceService.createInvoiceFromInvoicedOrPaidActs(request)
      .subscribe(res => {
        this.callback(res);
        this.updatedSelectedVisitActsRealisationStateEvent.emit(2);
        this.updatedSelectedVisitActsPaymentStateEvent.emit(visitActPaymentStateHhhId);
        this.displayUpdateVisitActState = false;
      });
  }

  onVisitActPaymentStateChange(calback: (visitStateHhhId: number) => void) {
    this.callback = calback;
  }

  private setMessage() {
    if (this.reason === 'COMPLETED') {
      this.message = 'MODULE_VISIT_ACTS_DIALOG_QUESTION_AUTO_SET_VISIT_ACTS_AS_COMPLETED';
    } else if (this.reason === 'INVOICED_FROM_INVOICED_ACTS' || this.reason === 'INVOICED_FROM_PAID_ACTS') {
      this.message = 'MODULE_VISIT_ACTS_DIALOG_QUESTION_AUTO_CREATE_INVOICE';
    }
  }

}

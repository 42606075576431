export class Pharmacy {
  pharmacyHhhId?: number;
  trialHhhId: number;
  mepDate: Date;
  principalInvestigatorValidation: boolean;
  pharmacistValidation: boolean;
  attributionMethod: number;
  treatmentTypes: number[];
  administrationRoutes: number[];
  doses: number[];
}

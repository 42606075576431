import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared/services/shared.service';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {SiteService} from '../../site/services/site.service';
import {HumanResourcesService} from '../../human-resources/services/human-resources.service';
import {HomeService} from '../services/home.service';
import {HomeGlobalFilter} from '../models/home-global-filter';
import {ClinicalResearchActivity} from '../models/clinical-research-activity';
import {StatisticsService} from '../../statistics/services/statistics.service';
import {InclusionPerState} from '../models/inclusion-per-state';
import {Data} from '../../shared/entity/data';
import {TranslateService} from '@ngx-translate/core';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-clinical-research-activity',
  templateUrl: './clinical-research-activity.component.html',
  styleUrls: ['./clinical-research-activity.component.css']
})
export class ClinicalResearchActivityComponent implements OnInit {
  public homeGlobalFilter: HomeGlobalFilter = new HomeGlobalFilter();
  public clinicalResearchActivity: ClinicalResearchActivity = new ClinicalResearchActivity();
  public inclusionStatesBackgroundColor = [];
  public inclusionStatesData: any;
  public inclusionStatesLabels = [];
  public inclusionsStatesData = [];
  public inclusionIncluded: number;
  public inclusionInTreatment: number;
  public inclusionScreening: number;
  public inclusionPreScreening: number;
  public inclusionFailureScrining: number;

  public trialStatesBackgroundColor = [];
  public trialStatesData: any;
  public trialStatesLabels = [];
  public doughnutChartOptions: any = Data.pieAndDoughnutChartOptions;
  public barChartOptions: any = Data.barChartOptions;

  public inclusionGoalData: any;
  public year = (new Date()).getFullYear();

  isMultiSiteEnabled: boolean;

  constructor(
      private sharedService: SharedService,
      private siteService: SiteService,
      private translate: TranslateService,
      private homeService: HomeService,
      private statisticsService: StatisticsService,
      private humanResourceService: HumanResourcesService,
      private translateService: InnohealthTranslateService,
      private dynamicConfigService: DynamicConfigService,
  ) {
    for (let i = 0; i < 4; i++) {
      this.clinicalResearchActivity.inclusionsProgressMap[i] = new InclusionPerState();
    }
  }

  ngOnInit() {
    this.initProperties();
    this.getHomeInfo();
    this.homeGlobalFilter.year = (new Date()).getFullYear();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isMultiSiteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
    });
  }


  getHomeInfo() {
    this.homeService.getHomeInfo(this.homeGlobalFilter).subscribe(res => {
        this.clinicalResearchActivity = res;
        this.extractData();
        const total = this.getInclusionPerStateCount('En traitement') +
          this.getInclusionPerStateCount('Inclus') +
          this.getInclusionPerStateCount('En screening') +
          this.getInclusionPerStateCount('Pré-screening') +
          this.getInclusionPerStateCount('Screening failure');
        this.inclusionInTreatment = Math.round(this.getInclusionPerStateCount('En traitement') * 100 / total);
        this.inclusionIncluded = Math.round(this.getInclusionPerStateCount('Inclus') * 100 / total);
        this.inclusionScreening = Math.round(this.getInclusionPerStateCount('En screening') * 100 / total);
        this.inclusionPreScreening = Math.round(this.getInclusionPerStateCount('Pré-screening') * 100 / total);
        this.inclusionFailureScrining = Math.round(this.getInclusionPerStateCount('Screening failure') * 100 / total);
        this.onLanguageChanged();
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading HomeInfo.' + error);
      }
    );
  }

  onLanguageChanged() {
    this.translate.onLangChange.subscribe(() => {
      this.extractData();
    });
  }

  isParent() {
    return this.dynamicConfigService.parent;
  }

  private extractData() {
    this.extractInclusionStateData();
    this.extractTrialStateData();
    this.extractInclusionsGoalData();
  }

  private extractInclusionsGoalData() {
    const inclusionsGoalData = [];
    const months = Data.monthList;
    const monthPrefix = 'MODULE_REPORTS_INCLUSIONS_BY_TRIAL_';
    const inclusionsGoalLabels = [];
    if (this.clinicalResearchActivity.inclusionsGoal.length === 0){
      this.inclusionGoalData= null;
    }
    for (const inclusionGoal of this.clinicalResearchActivity.inclusionsGoal) {
      this.translate.stream(monthPrefix + months[inclusionGoal.month - 1]).subscribe(res => {
        inclusionsGoalLabels.push(res);
        if (this.clinicalResearchActivity.inclusionsGoal.length === inclusionsGoalLabels.length) {
          this.inclusionGoalData = {
            labels: inclusionsGoalLabels,
            datasets: [
              {
                label: 'Inclusion',
                backgroundColor: '#42A5F5',
                borderColor: '#1E88E5',
                data: inclusionsGoalData
              }
            ]
          };
        }
      });
      inclusionsGoalData.push(inclusionGoal.inclusions);
    }

  }

  private extractTrialStateData() {
    this.trialStatesBackgroundColor = [];
    this.trialStatesLabels = [];
    const trialsStatesData = [];
    this.clinicalResearchActivity.trialStates.forEach((trialState, i) => {
      this.trialStatesLabels.push(this.translateService.getTranslationString(trialState.state));
      trialsStatesData.push(trialState.trialCount);
      this.trialStatesBackgroundColor.push(this.statisticsService.getColor(i));
    });
    this.trialStatesData = {
      labels: this.trialStatesLabels,
      datasets: [
        {
          data: trialsStatesData,
          backgroundColor: this.trialStatesBackgroundColor,
          hoverBackgroundColor: this.trialStatesBackgroundColor
        }]
    };
  }

  private extractInclusionStateData() {
    this.inclusionStatesBackgroundColor = [];
    this.inclusionStatesLabels = [];
    this.inclusionsStatesData = [];
    this.clinicalResearchActivity.inclusionStates.forEach((inclusionState, i) => {
      this.inclusionStatesLabels.push(this.translateService.getTranslationString(inclusionState.state));
      this.inclusionsStatesData.push(inclusionState.inclusions);
      this.inclusionStatesBackgroundColor.push(this.statisticsService.getColor(i));
    });
    this.inclusionStatesData = {
      labels: this.inclusionStatesLabels,
      datasets: [
        {
          data: this.inclusionsStatesData,
          backgroundColor: this.inclusionStatesBackgroundColor,
          hoverBackgroundColor: this.inclusionStatesBackgroundColor
        }]
    };
  }

  getInclusionsProgressCount(index: number) {
    if (!this.clinicalResearchActivity.inclusionsProgressMap) {
      return 0;
    }
    return this.clinicalResearchActivity.inclusionsProgressMap[index] ? this.clinicalResearchActivity.inclusionsProgressMap[index].inclusions : 0;
  }

  getInclusionPerStateCount(label: string) {
    if (!this.clinicalResearchActivity.inclusionStates) {
      return 0;
    }
    const index = this.inclusionStatesLabels.indexOf(label);
    return this.clinicalResearchActivity.inclusionStates[index] ? this.clinicalResearchActivity.inclusionStates[index].inclusions : 0;
  }

}

import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';

export class TrialFixedCost {
  public hhhId: number;
  public publicCommentCount: number;
  public contractType: string;
  public contractTypeHhhId: number;
  public typeHhhId: number;
  public typeName: string;
  public description: string;
  public attachedToVisit: boolean;
  public annualInvoicing: boolean;
  public invoiceable: boolean;
  public optional: boolean;
  public pricePerUnit: number;
  public fixedCostCategory: string;
  public referenceFixedCost: string;
  referenceFixedCostHhhId: number;
  public unitNumber: number;
  public firstSegment: string;
  public secondSegment: string;
  public temps: string;
  public total: number;
  public checked: boolean;
  public internalAccountNumber: string;
  public secondInternalAccountNumber: string;
  public editable = false;
  freeSS: boolean;

  init(obj: any): TrialFixedCost {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'contractType') {
        if (required && Util.isNullOrUndefined(this.contractTypeHhhId)) {
          return false;
        }
      } else if (key === 'type') {
        if (required && Util.isNullOrUndefined(this.typeHhhId)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }

}

import {ModuleIcons} from './module-icon';
import {DynamicDefinitionEnum} from './dynamic-definition-enum';

export class AdminConsoleModules {
  public static configurations = [
    {
      name: 'Configuration Globale',
      title: 'global-config',
      icon: ModuleIcons.globalConfigIcon,
      components: [
        {name: 'Configuration Globale', target: DynamicDefinitionEnum.GLOBAL_PROPERTIES},
        {name: 'Modules', target: DynamicDefinitionEnum.SIDEBAR_MENU_CONTAINER},
        {name: 'En tête', target: DynamicDefinitionEnum.PAGE_HEADER_CONTAINER},
        {
          name: 'Configuration administrateur', components: [
            {name: 'Charge de travail', target: DynamicDefinitionEnum.ADMIN_WORKLOAD_TABLE},
            {name: 'Gestion des comptes', target: DynamicDefinitionEnum.ADMIN_ACCOUNT_TABLE},
            {name: 'Ajouter un utilisateur', target: DynamicDefinitionEnum.ADMIN_NEW_USER_FORM},
            {name: 'Essais de connexion', target: DynamicDefinitionEnum.USER_ATTEMPTS_LIST},
            {name: 'Audit trail', target: DynamicDefinitionEnum.AUDIT_TRAIL_TABLE}

          ]
        },
        {name: 'Export personnalisé', target: DynamicDefinitionEnum.CUSTOM_EXPORT_FORM},
      ]
    },
    {
      name: 'Configuration du module Ressource Humaine',
      title: 'human-resource-module',
      icon: ModuleIcons.humanResourceIcon,
      components: [
        {
          name: 'Configuration globale', target: DynamicDefinitionEnum.HUMAN_RESOURCE_MODULE
        },
        {name: 'Ajouter', target: DynamicDefinitionEnum.HUMAN_RESOURCE_ADD_FORM},
        {
          name: 'Modifier', components: [
            {name: 'Informations', target: DynamicDefinitionEnum.HUMAN_RESOURCE_INFORMATION_FORM},
            {name: 'Contacts', target: DynamicDefinitionEnum.HUMAN_RESOURCE_CONTACT_FORM}
          ]
        },
        {name: 'Tableau', target: DynamicDefinitionEnum.HUMAN_RESOURCE_LIST_TABLE}
      ]
    },
    {
      name: 'Configuration du module Prestataire',
      title: 'provider-module',
      icon: ModuleIcons.providerIcon,
      components: [
        {name: 'Configuration globale', target: DynamicDefinitionEnum.PROVIDER_MODULE},
        {name: 'Ajouter', target: DynamicDefinitionEnum.PROVIDER_ADD_FORM},
        {name: 'Tableau', target: DynamicDefinitionEnum.PROVIDER_LIST_TABLE}
      ]
    },
    {
      name: 'Configuration du module Agenda',
      title: 'agenda-module',
      icon: ModuleIcons.agendaIcon,
      components: [
        {name: 'Configuration globale', target: DynamicDefinitionEnum.AGENDA_MODULE},
        {name: 'Filtre Agenda', target: DynamicDefinitionEnum.AGENDA_FILTER_FORM},
      ]
    },
    {
      name: 'Configuration du module Facturation',
      title: 'invoice-module',
      icon: ModuleIcons.invoiceIcon,
      components: [
        {name: 'Configuration globale', target: DynamicDefinitionEnum.INVOICE_MODULE},
        {
          name: 'Préfactures', components: [
            {name: 'Tableau', target: DynamicDefinitionEnum.INVOICE_LIST_TABLE},
            {name: 'Ajouter', target: DynamicDefinitionEnum.INVOICE_ADD_FORM},
            {name: 'Éditer', target: DynamicDefinitionEnum.INVOICE_INFORMATION_FORM},
            {name: 'Liste des actes', target: DynamicDefinitionEnum.INVOICE_ACT_LIST_TABLE},
            {name: 'Liste des contrepartie', target: DynamicDefinitionEnum.INVOICE_COUNTERPART_LIST_TABLE},
            {name: 'Liste des Coûts / Surcoûts ', target: DynamicDefinitionEnum.INVOICE_FIXED_COST_LIST_TABLE},
          ]
        },
        {
          name: 'Factures', components: [
            {name: 'Tableau', target: DynamicDefinitionEnum.INVOICE_STATIC_LIST_TABLE},
            {name: 'Éditer', target: DynamicDefinitionEnum.INVOICE_STATIC_INFORMATION_FORM},
            {name: 'Liste des actes', target: DynamicDefinitionEnum.INVOICE_STATIC_ACT_LIST_TABLE},
            {name: 'Liste des Coûts / Surcoûts ', target: DynamicDefinitionEnum.INVOICE_STATIC_FIXED_COST_LIST_TABLE},
            {name: 'Liste des contrepartie', target: DynamicDefinitionEnum.INVOICE_STATIC_COUNTERPART_LIST_TABLE}
          ]
        }
      ]
    },
    {
      name: 'Configuration du module Patient',
      title: 'patient-module',
      icon: ModuleIcons.patientIcon,
      components: [
        {name: 'Configuration globale', target: DynamicDefinitionEnum.PATIENT_MODULE},
        {name: 'Ajouter', target: DynamicDefinitionEnum.PATIENT_ADD_FORM},
        {
          name: 'Éditer', components: [
            {name: 'Information', target: DynamicDefinitionEnum.PATIENT_INFORMATION_FORM},
            {name: 'Contact', target: DynamicDefinitionEnum.PATIENT_CONTACT_FORM},
            {name: 'Liste des inclusions', target: DynamicDefinitionEnum.PATIENT_INCLUSION_TABLE},
          ]
        },
        {name: 'Tableau', target: DynamicDefinitionEnum.PATIENT_LIST_TABLE}
      ]
    },
    {
      name: 'Configuration du module Sites',
      title: 'site-module',
      icon: ModuleIcons.siteIcon,
      components: [
        {name: 'Configuration globale', target: DynamicDefinitionEnum.SITE_MODULE},
        {name: 'Ajouter', target: DynamicDefinitionEnum.SITE_ADD_FORM},
        {name: 'Tableau', target: DynamicDefinitionEnum.SITE_LIST_TABLE}
      ]
    },
    {
      name: 'Configuration du module Surcouts',
      title: 'additional-costs-module',
      icon: ModuleIcons.additionalCostsIcon,
      components: [
        {name: 'Configuration globale', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_MODULE},
        {
          name: 'Catégorie des actes', components: [
            {name: 'Ajouter', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_ACT_CATEGORY_ADD_FORM},
            {name: 'Tableau', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_ACT_CATEGORY_LIST_TABLE},
          ]
        },
        {
          name: 'Grille des actes', components: [
            {name: 'Ajouter', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_ACTS_ADD_FORM},
            {name: 'Tableau', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_ACTS_LIST_TABLE},
          ]
        },
        {
          name: 'Catégorie des Coûts / Surcoûts', components: [
            {name: 'Ajouter', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_FIXED_COST_CATEGORY_ADD_FORM},
            {name: 'Tableau', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_FIXED_COST_CATEGORY_LIST_TABLE},
          ]
        },
        {
          name: 'Grille des Coûts / Surcoûts', components: [
            {name: 'Ajouter', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_FIXED_COST_ADD_FORM},
            {name: 'Tableau', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_FIXED_COST_LIST_TABLE},
          ]
        },
        {
          name: 'Catégorie des contres parties', components: [
            {name: 'Ajouter', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_COUNTER_PART_CATEGORY_ADD_FORM},
            {name: 'Tableau', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_COUNTER_PART_CATEGORY_LIST_TABLE},
          ]
        },
        {
          name: 'Grille des contres parties', components: [
            {name: 'Ajouter', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_COUNTER_PART_ADD_FORM},
            {name: 'Tableau', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_COUNTER_PART_LIST_TABLE},
          ]
        },
        {
          name: 'Actes opérationnels', components: [
            {name: 'Tableau', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_OPERATIONAL_ACTS_LIST_TABLE},
            {name: 'Ajouter', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_OPERATIONAL_ACTS_ADD_FORM},
            {name: 'Liste des actes de références', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_INCLUSION_ACTS_LIST_TABLE},
            {name: 'Actes inclus dans les études', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_OPERATIONAL_TRIAL_ACTS_LIST_TABLE},
            {name: 'Actes inclus dans les actes opérationnels ', target: DynamicDefinitionEnum.ADDITIONAL_COSTS_INCLUSION_OPERATIONAL_ACTS_LIST_TABLE},
          ]
        },
      ]
    },
    {
      name: 'Configuration du module Etablissement',
      title: 'establishment-module',
      icon: ModuleIcons.establishmentIcon,
      components: [
        {name: 'Configuration globale', target: DynamicDefinitionEnum.ESTABLISHMENT_MODULE},
        {
          name: 'Etablissement', components: [
            {name: 'Formulaire des informations', target: DynamicDefinitionEnum.ESTABLISHMENT_INFORMATION_FORM},
            {name: 'Liste des services', target: DynamicDefinitionEnum.ESTABLISHMENT_LIST_TABLE},
            {name: 'Formulaire d\'ajout des files actives', target: DynamicDefinitionEnum.ESTABLISHMENT_ACTIVE_FILE_BY_YEAR_FORM}
          ]
        },
        {
          name: 'Autre Etablissement', components: [
            {name: 'Liste des autres établissement', target: DynamicDefinitionEnum.ESTABLISHMENT_OTHER_TABLE},
            {name: 'Formulaire d\'édit', target: DynamicDefinitionEnum.ESTABLISHMENT_OTHER_FORM},
          ]
        },
        {name: 'Liste des contacts', target: DynamicDefinitionEnum.ESTABLISHMENT_CONTACT_LIST_TABLE},

      ]
    },
    {
      name: 'Configuration du module Etude',
      title: 'trial-module',
      icon: ModuleIcons.trialIcon,
      components: [
        {name: 'Configuration globale', target: DynamicDefinitionEnum.TRIAL_MODULE},
        {name: 'Tableau', target: DynamicDefinitionEnum.TRIAL_LIST_TABLE},
        {
          name: 'informations d\'identification', components: [
            {name: 'Onglet', target: DynamicDefinitionEnum.TRIAL_IDENTIFICATION_INFORMATION_CONTAINER},
            {name: 'Information', target: DynamicDefinitionEnum.TRIAL_IDENTIFICATION_INFORMATION_FORM},
            {name: 'Personnel', target: DynamicDefinitionEnum.TRIAL_PERSONNEL_INFORMATION_FORM},
            {name: 'Personnel Prestataire', target: DynamicDefinitionEnum.TRIAL_PERSONNEL_PROVIDER_INFORMATION_FORM},
            {name: 'Versions protocoles', target: DynamicDefinitionEnum.TRIAL_AMENDMENT_PROTOCOL_FORM},
            {name: 'Versions consentements', target: DynamicDefinitionEnum.TRIAL_AMENDMENT_CONSENT_FORM},
            {name: 'Traitement(s)', target: DynamicDefinitionEnum.TRIAL_TREATMENT_METHODOLOGIE_FORM},
            {name: 'Critère d\'inclusions exclusions', target: DynamicDefinitionEnum.TRIAL_INCLUSION_EXCLUSION_CRITERIA_FORM},
            {name: 'Discipline d\'inclusions exclusions', target: DynamicDefinitionEnum.TRIAL_INCLUSION_EXCLUSION_CRITERIA_DISCIPLINE_FORM},
            {name: 'Discipline', target: DynamicDefinitionEnum.TRIAL_DISCIPLINE_INFORMATION_FORM},
            {name: 'Bras', target: DynamicDefinitionEnum.TRIAL_ARM_LIST_TABLE},
            {name: 'Design', target: DynamicDefinitionEnum.TRIAL_DESIGN_FORM},
            {name: 'Proposer l\'étude à un centre', target: DynamicDefinitionEnum.TRIAL_SEND_TRIAL_TO_ESTABLISHMENT_FORM}
          ]
        },
        {
          name: 'Administratif', components: [
            {name: 'Onglet', target: DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_INFORMATION_CONTAINER},
            {name: 'Reglementaires', target: DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_REGULATORY_INFORMATION_FORM},
            {name: 'Dates', target: DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_DATES_INFORMATION_FORM},
            {name: 'Faisabilié', target: DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_FEASIBILITY_INFORMATION_FORM},
            {name: 'CEC', target: DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_CEC_INFORMATION_FORM},
            {name: 'Convention', target: DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_CONVENTION_INFORMATION_FORM},
            {name: 'Logistique', target: DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_LOGISTIC_INFORMATION_FORM},
            {name: 'Suivi étude', target: DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_TRIAL_FOLLOWUP_INFORMATION_FORM},
            {name: 'COMOP', target: DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_COMOP_INFORMATION_FORM}
          ]
        },
        {
          name: 'Tableau de bord', components: [
            {name: 'Tableau de bord', target: DynamicDefinitionEnum.TRIAL_DASHBORD_FORM}
          ]
        },
        {name: 'Contacts', components: [
            {name: 'formulaire d\'ajout d\'un contact', target: DynamicDefinitionEnum.TRIAL_CONTACT_ADD_FORM},
            {name: 'formulaire d\'édit d\'un contact', target: DynamicDefinitionEnum.TRIAL_CONTACT_UPDATE_FORM},
          ]
        },
        {
          name: 'Inclusions', components: [
            {name: 'Liste d\'inclusions ', target: DynamicDefinitionEnum.TRIAL_INCLUSION_LIST_TABLE},
            {name: 'Informations Bras ', target: DynamicDefinitionEnum.TRIAL_INCLUSION_INFORMATION_ARM_FORM},
            {name: 'Informations  Version Protocole', target: DynamicDefinitionEnum.TRIAL_INCLUSION_INFORMATION_PROTOCOL_VERSION_FORM},
            {name: 'Ajouter une visite', target: DynamicDefinitionEnum.TRIAL_INCLUSION_CALENDAR_VISIT_FORM},
            {name: 'Détails visite', target: DynamicDefinitionEnum.TRIAL_INCLUSION_CALENDAR_VISIT_DETAIL_FORM},
            {name: 'Onglet du Followup', target: DynamicDefinitionEnum.TRIAL_INCLUSION_PATIENT_FOLLOWUP_CONTAINER},
            {name: 'EIG', target: DynamicDefinitionEnum.TRIAL_ADVERSE_EFFECT_TABLE},
            {name: 'Editer EIG', target: DynamicDefinitionEnum.TRIAL_ADVERSE_EFFECT_FORM},
            {name: 'Traitements concomitants', target: DynamicDefinitionEnum.TRIAL_CONCOMITANT_TREATMENT_TABLE},
            {name: 'Editer traitements concomitants', target: DynamicDefinitionEnum.TRIAL_CONCOMITANT_TREATMENT_FORM},
            {name: 'Signes vitaux', target: DynamicDefinitionEnum.TRIAL_VITAL_SIGNS_TABLE},
            {name: 'Editer signes vitaux', target: DynamicDefinitionEnum.TRIAL_VITAL_SIGNS_FORM},
          ]
        },
        {
          name: 'Sites', components: [
            {name: 'Liste des sites', target: DynamicDefinitionEnum.TRIAL_SITE_LIST_TABLE},
            {name: 'Onglet', target: DynamicDefinitionEnum.TRIAL_SITE_CONTAINER}
          ]
        },
        {
          name: 'Tableau de suivi', components: [
            {name: 'Table de suivi', target: DynamicDefinitionEnum.TRIAL_TRACKING_TABLE_FILTER_FORM},
          ]
        },
        {
          name: 'Calendrier Théorique', components: [
            {name: 'Actes de référence', target: DynamicDefinitionEnum.TRIAL_THEORETICAL_CALENDAR_REFERENCE_ACT_LIST_TABLE},
            {name: 'Surcoûts', target: DynamicDefinitionEnum.TRIAL_THEORETICAL_CALENDAR_ADDITIONAL_COST_LIST_TABLE},
            {name: 'Visite théorique', target: DynamicDefinitionEnum.TRIAL_THEORETICAL_CALENDAR_VISIT_ADD_FORM},
            {name: 'Acte théorique', target: DynamicDefinitionEnum.TRIAL_THEORETICAL_VISIT_ACTS_TABLE}

          ]
        },
        {
          name: 'Grille budgétaire', components: [
            {name: 'Liste des actes', target: DynamicDefinitionEnum.TRIAL_ADDITIONAL_COSTS_ACTS_LIST_TABLE},
            {name: 'Edition acte', target: DynamicDefinitionEnum.TRIAL_ACT_FORM},
            {name: 'Coûts / Surcoûts', target: DynamicDefinitionEnum.TRIAL_ADDITIONAL_COSTS_FIXED_COST_LIST_TABLE},
            {name: 'Edition Coût / Surcoût', target: DynamicDefinitionEnum.TRIAL_FIXED_COST_FORM},
            {name: 'Contreparties', target: DynamicDefinitionEnum.TRIAL_ADDITIONAL_COSTS_COUNTERPART_LIST_TABLE},
            {name: 'Edition contrepartie', target: DynamicDefinitionEnum.TRIAL_COUNTERPART_FORM},
            {name: 'Actes opérationnels', target: DynamicDefinitionEnum.TRIAL_ADDITIONAL_COSTS_OPERATIONAL_ACTS_LIST_TABLE},
            {name: 'Edition acte opérationnel', target: DynamicDefinitionEnum.TRIAL_OPERATIONAL_ACT_FORM},

          ]
        },
        {

          name: 'Pharmacie', components: [
            {name: 'Information', target: DynamicDefinitionEnum.TRIAL_PHARMACY_INFORMATION_FORM},
            {name: 'Stock', target: DynamicDefinitionEnum.TRIAL_PHARMACY_STOCK_TABLE},
            {name: 'Ajout d\'un lot', target: DynamicDefinitionEnum.TRIAL_PHARMACY_STOCK_FORM},
            {name: 'Dispention', target: DynamicDefinitionEnum.TRIAL_PHARMACY_DISPENSATION_TABLE}
          ]
        },
      ]
    },
    {
      name: 'Configuration du module de Statistiques',
      title: 'statistics-module',
      icon: ModuleIcons.statisticIcon,
      components: [
        {name: 'Configuration globale', target: DynamicDefinitionEnum.STATISTICS_MODULE},
        {
          name: 'Statistiques', components: [
            {name: 'Filtre de suivi des études', target: DynamicDefinitionEnum.STATISTICS_FOLLOW_TRIALS_FILTER_FORM},
            {name: 'Filtre de suivi des inclusions', target: DynamicDefinitionEnum.STATISTICS_FOLLOW_INCLUSIONS_FILTER_FORM},
            {name: 'Filtre de suivi de file active', target: DynamicDefinitionEnum.STATISTICS_FOLLOW_ACTIVE_FILE_FILTER_FORM},
            {name: 'Filtre de suivi d\'inclusions par études', target: DynamicDefinitionEnum.STATISTICS_FOLLOW_INCLUSIONS_BY_TRIAL_FILTER_FORM},
            {name: 'Table de suivi d\'inclusions par étude', target: DynamicDefinitionEnum.STATISTICS_FOLLOW_INCLUSIONS_BY_TRIAL_LIST_TABLE},
          ]
        }
      ]
    },
    {
      name: 'Configuration du module de Pharmacie',
      title: 'pharmacy-module',
      icon: ModuleIcons.pharmacyIcon,
      components: [
        {name: 'Configuration globale', target: DynamicDefinitionEnum.PHARMACY_MODULE},
        {
          name: 'pharmacy', components: [
            {name: 'stock', target: DynamicDefinitionEnum.PHARMACY_STOCK_TABLE},
            {name: 'dispensation', target: DynamicDefinitionEnum.PHARMACY_DISPENSATION_TABLE},
          ]
        }
      ]
    }
    ];
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {Inclusion} from '../entity/inclusion/inclusion';
import {OtherConsent} from '../../other-consent';
import {RoleValue} from '../../human-resources/models/role-value';
import {BlocFields} from '../../bloc-fields';
import {catchError, map} from 'rxjs/operators';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {LabelValue} from "../../shared/entity/label-value";

@Injectable({
  providedIn: 'root'
})
export class InclusionDetailService {

  public host = new Properties().host + '/inclusion';
  public trialId: number;
  public inclusionHhhId: number;
  public inclusionInformation = new Inclusion();

  constructor(private httpClient: HttpClient) {
  }

  getInclusionInformation(hhhId: number): Observable<Inclusion> {
    this.inclusionHhhId = hhhId;
    return this.httpClient.get<Inclusion>(this.host + '/load/' + hhhId).pipe(
      map(res => {
          return (new Inclusion()).init(res);
        }
      ),
      catchError(this.handleError));
  }

  getTrialHasMolecularPreScreening(trialHhhId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(this.host + '/trial-has-molecular-pre-screening/' + trialHhhId);
  }

  getPatientData(inclusionHhhId: number): Observable<RoleValue> {
    return this.httpClient.get<RoleValue>(this.host + `/role-value/${inclusionHhhId}`);
  }

  updateInclusion(inclusion: Inclusion): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update', inclusion);
  }

  updateChangeDesign(inclusionHhhId: number) {
    return this.httpClient.put(this.host + '/change-design-autorisation/' + inclusionHhhId, {});
  }

  setTrialAndInclusionId(trialId) {
    this.trialId = trialId;
  }

  setInclusionInformationToExport(inclusionInformation: Inclusion) {
    this.inclusionInformation = inclusionInformation;
  }

  exportInclusionInformation(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request);
  }

  getOtherConsents(hhhId: number): Observable<OtherConsent[]> {
    return this.httpClient.get<OtherConsent[]>(this.host + '/other-consent/load-list/' + hhhId);
  }

  deleteOtherConsent(hhhId: number) {
    return this.httpClient.get<void>(this.host + '/other-consent/delete/' + hhhId);
  }

  addAmendments(amendmentList: OtherConsent[], hhhId: number) {
    return this.httpClient.post(this.host + '/other-consent/add/' + hhhId, amendmentList);
  }

  getBlocFields(hhhId: number): Observable<BlocFields[]> {
    return this.httpClient.get<BlocFields[]>(this.host + '/bloc-field/load-list/' + hhhId);
  }

  addBlocDate(blocFieldsList: BlocFields[], hhhId: number) {
    return this.httpClient.post(this.host + '/bloc-field/add/' + hhhId, blocFieldsList);
  }

  getCaseManagementSelectedByTrial(trialHhhId :number): Observable<LabelValue[]> {
    return this.httpClient.get<LabelValue[]>(this.host + "/case-management-selected/" + trialHhhId);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}


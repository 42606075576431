import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {GlobalInvoicesTotalAmountByMonthFilter} from '../models/GlobalInvoicesTotalAmountByMonthFilter';
import {InvoicesTotalAmountByMonth} from '../models/invoices-total-amount-by-month';
import {SelectItem} from 'primeng/api';
import {FollowInvoiceService} from '../services/follow-invoice.service';
import {InnohealthTranslateService} from 'src/app/shared/services/innohealth-translate.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {DataService} from 'src/app/shared/services/data-service';
import {YearRecord} from '../../../establishment/models/year-record';
import {PaginatorTableComponent} from '../../../shared/component/paginator-table/paginator-table.component';
import {Data} from '../../../shared/entity/data';
import {DynamicConfigService} from '../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-follow-invoice',
  templateUrl: './follow-invoice.component.html',
  styleUrls: ['./follow-invoice.component.css']
})
export class FollowInvoiceComponent extends PaginatorTableComponent<InvoicesTotalAmountByMonth> implements OnInit, OnDestroy {

  investigatorSpecialityLabelKey = 'REPORT_TRIALS_TOTAL_BY_MONTH_SPECIALITY';

  totalAmountByMonthColumns: any;
  years: YearRecord[] = [];
  globalFilter: GlobalInvoicesTotalAmountByMonthFilter;
  invoicesTotalsAmountByMonth: InvoicesTotalAmountByMonth = new InvoicesTotalAmountByMonth();
  data: any;
  additionalCostCategories: SelectItem[] = [];
  options: any = Data.histogramOptions;
  retrocessionFees = false;


  constructor(
      private dataService: DataService,
      private cfr: ComponentFactoryResolver,
      private translateService: InnohealthTranslateService,
      private followInvoiceService: FollowInvoiceService,
      private router: Router,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    this.retrocessionFees = url.toString().indexOf('retrocession-fees') !== -1;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.globalFilter = new GlobalInvoicesTotalAmountByMonthFilter();
    this.getYears();
    this.getInvoicesTotalAmountByMonth();
    this.getAdditionalCostCategory();
    this.globalFilter.filterInvoiceIssueYear = new YearRecord();
    this.globalFilter.filterInvoiceIssueYear.year = Number(moment(new Date()).format('YYYY'));
    this.totalAmountByMonthColumns = [
      {header: 'REPORT_INCLUSIONS_TOTAL_BY_MONTH_TRIAL', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_1', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_2', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_3', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_4', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_5', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_6', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_7', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_8', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_9', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_10', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_11', field: ''},
      {header: 'GENERAL_LINGUISTICS_MONTH_MMM_UCASE_12', field: ''},
      {header: 'REPORT_INVOICES_TOTAL_AMOUNT_BY_MONTH_LEGEND_TOTAL', field: ''}
    ];
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.investigatorSpecialityLabelKey = this.dynamicConfigService.getProperty(PropertyEnum.investigatorSpecialityLabelKey);
    });
  }

  getExportPageModule(): string {
    return 'src/app/invoice/follow-invoice/follow-invoice/follow-invoice.component';
  }

  getInvoicesTotalAmountByMonth() {
    this.followInvoiceService.getInvoicesTotalAmountByMonth(this.retrocessionFees, this.globalFilter).subscribe(res => {
      this.invoicesTotalsAmountByMonth = res;
      this.generateHistogramData();
      this.followInvoiceService.invoicesTotalsAmountByMonth = this.invoicesTotalsAmountByMonth;
      this.followInvoiceService.globalFilter = this.globalFilter;
    });
  }

  generateHistogramData() {
    this.data = {
      labels: [
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_1'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_2'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_3'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_4'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_5'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_6'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_7'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_8'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_9'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_10'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_11'),
        this.translateService.getTranslationString('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_12')],
      datasets: [
        {
          type: 'line',
          label: 'Facturations',
          data: this.invoicesTotalsAmountByMonth.totalAmountByMonth,
          fill: false,
          borderColor: '#397CC3',
          backgroundColor: '#397CC3',
          borderWidth: 2,
        }
      ],
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{ticks: {suggestedMin: 0, beginAtZero: true, stepSize: 10000}, autoSkip: true}]
        }
      }

    };
  }

  getYears() {
    for (let i = 2029; i > 1899; i--) {
      const year = new YearRecord();
      year.year = i;
      this.years.push(year);
    }
  }

  getAdditionalCostCategory() {
    this.followInvoiceService.getAdditionalCosts().subscribe(res => {
      for (const actCategory of res) {
        this.additionalCostCategories.push({label: actCategory.name, value: actCategory});
      }
    });
  }

  navigateToTrialDetails(hhhId: number) {
    this.router.navigate(
        ['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId}}
    ).then();
  }
}

import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../shared/component/form/form.component';
import {DropdownField} from '../../admin/models/dropdown-field';
import {SharedService} from '../../shared/services/shared.service';

import {Data} from '../../shared/entity/data';
import {NgForm} from '@angular/forms';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DataItemService} from '../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-dropdown-field-form',
  templateUrl: './dropdown-field-form.component.html',
  styleUrls: ['./dropdown-field-form.component.css']
})
export class DropdownFieldFormComponent extends FormComponent<DropdownField> implements OnInit {

  organFamilyEnabled = false;

  @ViewChild('siteForm') dropdownFieldForm: NgForm;
  @Input() selectedElement: string;
  @Input() showSubClassSection: boolean;
  public display = false;
  public newField: DropdownField = new DropdownField();
  typeListOrgans = Data.typeListOrgans;
  associationBoxVisible = false;
  associationBoxRequestVisible = false;
  associationName: string;
  existingTargetOfTreatmentExperimentalNames: string[] = [];
  filteredTargetOfTreatmentExperimentalNames: string[] = [];

  constructor(
    private sharedService: SharedService,
    public dynamicConfigService: DynamicConfigService,
    private dataService: DataItemService) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(DropdownFieldFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.organFamilyEnabled = this.dynamicConfigService.getProperty(PropertyEnum.organFamilyEnabled);
    });
  }

  submit() {
    this.submitted = true;
    if (this.newField.dropdownFieldName.length !== 0) {
      this.newField.dropdownType = this.newField.dropdownType == null ? this.selectedElement : this.newField.dropdownType;
      if (this.selectedElement.indexOf('organ-family') !== -1) {
        if (this.organFamilyEnabled && this.newField.dropdownType.indexOf('Famille d\'organe') !== -1) {
          this.selectedElement = 'organ-family';
        } else {
          this.selectedElement = 'organ-and-organ-family';
        }
      }
      if (this.selectedElement.indexOf('therapeutic-class-for-drugs') !== -1) {
        this.selectedElement = 'therapeutic-class-for-drugs';
      }
      this.dataService.addField(this.selectedElement, this.newField).subscribe(res => {
        this.display = false;
        this.sharedService.showSuccess();
        const newItem = new DropdownField();
        for (let i = 0; i < this.newField.dropdownFieldName.length; i++) {
          newItem.dropdownFieldName[i] = res[i].label;
          newItem.dropdownType = res[i].value;
        }
        this.callback(newItem);
      });
    } else {
      return;
    }
  }

  getAllExistingTargetOfTreatmentExperimentalnames() {
    this.sharedService.getTargetOfTreatmentExperimentalNames().subscribe(res => {
      this.existingTargetOfTreatmentExperimentalNames = res;
    });
  }

  onNameAdd() {
    if (this.selectedElement === 'trial-treatment-experimental') {
      this.associationBoxRequestVisible = true;
    }
  }

  showAssociationBox() {
    this.getAllExistingTargetOfTreatmentExperimentalnames();
    this.associationBoxRequestVisible = false;
    this.associationBoxVisible = true;
  }

  addAssociation() {
    this.newField.dropdownFieldName[this.newField.dropdownFieldName.length - 1] += ';' + this.associationName;
    this.associationName = null;
    this.associationBoxVisible = false;
  }

  search($event: any) {
    this.filteredTargetOfTreatmentExperimentalNames = this.existingTargetOfTreatmentExperimentalNames.filter(s => s.indexOf($event.query) >= 0);
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {AdverseEffect} from '../../../../entity/inclusion/adverse-effect';
import {VisitService} from '../../../../service/visit.service';
import {SharedService} from '../../../../../shared/services/shared.service';
import {AdverseEffectService} from '../../../../service/adverse-effect.service';
import {FormComponent} from '../../../../../shared/component/form/form.component';
import {FR} from '../../../../../shared/entity/calendar-language';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../dynamic-config/entity/form-config';
import {PatientAppData} from '../../../../../patient/patient-app-data';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../../trial-app-data';

@Component({
  selector: 'ih-adverse-effect-form',
  templateUrl: './adverse-effect-form.component.html',
  styleUrls: ['./adverse-effect-form.component.css']
})
export class AdverseEffectFormComponent extends FormComponent<AdverseEffect> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_ADVERSE_EFFECT_FORM;
  config: FormConfig = new FormConfig();

  locale = FR;
  adverseEffect: AdverseEffect = new AdverseEffect();
  severities = [
    {label: 'Niveau 1', value: 1},
    {label: 'Niveau 2', value: 2},
    {label: 'Niveau 3', value: 3},
    {label: 'Niveau 4', value: 4}
  ];

  constructor(
      private visitService: VisitService,
      private adverseEffectService: AdverseEffectService,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService,
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(AdverseEffectFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  submit() {
    this.submitted = true;
    if (!this.adverseEffect.isValid(this.config)) {
      return;
    }
    if (!this.adverseEffect.hhhId) {
      this.addAdverseEffect();
    } else {
      this.updateAdverseEffect();
    }
    this.callback(this.adverseEffect);
    this.display = false;
  }

  private updateAdverseEffect() {
    this.adverseEffectService.updateAdverseEffect(this.adverseEffect).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  private addAdverseEffect() {
    this.adverseEffectService.addAdverseEffect(this.adverseEffect).subscribe(() => {
      this.sharedService.showSuccess();
    });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
}

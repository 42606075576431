import {ClonedVisit} from './cloned-visit';
import {DetailedVisit} from './detailed-visit';
import {VisitUpdate} from './visit-update';
import {VisitActUpdate} from './visit-act-update';

export class TrialInclusionCalendar {
  clonedVisits: ClonedVisit[];
  deletedVisits: number[];
  detailedVisitDto: DetailedVisit[];
  visitsToEdit: VisitUpdate[] = [];
  visitActsToEdit: VisitActUpdate[] = [];
  trialHhhId: number;
  inclusionHhhId: number;
}

import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {TrialAdministrativeRegulatoryInformation} from '../models/trial-administrative-regulatory-information';
import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Properties} from '../../helpers/properties';

@Injectable({
  providedIn: 'root'
})
export class TrialAdministrativeRegulatoryService {

  public host = new Properties().host + '/trial-administrative-regulatory-information';

  constructor(private httpClient: HttpClient) {}

  getTrialAdministrativeRegulatoryInformation(trialHhhId: number): Observable<TrialAdministrativeRegulatoryInformation> {
    return this.httpClient.get<TrialAdministrativeRegulatoryInformation>(this.host + '/load/' + trialHhhId)
      .pipe(
        map(res => {
            return (new TrialAdministrativeRegulatoryInformation()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  saveTrialAdministrativeRegulatoryInformation(trialHhhId: number, trialAdministrativeRegulatoryInformation: TrialAdministrativeRegulatoryInformation): Observable<any> {
    return this.httpClient.put<any>(this.host + '/update' + '?trialId=' + trialHhhId, trialAdministrativeRegulatoryInformation);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

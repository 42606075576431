import {isUndefined} from 'util';

export class OtherConsent {
  public name: string;
  public date: Date;
  public value: number;
  public isNew: boolean; // this attribute is used only in front
  public inputName: string; // this attribute is used only in front

  constructor() {
    if (!isUndefined(this.date)) {
      this.date = new Date(this.date);
    } else {
      this.date = null;
    }
    this.isNew = true;
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {InclusionDetailService} from '../../../service/inclusion-detail.service';
import {FR} from '../../../../shared/entity/calendar-language';
import {SharedService} from '../../../../shared/services/shared.service';
import {OtherConsent} from '../../../../other-consent';
import {TheoreticalCalendarService} from '../../../service/theoretical-calendar.service';
import {SelectItem} from 'primeng/api';
import {NgForm} from '@angular/forms';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {TrialConsentService} from '../../../services/trial-consent.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-consentment-form',
  templateUrl: './consentment-form.component.html',
  styleUrls: ['./consentment-form.component.css']
})
export class ConsentmentFormComponent extends FormComponent<OtherConsent> implements OnInit {

  isAmendmentEnabled = false;

  @ViewChild('amendmentForm') amendmentForm: NgForm;

  displayAddOtherConsentDates = true;
  theoreticalCalendarAmendmentList: SelectItem[] = [];
  amendmentListOfOtherConsent: OtherConsent[] = [];
  inclusionHhhId: number;
  trialHhhId: number;
  locale = FR;
  isItInput = new Array<boolean>();
  countOtherConsent: number;
  initialConsentId: number;
  allConsents: Array<SelectItem> = new Array<SelectItem>();

  constructor(
      private inclusionService: InclusionDetailService,
      private theoreticalCalendarService: TheoreticalCalendarService,
      private sharedService: SharedService,
      private trialConsentService: TrialConsentService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.getConsentDates();
    this.getTheoreticalCalendarAmendment();
    this.getAllConsents();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAmendmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
    });
  }

  getAllConsents() {
    this.trialConsentService.getLabelValuesByTrial(this.trialHhhId).subscribe(result => {
      this.allConsents = [];
      result.forEach(cons => {
        this.allConsents.push({
          label: cons.label,
          value: cons.value,
          disabled: false
        });
      });
    });
  }

  addAmendment() {
    this.amendmentListOfOtherConsent.splice(0, 0, new OtherConsent());
  }

  deleteAmendment(i) {
    if (this.amendmentListOfOtherConsent.length > 0) {
      this.amendmentListOfOtherConsent.splice(i, 1);
    }
  }

  getConsentDates() {
    this.inclusionService.getOtherConsents(this.inclusionHhhId).subscribe(res => {
      for (const amendment of res) {
        amendment.inputName = this.injectString(amendment.name, '');
        amendment.date = amendment.date ? new Date(amendment.date) : amendment.date;
        this.amendmentListOfOtherConsent.push(amendment);
      }
      if (this.amendmentListOfOtherConsent.length === 0) {
        this.amendmentListOfOtherConsent.push(new OtherConsent());
      }
    });
  }

  addAmendmentConfirm() {
    this.submitted = true;
    if (this.amendmentForm.invalid) {
      return;
    }
    this.amendmentListOfOtherConsent.forEach((otherConsent) => {
      otherConsent.name = this.injectString(otherConsent.inputName, otherConsent.name);
    });


    this.inclusionService.addAmendments(this.amendmentListOfOtherConsent, this.inclusionHhhId).subscribe(() => {
          this.countOtherConsent = this.amendmentListOfOtherConsent.length;
          this.sharedService.showSuccess();
          this.displayAddOtherConsentDates = false;
          this.callback(this.amendmentListOfOtherConsent[0]);
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        }
    );
  }

  getTheoreticalCalendarAmendment() {
    this.theoreticalCalendarService.getAmendmentByTrialHhhId(this.trialHhhId).subscribe(amendment => {
      if (amendment != null) {
        this.theoreticalCalendarAmendmentList.push({label: amendment, value: amendment});
        this.theoreticalCalendarAmendmentList.push({label: 'autre', value: 'other'});
      }
    });
  }

  isItOtherField(name: string, index: number) {
    if (!(name === 'other' || this.isItInput[index])) {
      return false;
    } else {
      this.amendmentListOfOtherConsent[index].name = '';
      this.isItInput[index] = true;
      return true;
    }
  }

  injectString(A: string, B: string) {
    return A ? A : B;
  }

  getAvailableConsentList(consentId?: number): Array<SelectItem> {
    return this.allConsents.filter(cons => {
      return (this.amendmentListOfOtherConsent.find(c => c.value === cons.value) === undefined
          && cons.value !== this.initialConsentId) || cons.value === consentId;
    });
  }

  existsAvailableConsents(): boolean {
    return this.allConsents.length > (this.amendmentListOfOtherConsent.length + 1);
  }

  areConsentsInvalid(): boolean {
    for (const otherConsent of this.amendmentListOfOtherConsent) {
      if (otherConsent.date == null || (otherConsent.value == null && otherConsent.name == null)) {
        return false;
      }
    }
    return true;
  }
}

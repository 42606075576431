import {VisitState} from './visit-state';
import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';

export class VisitForUpdate {
  public hhhId: number;
  public visitName: string;
  public visitType: string;
  public howManyDays: string;
  public expectedVisitDate: Date;
  public visitDate: Date;
  public comment: string;
  public visitState: VisitState;
  public imagingDespatchStateHhhId: number;
  public samplesDespatchStateHhhId: number;
  public transportRefundStateHhhId: number;
  public totalInvoiceable: number;
  public totalInvoiced: number;
  public totalPayed: number;
  public inTheoreticalCalendar: boolean;
  public visitIsAdditionalTest: boolean;
  public difference: number;

  init(obj: any): VisitForUpdate {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {DetailedTrialAct} from '../../../entity/trial-additionnal-costs/detailed-trial-act';
import {TableHeader} from '../../../../shared/entity/table-header';
import {SelectItem} from 'primeng/api';
import {isNull, isNullOrUndefined} from 'util';
import {InputType} from '../../../../input-type';
import {TheoreticalCalendar} from '../../../entity/theoretical-calendar/theoretical-calendar';
import {TheoreticalVisitAct} from '../../../entity/theoretical-calendar/theoretical-visit-act';
import {TheoreticalVisit} from '../../../entity/theoretical-calendar/theoretical-visit';
import {TheoreticalVisitInInclusion} from '../../../entity/theoretical-calendar/theoretical-visit-in-inclusion';
import {DeleteConfirmationComponent} from '../../../../modals/delete-confirmation/delete-confirmation.component';
import {TheoreticalVisitService} from '../../../service/theoretical-visit.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {Data} from '../../../../shared/entity/data';
import {TheoreticalVisitActService} from '../../../service/theoretical-visit-act.service';
import {DeleteMultipleComponent} from '../../../../modals/delete-multiple/delete-multiple.component';
import {TrialActService} from '../../../service/trial-act.service';
import {Arm} from '../../../entity/inclusion/arm';
import {ArmService} from '../../../services/arm.service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {VisitAdditionalCost} from '../../../entity/trial-additionnal-costs/visit-additional-cost';
import {TrialCounterpartService} from '../../../service/trial-counterpart.service';
import {TrialFixedCostService} from '../../../service/trial-fixed-cost.service';
import {TrialOperationalActService} from '../../../service/trial-operational-act.service';
import {UsersService} from '../../../../admin/services/users.service';
import {VisitAct} from '../../../entity/inclusion/visit-act';
import {TrialService} from '../../../services/trial.service';
import {TheoreticalVisitFormComponent} from '../theoretical-visit-form/theoretical-visit-form.component';
import {
  OperationalTrialActsComponent
} from '../../trial-additionnal-costs/operational-trial-acts/operational-trial-acts.component';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {TrialOperationalAct} from "../../../entity/trial-additionnal-costs/trial-operational-act";
import {LabelValue} from "../../../../shared/entity/label-value";
import {Util} from "../../../../helpers/util";

declare var $: any;

@Component({
  selector: 'ih-theoretical-calendar-acts',
  templateUrl: './theoretical-calendar-acts.component.html',
  styleUrls: ['./theoretical-calendar-acts.component.css'],
  changeDetection: ChangeDetectionStrategy.Default

})
export class TheoreticalCalendarActsComponent implements OnInit {

  visitReferenceDateEnabled = true;
  optionalEnabled = false;
  financeViewEnabled = false;
  amendmentsEnabled = true;
  enableRandomizationDateReference = false;
  designEnabled = true;
  operationalActEnabled = true;
  canMatchOAwithVisit = false;


  @ViewChild('deleteTheoreticalVisitPopup', {read: ViewContainerRef}) deletePopupContainer: ViewContainerRef;
  @ViewChild('addTheoreticalVisitDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;
  @Output() updateEvent = new EventEmitter<boolean>();
  @Input() acts: VisitAdditionalCost[];
  @Input() actDescriptionFilterList: SelectItem[];
  @Input() actCategoryFilterList: SelectItem[];
  @Input() additionalCostTypes: SelectItem[];
  @Input() trialHhhId: number;
  @Input() disabledArms: Array<number> = new Array<number>();
  @Input() isArcViewActive: boolean;
  arm: string;
  armOptions: LabelValue [];
  theoreticalVisitInInclusionDtoListByArm: TheoreticalVisitInInclusion[] = [];

  theoreticalCalendar: TheoreticalCalendar;
  inInclusionTypeColspan: number;
  actColumnListHeader: TableHeader[];
  visitTypes: any[];
  noYesOptions: SelectItem[];
  allFields: boolean;
  beforeInclusionAllFields: boolean;
  inInclusionAllFields: boolean;
  afterInclusionAllFields: boolean;
  shiftedUnits: SelectItem[] = Data.shiftedUnits;
  filterSelectedItemList: any[] = [];
  filterSelectedItem: any;
  allActs: VisitAdditionalCost[] = [];
  currentVisitsPage = 0;
  allVisits: TheoreticalVisit[] = [];
  theoreticalVisitBeforeInclusionList: TheoreticalVisit[] = [];
  theoreticalVisitAfterInclusionList: TheoreticalVisit[] = [];
  theoreticalVisitInInclusionDtoList: TheoreticalVisitInInclusion[] = [];
  disableNextVisitsButton = false;
  showBeforeInclusion = false;
  showInInclusion = false;
  showAfterInclusion = false;
  totalVisitPages = 0;
  useDropdownWhenSelectArms = true;
  readonly visitsPageSize = 7;

  constructor(private useService: UsersService,
              private theoreticalVisitService: TheoreticalVisitService,
              private theoreticalVisitActService: TheoreticalVisitActService,
              private fixedCostService: TrialFixedCostService,
              private counterPartService: TrialCounterpartService,
              private sharedService: SharedService,
              private trialActService: TrialActService,
              private armService: ArmService,
              public trialService: TrialService,
              private changeDetectorRef: ChangeDetectorRef,
              private trialOperationalActService: TrialOperationalActService,
              private canDeactivateService: CanDeactivateService,
              private cfr: ComponentFactoryResolver,
              private dynamicConfigService: DynamicConfigService,
              public trialPageLockService: TrialPageLockService) {
  }

  @Input('theoreticalCalendar')
  set setTheoreticalCalendar(thc: TheoreticalCalendar) {
    this.theoreticalCalendar = thc;
    this.setVisitTypes();
    this.setColumns(this.acts);
    if (thc.theoreticalVisitInInclusionDtoList) {
      this.theoreticalVisitInInclusionDtoListByArm = [];
      this.theoreticalVisitInInclusionDtoListByArm.push(thc.theoreticalVisitInInclusionDtoList[0]);
    }
    if(!Util.isNullOrUndefinedOrEmpty(this.arm)){
      this.theoreticalVisitInInclusionDtoListByArm = this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.filter(value => value.arm === this.arm);
    }

    this.setAllVisits();
    this.setArmOptions();
  }

  ngOnInit() {
    this.initProperties().then(() => {
      this.setVisitTypes();
      this.allFields = false;
      this.beforeInclusionAllFields = true;
      this.inInclusionAllFields = true;
      this.afterInclusionAllFields = true;
      this.setColumns(this.acts);
      for (const act of this.acts) {
        this.allActs.push(act);
      }

    });
  }

  initProperties(): Promise<any> {
    return new Promise((resolver) => {
      this.dynamicConfigService.initProperties().subscribe(() => {
        this.visitReferenceDateEnabled = this.dynamicConfigService.getProperty(PropertyEnum.visitReferenceEnabled);
        this.optionalEnabled = this.dynamicConfigService.getProperty(PropertyEnum.optionalEnabled);
        this.financeViewEnabled = this.dynamicConfigService.getProperty(PropertyEnum.financeViewEnabled);
        this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
        this.enableRandomizationDateReference = this.dynamicConfigService.getProperty(PropertyEnum.enableRandomizationDateReference);
        this.designEnabled = this.dynamicConfigService.getProperty(PropertyEnum.designEnabled);
        this.operationalActEnabled = this.dynamicConfigService.getProperty(PropertyEnum.operationalActEnabled);
        this.canMatchOAwithVisit = this.dynamicConfigService.getProperty(PropertyEnum.canMatchOAwithVisit);
        this.useDropdownWhenSelectArms = this.dynamicConfigService.getProperty(PropertyEnum.useDropdownWhenSelectArms);
        console.log("useDropdownWhenSelectArms = ", this.useDropdownWhenSelectArms)
        resolver();
      });
    });
  }

  displayVisitTrialActNumberFromData(data: VisitAdditionalCost, visitActs: TheoreticalVisitAct[]): string {
    let actsNumber = 0;
    let price;

    if (!this.isArcViewActive && data.fromAOP) {
      const va = visitActs.find(v => (v.trialActHhhId === data.hhhId && v.type === data.additionalCostType));
      return va ? (va.numberOfUnits === 0 ? '' : va.numberOfUnits + '') : '';
    }
    if (data.additionalCostType !== 'OPERATIONAL_ACT') {
      const visitAct = visitActs.find(item => (item.trialActHhhId === data.hhhId) && (item.type === data.additionalCostType)
        && !item.parentOperationalActHhhId);
      if (visitAct) {
        actsNumber = visitAct.numberOfUnits;
      }
      return (actsNumber === 0 ? '' : actsNumber + '');
    } else {
      actsNumber = this.displayVisitTrialOperationalCost(data.operationalSubActs, visitActs, data.hhhId);
      if (isNull(actsNumber) && data.operationalSubActs && visitActs.findIndex(item => (item.trialActHhhId === data.hhhId)
        && (item.type === data.additionalCostType)) !== -1) {
        actsNumber = 0;
      }
      price = Number(actsNumber).toFixed(2) + ' €';
      return actsNumber === null ? '' : price;
    }
  }

  displayVisitTrialOpActIsChecked(data: VisitAdditionalCost, visitActs: TheoreticalVisitAct[]): boolean {
    const visitAct = visitActs.find(item => (item.trialActHhhId === data.hhhId) && (item.type === data.additionalCostType));
    return !!visitAct;
  }

  setColumns(event) {
    this.actColumnListHeader = [];
    this.actColumnListHeader = [
      {
        field: 'rowExpand',
        header: '',
        width: '50px',
        cellAlign: 'center',
        inputType: InputType.dropDown,
        options: this.additionalCostTypes,
      },
      {
        field: 'additionalCostCategoryName',
        header: 'MODULE_REFERENCE_ACTS_FIELD_ACT_CATEGORY',
        width: '155px',
        filterable: true,
        optionFilterable: true,
        inputType: InputType.dropDown,
        options: this.actCategoryFilterList,
        sortable: true
      },
      {
        field: 'additionalCostName',
        header: 'MODULE_REFERENCE_ACTS_FIELD_DESCRIPTION',
        width: '225px',
        filterable: true,
        optionFilterable: true,
        inputType: InputType.dropDown,
        options: this.actDescriptionFilterList,
        sortable: true,
        editable: true,
        onChange: (data: VisitAdditionalCost) => {
          this.updateTrialAct(data);
        }
      }
    ];
    if (this.allFields) {
      this.actColumnListHeader.push({
          field: 'invoiceable',
          header: 'MODULE_REFERENCE_ACTS_FIELD_INVOICEABLE',
          width: '80px',
          filterable: true,
          inputType: InputType.checkbox,
          sortable: true,
          translated: true,
          editable: false,
          cellAlign: 'center',
          onChange: (data: VisitAdditionalCost) => {
            this.updateTrialAct(data);
          }
        },
        {
          field: 'pricePerUnit',
          header: 'MODULE_REFERENCE_ACTS_FIELD_PRICE_PER_UNIT',
          width: '80px',
          filterable: false,
          inputType: InputType.input,
          sortable: true,
          cssClass: () => {
            return 'pull-right';
          },
          displayContent: (data: VisitAdditionalCost) => {
            if (!this.useService.canAccessFieldByValue(data.additionalCostType)) {
              return '-';
            }
            let price = '';
            if (!isNullOrUndefined(data.pricePerUnit)) {
              price = Number(data.pricePerUnit).toFixed(2) + ' €';
            }
            return price;
          },
          cssClassForContent: true,
          hideField: (data: VisitAdditionalCost) => {
            return data.additionalCostType === 'OPERATIONAL_ACT';
          },
          editable: true,
          onChange: (data: VisitAdditionalCost) => {
            this.updateTrialAct(data);
          }
        });

      if (this.optionalEnabled) {
        this.actColumnListHeader.push({
          field: 'optional',
          header: 'MODULE_REFERENCE_ACTS_FIELD_OPTIONAL',
          width: '80px',
          filterable: true,
          inputType: InputType.checkbox,
          sortable: true,
          editable: false,
          cellAlign: 'center',
          onChange: (data: VisitAdditionalCost) => {
            this.updateTrialAct(data);
          }
        });
      }
    }
    this.actColumnListHeader.push({
      field: !this.allFields ? 'more' : 'less',
      header: '',
      width: '36px',
      iconButton: true,
      cellAlign: 'center',
      iconButtonCssClass: () => {
        return this.trialPageLockService.isLocked ? 'fa fa-times' : 'fa fa-trash-o';
      },
      iconButtonEvent: (data: VisitAdditionalCost) => {
        this.removeSelectedAct(data);
      },
      moreFields: !this.allFields,
      displayFields: () => {
        this.allFields = true;
        this.setColumns(event);
      },
      hideFields: () => {
        this.allFields = false;
        this.setColumns(event);
      },
      editable: false
    });
    this.actColumnListHeader.forEach(item => {
      this.filterSelectedItemList.push(this.filterSelectedItem);
    });
  }

  setVisitTypes() {
    this.inInclusionTypeColspan = 0;
    for (const theoreticalVisitInInclusionDto of this.theoreticalVisitInInclusionDtoList) {
      this.inInclusionTypeColspan = this.inInclusionTypeColspan +
        (theoreticalVisitInInclusionDto && theoreticalVisitInInclusionDto.theoreticalVisits && theoreticalVisitInInclusionDto.theoreticalVisits.length !== 0 ?
          theoreticalVisitInInclusionDto.theoreticalVisits.length : 1);
    }
    this.visitTypes = [
      {
        field: 'BEFORE_INCLUSION',
        header: 'MODULE_TRIALS_PATIENTS_CALENDARS_TITLE_BEFORE_INCLUSION',
        colspan: this.theoreticalVisitBeforeInclusionList && this.theoreticalVisitBeforeInclusionList.length !== 0 ?
          this.theoreticalVisitBeforeInclusionList.length : 1,
        rowspan: 2,
        displayFields: () => {
          this.beforeInclusionAllFields = true;
        },
        hideFields: () => {
          this.beforeInclusionAllFields = false;
        }
      },
      {
        field: 'IN_INCLUSION',
        header: 'MODULE_TRIALS_PATIENTS_CALENDARS_TITLE_IN_INCLUSION',
        colspan: this.inInclusionTypeColspan !== 0 ? this.inInclusionTypeColspan : 1,
        rowspan: this.theoreticalVisitInInclusionDtoList.length > 0 ? 1 : 2,
        displayFields: () => {
          this.inInclusionAllFields = true;
        },
        hideFields: () => {
          this.inInclusionAllFields = false;
        }
      },
      {
        field: 'AFTER_INCLUSION',
        header: 'MODULE_TRIALS_PATIENTS_CALENDARS_TITLE_AFTER_INCLUSION',
        colspan: this.theoreticalVisitAfterInclusionList && this.theoreticalVisitAfterInclusionList.length !== 0 ?
          this.theoreticalVisitAfterInclusionList.length : 1,
        rowspan: 2,
        displayFields: () => {
          this.afterInclusionAllFields = true;
        },
        hideFields: () => {
          this.afterInclusionAllFields = false;
        }
      }
    ];
  }

  addNewArm(inInclusionVisits: TheoreticalVisitInInclusion[]) {
    const newArm: Arm = new Arm();
    newArm.trialHhhId = this.trialHhhId;
    this.armService.addArm(newArm).subscribe((armHhhId) => {
      const theoreticalVisitInInclusion: TheoreticalVisitInInclusion = new TheoreticalVisitInInclusion();
      theoreticalVisitInInclusion.armHhhId = armHhhId;
      inInclusionVisits.push(theoreticalVisitInInclusion);
      this.inInclusionTypeColspan = this.inInclusionTypeColspan + 1;
      this.setVisitTypes();
      this.sharedService.showSuccess();
      this.setAllVisits();
    });
  }

  updateExistingArm(inInclusionVisit: TheoreticalVisitInInclusion) {
    this.canDeactivateService.canBeDeactivated = false;
    const arm: Arm = this.theoreticalCalendar.armsToEdit.find(item => item.hhhId === inInclusionVisit.armHhhId);
    if (!arm) {
      const newArm: Arm = new Arm();
      newArm.hhhId = inInclusionVisit.armHhhId;
      newArm.trialHhhId = this.trialHhhId;
      newArm.name = inInclusionVisit.arm;
      this.theoreticalCalendar.armsToEdit.push(newArm);
    } else {
      arm.name = inInclusionVisit.arm;
    }
  }

  removeArm(inInclusionVisit: TheoreticalVisitInInclusion) {
    const deleteConfirmationComponent = DeleteConfirmationComponent.displayDeleteConfirmationDialogue(this.deletePopupContainer, this.cfr);
    deleteConfirmationComponent.hhhId = inInclusionVisit.armHhhId;
    deleteConfirmationComponent.name = inInclusionVisit.arm ? inInclusionVisit.arm : '\"\"';
    deleteConfirmationComponent.trialHhhId = this.trialHhhId;
    deleteConfirmationComponent.popupMessage = 'MODULE_TRIALS_ARMS_DIALOG_WARNING_ARM_WILL_BE_DELETED';
    deleteConfirmationComponent.service = this.armService;
    deleteConfirmationComponent.dontDeleteWhenDeletable = true;
    deleteConfirmationComponent.onDelete(() => {
      this.canDeactivateService.canBeDeactivated = false;
      this.theoreticalCalendar.armsHhhIdsToRemove.push(inInclusionVisit.armHhhId);
      this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.splice(this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.indexOf(inInclusionVisit), 1);
      this.inInclusionTypeColspan = this.inInclusionTypeColspan - 1;
      this.setVisitTypes();
    });
  }

  addNewVisit(visits: TheoreticalVisit[], type: string, armHhhId?: number, armName?: string) {

    const addDialogFactory = this.cfr.resolveComponentFactory(TheoreticalVisitFormComponent);
    const addDialogComponentRef = this.dialogContainer.createComponent(addDialogFactory);
    const theoreticalVisitFormComponent = addDialogComponentRef.instance;
    theoreticalVisitFormComponent.displayTheoreticalVisitDialog = true;
    theoreticalVisitFormComponent.theoreticalVisitAdd.armHhhId = armHhhId;
    theoreticalVisitFormComponent.theoreticalVisitAdd.type = type;
    theoreticalVisitFormComponent.theoreticalVisitAdd.periodType = '-1';
    theoreticalVisitFormComponent.theoreticalVisitAdd.armHhhId = armHhhId;
    theoreticalVisitFormComponent.theoreticalVisitAdd.theoreticalCalendarHhhId = this.theoreticalCalendar.hhhId;
    theoreticalVisitFormComponent.theoreticalCalendar = this.theoreticalCalendar;
    theoreticalVisitFormComponent.targetTrialProtocol = this.theoreticalCalendar.trialProtocolHhhId;
    theoreticalVisitFormComponent.prepareDateReferences();
    theoreticalVisitFormComponent.addSuccess.subscribe((theoreticalVisit) => {
      this.canDeactivateService.canBeDeactivated = false;
      const theoreticalVisitToAdd = new TheoreticalVisit().buildFrom(theoreticalVisit);
      if (type === 'IN_INCLUSION') {
        let targetArm = this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.find(a => a.armHhhId === armHhhId);
        if (!targetArm) {
          targetArm = this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.find(a => a.arm === armName);
        }
        visits = targetArm.theoreticalVisits;
      }
      visits.push(theoreticalVisitToAdd);
      visits.sort((theoreticalVisit1, theoreticalVisit2) => {
        return +theoreticalVisit1.displayHowManyDays.substring(1) > +theoreticalVisit2.displayHowManyDays.substring(1) ? 1 : -1;
      });
      theoreticalVisit.trialActsHhhIds = [];
      theoreticalVisit.trialActsToEdit = [];
      this.theoreticalCalendar.theoreticalVisitDtoList.push(theoreticalVisitToAdd);
      this.setAllVisits();
      this.forceVisitShow(theoreticalVisitToAdd);
    });
  }

  updateExistingVisit(visit: TheoreticalVisit) {
    if (visit.hhhId) {
      this.canDeactivateService.canBeDeactivated = false;
      const lastModifiedVisit: TheoreticalVisit = this.theoreticalCalendar.theoreticalVisitToEditDtoList.find(item => item.hhhId === visit.hhhId);
      if (!lastModifiedVisit) {
        if (isNullOrUndefined(visit.visitActsToAdd)) {
          visit.visitActsToAdd = [];
        }
        if (isNullOrUndefined(visit.visitActsToRemove)) {
          visit.visitActsToRemove = [];
        }
        this.theoreticalCalendar.theoreticalVisitToEditDtoList.push(visit);
      }
    }
  }

  removeVisit(visits: TheoreticalVisit[], visit: TheoreticalVisit, inInclusionType?: boolean) {
    if (visit.hhhId) {
      const deleteConfirmationComponent = DeleteConfirmationComponent.displayDeleteConfirmationDialogue(this.deletePopupContainer, this.cfr);
      deleteConfirmationComponent.hhhId = visit.hhhId;
      deleteConfirmationComponent.popupMessage =
        'MODULE_TRIALS_THEORETICAL_CALENDARS_THEORETICAL_VISITS_DIALOG_WARNING_THEORETICAL_VISIT_WILL_BE_DELETED';
      deleteConfirmationComponent.service = this.theoreticalVisitService;
      deleteConfirmationComponent.name = visit.name;
      deleteConfirmationComponent.dontDeleteWhenDeletable = true;
      deleteConfirmationComponent.onDelete(() => {
        this.canDeactivateService.canBeDeactivated = false;
        if (this.theoreticalCalendar.theoreticalVisitToDeleteDtoList.indexOf(visit.hhhId) < 0) {
          this.theoreticalCalendar.theoreticalVisitToDeleteDtoList.push(visit.hhhId);
        }
        visit.modificationType = 'delete';
        this.setAllVisits();
      });
    } else {
      this.canDeactivateService.canBeDeactivated = false;
      this.theoreticalCalendar.theoreticalVisitDtoList.splice(this.theoreticalCalendar.theoreticalVisitDtoList.indexOf(visit), 1);
      visits.splice(visits.indexOf(visit), 1);
      this.setAllVisits();
      this.sharedService.showSuccess();
    }
  }

  addActToVisit(unitNumber, visit: TheoreticalVisit, additionalCost: VisitAdditionalCost, operationalAct?: VisitAdditionalCost) {
    if (this.financeViewEnabled && additionalCost.trialOperationalActHhhId && operationalAct === undefined) {
      operationalAct = new VisitAdditionalCost();
      operationalAct.hhhId = additionalCost.trialOperationalActHhhId;
      operationalAct.additionalCostType = 'OPERATIONAL_ACT';
    }
    if (!visit.visitActs) {
      visit.visitActs = [];
    }
    if (!visit.visitActsToAdd) {
      visit.visitActsToAdd = [];
    }
    if (operationalAct) {
      const operationalVisitAct = visit.visitActs.find(item => item.trialActHhhId === operationalAct.hhhId);
      let visitAct: TheoreticalVisitAct = visit.visitActs.find(item => (item.trialActHhhId === additionalCost.hhhId) &&
        (item.type === additionalCost.additionalCostType) && item.parentOperationalActHhhId === operationalAct.hhhId);
      if (operationalVisitAct && operationalVisitAct.hhhId) {
        if (visitAct && visitAct.hhhId) {
          unitNumber === 1 ? visitAct.numberOfUnits = Number((visitAct.numberOfUnits + 1).toFixed(2)) : visitAct.numberOfUnits = Number(unitNumber);
          if (this.theoreticalCalendar.theoreticalVisitActsToEdit.indexOf(visitAct) === -1) {
            this.theoreticalCalendar.theoreticalVisitActsToEdit.push(visitAct);
          }
          const cost: number = this.displayVisitTrialOperationalCost(operationalAct.operationalSubActs, visit.visitActs, operationalAct.hhhId);
          operationalVisitAct.pricePerUnit = !isNull(cost) ? cost : 0;
          if (this.theoreticalCalendar.theoreticalVisitActsToEdit.indexOf(operationalVisitAct) === -1) {
            this.theoreticalCalendar.theoreticalVisitActsToEdit.push(operationalVisitAct);
          }
        } else if (visitAct) {
          unitNumber === 1 ? visitAct.numberOfUnits = Number((visitAct.numberOfUnits + 1).toFixed(2)) : visitAct.numberOfUnits = Number(unitNumber);
          const cost: number = this.displayVisitTrialOperationalCost(operationalAct.operationalSubActs, visit.visitActs, operationalAct.hhhId);
          operationalVisitAct.pricePerUnit = !isNull(cost) ? cost : 0;
        } else {
          visitAct = TheoreticalVisitAct.generateTheoreticalVisitActForSubAct(additionalCost, unitNumber);
          visitAct.parentOperationalActHhhId = operationalAct.hhhId;
          visit.visitActs.push(visitAct);
          if (!operationalVisitAct.details) {
            operationalVisitAct.details = [];
          }
          operationalVisitAct.type = operationalAct.additionalCostType;
          operationalVisitAct.details.push(visitAct);
          const cost: number = this.displayVisitTrialOperationalCost(operationalAct.operationalSubActs, visit.visitActs, operationalAct.hhhId);
          operationalVisitAct.pricePerUnit = !isNull(cost) ? cost : 0;
          if (isNullOrUndefined(visit.visitActsToAdd.find(item => item.trialActHhhId === operationalAct.hhhId))) {
            visit.visitActsToAdd.push(operationalVisitAct);
          }
          this.calculateHowManyDays(visit);
        }
      } else if (operationalVisitAct) {
        if (visitAct) {
          unitNumber === 1 ? visitAct.numberOfUnits = Number((visitAct.numberOfUnits + 1).toFixed(2)) : visitAct.numberOfUnits = Number(unitNumber);
        } else {
          visitAct = TheoreticalVisitAct.generateTheoreticalVisitActForSubAct(additionalCost, unitNumber);
          visitAct.parentOperationalActHhhId = operationalAct.hhhId;
          visit.visitActs.push(visitAct);
          operationalVisitAct.details.push(visitAct);
        }
        let cost = 0;
        for (const visitCost of operationalVisitAct.details) {
          cost += visitCost.pricePerUnit * this.displayVisitTrialActNumber(visitCost.trialActHhhId, visit.visitActs, operationalAct.hhhId);
        }
        operationalVisitAct.pricePerUnit = cost;
      } else {
        visitAct = TheoreticalVisitAct.generateTheoreticalVisitActForSubAct(additionalCost, unitNumber);
        visitAct.parentOperationalActHhhId = operationalAct.hhhId;
        visit.visitActs.push(visitAct);
        const oppVisitAct: TheoreticalVisitAct = TheoreticalVisitAct.generateTheoreticalVisitActForOperationalAct(operationalAct, unitNumber);
        oppVisitAct.details.push(visitAct);
        let cost = 0;
        for (const visitCost of oppVisitAct.details) {
          cost += visitCost.pricePerUnit * this.displayVisitTrialActNumber(visitCost.trialActHhhId, visit.visitActs, operationalAct.hhhId);
        }
        oppVisitAct.pricePerUnit = cost;
        visit.visitActs.push(oppVisitAct);
        visit.visitActsToAdd.push(oppVisitAct);
        this.calculateHowManyDays(visit);
      }
    } else {
      let visitAct;
      if (!this.isArcViewActive && additionalCost.fromAOP) {
        visitAct = visit.visitActs.find(item => (item.trialActHhhId === additionalCost.hhhId) && (item.type === additionalCost.additionalCostType));
      } else {
        visitAct = visit.visitActs.find(item => (item.trialActHhhId === additionalCost.hhhId) && (item.type === additionalCost.additionalCostType)
          && isNullOrUndefined(item.parentOperationalActHhhId));
      }
      if (visitAct && visitAct.hhhId) {
        unitNumber === 1 ? visitAct.numberOfUnits = Number((visitAct.numberOfUnits + 1).toFixed(2)) : visitAct.numberOfUnits = Number(unitNumber);
        if (this.theoreticalCalendar.theoreticalVisitActsToEdit.indexOf(visitAct) === -1) {
          this.theoreticalCalendar.theoreticalVisitActsToEdit.push(visitAct);
        }
      } else if (visitAct) {
        unitNumber === 1 ? visitAct.numberOfUnits = Number((visitAct.numberOfUnits + 1).toFixed(2)) : visitAct.numberOfUnits = Number(unitNumber);
      } else {
        const newVisitAct: TheoreticalVisitAct = TheoreticalVisitAct.generateTheoreticalVisitActFromAdditionalCost(additionalCost);
        if (unitNumber != null) {
          newVisitAct.numberOfUnits = Number(unitNumber);
        }
        visit.visitActs.push(newVisitAct);
        visit.visitActsToAdd.push(newVisitAct);
        this.calculateHowManyDays(visit);
      }
    }
    this.canDeactivateService.canBeDeactivated = false;
  }

  removeActFromVisit(visit: TheoreticalVisit, trialAct: VisitAdditionalCost, operationalAct?: VisitAdditionalCost) {
    let notDeletedAct = true;
    if (!this.theoreticalCalendar.theoreticalVisitActsToEdit) {
      this.theoreticalCalendar.theoreticalVisitActsToEdit = [];
    }
    let visitActToAdd;
    if (visit.visitActsToAdd) {
      if (operationalAct) {
        const existingOperationalAct = visit.visitActsToAdd.find(item => (item.trialActHhhId === operationalAct.hhhId)
          && (item.type === operationalAct.additionalCostType) && item.parentOperationalActHhhId === operationalAct.hhhId);
        if (existingOperationalAct) {
          visitActToAdd = existingOperationalAct.details.find(item => item.trialActHhhId === trialAct.hhhId);
        }
      } else {
        visitActToAdd = visit.visitActsToAdd.find(item => (item.trialActHhhId === trialAct.hhhId) && (item.type === trialAct.additionalCostType)
          && item.parentOperationalActHhhId === undefined);
      }
    }
    if (!isNullOrUndefined(visitActToAdd)) {
      notDeletedAct = false;
      this.canDeactivateService.canBeDeactivated = false;
      if (visitActToAdd.numberOfUnits > 1) {
        visitActToAdd.numberOfUnits = Number((visitActToAdd.numberOfUnits - 1).toFixed(2));
      } else {
        visit.visitActs.splice(visit.visitActs.indexOf(visitActToAdd), 1);
        if (operationalAct) {
          const existingOperationalAct = visit.visitActsToAdd.find(item => (item.trialActHhhId === operationalAct.hhhId) && (item.type === operationalAct.additionalCostType));
          existingOperationalAct.details.splice(existingOperationalAct.details.indexOf(visitActToAdd), 1);
          if ((isNullOrUndefined(existingOperationalAct.details) || existingOperationalAct.details.length === 0)) {
            if (existingOperationalAct.hhhId && this.theoreticalCalendar.theoreticalVisitActsToEdit.indexOf(existingOperationalAct) === -1) {
              const cost: number = this.displayVisitTrialOperationalCost(operationalAct.operationalSubActs, visit.visitActs);
              existingOperationalAct.pricePerUnit = !isNull(cost) ? cost : 0;
              this.theoreticalCalendar.theoreticalVisitActsToEdit.push(existingOperationalAct);
            }
            const index = visit.visitActsToAdd.indexOf(existingOperationalAct);
            visit.visitActsToAdd.splice(index, 1);
            visit.visitActs.splice(visit.visitActs.indexOf(existingOperationalAct), 1);
          }
        } else {
          visit.visitActsToAdd.splice(visit.visitActsToAdd.indexOf(visitActToAdd), 1);
        }
      }
      if (operationalAct && visit.visitActsToAdd && visit.visitActsToAdd.length !== 0) {
        const existingOperationalAct = visit.visitActsToAdd.find(item => (item.trialActHhhId === operationalAct.hhhId) && (item.type === operationalAct.additionalCostType));
        if (existingOperationalAct) {
          const cost: number = this.displayVisitTrialOperationalCost(operationalAct.operationalSubActs, visit.visitActs);
          existingOperationalAct.pricePerUnit = !isNull(cost) ? cost : 0;
        }
      }
    } else {
      const visitAct: TheoreticalVisitAct = visit.visitActs.find(item =>
        (item.trialActHhhId === trialAct.hhhId)
        && (item.type === trialAct.additionalCostType)
        && ((operationalAct === undefined && item.parentOperationalActHhhId == null)
          || (operationalAct && item.parentOperationalActHhhId === operationalAct.hhhId)
          || (operationalAct === undefined && trialAct.fromAOP)));
      if (visitAct) {
        if (visitAct.numberOfUnits > 1) {
          notDeletedAct = false;
          visitAct.numberOfUnits = Number((visitAct.numberOfUnits - 1).toFixed(2));
          if (this.theoreticalCalendar.theoreticalVisitActsToEdit.indexOf(visitAct) === -1) {
            this.theoreticalCalendar.theoreticalVisitActsToEdit.push(visitAct);
          }
          if (operationalAct) {
            const existingOperationalAct = visit.visitActs.find(item => (item.trialActHhhId === operationalAct.hhhId) && (item.type === operationalAct.additionalCostType));
            const cost: number = this.displayVisitTrialOperationalCost(operationalAct.operationalSubActs, visit.visitActs, operationalAct.hhhId);
            existingOperationalAct.pricePerUnit = !isNull(cost) ? cost : 0;
            if (this.theoreticalCalendar.theoreticalVisitActsToEdit.indexOf(existingOperationalAct) === -1) {
              this.theoreticalCalendar.theoreticalVisitActsToEdit.push(existingOperationalAct);
            }
          }
        } else {
          if (this.theoreticalCalendar.theoreticalVisitActsToEdit.indexOf(visitAct) !== -1) {
            this.theoreticalCalendar.theoreticalVisitActsToEdit.splice(this.theoreticalCalendar.theoreticalVisitActsToEdit.indexOf(visitAct), 1);
            visit.visitActsToAdd.splice(visit.visitActsToAdd.indexOf(visitAct), 1);
          }
        }
        }
    }
    if (visit.hhhId && notDeletedAct && visit.visitActs.find(item => (item.trialActHhhId === trialAct.hhhId) && (item.type === trialAct.additionalCostType)) != null) {
      this.displayActDeleteDialogue(visit, trialAct, operationalAct);
    }
  }

  displayActDeleteDialogue(visit: TheoreticalVisit, trialAct: VisitAdditionalCost, operationalAct?: VisitAdditionalCost) {
    const visitAct: TheoreticalVisitAct = visit.visitActs.find(item =>
      (item.trialActHhhId === trialAct.hhhId)
      && (item.type === trialAct.additionalCostType)
      && ((operationalAct === undefined && item.parentOperationalActHhhId == null)
        || (operationalAct && item.parentOperationalActHhhId === operationalAct.hhhId)
        || (operationalAct === undefined && trialAct.fromAOP)));
    const deletePopupFactory = this.cfr.resolveComponentFactory(DeleteConfirmationComponent);
    const deletePopupComponentRef = this.deletePopupContainer.createComponent(deletePopupFactory);
    const deleteConfirmationComponent = deletePopupComponentRef.instance;
    deleteConfirmationComponent.hhhId = visitAct.hhhId;
    deleteConfirmationComponent.popupMessage = 'MODULE_TRIALS_THEORETICAL_ACTS_DIALOG_WARNING_ACT_WILL_BE_DELETED';
    deleteConfirmationComponent.service = this.theoreticalVisitActService;
    deleteConfirmationComponent.name = visitAct.description;
    deleteConfirmationComponent.dontDeleteWhenDeletable = true;
    visit.visitActs.splice(visit.visitActs.indexOf(visitAct), 1);
    deleteConfirmationComponent.onCancel(() => {
      visit.visitActs.push(visitAct);
    });
    deleteConfirmationComponent.onDelete(() => {
      this.canDeactivateService.canBeDeactivated = false;
      if (visitAct.hhhId) {
        this.theoreticalCalendar.theoreticalVisitActsToDeleteHhhIds.push(visitAct.hhhId);
      }
      if (operationalAct) {
        const existingOperationalAct = visit.visitActs.find(item => (item.trialActHhhId === operationalAct.hhhId) && (item.type === operationalAct.additionalCostType));
        if (existingOperationalAct.pricePerUnit !== 0) {
          existingOperationalAct.pricePerUnit -= visitAct.pricePerUnit;
        }
        const index = existingOperationalAct.details.indexOf(visitAct);
        existingOperationalAct.details.splice(index, 1);
        if (this.theoreticalCalendar.theoreticalVisitActsToEdit.indexOf(existingOperationalAct) === -1) {
          this.theoreticalCalendar.theoreticalVisitActsToEdit.push(existingOperationalAct);
        }
      }
    });
  }

  updateTrialAct(trialAct: VisitAdditionalCost) {
    if (!this.theoreticalCalendar.trialActsToEdit) {
      this.theoreticalCalendar.trialActsToEdit = [];
    }
    const lastModifiedTrialAct: DetailedTrialAct = this.theoreticalCalendar.trialActsToEdit.find(item => item.hhhId === trialAct.hhhId);
    if (!lastModifiedTrialAct) {
      this.canDeactivateService.canBeDeactivated = false;
      this.theoreticalCalendar.trialActsToEdit.push(trialAct);
    }
  }

  removeSelectedAct(data: VisitAdditionalCost) {
    const actsToRemove: VisitAdditionalCost[] = [];
    actsToRemove.push(data);
    this.displayDeleteMultiple(actsToRemove);
  }

  displayDeleteMultiple(actsToRemove: VisitAdditionalCost[]) {
    if (this.isAdditionalCostUsed(actsToRemove[0], false)) {
      this.sharedService.showFailure('ACT_RECENTLY_ADDED');
      return;
    }

    const deleteMultipleComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    if (actsToRemove[0].additionalCostType === 'ACT') {
      deleteMultipleComponent.service = this.trialActService;
    } else if (actsToRemove[0].additionalCostType === 'FIXED_COST') {
      deleteMultipleComponent.service = this.fixedCostService;
    } else if (actsToRemove[0].additionalCostType === 'COUNTER_PART') {
      deleteMultipleComponent.service = this.counterPartService;
    } else if (actsToRemove[0].additionalCostType === 'OPERATIONAL_ACT' || actsToRemove[0].fromAOP) {
      deleteMultipleComponent.service = this.trialOperationalActService;
    }
    deleteMultipleComponent.selectedList = actsToRemove;
    deleteMultipleComponent.name = actsToRemove[0].additionalCostName;
    deleteMultipleComponent.dontDeleteWhenDeletable = true;
    deleteMultipleComponent.navigationRoute = '/trial-details/theoretical-calendar';
    deleteMultipleComponent.optionalNavigationRoute = 'trial-details/inclusion-details/calendar';
    deleteMultipleComponent.popupMessage = 'MODULE_TRIALS_ACTS_DIALOG_WARNING_ACT_WILL_BE_DELETED';
    deleteMultipleComponent.hhhId = this.trialHhhId;
    deleteMultipleComponent.onDelete((deletedItem: number[]) => {
      const aopIndex = this.acts.findIndex((act, i: number) => act.hhhId === deletedItem[0] && this.acts[i].fromAOP);
      if (aopIndex !== -1) {
        this.processTheSubActDelete(this.acts[aopIndex]);
        this.sharedService.showSuccess();
        return;
      }
      if (deletedItem && deletedItem.length !== 0) {
        this.canDeactivateService.canBeDeactivated = false;
        if (!this.theoreticalCalendar.trialActsToRemoveHhhIds) {
          this.theoreticalCalendar.trialActsToRemoveHhhIds = [];
        }
        this.theoreticalCalendar.trialActsToRemoveHhhIds.push(actsToRemove[0].hhhId + '-' + actsToRemove[0].additionalCostType);
        this.allActs.splice(this.allActs.indexOf(actsToRemove[0]), 1);
        this.acts.splice(this.acts.indexOf(actsToRemove[0]), 1);
        const item = this.actDescriptionFilterList.find(i => i.value === actsToRemove[0].additionalCostName);
        if (!isNullOrUndefined(item)) {
          this.actDescriptionFilterList.splice(this.actDescriptionFilterList.indexOf(item), 1);
        }
        this.changeDetectorRef.detectChanges();
        this.sharedService.showSuccess();
      }
    });
  }

  isAdditionalCostUsed(cost: VisitAdditionalCost, operational: boolean): boolean {
    for (const theoreticalVisitActsToEditElement of this.theoreticalCalendar.theoreticalVisitToEditDtoList) {
      for (const visitActsToAddElement of theoreticalVisitActsToEditElement.visitActsToAdd) {
        if (!operational && cost.additionalCostType === visitActsToAddElement.type && visitActsToAddElement.trialActHhhId === cost.hhhId) {
          return true;
        } else if (operational && visitActsToAddElement.details) {
          for (const detail of visitActsToAddElement.details) {
            if (detail.trialActHhhId === cost.hhhId) {
              return true;
            }
          }
        }
      }
    }
    for (const theoreticalVisitActsToAddElement of this.theoreticalCalendar.theoreticalVisitDtoList) {
      for (const visitActsToAddElement of theoreticalVisitActsToAddElement.visitActsToAdd) {
        if (!operational && cost.additionalCostType === visitActsToAddElement.type && visitActsToAddElement.trialActHhhId === cost.hhhId) {
          return true;
        } else if (operational && visitActsToAddElement.details) {
          for (const detail of visitActsToAddElement.details) {
            if (detail.trialActHhhId === cost.hhhId) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  isNotValidPeriod(visit: TheoreticalVisit): boolean {
    const invalidBeforePeriod = visit.period > 0 && visit.type === 'BEFORE_INCLUSION';
    const invalidAfterPeriod = visit.period < 0 &&
      (visit.type === 'IN_INCLUSION' || visit.type === 'AFTER_INCLUSION');
    return invalidBeforePeriod || invalidAfterPeriod;
  }

  calculateHowManyDays(visit: TheoreticalVisit) {
    const theoreticalVisit: TheoreticalVisit = new TheoreticalVisit();
    theoreticalVisit.periodType = visit.periodType !== '-1' ? visit.periodType.toString() : '0';
    theoreticalVisit.period = visit.period;
    theoreticalVisit.type = visit.type;
    visit.displayHowManyDays = theoreticalVisit.getHowManyDays();
    this.updateExistingVisit(visit);
  }

  displayVisitTrialActNumber(hhhId: number, visitActs: TheoreticalVisitAct[], trialOperationalActHhhId ?: number): number {
    let actsNumber = 0;
    const visitAct = visitActs.find(item => item.trialActHhhId === hhhId && item.parentOperationalActHhhId === trialOperationalActHhhId);
    if (visitAct) {
      actsNumber = visitAct.numberOfUnits;
    }
    return actsNumber === 0 ? null : actsNumber;
  }

  displayVisitTrialOperationalCost(operationalSubActs: VisitAdditionalCost[], visitActs: TheoreticalVisitAct[], trialOperationalActHhhId ?: number): number {
    let cost = 0;
    if (operationalSubActs) {
      for (const visitCost of operationalSubActs) {
        if (trialOperationalActHhhId) {
          cost += visitCost.pricePerUnit * this.displayVisitTrialActNumber(visitCost.hhhId, visitActs, trialOperationalActHhhId);
        }
      }
    }
    return cost === 0 ? null : cost;
  }

  arrayOne(n: number): any[] {
    return Array(n);
  }

  onRowExpand(operationalAct) {
    if (isNullOrUndefined(operationalAct.data.operationalSubActs)) {
      this.trialOperationalActService.getVisitAdditionalCostList(operationalAct.data.hhhId).subscribe(
        res => {
          operationalAct.data.operationalSubActs = res;
        });
    }
  }

  removeActRelatedToOperationalAct(data: VisitAdditionalCost) {
    if (this.isAdditionalCostUsed(data, true)) {
      this.sharedService.showFailure('ACT_RECENTLY_ADDED');
      return;
    }
    const deleteConfirmationComponent = DeleteConfirmationComponent.displayDeleteConfirmationDialogue(this.deletePopupContainer, this.cfr);
    deleteConfirmationComponent.hhhId = data.hhhId;
    deleteConfirmationComponent.name = data.additionalCostCategoryName;
    deleteConfirmationComponent.popupMessage = 'MODULE_OPERATIONAL_ACTS_DIALOG_WARNING_OPERATIONAL_SUB_ACT_LIAISON_WILL_BE_DELETED';
    deleteConfirmationComponent.service = this.trialActService;
    deleteConfirmationComponent.dontDeleteWhenDeletable = true;
    deleteConfirmationComponent.onDelete(() => {
      this.processTheSubActDelete(data);
    });
  }

  isPriceAccessed(visitAct: VisitAct) {
    return this.useService.canAccessFieldByValue(visitAct.additionalCostType);
  }

  getTitle(rowData: any): string {
    return rowData.additionalCostType + ' | ' + rowData.additionalCostCategoryName + ' | ' + rowData.additionalCostName;
  }

  prepareDateReferences(theoreticalVisit: TheoreticalVisit): SelectItem[] {
    let dateReferences: SelectItem[] = [];
    dateReferences = [
      {
        label: 'signature de consentement de pré-screening',
        value: 'PRE_SCREENING_CONSENT_SIGNATURE'
      },
      {
        label: 'signature de consentement de screening',
        value: 'INITIAL_CONSENT_SIGNATURE'
      },
      {
        label: 'Date Début de traitement',
        value: 'TREATMENT_START_DATE'
      },
      {
        label: 'Date fin de traitement',
        value: 'TREATMENT_END_DATE'
      },
      {
        label: 'Date fin de l\'étude',
        value: 'TRIAL_END_DATE'
      }
    ];
    if (!this.amendmentsEnabled) {
      dateReferences.push({
        label: 'Date d\'inclusion',
        value: 'INCLUSION_DATE'
      });
    }
    if (this.enableRandomizationDateReference) {
      dateReferences.push({
        label: 'Date de randomisation',
        value: 'RANDOMIZATION_DATE'
      });
    }
    if (theoreticalVisit.type === 'BEFORE_INCLUSION') {
      for (const th of this.theoreticalCalendar.theoreticalVisitBeforeInclusionList) {
        dateReferences.push({
          label: th.name,
          value: th
        });
      }
      for (const theoreticalVisitInInclusion of this.theoreticalCalendar.theoreticalVisitInInclusionDtoList) {
        for (const th of theoreticalVisitInInclusion.theoreticalVisits) {
          dateReferences.push({
            label: th.name,
            value: th
          });
        }
      }
    } else if (theoreticalVisit.type === 'IN_INCLUSION') {
      if (!this.amendmentsEnabled) {
        dateReferences.push({
          label: 'Date d\'inclusion',
          value: 'INCLUSION_DATE'
        });
      }
      for (const theoreticalVisitInInclusion of this.theoreticalCalendar.theoreticalVisitInInclusionDtoList) {
        if (theoreticalVisitInInclusion.armHhhId === theoreticalVisit.armHhhId) {
          for (const th of theoreticalVisitInInclusion.theoreticalVisits) {
            dateReferences.push({
              label: th.name,
              value: th
            });
          }
          break;
        }
      }
    } else {
      if (!this.amendmentsEnabled) {
        dateReferences.push({
          label: 'Date du fin de suivi',
          value: 'FOLLOWUP_END_DATE'
        });
      }
      for (const theoreticalVisitInInclusion of this.theoreticalCalendar.theoreticalVisitInInclusionDtoList) {
        for (const th of theoreticalVisitInInclusion.theoreticalVisits) {
          dateReferences.push({
            label: th.name,
            value: th
          });
        }
      }
    }
    this.updateExistingReference(theoreticalVisit, dateReferences);
    return dateReferences;
  }

  updateExpectedDateReference(theoreticalVisit: TheoreticalVisit) {
    if (typeof theoreticalVisit.expectedDateRelativeTo === 'string') {
      theoreticalVisit.expectedDateReference = theoreticalVisit.expectedDateRelativeTo;
    } else {
      theoreticalVisit.expectedDateReference = 'OTHER_VISIT';
      theoreticalVisit.dateReferenceVisit = theoreticalVisit.expectedDateRelativeTo;
    }
    this.theoreticalCalendar.theoreticalVisitToEditDtoList.push(theoreticalVisit);
  }

  doFilterActs() {
    const additionalCostCategoryNameIndex = this.actColumnListHeader.findIndex(val => val.field === 'additionalCostCategoryName');
    const additionalCostNameIndex = this.actColumnListHeader.findIndex(val => val.field === 'additionalCostName');
    const pricePerUnitIndex = this.actColumnListHeader.findIndex(val => val.field === 'pricePerUnit');

    const searchedAdditionalCostCategoryName = this.filterSelectedItemList[additionalCostCategoryNameIndex];
    const searchedAdditionalCostName = this.filterSelectedItemList[additionalCostNameIndex];
    const searchedPricePerUnit = pricePerUnitIndex >= 0 ? this.filterSelectedItemList[pricePerUnitIndex] : null;
    let filteredActs: VisitAdditionalCost[] = this.allActs;
    if (searchedAdditionalCostCategoryName && searchedAdditionalCostCategoryName.trim().length > 0) {
      filteredActs = filteredActs.filter(act => act.additionalCostCategoryName === searchedAdditionalCostCategoryName);
    }
    if (searchedAdditionalCostName && searchedAdditionalCostName.trim().length > 0) {
      filteredActs = filteredActs.filter(act => act.additionalCostName === searchedAdditionalCostName);
    }
    if (searchedPricePerUnit && searchedPricePerUnit.trim().length > 0) {
      filteredActs = filteredActs.filter(act => act.pricePerUnit === Number(searchedPricePerUnit));
    }
    this.acts = filteredActs;
  }

  refreshVisitsPage() {
    this.theoreticalVisitBeforeInclusionList = [];
    this.theoreticalVisitAfterInclusionList = [];
    this.theoreticalVisitInInclusionDtoList = [];
    this.showBeforeInclusion = false;
    this.showInInclusion = false;
    this.showAfterInclusion = false;
    const beginIndex = (this.visitsPageSize * (this.currentVisitsPage + 1)) - this.visitsPageSize;
    const lastIndex = this.visitsPageSize * (this.currentVisitsPage + 1);
    this.disableNextVisitsButton = lastIndex >= this.allVisits.length;
    for (let i = Math.max(beginIndex, 0); i < Math.min(lastIndex, this.allVisits.length); i++) {
      this.addVisitsToBeShown(this.allVisits[i]);
    }
    this.setVisitTypes();
  }

  goToNextVisitsPage() {
    this.currentVisitsPage++;
    this.refreshVisitsPage();
    this.refreshShrunkVisitTypes();

  }

  goToPreviousVisitsPage() {
    this.currentVisitsPage--;
    this.refreshVisitsPage();
    this.refreshShrunkVisitTypes();
  }

  shouldShowVisitType(field: string) {
    return field === 'BEFORE_INCLUSION' && this.showBeforeInclusion || field === 'IN_INCLUSION' && this.showInInclusion
      || field === 'AFTER_INCLUSION' && this.showAfterInclusion;
  }

  forceVisitShow(th: TheoreticalVisit) {
    if (th.type === 'BEFORE_INCLUSION') {
      if (this.theoreticalVisitBeforeInclusionList.indexOf(th) >= 0) {
        return;
      }
      if (this.theoreticalVisitBeforeInclusionList.length > 0) {
        this.theoreticalVisitBeforeInclusionList.splice(this.theoreticalVisitBeforeInclusionList.length - 1, 1);
      }
      this.theoreticalVisitBeforeInclusionList.push(th);
    } else if (th.type === 'IN_INCLUSION') {
      const arm = this.theoreticalVisitInInclusionDtoList.find(a => a.armHhhId === th.armHhhId);
      if (arm.theoreticalVisits.indexOf(th) >= 0) {
        return;
      }
      if (arm.theoreticalVisits.length > 0) {
        arm.theoreticalVisits.splice(arm.theoreticalVisits.length - 1, 1);
      }
      arm.theoreticalVisits.push(th);
    } else if (th.type === 'AFTER_INCLUSION') {
      if (this.theoreticalVisitAfterInclusionList.indexOf(th) >= 0) {
        return;
      }
      if (this.theoreticalVisitAfterInclusionList.length > 0) {
        this.theoreticalVisitAfterInclusionList.splice(this.theoreticalVisitAfterInclusionList.length - 1, 1);
      }
      this.theoreticalVisitAfterInclusionList.push(th);
    }
  }

  goToLastPage() {
    this.currentVisitsPage = this.totalVisitPages - 1;
    this.refreshVisitsPage();
    this.refreshShrunkVisitTypes();
  }

  goToFirstPage() {
    this.currentVisitsPage = 0;
    this.refreshVisitsPage();
    this.refreshShrunkVisitTypes();
  }

  onVisitTypeShowHide(field: string, action: 'show' | 'hide') {
    if (field === 'BEFORE_INCLUSION') {
      if (action === 'hide') {
        const targetElementIndex = this.findFirstVisitIndexInAllVisitsByType('IN_INCLUSION', 'DUMMY_IN_INCLUSION', 'DUMMY_ARM');
        this.currentVisitsPage = Math.floor(targetElementIndex / this.visitsPageSize);
      } else if (action === 'show') {
        const targetElementIndex = this.findLastVisitIndexInAllVisitsByType('BEFORE_INCLUSION', 'DUMMY_BEFORE_INCLUSION');
        this.currentVisitsPage = Math.floor(targetElementIndex / this.visitsPageSize);
      }
    } else if (field === 'IN_INCLUSION') {
      if (action === 'hide') {
        const targetElementIndex = this.findFirstVisitIndexInAllVisitsByType('AFTER_INCLUSION', 'DUMMY_AFTER_INCLUSION');
        this.currentVisitsPage = Math.floor(targetElementIndex / this.visitsPageSize);
      } else if (action === 'show') {
        const targetElementIndex = this.findLastVisitIndexInAllVisitsByType('IN_INCLUSION', 'DUMMY_IN_INCLUSION', 'DUMMY_ARM');
        this.currentVisitsPage = Math.floor(targetElementIndex / this.visitsPageSize);
      }
    } else if (field === 'AFTER_INCLUSION') {
      if (action === 'hide') {
        const targetElementIndex = this.findLastVisitIndexInAllVisitsByType('IN_INCLUSION', 'DUMMY_IN_INCLUSION', 'DUMMY_ARM');
        this.currentVisitsPage = Math.floor(targetElementIndex / this.visitsPageSize);
      } else if (action === 'show') {
        const targetElementIndex = this.findFirstVisitIndexInAllVisitsByType('AFTER_INCLUSION', 'DUMMY_AFTER_INCLUSION');
        this.currentVisitsPage = Math.floor(targetElementIndex / this.visitsPageSize);
      }
    }
    this.refreshVisitsPage();
  }

  matchOperationalActWithVisit(data: VisitAdditionalCost, visit: TheoreticalVisit) {
    if (data.additionalCostType !== 'OPERATIONAL_ACT') {
      return;
    }
    const visitActIndex = visit.visitActs.findIndex(item =>
      (item.trialActHhhId === data.hhhId) && (item.type === data.additionalCostType)
    );

    if (visitActIndex !== -1) {
      const visitAct = visit.visitActs.find(item =>
        (item.trialActHhhId === data.hhhId) && (item.type === data.additionalCostType)
      );
      visit.visitActsToRemove.push(visitAct);
      visit.visitActs.splice(visitActIndex, 1);
      const index = visit.visitActsToAdd.indexOf(visitAct);
      if (index !== -1) {
        visit.visitActsToAdd.splice(index, 1);
      }
      this.updateExistingVisit(visit);
    } else {
      const newVisitAct: TheoreticalVisitAct = TheoreticalVisitAct.generateTheoreticalVisitActFromAdditionalCost(data);
      if(!!visitActIndex){
        visit.visitActs.push(newVisitAct);
        visit.visitActsToAdd.push(newVisitAct);
      }
      this.updateExistingVisit(visit);
    }
  }


  editTrialOperationalAct(rowData: any) {
    this.trialOperationalActService.getTrialOperationalActById(rowData.hhhId).subscribe(res => {
      const formComponent = OperationalTrialActsComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
      formComponent.trialOperationalAct = new TrialOperationalAct().init(res);
      formComponent.display = true;
      formComponent.onUpdateCallback(() => {
        this.updateEvent.emit(true);
      });
      this.changeDetectorRef.detectChanges();
    });
  }

  private setAllVisits() {
    this.allVisits = [];

    if (this.theoreticalCalendar.theoreticalVisitBeforeInclusionList) {
      if (this.theoreticalCalendar.theoreticalVisitBeforeInclusionList.length === 0) {
        const dummyVisit = new TheoreticalVisit();
        dummyVisit.type = 'DUMMY_BEFORE_INCLUSION';
        this.allVisits.push(dummyVisit);
      }
      for (const theoreticalVisit of this.theoreticalCalendar.theoreticalVisitBeforeInclusionList) {
        this.allVisits.push(theoreticalVisit);
      }
    }
    if (this.useDropdownWhenSelectArms) {
      if (this.theoreticalVisitInInclusionDtoListByArm) {
        if (this.theoreticalVisitInInclusionDtoListByArm.length === 0) {
          const dummyVisit = new TheoreticalVisit();
          dummyVisit.type = 'DUMMY_IN_INCLUSION';
          this.allVisits.push(dummyVisit);
        }
        for (const theoreticalVisitInInclusion of this.theoreticalVisitInInclusionDtoListByArm) {
          if (!theoreticalVisitInInclusion.theoreticalVisits || theoreticalVisitInInclusion.theoreticalVisits.length === 0) {
            const dummyVisit = new TheoreticalVisit();
            dummyVisit.type = 'DUMMY_ARM';
            dummyVisit.armHhhId = theoreticalVisitInInclusion.armHhhId;
            this.allVisits.push(dummyVisit);
          }
          if (theoreticalVisitInInclusion.theoreticalVisits) {
            for (const theoreticalVisit of theoreticalVisitInInclusion.theoreticalVisits) {
              this.allVisits.push(theoreticalVisit);
            }
          }
        }
      }
    } else {
      if (this.theoreticalCalendar.theoreticalVisitInInclusionDtoList) {
        if (this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.length === 0) {
          const dummyVisit = new TheoreticalVisit();
          dummyVisit.type = 'DUMMY_IN_INCLUSION';
          this.allVisits.push(dummyVisit);
        }
        for (const theoreticalVisitInInclusion of this.theoreticalCalendar.theoreticalVisitInInclusionDtoList) {
          if (!theoreticalVisitInInclusion.theoreticalVisits || theoreticalVisitInInclusion.theoreticalVisits.length === 0) {
            const dummyVisit = new TheoreticalVisit();
            dummyVisit.type = 'DUMMY_ARM';
            dummyVisit.armHhhId = theoreticalVisitInInclusion.armHhhId;
            this.allVisits.push(dummyVisit);
          }
          if (theoreticalVisitInInclusion.theoreticalVisits) {
            for (const theoreticalVisit of theoreticalVisitInInclusion.theoreticalVisits) {
              this.allVisits.push(theoreticalVisit);
            }
          }
        }
      }
    }
    if (this.theoreticalCalendar.theoreticalVisitAfterInclusionList) {
      if (this.theoreticalCalendar.theoreticalVisitAfterInclusionList.length === 0) {
        const dummyVisit = new TheoreticalVisit();
        dummyVisit.type = 'DUMMY_AFTER_INCLUSION';
        this.allVisits.push(dummyVisit);
      }
      for (const theoreticalVisit of this.theoreticalCalendar.theoreticalVisitAfterInclusionList) {
        this.allVisits.push(theoreticalVisit);
      }
    }
    this.totalVisitPages = (Math.ceil(this.allVisits.length / this.visitsPageSize));
    this.refreshVisitsPage();
  }

  private updateExistingReference(theoreticalVisit: TheoreticalVisit, dateReferences: SelectItem[]): void {
    theoreticalVisit.expectedDateRelativeTo = theoreticalVisit.expectedDateReference;
    if (!theoreticalVisit.expectedDateRelativeTo) {
      return;
    }
    if (theoreticalVisit.expectedDateRelativeTo !== 'OTHER_VISIT') {
      return;
    }
    for (const dateReference of dateReferences) {
      if (theoreticalVisit.dateReferenceVisit.hhhId === dateReference.value.hhhId) {
        theoreticalVisit.expectedDateRelativeTo = dateReference.value;
        return;
      }
    }
  }

  private refreshShrunkVisitTypes() {
    if (this.showBeforeInclusion) {
      this.beforeInclusionAllFields = this.theoreticalVisitBeforeInclusionList.length > 0;
    }
    if (this.showInInclusion) {
      this.inInclusionAllFields = this.theoreticalVisitInInclusionDtoList.length > 0;
    }
    if (this.showAfterInclusion) {
      this.afterInclusionAllFields = this.theoreticalVisitAfterInclusionList.length > 0;
    }
  }

  private addVisitsToBeShown(theoreticalVisit: TheoreticalVisit) {
    if (theoreticalVisit.type === 'BEFORE_INCLUSION' || theoreticalVisit.type === 'DUMMY_BEFORE_INCLUSION') {
      this.showBeforeInclusion = true;
    } else if (theoreticalVisit.type === 'IN_INCLUSION' || theoreticalVisit.type === 'DUMMY_ARM' || theoreticalVisit.type === 'DUMMY_IN_INCLUSION') {
      this.showInInclusion = true;
    } else if (theoreticalVisit.type === 'AFTER_INCLUSION' || theoreticalVisit.type === 'DUMMY_AFTER_INCLUSION') {
      this.showAfterInclusion = true;
    }

    if (theoreticalVisit.type === 'BEFORE_INCLUSION') {
      this.theoreticalVisitBeforeInclusionList.push(theoreticalVisit);
    } else if (theoreticalVisit.type === 'DUMMY_ARM') {
      let arm;
      if (this.useDropdownWhenSelectArms) {
        arm = this.theoreticalVisitInInclusionDtoListByArm.find(a => a.armHhhId === theoreticalVisit.armHhhId);
      } else {
        arm = this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.find(a => a.armHhhId === theoreticalVisit.armHhhId);
      }

      if (arm)
        this.theoreticalVisitInInclusionDtoList.push(arm);


    } else if (theoreticalVisit.type === 'IN_INCLUSION') {
      let arm = this.theoreticalVisitInInclusionDtoList.find(a => a.armHhhId === theoreticalVisit.armHhhId);
      if (!arm) {
        let existingArm;
        if (this.useDropdownWhenSelectArms) {
          existingArm = this.theoreticalVisitInInclusionDtoListByArm.find(a => a.armHhhId === theoreticalVisit.armHhhId);
        } else {
          existingArm = this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.find(a => a.armHhhId === theoreticalVisit.armHhhId);
        }
        if(existingArm){
          arm = new TheoreticalVisitInInclusion();
          arm.arm = existingArm.arm;
          arm.armHhhId = existingArm.armHhhId;
          arm.theoreticalVisits = [];
          this.theoreticalVisitInInclusionDtoList.push(arm);
        }

      }
      arm.theoreticalVisits.push(theoreticalVisit);
    } else if (theoreticalVisit.type === 'AFTER_INCLUSION') {
      this.theoreticalVisitAfterInclusionList.push(theoreticalVisit);
    }
  }

  private findFirstVisitIndexInAllVisitsByType(...types: string[]): number {
    for (let i = 0; i < this.allVisits.length; i++) {
      if (types.indexOf(this.allVisits[i].type) >= 0) {
        return i;
      }
    }
    return -1;
  }

  private findLastVisitIndexInAllVisitsByType(...types: string[]): number {
    for (let i = (this.allVisits.length - 1); i >= 0; i--) {
      if (types.indexOf(this.allVisits[i].type) >= 0) {
        return i;
      }
    }
    return -1;
  }

  private processTheSubActDelete(data: VisitAdditionalCost) {
    if (!this.theoreticalCalendar.operationalSubActsToRemove) {
      this.theoreticalCalendar.operationalSubActsToRemove = [];
    }
    this.theoreticalCalendar.operationalSubActsToRemove.push(data);
    const trialOperationalAct: VisitAdditionalCost = this.acts.find(item => item.hhhId === data.trialOperationalActHhhId);
    if (trialOperationalAct != null) {
      trialOperationalAct.operationalSubActs.splice(trialOperationalAct.operationalSubActs.indexOf(data), 1);
    }
  }

  nothing() {
  }

  setArmOptions(): void {
    this.armOptions = this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.map(value => new LabelValue(value.arm, value.arm));
  }

  armChange(event: string) {
    this.theoreticalVisitInInclusionDtoListByArm = this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.filter(value => value.arm === event);
    this.currentVisitsPage = 0;
    this.setAllVisits();
    this.refreshVisitsPage();
    this.refreshShrunkVisitTypes();
  }
}

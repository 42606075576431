import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {StatisticsService} from '../services/statistics.service';
import {ReportInclusionByTrialFilter} from './models/report-inclusion-by-trial-filter';
import {ReportsInclusionsByTrial} from './models/reports-inclusions-by-trial';
import {DataService} from '../../shared/services/data-service';
import {SelectItem, SelectItemGroup} from 'primeng/api';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {ResponsePage} from '../../shared/entity/response-page';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {StatisticsAppData} from '../statistics-app-data';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {AppData} from '../../helpers/app-data';
import {ExportTypesEnum} from '../../dynamic-config/exported/data-export/ExportTypesEnum';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-follow-inclusions-by-trial',
  templateUrl: './follow-inclusions-by-trial.component.html',
  styleUrls: ['./follow-inclusions-by-trial.component.css']
})
export class FollowInclusionsByTrialComponent extends PaginatorTableComponent<ReportsInclusionsByTrial> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.Inclusion;
  filterTarget = DynamicDefinitionEnum.STATISTICS_FOLLOW_INCLUSIONS_BY_TRIAL_FILTER_FORM;
  target = DynamicDefinitionEnum.STATISTICS_FOLLOW_INCLUSIONS_BY_TRIAL_LIST_TABLE;

  filterConfig: FormConfig = new FormConfig();
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  multiSiteEnabled = false;
  committeeToPole = false;

  reportsInclusionsByTrial: ReportsInclusionsByTrial[];
  siteHhhId: number;
  years: SelectItem[] = [];
  organFamilies: SelectItemGroup[] = [];
  organFamily: string;
  filters: ReportInclusionByTrialFilter = new ReportInclusionByTrialFilter();
  currentYear: number;

  constructor(
    private statisticsService: StatisticsService,
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    private dynamicConfigService: DynamicConfigService,
    private _cdr: ChangeDetectorRef
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.getYears();
    this.getList();
    this.getOrgansFamilies();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.multiSiteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
      this.committeeToPole = this.dynamicConfigService.getProperty(PropertyEnum.committeeToPole);
    });
    this.initFormConfig();
    this.initTableConfig();
  }

  initFormConfig() {
    this.dynamicConfigService.getFormConfig(this.filterTarget, StatisticsAppData.formConfig).subscribe(
      config => {
        this.filterConfig = config;
      }
    );
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, StatisticsAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.filterConfig.getFieldConfig(fieldName);

  buildTableHeaders() {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('trial')
      .header('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_TRIAL')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('organs')
      .header('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_ORGANS')
      .displayContent((data: any) => {
        let organList = '';
        for (const item of data.organFamilies) {
          organList += ('-' + item + '<br>');
        }
        for (const item of data.organs) {
          organList += ('-' + item + '<br>');
        }
        return organList;
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('jan')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_1')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[0];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('feb')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_2')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[1];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('mar')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_3')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[2];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('apr')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_4')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[3];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('may')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_5')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[4];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('jun')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_6')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[5];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('jul')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_7')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[6];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('aug')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_8')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[7];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('sep')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_9')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[8];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('oct')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_10')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[9];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('nov')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_11')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[10];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('dec')
      .header('GENERAL_LINGUISTICS_MONTH_MMM_UCASE_12')
      .width('50px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerTrialInCurrentYear[11];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('previousYear')
      .header('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_INCLUSIONS_IN_PREVIOUS_YEAR_HEADER')
      .width('80px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerYear[0];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('currentYear')
      .header('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_INCLUSIONS_IN_CURRENT_YEAR_HEADER')
      .width('80px')
      .displayContent((data: ReportsInclusionsByTrial) => {
        return data.inclusionsPerYear[1];
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('total')
      .header('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_TOTAL')
      .width('80px')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('numberPatientsPlanned')
      .header('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_NUMBER_PATIENTS_PLANNED_HEADER')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('numberInclusionsInScreeningFailure')
      .header('MODULE_TRIALS_DASHBOARD_STATE_SCREENING_FAILURE')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('actualProductionLaunchDate')
      .header('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_MEP_DATE_HEADER')
      .sortable()
      .sortField('ts.actualProductionLaunchDate')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialEndDate')
      .header('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_TRIAL_END_DATE_HEADER')
      .sortable()
      .sortField('ts.trialEndDate')
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getList() {
    this.statisticsService.getReportsInclusionsByTrial(this.page, this.limit, this.sortBy, this.orderBy,
      this.filters).subscribe((res: ResponsePage<ReportsInclusionsByTrial>) => {
      this.statisticsService.setPaginationConfiguration(res);
      this.reportsInclusionsByTrial = res.content;
      this.totalNumber = res.totalElements;
      this._cdr.detectChanges();
    }, error => {
      console.error(error);
    });
  }

  getYears() {
    for (let i = 1949; i < 2049; i++) {
      this.years.push({label: i.toString(), value: i});
    }
    this.currentYear = new Date().getFullYear();
    this.filters.year = this.currentYear;
  }

  getOrgansFamilies() {
    this.statisticsService.getOrganFamilies().subscribe(res => {
      this.organFamilies.push({label: '', items: null});
      for (const organFamily of res) {
        const itemsChild: SelectItem[] = [];
        for (const organ of organFamily.organs) {
          const itemChild = {label: organ.nameFr, value: organ.hhhId};
          itemsChild.push(itemChild);
        }
        const item = {label: organFamily.nameFr, items: itemsChild};
        this.organFamilies.push(item);
      }
    });
  }

  isParent() {
    return this.dynamicConfigService.parent;
  }

  exportFollowInclusions(event: any): void {
    const request = new ExportRequestBuilder<ReportInclusionByTrialFilter>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .sortBy(this.sortBy)
      .orderBy(this.orderBy)
      .filters(this.filters)
      .build();

    this.statisticsService.exportFollowInclusionByTrial(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }


}

import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';

import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HumanResourcesService} from './human-resources/services/human-resources.service';
import {SharedModule} from './shared/shared.module';
import {TrialModule} from './trial/trial.module';
import {InvoiceModule} from './invoice/invoice.module';
import {HomeModule} from './home/home.module';
import {AgendaModule} from './agenda/agenda.module';
import {StatisticsModule} from './statistics/statistics.module';
import {PatientModule} from './patient/patient.module';
import {ProviderModule} from './provider/provider.module';
import {SiteModule} from './site/site.module';
import {HumanResourcesModule} from './human-resources/human-resources.module';
import {AdditionalCostsModule} from './additional-costs/additional-costs.module';
import {EstablishmentModule} from './establishment/establishment.module';
import {AccountListComponent} from './admin/account-list/account-list.component';
import {DataImportModule} from './data-import/data-import.module';
import {NewUserComponent} from './admin/new-user/new-user.component';
import {AccessRightsComponent} from './admin/access-rights/access-rights.component';
import {LoginComponent} from './login/login-component/login.component';
import {StorageServiceModule} from 'ngx-webstorage-service';
import {LocalStorageService} from './local-storage-service.service';
import {ProviderFormComponent} from './provider/provider-form/provider-form.component';
import {DeleteConfirmationComponent} from './modals/delete-confirmation/delete-confirmation.component';
import {ContactFormComponent} from './modals/contact-form/contact-form.component';
import {CurrentUserComponent} from './admin/current-user/current-user.component';
import {AuthGuard} from './helpers/auth-guard.service';
import {JwtInterceptor} from './helpers/jwt.interceptor';
import {ErrorPageComponent} from './error-page/error-page.component';
import {AmendmentVerificationComponent} from './modals/amendment-verification/amendment-verification.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ListHeaderComponent} from './modals/list-header/list-header.component';
import {LoaderInterceptorService} from './helpers/loader-interceptor.service';
import {AccessRightsFormComponent} from './admin/access-rights-form/access-rights-form.component';
import {CurrentUserFormComponent} from './admin/current-user-form/current-user-form.component';
import {CurrentUserChangePasswordFormComponent} from './admin/current-user-change-password-form/current-user-change-password-form.component';
import {PatientTrialFormComponent} from './modals/patient-add-to-trial-form/patient-trial-form.component';
import {FolderFormComponent} from './modals/folder-form/folder-form.component';
import {FileFormComponent} from './modals/file-form/file-form.component';
import {InclusionFormComponent} from './modals/inclusion-form/inclusion-form.component';
import {InvoiceCreationWarningFormComponent} from './modals/invoice-creation-warning-form/invoice-creation-warning-form.component';
import {DatePipe} from '@progress/kendo-angular-intl';
import {DeleteDeletableEntityConfirmationComponent} from './modals/delete-deletable-entity-confirmation/delete-deletable-entity-confirmation.component';
import {DeleteMultipleComponent} from './modals/delete-multiple/delete-multiple.component';
import {NoSelectedItemFormComponent} from './modals/no-selected-item-form/no-selected-item-form.component';
import {ImportTrialActFormComponent} from './modals/import-trial-act-form/import-trial-act-form.component';
import {VisitStateUpdateConfirmationComponent} from './trial/component/inclusions/inclusion-details/calendar-details/visit-state-update-confirmation/visit-state-update-confirmation.component';
import {VisitActStateUpdateConfirmationComponent} from './trial/component/inclusions/inclusion-details/calendar-details/visit-act-state-update-confirmation/visit-act-state-update-confirmation.component';
import {DateInterceptor} from './helpers/date-interceptor';
import {CanDeactivateGuard} from './helpers/can-deactivate-guard';
import {ErrorInterceptor} from './helpers/error.interceptor';
import {UserAttemptListComponent} from './user-attempts/user-attempt-list/user-attempt-list.component';
import {ForgetPasswordComponent} from './login/forget-password/forget-password.component';

import {registerLocaleData} from '@angular/common';

import localeFr from '@angular/common/locales/fr';
import {CounterPartCategoriesComponent} from './additional-costs/counter-part-categories/counter-part-categories.component';
import {CounterPartReferenceFormComponent} from './additional-costs/counter-part-reference-form/counter-part-reference-form.component';
import {DropdownFieldFormComponent} from './modals/dropdown-field-form/dropdown-field-form.component';
import {BlocFieldsComponent} from './modals/bloc-date-form/bloc-fields.component';
import {WorkloadComponent} from './admin/workload/workload.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {PageDownComponent} from './page-down/page-down.component';
import {ModifyStatusFormComponent} from './modals/modify-status-form/modify-status-form.component';
import {PharmacyModule} from './pharmacy/pharmacy.module';
import {MobileModule} from './mobile/mobile.module';
import {RetrocessionFeesModule} from './retrocession-fees/retrocession-fees.module';
import {OtherTheoreticalInvoiceDateComponent} from './modals/other-theoretical-invoice-date/other-theoretical-invoice-date.component';
import {OtherLastCppAmendmentDateComponent} from './modals/other-last-cpp-amendment-date/other-last-cpp-amendment-date.component';
import {DynamicConfigModule} from './dynamic-config/dynamic-config.module';
import {DynamicConfigService} from './dynamic-config/service/dynamic-config.service';
import {PageLockConfirmationComponent} from './dynamic-config/exported/page-lock/page-lock-confirmation/page-lock-confirmation.component';
import {PageLockPasswordComponent} from './dynamic-config/exported/page-lock/page-lock-password/page-lock-password.component';
import {PageUnlockConfirmationComponent} from './dynamic-config/exported/page-lock/page-unlock-confirmation/page-unlock-confirmation.component';
import {DataCommentModule} from './data-comment/data-comment.module';
import {AuthenticationService, TOKEN} from './login/services/authentication.service';
import {of} from 'rxjs';
import {AuditTrailComponent} from './admin/audit-trail/audit-trail.component';
import {RequirePasswordUpdateGuard} from './helpers/require-password-update-guard';


registerLocaleData(localeFr);

@NgModule({
  imports: [
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    SharedModule,
    DynamicConfigModule,
    AgendaModule,
    PatientModule,
    StatisticsModule,
    HomeModule,
    TrialModule,
    ProviderModule,
    InvoiceModule,
    RetrocessionFeesModule,
    SiteModule,
    HumanResourcesModule,
    DataCommentModule,
    MobileModule,
    AdditionalCostsModule,
    EstablishmentModule,
    DataImportModule,
    StorageServiceModule,
    NgbModule.forRoot(),
    PharmacyModule,
  ],
  declarations: [
    AppComponent,
    AccountListComponent,
    AuditTrailComponent,
    NewUserComponent,
    AccessRightsComponent,
    LoginComponent,
    ProviderFormComponent,
    DeleteConfirmationComponent,
    DeleteDeletableEntityConfirmationComponent,
    ContactFormComponent,
    CurrentUserComponent,
    ErrorPageComponent,
    AmendmentVerificationComponent,
    ContactFormComponent,
    CurrentUserComponent,
    ErrorPageComponent,
    AmendmentVerificationComponent,
    CounterPartReferenceFormComponent,
    AccessRightsFormComponent,
    CurrentUserFormComponent,
    CurrentUserChangePasswordFormComponent,
    PatientTrialFormComponent,
    FolderFormComponent,
    FileFormComponent,
    InclusionFormComponent,
    DropdownFieldFormComponent,
    InvoiceCreationWarningFormComponent,
    BlocFieldsComponent,
    DeleteDeletableEntityConfirmationComponent,
    ImportTrialActFormComponent,
    DeleteMultipleComponent,
    NoSelectedItemFormComponent,
    UserAttemptListComponent,
    ForgetPasswordComponent,
    CounterPartCategoriesComponent,
    ForgetPasswordComponent,
    DropdownFieldFormComponent,
    BlocFieldsComponent,
    WorkloadComponent,
    WorkloadComponent,
    PageDownComponent,
    ModifyStatusFormComponent,
    ModifyStatusFormComponent,
    OtherTheoreticalInvoiceDateComponent,
    OtherLastCppAmendmentDateComponent,
  ],
  entryComponents: [
    VisitStateUpdateConfirmationComponent,
    VisitActStateUpdateConfirmationComponent,
    DeleteConfirmationComponent,
    DeleteDeletableEntityConfirmationComponent,
    ImportTrialActFormComponent,
    NoSelectedItemFormComponent,
    ContactFormComponent,
    FolderFormComponent,
    FileFormComponent,
    InclusionFormComponent,
    DeleteMultipleComponent,
    InclusionFormComponent,
    InvoiceCreationWarningFormComponent,
    BlocFieldsComponent,
    DropdownFieldFormComponent,
    ModifyStatusFormComponent,
    OtherLastCppAmendmentDateComponent,
    ModifyStatusFormComponent,
    DropdownFieldFormComponent,
    ModifyStatusFormComponent,
    PageLockConfirmationComponent,
    PageLockPasswordComponent,
    PageUnlockConfirmationComponent
  ],
  providers: [
    HumanResourcesService,
    ConfirmationService,
    MessageService,
    LocalStorageService,
    AuthGuard,
    ListHeaderComponent,
    DatePipe,
    DynamicConfigService,
    CanDeactivateGuard,
    RequirePasswordUpdateGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initAppAndTranslation,
      multi: true,
      deps: [DynamicConfigService, AuthenticationService,TranslateService]
    },
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true},
    {provide: 'LOCATION_TOKEN', useValue: window.location},
    {provide: LOCALE_ID, useValue: 'fr-CA'}
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


export function initAppAndTranslation(
  dynamicConfigService: DynamicConfigService,
  authService: AuthenticationService,
  translate: TranslateService
): () => Promise<void> {
  return async () => {
    await initApplication(dynamicConfigService, authService)();
    await delay(200);
    await initTranslation(translate)();
  };
}

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function initApplication(dynamicConfigService: DynamicConfigService, authService: AuthenticationService) {
  return async () => {
    if (!authService.isAccessTokenPresent()) {
      console.log('token not present...');
      authService.logout();
      return;
    } else {
      try {
        if (!authService.isUserAuthenticated()) {
          console.log('Refreshing token...');
          await authService.refreshToken().toPromise();
          console.log('token refreshed successfully');
        } else {
          console.log('refresh token still valid');
        }
        authService.setRefreshJob();
        dynamicConfigService.initProperties();
        return of(void 0).toPromise();
      } catch (error) {
        console.error('Error refreshing token:', error);
        authService.logout();
        return Promise.resolve();
      }
    }
  };
}

export function initTranslation(translate: TranslateService): () => Promise<void> {
  return async () => {
    console.log('Initializing translations...');
    translate.setDefaultLang('fr');
    await translate.use('fr').toPromise()
    translate.currentLang = 'fr';
    try {
      await translate.get('UI_MENU_MAIN_ENTRY_DASHBOARD').toPromise();
      console.log('Translations loaded successfully!');
    } catch (error) {
      console.error('Error loading translations:', error);
    }
  };
}

export class UpdateSite {
  hhhId: number;
  newName: string;
  establishmentHhhId: number;

  constructor(id: number, newName: string, establishmentHhhId: number) {
    this.hhhId = id;
    this.newName = newName;
    this.establishmentHhhId = establishmentHhhId;
  }
}

import {Component} from '@angular/core';

@Component({
  selector: 'ih-statistic-icon',
  templateUrl: './statistic-icon.component.html',
  styleUrls: ['./statistic-icon.component.css']
})
export class StatisticIconComponent {

  constructor() { }
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../shared/component/form/form.component';
import {Account} from '../models/account';
import {Data} from '../../shared/entity/data';
import {UsersService} from '../../admin/services/users.service';
import {SharedService} from '../../shared/services/shared.service';
import {NgForm} from '@angular/forms';
import {AccountsManagementService} from '../services/accounts-management.service';

@Component({
  selector: 'app-account-management-form',
  templateUrl: './account-management-form.component.html',
  styleUrls: ['./account-management-form.component.css']
})
export class AccountManagementFormComponent extends FormComponent<Account> implements OnInit {
  @ViewChild('accountManagementForm') accountManagementForm: NgForm;

  account: Account = new Account();
  disablePassword = false;
  civilities = Data.userTitles;
  active = Data.activeOptions;

  constructor(private userService: UsersService,
              private accountManagementService: AccountsManagementService,
              private sharedService: SharedService) {
    super();
  }

  private _service: any;

  // service needs to be passed as a parameter.
  get service(): any {
    return this._service;
  }

  set service(value: any) {
    this._service = value;
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(AccountManagementFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.disablePassword = this.account.hhhId != null;
  }

  submitCreateAccount() {
    this.submitted = true;
    if (this.accountManagementForm.invalid) {
      return;
    }
    if (!this.account.hhhId) {
      this.createAccount();
    } else {
      this.updateAccount();
    }
  }

  createAccount() {
    this.accountManagementService.addMobileUserAccount(this.account).subscribe(account => {
      this.callback(account);
      this.display = false;
      this.sharedService.showSuccess();
    }, error => {
      console.error(error);
      this.sharedService.showFailure();
    });
  }

  private updateAccount() {
    this.accountManagementService.updateMobileUserAccount(this.account).subscribe(() => {
      this.callback(this.account);
      this.display = false;
      this.sharedService.showSuccess();
    }, error => {
      console.error(error);
      this.sharedService.showFailure();
    });
  }
}

import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Inject,
  InjectionToken,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import * as XLSX from 'xlsx';
import {TrialActService} from '../../trial/service/trial-act.service';
import {Router} from '@angular/router';
import {saveAs} from 'file-saver';
import {Properties} from '../../helpers/properties';
import {DynamicFilesService} from '../../shared/services/dynamic-files.service';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {ImportedTrialAct} from '../../trial/entity/trial-additionnal-costs/imported-trial-act';

export const LOCATION_TOKEN = new InjectionToken<Location>('Window location object');

@Component({
  providers: [
    {provide: LOCATION_TOKEN, useValue: window.location}
  ],
  selector: 'ih-import-trial-act-form',
  templateUrl: './import-trial-act-form.component.html',
  styleUrls: ['./import-trial-act-form.component.css']
})
export class ImportTrialActFormComponent implements OnInit {
  @Output() static importEvent = new EventEmitter<boolean>();
  displayImportActsListDialog: boolean;
  file: File;
  trialHhhId: number;
  host = new Properties().host;


  constructor(private trialActService: TrialActService,
              private router: Router,
              private dynamicFiles: DynamicFilesService,
              private translateService: InnohealthTranslateService,
              @Inject(LOCATION_TOKEN) private location: Location) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ImportTrialActFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  private static saveAsExcelFile(buffer: any, fileName: string): void {
    const excelMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelExtension = '.xlsx';
    const data: Blob = new Blob([buffer], {type: excelMimeType});
    saveAs(data, fileName + '_export_' + new Date().getDate() + excelExtension);
  }

  ngOnInit() {
    this.displayImportActsListDialog = true;
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

  importActs() {
    this.trialActService.importActs(this.file, this.trialHhhId).subscribe(res => {
      this.displayImportActsListDialog = false;
      ImportTrialActFormComponent.importEvent.emit(true);
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.constructData(res));
      worksheet.A1.v = this.getColumnInPositionX(0);
      worksheet.B1.v = this.getColumnInPositionX(1);
      worksheet.C1.v = this.getColumnInPositionX(2);
      worksheet.D1.v = this.getColumnInPositionX(3);
      worksheet.E1.v = this.getColumnInPositionX(4);
      worksheet.F1.v = this.getColumnInPositionX(5);
      worksheet.G1.v = this.getColumnInPositionX(6);
      if (worksheet.H1 !== undefined) {
        worksheet.H1.v = this.getColumnInPositionX(7);
      }
      if (worksheet.I1 !== undefined) {
        worksheet.I1.v = this.getColumnInPositionX(8);
      }
      if (worksheet.J1 !== undefined) {
        worksheet.J1.v = '';
      }
      worksheet['!cols'] = [
        {wpx: 200},
        {wpx: 200},
        {wpx: 200},
        {wpx: 200},
        {wpx: 200},
        {wpx: 200},
        {wpx: 200},
        {wpx: 200},
        {wpx: 200},
        {wpx: 200}
      ];
      const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};
      const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
      ImportTrialActFormComponent.saveAsExcelFile(excelBuffer, 'excelFileName');
    }, error => {
      console.error(error);
    });
  }

  getColumnInPositionX(position: number): string {
    const columnLabel = this.dynamicFiles.trialActFileHeaders.split(',')[position];
    if (columnLabel === undefined) {
      return '';
    }
    return this.translateService.getTranslationString('MODULE_TRIAL_ACT_IMPORT_FIELD_' + columnLabel);
  }

  downloadModal() {
    this.location.assign(this.host + '/' + this.dynamicFiles.importTrialActFileName);
  }

  constructData(importedActs: ImportedTrialAct[]) {
    const data: object[] = [];

    importedActs.forEach(value => {
      const object = new Object();
      if (this.dynamicFiles.importTrialActFileName === 'import_act_template_igr.csv' ||
        this.dynamicFiles.importTrialActFileName === 'import_act_template_col.csv') {
        object[0] = value.contractType;
        object[1] = value.type;
        object[2] = value.actCategory;
        object[3] = value.description;
        object[4] = value.invoiceable;
        object[5] = value.pricePerUnit;
        object[6] = value.imputationCode;
        object[7] = value.firstSegment;
        object[8] = value.secondSegment;
        object[9] = value.result;
      } else {
        object[0] = value.actCategory;
        object[1] = value.description;
        object[2] = value.onPrescription;
        object[3] = value.externalFromCentre;
        object[4] = value.invoiceable;
        object[5] = value.pricePerUnit;
        object[6] = value.result;
      }

      data.push(object);
    });
    return data;
  }
}

import {Component, OnInit} from '@angular/core';
import {LastDateList} from '../../../models/LastDateList';
import {FR} from '../../../../shared/entity/calendar-language';
import {LastDateService} from '../../../services/last-date.service';
import {SharedService} from '../../../../shared/services/shared.service';


@Component({
  selector: 'ih-date-last-cset-agreement-form',
  templateUrl: './date-last-cset-agreement-form.component.html',
  styleUrls: ['./date-last-cset-agreement-form.component.css']
})
export class DateLastCsetAgreementFormComponent implements OnInit {

  displayDateLastCsetAgreement = true;
  lastDateCsetAgreementList: LastDateList[] = [];
  locale = FR;
  public trialId: number;
  callback: (dateLastCsetAgreementStream: string) => void;

  constructor(private lastDateService: LastDateService, private sharedService: SharedService) {}

  ngOnInit() {
    this.getOtherLastDate();
    this.addDate();
  }

  setTrialId(trialId: number) {
    this.trialId = trialId;
  }

  save() {
    this.lastDateService.saveModificationCSET(this.lastDateCsetAgreementList, this.trialId ? this.trialId : 0).subscribe((res) => {
      this.displayDateLastCsetAgreement = false;
      this.sharedService.showSuccess();
      const listofvalue = res.value.split(';');
      const c = listofvalue[0];
      this.callback(c);
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  addDate() {
    const lastCsetAgreementDate = new LastDateList();
    lastCsetAgreementDate.trialHhhId = this.trialId;
    this.lastDateCsetAgreementList.splice(0, 0, lastCsetAgreementDate);
    this.refreshOtherAmendmentIndexes();
  }

  refreshOtherAmendmentIndexes() {
    for (let i = 0; i < this.lastDateCsetAgreementList.length; i++) {
      this.lastDateCsetAgreementList[i].index = i;
    }
  }

  deleteDate(i: number) {
    this.lastDateCsetAgreementList.splice(i, 1);
    this.refreshOtherAmendmentIndexes();
  }

  onAdd(callback: (dateLastCsetAgreementStream: string) => void) {
    this.callback = callback;
  }

  private getOtherLastDate() {
    this.lastDateService.getCSET(this.trialId).subscribe(res => {
      this.lastDateCsetAgreementList = res;
      this.addDate();
    }, error => {
      console.error(error);
    });
  }

}

import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {Properties} from '../../../helpers/properties';
import {TrialService} from '../../services/trial.service';
import {Trial} from '../../../mobile/models/trial';
import {AttachedToEntityEnum} from '../../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.css']
})
export class FileListComponent implements OnInit {

  @ViewChild('addFolderOrFileDialog', {read: ViewContainerRef}) addFolderOrFileDialogueContainer: ViewContainerRef;
  @ViewChild('deleteFolderOrFileDialog', {read: ViewContainerRef}) deleteFolderOrFileDialog: ViewContainerRef;

  public readonly AttachedToEntityEnum = AttachedToEntityEnum;

  trial: Trial = new Trial();
  public trialHhhId: number;
  public hhhId: number;
  public fileHhhId: string;
  public type: string;
  public targetFolder: string;
  public parentFolder: string;
  public cols: any[];
  host: string;
  pharmacyHhhId: number;
  public fileName: string;


  constructor(public trialService: TrialService,
              private router: Router) {
    const url = this.router.parseUrl(this.router.url);
    this.host = new Properties().host;
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
  }

}

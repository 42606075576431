import {Component, ComponentFactoryResolver, Inject, OnInit, ViewContainerRef} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {DynamicFilesService} from '../../shared/services/dynamic-files.service';
import {Router} from '@angular/router';
import {ProviderService} from '../services/provider.service';

@Component({
  selector: 'ih-provider-import',
  templateUrl: './provider-import.component.html',
  styleUrls: ['./provider-import.component.css']
})
export class ProviderImportComponent implements OnInit {

  importProviderDialogueVisible = false;
  file: File;
  host = new Properties().host;
  private rowSeparator = '\r\n';

  constructor(public dynamicFiles: DynamicFilesService,
              private prService: ProviderService,
              private router: Router,
              @Inject('LOCATION_TOKEN') private location: Location) {
    console.log(location);
  }

  public static display(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ProviderImportComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {}

  importProviders() {
    this.prService.importProviders(this.file).subscribe(res => {
      console.log(res);
      let csvData = '';
      for (const re of res) {
        csvData += (re + this.rowSeparator);
      }
      this.importProviderDialogueVisible = false;
      const BOM = '\uFEFF';
      const blob = new Blob([BOM + csvData], {type: 'text/csv;charset=utf-8'});
      this.router.navigate(['']).then(() => {
        this.router.navigate(['/provider-list']);
      });
    });
  }

  downloadModal() {
    location.assign(this.host + '/' + this.dynamicFiles.importProvidersFileName);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

}

import {CalendarCheckbox} from '../../models/calendar-checkbox';
import {InclusionCrossover} from './InclusionCrossover';
import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';
import {DataCommentCount} from '../../../data-comment/entity/data-comment-count';

export class Inclusion extends DataCommentCount {
  hhhId: number;
  trialHhhId: number;
  patientHhhId: number;
  patientInternalIdentifier: string;
  patientName: string;
  patientBirthDate: Date;
  calculatedState: string;
  patientSex: string;
  stateHhhId: number;
  hasManualState: boolean;
  trialSiteHhhId: number;
  armHhhId: number;
  inclusionArmHhhId: number;
  hasCrossoverDate: boolean;
  crossoverPhase: number;
  crossoverEscalationExtension: number;
  crossoverCohort: number;
  crossoverDose: number;
  phase: number;
  escalationExtension: number;
  cohort: number;
  crossoverDate: Date;
  arm2HhhId: number;
  inclusionArm2HhhId: number;
  referrerInvestigatorHhhId: number;
  tecIncludedHhhId: number;
  preScreeningSignatoryDoctorHhhId: number;
  coInvestigatorHhhId: number;
  initials: string;
  folderNumber: string;
  inclusionPreScreeningIdentifier: string;
  inclusionRandomizationIdentifier: string;
  preSelectionTypes: number[];
  rcpValidationId: number;
  inclusionIdentifier1: string;
  inclusionIdentifier2: string;
  consentHhhId: number;
  result: string;
  preScreenFailure: boolean;
  screeningFailure: boolean;
  organList: string[] = [];
  inclusionTrialEndingReason: string;
  inclusionTrialEndReasonHhhId: number;
  trialTreatmentEndReasonHhhId: number;
  screeningFailureCauseHhhId: number;
  preScreeningFailureReason: string;
  ancillaryStudySampling: boolean;
  countOtherConsent: number;
  otherConsentDates: string;
  countBlocDates: number;
  blindedTrial: boolean;
  promoterAgreement: boolean;
  blindDate: Date;
  patientDeathDate: Date;
  mandatoryBiologicalSampling: boolean;
  prescreeningResult: boolean;
  mutationListHhhIds: number[] = [];
  receivedTreatment: string;
  preScreeningConsentHhhId: number;
  changeDesign: boolean;
  consentSignatureType1HhhId: number;
  consentSignatureType2HhhId: number;
  consentSignatureType3HhhId: number;
  crossovers: InclusionCrossover[] = [];
  arpegoStuffDate: Date;
  hasArpegoStuff: boolean;
  patientFirstName: string;
  patientLastName: string;
  resultComment: string;
  labHhhId: number;
  stateId: number;
  shipmentAddress: string;
  site: string;
  screenedNotIncluded: boolean;
  nonInclusionReason: string;
  nonInclusionReasonHhhId: number;
  nonInclusionReasonComment: string;
  screeningFailureCauseComment: string;
  caseManagementHhhIds: number[] = [];

  randomization: CalendarCheckbox;
  ancillaryStudySamplingDate: CalendarCheckbox;
  mandatoryBiologicalSamplingDate: CalendarCheckbox;
  initialConsentSignature: CalendarCheckbox;
  familyConsentSignature: CalendarCheckbox;
  preScreening: CalendarCheckbox;
  inclusionCalendar: CalendarCheckbox;
  trialTreatmentStart: CalendarCheckbox;
  trialTreatmentEnd: CalendarCheckbox;
  inclusionTrialEndDate: CalendarCheckbox;
  dispatchBlockDate: CalendarCheckbox;
  resultDate: CalendarCheckbox;
  blocRequestDate: CalendarCheckbox;
  preScreeningConsentSignatureDate: CalendarCheckbox;
  preScreeningFailureDate: CalendarCheckbox;
  consentSignatureDate1: CalendarCheckbox;
  consentSignatureDate2: CalendarCheckbox;
  consentSignatureDate3: CalendarCheckbox;
  oppositionPatientDate: CalendarCheckbox;
  followupEnd: CalendarCheckbox;
  screenFailure: CalendarCheckbox;
  vitalStatus: CalendarCheckbox;
  longTermFollowUp: CalendarCheckbox;

  removedOrganList: string[] = [];
  addedOrganList: string[] = [];
  inclusionCrossoverDesignPublicCommentCount: number;

  init(obj: any): Inclusion {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  isValid(config: FormConfig, multiSite: boolean): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'state') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.stateHhhId)) {
          return false;
        }
      } else if (key === 'arm') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.armHhhId)) {
          return false;
        }
      } else if (key === 'crossoverDate') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.crossoverDate)) {
          return false;
        }
      } else if (key === 'arm2') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.arm2HhhId)) {
          return false;
        }
      } else if (key === 'referrerInvestigator') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.referrerInvestigatorHhhId)) {
          return false;
        }
      } else if (key === 'coInvestigator') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.coInvestigatorHhhId)) {
          return false;
        }
      } else if (key === 'consent') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.consentHhhId)) {
          return false;
        }
      } else if (key === 'trialTreatmentEndReason') {
        if (this.trialTreatmentEnd != null && this.trialTreatmentEnd.date != null && required && Util.isNullOrUndefinedOrEmpty(this.trialTreatmentEndReasonHhhId)) {
          return false;
        }
      } else if (key === 'inclusionTrialEndReason') {
        if (this.inclusionTrialEndDate != null && this.inclusionTrialEndDate.date != null && required && Util.isNullOrUndefinedOrEmpty(this.inclusionTrialEndReasonHhhId)) {
          return false;
        }
      } else if (key === 'screeningFailureCause') {
        if (this.screenFailure != null && this.screenFailure.date && required && Util.isNullOrUndefinedOrEmpty(this.screeningFailureCauseHhhId)) {
          return false;
        }
      }
      if (key === 'state') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.stateHhhId)) {
          return false;
        }
      } else if (key === 'trialSite') {
        if(multiSite){
          if (required && Util.isNullOrUndefinedOrEmpty(this.trialSiteHhhId)) {
            return false;
        }
        }
      } else if (key === 'arm') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.armHhhId)) {
          return false;
        }
      } else if (key === 'crossoverDate') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.crossoverDate)) {
          return false;
        }
      } else if (key === 'arm2') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.arm2HhhId)) {
          return false;
        }
      } else if (key === 'referrerInvestigator') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.referrerInvestigatorHhhId)) {
          return false;
        }
      } else if (key === 'coInvestigator') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.coInvestigatorHhhId)) {
          return false;
        }
      } else if (key === 'consent') {
        if (!Util.isNullOrUndefinedOrEmpty(this.consentSignatureDate1) && this.consentSignatureDate1.date != null && required && Util.isNullOrUndefinedOrEmpty(this.consentHhhId)) {
          return false;
        }
      } else if (key === 'trialTreatmentEndReason') {
        if (this.trialTreatmentEnd != null && this.trialTreatmentEnd.date != null && required && Util.isNullOrUndefinedOrEmpty(this.trialTreatmentEndReasonHhhId)) {
          return false;
        }
      } else if (key === 'inclusionTrialEndReason') {
        if (this.inclusionTrialEndDate != null && this.inclusionTrialEndDate.date != null && required && Util.isNullOrUndefinedOrEmpty(this.inclusionTrialEndReasonHhhId)) {
          return false;
        }
      } else if (key === 'screeningFailureCause') {
        if (this.screenFailure != null && this.screenFailure.date && required && Util.isNullOrUndefinedOrEmpty(this.screeningFailureCauseHhhId)) {
          return false;
        }
      } else if (key === 'preScreeningSignatoryDoctor') {
        if (this.preScreeningConsentSignatureDate != null && this.preScreeningConsentSignatureDate.date && required && Util.isNullOrUndefinedOrEmpty(this.preScreeningSignatoryDoctorHhhId)) {
          return false;
        }
      } else if (key === 'lab') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.labHhhId)) {
          return false;
        }
      } else if (key === 'preScreeningFailureDate') {
        if (this.preScreenFailure && required && Util.isNullOrUndefinedOrEmpty(this.preScreeningFailureDate)) {
          return false;
        }
      } else if (key === 'preScreeningFailureReason') {
        if (this.preScreenFailure && required && Util.isNullOrUndefinedOrEmpty(this.preScreeningFailureReason)) {
          return false;
        }
      } else if (key === 'mutationList') {
        if (this.prescreeningResult && required && Util.isNullOrUndefinedOrEmpty(this.mutationListHhhIds)) {
          return false;
        }
      } else if (key === 'consentSignatureType1') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.consentSignatureType1HhhId)) {
          return false;
        }
      } else if (key === 'consentSignatureDate1') {
        if (this.consentSignatureType1HhhId != null && required && Util.isNullOrUndefinedOrEmpty(this.consentSignatureDate1)) {
          return false;
        }
      } else if (key === 'consentSignatureType2') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.consentSignatureType2HhhId)) {
          return false;
        }
      } else if (key === 'consentSignatureDate2') {
        if (this.consentSignatureType2HhhId != null && required && Util.isNullOrUndefinedOrEmpty(this.consentSignatureDate2)) {
          return false;
        }
      } else if (key === 'consentSignatureType3') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.consentSignatureType3HhhId)) {
          return false;
        }
      } else if (key === 'consentSignatureDate3') {
        if (this.consentSignatureType3HhhId != null && required && Util.isNullOrUndefinedOrEmpty(this.consentSignatureDate3)) {
          return false;
        }
      } else if (key === 'inclusionArm') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.inclusionArmHhhId)) {
          return false;
        }
      } else if (key === 'screenFailure') {
        if (this.screeningFailure && required && Util.isNullOrUndefinedOrEmpty(this.screenFailure)) {
          return false;
        }
      } else if (key === 'screenFailureNotAttachedToScreeningFailure') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.screenFailure)) {
          return false;
        }
      } else if (key === 'screeningFailureCause') {
        if (this.screenFailure && required && Util.isNullOrUndefinedOrEmpty(this.screeningFailureCauseHhhId)) {
          return false;
        }
      } else if (key === 'mandatoryBiologicalSamplingDate') {
        if (this.mandatoryBiologicalSampling && required && Util.isNullOrUndefinedOrEmpty(this.mandatoryBiologicalSamplingDate)) {
          return false;
        }
      } else if (key === 'ancillaryStudySamplingDate') {
        if (this.ancillaryStudySampling && required && Util.isNullOrUndefinedOrEmpty(this.ancillaryStudySamplingDate)) {
          return false;
        }
      } else if (key === 'crossoverDate') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.crossoverDate)) {
          return false;
        }
      } else if (key === 'arm2') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.arm2HhhId)) {
          return false;
        }
      } else if (key === 'crossoverPhase') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.crossoverPhase)) {
          return false;
        }
      } else if (key === 'crossoverEscalationExtension') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.crossoverEscalationExtension)) {
          return false;
        }
      } else if (key === 'inclusionArm2') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.inclusionArm2HhhId)) {
          return false;
        }
      } else if (key === 'crossoverCohort') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.crossoverCohort)) {
          return false;
        }
      } else if (key === 'crossoverDose') {
        if (this.hasCrossoverDate && required && Util.isNullOrUndefinedOrEmpty(this.crossoverDose)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

export class InclusionInformationToExport {
  patientInternalIdentifier: string;
  patientName: string;
  patientBirthDate: string;
  calculatedState: string;
  hasManualState: string;
  trialSite: string;
  preSelectionTypesToExport: string;
  rcpValidationsToExport: string;
  preScreeningConsentSignatureDate: string;
  result: string;
  preScreenFailure: string;
  trialTreatmentEndDate: string;
  inclusionTrialEndDate: string;
  initialConsentSignatureDate: string;
  consentVersion: string;
  otherCstDate: string;
  referrerInvestigatorName: string;
  inclusionScreeningNumber: string;
  inclusionTrialEndingReason: string;
  inclusionNumber: string;
  arm: string;
  phase: string;
  escaladeExtension: string;
  cohorts: string;
  arm2: string;
  inclusionDate: string;
  inclusionRandomizationIdentifier: string;
  randomizationDate: string;
  trialTreatmentStartDate: string;
  screeningFailure: string;
  screeningFailureDate: string;
  screeningFailureCause: string;
  crossoverDate: string;
  initials: string;
  preScreeningDate: string;
  followupEndDate: string;
  pathology: string;
}

import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {DynamicFilesService} from '../../shared/services/dynamic-files.service';
import {Router} from '@angular/router';
import {HumanResourcesService} from '../services/human-resources.service';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';

@Component({
  selector: 'ih-human-resource-import',
  templateUrl: './rh-import.component.html',
  styleUrls: ['./rh-import.component.css']
})
export class RhImportComponent implements OnInit {

  @Output() static importEvent = new EventEmitter<boolean>();

  importRhDialogueVisible = false;
  file: File;
  host = new Properties().host;
  private rowSeparator = '\r\n';


  constructor(public dynamicFiles: DynamicFilesService,
              private hrService: HumanResourcesService,
              private translateService: InnohealthTranslateService,
              private router: Router,
              @Inject('LOCATION_TOKEN') private location: Location) {
    console.log(location);
  }

  public static display(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(RhImportComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  public static saveAsExcelFile(buffer: any, fileName: string): void {
    const excelMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const excelExtension = '.xlsx';
    const data: Blob = new Blob([buffer], {type: excelMimeType});
    saveAs(data, fileName + '_export_' + new Date().getDate() + excelExtension);
  }

  ngOnInit() {
  }

  importRhs() {
    this.hrService.importRhs(this.file).subscribe(res => {
      this.importRhDialogueVisible = false;
      RhImportComponent.importEvent.emit(true);
      let headers: string[];

      if (this.dynamicFiles.importRhsFileName === 'import_humanResources_ght_template.xlsx') {
        headers = [
          'Ressource humaine',
          'Civilité',
          'Nom',
          'Prénom(s)',
          'Etablissement',
          'Service Opérationnel',
          'Responsabilités',
          'Spécialité',
          'Date d\'entrée',
          'Date de sortie',
          'RPPS',
          'N° CNOM',
          'Téléphone',
          'Fax',
          'Email'
        ];
      } else {
        headers = [
          'Ressource humaine',
          'Civilité',
          'Nom',
          'Prénom(s)',
          'Etablissement',
          'Service',
          'Responsabilités',
          'Spécialité',
          'Date d\'entrée',
          'Date de sortie'
        ];
      }

      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.constructData(res));

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: range.s.r };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
        if (!worksheet[cellRef]) continue;
        worksheet[cellRef].v = headers[C];
      }

      worksheet['!cols'] = [
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 },
        { wpx: 200 }
      ];

      const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      RhImportComponent.saveAsExcelFile(excelBuffer, 'Rapport_import_Rhs');

      this.router.navigate(['']).then(() => {
        this.router.navigate(['/human-resource-list']);
      });
    });
  }

  constructData(allOperationDetails: { [key: number]: { [key: string]: string } }) {
    const data: object[] = [];

    for (const key in allOperationDetails) {
      if (Object.prototype.hasOwnProperty.call(allOperationDetails, key)) {
        const value = allOperationDetails[key];
        const object: any = {};
        if (this.dynamicFiles.importRhsFileName === 'import_humanResources_ght_template.xlsx') {
          object.overallStatus = value.overallStatus;
          object.civility = value.civility;
          object.lastname = value.lastname;
          object.firstname = value.firstname;
          object.establishment = value.establishment;
          object.operationalService = value.operationalService;
          object.responsibilities = value.responsibilities;
          object.specialities = value.specialities;
          object.entryDate = value.entryDate;
          object.releaseDate = value.releaseDate;
          object.rppsNumber = value.rppsNumber;
          object.cnomNumber = value.cnomNumber;
          object.phone = value.phone;
          object.fax = value.fax;
          object.email = value.email;
        } else {
          object.overallStatus = value.overallStatus;
          object.civility = value.civility;
          object.lastname = value.lastname;
          object.firstname = value.firstname;
          object.establishment = value.establishment;
          object.departments = value.departments;
          object.responsibilities = value.responsibilities;
          object.specialities = value.specialities;
          object.entryDate = value.entryDate;
          object.releaseDate = value.releaseDate;
        }

        data.push(object);
      }
    }
    return data;
  }

  downloadModal() {
    location.assign(this.host + '/' + this.dynamicFiles.importRhsFileName);
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }

}

import {DynamicFormDefinitionField} from './dynamic-form-definition-field';
import {JsonObject, JsonProperty} from 'json2typescript';
import {DynamicFormDefinitionTitle} from './dynamic-form-definition-title';
import {DynamicFormDefinitionFieldset} from './dynamic-form-definition-fieldset';
import {Util} from '../../helpers/util';

@JsonObject('DynamicFormDefinition')
export class DynamicFormDefinition {

  @JsonProperty('definition', String)
  definition: string = undefined;

  @JsonProperty('defaultTitle', DynamicFormDefinitionTitle)
  defaultTitle: DynamicFormDefinitionTitle = new DynamicFormDefinitionTitle();

  @JsonProperty('hasFieldset', Boolean)
  hasFieldset: boolean = undefined;

  @JsonProperty('hasGroup', Boolean)
  hasGroup: boolean = undefined;

  @JsonProperty('fields', [DynamicFormDefinitionField])
  fields: DynamicFormDefinitionField[] = [];

  buildData(): DynamicFormDefinitionFieldset[] {
    let fieldset: DynamicFormDefinitionFieldset[] = [];
    this.fields.forEach(f => {
      if (f.type === 'fieldset') {
        fieldset.push(new DynamicFormDefinitionFieldset(f));
      }
    });
    fieldset = fieldset.sort((a, b) => a.parent.hhhId - b.parent.hhhId);
    fieldset.unshift(new DynamicFormDefinitionFieldset(new DynamicFormDefinitionField('Non Attachés')));
    this.fields.forEach(f => {
      if (f.type !== 'fieldset') {
        if (Util.isStringNullOrEmpty(f.config.attachedTo)) {
          fieldset[0].children.push(f);
        } else {
          const index = fieldset.findIndex(parent => parent.parent.field === f.config.attachedTo);
          if (index !== -1) {
            fieldset[index].children.push(f);
          }
        }
      }
    });
    fieldset.forEach(f => f.children = this.sortFields(f.children));
    return fieldset;
  }

  sortFields(fields: DynamicFormDefinitionField[]): DynamicFormDefinitionField[] {
    fields = fields.sort((f1, f2) => {
      if (!f1.config.display) {
        return 1;
      }
      if (!f2.config.display) {
        return -1;
      }
      return f2.config.order - f1.config.order;
    });
    fields = fields.sort((f1, f2) => {
      if (f1.config.display && f2.config.display) {
        return f1.config.order - f2.config.order;
      }
      return 0;
    });
    return fields;
  }

  hasPostIt(): boolean {
    const hasPostIt = this.fields.find((f) => f.hasPostIt === true);
    return hasPostIt !== undefined;
  }
}

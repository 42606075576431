import {TableHeaderConfig} from './table-header-config';
import {TableButtonConfig} from './table-button-config';
import {DynamicTableHeader} from '../exported/dynamic-lazy-table/dynamic-table-header';


export class TableConfig {

  headers: Map<string, TableHeaderConfig>;
  buttons: Map<string, TableButtonConfig>;


  constructor(headers: Map<string, TableHeaderConfig>, buttons: Map<string, TableButtonConfig>) {
    this.headers = headers;
    this.buttons = buttons;
  }

  buildTable(availableHeaders: DynamicTableHeader[]): DynamicTableHeader[] {
    const headers: DynamicTableHeader[] = [];
    for (const header of availableHeaders) {
      if (this.displayHeader(header.field)) {
        headers.push(header.updateConfig(this.headers.get(header.field)));
      }
    }
    return headers.filter(header => header.display);
  }

  displayHeader(field: string): boolean {
    return this.headers.has(field) && this.headers.get(field).display;

  }

  getHeaderConfig(field: string): TableHeaderConfig {
    if (this.headers.has(field)) {
      return this.headers.get(field);
    }
  }

  displayBtn(btnId: string): boolean {
    return this.buttons.has(btnId) ? this.buttons.get(btnId).display : false;
  }

  getBtnClass(): string {
    if (!this.buttons) {
      return '';
    }
    let btnCount = 0;
    const btns = Array.from(this.buttons.values());
    btns.forEach(b => {
      if (b.display) {
        btnCount++;
      }
    });
    return btnCount <= 3 ? 'tab-col-3btn' : 'tab-col-4btn';
  }

  public hasHeader(header: string) {
    return this.headers.has(header);
  }

}

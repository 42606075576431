import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IsEstablishmentsDeletable} from '../../IsEstablishmentsDeletable';
import {Properties} from '../../helpers/properties';
import {LabelValue} from '../../shared/entity/label-value';
import { EstablishmentDepartment } from 'src/app/shared/entity/establishment-department';
import { YearRecord } from '../models/year-record';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentDepartmentService {
  properties: Properties = new Properties();
  host = this.properties.host + '/establishment-department';

  constructor(private httpClient: HttpClient) {
  }

  addEstablishmentDepartment(service: string, establishmentHhhId: number): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add/' + (establishmentHhhId || -1), service);
  }

  updateEstablishmentDepartment(service: EstablishmentDepartment): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update', service);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + `/delete/${hhhId}`);
  }

  isDeletable(hhhId: number): Observable<IsEstablishmentsDeletable> {
    return this.httpClient.get<IsEstablishmentsDeletable>(this.host + '/deletable/' + hhhId);
  }

  addYearRecord(newYearRecord: YearRecord): Observable<number> {
    return this.httpClient.post<number>(this.host + '/current-active-number-inpatient/add', newYearRecord);
  }

  updateYearRecord(newYearRecord: YearRecord): Observable<any> {
    return this.httpClient.put<any>(this.host + '/current-active-number-inpatient/update', newYearRecord);
  }

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {DataFolder} from '../entity/data-folder';
import {DataDocument} from '../entity/data-document';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {DataDocumentList} from '../entity/data-document-list';
import {DataDocumentRequestBuilder} from '../entity/data-document-request';
import {catchError, switchMap} from 'rxjs/operators';
import {Util} from '../../helpers/util';
import {DocumentViewer} from '../entity/document-viewer';


@Injectable({
  providedIn: 'root'
})
export class DataDocumentService {

  public host = new Properties().host + '/data-document';

  constructor(private httpClient: HttpClient) {
  }

  addFolder(folder: DataFolder): Observable<DataFolder> {
    return this.httpClient.post<DataFolder>(this.host + '/add-folder', folder);
  }

  uploadDocuments(attachedTo: AttachedToEntityEnum, attachedToId: number, files: FileList): Observable<DataDocument> {
    const formData: FormData = new FormData();
    for (let i = 0; i < files.length; i++) {
            formData.append('files', files.item(i));
    }
    formData.append('attachedTo', attachedTo);
    return this.httpClient.post<DataDocument>(this.host + '/upload-documents/' + attachedToId, formData, {
      reportProgress: true,
      responseType: 'json'
    });
  }

  loadList(attachedTo: AttachedToEntityEnum, attachedToId: number, root: any): Observable<DataDocumentList> {
    return this.httpClient.post<DataDocumentList>(this.host + '/load-list',
      new DataDocumentRequestBuilder()
        .setAttachedTo(attachedTo)
        .setAttachedToId(attachedToId)
        .setRoot(root)
        .build()
    );
  }

  moveDocument(attachedToId: number, documentId: number, targetFolder: number): Observable<void> {
    const formdata: FormData = new FormData();
    formdata.append('attachedToId', String(attachedToId));
    formdata.append('documentId', String(documentId));
    formdata.append('targetFolder', String(targetFolder));
    return this.httpClient.post<void>(this.host + '/move-document', formdata);
  }

  downloadDocument(uuid: string, filename?: string): Observable<Blob> {
    return this.httpClient.post<Blob>(this.host + '/download', uuid, { responseType: 'blob' as 'json' })
      .pipe(
        switchMap(blob => {
          Util.downloadFile(blob, filename);
          return of(blob);
        }),
        catchError(this.handleError)
      );
  }

  previewDocument(uuid: string, filename?: string): Observable<Blob> {
    return this.httpClient.post<Blob>(this.host + '/download', uuid, { responseType: 'blob' as 'json' })
      .pipe(
        switchMap(blob => {
          return of(blob);
        }),
        catchError(this.handleError)
      );
  }

  deleteDocument(dataDocumentId: number): Observable<void> {
    return this.httpClient.post<void>(this.host + '/delete-document', dataDocumentId)
      .pipe(catchError(this.handleError)
      );
  }

  deleteFolder(folderHhhId: number): Observable<void> {
    return this.httpClient.post<void>(this.host + '/delete-folder', folderHhhId)
      .pipe(catchError(this.handleError)
      );
  }

  countDocumentsByFolder(folderHhhId: number): Observable<number> {
    return this.httpClient.get<number>(this.host + '/count/' + folderHhhId)
      .pipe(catchError(this.handleError));
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    console.error('An error occurred', errorResponse.error);
    return throwError(errorResponse.error);
  }

  uploadDocument(attachedTo: AttachedToEntityEnum, attachedToId: number, file: File): Observable<DataDocument> {
    const formData: FormData = new FormData();
    formData.append('attachedTo', attachedTo);
    formData.append('file', file);
    return this.httpClient.post<DataDocument>(this.host + '/upload-document/' + attachedToId, formData);
  }

  loadTrialSchemaDocument(attachedTo: AttachedToEntityEnum.TrialSchema, attachedToId: number): Observable<DataDocument> {
      return this.httpClient.post<DataDocument>(this.host + '/load-trial-schema',
          new DataDocumentRequestBuilder()
              .setAttachedTo(attachedTo)
              .setAttachedToId(attachedToId)
              .build()
      );
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const minutes: string = ('00' + Math.floor(value / 60)).slice(-2);
    return minutes + ':' + ('00' + Math.floor(value - Number(minutes) * 60)).slice(-2);
  }

}

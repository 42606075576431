export class LabelValue {
  label: string;
  value: any;
  disabled?: boolean;
  selected?: boolean;

  constructor(label?: string, value?: any, disabled?: boolean, selected?: boolean) {
    this.label = label;
    this.value = value;
    this.disabled = disabled;
    this.selected = selected;
  }
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {EmailTokenDto} from '../models/email-token-dto';
import {User} from '../../admin/models/user';
import {JwtHelperService} from '@auth0/angular-jwt';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {AppData} from '../../helpers/app-data';
import {DynamicFilesService} from '../../shared/services/dynamic-files.service';
import Timer = NodeJS.Timer;


export const TOKEN = 'token';
export const AUTHENTICATED_USER = 'authenticatedUser';
export const AUTHENTICATED_USER_ROLE = 'authenticatedUserRole';
export const AUTHENTICATED_USER_ID = 'authenticatedUserID';
export const FIRST_NAME_CLAIM = 'authenticatedUserFirstName';
export const LAST_NAME_CLAIM = 'authenticatedUserLastName';
export const ACCOUNT_MANAGEMENT_READ_WRITE = 'AccountManagement_READ_WRITE';
export const ACCOUNT_MANAGEMENT_EXPORT = 'AccountManagement_EXPORT';
export const ESTABLISHMENT_READ_WRITE = 'Establishment_READ_WRITE';
export const CONFIGURATION_READ_WRITE = 'Configuration_READ_WRITE';
export const ESTABLISHMENT_EXPORT = 'Establishment_EXPORT';
export const TRIAL_READ_WRITE = 'Trial_READ_WRITE';
export const TRIAL_EXPORT = 'Trial_EXPORT';
export const PATIENT_READ_WRITE = 'Patient_READ_WRITE';
export const PATIENT_EXPORT = 'Patient_EXPORT';
export const PROVIDER_READ_WRITE = 'Provider_READ_WRITE';
export const PROVIDER_EXPORT = 'Provider_EXPORT';
export const SITE_READ_WRITE = 'Site_READ_WRITE';
export const SITE_EXPORT = 'Site_EXPORT';
export const HR_READ_WRITE = 'HR_READ_WRITE';
export const HR_EXPORT = 'HR_EXPORT';
export const ACT_CATEGORY_READ_WRITE = 'ActCategory_READ_WRITE';
export const ACT_CATEGORY_EXPORT = 'ActCategory_EXPORT';
export const ACT_GRID_READ_WRITE = 'ActGrid_READ_WRITE';
export const ACT_GRID_EXPORT = 'ActGrid_EXPORT';
export const FIXED_COST_READ_WRITE = 'FixedCost_READ_WRITE';
export const FIXED_COST_EXPORT = 'FixedCost_EXPORT';
export const INVOICE_READ_WRITE = 'Invoice_READ_WRITE';
export const INVOICE_EXPORT = 'Invoice_EXPORT';
export const REPORT_READ_WRITE = 'Report_READ_WRITE';
export const REPORT_EXPORT = 'Report_EXPORT';
export const AGENDA_READ_WRITE = 'Agenda_READ_WRITE';
export const AGENDA_EXPORT = 'Agenda_EXPORT';
export const FIXED_COST_CATEGORY_READ_WRITE = 'FixedCostCategory_READ_WRITE';
export const FIXED_COST_CATEGORY_EXPORT = 'FixedCostCategory_EXPORT';
export const RETROCESSION_INVOICE_READ_WRITE = 'RetroCessionInvoice_READ_WRITE';
export const RETROCESSION_INVOICE_EXPORT = 'RetroCessionInvoice_EXPORT';
export const STATIC_INVOICE_READ_WRITE = 'RetroCessionInvoice_READ_WRITE';
export const STATIC_INVOICE_EXPORT = 'RetroCessionInvoice_EXPORT';
export const FOLLOW_INVOICE_READ_WRITE = 'FollowInvoice_READ_WRITE';
export const FOLLOW_INVOICE_EXPORT = 'FollowInvoice_EXPORT';
export const COUNTER_PART_CATEGORY_READ_WRITE = 'CounterPartCategory_READ_WRITE';
export const COUNTER_PART_CATEGORY_EXPORT = 'CounterPartCategory_EXPORT';
export const COUNTER_PART_REFERENCE_READ_WRITE = 'CounterPartReference_READ_WRITE';
export const COUNTER_PART_REFERENCE_EXPORT = 'CounterPartReference_EXPORT';
export const OPERATIONAL_ACT_READ_WRITE = 'OperationalAct_READ_WRITE';
export const OPERATIONAL_ACT_EXPORT = 'OperationalAct_EXPORT';
export const USER_HIDDEN_FIELDS_ACCESS = 'UserHidden_Fields_access';
export const PHARMACY_READ_WRITE = 'Pharmacy_READ_WRITE';
export const PHARMACY_EXPORT = 'Pharmacy_EXPORT';
export const MOBILE_APPLICATION_READ_WRITE = 'MobileApplication_READ_WRITE';
export const WEBSOCKET_SESSION_ID = 'WEBSOCKET_SESSION_ID';
export const SESSION_ID = 'SESSION_ID';
export const CHANGED_PASSWORD = 'CHANGED_PASSWORD';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public properties: Properties = new Properties();
  public host = this.properties.host;
  public currentUser: Observable<any>;
  private readonly token;
  private payload;
  private currentUserSubject: BehaviorSubject<any>;
  private timerId: Timer;

  constructor(
      private httpClient: HttpClient,
      private dynamicConfigService: DynamicConfigService,
      private dynamicFilesService: DynamicFilesService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem(AUTHENTICATED_USER));
    this.currentUser = this.currentUserSubject.asObservable();
    this.token = localStorage.getItem(TOKEN);
    if (this.token) {
      this.payload = atob((this.token).split('.')[1]);
      AppData.socketId = JSON.parse(this.payload).WEBSOCKET_SESSION_ID;
    }
  }

  public isUserAuthenticated(): boolean {
    let token = localStorage.getItem(TOKEN);
    if (!token) {
      return false;
    }
    token = token.replace('Bearer ', '');
    const jwtHelper = new JwtHelperService();
    return !jwtHelper.isTokenExpired(token);
  }

  public isAccessTokenPresent(): boolean {
    return !!localStorage.getItem(TOKEN);
  }

  public refreshToken(): Observable<any> {
    return this.httpClient.post<any>(this.host + '/refresh-token', {}, { withCredentials: true } ).pipe(
      tap(data => {
        this.processTokenResult(data);
      }),
      catchError(error => {
        console.error('Error refreshing token:', error);
        this.logout().subscribe();
        return throwError('Failed to refresh token');
      })
    );
  }

  public get currentUserRole(): any {
    return localStorage.getItem(AUTHENTICATED_USER_ROLE);
  }

  public get currentUserId(): any {
    return localStorage.getItem(AUTHENTICATED_USER_ID);
  }
  public get currentUserLastName(): any {
    return localStorage.getItem(LAST_NAME_CLAIM);
  }
  public get currentUserFirstName(): any {
    return localStorage.getItem(FIRST_NAME_CLAIM);
  }

  authenticateUser(username, password) {
    username = username.trim();
    password=password.trim();
    return this.httpClient.post<any>(this.host + '/authentication', {
      username, password
    }, {withCredentials: true}).pipe(map(
      async (data) => {
          localStorage.setItem(AUTHENTICATED_USER, username);
          const result = this.processTokenResult(data);
          this.setRefreshJob();
          await this.dynamicConfigService.initApplication().then();
          await this.dynamicFilesService.initDynamicFilesProperties().then();
          return result;
        }
    ));
  }

  public setRefreshJob() {
    const ttl = this.calculateTokenRefreshInterval();
    if (ttl > 0) {
      this.timerId = setInterval(() => {
        this.refreshToken().subscribe(() => {});
      }, ttl);
    } else {
      console.log('Token has already expired. No refresh scheduled.');
    }
  }

  public calculateTokenRefreshInterval(): number {
    const token = localStorage.getItem(TOKEN);
    if (!token) {
      return;
    }
    const expirationDate = new JwtHelperService().getTokenExpirationDate(token);
    const now = new Date().getTime();
    const millisecondsLeft = expirationDate.getTime() - now - (4 * 60 * 1000); // schedule token refresh 4 minutes in advance
    return millisecondsLeft;
  }

  private processTokenResult(data) {
    const tokenData = atob((data.token).split('.')[1]);
    localStorage.setItem(TOKEN, `Bearer ${data.token}`);
    localStorage.setItem(AUTHENTICATED_USER_ID, JSON.parse(tokenData).ID_CLAIM);
    localStorage.setItem(FIRST_NAME_CLAIM, JSON.parse(tokenData).FIRST_NAME_CLAIM);
    localStorage.setItem(LAST_NAME_CLAIM, JSON.parse(tokenData).LAST_NAME_CLAIM);
    localStorage.setItem(AUTHENTICATED_USER_ROLE, JSON.parse(tokenData).ROLE_CLAIM);
    localStorage.setItem(ACCOUNT_MANAGEMENT_READ_WRITE, JSON.parse(tokenData).AccountManagement_READ_WRITE);
    localStorage.setItem(ACCOUNT_MANAGEMENT_EXPORT, JSON.parse(tokenData).AccountManagement_EXPORT);
    localStorage.setItem(ESTABLISHMENT_READ_WRITE, JSON.parse(tokenData).Establishment_READ_WRITE);
    localStorage.setItem(ESTABLISHMENT_EXPORT, JSON.parse(tokenData).Establishment_EXPORT);
    localStorage.setItem(TRIAL_READ_WRITE, JSON.parse(tokenData).Trial_READ_WRITE);
    localStorage.setItem(TRIAL_EXPORT, JSON.parse(tokenData).Trial_EXPORT);
    localStorage.setItem(PATIENT_READ_WRITE, JSON.parse(tokenData).Patient_READ_WRITE);
    localStorage.setItem(PATIENT_EXPORT, JSON.parse(tokenData).Patient_EXPORT);
    localStorage.setItem(PROVIDER_READ_WRITE, JSON.parse(tokenData).Provider_READ_WRITE);
    localStorage.setItem(PROVIDER_EXPORT, JSON.parse(tokenData).Provider_EXPORT);
    localStorage.setItem(SITE_READ_WRITE, JSON.parse(tokenData).Site_READ_WRITE);
    localStorage.setItem(SITE_EXPORT, JSON.parse(tokenData).Site_EXPORT);
    localStorage.setItem(HR_READ_WRITE, JSON.parse(tokenData).HR_READ_WRITE);
    localStorage.setItem(HR_EXPORT, JSON.parse(tokenData).HR_EXPORT);
    localStorage.setItem(ACT_CATEGORY_READ_WRITE, JSON.parse(tokenData).ActCategory_READ_WRITE);
    localStorage.setItem(ACT_CATEGORY_EXPORT, JSON.parse(tokenData).ActCategory_EXPORT);
    localStorage.setItem(ACT_GRID_READ_WRITE, JSON.parse(tokenData).ActGrid_READ_WRITE);
    localStorage.setItem(ACT_GRID_EXPORT, JSON.parse(tokenData).ActGrid_EXPORT);
    localStorage.setItem(FIXED_COST_READ_WRITE, JSON.parse(tokenData).FixedCost_READ_WRITE);
    localStorage.setItem(FIXED_COST_EXPORT, JSON.parse(tokenData).FixedCost_EXPORT);
    localStorage.setItem(INVOICE_READ_WRITE, JSON.parse(tokenData).Invoice_READ_WRITE);
    localStorage.setItem(INVOICE_EXPORT, JSON.parse(tokenData).Invoice_EXPORT);
    localStorage.setItem(REPORT_READ_WRITE, JSON.parse(tokenData).Report_READ_WRITE);
    localStorage.setItem(REPORT_EXPORT, JSON.parse(tokenData).Report_EXPORT);
    localStorage.setItem(AGENDA_READ_WRITE, JSON.parse(tokenData).Agenda_READ_WRITE);
    localStorage.setItem(AGENDA_EXPORT, JSON.parse(tokenData).Agenda_EXPORT);
    localStorage.setItem(FIXED_COST_CATEGORY_READ_WRITE, JSON.parse(tokenData).FixedCostCategory_READ_WRITE);
    localStorage.setItem(FIXED_COST_CATEGORY_EXPORT, JSON.parse(tokenData).FixedCostCategory_EXPORT);
    localStorage.setItem(RETROCESSION_INVOICE_READ_WRITE, JSON.parse(tokenData).RetroCessionInvoice_READ_WRITE);
    localStorage.setItem(RETROCESSION_INVOICE_EXPORT, JSON.parse(tokenData).RetroCessionInvoice_EXPORT);
    localStorage.setItem(STATIC_INVOICE_READ_WRITE, JSON.parse(tokenData).StaticInvoice_READ_WRITE);
    localStorage.setItem(STATIC_INVOICE_EXPORT, JSON.parse(tokenData).StaticInvoice_EXPORT);
    localStorage.setItem(FOLLOW_INVOICE_READ_WRITE, JSON.parse(tokenData).FollowInvoice_READ_WRITE);
    localStorage.setItem(FOLLOW_INVOICE_EXPORT, JSON.parse(tokenData).FollowInvoice_EXPORT);
    localStorage.setItem(COUNTER_PART_CATEGORY_READ_WRITE, JSON.parse(tokenData).CounterPartCategory_READ_WRITE);
    localStorage.setItem(COUNTER_PART_CATEGORY_EXPORT, JSON.parse(tokenData).CounterPartCategory_EXPORT);
    localStorage.setItem(COUNTER_PART_REFERENCE_READ_WRITE, JSON.parse(tokenData).CounterPartReference_READ_WRITE);
    localStorage.setItem(COUNTER_PART_REFERENCE_EXPORT, JSON.parse(tokenData).CounterPartReference_EXPORT);
    localStorage.setItem(OPERATIONAL_ACT_READ_WRITE, JSON.parse(tokenData).OperationalAct_READ_WRITE);
    localStorage.setItem(OPERATIONAL_ACT_EXPORT, JSON.parse(tokenData).OperationalAct_EXPORT);
    localStorage.setItem(USER_HIDDEN_FIELDS_ACCESS, JSON.parse(tokenData).UserHidden_Fields_access);
    localStorage.setItem(PHARMACY_READ_WRITE, JSON.parse(tokenData).Pharmacy_READ_WRITE);
    localStorage.setItem(PHARMACY_EXPORT, JSON.parse(tokenData).Pharmacy_EXPORT);
    localStorage.setItem(MOBILE_APPLICATION_READ_WRITE, JSON.parse(tokenData).MobileApplication_READ_WRITE);
    localStorage.setItem(WEBSOCKET_SESSION_ID, JSON.parse(tokenData).WEBSOCKET_SESSION_ID);
    localStorage.setItem(SESSION_ID, JSON.parse(tokenData).SESSION_ID);
    localStorage.setItem(CHANGED_PASSWORD, JSON.parse(tokenData).CHANGED_PASSWORD)
    AppData.socketId = JSON.parse(tokenData).WEBSOCKET_SESSION_ID;
    this.currentUserSubject.next(data);
    return data;
  }

  forgetPassword(email: string): Observable<boolean> {
    return this.httpClient.post<boolean>(this.host + '/user/check-email', email);
  }
  isUserActivated(email: string): Observable<boolean> {
    return this.httpClient.post<boolean>(this.host + '/user/check-email-active', email);
  }

  reinitializePassword(email: string, code: string): Observable<boolean> {
    return this.httpClient.post<boolean>(this.host + '/user/reset-password', new EmailTokenDto(email, code));
  }

  updatePassword(user: User): Observable<boolean> {
    return this.httpClient.post<boolean>(this.host + '/user/update-password', user);
  }

  logout(): Observable<any> {

    if (!localStorage.getItem('authenticatedUser')) {
      this.clearLocalStorage();
      clearInterval(this.timerId);
      this.currentUserSubject.next(null);
      return of(void 0);
    }
    return this.httpClient.get(this.host + '/logout/').pipe(
      catchError(error => {
        return of(void 0); // Return an empty observable to complete the stream
      }),
      finalize(() => {
        this.clearLocalStorage();
        clearInterval(this.timerId);
        this.currentUserSubject.next(null);
      })
    );
  }


  clearLocalStorage(): void {
    const obj = {...localStorage};
    const keysToRemove = [];
    for (const prop in obj) {
      if (!prop.includes('|')) {
        keysToRemove.push(prop);
      }
    }
    for (const key of keysToRemove) {
      localStorage.removeItem(key);
    }
  }
}

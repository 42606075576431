import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {HomeGlobalFilter} from '../models/home-global-filter';
import {Observable} from 'rxjs';
import {ClinicalResearchActivity} from '../models/clinical-research-activity';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public host = new Properties().host + '/home';

  constructor(private httpClient: HttpClient) {
  }

  getHomeInfo(homeGlobalFilter: HomeGlobalFilter): Observable<ClinicalResearchActivity> {
    return this.httpClient.post<ClinicalResearchActivity>(this.host + '/clinical-research-activity', homeGlobalFilter);
  }

}

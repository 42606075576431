export class OtherAmendment {
  hhhId: number;
  version: string;
  amendmentSignatureDate: Date;
  protocolVersionHhhId: number;
  trialHhhId: number;
  trialSiteLiaisonHhhId: number;
  index: number;
  motifAmendement: string;
}

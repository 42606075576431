import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TrialCounterpart} from '../entity/trial-additionnal-costs/trial-counterpart';
import {Properties} from '../../helpers/properties';
import {IsDeletable} from '../../is-deletable';
import {VisitAdditionalCost} from '../entity/trial-additionnal-costs/visit-additional-cost';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class TrialCounterpartService {

  host = new Properties().host + '/trial-counterpart';
  trialHhhId: number;
  trialCounterPartList: TrialCounterpart[] = [];
  localIdentifier: string;
  promoter1: string;
  protocolNumber: string;

  // Refactored By souhail
  constructor(private httpClient: HttpClient) {
  }

  loadList(trialHhhId: number): Observable<TrialCounterpart[]> {
    return this.httpClient.get<TrialCounterpart[]>(this.host + '/load-list/' + trialHhhId);
  }

  getAttachedTrialCounterParts(trialHhhId: number): Observable<VisitAdditionalCost[]> {
    return this.httpClient.get<VisitAdditionalCost[]>(this.host + '/attached/' + trialHhhId);
  }

  editTrialCounterPart(trialCounterPart: TrialCounterpart): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update', trialCounterPart);
  }

  addCounterPartToTrial(trialHhhId: number, counterPartHhhIds: number[]) {
    return this.httpClient.get(this.host + '/add/' + trialHhhId + '/' + counterPartHhhIds);
  }

  isDeletable(hhhIds: number[]): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhIds);
  }

  delete(hhhIds: number[]) {
    return this.httpClient.get(this.host + '/delete/' + hhhIds);
  }

  setIdentificationInformation(localIdentifier, promoter1, protocolNumber) {
    this.localIdentifier = localIdentifier;
    this.promoter1 = promoter1;
    this.protocolNumber = protocolNumber;
  }

  exportTrialCounterPart(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

}

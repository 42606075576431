import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'app-no-selected-item-form',
  templateUrl: './no-selected-item-form.component.html',
  styleUrls: ['./no-selected-item-form.component.css']
})
export class NoSelectedItemFormComponent implements OnInit {


  public displayNoSelectedAct: boolean;
  public header: string;
  text = 'MODULE_REFERENCE_ACTS_DIALOG_ACTS_MUST_BE_SELECTED';

  constructor() {
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(NoSelectedItemFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.displayNoSelectedAct = true;
  }

}

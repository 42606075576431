import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {TrialHumanResource} from '../../trial/models/trial-human-resource';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrialPersonalInformationService {

  public trialSiteHhhId: number;
  host = new Properties().host;

  private isSubmitted: Subject<boolean> = new BehaviorSubject<boolean>(null);
  submitted = this.isSubmitted.asObservable();
  private trialIdentificationInfo: Subject<TrialHumanResource> = new BehaviorSubject<TrialHumanResource>(null);
  trialIdentificationInfoValue = this.trialIdentificationInfo.asObservable();

  constructor(private httpClient: HttpClient) {
  }

  setTrialSiteLiaisonHhhId(trialSiteLiaisonHhhId: number) {
    this.trialSiteHhhId = trialSiteLiaisonHhhId;
  }

  setTrialIdentificationInfo(value) {
    this.trialIdentificationInfo.next(value);
  }

  changeSubmit(isSubmit: boolean) {
    this.isSubmitted.next(isSubmit);
  }

}

import {NgModule} from '@angular/core';
import {AdditionalCostsRoutingModule} from './additional-costs-routing.module';
import {ActsCategoriesComponent} from './acts-categories/acts-categories.component';
import {FixedCostCategoriesComponent} from './fixed-cost-categories/fixed-cost-categories.component';
import {FixedCostReferenceComponent} from './fixed-cost-reference/fixed-cost-reference.component';
import {AdditionalCostsContainerComponent} from './additional-costs-container/additional-costs-container.component';
import {FixedCostCategoryFormComponent} from './fixed-cost-category-form/fixed-cost-category-form.component';
import {FixedCostReferenceFormComponent} from './fixed-cost-reference-form/fixed-cost-reference-form.component';
import {SharedModule} from '../shared/shared.module';
import {CounterPartCategoryFormComponent} from './counter-part-category-form/counter-part-category-form.component';
import {CounterPartReferenceFormComponent} from './counter-part-reference-form/counter-part-reference-form.component';
import {OperationalActsComponent} from './operational-acts/operational-acts.component';
import {OperationalActFormComponent} from './operational-act-form/operational-act-form.component';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';
import {ReferenceActsComponent} from './reference-acts/reference-acts.component';
import {ActCategoryFormComponent} from './act-category-form/act-category-form.component';
import {ReferenceActFormComponent} from './reference-act-form/reference-act-form.component';
import {CounterPartReferenceComponent} from './counter-part-reference/counter-part-reference.component';

@NgModule({
  imports: [
    AdditionalCostsRoutingModule,
    SharedModule,
    DynamicConfigModule
  ],
  declarations: [
    FixedCostReferenceComponent,
    FixedCostReferenceFormComponent,
    ActsCategoriesComponent,
    ActCategoryFormComponent,
    FixedCostCategoriesComponent,
    FixedCostCategoryFormComponent,
    ReferenceActsComponent,
    ReferenceActFormComponent,
    AdditionalCostsContainerComponent,
    CounterPartCategoryFormComponent,
    OperationalActsComponent,
    OperationalActFormComponent,
    CounterPartReferenceComponent
  ],
  exports: [
    ReferenceActsComponent
  ],
  entryComponents: [
    FixedCostCategoryFormComponent,
    ReferenceActFormComponent,
    FixedCostReferenceFormComponent,
    CounterPartCategoryFormComponent,
    CounterPartReferenceFormComponent,
    OperationalActFormComponent,
    ActCategoryFormComponent,
    CounterPartReferenceComponent
  ]

})
export class AdditionalCostsModule {
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import * as _ from 'lodash';
import {isArray, isBoolean, isNull, isNullOrUndefined, isObject} from 'util';


@Injectable()
export class DateInterceptor implements HttpInterceptor {

  // serverMinUTCOffset = 60;


  constructor() {
  }

  // function which will be called for all http calls
  intercept(request: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.body instanceof FormData === false) {
      const reqBody = _.cloneDeep(request.body);
      this.checkDatesInObjectFields(reqBody, 'request');
      let params: HttpParams = request.params;
      params = this.checkDateInParams(params);
      request = request.clone({params: params});
      request = request.clone({body: reqBody});
    }

    return next.handle(request).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        const resBody = event.body;
        this.checkDatesInObjectFields(resBody, 'response');
        event = event.clone({body: resBody});
      }
      return event;
    }));
  }

  private checkDatesInArrayFields(object, type) {
    object.map((entry) => {
      if (isObject(entry)) {
        this.checkDatesInObjectFields(entry, type);
      }
    });
  }

  toUTC(datetime) {
    const myDate = (typeof datetime === 'number')
        ? new Date(datetime)
        : datetime;

    if (!myDate || (typeof myDate.getTime !== 'function')) {
      return 0;
    }
    return myDate.getTime();

  }

  private checkDateInParams(params) {
    if (params.has('date')) {
      const date: Date = new Date(params.get('date'));
      params = params.set('date', date.getTime().toString());
    }
    return params;
  }

  private searchString(fieldName: string, searchedString: string): boolean {
    return fieldName.substr(fieldName.length - searchedString.length, searchedString.length) === searchedString;
  }

  fromUTC(datetime) {
    const myDate = (typeof datetime === 'number')
        ? new Date(datetime)
        : datetime;

    if (!myDate || (typeof myDate.getTime !== 'function')) {
      return 0;
    }
    return myDate.getTime();
  }

  private checkDatesInObjectFields(object, type) {
    for (const i in object) {
      if (isArray(object[i])) {
        this.checkDatesInArrayFields(object[i], type);
      }
      if (isObject(object[i]) && !(object[i] instanceof File)) {
        this.checkDatesInObjectFields(object[i], type);
      }
      if ((this.searchString(i, 'Date') || this.searchString(i, 'date'))
          && !isNaN(object[i]) && !isNull(object[i])
          && !isBoolean(object[i]) && type === 'response') {
        if (!isNullOrUndefined(object[i]) && object[i] !== '') {
          object[i] = new Date(this.fromUTC(object[i]));
        }
      }
      if ((this.searchString(i, 'Date') || this.searchString(i, 'date'))
          && (object[i] instanceof Date) && type === 'request'
          && (new Date(object[i].getTime()).getFullYear())) {
        if (!isNullOrUndefined(object[i]) && object[i] !== '') {
          object[i] = new Date(this.toUTC(object[i]));
        }
      }
    }
  }

}

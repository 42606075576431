import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../shared/services/data-service';
import {Router} from '@angular/router';
import {InvoiceCounterPartService} from './services/invoice-counter-part.service';
import {AdditionalCostState} from '../../additional-cost-state.enum';
import {InvoiceFixedCost} from '../models/invoice-fixed-cost';

@Component({
  selector: 'ih-invoice-counter-parts',
  templateUrl: './invoice-counter-parts.component.html',
  styleUrls: ['./invoice-counter-parts.component.css']
})
export class InvoiceCounterPartsComponent implements OnInit, OnDestroy {
  public invoiceHhhId: number;
  refreshInvoiced: InvoiceFixedCost[] = [];
  refreshPending: InvoiceFixedCost[] = [];

  constructor(
    private dataService: DataService,
    private invoiceCounterPartService: InvoiceCounterPartService,
    private router: Router
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.invoiceHhhId = url.queryParams.invoiceHhhId;
    }
  }

  get additionalCostState() {
    return AdditionalCostState;
  }

  ngOnInit() {
    this.dataService.setExportPageModule('src/app/invoice/invoice-counter-parts/invoice-counter-parts.component');
    this.invoiceCounterPartService.setInvoiceId(this.invoiceHhhId);
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  refresh(obj) {
    if (obj.list.length > 0) {
      if (obj.state === AdditionalCostState.Pending) {
        this.refreshInvoiced = obj.list;
      } else {
        this.refreshPending = obj.list;
      }
    }
  }
}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TrialService} from '../../../services/trial.service';
import {FR} from '../../../../shared/entity/calendar-language';
import {SharedService} from '../../../../shared/services/shared.service';
import {DataService} from '../../../../shared/services/data-service';
import {Router} from '@angular/router';
import {SelectItem} from 'primeng/api';
import {LabelValue} from '../../../../shared/entity/label-value';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {TrialHead} from '../../../models/trial-head';
import {TrialAdministrativeFeasibilityService} from '../../../services/trial-administrative-feasibility.service';
import {TrialAdministrativeFeasibilityInformation} from '../../../models/trial-administrative-feasibility-information';
import {Data} from '../../../../shared/entity/data';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {AppData} from '../../../../helpers/app-data';
import {TrialAdministrativeInformationService} from '../../../services/trial-administrative-information.service';
import {CommitteeDecisionService} from '../../../services/committee-decision.service';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';

@Component({
  selector: 'ih-trial-administrative-feasibility-information',
  templateUrl: './trial-administrative-feasibility-information.component.html',
  styleUrls: ['./trial-administrative-feasibility-information.component.css']
})
export class TrialAdministrativeFeasibilityInformationComponent implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_FEASIBILITY_INFORMATION_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  public feasibility: TrialAdministrativeFeasibilityInformation = new TrialAdministrativeFeasibilityInformation();
  public trialId: number;
  public committeeDecisions: LabelValue[];
  public locale = FR;
  public trialSiteHhhId: number;
  public priorityOptions: SelectItem[];
  public years: SelectItem[] = [];
  public showDitepDrcFields: boolean;
  trialHead: TrialHead = new TrialHead(null);
  noYesPendingOptions: SelectItem[] = Data.yesOrNoOrNoPending;

  noOrYesOrNeither = Data.yesOrNoOrNeither;
  noOrYesOrNaOrNeither = Data.yesOrNoOrNaOrNeither;

  public noYesOptions = Data.FeasibilityRetainedOotions;
  public feasibilityRetainedOptions = Data.FeasibilityRetainedOptions;
  public noOrYesOptions = Data.yesOrNoOrNa;


  constructor(
    public trialAdministrativeFeasibilityService: TrialAdministrativeFeasibilityService,
    public trialService: TrialService,
    private dataService: DataService,
    private sharedService: SharedService,
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private committeeDecisionService: CommitteeDecisionService,
    private trialAdministrativeInformationService: TrialAdministrativeInformationService,
    private dynamicConfigService: DynamicConfigService,
    private canDeactivateService: CanDeactivateService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
      this.trialSiteHhhId = url.queryParams.trialSiteLiaisonHhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    this.getPriority();
    this.dataService.setExportPageModule('src/app/trial/trial-administrative-information');
    this.trialAdministrativeFeasibilityService.trialHhhId = this.trialId;
    this.getCommitteeDecisions();
    this.getAdministrativeInformation();
    this.getTrialHead();
    this.getYears();

    this.showDitepDrcFields = false;
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  displayField = (fieldName: string) => this.config.displayField(fieldName);

  getYears() {
    this.years.push({label: ''.toString(), value: ''});
    for (let i = 1940; i < 2050; i += 1) {
      this.years.push({label: i.toString(), value: i});
    }
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }
  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.feasibility.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialAdministrativeFeasibility, this.feasibility.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.feasibility.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  getCommitteeDecisions() {
    this.committeeDecisionService.getCommitteeDecisions().subscribe(res => {
        this.committeeDecisions = res;
        this.committeeDecisions.splice(0, 0, null);
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading committee decision list.' + error);
      }
    );
  }

  updateAdministrativeInformation() {
    if (!this.feasibility.isValid(this.config, this.showDitepDrcFields)) {
      return;
    }
    this.feasibility.trialHhhId = this.trialId;
    this.feasibility.trialSiteHhhId = (this.trialSiteHhhId || 0);
    this.trialAdministrativeFeasibilityService.updateAdministrativeFeasibilityInformation(this.feasibility).subscribe(() => {
      this.displayDitepDrcFields(this.feasibility);
      this.sharedService.showSuccess();
      this.sharedService.setInformationInLocalStorage('Études', 'Éditer', this.trialHead.localIdentifier);
      this.canDeactivateService.canBeDeactivated = true;
      // this.trialService.addTrialAdministrative(this.trialAdministrativeFeasibilityInformation);
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while updating administrative information.' + error);
    });
  }

  getAdministrativeInformation() {
    this.trialAdministrativeFeasibilityService.getAdministrativeFeasibilityInformation(this.trialId, this.trialSiteHhhId).subscribe(
      res => {
        this.displayDitepDrcFields(res);
        this.feasibility = res;
      });
  }

  displayDitepDrcFields(trial: TrialAdministrativeFeasibilityInformation) {
    if (trial.coordoPrcDate && trial.coordoPrcDecision && trial.coordoPrcDecision.label === 'Accepté'
      || trial.csetDate && trial.csetDecision && trial.csetDecision.label === 'Accepté'
      || trial.cs2bDate && trial.cs2bDecision && trial.cs2bDecision.label === 'Accepté') {
      this.showDitepDrcFields = true;
    }
  }

  getPriority() {
    this.priorityOptions = [
      {label: '', value: null},
      {label: '1', value: 1},
      {label: '2', value: 2},
      {label: '3', value: 3},
      {label: '4', value: 4},
      {label: '5', value: 5}];
  }

  decisionChanged(decision: LabelValue) {

    this.showDitepDrcFields = decision && decision.label === 'Accepté';
    this.canDeactivateService.canBeDeactivated = false;
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  getTrialHead() {
    this.trialService.getTrialHead(this.trialId).subscribe(trialHead => {
      this.trialHead = trialHead;
    });
  }


  export(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.trialId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .build();
    if (this.trialSiteHhhId) {
      this.trialAdministrativeFeasibilityService.exportedAdministrativeFeasibility(request, this.trialSiteHhhId).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
    }
  }

}

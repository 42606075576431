import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {TrialOperationalActService} from '../../../service/trial-operational-act.service';
import {TrialOperationalAct} from '../../../entity/trial-additionnal-costs/trial-operational-act';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {DataService} from '../../../../shared/services/data-service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {DetailedTrialAct} from '../../../entity/trial-additionnal-costs/detailed-trial-act';
import {OperationalTrialActFormComponent} from '../operational-trial-act-form/operational-trial-act-form.component';
import {DeleteMultipleComponent} from '../../../../modals/delete-multiple/delete-multiple.component';
import {TrialActService} from '../../../service/trial-act.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {ReferenceAct} from '../../../../additional-costs/models/reference-act';
import {OperationalActEditDto} from '../../../entity/trial-additionnal-costs/operational-act-edit-dto';
import {TrialFixedCostService} from '../../../service/trial-fixed-cost.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {AdditionalCostsAppData} from '../../../../additional-costs/additional-costs-app-data';
import {
  DynamicTableHeaderBuilder
} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {OperationalAdditionalCost} from '../../../../additional-costs/models/operational-additional-cost';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {NgForm} from '@angular/forms';
import {ReferenceFixedCostService} from '../../../../additional-costs/services/reference-fixed-cost.service';
import {Util} from '../../../../helpers/util';
import {DataItemService} from '../../../../custom-field/service/data-item.service';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';


@Component({
  selector: 'ih-operational-trial-acts',
  templateUrl: './operational-trial-acts.component.html',
  styleUrls: ['./operational-trial-acts.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class OperationalTrialActsComponent extends PaginatorTableComponent<DetailedTrialAct> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.TrialAct;
  formTarget = DynamicDefinitionEnum.ADDITIONAL_COSTS_OPERATIONAL_ACTS_ADD_FORM;
  operationalTrialActsTableTarget = DynamicDefinitionEnum.ADDITIONAL_COSTS_OPERATIONAL_TRIAL_ACTS_LIST_TABLE;
  inclusionOperationalActsTableTarget = DynamicDefinitionEnum.ADDITIONAL_COSTS_INCLUSION_OPERATIONAL_ACTS_LIST_TABLE;
  operationalTrialActsTableConfigs: TableConfig;
  inclusionOperationalActsTableConfigs: TableConfig;
  operationalTrialActsHeaders: DynamicTableHeader[] = [];
  inclusionOperationalActsHeaders: DynamicTableHeader[] = [];
  formConfig = new FormConfig();
  submitted = false;

  @ViewChild('addForm') addForm: NgForm;

  contractTypeFilterList: SelectItem[] = [];
  typeFilterList: SelectItem[] = [];
  actCategoryFilterList: SelectItem[] = [];
  actDescriptionFilterList: SelectItem[] = [];
  externalFromCenterFilterList: SelectItem[] = [];
  invoiceableFilterList: SelectItem[] = [];
  onPrescriptionFilterList: SelectItem[] = [];

  trialHhhId: number;
  display = false;
  trialOperationalAct: TrialOperationalAct;
  totalNumber: number;

  referenceActCheckedList: DetailedTrialAct [] = [];
  trialActs: DetailedTrialAct[] = [];
  operationalTrialActEdit: OperationalActEditDto;

  noYesOptions: SelectItem[];
  displayNoSelectedReferenceActDialog: boolean;
  showTrialActs = true;
  fromTheoreticalVisit = false;
  callback: (trialOperationalAct: TrialOperationalAct) => void;

  contractTypes: SelectItem[] = [];
  additionalCostTypeItems: SelectItem[] = [];

  constructor(private trialOperationalActService: TrialOperationalActService,
              private trialActService: TrialActService,
              private cfr: ComponentFactoryResolver,
              private translateService: InnohealthTranslateService,
              private dataService: DataService,
              private router: Router,
              private trialFixedCostService: TrialFixedCostService,
              private translate: TranslateService,
              private sharedService: SharedService,
              private fixedCostReferenceService: ReferenceFixedCostService,
              private dataItemService: DataItemService,
              private dynamicConfigService: DynamicConfigService) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(OperationalTrialActsComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  async ngOnInit() {
    super.ngOnInit();
    this.contractTypes = await this.getContractType();
    this.additionalCostTypeItems = await this.getAdditionalCostTypeItems();
    this.initFormConfig();
    this.display = true;
    this.operationalTrialActEdit = new OperationalActEditDto();
    this.getTrialOperationalSubActs();
    this.initOperationalTrialActsTableConfig();
    this.translate.stream('UI_GLOBAL_PLACEHOLDER_SELECT_FROM_LIST').subscribe(() => {
          this.extractStatus();
        }
    );
    this.getTrialActs();
    this.initInclusionOperationalActsTableConfig();
  }

  initFormConfig() {
    this.dynamicConfigService.getFormConfig(this.formTarget, AdditionalCostsAppData.formConfig).subscribe(
        config => {
          this.formConfig = config;
        }
    );
  }

  getAdditionalCostTypeItems(): Promise<SelectItem[]> {
    return this.dataItemService.getItems('additional-cost-type').toPromise();
  }

  getContractType(): Promise<SelectItem[]> {
    return this.fixedCostReferenceService.getContractTypeList().toPromise();
  }

  getFieldConfig = (fieldName: string) => this.formConfig.getFieldConfig(fieldName);

  initOperationalTrialActsTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.operationalTrialActsTableTarget, AdditionalCostsAppData.tableConfig).subscribe(
        config => {
          this.operationalTrialActsTableConfigs = config;
          this.buildOperationalTrialActsTableHeaders();
        }
    );
  }

  buildOperationalTrialActsTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('contractType')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.contractTypes)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('actCategoryName')
        .displayContent((operationalTrialAct: DetailedTrialAct) => {
          return operationalTrialAct.actCategory;
        })
        .filter('actCategory')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actCategoryFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('description')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actDescriptionFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('invoiceable')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.invoiceableFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('internalAccountNumber')
        .withDisplayCssClassContent()
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('pricePerUnit')
        .withDisplayCssClassContent()
        .cssClass((() => {
          return 'right-align';
        }))
        .displayContent((data: OperationalAdditionalCost) => {
          return Number(data.pricePerUnit).toFixed(2) + ' €';
        })
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('numberOfUnits')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .cssClass((() => {
          return 'right-align';
        }))
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('totalPrice')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .cssClass((() => {
          return 'right-align';
        }))
        .displayContent((data: OperationalAdditionalCost) => {
          return Number(data.pricePerUnit).toFixed(2) + ' €';
        })
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('onPrescription')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.onPrescriptionFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('type')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .filter('typeHhhId')
        .options(this.typeFilterList)
        .sortable()
        .displayContent((data: DetailedTrialAct) => data.typeName)
        .build());
    this.operationalTrialActsHeaders = this.operationalTrialActsTableConfigs.buildTable(availableHeaders);
  }

  initInclusionOperationalActsTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.inclusionOperationalActsTableTarget, AdditionalCostsAppData.tableConfig).subscribe(
        config => {
          this.inclusionOperationalActsTableConfigs = config;
          this.buildInclusionOperationActsTableHeaders();
        }
    );
  }

  buildInclusionOperationActsTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('contractType')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.contractTypes)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('categoryName')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actCategoryFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('description')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actDescriptionFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('pricePerUnit')
        .withDisplayCssClassContent()
        .cssClass((() => {
          return 'right-align';
        }))
        .displayContent((data: OperationalAdditionalCost) => {
          return Number(data.pricePerUnit).toFixed(2) + ' €';
        })
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('type')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .filter('typeHhhId')
        .options(this.typeFilterList)
        .displayContent((operationalAdditionalCost: DetailedTrialAct) => operationalAdditionalCost.typeName)
        .sortable()
        .build());
    this.inclusionOperationalActsHeaders = this.inclusionOperationalActsTableConfigs.buildTable(availableHeaders);
    console.warn('operationalAdditionalCost');
    console.warn(this.typeFilterList);
  }

  getTrialActs() {
    this.trialActService.getTrialActList(this.trialHhhId).subscribe(res => {
      this.trialActs = res;
      this.trialFixedCostService.getAttachedTrialFixedCosts(this.trialHhhId).subscribe(res2 => {
        for (const trialFixedCost of res2) {
          this.trialActs.push(new DetailedTrialAct().buidFromFixedCost(trialFixedCost));
        }
        this.updateDropdownOptionsForTrialActs(this.trialActs);
      });
    });
  }

  getTrialOperationalSubActs() {
    this.trialOperationalActService.getTrialOperationalSubActList(this.trialOperationalAct.hhhId).subscribe(
        res => {
          this.values = res;
          this.totalNumber = this.values.length;
          this.updateDropdownOptionsForSubActs(res);
        });
  }

  updateDropdownOptionsForSubActs(event) {
    this.prepareActColumns(event);
  }

  updateDropdownOptionsForTrialActs(event) {
    this.prepareActColumns(event);
  }

  prepareActColumns(event) {
    for (const operationalAct of event) {
      const contractTypeItem = {label: operationalAct.contractType, value: operationalAct.contractTypeHhhId};
      const typeItem = {label: operationalAct.typeName, value: operationalAct.typeHhhId};
      const descItem = {label: operationalAct.description, value: operationalAct.description};
      const actCategoryItem = {label: operationalAct.actCategory, value: operationalAct.actCategory};
      const invoiceableItem = {
        label: operationalAct.invoiceable != null ? this.translateService.getTranslationString(operationalAct.invoiceable.toString()) : '',
        value: operationalAct.invoiceable
      };
      const externalFromCenterItem = {
        label: operationalAct.externalFromCentre != null ? this.translateService.getTranslationString(operationalAct.externalFromCentre.toString()) : '',
        value: operationalAct.externalFromCentre
      };
      const onPrescriptionItem = {
        label: operationalAct.onPrescription != null ? this.translateService.getTranslationString(operationalAct.onPrescription.toString()) : '',
        value: operationalAct.onPrescription
      };
      if (operationalAct.contractType != null &&
          this.contractTypeFilterList.findIndex((item: SelectItem) => item.label === contractTypeItem.label) === -1) {
        this.contractTypeFilterList.push(contractTypeItem);
      }
      if (operationalAct.typeName != null &&
          this.typeFilterList.findIndex((item: SelectItem) => item.label === typeItem.label) === -1) {
        this.typeFilterList.push(typeItem);
      }
      if (operationalAct.actCategory != null &&
          this.actCategoryFilterList.findIndex((item: SelectItem) => item.value === actCategoryItem.value) === -1) {
        this.actCategoryFilterList.push(actCategoryItem);
      }
      if (operationalAct.invoiceable != null &&
          this.invoiceableFilterList.findIndex((item: SelectItem) => item.value === invoiceableItem.value) === -1) {
        this.invoiceableFilterList.push(invoiceableItem);
      }
      if (operationalAct.description != null &&
          this.actDescriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
        this.actDescriptionFilterList.push(descItem);
      }
      if (operationalAct.externalFromCentre != null &&
          this.externalFromCenterFilterList.findIndex((item: SelectItem) => item.value === externalFromCenterItem.value) === -1) {
        this.externalFromCenterFilterList.push(externalFromCenterItem);
      }
      if (operationalAct.onPrescription != null &&
          this.onPrescriptionFilterList.findIndex((item: SelectItem) => item.value === onPrescriptionItem.value) === -1) {
        this.onPrescriptionFilterList.push(onPrescriptionItem);
      }
    }

    this.contractTypeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.typeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.actDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.actCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.externalFromCenterFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.invoiceableFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.onPrescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  extractStatus() {
    this.noYesOptions = [
      {label: '', value: null},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE'), value: true},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE'), value: false}
    ];
  }

  updateSelectedList(selectedList: any) {
    this.referenceActCheckedList = selectedList;
  }

  displayFormDialogue(detailedTrialAct: DetailedTrialAct) {
    const formComponent = OperationalTrialActFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.detailedTrialAct = new DetailedTrialAct().init(detailedTrialAct);
    formComponent.formHeader = this.getUpdateTitle();
    formComponent.onAdd((newDetailedTrialAct: DetailedTrialAct) => {
      const act = this.values.find(item => item.hhhId === newDetailedTrialAct.hhhId);
      if (!isNullOrUndefined(act)) {
        this.values[this.values.indexOf(act)] = newDetailedTrialAct;
      }
    });
  }

  openDeleteReferenceActDialog(data: DetailedTrialAct) {
    if (data.hhhId) {
      const trialActIdentifier = data.additionalCostType + '-' + data.hhhId;
      this.operationalTrialActEdit.subTrialActsToBeRemoved.push(trialActIdentifier);
      const actToDeleteFromSubTrialActsIndex = this.operationalTrialActEdit.subTrialActs.indexOf(trialActIdentifier);
      this.operationalTrialActEdit.subTrialActs.splice(actToDeleteFromSubTrialActsIndex, 1);
    } else {
      const referenceActIdentifier = data.additionalCostType + '-' + data.referenceActHhhId;
      const actToDeleteFromSubActsIndex = this.operationalTrialActEdit.subActs.indexOf(referenceActIdentifier);
      this.operationalTrialActEdit.subActs.splice(actToDeleteFromSubActsIndex, 1);
    }
    const actToDeleteIndex = this.values.indexOf(data);
    this.values.splice(actToDeleteIndex, 1);

  }

  displayDeleteMultiple() {
    const formComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    formComponent.modal = false;
    formComponent.service = this.trialActService;
    formComponent.selectedList = this.referenceActCheckedList;
    formComponent.dontDeleteWhenDeletable = true;
    formComponent.navigationRoute = '/trial-details/theoretical-calendar';
    formComponent.optionalNavigationRoute = 'trial-details/inclusion-details/calendar';
    formComponent.popupMessage = 'MODULE_REFERENCE_ACTS_DIALOG_WARNING_SELECTED_REFERENCE_ACT_WILL_BE_DELETED';
    formComponent.hhhId = this.trialHhhId;
    formComponent.onDelete((itemsToDelete: number[]) => {
      if (itemsToDelete && itemsToDelete.length !== 0) {
        this.trialOperationalActService.deleteOperationalSubActs(this.trialOperationalAct.hhhId, itemsToDelete).subscribe(res => {
          for (const hhhId of itemsToDelete) {
            const index = this.values.findIndex((trialAct) => trialAct.hhhId === hhhId);
            this.values.splice(index, 1);
          }
          this.sharedService.showSuccess();
        });
      }
    });
  }

  addSelectedAct(data: ReferenceAct) {
    if (this.referenceActAlreadyAdded(data)) {
      this.sharedService.showWarning('OPERATIONAL_ACT_ACT_ALREADY_ADDED');
      return;
    }
    const detailedTrialAct: DetailedTrialAct = new DetailedTrialAct();
    detailedTrialAct.actCategoryHhId = data.actCategoryHhhId;
    detailedTrialAct.typeHhhId = data.typeHhhId;
    detailedTrialAct.description = data.description;
    detailedTrialAct.referenceActHhhId = data.hhhId;
    detailedTrialAct.additionalCostType = data.additionalCostType;
    detailedTrialAct.contractType = data.contractType;
    detailedTrialAct.actCategory = data.actCategoryName;
    detailedTrialAct.invoiceable = data.invoiceable;
    detailedTrialAct.pricePerUnit = data.pricePerUnit;
    detailedTrialAct.internalAccountNumber = data.internalAccountNumber;
    detailedTrialAct.secondInternalAccountNumber = data.secondInternalAccountNumber;
    detailedTrialAct.firstSegment = data.firstSegment;
    detailedTrialAct.secondSegment = data.secondSegment;
    detailedTrialAct.freeSS = data.freeSS;
    detailedTrialAct.optional = data.optional;
    detailedTrialAct.editable = false;
    detailedTrialAct.isNew = true;
    this.values.push(detailedTrialAct);
    this.operationalTrialActEdit.subActs.push(data.additionalCostType + '-' + data.hhhId);
  }

  referenceActAlreadyAdded(act: ReferenceAct): boolean {
    return this.values.some(trialAct =>
        trialAct.description === act.description
        && trialAct.additionalCostType === act.additionalCostType);
  }

  saveOperationalTrialAct() {
    this.submitted = true;
    if (this.addForm.invalid || !this.trialOperationalAct.isValid(this.formConfig)) {
      return;
    }
    this.operationalTrialActEdit.hhhId = this.trialOperationalAct.hhhId;
    this.operationalTrialActEdit.description = this.trialOperationalAct.description;
    this.operationalTrialActEdit.rdvDescription = this.trialOperationalAct.rdvDescription;
    this.trialOperationalActService.updateTrialOperationalAct(this.operationalTrialActEdit).subscribe(() => {
      this.display = false;
      this.sharedService.showSuccess();
      this.callback(this.trialOperationalAct);
    }, error => {
      console.error(error);
    });
  }


  cancel() {
    this.display = false;
  }

  addTrialActToTrialOperationalAct(data: DetailedTrialAct) {
    if (this.trialActAlreadyAdded(data)) {
      this.sharedService.showWarning('OPERATIONAL_ACT_ACT_ALREADY_ADDED');
      return;
    }
    this.operationalTrialActEdit.subTrialActs.push(data.additionalCostType + '-' + data.hhhId);
    const actToAdd = Object.assign({}, data);
    actToAdd.isNew = true;
    this.values.push(actToAdd);
  }

  trialActAlreadyAdded(act: DetailedTrialAct): boolean {
    return this.values.some(trialAct =>
        trialAct.description === act.description
        && trialAct.additionalCostType === act.additionalCostType);
  }

  onUpdateCallback(callback: (operationalTrialAct: TrialOperationalAct) => void) {
    this.callback = callback;
  }


}

export class TrialInclusionGlobalFilter {
  public inclusionIdentifier1 = '';
  public arm = -1;
  public patientInternalIdentifier = '';
  public patientLastName = '';
  public patientFirstName = '';
  public patientBirthName = '';
  public state = -1;
  public siteHhhId = -1;
  public site = -1;
  public inclusionDate = '';
  public referrerInvestigator = -1;
  public preScreeningSignatoryDoctor = -1;
  public inclusionIdentifier2 = '';
  public patientSex = '';
  public patientBirthDate: Date;
  public preScreeningDate: Date;
  public inclusionPreScreeningIdentifier = '';
  public preScreeningConsentSignatureDate: Date;
  public organList: number[] = [];
  public preScreeningConsent: number;
  public caseManagement: number;
  public result = '';
  public preScreeningFailure: boolean;
  public preScreeningFailureDate: Date;
  public preScreeningFailureReason: string;
  public trialTreatmentEndReason: number;
  public inclusionTrialEndReason: number;
  public screeningFailure: boolean;
  public screenFailureDate: Date;
  public screeningFailureCause: number;
  public randomizationDate: Date;
  public trialTreatmentEndDate: Date;
  public lastRealizedVisitDate: Date;
  public inclusionRandomizationIdentifier: string;
  public trialTreatmentStartDate: Date;
  public hasCrossoverDate: boolean;
  public dispatchBlockDate: Date;
  public inclusionTrialEndDate: Date;
  public tecIncluded: string;

  TrialInclusionGlobalFilter() {
    this.inclusionIdentifier1 = '';
    this.arm = -1;
    this.patientInternalIdentifier = '';
    this.patientLastName = '';
    this.patientFirstName = '';
    this.patientBirthName = '';
    this.state = -1;
    this.siteHhhId = -1;
    this.inclusionDate = '';
    this.referrerInvestigator = -1;
    this.inclusionIdentifier2 = '';
    this.patientSex = '';
  }
}

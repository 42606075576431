import {Any, JsonObject, JsonProperty} from 'json2typescript';
import {ModuleHeaderConfig} from './module-header-config';

@JsonObject('ModuleConfig')
export class ModuleConfig {

  @JsonProperty('header', ModuleHeaderConfig)
  header: ModuleHeaderConfig = new ModuleHeaderConfig();

  @JsonProperty('properties', Any)
  properties: Map<string, any>;

  constructor(header?: ModuleHeaderConfig, properties?: Map<string, any>) {
    this.header = header;
    this.properties = properties;
  }


}

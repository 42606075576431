import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {Patient} from '../models/patient';
import {PatientGlobalFilter} from '../models/patient-global-filter';
import {ResponsePage} from '../../shared/entity/response-page';
import {IsDeletable} from '../../is-deletable';
import {Phone} from '../../shared/entity/phone';
import {Fax} from '../../shared/entity/fax';
import {Email} from '../../shared/entity/email';
import {Address} from '../../shared/entity/address';
import {ContactSet} from '../../shared/entity/contact-set';
import {InclusionInfo} from '../models/inclusion-info';
import {DataTransferService} from './data-transfer.service';
import {Value} from '../../shared/entity/value';
import {PATIENT_READ_WRITE} from '../../login/services/authentication.service';
import {Permission} from '../../helpers/permission';
import {catchError, map} from 'rxjs/operators';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {ExportTypesEnum} from '../../dynamic-config/exported/data-export/ExportTypesEnum';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  public host = new Properties().host + '/patient';
  public dataPage: ResponsePage<Patient>;
  public readOnlyMode = false;

  public sortBy = 'hhhId';
  public orderBy = 'asc';

  constructor(
    private httpClient: HttpClient,
    private dataTransferService: DataTransferService
  ) {
  }

  setPaginationConfiguration(dataPage: ResponsePage<Patient>, sortBy: string, orderBy: string) {
    this.sortBy = sortBy;
    this.orderBy = orderBy;
    this.dataPage = dataPage;
  }

  load(hhhId: number): Observable<Patient> {
    return this.httpClient.get<Patient>(this.host + '/load/' + hhhId)
      .pipe(
        map(res => {
            return (new Patient()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  loadList(page: number, limit: number, sortBy: string, orderBy: string, filters: PatientGlobalFilter): Observable<ResponsePage<Patient>> {
    return this.httpClient.post<ResponsePage<Patient>>(
      this.host + '/load-list',
      new RequestPageBuilder<PatientGlobalFilter>()
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(filters)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(patient => new Patient().init(patient));
          return result;
        } catch (e) {
          console.log(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  getPatientWithQuery(query: string, trialHhhId: number): Observable<PatientGlobalFilter[]> {
    return this.httpClient.get<PatientGlobalFilter[]>(this.host + '/search/' + query + '/' + trialHhhId);
  }

  getPatientInfo(hhhId: number): Observable<InclusionInfo[]> {
    return this.httpClient.get<InclusionInfo[]>(this.host + '/searchInclusions/' + hhhId)
      .pipe();
  }

  updatePatient(patient: Patient): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update', patient);
  }

  addPatient(patient: Patient): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', patient);
  }

  delete(hhhId: number) {
    this.dataTransferService.deletePatientById(hhhId);
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/is-deletable/' + hhhId);
  }

  addPhone(phone: Phone, hhhId: number): Observable<number> {
    return this.httpClient.post<number>(this.host + '/contact/phone/save/' + hhhId, phone);
  }

  getContactsOfPatient(hhhId: number): Observable<ContactSet> {
    return this.httpClient.get<ContactSet>(this.host + '/contact/' + hhhId);
  }

  deletePhone(hhhId: number) {
    return this.httpClient.get(this.host + '/contact/phone/delete/' + hhhId);
  }

  updatePhone(phone: Phone) {
    return this.httpClient.post(this.host + '/contact/phone/update', phone);
  }

  addFax(fax: Fax, hhhId: number): Observable<number> {
    return this.httpClient.post<number>(this.host + '/contact/fax/save/' + hhhId, fax);
  }

  deleteFax(hhhId: number) {
    return this.httpClient.get(this.host + '/contact/fax/delete/' + hhhId);
  }

  updateFax(fax: Fax) {
    return this.httpClient.post(this.host + '/contact/fax/update', fax);
  }

  addEmail(email: Email, hhhId: number): Observable<number> {
    return this.httpClient.post<number>(this.host + '/contact/email/save/' + hhhId, email);
  }

  deleteEmail(hhhId: number) {
    return this.httpClient.get(this.host + '/contact/email/delete/' + hhhId);
  }

  updateEmail(email: Email) {
    return this.httpClient.post(this.host + '/contact/email/update', email);
  }

  addAddress(address: Address, hhhId: number): Observable<number> {
    return this.httpClient.post<number>(this.host + '/contact/address/save/' + hhhId, address);
  }

  deleteAddress(hhhId: number) {
    return this.httpClient.get(this.host + '/contact/address/delete/' + hhhId);
  }

  updateAddress(address: Address) {
    return this.httpClient.post(this.host + '/contact/address/update', address);
  }

  getUrlForTotalExportOfPatientList(): string {
    return (this.host + '/csv/patients/list/All/');
  }

  checkNipExistence(internalIdentifier: string): Observable<Value<boolean>> {
    return this.httpClient.get<Value<boolean>>(this.host + '/check-nip/' + internalIdentifier);
  }

  setPatientReadonlyMode() {
    if (localStorage.getItem(PATIENT_READ_WRITE) !== Permission.READ_WRITE) {
      this.readOnlyMode = true;
    }
  }

  exportPatientList(request: ExportRequest<any>): Observable<void> {
    switch (request.exportType) {
      case ExportTypesEnum.FULL_EXPORT :
      case ExportTypesEnum.SINGLE_PAGE_EXPORT :
      case ExportTypesEnum.GLOBAL_SINGLE_PAGE_EXPORT :
      case ExportTypesEnum.GLOBAL_EXPORT :
        return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
      case ExportTypesEnum.CUSTOM_EXPORT :
        return this.httpClient.post<void>(this.host + '/export-csv/custom', request).pipe();
    }
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {PharmacyStock} from '../../../entity/trial-pharmacy/pharmacyStock';
import {NgForm} from '@angular/forms';
import {SelectItem} from 'primeng/api';
import {Data} from '../../../../shared/entity/data';
import {FR} from '../../../../shared/entity/calendar-language';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-pharmacy-stock-lot-form',
  templateUrl: './pharmacy-stock-lot-form.component.html',
  styleUrls: ['./pharmacy-stock-lot-form.component.css']
})
export class PharmacyStockLotFormComponent extends FormComponent<PharmacyStock> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_PHARMACY_STOCK_FORM;
  config: FormConfig = new FormConfig();

  constructor(private dynamicConfigService: DynamicConfigService) {
    super();
  }

  @ViewChild('pharmacyTabStockFormValidator') pharmacyTabStockFormValidator: NgForm;
  pharmacyStock = new PharmacyStock();
  states: SelectItem[] = Data.states;
  events: SelectItem[] = Data.events;
  locale = FR;
  pharmacyHhhId: number;
  showFieldWhenOther = false;

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(PharmacyStockLotFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  saveLot() {
    this.display = false;
    this.callback(this.pharmacyStock);
  }

  onChangeEventValue() {
    this.pharmacyStock.events === 'OTHER' ? this.showFieldWhenOther = true : this.showFieldWhenOther = false;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

}

import {TheoreticalVisit} from './theoretical-visit';
import {TheoreticalVisitInInclusion} from './theoretical-visit-in-inclusion';
import {Arm} from '../inclusion/arm';

export class TheoreticalCalendar {
  public hhhId: number;
  public theoreticalVisitBeforeInclusionList: TheoreticalVisit[];
  public theoreticalVisitAfterInclusionList: TheoreticalVisit[];
  public theoreticalVisitInInclusionDtoList: TheoreticalVisitInInclusion[];
  public amendment: string;
  public ivrs: boolean;
  public theoreticalVisitDtoList: any[];
  public theoreticalVisitToEditDtoList: any[];
  public theoreticalVisitActsToDeleteHhhIds: any[];
  public theoreticalVisitToDeleteDtoList: any[];
  public theoreticalVisitActsToEdit: any[];
  public theoreticalVisitActsToAdd: any[];
  public theoreticalVisitToCloneDtoList: any[];

  public trialActsToRemoveHhhIds: any[];
  public trialActsToEdit: any[];
  public armsToEdit: Arm[];
  public armsHhhIdsToRemove: number[];

  public operationalSubActsToRemove: any[];
  public trialProtocolHhhId: number;
  public trialProtocolVersion: string;
  public iwrs: boolean;

  constructor() {
    this.theoreticalVisitBeforeInclusionList = [];
    this.theoreticalVisitAfterInclusionList = [];
    this.theoreticalVisitInInclusionDtoList = [];
    this.theoreticalVisitDtoList = [];
    this.theoreticalVisitToEditDtoList = [];
    this.theoreticalVisitActsToDeleteHhhIds = [];
    this.theoreticalVisitToDeleteDtoList = [];
    this.theoreticalVisitActsToEdit = [];
    this.theoreticalVisitActsToAdd = [];
    this.theoreticalVisitToCloneDtoList = [];

    this.trialActsToRemoveHhhIds = [];
    this.trialActsToEdit = [];
    this.armsToEdit = [];
    this.armsHhhIdsToRemove = [];
  }
}

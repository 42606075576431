import {Component, ComponentFactoryResolver, HostListener, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TrialInformation} from '../../../../models/trial-information';
import {Data} from '../../../../../shared/entity/data';
import {TrialService} from '../../../../services/trial.service';
import {OrganService} from '../../../../services/organ.service';
import {SharedService} from '../../../../../shared/services/shared.service';
import {CanDeactivateService} from '../../../../../shared/services/can-deactivate.service';
import {MinimizedTrialProtocol} from '../../../../models/minimized-trial-protocol';
import {TrialProtocolService} from '../../../../services/trial-protocol.service';
import {TrialInclusionExclusionCriteriaService} from '../../../../services/trial-inclusion-exclusion-criteria.service';
import {Router} from '@angular/router';
import {TrialInclusionExclusionCriteria} from '../../../../models/trial-inclusion-exclusion-criteria';
import {ChooseProtocolVersionComponent} from '../../../modals/choose-protocol-version/choose-protocol-version.component';
import {TrialObservablesService} from '../../../../services/trial-observables.service';
import {SelectItem} from 'primeng/api';
import {InnohealthTranslateService} from '../../../../../shared/services/innohealth-translate.service';
import {TrialPageLockService} from '../../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {PropertyEnum} from '../../../../../dynamic-config/entity/property-enum';
import {TrialAppData} from '../../../../trial-app-data';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../dynamic-config/entity/form-config';
import {DataCommentInfo} from '../../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../../data-comment/entity/DataCommentEvent';

@Component({
  selector: 'ih-inclusion-exclusion-criteria',
  templateUrl: './inclusion-exclusion-criteria.component.html',
  styleUrls: ['./inclusion-exclusion-criteria.component.css']
})
export class InclusionExclusionCriteriaComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_INCLUSION_EXCLUSION_CRITERIA_FORM;
  config: FormConfig = new FormConfig();
  organFamilyEnabled = false;
  maxAgeEnabled = true;


  @Input() mode: string;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;

  public trial: TrialInformation = new TrialInformation();
  public organsList = [];
  maxSelectedLabels: number;
  trialId: number;
  inclusionExclusion: TrialInclusionExclusionCriteria = new TrialInclusionExclusionCriteria();
  sexList: SelectItem[] = [];
  yesOrNo = Data.yesOrNo;
  protocol: MinimizedTrialProtocol = new MinimizedTrialProtocol();
  usedProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  usedProtocolsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  allProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();

  showEmptyProtocolWarning = false;
  noYesOptions: SelectItem[] = Data.yesOrNoOrNull;
  submitted: boolean;

  constructor(
      public trialService: TrialService,
      private organService: OrganService,
      private router: Router,
      private cfr: ComponentFactoryResolver,
      private translateService: InnohealthTranslateService,
      private inclusionExclusionService: TrialInclusionExclusionCriteriaService,
      private sharedService: SharedService,
      private trialProtocolService: TrialProtocolService,
      private trialObservablesService: TrialObservablesService,
      private canDeactivateService: CanDeactivateService,
      public trialPageLockService: TrialPageLockService,
      private dynamicConfigService: DynamicConfigService
  ) {
    this.canDeactivateService.canBeDeactivated = true;
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    this.getTrial();
    this.getOrganList();
    this.getSexList();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.organFamilyEnabled = this.dynamicConfigService.getProperty(PropertyEnum.organFamilyEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
        config => {
          this.config = config;
          this.maxAgeEnabled = this.displayField('maxAge');
        }
    );
  }

  isModeEdit(): boolean {
    return this.mode === 'edit';
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  checkIfLastProtocolExists() {
    this.showEmptyProtocolWarning = this.trialProtocolService.isLastProtocolNotUsed(this.allProtocols, this.usedProtocols);
  }

  getTrial() {
    this.trialService.trial$.subscribe(res => {
      this.trial = res;
      if (this.trialId) {
        this.getUsedProtocols();
        this.getAllProtocols();
      }
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred in trials$.' + error);
    });
  }

  getInclusionExclusionByProtocol() {
    this.inclusionExclusionService.load(this.trialId, this.protocol.hhhId).subscribe(result => {
      this.inclusionExclusion = result;
    }, error => {
      this.sharedService.showFailure();
    });
  }

  initProtocolToLatest() {
    this.protocol = this.usedProtocols[this.usedProtocols.length - 1];
  }

  getAllProtocols() {
    this.trialProtocolService.getTrialProtocolList(this.trialId).subscribe(result => {
      this.allProtocols = result.sort((a, b) => a.hhhId - b.hhhId);
      this.checkIfLastProtocolExists();
    });
  }

  getUsedProtocols(targetProtocol?: MinimizedTrialProtocol) {
    this.inclusionExclusionService.getRelatedProtocolsByTrial(this.trialId).subscribe(result => {
      this.usedProtocols = result.sort((a, b) => a.hhhId - b.hhhId);
      this.usedProtocolsSelectItems = [];
      this.usedProtocols.forEach(up => this.usedProtocolsSelectItems.push({
        label: (up.version ? ('Version du protocole : ' + up.version) : ('Version de l\'amendement : ' + up.amendmentVersion)),
        value: up.hhhId
      }));
      this.checkIfLastProtocolExists();
      if (this.usedProtocols.length > 0) {
        if (!targetProtocol) {
          this.initProtocolToLatest();
        }
        this.getInclusionExclusionByProtocol();
      }
    }, error => {
      this.sharedService.showFailure();
    });
  }


  getOrganList() {
    this.organService.getOrgansAndOrganFamilies().subscribe(
        res => {
          this.organsList = res;
          this.maxSelectedLabels = res.length;
        }, error => {
          this.sharedService.showFailure();
          console.error('An error occurred while loading organ list.' + error);
        }
    );
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.inclusionExclusion.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialInclusionExclusionCriteria, this.inclusionExclusion.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.inclusionExclusion.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  isEditMode() {
    return this.mode === 'edit';
  }

  formHasBeenChanged() {
    console.log('form changed ');
    this.canDeactivateService.canBeDeactivated = false;
  }

  onProtocolChange() {
    this.protocol = this.usedProtocols.find(p => p.hhhId === this.protocol.hhhId);
    this.getInclusionExclusionByProtocol();
  }

  onSave() {
    this.submitted = true;
    const componentRef = ChooseProtocolVersionComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    componentRef.initializeProtocols(this.allProtocols, this.usedProtocols, this.protocol);
    componentRef.disableUpdateCurrentVersion = this.inclusionExclusion.hhhId == null;
    componentRef.useCurrentProtocol = this.inclusionExclusion.hhhId != null;
    componentRef.onSave.subscribe((p: MinimizedTrialProtocol) => {
      this.protocol = this.allProtocols.find(tp => tp.hhhId === p.hhhId);
      this.inclusionExclusion.protocolId = p.hhhId;
      this.inclusionExclusion.trialId = this.trialId;
      this.inclusionExclusionService.save(this.inclusionExclusion).subscribe(result => {
        this.canDeactivateService.canBeDeactivated = true;
        this.inclusionExclusion = result;
        this.trialObservablesService.onInclusionExclusionSave.emit();
        this.getUsedProtocols(this.protocol);
        this.sharedService.setInformationInLocalStorage('Études', 'Éditer', this.trial.trialIdentificationInformationGeneral.localIdentifier);
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
      });
    });
    componentRef.onCancel.subscribe(() => {
      console.log('form cancelled');
    });
  }

  handleHasMolecularPreScreeningChange(event) {
    if (!event.value) {
      this.inclusionExclusion.inclusionPreScreeningMolecules = [];
    }
    this.formHasBeenChanged();
  }

  handleInclusionBiomarkersChange(event) {
    if (!event.value) {
      this.inclusionExclusion.inclusionBiomarkers = [];
    }
    this.formHasBeenChanged();
  }

  getSexList() {
    const mrTitle = this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MR');
    const mmeTitle = this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MME');
    this.sexList.push(
        {label: mrTitle, value: mrTitle},
        {label: mmeTitle, value: mmeTitle},
        {label: 'NA', value: 'NA'});
  }
}

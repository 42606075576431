export class TrialGlobalFilter {
  user: number;
  state: number[];
  phase: number[];
  promoterType: number;
  speciality: number;
  localIdentifier = '';
  protocolNumber = '';
  site: number[];
  moleculeStudied = '';
  principalInvestigator1 = '';
  clinicalResearchAssistant1 = '';
  organ: number;
  organFamily: number;
  trialOrgan: any;
  illnessStates: number;
  establishmentDepartment: number;
  promoter: number[];
  actualProductionLaunchDate: Date;
  inclusionDate: Date;
  inclusionYear: number;
  actualClosingForInclusionsYear: number;
  actualFollowupEndYear: number;
  trialClosingYear: number;
  archiveYear: number;
  actualProductionLaunchYear: number;
  lastCPPAmendmentYear: number;
  cppSendingYear: number;
  ansmAuthorizationYear: number;
  conventionSignatureByPromoterYear: number;
  actualClosingForInclusionsDate: Date;
  trialClosingDate: Date;
  actualFollowupEndDate: Date;
  archiveDate: Date;
  blinded: boolean;
  inclusionsNumber: number;
  patientIncludedState: number;
  coInvestigators = '';
  conventionTypes: number[];
  conventionSignatureByPromoterDate: Date;
  minimalAge: string;
  maximalAge: string;
  researchType: number;
  researchNature: number;
  nationalCoordinator = '';
  cppSendingDate: Date;
  lastCPPAmendmentDate: Date;
  ansmAuthorizationDate: Date;
  ivrs: boolean;
  priorityNumber: string;
  establishment: number;
  trialTreatment: number[];
  trialCommittee: number;
  trialTechniqueType: number;
  molecularAnomalyType: number;
  preInclusionNumber: number;
  totalPreScreeningConsents: number;
  totalTreatedPatients: number;
  idRcb: string;
  projectManagerFrontDesk: string;
  contractManagers: string;
  invoicingManagers: string;
  dataManagers2: string;
  tec1: string;
  ansmProvisionalSoumissionDate: number;
  ansmSoumissionDate: number;
  cppProvisionalSoumissionDate: number;
  cppActualSoumissionDate: number;
  cppProvisionalAgreementDate: number;
  cppActualAgreementDate: number;
  // new fields
  escalationExtension: string;
  firstInHuman: boolean;
  hasMolecularPreScreening: boolean;
  trialTypesMethodologies: number[] = [];
  provider: number;
  numberCentersAllocatedByPromoter: string;
  multiCentric: number;
  numberOfCenterAllocatedByPromoter: string;
  numberClinicalTrialsGov: string;
  referentDoctor: string;
  arcManager: string;
  arc2: string;
  arcReferent: string;
  amr: string;
  irc: string;
  gp: string;
  projectManager: string;
  targetOfTreatmentExperimental: number[] = [];
  otherTreatmentExperimental: number[] = [];
  therapeuticClassForDrugs: number[] = [];
  researchObject: number[] = [];
  ansmInformationDate = 0;
  initialEvaluationCppSessionDate = 0;
  promoterFeasibilityQuestionnaireDespatchDate = 0;
  insuranceExpiry = 0;
  selectionCenterSelectedDate = 0;
  abandonedTrialDate = 0;
  committeeReunionDecisionDate = 0;
  cppAuthorizationMSDate: number;
  substantialModification: boolean;
  ctisAuthorization: boolean;
  ctisProvisionalSoumissionDate = 0;
  ctisActualSoumissionDate = 0;
  ctisProvisionalAgreementDate = 0;
  ctisActualAgreementDate = 0;
  nationalAgencyActualAgreementDate = 0;
  committeeEthicsActualAgreementDate = 0;
  csetCompleteFolderReceptionDate = 0;
  csetSoumissionDate = 0;
  drcAgreementDate = 0;
  provisionalProductionLaunch = 0;
  temporarySuspensionOfInclusions = 0;
  centerActivation = 0;
  audit = 0;
  inspection = 0;
  preArchiveDate = 0;
  receivedConventionDate = 0;
  revisedConventionDate = 0;
  conventionStatus: number[] = [];
  typeOfStudyLaw2: number[] = [];
  completeReceipt = 0;
  sendingConventionDate = 0;
  preArchiveLastInvoicementDate = 0;
  coordoPrcDate = 0;
  comopAgreeementDate = 0;
  csetAgreementDate = 0;
  promoter2: number;
  inclusionHistologies: number;
  preArchiveInvoicementIsValid: 'YES' | 'NO' | 'NO_ANSWER';

  trialFilter() {
    this.user = -1;
    this.state = null;
    this.phase = null;
    this.promoterType = -1;
    this.speciality = -1;
    this.localIdentifier = '';
    this.protocolNumber = '';
    this.site = null;
    this.moleculeStudied = '';
    this.principalInvestigator1 = '';
    this.clinicalResearchAssistant1 = '';
    this.organ = -1;
    this.organFamily = -1;
    this.illnessStates = -1;
    this.establishmentDepartment = -1;
    this.promoter = null;
    this.actualProductionLaunchDate = null;
    this.inclusionDate = null;
    this.actualClosingForInclusionsDate = null;
    this.trialClosingDate = null;
    this.actualFollowupEndDate = null;
    this.archiveDate = null;
    this.blinded = null;
    this.inclusionsNumber = -1;
    this.patientIncludedState = -1;
    this.coInvestigators = '';
    this.conventionTypes = [];
    this.conventionSignatureByPromoterDate = null;
    this.minimalAge = '';
    this.maximalAge = '';
    this.researchType = -1;
    this.researchNature = -1;
    this.nationalCoordinator = '';
    this.cppSendingDate = null;
    this.lastCPPAmendmentDate = null;
    this.ansmAuthorizationDate = null;
    this.priorityNumber = '';
    this.establishment = -1;
    this.trialTreatment = [];
    this.trialCommittee = -1;
    this.trialTechniqueType = -1;
    this.inclusionYear = 0;
    this.actualClosingForInclusionsYear = 0;
    this.actualFollowupEndYear = 0;
    this.trialClosingYear = 0;
    this.archiveYear = 0;
    this.actualProductionLaunchYear = 0;
    this.lastCPPAmendmentYear = 0;
    this.conventionSignatureByPromoterYear = 0;
    this.ansmAuthorizationYear = 0;
    this.cppSendingYear = 0;
    this.preInclusionNumber = -1;
    this.totalPreScreeningConsents = -1;
    this.totalTreatedPatients = -1;
    this.ansmProvisionalSoumissionDate = 0;
    this.ansmSoumissionDate = 0;
    this.cppProvisionalSoumissionDate = 0;
    this.cppActualSoumissionDate = 0;
    this.cppProvisionalAgreementDate = 0;
    this.cppActualAgreementDate = 0;
    this.promoter2 = 0;
    this.csetAgreementDate = 0;
    this.inclusionHistologies = -1;
    return this;

  }

}

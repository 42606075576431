import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('FormInputConfig')
export class FormInputConfig {

  @JsonProperty('label', String, true)
  label: string = undefined;

  @JsonProperty('display', Boolean)
  display = false;

  @JsonProperty('required', Boolean)
  required = false;

  @JsonProperty('disabled', Boolean)
  disabled: boolean = undefined;

  @JsonProperty('order', Number)
  order: number = undefined;

  @JsonProperty('group', String, true)
  group: string = undefined;

  @JsonProperty('width', String, true)
  width: string = undefined;

  @JsonProperty('attachedTo', String, true)
  attachedTo: string = undefined;

  @JsonProperty('privateComment', Boolean)
  privateComment: boolean = undefined;

  @JsonProperty('publicComment', Boolean)
  publicComment: boolean = undefined;

  @JsonProperty('important', Boolean)
  important = false;

  @JsonProperty('importantDescription', String, true)
  importantDescription = undefined;

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'ih-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent<T> implements OnInit {

  @ViewChild('addForm') addForm: NgForm;

  display = false;
  submitted = false;
  formHeader: string;
  fieldName: string;
  callback: (entity: T) => void;

  constructor() {
  }

  ngOnInit() {
    this.submitted = false;
    this.display = true;
  }

  onAdd(callback: (entity: T) => void) {
    this.callback = callback;
  }

}

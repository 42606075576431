import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {MobileStaticData} from '../models/mobile-static-data';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {
  public host = new Properties().host;

  constructor(private httpClient: HttpClient) {
  }

  getTermsAndConditions(): Observable<MobileStaticData> {
    return this.httpClient.get<MobileStaticData>(this.host + '/mobile-application/terms-and-conditions');
  }

  editTermsAndConditions(termsAndConditions: MobileStaticData): Observable<MobileStaticData> {
    return this.httpClient.post<MobileStaticData>(this.host + '/mobile-application/terms-and-conditions', termsAndConditions);
  }
}

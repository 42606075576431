import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ConventionType} from '../../../models/convention-type';
import {FR} from '../../../../shared/entity/calendar-language';
import {Data} from '../../../../shared/entity/data';
import {TrialService} from '../../../services/trial.service';
import {DataService} from '../../../../shared/services/data-service';
import {SharedService} from '../../../../shared/services/shared.service';
import {Router} from '@angular/router';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {TrialAdministrativeDatesInformation} from '../../../models/trial-administrative-dates-information';
import {TrialAdministrativeDatesService} from '../../../services/trial-administrative-dates.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {isNullOrUndefined} from 'util';
import {AppData} from '../../../../helpers/app-data';
import {TrialAdministrativeInformationService} from '../../../services/trial-administrative-information.service';
import {ConventionTypeService} from '../../../services/convention-type.service';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';
import {DataItemService} from '../../../../custom-field/service/data-item.service';
import {OtherAmendedFormComponent} from '../other-amended-form/other-amended-form.component';

@Component({
  selector: 'ih-trial-administrative-convention-information',
  templateUrl: './trial-administrative-convention-information.component.html',
  styleUrls: ['./trial-administrative-convention-information.component.css']
})
export class TrialAdministrativeConventionInformationComponent implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_CONVENTION_INFORMATION_FORM;
  config = new FormConfig();
  isAmendmentEnabled = false;

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  convention: TrialAdministrativeDatesInformation = new TrialAdministrativeDatesInformation();
  trialId: number;
  conventionTypes: ConventionType[] = [];
  locale = FR;
  noYesOptions: any;
  noYesNoAnswerOptions = Data.yesOrNoOrNoAnswerEnum;
  yesNoWithEmptyOption: Array<any>;
  trialSiteLiaisonHhhId = 0;
  selectedConventionTypeName: string;
  pendingOrValidOptions = Data.pendingOrValidOptions;
  conventionTypeNA:number|null=null;

  constructor(
    private trialAdministrativeDatesService: TrialAdministrativeDatesService,
    public trialService: TrialService,
    private dataService: DataService,
    private sharedService: SharedService,
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private conventionTypeService: ConventionTypeService,
    private canDeactivateService: CanDeactivateService,
    private trialAdministrativeInformationService: TrialAdministrativeInformationService,
    private dynamicConfigService: DynamicConfigService,
    private dataItemService: DataItemService) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
      this.trialSiteLiaisonHhhId = url.queryParams.trialSiteLiaisonHhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    if (this.trialSiteLiaisonHhhId) {
      this.trialService.setTrialSiteLiaisonHhhId(this.trialSiteLiaisonHhhId);
      this.dataService.setExportPageModule('src/app/trial/trial-administrative-information/dates');
    } else {
      this.dataService.setExportPageModule('src/app/trial/trial-administrative-information');
    }
    this.getTrialAdministrativeDatesInformation();
    this.yesNoWithEmptyOption = Data.yesOrNoOrNull;
    this.noYesOptions = Data.yesOrNoOrNoAnswer;
    this.getConventionTypeNA();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAmendmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);


  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }


  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.convention.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialAdministrativeDate, this.convention.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateAdministrativeInformation() {
    if (!this.convention.isValid(this.config, this.isAmendmentEnabled)) {
      return;
    }
    if (typeof this.convention.conventionStatus === 'number') {
      this.convention.conventionStatus = [this.convention.conventionStatus];
    }
    localStorage.setItem('modificationSaved', 'true');
    this.convention.trialHhhId = this.trialId;
    this.convention.trialSiteHhhId = (this.trialSiteLiaisonHhhId || 0);
    this.trialAdministrativeDatesService.updateAdministrativeDatesInformation(this.convention).subscribe(() => {
      this.sharedService.showSuccess();
      this.canDeactivateService.canBeDeactivated = true;
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.convention.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  onConventionTypeChange(event?) {
    this.convention.conventionTypesHhhId = [];
    this.convention.conventionTypesHhhId.push(this.convention.conventionType);
    this.formHasBeenChanged();
    this.selectedConventionTypeName = event.label;
  }

  getTrialAdministrativeDatesInformation() {
    this.trialAdministrativeDatesService.load(this.trialId, this.trialSiteLiaisonHhhId).subscribe(
      res => {
        this.convention = res;
        if (this.convention.conventionTypesHhhId && this.convention.conventionTypesHhhId.length > 0) {
          this.convention.conventionType = this.convention.conventionTypesHhhId[0];
        }
        if (!isNullOrUndefined(this.convention.conventionType)) {
          this.dataItemService.getItems('convention-type').subscribe(items => {
            this.selectedConventionTypeName = items.find(item => item.value === this.convention.conventionType).label;
          });
        }
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred in trialAdministrative.' + error);
      });
  }

  export(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.trialId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .build();

    this.trialAdministrativeInformationService.exportedAdministrativeInformation(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }

  addOtherAmendedAgreement() {
    const showPopupFactory = this.cfr.resolveComponentFactory(OtherAmendedFormComponent);
    const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
    const otherAmendedFormComponent = showPopupComponentRef.instance;
    otherAmendedFormComponent.setTrialId(this.trialId);
    otherAmendedFormComponent.trialSiteLiaisonId = this.trialSiteLiaisonHhhId;
    otherAmendedFormComponent.onAdd((callbackValue: {value: string, count: number}) => {
      this.convention.amendedAgreementStream = callbackValue.value;
      this.convention.countOtherAmendedAgreement = callbackValue.count;
    });
  }

  onResetToNAChanged(isChecked: boolean, nbr: number) {
    if (!isChecked) {
      switch (nbr) {
        case 1:
          this.convention.conventionType = null;
          this.convention.conventionTypesHhhId = [];
          break;
      }
    }else {
      switch (nbr) {
        case 1:
          this.convention.conventionType  = this.conventionTypeNA;
          this.convention.conventionTypesHhhId = [this.conventionTypeNA];
          break;
      }
    }
  }
  getConventionTypeNA(): void {
    this.trialAdministrativeDatesService.getConventionTypeNA().subscribe(
      (data: number) => {
        this.conventionTypeNA = data;
      },
      (error) => {
        console.error('Erreur lors de la récupération de l\'ID:', error);
        this.sharedService.showFailure('Impossible de récupérer l\'ID de Type de convention NA.');
      }
    );
  }
}

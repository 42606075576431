import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {TableHeader} from '../../shared/entity/table-header';
import {Router} from '@angular/router';
import {DataService} from '../../shared/services/data-service';
import {PharmacyDispensation} from '../../trial/entity/trial-pharmacy/pharmacyDispensation';
import {PharmacyDispensationService} from '../../trial/service/pharmacy-dispensation.service';
import {PharmacyDispensationGlobalFilter} from '../model/pharmacyDispensationGlobalFilter';
import {FR} from '../../shared/entity/calendar-language';
import {DatePipe} from '@angular/common';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PharmacyService} from '../../trial/service/pharmacy.service';
import {SharedService} from '../../shared/services/shared.service';
import {isNullOrUndefined} from 'util';
import {Data} from '../../shared/entity/data';
import {SelectItem} from 'primeng/components/common/api';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {PharmacyAppData} from '../pharmacy-app-data';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {AppData} from '../../helpers/app-data';
import {ExportTypesEnum} from '../../dynamic-config/exported/data-export/ExportTypesEnum';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';


@Component({
  selector: 'ih-pharmacy-dispensation',
  templateUrl: './pharmacy-dispensation.component.html',
  styleUrls: ['./pharmacy-dispensation.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]

})
export class PharmacyDispensationComponent extends PaginatorTableComponent<PharmacyDispensation> implements OnInit, OnDestroy {

  readonly target = DynamicDefinitionEnum.PHARMACY_DISPENSATION_TABLE;
  config: TableConfig;

  dispensationColumnListHeader: DynamicTableHeader[];
  kitsColumnListHeader: DynamicTableHeader[];
  pharmacyHhhId: number;
  trialHhhId: number;
  locale = FR;
  filters = new PharmacyDispensationGlobalFilter();
  first = 0;
  monthsSelectItems: SelectItem[] = [];
  yearsSelectItems: SelectItem[] = [];

  constructor(
    private datePipe: DatePipe,
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    private pharmacyDispensationService: PharmacyDispensationService,
    private pharmacyService: PharmacyService,
    private router: Router,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
      this.pharmacyHhhId = url.queryParams.pharmacyHhhId;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.monthsSelectItems = Data.monthsList;
    for (let i = new Date().getFullYear() + 20; i >= 1900; i--) {
      this.yearsSelectItems.push({value: i, label: String(i)});
    }
    this.pharmacyService.setPharmacyReadonlyMode();
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, PharmacyAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const dispensationListHeader = [];
    dispensationListHeader.push(new DynamicTableHeaderBuilder()
      .field('trialName')
      .filterable()
      .filter(this.filters.trialName)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    dispensationListHeader.push(new DynamicTableHeaderBuilder()
      .field('treatmentName')
      .filterable()
      .filter(this.filters.treatmentName)
      .filterType(FilterType.IH_DROPDOWN)
      .type('trial-treatment-experimental-selected')
      .sortable()
      .build());
    dispensationListHeader.push(new DynamicTableHeaderBuilder()
      .field('pharmaceuticalFormName')
      .filterable()
      .filter(this.filters.pharmaceuticalFormName)
      .filterType(FilterType.IH_DROPDOWN)
      .type('pharmaceutical-form-selected')
      .sortable()
      .build());
    this.dispensationColumnListHeader = this.config.buildTable(dispensationListHeader);
    const kitsListHeader = [];
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('kit')
      .filterable()
      .filter(this.filters.kit.kit)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('prescriberName')
      .filterable()
      .filter(this.filters.kit.prescriberHhhId)
      .filterType(FilterType.IH_DROPDOWN)
      .type('pharmacist-name')
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('schedulerNumber')
      .filterable()
      .filter(this.filters.schedulerNumber)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
        .field('dispensationDate')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .filter(this.filters.dispensationDate)
        .sortable()
        .sortField('dispensationDate')
        .options(this.yearsSelectItems)
        .withDateFormatter()
        .build());
  // .field('dispensationDates')
  //     .filterable()
  //     .filter(this.filters.dispensationDate)
  //     .filterType(FilterType.DROPDOWN)
  //     .options(this.yearsSelectItems)
  //     .sortable()
  //     .sortField('dispensationDates')
  //     .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('patientName')
      .filterable()
      .filter(this.filters.patientName)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('visitName')
      .filterable()
      .filter(this.filters.visitName)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('processingUnit')
      .filterable()
      .filter(this.filters.kit.processingUnit)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('initialQuantity')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('dispensedQuantity')
      .filterable()
      .filter(this.filters.dispensedQuantity)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('returnedQuantity')
      .filterable()
      .filter(this.filters.returnedQuantity)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('destructionDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.destructionDate)
      .sortable()
      .sortField('destructionDate')
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .build());
    this.kitsColumnListHeader = this.config.buildTable(kitsListHeader);
  }

  saveHeadersLayout() {
    localStorage.setItem(this.target, JSON.stringify(this.kitsColumnListHeader));
  }

  getList() {
    this.pharmacyDispensationService.loadList(this.page, this.limit, this.sortBy, this.orderBy, this.pharmacyHhhId, this.filters).subscribe(
      res => {
        this.values = res.content;
        this.totalNumber = res.totalElements;
        this.pharmacyDispensationService.setPaginationConfiguration(res, this.page, this.limit, this.sortBy, this.orderBy, this.filters, this.pharmacyHhhId);
      }, error => {
        this.sharedService.showFailure();
        console.log(error);
      });
  }

  navigateToDispensation(pharmacyDispensation: PharmacyDispensation) {
    this.router.navigate(['/trial-details/pharmacy/dispensation'], {
      queryParams: {
        hhhId: pharmacyDispensation.trialHhhId,
        pharmacyHhhId: pharmacyDispensation.pharmacyHhhId,
      }
    }).then();
  }
  // getTdClass(): string {
  //   return this.kitsColumnListHeader.length === 0 ? 'empty-header' : '';
  // }
  get filterType() {
    return FilterType;
  }

  filterUpdated() {
    this.page = 0;
    this.first = 0;
    this.filters = Object.assign(TableHeader.buildFilterData(this.dispensationColumnListHeader), TableHeader.buildFilterData(this.kitsColumnListHeader));
    this.dispensationColumnListHeader.forEach(value => {
      if (value.composedFilter) {
        this.filters[value.field + 'Month'] = value.composedFilter[0];
        this.filters[value.field + 'Year'] = value.composedFilter[1];
      }
    });
    this.getList();
  }

  getExportPageModule(): string {
    return 'src/app/pharmacy/pharmacy-dispensation/pharmacy-dispensation.component';
  }

  resetFilter() {
    this.filters = new PharmacyDispensationGlobalFilter();
    this.page = 0;
    this.first = 0;
    this.ngOnInit();
    this.getList();
  }

  displayContent(col: any, colField: any, rowData: any): string {
    let content = colField;
    if (isNullOrUndefined(rowData[col.field]) && !col.displayContent) {
      content = '';
    } else if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (col.dateFormatter) {
      content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
    }
    return content;
  }

  displayBtn(btnId: string): boolean {
    if (!this.config || !this.config.buttons) {
      return false;
    }
    return this.config.displayBtn(btnId);
  }

  getBtnClass(): string {
    return this.config ? this.config.getBtnClass() : '';
  }

  exportPharmacyDispensation(event: any): void {
    const request = new ExportRequestBuilder<PharmacyDispensationGlobalFilter>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.getOrderedColumns(this.target), this.limit, this.page)
      .sortBy(this.sortBy)
      .orderBy(this.sortBy)
      .filters(this.filters)
      .build();

    this.pharmacyDispensationService.exportPharmacyDispensations(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }
  public getOrderedColumns(target: string): string[] {
    return localStorage.getItem(target).split(',');
  }
}

import {Component, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {Router} from '@angular/router';
import {TrialDashboardService} from '../../service/trial-dashboard.service';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {TrialDashboard} from '../../entity/trial-dashboard';
import {TimelineItem} from 'ngx-horizontal-timeline';
import {DatePipe} from '@angular/common';
import {ColoredMessage} from '../../entity/colored-message';
import {TrialAppData} from '../../trial-app-data';
import {DynamicDefinitionEnum} from '../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {DynamicConfigService} from '../../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_DASHBORD_FORM;
  config: FormConfig = new FormConfig();

  data: any;
  trialId: number;
  trialDashboard: TrialDashboard = new TrialDashboard();
  isMultiSite: boolean;
  timeline: TimelineItem[];
  chartOptions: any;
  inclusionIncluded = 0;
  inclusionCreated = 0;
  inclusionPreScreening = 0;
  inclusionPreScreeningFailure = 0;
  inclusionScreening = 0;
  inclusionScreeningFailure = 0;
  inclusionInTreatment = 0;
  inclusionInFollowUp = 0;
  inclusionInFollowUpAfterProgression = 0;
  inclusionInFollowUpAfterToxicity = 0;
  inclusionExcluded = 0;
  inclusionWithdrawalOfConsent = 0;
  inclusionPreselected = 0;
  inclusionPreIncluded = 0;
  inclusionLostView = 0;
  inclusionFollowUpEnd = 0;
  inclusionEnded = 0;
  inclusionFollowUpCompleted = 0;

  constructor(
    private messageService: MessageService,
    private translateService: InnohealthTranslateService,
    private router: Router,
    private trialDashboardService: TrialDashboardService,
    public datepipe: DatePipe,
    private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.getTrialDashboard();
    this.initFormConfig();
  }

  selectData(event) {
    this.messageService.add({
      severity: 'info',
      summary: 'Data Selected',
      detail: this.data.datasets[event.element._datasetIndex].data[event.element._index]
    });
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getTrialDashboard() {
    this.trialDashboardService.getTrialDashboard(this.trialId).subscribe(res => {
        this.timeline = [];
        this.trialDashboard = res;
        const step = Math.ceil(this.getNumberOfInclusions() / 10);
        this.chartOptions = {
          plugins: {
            datalabels: {
              display: false
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                suggestedMin: 0,
                beginAtZero: true,
                suggestedMax: step > 0 ? step : 1,
                stepSize: (step >= 1 ? step : 1)
              },
              autoSkip: true
            }]
          }
        };
        const total = this.trialDashboard.inclusionTotal;
        if (total > 0) {
          this.inclusionPreScreening = Math.round(this.trialDashboard.inclusionState[0].inclusions * 100 / total);
          this.inclusionPreScreeningFailure = Math.round(this.trialDashboard.inclusionState[1].inclusions * 100 / total);
          this.inclusionScreening = Math.round(this.trialDashboard.inclusionState[2].inclusions * 100 / total);
          this.inclusionScreeningFailure = Math.round(this.trialDashboard.inclusionState[3].inclusions * 100 / total);
          this.inclusionInTreatment = Math.round(this.trialDashboard.inclusionState[4].inclusions * 100 / total);
          this.inclusionInFollowUp = Math.round(this.trialDashboard.inclusionState[5].inclusions * 100 / total);
          this.inclusionInFollowUpAfterProgression = Math.round(this.trialDashboard.inclusionState[6].inclusions * 100 / total);
          this.inclusionInFollowUpAfterToxicity = Math.round(this.trialDashboard.inclusionState[7].inclusions * 100 / total);
          this.inclusionExcluded = Math.round(this.trialDashboard.inclusionState[8].inclusions * 100 / total);
          this.inclusionWithdrawalOfConsent = Math.round(this.trialDashboard.inclusionState[9].inclusions * 100 / total);
          this.inclusionCreated = Math.round(this.trialDashboard.inclusionState[10].inclusions * 100 / total);
          this.inclusionIncluded = Math.round(this.trialDashboard.inclusionState[11].inclusions * 100 / total);
          this.inclusionPreIncluded = Math.round(this.trialDashboard.inclusionState[12].inclusions * 100 / total);
          this.inclusionPreselected = Math.round(this.trialDashboard.inclusionState[13].inclusions * 100 / total);
          this.inclusionLostView = Math.round(this.trialDashboard.inclusionState[14].inclusions * 100 / total);
          this.inclusionFollowUpEnd = Math.round(this.trialDashboard.inclusionState[15].inclusions * 100 / total);
          this.inclusionEnded = Math.round(this.trialDashboard.inclusionState[16].inclusions * 100 / total);
          this.inclusionFollowUpCompleted = Math.round(this.trialDashboard.inclusionState[16].inclusions * 100 / total);
        }
        this.isMultiSite = res.trial.multiSite;
        this.trialDashboard.actualAndProvisionalDates.sort((a, b) => (a.date > b.date ? 1 : ((b.date > a.date) ? -1 : 0)));
        for (const item of this.trialDashboard.actualAndProvisionalDates) {
          for (const date of item.coloredMessages) {
            const realIndex = item.coloredMessages.findIndex((coloredMessage: ColoredMessage) => coloredMessage.color === 'NORMAL');
            const index = this.timeline.findIndex((timeLineItem: TimelineItem) => this.convertDate(item.date) === timeLineItem.title);
            if (item === this.trialDashboard.actualAndProvisionalDates[0]) {
              if (index > -1) {
                const lastItemInTHeSameIndex = this.timeline[index];
                this.timeline[index] = {
                  label: date.message + ' , ' + lastItemInTHeSameIndex.label,
                  title: this.convertDate(item.date),
                  color: realIndex > -1 ? '3399cc' : '33cccc',
                };
              } else {
                this.timeline.push({
                  label: date.message,
                  title: this.convertDate(item.date),
                  color: item.coloredMessages[0].color === 'NORMAL' ? '3399cc' : '33cccc',
                });
              }
            } else {
              if (index > -1) {
                const lastItemInTHeSameIndex = this.timeline[index];
                this.timeline[index] = {
                  label: date.message + ' , ' + lastItemInTHeSameIndex.label,
                  title: this.convertDate(item.date),
                  color: realIndex > -1 ? '3399cc' : '33cccc',
                };
              } else {
                this.timeline.push({
                  label: date.message,
                  title: this.convertDate(item.date),
                  color: item.coloredMessages[0].color === 'NORMAL' ? '3399cc' : '33cccc',
                });
              }
            }
          }
        }
        this.generateOrganList(this.trialDashboard);
        const inclusionPerMonthData = [];
        const inclusionObjectivePerMonthData = [];
        const inclusionPerMonthLabels = [];

        let cumulativeSum = 0;
        let r = 0;
        let monthNumber = 0;
        inclusionObjectivePerMonthData.push(r);
        for (const inclusionPerMonth of this.trialDashboard.inclusionPerMonth) {
          monthNumber += 1;
          cumulativeSum += inclusionPerMonth.nbInclusion;
          inclusionPerMonthData.push(cumulativeSum);
          r += this.trialDashboard.inclusionTotal / (this.trialDashboard.inclusionPerMonth.length - 1);
          if (monthNumber < this.trialDashboard.inclusionPerMonth.length) {
            inclusionObjectivePerMonthData.push(Math.trunc(r) + 1);
          }

          inclusionPerMonthLabels.push(inclusionPerMonth.inclusionDate.substring(5, 7) + '/'
            + inclusionPerMonth.inclusionDate.substring(2, 4));
        }
        this.data = {
          labels: inclusionPerMonthLabels,
          datasets: [
            {
              label: this.translateService.getTranslationString('MODULE_CLINICAL_RESEARCH_ACTIVITIES_OBJECTIVE'),
              data: this.removeConsecutiveDuplicates(inclusionObjectivePerMonthData),
              fill: false,
              borderColor: '#3498DB',
              lineTension: 0,
              spanGaps: true
            },
            {
              label: this.translateService.getTranslationString('MODULE_CLINICAL_RESEARCH_ACTIVITIES_PATIENTS_PER_STATE_IN_TREATMENT'),
              data: this.removeConsecutiveDuplicates(inclusionPerMonthData),
              fill: false,
              borderColor: '#4bc0c0',
              lineTension: 0,
              spanGaps: true
            }
          ]
        };
      }, error => {
        console.error('An error occurred while loading trial dashboard.' + error);
      }
    );
  }

  getNumberOfInclusions(): number {
    if (this.displayField('forecastInclusions')) {
      return this.trialDashboard.trial.forecastInclusions;
    } else {
      return this.trialDashboard.trial.theoreticalNumberOfInclusions;
    }
  }

  convertDate(date: Date) {
    return this.datepipe.transform(date, 'dd-MM-yyyy');
  }

  generateOrganList(trialDashboard: TrialDashboard): string {
    let organList = '';
    if (trialDashboard.trial.organList && trialDashboard.trial.organList.length > 0) {
      for (const organ of trialDashboard.trial.organList) {
        organList = organList + organ.nameFr + ' / ';
      }
      return organList.length !== 0 ? organList.substring(0, organList.length - 3) : organList;
    } else if (trialDashboard.trial.organFamilies && trialDashboard.trial.organFamilies.length > 0) {
      organList = organList + trialDashboard.trial.organFamilies[0].nameFr + ' / ';
      return organList.length !== 0 ? organList.substring(0, organList.length - 3) : organList;
    } else {
      return '';
    }
  }

  generateSiteList(trialDashboard: TrialDashboard): string {
    let siteList = '';
    if (trialDashboard.trial.siteList) {
      for (const site of trialDashboard.trial.siteList) {
        siteList = siteList + site.name + ' / ';
      }
      return siteList.length !== 0 ? siteList.substring(0, siteList.length - 3) : siteList;
    } else {
      return '';
    }
  }

  removeConsecutiveDuplicates(data: any): any[] {
    return data.map((e, i, a) => {
      const prev = a[i - 1];
      if (e === prev) {
        return null;
      }
      return e;
    });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);
}


import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {DataCommentCount} from '../../data-comment/entity/data-comment-count';
import {TrialFollowUp} from './trial-follow-up';

export class TrialAdministrativeCecInformation extends DataCommentCount {
  hhhId: number ;
  trialHhhId: number;
  completeFile: boolean;
  cecDate: Date;
  cecNotice: string;
  cec2Date: Date;
  cecNotice2: string;
  manualLaboratory: boolean;
  manualLaboratoryAuthorization: boolean;
  manualLaboratoryComment: string;
  manualPharmacy: boolean;
  manualPharmacyAuthorization: boolean;
  manualPharmacyComment: string;
  finalProtocolVersion: boolean;
  finalProtocolVersionComment: string;
  finalProtocolVersionAuthorization: boolean;
  nice: boolean;
  niceComment: string;
  submittedANSM: boolean;
  ansmComment: string;
  submittedCPP: boolean;
  cppComment: string;
  ideOpinion: boolean;
  ideAuthorization: boolean;
  ideOpinionComment: string;
  externalProvider: boolean;
  externalProviderAuthorization: boolean;
  externalProviderComment: string;
  molecularPreScreening: boolean;
  dataTransmissionModeHhhId: number;
  pharmacyParticipation: boolean;
  radiotherapyParticipation: boolean;
  trialFollowUp: TrialFollowUp[] = [];
  biopsiesRequired: boolean;

  init(obj: any): TrialAdministrativeCecInformation {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'cec2Date') {
        if (this.cecNotice === 'SUSPENSIVE' && required && Util.isNullOrUndefinedOrEmpty(this.cec2Date)) {
          return false;
        }
      } else if (key === 'manualLaboratoryAuthorization') {
        if (this.manualLaboratory && required && Util.isNullOrUndefinedOrEmpty(this.manualLaboratoryAuthorization)) {
          return false;
        }
      } else if (key === 'manualLaboratoryComment') {
        if (this.manualLaboratory && required && Util.isNullOrUndefinedOrEmpty(this.manualLaboratoryComment)) {
          return false;
        }
      } else if (key === 'manualPharmacyAuthorization') {
        if (this.manualPharmacy && required && Util.isNullOrUndefinedOrEmpty(this.manualPharmacyAuthorization)) {
          return false;
        }
      } else if (key === 'manualPharmacyComment') {
        if (this.manualPharmacy && required && Util.isNullOrUndefinedOrEmpty(this.manualPharmacyComment)) {
          return false;
        }
      } else if (key === 'ideAuthorization') {
        if (this.ideOpinion && required && Util.isNullOrUndefinedOrEmpty(this.ideAuthorization)) {
          return false;
        }
      } else if (key === 'ideOpinionComment') {
        if (this.ideOpinion && required && Util.isNullOrUndefinedOrEmpty(this.ideOpinionComment)) {
          return false;
        }
      } else if (key === 'externalProviderAuthorization') {
        if (this.externalProvider && required && Util.isNullOrUndefinedOrEmpty(this.externalProviderAuthorization)) {
          return false;
        }
      } else if (key === 'externalProviderComment') {
        if (this.externalProvider && required && Util.isNullOrUndefinedOrEmpty(this.externalProviderComment)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

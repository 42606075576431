import {InvoiceState} from '../../../invoice/invoice-fixed-costs/models/invoice-state';

export class InvoiceMonitor {
  public hhhId: number;
  public name: string;
  public state: InvoiceState;
  public amount: number;
  public issueDate: Date;
  public paymentDate: Date;
  public validationDate: Date;
  public despatchDate: Date;

}

import {TrialLockPageStatus} from "./trial-lock-page-status.enum";

export class TrialPageLock {
  hhhId: number;
  username: string;
  userHhhId: string;
  trialHhhId: number;
  password: string;
  trialUnlockCommentHhhId: number;
  optionalComment: string;
  date: Date;
  status: TrialLockPageStatus;
}

export class UserAttempt {
  username: string;
  lastConnectionAttemptDate: Date;
  lastConnectionAttempt: string; // used in export
  successed: boolean;
  status: string; // used in export
  remoteAddress: string;

  init(obj: any): UserAttempt {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}

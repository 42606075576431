import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Properties} from '../../helpers/properties';

@Injectable({
  providedIn: 'root'
})
export class DynamicFilesService {

  host = new Properties().host;
  public currentUser: Observable<any>;
  private currentUserSubject: BehaviorSubject<any>;

  private _importTrialActFileName: string;
  private _trialActFileHeaders: string;
  private _importVisitRealDatesFileName: string;
  private _importTrialsFileName: string;
  private _importRhsFileName: string;
  private _importProvidersFileName: string;

  get importProvidersFileName(): string {
    return this._importProvidersFileName;
  }

  get importTrialsFileName(): string {
    return this._importTrialsFileName;
  }

  get importTrialActFileName(): string {
    return this._importTrialActFileName;
  }

  get trialActFileHeaders(): string {
    return this._trialActFileHeaders;
  }

  get importVisitRealDatesFileName(): string {
    return this._importVisitRealDatesFileName;
  }


  set importTrialsFileName(value: string) {
    this._importTrialsFileName = value;
  }

  set importTrialActFileName(value: string) {
    this._importTrialActFileName = value;
  }

  set trialActFileHeaders(value: string) {
    this._trialActFileHeaders = value;
  }

  set importVisitRealDatesFileName(value: string) {
    this._importVisitRealDatesFileName = value;
  }

  get importRhsFileName(): string {
    return this._importRhsFileName;
  }

  set importRhsFileName(value: string) {
    this._importRhsFileName = value;
  }

  set importProvidersFileName(value: string) {
    this._importProvidersFileName = value;
  }

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(localStorage.getItem('authenticatedUser'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  userLoggedIn(): boolean {
    return !!localStorage.getItem('token');
  }

  initDynamicFilesProperties(): Promise<any> {
    return !this.userLoggedIn()
      ? Promise.resolve()
      : this.http.get<any>(this.host + '/application-initializer')
        .toPromise()
        .then(fileProperties => {
          this.trialActFileHeaders = fileProperties.trialActFileHeaders;
          this.importTrialActFileName = fileProperties.importTrialActFileName;
          this.importVisitRealDatesFileName = fileProperties.importVisitRealDatesFileName;
          this.importTrialsFileName = fileProperties.importTrialsFileName;
          this.importRhsFileName = fileProperties.importRhsFileName;
          this.importProvidersFileName = fileProperties.importProvidersFileName;

        }).catch(reason => {
          console.error(reason);
          this.currentUserSubject.next(null);
        });
  }
}

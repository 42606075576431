import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {VisitShiftingHistory} from '../entity/inclusion/visit-shifting-history';
import {IsDeletable} from '../../is-deletable';

@Injectable({
  providedIn: 'root'
})
export class ShiftingHistoryService {

  public host = new Properties().host + '/visit/visit-shifting-history';

  constructor(private httpClient: HttpClient) {
  }

  public getShiftingHistories(visitHhhId: number): Observable<VisitShiftingHistory[]> {
    return this.httpClient.get<VisitShiftingHistory[]>(this.host + '/' + visitHhhId);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }
  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

}

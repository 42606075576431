import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {TableHeader} from '../../../../shared/entity/table-header';
import {Router} from '@angular/router';
import {SharedService} from '../../../../shared/services/shared.service';
import {DataService} from '../../../../shared/services/data-service';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {PharmacyStock} from '../../../entity/trial-pharmacy/pharmacyStock';
import {PharmacyStockService} from '../../../service/pharmacy-stock.service';
import {PharmacyStockLotFormComponent} from '../pharmacy-stock-lot-form/pharmacy-stock-lot-form.component';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {PharmacyStockGlobalFilter} from '../../../../pharmacy/model/pharmacyStockGlobalFilter';
import {PharmacyService} from '../../../service/pharmacy.service';
import {FR} from '../../../../shared/entity/calendar-language';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {isNullOrUndefined} from 'util';
import {DatePipe} from '@angular/common';
import {Data} from '../../../../shared/entity/data';
import {
  PharmacyDispensationKitFormComponent
} from '../pharmacy-dispensation-kit-form/pharmacy-dispensation-kit-form.component';
import {PharmacyDispensationKit} from '../../../entity/trial-pharmacy/PharmacyDispensationKit';
import {PharmacyDispensationKitService} from '../../../service/pharmacy-dispensation-kit.service';
import {SelectItem} from 'primeng/components/common/api';
import {AUTHENTICATED_USER_ROLE} from '../../../../login/services/authentication.service';
import {Role} from '../../../../login/models/role';
import {DeleteConfirmationComponent} from '../../../../modals/delete-confirmation/delete-confirmation.component';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {
  DynamicTableHeaderBuilder
} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {AppData} from '../../../../helpers/app-data';
import {RollbackService} from '../../../../shared/services/rollback.service';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';


declare var $: any;

@Component({
  selector: 'ih-trial-pharmacy-stock',
  templateUrl: './trial-pharmacy-stock.component.html',
  styleUrls: ['./trial-pharmacy-stock.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class TrialPharmacyStockComponent extends PaginatorTableComponent<PharmacyStock> implements OnInit, OnDestroy {

  readonly target = DynamicDefinitionEnum.TRIAL_PHARMACY_STOCK_TABLE;
  config: TableConfig;

  stockColumnListHeader: DynamicTableHeader[] = [];
  possibleHeaders: DynamicTableHeader[] = [];
  kitsColumnListHeader: DynamicTableHeader[] = [];
  pharmacyGlobalFilter: PharmacyStockGlobalFilter;

  trialHhhId: number;
  pharmacyHhhId: number;
  locale = FR;
  first = 0;
  filters = new PharmacyStockGlobalFilter();
  monthsSelectItems: SelectItem[] = [];
  yearsSelectItems: SelectItem[] = [];
  showButtons = false;
  fromDate: Date;
  toDate: Date;
  years: SelectItem[] = Data.getYears();
  dateTypesSelected: SelectItem[] = [
    {label: 'date de reception ', value: 'date de reception'},
    {label: 'date de péremption', value: 'date de péremption'},
    {label: 'date de dluo', value: 'date de dluo'}];
  dateType: string;
  headersReady = false;


  constructor(
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private sharedService: SharedService,
    private rollBack: RollbackService<PharmacyStockGlobalFilter>,
    private pharmacyStockService: PharmacyStockService,
    public pharmacyService: PharmacyService,
    private translateService: InnohealthTranslateService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private pharmacyDispensationKitService: PharmacyDispensationKitService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
      this.pharmacyHhhId = url.queryParams.pharmacyHhhId;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.monthsSelectItems = Data.monthsList;
    this.dateType = '';
    for (let i = new Date().getFullYear() + 20; i >= 1900; i--) {
      this.yearsSelectItems.push({value: i, label: String(i)});
    }
    this.initTableConfig();
    this.getFilteredList();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const stockListHeader = [];
    stockListHeader.push(new DynamicTableHeaderBuilder()
        .field('lotNumber')
        .filterable()
        .filter(this.filters.lotNumber)
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .sortField('lotNumber')
        .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
        .field('state')
        .filterable()
        .filter(this.filters.state)
        .filterType(FilterType.DROPDOWN)
        .options(Data.states)
        .sortable()
        .sortField('state')
        .displayContent((data: PharmacyStock) => {
          if (data.state !== null) {
            return this.translateService.getTranslationString(data.state);
          }
        })
        .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('receptionDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.receptionDateYear)
      .sortable()
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .sortField('receptionDate')
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
        .field('wording')
        .filterable()
        .filter(this.filters.wording)
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .sortField('wording')
        .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
        .field('receptionAuthorName')
        .filterable()
        .filter(this.filters.receptionAuthorName)
        .filterType(FilterType.IH_DROPDOWN)
        .type('pharmacist-name')
        .sortable()
        .sortField('receptionAuthorName')
        .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('treatmentName')
      .filterable()
      .filter(this.filters.treatmentName)
      .filterType(FilterType.IH_DROPDOWN)
      .sortable()
      .sortField('receptionAuthorName')
      .type('pharmacy-treatment-type|' + this.pharmacyHhhId)
      .build());

    stockListHeader.push(new DynamicTableHeaderBuilder()
      .field('dluoDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.dluoDateYear)
      .sortable()
      .sortField('dluoDate')
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .build());
    stockListHeader.push(new DynamicTableHeaderBuilder()
        .field('dosageName')
        .filterable()
        .filter(this.filters.dosageName)
        .filterType(FilterType.IH_DROPDOWN)
        .type('pharmacy-dose')
        .sortable()
        .sortField('dosageName')
        .build());
    this.stockColumnListHeader = this.config.buildTable(stockListHeader);
    const possibleHeaders = [];
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('receptionNumber')
      .filterable()
      .filter(this.filters.receptionNumber)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('receptionNumber')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('pharmaceuticalFormName')
      .filterable()
      .filter(this.filters.pharmaceuticalFormName)
      .filterType(FilterType.IH_DROPDOWN)
      .type('pharmaceutical-form-selected')
      .sortable()
      .sortField('pharmaceuticalFormName')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('expirationDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.expirationDateYear)
      .sortable()
      .sortField('expirationDate')
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('dispensationDates')
      .filterable()
      .filter(this.filters.dispensationDate)
      .filterType(FilterType.DROPDOWN)
      .options(this.yearsSelectItems)
      .sortable()
      .sortField('dispensationDates')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
        .field('events')
        .filterable()
        .filter(this.filters.events)
        .filterType(FilterType.DROPDOWN)
        .options(Data.events)
        .sortable()
        .sortField('events')
        .displayContent((data: PharmacyStock) => {
          if (data.events !== null) {
            return this.translateService.getTranslationString(data.events);
          }
        })
        .build());
    this.possibleHeaders = this.config.buildTable(possibleHeaders);
    this.headersReady = true;
    const kitsListHeader = [];
    kitsListHeader.push(new DynamicTableHeaderBuilder()
        .field('authorName')
        .filterable()
        .filter(this.filters.authorName)
        .filterType(FilterType.IH_DROPDOWN)
        .type('pharmacist-name')
        .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
        .field('processingUnit')
        .filterable()
        .filter(this.filters.kit.processingUnit)
        .filterType(FilterType.INPUT_TEXT)
        .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
        .field('initialQuantity')
        .filterable()
        .filter(this.filters.kit.initialQuantity)
        .filterType(FilterType.INPUT_TEXT)
        .build());
    kitsListHeader.push(new DynamicTableHeaderBuilder()
      .field('destructionDate')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter(this.filters.destructionDate)
      .sortable()
      .sortField('destructionDate')
      .options(this.yearsSelectItems)
      .withDateFormatter()
      .build());
    this.kitsColumnListHeader = this.config.buildTable(kitsListHeader);
  }

  saveHeadersLayout() {
    localStorage.setItem(this.target, JSON.stringify(this.stockColumnListHeader));
  }

  getAddTitle(): string {
    return 'MODULE_TRIALS_PHARMACY_TAB_STOCK_ADD_LOT';
  }

  getUpdateTitle(): string {
    return 'MODULE_TRIALS_PHARMACY_TAB_STOCK_EDIT_LOT';
  }

  getDeleteMessage(): string {
    return 'MODULE_TRIALS_PHARMACY_TAB_STOCK_DIALOG_WARNING_LOT_WILL_BE_DELETED';
  }

  getExportPageModule(): string {
    return 'src/app/trial/trial-pharmacy/trial-pharmacy-stock/trial-pharmacy-stock.component';
  }

  getService(): any {
    return this.pharmacyStockService;
  }

  getList() {
    this.pharmacyStockService.loadList(this.pharmacyHhhId, this.dateType, this.fromDate, this.toDate, this.page, this.limit, this.sortBy, this.orderBy, this.filters).subscribe(data => {
      this.values = data.content;
      this.totalNumber = data.totalElements;
      if (this.values == null || this.values.length === 0) {
        $('div >  p-table > div > div > div > div.ui-table-scrollable-header.ui-widget-header').css('overflow', 'auto');
      } else {
        $('div > p-table > div > div > div > div.ui-table-scrollable-header.ui-widget-header').css('overflow', 'visible');
      }
      this.pharmacyStockService.setPaginationConfiguration(data, this.dateType, this.limit, this.sortBy, this.orderBy, this.filters, this.pharmacyHhhId);
      this.showButtons = true;
    }, error => {
      this.sharedService.showFailure();
      console.log(error);
    });
  }

  getFilteredList(filter?) {
    this.first = 0;
    this.page = 0;
    if (filter) {
      Object.assign(this.filters, DynamicTableHeader.buildFilterData(this.stockColumnListHeader));
    } else {
      this.filters = new PharmacyStockGlobalFilter();
    }
    this.getList();
  }

  displayFormDialogLot(pharmacyStock?: PharmacyStock) {
    const formComponent = PharmacyStockLotFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (pharmacyStock != null) {
      formComponent.pharmacyStock = Object.assign({}, pharmacyStock);
    }
    formComponent.formHeader = (pharmacyStock != null) ? this.getUpdateTitle() : this.getAddTitle();
    formComponent.pharmacyHhhId = this.pharmacyHhhId;
    formComponent.onAdd((newStock: PharmacyStock) => {
      newStock.pharmacyHhhId = this.pharmacyHhhId;
      if (pharmacyStock != null) {
        this.pharmacyStockService.update(newStock).subscribe(() => {
          this.getList();
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        });
      } else {
        this.pharmacyStockService.add(newStock).subscribe(
          () => {
            this.getList();
            this.sharedService.showSuccess();
          }, error => {
            this.sharedService.showFailure();
            console.error(error);
          }
        );
      }
    });
  }

  displayDeleteLot(pharmacyStock: PharmacyStock) {
    this.displayDeleteDialogueFromInfos(pharmacyStock.hhhId, pharmacyStock.lotNumber, pharmacyStock);
  }

  refreshAfterDelete(entity?: any): void {
    const index = this.values.findIndex((pharmacyStock: PharmacyStock) => pharmacyStock.hhhId === entity.hhhId);
    this.values.splice(index, 1);
  }


  filterUpdated() {
    this.page = 0;
    this.first = 0;
    this.filters = Object.assign(TableHeader.buildFilterData(this.stockColumnListHeader), TableHeader.buildFilterData(this.kitsColumnListHeader));
    this.getList();
    this.rollBack.saveGlobalFilter('pharmacyStockGlobalFIlter', this.pharmacyGlobalFilter);
  }

  get filterType() {
    return FilterType;
  }

  displayContent(col: any, colField: any, rowData: any): string {
    let content = colField;
    if (isNullOrUndefined(rowData[col.field]) && !col.displayContent) {
      content = '';
    } else if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (col.dateFormatter) {
      content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
    }
    return content;
  }

  displayFormDialogAddKit(pharmacyStock: PharmacyStock) {
    if (pharmacyStock.state !== 'AVAILABLE' || pharmacyStock.events === 'QUARANTINE' || pharmacyStock.state == null || pharmacyStock.events == null) {
      this.sharedService.showWarning(this.translateService.getTranslationString('MODULE_TRIALS_PHARMACY_TAB_STOCK_ADD_KIT_WARNING_MSG'));
    } else {
      const formComponent = PharmacyDispensationKitFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
      formComponent.formHeader = 'MODULE_TRIALS_PHARMACY_TAB_DISPENSATION_BUTTON_ADD_KIT';
      formComponent.trialHhhId = this.trialHhhId;
      formComponent.stockHhhId = pharmacyStock.hhhId;
      formComponent.displaySaveButton = false;
      formComponent.onAdd((kit: PharmacyDispensationKit) => {
        this.pharmacyDispensationKitService.add(kit).subscribe(() => {
          this.sharedService.showSuccess();
          this.getList();
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        });
      });
    }
  }

  displayFormDialogEditKit(pharmacyDispensationKit: PharmacyDispensationKit) {
    const formComponent = PharmacyDispensationKitFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.formHeader = 'MODULE_TRIALS_PHARMACY_TAB_DISPENSATION_BUTTON_EDIT_KIT';
    formComponent.trialHhhId = this.trialHhhId;
    formComponent.pharmacyDispensationKitGlobal = pharmacyDispensationKit;
    formComponent.displayKitList = false;
    formComponent.displaySaveButton = true;
    formComponent.onAdd((kit: PharmacyDispensationKit) => {
      this.pharmacyDispensationKitService.updateStock(kit).subscribe(
        () => {
          formComponent.display = false;
          this.getList();
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        });
    });
  }

  resetFilter() {
    this.filters = new PharmacyStockGlobalFilter();
    this.page = 0;
    this.first = 0;
    this.ngOnInit();
    this.getList();
  }

  isAdmin() {
    return localStorage.getItem(AUTHENTICATED_USER_ROLE) === Role.Admin;
  }

  displayDeleteKit(event?: any) {
    const deleteConfirmationComponent = DeleteConfirmationComponent.displayDeleteConfirmationDialogue(this.deletePopupContainer, this.cfr);
    deleteConfirmationComponent.hhhId = event.kit.hhhId;
    deleteConfirmationComponent.popupMessage = 'MODULE_TRIALS_PHARMACY_TAB_STOCK_DIALOG_WARNING_KIT_WILL_BE_DELETED';
    deleteConfirmationComponent.service = this.pharmacyDispensationKitService;
    deleteConfirmationComponent.onDelete(() => {
      this.values[event.lri].kits.splice(event.kri, 1);
    });
  }

  displayAddKitsHint() {
    this.sharedService.showWarning('lot non disponible');
  }

  displayBtn(btnId: string): boolean {
    if (!this.config || !this.config.buttons) {
      return false;
    }
    return this.config.displayBtn(btnId);
  }

  getBtnClass(): string {
    return this.config ? this.config.getBtnClass() : '';
  }
  getTdClass(): string {
    return this.kitsColumnListHeader.length === 0 ? 'empty-header' : '';
  }

  exportPharmacyStock(event: any): void {

    const request = new ExportRequestBuilder<PharmacyStockGlobalFilter>()
      .objectId(this.pharmacyHhhId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .filters(this.filters)
      .build();

    this.pharmacyStockService.exportPharmacyStocks(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }

}

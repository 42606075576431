import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'ih-page-lock-confirmation',
  templateUrl: './page-lock-confirmation.component.html',
  styleUrls: ['./page-lock-confirmation.component.css']
})
export class PageLockConfirmationComponent implements OnInit {

  displayLockConfirmation: boolean;
  callback: (isLocked: boolean) => void;

  constructor() {
  }

  public static displayFormDialog(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(PageLockConfirmationComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.displayLockConfirmation = true;
  }

  confirm() {
    this.displayLockConfirmation = false;
    this.callback(true);
  }

  onLock(callback: (isLocked: boolean) => void) {
    this.callback = callback;
  }
}

import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import {Provider} from '../models/provider';
import {ProviderService} from '../services/provider.service';
import {ProviderTypeDto} from '../models/provider-type-dto';
import {PromoterType} from '../models/promoter-type';
import {Data} from '../../shared/entity/data';
import {SharedService} from '../../shared/services/shared.service';
import {FormComponent} from '../../shared/component/form/form.component';
import {ProviderBasicInformation} from '../models/provider-basic-information';
import {isNullOrUndefined} from 'util';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {ProviderAppData} from '../provider-app-data';
import {FinancialEntity} from '../models/financial-entity';
import {FinancialEntityService} from '../services/financial-entity.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Subscription} from 'rxjs';
import * as Util from 'util';
import {PromoterService} from '../services/promoter.service';
import {LabelValue} from '../../shared/entity/label-value';

@Component({
  selector: 'ih-provider-form',
  templateUrl: './provider-form.component.html',
  styleUrls: ['./provider-form.component.css']
})
export class ProviderFormComponent extends FormComponent<Provider> implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.PROVIDER_ADD_FORM;
  config: FormConfig = new FormConfig();
  financialEntitiesEnabled = true;
  promoterTypeEnabled = true;
  disabledAddButton: boolean;

  providerTypes: ProviderTypeDto[];
  promoterTypes: LabelValue[] = [];
  provider: ProviderBasicInformation;
  displayPromoterTypeFields: boolean;
  displayProviderTypeInForm: boolean;
  displayProviderTypeDropdown = true;
  otherOptionSelected: boolean;
  promoteurOptionSelected: boolean;
  providerTypePath: string;
  tvaOptions = Data.TvaOptions;
  providerAlreadyExist = false;
  financialEntities: FinancialEntity[] = [];
  financialEntitiesSubscription: Subscription;

  constructor(
      private providerService: ProviderService,
      private sharedService: SharedService,
      private promoterService: PromoterService,
      private dynamicConfigService: DynamicConfigService,
      private financialEntityService: FinancialEntityService,
      private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  public static display(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ProviderFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  static getAddTitle(): string {
    return 'MODULE_PROVIDERS_GENERAL_ADD_NEW';
  }

  static getUpdateTitle(): string {
    return 'MODULE_PROVIDERS_GENERAL_UPDATE';
  }

  private static searchList(list: any[], property: string, value: any): any {
    for (const item of list) {
      if (item[property] === value) {
        return item;
      }
    }
    return null;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    if (!isNullOrUndefined(this.provider.hhhId)) {
      this.getProviderExtraInfos();
    }
    this.displayPromoterTypeFields = null ? true : this.displayPromoterTypeFields;
    this.providerService.getProviderTypes().subscribe((providerTypes: ProviderTypeDto[]) => {
      this.providerTypes = providerTypes;
    });
    this.getPromoterType();
    this.getFinancialEntities();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.financialEntitiesEnabled = this.dynamicConfigService.getProperty(PropertyEnum.financialEntitiesEnabled);
      this.promoterTypeEnabled = this.dynamicConfigService.getProperty(PropertyEnum.promoterTypeEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, ProviderAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getFinancialEntities(): void {
    if (Util.isNullOrUndefined(this.provider.hhhId)) {
      return;
    }
    this.financialEntitiesSubscription = this.financialEntityService.getFinancialEntitiesByProvider(this.provider.hhhId).subscribe(entities => {
      this.financialEntities = entities;
    });
  }

  getProviderExtraInfos() {
    this.providerService.getProviderExtraInfos(this.provider.hhhId).subscribe(res => {
      this.provider.providerExtraInfos = res;
    });
  }

  getPromoterType() {
    this.promoterService.getPromoterTypeDtosList().subscribe(res => {
      this.promoterTypes = res;
      this.promoterTypes.splice(0, 0, {value: 0, label: null});
    });
  }

  submitProvider() {
    this.submitted = true;
    if (this.addForm.invalid) {
      return;
    }
    if (!this.provider.hhhId) {
      this.addProviderAttempt();
    } else {
      this.updateProvider();
    }
  }

  private addProviderAttempt() {
    this.providerService.doesProviderExist(this.provider.name).subscribe(exist => {
      if (!exist) {
        this.addProvider();
      } else {
        this.providerAlreadyExist = true;
      }
    });
  }

  private addProvider() {
    this.submitted = true;
    if (!this.provider.isValid(this.config, this.promoteurOptionSelected, this.otherOptionSelected)) {
      this.disabledAddButton = false;
      return;
    }
    this.providerService.add(this.provider).subscribe(res => {
          this.provider.hhhId = res;
          const newProvider: Provider = Provider.fromBasicInformation(this.provider);
          newProvider.providerType = ProviderFormComponent.searchList(this.providerTypes, 'hhhId', newProvider.providerTypeHhhId);
          if (newProvider.providerType.hhhId === Data.promoterTypeId) {
            newProvider.promoterType = ProviderFormComponent.searchList(this.promoterTypes, 'hhhId', newProvider.promoterTypeHhhId);
          }
          newProvider.contactableMembers = [];
          this.sharedService.showSuccess();
          this.sharedService.setInformationInLocalStorage('Prestataires', 'Ajouter', this.provider.name);
          this.callback(newProvider);
        },
        error => {
          this.sharedService.showFailure('UI_FORM_ERROR_FAILED');
          console.error(error);
        }
    );
    this.display = false;
  }

  setProvider(provider: any) {
    if (provider) {
      if (provider.providerType.nameFr === 'Promoteur') {
        this.displayProviderTypeDropdown = false;
      } else {
        this.displayProviderTypeInForm = false;
      }
      this.formHeader = ProviderFormComponent.getUpdateTitle();
      this.provider = new ProviderBasicInformation().fromProvider(provider);
    } else {
      this.provider = new ProviderBasicInformation();
      this.formHeader = ProviderFormComponent.getAddTitle();
    }
  }


  private updateProvider() {
    this.providerService.updateProvider(this.provider).subscribe(res => {
      this.sharedService.showSuccess();
      this.sharedService.setInformationInLocalStorage('Prestataires', 'Éditer', this.provider.name);
      this.callback(res);
    }, error => {
      this.sharedService.showFailure('UI_FORM_ERROR_FAILED');
      console.error('An error has occurred when trying to update the provider.' + error);
    });
    this.display = false;
  }


  displayFormDialogue() {
    this.financialEntities.push(new FinancialEntity());
  }

  submitFinancialEntity(financialEntity: FinancialEntity) {
    if (isNullOrUndefined(this.provider.hhhId)) {
      this.sharedService.showWarning();
      return;
    }
    financialEntity.providerId = this.provider.hhhId;
    if (isNullOrUndefined(financialEntity.hhhId)) {
      this.financialEntityService.createFinancialEntity(financialEntity).subscribe((res) => {
        this.sharedService.showSuccess();
      }, (error) => {
        this.sharedService.showFailure('UI_FORM_ERROR_FAILED');

      });
    } else {
      this.financialEntityService.updateFinancialEntity(financialEntity).subscribe((res) => {
        this.sharedService.showSuccess();
      }, (error) => {
        this.sharedService.showFailure('UI_FORM_ERROR_FAILED');

      });
    }
  }


  providerTypeChanged(selectedItem: any): void {
    this.otherOptionSelected = false;
    this.promoteurOptionSelected = false;
    if (!selectedItem) {
      return;
    }
    if (selectedItem.label === 'Autre') {
      this.otherOptionSelected = true;
      this.promoteurOptionSelected = false;
      return;
    }
    if (selectedItem.label === 'Promoteur') {
      this.promoteurOptionSelected = true;
      this.otherOptionSelected = false;
      return;
    }
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  ngOnDestroy(): void {
    if (this.financialEntitiesSubscription) {
      this.financialEntitiesSubscription.unsubscribe();
    }
  }
}

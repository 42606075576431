import {StateType} from '../../../shared/entity/payment-type.enum';

export class SwitchState {
  public visitHhhId: number;
  public stateTypeEnum: StateType;
  public stateFromHhhId: number;
  public stateToHhhId: number;

  constructor(visitHhhId: number, stateTypeEnum: StateType, stateFromHhhId: number, stateToHhhId: number) {
    this.visitHhhId = visitHhhId;
    this.stateTypeEnum = stateTypeEnum;
    this.stateFromHhhId = stateFromHhhId;
    this.stateToHhhId = stateToHhhId;
  }
}

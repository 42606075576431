import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FR} from '../../../../shared/entity/calendar-language';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {TrialService} from '../../../services/trial.service';
import {DataService} from '../../../../shared/services/data-service';
import {Router} from '@angular/router';
import {SharedService} from '../../../../shared/services/shared.service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {TrialAdministrativeCecInformation} from '../../../models/trial-administrative-cec-information';
import {TrialAdministrativeCecService} from '../../../services/trial-administrative-cec.service';
import {TrialFollowUp} from '../../../models/trial-follow-up';
import { Util } from 'src/app/helpers/util';


@Component({
  selector: 'ih-administrative-trial-follow-up-information',
  templateUrl: './trial-administrative-follow-up-information.component.html',
  styleUrls: ['./trial-administrative-follow-up-information.component.css']
})
export class TrialAdministrativeFollowupInformationComponent implements OnInit, OnDestroy {
  target = DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_TRIAL_FOLLOWUP_INFORMATION_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  cecInformation: TrialAdministrativeCecInformation = new TrialAdministrativeCecInformation();
  trialId: number;
  locale = FR;
  submitted = false;

  constructor(private trialAdministrativeCecService: TrialAdministrativeCecService,
              public trialService: TrialService,
              private dataService: DataService,
              private router: Router,
              private cfr: ComponentFactoryResolver,
              private sharedService: SharedService,
              private canDeactivateService: CanDeactivateService,
              private dynamicConfigService: DynamicConfigService) {
    const url = this.router.parseUrl(this.router.url);
    this.trialId = url.queryParams.hhhId;
  }

  ngOnInit() {
    this.initProperties();
    const url = this.router.parseUrl(this.router.url);
    this.trialId = url.queryParams.hhhId;
    this.trialService.trialHhhId = url.queryParams.hhhId;
    this.getTrialAdministrativeTrialFollowupInformation();
  }

  initProperties(): void {
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  isModeEdit() {
    return false;
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  getTrialAdministrativeTrialFollowupInformation() {
    this.trialAdministrativeCecService.getAdministrativeCecInformation(this.trialId).subscribe(
      res => {
        this.cecInformation = res;
        if (res.trialFollowUp.length === 0) {
          this.addTrialFollowUpField();
        }
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred in trialAdministrative$.' + error);
      });
  }

  addTrialFollowUpField() {
    this.cecInformation.trialFollowUp.push(new TrialFollowUp(this.cecInformation.hhhId));
  }

  deleteTrialFollowUp(i: number) {
    this.cecInformation.trialFollowUp.splice(i, 1);
  }

  updateTrialFollowUpInformation() {
    for (const followUp of this.cecInformation.trialFollowUp) {
      const tf = new TrialFollowUp(this.cecInformation.hhhId);
      tf.date = followUp.date;
      tf.comment = followUp.comment;
      if (!tf.isValid(this.config)) {
        return;
      }
    }
    this.cecInformation.trialHhhId = this.trialId;
    this.trialAdministrativeCecService.updateAdministrativeCecInformation(this.cecInformation).subscribe((res) => {
      this.sharedService.showSuccess();
      this.canDeactivateService.canBeDeactivated = true;
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred in trialAdministrative$.' + error);
    });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);
}

import {NgModule} from '@angular/core';
import {TrialRoutingModule} from './trial-routing.module';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {TrialDetailsComponent} from './component/trial-details/trial-details.component';
import {TrialListComponent} from './component/trial-list/trial-list.component';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {TrackingTableComponent} from './component/trial-track/tracking-table/tracking-table.component';
import {
  TheoreticalCalendarComponent
} from './component/theoretical-calendar/theoretical-calendar/theoretical-calendar.component';
import {TrialSiteListComponent} from './component/trial-site/trial-site-list/trial-site-list.component';
import {
  TheoreticalVisitComponent
} from './component/theoretical-calendar/theoretical-visit/theoretical-visit.component';
import {ProviderFormComponent} from '../provider/provider-form/provider-form.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {NgxHorizontalTimelineModule} from 'ngx-horizontal-timeline';
import {MglTimelineModule} from 'angular-mgl-timeline';
import {HorizontalTimelineComponent} from './component/horizontal-timeline/horizontal-timeline.component';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {AmendmentVerificationComponent} from '../modals/amendment-verification/amendment-verification.component';
import {SharedModule} from '../shared/shared.module';
import {
  TrialIdentificationDialogComponent
} from './component/trial-identification/trial-identification-dialog/trial-identification-dialog.component';
import {VisitTrackingComponent} from './component/trial-track/visit-tracking/visit-tracking.component';
import {VisitTrackingRowComponent} from './component/trial-track/visit-tracking-row/visit-tracking-row.component';
import {
  TheoreticalVisitAccordionTabComponent
} from './component/theoretical-calendar/theoretical-visit-accordion-tab/theoretical-visit-accordion-tab.component';
import {TrackingBlocComponent} from './component/trial-track/tracking-bloc/tracking-bloc.component';
import {
  TheoreticalCalendarContainerComponent
} from './component/theoretical-calendar/theoretical-calendar-container/theoretical-calendar-container.component';
import {
  SyntheticTheoreticalCalendarComponent
} from './component/theoretical-calendar/synthetic-theoretical-calendar/synthetic-theoretical-calendar.component';
import {
  TheoreticalCalendarActsComponent
} from './component/theoretical-calendar/theoretical-calendar-acts/theoretical-calendar-acts.component';
import {
  TheoreticalCalendarReferenceActsComponent
} from './component/theoretical-calendar/theoretical-calendar-reference-acts/theoretical-calendar-reference-acts.component';
import {VisitComponent} from './component/inclusions/inclusion-details/calendar-details/visit/visit.component';
import {AmendmentDialogComponent} from './component/theoretical-calendar/amendment-dialog/amendment-dialog.component';
import {
  TheoreticalCalendarActsAddComponent
} from './component/theoretical-calendar/theoretical-calendar-acts-add/theoretical-calendar-acts-add.component';
import {
  TheoreticalCalendarActsV2Component
} from './component/theoretical-calendar/theoretical-calendar-acts-v2/theoretical-calendar-acts-v2.component';
import {
  TheoreticalCalendarActVisitCellComponent
} from './component/theoretical-calendar/theoretical-calendar-act-visit-cell/theoretical-calendar-act-visit-cell.component';
import {
  TheoreticalCalendarActVisitContainerComponent
} from './component/theoretical-calendar/theoretical-calendar-act-visit-container/theoretical-calendar-act-visit-container.component';
import {
  TrialDesignFormComponent
} from './component/trial-identification/design/trial-design-form/trial-design-form.component';
import {
  TheoreticalCalendarAdditionalCostComponent
} from './component/theoretical-calendar/theoretical-calendar-additional-cost/theoretical-calendar-additional-cost.component';
import {
  InclusionInformationArmComponent
} from './component/inclusions/inclusion-information-arm/inclusion-information-arm.component';
import {
  DesignIdentificationInformationComponent
} from './component/trial-identification/design/design-identification-information/design-identification-information.component';
import {
  AmendmentIdentificationInformationComponent
} from './component/trial-identification/version-protocol/amendment-identification-information/amendment-identification-information.component';
import {
  InclusionExclusionCriteriaComponent
} from './component/trial-identification/inclusion-exclusion/inclusion-exclusion-criteria/inclusion-exclusion-criteria.component';
import {
  DisciplineIdentificationInformationComponent
} from './component/trial-identification/discipline-identification-information/discipline-identification-information.component';
import {
  AmendmentConsentsComponent
} from './component/trial-identification/version-protocol/amendment-consents/amendment-consents.component';
import {
  AmendmentProtocolsComponent
} from './component/trial-identification/version-protocol/amendment-protocols/amendment-protocols.component';
import {
  AmendmentConsentFormComponent
} from './component/trial-identification/version-protocol/amendment-consent-form/amendment-consent-form.component';
import {
  OtherAmendedFormComponent
} from './component/trial-administrative/other-amended-form/other-amended-form.component';
import {
  TrialAdministrativeFeasibilityInformationComponent
} from './component/trial-administrative/trial-administrative-feasibility-information/trial-administrative-feasibility-information.component';
import {
  OtherTheoreticalInvoiceDateComponent
} from '../modals/other-theoretical-invoice-date/other-theoretical-invoice-date.component';
import {
  TrialAdministrativeConventionInformationComponent
} from './component/trial-administrative/trial-administrative-convention-informations/trial-administrative-convention-information.component';
import {TrialActsGridComponent} from './component/trial-additionnal-costs/trial-acts-grid/trial-acts-grid.component';
import {
  TrialCounterpartComponent
} from './component/trial-additionnal-costs/trial-counter-parts/trial-counterpart.component';
import {
  TrialFixedCostsComponent
} from './component/trial-additionnal-costs/trial-fixed-costs/trial-fixed-costs.component';
import {
  TrialOperationalActsComponent
} from './component/trial-additionnal-costs/trial-operational-acts/trial-operational-acts.component';
import {
  OperationalTrialActsComponent
} from './component/trial-additionnal-costs/operational-trial-acts/operational-trial-acts.component';
import {
  OperationalTrialActFormComponent
} from './component/trial-additionnal-costs/operational-trial-act-form/operational-trial-act-form.component';
import {AdditionalCostsModule} from '../additional-costs/additional-costs.module';
import {
  ContactListInformationComponent
} from './component/trial-contact/contact-list-information/contact-list-information.component';
import {
  DeleteFileOrFolderConfirmationComponent
} from './component/modals/delete-file-or-folder-confirmation/delete-file-or-folder-confirmation.component';
import {FileListComponent} from './component/file-list/file-list.component';
import {AdverseEffectComponent} from './component/inclusions/patient-follow-up/adverse-effect/adverse-effect.component';
import {
  ConcomitantTreatmentComponent
} from './component/inclusions/patient-follow-up/concomitant-treatment/concomitant-treatment.component';
import {
  FollowUpPatientContainerComponent
} from './component/inclusions/patient-follow-up/follow-up-patient-container/follow-up-patient-container.component';
import {VitalSignsComponent} from './component/inclusions/patient-follow-up/vital-signs/vital-signs.component';
import {
  CalendarInclusionComponent
} from './component/inclusions/inclusion-details/calendar-details/calendar/calendar-inclusion.component';
import {InclusionListComponent} from './component/inclusions/inclusion-list/inclusion-list.component';
import {
  InclusionDetailsContainerComponent
} from './component/inclusions/inclusion-details/inclusion-details-container/inclusion-details-container.component';
import {
  VisitDetailComponent
} from './component/inclusions/inclusion-details/calendar-details/visit-detail/visit-detail.component';
import {
  VisitAccordionTabComponent
} from './component/inclusions/inclusion-details/calendar-details/visit-accordion-tab/visit-accordion-tab.component';
import {
  VisitStateUpdateConfirmationComponent
} from './component/inclusions/inclusion-details/calendar-details/visit-state-update-confirmation/visit-state-update-confirmation.component';
import {
  VisitActStateUpdateConfirmationComponent
} from './component/inclusions/inclusion-details/calendar-details/visit-act-state-update-confirmation/visit-act-state-update-confirmation.component';
import {
  RealVisitDateUpdateConfirmationComponent
} from '../modals/real-visit-date-update-confirmation/real-visit-date-update-confirmation.component';
import {
  VisitDoseFormComponent
} from './component/inclusions/inclusion-details/calendar-details/visit-dose-form/visit-dose-form.component';
import {
  VisitsPharmacyDispensationKitFormComponent
} from './component/inclusions/inclusion-details/calendar-details/visits-pharmacy-dispensation-kit-form/visits-pharmacy-dispensation-kit-form.component';
import {PatientDetailFormComponent} from './component/trial-track/patient-detail-form/patient-detail-form.component';
import {TrialImportComponent} from './component/trial-import/trial-import.component';
import {
  TrialSiteDetailsContainerComponent
} from './component/trial-site/trial-site-details-container/trial-site-details-container.component';
import {
  TrialPharmacyContainerComponent
} from './component/trial-pharmacy/trial-pharmacy-container/trial-pharmacy-container.component';
import {
  TrialPharmacyInformationsComponent
} from './component/trial-pharmacy/trial-pharmacy-informations/trial-pharmacy-informations.component';
import {
  TrialPharmacyStockComponent
} from './component/trial-pharmacy/trial-pharmacy-stock/trial-pharmacy-stock.component';
import {
  TrialPharmacyDispensationComponent
} from './component/trial-pharmacy/trial-pharmacy-dispensation/trial-pharmacy-dispensation.component';
import {
  TrialPharmacyFilesComponent
} from './component/trial-pharmacy/trial-pharmacy-files/trial-pharmacy-files.component';
import {
  PharmacyAddOneFieldFormComponent
} from './component/trial-pharmacy/pharmacy-informations-dose-form/pharmacy-add-one-field-form.component';
import {
  PharmacyStockLotFormComponent
} from './component/trial-pharmacy/pharmacy-stock-lot-form/pharmacy-stock-lot-form.component';
import {
  PharmacyDispensationKitFormComponent
} from './component/trial-pharmacy/pharmacy-dispensation-kit-form/pharmacy-dispensation-kit-form.component';
import {
  TheoreticalVisitActUpdateFormComponent
} from './component/theoretical-calendar/theoretical-visit-act-update-form/update-theoretical-visit-act-form.component';
import {
  TheoreticalVisitDuplicationFormComponent
} from './component/theoretical-calendar/theoretical-visit-duplication-form/theoretical-visit-duplication-form.component';
import {
  VitalSignsFormComponent
} from './component/inclusions/patient-follow-up/vital-signs-form/vital-signs-form.component';
import {VisitTrackingFormComponent} from './component/trial-track/visit-tracking-form/visit-tracking-form.component';
import {
  VisitFormComponent
} from './component/inclusions/inclusion-details/calendar-details/visit-form/visit-form.component';
import {
  TheoreticalVisitFormComponent
} from './component/theoretical-calendar/theoretical-visit-form/theoretical-visit-form.component';
import {TrialActFormComponent} from './component/trial-additionnal-costs/trial-act-form/trial-act-form.component';
import {TrialContactFormComponent} from './component/trial-contact/trial-contact-form/trial-contact-form.component';
import {
  TrialContactUpdateFormComponent
} from './component/trial-contact/trial-contact-update-form/trial-contact-update-form.component';
import {
  TrialCounterpartFormComponent
} from './component/trial-additionnal-costs/trial-counter-part-form/trial-counterpart-form.component';
import {
  TrialFixedCostFormComponent
} from './component/trial-additionnal-costs/trial-fixed-cost-form/trial-fixed-cost-form.component';
import {
  VisitActUpdateFormComponent
} from './component/inclusions/inclusion-details/calendar-details/visit-act-update-form/visit-act-update-form.component';
import {
  VisitDuplicationFormComponent
} from './component/inclusions/inclusion-details/calendar-details/visit-duplication-form/visit-duplication-form.component';
import {TrialSiteFormComponent} from './component/trial-site/trial-site-form/trial-site-form.component';
import {
  TrialIdentificationInformationComponent
} from './component/trial-identification/trial-identification-information/trial-identification-information.component';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';
import {
  TrialAdministrativeRegulatoryInformationComponent
} from './component/trial-administrative/trial-administrative-regulatory-information/trial-administrative-regulatory-information.component';
import {
  TrialAdministrativeInformationContainerComponent
} from './component/trial-administrative/trial-administrative-information-container/trial-administrative-information-container.component';
import {
  DateLastAmendmentCPPFormComponent
} from './component/trial-administrative/date-last-amendment-cpp-form/date-last-amendment-cpp-form.component';
import {
  DateLastAmendmentFormComponent
} from './component/trial-administrative/date-last-amendment-form/date-last-amendment-form.component';
import {
  InclusionInformationVersionProtocolComponent
} from './component/inclusions/inclusion-information-version-protocol/inclusion-information-version-protocol.component';
import {
  TrialAdministrativeDatesInformationComponent
} from './component/trial-administrative/trial-administrative-dates-information/trial-administrative-dates-information.component';
import {
  TrialInformationGenericComponent
} from './component/trial-identification/trial-information-generic/trial-information-generic.component';
import {
  TrialPersonnelInformationComponent
} from './component/trial-identification/personnel/trial-personnel-information/trial-personnel-information.component';
import {
  TrialPersonnelProviderInformationComponent
} from './component/trial-identification/personnel/trial-personnel-provider-information/trial-personnel-provider-information.component';
import {
  InclusionExclusionCriteriaDisciplineComponent
} from './component/trial-identification/inclusion-exclusion/inclusion-exclusion-criteria-discipline/inclusion-exclusion-criteria-discipline.component';
import {
  TrialTreatmentMethodologieComponent
} from './component/trial-identification/trial-treatment-methodologie/trial-treatment-methodologie.component';
import {
  ArmIdentificationInformationComponent
} from './component/trial-identification/arm/arm-identification-information/arm-identification-information.component';
import {AddArmFormComponent} from './component/trial-identification/arm/add-arm-form/add-arm-form.component';
import {
  ChooseProtocolVersionComponent
} from './component/modals/choose-protocol-version/choose-protocol-version.component';
import {
  TrialAdministrativeCecInformationComponent
} from './component/trial-administrative/trial-administrative-cec-information/trial-administrative-cec-information.component';
import {
  AdverseEffectFormComponent
} from './component/inclusions/patient-follow-up/adverse-effect-form/adverse-effect-form.component';
import {
  ConcomitantTreatmentFormComponent
} from './component/inclusions/patient-follow-up/concomitant-treatment-form/concomitant-treatment-form.component';
import {
  AmendmentProtocolFormComponent
} from './component/trial-identification/version-protocol/amendment-protocol-form/amendment-protocol-form.component';
import {
  SendTrialToEstablishmentFormComponent
} from './component/trial-identification/send-trial-to-establishment-form/send-trial-to-establishment-form.component';
import {
  TrialIdentificationInformationContainerComponent
} from './component/trial-identification/trial-identification-information-container/trial-identification-information-container.component';
import {ConsentmentFormComponent} from './component/inclusions/consentment-form/consentment-form.component';
import {
  OperationalActUpdateFormComponent
} from './component/inclusions/inclusion-details/calendar-details/operational-act-update-form/operational-act-update-form.component';
import {
  ImportCalendarFormComponent
} from './component/theoretical-calendar/import-calendar-form/import-calendar-form.component';
import {ImportPatientFormComponent} from './component/inclusions/import-patient-form/import-patient-form.component';
import {
  ImportRealDatesVisitsPatientsFormComponent
} from './component/trial-track/import-real-dates-visits-patients-form/import-real-dates-visits-patients-form.component';
import {DataDocumentModule} from '../data-document/data-document.module';
import {
  TrialAdministrativeLogisticInformationComponent
} from './component/trial-administrative/trial-administrative-logistic-information/trial-administrative-logistic-information.component';
import {
  TrialAdministrativeFollowupInformationComponent
} from './component/trial-administrative/trial-administrative-follow-up-information/trial-administrative-follow-up-information.component';
import {InvoiceBudgetComponent} from './component/invoice-monitor/invoice-budget/invoice-budget.component';
import {InvoiceTrackingComponent} from './component/invoice-monitor/invoice-tracking/invoice-tracking.component';
import {InvoiceMonitorComponent} from './component/invoice-monitor/invoice-monitor/invoice-monitor.component';
import {BudgetTrackingComponent} from './component/invoice-monitor/budget-tracking/budget-tracking.component';
import {
  DateLastCsetAgreementFormComponent
} from './component/trial-administrative/date-last-cset-agreement-form/date-last-cset-agreement-form.component';
import {
  DateLastDrcAgreementFormComponent
} from './component/trial-administrative/date-last-drc-agreement-form/date-last-drc-agreement-form.component';
import {
  TrialAdministrativeComopInformationComponent
} from './component/trial-administrative/trial-administrative-comop-information/trial-administrative-comop-information.component';

@NgModule({
    imports: [
        TrialRoutingModule,
        DynamicConfigModule,
        SharedModule,
        AdditionalCostsModule,
        NgCircleProgressModule.forRoot({
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300,
        }),
        NgxHorizontalTimelineModule,
        MglTimelineModule,
        AngularMultiSelectModule,
        SlickCarouselModule,
        DataDocumentModule
    ],
  declarations: [
    // general
    DashboardComponent,
    FileListComponent,
    HorizontalTimelineComponent,
    TrialDetailsComponent,
    TrialIdentificationDialogComponent,
    TrialImportComponent,
    TrialListComponent,


    // inclusion
    CalendarInclusionComponent,
    InclusionListComponent,
    InclusionDetailsContainerComponent,
    VisitComponent,
    OperationalActUpdateFormComponent,
    VisitFormComponent,
    VisitDetailComponent,
    VisitAccordionTabComponent,
    VisitStateUpdateConfirmationComponent,
    VisitActStateUpdateConfirmationComponent,
    RealVisitDateUpdateConfirmationComponent,
    VisitDoseFormComponent,
    VisitsPharmacyDispensationKitFormComponent,
    VisitActUpdateFormComponent,
    VisitDuplicationFormComponent,
    InclusionInformationArmComponent,
    InclusionInformationVersionProtocolComponent,
    ImportPatientFormComponent,

    // patient-follow-up
    AdverseEffectComponent,
    ConcomitantTreatmentComponent,
    FollowUpPatientContainerComponent,
    AdverseEffectFormComponent,
    VitalSignsComponent,
    VitalSignsFormComponent,
    ConcomitantTreatmentFormComponent,

    // theoretical-calendar
    AmendmentDialogComponent,
    SyntheticTheoreticalCalendarComponent,
    TheoreticalCalendarComponent,
    TheoreticalCalendarActVisitCellComponent,
    TheoreticalCalendarActVisitContainerComponent,
    TheoreticalCalendarActsComponent,
    TheoreticalCalendarActsAddComponent,
    TheoreticalCalendarActsV2Component,
    TheoreticalCalendarAdditionalCostComponent,
    TheoreticalCalendarContainerComponent,
    TheoreticalCalendarReferenceActsComponent,
    TheoreticalVisitComponent,
    TheoreticalVisitAccordionTabComponent,
    TheoreticalVisitActUpdateFormComponent,
    TheoreticalVisitDuplicationFormComponent,
    TheoreticalVisitFormComponent,
    ImportCalendarFormComponent,

    // trial-additional-costs
    TrialActsGridComponent,
    TrialCounterpartComponent,
    TrialCounterpartFormComponent,
    TrialFixedCostsComponent,
    TrialFixedCostFormComponent,
    TrialOperationalActsComponent,
    OperationalTrialActsComponent,
    OperationalTrialActFormComponent,
    TrialActFormComponent,

    // trial-administrative
    TrialAdministrativeInformationContainerComponent,
    TrialAdministrativeRegulatoryInformationComponent,
    TrialAdministrativeDatesInformationComponent,
    TrialAdministrativeFeasibilityInformationComponent,
    TrialAdministrativeCecInformationComponent,
    TrialAdministrativeConventionInformationComponent,
    TrialAdministrativeLogisticInformationComponent,
    TrialAdministrativeComopInformationComponent,
    DateLastAmendmentCPPFormComponent,
    DateLastAmendmentFormComponent,
    OtherAmendedFormComponent,
    DateLastDrcAgreementFormComponent,
    DateLastCsetAgreementFormComponent,
    TrialAdministrativeFollowupInformationComponent,

    // trial-contact
    ContactListInformationComponent,
    TrialContactFormComponent,
    TrialContactUpdateFormComponent,

    // trial-identification
    TrialIdentificationInformationContainerComponent,
    TrialInformationGenericComponent,
    TrialIdentificationInformationComponent,
    TrialPersonnelInformationComponent,
    TrialPersonnelProviderInformationComponent,
    InclusionExclusionCriteriaComponent,
    InclusionExclusionCriteriaDisciplineComponent,
    TrialTreatmentMethodologieComponent,
    DisciplineIdentificationInformationComponent,
    ArmIdentificationInformationComponent,
    AddArmFormComponent,
    SendTrialToEstablishmentFormComponent,
    // trial-version-protocol
    AmendmentIdentificationInformationComponent,
    AmendmentConsentsComponent,
    AmendmentConsentFormComponent,
    AmendmentProtocolsComponent,
    AmendmentProtocolFormComponent,
    ConsentmentFormComponent,
    // Design
    DesignIdentificationInformationComponent,
    TrialDesignFormComponent,

    // trial-pharmacy
    TrialPharmacyContainerComponent,
    TrialPharmacyInformationsComponent,
    TrialPharmacyStockComponent,
    TrialPharmacyDispensationComponent,
    TrialPharmacyFilesComponent,
    PharmacyStockLotFormComponent,
    PharmacyAddOneFieldFormComponent,
    PharmacyDispensationKitFormComponent,

    // trial-site
    TrialSiteListComponent,
    TrialSiteDetailsContainerComponent,
    TrialSiteFormComponent,

    // invoice-monitor
    // trial-invoice-monitor
    InvoiceMonitorComponent,
    InvoiceTrackingComponent,
    InvoiceBudgetComponent,
    BudgetTrackingComponent,

    // trial-track
    TrackingBlocComponent,
    TrackingTableComponent,
    VisitTrackingComponent,
    VisitTrackingRowComponent,
    VisitTrackingFormComponent,
    PatientDetailFormComponent,
    ImportRealDatesVisitsPatientsFormComponent,

    // Modals
    DeleteFileOrFolderConfirmationComponent,
    ChooseProtocolVersionComponent,
  ],
  bootstrap: [TrackingTableComponent],
  entryComponents: [
    // general
    TrialIdentificationDialogComponent,
    TrialImportComponent,

    // inclusion
    CalendarInclusionComponent,
    OperationalActUpdateFormComponent,
    RealVisitDateUpdateConfirmationComponent,
    VisitFormComponent,
    VisitDoseFormComponent,
    VisitsPharmacyDispensationKitFormComponent,
    VisitDetailComponent,
    VisitsPharmacyDispensationKitFormComponent,
    VisitActUpdateFormComponent,
    VisitDuplicationFormComponent,
    InclusionInformationArmComponent,
    InclusionInformationVersionProtocolComponent,
    ImportPatientFormComponent,

    // patient-follow-up
    FollowUpPatientContainerComponent,
    AdverseEffectComponent,
    VitalSignsComponent,
    ConcomitantTreatmentComponent,
    VitalSignsFormComponent,
    AdverseEffectFormComponent,
    ConcomitantTreatmentFormComponent,

    // theoretical-calendar
    TheoreticalVisitActUpdateFormComponent,
    TheoreticalVisitDuplicationFormComponent,
    TheoreticalVisitFormComponent,
    ImportCalendarFormComponent,

    // trial-additional-costs
    OperationalTrialActsComponent,
    OperationalTrialActFormComponent,
    TrialFixedCostFormComponent,
    TrialCounterpartFormComponent,
    TrialActFormComponent,

    // trial-administrative
    TrialAdministrativeRegulatoryInformationComponent,
    TrialAdministrativeDatesInformationComponent,
    TrialAdministrativeFeasibilityInformationComponent,
    TrialAdministrativeCecInformationComponent,
    TrialAdministrativeConventionInformationComponent,
    TrialAdministrativeComopInformationComponent,
    DateLastAmendmentCPPFormComponent,
    DateLastAmendmentFormComponent,
    OtherAmendedFormComponent,
    TrialAdministrativeFollowupInformationComponent,
    DateLastCsetAgreementFormComponent,
    DateLastDrcAgreementFormComponent,

    // trial-contact
    TrialContactFormComponent,
    TrialContactUpdateFormComponent,

    // trial-identification
    TrialIdentificationInformationContainerComponent,
    TrialInformationGenericComponent,
    TrialIdentificationInformationComponent,
    TrialPersonnelInformationComponent,
    TrialPersonnelProviderInformationComponent,
    InclusionExclusionCriteriaComponent,
    InclusionExclusionCriteriaDisciplineComponent,
    TrialTreatmentMethodologieComponent,
    DisciplineIdentificationInformationComponent,
    ArmIdentificationInformationComponent,
    AddArmFormComponent,
    SendTrialToEstablishmentFormComponent,
    // version-protocol
    AmendmentConsentsComponent,
    AmendmentConsentFormComponent,
    AmendmentProtocolsComponent,
    AmendmentProtocolFormComponent,
    ConsentmentFormComponent,
    // Design
    DesignIdentificationInformationComponent,
    TrialDesignFormComponent,

    // trial-pharmacy
    PharmacyAddOneFieldFormComponent,
    PharmacyStockLotFormComponent,
    PharmacyDispensationKitFormComponent,

    // trial-site
    TrialSiteFormComponent,

    // trial-track
    TrackingBlocComponent,
    VisitTrackingFormComponent,
    PatientDetailFormComponent,
    ImportRealDatesVisitsPatientsFormComponent,

    ProviderFormComponent,
    AmendmentVerificationComponent,
    TheoreticalVisitComponent,
    TheoreticalCalendarReferenceActsComponent,
    AmendmentDialogComponent,
    TheoreticalCalendarActsAddComponent,
    OtherTheoreticalInvoiceDateComponent,
    // Modals
    DeleteFileOrFolderConfirmationComponent,
    ChooseProtocolVersionComponent
  ],
  exports: [
    TheoreticalVisitComponent,
    VisitComponent,
    TrialPersonnelProviderInformationComponent]
})
export class TrialModule {
}

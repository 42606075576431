import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Phone} from '../../shared/entity/phone';
import {Email} from '../../shared/entity/email';
import {Fax} from '../../shared/entity/fax';
import {Address} from '../../shared/entity/address';
import {Contact} from '../../dynamic-config/exported/contact-list/contact';
import {Country} from '../../shared/entity/country';
import {SharedService} from '../../shared/services/shared.service';
import {ContactService} from '../../shared/services/contact.service';
import {SelectItem} from 'primeng/api';
import {NgForm} from '@angular/forms';
import {FormComponent} from '../../shared/component/form/form.component';
import {Data} from '../../shared/entity/data';
import {DataItemService} from '../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent extends FormComponent<Contact> implements OnInit {

  @ViewChild('contactForm') contactForm: NgForm;

  contact: Contact = new Contact();
  country: Country = new Country();
  contactTypes: SelectItem[] = Data.contacts;
  countries: Country[] = [];
  faxEmailContactTypes: SelectItem[] = Data.faxEmailAddressContactTypes;
  addressContactTypes: SelectItem[] = Data.contactTypes;
  functions = [
    { value: '', label: null },
    { value: 'ARC moniteur', label: 'ARC moniteur' },
    { value: 'ARC moniteur backup', label: 'ARC moniteur backup' },
    { value: 'Chef de Projet', label: 'Chef de Projet' },
    { value: 'Moniteur médical', label: 'Moniteur médical' },
    { value: 'Autres', label: 'Autres' }
  ];

  constructor(
    private sharedService: SharedService,
    private contactService: ContactService,
    private dataItemService: DataItemService
  ) {
    super();
    this.getCountries();
  }

  get form() {
    return this.contactForm.controls;
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ContactFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {

    super.ngOnInit();
    this.splitName();
    if (!this.contact.phones || this.contact.phones.length === 0) {
      this.contact.phones = [];
      this.contact.phones.push(new Phone());
    }
    if (!this.contact.faxes || this.contact.faxes.length === 0) {
      this.contact.faxes = [];
      this.contact.faxes.push(new Fax());
    }
    if (!this.contact.emails || this.contact.emails.length === 0) {
      this.contact.emails = [];
      this.contact.emails.push(new Email());
    }
    if (!this.contact.addresses || this.contact.addresses.length === 0) {
      this.contact.addresses = [];
      this.contact.addresses.push(new Address());
    }
  }
  splitName() {
    if (!this.contact.name || this.contact.name.trim() === '') {
      this.contact.firstName = '';
      this.contact.lastName = '';
    } else {
      const nameParts = this.contact.name.trim().split(/\s+/);

      const lastNameParts = nameParts.filter(part => part === part.toUpperCase());
      if (lastNameParts.length > 0) {
        this.contact.lastName = lastNameParts.join(' ');

        const firstNameParts = nameParts.filter(part => part !== part.toUpperCase());
        this.contact.firstName = firstNameParts
          .map((part, index) => index === 0 ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
          .join(' ');
      } else {
        this.contact.firstName = nameParts
          .map((part, index) => index === 0 ? part.charAt(0).toUpperCase() + part.slice(1).toLowerCase() : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
          .join(' ');
        this.contact.lastName = '';
      }
    }
  }

  getCountries() {
    this.dataItemService.getItems('country').subscribe(res => {
      this.countries = res.map(country => new Country().convert(country));
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    this.contact.name = this.contact.firstName.charAt(0).toUpperCase() + this.contact.firstName.slice(1).toLowerCase() + ' ' + this.contact.lastName.toUpperCase();

    this.contact.entityType = localStorage.getItem('entityType');
    this.contact.entityTypeHhhId = localStorage.getItem('entityHhhId');
    if (this.contact.hhhId) {
      this.contact = new Contact().clone(this.contact);
      this.contact.removeNullContactEntries();
      this.contactService.updateContact(this.contact).subscribe(() => {
          this.sharedService.showSuccess();
          this.callback(this.contact);
        }, error => {
          this.sharedService.showFailure();
          console.error('An error occurred while updating Contact.' + error);
        }
      );
      this.display = false;
    } else {
      this.contactService.addContact(this.contact).subscribe(res => {
          this.sharedService.showSuccess();
          this.callback(this.contact);
          this.contact.hhhId = res;
        }, error => {
          this.sharedService.showFailure();
          console.error('An error occurred while adding Contact.' + error);
        }
      );
      this.display = false;
    }
  }

  addPhone() {
    this.contact.phones.push(new Phone());
  }

  deletePhone(i) {
    if (this.contact.phones.length > 1) {
      this.contact.phones.splice(i, 1);
    }
  }

  addFax() {
    this.contact.faxes.push(new Fax());
  }

  deleteFax(i) {
    if (this.contact.faxes.length > 1) {
      this.contact.faxes.splice(i, 1);
    }
  }

  addEmail() {
    this.contact.emails.push(new Email());
  }

  deleteEmail(i) {
    if (this.contact.emails.length > 1) {
      this.contact.emails.splice(i, 1);
    }
  }

  addAddress() {
    this.contact.addresses.push(new Address());
  }

  deleteAddress(i) {
    if (this.contact.addresses.length > 1) {
      this.contact.addresses.splice(i, 1);
    }
  }

}

import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';

@Component({
  selector: 'app-real-visit-date-update-confirmation',
  templateUrl: './real-visit-date-update-confirmation.component.html',
  styleUrls: ['./real-visit-date-update-confirmation.component.css']
})
export class RealVisitDateUpdateConfirmationComponent implements OnInit {


  display: boolean;

  private confirmCallback: () => void;
  private abortCallback: () => void;

  constructor() {
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(RealVisitDateUpdateConfirmationComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.display = true;
  }


  updateConfirmation() {
    this.confirmCallback();
    this.display = false;
  }

  updateAbort() {
    this.abortCallback();
    this.display = false;
  }

  onConfirm(callback: () => void) {
    this.confirmCallback = callback;
  }

  onAbort(callback: () => void) {
    this.abortCallback = callback;
  }
}

import {ReferenceAct} from '../../../additional-costs/models/reference-act';
import {ReferenceFixedCost} from '../../../additional-costs/models/referenceFixedCost';
import {CounterpartReference} from '../../../additional-costs/models/counterpart-reference';
import {OperationalAct} from '../../../additional-costs/models/operational-act';
import {TrialOperationalAct} from './trial-operational-act';

export class VisitAdditionalCost {

  public hhhId: any;
  public additionalCostType: string;
  public trialHhhId: number;
  public contractType: string;
  public contractTypeHhhId: number;
  public typeHhhId: number;
  public type: string;
  public additionalCostName: string;
  public additionalCostCategoryName: string;
  public invoiceable: boolean;
  public optional: boolean;
  public paymentStateHhhId: number;
  public realisationStateHhhId: number;
  public checked: boolean;
  public pricePerUnit: number;
  public numberOfUnits: number;
  public internalAccountNumber: string;
  public firstSegment: string;
  public secondSegment: string;
  public selected: boolean;
  public trialOperationalActHhhId: number;
  public operationalActHhhId: number;
  referenceActHhhId: number;
  public dataKeyId: string;
  public operationalSubActsEdited: boolean;
  public operationalSubActs: VisitAdditionalCost[];
  public fromAOP: boolean;

  constructor(hhhId?: number, additionalCostCategoryName?: string, additionalCostName?: string,
              invoiceable?: boolean, optional?: boolean, pricePerUnit?: number, contractType?: string, type?: number, firstSegment?: string, secondSegment?: string, additionalCostType?: string, numberOfUnits?: number) {
    this.hhhId = hhhId;
    this.additionalCostCategoryName = additionalCostCategoryName;
    this.additionalCostName = additionalCostName;
    this.invoiceable = invoiceable;
    this.optional = optional;
    this.pricePerUnit = pricePerUnit;
    this.contractType = contractType;
    this.typeHhhId = type;
    this.firstSegment = firstSegment;
    this.secondSegment = secondSegment;
    this.additionalCostType = additionalCostType;
    this.numberOfUnits = numberOfUnits;
  }

  static generateVisitAdditionalCostFromAct(res: ReferenceAct[]) {
    const additionalCosts: VisitAdditionalCost[] = [];
    for (const referenceAct of res) {
      additionalCosts.push(new VisitAdditionalCost(referenceAct.hhhId, referenceAct.actCategoryName, referenceAct.description,
        referenceAct.invoiceable, referenceAct.optional, referenceAct.pricePerUnit, referenceAct.contractType, referenceAct.typeHhhId, referenceAct.firstSegment, referenceAct.secondSegment, 'ACT'));
    }
    return additionalCosts;
  }

  static generateVisitAdditionalCostFromFixedCost(res: ReferenceFixedCost[]) {
    const additionalCosts: VisitAdditionalCost[] = [];
    for (const referenceFixedCost of res) {
      additionalCosts.push(new VisitAdditionalCost(referenceFixedCost.hhhId, referenceFixedCost.fixedCostCategoryName,
        referenceFixedCost.description, referenceFixedCost.invoiceable, referenceFixedCost.optional, referenceFixedCost.pricePerUnit,
        referenceFixedCost.contractType, referenceFixedCost.typeHhhId, referenceFixedCost.firstSegment, referenceFixedCost.secondSegment, 'FIXED_COST'));
    }
    return additionalCosts;
  }

  static generateVisitAdditionalCostFromCounterPart(res: CounterpartReference[]) {
    const additionalCosts: VisitAdditionalCost[] = [];
    for (const referenceCounterPart of res) {
      additionalCosts.push(new VisitAdditionalCost(referenceCounterPart.hhhId, referenceCounterPart.counterPartCategoryName,
        referenceCounterPart.description, referenceCounterPart.invoiceable, false, referenceCounterPart.pricePerUnit, null, null,
        referenceCounterPart.firstSegment, referenceCounterPart.secondSegment, 'COUNTER_PART'
      ));
    }
    return additionalCosts;
  }

  static generateVisitAdditionalCostFromOperationalAct(res: OperationalAct[]) {
    const additionalCosts: VisitAdditionalCost[] = [];
    for (const operationalAct of res) {
      const visitAdditionalCost = this.generateSingleVisitAdditionalCostFromOperationalAct(operationalAct);
      additionalCosts.push(visitAdditionalCost);
    }
    return additionalCosts;
  }

  static generateSingleVisitAdditionalCostFromOperationalAct(operationalAct: OperationalAct) {
    const visitAdditionalCost: VisitAdditionalCost = new VisitAdditionalCost();
    visitAdditionalCost.hhhId = operationalAct.hhhId;
    visitAdditionalCost.additionalCostCategoryName = operationalAct.actCategoryName;
    visitAdditionalCost.additionalCostType = 'OPERATIONAL_ACT';
    visitAdditionalCost.additionalCostName = operationalAct.description;
    visitAdditionalCost.invoiceable = operationalAct.invoiceable;
    return visitAdditionalCost;
  }

  static generateSingleVisitAdditionalCostFromTrialOperationalAct(operationalAct: TrialOperationalAct) {
    const visitAdditionalCost: VisitAdditionalCost = new VisitAdditionalCost();
    visitAdditionalCost.hhhId = operationalAct.hhhId;
    visitAdditionalCost.additionalCostCategoryName = operationalAct.actCategoryName;
    visitAdditionalCost.additionalCostType = 'OPERATIONAL_ACT';
    visitAdditionalCost.additionalCostName = operationalAct.description;
    visitAdditionalCost.invoiceable = operationalAct.invoiceable;
    return visitAdditionalCost;
  }

}

export class TrialAct {
  public hhhId: number;
  public name: string;
  public invoiceable: boolean;
  public onPrescription: boolean;
  public externalFromCentre: boolean;
  public pricePerUnit: number;
  public actCategory: string;
  public type: string;
  public optional: boolean;
}

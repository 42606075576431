import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Properties } from 'src/app/helpers/properties';
import { Address } from 'src/app/shared/entity/address';
import { Email } from 'src/app/shared/entity/email';
import { Fax } from 'src/app/shared/entity/fax';
import { Phone } from 'src/app/shared/entity/phone';

@Injectable({
  providedIn: 'root'
})
export class HumanResourceContactService {

  host = new Properties().host + '/human-resource/contact';

  constructor(private httpClient: HttpClient) {
  }

  saveAddress(address: Address): Observable<number> {
    return this.httpClient.post<number>(this.host + '/address/save', address);
  }

  updateAddress(address: Address): Observable<void> {
    return this.httpClient.post<void>(this.host + '/address/update', address);
  }

  deleteAddress(hhhId: number): Observable<void> {
    return this.httpClient.get<void>(this.host + '/address/delete/' + hhhId);
  }

  saveEmail(email: Email): Observable<number> {
    return this.httpClient.post<number>(this.host + '/email/save', email);
  }

  updateEmail(email: Email): Observable<void> {
    return this.httpClient.post<void>(this.host + '/email/update', email);
  }

  deleteEmail(hhhId: number): Observable<void> {
    return this.httpClient.get<void>(this.host + '/email/delete/' + hhhId);
  }

  SaveFax(fax: Fax): Observable<number> {
    return this.httpClient.post<number>(this.host + '/fax/save', fax);
  }

  updateFax(fax: Fax): Observable<void> {
    return this.httpClient.post<void>(this.host + '/fax/update', fax);
  }
  
  deleteFax(hhhId: number): Observable<void> {
    return this.httpClient.get<void>(this.host + '/fax/delete/' + hhhId);
  }

  savePhone(phone: Phone): Observable<number> {
    return this.httpClient.post<number>(this.host + '/phone/save', phone);
  }

  updatePhone(phone: Phone): Observable<void> {
    return this.httpClient.post<void>(this.host + '/phone/update', phone);
  }

  deletePhone(hhhId: number): Observable<void> {
    return this.httpClient.get<void>(this.host + '/phone/delete/' + hhhId);
  }

}

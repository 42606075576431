import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ImportExport} from '../../../entity/theoretical-calendar/import-export';
import {TheoreticalCalendarService} from '../../../service/theoretical-calendar.service';
import {Router} from '@angular/router';
import {SharedService} from '../../../../shared/services/shared.service';
import {TrialService} from '../../../services/trial.service';
import {Util} from '../../../../helpers/util';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {TrialProtocolService} from '../../../services/trial-protocol.service';

@Component({
  selector: 'ih-import-calendar-form',
  templateUrl: './import-calendar-form.component.html',
  styleUrls: ['./import-calendar-form.component.css']
})
export class ImportCalendarFormComponent implements OnInit {

  isAmendmentEnabled = false;
  idrcbDropdownListEnabled = false;
  importCalendarByProtocolNumberEnabled= false;
  public currentTrialHhhId: number;
  public selectedTrialHhhId: number;
  public protocolId: number;
  public displayImportCalendarDialog: boolean;
  public importExport: ImportExport = new ImportExport();
  public isImportAction;
  @Output() importEvent: EventEmitter<ImportExport> = new EventEmitter<ImportExport>();
  submitted = false;

  sourceArms: any[] = [];
  destinationArms: any[] = [];

  constructor(private theoreticalCalendarService: TheoreticalCalendarService,
              private sharedService: SharedService,
              private trialService: TrialService,
              private trialProtocolService: TrialProtocolService,
              private dynamicConfigService: DynamicConfigService,
              private router: Router) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.currentTrialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    this.displayImportCalendarDialog = true;
  }


  getSourceProtocolId(): void {
    if (this.isImportAction === true) {
      this.trialProtocolService.getLatestByTrialId(this.importExport.sourceTrialHhhId).subscribe(protocol => {
        this.protocolId = protocol.hhhId;
      });
    }
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAmendmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.idrcbDropdownListEnabled = this.dynamicConfigService.getProperty(PropertyEnum.idrcbDropdownListEnabled);
      this.importCalendarByProtocolNumberEnabled = this.dynamicConfigService.getProperty(PropertyEnum.importCalendarByProtocolNumberEnabled);
    });
  }

  submitImportExportCalendar() {
    this.submitted = true;
    if (this.existsEmptySourceDestination()) {
      return;
    }
    this.importExport.protocolId = this.protocolId;
    this.theoreticalCalendarService.importExportCalendar(this.importExport).subscribe(() => {
      this.sharedService.showSuccess();
      this.importEvent.emit(this.importExport);
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
    this.displayImportCalendarDialog = false;
  }

  existsEmptySourceDestination(): boolean {
    for (const destinationArm of this.importExport.destinationArms) {
      if (Util.isStringNullOrEmpty(destinationArm)) {
        return true;
      }
    }

    for (const sourceArm of this.importExport.sourceArms) {
      if (Util.isStringNullOrEmpty(sourceArm)) {
        return true;
      }
    }

    return false;
  }

  addFirstArmsRelationship() {
    this.importExport.sourceTrialHhhId = this.isImportAction ? this.selectedTrialHhhId : this.currentTrialHhhId;
    this.importExport.destinationTrialHhhId = this.isImportAction ? this.currentTrialHhhId : this.selectedTrialHhhId;
    this.getSourceProtocolId();
    if (this.importExport.sourceArms.length < 1) {
      this.importExport.sourceArms.push('');
    }
    if (this.importExport.destinationArms.length < 1) {
      this.importExport.destinationArms.push('');
    }
  }

  addArmsRelationship() {
    const tmp = this.importExport.sourceArms;
    tmp.push('');
    this.importExport.sourceArms = [...tmp];
  }

  addSourceArm(source: string, i: number) {
  }

  addDestinationArm(destination: string, i: number) {
  }

  sourceExistsInDestination(): boolean {
    const sameTrialInSourceDestination = this.importExport.sourceTrialHhhId == this.importExport.destinationTrialHhhId;
    if (this.importExport.destinationArms.length !== this.importExport.sourceArms.length) {
      return true;
    }
    for (let i = 0; i < (Math.max(this.importExport.destinationArms.length - 1, 1)); i++) {
      if (sameTrialInSourceDestination && this.importExport.destinationArms[i] == this.importExport.sourceArms[i]) {
        return true;
      }
      for (let j = i + 1; j < this.importExport.destinationArms.length; j++) {
        if (sameTrialInSourceDestination && this.importExport.destinationArms[j] == this.importExport.sourceArms[j]) {
          return true;
        }
        if (this.importExport.destinationArms[i] == this.importExport.destinationArms[j]) {
          return true;
        }
      }
    }
    return false;
  }

  public sourceArmList(): string {
    if (this.isAmendmentEnabled) {
      return 'latest-arm-by-visit-position|' + Number(this.importExport.sourceTrialHhhId) + '|' + this.protocolId;
    }
    return 'arm-by-visit-position|' + Number(this.importExport.sourceTrialHhhId);
  }

  public destinationArmList(): string {
    if (this.isAmendmentEnabled && Number(this.importExport.destinationTrialHhhId) === Number(this.importExport.sourceTrialHhhId)) {
        return 'latest-arm-by-visit-position|' + this.importExport.destinationTrialHhhId + '|' + this.protocolId;
      } else if (this.isAmendmentEnabled) {
      return 'latest-arm-by-visit-position|' + Number(this.importExport.destinationTrialHhhId);
    }
    return 'arm-by-visit-position|' + Number(this.importExport.destinationTrialHhhId);
  }
}

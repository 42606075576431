import {NgModule} from '@angular/core';
import {AdminConsoleComponent} from './component/admin-console/admin-console.component';
import {ConfigDetailComponent} from './component/config-detail/config-detail.component';
import {SharedModule} from '../shared/shared.module';
import {SvgIconComponent} from './component/svg-icon/svg-icon.component';
import {FormConfigEditorComponent} from './component/form-config-editor/form-config-editor.component';
import {TableConfigEditorComponent} from './component/table-config-editor/table-config-editor.component';
import {ModuleConfigEditorComponent} from './component/module-config-editor/module-config-editor.component';
import {ContainerConfigEditorComponent} from './component/container-config-editor/container-config-editor.component';
import {DynamicInputComponent} from './exported/dynamic-input/dynamic-input.component';
import {DynamicCalendarComponent} from './exported/dynamic-calendar/dynamic-calendar.component';
import {DynamicMultiselectComponent} from './exported/dynamic-multiselect/dynamic-multiselect.component';
import {DynamicDropdownComponent} from './exported/dynamic-dropdown/dynamic-dropdown.component';
import {DynamicLazyTableComponent} from './exported/dynamic-lazy-table/dynamic-lazy-table.component';
import {DynamicContactComponent} from './exported/dynamic-contact/dynamic-contact.component';
import {DynamicTableComponent} from './exported/dynamic-table/dynamic-table.component';
import {DynamicToolbarComponent} from './exported/dynamic-toolbar/dynamic-toolbar.component';
import {DynamicListHeaderComponent} from './exported/dynamic-list-header/dynamic-list-header.component';
import {DynamicCheckboxComponent} from './exported/dynamic-checkbox/dynamic-checkbox.component';
import {DynamicFormDirective} from './directive/dynamic-form.directive';
import {StartAfterLastDateValidatorDirective} from './directive/start-after-last-date-validator.directive';
import {StartBeforeEndDateValidatorDirective} from './directive/start-before-end-date-validator.directive';
import {TrialIdentificationIconComponent} from './exported/svg/trial-identification-icon/trial-identification-icon.component';
import {TrialPersonnelIconComponent} from './exported/svg/trial-personnel-icon/trial-personnel-icon.component';
import {TrialAmendmentIconComponent} from './exported/svg/trial-amendment-icon/trial-amendment-icon.component';
import {TrialMethodologyIconComponent} from './exported/svg/trial-methodology-icon/trial-methodology-icon.component';
import {TrialDisciplineIconComponent} from './exported/svg/trial-discipline-icon/trial-discipline-icon.component';
import {TrialInclusionCriteriaIconComponent} from './exported/svg/trial-inclusion-criteria-icon/trial-inclusion-criteria-icon.component';
import {TrialArmIconComponent} from './exported/svg/trial-arm-icon/trial-arm-icon.component';
import {TrialDesignIconComponent} from './exported/svg/trial-design-icon/trial-design-icon.component';
import {DynamicTranslatorPipe} from './pipe/dynamic-translator.pipe';
import {CustomExportManagementComponent} from './exported/custom-export-management/custom-export-management.component';
import {DashboardIconComponent} from './exported/svg/dashboard-icon/dashboard-icon.component';
import {AgendaIconComponent} from './exported/svg/agenda-icon/agenda-icon.component';
import {EstablishmentIconComponent} from './exported/svg/establishment-icon/establishment-icon.component';
import {TrialIconComponent} from './exported/svg/trial-icon/trial-icon.component';
import {PatientIconComponent} from './exported/svg/patient-icon/patient-icon.component';
import {ProviderIconComponent} from './exported/svg/provider-icon/provider-icon.component';
import {SiteIconComponent} from './exported/svg/site-icon/site-icon.component';
import {HumanResourceIconComponent} from './exported/svg/human-resource-icon/human-resource-icon.component';
import {AdditionalCostIconComponent} from './exported/svg/additional-cost-icon/additional-cost-icon.component';
import {InvoiceIconComponent} from './exported/svg/invoice-icon/invoice-icon.component';
import {StatisticIconComponent} from './exported/svg/statistic-icon/statistic-icon.component';
import {DataImportIconComponent} from './exported/svg/data-import-icon/data-import-icon.component';
import {MobileApplicationIconComponent} from './exported/svg/mobile-application-icon/mobile-application-icon.component';
import {PharmacyIconComponent} from './exported/svg/pharmacy-icon/pharmacy-icon.component';
import {AdminConsoleIconComponent} from './exported/svg/admin-console-icon/admin-console-icon.component';
import {SideBarComponent} from './exported/side-bar/side-bar.component';
import {DynamicTextareaComponent} from './exported/dynamic-textarea/dynamic-textarea.component';
import {DynamicAnchorComponent} from './exported/dynamic-anchor/dynamic-anchor.component';
import {DynamicCardComponent} from './exported/dynamic-card/dynamic-card.component';
import {DynamicMenuComponent} from './exported/dynamic-menu/dynamic-menu.component';
import {PageTopComponent} from './exported/page-top/page-top.component';
import {AdministrativeRegulatoryIconComponent} from './exported/svg/administrative-regulatory-icon/administrative-regulatory-icon.component';
import {AdministrativeDateIconComponent} from './exported/svg/administrative-date-icon/administrative-date-icon.component';
import {AdministrativeFeasibilityIconComponent} from './exported/svg/administrative-feasibility-icon/administrative-feasibility-icon.component';
import {InclusionAdverseEffectIconComponent} from './exported/svg/inclusion-adverse-effect-icon/inclusion-adverse-effect-icon.component';
import {InclusionConcomitantTreatmentIconComponent} from './exported/svg/inclusion-concomitant-treatment-icon/inclusion-concomitant-treatment-icon.component';
import {InclusionVitalSignsIconComponent} from './exported/svg/inclusion-vital-signs-icon/inclusion-vital-signs-icon.component';
import {PageLockConfirmationComponent} from './exported/page-lock/page-lock-confirmation/page-lock-confirmation.component';
import {PageLockNavbarComponent} from './exported/page-lock/page-lock-navbar/page-lock-navbar.component';
import {PageLockPasswordComponent} from './exported/page-lock/page-lock-password/page-lock-password.component';
import {PageUnlockConfirmationComponent} from './exported/page-lock/page-unlock-confirmation/page-unlock-confirmation.component';
import {ContactListComponent} from './exported/contact-list/contact-list.component';
import { FormPreviewComponent } from './component/form-preview/form-preview.component';
import { TablePreviewComponent } from './component/table-preview/table-preview.component';
import { DataManagementComponent} from './exported/data-management/data-management.component';
import { DataExportComponent } from './exported/data-export/data-export.component';
import { ExportDialogComponent } from './exported/export-dialog/export-dialog.component';
import {UserInformationIconComponent} from './exported/svg/user-information-icon/user-information-icon.component';
import {GeneratePasswordIconComponent} from './exported/svg/generate-password-icon/generate-password-icon.component';
import {UserAccesRightIconComponent} from './exported/svg/user-acces-right-icon/user-acces-right-icon.component';
import {DynamicTableOverlayComponent} from './exported/dynamic-table-overlay/dynamic-table-overlay.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    AdminConsoleComponent,
    ConfigDetailComponent,
    FormConfigEditorComponent,
    TableConfigEditorComponent,
    ModuleConfigEditorComponent,
    ContainerConfigEditorComponent,
    FormPreviewComponent,
    TablePreviewComponent,
    DataManagementComponent,

    CustomExportManagementComponent,
    ContactListComponent,
    SideBarComponent,
    PageTopComponent,
    PageLockConfirmationComponent,
    PageLockNavbarComponent,
    PageLockPasswordComponent,
    PageUnlockConfirmationComponent,
    DynamicInputComponent,
    DynamicCalendarComponent,
    DynamicMultiselectComponent,
    DynamicDropdownComponent,
    DynamicLazyTableComponent,
    DynamicTableComponent,
    DynamicContactComponent,
    DynamicListHeaderComponent,
    DynamicToolbarComponent,
    DynamicCheckboxComponent,
    DynamicTextareaComponent,
    DynamicAnchorComponent,
    DynamicCardComponent,
    DynamicMenuComponent,
    DynamicTableOverlayComponent,

    DynamicFormDirective,
    StartBeforeEndDateValidatorDirective,
    StartAfterLastDateValidatorDirective,

    SvgIconComponent,
    TrialIdentificationIconComponent,
    TrialPersonnelIconComponent,
    TrialAmendmentIconComponent,
    TrialMethodologyIconComponent,
    TrialDisciplineIconComponent,
    TrialInclusionCriteriaIconComponent,
    TrialArmIconComponent,
    TrialDesignIconComponent,
    DashboardIconComponent,
    AgendaIconComponent,
    EstablishmentIconComponent,
    TrialIconComponent,
    PatientIconComponent,
    ProviderIconComponent,
    SiteIconComponent,
    HumanResourceIconComponent,
    AdditionalCostIconComponent,
    InvoiceIconComponent,
    StatisticIconComponent,
    DataImportIconComponent,
    MobileApplicationIconComponent,
    PharmacyIconComponent,
    AdminConsoleIconComponent,
    AdministrativeRegulatoryIconComponent,
    AdministrativeDateIconComponent,
    AdministrativeFeasibilityIconComponent,
    InclusionAdverseEffectIconComponent,
    InclusionConcomitantTreatmentIconComponent,
    InclusionVitalSignsIconComponent,
    UserInformationIconComponent,
    GeneratePasswordIconComponent,
    UserAccesRightIconComponent,

    DynamicTranslatorPipe,

    DataExportComponent,

    ExportDialogComponent,
  ],
  exports: [
    DynamicFormDirective,
    StartBeforeEndDateValidatorDirective,
    StartAfterLastDateValidatorDirective,

    CustomExportManagementComponent,
    ContactListComponent,
    SideBarComponent,
    PageTopComponent,
    PageLockConfirmationComponent,
    PageLockNavbarComponent,
    PageLockPasswordComponent,
    PageUnlockConfirmationComponent,
    DynamicInputComponent,
    DynamicCalendarComponent,
    DynamicMultiselectComponent,
    DynamicDropdownComponent,
    DynamicLazyTableComponent,
    DynamicTableComponent,
    DynamicContactComponent,
    DynamicListHeaderComponent,
    DynamicToolbarComponent,
    DynamicCheckboxComponent,
    DynamicTextareaComponent,
    DynamicAnchorComponent,
    DynamicCardComponent,
    DynamicTableOverlayComponent,
    AdministrativeRegulatoryIconComponent,
    AdministrativeDateIconComponent,
    AdministrativeFeasibilityIconComponent,
    DynamicTableOverlayComponent,

    TrialIdentificationIconComponent,
    TrialPersonnelIconComponent,
    TrialAmendmentIconComponent,
    TrialMethodologyIconComponent,
    TrialDisciplineIconComponent,
    TrialInclusionCriteriaIconComponent,
    TrialArmIconComponent,
    TrialDesignIconComponent,
    DashboardIconComponent,
    AgendaIconComponent,
    EstablishmentIconComponent,
    TrialIconComponent,
    PatientIconComponent,
    ProviderIconComponent,
    SiteIconComponent,
    HumanResourceIconComponent,
    AdditionalCostIconComponent,
    InvoiceIconComponent,
    StatisticIconComponent,
    DataImportIconComponent,
    MobileApplicationIconComponent,
    PharmacyIconComponent,
    AdminConsoleIconComponent,
    AdministrativeRegulatoryIconComponent,
    AdministrativeDateIconComponent,
    AdministrativeFeasibilityIconComponent,
    InclusionAdverseEffectIconComponent,
    InclusionConcomitantTreatmentIconComponent,
    InclusionVitalSignsIconComponent,
    UserInformationIconComponent,
    GeneratePasswordIconComponent,
    UserAccesRightIconComponent,

    DynamicTranslatorPipe,
    DataExportComponent,
    ExportDialogComponent

  ],
  entryComponents: [
    ExportDialogComponent
  ],
  providers: [
    DynamicTranslatorPipe
  ]
})
export class DynamicConfigModule {
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Patient} from '../models/patient';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {SelectItem} from 'primeng/api';
import {FR} from '../../shared/entity/calendar-language';
import {TranslateService} from '@ngx-translate/core';
import {PatientService} from '../services/patient.service';
import {SharedService} from '../../shared/services/shared.service';
import {FormComponent} from '../../shared/component/form/form.component';
import {Role} from '../../login/models/role';
import {AuthenticationService} from '../../login/services/authentication.service';
import {DataTransferService} from '../services/data-transfer.service';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PatientAppData} from '../patient-app-data';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import { UsersService } from 'src/app/admin/services/users.service';
import { User } from 'src/app/admin/models/user';
import { LabelValue } from 'src/app/shared/entity/label-value';
import {Data} from '../../shared/entity/data';
import {Util} from '../../helpers/util';

@Component({
  selector: 'ih-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.css']
})
export class PatientFormComponent extends FormComponent<Patient> implements OnInit {

  target = DynamicDefinitionEnum.PATIENT_ADD_FORM;
  config: FormConfig = new FormConfig();

  patient: Patient = new Patient();
  sexList: SelectItem[] = [];
  noYesOptions: SelectItem[];
  locale = FR;
  @ViewChild('patientForm') patientForm: NgForm;
  public userRoleIsEditor: boolean;
  public nipCodeAlreadyExists = false;
  isLoaderVisible = false;
  isNipExist = false;
  currentDate: Date = new Date();
  mustEnterDeathDate = false;
  currentEstablishmenthhhId: number;
  currentEstablishmentName: string;
  externalDoctors: SelectItem[] = this.getExternalDoctorsList();
  externalEstablishments: SelectItem[] = this.getExternalDoctorEstablishmentsList();
  constructor(
      private translateService: InnohealthTranslateService,
      private translate: TranslateService,
      private patientService: PatientService,
      private authService: AuthenticationService,
      private sharedService: SharedService,
      private dataTransferService: DataTransferService,
      private dynamicConfigService: DynamicConfigService,
      private userService: UsersService
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(PatientFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  GetCurrentUserEstablishmentId(): void {
      this.userService.getCurrentUser().subscribe(res => {
        this.currentEstablishmenthhhId = res.establishmentHhhId;
        this.currentEstablishmentName = res.establishment;
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading user.' + error);
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.initFormConfig();
    this.translate.stream('GENERAL_LINGUISTICS_YES_UFCASE').subscribe(() => {
      this.getNoYesOptions();
      this.getSexList();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
    this.userRoleIsEditor = this.authService.currentUserRole === Role.Editor;
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, PatientAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
    this.GetCurrentUserEstablishmentId();
  }
  addPatient() {
    this.patient.establishment = this.currentEstablishmentName;
    this.patient.establishmentHhhId = this.currentEstablishmenthhhId;
    this.submitted = true;
    if (!(this.patient as Patient).isValid(this.config)) {
      return;
    }
    this.mustEnterDeathDate = false;
    if (this.patient.lifeStateHhhId === 2 && this.patient.deathDate == null) {
      this.mustEnterDeathDate = true;
      return;
    }
    this.patientService.addPatient(this.patient).subscribe(
        res => {
          this.patient.hhhId = res;
          this.sharedService.showSuccess('UI_FORM_SUCCESS_SAVING_SUCCEEDED');
          this.sharedService.setInformationInLocalStorage('Patients', 'Ajouter', this.patient.internalIdentifier);
          this.display = false;
          this.callback(this.patient);
          this.nipCodeAlreadyExists = false;
          // Transfer data
          this.dataTransferService.addPatient(this.patient);
        }, error => {
          if (error.error === 'Patient already exists.') {
            this.nipCodeAlreadyExists = true;
          } else {
            this.sharedService.showFailure();
            this.display = false;
          }
          console.error('An error occurred while adding patient. ' + error.error);
        }
    );
  }

  getNoYesOptions() {
    this.noYesOptions = [
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE'), value: false},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE'), value: true}];
  }

  getSexList() {
    const mrTitle = this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MR');
    const mmeTitle = this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MME');
    this.sexList.push(
        {label: '', value: null},
        {label: mrTitle, value: mrTitle},
        {label: mmeTitle, value: mmeTitle},
        {label: 'NA', value: 'NA'});
  }

  checkNipExistence() {
    this.isLoaderVisible = true;
    if (this.patient.internalIdentifier === '') {
      this.isNipExist = false;
      this.isLoaderVisible = false;
      return;
    }
    this.patientService.checkNipExistence(this.patient.internalIdentifier).subscribe(res => {
      this.isNipExist = res.value;
      this.isLoaderVisible = false;
    }, error => {
      this.isLoaderVisible = false;
      console.log(error);
    });
  }

  clearPatientDeathDate() {
    this.patient.deathDate = null;
  }

  getExternalDoctorsList(): SelectItem[] {
    return Array.from(Data.externalDoctorToExternalEstablishments.keys()).map(doctor => {
      return {label: doctor, value: doctor};
    });
  }

  getExternalDoctorEstablishmentsList(): SelectItem[] {
    return Array.from(Data.externalEstablishmentsToExternalDoctors.keys()).map(establishment => {
      return {label: establishment, value: establishment};
    });
  }

  externalDoctorChanged(): void {
    if (Util.isNullOrUndefinedOrEmpty(this.patient.externalDoctorName)) {
      this.externalDoctors = this.getExternalDoctorsList();
      this.externalEstablishments = this.getExternalDoctorEstablishmentsList();
      this.patient.externalDoctorEstablishment = null;
      return;
    }
    this.patient.externalDoctorEstablishment = Data.externalDoctorToExternalEstablishments.get(this.patient.externalDoctorName).value;
    this.externalEstablishments = [Data.externalDoctorToExternalEstablishments.get(this.patient.externalDoctorName)];
  }

  externalDoctorEstablishmentChanged(): void {
    if (Util.isNullOrUndefinedOrEmpty(this.patient.externalDoctorEstablishment)) {
      this.externalDoctors = this.getExternalDoctorsList();
      this.externalEstablishments = this.getExternalDoctorEstablishmentsList();
      this.patient.externalDoctorName = null;
      return;
    }
    this.externalDoctors = Data.externalEstablishmentsToExternalDoctors.get(this.patient.externalDoctorEstablishment);
    this.patient.externalDoctorName = null;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
}

export class RequestPage<T> {
  page = 0;
  limit = 10;
  sortBy = 'hhhId';
  secondSortBy = '';
  orderBy = 'desc';
  filters: T;

  objectId: number;
  condition: boolean;
  secondCondition = false;
  type = '';
  fromDate = '';
  toDate = '';

  constructor() {
  }

}

import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../login/services/authentication.service';
import {UsersService} from '../../admin/services/users.service';
import {User} from '../../admin/models/user';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-current-user-identity',
  templateUrl: './current-user-identity.component.html',
  styleUrls: ['./current-user-identity.component.css']
})
export class CurrentUserIdentityComponent implements OnInit {
  public user: User = new User();
  public currentUserName;
  public currentUserRole;
  public currentUserPrefix;

  constructor(private authService: AuthenticationService,
              private userService: UsersService,
              private translate: TranslateService,
              private translateService: InnohealthTranslateService,
              private router: Router) {
  }

  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.userService.getCurrentUser().subscribe(res => {
      this.user = res;
      this.getCurrentUserRole();
      this.currentUserName = (this.user.firstName != null ? this.user.firstName : '') + ' ' + (this.user.lastName ? this.user.lastName.toUpperCase() : '');
      this.currentUserPrefix = this.user.title;
    });
  }

  getCurrentUserRole() {
    this.translate.stream('MODULE_USER_ADMIN_ROLE_' + this.user.role, null).subscribe(res => {
      this.currentUserRole = res;
    });
  }

  goToEditAccount() {
    this.router.navigateByUrl('/auth/edit').then();
  }

}

import {JsonObject, JsonProperty} from 'json2typescript';
import {DynamicContainerDefinitionCard} from './dynamic-container-definition-card';

@JsonObject('DynamicContainerDefinition')
export class DynamicContainerDefinition {

  @JsonProperty('definition', String)
  definition: string = undefined;

  @JsonProperty('cards', [DynamicContainerDefinitionCard])
  cards: DynamicContainerDefinitionCard[] = [];

  sortCards(): void {
    this.cards = this.cards.sort((c1, c2) => {
      if (!c1.config.display) {
        return 1;
      }
      if (!c2.config.display) {
        return -1;
      }
      return c2.config.order - c1.config.order;
    });
    this.cards = this.cards.sort((c1, c2) => {
      if (c1.config.display && c2.config.display) {
        return c1.config.order - c2.config.order;
      }
      return 0;
    });
  }
}

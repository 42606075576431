import {JsonObject} from 'json2typescript';
import {ContainerCardConfig} from './container-card-config';

@JsonObject('ContainerConfig')
export class ContainerConfig {

  cards: Map<string, ContainerCardConfig> = new Map<string, ContainerCardConfig>();

  constructor(cards?: Map<string, ContainerCardConfig>) {
    this.cards = cards;
  }

  getCardConfig(cardName: string): ContainerCardConfig {
    if (!this.cards || !this.cards.has(cardName)) {
      return new ContainerCardConfig();
    }
    return this.cards.get(cardName);
  }

  displayCard(cardName: string): boolean {
    if (!this.cards || !this.cards.has(cardName)) {
      return false;
    }
    return this.cards.get(cardName).display;
  }

  getCardOrder(cardName: string): number {
    if (!this.cards || !this.cards.has(cardName)) {
      return 0;
    }
    return this.cards.get(cardName).order;
  }

}

import {NgModule} from '@angular/core';
import {HumanResourceDetailsComponent} from './human-resource-details/human-resource-details.component';
import {HumanResourceListComponent} from './human-resource-list/human-resource-list.component';
import {HumanResourceRoutingModule} from './human-resource-routing.module';
import {SharedModule} from '../shared/shared.module';
import {HumanResourceInformationsFormComponent} from './human-resource-informations-form/human-resource-informations-form.component';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';
import {HumanResourceFormComponent} from './human-resource-form/human-resource-form.component';
import {RhImportComponent} from './rh-import/rh-import.component';

@NgModule({
  declarations: [
    HumanResourceFormComponent,
    HumanResourceDetailsComponent,
    HumanResourceListComponent,
    HumanResourceInformationsFormComponent,
    RhImportComponent
  ],
  imports: [
    HumanResourceRoutingModule,
    SharedModule,
    DynamicConfigModule
  ],
  entryComponents: [
    HumanResourceFormComponent,
    RhImportComponent
  ]
})
export class HumanResourcesModule {
}

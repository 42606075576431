import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('TableHeaderConfig')
export class TableHeaderConfig {

  @JsonProperty('display', Boolean)
  display = false;

  @JsonProperty('header', String, true)
  header: string = undefined;

  @JsonProperty('width', String, true)
  width: string = undefined;

  @JsonProperty('filterable', Boolean)
  filterable = false;

  @JsonProperty('sortable', Boolean)
  sortable = false;

  @JsonProperty('importe', Boolean)
  importe = false;

  @JsonProperty('export', Boolean)
  export = false;
}

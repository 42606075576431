import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ReferenceActService} from '../../../../additional-costs/services/reference-act.service';
import {InputType} from '../../../../input-type';
import {isNullOrUndefined} from 'util';
import {TableHeader} from '../../../../shared/entity/table-header';
import {MenuItem, SelectItem} from 'primeng/api';
import {TrialActService} from '../../../service/trial-act.service';
import {SyntheticTrialAct} from '../../../entity/theoretical-calendar/synthetic-trial-act';
import {DeleteMultipleComponent} from '../../../../modals/delete-multiple/delete-multiple.component';
import {SharedService} from '../../../../shared/services/shared.service';
import {VisitAdditionalCost} from '../../../entity/trial-additionnal-costs/visit-additional-cost';
import {TheoreticalCalendar} from '../../../entity/theoretical-calendar/theoretical-calendar';
import {ReferenceFixedCostService} from '../../../../additional-costs/services/reference-fixed-cost.service';
import {CounterpartReferenceService} from '../../../../additional-costs/services/counterpart-reference.service';
import {TrialFixedCostService} from '../../../service/trial-fixed-cost.service';
import {TrialCounterpartService} from '../../../service/trial-counterpart.service';
import {OperationalActService} from '../../../../additional-costs/services/operational-act.service';
import {DataService} from '../../../../shared/services/data-service';
import {Router} from '@angular/router';
import {TrialOperationalActService} from '../../../service/trial-operational-act.service';
import {DeleteConfirmationComponent} from '../../../../modals/delete-confirmation/delete-confirmation.component';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {HiddenFields} from '../../../../admin/models/HiddenFields';
import {UsersService} from '../../../../admin/services/users.service';
import {SyntheticTrialActDataService} from './synthetic-trial-act-data.service';
import {ACT_GRID_READ_WRITE, COUNTER_PART_REFERENCE_READ_WRITE, FIXED_COST_READ_WRITE, OPERATIONAL_ACT_READ_WRITE} from '../../../../login/services/authentication.service';
import {OperationalAct} from '../../../../additional-costs/models/operational-act';
import {OperationalActFormComponent} from '../../../../additional-costs/operational-act-form/operational-act-form.component';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-theoretical-calendar-acts-add',
  templateUrl: './theoretical-calendar-acts-add.component.html',
  styleUrls: ['./theoretical-calendar-acts-add.component.css']
})
export class TheoreticalCalendarActsAddComponent implements OnInit, OnDestroy {

  referenceActTarget = DynamicDefinitionEnum.TRIAL_THEORETICAL_CALENDAR_REFERENCE_ACT_LIST_TABLE;
  additionalCostTarget = DynamicDefinitionEnum.TRIAL_THEORETICAL_CALENDAR_ADDITIONAL_COST_LIST_TABLE;
  referenceActTableConfig: TableConfig;
  referenceActHeaders: DynamicTableHeader[] = [];
  additionalCostTableConfig: TableConfig;
  additionalCostHeaders: DynamicTableHeader[] = [];
  attachedToVisit = true;
  counterPartEnabled = true;
  operationalActEnabled = true;

  @ViewChild('deletePopup', {read: ViewContainerRef}) deletePopupContainer: ViewContainerRef;
  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;


  trialAddtionalCost: MenuItem[] = [];
  referenceAddtionalCost: MenuItem[] = [];

  referenceActiveItem: MenuItem;
  trialActiveItem: MenuItem;

  actActive = true;
  fixedCostActive = false;
  counterPartActive = false;
  operationalActActive = false;
  isOperationalAct: boolean;
  trialActIsUsedOnlyOnce = true;

  activeModule = 'ACT';

  referenceActs: VisitAdditionalCost[];
  display = true;
  allTrialActs: VisitAdditionalCost[];
  additionalCostColumns: TableHeader[];
  allFields: boolean;
  disabledSaveActsButton = false;
  trialHhhId: number;
  syntheticTrialAct: SyntheticTrialAct;
  theoreticalCalendar: TheoreticalCalendar = new TheoreticalCalendar();
  hasActsPermission = false;
  hasFixedCostsPermission = false;
  hasCounterPartsPermission = false;
  hasOperationalActsPermission = false;
  isArcViewActive = true;

  // filters
  additionalCostCategoryFilterList: SelectItem[] = [];
  referenceDescriptionFilterList: SelectItem[] = [];

  private saveCallback: (trialActs: VisitAdditionalCost[], allTrialActs: VisitAdditionalCost[]) => void;

  constructor(
    private referenceActService: ReferenceActService,
    private referenceFixedCostService: ReferenceFixedCostService,
    private useService: UsersService,
    private referenceCounterPartService: CounterpartReferenceService,
    private trialActService: TrialActService,
    private translateService: InnohealthTranslateService,
    private trialFixedCostService: TrialFixedCostService,
    private trialOperationalActService: TrialOperationalActService,
    private trialCounterPartService: TrialCounterpartService,
    private sharedService: SharedService,
    private cfr: ComponentFactoryResolver,
    private operationalActService: OperationalActService,
    private dataService: DataService,
    private router: Router,
    private syntheticTrialActDataService: SyntheticTrialActDataService,
    private dynamicConfigService: DynamicConfigService
  ) {
  }

  static displayDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const deletePopupFactory = cfr.resolveComponentFactory(TheoreticalCalendarActsAddComponent);
    const deletePopupComponentRef = viewRef.createComponent(deletePopupFactory);
    return deletePopupComponentRef.instance;
  }

  ngOnInit() {
    this.initProperties();
    this.hasActsPermission = ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(ACT_GRID_READ_WRITE)) >= 0;
    this.hasFixedCostsPermission = ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(FIXED_COST_READ_WRITE)) >= 0;
    this.hasCounterPartsPermission = ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(COUNTER_PART_REFERENCE_READ_WRITE)) >= 0;
    this.hasOperationalActsPermission = ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(OPERATIONAL_ACT_READ_WRITE)) >= 0;
    this.dataService.fromOperationalActsAction.subscribe(res => {
      this.setMenu();
      if (res) {
        this.actActive = false;
        this.fixedCostActive = false;
        this.counterPartActive = false;
        this.operationalActActive = true;
        this.activeModule = 'OPERATIONAL_ACT';

        this.trialActiveItem = this.trialAddtionalCost[3];
        this.referenceActiveItem = this.referenceAddtionalCost[3];
        this.dataService.changeFromSyntheticCalendar(false);
      } else {
        this.actActive = this.hasActsPermission;
        this.fixedCostActive = !this.actActive && this.hasFixedCostsPermission;
        this.counterPartActive = !this.actActive && !this.fixedCostActive && this.hasCounterPartsPermission;
        this.operationalActActive = !this.actActive && !this.fixedCostActive && !this.counterPartActive && this.hasOperationalActsPermission;
        this.trialActiveItem = this.trialAddtionalCost[[this.hasActsPermission, this.hasFixedCostsPermission, this.hasCounterPartsPermission, this.hasOperationalActsPermission].indexOf(true)];
        this.referenceActiveItem = this.referenceAddtionalCost[[this.hasActsPermission, this.hasFixedCostsPermission, this.hasCounterPartsPermission, this.hasOperationalActsPermission].indexOf(true)];
      }
      this.syntheticTrialAct = this.syntheticTrialActDataService.syntheticTrialAct;
      this.allFields = true;
      this.getReferenceAdditionalCostList();
      this.getAdditionalCost();
    });

  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.attachedToVisit = this.dynamicConfigService.getProperty(PropertyEnum.attachedToVisit);
      this.counterPartEnabled = this.dynamicConfigService.getProperty(PropertyEnum.counterPartEnabled);
      this.operationalActEnabled = this.dynamicConfigService.getProperty(PropertyEnum.operationalActEnabled);
    });
    this.initReferenceActTableConfig();
    this.initAdditionalCostTableConfig();
  }

  initReferenceActTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.referenceActTarget, TrialAppData.tableConfig).subscribe(
      config => {
        this.referenceActTableConfig = config;
        this.buildReferenceActTableHeaders();
      }
    );
  }

  initAdditionalCostTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.additionalCostTarget, TrialAppData.tableConfig).subscribe(
      config => {
        this.additionalCostTableConfig = config;
        this.buildAdditionalCostTableHeaders();
      }
    );
  }


  buildAdditionalCostTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('additionalCostCategoryName')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .optionFilterable()
      .options(this.additionalCostCategoryFilterList)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('additionalCostName')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .optionFilterable()
      .options(this.referenceDescriptionFilterList)
      .sortable()
      .isEditable()
      .onChange((data: VisitAdditionalCost) => {
        this.updateTrialAct(data);
      })
      .onEdit((data: VisitAdditionalCost, trialOperationalAct: VisitAdditionalCost) => {
        if (!isNullOrUndefined(trialOperationalAct.hhhId)) {
          this.updateTrialAct(data);
        } else {
          trialOperationalAct.operationalSubActsEdited = true;
        }
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('invoiceable')
      .inputType(InputType.checkbox)
      .sortable()
      .onChange((data: VisitAdditionalCost) => {
        this.updateTrialAct(data);
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('pricePerUnit')
      .accessible(this.useService.canAccessField(this.getFieldNameForActiveModule()))
      .sortable()
      .withDisplayCssClassContent()
      .cssClass(() => {
        return 'pull-right';
      })
      .displayContent((data: VisitAdditionalCost) => {
        let price = '';
        if (!isNullOrUndefined(data.pricePerUnit)) {
          price = Number(data.pricePerUnit).toFixed(2) + ' €';
        }
        return price;
      })
      .isEditable()
      .onChange((data: VisitAdditionalCost) => {
        this.updateTrialAct(data);
      })
      .hideField((data: VisitAdditionalCost) => {
        return data.additionalCostType === 'OPERATIONAL_ACT';
      })
      .withCurrency()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('optional')
      .accessible((this.activeModule === 'ACT') || (this.activeModule === 'FIXED_COST'))
      .filterable()
      .inputType(InputType.checkbox)
      .sortable()
      .isTranslated()
      .isEditable()
      .hideField(() => {
        this.allFields = false;
        this.getAdditionalCostColumns(event);
      })
      .onChange((data: VisitAdditionalCost) => {
        this.updateTrialAct(data);
      })
      .build());
    // todo : remove this
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field(!this.allFields ? 'more' : 'less')
      .hideField(() => {
        this.allFields = false;
        this.getAdditionalCostColumns(event);
      })
      .onChange((data: VisitAdditionalCost) => {
        this.removeSelectedAct(data);
      })
      .build());
    this.additionalCostHeaders = this.additionalCostTableConfig.buildTable(availableHeaders);
  }

  buildReferenceActTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('additionalCostCategoryName')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .optionFilterable()
      .options(this.additionalCostCategoryFilterList)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('additionalCostName')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .optionFilterable()
      .options(this.referenceDescriptionFilterList)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('invoiceable')
      .sortable()
      .inputType(InputType.checkbox)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('pricePerUnit')
      .accessible(!this.operationalActActive && this.useService.canAccessField(this.getFieldNameForActiveModule()))
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .withDisplayCssClassContent()
      .cssClass(() => {
        return 'pull-right';
      })
      .displayContent((data) => {
        return Number(data.pricePerUnit).toFixed(2) + ' €';
      })
      .withCurrency()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('optional')
      .accessible((this.activeModule === 'ACT') || (this.activeModule === 'FIXED_COST'))
      .filterable()
      .inputType(InputType.checkbox)
      .sortable()
      .isTranslated()
      .build());
    this.referenceActHeaders = this.referenceActTableConfig.buildTable(availableHeaders);
  }

  ngOnDestroy(): void {
    this.dataService.changeFromOperationalActs(false);
  }


  getReferenceAdditionalCostList() {
    this.referenceActs = [];
    if (this.actActive) {
      this.referenceActService.getReferenceActListForTrial(this.trialHhhId).subscribe(
        res => {
          this.referenceActs = VisitAdditionalCost.generateVisitAdditionalCostFromAct(res);
          this.updateReferenceActsDropdownOptions(res);
          this.initReferenceActTableConfig();
        }, error => {
          console.error('An error occurred while loading reference Act.' + error);
        });
    } else if (this.fixedCostActive) {
      this.referenceFixedCostService.getAttachedFixedCostReferenceForTrial(this.trialHhhId, true).subscribe(res => {
        this.referenceActs = VisitAdditionalCost.generateVisitAdditionalCostFromFixedCost(res);
        this.updateReferenceActsDropdownOptions(res);
        this.initReferenceActTableConfig();
      });
    } else if (this.counterPartActive) {
      this.referenceCounterPartService.loadAttachedList().subscribe(res => {
        this.referenceActs = VisitAdditionalCost.generateVisitAdditionalCostFromCounterPart(res);
        this.updateReferenceActsDropdownOptions(res);
        this.initReferenceActTableConfig();
      });
    } else if (this.operationalActActive) {
      this.isOperationalAct = true;
      this.operationalActService.getOperationalActList(true).subscribe(res => {
        this.referenceActs = VisitAdditionalCost.generateVisitAdditionalCostFromOperationalAct(res);
        this.updateReferenceActsDropdownOptions(res);
        this.initReferenceActTableConfig();
      });
    }
  }

  updateReferenceActsDropdownOptions(event) {
    this.additionalCostCategoryFilterList = [];
    this.referenceDescriptionFilterList = [];
    for (const act of event) {
      const descItem = {
        label: act.description ? act.description : act.additionalCostName,
        value: act.description ? act.description : act.additionalCostName
      };
      const actCategoryItem = {
        label: act.actCategoryName ? act.actCategoryName : act.additionalCostCategoryName ? act.additionalCostCategoryName : act.fixedCostCategoryName
          ? act.fixedCostCategoryName : act.counterPartCategoryName,
        value: act.actCategoryName ? act.actCategoryName : act.additionalCostCategoryName ? act.additionalCostCategoryName : act.fixedCostCategoryName
          ? act.fixedCostCategoryName : act.counterPartCategoryName
      };
      if ((!isNullOrUndefined(act.actCategoryName) || !isNullOrUndefined(act.additionalCostCategoryName) || !isNullOrUndefined(act.fixedCostCategoryName) || act.counterPartCategoryName != null) &&
        this.additionalCostCategoryFilterList.findIndex((item: SelectItem) => item.value === actCategoryItem.value) === -1) {
        this.additionalCostCategoryFilterList.push(actCategoryItem);
      }
      if ((!isNullOrUndefined(act.description) || !isNullOrUndefined(act.additionalCostName) || !isNullOrUndefined(act.fixedCostCategoryName)) &&
        this.referenceDescriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
        this.referenceDescriptionFilterList.push(descItem);
      }

    }

    this.additionalCostCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.referenceDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  getAdditionalCostColumns(event) {
    this.additionalCostCategoryFilterList = [];
    this.referenceDescriptionFilterList = [];
    this.additionalCostColumns = [];
    for (const act of event) {
      const descItem = {
        label: act.description ? act.description : act.additionalCostName,
        value: act.description ? act.description : act.additionalCostName
      };
      const actCategoryItem = {
        label: act.actCategoryName ? act.actCategoryName : act.additionalCostCategoryName,
        value: act.actCategoryName ? act.actCategoryName : act.additionalCostCategoryName
      };
      if ((!isNullOrUndefined(act.actCategoryName) || !isNullOrUndefined(act.additionalCostCategoryName)) &&
        this.additionalCostCategoryFilterList.findIndex((item: SelectItem) => item.value === actCategoryItem.value) === -1) {
        this.additionalCostCategoryFilterList.push(actCategoryItem);
      }
      if ((!isNullOrUndefined(act.description) || !isNullOrUndefined(act.additionalCostName)) &&
        this.referenceDescriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
        this.referenceDescriptionFilterList.push(descItem);
      }

    }
    this.additionalCostCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.referenceDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

    this.additionalCostColumns = [
      {
        field: 'rowExpand',
        header: '',
        width: '33px',
      },
      {
        field: 'additionalCostCategoryName',
        header: 'MODULE_REFERENCE_ACTS_FIELD_ACT_CATEGORY',
        width: '100px',
        filterable: true,
        optionFilterable: true,
        inputType: InputType.dropDown,
        options: this.additionalCostCategoryFilterList,
        sortable: true
      },
      {
        field: 'additionalCostName',
        header: 'MODULE_REFERENCE_ACTS_FIELD_DESCRIPTION',
        width: '240px',
        filterable: true,
        optionFilterable: true,
        inputType: InputType.dropDown,
        options: this.referenceDescriptionFilterList,
        sortable: true,
        editable: true,
        onChange: (data: VisitAdditionalCost) => {
          this.updateTrialAct(data);
        },
        onEdit: (data: VisitAdditionalCost, trialOperationalAct: VisitAdditionalCost) => {
          if (!isNullOrUndefined(trialOperationalAct.hhhId)) {
            this.updateTrialAct(data);
          } else {
            trialOperationalAct.operationalSubActsEdited = true;
          }
        }
      },
      {
        field: 'invoiceable',
        header: 'MODULE_REFERENCE_ACTS_FIELD_INVOICEABLE',
        width: '70px',
        filterable: true,
        inputType: InputType.checkbox,
        sortable: true,
        translated: true,
        editable: false,
        onChange: (data: VisitAdditionalCost) => {
          this.updateTrialAct(data);
        },
        cellAlign: 'center'
      },
    ];

    this.additionalCostColumns.push(
      ...this.useService.canAccessField(this.getFieldNameForActiveModule()) ? [{
        field: 'pricePerUnit',
        header: 'MODULE_REFERENCE_ACTS_FIELD_PRICE_PER_UNIT',
        width: '80px',
        filterable: false,
        inputType: InputType.input,
        sortable: true,
        cssClass: () => {
          return 'pull-right';
        },
        displayContent: (data: VisitAdditionalCost) => {
          let price = '';
          if (!isNullOrUndefined(data.pricePerUnit)) {
            price = Number(data.pricePerUnit).toFixed(2) + ' €';
          }
          return price;
        },
        cssClassForContent: true,
        editable: true,
        hideField: (data: VisitAdditionalCost) => {
          return data.additionalCostType === 'OPERATIONAL_ACT';
        },
        onChange: (data: VisitAdditionalCost) => {
          this.updateTrialAct(data);
        },
        onEdit: (data: VisitAdditionalCost, trialOperationalAct: VisitAdditionalCost) => {
          if (!isNullOrUndefined(trialOperationalAct.hhhId)) {
            this.updateTrialAct(data);
          } else {
            trialOperationalAct.operationalSubActsEdited = true;
          }
        }
      }] : []
    );
    if (((this.activeModule === 'ACT') || (this.activeModule === 'FIXED_COST'))) {
      this.additionalCostColumns.push({
        field: 'optional',
        header: 'MODULE_REFERENCE_ACTS_FIELD_OPTIONAL',
        width: '70px',
        filterable: true,
        inputType: InputType.checkbox,
        sortable: true,
        translated: true,
        editable: false,
        onChange: (data: VisitAdditionalCost) => {
          this.updateTrialAct(data);
        },
        cellAlign: 'center'
      });
    }
    this.additionalCostColumns.push(
      {
        field: !this.allFields ? 'more' : 'less',
        header: '',
        width: '33px',
        iconButton: true,
        iconButtonCssClass: () => {
          return 'fa fa-trash-o ';
        },
        iconButtonEvent: (data: VisitAdditionalCost) => {
          this.removeSelectedAct(data);
        },
        moreFields: !this.allFields,
        displayFields: () => {
          this.allFields = true;
          this.getAdditionalCostColumns(event);
        },
        hideFields: () => {
          this.allFields = false;
          this.getAdditionalCostColumns(event);
        },
        editable: false,
        removeRelatedAct: (data: VisitAdditionalCost) => {
          this.removeActRelatedToOperationalAct(data);
        },
        cellAlign: 'center'
      }
    );
  }


  setMenu() {
    this.trialAddtionalCost = [];
    this.referenceAddtionalCost = [];

    this.trialAddtionalCost.push({
      label: 'Actes de l\'étude', command: event1 => {
        this.actActive = true;
        this.fixedCostActive = false;
        this.counterPartActive = false;
        this.operationalActActive = false;
        this.activeModule = 'ACT';
        this.getReferenceAdditionalCostList();
        this.getAdditionalCost();
        this.setActiveMenu();
      },
      disabled: !this.hasActsPermission
    });
    this.referenceAddtionalCost.push({
      label: 'Actes de reference', command: event1 => {
        this.actActive = true;
        this.fixedCostActive = false;
        this.counterPartActive = false;
        this.operationalActActive = false;
        this.activeModule = 'ACT';
        this.getReferenceAdditionalCostList();
        this.getAdditionalCost();
        this.setActiveMenu();
      },
      disabled: !this.hasActsPermission
    });

    if (this.attachedToVisit) {
      // Fixed costs
      this.trialAddtionalCost.push(
        {
          label: 'Coûts / Surcoûts de l\'étude',
          command: event1 => {
            this.actActive = false;
            this.fixedCostActive = true;
            this.counterPartActive = false;
            this.operationalActActive = false;
            this.activeModule = 'FIXED_COST';
            this.getReferenceAdditionalCostList();
            this.getAdditionalCost();
            this.setActiveMenu();
          },
          disabled: !this.hasFixedCostsPermission
        }
      );
      this.referenceAddtionalCost.push(
        {
          label: 'Coûts / Surcoûts de reference', command: event1 => {
            this.actActive = false;
            this.fixedCostActive = true;
            this.counterPartActive = false;
            this.operationalActActive = false;
            this.activeModule = 'FIXED_COST';
            this.getReferenceAdditionalCostList();
            this.getAdditionalCost();
            this.setActiveMenu();
          },
          disabled: !this.hasFixedCostsPermission
        }
      );


      // Counter parts
      if (this.counterPartEnabled) {
        this.trialAddtionalCost.push(
          {
            label: 'Contreparties de l\étude', command: event1 => {
              this.actActive = false;
              this.fixedCostActive = false;
              this.counterPartActive = true;
              this.operationalActActive = false;
              this.activeModule = 'COUNTER_PART';
              this.getReferenceAdditionalCostList();
              this.getAdditionalCost();
              this.setActiveMenu();
            },
            disabled: !this.hasCounterPartsPermission
          }
        );
        this.referenceAddtionalCost.push(
          {
            label: 'Contreparties de reference', command: event1 => {
              this.actActive = false;
              this.fixedCostActive = false;
              this.counterPartActive = true;
              this.operationalActActive = false;
              this.activeModule = 'COUNTER_PART';
              this.getReferenceAdditionalCostList();
              this.getAdditionalCost();
              this.setActiveMenu();
            },
            disabled: !this.hasCounterPartsPermission
          }
        );
      }
    }
    if (this.operationalActEnabled && this.isArcViewActive) {
      this.trialAddtionalCost.push(
        {
          label: 'Actes opérationnels de l\étude', command: event1 => {
            this.actActive = false;
            this.fixedCostActive = false;
            this.counterPartActive = false;
            this.operationalActActive = true;
            this.activeModule = 'OPERATIONAL_ACT';
            this.getReferenceAdditionalCostList();
            this.getAdditionalCost();
            this.setActiveMenu();
          },
          disabled: !this.hasOperationalActsPermission
        }
      );
      this.referenceAddtionalCost.push(
        {
          label: 'Actes opérationnels de reference', command: event1 => {
            this.actActive = false;
            this.fixedCostActive = false;
            this.counterPartActive = false;
            this.operationalActActive = true;
            this.activeModule = 'OPERATIONAL_ACT';
            this.getReferenceAdditionalCostList();
            this.getAdditionalCost();
            this.setActiveMenu();
          },
          disabled: !this.hasOperationalActsPermission
        }
      );
    }
  }

  removeActRelatedToOperationalAct(data: VisitAdditionalCost) {
    if (!isNullOrUndefined(data.operationalActHhhId)) {
      const operationalAct: VisitAdditionalCost = this.allTrialActs.find(item => item.operationalActHhhId === data.operationalActHhhId);
      if (operationalAct != null) {
        operationalAct.operationalSubActsEdited = true;
        operationalAct.operationalSubActs.splice(operationalAct.operationalSubActs.indexOf(data), 1);
      }
    } else {
      this.displayDeleteSubAct(data);
    }
  }

  displayDeleteSubAct(data: VisitAdditionalCost) {
    const deleteConfirmationComponent = DeleteConfirmationComponent.displayDeleteConfirmationDialogue(this.deletePopupContainer, this.cfr);
    deleteConfirmationComponent.notModal = true;
    deleteConfirmationComponent.hhhId = data.hhhId;
    deleteConfirmationComponent.name = data.additionalCostCategoryName;
    deleteConfirmationComponent.popupMessage = 'MODULE_OPERATIONAL_ACTS_DIALOG_WARNING_OPERATIONAL_SUB_ACT_LIAISON_WILL_BE_DELETED';
    deleteConfirmationComponent.service = this.trialActService;
    deleteConfirmationComponent.dontDeleteWhenDeletable = true;
    deleteConfirmationComponent.onDelete(() => {
      if (!this.syntheticTrialAct.operationalSubActsToRemove) {
        this.syntheticTrialAct.operationalSubActsToRemove = [];
      }
      this.syntheticTrialAct.operationalSubActsToRemove.push(data);
      const trialOperationalAct: VisitAdditionalCost = this.allTrialActs.find(item => item.hhhId === data.trialOperationalActHhhId);
      if (trialOperationalAct != null) {
        trialOperationalAct.operationalSubActs.splice(trialOperationalAct.operationalSubActs.indexOf(data), 1);
      }
    });
  }

  removeSelectedAct(trialAct: VisitAdditionalCost) {
    if (isNullOrUndefined(trialAct.hhhId)) {
      const index = this.allTrialActs.indexOf(trialAct);
      if (index !== -1) {
        this.allTrialActs.splice(index, 1);
      }
      const selectedActIndex = this.syntheticTrialAct.actsToAdd.indexOf(trialAct);
      if (index !== -1) {
        this.syntheticTrialAct.actsToAdd.splice(selectedActIndex, 1);
      }
    } else {
      const actsToRemove: VisitAdditionalCost[] = [];
      actsToRemove.push(trialAct);
      this.displayDeleteMultiple(actsToRemove);
    }
  }

  displayDeleteMultiple(actsToRemove: VisitAdditionalCost[]) {
    const deleteMultipleComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    deleteMultipleComponent.modal = false;
    if (this.activeModule === 'ACT') {
      deleteMultipleComponent.service = this.trialActService;
    } else if (this.activeModule === 'FIXED_COST') {
      deleteMultipleComponent.service = this.trialFixedCostService;
    } else if (this.activeModule === 'OPERATIONAL_ACT') {
      deleteMultipleComponent.service = this.trialOperationalActService;
    } else {
      deleteMultipleComponent.service = this.trialCounterPartService;
    }
    deleteMultipleComponent.selectedList = actsToRemove;
    deleteMultipleComponent.dontDeleteWhenDeletable = true;
    deleteMultipleComponent.navigationRoute = '/trial-details/theoretical-calendar';
    deleteMultipleComponent.optionalNavigationRoute = 'trial-details/inclusion-details/calendar';
    deleteMultipleComponent.popupMessage = 'MODULE_TRIALS_ACTS_DIALOG_WARNING_ACT_WILL_BE_DELETED';
    deleteMultipleComponent.name = actsToRemove[0].additionalCostName;
    deleteMultipleComponent.hhhId = this.trialHhhId;
    deleteMultipleComponent.onDelete((deletedItem: number[]) => {
      if (deletedItem && deletedItem.length !== 0) {
        if (!this.syntheticTrialAct.actsToRemoveHhhIds) {
          this.syntheticTrialAct.actsToRemoveHhhIds = [];
        }
        const toRemove = actsToRemove[0].hhhId + '-' + this.activeModule;
        if (this.syntheticTrialAct.actsToRemoveHhhIds.indexOf(toRemove) < 0) {
          this.syntheticTrialAct.actsToRemoveHhhIds.push(toRemove);
        }
        this.allTrialActs.splice(this.allTrialActs.indexOf(actsToRemove[0]), 1);
        this.sharedService.showSuccess();
      }
    });
  }

  displaySelectedAct(referenceAct: VisitAdditionalCost) {
    if (this.trialActIsUsedOnlyOnce && this.referenceActAlreadyAdded(referenceAct)) {
      this.sharedService.showWarning('OPERATIONAL_ACT_ACT_ALREADY_ADDED');
      return;
    }
    if (referenceAct) {
      const trialAct: VisitAdditionalCost = new VisitAdditionalCost();
      if (this.actActive) {
        trialAct.additionalCostType = 'ACT';
        trialAct.referenceActHhhId = referenceAct.hhhId;
      } else if (this.fixedCostActive) {
        trialAct.additionalCostType = 'FIXED_COST';
        trialAct.referenceActHhhId = referenceAct.hhhId;
      } else if (this.counterPartActive) {
        trialAct.additionalCostType = 'COUNTER_PART';
        trialAct.referenceActHhhId = referenceAct.hhhId;
      } else if (this.operationalActActive) {
        trialAct.additionalCostType = 'OPERATIONAL_ACT';
        trialAct.operationalActHhhId = referenceAct.hhhId;
        const actsToAddLength = this.syntheticTrialAct.actsToAdd.length + 1;
        trialAct.dataKeyId = referenceAct.hhhId + '-' + actsToAddLength;
        trialAct.hhhId = null;
      }
      trialAct.additionalCostName = referenceAct.additionalCostName;
      trialAct.invoiceable = referenceAct.invoiceable;
      trialAct.optional = referenceAct.optional;
      trialAct.pricePerUnit = referenceAct.pricePerUnit;
      trialAct.additionalCostCategoryName = referenceAct.additionalCostCategoryName;
      trialAct.selected = true;
      this.syntheticTrialAct.actsToAdd.push(trialAct);
      this.allTrialActs.push(trialAct);
      this.getAdditionalCostColumns(this.allTrialActs);
    }
  }

  referenceActAlreadyAdded(act: VisitAdditionalCost): boolean {
    return this.allTrialActs.some(trialAct =>
      trialAct.additionalCostName === act.additionalCostName
      && trialAct.additionalCostCategoryName === act.additionalCostCategoryName);
  }

  saveActs() {
    this.disabledSaveActsButton = true;
    this.trialActService.addDetailedTrialActs(this.trialHhhId, this.syntheticTrialAct).subscribe((trialActs: VisitAdditionalCost[]) => {
      const newTrialActs: VisitAdditionalCost[] = this.allTrialActs.filter(item => isNullOrUndefined(item.hhhId));
      this.syntheticTrialActDataService.refresh();
      for (const newAct of newTrialActs) {
        this.allTrialActs.splice(this.allTrialActs.indexOf(newAct), 1);
      }
      // this.allTrialActs.push(...trialActs);
      this.getAdditionalCostColumns(this.allTrialActs);
      this.saveCallback(trialActs, this.allTrialActs);
      this.display = false;
      this.dataService.changeFromOperationalActs(false);
    });
  }

  updateTrialAct(trialAct: VisitAdditionalCost) {
    if (!isNullOrUndefined(trialAct.hhhId)) {
      if (!this.syntheticTrialAct.actsToEdit) {
        this.syntheticTrialAct.actsToEdit = [];
      }
      const lastModifiedTrialAct: VisitAdditionalCost = this.syntheticTrialAct.actsToEdit.find(item => item.hhhId === trialAct.hhhId);
      if (!lastModifiedTrialAct) {
        this.syntheticTrialAct.actsToEdit.push(trialAct);
      }
    }
  }

  cancel() {
    for (const act of this.syntheticTrialAct.actsToAdd) {
      const index = this.allTrialActs.indexOf(act);
      if (index !== -1) {
        this.allTrialActs.splice(index, 1);
      }
    }
    this.syntheticTrialAct.actsToAdd = [];
    this.display = false;
    this.dataService.changeFromOperationalActs(false);
  }

  onSave(callback: (trialActs: VisitAdditionalCost[], allTrialActs: VisitAdditionalCost[]) => void) {
    this.saveCallback = callback;
  }

  getAdditionalCost() {
    this.allTrialActs = [];
    if (this.actActive) {
      this.trialActService.getTrialTrialActList(this.trialHhhId).subscribe(res => {
        this.allTrialActs = res.filter(act => this.syntheticTrialAct.actsToRemoveHhhIds.indexOf(act.hhhId + '-ACT') < 0);
        for (const trialAct of this.syntheticTrialAct.actsToAdd) {
          if (trialAct.additionalCostType === 'ACT') {
            this.allTrialActs.push(trialAct);
          }
        }
        this.getAdditionalCostColumns(res);
        this.buildAdditionalCostTableHeaders();
      });
    } else if (this.fixedCostActive) {
      this.trialFixedCostService.getAttachedTrialFixedCosts(this.trialHhhId).subscribe(res => {
        this.allTrialActs = res.filter(act => this.syntheticTrialAct.actsToRemoveHhhIds.indexOf(act.hhhId + '-FIXED_COST') < 0);
        for (const trialFixedCost of this.syntheticTrialAct.actsToAdd) {
          if (trialFixedCost.additionalCostType === 'FIXED_COST') {
            this.allTrialActs.push(trialFixedCost);
          }
        }
        this.getAdditionalCostColumns(res);
        this.buildAdditionalCostTableHeaders();
      });
    } else if (this.counterPartActive) {
      this.allTrialActs = [];
      this.trialCounterPartService.getAttachedTrialCounterParts(this.trialHhhId).subscribe(res => {
        this.allTrialActs = res.filter(act => this.syntheticTrialAct.actsToRemoveHhhIds.indexOf(act.hhhId + '-COUNTER_PART') < 0);
        for (const trialCounterPart of this.syntheticTrialAct.actsToAdd) {
          if (trialCounterPart.additionalCostType === 'COUNTER_PART') {
            this.allTrialActs.push(trialCounterPart);
          }
        }
        this.getAdditionalCostColumns(res);
        this.buildAdditionalCostTableHeaders();

      });
    } else if (this.operationalActActive) {
      this.trialOperationalActService.getAttachedTrialOperationalActList(this.trialHhhId).subscribe(res => {
        this.allTrialActs = res.filter(act => this.syntheticTrialAct.actsToRemoveHhhIds.indexOf(act.hhhId + '-OPERATIONAL_ACT') < 0);
        for (const trialOperationalAct of this.syntheticTrialAct.actsToAdd) {
          if (trialOperationalAct.additionalCostType === 'OPERATIONAL_ACT') {
            this.allTrialActs.push(trialOperationalAct);
          }
        }
        this.getAdditionalCostColumns(res);
        this.buildAdditionalCostTableHeaders();
      });
    }
  }

  setActiveMenu() {
    if (this.actActive) {
      this.trialActiveItem = this.trialAddtionalCost[0];
      this.referenceActiveItem = this.referenceAddtionalCost[0];
    } else if (this.fixedCostActive) {
      this.trialActiveItem = this.trialAddtionalCost[1];
      this.referenceActiveItem = this.referenceAddtionalCost[1];

    } else if (this.counterPartActive) {
      this.trialActiveItem = this.trialAddtionalCost[2];
      this.referenceActiveItem = this.referenceAddtionalCost[2];

    }
    if (this.operationalActActive) {
      this.trialActiveItem = this.trialAddtionalCost[3];
      this.referenceActiveItem = this.referenceAddtionalCost[3];

    }
  }

  addNewActs() {
    this.display=false
    this.syntheticTrialActDataService.syntheticTrialAct = JSON.parse(JSON.stringify(this.syntheticTrialAct));
    this.dataService.changeFromModule(this.trialHhhId);
    this.dataService.changeFromSyntheticCalendar(true);
    this.displayFormDialogue();
  }


  displayFormDialogue() {
    const formComponent = OperationalActFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.dialogReferenceActHeader = 'MODULE_OPERATIONAL_ACTS_GENERAL_ADD_NEW';
    formComponent.operationalAct = new OperationalAct();
    formComponent.saveOnlyTrialOperationalAct = true;
    formComponent.onAdd((newOperationalAct: OperationalAct) => {
      this.trialOperationalActService.createNew(this.trialHhhId, newOperationalAct).subscribe((res) => {
        this.allTrialActs.push(VisitAdditionalCost.generateSingleVisitAdditionalCostFromTrialOperationalAct(res));
        this.display=true;
      });
    });
    formComponent.cancelAction.subscribe(() => {
      this.display = true;
    });
  }


  getFieldNameForActiveModule(): string {
    switch (this.activeModule) {
      case 'ACT': {
        return HiddenFields.ACTE_PRICE;
      }
      case 'FIXED_COST' : {
        return HiddenFields.FIXED_COST_PRICE;
      }
      case 'COUNTER_PART': {
        return HiddenFields.COUNTER_PART_PRICE;
      }
      case 'OPERATIONAL_ACT': {
        return HiddenFields.OPERATIONAL_ACT_PRICE;
      }
    }

  }

}

import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {UsersService} from '../services/users.service';
import {User} from '../models/user';
import {SelectItem} from 'primeng/api';
import {Data} from '../../shared/entity/data';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {Router} from '@angular/router';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {DataService} from '../../shared/services/data-service';
import {ACCOUNT_MANAGEMENT_READ_WRITE} from '../../login/services/authentication.service';
import {Permission} from '../../helpers/permission';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {GlobalConfigAppData} from '../../dynamic-config/gloabl-config-app-data';
import {AppData} from '../../helpers/app-data';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';


@Component({
  selector: 'ih-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.css']
})
export class AccountListComponent extends PaginatorTableComponent<User> implements OnInit {

  target = DynamicDefinitionEnum.ADMIN_ACCOUNT_TABLE;
  config: TableConfig;

  headers: DynamicTableHeader[] = [];
  users: User[];
  accountStateFilter: SelectItem[];
  public authorized: boolean;
  public userRole: string;

  constructor(
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    private userService: UsersService,
    private dynamicConfigService: DynamicConfigService,
    private translateService: InnohealthTranslateService,
    private router: Router
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.userRole = localStorage.getItem('authenticatedUserRole');
    this.authorized = ((this.userRole === 'ADMIN' || this.userRole === 'EDITOR') &&
      localStorage.getItem(ACCOUNT_MANAGEMENT_READ_WRITE) !== Permission.NO_RIGHT);
    if (this.authorized) {
      this.getUsers();
      this.accountStateFilter = Data.activeOptions;
      this.initTableConfig();
    }
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, GlobalConfigAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders() {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('userName')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('title')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('lastName')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstName')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('duty')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('establishment')
      .accessible(this.dynamicConfigService.parent)
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('establishment-children')
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('role')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('role')
      .sortable()
      .displayContent((user: User) => {
        return user.role;
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('active')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.accountStateFilter)
      .sortable()
      .displayContent((data: User) => {
        return data.active ?
          this.translateService.getTranslationString('MODULE_USER_ADMIN_USER_ACTIVE') :
          this.translateService.getTranslationString('MODULE_USER_ADMIN_USER_NOT_ACTIVE');
      })
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getUsers() {
    this.userService.getUsers().subscribe(users => {
      this.users = users;
      this.userService.setExportedUser(users);
    });
  }

  getExportPageModule(): string {
    return 'src/app/admin/account-list/account-list.component';
  }

  edit(user: User) {
    this.router.navigate(['/access-rights'], {queryParams: {id: user.hhhId}}).then();
  }

  addNew() {
    this.router.navigate(['/new-user']).then();
  }

  exportAccounts(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .build();

    this.userService.exportUsers(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }
}

export class ArcVisit {
  public arcName: string;
  public trialName: string;
  public patientName: string;
  public expectedVisitDate: Date;
  public realVisitDate: Date;
  public visitName: string;
  public visitType: string;
  init(obj: any): ArcVisit {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }


}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {IsStateSwitchable} from '../entity/inclusion/is-state-switchable';
import {Observable} from 'rxjs/index';
import {TrialAct} from '../entity/theoretical-calendar/trial-act';
import {InvoiceAct} from '../../invoice/invoice-acts/models/invoice-act';
import {InvoiceFixedCost} from '../../invoice/models/invoice-fixed-cost';
import {VisitAct} from '../entity/inclusion/visit-act';
import {ReferenceAdditionalCost} from '../../additional-costs/models/refrence-additional-cost';
import {VisitAdditionalCost} from '../entity/trial-additionnal-costs/visit-additional-cost';
import {StateType} from '../../shared/entity/payment-type.enum';
import {SwitchState} from '../entity/inclusion/switch-state';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VisitActService {

  public host = new Properties().host + '/inclusion-visit-act';

  constructor(private httpClient: HttpClient) {
  }

  setAllActsAsCompleted(switchState: SwitchState) {
    return this.httpClient.put<IsStateSwitchable>(this.host + '/switch-status-to-complete/all', switchState);
  }

  setAllActsAsNotRealised(switchState: SwitchState) {
    return this.httpClient.put<IsStateSwitchable>(this.host + '/switch-status-to-not-realised/all', switchState);
  }

  setSelectedActsAsCompleted(visitActHhhIds: number[], stateHhhIdTo: number) {
    const data = {stateType: StateType.REALISATION, stateHhhIdTo, visitActHhhIds};
    return this.httpClient.put<IsStateSwitchable>(this.host + '/switch-status', data);
  }

  getIsVisitActStateSwitchable(hhhIds: number[], stateHhhIdTo: number, paymentStateType: StateType) {
    const data = {visitActHhhIds: hhhIds, paymentStateType, stateHhhIdTo};
    return this.httpClient.post<IsStateSwitchable>(this.host + '/switchable', data);
  }

  updateVisitActsState(visitActHhhIds: number[], stateType: StateType, stateHhhIdTo: number) {
    const data = {stateType, stateHhhIdTo, visitActHhhIds};
    return this.httpClient.put(this.host + '/switch-status', data);
  }

  getReferenceActByVisitAct(hhhId: number, type: string): Observable<ReferenceAdditionalCost> {
    return this.httpClient.get<ReferenceAdditionalCost>(this.host + '/reference-act/' + hhhId + '/' + type)
      .pipe(map(res => new ReferenceAdditionalCost().init(res)));
  }

  getTrialActByVisitAct(hhhId: number): Observable<TrialAct> {
    return this.httpClient.get<TrialAct>(this.host + '/trial-act' + '/' + hhhId );
  }

  updateVisitAct(hhhId: number, invoiceable: boolean, pricePerUnit: number, numberOfUnit: number) {
    const visitAct = new VisitAct();
    visitAct.hhhId = hhhId;
    visitAct.invoiceable = invoiceable;
    visitAct.pricePerUnit = pricePerUnit;
    visitAct.numberOfUnits = numberOfUnit;
    return this.httpClient.put(this.host + '/update/' + hhhId, visitAct);
  }

  updateVisitOperationalSubActs(visitOperationalSubActs: VisitAdditionalCost[]) {
    return this.httpClient.put<void>(this.host + '/update/visit-operational-sub-acts', visitOperationalSubActs);
  }

  isDeletable(hhhId: number) {
    return this.httpClient.get(this.host +  '/deletable/' + hhhId);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  getAllPendingActs(trialId: number): Observable<InvoiceAct[]> {
    return this.httpClient.get<InvoiceAct[]>(this.host + '/acts/pending/load-list/' + (trialId ? trialId : '0'));
  }

  getAllPendingCounterParts(trialId: number): Observable<InvoiceAct[]> {
    return this.httpClient.get<InvoiceAct[]>(this.host + '/counter-parts/pending/load-list/' + (trialId ? trialId : '0'));
  }

  getAllPendingFixedCosts(trialId: number): Observable<InvoiceFixedCost[]> {
    return this.httpClient.get<InvoiceFixedCost[]>(this.host + '/fixed-costs/pending/' + (trialId ? trialId : '0'));
  }


  updateVisitActRealisationDate(visitActHhhId: number, realisationDate: Date) {
    const data = {
      visitActHhhId: String(visitActHhhId),
      realisationDate: realisationDate.toISOString() // ✅ Convertir en format ISO-8601
    };
    return this.httpClient.put<void>(this.host + '/update/realisation-date', data);
  }


  getVisitActs(hhhId: number): Observable<VisitAct[]> {
    return this.httpClient.get<VisitAct[]>(this.host + '/load-list/' + hhhId);
  }

  addActsToVisit(visitHhhId: number, trialActsHhhIds: any): Observable<VisitAct[]> {
    return this.httpClient.get<VisitAct[]>(this.host + '/add-acts/' + visitHhhId + '/' + trialActsHhhIds);
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'underScoreSplit'
})
export class UnderScoreSplitPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value != null && value.length > 0 ? value.toString().substring(0, value.length - 1).split('_') : [];
    return value;
  }

}

export class AuditLogGlobalFilter {
  public content: string[];
  public action: string[];
  public owner: number[];
  public username: string[];
  public trialHhhId: number[];
  public trialIdentifier: string[];
  public module: string[];
  public timestamp: string[];

  constructor() {
    this.content = [];
    this.action = [];
    this.owner = [];
    this.username = [];
    this.trialHhhId = [];
    this.trialIdentifier = [];
    this.module = [];
    this.timestamp = [];
  }
}

import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS} from '@angular/forms';
import {isNullOrUndefined} from 'util';

@Directive({
  selector: '[startBeforeEndDateValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: StartBeforeEndDateValidatorDirective,
    multi: true
  }]
})
export class StartBeforeEndDateValidatorDirective {
  @Input() startBeforeEndDateValidator: Date[] = [];

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (this.startBeforeEndDateValidator !== undefined) {
      for (const comparatorValue of this.startBeforeEndDateValidator) {
        if (!isNullOrUndefined(comparatorValue) && !isNullOrUndefined(control.value) && comparatorValue > control.value) {
          return {notAfter: true};
        }
      }
    }
    return null;
  }
}

import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {DataCommentCount} from '../../data-comment/entity/data-comment-count';

export class TrialMethodologyIdentificationInformation extends DataCommentCount {

  public hhhId: number;
  public trialId: number;
  public protocolId: number;
  public frequencyOfTreatmentAdministration: string;
  public treatmentExperimentalPrincipal: string;
  public treatmentExperimentalOther: string;
  public otherTreatmentMethodology: string;
  public otherAdministrationRoutes: string;
  public otherTreatmentLine: string;
  public dmTrial: string;
  public treatmentExperimentalHhhId: number;
  public trialTreatmentsHhhId: number[] = [];
  public trialTechniqueTypesHhhId: number[] = [];
  public therapeuticClassForDrugsList: string[] = [];
  public otherTreatmentExperimentalHhhId: number[] = [];
  public targetOfTreatmentExperimentalPrincipalHhhId: number[] = [];
  public moleculesStudiedListHhhId: number[] = [];
  public administrationRoutesHhhId: number[] = [];
  public metastaticLinesHhhId: number[] = [];
  public trialTechniqueTypeOtherValue: string;
  public researchObjectHhhId: number;
  public researchObjectOtherValue: string;
  public researchNatureHhhId: number;
  public researchNatureOtherValue: string;
  public technologicalMaturityLevel: number;
  public minAge: string;
  public maxAge: string;
  public moleculeStudied: string;
  public inclusionObjective: any;
  public multiSite: boolean;
  public blinded: boolean;
  public numberOfCentresHhhId: number[] = [];
  public targetOfTreatmentExperimentalHhhId: number[] = [];
  public numberOfCentresSingleHhhId: number;

  public researchObjectHhhIds: number[]=[];
  public interventionResearch: boolean;
  public medicoEconomic: boolean;
  public cancerTrial: boolean;

  public dci1: number;
  public dci2: number;
  public dci3: number;
  public inIndicationAMM: boolean;
  public outOfIndicationAMM: boolean;
  public withoutAMM: boolean;
  public franceAMM: boolean;
  public otherCountriesAMM: boolean;
  public pharmaceuticalCircuitLatestVersion: string;

  init(obj: any): TrialMethodologyIdentificationInformation {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  isValid(config: FormConfig, isOtherTreatmentMethodologySelected: boolean,
          isOtherSelected: boolean, isOtherAdministrationRouteSelected: boolean,
          isOtherTreatmentLineSelected: boolean, researchNatureOther: boolean,
          researchObjectOther: boolean): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'trialTreatments') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.trialTreatmentsHhhId)) {
          return false;
        }
      } else if (key === 'otherTreatmentMethodology') {
        if (isOtherTreatmentMethodologySelected && required && Util.isNullOrUndefinedOrEmpty(this.otherTreatmentMethodology)) {
          return false;
        }
      } else if (key === 'trialTechniqueTypes') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.trialTechniqueTypesHhhId)) {
          return false;
        }
      } else if (key === 'trialTechniqueTypeOtherValue') {
        if (isOtherSelected && required && Util.isNullOrUndefinedOrEmpty(this.trialTechniqueTypeOtherValue)) {
          return false;
        }
      } else if (key === 'therapeuticClassForDrugs') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.therapeuticClassForDrugsList)) {
          return false;
        }
      } else if (key === 'treatmentExperimental') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.treatmentExperimentalHhhId)) {
          return false;
        }
      } else if (key === 'targetOfTreatmentExperimentalPrincipal') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.targetOfTreatmentExperimentalPrincipalHhhId)) {
          return false;
        }
      } else if (key === 'otherTreatmentExperimental') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.otherTreatmentExperimentalHhhId)) {
          return false;
        }
      } else if (key === 'targetOfTreatmentExperimental') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.targetOfTreatmentExperimentalHhhId)) {
          return false;
        }
      } else if (key === 'numberOfCentres') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.numberOfCentresSingleHhhId)) {
          return false;
        }
      } else if (key === 'moleculesStudiedList') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.moleculesStudiedListHhhId)) {
          return false;
        }
      } else if (key === 'administrationRoutes') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.administrationRoutesHhhId)) {
          return false;
        }
      } else if (key === 'otheradministrationRoutes') {
        if (isOtherAdministrationRouteSelected && required && Util.isNullOrUndefinedOrEmpty(this.otherAdministrationRoutes)) {
          return false;
        }
      } else if (key === 'dmTrial') {
        if (this.trialTreatmentsHhhId && this.trialTreatmentsHhhId.indexOf(5) !== -1 && required && Util.isNullOrUndefinedOrEmpty(this.dmTrial)) {
          return false;
        }
      } else if (key === 'metastaticLines') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.metastaticLinesHhhId)) {
          return false;
        }
      } else if (key === 'otherTreatmentLine') {
        if (isOtherTreatmentLineSelected && required && Util.isNullOrUndefinedOrEmpty(this.otherTreatmentLine)) {
          return false;
        }
      } else if (key === 'researchNature') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.researchNatureHhhId)) {
          return false;
        }
      } else if (key === 'researchNatureOtherValue') {
        if (this.researchNatureHhhId && researchNatureOther && required && Util.isNullOrUndefinedOrEmpty(this.researchNatureOtherValue)) {
          return false;
        }
      } else if (key === 'researchObject') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.researchObjectHhhIds)) {
          return false;
        }
      } else if (key === 'researchObjectOtherValue') {
        if (this.researchObjectHhhIds && researchObjectOther && required && Util.isNullOrUndefinedOrEmpty(this.researchObjectOtherValue)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

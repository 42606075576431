import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {OperationalAct} from '../models/operational-act';
import {IsDeletable} from '../../is-deletable';
import {VisitAdditionalCost} from '../../trial/entity/trial-additionnal-costs/visit-additional-cost';
import {AdditionalCostOaImage} from '../models/additional-cost-oa-image';
import {OperationalAdditionalCost} from '../models/operational-additional-cost';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class OperationalActService {
  public host = new Properties().host + '/operational-act';

  constructor(private httpClient: HttpClient) {
  }

  getOperationalActList(isConcatenated: boolean): Observable<OperationalAct[]> {
    return this.httpClient.get<OperationalAct[]>(this.host + '/load-list/' + isConcatenated);
  }

  getOperationalReferenceActsByOperationalActId(operationalActHhhId: number): Observable<OperationalAdditionalCost[]> {
    return this.httpClient.get<OperationalAdditionalCost[]>(this.host + '/additional-cost/' + operationalActHhhId);
  }

  getVisitAdditionalCostsByOperationalActId(operationalActHhhId: number): Observable<VisitAdditionalCost[]> {
    return this.httpClient.get<VisitAdditionalCost[]>(this.host + '/visit-additional-cost/' + operationalActHhhId);
  }


  getReferenceActsAndFixedCosts(trialId?: number): Observable<AdditionalCostOaImage[]> {
    return this.httpClient.get<AdditionalCostOaImage[]>(this.host + '/reference-act-and-fixed-cost/' + (trialId = trialId ? trialId : -1));
  }

  addOperationalAct(operationalAct: OperationalAct): Observable<OperationalAct> {
    return this.httpClient.post<OperationalAct>(this.host + '/add', operationalAct);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(operationalActId: number) {
    return this.httpClient.get(this.host + `/delete/${operationalActId}`);
  }

  exportOperationalActs(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

}

import {AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DynamicTableHeader} from './dynamic-table-header';
import {LazyLoadEvent} from 'primeng/api';
import {isNullOrUndefined} from 'util';
import {Table} from 'primeng/table';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {FR} from '../../../shared/entity/calendar-language';
import {FilterType} from './filter-type';
import {TableConfig} from '../../entity/table-config';
import {Data} from '../../../shared/entity/data';
import {Util} from '../../../helpers/util';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {PropertyEnum} from '../../entity/property-enum';

@Component({
  selector: 'ih-dynamic-lazy-table',
  templateUrl: './dynamic-lazy-table.component.html',
  styleUrls: ['./dynamic-lazy-table.component.css']
})
export class DynamicLazyTableComponent implements OnInit, AfterViewChecked {

  @Input() config: TableConfig;
  private _headers: DynamicTableHeader[];
  @Input() values;
  @Input() totalRecord: number;
  @Input() clickable: boolean;
  @Input() hasDetails: boolean;
  @Input() canUpdateOnClear = false;
  @Input() hasRowCssClass = false;
  @Input() responsive = true;
  @Input() disabledDelete = false;
  @Input() disabledUpdate = false;
  @Input() enableEdit = false;
  @Input() editableFields: string[] = [];
  @Input() paginatorRightLabel = 'MODULE_TRIALS_INCLUSIONS_VISITS_ACTS_FIELD_TOTAL';
  @Input() hasSummary = false;
  @Input() hasSaveButton = false;
  @Input() summaryValue: any;
  @Input() dataKey: string;
  @Input() scrollHeight: string='700px';
  @Input() selectable = false;
  @Input() autoDisplayFirst = false;
  @Input() target: string;
  @Input() hasChat = false;
  @Input() hasSuggestPatients = false;
  @Input() exportFilename = '';
  @Input() exportDialogTitle = '';
  @Input() showExportButton = true;
  @Input() lazyLoadOnInit = true;
  @Input() sortOrder = 1;


  first = 0;
  limit = 50;
  paginationOptions: any;
  filterValues: any;
  hhhId: number;
  allSelected = false;
  hasPrivateComment = true;

  locale = FR;
  @Output() filterEvent = new EventEmitter<any>();
  @Output() lazyLoad = new EventEmitter<any>();
  @Output() limitEvent = new EventEmitter<any>();
  @Output() pageEvent = new EventEmitter<any>();
  @Output() preview = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() dataComment = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() clickEvent = new EventEmitter<any>();
  @Output() unselectEvent = new EventEmitter<any>();
  @Output() selectAllEvent = new EventEmitter<any>();
  @Output() resetTableEvent = new EventEmitter<any>();
  @Output() saveTableEvent = new EventEmitter<any>();
  @Output() suggestPatients = new EventEmitter<any>();
  @Output() updateTableEvent = new EventEmitter<any>();
  @Output() chat = new EventEmitter<any>();
  @Output() export = new EventEmitter<any>();

  filterType = FilterType;

  private _selection: any;
  get selection(): any {
    return this._selection;
  }

  @Input()
  set selection(value: any) {
    if (!Util.isNullOrUndefinedOrEmpty(this._selection) && this._selection.length !== this.totalRecord) {
      this.allSelected = false;
    }
    if (this._selection === value) {
      return;
    }
    this._selection = value;
    this.selectionChange.emit(this._selection);
  }

  get headers(): DynamicTableHeader[] {
    return this._headers;
  }

  @Input()
  set headers(headers: DynamicTableHeader[]) {
    if (Util.isNullOrUndefined(headers)) {
      return;
    }
    const savedColumnOrder = !Util.isNullOrUndefined(this.target) ? localStorage.getItem(this.target) : null;
    if (!savedColumnOrder) {
      this._headers = headers;
      this.saveColumnOrder({columns: this.headers});
      return;
    }
    const fields = savedColumnOrder.split(',');
    this._headers = [];
    for (const field of fields) {
      headers.forEach(header => {
        if (header.field === field) {
          this._headers.push(header);
        }
      });
    }
    for (const header of headers) {
      const index = this._headers.findIndex(h => header.field === h.field);
      if (index === -1) {
        this._headers.push(header);
      }
    }
    this.saveColumnOrder({columns: this.headers});
  }

  @Output()
  selectionChange = new EventEmitter<any>();

  constructor(
      private translateService: InnohealthTranslateService,
      public dynamicConfigService: DynamicConfigService,
      private datePipe: DatePipe
  ) {
  }

  ngAfterViewChecked() {
    const tableHeader = document.querySelectorAll<HTMLElement>('.ui-table-scrollable-header');
    for (let i = 0; i < tableHeader.length; i++) {
      if (this.values && this.values.length > 0) {
        tableHeader[i].style.overflow = 'hidden';
      } else {
        tableHeader[i].style.overflow = 'auto';
      }
    }
  }


  ngOnInit(): void {
    this.paginationOptions = Data.paginationOptions;
    this.dynamicConfigService.initProperties().subscribe(() =>
      this.hasPrivateComment = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled));
  }

  filterUpdated() {
    const filterValues = [];
    for (const column of this.headers) {
      if (column.filterable && column.filter) {
        filterValues[column.filter] = column.filterValue;
        if (column.filterType === FilterType.CALENDAR && filterValues[column.filter]) {
          filterValues[column.filter].setHours(12, 0, 0, 0);
        }
      } else if (column.filterable && !column.filter) {
        filterValues[column.field] = column.filterValue;
        if (column.filterType === FilterType.CALENDAR && filterValues[column.field]) {
          filterValues[column.field].setHours(12, 0, 0, 0);
        }
      }
    }
    this.filterEvent.emit(filterValues);
  }


  getData(value: any, index: string) {
    this.first = 0;
    this.filterValues[index] = value;
    this.filterEvent.emit(this.filterValues);
  }


  getDataLimited(value: string) {
    if (value === 'Tout') {
      this.limit = this.totalRecord;
    } else {
      this.limit = +value;
    }
    this.limitEvent.emit(this.limit);
  }

  getRows(): number {
    return this.limit.toString() === 'Tout' ? this.totalRecord : this.limit;
  }

  getSortedData(event: LazyLoadEvent) {
    this.lazyLoad.emit(event);
  }

  getRelatedData(hhhId) {
    if (this.clickable) {
      this.clickEvent.emit(hhhId);
    }
  }

  emitPreview(entity: any) {
    this.preview.emit(entity);
  }

  emitUpdate(entity: any) {
    this.update.emit(entity);
  }

  emitSuggestPatients(entity: any) {
    this.suggestPatients.emit(entity);
    entity.numberOfUnseenSuggestedPatients = 0;
  }

  emitChat(entity: any) {
    this.chat.emit(entity);
  }

  emitDataComment(attachedToId: number, attachedToField: string, isPrivate: boolean) {
    this.dataComment.emit({attachedToId, attachedToField, isPrivate});
  }

  emitDelete(entity: any) {
    this.delete.emit(entity);
  }

  emitUnselect(event: any) {
    this.allSelected = false;
    this.unselectEvent.emit(event);
  }

  emitSelectAll(event: any) {
    this.allSelected = true;
    this.selectAllEvent.emit(event);
  }

  emitUpdateTable(data: any) {
    this.updateTableEvent.emit(data);
  }

  emitResetTable() {
    this.resetTableEvent.emit();
  }

  emitSaveTable() {
    this.saveTableEvent.emit();
  }

  emitExport(event) {
    this.export.emit(event);
  }

  displayContent(col: DynamicTableHeader, colField: any, rowData: any): string {
    let content = colField;
    if (isNullOrUndefined(rowData[col.field]) && !col.displayContent) {
      content = '';
    } else if (col.dateFormatter) {
      content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
    } else if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (col.isTranslated) {
      content = colField ?
          this.translateService.getTranslationString('UI_DIALOG_BUTTON_YES')
          : this.translateService.getTranslationString('UI_DIALOG_BUTTON_NO');
    }
    return content;
  }


  getBtnClass(): string {
    return this.config.getBtnClass();
  }

  displayBtn(btnId: string): boolean {
    if (!this.config || !this.config.buttons) {
      return false;
    }
    return this.config.displayBtn(btnId);
  }

  onClearClickDo(value: any, column: any) {
    if (this.canUpdateOnClear) {
      this.getData(value, column);
    }
  }

  goToBottom(table: Table) {
    const body = table.containerViewChild.nativeElement.getElementsByClassName('ui-table-scrollable-body')[0];
    body.scrollTop = body.scrollHeight;
  }

  goToTop(table: Table) {
    const body = table.containerViewChild.nativeElement.getElementsByClassName('ui-table-scrollable-body')[0];
    body.scrollTop = 0;
  }

  isFieldEditable(name: string): boolean {
    return this.editableFields.includes(name);
  }

  saveColumnOrder(event: any) {
    const fields = event.columns.map(value => value.field);
    if (Util.isNullOrUndefinedOrEmpty(fields)) {
      return;
    }
    localStorage.setItem(this.target, fields);
  }
}

import {JsonObject, JsonProperty} from 'json2typescript';
import {ModuleHeaderConfig} from './module-header-config';

@JsonObject('DynamicModuleDefinitionHeader')
export class DynamicModuleDefinitionHeader {

  @JsonProperty('defaultTitle', String, true)
  defaultTitle: string = undefined;

  @JsonProperty('config', ModuleHeaderConfig)
  config: ModuleHeaderConfig = new ModuleHeaderConfig();


}

import {ArmTreeNode} from './arm-tree-node';
import {Arm} from '../entity/inclusion/arm';
import {DesignStructureConfig} from './design-structure-config';
import {TreeNode} from 'primeng/api';

export class ArmTree {
  nodes: Array<ArmTreeNode> = new Array<ArmTreeNode>();

  public buildFrom(arms: Array<Arm>, config: DesignStructureConfig): ArmTree {
    if (config.name.shown) {
      for (const arm of arms) {
        this.nodes.push(this.constructTreeNode(arm.name, arm, 1, config));
      }
      return this;
    }
    for (const arm of arms) {
      let level = 0;
      let nodeValue = this.getFirstNodeValue(arm, config, level);
      const armMaxDepth = 4 + arm.designLevels.length;
      while (nodeValue.length === 0 && level <= armMaxDepth) {
        level++;
        nodeValue = this.getFirstNodeValue(arm, config, level);
      }
      if (nodeValue.length > 0) {
        const existingNode = this.nodes.find(n => n.value === nodeValue);
        const newNode = this.constructTreeNode(nodeValue, arm, level, config);
        if (existingNode && !(existingNode.nodes.length === 0 || newNode.nodes.length === 0)) {
          existingNode.addNode(arm, config);
        } else {
          this.nodes.push(newNode);
        }
      }
    }
    return this;
  }

  public toTreeNodeArray(): TreeNode[] {
    const result: Array<TreeNode> = [];
    for (const node of this.nodes) {
      result.push(node.toTreeNode());
    }
    return result;
  }

  private getFirstNodeValue(arm: Arm, config: DesignStructureConfig, level: number): string {
    let result = '';
    if (config.phase.level === level && arm.phase && arm.phase.phase && arm.phase.enabled) {
      result = arm.phase.phase.nameFr;
    } else if (config.cohort.level === level && arm.cohort && arm.cohort.cohort && arm.cohort.enabled) {
      result = arm.cohort.cohort.name;
    } else if (config.inclusionArm.level === level && arm.inclusionArm && arm.inclusionArm.inclusionArm && arm.inclusionArm.enabled) {
      result = arm.inclusionArm.inclusionArm.name;
    } else if (config.escaladeExtension.level === level && arm.escaladeExtension && arm.escaladeExtension.escaladeExtension && arm.escaladeExtension.enabled) {
      result = arm.escaladeExtension.escaladeExtension;
    } else {
      for (let i = 0; i < arm.designLevels.length; i++) {
        const target = arm.designLevels[i];
        if (target.type === 'LIAISON' && level === (i + 4)) {
          result = target.fieldValue;
          break;
        } else if (target.type === 'TREATMENT') {
          if (level === (i + 4)) {
            result = target.studiedMolecule && target.studiedMolecule.enabled && target.studiedMolecule.studiedMolecule ? target.studiedMolecule.studiedMolecule.name : '';
          } else if (level === (i + 5)) {
            result = target.administrationRoute && target.administrationRoute.enabled && target.administrationRoute.administrationRoute ? target.administrationRoute.administrationRoute.name : '';
          } else if (level === (i + 6)) {
            result = target.dose ? target.dose.dose : '';
          }
        }
      }
    }
    return result ? result : '';
  }

  private constructTreeNode(targetValue: string, arm: Arm, nextLevel: number, config: DesignStructureConfig): ArmTreeNode {
    const newNode = new ArmTreeNode();
    newNode.value = targetValue;
    newNode.level = nextLevel;
    newNode.armDto = arm;
    newNode.addNode(arm, config);
    return newNode;
  }

}

import {DynamicTableHeader} from './dynamic-table-header';
import {FilterType} from './filter-type';

export class DynamicTableHeaderBuilder {

  _dynamicTableHeader: DynamicTableHeader;

  constructor() {
    this._dynamicTableHeader = new DynamicTableHeader();
  }

  accessible(accessible: boolean): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.accessible = accessible;
    return this;
  }

  field(field: string): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.field = field;
    return this;
  }

  header(header: string): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.header = header;
    return this;
  }

  width(width: string): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.width = width;
    return this;
  }

  filterable(filterable = true): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.filterable = filterable;
    return this;
  }

  filter(filter: any): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.filter = filter;
    return this;
  }

  filterType(filterType: FilterType): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.filterType = filterType;
    return this;
  }

  options(options: any): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.options = options;
    return this;
  }

  optionFilterable(): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.optionFilterable = true;
    return this;
  }

  type(type: any): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.type = type;
    return this;
  }

  composedFilter(composedFilter: any []): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.composedFilter = composedFilter;
    return this;
  }

  sortable(sortable = true): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.sortable = sortable;
    return this;
  }

  sortField(sortField: string): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.sortField = sortField;
    return this;
  }

  linkClickEvent(linkClickEvent: any): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.linkClickEvent = linkClickEvent;
    return this;
  }

  isTranslated(): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.isTranslated = true;
    return this;
  }
  isYesNoEnumTranslated(): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.isYesNoEnumTranslated = true;
    return this;
  }

  withDateFormatter(): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.dateFormatter = true;
    return this;
  }

  cssClass(cssClass: any): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.cssClass = cssClass;
    return this;
  }

  justifyContent(align: string): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.justifyContent = align;
    return this;
  }

  filterMatchType(filterMatchType: 'contains' | 'equals'): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.filterMatchType = filterMatchType;
    return this;
  }

  displayContent(displayContent: any): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.displayContent = displayContent;
    return this;
  }

  withDisplayCssClassContent(): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.displayCssClassContent = true;
    return this;
  }

  inputType(type: string): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.inputType = type;
    return this;
  }

  checked(): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.checked = true;
    return this;
  }

  dynamicCssClass(dynamicClass: any): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.dynamicCssClass = dynamicClass;
    return this;
  }

  withCurrency(): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.currency = true;
    return this;
  }

  isEditable(): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.editable = true;
    return this;
  }

  hideField(callback: any): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.hideField = callback;
    return this;
  }

  onChange(callback: any): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.onChange = callback;
    return this;
  }

  onEdit(callback: any): DynamicTableHeaderBuilder {
    this._dynamicTableHeader.onEdit = callback;
    return this;
  }

  build(): DynamicTableHeader {
    return this._dynamicTableHeader;
  }

}

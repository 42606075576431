import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';

export class VitalSign {
  hhhId: number;
  publicCommentCount: number;
  inclusionHhhId: number;
  size: number;
  weight: number;
  pouls: number;
  temperature: number;
  bloodPressure: number;
  arm: string;
  respiration: any;
  saturation: any;
  ecg: any;
  fasting: boolean;
  visit: string;
  visitHhhId: number;

  init(obj: any): VitalSign {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    const valid = true;
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return valid;
  }
}

import {Component, OnInit} from '@angular/core';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {MenuItem} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit {

  isoStatsEnabled = false;

  public items: MenuItem[];

  constructor(
      private translateService: InnohealthTranslateService,
      private translate: TranslateService,
      private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isoStatsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.isoStatsEnabled);
      this.translate.stream('key').subscribe(() => {
        this.extractItems();
      });
    });
  }

  private extractItems() {
    this.items = [
      {
        label: this.translateService.getTranslationString('REPORT_INCLUSIONS_BY_TRIALS'),
        routerLink: ['/statistics/follow-trials']
      },
      {
        label: this.translateService.getTranslationString('REPORT_INCLUSIONS_BY_TRIALS_INCLUSION'),
        routerLink: ['/statistics/follow-inclusions']
      },
      {
        label: this.translateService.getTranslationString('REPORT_RATE_TO_ACTIVE_IN_PATIENTS'),
        routerLink: ['/statistics/follow-active-file']
      },
      {
        label: this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_EXPORT_FILE_NAME'),
        routerLink: ['/statistics/follow-inclusions-by-trial']
      }
    ];
    if (this.isoStatsEnabled) {
      this.items.push(
          {
            label: this.translateService.getTranslationString('REPORT_ISO_STATS'),
            routerLink: ['/statistics/follow-iso-stats'],
            visible: false
          }
      );
    }
  }

}

import {Injectable} from '@angular/core';

import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {VisitTracking} from '../entity/trial-track/visit-tracking';
import {Properties} from '../../helpers/properties';
import {ResponsePage} from '../../shared/entity/response-page';
import {InclusionTracking} from '../entity/trial-track/inclusion-tracking';
import {TrialFollowUpFilter} from '../entity/trial-track/trial-follow-up-filter';
import {RealDatesVisitsPatientsFileImport} from '../entity/RealDatesVisitsPatientsFileImport';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {ExportTypesEnum} from '../../dynamic-config/exported/data-export/ExportTypesEnum';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrackingTableService {

  public host = new Properties().host + '/trial-follow-up';
  public trialHhhId: number;

  constructor(private httpClient: HttpClient) {
  }

  getTrialTrackingPage(hhhId: number, page: number, limit: number, sortBy: string, orderBy: string, filters: TrialFollowUpFilter): Observable<ResponsePage<InclusionTracking>> {
    return this.httpClient.post<ResponsePage<InclusionTracking>>(
      this.host + '/load-list',
      new RequestPageBuilder<TrialFollowUpFilter>()
        .objectId(hhhId)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(filters)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(inclusionTracking => new InclusionTracking().init(inclusionTracking));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  editVisit(visitTracking: VisitTracking): Observable<void> {
    return this.httpClient.put<void>(this.host + '/update-visit', visitTracking);
  }

  setTrialHhhId(id) {
    this.trialHhhId = id;
  }


  importRealDatesVisitsPatients(realDatesVisitsPatientsFileImport: RealDatesVisitsPatientsFileImport): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', realDatesVisitsPatientsFileImport.file);

    const trialId = realDatesVisitsPatientsFileImport.trialHhhId;
    const armId = realDatesVisitsPatientsFileImport.armHhhId;
    return this.httpClient.post(this.host + '/import/real-dates/visits/' + trialId + '/' + armId, formData, {
      responseType: 'blob'
    });
  }


  export(request: ExportRequest<TrialFollowUpFilter>): Observable<void> {
    switch (request.exportType) {
      case ExportTypesEnum.FORM_EXPORT :
        request.exportType = ExportTypesEnum.FULL_EXPORT;
        return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
      case ExportTypesEnum.TRACKING_TABLE_EXPORT :
      case ExportTypesEnum.TRACKING_TABLE_ADDITIONAL_TEST_EXPORT :
        return this.httpClient.post<void>(this.host + '/export-csv/tracking-table', request).pipe();
    }
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

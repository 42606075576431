import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {InclusionTracking} from '../../../entity/trial-track/inclusion-tracking';
import {PatientDetailFormComponent} from '../patient-detail-form/patient-detail-form.component';
import {VisitTracking} from '../../../entity/trial-track/visit-tracking';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {Consent} from '../../../models/consent';


@Component({
  selector: 'ih-visit-tracking-row',
  templateUrl: './visit-tracking-row.component.html',
  styles: [`
    .carousel-demo .ui-carousel .ui-carousel-content .ui-carousel-item .car-details > .p-grid {
      border: 1px solid #b3c2ca;
      border-radius: 3px;
      margin: 0.3em;
      text-align: center;
      padding: 2em 0 2.25em 0;
    }

    .carousel-demo .ui-carousel .ui-carousel-content .ui-carousel-item .car-data .car-title {
      font-weight: 700;
      font-size: 20px;
      margin-top: 24px;
    }

    .carousel-demo .ui-carousel .ui-carousel-content .ui-carousel-item .car-data .car-subtitle {
      margin: 0.25em 0 2em 0;
    }

    .carousel-demo .ui-carousel .ui-carousel-content .ui-carousel-item .car-data button {
      margin-left: 0.5em;
    }

    .carousel-demo .ui-carousel .ui-carousel-content .ui-carousel-item .car-data button:first-child {
      margin-left: 0;
    }

    .carousel-demo .ui-carousel.custom-carousel .ui-carousel-dot-icon {
      width: 16px !important;
      height: 16px !important;
      border-radius: 50%;
    }

    .carousel-demo .ui-carousel.ui-carousel-horizontal .ui-carousel-content .ui-carousel-item.ui-carousel-item-start .car-details > .p-grid {
      margin-left: 0.6em;
    }

    .carousel-demo .ui-carousel.ui-carousel-horizontal .ui-carousel-content .ui-carousel-item.ui-carousel-item-end .car-details > .p-grid {
      margin-right: 0.6em;
    }
  `],
  styleUrls: ['./visit-tracking-row.component.css']
})

export class VisitTrackingRowComponent implements OnInit {
  @ViewChild('popupDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  @Input() inclusion: InclusionTracking;
  @Input() carouselVisibleNumber: number;
  @Input() beforeDate: boolean;
  @Input() filterByBeforeDate: boolean;
  @Input() isPatientDetails = false;
  public patientInformation: string;
  visits: VisitTracking[];
  responsiveOptions: any;

  constructor(
    private cfr: ComponentFactoryResolver,
    private translateService: InnohealthTranslateService,
    private dynamicConfigService: DynamicConfigService
  ) {

  }

  ngOnInit() {
    this.generatePatientInformation(this.inclusion);
    this.responsiveOptions = [
      {
        breakpoint: '1200px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  showPatientDetails() {
    this.inclusion.consents = this.inclusion.consents.map(consent => new Consent().init(consent));
    const popupDialogFactory = this.cfr.resolveComponentFactory(PatientDetailFormComponent);
    const popupDialogComponentRef = this.popupContainer.createComponent(popupDialogFactory);
    const patientDetailFormComponent = popupDialogComponentRef.instance;
    patientDetailFormComponent.inclusion = this.inclusion;
  }

  generatePatientInformation(inclusion
                               :
                               InclusionTracking
  ) {
    this.inclusion = inclusion;
    if (this.filterByBeforeDate) {
      this.visits = [];
      for (const visit of this.inclusion.visitTrackingDtoList) {
        if (this.beforeDate === visit.beforeDate) {
          this.visits.push(visit);
        }
      }
    }
    const patientName = this.inclusion.patientName ? this.inclusion.patientName : '';
    const inclusionIdentifier = this.inclusion.inclusionIdentifier1 ?
      this.translateService.getTranslationString('MODULE_INVOICES_TRIAL_INCLUSION_NUMBER1_TRACKING_TABLE') + this.inclusion.inclusionIdentifier1 : '';
    const inclusionIdentifier2 = this.inclusion.inclusionIdentifier2 ?
      this.translateService.getTranslationString('MODULE_TRIALS_PATIENTS_FIELD_INCLUSION_IDENTIFIER_2_TRACKING_TABLE') + this.inclusion.inclusionIdentifier2 : '';
    const patientBirthDate = this.inclusion.patientBirthDate ? (this.inclusion.patientBirthDate.toLocaleString() === 'null' ? '' : (this.translateService.getTranslationString('MODULE_TRACKING_TABLE_FIELD_BIRTH_DATE') + this.inclusion.patientBirthDate.toLocaleDateString())) : '';
    const trialTreatmentEndDate = this.inclusion.trialTreatmentEndDate ? (this.inclusion.trialTreatmentEndDate.toLocaleString() === 'null'
      ? '' : this.translateService.getTranslationString('MODULE_TRIALS_PATIENTS_FIELD_TRIAL_TREATMENT_END_DATE_TRACKING_TABLE') + this.inclusion.trialTreatmentEndDate.toLocaleDateString()) : '';
    const armName = this.inclusion.armName ? this.inclusion.armName : '';
    const internalIdentifier = this.inclusion.internalIdentifier ? this.translateService.getTranslationString('MODULE_PATIENT_IMPORT_FIELD_NIP') + ': ' + this.inclusion.internalIdentifier : '';
    this.patientInformation = '';


    if (this.dynamicConfigService.verifyClient(['IGR'])) {
      this.patientInformation = this.patientInformation
        + (patientName !== '' ? (patientName + ' - ') : '')
        + (internalIdentifier !== '' ? (internalIdentifier + ' - ') : '')

        + (inclusionIdentifier !== '' ? (inclusionIdentifier + ' - ') : '')
        + (armName !== '' ? (armName + ' - ') : '');
    } else if (this.dynamicConfigService.verifyClient(['COL'])) {
      const patientName = this.inclusion.patientName ? this.inclusion.patientName : '';
      const inclusionIdentifier = this.inclusion.inclusionIdentifier1 ? this.inclusion.inclusionIdentifier1 : '';
      const armName = this.inclusion.armName ? this.inclusion.armName : '';
      const sex = this.inclusion.sex ? this.inclusion.sex : '';
      const patientInternalIdentifier = this.inclusion.internalIdentifier ? this.inclusion.internalIdentifier : '';
      this.patientInformation = this.patientInformation
        + (patientName !== '' ? (patientName + ' - ') : '')
        + (sex !== '' ? (sex + ' - ') : '')
        + (patientInternalIdentifier !== '' ? (patientInternalIdentifier + ' - ') : '')
        + (inclusionIdentifier !== '' ? (inclusionIdentifier + ' - ') : '')
        + (armName !== '' ? (armName) : '');
    } else {
      this.patientInformation = this.patientInformation
        + (patientName !== '' ? (patientName + ' - ') : '')
        + (inclusionIdentifier !== '' ? (inclusionIdentifier + ' - ') : '')
        + (inclusionIdentifier2 !== '' ? (inclusionIdentifier2 + ' - ') : '')
        + (patientBirthDate !== '' ? (patientBirthDate + ' - ') : '')
        + (trialTreatmentEndDate !== '' ? (trialTreatmentEndDate + ' - ') : '')
        + (armName !== '' ? (armName + ' - ') : '');
    }
    return this.patientInformation;

  }

}

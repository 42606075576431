import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Pharmacy} from '../../../entity/trial-pharmacy/pharmacy';
import {PharmacyAddOneFieldFormComponent} from '../pharmacy-informations-dose-form/pharmacy-add-one-field-form.component';
import {FR} from '../../../../shared/entity/calendar-language';
import {PharmacyService} from '../../../service/pharmacy.service';
import {Router} from '@angular/router';
import {DropdownComponent} from '../../../../shared/component/dropdown/dropdown.component';
import {SharedService} from '../../../../shared/services/shared.service';
import {AUTHENTICATED_USER_ROLE} from '../../../../login/services/authentication.service';
import {Role} from '../../../../login/models/role';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PharmacyDoseService} from '../../../service/pharmacy-dose.service';

@Component({
  selector: 'ih-pharmacy-tab-information',
  templateUrl: './trial-pharmacy-informations.component.html',
  styleUrls: ['./trial-pharmacy-informations.component.css']
})
export class TrialPharmacyInformationsComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_PHARMACY_INFORMATION_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('displayAddDoseFormDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  @ViewChild('dosesDropdown') dosesDropdown: DropdownComponent<any>;

  pharmacyInformation = new Pharmacy();
  locale = FR;
  pharmacyHhhId: number;
  trialHhhId: number;
  pharmacistValidation = false;
  submitted = false;


  // to do
  constructor(
    private cfr: ComponentFactoryResolver,
    public pharmacyService: PharmacyService,
    public pharmacyDoseService: PharmacyDoseService,
    private router: Router,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
      this.pharmacyHhhId = url.queryParams.pharmacyHhhId;
    }
  }

  ngOnInit() {
    this.pharmacyService.setPharmacyReadonlyMode();
    this.load();
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/trial-details/pharmacy'], {
      queryParams: {
        hhhId: this.trialHhhId,
        pharmacyHhhId: this.pharmacyHhhId
      }
    }).then();
  }

  save() {
    this.pharmacyInformation.trialHhhId = this.trialHhhId;
    if (this.pharmacyHhhId == null) {
      this.pharmacyService.add(this.pharmacyInformation).subscribe(
        (data: number) => {
          this.pharmacyHhhId = data;
          this.reloadComponent();
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure();
          console.log(error);
        }
      );
    } else {
      this.pharmacyInformation.pharmacyHhhId = this.pharmacyHhhId;
      this.pharmacyService.update(this.pharmacyInformation).subscribe(
        () => {
          this.reloadComponent();
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure();
          console.log(error);
        }
      );
    }
  }

  load() {
    this.pharmacyService.load(this.trialHhhId).subscribe(
      data => {
        if (data !== null) {
          this.pharmacyInformation = data;
          this.pharmacistValidation = this.pharmacyInformation.pharmacistValidation;
        }
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading information. ' + error);
      }
    );
  }

  displayFormDialogue() {
    const formComponent = PharmacyAddOneFieldFormComponent.displayFormDialogue(this.dialogContainer, this.cfr);
    formComponent.formHeader = 'MODULE_TRIALS_PHARMACY_TAB_INFORMATIONS_BUTTON_ADD_DOSE';
    formComponent.fieldName = 'MODULE_TRIALS_PHARMACY_TAB_INFORMATIONS_FIELD_DOSE';
    formComponent.onAdd((dose) => {
      this.pharmacyDoseService.add(dose).subscribe(
        () => {
          this.dosesDropdown.onRefreshDrop();
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure();
          console.log(error);
        }
      );
    });
  }

  isAdmin() {
    return localStorage.getItem(AUTHENTICATED_USER_ROLE) === Role.Admin;
  }

  cancelChanges() {
    this.ngOnInit();
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);
}


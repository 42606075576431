import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {VisitDoseService} from '../../../../../service/visit-dose.service';
import {VisitDose} from '../../../../../entity/inclusion/visit-dose';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'ih-visit-dose-form',
  templateUrl: './visit-dose-form.component.html',
  styleUrls: ['./visit-dose-form.component.css']
})
export class VisitDoseFormComponent implements OnInit {

  visitHhhId: number;

  display = false;
  visitDoses: VisitDose[] = [];
  visitDose: VisitDose = new VisitDose();
  editMode = false;

  addCallback: () => void;
  deleteCallback: () => void;

  constructor(
    private visitDoseService: VisitDoseService,
    private datepipe: DatePipe
  ) {
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(VisitDoseFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.display = true;
    this.getVisitDoses();
  }

  getVisitDoses() {
    this.visitDoseService.getVisitDoses(this.visitHhhId).subscribe(res => {
      this.visitDoses = res;
    });
  }

  addVisitDose() {
    this.visitDose.visitHhhId = this.visitHhhId;
    this.visitDose.date = this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm');
    this.visitDoseService.addVisitDoses(this.visitDose).subscribe(res => {
      this.visitDoses.push(res);
      this.visitDose = new VisitDose();
      this.addCallback();
    });
  }

  editVisitDose(visitDose: VisitDose) {
    this.editMode = true;
    this.visitDose = visitDose;
  }

  submitEditVisitDose() {
    this.visitDoseService.editDose(this.visitDose).subscribe(res => {
      this.editMode = false;
      this.visitDose = new VisitDose();
    });
  }

  deleteVisitDose(visitDoseHhhId: number) {
    this.visitDoseService.deleteVisitDoses(visitDoseHhhId).subscribe(res => {
      this.visitDoses.splice(this.visitDoses.findIndex((visitDose: VisitDose) => visitDose.hhhId === visitDoseHhhId), 1);
      this.deleteCallback();
    });
  }

  onAdd(callback: () => void) {
    this.addCallback = callback;
  }

  onDelete(callback: () => void) {
    this.deleteCallback = callback;
  }
}

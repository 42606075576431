import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Arm} from '../../../../entity/inclusion/arm';
import {DeleteConfirmationComponent} from '../../../../../modals/delete-confirmation/delete-confirmation.component';
import {ArmService} from '../../../../services/arm.service';
import {AddArmFormComponent} from '../add-arm-form/add-arm-form.component';
import {Router} from '@angular/router';
import {isNullOrUndefined} from 'util';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {DynamicTableHeaderBuilder} from '../../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicTableHeader} from '../../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../../trial-app-data';
import {TableConfig} from '../../../../../dynamic-config/entity/table-config';
import {DataCommentInfo} from '../../../../../data-comment/entity/data-comment';
import {DataCommentComponent} from '../../../../../data-comment/component/data-comment/data-comment.component';
import {AttachedToEntityEnum} from '../../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentEvent} from '../../../../../data-comment/entity/DataCommentEvent';


@Component({
  selector: 'ih-arm-identification-information',
  templateUrl: './arm-identification-information.component.html',
  styleUrls: ['./arm-identification-information.component.css']
})
export class ArmIdentificationInformationComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_ARM_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  @Input() mode: string;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('deleteTrialArmPopup', {read: ViewContainerRef}) deleteArmPopupContainer: ViewContainerRef;
  @ViewChild('addArmDialog', {read: ViewContainerRef}) addArmPopupContainer: ViewContainerRef;

  public arms: Arm[] = [];
  public trialId: number;

  constructor(
      private armService: ArmService,
      private cfr: ComponentFactoryResolver,
      private router: Router,
      private dynamicConfigService: DynamicConfigService,
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    if (!this.mode) {
      this.mode = 'edit';
    }
    if (!isNullOrUndefined(this.trialId)) {
      this.getListArms();
    } else {
      this.armService.armList.subscribe(res => {
        if (res) {
          this.arms = res;
        }
      });
    }
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('name')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  displayDeleteDialogue(entity: any) {
    const deletePopupFactory = this.cfr.resolveComponentFactory(DeleteConfirmationComponent);
    const deletePopupComponentRef = this.deleteArmPopupContainer.createComponent(deletePopupFactory);
    const deleteConfirmationComponent = deletePopupComponentRef.instance;
    deleteConfirmationComponent.hhhId = entity.hhhId;
    deleteConfirmationComponent.name = entity.name;
    deleteConfirmationComponent.trialHhhId = this.trialId;
    deleteConfirmationComponent.popupMessage = 'MODULE_TRIALS_ARMS_DIALOG_WARNING_ARM_WILL_BE_DELETED';
    deleteConfirmationComponent.service = this.armService;
    deleteConfirmationComponent.onDelete(() => {
      const index = this.arms.findIndex(
          (arm: Arm) => arm.hhhId === entity.hhhId
      );
      this.arms.splice(index, 1);
      this.setArmList(this.arms);
    });
  }

  displayAddArmDialog(arm?: Arm) {
    const addArmPopupFactory = this.cfr.resolveComponentFactory(AddArmFormComponent);
    const addArmPopupComponentRef = this.addArmPopupContainer.createComponent(addArmPopupFactory);
    const addArmConfirmationComponent = addArmPopupComponentRef.instance;
    addArmConfirmationComponent.mode = this.mode;
    addArmConfirmationComponent.trialId = this.trialId;
    addArmConfirmationComponent.arm = arm || new Arm();
    addArmConfirmationComponent.onAdd((newArm: Arm) => {
      if (!arm) {
        this.arms.push(newArm);
        this.setArmList(this.arms);
      }
    });
  }

  isModeEdit() {
    return this.mode === 'edit';
  }

  getListArms() {
    this.armService.loadListByTrialAndProtocolId(String(this.trialId), -1).subscribe(result => {
      this.arms = result;
    });
  }


  setArmList(arms: Arm[]) {
    this.armService.setArmList(arms);
  }

  displayDataCommentDialog(event: { attachedToId: number, attachedToField: string, isPrivate: boolean }) {
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.Arm, event.attachedToId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
  }


}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TrialPageLock} from '../../../../shared/entity/trial-page-lock';
import {NgForm} from '@angular/forms';
import {TrialPageLockService} from '../trial-page-lock.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'ih-page-unlock-confirmation',
  templateUrl: './page-unlock-confirmation.component.html',
  styleUrls: ['./page-unlock-confirmation.component.css']
})
export class PageUnlockConfirmationComponent implements OnInit {
  @ViewChild('unlockForm') unlockForm: NgForm;

  displayUnlock: boolean;
  trialLockPage: TrialPageLock = new TrialPageLock();
  submitted = false;
  trialId: number;
  isPasswordInvalid = false;
  commentIsInvalid = false;
  callback: (isUnlocked: boolean) => void;

  constructor(
      private pageLockService: TrialPageLockService,
      private sharedService: SharedService
  ) {
  }

  public static displayUnlockDialog(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(PageUnlockConfirmationComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.getOtherInCommentId();
    this.displayUnlock = true;
  }

  unlock() {
    this.submitted = true;
    if (this.unlockForm.invalid) {
      return;
    }
    if ((this.trialLockPage.trialUnlockCommentHhhId === this.pageLockService.otherId)
        && this.isNullOrEmptyOrUndefined(this.trialLockPage.optionalComment)) {
      this.commentIsInvalid = true;
      return;
    }
    this.trialLockPage.userHhhId = localStorage.getItem('authenticatedUserID');
    this.trialLockPage.username = localStorage.getItem('authenticatedUser');
    this.trialLockPage.trialHhhId = this.trialId;
    this.pageLockService.unlock(this.trialLockPage).subscribe((res) => {
      this.sharedService.showSuccess();
      this.displayUnlock = false;
      this.callback(true);
    }, error => {
      console.error(error);
      this.isPasswordInvalid = true;
    });
  }

  onUnlock(callback: (isUnlocked: boolean) => void) {
    this.callback = callback;
  }

  getOtherInCommentId() {
    this.pageLockService.getOtherInCommentId().subscribe((res) => {
      this.pageLockService.otherId = res.value;
    });
  }

  isNullOrEmptyOrUndefined(value): boolean {
    if (!isNullOrUndefined(value)) {
      return value === '';
    }
    return true;
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {VitalSign} from '../entity/inclusion/vital-sign';

@Injectable({
  providedIn: 'root'
})
export class VitalSignsService {

  public host = new Properties().host + '/inclusion/vital-sign';

  constructor(private httpClient: HttpClient) {
  }

  loadList(hhhId: number): Observable<any> {
    return this.httpClient.get<any>(this.host + '/load-list/' + hhhId)
      .pipe();
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  updateVitalSigns(vitalSign: VitalSign) {
    return this.httpClient.post(this.host + '/update', vitalSign);
  }

  addVitalSign(vitalSign: VitalSign) {
    return this.httpClient.post(this.host + '/add', vitalSign);
  }

}

import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {HumanResource} from '../models/human-resource';
import {HumanResourcesService} from '../services/human-resources.service';
import {SelectItem} from 'primeng/api';
import {Data} from '../../shared/entity/data';
import {NgForm} from '@angular/forms';
import {FormComponent} from '../../shared/component/form/form.component';
import {SharedService} from '../../shared/services/shared.service';
import {FR} from '../../shared/entity/calendar-language';
import {HumanResourceAppData} from '../human-resource-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';

@Component({
  selector: 'ih-human-resource-form',
  templateUrl: './human-resource-form.component.html',
  styleUrls: ['./human-resource-form.component.css']
})
export class HumanResourceFormComponent extends FormComponent<HumanResource> implements OnInit {

  target = DynamicDefinitionEnum.HUMAN_RESOURCE_ADD_FORM;
  config: FormConfig = new FormConfig();
  committeeToPole = false;

  @ViewChild('humanResourceForm') humanResourceForm: NgForm;
  humanResource: HumanResource = new HumanResource();
  titles: SelectItem[] = Data.titles;
  disabledAddButton: boolean;
  isDoctorSelected = false;
  responsibilitiesPath: string;
  locale = FR;
  hrExists = false;

  constructor(
      private humanResourceService: HumanResourcesService,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService,
      private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(HumanResourceFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.titles.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }


  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.committeeToPole = this.dynamicConfigService.getProperty(PropertyEnum.committeeToPole);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, HumanResourceAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  addHumanResource() {
    this.disabledAddButton = true;
    this.submitted = true;
    if (this.humanResourceForm.invalid || !this.humanResource.isValid(this.config, this.isDoctorSelected)) {
      this.disabledAddButton = false;
      return;
    }
    this.humanResourceService.exists(this.humanResource).subscribe(res => {
      if (res.value) {
        this.hrExists = true;
        this.disabledAddButton = false;
      } else {
        this.hrExists = false;
        this.humanResourceService.add(this.humanResource).subscribe(
            result => {
              this.humanResource.hhhId = result.hhhId;
              this.humanResource.concatenatedResponsabilities = result.concatenatedResponsabilities;
              this.sharedService.showSuccess();
              const name = this.humanResource.lastName;
              this.sharedService.setInformationInLocalStorage('Ressources humaines', 'Ajouter', name);
              this.display = false;

              this.callback(this.humanResource);
              this.disabledAddButton = false;
            }, error => {
              this.sharedService.showFailure();
              console.error('An error occurred while adding HumanResources.' + error);
              this.display = false;
              this.disabledAddButton = false;
            }
        );
      }
    });
  }

  isParent() {
    return this.dynamicConfigService.parent;
  }

  notifyWhenWantedValueSelected(isSelected: boolean) {
    const changed = this.isDoctorSelected !== isSelected;
    this.isDoctorSelected = isSelected;
    if (changed) {
      this._cdr.detectChanges();
    }
  }

  hrServicePoleList() {
    return this.committeeToPole ? 'trial-committee-selected' : 'service';
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

}

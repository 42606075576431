import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MobileStaticData} from '../models/mobile-static-data';

@Injectable({
  providedIn: 'root'
})
export class AboutService {
  public host = new Properties().host;

  constructor(private httpClient: HttpClient) {
  }

  getAbout(): Observable<MobileStaticData> {
    return this.httpClient.get<MobileStaticData>(this.host + '/mobile-application/about');
  }

  editAbout(about: MobileStaticData): Observable<MobileStaticData> {
    return this.httpClient.post<MobileStaticData>(this.host + '/mobile-application/about', about);
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-amendment-verification',
  templateUrl: './amendment-verification.component.html',
  styleUrls: ['./amendment-verification.component.css']
})
export class AmendmentVerificationComponent implements OnInit {

  public display = false;

  constructor() { }

  ngOnInit() {
    this.display = true;
  }

}

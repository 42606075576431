import {CalendarEvent} from './calendar-event';
import {ScheduledItem} from './scheduled-item';
import {ScheduleHumanResourcesColor} from './schedule-human-resources-color';

export class Schedule {
  public hhhId: number;
  public todayEvents: CalendarEvent[] = [];
  public yearEvents: CalendarEvent[] = [];
  public scheduledHRS: ScheduledItem[] = [];
  public scheduledTrial: ScheduledItem[] = [];
  public hrColor: ScheduleHumanResourcesColor[] = [];
}

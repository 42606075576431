import {Component} from '@angular/core';

@Component({
  selector: 'ih-data-import-icon',
  templateUrl: './data-import-icon.component.html',
  styleUrls: ['./data-import-icon.component.css']
})
export class DataImportIconComponent {

  constructor() { }

}

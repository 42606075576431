import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectItem} from 'primeng/components/common/api';
import {SharedService} from '../../../shared/services/shared.service';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {isNullOrUndefined, isUndefined} from 'util';
import {FormInputConfig} from '../../entity/form-input-config';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {PropertyEnum} from '../../entity/property-enum';
import {DataItemService} from '../../../custom-field/service/data-item.service';
import {Util} from '../../../helpers/util';
import {LabelValue} from '../../../shared/entity/label-value';

export const DYNAMIC_DROPDOWN_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicDropdownComponent),
  multi: true
};

@Component({
  selector: 'ih-dynamic-dropdown',
  templateUrl: './dynamic-dropdown.component.html',
  styleUrls: ['./dynamic-dropdown.component.css'],
  providers: [DYNAMIC_DROPDOWN_VALUE_ACCESSOR]
})
export class DynamicDropdownComponent implements OnInit, ControlValueAccessor {


  @Input() name: string;
  @Input() config: FormInputConfig;
  @Input() hasComment = false;
  @Input() commentDisabled = false;
  @Input() publicCommentCount: number;
  @Input() requiredErrorMsg = 'FORM_REQUIRED_FIELD_ERROR';
  @Input() hasMolecularPreScreening = true;
  @Input() MolecularPreScreeningDisabledErrorMsg = 'MOLECULAR_PRESCREENING_DISABLED_ERROR';
  @Input() submitted = false;
  @Input() showLabel = true;
  @Input() alignLabel = false;
  @Input() important = false;
  @Input() title: string;

  @Input() items: any[] = [];
  @Input() disabled: boolean;
  @Input() editable = false;
  @Input() showClear = true;
  @Input() optionLabel: string;
  @Input() placeholder = 'UI_GLOBAL_PLACEHOLDER_SELECT_FROM_LIST';
  @Input() disableWhenSelected: boolean;
  @Input() selectedValues: any[]=[];
  @Input() otherValue: boolean;
  @Input() addToDropDown: boolean;
  @Input() buttonText: boolean;
  @Input() noCache = true;
  @Input() appendTo: any;
  @Input() class: string;
  @Input() autoDisplayFirst = false;
  @Input() checkedDropdown = false;
  @Input() hasCheckBtn = false;
  @Input() resetDropdownToNA = false;

  @Input() iconClass: string;
  @Input() group = false;
  @Input() sortAlphabetically = true;
  @Input() disableEyeBtn = false;
  @Input() showEyeBtn = false;
  @Input() specialTemplating: { class: string, value: string } = undefined;

  @Output() valueChange = new EventEmitter();
  @Output() valueInput = new EventEmitter();
  @Output() onShow = new EventEmitter();
  @Output() onHide = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Output() itemValueChange = new EventEmitter();
  @Output() notifyWhenOther = new EventEmitter();
  @Output() displaySelectedItems = new EventEmitter();
  @Output() onAddToDropDown = new EventEmitter();
  @Output() ngModelChange = new EventEmitter();
  @Output() displayDataComment = new EventEmitter<{attachedToField: string, isPrivate: boolean}>();
  @Output() onCheckToDropDown = new EventEmitter();
  @Output() showDetails = new EventEmitter();

  @Output() resetToNAChanged = new EventEmitter<boolean>();  // Emit boolean

  @Input() resetDropdownToNAChecked = false;
  checked: boolean = false;
  hasPrivatePostIt = true;
  initialValue: any;
  overlayVisible = false;
  lastDisabledItemValue: any;
  private _value: any;

  constructor(
      private sharedService: SharedService,
      private translateService: InnohealthTranslateService,
      private dynamicConfigService: DynamicConfigService,
      private dataItemService: DataItemService
  ) {
  }

  ngOnInit(): void {
    this.dynamicConfigService.initProperties().subscribe(() =>
        this.hasPrivatePostIt = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled));
  }

  get value(): any {
    if (!isNullOrUndefined(this._value)) {
      if (this.otherValue) {
        const translateService = this.translateService;
        const otherItem: SelectItem = this.items.find(item => item.label.toLowerCase() === translateService.getTranslationString('MODULE_CONTACTS_MGT_SECTION_OTHER').toLowerCase());
        this.notifyWhenOther.emit(otherItem && otherItem.value === this._value);
      }
      if (this.disableWhenSelected) {
        let exists = null;
        if (!isUndefined(this.selectedValues)) {
          exists = this.selectedValues.find(item => item === this._value);
        }
        if (isNullOrUndefined(exists)) {
          this.lastDisabledItemValue = this._value;
          this.selectedValues.push(this._value);
          this.displaySelectedItems.emit(this.selectedValues);
        }
      }
    }
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      if (this.otherValue) {
        const translateService = this.translateService;
        const otherItem: SelectItem = this.items.find(item => item.label.toLowerCase() === translateService.getTranslationString('MODULE_CONTACTS_MGT_SECTION_OTHER').toLowerCase());
        this.notifyWhenOther.emit(otherItem && otherItem.value === v);
      }
      this._value = v;
      this.onChange(v);
      if (this.disableWhenSelected) {
        if (!isNullOrUndefined(this.lastDisabledItemValue)) {
          const index = this.selectedValues.findIndex(item => item === this.lastDisabledItemValue);
          this.selectedValues.splice(index, 1);
        }
        this.lastDisabledItemValue = this._value;
        this.selectedValues.push(this._value);
        this.displaySelectedItems.emit(this.selectedValues);
      }
      this.valueChange.emit(this._value);
      if (!this.overlayVisible) {
        this.valueInput.emit(this._value);
      }
    }
  }

  private _type: string;

  get type(): string {
    return this._type;
  }

  @Input()
  set type(v: string) {
    if (v !== this._type) {
      this._type = v;
      this.loadItems(v);
    }
  }
  @Input()
  set url(v: string) {
    if (v !== this._type) {
      this._type = v;
      this.getItems(v);
    }
  }


  writeValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(this._value);
    }
  }

  loadItems(v: string, refreshCash?: boolean): void {
      this.dataItemService.getItems(v, this.sortAlphabetically).subscribe(res => {
        this.items = Util.clone(res);
      });
  }
  getItems(v: string, refreshCash?: boolean): void {
      this.dataItemService.getItems(v, false).subscribe(res => {
        this.items = Util.clone(res);
      });
  }

  onFocusDo(): void {
    this.onFocus.emit();
  }

  onBlurDo(): void {
    this.onBlur.emit();
  }

  onShowDo() {
    this.onShow.emit();
    if (this.disableWhenSelected && !isNullOrUndefined(this.items) && !isNullOrUndefined(this.selectedValues)) {
      for (const selectedValue of this.selectedValues) {
        if (selectedValue !== this._value) {
          const index = this.items.findIndex(item => item.value === selectedValue);
          if (index > -1) {
            this.items[index].disabled = true;
          }
        }
      }
    }
    this.initialValue = this._value;
    this.overlayVisible = true;
  }

  onHideDo() {
    this.onHide.emit();
    this.overlayVisible = false;
    if (this.value !== this.initialValue) {
      this.valueInput.emit(this._value);
    }
  }

  addToDropDownEvent() {
    this.onAddToDropDown.emit();
  }

  selectedItemChange(event?) {
    if (!isNullOrUndefined(event)) {
      this.itemValueChange.emit(this.items[this.items.findIndex((item) => item && item.value === event.value)]);
    } else {
      this.itemValueChange.emit(undefined);
    }
  }

  modelChanged() {
    this.ngModelChange.emit(this._value);
  }

  emitDataCommentEvent(p: boolean) {
    this.displayDataComment.emit({attachedToField: this.name, isPrivate: p});
  }

  emitCheckEvent() {
    this.onCheckToDropDown.emit();
  }

  onChange = (_) => {};

  onTouched = () => {};


  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  emitEyeEvent() {
    this.showDetails.emit();
  }

  refreshDropdown(): void {
    this.dataItemService.resetDataCache(this._type);
    this.loadItems(this._type);
  }

  specialTemplatingDisabled(): boolean {
    return Util.isNullOrUndefined(this.specialTemplating);
  }

  parseItem(item: LabelValue, delimiter: string): { mainTitle: string, subtitle: string } {
    return Util.parseItem(item, delimiter);
  }
  verifyClient = (clients: string[]) => this.dynamicConfigService.verifyClient(clients);



  onCheckboxChange(checked : boolean) {
    checked=this.resetDropdownToNAChecked
    console.log('Checkbox value:', checked);
    this.resetToNAChanged.emit(checked);
    this.checked=!this.checked;
  }
}

import {VisitDuration} from './visit-duration';

export class VisitWorkload {
  arcHhhId: number;
  beforeInclusionVisitNumber: number;
  inInclusionVisitNumber: number;
  afterInclusionVisitNumber: number;
  visitDuration: VisitDuration;

}

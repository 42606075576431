import {VisitAct} from './inclusion/visit-act';
import {DetailedVisit} from './inclusion/detailed-visit';
import {isNullOrUndefined} from 'util';
import {PharmacyDispensationKit} from './trial-pharmacy/PharmacyDispensationKit';

export class Visit {
  public hhhId: number;
  public publicCommentCount: number;
  public theoreticalVisitHhhId: number;
  public visitStateHhhId: number;
  public visitStateName: string;
  public visitProviderStateHhhId: number;
  public providerVisitStateName: string;
  public visitName: string;
  public expectedVisitDate: Date;
  public visitDate: Date;
  public amountTotalDue = 0;
  public amountTotalInvoiced = 0;
  public amountTotalPaid = 0;
  public clonedStateOfInclusion: string;
  public visitActs: VisitAct[] = [];
  public inTheoreticalCalendar: boolean;
  public comment: string;
  public registrationDate: Date;
  public visitArmValue: string;
  public visitIsAdditionalTest: boolean;
  public periodMargin: number;
  public numberOfDoses: number;
  public isNotSavedYet: boolean;
  public toBeDeleted: boolean;
  public pharmacyDispensationKit: PharmacyDispensationKit;

  constructor(detailedVisit?: DetailedVisit) {
    if (!isNullOrUndefined(detailedVisit)) {
      this.hhhId = detailedVisit.hhhId;
      this.visitName = detailedVisit.name;
      this.visitDate = detailedVisit.visitDate;
      this.registrationDate = detailedVisit.registrationDate;
      this.visitStateHhhId = detailedVisit.visitState.hhhId;
      this.visitProviderStateHhhId = detailedVisit.visitProviderState.hhhId;
      this.clonedStateOfInclusion = detailedVisit.clonedStateOfInclusion;
      this.visitIsAdditionalTest = detailedVisit.visitIsAdditionalTest;
      this.isNotSavedYet = detailedVisit.isNotSavedYet;
    }
  }

  fromClonedVisit(name: string) {
    this.isNotSavedYet = true;
    this.visitName = name;
    return this;
  }
}

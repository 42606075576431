import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../shared/services/data-service';
import {SharedService} from '../../shared/services/shared.service';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {CounterpartCategoryService} from '../services/counterpart-category.service';
import {CounterPartCategoryFormComponent} from '../counter-part-category-form/counter-part-category-form.component';
import {CounterpartCategory} from '../models/counterpart-category';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {AppData} from '../../helpers/app-data';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-counter-part-categories',
  templateUrl: './counter-part-categories.component.html',
  styleUrls: ['./counter-part-categories.component.css']
})
export class CounterPartCategoriesComponent extends PaginatorTableComponent<CounterpartCategory> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.CounterpartCategory;
  target = DynamicDefinitionEnum.ADDITIONAL_COSTS_COUNTER_PART_CATEGORY_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader [] = [];
  disableEdit = false;

  constructor(
      private counterPartsService: CounterpartCategoryService,
      private dataService: DataService,
      private cfr: ComponentFactoryResolver,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initTableConfig();
    this.getList();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, AdditionalCostsAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('code')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build()
    );
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('nameFr')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build()
    );
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/additional-costs/counter-part-categories.component';
  }

  getAddTitle(): string {
    return 'MODULE_COUNTER_PART_GENERAL_ADD_NEW';
  }

  getUpdateTitle(): string {
    return 'MODULE_ACT_CATEGORIES_GENERAL_UPDATE';
  }


  getDeleteMessage(): string {
    return 'MODULE_ACT_CATEGORIES_DIALOG_WARNING_ACT_CATEGORY_WILL_BE_DELETED';
  }

  getService(): any {
    return this.counterPartsService;
  }

  getList() {
    this.counterPartsService.loadList().subscribe(
        res => {
          this.counterPartsService.setCounterPartsCategoryToExportAllPage(res);
          this.counterPartsService.setCounterPartsCategoryListToExportSinglePage(res.slice(0, 10));
          this.values = res;
          this.totalNumber = this.values.length;
        }, error => {
          this.sharedService.showFailure();
          console.error('An error occurred while loading counter parts. ' + error);
        }
    );
  }

  displayFormDialogue(counterPart?: CounterpartCategory) {
    const formComponent = CounterPartCategoryFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.counterPartCategoryList = this.values;
    if (counterPart) {
      const counterPartToEdit: CounterpartCategory = Object.assign({}, counterPart);
      formComponent.formHeader = this.getUpdateTitle();
      formComponent.category = counterPartToEdit;
      formComponent.onAdd(this.updateList(counterPart));
    } else {
      formComponent.formHeader = this.getAddTitle();
      formComponent.onAdd(this.updateList());
    }
  }

  updateList(counterPartToEdit?: CounterpartCategory) {
    if (counterPartToEdit) {
      return (newCounterPart: CounterpartCategory) => {
        const index = this.values.indexOf(counterPartToEdit);
        this.values[index].nameFr = newCounterPart.nameFr;
        this.values[index].code = newCounterPart.code;
      };
    } else {
      return (counterPart: CounterpartCategory) => {
        this.values.push(counterPart);
        this.values.sort((a, b) => a.nameFr.toLowerCase().localeCompare(b.nameFr.toLowerCase()));
      };
    }
  }

  displayDeleteDialogue(counterPartCategory: CounterpartCategory) {
    this.displayDeleteDialogueFromInfos(counterPartCategory.hhhId, counterPartCategory.nameFr, counterPartCategory);
  }

  refreshAfterDelete(entity?: any): void {
    const index = this.values.findIndex((counterPart: CounterpartCategory) => counterPart.hhhId === entity.hhhId);
    this.sharedService.setInformationInLocalStorage('Grille budgétaire: Catégories des contreparties', 'Supprimer', entity.nameFr);
    this.values.splice(index, 1);
  }

  getPageDisplayed(event) {
    this.counterPartsService.setCounterPartsCategoryListToExportSinglePage(this.values.slice(event.first, event.first + event.rows));
  }

  exportCounterPartCategories(event: any): void {
    const request = new ExportRequestBuilder<void>()
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
        .build();
    this.counterPartsService.exportCounterPartCategories(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }

}

import {AfterViewChecked, Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TrialService} from '../../services/trial.service';
import {MenuItem} from 'primeng/api';
import {SharedService} from '../../../shared/services/shared.service';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {TranslateService} from '@ngx-translate/core';
import {TrialHead} from '../../models/trial-head';
import {TrialProtocolService} from '../../services/trial-protocol.service';
import {TrialObservablesService} from '../../services/trial-observables.service';
import {PharmacyService} from '../../service/pharmacy.service';
import {DynamicDefinitionEnum} from '../../../dynamic-config/entity/dynamic-definition-enum';
import {Util} from '../../../helpers/util';
import {DynamicConfigService} from '../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../dynamic-config/entity/property-enum';
import {DataCommentInfo} from '../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../data-comment/entity/DataCommentEvent';

declare var $: any;

@Component({
  selector: 'ih-trial-details',
  templateUrl: './trial-details.component.html',
  styleUrls: ['./trial-details.component.css']
})
export class TrialDetailsComponent implements OnInit, AfterViewChecked, OnDestroy {

  _entity = AttachedToEntityEnum.Trial;
  _target = DynamicDefinitionEnum.TRIAL_MODULE;
  amendmentsEnabled = false;
  multiSiteEnabled = false;
  siteEnabled = true;
  pharmacyEnabled = false;
  counterPartEnabled = true;
  operationalActEnabled = true;
  invoiceMonitoringEnabled = false;
  additionalCostModuleLabelKey = 'ADDITIONAL_COSTS_MODULE';

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  trialHead: TrialHead = new TrialHead(null);
  trialId: number;
  trialInformationMenu: MenuItem[];
  trialMenu: MenuItem[];
  menuItems: HTMLCollectionOf<Element>;
  counterValue = 0;
  currentUrl: string;
  private trialAdditionalCostMenu: MenuItem[];
  showWarningForTheoreticalCalendar = false;
  disabledTheoreticalCalendar = false;
  pharmacyHhhId: number;
  pharmacistValidation = true;

  constructor(
      private trialService: TrialService,
      private cfr: ComponentFactoryResolver,
      private translate: TranslateService,
      private sharedService: SharedService,
      private translateService: InnohealthTranslateService,
      private router: Router,
      private trialProtocolService: TrialProtocolService,
      private observablesService: TrialObservablesService,
      private pharmacyInformationsService: PharmacyService,
      private dynamicConfigService: DynamicConfigService) {
    sharedService.loadRouting();
    const url = this.router.parseUrl(this.router.url);
    this.currentUrl = url.toString();
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.trialService.setTrialReadonlyMode(this.trialId);
    $('.second-menu').on('click', () => {
      $('#menubar-mobile').toggleClass('active');
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.activeMenu(val.url);
      }
    });
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.dynamicConfigService.getModuleConfig(DynamicDefinitionEnum.ADDITIONAL_COSTS_MODULE).subscribe(
          (config) => this.additionalCostModuleLabelKey = config.header.title
      );
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.multiSiteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
      this.siteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.siteEnabled);
      this.pharmacyEnabled = this.dynamicConfigService.getProperty(PropertyEnum.pharmacyEnabled);
      this.counterPartEnabled = this.dynamicConfigService.getProperty(PropertyEnum.counterPartEnabled);
      this.operationalActEnabled = this.dynamicConfigService.getProperty(PropertyEnum.operationalActEnabled);
      this.invoiceMonitoringEnabled = this.dynamicConfigService.getProperty(PropertyEnum.invoiceMonitoringEnabled);
      this.getTrialHead();
      this.verifyLastProtocolHasCalendar();
      this.verifyProtocolsExistsByTrial();
      this.observablesService.onProtocolSave.subscribe(() => {
        this.verifyLastProtocolHasCalendar();
        this.verifyProtocolsExistsByTrial();
      });
      this.observablesService.onTheoreticalCalendarSave.subscribe(() => {
        this.verifyLastProtocolHasCalendar();
      });
      this.getPharmacyHhhId();
    });
  }

  verifyLastProtocolHasCalendar() {
    this.trialProtocolService.isLastProtocolHavingCalendarByTrial(this.trialId).subscribe(res => {
      this.showWarningForTheoreticalCalendar = !res.value;
      this.setTrialMenu();
    });
  }

  verifyProtocolsExistsByTrial() {
    this.trialProtocolService.existsByTrial(this.trialId).subscribe(result => {
      this.disabledTheoreticalCalendar = !result.value;
      this.setTrialMenu();
    });
  }

  getTrialHead() {
    this.trialService.getTrialHead(this.trialId).subscribe(trialHead => {
      this.trialHead = trialHead;
      this.translate.stream('key').subscribe(() => {
        if (this.trialHead.multiSite) {
          this.setMenuForMultisiteTrial();
        } else {
          this.setMenuForNonMultisiteTrial();
        }
        this.extendAdditionalCostMenu();
        this.setTrialMenu();
        this.trialService.trialName.next(trialHead.localIdentifier + '/' + trialHead.promoter1 + '/' + trialHead.protocolNumber);
        this.translate.stream('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_HOME').subscribe((res: string) => {
          return res;
        });
      });
    });
  }

  ngAfterViewChecked() {
    if (this.menuItems && this.menuItems.length === 0) {
      return;
    }
    this.menuItems = document.getElementsByClassName('ui-menuitem-link');
    if (this.menuItems.length === 0) {
      return;
    }
    const subInformationUrlVerified = this.currentUrl.indexOf('trial-details/contact-list') !== -1
        || this.currentUrl.indexOf('trial-details/trial-administrative') !== -1
        || this.currentUrl.indexOf('trial-details/trial-identification') !== -1
        || this.currentUrl.indexOf('trial-details/trial-site') !== -1;

    if (this.counterValue < 1 && subInformationUrlVerified) {
      for (let i = 0; i < this.menuItems.length; i++) {
        if ((this.menuItems[i].innerHTML.indexOf(this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_HOME')) > -1)) {
          this.menuItems[i].classList.add('active');
          this.counterValue += 1;
          return;
        }
      }
    }
    if (this.counterValue < 1 && (this.currentUrl.indexOf('fixed-costs') > -1 || this.currentUrl.indexOf('acts-grid') > -1)) {
      for (let i = 0; i < this.menuItems.length; i++) {
        if ((this.menuItems[i].innerHTML.indexOf(this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_ADDITIONAL_COSTS')) > -1)) {
          this.menuItems[i].classList.add('active');
          this.counterValue += 1;
          return;
        }
      }
    }
  }

  returnToTrialIdentificationInformation() {
    this.router.navigate(
        ['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId: this.trialId}}
    ).then();
  }

  displayDataCommentDialog(isPrivate: boolean) {
    if (!this.trialHead || !this.trialHead.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(this._entity, this.trialHead.hhhId, 'hhhId', isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.trialHead.publicCommentCount = dataCommentEvent.publicCommentCount;
  }

  activeMenu(url: string) {
    const subInformationSelected = this.isSubMenuSelected(url,
        ['trial-identification-information-container',
          'trial-administrative-information-container',
          'trial-site-list', 'contact-list-information'
        ]);

    const subAdditionalCostSelected = this.isSubMenuSelected(url, ['acts-grid', 'fixed-costs']);

    const subInclusionSelected = this.isSubMenuSelected(url, ['inclusion-details']);

    for (let i = 0; i < this.menuItems.length; i++) {
      if ((this.menuItems[i].innerHTML.indexOf('Information') > -1) && subInformationSelected) {
        this.menuItems[i].classList.add('active');
      } else if (this.menuItems[i].innerHTML.indexOf(this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_ADDITIONAL_COSTS')) > -1 && subAdditionalCostSelected) {
        this.menuItems[i].classList.add('active');
      } else if (this.menuItems[i].innerHTML.indexOf(this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_INCLUSIONS')) > -1 && subInclusionSelected) {
        this.menuItems[i].classList.add('active');
      } else {
        this.menuItems[i].classList.remove('active');
      }
    }
  }

  isSubMenuSelected(url: string, subUrls: string[]): boolean {
    for (const subUrl of subUrls) {
      if (url.indexOf(subUrl) !== -1) {
        return true;
      }
    }
    return false;
  }

  private setMenuForMultisiteTrial() {
    this.trialInformationMenu = [
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_IDENTIFICATION'),
        command: () => this.router.navigate(
            ['/trial-details/trial-identification-information-container'],
            {queryParams: {hhhId: this.trialId}}
        ),
        routerLink: ['/trial-details/trial-identification-information-container'],
        queryParams: {hhhId: this.trialId},
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_REGULATORY'),
        command: () => this.router.navigate(['/trial-details/trial-administrative-information-container'], {queryParams: {hhhId: this.trialId}}),
        routerLink: ['/trial-details/trial-administrative-information-container'],
        queryParams: {hhhId: this.trialId},
      }
    ];
    if (this.multiSiteEnabled === true && this.siteEnabled) {
      this.trialInformationMenu.push(
          {
            label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_SITES'),
            command: () => this.router.navigate(['/trial-details/trial-site-list'], {queryParams: {hhhId: this.trialId}}),
            routerLink: ['/trial-details/trial-site-list'],
            queryParams: {hhhId: this.trialId},
          }
      );
    }
    this.trialInformationMenu.push(
        {
          label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_CONTACTS'),
          command: () => this.router.navigate(['/trial-details/contact-list-information'], {queryParams: {hhhId: this.trialId}}),
          routerLink: ['/trial-details/contact-list-information'],
          queryParams: {hhhId: this.trialId},
        }
    );
  }

  private setMenuForNonMultisiteTrial() {
    this.trialInformationMenu = [
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_IDENTIFICATION'),
        command: () => this.router.navigate(['/trial-details/trial-identification-information-container'], {queryParams: {hhhId: this.trialId}})
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_ADMINISTRATIVE'),
        command: () => this.router.navigate(['/trial-details/trial-administrative-information-container'], {queryParams: {hhhId: this.trialId}})
      },
      ...this.siteEnabled ? [{
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_SITES'),
        command: () => this.router.navigate(['/trial-details/trial-site-list'], {queryParams: {hhhId: this.trialId}})
      }] : [],
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_CONTACTS'),
        command: () => this.router.navigate(['/trial-details/contact-list-information'], {queryParams: {hhhId: this.trialId}})
      },
    ];
  }

  extendAdditionalCostMenu() {
    this.trialAdditionalCostMenu = [
      {
        label: this.translateService.getTranslationString('MODULE_INVOICES_ACTS_FIELD_ACTS'),
        command: () => this.router.navigate(['/trial-details/acts-grid'], {queryParams: {hhhId: this.trialId}}),
        routerLink: ['/trial-details/acts-grid'],
        queryParams: {hhhId: this.trialId}
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_FIXED_COSTS'),
        command: () => this.router.navigate(['/trial-details/fixed-costs'], {queryParams: {hhhId: this.trialId}}),
        routerLink: ['/trial-details/fixed-costs'],
        queryParams: {hhhId: this.trialId}
      }
    ];
    if (this.counterPartEnabled) {
      this.trialAdditionalCostMenu.push(
          {
            label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_COUNTER_PART'),
            command: () => this.router.navigate(['/trial-details/counter-parts'], {queryParams: {hhhId: this.trialId}}),
            routerLink: ['/trial-details/counter-parts'],
            queryParams: {hhhId: this.trialId}
          }
      );
    }
    if (this.operationalActEnabled) {
      this.trialAdditionalCostMenu.push(
          {
            label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_OPERATIONAL_ACTS'),
            command: () => this.router.navigate(['/trial-details/operational-acts'], {queryParams: {hhhId: this.trialId}}),
            routerLink: ['/trial-details/operational-acts'],
            queryParams: {hhhId: this.trialId}
          }
      );
    }
  }

  getPharmacyHhhId() {
    this.pharmacyInformationsService.getPharmacyIdByTrialId(this.trialId).subscribe(
        data => {
          if (data === null) {
            this.pharmacistValidation = false;
            this.setTrialMenu();
          } else {
            this.pharmacyHhhId = data;
            this.getPharmacistValidation();
          }
        }, error => {
          console.log(error);
        }
    );
  }

  getPharmacistValidation() {
    this.pharmacyInformationsService.hasPharmacistValidation(this.pharmacyHhhId).subscribe(
        (data: boolean) => {
          this.pharmacistValidation = data;
          this.setTrialMenu();
        }, error => {
          console.log(error);
        }
    );
  }

  isPharmacyDisplayed() {
    return (this.pharmacyInformationsService.isPharmacyDisplayed() && this.pharmacyEnabled);
  }

  private setTrialMenu() {
    this.trialMenu = [
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_HOME'),
        items: this.trialInformationMenu
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_PHARMACY_TAB'),
        command: () => this.router.navigate(['/trial-details/pharmacy'], {
          queryParams: {
            hhhId: this.trialId,
            pharmacyHhhId: this.pharmacyHhhId
          }
        }),
        routerLink: ['/trial-details/pharmacy'],
        queryParams: {hhhId: this.trialId, pharmacyHhhId: this.pharmacyHhhId},
        visible: this.isPharmacyDisplayed(),
        icon: !this.pharmacistValidation ? 'fa fa-exclamation-triangle' : ''
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_DASHBOARD'),
        command: () => this.router.navigate(['/trial-details/dashboard'], {queryParams: {hhhId: this.trialId}}),
        routerLink: ['/trial-details/dashboard'],
        queryParams: {hhhId: this.trialId}, visible: true
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_INCLUSIONS'),
        command: () => this.router.navigate(['/trial-details/inclusions-list'], {queryParams: {hhhId: this.trialId}}),
        routerLink: ['/trial-details/inclusions-list'],
        queryParams: {hhhId: this.trialId},
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_FOLLOW_UP_TABLE'),
        command: () => this.router.navigate(['/trial-details/tracking-table'], {queryParams: {hhhId: this.trialId}}),
        routerLink: ['/trial-details/tracking-table'],
        queryParams: {hhhId: this.trialId},

      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_THEORETICAL_CALENDAR'),
        command: () => {
          this.navigateToTheoreticalCalendar();
        },
        icon: this.amendmentsEnabled && this.showWarningForTheoreticalCalendar ? 'fa fa-exclamation-triangle' : ''
      },
      {
        label: this.translateService.getTranslationString(this.additionalCostModuleLabelKey),
        items: this.trialAdditionalCostMenu,
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_FILES'),
        command: () => this.router.navigate(['/trial-details/files'], {queryParams: {hhhId: this.trialId}}),
        routerLink: ['/trial-details/files'],
        queryParams: {hhhId: this.trialId},
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_ENTRY_FINANCIAL_MONITOR'),
        command: () => this.router.navigate(['/trial-details/invoice-monitor'], {queryParams: {hhhId: this.trialId}}),
        routerLink: ['/trial-details/invoice-monitor'],
        queryParams: {hhhId: this.trialId},
        visible: this.invoiceMonitoringEnabled
      }
    ];
  }

  private navigateToTheoreticalCalendar() {
    if (this.amendmentsEnabled && this.disabledTheoreticalCalendar) {
      this.sharedService.showWarning(this.translateService.getTranslationString('MODULE_TRIALS_DIALOG_THEORETICAL_CALENDAR_WARNING'));
    } else {
      this.router.navigate(['/trial-details/theoretical-calendar'], {queryParams: {hhhId: this.trialId}});
    }
  }


  ngOnDestroy(): void {
    this.trialService.readOnlyMode = false;
  }

  getTitle(): string {
    let title = '';
    if (!Util.isStringNullOrEmpty(this.trialHead.localIdentifier)) {
      title = title + this.trialHead.localIdentifier + '/';
    }
    if (!Util.isStringNullOrEmpty(this.trialHead.promoter1)) {
      title = title + this.trialHead.promoter1 + '/';
    }
    if (!Util.isStringNullOrEmpty(this.trialHead.protocolNumber)) {
      title = title + this.trialHead.protocolNumber;
    }
    return title;
  }
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {OtherLastCppAmendmentDate} from '../models/other-last-cpp-amendment-date';

@Injectable({
  providedIn: 'root'
})
export class OtherLastCppAmendmentDateService {

  public host = new Properties().host + '/other-last-cpp-amendment-date';

  constructor(private httpClient: HttpClient) {
  }

  saveOtherLastCppAmendmentDates(trialId: number, otherLastCppAmendmentDateList: OtherLastCppAmendmentDate[]): Observable<OtherLastCppAmendmentDate[]> {
    return this.httpClient.post<OtherLastCppAmendmentDate[]>(this.host + '/save/' + trialId, otherLastCppAmendmentDateList);

  }

  loadList(trialId: number): Observable<OtherLastCppAmendmentDate[]> {
    return this.httpClient.get<OtherLastCppAmendmentDate[]>(this.host + '/load-list/' + trialId);

  }

  delete(hhhId: number): Observable<number> {
    return this.httpClient.get<number>(this.host + '/delete/' + hhhId);
  }
}

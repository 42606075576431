import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {Observable, Subject, throwError} from 'rxjs';
import {SharedService} from '../../shared/services/shared.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {StringConfigMapConverter} from '../entity/string-config-map-converter';

export enum ExportStatusEnum {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}

export enum ExportButtonStatus {
  READY_TO_EXPORT = 'READY_TO_EXPORT',
  EXPORTING = 'EXPORTING',
  READY_TO_DOWNLOAD = 'READY_TO_DOWNLOAD'
}

@Injectable({
  providedIn: 'root'
})
export class DataExportService {
  host = new Properties().host;

  private file: Subject<ArrayBuffer>;

  constructor(private sharedService: SharedService, private http: HttpClient) {
    this.file = new Subject<ArrayBuffer>();
  }


  downloadFile(fileUUID: string, fileExtension: string): Observable<Blob> {
    return this.http.get(this.host + `/download/${fileUUID}?fileExtension=${fileExtension}`,
      {responseType: 'blob'});
  }

  deleteFile(fileUUID: string): Observable<any> {
    return this.http.delete(this.host + `/export/delete/${fileUUID}`);
  }

  getListExportFields(fieldsToExportList: string): Observable<Map<string, string>> {
    return this.http.get<Map<string, string>>(this.host + fieldsToExportList).pipe(
      map(res => {
        try {
          return new StringConfigMapConverter<string>().deserialize(res);
        } catch (e) {
          console.log(e as Error);
        }
      }),
      catchError(this.handleError)
    );
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

}

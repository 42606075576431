import {Any, JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('DynamicModuleDefinitionProperty')
export class DynamicModuleDefinitionProperty {

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('type', String)
  type: string = undefined;

  @JsonProperty('defaultValue', String)
  defaultValue: string = undefined;

  @JsonProperty('description', String, true)
  description: string = undefined;

  @JsonProperty('configValue', Any)
  configValue: any = undefined;



  color = '';
}

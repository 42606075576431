import {Component, OnInit} from '@angular/core';
import {Doctor} from '../models/doctor';
import {Message} from '../models/message';

@Component({
  selector: 'app-doctors-slider',
  templateUrl: './doctors-slider.component.html',
  styleUrls: ['./doctors-slider.component.css']
})
export class DoctorsSliderComponent implements OnInit {
  doctors: Doctor[];
  messages: Message[];

  constructor() {
  }

  ngOnInit() {
    this.doctors = [{
      hhhId: 1,
      fullName: 'Dr Saif'
    }, {
      hhhId: 2,
      fullName: 'Dr Achref'
    }, {
      hhhId: 3, fullName: 'Dr Imen'
    }, {
      hhhId: 4,
      fullName: 'Dr Yousef'
    }];
  }

}

import {ProviderTypeDto} from './provider-type-dto';
import {PromoterType} from './promoter-type';
import {ContactableMember} from '../../shared/entity/contactable-member';
import {ProviderBasicInformation} from './provider-basic-information';

export class Provider {
  public hhhId: number;
  public publicCommentCount: number;
  public name: string;
  public contactableMembers: ContactableMember[];
  public totalContactableMemberNames: string;
  public totalContactableMemberFunctions: string;
  public providerType: ProviderTypeDto;
  public providerTypeHhhId: number;
  public promoterType: PromoterType;
  public promoterTypeHhhId: number;
  public providerTypeOtherValue: string;
  public providerTypeValue: any;

  constructor(id?: number, name?: string, providerType?: ProviderTypeDto) {
    this.hhhId = id || 0;
    this.name = name || '';
    this.providerType = providerType;
  }

  init(obj: any): Provider {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }


  static fromBasicInformation(providerBasicInformation: ProviderBasicInformation): Provider {
    const provider: Provider = new Provider(providerBasicInformation.hhhId, providerBasicInformation.name, null);
    provider.providerTypeHhhId = providerBasicInformation.providerTypeHhhId;
    provider.promoterTypeHhhId = providerBasicInformation.promoterTypeHhhId;
    provider.providerTypeValue = providerBasicInformation.providerTypeValue;
    provider.providerTypeOtherValue = providerBasicInformation.providerOtherTypeValue;
    return provider;
  }
}

export class ExportedProvider {
  public providerType = '';
  public providerName = '';
  public contact = '';
  public function = '';
}

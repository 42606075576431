import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {InvoiceService} from '../services/invoice.service';
import {DataService} from '../../shared/services/data-service';
import {Router} from '@angular/router';
import {RollbackService} from '../../shared/services/rollback.service';
import {InvoiceGlobalFilter} from '../models/invoice-global-filter';
import {Invoice} from '../models/invoice';
import {InvoiceAddFormComponent} from '../invoice-add-form/invoice-add-form.component';
import {InvoiceState} from '../invoice-fixed-costs/models/invoice-state';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {SharedService} from '../../shared/services/shared.service';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {InvoiceAppData} from '../Invoice-app-data';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {AppData} from '../../helpers/app-data';
import {StaticInvoiceService} from '../services/static-invoice.service';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

declare var $: any;

@Component({
  selector: 'ih-static-invoice-list',
  templateUrl: './static-invoice-list.component.html',
  styleUrls: ['./static-invoice-list.component.css']
})
export class StaticInvoiceListComponent extends PaginatorTableComponent<Invoice> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.Invoice;
  target = DynamicDefinitionEnum.INVOICE_STATIC_LIST_TABLE;
  principalInvestigatorLabelKey = 'MODULE_TRIALS_TABLE_FIELD_PI';
  isMultiSiteEnabled = false;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  invoiceStates: InvoiceState[];
  invoiceGlobalFilter: InvoiceGlobalFilter = new InvoiceGlobalFilter();
  invoiceTrialByProtocolNumber = true;
  invoiceProtocolNumberEnabled = false;
  isCsetEnabled = true;
  constructor(
    private invoiceService: InvoiceService,
    private cfr: ComponentFactoryResolver,
    private staticInvoiceService: StaticInvoiceService,
    private router: Router,
    private rollbackService: RollbackService<InvoiceGlobalFilter>,
    public sharedService: SharedService,
    private dataService: DataService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    $('.filter').on('click', () => {
      $('.filter-mobile').toggleClass('active-filter-inclusion');
    });
    super.ngOnInit();
    this.initProperties();
    if (this.rollbackService.hasGlobalFilter('invoiceGlobalFilter')) {
      this.invoiceGlobalFilter = this.rollbackService.getGlobalFilter('invoiceGlobalFilter');
    } else {
      this.invoiceGlobalFilter = new InvoiceGlobalFilter();
    }
    this.getInvoiceStates();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.principalInvestigatorLabelKey = this.dynamicConfigService.getProperty(PropertyEnum.principalInvestigatorLabelKey);
      this.isMultiSiteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
      this.invoiceTrialByProtocolNumber = this.dynamicConfigService.getProperty(PropertyEnum.invoiceTrialByProtocolNumber);
      this.invoiceProtocolNumberEnabled = this.dynamicConfigService.getProperty(PropertyEnum.invoiceProtocolNumberEnabled);
      this.isCsetEnabled = this.dynamicConfigService.getProperty(PropertyEnum.csetEnabled);
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, InvoiceAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('state')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('invoice-state')
      .sortable()
      .sortField('stateHhhid')
      .cssClass(((data: Invoice) => {
        return data.manualState ? 'manual-state' : '';
      }))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('establishment')
      .filterable()
      .filter(this.invoiceGlobalFilter.establishmentHhhId)
      .filterType(FilterType.IH_DROPDOWN)
      .type('establishment-children')
      .sortable()
      .sortField('establishmentHhhId')
      .displayContent((data: Invoice) => {
        return data.establishment;
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('number')
      .filterable()
      .filter(this.invoiceGlobalFilter.number)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('trial')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type(this.getTrialType())
      .sortable()
      .sortField(this.invoiceTrialByProtocolNumber ? 'trialInfoGeneral.protocolNumber' : 'trialInfoGeneral.localIdentifier')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('amountTotal')
      .filterable()
      .filter(this.invoiceGlobalFilter.amountTotal)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionsNumbers')
      .filterable()
      .filter(this.invoiceGlobalFilter.inclusionsNumbers)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitNames')
      .filterable()
      .filter(this.invoiceGlobalFilter.visitNames)
      .filterType(FilterType.INPUT_TEXT)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('issueDate')
      .filterable()
      .filter(this.invoiceGlobalFilter.issueDate)
      .filterType(FilterType.CALENDAR)
      .withDateFormatter()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('validationDate')
      .filterable()
      .filter(this.invoiceGlobalFilter.validationDate)
      .filterType(FilterType.CALENDAR)
      .withDateFormatter()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('despatchDate')
      .filterable()
      .filter(this.invoiceGlobalFilter.despatchDate)
      .filterType(FilterType.CALENDAR)
      .withDateFormatter()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('paymentDate')
      .filterable()
      .filter(this.invoiceGlobalFilter.paymentDate)
      .filterType(FilterType.CALENDAR)
      .withDateFormatter()
      .sortable()
      .build());
    this.headers = this.config.buildTable(availableHeaders);

  }
  getTrialType(): string {
    return this.invoiceTrialByProtocolNumber
      ? 'protocol-number'
      : (this.isCsetEnabled
        ? 'cset-protocol-number'
        : (this.invoiceProtocolNumberEnabled
          ? 'protocol-number'
          : 'local-identifier'));
  }
  getExportPageModule(): string {
    return 'src/app/invoice/static-invoice-list/static-invoice-list.component';
  }

  getAddTitle(): string {
    return 'MODULE_INVOICES_GENERAL_ADD_NEW';
  }

  getDeleteMessage(): string {
    return 'MODULE_INVOICES_DIALOG_WARNING_INVOICE_WILL_BE_DELETED';
  }

  getService(): any {
    return this.invoiceService;
  }

  getList(filters?: any) {
    const invoiceGlobalFilter: InvoiceGlobalFilter = new InvoiceGlobalFilter();
    Object.assign(invoiceGlobalFilter, DynamicTableHeader.buildFilterData(this.headers));
    invoiceGlobalFilter.promoter1HhhId = this.invoiceGlobalFilter.promoter1HhhId;
    invoiceGlobalFilter.siteHhhId = this.invoiceGlobalFilter.siteHhhId;
    invoiceGlobalFilter.protocolNumber = this.invoiceGlobalFilter.protocolNumber;
    invoiceGlobalFilter.specialityHhhId = this.invoiceGlobalFilter.specialityHhhId;
    invoiceGlobalFilter.principalInvestigator = this.invoiceGlobalFilter.principalInvestigator;
    this.rollbackService.saveGlobalFilter('invoiceGlobalFilter', invoiceGlobalFilter);
    this.rollbackService.isRollBack = true;

    this.staticInvoiceService.loadList(this.page, this.limit, this.sortBy, this.orderBy, invoiceGlobalFilter)
      .subscribe(res => {
        this.values = res.content;
        this.totalNumber = res.totalElements;
        this.staticInvoiceService.setPaginationConfiguration(res, this.limit, this.sortBy, this.orderBy, filters);
      });
  }

  getInvoiceStates() {
    this.invoiceService.getInvoiceStates().subscribe(res => {
      this.invoiceStates = res;
    });
  }

  edit(invoice: Invoice) {
    this.router.navigate(['/static-invoice-details'], {queryParams: {invoiceHhhId: invoice.hhhId}}).then();
  }

  displayFormDialogue() {
    const formComponent = InvoiceAddFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.invoiceStates = this.invoiceStates;
    formComponent.formHeader = this.getAddTitle();
    formComponent.onAdd((newInvoice: Invoice) => {
      this.values.push(newInvoice);
    });
  }

  displayDeleteDialogue(entity: any) {
    this.displayDeleteDialogueFromInfos(entity.hhhId, entity.number, entity);
  }

  refreshAfterDelete(entity: any): void {
    const index = this.values.findIndex((value: Invoice) => value.hhhId === entity.hhhId);
    this.sharedService.setInformationInLocalStorage('Facturation', 'Supprimer', entity.number);
    this.values.splice(index, 1);
    this.totalNumber -= 1;
  }

  exportStaticInvoice(event: any): void {
    const request = new ExportRequestBuilder<InvoiceGlobalFilter>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .condition(true)
      .secondCondition(false)
      .sortBy(this.sortBy)
      .orderBy(this.orderBy)
      .filters(this.invoiceGlobalFilter)
      .target(this.target)
      .build();

    this.invoiceService.exportInvoiceList(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }

}

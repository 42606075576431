import {Component, Input, OnInit} from '@angular/core';
import {DetailedTrialAct} from '../../../entity/trial-additionnal-costs/detailed-trial-act';
import {TableHeader} from '../../../../shared/entity/table-header';
import {InputType} from '../../../../input-type';
import {SelectItem} from 'primeng/api';
import {TheoreticalCalendar} from '../../../entity/theoretical-calendar/theoretical-calendar';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {isNullOrUndefined} from 'util';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'ih-theoretical-calendar-acts-v2',
  templateUrl: './theoretical-calendar-acts-v2.component.html',
  styleUrls: ['./theoretical-calendar-acts-v2.component.css'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class TheoreticalCalendarActsV2Component implements OnInit {

  @Input() acts: DetailedTrialAct[];
  @Input() actDescriptionFilterList: SelectItem[];
  @Input() theoreticalCalendar: TheoreticalCalendar;

  actColumnListHeader: TableHeader[];


  constructor(private canDeactivateService: CanDeactivateService) {
  }

  ngOnInit() {
    this.setColumns();
  }

  updateTrialAct(trialAct: DetailedTrialAct) {
    if (!this.theoreticalCalendar.trialActsToEdit) {
      this.theoreticalCalendar.trialActsToEdit = [];
    }
    const lastModifiedTrialAct: DetailedTrialAct = this.theoreticalCalendar.trialActsToEdit.find(item => item.hhhId === trialAct.hhhId);
    if (!lastModifiedTrialAct) {
      this.canDeactivateService.canBeDeactivated = false;
      this.theoreticalCalendar.trialActsToEdit.push(trialAct);
    }
  }

  private setColumns() {
    this.actColumnListHeader = [
      {
        field: '',
        header: ''
      },
      {
        field: 'actCategory',
        header: 'MODULE_REFERENCE_ACTS_FIELD_ACT_CATEGORY',
        width: '150px',
        filterable: true,
        optionFilterable: true,
        inputType: InputType.ihDropDown,
        type: 'act-category',
        sortable: true
      },
      {
        field: 'description',
        header: 'MODULE_REFERENCE_ACTS_FIELD_DESCRIPTION',
        width: '200px',
        filterable: true,
        optionFilterable: true,
        inputType: InputType.dropDown,
        options: this.actDescriptionFilterList,
        sortable: true,
        editable: true,
        onChange: (data: DetailedTrialAct) => {
          this.updateTrialAct(data);
        }
      },
      {
        field: 'invoiceable',
        header: 'MODULE_REFERENCE_ACTS_FIELD_INVOICEABLE',
        width: '50px',
        filterable: true,
        inputType: InputType.checkbox,
        sortable: true,
        translated: true,
        editable: false,
        onChange: (data: DetailedTrialAct) => {
          this.updateTrialAct(data);
        }
      },
      {
        field: 'pricePerUnit',
        header: 'MODULE_REFERENCE_ACTS_FIELD_PRICE_PER_UNIT',
        width: '80px',
        filterable: false,
        inputType: InputType.input,
        sortable: true,
        cssClass: () => {
          return 'pull-right';
        },
        displayContent: (data: DetailedTrialAct) => {
          let price = '';
          if (!isNullOrUndefined(data.pricePerUnit)) {
            price = Number(data.pricePerUnit).toFixed(2) + ' €';
          }
          return price;
        },
        cssClassForContent: true,
        editable: true,
        onChange: (data: DetailedTrialAct) => {
          this.updateTrialAct(data);
        }
      }
    ];
  }
}

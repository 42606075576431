import {Component, OnInit} from '@angular/core';
import {InclusionTracking} from '../../../entity/trial-track/inclusion-tracking';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-patient-detail-form',
  templateUrl: './patient-detail-form.component.html',
  styleUrls: ['./patient-detail-form.component.css']
})
export class PatientDetailFormComponent implements OnInit {

  displayConsentsInInclusionInfoEnabled = false;

  public displayPatientDetails: boolean;

  public inclusion: InclusionTracking;

  constructor(private dynamicConfigService: DynamicConfigService) {
  }

  ngOnInit() {
    this.initProperties();
    this.displayPatientDetails = true;
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.displayConsentsInInclusionInfoEnabled = this.dynamicConfigService.getProperty(PropertyEnum.displayConsentsInInclusionInfoEnabled);
    });
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {SuggestedPatient} from '../models/suggested-patient';

@Component({
  selector: 'app-suggested-patients-form',
  templateUrl: './suggested-patients-form.component.html',
  styleUrls: ['./suggested-patients-form.component.css']
})
export class SuggestedPatientsFormComponent implements OnInit {

  @Input() suggestedPatients: SuggestedPatient[];
  // those supposed to be the patients by doctor X
  patients: any[];

  constructor() {
  }

  ngOnInit() {
    this.patients = ['1', '2', '3', '4'];
  }

}

import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Router} from '@angular/router';
import {PharmacyService} from '../../../service/pharmacy.service';

@Component({
  selector: 'ih-trial-pharmacy',
  templateUrl: './trial-pharmacy-container.component.html',
  styleUrls: ['./trial-pharmacy-container.component.css']
})
export class TrialPharmacyContainerComponent implements OnInit {
  trialHhhId: number;
  pharmacyHhhId: number;
  items: MenuItem[];
  public userRole: string;
  pharmacistValidation = false;

  constructor(
    private router: Router,
    private pharmacyInformationsService: PharmacyService
  ) {
    const url = this.router.parseUrl(this.router.url);
    this.userRole = localStorage.getItem('authenticatedUserRole');
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
      this.pharmacyHhhId = url.queryParams.pharmacyHhhId;
    }
  }

  ngOnInit() {
    if (this.pharmacyHhhId != null) {
      this.getPharmacistValidation();
    } else {
      this.setPharmacyMenu();
    }
  }

  getPharmacistValidation() {
    this.pharmacyInformationsService.hasPharmacistValidation(this.pharmacyHhhId).subscribe(
      (data: boolean) => {
        this.pharmacistValidation = data;
        this.setPharmacyMenu();
      }, error => {
        console.log(error);
      }
    );
  }

  setPharmacyMenu() {
    const queryParams = {
      hhhId: this.trialHhhId,
      pharmacyHhhId: this.pharmacyHhhId
    };
    this.items = [{
      label: 'MODULE_TRIALS_PHARMACY_TAB_INFORMATIONS',
      routerLink: ['/trial-details/pharmacy/informations'],
      queryParams,
    }, {
      label: 'MODULE_TRIALS_PHARMACY_TAB_STOCK',
      routerLink: ['/trial-details/pharmacy/stock'],
      queryParams,
       disabled: !this.pharmacistValidation &&  this.userRole !== 'ADMIN'
    }, {
      label: 'MODULE_TRIALS_PHARMACY_TAB_DISPENSATION',
      routerLink: ['/trial-details/pharmacy/dispensation'],
      queryParams,
      disabled: !this.pharmacistValidation &&  this.userRole !== 'ADMIN'
    }, {
      label: 'MODULE_TRIALS_PHARMACY_TAB_FILES',
      routerLink: ['/trial-details/pharmacy/files'],
      queryParams,
      disabled: !this.pharmacistValidation &&  this.userRole !== 'ADMIN'
    }];
  }
}

export enum AttachedToEntityEnum {
  HumanResource = 'HumanResource',
  Patient = 'Patient',
  Provider = 'Provider',
  Contact = 'Contact',
  Site = 'Site',
  Invoice = 'Invoice',
  Pharmacy = 'Pharmacy',
  TrialSchema = 'TrialSchema',

  Visit = 'Visit',
  VisitTracking = 'VisitTracking',
  Trial = 'Trial',
  TrialIdentificationInformation = 'TrialIdentificationInformation',
  TrialHumanResource = 'TrialHumanResource',
  TrialMethodology = 'TrialMethodology',
  TrialDiscipline = 'TrialDiscipline',
  TrialInclusionExclusionCriteria = 'TrialInclusionExclusionCriteria',
  Arm = ' Arm',

  TrialAdministrativeRegulatory = 'TrialAdministrativeRegulatory',
  TrialAdministrativeDate = 'TrialAdministrativeDate',
  TrialAdministrativeFeasibility = 'TrialAdministrativeFeasibility',
  TrialAdministrativeComop = 'TrialAdministrativeComop',
  TrialSiteLiaison = 'TrialSiteLiaison',
  TrialContact = 'TrialContact',
  TrialInclusion = 'TrialInclusion',
  Inclusion = 'Inclusion',
  AdverseEffect = 'AdverseEffect',
  ConcomitantTreatment = 'ConcomitantTreatment',
  VitalSign = 'VitalSign',

  ActCategory = 'ActCategory',
  ReferenceAct = 'ReferenceAct',
  CounterpartCategory = 'CounterpartCategory',
  CounterpartReference = 'CounterpartReference',
  FixedCostCategory = 'FixedCostCategory',
  ReferenceFixedCost = 'ReferenceFixedCost',
  OperationalAct = 'OperationalAct',
  TrialAct = 'TrialAct',
  TrialCounterpart = 'TrialCounterPart',
  TrialFixedCost = 'TrialFixedCost',
  TrialOperationalAct = 'TrialOperationalAct',
}

import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Router} from '@angular/router';
import {DataService} from '../../../../shared/services/data-service';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {TranslateService} from '@ngx-translate/core';
import {TrialActService} from '../../../service/trial-act.service';
import {DetailedTrialAct} from '../../../entity/trial-additionnal-costs/detailed-trial-act';
import {TrialActFormComponent} from '../trial-act-form/trial-act-form.component';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {ImportTrialActFormComponent} from '../../../../modals/import-trial-act-form/import-trial-act-form.component';
import {DeleteMultipleComponent} from '../../../../modals/delete-multiple/delete-multiple.component';
import {HiddenFields} from '../../../../admin/models/HiddenFields';
import {UsersService} from '../../../../admin/services/users.service';
import {TrialService} from '../../../services/trial.service';
import {ACT_GRID_READ_WRITE} from '../../../../login/services/authentication.service';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {TrialAppData} from '../../../trial-app-data';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {AppData} from '../../../../helpers/app-data';
import {DataItemService} from '../../../../custom-field/service/data-item.service';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {TrialAdministrativeDatesService} from '../../../services/trial-administrative-dates.service';
import {SharedService} from '../../../../shared/services/shared.service';
@Component({
  selector: 'ih-trial-acts-grid',
  templateUrl: './trial-acts-grid.component.html',
  styleUrls: ['./trial-acts-grid.component.css']
})
export class TrialActsGridComponent extends PaginatorTableComponent<DetailedTrialAct> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.TrialAct;
  target = DynamicDefinitionEnum.TRIAL_ADDITIONAL_COSTS_ACTS_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];
  trialSecondUfEnabled = false;
  conventionTypeIsRequiredToAddTrialFixedCostEnabled = false;

  @ViewChild('importActsDialog', {read: ViewContainerRef}) importPopupContainer: ViewContainerRef;

  trialHhhId: number;
  referenceActCheckedList: DetailedTrialAct [] = [];
  noYesOptions: SelectItem[];
  displayNoSelectedReferenceActDialog: boolean;
  trialActHhhId: number;
  userCanAccessActs = false;

  contractTypeFilterList: SelectItem[] = [];
  typeFilterList: SelectItem[] = [];
  externalFromCenterFilterList: SelectItem[] = [];
  invoiceableFilterList: SelectItem[] = [];
  optionalFilterList: SelectItem[] = [];
  onPrescriptionFilterList: SelectItem[] = [];
  actDescriptionFilterList: SelectItem[] = [];
  actCategoryFilterList: SelectItem[] = [];
  freeSSFilterList: SelectItem[] = [];

  contractTypes: SelectItem[] = [];

  constructor(
    private cfr: ComponentFactoryResolver,
    private translateService: InnohealthTranslateService,
    private useService: UsersService,
    private dataService: DataService,
    private router: Router,
    private translate: TranslateService,
    public trialService: TrialService,
    private trialActService: TrialActService,
    public trialPageLockService: TrialPageLockService,
    private dynamicConfigService: DynamicConfigService,
    private dataItemService: DataItemService,
    private trialAdministrativeDatesService: TrialAdministrativeDatesService,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  async ngOnInit() {
    super.ngOnInit();
    this.contractTypes = await this.getContractType().then().catch();
    this.userCanAccessActs = ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(ACT_GRID_READ_WRITE)) >= 0;
    this.initProperties();
    this.getTrialActs();
    this.translate.stream('UI_GLOBAL_PLACEHOLDER_SELECT_FROM_LIST').subscribe(() => {
      this.extractStatus();
    });
    this.cdr.detectChanges();
  }

  getContractType(): Promise<SelectItem[]> {
    return this.dataItemService.getItems('contract-type').toPromise();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.trialSecondUfEnabled = this.dynamicConfigService.getProperty(PropertyEnum.trialSecondUfEnabled);
      this.conventionTypeIsRequiredToAddTrialFixedCostEnabled = this.dynamicConfigService.getProperty(PropertyEnum.conventionTypeIsRequiredToAddTrialFixedCostEnabled);
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('contractType')
      .filterable()
      .optionFilterable()
      .filter('contractTypeHhhId')
      .filterType(FilterType.DROPDOWN)
      .options(this.contractTypes)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('type')
      .filterable()
      .filter('typeHhhId')
      .filterType(FilterType.DROPDOWN)
      .options(this.typeFilterList)
      .sortable()
      .displayContent((act: DetailedTrialAct) => act.typeName)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('actCategory')
      .displayContent((trialAct: DetailedTrialAct) => {
        return trialAct.actCategory;
      })
      .filterable()
      .optionFilterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.actCategoryFilterList)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('description')
      .filterable()
      .optionFilterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.actDescriptionFilterList)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('externalFromCentre')
      .filterable()
      .optionFilterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.externalFromCenterFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('invoiceable')
      .filterable()
      .optionFilterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.invoiceableFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('pricePerUnit')
      .accessible(this.dynamicConfigService.verifyClient(['ccgm', 'col', 'chartres', 'igr']) ? this.useService.canAccessField(HiddenFields.ACTE_PRICE) : true)
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .withDisplayCssClassContent()
      .cssClass(() => {
        return 'right-align';
      })
      .displayContent((data: DetailedTrialAct) => {
        return (Number(data.pricePerUnit)).toFixed(2) + ' €';
      })
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('internalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('freeSS')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.freeSSFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('unitNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .withDisplayCssClassContent()
      .cssClass(() => {
        return 'pull-right';
      })
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('onPrescription')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.onPrescriptionFilterList)
      .isTranslated()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('optional')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.optionalFilterList)
      .isTranslated()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondInternalAccountNumber')
      .accessible(this.trialSecondUfEnabled)
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/trial/additional-costs/acts-grid/acts-grid.component';
  }

  getDeleteMessage(): string {
    return 'MODULE_REFERENCE_ACTS_DIALOG_WARNING_SELECTED_REFERENCE_ACT_WILL_BE_DELETED';
  }

  getService(): any {
    return this.trialActService;
  }


  getTrialActs() {
    this.trialActService.getTrialActList(this.trialHhhId).subscribe(
      res => {
        res.sort((a, b) => {
          if (a.actCategory) {
            return a.actCategory.localeCompare(b.actCategory);
          }
          return -1;
        });
        this.values = res;
        this.totalNumber = this.values.length;
        this.trialActService.trialActList = this.values;
        this.trialActService.trialHhhId = this.trialHhhId;
        this.updateDropdownOptions(res);
      }
    );

  }

  updateDropdownOptions(event) {
    for (const act of event) {
      const descItem = {label: act.description, value: act.description};
      const contractTypeItem = {
        label: act.contractType !== null ? this.translateService.getTranslationString(act.contractType) : '',
        value: act.contractType
      };
      const typeItem = {label: act.typeName, value: act.typeHhhId};
      const actCategItem = {label: act.actCategory, value: act.actCategory};
      const externalFromCenterItem = {
        label: act.externalFromCentre !== null ? this.translateService.getTranslationString(act.externalFromCentre.toString()) : '',
        value: act.externalFromCentre
      };
      const invoiceableItem = {
        label: act.invoiceable !== null ? this.translateService.getTranslationString(act.invoiceable.toString()) : '',
        value: act.invoiceable
      };
      const optionalItem = {
        label: act.optional !== null ? this.translateService.getTranslationString(act.optional.toString()) : '',
        value: act.optional
      };
      const onPrescriptionItem = {
        label: act.onPrescription !== null ? this.translateService.getTranslationString(act.onPrescription.toString()) : '',
        value: act.onPrescription
      };
      const freeSSItem = {
        label: act.freeSS !== null ? this.translateService.getTranslationString(act.freeSS.toString()) : '',
        value: act.freeSS
      };
      if (act.contractType !== null &&
        this.contractTypeFilterList.findIndex((item: SelectItem) => item.label === contractTypeItem.label) === -1) {
        this.contractTypeFilterList.push(contractTypeItem);
      }
      if (act.typeHhhId !== null &&
        this.typeFilterList.findIndex((item: SelectItem) => item.value === typeItem.value) === -1) {
        this.typeFilterList.push(typeItem);
      }
      if (act.description !== null &&
        this.actDescriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
        this.actDescriptionFilterList.push(descItem);
      }
      if (act.actCategory !== null &&
        this.actCategoryFilterList.findIndex((item: SelectItem) => item.value === actCategItem.value) === -1) {
        this.actCategoryFilterList.push(actCategItem);
      }
      if (act.externalFromCentre !== null &&
        this.externalFromCenterFilterList.findIndex((item: SelectItem) => item.value === externalFromCenterItem.value) === -1) {
        this.externalFromCenterFilterList.push(externalFromCenterItem);
      }
      if (act.invoiceable !== null &&
        this.invoiceableFilterList.findIndex((item: SelectItem) => item.value === invoiceableItem.value) === -1) {
        this.invoiceableFilterList.push(invoiceableItem);
      }
      if (act.optional !== null &&
        this.optionalFilterList.findIndex((item: SelectItem) => item.value === optionalItem.value) === -1) {
        this.optionalFilterList.push(optionalItem);
      }
      if (act.onPrescription !== null &&
        this.onPrescriptionFilterList.findIndex((item: SelectItem) => item.value === onPrescriptionItem.value) === -1) {
        this.onPrescriptionFilterList.push(onPrescriptionItem);
      }
      if (act.freeSS !== null &&
        this.freeSSFilterList.findIndex((item: SelectItem) => item.value === freeSSItem.value) === -1) {
        this.freeSSFilterList.push(freeSSItem);
      }
    }
    this.actDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.contractTypeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.typeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.actCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.externalFromCenterFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.invoiceableFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.optionalFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.onPrescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.freeSSFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }


  updateSelectedList(selectedList: any) {
    this.referenceActCheckedList = selectedList;
  }

  displayFormDialogue(trialAct?: DetailedTrialAct) {
    const formComponent = TrialActFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (trialAct) {
      formComponent.detailedTrialAct = new DetailedTrialAct().init(trialAct);
      formComponent.formHeader = this.getUpdateTitle();
    }
    formComponent.onAdd((detailedTrialAct: DetailedTrialAct) => {
      this.updateList(detailedTrialAct);
    });
  }

  openDeleteReferenceActDialog() {
    if (this.referenceActCheckedList.length === 0) {
      this.displayNoSelectedReferenceActDialog = true;
    } else {
      this.displayDeleteMultiple();
    }
  }

  displayDeleteMultiple() {
    const formComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    formComponent.service = this.trialActService;
    formComponent.selectedList = this.referenceActCheckedList;
    formComponent.navigationRoute = '/trial-details/theoretical-calendar';
    formComponent.optionalNavigationRoute = 'trial-details/inclusion-details/calendar';
    formComponent.popupMessage = 'MODULE_REFERENCE_ACTS_DIALOG_WARNING_SELECTED_REFERENCE_ACT_WILL_BE_DELETED';
    formComponent.hhhId = this.trialHhhId;
    formComponent.onDelete((deletedItem: number[]) => {
      for (const hhhId of deletedItem) {
        const index = this.values.findIndex((referenceAct: DetailedTrialAct) => referenceAct.hhhId === hhhId);
        this.values.splice(index, 1);
      }
    });
  }

  extractStatus() {
    this.noYesOptions = [
      {label: '', value: null},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE'), value: true},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE'), value: false}
    ];
  }

  openImportActListDialog() {
    ImportTrialActFormComponent.displayFormDialogue(this.importPopupContainer, this.cfr);
    ImportTrialActFormComponent.importEvent.subscribe(res => {
      this.getTrialActs();
    });
  }

  addNewActs() {
    if (this.conventionTypeIsRequiredToAddTrialFixedCostEnabled) {
      this.trialAdministrativeDatesService.hasConventionTypes(this.trialHhhId).subscribe(hasConventionTypes => {
        if (!hasConventionTypes) {
          this.sharedService.showFailure('MODULE_TRIALS_ACTS_GRID_CONVENTION_TYPE_IS_REQUIRED_TO_ADD_ELEMENT');
          return;
        }
        this.navigate();
      });
    } else {
      this.navigate();
    }
  }

  navigate() {
    this.dataService.changeFromModule(this.trialHhhId);
    this.router
      .navigate(
        ['/additional-costs-container/reference-acts'],
        {queryParams: {actSelected: true}}
      ).then();
  }

  updateList(detailedTrialAct?: DetailedTrialAct) {
    const index = this.values.findIndex((trialAct: DetailedTrialAct) => detailedTrialAct.hhhId === trialAct.hhhId);
    this.values[index] = detailedTrialAct;
  }

  exportTrialActs(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.trialHhhId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .build();

    this.trialActService.exportTrialActList(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }


}

import {MoleculeStudiedDto} from './molecule-studied-dto';

export class TreatmentStudiedMoleculeDto {
  enabled: boolean;
  studiedMolecule: MoleculeStudiedDto;
  constructor() {
    this.enabled = true;
    this.studiedMolecule = new MoleculeStudiedDto();
  }
}

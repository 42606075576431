export enum TrialInputType {
  COMMA_SPLIT_STRING = 'commaSplitString',
  COMMA_SPLIT_DATE = 'commaSplitDate',
  COMMA_SPLIT_ELIMINATE_NUMBER = 'commaSplitEliminateNumber',
  UNDER_SCORE_SPLIT = 'underScoreSplit',
  STATE = 'state',
  COL_INVESTIGATOR = 'colInvestigator',
  PRIORITY_NUMBERS = 'priorityNumbers',
  ARRAY = 'array'
}

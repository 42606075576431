import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2} from '@angular/core';

@Directive({
  selector: '[appCurrency]'
})
export class CurrencyDirective {

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  @Input() currencyValidationActivated = true;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.currencyValidationActivated) {
      return;
    }
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this.el.nativeElement.value;
    const position = this.getPosition();
    const next: string = this.getNextValue(current, event, position);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  private getPosition(): number {
    return this.renderer.selectRootElement(this.el.nativeElement).selectionStart;
  }

  private getNextValue(current: string, event: KeyboardEvent, position: number | null): string {
    if (position !== null) {
      return [current.slice(0, position), event.key === 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    } else {
      return current + (event.key === 'Decimal' ? '.' : event.key);
    }
  }


}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormInputConfig} from '../../entity/form-input-config';


@Component({
  selector: 'ih-dynamic-anchor',
  templateUrl: './dynamic-anchor.component.html',
  styleUrls: ['./dynamic-anchor.component.css']
})


export class DynamicAnchorComponent {

  @Input() value: any;
  @Input() config: FormInputConfig;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() alignLabel = true;


  @Output() navigate: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  emitNavigate() {
    this.navigate.emit();
  }

}

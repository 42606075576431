import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../shared/component/form/form.component';
import {CounterpartReference} from '../models/counterpart-reference';
import {SharedService} from '../../shared/services/shared.service';
import {SelectItem} from 'primeng/api';
import {NgForm} from '@angular/forms';
import {CounterpartCategoryService} from '../services/counterpart-category.service';
import {CounterpartReferenceService} from '../services/counterpart-reference.service';
import {Data} from '../../shared/entity/data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import { PropertyEnum } from 'src/app/dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-counter-part-reference-form',
  templateUrl: './counter-part-reference-form.component.html',
  styleUrls: ['./counter-part-reference-form.component.css']
})
export class CounterPartReferenceFormComponent extends FormComponent<CounterpartReference> implements OnInit {

  target = DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_COUNTER_PART_ADD_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('referenceForm') referenceForm: NgForm;


  public noYesOptions: SelectItem[];
  public reference: CounterpartReference = new CounterpartReference();
  public oldReferenceCounterPart: CounterpartReference = new CounterpartReference();
  public fixedCostReferenceCategoryEmpty: boolean;
  yesOrNoOptions: SelectItem[] = Data.yesOrNo;
  isNumericFirstSegmentEnabled = false;

  constructor(
    private sharedService: SharedService,
    private counterPartsReferencesService: CounterpartReferenceService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(CounterPartReferenceFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.initInvoiceable();
    this.initProperties();
    this.display = true;
    this.submitted = false;
    this.fixedCostReferenceCategoryEmpty = false;
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isNumericFirstSegmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.NumericFirstSegmentEnabled);
      this.initFormConfig();
    });
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, AdditionalCostsAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  submitReference() {
    this.submitted = true;
    if (this.referenceForm.invalid || this.fixedCostReferenceCategoryEmpty || !this.reference.isValid(this.config)) {
      return;
    }
    if (this.reference.counterPartCategoryHhhId) {
      if (!this.reference.hhhId) {
        this.counterPartsReferencesService.addReference(this.reference).subscribe(counterPartReference => {
          this.sharedService.showSuccess('UI_FORM_SUCCESS_SAVING_SUCCEEDED');
          this.sharedService.setInformationInLocalStorage('Grille budgétaire: Contreparties', 'Ajouter', this.reference.description);
          this.callback(counterPartReference);
          this.submitted = false;
        }, error => {
          this.sharedService.showFailure();
          this.submitted = false;
          console.error(error);
        });
      } else {
        this.counterPartsReferencesService.updateReference(this.reference).subscribe(res => {
          this.sharedService.showSuccess('UI_FORM_SUCCESS_SAVING_SUCCEEDED');
          this.sharedService.setInformationInLocalStorage('Grille budgétaire: Contreparties', 'Éditer', this.reference.description);
          this.reference.counterPartCategoryName = res;
          this.callback(this.reference);
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        });
        this.submitted = false;
      }
    }
    this.display = false;
  }

  initInvoiceable() {
    if (!this.reference.hhhId) {
      this.reference.invoiceable = false;
    }
  }

  onCancelClick() {
    this.display = false;
    this.initReferenceCounterPart(this.reference, this.oldReferenceCounterPart);
  }

  initReferenceCounterPart(referenceCounterPart: CounterpartReference, oldReferenceCounterPart: CounterpartReference) {
    referenceCounterPart.description = oldReferenceCounterPart.description;
    referenceCounterPart.invoiceable = oldReferenceCounterPart.invoiceable;
    referenceCounterPart.attachedToVisit = oldReferenceCounterPart.attachedToVisit;
    referenceCounterPart.pricePerUnit = oldReferenceCounterPart.pricePerUnit;
    referenceCounterPart.referenceCounterPart = oldReferenceCounterPart.referenceCounterPart;
    referenceCounterPart.counterPartCategoryName = oldReferenceCounterPart.counterPartCategoryName;
    referenceCounterPart.counterPartCategoryHhhId = oldReferenceCounterPart.counterPartCategoryHhhId;
    referenceCounterPart.unitNumber = oldReferenceCounterPart.unitNumber;
    referenceCounterPart.total = oldReferenceCounterPart.total;
    referenceCounterPart.editable = oldReferenceCounterPart.editable;
    referenceCounterPart.internalAccountNumber = oldReferenceCounterPart.internalAccountNumber;
    referenceCounterPart.firstSegment = oldReferenceCounterPart.firstSegment;
    referenceCounterPart.secondSegment = oldReferenceCounterPart.secondSegment;
    referenceCounterPart.publicCommentCount = oldReferenceCounterPart.publicCommentCount;
    referenceCounterPart.freeSS = oldReferenceCounterPart.freeSS;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
}

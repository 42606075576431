import {Component, OnInit, ViewChild} from '@angular/core';
import {ClonedVisit} from '../../../../../entity/inclusion/cloned-visit';
import {VisitService} from '../../../../../service/visit.service';
import {SharedService} from '../../../../../../shared/services/shared.service';
import {Arm} from '../../../../../entity/inclusion/arm';
import {VisitAction} from '../../../../../../visit-action';
import {NgForm} from '@angular/forms';
import {ArmService} from '../../../../../services/arm.service';
import {Router} from '@angular/router';
import {PropertyEnum} from '../../../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../../../dynamic-config/service/dynamic-config.service';
import {DataItemService} from '../../../../../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-visit-duplication-form',
  templateUrl: './visit-duplication-form.component.html',
  styleUrls: ['./visit-duplication-form.component.css']
})
export class VisitDuplicationFormComponent implements OnInit {
  @ViewChild('visitDuplicationForm') visitDuplicationForm: NgForm;

  public inclusionHhhId: number;

  public displayDuplicateVisitDialog = false;
  public clonedVisit: ClonedVisit = new ClonedVisit();
  public arms: { label, value }[];
  public arm: Arm = new Arm();
  public submitted = false;
  public visitName: string;
  private callback: (visitAction: VisitAction) => void;
  isInclusionCalendarSavingButtonApart = false;

  constructor(
    private visitService: VisitService,
    private armService: ArmService,
    private sharedService: SharedService,
    private router: Router,
    private dataItemService: DataItemService,
    private dynamicConfigService: DynamicConfigService
  ) {

    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.inclusionHhhId = url.queryParams.inclusionId;
    }
  }

  ngOnInit() {
    this.getDestinations();
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isInclusionCalendarSavingButtonApart = this.dynamicConfigService.getProperty(PropertyEnum.inclusionCalendarSavingButtonApart);
    });
  }

  cloneVisit() {
    this.submitted = true;
    if (this.visitDuplicationForm.invalid) {
      return;
    }
    if (this.isInclusionCalendarSavingButtonApart) {
      const visitAction = new VisitAction(this.clonedVisit, this.clonedVisit.selectedArm, 'clone', this.visitName);
      this.callback(visitAction);
      this.displayDuplicateVisitDialog = false;
      return;
    }
    this.visitService.cloneVisit(this.clonedVisit).subscribe(res => {
      const visitAction = new VisitAction(res, this.clonedVisit.selectedArm, 'clone');
      this.callback(visitAction);
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
    this.displayDuplicateVisitDialog = false;
  }

  getDestinations() {
    this.dataItemService.getItems('arm-by-inclusion|' + this.inclusionHhhId).subscribe(arms => {
      this.arms = arms;
    });
  }

  closeDuplicateVisitDialog() {
    this.displayDuplicateVisitDialog = false;
  }

  onClone(callback: (visitAction: VisitAction) => void) {
    this.callback = callback;
  }
}

import {FormInputConfig} from './form-input-config';
import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('DynamicFormDefinitionField')
export class DynamicFormDefinitionField {

  @JsonProperty('hhhId', Number)
  hhhId: number = undefined;

  @JsonProperty('field', String)
  field: string = undefined;

  @JsonProperty('defaultLabel', String)
  defaultLabel: string = undefined;

  @JsonProperty('type', String)
  type: string = undefined;

  @JsonProperty('hasPostIt', Boolean)
  hasPostIt: boolean = undefined;

  @JsonProperty('important', Boolean)
  important: boolean;

  @JsonProperty('defaultImportantDescription', String, true)
  defaultImportantDescription: string = undefined;

  @JsonProperty('config', FormInputConfig)
  config: FormInputConfig = new FormInputConfig();

  color = '';

  constructor(field?: string) {
    this.field = field;
  }

  displayContent(field: string): string {
    switch (field) {
      case 'type':
        return this.type;
      case 'field':
        return this.field;
      default:
        return this.config[field];
    }
  }

}

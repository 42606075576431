import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';

export class CounterpartReference {
  public hhhId: number;
  public publicCommentCount: number;
  public description: string;
  public attachedToVisit: boolean;
  public referenceCounterPart: string;
  public counterPartCategoryName: string;
  public counterPartCategoryHhhId: number;
  public invoiceable: boolean;
  public pricePerUnit: number;
  public unitNumber: number;
  public total: number;
  public editable: boolean;
  public internalAccountNumber: string;
  public firstSegment: string;
  public secondSegment: string;
  public temps: string;
  public freeSS: boolean;

  constructor() {
    this.attachedToVisit = false;
  }

  init(obj: any): CounterpartReference {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'actCategoryName') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.counterPartCategoryHhhId)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }

}

export class ExportedCounterPart {
  public counterPartCategoryName: string;
  public internalAccountNumber: string;
  public description: string;
  public attachedToVisit: string;
  public invoiceable: string;
  public pricePerUnit: number;
  public firstSegment: string;
  public secondSegment: string;
}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {VitalSign} from '../../../../entity/inclusion/vital-sign';
import {Router} from '@angular/router';
import {VitalSignsFormComponent} from '../vital-signs-form/vital-signs-form.component';
import {VitalSignsService} from '../../../../service/vital-signs.service';
import {PaginatorTableComponent} from '../../../../../shared/component/paginator-table/paginator-table.component';
import {DataService} from '../../../../../shared/services/data-service';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {TrialAppData} from '../../../../trial-app-data';
import {DynamicTableHeaderBuilder} from '../../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {AttachedToEntityEnum} from '../../../../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-vital-signs',
  templateUrl: './vital-signs.component.html',
  styleUrls: ['./vital-signs.component.css']
})
export class VitalSignsComponent extends PaginatorTableComponent<VitalSign> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.VitalSign;
  readonly target = DynamicDefinitionEnum.TRIAL_VITAL_SIGNS_TABLE;
  config: TableConfig;

  headers: DynamicTableHeader[];

  constructor(
      private vitalSignsService: VitalSignsService,
      private router: Router,
      private dataService: DataService,
      private cfr: ComponentFactoryResolver,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getInclusionVitalSign();
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('visit')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('size')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('weight')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('temperature')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('bloodPressure')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('arm')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('respiration')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('saturation')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('ecg')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('fasting')
        .isTranslated()
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getUpdateTitle(): string {
    return 'MODULE_TRIALS_PATIENTS_VITAL_SIGNS_GENERAL_EDIT';
  }

  getDeleteMessage(): string {
    return 'MODULE_TRIALS_PATIENTS_VITAL_SIGNS_DIALOG_WARNING_PATIENT_TRIAL_INCLUSION_WILL_BE_DELETED';
  }

  getService(): any {
    return this.vitalSignsService;
  }

  getInclusionVitalSign() {
    const url = this.router.parseUrl(this.router.url);
    const inclusionHhhId = url.queryParams.inclusionId;
    this.vitalSignsService.loadList(inclusionHhhId).subscribe(res => {
      this.values = res;
      this.totalNumber = this.values.length;
    });
  }

  displayFormDialogue(vitalSign?: VitalSign) {
    const formComponent = VitalSignsFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (vitalSign) {
      formComponent.vitalSign = (new VitalSign()).init(vitalSign);
      formComponent.formHeader = this.getUpdateTitle();
    } else {
      formComponent.formHeader = this.getAddTitle();
    }
    formComponent.onAdd(this.updateList(formComponent.vitalSign));
  }

  displayDeleteDialogueOfDeletableEntity(vitalSign: VitalSign) {
    this.displayDeleteDialogueOfDeletableEntityFromInfos(vitalSign.hhhId, vitalSign.visit, vitalSign);
  }

  refreshAfterDelete(entity?: any): void {
    const index = this.values.findIndex((vitalSign: VitalSign) => vitalSign.hhhId === entity.hhhId);
    this.values.splice(index, 1);
  }

  updateList(newEntity: VitalSign) {
    return (c: VitalSign) => {
      const index = this.values.findIndex(e => e.hhhId === newEntity.hhhId);
      if (index !== -1) {
        this.values[index] = newEntity;
      }
    };
  }

}

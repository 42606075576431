import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {VisitAdditionalCost} from '../../../entity/trial-additionnal-costs/visit-additional-cost';
import {SyntheticTrialAct} from '../../../entity/theoretical-calendar/synthetic-trial-act';
import {ReferenceActService} from '../../../../additional-costs/services/reference-act.service';
import {TrialActService} from '../../../service/trial-act.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {TrialCounterpartService} from '../../../service/trial-counterpart.service';
import {TrialFixedCostService} from '../../../service/trial-fixed-cost.service';
import {OperationalActService} from '../../../../additional-costs/services/operational-act.service';
import {TrialOperationalActService} from '../../../service/trial-operational-act.service';
import {isNullOrUndefined} from 'util';
import {OperationalTrialActsComponent} from '../../trial-additionnal-costs/operational-trial-acts/operational-trial-acts.component';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {InputType} from '../../../../input-type';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {TrialOperationalAct} from '../../../entity/trial-additionnal-costs/trial-operational-act';

@Component({
  selector: 'ih-theoretical-calendar-additional-cost',
  templateUrl: './theoretical-calendar-additional-cost.component.html',
  styleUrls: ['./theoretical-calendar-additional-cost.component.css']
})
export class TheoreticalCalendarAdditionalCostComponent implements OnInit {

  @ViewChild('deletePopup', {read: ViewContainerRef}) deletePopupContainer: ViewContainerRef;
  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;
  @Input() activeModule;
  @Input() allTrialActs: VisitAdditionalCost[];
  @Input() syntheticTrialAct: SyntheticTrialAct = new SyntheticTrialAct();
  @Input() editable = false;
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteSubAct: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();


  @Input() target: string;
  @Input() config: TableConfig;
  @Input() headers: DynamicTableHeader[] = [];

  filterSelectedItemList: any[] = [];
  filterSelectedItem: any;
  trialHhhId: number;

  filterType = FilterType;
  inputType = InputType;

  constructor(private referenceActService: ReferenceActService,
              private trialActService: TrialActService,
              private trialFixedCostService: TrialFixedCostService,
              private trialCounterPartService: TrialCounterpartService,
              private operationalActService: OperationalActService,
              private trialOperationalActService: TrialOperationalActService,
              private sharedService: SharedService,
              private translateService: InnohealthTranslateService,
              private datePipe: DatePipe,
              private cfr: ComponentFactoryResolver) {
  }

  ngOnInit() {
    for (const item of this.headers) {
      this.filterSelectedItemList.push(this.filterSelectedItem);
    }
  }

  onRowExpand(operationalAct) {
    if (isNullOrUndefined(operationalAct.data.operationalSubActs)) {
      if (this.syntheticTrialAct.actsToAdd && this.syntheticTrialAct.actsToAdd.indexOf(operationalAct.data) !== -1) {
        this.operationalActService.getVisitAdditionalCostsByOperationalActId(operationalAct.data.operationalActHhhId).subscribe(
          res => {
            operationalAct.data.operationalSubActs = res;
          });
      } else {
        this.trialOperationalActService.getVisitAdditionalCostList(operationalAct.data.hhhId).subscribe(
          res => {
            operationalAct.data.operationalSubActs = res;
          });
      }
    }
  }

  getFilteredValue(event: any) {
    this.filterEvent.emit(event);
  }

  editOperationalAct(rowData: any) {
    this.trialOperationalActService.getTrialOperationalActById(rowData.hhhId).subscribe(res => {
      const formComponent = OperationalTrialActsComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
      formComponent.trialOperationalAct = new TrialOperationalAct().init(res);
    });
  }

  displayContent(col: DynamicTableHeader, colField: any, rowData: any): string {
    let content = colField;
    if (isNullOrUndefined(rowData[col.field])) {
      content = '';
    } else if (col.isTranslated) {
      content = colField ?
        this.translateService.getTranslationString('UI_DIALOG_BUTTON_YES')
        : this.translateService.getTranslationString('UI_DIALOG_BUTTON_NO');
    } else if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (col.dateFormatter) {
      content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
    }
    return content;
  }

  getBtnClass(): string {
    return this.config.getBtnClass();
  }

  displayBtn(btnId: string): boolean {
    if (this.config) {
      return this.config.displayBtn(btnId);
    }
    return false;
  }

  emitDeleteSubActEvent(rowAct: VisitAdditionalCost) {
    this.deleteSubAct.emit(rowAct);
  }

  emitDeleteRow(row: any) {
    this.delete.emit(row);
  }
}

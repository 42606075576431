import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TheoreticalCalendarService} from '../../../service/theoretical-calendar.service';
import {Router} from '@angular/router';
import {CalendarService} from '../../../service/calendar.service';
import {DataService} from '../../../../shared/services/data-service';
import {TheoreticalVisit} from '../../../entity/theoretical-calendar/theoretical-visit';
import {TheoreticalCalendar} from '../../../entity/theoretical-calendar/theoretical-calendar';
import {SharedService} from '../../../../shared/services/shared.service';
import {ImportCalendarFormComponent} from '../import-calendar-form/import-calendar-form.component';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {MinimizedTrialProtocol} from '../../../models/minimized-trial-protocol';
import {SelectItem, SelectItemGroup} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {TrialProtocolService} from '../../../services/trial-protocol.service';
import {isNullOrUndefined} from 'util';
import {ImportExport} from '../../../entity/theoretical-calendar/import-export';
import {ArmService} from '../../../services/arm.service';
import {TheoreticalVisitInInclusion} from '../../../entity/theoretical-calendar/theoretical-visit-in-inclusion';
import {Arm} from '../../../entity/inclusion/arm';
import {TrialService} from '../../../services/trial.service';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {AppData} from '../../../../helpers/app-data';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';

@Component({
  selector: 'ih-theoretical-calendar',
  templateUrl: './theoretical-calendar.component.html',
  styleUrls: ['./theoretical-calendar.component.css']
})
export class TheoreticalCalendarComponent implements OnInit, OnDestroy {

  amendmentEnabled = true;
  ivrsEnabled = true;
  iwrsEnabled = false;
  disableSaveBtn = false;

  @ViewChild('importPopupDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  public trialHhhId: number;
  public theoreticalCalendar: TheoreticalCalendar = new TheoreticalCalendar();

  protocol: MinimizedTrialProtocol = new MinimizedTrialProtocol();
  usedProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  usedProtocolsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  allProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  allProtocolsGrouped: Array<SelectItemGroup> = new Array<SelectItemGroup>();
  showEmptyProtocolWarning = false;
  unusedProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  unusedProtocolsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  targetProtocol: number;
  disabledArms: Array<number> = new Array<number>();
  selectedProtocol: number;

  constructor(private theoreticalCalendarService: TheoreticalCalendarService,
              private router: Router,
              private sharedService: SharedService,
              private calendarService: CalendarService,
              private dataService: DataService,
              private cfr: ComponentFactoryResolver,
              private canDeactivateService: CanDeactivateService,
              private armService: ArmService,
              public trialService: TrialService,
              private translate: TranslateService,
              private trialProtocolService: TrialProtocolService,
              private dynamicConfigService: DynamicConfigService,
              public trialPageLockService: TrialPageLockService) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.theoreticalCalendarService.trialHhhId = this.trialHhhId;
    this.initProperties().then(() => {
      this.initializeProtocolVersionGroups();
      this.initTheoreticalCalendar();
      this.getUnusedProtocols();
    });
  }

  initProperties(): Promise<any> {
    return new Promise((resolver: any) => {
      this.dynamicConfigService.initProperties().subscribe(() => {
        this.amendmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
        this.ivrsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.ivrsEnabled);
        this.iwrsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.iwrsEnabled);
        resolver();
      });
    });
  }

  private initializeProtocolVersionGroups() {
    if (this.allProtocolsGrouped.length > 0) {
      return;
    }
    this.allProtocolsGrouped.push({
      label: 'Version(s) initialisée(s)',
      value: '',
      items: []
    });
    this.allProtocolsGrouped.push({
      label: 'Version(s) non initialisée(s)',
      value: '',
      items: []
    });
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  getTheoreticalCalendar(trialProtocolHhhId?: number, callBack?: () => void) {
    trialProtocolHhhId = trialProtocolHhhId === undefined ? -1 : trialProtocolHhhId;
    this.disableSaveBtn=true;
    this.theoreticalCalendarService.getTheoreticalCalendarByTrial(this.trialHhhId, trialProtocolHhhId, false).subscribe((theoreticalCalendar: TheoreticalCalendar) => {
      theoreticalCalendar.theoreticalVisitBeforeInclusionList.sort((theoreticalVisit1, theoreticalVisit2) => {
        this.disableSaveBtn=false;
        return (+theoreticalVisit1.displayHowManyDays.substring(1) >= +theoreticalVisit2.displayHowManyDays.substring(1)
          || (theoreticalVisit2.displayHowManyDays.substring(2) === 'Aucun')) ? 1 : -1;
      });
      theoreticalCalendar.theoreticalVisitAfterInclusionList.sort((theoreticalVisit1, theoreticalVisit2) => {
        this.disableSaveBtn=false;
        return (+theoreticalVisit1.displayHowManyDays.substring(1) >= +theoreticalVisit2.displayHowManyDays.substring(1)
          || (theoreticalVisit2.displayHowManyDays.substring(2) === 'Aucun')) ? 1 : -1;
      });
      for (const theoreticalVisitInInclusionDto of theoreticalCalendar.theoreticalVisitInInclusionDtoList) {
        theoreticalVisitInInclusionDto.theoreticalVisits.sort((theoreticalVisit1, theoreticalVisit2) => {
          this.disableSaveBtn=false;
          return (+theoreticalVisit1.displayHowManyDays.substring(1) >= +theoreticalVisit2.displayHowManyDays.substring(1)
            || (theoreticalVisit2.displayHowManyDays.substring(2) === 'Aucun')) ? 1 : -1;
        });
      }
      this.extractVisitsFromCalendar(theoreticalCalendar);
      if (callBack) {
        callBack();
      }
      this.disableSaveBtn=false;
    });
  }

  displayImportExportCalendarForm(isImport: boolean) {
    const popupDialogFactory = this.cfr.resolveComponentFactory(ImportCalendarFormComponent);
    const importCalendarFormRef = this.popupContainer.createComponent(popupDialogFactory);
    const importExportFormComponent = importCalendarFormRef.instance;
    importExportFormComponent.isImportAction = isImport;
    importExportFormComponent.protocolId = this.protocol.hhhId;
    importExportFormComponent.importEvent.subscribe((importExport: ImportExport) => {
      this.getTheoreticalCalendar(this.protocol ? this.protocol.hhhId : undefined);
    });
  }

  saveAmendment() {
    this.canDeactivateService.canBeDeactivated = true;
    this.dataService.saveAmendment(true);
  }

  setTheoreticalCalendar(trialProtocolHhhId?: number) {
    this.targetProtocol = null;
    if (this.amendmentEnabled && !isNullOrUndefined(trialProtocolHhhId)) {
      this.trialProtocolService.selectedTheoreticalCalendarProtocolHhhId = trialProtocolHhhId;
      this.protocol = new MinimizedTrialProtocol(trialProtocolHhhId);
      this.selectedProtocol = trialProtocolHhhId;
    }
    this.initializeProtocolVersionGroups();
    this.getUsedProtocols(trialProtocolHhhId ? new MinimizedTrialProtocol(trialProtocolHhhId) : null);
    this.getUnusedProtocols();
    this.getTheoreticalCalendar(this.trialProtocolService.selectedTheoreticalCalendarProtocolHhhId);
  }

  updateIVRS(active: boolean) {
    this.theoreticalCalendarService.updateTheoreticalCalendarIVRS(active, this.theoreticalCalendar.hhhId).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  getVisitsToDisplay(theoreticalVisits: TheoreticalVisit[]): TheoreticalVisit[] {
    const tmpList: TheoreticalVisit[] = [];
    for (const theoreticalVisit of theoreticalVisits) {
      if (theoreticalVisit.amendmentModificationType !== 'delete') {
        tmpList.push(theoreticalVisit);
      }
    }
    return tmpList;
  }

  extractVisitsFromCalendar(theoreticalCalendar: TheoreticalCalendar) {
    this.theoreticalCalendar = theoreticalCalendar;
    this.theoreticalCalendar.theoreticalVisitDtoList = [];
    this.theoreticalCalendar.theoreticalVisitToEditDtoList = [];
    this.theoreticalCalendar.theoreticalVisitActsToDeleteHhhIds = [];
    this.theoreticalCalendar.theoreticalVisitToDeleteDtoList = [];
    this.theoreticalCalendar.theoreticalVisitActsToEdit = [];
    this.theoreticalCalendar.theoreticalVisitActsToAdd = [];
    this.theoreticalCalendar.theoreticalVisitToCloneDtoList = [];
  }

  getUsedProtocols(targetProtocol?: MinimizedTrialProtocol) {
    this.theoreticalCalendarService.getRelatedProtocolsByTrial(this.trialHhhId).subscribe(result => {
      this.usedProtocols = result;
      if (this.usedProtocolsSelectItems.length > 0) {
        this.usedProtocolsSelectItems.splice(0, this.usedProtocolsSelectItems.length);
      }
      this.usedProtocols.forEach(up => {
        this.usedProtocolsSelectItems.push({
          label: up.version ? ('Version du protocole : ' + up.version) : ('Version de l\'amendement : ' + up.amendmentVersion),
          value: up.hhhId
        });
      });
      this.allProtocolsGrouped[0].items = this.usedProtocolsSelectItems;
      this.checkIfLastProtocolExists();
      if (this.usedProtocols.length > 0) {
        if (!targetProtocol) {
          this.initProtocolToLatest();
        }
        this.getTheoreticalCalendar(this.protocol.hhhId);
      } else {
        this.getTheoreticalCalendar();
      }
    }, error => {
      this.sharedService.showFailure();
    });
  }

  getAllProtocols() {
    this.trialProtocolService.getTrialProtocolList(this.trialHhhId).subscribe(result => {
      this.allProtocols = result;
      this.checkIfLastProtocolExists();
    });
  }

  checkIfLastProtocolExists() {
    this.showEmptyProtocolWarning = this.trialProtocolService.isLastProtocolNotUsed(this.allProtocols, this.usedProtocols);
  }

  initProtocolToLatest() {
    this.protocol = this.usedProtocols[this.usedProtocols.length - 1];
    this.selectedProtocol = this.protocol.hhhId;
    this.protocolValueHasChanged();
  }

  onTrialProtocolChange(callBack?: () => void) {
    this.protocol = this.usedProtocols.find(p => p.hhhId === this.protocol.hhhId);
    this.protocolValueHasChanged();
    this.getTheoreticalCalendar(this.protocol.hhhId, callBack);
  }

  protocolValueHasChanged() {
    this.trialProtocolService.selectedTheoreticalCalendarProtocolHhhId = this.protocol.hhhId;
  }

  getUnusedProtocols() {
    this.trialProtocolService.getNonUnusedInTheoreticalCalendar(this.trialHhhId).subscribe(res => {
      this.unusedProtocols = res;
      if (this.usedProtocolsSelectItems.length > 0) {
        this.unusedProtocolsSelectItems.splice(0, this.usedProtocolsSelectItems.length);
      }
      for (const pr of res) {
        this.unusedProtocolsSelectItems.push({
          label: pr.version ? ('Version du protocole : ' + pr.version) : ('Version de l\'amendement : ' + pr.amendmentVersion),
          value: pr.hhhId
        });
      }
      this.allProtocolsGrouped[1].items = this.unusedProtocolsSelectItems;
      if (this.allProtocolsGrouped[1].items.length === 0) {
        this.allProtocolsGrouped.splice(1, 1);
      }
    });
  }

  private initTheoreticalCalendar() {
    if (this.amendmentEnabled) {
      this.getUsedProtocols();
      this.getAllProtocols();
    } else {
      this.getTheoreticalCalendar();
    }
  }

  onTargetProtocolChange() {
    this.armService.loadListByTrialAndProtocolId('' + this.trialHhhId, this.targetProtocol).subscribe(res => {
      const armsToRemove: Array<TheoreticalVisitInInclusion> = new Array<TheoreticalVisitInInclusion>();
      for (const theoreticalVisitInInclusion of this.theoreticalCalendar.theoreticalVisitInInclusionDtoList) {
        if (!this.armNameExists(res, theoreticalVisitInInclusion.arm)) {
          armsToRemove.push(theoreticalVisitInInclusion);
          this.disabledArms.push(theoreticalVisitInInclusion.armHhhId);
        }
      }
      for (const theoreticalVisitInInclusion of armsToRemove) {
        for (const theoreticalVisit of theoreticalVisitInInclusion.theoreticalVisits) {
          theoreticalVisit.modificationType = 'delete';
          this.theoreticalCalendar.theoreticalVisitToDeleteDtoList.push(theoreticalVisit.hhhId);
        }
      }
      const existingArmNames: Array<string> = this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.map(a => a.arm);
      for (const arm of res) {
        if (existingArmNames.indexOf(arm.name) < 0) {
          const newArm: TheoreticalVisitInInclusion = new TheoreticalVisitInInclusion();
          newArm.arm = arm.name;
          newArm.armHhhId = arm.hhhId;
          newArm.theoreticalVisits = [];
          this.theoreticalCalendar.theoreticalVisitInInclusionDtoList.push(newArm);
        }
      }
    });
  }

  armNameExists(arms: Array<Arm>, armName: string): boolean {
    const matchingArm = arms.find(a => a.name === armName);
    return matchingArm !== undefined && matchingArm != null;
  }

  onSelectedProtocolChange() {
    this.protocolValueHasChanged();
    const isProtocolVersionInitialized = this.usedProtocolsSelectItems.find(p => p.value === this.selectedProtocol);
    if (isProtocolVersionInitialized) {
      this.protocol = new MinimizedTrialProtocol(this.selectedProtocol);
      this.onTrialProtocolChange();
    } else {
      const isSelectedProtocolNotLatestInitialized = this.protocol.hhhId !== this.usedProtocols[this.usedProtocols.length - 1].hhhId;
      if (isSelectedProtocolNotLatestInitialized) {
        this.protocol = this.usedProtocols[this.usedProtocols.length - 1];
        this.onTrialProtocolChange(() => {
          this.targetProtocol = this.selectedProtocol;
          this.onTargetProtocolChange();
        });
      } else {
        this.targetProtocol = this.selectedProtocol;
        this.onTargetProtocolChange();
      }
    }
  }

  updateIWRS(iwrs: boolean) {
    this.theoreticalCalendarService.updateTheoreticalCalendarIWRS(iwrs, this.theoreticalCalendar.hhhId).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  exportTheoreticalCalendar(event: any) {
    const request = new ExportRequestBuilder<void>()
      .objectId( !this.amendmentEnabled ? this.trialHhhId : this.trialProtocolService.selectedTheoreticalCalendarProtocolHhhId )
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .exportType(event.exportType)
      .build();
    this.theoreticalCalendarService.exportTheoreticalCalendar(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }
}

import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {Trial} from '../models/trial';
import {DataService} from '../../shared/services/data-service';
import {SharedService} from '../../shared/services/shared.service';
import {TrialsManagementService} from '../services/trials-management.service';
import {ResponsePage} from '../../shared/entity/response-page';
import {TrialGlobalFilter} from '../models/trial-global-filter';
import {EditTrialFormComponent} from '../edit-trial-form/edit-trial-form.component';
import {SuggestPatientsDialogComponent} from '../suggest-patients-dialog/suggest-patients-dialog.component';
import {ChatDialogComponent} from '../chat-dialog/chat-dialog.component';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';

import {TableButtonConfig} from '../../dynamic-config/entity/table-button-config';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {StompService} from '../../shared/services/stomp.service';
import {StompSubscription} from '@stomp/stompjs/src/stomp-subscription';

@Component({
  selector: 'ih-trials',
  templateUrl: './trials.component.html',
  styleUrls: ['./trials.component.css']
})
export class TrialsComponent extends PaginatorTableComponent<Trial> implements OnInit {

  headers: DynamicTableHeader[] = [];
  trialGlobalFilter: TrialGlobalFilter = new TrialGlobalFilter();
  config: TableConfig = new TableConfig(new Map(), new Map());
  realTimeConversationSubscription: StompSubscription;

  constructor(
    private dataService: DataService,
    private cfr: ComponentFactoryResolver,
    private sharedService: SharedService,
    private stompService: StompService,
    private trialManagementService: TrialsManagementService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildTableHeaders();
  }

  buildTableHeaders() {
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('protocolNumber')
      .header('MODULE_MOBILE_APPLICATION_PROTOCOL_NUMBER')
      .width('70%')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('state')
      .header('MODULE_TRIALS_FIELD_STATE')
      .width('70%')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.trialGlobalFilter.state)
      .type('state-selected')
      .sortable()
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('promoter')
      .header('MODULE_TRIALS_FIELD_PROMOTER_1')
      .width('70%')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.trialGlobalFilter.promoter)
      .type('provider-promoter')
      .sortable()
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('phase')
      .header('MODULE_TRIALS_FIELD_PHASE')
      .width('70%')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.trialGlobalFilter.phase)
      .type('phase')
      .sortable()
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('principalInvestigator')
      .header('MODULE_MOBILE_APPLICATION_PRINCIPAL_INVESTIGATOR')
      .width('70%')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.trialGlobalFilter.principalInvestigator)
      .type('principal-investigator')
      .sortable()
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('organsOnly')
      .header('MODULE_INCLUSIONS_FIELD_ORGANS')
      .width('70%')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.trialGlobalFilter.organsOnly)
      .type('organ')
      .sortable()
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('organFamiliesOnly')
      .header('MODULE_TRIALS_FIELD_ORGAN_FAMILIES')
      .width('70%')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.trialGlobalFilter.organFamiliesOnly)
      .type('mobile-trial-organ-family')
      .sortable()
      .build());
    this.headers.push(new DynamicTableHeaderBuilder()
      .field('treatmentLine')
      .header('MODULE_MOBILE_APPLICATION_TREATMENT_LINE')
      .width('70%')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.trialGlobalFilter.treatmentLine)
      .type('metastatic-line')
      .sortable()
      .build());
    const editBtnConfig = new TableButtonConfig();
    editBtnConfig.display = true;
    this.config.buttons.set('edit-btn', editBtnConfig);
  }

  getList(filters?: any) {
    if (filters) {
      this.trialGlobalFilter = Object.assign({}, filters);
    }
    this.trialManagementService
      .getTrials(this.page, this.limit, this.sortBy, this.orderBy, this.trialGlobalFilter)
      .subscribe((trialDataPage: ResponsePage<Trial>) => {
        this.values = trialDataPage.content;
        this.totalNumber = trialDataPage.totalElements;
        this.trialManagementService.setPaginationConfiguration(trialDataPage, this.sortBy, this.orderBy, filters);
      });
  }

  editTrial(trial: Trial) {
    const formComponent = EditTrialFormComponent.display(this.formDialogContainer, this.cfr);
    formComponent.formHeader = 'MODULE_MOBILE_APPLICATION_UPDATE_TRIAL';
    formComponent.setTrial(trial);
    formComponent.onAdd((newTrial: Trial) => {
      this.getList();
    });
    formComponent.onDeleteFile((trialHhhId: number) => {
      const index = this.values.findIndex((t) => t.hhhId === trialHhhId);
      this.values[index].trialSchema = undefined;
    });
  }

  getDefaultSortField(): string {
    return 'hhhId';
  }

  getService(): any {
    return this.trialManagementService;
  }

  suggestPatients(trial: Trial) {
    this.trialManagementService.getSuggestedPatients(trial.hhhId).subscribe(suggestedPatients => {
      if (suggestedPatients.length > 0) {
        const formComponent = SuggestPatientsDialogComponent.display(this.formDialogContainer, this.cfr);
        formComponent.trialHhhId = trial.hhhId;
        formComponent.formHeader = 'MODULE_MOBILE_APPLICATION_PATIENT_SUGGESTED_HEADER';
        formComponent.suggestedPatients = suggestedPatients;
        this.trialManagementService.setPatientSuggestionsAsSeen(trial.hhhId).subscribe(() => {
        });
      } else {
        this.sharedService.showWarning('MODULE_MOBILE_APPLICATION_ACCOUNT_NO_SUGGESTED_PATIENTS');
      }
    });

  }

  chat(trial: Trial) {
    this.trialManagementService.getDoctorsAlongWithMessages(trial.hhhId).subscribe(doctorsWithMessages => {
      if (doctorsWithMessages.length > 0) {
        this.trialManagementService.refreshConversations(doctorsWithMessages);
        const formComponent = ChatDialogComponent.display(this.formDialogContainer, this.cfr);
        formComponent.trialHhhId = trial.hhhId;
        formComponent.formHeader = 'MODULE_MOBILE_APPLICATION_CHAT_HEADER';
        this.subscribeToRealTimeConversation();

      } else {
        this.sharedService.showWarning('MODULE_MOBILE_APPLICATION_ACCOUNT_NO_ACTIVE_USERS');
      }
    });
  }

  subscribeToRealTimeConversation() {
    this.stompService.subscribe('/topic/messages', (res) => {
      this.trialManagementService.refreshConversations(JSON.parse(res));
    },
        subscription => this.realTimeConversationSubscription = subscription);
  }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {FormComponent} from '../../shared/component/form/form.component';
import {BlocFields} from '../../bloc-fields';
import {NgForm} from '@angular/forms';
import {FR} from '../../shared/entity/calendar-language';
import {InclusionDetailService} from '../../trial/service/inclusion-detail.service';
import {SharedService} from '../../shared/services/shared.service';

@Component({
  selector: 'app-bloc-date-form',
  templateUrl: './bloc-fields.component.html',
  styleUrls: ['./bloc-fields.component.css']
})

export class BlocFieldsComponent  extends FormComponent<BlocFields> implements OnInit {
  @ViewChild('blocFieldsForm') blocFieldsForm: NgForm;

  displayAddBlocFields = true;
  inclusionHhhId: number;
  locale = FR;
  blocFields: BlocFields[] = [];
  countBlocFields: number;

  constructor(private inclusionService: InclusionDetailService,
              private sharedService: SharedService
  ) { super(); }

  ngOnInit() {
    super.ngOnInit();
    this.getBlocFields();
  }

  getBlocFields() {
    this.inclusionService.getBlocFields(this.inclusionHhhId).subscribe(res => {
      for (const blocFields of res) {
        blocFields.blocRequestDate = blocFields.blocRequestDate ? new Date(blocFields.blocRequestDate) : blocFields.blocRequestDate;
        blocFields.dispatchBlockDate = blocFields.dispatchBlockDate ? new Date(blocFields.dispatchBlockDate) : blocFields.dispatchBlockDate;
        blocFields.resultDate = blocFields.resultDate ? new Date(blocFields.resultDate) : blocFields.resultDate;
        this.blocFields.push(blocFields);
      }
      if (this.blocFields.length === 0) {
        this.blocFields.push(new BlocFields());
      }
    });
  }


  addBlocField() {
    this.blocFields.push(new BlocFields());
  }
  addBlocFieldsConfirm() {
    let toDelete: BlocFields[] = [];
    for (const blocField of this.blocFields) {
      if (blocField.blocRequestDate == null && blocField.dispatchBlockDate == null && blocField.resultDate == null  && blocField.result === '') {
        toDelete.push(blocField);
      }
    }
    for (const element of toDelete) {
      this.blocFields.splice(this.blocFields.indexOf(element), 1);
    }
    this.submitted = true;
    if (this.blocFieldsForm.invalid) {
      return;
    }
    this.inclusionService.addBlocDate(this.blocFields, this.inclusionHhhId).subscribe(() => {
        this.countBlocFields = this.blocFields.length;
        this.sharedService.showSuccess();
        this.displayAddBlocFields = false;
        this.callback(this.blocFields[0]);
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      }
    );
  }

  deleteBlocField(i: number) {
    if (this.blocFields.length > 0) {
      this.blocFields.splice(i, 1);
    }
  }
}

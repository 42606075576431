import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {OperationalActService} from '../services/operational-act.service';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {OperationalAct} from '../models/operational-act';
import {DataService} from '../../shared/services/data-service';
import {TableHeader} from '../../shared/entity/table-header';
import {OperationalActFormComponent} from '../operational-act-form/operational-act-form.component';
import {SelectItem} from 'primeng/api';
import {NoSelectedItemFormComponent} from '../../modals/no-selected-item-form/no-selected-item-form.component';
import {DeleteMultipleComponent} from '../../modals/delete-multiple/delete-multiple.component';
import {SharedService} from '../../shared/services/shared.service';
import {Router} from '@angular/router';
import {TrialOperationalActService} from '../../trial/service/trial-operational-act.service';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {TrialService} from '../../trial/services/trial.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {AppData} from '../../helpers/app-data';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-operational-acts',
  templateUrl: './operational-acts.component.html',
  styleUrls: ['./operational-acts.component.css']
})
export class OperationalActsComponent extends PaginatorTableComponent<OperationalAct> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.OperationalAct;
  target = DynamicDefinitionEnum.ADDITIONAL_COSTS_OPERATIONAL_ACTS_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  @ViewChild('popupDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  trialHhhId: number;
  operationalActColumns: TableHeader[];
  selectedOperationalActList: OperationalAct[] = [];
  isFromTrial: boolean;
  actCategoryFilterList: SelectItem[] = [];
  actDescriptionFilterList: SelectItem[] = [];
  rdvDescriptionFilterList: SelectItem[] = [];
  invoiceableFilterList: SelectItem[] = [];

  constructor(
      private cfr: ComponentFactoryResolver,
      private dataService: DataService,
      private operationalActService: OperationalActService,
      private sharedService: SharedService,
      private router: Router,
      public trialService: TrialService,
      private translateService: InnohealthTranslateService,
      private trialOperationalActService: TrialOperationalActService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);

    if (url.queryParams && url.queryParams.trialHhhId !== undefined) {
      this.trialHhhId = url.queryParams.trialHhhId;
      this.isFromTrial = true;
      console.error(this.isFromTrial);
    }
    this.getOperationalActList();
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, AdditionalCostsAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders() {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('actCategoryName')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actCategoryFilterList)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('description')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actDescriptionFilterList)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('rdvDescription')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.rdvDescriptionFilterList)
        .optionFilterable()
        .sortable()
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getAddTitle(): string {
    return 'MODULE_OPERATIONAL_ACTS_GENERAL_ADD_NEW';
  }

  getUpdateTitle(): string {
    return 'MODULE_OPERATIONAL_ACTS_GENERAL_UPDATE';
  }

  getDeleteMessage(): string {
    return 'MODULE_OPERATIONAL_ACTS_DIALOG_WARNING_SELECTED_REFERENCE_ACT_WILL_BE_DELETED';
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.setItem('trialHhhId', '0');
    this.dataService.changeFromSyntheticCalendar(false);
  }

  getOperationalActList() {
    this.operationalActService.getOperationalActList(false).subscribe(
        res => {
          this.values = res;
          this.updateDropdownOptions(res);
        });
  }

  addOperationalAct() {
    if (this.trialHhhId === 0 || this.trialHhhId === undefined) {
      this.displayFormDialogue();
    } else {
      this.addActToTrial();
    }
  }

  addActToTrial() {
    const operationalActHhhIds = [];
    for (const operationalAct of this.selectedOperationalActList) {
      operationalActHhhIds.push(operationalAct.hhhId);
    }
    if (operationalActHhhIds.length === 0) {
      this.displayNoActSelected('add');
    } else {
      this.trialOperationalActService.addActsToTrial(this.trialHhhId, operationalActHhhIds, [], []).subscribe(() => {
        this.sharedService.showSuccess();
        this.router
            .navigate(['/trial-details/operational-acts'], {queryParams: {hhhId: this.trialHhhId}})
            .then();
      });
    }
  }

  updateSelectedList(selectedList: OperationalAct[]) {
    this.selectedOperationalActList = selectedList;
  }

  displayFormDialogue(operationalAct ?: OperationalAct) {
    const formComponent = OperationalActFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (operationalAct) {
      const operationalActToEdit: OperationalAct = new OperationalAct().init(operationalAct);
      formComponent.dialogReferenceActHeader = this.getUpdateTitle();
      formComponent.operationalAct = operationalActToEdit;
      formComponent.onAdd((newOperationalAct: OperationalAct) => {
        const index = this.values.indexOf(operationalAct);
        if (index !== -1) {
          this.values[index].hhhId = newOperationalAct.hhhId;
          this.values[index].description = newOperationalAct.description;
          this.values[index].actCategoryName = newOperationalAct.actCategoryName;
          this.values[index].actCategoryHhhId = newOperationalAct.actCategoryHhhId;
        }
      });
    } else {
      formComponent.dialogReferenceActHeader = this.getAddTitle();
      formComponent.operationalAct = new OperationalAct();
      formComponent.onAdd((newOperationalAct: OperationalAct) => {
        this.values.push(newOperationalAct);
      });
    }
  }

  openDeleteReferenceActDialog() {
    if (this.selectedOperationalActList.length === 0) {
      this.displayNoActSelected('remove');
    } else {
      this.displayDeleteMultiple();
    }
  }


  displayDeleteMultiple() {
    const formComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    formComponent.service = this.operationalActService;
    formComponent.popupMessage = 'MODULE_OPERATIONAL_ACTS_DIALOG_WARNING_SELECTED_REFERENCE_ACT_WILL_BE_DELETED';
    formComponent.navigationRoute = '/trial-details/operational-acts';
    formComponent.selectedList = this.selectedOperationalActList;
    formComponent.onDelete((deletedItem: number[]) => {
      for (const hhhId of deletedItem) {
        const index = this.values.findIndex((operationalAct: OperationalAct) => operationalAct.hhhId === hhhId);
        this.sharedService.setInformationInLocalStorage('Grille budgétaire: Actes opérationnels', 'Supprimer', this.values[index].description);
        this.values.splice(index, 1);
      }
    });
  }

  displayNoActSelected(type: string) {
    const formComponent = NoSelectedItemFormComponent.displayFormDialogue(this.popupContainer, this.cfr);
    formComponent.text = type === 'remove' ? 'MODULE_REFERENCE_ACTS_DIALOG_ACTS_MUST_BE_SELECTED' : 'MODULE_REFERENCE_ACTS_DIALOG_ACTS_MUST_BE_SELECTED_BEFORE_ADD';
    formComponent.header = 'UI_DIALOG_HEADER_NO_ACT_SELECTED';
  }

  updateDropdownOptions(event) {
    this.operationalActColumns = [];
    for (const operationalAct of event) {
      const descItem = {label: operationalAct.description, value: operationalAct.description};
      const rdvDescItem = {label: operationalAct.rdvDescription, value: operationalAct.rdvDescription};
      const actCategoryItem = {label: operationalAct.actCategoryName, value: operationalAct.actCategoryName};
      const invoiceableItem = {
        label: operationalAct.invoiceable !== null ? this.translateService.getTranslationString(operationalAct.invoiceable.toString()) : '',
        value: operationalAct.invoiceable
      };

      if (operationalAct.actCategoryName !== null &&
          this.actCategoryFilterList.findIndex((item: SelectItem) => item.value === actCategoryItem.value) === -1) {
        this.actCategoryFilterList.push(actCategoryItem);
      }
      if (operationalAct.invoiceable !== null &&
          this.invoiceableFilterList.findIndex((item: SelectItem) => item.value === invoiceableItem.value) === -1) {
        this.invoiceableFilterList.push(invoiceableItem);
      }
      if (operationalAct.description !== null &&
          this.actDescriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
        this.actDescriptionFilterList.push(descItem);
      }
      if (operationalAct.rdvDescription !== null &&
          this.rdvDescriptionFilterList.findIndex((item: SelectItem) => item.value === rdvDescItem.value) === -1) {
        this.rdvDescriptionFilterList.push(rdvDescItem);
      }

    }
    this.actCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.actDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.invoiceableFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.rdvDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }


  exportOperationalActsList(event: any): void {
    const request = new ExportRequestBuilder<void>()
        .condition(false)
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
        .build();
    this.operationalActService.exportOperationalActs(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }

}

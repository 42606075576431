import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Router} from '@angular/router';
import {DataService} from '../../../../shared/services/data-service';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {TrialFixedCost} from '../../../entity/trial-additionnal-costs/trial-fixed-cost';
import {TrialFixedCostService} from '../../../service/trial-fixed-cost.service';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {TranslateService} from '@ngx-translate/core';
import {DeleteMultipleComponent} from '../../../../modals/delete-multiple/delete-multiple.component';
import {TrialFixedCostFormComponent} from '../trial-fixed-cost-form/trial-fixed-cost-form.component';
import {HiddenFields} from '../../../../admin/models/HiddenFields';
import {UsersService} from '../../../../admin/services/users.service';
import {TrialService} from '../../../services/trial.service';
import {FIXED_COST_READ_WRITE} from '../../../../login/services/authentication.service';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicTableHeaderBuilder} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {AppData} from '../../../../helpers/app-data';
import {DataItemService} from '../../../../custom-field/service/data-item.service';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {TrialAdministrativeDatesService} from '../../../services/trial-administrative-dates.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-trial-fixed-costs',
  templateUrl: './trial-fixed-costs.component.html',
  styleUrls: ['./trial-fixed-costs.component.css']
})
export class TrialFixedCostsComponent extends PaginatorTableComponent<TrialFixedCost> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.TrialFixedCost;
  target = DynamicDefinitionEnum.TRIAL_ADDITIONAL_COSTS_FIXED_COST_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  conventionTypeIsRequiredToAddTrialFixedCostEnabled = false;

  trialHhhId: number;
  status: SelectItem[];
  trialFixedCostCheckedList: TrialFixedCost[] = [];
  displayNoSelectedFixedCostDialog: boolean;
  userCanAccessActs = false;

  contractTypeFilterList: SelectItem[] = [];
  typeFilterList: SelectItem[] = [];
  fixedCostCategoryFilterList: SelectItem[] = [];
  attachedToVisitFilterList: SelectItem[] = [];
  invoiceableFilterList: SelectItem[] = [];
  optionalFilterList: SelectItem[] = [];
  annualInvoicingFilterList: SelectItem[] = [];
  descriptionFilterList: SelectItem[] = [];
  freeSSFilterList: SelectItem[] = [];

  contractTypes: SelectItem[] = [];

  constructor(
    private cfr: ComponentFactoryResolver,
    private translateService: InnohealthTranslateService,
    private useService: UsersService,
    private dataService: DataService,
    private translate: TranslateService,
    private trialFixedCostService: TrialFixedCostService,
    private router: Router,
    public trialService: TrialService,
    public trialPageLockService: TrialPageLockService,
    private dynamicConfigService: DynamicConfigService,
    private trialAdministrativeDatesService: TrialAdministrativeDatesService,
    private sharedService: SharedService,
    private dataItemService: DataItemService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  async ngOnInit() {
    super.ngOnInit();
    this.contractTypes = await this.getContractType().then();
    this.userCanAccessActs = ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(FIXED_COST_READ_WRITE)) >= 0;
    this.getTrialFixedCost();
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.conventionTypeIsRequiredToAddTrialFixedCostEnabled = this.dynamicConfigService.getProperty(PropertyEnum.conventionTypeIsRequiredToAddTrialFixedCostEnabled);
    });
    this.initTableConfig();
  }

  getContractType(): Promise<SelectItem[]> {
    return this.dataItemService.getItems('contract-type-selected').toPromise();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('contractType')
      .filterable()
      .optionFilterable()
      .filterType(FilterType.DROPDOWN)
      .filter('contractTypeHhhId')
      .options(this.contractTypes)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('type')
      .filterable()
      .filter('typeHhhId')
      .filterType(FilterType.DROPDOWN)
      .options(this.typeFilterList)
      .sortable()
      .displayContent((trialFixedCost: TrialFixedCost) => trialFixedCost.typeName)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('fixedCostCategory')
      .filterable()
      .optionFilterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.fixedCostCategoryFilterList)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('description')
      .filterable()
      .optionFilterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.descriptionFilterList)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('attachedToVisit')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .optionFilterable()
      .options(this.attachedToVisitFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('annualInvoicing')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.annualInvoicingFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('invoiceable')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.invoiceableFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('internalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('pricePerUnit')
      .accessible(this.useService.canAccessField(HiddenFields.FIXED_COST_PRICE))
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .withDisplayCssClassContent()
      .cssClass((() => {
        return 'pull-right';
      }))
      .displayContent((data: TrialFixedCost) => {
        return (Number(data.pricePerUnit)).toFixed(2) + ' €';
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('unitNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .withDisplayCssClassContent()
      .cssClass((() => {
        return 'pull-right';
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('total')
      .accessible(this.dynamicConfigService.verifyClient(['ccgm', 'col', 'igr']) ? this.useService.canAccessField(HiddenFields.FIXED_COST_PRICE) : true)
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .withDisplayCssClassContent()
      .cssClass((() => {
        return 'pull-right';
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('freeSS')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.freeSSFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('optional')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.optionalFilterList)
      .sortable()
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondInternalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/trial/additional-costs/fixed-costs/fixed-costs.component';
  }

  getUpdateTitle(): string {
    return 'MODULE_FIXED_COSTS_GENERAL_UPDATE';
  }

  getDeleteMessage(): string {
    return 'MODULE_TRIALS_FIXED_COSTS_WARNING_SELECTED_FIXED_COSTS_WILL_BE_DELETED';
  }

  getService(): any {
    return this.trialFixedCostService;
  }

  updateSelectedList(selectedList: any) {
    this.trialFixedCostCheckedList = selectedList;
  }

  getTrialFixedCost(): void {
      this.trialFixedCostService.getTrialFixedCosts(this.trialHhhId).subscribe(
        res => {
          this.values = res;
          this.totalNumber = this.values.length;
          this.trialFixedCostService.trialFixedCostList = this.values;
          this.trialFixedCostService.trialHhhId = this.trialHhhId;
          this.updateDropdownOptions(res);
        });
  }

  updateDropdownOptions(event) {
    this.translate.stream('UI_GLOBAL_PLACEHOLDER_SELECT_FROM_LIST').subscribe(() => {
      this.status = [
        {label: '', value: null},
        {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE'), value: true},
        {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE'), value: false}];


      for (const fixedCostReference of event) {
        const descItem = {label: fixedCostReference.description, value: fixedCostReference.description};
        const contractTypeItem = {label: fixedCostReference.contractType, value: fixedCostReference.contractTypeHhhId};
        const typeItem = {label: fixedCostReference.typeName, value: fixedCostReference.typeHhhId};
        const fixedCostCategoryItem = {
          label: fixedCostReference.fixedCostCategory,
          value: fixedCostReference.fixedCostCategory
        };
        const attachedToVisitItem = {
          label: fixedCostReference.attachedToVisit !== null ? this.translateService.getTranslationString(fixedCostReference.attachedToVisit.toString()) : '',
          value: fixedCostReference.attachedToVisit
        };
        const invoiceableItem = {
          label: fixedCostReference.invoiceable !== null ? this.translateService.getTranslationString(fixedCostReference.invoiceable.toString()) : '',
          value: fixedCostReference.invoiceable
        };
        const optionalItem = {
          label: fixedCostReference.optional !== null ? this.translateService.getTranslationString(fixedCostReference.optional.toString()) : '',
          value: fixedCostReference.optional
        };
        const annualInvoicingItem = {
          label: fixedCostReference.annualInvoicing !== null ? this.translateService.getTranslationString(fixedCostReference.annualInvoicing.toString()) : '',
          value: fixedCostReference.annualInvoicing
        };
        const freeSSItem = {
          label: fixedCostReference.freeSS !== null ? this.translateService.getTranslationString(fixedCostReference.freeSS.toString()) : '',
          value: fixedCostReference.freeSS
        };
        if (fixedCostReference.contractType !== null &&
          this.contractTypeFilterList.findIndex((item: SelectItem) => item.label === contractTypeItem.label) === -1) {
          this.contractTypeFilterList.push(contractTypeItem);
        }
        if (fixedCostReference.typeHhhId !== null &&
          this.typeFilterList.findIndex((item: SelectItem) => item.value === typeItem.value) === -1) {
          this.typeFilterList.push(typeItem);
        }
        if (fixedCostReference.description !== null &&
          this.descriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
          this.descriptionFilterList.push(descItem);
        }
        if (fixedCostReference.actCategory !== null &&
          this.fixedCostCategoryFilterList.findIndex((item: SelectItem) => item.value === fixedCostCategoryItem.value) === -1) {
          this.fixedCostCategoryFilterList.push(fixedCostCategoryItem);
        }
        if (fixedCostReference.attachedToVisit !== null &&
          this.attachedToVisitFilterList.findIndex((item: SelectItem) => item.value === attachedToVisitItem.value) === -1) {
          this.attachedToVisitFilterList.push(attachedToVisitItem);
        }
        if (fixedCostReference.invoiceable !== null &&
          this.invoiceableFilterList.findIndex((item: SelectItem) => item.value === invoiceableItem.value) === -1) {
          this.invoiceableFilterList.push(invoiceableItem);
        }
        if (fixedCostReference.optional !== null &&
          this.optionalFilterList.findIndex((item: SelectItem) => item.value === optionalItem.value) === -1) {
          this.optionalFilterList.push(optionalItem);
        }
        if (fixedCostReference.annualInvoicing !== null &&
          this.annualInvoicingFilterList.findIndex((item: SelectItem) => item.value === annualInvoicingItem.value) === -1) {
          this.annualInvoicingFilterList.push(annualInvoicingItem);
        }
        if (fixedCostReference.freeSS !== null &&
          this.freeSSFilterList.findIndex((item: SelectItem) => item.value === freeSSItem.value) === -1) {
          this.freeSSFilterList.push(freeSSItem);
        }
      }

      this.descriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      this.contractTypeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      this.typeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      this.fixedCostCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      this.attachedToVisitFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      this.invoiceableFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      this.optionalFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      this.annualInvoicingFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      this.freeSSFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    });
  }


  displayFormDialogue(trialFixedCost ?: TrialFixedCost) {
    const formComponent = TrialFixedCostFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (trialFixedCost) {
      formComponent.trialFixedCost = new TrialFixedCost().init(trialFixedCost);
      // formComponent.trialFixedCost = Object.assign({}, trialFixedCost);
      formComponent.formHeader = this.getUpdateTitle();
    }
    formComponent.onAdd((updatedTrialFixedCost: TrialFixedCost) => {
      this.updateList(updatedTrialFixedCost);
    });
  }

  openDeleteFixedCostDialog() {
    if (this.trialFixedCostCheckedList.length === 0) {
      this.displayNoSelectedFixedCostDialog = true;
    } else {
      this.displayDeleteMultiple();
    }
  }

  displayDeleteMultiple() {
    const selectedList = [];
    const formComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    formComponent.service = this.trialFixedCostService;
    formComponent.popupMessage = 'MODULE_TRIALS_FIXED_COSTS_WARNING_SELECTED_FIXED_COSTS_WILL_BE_DELETED';
    for (const item of this.trialFixedCostCheckedList) {
      if (this.values.indexOf(item) > -1) {
        selectedList.push(item);
      }
    }
    formComponent.selectedList = selectedList;
    formComponent.onDelete((deletedItem: number[]) => {
      for (const hhhId of deletedItem) {
        const index = this.values.findIndex((trialFixedCost: TrialFixedCost) => trialFixedCost.hhhId === hhhId);
        this.values.splice(index, 1);
      }
    });
  }

  navigateToFixedCosts() {
    if (this.conventionTypeIsRequiredToAddTrialFixedCostEnabled) {
      this.trialAdministrativeDatesService.hasConventionTypes(this.trialHhhId).subscribe(hasConventionTypes => {
        if (!hasConventionTypes) {
          this.sharedService.showFailure('MODULE_TRIALS_ACTS_GRID_CONVENTION_TYPE_IS_REQUIRED_TO_ADD_ELEMENT');
          return;
        }
        this.navigate();
      });
    } else {
      this.navigate();
    }
  }

  private navigate(): void {
    this.dataService.changeFromModule(this.trialHhhId);
    this.router.navigate(
      ['/additional-costs-container/fixed-cost-reference'],
      {queryParams: {fixedCostSelected: true}}
    ).then();
  }

  updateList(trialFixedCost ?: TrialFixedCost) {
    const index = this.values.findIndex((trialFixedCost1: TrialFixedCost) => trialFixedCost.hhhId === trialFixedCost1.hhhId);
    this.values[index] = trialFixedCost;
  }

  exportTrialFixedCosts(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.trialHhhId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .build();

    this.trialFixedCostService.exportTrialFixedCosts(request).subscribe(
        (res) => {},
        (err) => {
          console.error(err);
        });
  }

}

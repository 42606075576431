import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {TrialInclusion} from '../../../entity/inclusion/trial-inclusion';
import {LazyLoadEvent, SelectItem} from 'primeng/api';
import {TrialService} from '../../../services/trial.service';
import {InclusionService} from '../../../service/inclusion.service';
import {NavigationStart, Router} from '@angular/router';
import {DataService} from '../../../../shared/services/data-service';
import {InclusionFormComponent} from '../../../../modals/inclusion-form/inclusion-form.component';
import {ImportPatientFormComponent} from '../import-patient-form/import-patient-form.component';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {TableHeader} from '../../../../shared/entity/table-header';
import {TrialHead} from '../../../models/trial-head';
import {TrialInclusionGlobalFilter} from '../../../entity/inclusion/trial-inclusion-global-filter';
import {RollbackService} from '../../../../shared/services/rollback.service';
import {PatientFormComponent} from '../../../../patient/patient-form/patient-form.component';
import {SharedService} from '../../../../shared/services/shared.service';
import {PatientInclusion} from '../../../entity/inclusion/patient-inclusion';
import {Data} from '../../../../shared/entity/data';
import {InclusionDetailService} from '../../../service/inclusion-detail.service';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {UtilsService} from '../../../../shared/services/utils.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {
  DynamicTableHeaderBuilder
} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DatePipe} from '@angular/common';
import {FR} from '../../../../shared/entity/calendar-language';
import {AppData} from '../../../../helpers/app-data';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {isNullOrUndefined} from 'util';
import {OrganService} from '../../../services/organ.service';

@Component({
  selector: 'ih-inclusion-list',
  templateUrl: './inclusion-list.component.html',
  styleUrls: ['./inclusion-list.component.css']
})
export class InclusionListComponent extends PaginatorTableComponent<TrialInclusion> implements OnInit, OnDestroy, AfterViewInit {

  _entity = AttachedToEntityEnum.TrialInclusion;
  readonly target = DynamicDefinitionEnum.TRIAL_INCLUSION_LIST_TABLE;
  config: TableConfig;
  effectiveHeaders: DynamicTableHeader[] = [];
  possibleHeaders: DynamicTableHeader[] = [];
  amendmentsEnabled = false;
  isInclusionInformationArmEnabled = false;
  isDesignEnabled = false;
  hasPrivatePostIt = true;

  @ViewChild('importPatientsDialog', {read: ViewContainerRef}) importPopupContainer: ViewContainerRef;
  trialHhhId: number;
  filters: TrialInclusionGlobalFilter;
  isEstablishmentParent = false;
  isTrialHasNotMolecularPreScreening: boolean;
  userCanAddInclusion = false;
  allSelected: boolean;
  first = 0;
  filterType = FilterType;
  locale = FR;
  paginationOptions = Data.paginationOptions;
  headersReady = false;
  organsList: SelectItem[] = [];

  constructor(
    public trialService: TrialService,
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    private trialInclusionService: InclusionService,
    private inclusionService: InclusionService,
    private translateService: InnohealthTranslateService,
    private router: Router,
    private sharedService: SharedService,
    private rollbackService: RollbackService<TrialInclusionGlobalFilter>,
    private inclusionDetailService: InclusionDetailService,
    private utilsService: UtilsService,
    private datePipe: DatePipe,
    private dynamicConfigService: DynamicConfigService,
    private organService: OrganService,
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    this.trialHhhId = url.queryParams.hhhId;
    router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.rollbackService.isRollBack = true;
          this.filters = this.rollbackService.getGlobalFilter('trialInclusionGlobalFilter');

        }
      });
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.dataService.changeInclusionMenu('information-calendar-patientTracking');
    this.inclusionService.isUserCanAddInclusion(this.trialHhhId).subscribe((res: boolean) => {
      this.userCanAddInclusion = res;
    });
    this.inclusionDetailService.getTrialHasMolecularPreScreening(this.trialHhhId).subscribe(
      res => {
        if (res === false) {
          this.isTrialHasNotMolecularPreScreening = true;
        }
      }
    );
    this.isEstablishmentParent = this.dynamicConfigService.parent;
    if (this.rollbackService.isRollBack && (this.rollbackService.getGlobalFilter('trialInclusionGlobalFilter') !== null)) {
      this.filters = this.rollbackService.getGlobalFilter('trialInclusionGlobalFilter');
      this.rollbackService.isRollBack = false;
    } else {
      this.filters = new TrialInclusionGlobalFilter();
    }
    if (this.filters == null) {
      this.filters = new TrialInclusionGlobalFilter();
    }
    this.trialService.trialHhhId = this.trialHhhId;
    this.inclusionService.trialInclusionHHhId = this.trialHhhId;
    this.getOrganList();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.isInclusionInformationArmEnabled = this.dynamicConfigService.getProperty(PropertyEnum.inclusionInformationArmEnabled);
      this.isDesignEnabled = this.dynamicConfigService.getProperty(PropertyEnum.designEnabled);
      this.hasPrivatePostIt = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled);
    });
  }

  initTableConfig(multiSite: boolean): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders(multiSite);
      }
    );
  }

  ngAfterViewInit() {
    this.trialService.getTrialHead(this.trialHhhId).subscribe((res: TrialHead) => {
      this.initTableConfig(res.multiSite);
    });  }

  buildTableHeaders(multiSite: boolean): void {
    if (this.headersReady) {
      return;
    }
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientInternalIdentifier')
      .filterable()
      .filter(this.filters.patientInternalIdentifier)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('patientInternalIdentifier')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientBirthName')
      .filterable()
      .filter(this.dynamicConfigService.verifyClient(['col', 'ccgm']) ?
        this.filters.patientLastName : this.filters.patientBirthName)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField(this.dynamicConfigService.verifyClient(['col', 'ccgm']) ? 'patient.lastName' : 'patientBirthName')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientLastName')
      .filterable()
      .filter(this.dynamicConfigService.verifyClient(['col', 'ccgm']) ?
        this.filters.patientBirthName : this.filters.patientLastName)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField(this.dynamicConfigService.verifyClient(['col', 'ccgm']) ? 'patient.birthName' : 'patientLastName')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientFirstName')
      .filterable()
      .filter(this.filters.patientFirstName)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('patientFirstName')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientBirthDate')
      .filterable()
      .filter(this.filters.patientBirthDate)
      .filterType(FilterType.CALENDAR)
      .sortable()
      .sortField('patientBirthDate')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientSex')
      .filterable()
      .filter(this.filters.patientSex)
      .filterType(this.dynamicConfigService.verifyClient(['col']) ? FilterType.DROPDOWN : FilterType.INPUT_TEXT)
      .options(this.dynamicConfigService.verifyClient(['col']) ? Data.genderWithNa : undefined)
      .sortable()
      .sortField('patientSex')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionIdentifier1')
      .filterable()
      .filter(this.filters.inclusionIdentifier1)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('referrerInvestigator')
      .filterable()
      .filter(this.filters.referrerInvestigator)
      .filterType(FilterType.IH_DROPDOWN)
      .type('investigator')
      .sortable()
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('arm')
      .filterable()
      .filter(this.filters.arm)
      .filterType(FilterType.IH_DROPDOWN)
      .type(this.amendmentsEnabled ? ('arm-having-theoretical-calendar|' + this.trialHhhId) : ('trial-arm|' + this.trialHhhId))
      .sortable()
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('state')
      .filterable()
      .filter(this.filters.state)
      .filterType(FilterType.IH_DROPDOWN)
      .type('inclusion-state')
      .sortable()
      .withDisplayCssClassContent()
      .cssClass((data: TrialInclusion) => {
        return this.utilsService.stateMap.get(data.state);
      })
      .dynamicCssClass((data: TrialInclusion) => {
        return data.manual ? 'manual-state' : '';
      })
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(!this.isTrialHasNotMolecularPreScreening)
      .field('inclusionPreScreeningIdentifier')
      .filterable()
      .filter(this.filters.inclusionPreScreeningIdentifier)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(multiSite)
      .field('site')
      .filterable()
      .filter(this.filters.siteHhhId)
      .filterType(FilterType.IH_DROPDOWN)
      .type('site|' + this.trialHhhId)
      .sortable()
      .sortField('site')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionDate')
      .sortable()
      .filterable()
      .filter(this.filters.inclusionDate)
      .filterType(FilterType.CALENDAR)
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionIdentifier2')
      .filterable()
      .filter(this.filters.inclusionIdentifier2)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(!this.isTrialHasNotMolecularPreScreening)
      .field('preScreeningSignatoryDoctor')
      .filterable()
      .filter(this.filters.preScreeningSignatoryDoctor)
      .filterType(FilterType.IH_DROPDOWN)
      .type('trial-included-doctor|' + this.trialHhhId)
      .sortable()
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('tecIncluded')
      .filterable()
      .filter(this.filters.tecIncluded)
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('tecIncluded')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(!this.isTrialHasNotMolecularPreScreening)
      .field('preScreeningDate')
      .filterable()
      .filter(this.filters.preScreeningDate)
      .filterType(FilterType.CALENDAR)
      .sortable()
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialTreatmentEndDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.trialTreatmentEndDate)
      .sortable()
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('followupEndDate')
      .sortable()
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('lastRealizedVisitDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.lastRealizedVisitDate)
      .sortable()
      .checked()
      .build());
    this.effectiveHeaders = this.config.buildTable(availableHeaders);

    const possibleHeaders = [];
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('blocRequestDate')
      .sortable()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('resultDate')
      .sortable()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('initialConsentSignatureDate')
      .sortable()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('initialConsent')
      .sortable()
      .sortField('initialConsent')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('preScreeningConsentSignatureDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .sortable()
      .sortField('preScreeningConsentSignatureDate')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('organList')
      .sortable()
      .sortField('organList')
      .filterable()
      .filterType(FilterType.IH_MULTISELECT)
      .filter(this.filters.organList)
      .options(this.organsList)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('preScreeningConsent')
      .sortable()
      .sortField('preScreeningConsent')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.filters.preScreeningConsent)
      .type(`consent|${this.trialHhhId}`)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('caseManagement')
      .sortable()
      .sortField('caseManagement')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.filters.caseManagement)
      .type('trial-committee-backup-selected')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('result')
      .sortable()
      .sortField('result')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.result)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('preScreeningFailure')
      .sortable()
      .sortField('preScreeningFailureDate')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .options(Data.yesOrNo)
      .filter(this.filters.preScreeningFailure)
      .isTranslated()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('preScreeningFailureDate')
      .sortable()
      .sortField('preScreeningFailureDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.preScreeningFailureDate)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('preScreeningFailureReason')
      .sortable()
      .sortField('preScreeningFailureReason')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.preScreeningFailureReason)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialTreatmentEndReason')
      .sortable()
      .sortField('trialTreatmentEndReason')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.filters.trialTreatmentEndReason)
      .type('trial-treatment-end-reason-selected')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionTrialEndReason')
      .sortable()
      .sortField('inclusionTrialEndReason')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.filters.inclusionTrialEndReason)
      .type('inclusion-trial-end-reason-selected')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('screeningFailure')
      .sortable()
      .sortField('screeningFailure')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .options(Data.yesOrNo)
      .filter(this.filters.screeningFailure)
      .isTranslated()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('screenFailureDate')
      .sortable()
      .sortField('screenFailureDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.screenFailureDate)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('screeningFailureCause')
      .sortable()
      .sortField('screeningFailureCause')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('screening-failure-cause')
      .filter(this.filters.screeningFailureCause)
      .build());

    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('randomizationDate')
      .sortable()
      .sortField('randomizationDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.randomizationDate)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionRandomizationIdentifier')
      .sortable()
      .sortField('inclusionRandomizationIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.inclusionRandomizationIdentifier)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialTreatmentStartDate')
      .sortable()
      .sortField('trialTreatmentStartDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.trialTreatmentStartDate)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('otherConsentDates')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('hasCrossoverDate')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .options(Data.yesOrNo)
      .filter(this.filters.hasCrossoverDate)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('dispatchBlockDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.dispatchBlockDate)
      .sortable()
      .sortField('dispatchBlockDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionTrialEndDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.inclusionTrialEndDate)
      .sortable()
      .sortField('inclusionTrialEndDate')
      .withDateFormatter()
      .build());
    this.possibleHeaders = this.config.buildTable(possibleHeaders);
    this.headersReady = true;

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.rollbackService.isRollBack = false;
  }

  getDeleteMessage(): string {
    return 'MODULE_TRIALS_PATIENTS_DIALOG_WARNING_PATIENT_TRIAL_INCLUSION_WILL_BE_DELETED';
  }

  getService(): any {
    return this.inclusionService;
  }

  getDefaultSortField(): string {
    return 'inclusionDate';
  }

  getSortedList(event: LazyLoadEvent) {
    this.sortBy = event.sortField === undefined ? this.getDefaultSortField() : event.sortField;
    this.orderBy = event.sortOrder === 1 ? 'desc' : 'asc';
    this.page = event.first / event.rows;
    this.limit = event.rows;
    this.getList();
  }

  getFilteredList(filters?: any) {
    this.page = 0;
    this.filters = TableHeader.buildFilterData(this.effectiveHeaders);
    this.rollbackService.saveGlobalFilter('trialInclusionGlobalFilter', this.filters);
    this.getList();
  }

  getList() {
    this.trialInclusionService.getTrialInclusions(this.trialHhhId, this.page, this.limit, this.sortBy, this.orderBy, this.filters).subscribe((res) => {
      this.values = res.content;
      this.totalNumber = res.totalElements;
      this.inclusionService.setPaginationConfiguration(res, this.limit, this.sortBy, this.orderBy, this.filters);
    });
  }

  displayFormDialogue() {
    const inclusionFormComponent = InclusionFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    inclusionFormComponent.onAdd((nip) => {
      this.displayAddNewPatientFormDialogue(nip);
    });
  }

  displayDeleteDialogue(trialInclusion: TrialInclusion) {
    this.displayDeleteDialogueFromInfos(trialInclusion.hhhId, trialInclusion.patientFirstName + ' ' + trialInclusion.patientLastName, trialInclusion);
  }

  refreshAfterDelete(entity?: any): void {
    const index = this.values.findIndex((trialInclusion: TrialInclusion) => trialInclusion.hhhId === entity.hhhId);
    this.values.splice(index, 1);
    this.totalNumber--;
  }

  displayImportPatientsDialogue() {
    const importPatientPopupFactory = this.cfr.resolveComponentFactory(ImportPatientFormComponent);
    const importPatientPopupComponentRef = this.importPopupContainer.createComponent(importPatientPopupFactory);
    const importPatientFormComponent = importPatientPopupComponentRef.instance;
    importPatientFormComponent.onImport(() => this.getList());
  }

  edit(inclusion: any) {
    localStorage.setItem('patientName', inclusion.patientFirstName + ' ' + inclusion.patientLastName);
    this.navigateToInclusionInformationComponent(inclusion.hhhId);
  }

  updateList() {
    return (inclusion: TrialInclusion) => {
      this.values.push(inclusion);
    };
  }

  displayAddNewPatientFormDialogue(nip: string) {
    const formComponent = PatientFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.formHeader = 'MODULE_PATIENTS_GENERAL_ADD_NEW';
    formComponent.patient.internalIdentifier = nip;
    formComponent.onAdd((p) => {
      this.inclusionService.isPatientInTrial(this.trialHhhId, p.hhhId).subscribe((res: boolean) => {
        if (!res) {
          const patientInclusion: PatientInclusion = {patientHhhId: p.hhhId, trialHhhId: this.trialHhhId};
          this.inclusionService.addInclusion(patientInclusion).subscribe((hhhId: number) => {
            this.navigateToInclusionInformationComponent(hhhId);
          }, error => {
            this.sharedService.showFailure();
          });
        }
      });
    });
  }


  getBtnClass(): string {
    return this.config.getBtnClass();
  }

  displayBtn(btnId: string): boolean {
    if (!this.config || !this.config.buttons) {
      return false;
    }
    return this.config.displayBtn(btnId);
  }

  getRows(): number {
    return this.limit.toString() === 'Tout' ? this.totalNumber : this.limit;
  }

  displayContent(col: DynamicTableHeader, colField: any, rowData: any): string {
    let content = colField;
    if (isNullOrUndefined(rowData[col.field]) && !col.displayContent) {
      content = '';
    } else if (col.dateFormatter) {
      content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
    } else if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (col.isTranslated) {
      content = colField ?
        this.translateService.getTranslationString('UI_DIALOG_BUTTON_YES')
        : this.translateService.getTranslationString('UI_DIALOG_BUTTON_NO');
    }
    return content;
  }

  getDataLimited(value: string) {
    if (value === 'Tout') {
      this.limit = this.totalNumber;
    } else {
      this.limit = +value;
    }
    this.getLimitedList(this.limit);
  }

  filterUpdated() {
    const filterValues = [];
    for (const column of this.effectiveHeaders) {
      if (column.filterable) {
        filterValues[column.field] = column.filter;
      }
    }
    this.getFilteredList(filterValues);
  }

  saveColumnOrder() {
    localStorage.setItem(this.target, JSON.stringify(this.effectiveHeaders));
  }

  public getOrderedColumns(target: string): string[] {
    return JSON.parse(localStorage.getItem(target)).map(header => header.field);
  }

  public navigateToInclusionInformationComponent(inclusionHhhId: number) {
    if (this.amendmentsEnabled && !this.isInclusionInformationArmEnabled) {
      this.router.navigate(['/trial-details/inclusion-details/inclusion-information-version-protocol'], {
        queryParams: {
          hhhId: this.trialHhhId,
          inclusionId: inclusionHhhId
        }
      }).then();
    } else {
      this.router.navigate(['/trial-details/inclusion-details/inclusion-information-arm'], {
        queryParams: {
          hhhId: this.trialHhhId,
          inclusionId: inclusionHhhId
        }
      }).then();
    }
  }

  private getOrganList() {
    this.organService.getTrialOrgansAndOrganFamilies(this.trialHhhId).subscribe(
      res => {
        this.organsList = res.map(item => ({label: item.label, value: +item.value}));
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading organ list.' + error);
      }
    );
  }

  exportInclusionList(event: any) {
    const request = new ExportRequestBuilder<TrialInclusionGlobalFilter>()
      .objectId(this.trialHhhId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.getOrderedColumns(this.target), this.limit, this.page)
      .sortBy(this.sortBy)
      .orderBy(this.orderBy)
      .filters(this.filters)
      .build();
    this.trialInclusionService.exportInclusions(request).subscribe();
  }


}

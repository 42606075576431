import {Component} from '@angular/core';

@Component({
  selector: 'ih-trial-amendment-icon',
  templateUrl: './trial-amendment-icon.component.html',
  styleUrls: ['./trial-amendment-icon.component.css']
})
export class TrialAmendmentIconComponent {

  constructor() { }

}

import {Component, OnInit} from '@angular/core';
import {AboutService} from '../services/about.service';
import {SharedService} from '../../shared/services/shared.service';
import {MobileStaticData} from '../models/mobile-static-data';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  about: MobileStaticData = new MobileStaticData();
  isFrenchText = true;

  constructor(private aboutService: AboutService,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    this.getAbout();
  }

  submitAbout() {
    this.aboutService.editAbout(this.about).subscribe(res => {
      this.about = res;
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
    });
  }

  private getAbout() {
    this.aboutService.getAbout().subscribe(res => {
      if (res !== null) {
        this.about = res;
      }
    });
  }
}

import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {TrialFixedCost} from '../../../entity/trial-additionnal-costs/trial-fixed-cost';
import {SelectItem} from 'primeng/api';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {NgForm} from '@angular/forms';
import {TrialFixedCostService} from '../../../service/trial-fixed-cost.service';
import {Router} from '@angular/router';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {SharedService} from '../../../../shared/services/shared.service';
import {Data} from '../../../../shared/entity/data';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {TrialAppData} from '../../../trial-app-data';

@Component({
  selector: 'ih-trial-fixed-cost-form',
  templateUrl: './trial-fixed-cost-form.component.html',
  styleUrls: ['./trial-fixed-cost-form.component.css']
})
export class TrialFixedCostFormComponent extends FormComponent<TrialFixedCost> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_FIXED_COST_FORM;
  config: FormConfig = new FormConfig();
  trialSecondUfEnabled = false;
  optionalEnabled = false;

  @ViewChild('trialFixedCostReferenceForm') trialFixedCostReferenceForm: NgForm;

  trialFixedCost: TrialFixedCost;
  submitted: boolean;
  status: SelectItem[];

  // contractTypes: SelectItem[] = [];
  noYesOptions = Data.yesOrNoOrNeither;
  other: SelectItem = {value: 'OTHER', label: 'Autres'};
  yesOrNoOptions = Data.yesOrNo;

  constructor(
    private translateService: InnohealthTranslateService,
    private trialFixedCostService: TrialFixedCostService,
    private sharedService: SharedService,
    private router: Router,
    private dynamicConfigService: DynamicConfigService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(TrialFixedCostFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.status = [
      {label: '', value: null},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE'), value: true},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE'), value: false}];
    this.cdr.detectChanges();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.trialSecondUfEnabled = this.dynamicConfigService.getProperty(PropertyEnum.trialSecondUfEnabled);
      this.optionalEnabled = this.dynamicConfigService.getProperty(PropertyEnum.optionalEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  onSubmit() {
    this.submitted = true;
    if (this.trialFixedCostReferenceForm.invalid || !this.trialFixedCost.isValid(this.config)) {
      return;
    } else {
      this.trialFixedCostService.editTrialFixedCost(this.trialFixedCost).subscribe(res => {
          this.trialFixedCost.total = res.total;
          if (this.trialFixedCost.hhhId) {
            this.callback(this.trialFixedCost);
          } else {
            this.callback(res);
          }
          this.sharedService.showSuccess();
        }, error => {
          console.error('An error occurred while saving fixed cost reference' + error);
        }
      );
      this.display = false;
    }
  }

  navigateToFixedCostReference() {
    this.router.navigate(['/additional-costs-container/fixed-cost-reference']).then();
  }

}


import {Component, Inject, InjectionToken, OnInit, ViewChild} from '@angular/core';
import {Message} from 'primeng/api';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {AuthenticationService} from '../services/authentication.service';
import {User} from '../../admin/models/user';
import {NgForm} from '@angular/forms';
import {UsersService} from "../../admin/services/users.service";

export const LOCATION_TOKEN = new InjectionToken<Location>('Window location object');

@Component({
  providers: [
    {provide: LOCATION_TOKEN, useValue: window.location}
  ],
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  submitted: boolean;
  resultMessage: Message[] = [];
  step1 = true;
  step2 = false;
  user: User = new User();
  @ViewChild('changePasswordForm') changePasswordForm: NgForm;
  passwordChanged: boolean;
  passwordScore = 0;
  isPasswordHidden: boolean = true;
  isPassword2Hidden: boolean = true;
  isPasswordContainsUppercase = false;
  isPasswordContainsLowercase = false;
  isPasswordContainsNumeric = false;
  isPasswordContainsSpecialCharacter = false;
  isPasswordLengthValid = false;

  constructor(
    private translateService: InnohealthTranslateService,
    private authenticationService: AuthenticationService,
    private userService: UsersService,
    @Inject(LOCATION_TOKEN) private location: Location,
  ) {
  }

  ngOnInit() {
  }

  abortProcess() {
    this.location.assign('/login');
  }

  displayMessage(type: string, summaryKey: string, messageKey: string) {
    this.initResultMessage();
    this.resultMessage.push({
      severity: type,
      summary: this.translateService.getTranslationString(summaryKey),
      detail: this.translateService.getTranslationString(messageKey)
    });
  }

  verifyCode() {
    this.authenticationService.reinitializePassword(this.user.userName, this.user.resetPasswordToken).subscribe(isUserVerified => {
      if (isUserVerified) {
        this.initResultMessage();
        this.step1 = false;
        this.step2 = true;
      } else {
        this.displayMessage('error', 'GENERAL_LINGUISTICS_FAILURE_UFCASE', 'UI_GLOBAL_REINITIALIZE_MY_PASSWORD_ERROR');
      }
    });
  }

  updatePassword() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    if (!this.isPasswordValid()) {
      return;
    }
    if (this.user.newPassword2.includes(this.user.newPassword1)) {
      this.authenticationService.updatePassword(this.user).subscribe(isPasswordChanged => {
          this.passwordChanged = isPasswordChanged;
          if (isPasswordChanged) {
            this.displayMessage('success', 'GENERAL_LINGUISTICS_SUCCESS_UFCASE', 'GENERAL_LINGUISTICS_PASSWORD_CHANGED_SUCCESSFULLY');
          } else {
            this.displayMessage('error', 'GENERAL_LINGUISTICS_FAILURE_UFCASE', 'GENERAL_LINGUISTICS_PASSWORD_CHANGED_FAILED');
          }
        }
      );
    }
  }

  private initResultMessage() {
    this.resultMessage = [];
  }

  goToLogin() {
    this.location.assign('/#/login');
  }


  passwordUpdated() {
    this.passwordScore = this.userService.calculatePasswordStrength(this.user.newPassword1);
    this.isPasswordContainsUppercase = this.userService.isPasswordContainsUppercase(this.user.newPassword1);
    this.isPasswordContainsLowercase = this.userService.isPasswordContainsLowercase(this.user.newPassword1);
    this.isPasswordContainsNumeric = this.userService.isPasswordContainsNumeric(this.user.newPassword1);
    this.isPasswordContainsSpecialCharacter = this.userService.isPasswordContainsSpecialCharacter(this.user.newPassword1);
    this.isPasswordLengthValid = this.userService.isPasswordLengthValid(this.user.newPassword1);
  }

  private isPasswordValid() {
    return this.user.newPassword1 && this.user.newPassword2 && this.user.newPassword1 === this.user.newPassword2 && this.passwordScore === 100;
  }

  goBack() {
    this.location.assign('/#/login?forgetPasswordSelected=true');
  }

  togglePassword() {
    this.isPasswordHidden = !this.isPasswordHidden;

  }

  togglePassword2() {
    this.isPassword2Hidden = !this.isPassword2Hidden;

  }
}

import {TableHeaderConfig} from '../../entity/table-header-config';
import {FilterType} from './filter-type';
import {InputType} from '../../../input-type';

export class DynamicTableHeader {
  accessible = true;
  display = false;
  header: string;
  width: string;
  filterable = false;
  sortable = false;
  importe = false;
  export = false;
  field: string;
  filter?: any;
  filterValue: any;
  filterType?: FilterType;
  options?: any;
  optionFilterable?: boolean;
  type?: string;
  composedFilter?: any[];
  linkClickEvent?: any;
  isTranslated = false;
  isYesNoEnumTranslated = false;
  dateFormatter = false;
  cssClass?: any;
  sortField?: string;
  displayContent?: any;
  displayCssClassContent = false;
  dynamicCssClass?: any;
  filterClass?: string;
  currency = false;
  justifyContent: string  ;
  filterMatchType: 'contains' | 'equals'; // filter match condition

  // Trial
  inputType: string = InputType.input;
  checked = false;

  // Theoretical Calendar
  editable = false;
  hideField?: any;
  onChange: any;
  onEdit: any;

  static buildFilterData(headers: DynamicTableHeader[]): any {
    const filterValues = [];
    for (const column of headers) {
      if (column.filterable && column.filter) {
        filterValues[column.field] = column.filterValue;
      } else if (column.filterable && !column.filter) {
        filterValues[column.field] = column.filterValue;
      }
    }
    return Object.assign({}, filterValues);
  }

  constructor() {
  }

  updateConfig(config: TableHeaderConfig): DynamicTableHeader {
    this.display = this.accessible && config.display;
    this.header = config.header;
    this.width = config.width;
    this.filterable = this.filterable && config.filterable;
    this.sortable = this.sortable && config.sortable;
    this.importe = config.importe;
    this.export = config.export;
    return this;
  }

}

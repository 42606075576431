import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {Util} from '../../../helpers/util';
import {DynamicModuleDefinition} from '../../entity/dynamic-module-definition';
import {SelectItem} from 'primeng/components/common/api';
import {DynamicModuleDefinitionProperty} from '../../entity/dynamic-module-definition-property';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'ih-module-config-editor',
  templateUrl: './module-config-editor.component.html',
  styleUrls: ['./module-config-editor.component.css']
})
export class ModuleConfigEditorComponent implements OnInit {

  @Input() name: string;
  @Input() globalConfig = false;
  _target: string;

  moduleDefinition: DynamicModuleDefinition = new DynamicModuleDefinition();
  dbModuleDefinition: DynamicModuleDefinition;
  cols: any[] = [];

  checked = false;
  propertyFilter = '';
  descriptionFilter = '';
  options: SelectItem[] = [
    {label: 'True', value: true},
    {label: 'False', value: false}
  ];

  constructor(
    private dynamicConfigService: DynamicConfigService,
    private _cdr: ChangeDetectorRef,
    private sharedService : SharedService
  ) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'name', header: 'Configuration', width: '20%'},
      {field: 'configValue', header: 'Activé', width: '20%', type: 'input'},
      {field: 'description', header: 'Description', width: '40%'}
    ];
  }

  @Input()
  set target(v: string) {
    this._target = v;
    this.getModuleDefinition();
  }

  get target(): string {
    return this._target;
  }


  getModuleDefinition(): void {
    this.dynamicConfigService.getAdminConsoleModuleConfig(this._target).subscribe(
      module => {
        this.moduleDefinition = Util.clone(module);
        this.dbModuleDefinition = Util.clone(module);
        this._cdr.detectChanges();
      }, error => console.log(error.message)
    );
  }

  getProperty(property: string): any {
    if (!this.moduleDefinition || !this.moduleDefinition.header) {
      return;
    }
    switch (property) {
      case 'title':
        return this.moduleDefinition.header.config.title ? this.moduleDefinition.header.config.title : this.moduleDefinition.header.defaultTitle;
        break;
    }

  }

  dataChanged(): boolean {
    return !Util.equal(this.dbModuleDefinition, this.moduleDefinition);
  }

  save(): void {
    this.dynamicConfigService.saveModuleConfig(this.moduleDefinition).subscribe(
      module => {
        this.moduleDefinition = Util.clone(module);
        this.dbModuleDefinition = Util.clone(module);
        this.sharedService.showSuccess();
        this._cdr.detectChanges();
      }, error => console.log(error.message)
    );
  }

  reset(): void {
    this.moduleDefinition = Util.clone(this.dbModuleDefinition);
  }

  showHeaderEditor(): boolean {
    if (this.globalConfig) {
      return false;
    }
    if (this.moduleDefinition) {
      return !Util.isStringNullOrEmpty(this.moduleDefinition.header.defaultTitle);
    }
  }


  getPlaceholder(field: string, property?: DynamicModuleDefinitionProperty): string {
    if (field === 'Title' && Util.isStringNullOrEmpty(this.moduleDefinition.header.config.title)) {
      return this.moduleDefinition.header.defaultTitle;
    }
    if (field === 'Property' && Util.isStringNullOrEmpty(property.configValue)) {
      return property.defaultValue;
    }
    return 'Default ' + field;
  }

  filter(column: string): void {
    if (column === 'name') {
      if (Util.isStringNullOrEmpty(this.propertyFilter)) {
        this.moduleDefinition.properties.forEach(p => p.color = '');
        return;
      }
      this.moduleDefinition.properties.forEach(p => {
        if (!Util.isStringNullOrEmpty(this.propertyFilter)) {
          p.color = (p.name).toLowerCase().includes(this.propertyFilter.toLowerCase()) ? '#D6EAF5' : '';
        }
      });
    } else if (column === 'description') {
      if (Util.isStringNullOrEmpty(this.descriptionFilter)) {
        this.moduleDefinition.properties.forEach(p => p.color = '');
        return;
      }
      this.moduleDefinition.properties.forEach(p => {
        if (!Util.isStringNullOrEmpty(this.descriptionFilter)) {
          p.color = (p.description).toLowerCase().includes(this.descriptionFilter.toLowerCase()) ? '#D6EAF5' : '';
        }
      });
    }
  }

}

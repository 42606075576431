export class CalendarCheckbox {
  public date: Date;
  public checked: boolean;

  constructor(date?: Date, checked?: boolean) {
    this.date = date;
    this.checked = checked;
  }

}

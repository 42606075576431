import {Component, Input} from '@angular/core';

@Component({
  selector: 'ih-trial-personnel-icon',
  templateUrl: './trial-personnel-icon.component.html',
  styleUrls: ['./trial-personnel-icon.component.css']
})
export class TrialPersonnelIconComponent {

  @Input() title: string;

  constructor() { }


}

import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('DataComment')
export class DataComment {

  @JsonProperty('hhhId', Number)
  hhhId: number = undefined;

  @JsonProperty('attachedTo', String)
  attachedTo: string = undefined;

  @JsonProperty('attachedToId', Number)
  attachedToId: number = undefined;

  @JsonProperty('attachedToField', String, true)
  attachedToField: string = undefined;

  @JsonProperty('content', String)
  content: string = undefined;

  @JsonProperty('ownerHhhId', Number)
  ownerHhhId: number = undefined;

  @JsonProperty('ownerName', Number)
  ownerName: number = undefined;

  @JsonProperty('creationDate', Date)
  creationDate: Date = undefined;

  @JsonProperty('privateComment', Boolean)
  privateComment: boolean = undefined;

  @JsonProperty('deletable', Boolean)
  deletable: boolean = undefined;


  constructor() {
  }

}

export class DataCommentInfo {
  attachedTo: string;
  attachedToId: number;
  attachedToField: string;
  isPrivate: boolean;

  constructor(attachedTo: string, attachedToId: number, attachedToField: string, isPrivate: boolean) {
    this.attachedTo = attachedTo;
    this.attachedToId = attachedToId;
    this.attachedToField = attachedToField;
    this.isPrivate = isPrivate;
  }
}

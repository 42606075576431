import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {EstablishmentService} from '../services/establishment.service';
import {DataService} from '../../shared/services/data-service';
import {Router} from '@angular/router';
import {ContactListComponent} from '../../dynamic-config/exported/contact-list/contact-list.component';
import {Establishment} from '../models/establishment';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'ih-my-establishment',
  templateUrl: './my-establishment.component.html',
  styleUrls: ['./my-establishment.component.css']
})
export class MyEstablishmentComponent implements OnInit {
  @ViewChild('addContactComponent', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  establishment: Establishment;
  establishmentHhhId: number;
  parent: boolean;
  public items: MenuItem[];

  constructor(
    private establishmentService: EstablishmentService,
    private dataService: DataService,
    private cfr: ComponentFactoryResolver,
    private router: Router,
  ) {
  }

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    this.establishmentHhhId = url.queryParams && url.queryParams.hhhId !== undefined ? url.queryParams.hhhId : -1;
    this.establishmentService.getEstablishment(this.establishmentHhhId).subscribe(res => {
      this.establishment = res;
      this.establishmentService.setEstablishment(res.hhhId);
      this.establishment.services.sort((a, b) => a.name.localeCompare(b.name));
      this.getContacts();
    });
  }


  createContactListComponent() {
    const addDialogFactory = this.cfr.resolveComponentFactory(ContactListComponent);
    this.dialogContainer.createComponent(addDialogFactory);
  }

  getContacts() {
    this.dataService.setContactData('Contact', 'Establishment', null, this.establishmentHhhId);
    this.createContactListComponent();
  }


}

import {VisitState} from '../inclusion/visit-state';

export class VisitTracking {
  public hhhId: number;
  public publicCommentCount: number;
  public visitName: string;
  public comment: string;
  public visitState: VisitState;
  public expectedVisitDate: Date;
  public visitDate: Date;
  beforeDate: boolean;
  public visitIsAdditionalTest: boolean;
}

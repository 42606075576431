import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Trial} from '../models/trial';
import {Properties} from '../../helpers/properties';
import {TrialListData} from '../models/trial-list-data';
import {TrialInformation} from '../models/trial-information';
import {IsDeletable} from '../../is-deletable';
import {TrialGlobalFilter} from '../models/trial-global-filter';
import {TrialHead} from '../models/trial-head';
import {DatePipe} from '@angular/common';
import {Deletable} from '../../modals/delete-confirmation/deletable';
import {TRIAL_READ_WRITE} from '../../login/services/authentication.service';
import {IgCsvExport} from '../../shared/entity/ig-csv-export';
import {DataPageWithFilter} from '../models/data-page-with-filter';
import {EstablishmentsTrialHhhIds} from '../models/establishments-trial-hhh-ids';
import {Value} from '../../shared/entity/value';
import {catchError, map} from 'rxjs/operators';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {ExportTypesEnum} from '../../dynamic-config/exported/data-export/ExportTypesEnum';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';

class TrialNotImported {
  trialName: string;
  reason: string;
}

@Injectable({
  providedIn: 'root'
})
export class TrialService implements Deletable {
  public host = new Properties().host + '/trial';
  public trials: TrialListData[] = [];
  public page: number;
  public limit = 50;
  public sortBy = 'hhhId';
  public orderBy = 'desc';
  public trialGlobalFilter: TrialGlobalFilter = new TrialGlobalFilter().trialFilter();
  public trialHhhId: number;
  public trialSiteHhhId: number;
  private trial: BehaviorSubject<TrialInformation> = new BehaviorSubject<TrialInformation>(new TrialInformation());
  private isPromoterNull: Subject<boolean> = new BehaviorSubject<boolean>(null);
  readOnlyMode = false;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe) {}

  private _trialName: Subject<string> = new BehaviorSubject<string>(null);

  get trialName(): Subject<string> {
    return this._trialName;
  }

  set trialName(value: Subject<string>) {
    this._trialName = value;
  }

  get trial$() {
    return this.trial;
  }

  addTrialIdentification(trial: TrialInformation) {
    this.trialSiteHhhId = trial.trialSiteHhhId;
    this.trial.next(trial);
  }

  addIsPromoterNull(isPromoterNull: boolean) {
    this.isPromoterNull.next(isPromoterNull);
  }

  addTrial(trialIdentification: TrialInformation): Observable<TrialListData> {
    return this.httpClient.post<TrialListData>(this.host + '/add', trialIdentification);
  }

  getTrials(): Observable<Trial[]> {
    return this.httpClient.get<Trial[]>(this.host + '/load-all')
        .pipe();
  }

  getTrialHead(hhhId: number): Observable<TrialHead> {
    return this.httpClient.get<TrialHead>(this.host + '/trial-head/' + hhhId);
  }

  getTrialFullname(hhhId: number): Observable<string> {
    const options = {responseType: 'text' as 'text'};
    return this.httpClient.get(this.host + '/full-name/' + hhhId, options);
  }

  getTrialByLocalIdentifier(localIdentifier: string): Observable<number> {
    return this.httpClient.post<number>(this.host + '/get-id-by-local-identifier', localIdentifier);
  }

  getTrialByProtocolNumber(protocolNumber: string): Observable<number> {
    return this.httpClient.post<number>(this.host + '/get-id-by-protocol-number', protocolNumber);
  }

  loadList(trialGlobalFilter: TrialGlobalFilter, sortBy: string, direction: string, page: number, limit: number): Observable<DataPageWithFilter<TrialListData>> {
    this.page = page;
    this.limit = limit;
    this.sortBy = sortBy;
    this.orderBy = direction;
    this.trialGlobalFilter = Object.assign({}, trialGlobalFilter);

    return this.httpClient.post<DataPageWithFilter<TrialListData>>(
        this.host + `/load-list`,
        new RequestPageBuilder<TrialGlobalFilter>()
            .page(page)
            .limit(limit)
            .sortBy(sortBy)
            .orderBy(direction)
            .filters(trialGlobalFilter)
            .build()
    ).pipe(map(result => {
          try {
            result.content = result.content.map(trialDisplayed => new TrialListData().init(trialDisplayed));
            return result;
          } catch (e) {
            console.error(e);
          }
        }),
        catchError(this.handleError)
    );
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  getTrialByInclusion(inclusionHhhId: number): Observable<number> {
    return this.httpClient.get<number>(this.host + `/get-id-by-inclusion/${inclusionHhhId}`);
  }

  updateTrialFavorite(hhhId: number): Observable<void> {
    return this.httpClient.put<void>(this.host + '/favorite/' + hhhId, {});
  }

  setTrialSiteLiaisonHhhId(trialSiteLiaisonHhhId: number) {
    this.trialSiteHhhId = trialSiteLiaisonHhhId;
  }

  setAllTrials(trials: TrialListData[]) {
    this.trials = trials;
  }

  cloneTrialToEstablishments(establishmentsTrialHhhIds: EstablishmentsTrialHhhIds) {
    return this.httpClient.post(this.host + '/clone-trial-to-establishments', establishmentsTrialHhhIds);
  }

  importGlobal(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.httpClient.post<any>(this.host + '/trial-importer', formdata);
  }

  setTrialReadonlyMode(trialId: number) {
    if (localStorage.getItem(TRIAL_READ_WRITE) !== 'READ_WRITE') {
      this.readOnlyMode = true;
      return;
    }
    this.httpClient.get<Value<boolean>>(this.host + '/is-read-only/' + trialId).subscribe(res => {
      this.readOnlyMode = res.value;
    }, error => {
      this.readOnlyMode = false;
    });
  }

  createImportReport(comments: string[]) {
    const trialsNotImported = [];
    comments.forEach(comment => {
      const trialNotImported = new TrialNotImported();
      if (comment.indexOf('fichier:') !== -1) {
        trialNotImported.trialName = comment;
        trialNotImported.reason = '';
      } else {
        trialNotImported.trialName = '';
        trialNotImported.reason = comment;
      }
      trialsNotImported.push(trialNotImported);
    });
    console.error(trialsNotImported);
    const date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const headers = [];
    headers.push('etude');
    headers.push('probléme');
    new IgCsvExport(
        trialsNotImported,
        headers,
        'rapport-import-' + date
    ).convertToCsvAndDownload();
  }

  importTrials(file: File): Observable<Array<string>> {
    const data: FormData = new FormData();
    data.append('file', file);
    return this.httpClient.post<any>(this.host + '/import', data);
  }

  updateIsTrialSelectedForMobileApplication(trialHhhId: number): Observable<any> {
    return this.httpClient.put(this.host + '/toggle-is-trial-selected-for-mobile-application', new Value(trialHhhId));
  }

  checkCSETAvailable(cset: string): Observable<Value<boolean>> {
    return this.httpClient.post<Value<boolean>>(this.host + '/cset-available', cset);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }


  exportTrials(request: ExportRequest<any>): Observable<any> {
    switch (request.exportType) {
      case ExportTypesEnum.FULL_EXPORT :
      case ExportTypesEnum.SINGLE_PAGE_EXPORT :
      case ExportTypesEnum.SIGREC_EXPORT :
      case ExportTypesEnum.PIRAMIG_EXPORT :
      case ExportTypesEnum.GLOBAL_EXPORT :
      case ExportTypesEnum.GLOBAL_SINGLE_PAGE_EXPORT :
        return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
      case ExportTypesEnum.CUSTOM_EXPORT :
        return this.httpClient.post<void>(this.host + '/export-csv/custom', request).pipe();
    }
  }

}

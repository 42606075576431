import {TheoreticalVisitAct} from './theoretical-visit-act';
import {TrialAct} from './trial-act';
import {TheoreticalVisitAdd} from './theoretical-visit-add';

export class TheoreticalVisit {
  public hhhId: number;
  public name: string;
  public displayHowManyDays: string;
  public type: string;
  public modificationType: string; // modification exists only in front
  public periodType: string;
  public period: any;
  public periodMargin: number;
  public activeVisitHhhId: number;
  public visitActs: TheoreticalVisitAct[] = [];
  public trialActsHhhIds: string [] = [];
  public visitActsToAdd: TheoreticalVisitAct[] = [];
  public visitActsToRemove: TheoreticalVisitAct[] = [];
  public trialActsToEdit: TrialAct[] = [];
  public total: number;
  public optional: boolean;
  public armHhhId: number;
  public comment: string;
  public amendmentModificationType: string; // modification coming from back (  saved in entity theoreticalVisitAct)
  public theoreticalCalendarHhhId: number;
  public parentId: number;
  public expectedDateReference: string;
  public dateReferenceVisit: TheoreticalVisit;
  public expectedDateRelativeTo: any; // front end use only
  public visitIsAdditionalTest: boolean;

  public calculateHowManyDays(): number {
    let periodInDays: number;
    switch (this.periodType) {
      case '0': {
        periodInDays = this.getPeriodInDaysFromDays(this.period);
        break;
      }
      case '1': {
        periodInDays = this.getPeriodInDaysFromWeeks(this.period);
        break;
      }
      case '2': {
        periodInDays = this.getPeriodInDaysFromMonths(this.period);
        break;
      }
      case '3': {
        periodInDays = this.getPeriodInDaysFromYears(this.period);
        break;
      }
    }
    return this.periodType ? periodInDays : 0;
  }

  public getHowManyDays(): string {
    const periodInDays = this.calculateHowManyDays();
    if (this.periodType !== '-1') {
      this.displayHowManyDays = 'J' + (periodInDays >= 0 ? '+' : '') + periodInDays;
    } else {
      this.periodType = '-1';
      this.displayHowManyDays = 'J+Aucun';
    }
    return this.displayHowManyDays;
  }

  buildFrom(th: TheoreticalVisitAdd): TheoreticalVisit {
    this.hhhId = th.hhhId;
    this.theoreticalCalendarHhhId = th.theoreticalCalendarHhhId;
    this.type = th.type;
    this.armHhhId = th.armHhhId;
    this.name = th.name;
    this.comment = th.comment;
    this.periodType = th.periodType;
    this.period = th.period;
    this.periodMargin = th.periodMargin;
    this.modificationType = th.modificationType;
    this.displayHowManyDays = th.displayHowManyDays;
    this.total = th.total;
    this.expectedDateReference = th.expectedDateReference;
    this.dateReferenceVisit = th.dateReferenceVisit;
    this.expectedDateRelativeTo = th.expectedDateRelativeTo;

    return this;
  }

  getPeriodInDaysFromDays(period: number): number {
    return period ? period : 0;
  }

  getPeriodInDaysFromWeeks(period: number): number {
    return period ? period * 7 : 0;
  }

  getPeriodInDaysFromMonths(period: number): number {
    return period ? period * 30 : 0;
  }

  getPeriodInDaysFromYears(period: number): number {
    return period ? period * 365 : 0;
  }

}



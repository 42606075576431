export class InvoiceAct {
  public hhhId: number;
  public localIdentifier: string;
  public promoter: string;
  public numberProtocol: string;
  public site: string;
  public inclusionNumber: string;
  public inclusionHhhId: number;
  public trialHhhId: number;
  public patientHhhId: number;
  public patientNumber: string;
  public patientInitials: string;
  public visitDate: Date;
  public actVisit: string;
  public visitActHhhId: number;
  public actCategory: string;
  public act: string;
  public unitPrice: number;
  public unitNumber: number;
  public total: number;
  public checked: boolean;
  public promoterHhhId: number;
  public typeHhhId: number;
  public typeName: string;
  public visitAct: boolean;
  public visitId: number;
  public inclusionPreScreeningIdentifier: string;
  public inclusionScreeningIdentifier: string;

  public constructor() {}

  init(obj: any): InvoiceAct {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

}

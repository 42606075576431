import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig} from '../../entity/form-input-config';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {PropertyEnum} from '../../entity/property-enum';


export const DYNAMIC_TEXTAREA_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicTextareaComponent),
  multi: true
};

@Component({
  selector: 'ih-dynamic-textarea',
  templateUrl: './dynamic-textarea.component.html',
  styleUrls: ['./dynamic-textarea.component.css'],
  providers: [DYNAMIC_TEXTAREA_VALUE_ACCESSOR]

})
export class DynamicTextareaComponent implements ControlValueAccessor {

  @Input() config: FormInputConfig;
  @Input() requiredErrorMsg = 'FORM_REQUIRED_FIELD_ERROR';
  @Input() submitted: boolean;
  @Input() hasComment = false;
  @Input() commentDisabled = false;
  @Input() publicCommentCount: number;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() defaultLabel: string;
  @Input() alignLabel = false;
  @Input() rows: number;
  @Input() maxlength: string;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueInput: EventEmitter<any> = new EventEmitter();
  @Output() displayDataComment = new EventEmitter<{attachedToField: string, isPrivate: boolean}>();

  hasPrivatePostIt = true;

  constructor(
    private dynamicConfigService: DynamicConfigService
  ) {
    this.dynamicConfigService.initProperties().subscribe(() =>
      this.hasPrivatePostIt = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled));
  }

  private _value: any;


  get value(): any {

    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
      this.valueChange.emit(this._value);
      this.valueInput.emit(this._value);
    }
  }

  writeValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(this._value);
    }
  }

  emitDataCommentEvent(p: boolean) {
    this.displayDataComment.emit({attachedToField: this.name, isPrivate: p});
  }
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onChange = (_) => {
  };

  onTouched = () => {
  };
}

import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {InvoiceCounterPart} from '../invoice-counter-parts/models/invoice-counter-part';
import {DataService} from '../../shared/services/data-service';
import {TrialService} from '../../trial/services/trial.service';
import {InvoiceService} from '../services/invoice.service';
import {InvoiceInformationService} from '../invoice-information/services/invoice-information.service';
import {Router} from '@angular/router';
import {InvoiceCounterPartService} from '../invoice-counter-parts/services/invoice-counter-part.service';
import {AdditionalCostState} from '../../additional-cost-state.enum';
import {InvoiceCounterParts} from '../invoice-counter-parts/models/invoice-counter-parts';
import {InvoiceState} from '../../invoice-state.enum';
import {InputType} from '../../input-type';
import {InvoiceFixedCost} from '../models/invoice-fixed-cost';
import {InvoiceAct} from '../invoice-acts/models/invoice-act';
import {VisitActive} from '../../shared/entity/visit-active';
import {HiddenFields} from '../../admin/models/HiddenFields';
import {UsersService} from '../../admin/services/users.service';
import {VisitAct} from '../../trial/entity/inclusion/visit-act';
import {SharedService} from '../../shared/services/shared.service';
import {AuthenticationService} from '../../login/services/authentication.service';
import {isDate, isNullOrUndefined} from 'util';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {SubInvoiceGlobalFilter} from '../models/sub-invoice-global-filter';
import {AdditionalCostType} from '../../shared/entity/additional-cost-type.enum';
import {FR} from '../../shared/entity/calendar-language';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {InvoiceAppData} from '../Invoice-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AppData} from '../../helpers/app-data';
import {Util} from '../../helpers/util';
import {LabelValue} from '../../shared/entity/label-value';
import {SelectItem} from 'primeng/api';
import {VisitService} from '../../trial/service/visit.service';

@Component({
  selector: 'ih-invoice-counter-part-list',
  templateUrl: './invoice-counter-part-list.component.html',
  styleUrls: ['./invoice-counter-part-list.component.css']
})
export class InvoiceCounterPartListComponent extends PaginatorTableComponent<InvoiceCounterPart> implements OnInit {

  target = DynamicDefinitionEnum.INVOICE_COUNTERPART_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];
  enablePreInvoicePriceEditing = false;
  enableAddActFromValidatedInvoice = true;

  @Input() counterPartState;
  @Input() selectable = true;
  @Input() showExportButton = true;
  @Output() changeInvoiceCounterPartList = new EventEmitter<any>();

  @Input() set counterPartList(refreshList) {
    if (refreshList.length > 0) {
      this.getList();
    }
  }

  invoiceHhhId: number;
  invoiceCounterParts: InvoiceCounterPart[] = [];
  selectedCounterParts: InvoiceCounterPart[] = [];
  counterPartsToInvoice: InvoiceCounterParts = new InvoiceCounterParts();
  actionDisabled: boolean;
  displayDeleteCounterPartsDialog: boolean;
  totalInvoiceCounterParts = 0;
  isDisplaySaveButton = false;
  isUserAdmin = false;
  updateConfirmationDialogVisible = false;
  visitActsToUpdate: Array<VisitAct> = new Array<VisitAct>();
  first = 0;
  isAllSelected = false;
  locale = FR;
  retrocessionFees = false;

  private filters: SubInvoiceGlobalFilter = new SubInvoiceGlobalFilter();
  unselectedCounterParts: number[] = [];

  inclusionIdentifier1Items: LabelValue[] = [];
  inclusionIdentifier2Items: LabelValue[] = [];
  inclusionPreScreeningIdentifierItems: LabelValue[] = [];
  public visitNameOptions: SelectItem[] = [];

  constructor(
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    private useService: UsersService,
    private trialService: TrialService,
    private invoiceService: InvoiceService,
    private invoiceInformationService: InvoiceInformationService,
    private invoiceCounterPartService: InvoiceCounterPartService,
    private router: Router,
    private sharedService: SharedService,
    private authenticationService: AuthenticationService,
    private dynamicConfigService: DynamicConfigService,
    private translateService: InnohealthTranslateService,
    private datePipe: DatePipe,
    private visitService: VisitService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.invoiceHhhId = url.queryParams.invoiceHhhId;
      this.retrocessionFees = url.toString().indexOf('retrocession-fees') !== -1;
    }
  }

  get AdditionalCostState() {
    return AdditionalCostState;
  }

  get inputType() {
    return InputType;
  }

  async ngOnInit() {
    this.visitNameOptions = await this.getVisitNameOptions().then();
    this.initProperties();
    this.limit = 50;
    this.sortBy = 'hhhId';
    this.orderBy = 'asc';
    this.isUserAdmin = this.authenticationService.currentUserRole === 'ADMIN';
    if (this.counterPartState === AdditionalCostState.Pending || this.counterPartState === AdditionalCostState.Invoiced) {
      this.dataService.setExportPageModule(this.getExportPageModule());
    }
  }
  private getVisitNameOptions(): Promise<SelectItem[]>  {
    return this.visitService.getVisitNameOptions(this.invoiceHhhId, this.counterPartState, false).toPromise();
  }
  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.enablePreInvoicePriceEditing = this.dynamicConfigService.getProperty(PropertyEnum.preInvoiceEnabled);
      this.enableAddActFromValidatedInvoice = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
      this.getInvoiceStateCode();
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, InvoiceAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('localIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.localIdentifier)
      .linkClickEvent(((data: InvoiceFixedCost) => {
        this.displayTrialByLocalIdentifier(data.localIdentifier);
      }))
      .sortable()
      .sortField('trialInfoGeneral.localIdentifier')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('promoter')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .filter(this.filters.promoterHhhId)
      .type('provider-promoter')
      .linkClickEvent(((data: InvoiceFixedCost) => {
        this.displayProvider(data);
      }))
      .sortable()
      .sortField('promoter.hhhId')
      .build()
    );
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('numberProtocol')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.numberProtocol)
      .linkClickEvent(((data: InvoiceFixedCost) => {
        this.displayTrialByProtocolNumber(data.numberProtocol);
      }))
      .sortable()
      .sortField('trialInfoGeneral.protocolNumber')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('category')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('counter-part-category')
      .filter(this.filters.category)
      .sortable(this.dynamicConfigService.verifyClient(['vivalto']) ? this.counterPartState !== AdditionalCostState.Invoiced : true)
      .sortField((this.dynamicConfigService.verifyClient(['col', 'cal']) ? 'counterPartReference.counterPartCategory.nameFr' : 'fixedCostCategory.nameFr'))
      .linkClickEvent((() => {
        this.displayCounterPartCategories();
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('description')
      .filterable(this.dynamicConfigService.verifyClient(['vivalto']) ? this.counterPartState !== AdditionalCostState.Invoiced : true)
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.description)
      .linkClickEvent(((data: InvoiceFixedCost) => {
        this.displayCounterPart(data.localIdentifier);
      }))
      .sortable()
      .sortField(this.counterPartState === AdditionalCostState.Pending ? 'description' : 'cp.description')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.dynamicConfigService.verifyClient(['vivalto']) && this.useService.canAccessField(HiddenFields.COUNTER_PART_PRICE))
      .field('unitPrice')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.unitPrice)
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .displayContent(((data: InvoiceAct) => {
        return data.unitPrice.toFixed(2) + '€';
      }))
      .sortable()
      .sortField(this.counterPartState === AdditionalCostState.Pending ? 'pricePerUnit' : 'va.pricePerUnit')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(!this.dynamicConfigService.verifyClient(['igr']))
      .field('unitPrice')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.unitPrice)
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .displayContent((this.dynamicConfigService.verifyClient(['vivalto', 'chro'])) ? ((data: InvoiceAct) => {
        return data.unitPrice.toFixed(2) + '€';
      }) : null)
      .sortable(this.dynamicConfigService.verifyClient(['vivalto']) ? this.counterPartState !== AdditionalCostState.Invoiced : true)
      .sortField('pricePerUnit')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('unitNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.unitNumber)
      .withDisplayCssClassContent()
      .cssClass((() => {
        return 'right-align';
      }))
      .sortable(this.dynamicConfigService.verifyClient(['vivalto']) ? this.counterPartState !== AdditionalCostState.Invoiced : true)
      .sortField('numberOfUnits')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.dynamicConfigService.verifyClient(['igr']) && this.useService.canAccessField(HiddenFields.COUNTER_PART_PRICE))
      .field('total')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.total)
      .linkClickEvent((() => {
      }))
      .sortable()
      .sortField('priceTotal')
      .displayContent(((data: InvoiceFixedCost) => {
        return data.total.toFixed(2) + '€';
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(!this.dynamicConfigService.verifyClient(['igr']))
      .field('total')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.total)
      .linkClickEvent((() => {
      }))
      .sortable(this.dynamicConfigService.verifyClient(['vivalto']) ? this.counterPartState !== AdditionalCostState.Invoiced : true)
      .sortField('priceTotal')
      .displayContent(((data: InvoiceFixedCost) => {
        return data.total.toFixed(2) + '€';
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('internalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.internalAccountNumber)
      .sortable()
      .sortField('internalAccountNumber')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      // TODO: refactor verifyClient condition, implement functionality to allow user to choose filterType from console.
      .field('inclusionScreeningIdentifier')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
      .options(this.dynamicConfigService.verifyClient(['igr']) ? this.inclusionIdentifier2Items : null)
      .filter(this.filters.inclusionScreeningIdentifier)
      .sortable()
      .sortField('inclusion.inclusionIdentifier2')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionPreScreeningIdentifier')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
      .options(this.dynamicConfigService.verifyClient(['igr']) ? this.inclusionPreScreeningIdentifierItems : null)
      .filter(this.filters.inclusionPreScreeningIdentifier)
      .sortable()
      .sortField('inclusion.inclusionPreScreeningIdentifier')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitDate')
      .filterable()
      .filterType(FilterType.CALENDAR)
      .filter(this.filters.visitDate)
      .linkClickEvent(((data: InvoiceAct) => {
        this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId);
      }))
      .sortable()
      .sortField('visit.visitDate')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitName')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
      .options(this.dynamicConfigService.verifyClient(['igr']) ? this.visitNameOptions : null)
      .filter(this.filters.visitName)
      .linkClickEvent(((data: InvoiceAct) => {
        this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId);
      }))
      .sortable()
      .sortField(this.dynamicConfigService.verifyClient(['igr', 'vivalto']) ? 'theoreticalVisit.name' : 'visit.name')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.firstSegment)
      .sortable()
      .sortField('firstSegment')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.filters.secondSegment)
      .sortable()
      .sortField('secondSegment')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionNumber')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
      .options(this.dynamicConfigService.verifyClient(['igr']) ? this.inclusionIdentifier1Items : null)
      .filter(this.filters.inclusionNumber)
      .sortable()
      .sortField('inclusion.inclusionIdentifier1')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientInitials')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortField('inclusion.patient.firstName')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientLastName')
      .filterType(FilterType.INPUT_TEXT)
      .filterable()
      .sortField('inclusion.patientNumber')
      .linkClickEvent(((data: InvoiceAct) => {
        this.displayPatient(data.patientHhhId);
      }))
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/invoice/invoice-counter-parts/invoice-counter-parts.component';
  }

  getFilteredList(filter?) {
    if (filter) {
      Object.assign(this.filters, DynamicTableHeader.buildFilterData(this.headers));
    } else {
      this.filters = new SubInvoiceGlobalFilter();
    }
    this.getList();
  }

  displayPatient(patientHhhId: number) {
    this.router.navigate(['/patient-details'],
      {queryParams: {hhhId: patientHhhId}}
    ).then();
  }

  getList() {
    this.invoiceCounterParts = [];
    if (this.counterPartState === AdditionalCostState.Invoiced || this.counterPartState === AdditionalCostState.All) {
      this.invoiceCounterPartService.loadInvoicedCounterPartsList(this.invoiceHhhId, this.page, this.limit, this.sortBy, this.orderBy, this.filters).subscribe(res => {
        this.invoiceCounterParts = res.content;
        this.totalNumber = res.totalElements;
        this.totalInvoiceCounterParts = res.extraValue;
        this.invoiceCounterPartService.setExportConfiguration(AdditionalCostState.Invoiced, this.invoiceCounterParts);
        this.buildFilterOptions();

      });
    } else if (this.counterPartState === AdditionalCostState.Pending) {
      this.invoiceCounterPartService.loadPendingCounterPartsList(this.invoiceHhhId, this.page, this.limit, this.sortBy, this.orderBy, this.retrocessionFees, this.filters).subscribe(res => {
        this.invoiceCounterParts = res.content;
        this.totalNumber = res.totalElements;
        this.totalInvoiceCounterParts = res.extraValue;
        this.invoiceCounterPartService.setExportConfiguration(AdditionalCostState.Pending, this.invoiceCounterParts);
        this.buildFilterOptions();

      });
    }
  }

  submitAddCounterParts() {
    if (this.isAllSelected) {
      this.invoiceCounterPartService.addAllCounterPartsToInvoice(this.invoiceHhhId, this.retrocessionFees, this.filters, this.unselectedCounterParts).subscribe(() => {
        this.updateList();
      });
    } else {
      this.counterPartsToInvoice.invoiceHhhId = this.invoiceHhhId;
      for (const counterPart of this.selectedCounterParts) {
        this.counterPartsToInvoice.additionalCostsHhhIds.push(new VisitActive(counterPart.hhhId, counterPart.visitAct));
      }
      this.invoiceCounterPartService.addCounterPartsToInvoice(this.counterPartsToInvoice, this.retrocessionFees).subscribe(() => {
        this.updateList();
      });
    }

  }

  deleteCounterParts() {
    this.displayDeleteCounterPartsDialog = false;
    if (this.isAllSelected) {
      this.invoiceCounterPartService.deleteAllCounterPartsFromInvoice(this.invoiceHhhId, this.retrocessionFees, this.filters, this.unselectedCounterParts).subscribe(() => {
        this.updateList();
      });
    } else {
      this.counterPartsToInvoice.invoiceHhhId = this.invoiceHhhId;
      for (const counterPart of this.selectedCounterParts) {
        this.counterPartsToInvoice.additionalCostsHhhIds.push(new VisitActive(counterPart.hhhId, counterPart.visitAct));
      }
      this.invoiceCounterPartService.deleteCounterPartsFromInvoice(this.counterPartsToInvoice, this.retrocessionFees).subscribe(() => {
        this.updateList();
      });
    }

  }

  updateList() {
    this.changeInvoiceCounterPartList.emit({state: this.counterPartState, list: this.selectedCounterParts});
    this.getList();
    this.selectedCounterParts = [];
    this.counterPartsToInvoice.additionalCostsHhhIds = [];
    this.isAllSelected = false;
    this.invoiceService.updateTotal();
  }


  updateSelectedList(selectedList: any) {
    this.selectedCounterParts = selectedList;
  }

  openDeleteCounterPartDialog() {
    if (this.selectedCounterParts.length > 0) {
      this.displayDeleteCounterPartsDialog = true;
    }
  }

  displayTrialByLocalIdentifier(localIdentifier: string) {
    this.trialService.getTrialByLocalIdentifier(localIdentifier).subscribe((trialId: number) => {
      this.router.navigate(
        ['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId: trialId}}
      ).then();
    });
  }

  displayTrialByProtocolNumber(protocolNumber: string) {
    this.trialService.getTrialByProtocolNumber(protocolNumber).subscribe((trialId: number) => {
      this.router.navigate(
        ['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId: trialId}}
      ).then();
    });
  }

  displayProvider(invoiceFixedCost: InvoiceFixedCost) {
    this.dataService.setContactData(invoiceFixedCost.promoter, 'Provider', '/assets/images/providers.png', invoiceFixedCost.promoterHhhId, 0);
    this.router.navigate(['/contact/' + invoiceFixedCost.promoterHhhId + '/Provider']).then();
  }

  displayCounterPart(localIdentifier: string) {
    this.trialService.getTrialByLocalIdentifier(localIdentifier).subscribe((trialId: number) => {
      this.router.navigate(
        ['/trial-details/counter-parts'],
        {queryParams: {hhhId: trialId}}
      ).then();
    });
  }

  tableUpdated(visitAct: VisitAct) {
    this.isDisplaySaveButton = true;
    if (this.visitActsToUpdate.indexOf(visitAct) < 0) {
      this.visitActsToUpdate.push(visitAct);
    }
  }

  confirmUpdate() {
    this.updateConfirmationDialogVisible = false;
    this.invoiceService.updateVisitActsFromInvoice(AdditionalCostType.COUNTER_PART, this.visitActsToUpdate, this.invoiceHhhId).subscribe(() => {
      this.sharedService.showSuccess();
      this.isDisplaySaveButton = false;
      this.getList();
    }, error => {
      this.sharedService.showFailure();
    });
  }

  saveTable() {
    this.updateConfirmationDialogVisible = true;
  }

  resetTable() {
    this.getList();
  }

  displayContent(col: any, colField: any, rowData: any): string {
    const translateService = this.translateService;
    let content = colField;
    if (isNullOrUndefined(rowData[col.field])) {
      content = '';
    } else if (col.translated) {
      content = colField ? translateService.getTranslationString('UI_DIALOG_BUTTON_YES') : translateService.getTranslationString('UI_DIALOG_BUTTON_NO');
    } else if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (col.dateFormatter) {
      content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
    } else if (col.stringToDate) {
      if (!isNullOrUndefined(colField)) {
        const date1 = colField.toString().split('/');
        if (!isDate(rowData[col.field]) && date1.length === 3) {
          rowData[col.field] = new Date(Number(date1[2]), Number(date1[1]), Number(date1[0]));
        }
        content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
      }
    }
    return content;
  }

  private displayCounterPartCategories() {
    this.router.navigate(['/additional-costs-container/counter-part-categories']).then();
  }

  displayInclusionCalendarByNumber(inclusionHhhId: number, trialHhhId: number) {
    this.router.navigate(['/trial-details/inclusion-details/calendar'],
      {
        queryParams: {
          hhhId: trialHhhId,
          inclusionId: inclusionHhhId
        }
      }).then();
  }

  onAllSelected(event: any) {
    this.isAllSelected = event.checked;
  }

  onUnselect(cp) {
    this.unselectedCounterParts.push(cp.data.hhhId);
  }

  getInvoiceStateCode() {
    this.invoiceInformationService.getInvoiceStateCode(this.invoiceHhhId).subscribe(res => {
      if (res) {
        this.dataService.setInvoiceState(res);
        this.actionDisabled = ((res === InvoiceState.Payed || res === InvoiceState.Despatched || res === InvoiceState.Validate)
            && this.enableAddActFromValidatedInvoice) ||
          ((res === InvoiceState.Despatched || res === InvoiceState.Payed) && !this.enableAddActFromValidatedInvoice);
      }
    });
  }

  getSortBy(sortBy: string) {
    switch (sortBy) {
      case 'type':
        return 'cp.type';
      case 'description':
        return 'description';
      case 'firstSegment':
        return 'cp.firstSegment';
      case 'secondSegment':
        return 'cp.secondSegment';
      case 'freeSS':
        return 'cp.freeSS';
      case 'numberOfUnits':
        return 'va.numberOfUnits';
      case 'priceTotal':
        return 'va.priceTotal';
      default:
        return sortBy;
    }
  }

  getSecondSortBy(sortBy: string) {
    switch (sortBy) {
      case 'pricePerUnit':
        return 'cp.pricePerUnit';
      case 'numberOfUnits':
        return 'cp.numberOfUnits';
      case 'priceTotal':
        return 'cp.priceTotal';
      default:
        return '';
    }
  }

  exportInvoiceCounterParts(event: any): void {
    const request = new ExportRequestBuilder<SubInvoiceGlobalFilter>()
      .objectId(this.invoiceHhhId)
      .condition(this.retrocessionFees)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .sortBy(this.getSortBy(this.sortBy))
      .secondSortBy(this.getSecondSortBy(this.sortBy))
      .orderBy(this.orderBy)
      .filters(this.filters)
      .build();

    if (this.counterPartState === AdditionalCostState.Invoiced || this.counterPartState === AdditionalCostState.All) {
      this.invoiceCounterPartService.exportInvoicedCounterPartList(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
    } else if (this.counterPartState === AdditionalCostState.Pending) {
      this.invoiceCounterPartService.exportPendingCounterPartList(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
    }

  }

  buildFilterOptions() {
    if (Util.isNullOrUndefinedOrEmpty(this.invoiceCounterParts)) return;
    const set1 = new Set(this.inclusionIdentifier1Items.map(item => item.value));
    const set2 = new Set(this.inclusionIdentifier2Items.map(item => item.value));
    const set3 = new Set(this.inclusionPreScreeningIdentifierItems.map(item => item.value));
    this.invoiceCounterParts.forEach(cp => {
      if (cp.inclusionNumber) {
        set1.add(cp.inclusionNumber);
      }
      if (cp.inclusionScreeningIdentifier) {
        set2.add(cp.inclusionScreeningIdentifier);
      }
      if (cp.inclusionPreScreeningIdentifier) {
        set3.add(cp.inclusionPreScreeningIdentifier);
      }
    });
    this.inclusionIdentifier1Items = Array.from(set1).map(elt => new LabelValue(elt, elt, false, false));
    this.inclusionIdentifier2Items = Array.from(set2).map(elt => new LabelValue(elt, elt, false, false));
    this.inclusionPreScreeningIdentifierItems = Array.from(set3).map(elt => new LabelValue(elt, elt, false, false));
    if (Util.isNullOrUndefinedOrEmpty(this.headers)) {
      return;
    }
    this.headers.find(header => header.field === 'inclusionPreScreeningIdentifier').options = this.inclusionPreScreeningIdentifierItems;
    this.headers.find(header => header.field === 'inclusionNumber').options = this.inclusionIdentifier1Items;
    this.headers.find(header => header.field === 'inclusionScreeningIdentifier').options = this.inclusionIdentifier2Items;
  }

}

export class ProviderExtraInfos {

  public financialManageName: string;
  public precisionPostalInvoicingAddress: string;
  public postalInvoicingAddressNumber: string;
  public postalInvoicingAddressStreet: string;
  public postalInvoicingAddressCode: string;
  public postalInvoicingAddressCity: string;
  public postalInvoicingAddressCedex: string;
  public postalInvoicingAddressCountry: string;
  public postalInvoiceSendingAddress: string;
  public mail: string;
  public phone: string;
  public promoterAccountantNumber: string;
  public tva: string;
  public tvaIntraCommunity: string;
}

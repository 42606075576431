import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {Observable} from 'rxjs';
import {Schedule} from '../modals/schedule';
import {ScheduledItem} from '../modals/scheduled-item';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  public properties: Properties = new Properties();
  public host = this.properties.host + '/agenda';

  constructor(private httpClient: HttpClient) {
  }

  getAllEvents(year: number): Observable<Schedule> {
    return this.httpClient.get<Schedule>(this.host + '/load-event-list/' + year);
  }

  getTrialItem(scheduleId: number): Observable<ScheduledItem[]> {
    return this.httpClient.get<ScheduledItem[]>(this.host + '/trial-items/' + scheduleId);
  }

  addHumanResource(hhhId: number) {
    return this.httpClient.get(this.host + '/add/human-resource/' + hhhId);
  }

  updateHumanResourceColor(hhhId: number, color: string) {
    return this.httpClient.put(this.host + '/update/human-resource', {hhhId, color});
  }

  removeHumanResource(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/human-resource/' + hhhId);
  }

  addTrial(hhhId: number) {
    return this.httpClient.get(this.host + '/add/trial/' + hhhId);
  }

  removeTrial(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/trial/' + hhhId);
  }
}

import {VisitAdditionalCost} from '../trial-additionnal-costs/visit-additional-cost';

export class VisitAct {
  hhhId: number;
  theoreticalVisitActHhhId: number;
  visitActCategory: string;
  description: string;
  pricePerUnit: number;
  numberOfUnits: number;
  priceTotal: number;
  realisationStateHhhId: number;
  realisationState: string;
  paymentStateHhhId: number;
  paymentState: string;
  providerPaymentStateHhhId: number;
  providerPaymentState: string;
  checked: boolean;
  invoiceable: boolean;
  optional: boolean;
  operational: boolean;
  type: string;
  total: number;
  additionalCostType: string;
  unitNumber: number;
  unitPrice: number;
  realisationDate: Date;
  childVisitActs: VisitAdditionalCost[];
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HumanResourceListComponent} from './human-resource-list/human-resource-list.component';
import {HumanResourceDetailsComponent} from './human-resource-details/human-resource-details.component';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {AuthGuard} from '../helpers/auth-guard.service';
import {RequirePasswordUpdateGuard} from '../helpers/require-password-update-guard';

const routes: Routes = [
  {path: '', redirectTo: 'human-resource-list', pathMatch: 'full'},
  {path: 'human-resource-list', component: HumanResourceListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'human-resource-details', component: HumanResourceDetailsComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    HttpClientModule,
    TranslateModule],
  exports: [RouterModule]
})
export class HumanResourceRoutingModule {
}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {ActCategoryService} from '../services/act-category.service';
import {DataService} from '../../shared/services/data-service';
import {SharedService} from '../../shared/services/shared.service';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {ActCategoryFormComponent} from '../act-category-form/act-category-form.component';
import {CounterpartCategory} from '../models/counterpart-category';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AppData} from '../../helpers/app-data';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-acts-categories',
  templateUrl: './acts-categories.component.html',
  styleUrls: ['./acts-categories.component.css']
})
export class ActsCategoriesComponent extends PaginatorTableComponent<CounterpartCategory> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.ActCategory;
  readonly target = DynamicDefinitionEnum.ADDITIONAL_COSTS_ACT_CATEGORY_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader [] = [];
  disableEdit = false;

  constructor(
      private actCategoryService: ActCategoryService,
      private dataService: DataService,
      private cfr: ComponentFactoryResolver,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initTableConfig();
    this.getList();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, AdditionalCostsAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('code')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build()
    );
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('nameFr')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build()
    );
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/additional-costs/acts-categories/acts-categories.component';
  }

  getAddTitle(): string {
    return 'MODULE_ACT_CATEGORIES_GENERAL_ADD_NEW';
  }

  getUpdateTitle(): string {
    return 'MODULE_ACT_CATEGORIES_GENERAL_UPDATE';
  }

  getDeleteMessage(): string {
    return 'MODULE_ACT_CATEGORIES_DIALOG_WARNING_ACT_CATEGORY_WILL_BE_DELETED';
  }

  getService(): any {
    return this.actCategoryService;
  }

  getList() {
    this.actCategoryService.loadList().subscribe(
        res => {
          console.log(res);
          this.actCategoryService.setActCategoryToExportAllPage(res);
          this.actCategoryService.setActCategoryListToExportSinglePage(res.slice(0, 10));
          this.values = res;
          this.totalNumber = this.values.length;
        }, error => {
          this.sharedService.showFailure();
          console.log(error);
          console.error('An error occurred while loading Act Categories.' + error);
        }
    );
  }

  displayFormDialogue(actCategory?: CounterpartCategory) {
    const formComponent = ActCategoryFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.actCategoryList = this.values;
    if (actCategory) {
      const actCategoryToEdit: CounterpartCategory = Object.assign({}, actCategory);
      formComponent.formHeader = this.getUpdateTitle();
      formComponent.actCategory = actCategoryToEdit;
      formComponent.onAdd(this.updateList(actCategory));
    } else {
      formComponent.formHeader = this.getAddTitle();
      formComponent.onAdd(this.updateList());
    }
  }

  updateList(actCategoryToEdit?: CounterpartCategory) {
    if (actCategoryToEdit) {
      return (newActCategory: CounterpartCategory) => {
        const index = this.values.indexOf(actCategoryToEdit);
        this.values[index].nameFr = newActCategory.nameFr;
        this.values[index].code = newActCategory.code;
      };
    } else {
      return (actCategory: CounterpartCategory) => {
        this.values.push(actCategory);
        this.values.sort((a, b) => a.nameFr.toLowerCase().localeCompare(b.nameFr.toLowerCase()));
      };
    }
  }

  displayDeleteDialogue(actCategory: CounterpartCategory) {
    this.displayDeleteDialogueFromInfos(actCategory.hhhId, actCategory.nameFr, actCategory);
  }

  refreshAfterDelete(entity?: any): void {
    this.sharedService.setInformationInLocalStorage('Grille budgétaire: Catégories des actes', 'Supprimer', entity.nameFr);
    const index = this.values.findIndex((act: CounterpartCategory) => act.hhhId === entity.hhhId);
    this.values.splice(index, 1);
  }

  getPageDisplayed(event) {
    this.actCategoryService.setActCategoryListToExportSinglePage(this.values.slice(event.first, event.first + event.rows));
  }

  exportActCategories(event: any): void {
    const request = new ExportRequestBuilder<void>()
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
        .build();
    this.actCategoryService.exportActsCategories(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }
}

import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {SharedService} from '../../shared/services/shared.service';
import {DataService} from '../../shared/services/data-service';
import {AccountsManagementService} from '../services/accounts-management.service';
import {Account} from '../models/account';
import {User} from '../../admin/models/user';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {Data} from '../../shared/entity/data';
import {AccountManagementFormComponent} from '../account-management-form/account-management-form.component';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {TableButtonConfig} from '../../dynamic-config/entity/table-button-config';

@Component({
  selector: 'ih-accounts-management',
  templateUrl: './accounts-management.component.html',
  styleUrls: ['./accounts-management.component.css']
})
export class AccountsManagementComponent extends PaginatorTableComponent<Account> implements OnInit {

  headers: DynamicTableHeader[] = [];
  config: TableConfig = new TableConfig(new Map(), new Map());

  accountStateFilter = Data.activeOptions;

  constructor(
      private dataService: DataService,
      private cfr: ComponentFactoryResolver,
      private sharedService: SharedService,
      private accountsManagementService: AccountsManagementService,
      private translateService: InnohealthTranslateService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buildTableHeaders();
    this.getList();
  }

  getList() {
    this.accountsManagementService.getAccounts().subscribe(
        res => {
          this.values = res;
          this.totalNumber = this.values.length;
        }, error => {
          this.sharedService.showFailure();
          console.log(error);
          console.error('An error occurred while loading Act Categories.' + error);
        }
    );
  }

  displayFormDialogue(userAccount?: Account) {
    const formComponent = AccountManagementFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    if (userAccount) {
      const userAccountToEdit: Account = Object.assign({}, userAccount);
      formComponent.formHeader = 'MODULE_MOBILE_APPLICATION_EDIT_ACCOUNT';
      formComponent.account = userAccountToEdit;
      formComponent.onAdd(() => this.getList());
    } else {
      formComponent.formHeader = 'MODULE_MOBILE_APPLICATION_ADD_ACCOUNT';
      formComponent.onAdd(account => this.values.push(account));
    }
  }

  getService(): any {
    return this.accountsManagementService;
  }

  displayDeleteDialogue(account: Account) {
    this.displayDeleteDialogueFromInfos(account.hhhId, this.buildDoctorName(account), account);
  }

  getDeleteMessage(): string {
    return 'MODULE_MOBILE_APPLICATION_DIALOG_WARNING_USER_WILL_BE_DELETED';
  }

  refreshAfterDelete(account: Account): void {
    const index = this.values.findIndex(
        (acc: Account) => acc.hhhId === account.hhhId
    );
    this.values.splice(index, 1);
  }

  buildTableHeaders() {
    this.headers.push(new DynamicTableHeaderBuilder()
        .field('userName')
        .header('MODULE_USER_ADMIN_USER_NAME')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers.push(new DynamicTableHeaderBuilder()
        .field('title')
        .header('MODULE_USER_ADMIN_USER_TITLE')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers.push(new DynamicTableHeaderBuilder()
        .field('lastName')
        .header('MODULE_USER_ADMIN_USER_LAST_NAME')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers.push(new DynamicTableHeaderBuilder()
        .field('firstName')
        .header('MODULE_USER_ADMIN_USER_FIRST_NAME')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers.push(new DynamicTableHeaderBuilder()
        .field('role')
        .header('MODULE_USER_ADMIN_USER_ROLE')
        .filterable()
        .filterType(FilterType.IH_DROPDOWN)
        .type('mobile-role')
        .sortable()
        .displayContent((users: User) => {
          return users.role;
        })
        .build());
    this.headers.push(new DynamicTableHeaderBuilder()
        .field('duty')
        .header('MODULE_USER_ADMIN_USER_DUTIES')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers.push(new DynamicTableHeaderBuilder()
        .field('establishment')
        .header('MODULE_USER_ADMIN_USER_ESTABLISHMENT')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers.push(new DynamicTableHeaderBuilder()
        .field('active')
        .header('MODULE_USER_ADMIN_USER_ACTIVE')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.accountStateFilter)
        .sortable()
        .displayContent((data: User) => {
          return data.active ?
              this.translateService.getTranslationString('MODULE_USER_ADMIN_USER_ACTIVE') :
              this.translateService.getTranslationString('MODULE_USER_ADMIN_USER_NOT_ACTIVE');
        })
        .build());
    this.headers.push(new DynamicTableHeaderBuilder()
        .field('nbrOfConnectionsPerMonth')
        .header('MODULE_MOBILE_APPLICATION_NUMBER_OF_CONNECTION_MONTHLY')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    this.headers.push(new DynamicTableHeaderBuilder()
        .field('lastConnection')
        .header('MODULE_MOBILE_APPLICATION_LAST_CONNECTION')
        .sortable()
        .withDateFormatter()
        .build());
    this.config.buttons.set('edit-btn', new TableButtonConfig(true));
    this.config.buttons.set('delete-btn', new TableButtonConfig(true));
  }

  buildDoctorName(account: Account): string {
    return account.title + ' ' + account.lastName + ' ' + account.firstName;
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {FR} from '../../../../shared/entity/calendar-language';
import {PharmacyDispensationKit} from '../../../entity/trial-pharmacy/PharmacyDispensationKit';

@Component({
  selector: 'ih-pharmacy-dispensation-kit-form',
  templateUrl: './pharmacy-dispensation-kit-form.component.html',
  styleUrls: ['./pharmacy-dispensation-kit-form.component.css']
})
export class PharmacyDispensationKitFormComponent extends FormComponent<PharmacyDispensationKit> implements OnInit {

  constructor() {
    super();
  }


  pharmacyDispensationKitGlobal: PharmacyDispensationKit = new PharmacyDispensationKit();
  pharmacyKits: PharmacyDispensationKit[] = [];
  locale = FR;
  trialHhhId: number;
  stockHhhId: number;
  displayKitList = false;
  displaySaveButton: boolean;

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(PharmacyDispensationKitFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.pharmacyDispensationKitGlobal.lotHhhId = this.stockHhhId;
  }

  saveKit(pharmacyKit?: PharmacyDispensationKit, position?: number) {
    this.displayKitList = true;
    if (!pharmacyKit) {
      this.callback(this.pharmacyDispensationKitGlobal);
    } else {
      this.callback(pharmacyKit);
      this.pharmacyKits.splice(position, 1);
    }
  }

  addKit() {
    // this.pharmacyDispensationKitService.add(this.pharmacyDispensationKitGlobal);
    this.pharmacyKits.push(Object.assign({}, this.pharmacyDispensationKitGlobal));
  }
}

import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';

export class EstablishmentsTrialHhhIds {
  public trialHhhId: number;
  public establishmentHhhIds: number[];

  init(obj: any): EstablishmentsTrialHhhIds {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    const valid = true;
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'establishment') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.establishmentHhhIds)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return valid;
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Invoice} from '../models/invoice';
import {InvoiceAdd} from '../models/invoice-add';
import {SharedService} from '../../shared/services/shared.service';
import {InvoiceService} from '../services/invoice.service';
import {InvoiceState} from '../invoice-fixed-costs/models/invoice-state';
import {NgForm} from '@angular/forms';
import {FormComponent} from '../../shared/component/form/form.component';
import {FR} from '../../shared/entity/calendar-language';
import {TrialService} from '../../trial/services/trial.service';
import {EstablishmentService} from '../../establishment/services/establishment.service';
import {Router} from '@angular/router';
import {StateType} from '../../shared/entity/payment-type.enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {InvoiceAppData} from '../Invoice-app-data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {Data} from '../../shared/entity/data';

@Component({
  selector: 'ih-invoice-add-form',
  templateUrl: './invoice-add-form.component.html',
  styleUrls: ['./invoice-add-form.component.css']
})
export class InvoiceAddFormComponent extends FormComponent<Invoice> implements OnInit {

  target = DynamicDefinitionEnum.INVOICE_ADD_FORM;
  config: FormConfig = new FormConfig();
  invoiceTrialByProtocolNumber = true;
  isCsetEnabled = true;

  @ViewChild('invoiceAddForm') invoiceAddForm: NgForm;
  invoiceAdd: InvoiceAdd = new InvoiceAdd();
  manualState = false;
  state = new InvoiceState();
  invoiceStates: InvoiceState[] = [];
  locale = FR;
  numberIsUsed = false;
  retrocessionFees = false;
  tvaOptionsDisabled = true;
  tvaOptionsForCHRO = Data.TvaOptionsForCHRO;
  imputationAccountOptions = Data.ImputationAccountOptions;

  constructor(
      private sharedService: SharedService,
      private invoiceService: InvoiceService,
      private trialService: TrialService,
      private router: Router,
      private establishmentService: EstablishmentService,
      private dynamicConfigService: DynamicConfigService,
  ) {
    super();
    const url = this.router.parseUrl(this.router.url);
    this.retrocessionFees = url.toString().indexOf('retrocession-fees') !== -1;
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(InvoiceAddFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.initProperties();
    this.display = true;
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.invoiceTrialByProtocolNumber = this.dynamicConfigService.getProperty(PropertyEnum.invoiceTrialByProtocolNumber);
      this.isCsetEnabled = this.dynamicConfigService.getProperty(PropertyEnum.csetEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, InvoiceAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  addInvoice() {
    this.invoiceAdd.state = this.state.hhhId;
    this.submitted = true;
    if (this.invoiceAddForm.invalid || !this.invoiceAdd.isValid(this.config, this.isParent())) {
      return;
    }
    this.invoiceAdd.type = this.retrocessionFees ? StateType.PROVIDER_PAYMENT : StateType.PROMOTER_PAYMENT;
    this.invoiceService.addInvoice(this.invoiceAdd).subscribe(res => {
      this.sharedService.setInformationInLocalStorage('Pré-facturation', 'Ajouter', this.invoiceAdd.invoiceNumber);
      this.sharedService.showSuccess();
      const invoice = new Invoice();
      invoice.number = this.invoiceAdd.invoiceNumber;
      invoice.hhhId = res;
      invoice.validationDate = this.invoiceAdd.validationDate;
      invoice.paymentDate = this.invoiceAdd.paymentDate;
      invoice.issueDate = this.invoiceAdd.issueDate;
      invoice.despatchDate = this.invoiceAdd.despatchDate;
      const invoiceState = this.invoiceStates.find(item => item.hhhId === this.invoiceAdd.state);
      invoice.state = invoiceState ? invoiceState.nameFr : 'En cours de préparation';
      if (this.invoiceAdd.trial) {
        this.trialService.getTrialFullname(this.invoiceAdd.trial).subscribe(
            fullName => invoice.trial = fullName
        );
      }
      if (this.retrocessionFees) {
        this.router.navigate(['/retrocession-fees-details'], {queryParams: {invoiceHhhId: invoice.hhhId}}).then();
      } else {
        this.router.navigate(['/invoice-details'], {queryParams: {invoiceHhhId: invoice.hhhId}}).then();
      }
      this.invoiceService.getEstablishmentFullNameByInvoiceHhhId(res).subscribe(
          fullName => invoice.establishment = fullName);
      this.callback(invoice);
    }, error => {
      this.sharedService.showFailure(error.error);
    });
    this.display = false;
  }

  isParent() {
    return this.dynamicConfigService.parent;
  }

  checkInvoiceNumberExistence() {
    if (Util.isNullOrUndefinedOrEmpty(this.invoiceAdd.invoiceNumber)) {
      return;
    }
    this.invoiceService.isInvoiceNumberAvailable(this.invoiceAdd.invoiceNumber).subscribe(result => {
      this.numberIsUsed = !(result.value);
    });
  }

  onImputationAccountChange() {
    switch (this.invoiceAdd.imputationAccount) {
      case '754815':
      case '754816':
      case '754817':
        this.invoiceAdd.tvaRate = '0';
        this.tvaOptionsDisabled = true;
        break;

      case '754811':
        this.invoiceAdd.tvaRate = '20';
        this.tvaOptionsDisabled = true;
        break;

      case '7721':
        this.invoiceAdd.tvaRate = '';
        this.tvaOptionsDisabled = false;
        break;

      default:
        this.invoiceAdd.tvaRate = '';
        this.tvaOptionsDisabled = true;
        break;
    }
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);
}

import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';

export class TrialCounterpart {
  public hhhId: number;
  public publicCommentCount: number;
  public description: any;
  public attachedToVisit: boolean;
  public invoiceable: boolean;
  public pricePerUnit: number;
  public counterPartCategory: string;
  public counterPartReference: string;
  public unitNumber: number;
  public total: number;
  public checked: boolean;
  public internalAccountNumber: string;
  public secondInternalAccountNumber: string;
  public firstSegment: string;
  public secondSegment: string;
  public temps: string;
  public editable = false;
  freeSS: boolean;

  init(obj: any): TrialCounterpart {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }

}


import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {VisitService} from '../../../../../service/visit.service';
import {Visit} from '../../../../../entity/visit';
import {SharedService} from '../../../../../../shared/services/shared.service';
import {VisitActService} from '../../../../../service/visit-act.service';
import {InvoiceService} from '../../../../../../invoice/services/invoice.service';
import {Data} from '../../../../../../shared/entity/data';
import {VisitUpdate} from '../../../../../entity/inclusion/visit-update';
import {StateType} from '../../../../../../shared/entity/payment-type.enum';
import {PropertyEnum} from '../../../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../../../dynamic-config/service/dynamic-config.service';
import {SwitchState} from '../../../../../entity/inclusion/switch-state';

@Component({
  selector: 'ih-visit-state-update-confirmation',
  templateUrl: './visit-state-update-confirmation.component.html',
  styleUrls: ['./visit-state-update-confirmation.component.css']
})
export class VisitStateUpdateConfirmationComponent implements OnInit {

  public displayUpdateVisitState = false;
  public visit: Visit;
  public oldVisitStateHhhId: number;
  public reason: string;
  public message: string;
  public header: string;
  public from = '';
  public stateType: StateType;
  @Output() updatedVisitActsRealisationStateEvent = new EventEmitter<number>();
  @Output() updatedVisitActsPaymentStateEvent = new EventEmitter<number>();
  @Output() canceledUpdateOfVisitActStateEvent = new EventEmitter<void>();
  @Output() visitStateUpdatedEvent = new EventEmitter<void>();
  pendingVisitUpdate: EventEmitter<VisitUpdate> = new EventEmitter<VisitUpdate>();
  isInclusionCalendarSavingButtonApart = false;

  constructor(
    private visitService: VisitService,
    private invoiceService: InvoiceService,
    private visitActService: VisitActService,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    this.initProperties();
    this.displayUpdateVisitState = true;
    this.header = 'MODULE_VISITS_DIALOG_TITLE_SAVE_VISIT_MORE_ACTION_NECESSARY';
    if (this.reason === 'COMPLETED') {
      this.message = 'MODULE_VISITS_DIALOG_QUESTION_AUTO_SET_VISIT_ACTS_AS_COMPLETED';
    } else if (
      this.reason === 'INVOICED'
      || this.reason === 'INVOICED_FROM_PAID_VISIT'
      || this.reason === 'INVOICED_FROM_INVOICED_VISIT'
    ) {
      this.message = 'MODULE_VISITS_DIALOG_QUESTION_AUTO_CREATE_INVOICE';
    } else if (this.reason === 'CANT_SWITCH_TO_UNINVOICED') {
      this.message = 'MODULE_VISITS_DIALOG_QUESTION_CANT_SWITCH_TO_UNINVOICED';
    } else if (this.reason === 'MODULE_TRIAL_PATIENT_CALENDAR_NOT_APPLICABLE') {
      this.message = 'MODULE_VISITS_DIALOG_QUESTION_NOT_APPLICABLE';
    } else if (this.reason === 'ALL_VISIT_ACTS_MUST_BE_NOT_RELEASED') {
      this.message = 'MODULE_VISITS_DIALOG_QUESTION_NOT_REALISED';
  }
  }
  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isInclusionCalendarSavingButtonApart = this.dynamicConfigService.getProperty(PropertyEnum.inclusionCalendarSavingButtonApart);
    });
  }

  setAllActsAsCompleted() {
    const stateSwitch = new SwitchState(this.visit.hhhId, StateType.PROVIDER_PAYMENT, this.oldVisitStateHhhId, this.visit.visitStateHhhId);
    this.visitActService.setAllActsAsCompleted(stateSwitch)
      .subscribe(isStateSwitchable => {
        this.displayUpdateVisitState = false;
        this.visitStateUpdatedEvent.emit();
        this.updatedVisitActsRealisationStateEvent.emit(2);
        if (isStateSwitchable.switchable) {
          this.updateVisitState();
        } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_INVOICED_VISIT') {
          this.reason = 'INVOICED_FROM_INVOICED_VISIT';
          this.message = 'MODULE_VISITS_DIALOG_QUESTION_AUTO_CREATE_INVOICE';
          this.displayUpdateVisitState = true;
        } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_PAID_VISIT') {
          this.reason = 'INVOICED_FROM_PAID_VISIT';
          this.message = 'MODULE_VISITS_DIALOG_QUESTION_AUTO_CREATE_INVOICE';
          this.displayUpdateVisitState = true;
        }
      });
  }
  setAllActsAsNotRealised(): void {
    const stateSwitch = new SwitchState(this.visit.hhhId, StateType.PROVIDER_PAYMENT, this.oldVisitStateHhhId, this.visit.visitStateHhhId);
    this.visitActService.setAllActsAsNotRealised(stateSwitch)
      .subscribe(isStateSwitchable => {
        this.displayUpdateVisitState = false;
        this.visitStateUpdatedEvent.emit();
        this.updatedVisitActsRealisationStateEvent.emit(1);
        this.updatedVisitActsPaymentStateEvent.emit(1);
        if (isStateSwitchable.switchable) {
          this.updateVisitState();
        }
      });
  }

  createInvoiceForUninvoicedActs() {
    this.invoiceService.createInvoiceForUninvoicedActs(this.visit.hhhId, this.visit.visitStateHhhId)
      .subscribe(isStateSwitchable => {
        this.displayUpdateVisitState = false;
        this.visitStateUpdatedEvent.emit();
        this.updatedVisitActsRealisationStateEvent.emit(2);
        this.updatedVisitActsPaymentStateEvent.emit(2);
        this.sharedService.showSuccess();
        if (isStateSwitchable.switchable) {
          this.updateVisitState();
        }
      });
  }

  createInvoiceFromInvoicedOrPaidVisit(visitActPaymentStateHhhId: number, invoiceStateHhhId: number) {
    const request = {
      visitHhhId: this.visit.hhhId,
      stateToHhhId: this.visit.visitStateHhhId,
      stateType: this.stateType,
      visitActPaymentStateId: visitActPaymentStateHhhId,
      invoiceStateId: invoiceStateHhhId
    };

    // Pass the responseType option in the correct form
    this.invoiceService.createInvoiceFromInvoicedOrPaidVisit(request)
      .subscribe(
        res => {
          this.displayUpdateVisitState = false;
          this.visitStateUpdatedEvent.emit();
          this.updatedVisitActsRealisationStateEvent.emit(2);
          this.updatedVisitActsPaymentStateEvent.emit(visitActPaymentStateHhhId);
          this.sharedService.showSuccess();
          this.updateVisitState();
        },
        error => {
          console.error('Error while creating invoice:', error);
        }
      );
  }


  updateVisitState() {
    if (this.isInclusionCalendarSavingButtonApart && this.from !== 'TRACKING_TABLE') {
      this.prepareUpdateStandByVisit();
    } else {
      const stateSwitch = new SwitchState(this.visit.hhhId, null, this.oldVisitStateHhhId, this.visit.visitStateHhhId);
      this.visitService.updateVisitAndActsState(stateSwitch).subscribe(stateName => {
        this.sharedService.showSuccess();
        this.visit.visitStateName = stateName;
        this.visitStateUpdatedEvent.emit();
        this.displayUpdateVisitState = false;
      }, error => {
        this.sharedService.showFailure();
      });
    }
  }

  prepareUpdateStandByVisit() {
    const visitUpdate: VisitUpdate = new VisitUpdate();
    visitUpdate.visitId = this.visit.hhhId;
    visitUpdate.state = this.visit.visitStateHhhId;
    visitUpdate.visitActStateTypeEnum = this.stateType;
    this.pendingVisitUpdate.emit(visitUpdate);
    this.displayUpdateVisitState = false;
  }

  cancel() {
    this.displayUpdateVisitState = false;
  }

  getVisitStateName(stateId: number): string {
    return this.visitService.getVisitStateName(stateId);
  }

  updateVisitAndVisitActs() {
    if (this.reason === 'COMPLETED') {
      if (this.isInclusionCalendarSavingButtonApart && this.from !== 'TRACKING_TABLE') {
        const visitUpdate: VisitUpdate = new VisitUpdate();
        visitUpdate.visitId = this.visit.hhhId;
        visitUpdate.state = this.visit.visitStateHhhId;
        visitUpdate.providerState = this.visit.visitProviderStateHhhId;
        visitUpdate.olVisitStateId = this.oldVisitStateHhhId;
        visitUpdate.setVisitActsAsCompleted = true;
        visitUpdate.visitActStateTypeEnum = this.stateType;
        this.pendingVisitUpdate.emit(visitUpdate);
      } else {
        this.setAllActsAsCompleted();
      }
    }    if (this.reason === 'ALL_VISIT_ACTS_MUST_BE_NOT_RELEASED') {
      if (this.isInclusionCalendarSavingButtonApart && this.from !== 'TRACKING_TABLE') {
        const visitUpdate: VisitUpdate = new VisitUpdate();
        visitUpdate.visitId = this.visit.hhhId;
        visitUpdate.state = this.visit.visitStateHhhId;
        visitUpdate.providerState = this.visit.visitProviderStateHhhId;
        visitUpdate.olVisitStateId = this.oldVisitStateHhhId;
        visitUpdate.setVisitActsAsNotRealised= true;
        visitUpdate.visitActStateTypeEnum = this.stateType;
        this.pendingVisitUpdate.emit(visitUpdate);
      } else {
        this.setAllActsAsNotRealised();
      }
    }  else if (this.reason === 'INVOICED') {
      if (this.isInclusionCalendarSavingButtonApart && this.from !== 'TRACKING_TABLE') {
        const visitUpdate: VisitUpdate = new VisitUpdate();
        visitUpdate.visitId = this.visit.hhhId;
        visitUpdate.state = this.visit.visitStateHhhId;
        visitUpdate.providerState = this.visit.visitProviderStateHhhId;
        visitUpdate.olVisitStateId = this.oldVisitStateHhhId;
        visitUpdate.createInvoiceForUninvoicedActs = true;
        visitUpdate.visitActStateTypeEnum = this.stateType;
        this.pendingVisitUpdate.emit(visitUpdate);
      } else {
        this.createInvoiceForUninvoicedActs();
      }
    } else if (this.reason === 'INVOICED_FROM_INVOICED_VISIT') {
      if (this.isInclusionCalendarSavingButtonApart && this.from !== 'TRACKING_TABLE') {
        const visitUpdate: VisitUpdate = new VisitUpdate();
        visitUpdate.visitId = this.visit.hhhId;
        visitUpdate.state = this.visit.visitStateHhhId;
        visitUpdate.providerState = this.visit.visitProviderStateHhhId;
        visitUpdate.olVisitStateId = this.oldVisitStateHhhId;
        visitUpdate.createInvoiceFromInvoicedOrPaidVisit = true;
        visitUpdate.visitActPaymentStateHhhId = Data.visitActPaymentStates.INVOICED;
        visitUpdate.invoiceStateHhhId = Data.invoiceStates.ISSUED;
        visitUpdate.visitActStateTypeEnum = this.stateType;
        this.pendingVisitUpdate.emit(visitUpdate);
      } else {
        this.createInvoiceFromInvoicedOrPaidVisit(Data.visitActPaymentStates.INVOICED, Data.invoiceStates.ISSUED);
      }
    } else if (this.reason === 'INVOICED_FROM_PAID_VISIT') {
      if (this.isInclusionCalendarSavingButtonApart && this.from !== 'TRACKING_TABLE') {
        const visitUpdate: VisitUpdate = new VisitUpdate();
        visitUpdate.visitId = this.visit.hhhId;
        visitUpdate.state = this.visit.visitStateHhhId;
        visitUpdate.providerState = this.visit.visitProviderStateHhhId;
        visitUpdate.olVisitStateId = this.oldVisitStateHhhId;
        visitUpdate.createInvoiceFromInvoicedOrPaidVisit = true;
        visitUpdate.visitActPaymentStateHhhId = Data.visitActPaymentStates.PAID;
        visitUpdate.invoiceStateHhhId = Data.invoiceStates.PAID;
        visitUpdate.visitActStateTypeEnum = this.stateType;
        this.pendingVisitUpdate.emit(visitUpdate);
      } else {
        this.createInvoiceFromInvoicedOrPaidVisit(Data.visitActPaymentStates.PAID, Data.invoiceStates.PAID);
      }
    } else if (this.reason === 'CANT_SWITCH_TO_UNINVOICED') {
      if (this.isInclusionCalendarSavingButtonApart && this.from !== 'TRACKING_TABLE') {
        const visitUpdate: VisitUpdate = new VisitUpdate();
        visitUpdate.visitId = this.visit.hhhId;
        visitUpdate.state = this.visit.visitStateHhhId;
        visitUpdate.providerState = this.visit.visitProviderStateHhhId;
        visitUpdate.visitActStateTypeEnum = this.stateType;
        this.pendingVisitUpdate.emit(visitUpdate);
      } else {
        this.updateVisitState();
      }
    }
    else if (this.reason === 'MODULE_TRIAL_PATIENT_CALENDAR_NOT_APPLICABLE') {
      this.visit.visitStateHhhId= this.oldVisitStateHhhId;
    }
    this.displayUpdateVisitState = false;
    this.visitStateUpdatedEvent.emit();
  }
}

import {PromoterType} from '../../provider/models/promoter-type';
import {LabelValue} from './label-value';
import {SelectItem} from 'primeng/api';
import {StateType} from './payment-type.enum';

export class Data {

  static readonly visitActPaymentStates = {
    PENDING: 1,
    INVOICED: 2,
    PAID: 3
  };

  static readonly visitActRealisationStates = {
    PENDING: 1,
    COMPLETED: 2
  };

  static readonly invoiceStates = {
    CREATED: 1,
    ISSUED: 2,
    VALIDATED: 3,
    DESPATCHED: 4,
    PAID: 5,
    CANCELED: 6
  };

  static readonly visitActStates = [{
    label: 'Réalisation',
    items: [
      {label: 'Non Réalisé', value: {id: 1, type: StateType.REALISATION}},
      {label: 'Réalisé', value: {id: 2, type: StateType.REALISATION}}
    ]
  }, {
    label: 'Paiement',
    items: [
      {label: 'Non Facturé', value: {id: 1, type: StateType.PROMOTER_PAYMENT}},
      {label: 'Payé', value: {id: 3, type: StateType.PROMOTER_PAYMENT}},
      {label: 'Facturé', value: {id: 2, type: StateType.PROMOTER_PAYMENT}}

    ]
  }];

  static typeListOrgans: LabelValue[] = [
    {label: null, value: null},
    {label: 'Famille d\'organe', value: 'Famille d\'organe'},
    {label: 'organe', value: 'organe'},
  ];

  static VisitTypes: SelectItem[] = [
    {label: null, value: null},
    {label: 'Avant inclusion', value: 'BEFORE_INCLUSION'},
    {label: 'Pendant le traitement', value: 'IN_INCLUSION'},
    {label: 'Aprés inclusion', value: 'AFTER_INCLUSION'}
  ];


  static classTherapeutic: LabelValue[] = [
    {label: null, value: null},
    {label: 'Classe thérapeutique', value: 'Classe thérapeutique'},
    {label: 'Sous classe thérapeutique', value: 'Sous classe thérapeutique'},
  ];

  static promoterType: PromoterType[] = [
    {nameFr: null, hhhId: 0},
    {nameFr: 'Académique', hhhId: 2},
    {nameFr: 'Industriel', hhhId: 1},
    {nameFr: 'Institutionnel', hhhId: 3}
  ];
  static readonly contacts: any = [
    {label: 'Professionnel', value: 'Professionnel'},
    {label: 'Personnel', value: 'Personnel'},
    {label: 'Mobile', value: 'Mobile'},
    {label: 'Autre', value: 'Autre'}];
  static readonly faxEmailAddressContactTypes: any = [
    {label: 'Professionnel', value: 'Professionnel'},
    {label: 'Personnel', value: 'Personnel'},
    {label: 'Autre', value: 'Autre'}];
  static readonly contactTypes: any = [
    {label: 'Professionnelle', value: 'Professionnelle'},
    {label: 'Personnelle', value: 'Personnelle'},
    {label: 'Autre', value: 'Autre'}
  ];
  static readonly yesOrNo = [
    {label: 'Non', value: false},
    {label: 'Oui', value: true}
  ];
  static readonly yesOrNoOrNull = [
    {label: '', value: null},
    {label: 'Non', value: false},
    {label: 'Oui', value: true}
  ];
  static readonly yesOrNoOrNa = [
    {label: 'NA', value: null},
    {label: 'Non', value: false},
    {label: 'Oui', value: true}
  ];
  static readonly yesOrNoOrNaOrNeither = [
    {label: '', value: null},
    {label: 'N/A', value: 'NA'},
    {label: 'Non', value: 'NO'},
    {label: 'Oui', value: 'YES'}

  ];
  static readonly yesOrNoOrNeither = [
    {label: '', value: ''},
    {label: 'Non', value: false},
    {label: 'Oui', value: true}
  ];
  static readonly yesOrNoOrNoAnswer = [
    {label: 'Pas de reponse', value: null},
    {label: 'Non', value: false},
    {label: 'Oui', value: true}
  ];
  static readonly yesOrNoOrNoAnswerEnum = [
    {label: 'Pas de reponse', value: 'NO_ANSWER'},
    {label: 'Non', value: 'NO'},
    {label: 'Oui', value: 'YES'}
  ];
  static readonly yesOrNoOrNoPending = [
    {label: '', value: null},
    {label: 'En attente', value: 'PENDING'},
    {label: 'Non', value: 'NO'},
    {label: 'Oui', value: 'YES'}
  ];
  static readonly yesOrNoEnum = [
    {label: 'Non', value: 'NO'},
    {label: 'Oui', value: 'YES'}
  ];
  static readonly activeOptions: any = [
    {label: '', value: ''},
    {label: 'Activé', value: true},
    {label: 'Désactivé', value: false}
  ];
  static readonly titles = [
    {label: '', value: ''},
    {label: 'M', value: 'M'},
    {label: 'Mme', value: 'Mme'},
    {label: 'Pr', value: 'Pr'},
    {label: 'Dr', value: 'Dr'},
    {label: 'Melle', value: 'Melle'}
  ];
  static readonly userTitles = [{label: '', value: ''},
    {label: 'M', value: 'M'},
    {label: 'Mme', value: 'Mme'}];

  static readonly escalationExtensionForDesign = [
    {label: '', value: 0},
    {label: 'Escalade', value: 1},
    {label: 'Extension', value: 2}
  ];

  static readonly escalationExtensionForCoL = [
    {label: '', value: 0},
    {label: 'Ouvert', value: 3},
    {label: 'Double aveugle', value: 4},
    {label: 'Extension', value: 2},
    {label: 'Escalation', value: 5},
    {label: 'NA', value: 6}
  ];

  static readonly escalationExtensionNumberValues = {
    ESCALADE: 1,
    EXTENSION: 2
  };
  static readonly escaladeExtensionStringFormatForDesign = [
    {label: '', value: null},
    {label: 'Escalade', value: 'ESCALADE'},
    {label: 'Extension', value: 'EXTENSION'}
  ];

  static readonly escalationExtensionForDesignForCoL = [
    {label: '', value: null},
    {label: 'Ouvert', value: 'OPEN'},
    {label: 'Double aveugle', value: 'DOUBLE_AVEUGLE'},
    {label: 'Extension', value: 'EXTENSION'},
    {label: 'Escalation', value: 'ESCALATION'},
    {label: 'NA', value: 'NA'}
  ];
  static readonly escaladeExtensionStringFormatForDesignMap = {
    'COL': Data.escalationExtensionForDesignForCoL,
    'OTHERS': Data.escaladeExtensionStringFormatForDesign
  };

  static readonly priorityNumbers = [{label: '', value: null},
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5}];

  static readonly escalationExtension = [
    {label: '', value: ''},
    {label: 'Escalade', value: 'Escalade'},
    {label: 'Escalade/Extension', value: 'Escalade/Extension'},
    {label: 'Extension', value: 'Extension'}
  ];
  static readonly responsibilities: any = [
    {label: '', value: ''},
    {label: 'ARC', value: 'CLINICAL_RESEARCH_ASSISTANT'},
    {label: 'ARC contrat', value: 'CONTRACT_CLINICAL_RESEARCH_ASSISTANT'},
    {label: 'ARC règlementaire', value: 'REGULATORY_CLINICAL_RESEARCH_ASSISTANT'},
    {label: 'Assistant(e)', value: 'ASSISTANT'},
    {label: 'Chargé de qualité', value: 'QUALITY_RESPONSIBLE'},
    {label: 'Chargé de vigilance', value: 'VIGILANCE_RESPONSIBLE'},
    {label: 'Chef de Projet', value: 'PROJECT_MANAGER'},
    {label: 'EMRC', value: 'EMRC'},
    {label: 'IRC', value: 'CLINICAL_RESEARCH_NURSE'},
    {label: 'Infirmier / Infirmière', value: 'CLINICAL_RESEARCH_NURSE'},
    {label: 'Médecin', value: 'DOCTOR'},
    {label: 'Pharmacien', value: 'PHARMACIST'},
    {label: 'Data Manager', value: 'CLINICAL_TRIAL_DATA_MANAGER'},
    {label: 'Assistant(e) médical(e) de recherche', value: 'AMR'},
    {label: 'Gestionnaire de prélèvement', value: 'GP'},
    {label: 'ARC Manager', value: 'ARC_MANAGER'},
    {label: 'Médecin référent', value: 'REFERRING_DOCTOR'},
    {label: 'Méthodologique', value: 'METHODOLOGIST'},
    {label: 'Psychologue', value: 'PSYCHOLOGIST'},
    {label: 'Sage-femme', value: 'MIDWIFE'},
    {label: 'Masseurs-kinésithérapeutes', value: 'Masseurs-kinésithérapeutes'},
    {label: 'Pédicures-podologues', value: 'Pédicures-podologues'},
    {label: 'Ergothérapeutes', value: 'Ergothérapeutes'},
    {label: 'Psychomotriciens', value: 'Psychomotriciens'},
    {label: 'Orthophonistes', value: 'Orthophonistes'},
    {label: 'Orthoptistes', value: 'Orthoptistes'},
    {label: 'Manipulateurs d’électroradiologie médicale', value: 'PHYSIOTHERAPISTS'},
    {label: 'Techniciens de laboratoire médical', value: 'MEDICAL_LABORATORY_TECHNICIANS'},
    {label: 'Audioprothésiste', value: 'AUDIOPROSTHETISTS'},
    {label: 'Opticiens-lunettiers', value: 'OPTICIANS'},
    {
      label: 'Prothésistes et orthésistes pour l’appareillage des personnes handicapées',
      value: 'PROSTHETISTS_AND_ORTHOTISTS'
    },
    {label: 'Diététiciens', value: 'DIETICIANS'},
    {label: 'Comptable', value: 'ACCOUNTANT'}
  ].sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  static readonly shiftedUnits: any = [
    {label: 'Fréquence de la visite', value: '-1'},
    {label: 'J+/-(combien de jours)', value: '0'},
    {label: 'S+/-(combien de semaine)', value: '1'},
    {label: 'M+/-(combien de mois)', value: '2'},
    {label: 'A+/-(combien d\'années)', value: '3'}
  ];
  static readonly paginationOptions: any = [10, 15, 25, 30, 50, 'Tout'];
  static readonly monthList: any = [
    'JANUARY',
    'FEBRUARY',
    'MARS',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER'
  ];
  static readonly country: any = [
    {label: '-- Pays --', value: null},
    {label: 'France', value: null},
    {label: 'Allemagn', value: null},
    {label: 'Belgiqu', value: null},
    {label: 'Canad', value: null},
    {label: 'États-Uni', value: null},
    {label: 'Itali', value: null},
    {label: 'Royaume-Un', value: null},
    {label: 'Afghanista', value: null},
    {label: 'Afrique du Su', value: null},
    {label: 'Albani', value: null},
    {label: 'Algéri', value: null},
    {label: 'Allemagn', value: null},
    {label: 'Andorr', value: null},
    {label: 'Angol', value: null},
    {label: 'Anguill', value: null},
    {label: 'Antarctiqu', value: null},
    {label: 'Antigua-et-Barbud', value: null},
    {label: 'Antilles néerlandaise', value: null},
    {label: 'Arabie saoudit', value: null},
    {label: 'Argentin', value: null},
    {label: 'Arméni', value: null},
    {label: 'Arub', value: null},
    {label: 'Australi', value: null},
    {label: 'Autrich', value: null},
    {label: 'Azerbaïdja', value: null},
    {label: 'Bahama', value: null},
    {label: 'Bahreïn', value: null},
    {label: 'Banglades', value: null},
    {label: 'Barbad', value: null},
    {label: 'Belgiqu', value: null},
    {label: 'Beliz', value: null},
    {label: 'Béni', value: null},
    {label: 'Bermude', value: null},
    {label: 'Bhouta', value: null},
    {label: 'Biélorussi', value: null},
    {label: 'Birmani', value: null},
    {label: 'Bolivi', value: null},
    {label: 'Bosnie-Herzégovin', value: null},
    {label: 'Botswan', value: null},
    {label: 'Brési', value: null},
    {label: 'Brune', value: null},
    {label: 'Bulgari', value: null},
    {label: 'Burkina Fas', value: null},
    {label: 'Burund', value: null},
    {label: 'Cambodg', value: null},
    {label: 'Camerou', value: null},
    {label: 'Canad', value: null},
    {label: 'Cap-Ver', value: null},
    {label: 'Chil', value: null},
    {label: 'Chin', value: null},
    {label: 'Chypr', value: null},
    {label: 'Colombi', value: null},
    {label: 'Comore', value: null},
    {label: 'Corée du Nor', value: null},
    {label: 'Corée du Su', value: null},
    {label: 'Costa Ric', value: null},
    {label: 'Côte d Ivoir', value: null},
    {label: 'Croati', value: null},
    {label: 'Cub', value: null},
    {label: 'Danemar', value: null},
    {label: 'Djibout', value: null},
    {label: 'Dominiqu', value: null},
    {label: 'Égypt', value: null},
    {label: 'Émirats arabes uni', value: null},
    {label: 'Équateu', value: null},
    {label: 'Érythré', value: null},
    {label: 'Espagn', value: null},
    {label: 'Estoni', value: null},
    {label: 'États-Uni', value: null},
    {label: 'Éthiopi', value: null},
    {label: 'Fidj', value: null},
    {label: 'Finland', value: null},
    {label: 'Franc', value: null},
    {label: 'Gabo', value: null},
    {label: 'Gambi', value: null},
    {label: 'Géorgi', value: null},
    {label: 'Ghan', value: null},
    {label: 'Gibralta', value: null},
    {label: 'Grèc', value: null},
    {label: 'Grenad', value: null},
    {label: 'Groenlan', value: null},
    {label: 'Gua', value: null},
    {label: 'Guatemal', value: null},
    {label: 'Guiné', value: null},
    {label: 'Guinée équatorial', value: null},
    {label: 'Guinée-Bissa', value: null},
    {label: 'Guyan', value: null},
    {label: 'Haït', value: null},
    {label: 'Hondura', value: null},
    {label: 'Hong Kon', value: null},
    {label: 'Hongri', value: null},
    {label: 'Île Christma', value: null},
    {label: 'Île de Ma', value: null},
    {label: 'Îles Caïman', value: null},
    {label: 'Îles Coco', value: null},
    {label: 'Îles Coo', value: null},
    {label: 'Îles Féro', value: null},
    {label: 'Îles Mariannes du Nor', value: null},
    {label: 'Îles Marshal', value: null},
    {label: 'Îles Turques-et-Caïque', value: null},
    {label: 'Îles Vierges britannique', value: null},
    {label: 'Îles Vierges des États-Uni', value: null},
    {label: 'Ind', value: null},
    {label: 'Indonésie', value: null},
    {label: 'Ira', value: null},
    {label: 'Ira', value: null},
    {label: 'Irlande', value: null},
    {label: 'Islande', value: null},
    {label: 'Itali', value: null},
    {label: 'Jamaïqu', value: null},
    {label: 'Japo', value: null},
    {label: 'Jordani', value: null},
    {label: 'Kazakhsta', value: null},
    {label: 'Keny', value: null},
    {label: 'Kirghizista', value: null},
    {label: 'Kiribat', value: null},
    {label: 'Koweï', value: null},
    {label: 'Lao', value: null},
    {label: 'Lesoth', value: null},
    {label: 'Lettoni', value: null},
    {label: 'Liba', value: null},
    {label: 'Liberi', value: null},
    {label: 'Liby', value: null},
    {label: 'Liechtenstei', value: null},
    {label: 'Lituani', value: null},
    {label: 'Luxembour', value: null},
    {label: 'Maca', value: null},
    {label: 'Macédoin', value: null},
    {label: 'Madagasca', value: null},
    {label: 'Malaisi', value: null},
    {label: 'Malaw', value: null},
    {label: 'Maldive', value: null},
    {label: 'Mal', value: null},
    {label: 'Malouines', value: null},
    {label: 'Malt', value: null},
    {label: 'Maro', value: null},
    {label: 'Mauric', value: null},
    {label: 'Mauritani', value: null},
    {label: 'Mayott', value: null},
    {label: 'Mexiqu', value: null},
    {label: 'Micronési', value: null},
    {label: 'Moldavi', value: null},
    {label: 'Monac', value: null},
    {label: 'Mongoli', value: null},
    {label: 'Monténégr', value: null},
    {label: 'Montserra', value: null},
    {label: 'Mozambiqu', value: null},
    {label: 'Namibi', value: null},
    {label: 'Naur', value: null},
    {label: 'Népa', value: null},
    {label: 'Nicaraguan', value: null},
    {label: 'Nige', value: null},
    {label: 'Nigeri', value: null},
    {label: 'Niu', value: null},
    {label: 'Norvèg', value: null},
    {label: 'Nouvelle-Calédoni', value: null},
    {label: 'Nouvelle-Zéland', value: null},
    {label: 'Oma', value: null},
    {label: 'Ougand', value: null},
    {label: 'Ouzbékista', value: null},
    {label: 'Pakista', value: null},
    {label: 'Palao', value: null},
    {label: 'Panam', value: null},
    {label: 'Papouasie-Nouvelle-Guiné', value: null},
    {label: 'Paragua', value: null},
    {label: 'Pays-Ba', value: null},
    {label: 'Péro', value: null},
    {label: 'Philippine', value: null},
    {label: 'Pologn', value: null},
    {label: 'Polynésie français', value: null},
    {label: 'Porto Ric', value: null},
    {label: 'Portuga', value: null},
    {label: 'Qata', value: null},
    {label: 'République centrafricain', value: null},
    {label: 'République démocratique du Congotion', value: null},
    {label: 'République dominicaine', value: null},
    {label: 'République du Cong', value: null},
    {label: 'République tchèqu', value: null},
    {label: 'Roumani', value: null},
    {label: 'Royaume-Un', value: null},
    {label: 'Russi', value: null},
    {label: 'Rwand', value: null},
    {label: 'Saint-Barthélem', value: null},
    {label: 'Saint-Christophe-et-Niévè', value: null},
    {label: 'Sainte-Hélène, Ascension et Tristan da Cunh', value: null},
    {label: 'Sainte-Luci', value: null},
    {label: 'Saint-Mari', value: null},
    {label: 'Saint-Marti', value: null},
    {label: 'Saint-Pierre-et-Miquelo', value: null},
    {label: 'Saint-Siège (État de la Cité du Vatican', value: null},
    {label: 'Saint-Vincent-et-les Grenadine', value: null},
    {label: 'Salomo', value: null},
    {label: 'Salvado', value: null},
    {label: 'Samo', value: null},
    {label: 'Samoa américaine', value: null},
    {label: 'Sénéga', value: null},
    {label: 'Serbi', value: null},
    {label: 'Seychelle', value: null},
    {label: 'Sierra Leo', value: null},
    {label: 'Singapou', value: null},
    {label: 'Slovaqui', value: null},
    {label: 'Slovéni', value: null},
    {label: 'Somali', value: null},
    {label: 'Souda', value: null},
    {label: 'Sri Lank', value: null},
    {label: 'Suèd', value: null},
    {label: 'Suiss', value: null},
    {label: 'Surinam', value: null},
    {label: 'Swazilan', value: null},
    {label: 'Syri', value: null},
    {label: 'Tadjikista', value: null},
    {label: 'Taïwa', value: null},
    {label: 'Tanzani', value: null},
    {label: 'Tcha', value: null},
    {label: 'Thaïland', value: null},
    {label: 'Timor orienta', value: null},
    {label: 'Tog', value: null},
    {label: 'Tokela', value: null},
    {label: 'Tong', value: null},
    {label: 'Trinité-et-Tobag', value: null},
    {label: 'Tunisi', value: null},
    {label: 'Turkménista', value: null},
    {label: 'Turqui', value: null},
    {label: 'Tuval', value: null},
    {label: 'Ukrain', value: null},
    {label: 'Urugua', value: null},
    {label: 'Vanuat', value: null},
    {label: 'Venezuel', value: null},
    {label: 'Viêt Na', value: null},
    {label: 'Wallis-et-Futun', value: null},
    {label: 'Yéme', value: null},
    {label: 'Zambi', value: null},
    {label: 'Zimbabw', value: null}
  ];
  static readonly orders: any = [
    {label: 'Croissant', value: 'asc'},
    {label: 'Décroissant', value: 'desc'}
  ];
  static readonly promoterTypeId = 2; // promoter provider has the Id 2 by construction.

  static readonly pieAndDoughnutChartOptions = {
    responsive: true,
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        fontSize: 11
      }
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          const dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          const percentage = value * 100 / sum;
          return percentage > 10 ? Math.round(percentage) + '%' : '';
        },
        color: 'white',
        font: {
          weight: 'bold',
          size: 14,
          family: 'OpenSans Bold'
        }
      }
    }
  };

  static readonly barChartOptions = {
    responsive: true,
    title: {
      display: false
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            beginAtZero: true,
            maxTicksLimit: 10,
            callback(value) {
              if (value % 1 === 0) {
                return value;
              }
            }
          },
          autoSkip: true
        }
      ]
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };

  static readonly histogramOptions = {
    responsive: true,
    title: {
      display: false
    },
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        align: 'end',
        formatter: (value) => {
          const formattedValue = parseFloat(value.toFixed(2));
          return formattedValue === 0 ? null : formattedValue;
        }
      }
    }
  };

  static readonly moduleList: any = [
    {label: '', value: 0},
    {label: 'Études', value: 1},
    {label: 'Établissement', value: 2},
    {label: 'Agenda', value: 3},
    {label: 'Patients', value: 4},
    {label: 'Prestataires', value: 5},
    {label: 'Sites', value: 6},
    {label: 'Ressources humaines', value: 7},
    {label: 'Grille budgétaire', value: 8},
    {label: 'Facturation', value: 9},
    {label: 'Statistiques', value: 10},
    {label: 'Import de données', value: 11}
  ];

  static readonly OmsEcogOptions: SelectItem[] = [];
  static readonly IndicatorsAndInclusionOrgansOptions: SelectItem[] = [];
  static readonly InclusionHistologiesOptions: SelectItem[] = [];
  static readonly InclusionBiomarkersOptions: SelectItem[] = [];
  static readonly BiopsiesOptions: SelectItem[] = [
    {label: 'Facultative', value: 'OPTIONAL'},
    {label: 'Archivée', value: 'ARCHIVED'},
    {label: 'Obligatoire(s)', value: 'OBLIGATORY'},
    {label: 'pré/on/post', value: 'PRE_ON_POST'},
    {label: 'Autre', value: 'OTHER'}];
  static readonly OtherexclusionCriteriaOptions: SelectItem[] = [
    {label: 'Métastase cérébrale non traitée', value: 'UNTREATED_BRAIN_METASTASIS'},
    {label: 'Toxicités résiduelles', value: 'RESIDUAL_TOXICITIES'},
    {label: 'Comorbidités', value: 'COMORBIDITIES'},
    {label: 'Auto-immunités non stabilisées', value: 'UNSTABILIZED_AUTOIMMUNITIES'},
    {label: 'Autres', value: 'OTHER'}];

  static readonly ContractTypes: SelectItem[] = [
    {label: 'ACA', value: 'ACA'},
    {label: 'GR', value: 'GR'},
    {label: 'CU', value: 'CU'},
    {label: 'CU industriel', value: 'INDUSTRIAL_CU'},
    {label: 'CU conventionnel', value: 'CONVENTIONAL_CU'},
    {label: 'Promotion interne', value: 'INTERNAL_PROMOTION'},
    {label: 'GR+CU', value: 'GR_CU'},
    {label: 'CU#2', value: 'CU_2'},
    {label: 'CU#3', value: 'CU_3'},
    {label: 'Autres', value: 'Autres'}
  ];

  static readonly ReferenceAdditionalCostTypes: SelectItem[] = [
    {label: 'Surcoût', value: 'ADDITIONAL_COST'},
    {label: 'Prestation+ Prestation Spécifique', value: 'SERVICE_SPECIFIC_SERVICE'},
    {label: 'Recherche Clinique', value: 'CLINICAL_RESEARCH'},
    {label: 'Laboratoires', value: 'LABORATORIES'},
    {label: 'Imagerie', value: 'IMAGERIE'},
    {label: 'Consultations', value: 'CONSULTATIONS'},
    {label: 'Logistique Médicale', value: 'MEDICAL_LOGISTICS'},
    {label: 'HOSPITALISATION', value: 'HOSPITALIZATION'}
  ];

  static readonly TvaOptions: SelectItem[] = [
    {label: '', value: null},
    {label: '20%', value: '20'},
    {label: 'non applicable', value: 'NOT_APPLICABLE'},
    {
      label: 'Autoliquidation par le preneur , art 283-2 du CGI',
      value: 'REVERSE CHARGE_BY_THE_LESSEE_ART_283_2_OF_THE_CGI_BILLING_ADDRESS_IN_THE_EUROPEAN_ZONE_EXCLUDING_FRANCE'
    },
    {
      label: 'Exonération de Tva - Art 259-1 du CGI',
      value: 'EXEMPTION_FROM_VAT_ART_259_1_OF_THE_CGI_BILLING_ADDRESS_ABROAD_OUTSIDE_EUROPE'
    }];

  static readonly TvaOptionsForCOL: SelectItem[] = [
    {label: '', value: null},
    {label: '20%', value: '20'},
    {label: 'non applicable', value: 'NOT_APPLICABLE'},
    {
      label: 'Autoliquidation par le preneur , art 283-2 du CGI',
      value: 'REVERSE CHARGE_BY_THE_LESSEE_ART_283_2_OF_THE_CGI_BILLING_ADDRESS_IN_THE_EUROPEAN_ZONE_EXCLUDING_FRANCE'
    },
    {
      label: 'Exonération de Tva',
      value: 'EXEMPTION_FROM_VAT_ART_259_1_OF_THE_CGI_BILLING_ADDRESS_ABROAD_OUTSIDE_EUROPE'
    }];

  static readonly TvaOptionsForCHRO: SelectItem[] = [
    {label: '0', value: '0'},
    {label: '20%', value: '20'}];

  static readonly ImputationAccountOptions: SelectItem[]  = [
    { label: '754811', value: '754811' },
    { label: '754815', value: '754815' },
    { label: '754816', value: '754816' },
    { label: '754817', value: '754817' },
    { label: '7721', value: '7721' }
  ];

  static readonly FeasibilityRetainedOotions: SelectItem[] = [
    {label: '', value: null},
    {label: 'oui', value: 'YES'},
    {label: 'non', value: 'NO'},
    {label: 'en cours', value: 'PENDING'}];

  static readonly gender: SelectItem[] = [
    {label: '', value: null},
    {label: 'H', value: 'Homme'},
    {label: 'F', value: 'Femme'}
  ];

  static readonly states: SelectItem[] = [
    {label: '', value: null},
    {label: 'En attente de réception', value: 'WAITING_FOR_RECEPTION'},
    {label: 'Receptionné', value: 'RECEIVED'},
    {label: 'Disponible', value: 'AVAILABLE'},
    {label: 'En cours de dispensation', value: 'DURING_DISPENSATION'},
    {label: 'En quarantaine', value: 'IN_QUARANTINE'},
    {label: 'Périmé', value: 'EXPIRED'},
    {label: 'Détruit', value: 'DESTROYED'},
    {label: 'Renvoyé', value: 'FIRED'}
  ];

  static readonly events: SelectItem[] = [
    {label: '', value: null},
    {label: 'Quarantaine', value: 'QUARANTINE'},
    {label: 'Quarantaine terminée', value: 'QUARANTINE_COMPLETED'},
    {label: 'NA', value: 'NA'},
    {label: 'Autre', value: 'OTHER'}
  ];

  static readonly FeasibilityRetainedOptions: SelectItem[] = [
    {label: '', value: null},
    {label: 'Non', value: 'NO'},
    {label: 'Oui', value: 'YES'},
    {label: 'En cours', value: 'PENDING'},

  ];
 /*  static readonly ConvetionStatus: SelectItem[] = [
    {label: '', value: null},
    {label: 'signature DG', value: 'DG_SIGNATURE'},
    {label: 'signature IP', value: 'IP_SIGNATURE'},
    {label: 'signatures OK', value: 'SIGNATURES OK'},
    {label: 'abandonné', value: ''},
    {label: 'NA', value: 'NA'},

  ]; */
  static readonly CecNoticeOptions: SelectItem[] = [
    {label: '', value: null},
    {label: 'favorable', value: 'FAVORABLE'},
    {label: 'suspensif', value: 'SUSPENSIVE'},
    {label: 'defavorable', value: 'UNFAVORABLE'},
    {label: 'abandonné', value: 'ABANDONED'},
    {label: 'NA', value: 'NA'}
  ];

  static readonly followUpMotif = [
    { label: 'Protocolaire', value: 'Protocolaire' },
    { label: 'Décès', value: 'Décès' },
    { label: 'Retrait consentement sans utilisation des données', value: 'Retrait consentement sans utilisation des données' },
    { label: 'Retrait consentement avec utilisation des données', value: 'Retrait consentement avec utilisation des données' },
    { label: 'Décision investigateur', value: 'Décision investigateur' },
    { label: 'décision promoteur', value: 'décision promoteur' },
    { label: 'perdu de vue', value: 'perdu de vue' }
  ];

  static readonly genderWithNa: SelectItem[] = [
    {label: '', value: null},
    {label: 'H', value: 'Homme'},
    {label: 'F', value: 'Femme'},
    {label: 'N/A', value: 'NA'}
  ];
  static readonly pendingOrValidOptions: SelectItem[] = [
    {label: '', value: null},
    {label: 'En cours', value: 'PENDING'},
    {label: 'Validé', value: 'VALID'}
  ];

  static readonly positiveOrNegative = [
    {label: '', value: null},
    {label: 'Négatif', value: false},
    {label: 'Positif', value: true}
  ];

  static readonly fromOneToNineItems = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9}
  ];

  static readonly monthsList: SelectItem[] = [
    {label: '', value: null},
    {label: 'Jan', value: 1},
    {label: 'Fév', value: 2},
    {label: 'Mars', value: 3},
    {label: 'Avr', value: 4},
    {label: 'Mai', value: 5},
    {label: 'Juin', value: 6},
    {label: 'Juil', value: 7},
    {label: 'Août', value: 8},
    {label: 'Sept', value: 9},
    {label: 'Oct', value: 10},
    {label: 'Nov', value: 11},
    {label: 'Déc', value: 12}
  ];

  static readonly auditModules: SelectItem[] = [
    {label: 'Identification', value: 'IDENTIFICATION'},
    {label: 'Administrative', value: 'ADMINISTRATIVE'},
    {label: 'Inclusion', value: 'INCLUSION'},
    {label: 'Calendrier théorique', value: 'THEORETICAL_CALENDAR'},
    {label: 'Grille budgétaire', value: 'ACTS_GRID'},
    {label: 'Pharmacie', value: 'PHARMACY'}
  ];

  static readonly externalDoctorToExternalEstablishments: Map<string, SelectItem> = new Map<string, SelectItem>([
    ['BARRIERE Jérôme', {label: 'Clinique St Jean_Cagnes sur Mer', value: 'Clinique St Jean_Cagnes sur Mer'}],
    ['BERDAH Jean-François', {label: 'CH Castellucio_Ajaccio', value: 'CH Castellucio_Ajaccio'}],
    ['BLANCHOUIN Elea', {label: 'CH_Cannes', value: 'CH_Cannes'}],
    ['BOUREDJI Kenza', {label: 'CH_Antibes', value: 'CH_Antibes'}],
    ['BORY Pierre', {label: 'Clinique Maymard_Bastia', value: 'Clinique Maymard_Bastia'}],
    ['CANOVA Charles', {label: 'Clinique St Georges_Nice', value: 'Clinique St Georges_Nice'}],
    ['CASSUTO Ophélie', {label: 'Clinique St Georges_Nice', value: 'Clinique St Georges_Nice'}],
    ['CASTELNAU Olivier', {label: 'Institut Arnault Tzanck', value: 'Institut Arnault Tzanck'}],
    ['CHEVALLIER Daniel', {label: 'CHU Nice', value: 'CHU Nice'}],
    ['ESCURE Paul', {label: 'Cabinet de ville_Nice', value: 'Cabinet de ville_Nice'}],
    ['FALK Alexandre', {label: 'CAC_Mougins', value: 'CAC_Mougins'}],
    ['FRIKHA Ahmed', {label: 'Clinique Maymard_Bastia', value: 'Clinique Maymard_Bastia'}],
    ['FRIN Anne-Claire', {label: 'CHU Nice', value: 'CHU Nice'}],
    ['FREYER Gilles', {label: 'Hospices Civils Lyon', value: 'Hospices Civils Lyon'}],
    ['GARNIER Georges', {label: 'CHPG_Monaco', value: 'CHPG_Monaco'}],
    ['GUELFUCCI Bruno', {label: 'CH St Musse_Toulon', value: 'CH St Musse_Toulon'}],
    ['GUERRIERI Michel', {label: 'Clinique St Georges_Nice', value: 'Clinique St Georges_Nice'}],
    ['GUILLET Pierre', {label: 'CH St Musse_Toulon', value: 'CH St Musse_Toulon'}],
    ['GUTNECHT Jean', {label: 'CH Fréjus-St Raphael', value: 'CH Fréjus-St Raphael'}],
    ['HASTIER Audrey',	{label: 'CHPG_Monaco', value: 'CHPG_Monaco'}],
    ['HOCH Benjamin',	{label: 'CAC_Mougins', value: 'CAC_Mougins'}],
    ['KAPHAN Régis',	{label: 'CH_Cannes', value: 'CH_Cannes'}],
    ['KARIMDJEE Babou',	{label: 'Clinique St Jean_Cagnes sur Mer', value: 'Clinique St Jean_Cagnes sur Mer'}],
    ['KOGAY Maria',	{label: 'CHPG_Monaco', value: 'CHPG_Monaco'}],
    ['KREITMAN Thomas',	{label: 'CAC_Mougins', value: 'CAC_Mougins'}],
    ['LARGILLIER Rémy', {label: 'CAC_Mougins', value: 'CAC_Mougins'}],
    ['LESCAUT Willy', {label: 'CHPG_Monaco', value: 'CHPG_Monaco'}],
    ['LEYSALLE Axel', {label: 'CAC_Mougins', value: 'CAC_Mougins'}],
    ['NAMAN Hervé', {label: 'CAC_Mougins', value: 'CAC_Mougins'}],
    ['ORTHOLAN Cécile', {label: 'CHPG_Monaco', value: 'CHPG_Monaco'}],
    ['OUVRIER Delphine', {label: 'CHU Nice', value: 'CHU Nice'}],
    ['PETIT Emmanuel', {label: 'CH_Cannes', value: 'CH_Cannes'}],
    ['RE Daniel', {label: 'CH_Antibes', value: 'CH_Antibes'}],
    ['RONCHIN Philippe', {label: 'CAC_Mougins', value: 'CAC_Mougins'}],
    ['ROSSIGNOL Benoît', {label: 'Centre Hospitalier Dracénie', value: 'Centre Hospitalier Dracénie'}],
    ['ROTOMONDO Christine', {label: 'CH_Antibes', value: 'CH_Antibes'}],
    ['SAUDES Laurence', {label: 'CH_Cannes', value: 'CH_Cannes'}],
    ['SKAF Richard', {label: 'Clinique St Georges_Nice', value: 'Clinique St Georges_Nice'}],
    ['TAILLAN Bruno', {label: 'CHPG_Monaco', value: 'CHPG_Monaco'}],
    ['TEISSIER Eric', {label: 'CAC_Mougins', value: 'CAC_Mougins'}],
    ['TIBI Brannwel', {label: 'CHU Nice', value: 'CHU Nice'}],
    ['TOUBOL Jacques', {label: 'CHU Nice', value: 'CHU Nice'}],
    ['VALENZA Bruno', {label: 'CH Fréjus-St Raphael', value: 'CH Fréjus-St Raphael'}],
    ['VOCILA Flavia', {label: 'CH_Cannes', value: 'CH_Cannes'}]
  ]);

  static readonly externalEstablishmentsToExternalDoctors: Map<string, SelectItem[]> = new Map<string, SelectItem[]>([
    ['Clinique St Jean_Cagnes sur Mer', [{label: 'BARRIERE Jérôme', value: 'BARRIERE Jérôme'}, {label: 'KARIMDJEE Babou', value: 'KARIMDJEE Babou'}]],
    ['CH Castellucio_Ajaccio', [ {label: 'BERDAH Jean-François', value: 'CBERDAH Jean-François'} ]],
    ['CH_Antibes', [
      {label: 'BOUREDJI Kenza', value: 'BOUREDJI Kenza'},
      {label: 'RE Daniel', value: 'RE Daniel'},
      {label: 'ROTOMONDO Christine', value: 'ROTOMONDO Christine'}
    ]],
    ['Clinique Maymard_Bastia', [
      {label: 'BORY Pierre', value: 'BORY Pierre'},
      {label: 'FRIKHA Ahmed', value: 'FRIKHA Ahmed'}
    ]],
    ['Clinique St Georges_Nice', [
      {label: 'CANOVA Charles', value: 'CANOVA Charles'},
      {label: 'CASSUTO Ophélie', value: 'CASSUTO Ophélie'},
      {label: 'GUERRIERI Michel', value: 'GUERRIERI Michel'},
      {label: 'SKAF Richard', value: 'SKAF Richard'}
    ]],
    ['Institut Arnault Tzanck', [
      {label: 'CASTELNAU Olivier', value: 'CASTELNAU Olivier'}
    ]],
    ['CHU Nice', [
      {label: 'CHEVALLIER Daniel', value: 'CHEVALLIER Daniel'},
      {label: 'FRIN Anne-Claire', value: 'FRIN Anne-Claire'},
      {label: 'OUVRIER Delphine', value: 'OUVRIER Delphine'},
      {label: 'TIBI Brannwel', value: 'TIBI Brannwel'},
      {label: 'TOUBOL Jacques', value: 'TOUBOL Jacques'}
    ]],
    ['Cabinet de ville_Nice', [
      {label: 'ESCURE Paul', value: 'ESCURE Paul'}
    ]],
    ['CAC_Mougins', [
      {label: 'FALK Alexandre', value: 'FALK Alexandre'},
      {label: 'HOCH Benjamin', value: 'HOCH Benjamin'},
      {label: 'KREITMAN Thomas', value: 'KREITMAN Thomas'},
      {label: 'LARGILLIER Rémy', value: 'LARGILLIER Rémy'},
      {label: 'LEYSALLE Axel', value: 'LEYSALLE Axel'},
      {label: 'NAMAN Hervé', value: 'NAMAN Hervé'},
      {label: 'RONCHIN Philippe', value: 'RONCHIN Philippe'},
      {label: 'TEISSIER Eric', value: 'TEISSIER Eric'}
    ]],
    ['Hospices Civils Lyon', [
      {label: 'FREYER Gilles', value: 'FREYER Gilles'}
    ]],
    ['CHPG_Monaco', [
      {label: 'GARNIER Georges', value: 'GARNIER Georges'},
      {label: 'HASTIER Audrey', value: 'HASTIER Audrey'},
      {label: 'KOGAY Maria', value: 'KOGAY Maria'},
      {label: 'LESCAUT Willy', value: 'LESCAUT Willy'},
      {label: 'ORTHOLAN Cécile', value: 'ORTHOLAN Cécile'},
      {label: 'TAILLAN Bruno', value: 'TAILLAN Bruno'}
    ]],
    ['CH St Musse_Toulon', [
      {label: 'GUELFUCCI Bruno', value: 'GUELFUCCI Bruno'},
      {label: 'GUILLET Pierre', value: 'GUILLET Pierre'}
    ]],
    ['CH Fréjus-St Raphael', [
      {label: 'GUTNECHT Jean', value: 'GUTNECHT Jean'},
      {label: 'VALENZA Bruno', value: 'VALENZA Bruno'}
    ]],
    ['Centre Hospitalier Dracénie', [
      {label: 'ROSSIGNOL Benoît', value: 'ROSSIGNOL Benoît'}
    ]]
  ]);

  constructor() {
  }

  static getYearsTrialDate(): SelectItem[] {
    const years = [];
    const year = (new Date()).getFullYear() + 10;
    for (let i = year; i > 1980; i -= 1) {
      years.push({label: i.toString(), value: i});
    }
    return years;
  }

  static getYears(): SelectItem[] {
    const years = [];
    for (let i = 1900; i < (new Date()).getFullYear() + 30; i += 1) {
      years.push({label: i.toString(), value: i});
    }
    return years;
  }
}

import {Util} from '../../helpers/util';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {DataCommentCount} from '../../data-comment/entity/data-comment-count';

export class TrialInclusionExclusionCriteria extends DataCommentCount {
  hhhId: number;
  protocolId: number;
  trialId: number;

  biopsiesRequired: boolean;
  minAge: string;
  maxAge: string;
  numberOfPreviousTreatmentLines: string;
  inclusionCriteria: string;
  otherSpecificCriteria: string;
  otherSpecificCriteriaSingle: string;
  inclusionExclusionComments: string;
  inclusionCriteriaComment: string;
  preTreatmentNotAuthorized: string;
  hasMolecularPreScreening: boolean;
  hasBiomarker: boolean;
  sex: string[];

  biopsies: number[] = [];
  omsEcog: number[] = [];
  organList: string[] = [];
  organFamiliesOnly: number[] = [];
  organsOnly: string[] = [];
  inclusionHistologies: number[] = [];
  inclusionBiomarkers: number[] = [];
  otherExclusionCriteria: number[] = [];
  molecularAnomalyTypes: number[] = [];
  molecularAnomalyType: number;
  hasProvider: boolean;
  providerName: string;

  illnessStates: number[] = [];
  inclusionPreScreeningMolecules: string[] = [];
  treatmentLineDetails: string;
  trialTreatmentsHhhId: number[] = [];

  establishmentDepartmentHhhId: number;
  illnessClassification: string;
  interventionalProtocol: string;
  researchTypeHhhId: number;
  researchTypeOtherValue: string;
  trialTypesHhhId: number[] = [];
  researchObjectHhhIds: number;
  researchFieldOtherValue: string;
  researchNatureHhhId: number;
  researchNatureOtherValue: string;
  interventionTypesHhhId: number[] = [];
  metastaticLinesHhhId: number[] = [];
  dmTrial: string;
  trialPopulationHhhId: number;

  init(obj: any): TrialInclusionExclusionCriteria {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  isValid(
    config: FormConfig,
    metastaticLineHhhId: number[],
    researchTypeOtherValue?: boolean,
    researchFieldOther?: boolean,
    researchNatureOther?: boolean
  ): boolean {
    const isValidForm = true;
    const fieldValidations: Map<string, () => boolean> = new Map([
      ['metastaticLines', () => Util.isNullOrUndefinedOrEmpty(metastaticLineHhhId)],
      ['treatmentLines', () => Util.isNullOrUndefinedOrEmpty(this.trialTreatmentsHhhId)],
      ['inclusionPreScreeningMolecules', () => this.hasMolecularPreScreening && Util.isNullOrUndefinedOrEmpty(this.inclusionPreScreeningMolecules)],
      ['providerName', () => this.hasProvider && Util.isNullOrUndefinedOrEmpty(this.providerName)],
      ['inclusionBiomarkers', () => this.hasBiomarker && Util.isNullOrUndefinedOrEmpty(this.inclusionBiomarkers)],
      ['maxAge', () => Util.isNullOrUndefinedOrEmpty(this.maxAge)],
      ['biopsies', () => this.biopsiesRequired && Util.isNullOrUndefinedOrEmpty(this.biopsies)],
      ['comments', () => Util.isNullOrUndefinedOrEmpty(this.inclusionExclusionComments)],
      ['researchTypeOtherValue', () => this.researchTypeHhhId && researchTypeOtherValue && Util.isNullOrUndefinedOrEmpty(this.researchTypeOtherValue)],
      ['researchObjectOtherValue', () => this.researchObjectHhhIds && researchFieldOther && Util.isNullOrUndefinedOrEmpty(this.researchFieldOtherValue)],
      ['researchNatureOtherValue', () => this.researchNatureHhhId && researchNatureOther && Util.isNullOrUndefinedOrEmpty(this.researchNatureOtherValue)],
      ['researchType', () => Util.isNullOrUndefinedOrEmpty(this.researchTypeHhhId)],
      ['interventionTypes', () => Util.isNullOrUndefinedOrEmpty(this.interventionTypesHhhId)],
      ['researchNature', () => Util.isNullOrUndefinedOrEmpty(this.researchNatureHhhId)],
      ['service', () => Util.isNullOrUndefinedOrEmpty(this.establishmentDepartmentHhhId)],
      ['researchObject', () => Util.isNullOrUndefinedOrEmpty(this.researchObjectHhhIds)],
      ['trialTypes', () => Util.isNullOrUndefinedOrEmpty(this.trialTypesHhhId)],
      ['trialPopulation', () => Util.isNullOrUndefinedOrEmpty(this.trialPopulationHhhId)],
    ]);

    fieldValidations.forEach((validation, key) => {
      const required = config.isFieldRequired(key);
      if (required && validation()) {
        return false;
      }
    });

    return isValidForm;
  }

}

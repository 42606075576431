import {Injectable} from '@angular/core';
import {SyntheticTrialAct} from '../../../entity/theoretical-calendar/synthetic-trial-act';

@Injectable({
  providedIn: 'root'
})
export class SyntheticTrialActDataService {

  syntheticTrialAct: SyntheticTrialAct = new SyntheticTrialAct();

  constructor() { }

  refresh(): void {
    this.syntheticTrialAct = new SyntheticTrialAct();
  }
}

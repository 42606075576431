import {PageLockNavbarComponent} from './page-lock-navbar/page-lock-navbar.component';
import {StompSubscription} from '@stomp/stompjs/src/stomp-subscription';
import {StompService} from '../../../shared/services/stomp.service';

export class PageLockWebSocketService {
  pageLockNavbarComponent: PageLockNavbarComponent;

  private websocketSubscription: StompSubscription;
  private stompService: StompService;

  constructor(pageLockNavbarComponent: PageLockNavbarComponent) {
    this.pageLockNavbarComponent = pageLockNavbarComponent;
    this.stompService = pageLockNavbarComponent.stompService;
  }

  connect() {
    console.log('connect socket');
    this.stompService.subscribe('/topic/is-page-locked', (sdkEvent) => {
      this.onIsLockedUpdated(sdkEvent);
    }, subscription => this.websocketSubscription = subscription);
  }

  close() {
    console.log('close socket');
    if (this.websocketSubscription) {
      this.stompService.unsubscribe(this.websocketSubscription);
    }
  }

  errorCallBack(error) {
    setTimeout(() => {
    }, 5000);
  }

  update(trialId) {
    this.stompService.send('/app/is-locked', trialId);
  }

  onIsLockedUpdated(concernedTrialId) {
    this.pageLockNavbarComponent.handleIsLockedUpdate(concernedTrialId.body);
  }
}

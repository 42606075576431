import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TrialService} from '../../../services/trial.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {OrganService} from '../../../services/organ.service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {Router} from '@angular/router';
import {TrialDisciplineIdentification} from '../../../models/trial-discipline-identification';
import {TrialDisciplineInformationService} from '../../../services/trial-discipline-information.service';
import {TrialInformationGenericComponent} from '../trial-information-generic/trial-information-generic.component';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';

@Component({
  selector: 'ih-discipline-identification-information',
  templateUrl: './discipline-identification-information.component.html',
  styleUrls: ['./discipline-identification-information.component.css']
})
export class DisciplineIdentificationInformationComponent extends TrialInformationGenericComponent<TrialDisciplineIdentification> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_DISCIPLINE_INFORMATION_FORM;
  config = new FormConfig();
  maxAgeEnabled = true;

  @Input() mode: string;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  public organsList: { label, value }[];
  researchNatureOther = false;
  researchFieldOther = false;
  researchTypeOther = false;
  maxSelectedLabels: number;
  private trialHhhId: number;

  constructor(
      public trialDisciplineInformationService: TrialDisciplineInformationService,
      public trialService: TrialService,
      private organService: OrganService,
      private router: Router,
      private cfr: ComponentFactoryResolver,
      private sharedService: SharedService,
      private canDeactivateService: CanDeactivateService,
      private dynamicConfigService: DynamicConfigService,
  ) {
    super(new TrialDisciplineIdentification());
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.mode = 'edit';
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initFormConfig();
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
    if (this.trialHhhId) {
      this.getTrialDiscipline();
    } else {
      super.getUnsavedTrialInformationGeneral();
    }
    this.getOrganList();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getService() {
    return this.trialDisciplineInformationService;
  }

  getTrialDiscipline() {
    this.trialDisciplineInformationService.load(this.trialHhhId).subscribe(res => {
      this.trialInformation = res;
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred in trials$.' + error);
    });
  }

  getOrganList() {
    this.organService.getOrgansAndOrganFamilies().subscribe(
        res => {
          this.organsList = res;
          this.maxSelectedLabels = res.length;
        }, error => {
          this.sharedService.showFailure();
          console.error('An error occurred while loading organ list.' + error);
        }
    );
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.trialInformation.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialDiscipline, this.trialInformation.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.trialInformation.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  isModeEdit() {
    return this.mode === 'edit';
  }

  notifyWhenResearchNatureOther(otherValue) {
    this.researchNatureOther = otherValue;
  }

  notifyWhenResearchObjectOther(otherValue) {
    this.researchFieldOther = otherValue;
  }

  notifyWhenResearchTypeOther(otherValue) {
    this.researchTypeOther = otherValue;
  }

  formHasBeenChanged() {
    this.sendNewTrialIdentificationInfo();
    this.canDeactivateService.canBeDeactivated = false;
  }

  saveDisciplineInformation() {
    if (!this.trialInformation.isValid(this.config, this.researchTypeOther, this.researchFieldOther, this.researchNatureOther)) {
      return;
    }
    this.trialInformation.trialHhhId = this.trialHhhId;
    this.trialDisciplineInformationService.updateDisciplineInformation(this.trialHhhId, this.trialInformation).subscribe(() => {
          this.sharedService.showSuccess();
        }, error => {
          console.error('An error occurred while update trial identification.' + error);
          this.sharedService.showFailure();
        }
    );
  }

  sendNewTrialIdentificationInfo() {
    this.trialDisciplineInformationService.setTrialIdentificationInfo(this.trialInformation);
  }

  isEditMode() {
    return this.mode === 'edit';
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);

}

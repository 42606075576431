import {ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InvoiceAct} from '../invoice-acts/models/invoice-act';
import {Router} from '@angular/router';
import {InvoiceActs} from '../invoice-acts/models/invoice-acts';
import {InvoiceActService} from '../invoice-acts/services/invoice-act.service';
import {DataService} from '../../shared/services/data-service';
import {TrialService} from '../../trial/services/trial.service';
import {InputType} from '../../input-type';
import {InvoiceInformationService} from '../invoice-information/services/invoice-information.service';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {AdditionalCostState} from '../../additional-cost-state.enum';
import {InvoiceService} from '../services/invoice.service';
import {InvoiceState} from '../../invoice-state.enum';
import {VisitActive} from '../../shared/entity/visit-active';
import {HiddenFields} from '../../admin/models/HiddenFields';
import {UsersService} from '../../admin/services/users.service';
import {AuthenticationService} from '../../login/services/authentication.service';
import {VisitAct} from '../../trial/entity/inclusion/visit-act';
import {SharedService} from '../../shared/services/shared.service';
import {isDate, isNullOrUndefined} from 'util';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {SubInvoiceGlobalFilter} from '../models/sub-invoice-global-filter';
import {AdditionalCostType} from '../../shared/entity/additional-cost-type.enum';
import {FR} from '../../shared/entity/calendar-language';
import {LabelValue} from '../../shared/entity/label-value';
import {VisitSharedService} from '../../shared/services/visit-shared.service';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {InvoiceAppData} from '../Invoice-app-data';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {Data} from '../../shared/entity/data';
import {AppData} from '../../helpers/app-data';
import {ResponsePage} from '../../shared/entity/response-page';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {Util} from '../../helpers/util';


@Component({
    selector: 'ih-invoice-act-list',
    templateUrl: './invoice-act-list.component.html',
    styleUrls: ['./invoice-act-list.component.css']
})
export class InvoiceActListComponent extends PaginatorTableComponent<InvoiceAct> implements OnInit {

    target = DynamicDefinitionEnum.INVOICE_ACT_LIST_TABLE;
    config: TableConfig;
    headers: DynamicTableHeader[] = [];
    enablePreInvoicePriceEditing = false;
    enableAddActFromValidatedInvoice = true;


    @Input() actState: string;
    @Input() selectable = true;
    @Input() showExportButton = true;
    @Output() changeInvoiceActList = new EventEmitter<any>();

    @Input() set actList(refreshList) {
        if (refreshList.length > 0) {
            this.getList();
        }
    }

    invoiceHhhId: number;
    invoiceActs: InvoiceAct[] = [];
    selectedInvoiceActList: InvoiceAct[] = [];
    unselectedActs: number[] = [];
    actsToInvoice: InvoiceActs = new InvoiceActs();
    actionDisabled: boolean;
    displayDeleteActDialog: boolean;
    totalInvoiceActList = 0;
    isDisplaySaveButton = false;
    isUserAdmin = false;
    updateConfirmationDialogVisible = false;
    visitActsToUpdate: Array<VisitAct> = new Array<VisitAct>();
    enabledPriceEditing = false;
    first = 0;
    filters: SubInvoiceGlobalFilter = new SubInvoiceGlobalFilter();
    locale = FR;
    private visitNameOptions: LabelValue[];
    isAllSelected = false;
    retrocessionFees = false;
    fromDate: Date;
    toDate: Date;

    inclusionIdentifier1Items: LabelValue[] = [];
    inclusionIdentifier2Items: LabelValue[] = [];
    inclusionPreScreeningIdentifierItems: LabelValue[] = [];

    constructor(
        private cfr: ComponentFactoryResolver,
        private dataService: DataService,
        private useService: UsersService,
        private trialService: TrialService,
        private invoiceService: InvoiceService,
        private invoiceInformationService: InvoiceInformationService,
        private invoiceActService: InvoiceActService,
        private router: Router,
        private sharedService: SharedService,
        private authenticationService: AuthenticationService,
        private translateService: InnohealthTranslateService,
        private datePipe: DatePipe,
        private dynamicConfigService: DynamicConfigService,
        private visitSharedService: VisitSharedService) {
        super(dataService, cfr);
        const url = this.router.parseUrl(this.router.url);
        this.invoiceHhhId = url.queryParams.invoiceHhhId;
        this.retrocessionFees = url.toString().indexOf('retrocession-fees') !== -1;
    }

    get additionalCostState() {
        return AdditionalCostState;
    }

    get inputType() {
        return InputType;
    }

    async ngOnInit() {
        this.initProperties();
        this.limit = 50;
        this.sortBy = 'hhhId';
        this.orderBy = 'asc';
        this.isUserAdmin = this.authenticationService.currentUserRole === 'ADMIN';
        if (this.actState === AdditionalCostState.Pending || this.actState === AdditionalCostState.Invoiced) {
            this.dataService.setExportPageModule(this.getExportPageModule());
        }
        await this.getList();
        this.getInvoiceStateCode();
        this.initTableConfig();
    }

    initProperties(): void {
        this.dynamicConfigService.initProperties().subscribe(() => {
            this.enablePreInvoicePriceEditing = this.dynamicConfigService.getProperty(PropertyEnum.preInvoiceEnabled);
            this.enableAddActFromValidatedInvoice = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
            this.enabledPriceEditing = this.enablePreInvoicePriceEditing;
        });
    }

    initTableConfig(): void {
        this.dynamicConfigService.getTableConfig(this.target, InvoiceAppData.tableConfig).subscribe(
            config => {
                this.config = config;
                this.buildTableHeaders();
            }
        );
    }

    buildTableHeaders(): void {
        const availableHeaders = [];
        availableHeaders.push(new DynamicTableHeaderBuilder()
            // TODO: refactor verifyClient condition, implement functionality to allow user to choose filterType from console.
            .field('inclusionScreeningIdentifier')
            .filterable()
            .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
            .options(this.dynamicConfigService.verifyClient(['igr']) ? this.inclusionIdentifier2Items : null)
            .filter(this.filters.inclusionScreeningIdentifier)
            .sortable()
            .sortField(this.dynamicConfigService.verifyClient(['col']) ? 'visit.calendar.inclusion.inclusionIdentifier2' : 'inclusion.inclusionIdentifier2')
            .linkClickEvent(((data: InvoiceAct) => this.displayInclusionByNumber(data.inclusionHhhId, data.trialHhhId)))
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('inclusionPreScreeningIdentifier')
            .filterable()
            .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
            .options(this.dynamicConfigService.verifyClient(['igr']) ? this.inclusionPreScreeningIdentifierItems : null)
            .filter(this.filters.inclusionPreScreeningIdentifier)
            .sortable()
            .sortField(this.dynamicConfigService.verifyClient(['col', 'ccgm']) ? 'visit.calendar.inclusion.inclusionPreScreeningIdentifier' : 'inclusion.inclusionPreScreeningIdentifier')
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('visitDate')
            .filterable()
            .filterType(FilterType.CALENDAR)
            .filter(this.filters.visitDate)
            .sortable()
            .sortField('visit.visitDate')
            .linkClickEvent(((data: InvoiceAct) => {
                this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId, data.visitId);
            }))
            .withDateFormatter()
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('actVisit')
            .filterable()
            .filterType(this.dynamicConfigService.verifyClient(['col', 'vivalto', 'ccgm']) ? FilterType.INPUT_TEXT : FilterType.IH_DROPDOWN)
            .filter(this.filters.visitName)
            .sortable()
            .sortField(this.dynamicConfigService.verifyClient(['col', 'ccgm']) ? 'visit.name' : 'theoreticalVisit.name')
            .options(this.visitNameOptions)
            .linkClickEvent(((data: InvoiceAct) => {
                this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId, data.visitId);
            }))
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('actCategory')
            .filterable()
            .filterType(FilterType.IH_DROPDOWN)
            .filter(this.filters.actCategory)
            .sortable()
            .sortField(this.dynamicConfigService.verifyClient(['col', 'ccgm']) ? 'trialAct.referenceAct.actCategory.nameFr' : 'actCategory.nameFr')
            .type('act-category')
            .linkClickEvent(((data: InvoiceAct) => {
                this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId, data.visitId);
            }))
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('act')
            .filterable()
            .filterType(FilterType.INPUT_TEXT)
            .filter(this.filters.description)
            .sortable()
            .sortField('trialAct.description')
            .linkClickEvent(((data: InvoiceAct) => {
                this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId, data.visitId);
            }))
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('unitPrice')
            .filterable()
            .filterType(FilterType.INPUT_TEXT)
            .sortable()
            .sortField('pricePerUnit')
            .linkClickEvent(((data: InvoiceAct) => {
                this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId, data.visitId);
            }))
            .cssClass((() => {
                return 'right-align';
            }))
            .withDisplayCssClassContent()
            .displayContent(((data: InvoiceAct) => {
                return data.unitPrice.toFixed(2) + '€';
            }))
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('unitNumber')
            .filterable()
            .filterType(FilterType.INPUT_TEXT)
            .filter(this.filters.unitNumber)
            .sortable()
            .sortField('numberOfUnits')
            .linkClickEvent(((data: InvoiceAct) => {
                this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId, data.visitId);
            }))
            .cssClass((() => {
                return 'right-align';
            }))
            .withDisplayCssClassContent()
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('total')
            .filterable()
            .filterType(FilterType.INPUT_TEXT)
            .filter(this.filters.total)
            .sortable()
            .sortField('priceTotal')
            .linkClickEvent(((data: InvoiceAct) => {
                this.displayInclusionCalendarByNumber(data.inclusionHhhId, data.trialHhhId, data.visitId);
            }))
            .displayContent(((data: InvoiceAct) => {
                return data.total.toFixed(2) + '€';
            }))
            .cssClass((() => {
                return 'right-align';
            }))
            .withDisplayCssClassContent()
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('internalAccountNumber')
            .filterable()
            .filterType(FilterType.INPUT_TEXT)
            .filter(this.filters.internalAccountNumber)
            .sortable()
            .sortField('trialAct.internalAccountNumber')
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('firstSegment')
            .filterable()
            .filterType(FilterType.INPUT_TEXT)
            .filter(this.filters.firstSegment)
            .sortable()
            .sortField('trialAct.firstSegment')
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('secondSegment')
            .filterable()
            .filterType(FilterType.INPUT_TEXT)
            .filter(this.filters.secondSegment)
            .sortable()
            .sortField('trialAct.secondSegment')
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('inclusionNumber')
            .filterable()
            .filterType(this.dynamicConfigService.verifyClient(['igr']) ? FilterType.IH_DROPDOWN : FilterType.INPUT_TEXT)
            .options(this.dynamicConfigService.verifyClient(['igr']) ? this.inclusionIdentifier1Items : null)
            .filter(this.filters.inclusionNumber)
            .sortable()
            .sortField('inclusion.inclusionIdentifier1')
            .linkClickEvent(((data: InvoiceAct) => {
                this.displayInclusionByNumber(data.inclusionHhhId, data.trialHhhId);
            }))
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('type')
            .filterable()
            .filterType(FilterType.IH_DROPDOWN)
            .filter(this.filters.type)
            .optionFilterable()
            .sortable()
            .sortField('trialAct.type')
            .type('additional-cost-type-selected')
            .displayContent((act: InvoiceAct) => act.typeName)
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .accessible(this.actState !== AdditionalCostState.Pending)
            .field('patientInitials')
            .sortable()
            .sortField('patient.firstName')
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('site')
            .filterable()
            .filterType(FilterType.INPUT_TEXT)
            .filter(this.filters.siteName)
            .sortable()
            .sortField('site.name')
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('patientNumber')
            .filterable()
            .filterType(FilterType.INPUT_TEXT)
            .filter(this.filters.patientNumber)
            .linkClickEvent(((data: InvoiceAct) => {
                this.displayPatient(data.patientHhhId);
            }))
            .build());
        availableHeaders.push(new DynamicTableHeaderBuilder()
            .field('freeSS')
            .filterable()
            .filterType(FilterType.IH_DROPDOWN)
            .options(Data.yesOrNo)
            .isTranslated()
            .build());
        this.headers = this.config.buildTable(availableHeaders);
    }

    getExportPageModule(): string {
        return 'src/app/invoice/invoice-acts/invoice-acts.component';
    }

    getFilteredList(filter?) {
        if (filter) {
            Object.assign(this.filters, DynamicTableHeader.buildFilterData(this.headers));
        } else {
            this.filters = new SubInvoiceGlobalFilter();
        }
        this.getList();
    }

  async getList(): Promise<void> {
    let data: ResponsePage<InvoiceAct>;
    if (this.actState === AdditionalCostState.Invoiced || this.actState === AdditionalCostState.All) {
      data = await this.invoiceActService.loadInvoiceInvoicedActsList(this.invoiceHhhId, this.page, this.limit, this.sortBy, this.orderBy, this.filters, this.fromDate, this.toDate).toPromise();
      this.setResult(data, AdditionalCostState.Invoiced);
      this.buildFilterOptions();
    } else if (this.actState === AdditionalCostState.Pending) {
      data = await this.invoiceActService.loadInvoicePendingActsList(this.invoiceHhhId, this.retrocessionFees, this.page, this.limit, this.sortBy, this.orderBy, this.filters, this.fromDate, this.toDate).toPromise();
      this.setResult(data, AdditionalCostState.Invoiced);
      this.buildFilterOptions();

    }
    this.buildVisitOptions();
    return;
  }

    private setResult(data: ResponsePage<InvoiceAct>, state: AdditionalCostState): void {
        this.invoiceActs = data.content;
        this.totalNumber = data.totalElements;
        this.totalInvoiceActList = data.extraValue;
        this.invoiceActService.setExportConfiguration(state, this.invoiceActs);
    }

    buildVisitOptions(): void {
        const visitedIds = new Set();
        this.visitNameOptions = [];
        for (const invoiceAct of this.invoiceActs) {
            if (!visitedIds.has(invoiceAct.visitId)) {
                visitedIds.add(invoiceAct.visitId);
                this.visitNameOptions.push(new LabelValue(invoiceAct.actVisit, invoiceAct.actVisit));
            }
        }
    }

    submitAddActs() {
        if (this.isAllSelected) {
            this.invoiceActService.addAllActsToInvoice(this.invoiceHhhId, this.unselectedActs, this.retrocessionFees, this.filters, this.fromDate, this.toDate).subscribe(() => {
                this.updateList();
            });
        } else {
            this.actsToInvoice.invoiceHhhId = this.invoiceHhhId;
            for (const act of this.selectedInvoiceActList) {
                this.actsToInvoice.additionalCostsHhhIds.push(new VisitActive(act.hhhId, act.visitAct));
            }
            this.invoiceActService.addActsToInvoice(this.actsToInvoice, this.retrocessionFees).subscribe(() => {
                this.updateList();
            });
        }
    }

    deleteActs() {
        this.displayDeleteActDialog = false;
        if (this.isAllSelected) {
            this.invoiceActService.deleteAllActsFromInvoice(this.invoiceHhhId, this.unselectedActs, this.filters, this.retrocessionFees).subscribe(() => {
                this.updateList();
            });
        } else {
            this.actsToInvoice.invoiceHhhId = this.invoiceHhhId;
            for (const act of this.selectedInvoiceActList) {
                this.actsToInvoice.additionalCostsHhhIds.push(new VisitActive(act.hhhId, act.visitAct));
            }
            this.invoiceActService.deleteActsFromInvoice(this.actsToInvoice, this.retrocessionFees).subscribe(() => {
                this.updateList();
            });
        }
    }

    updateList() {
        this.changeInvoiceActList.emit({state: this.actState, list: this.selectedInvoiceActList});
        this.getList();
        this.selectedInvoiceActList = [];
        this.actsToInvoice.additionalCostsHhhIds = [];
        this.isAllSelected = false;
        this.invoiceService.updateTotal();
    }

    onUnselect(act) {
        this.unselectedActs.push(act.data.hhhId);
    }

    getInvoiceStateCode() {
        const subscription = this.invoiceInformationService.getInvoiceStateCode(this.invoiceHhhId).subscribe(res => {
            if (res) {
                this.dataService.setInvoiceState(res);
                this.actionDisabled = ((res === InvoiceState.Payed || res === InvoiceState.Despatched || res === InvoiceState.Validate)
                        && this.enableAddActFromValidatedInvoice) ||
                    ((res === InvoiceState.Despatched || res === InvoiceState.Payed) && !this.enableAddActFromValidatedInvoice);
            }
        });
        this.addSubscription(subscription);
        this.updateList();
    }

    displayTrialByLocalIdentifier(localIdentifier: string) {
        this.trialService.getTrialByLocalIdentifier(localIdentifier).subscribe((trialId: number) => {
            this.router.navigate(
                ['/trial-details/trial-identification-information-container/trial-identification-information'],
                {queryParams: {hhhId: trialId}}
            ).then();
        });
    }

    displayTrialByProtocolNumber(protocolNumber: string) {
        this.trialService.getTrialByProtocolNumber(protocolNumber).subscribe((trialId: number) => {
            this.router.navigate(
                ['/trial-details/trial-identification-information-container/trial-identification-information'],
                {queryParams: {hhhId: trialId}}
            ).then();
        });
    }

    displayProvider(invoiceAct: InvoiceAct) {
        this.dataService.setContactData(invoiceAct.promoter, 'Provider', '/assets/images/providers.png', invoiceAct.promoterHhhId, 0);
        this.router.navigate(['/contact/' + invoiceAct.promoterHhhId + '/Provider']).then();
    }

    displayInclusionByNumber(inclusionHhhId: number, trialHhhId: number) {
        this.router.navigate(['/trial-details/inclusion-details/inclusion-information'],
            {
                queryParams: {
                    hhhId: trialHhhId,
                    inclusionId: inclusionHhhId
                }
            }).then();
    }

    displayPatient(patientHhhId: number) {
        this.router.navigate(['/patient-details'],
            {queryParams: {hhhId: patientHhhId}}
        ).then();
    }

    displayInclusionCalendarByNumber(inclusionHhhId: number, trialHhhId: number, visitHhhId: number) {
        this.visitSharedService.setVisitHhhId(visitHhhId);
        this.router.navigate(['/trial-details/inclusion-details/calendar'],
            {
                queryParams: {
                    hhhId: trialHhhId,
                    inclusionId: inclusionHhhId
                }
            }).then();
    }

    updateSelectedList(selectedList: any) {
        this.selectedInvoiceActList = selectedList;
    }

    openDeleteActDialog() {
        if (this.selectedInvoiceActList.length > 0) {
            this.displayDeleteActDialog = true;
        }
    }

    tableUpdated(visitAct: VisitAct) {
        this.isDisplaySaveButton = true;
        if (this.visitActsToUpdate.indexOf(visitAct) < 0) {
            this.visitActsToUpdate.push(visitAct);
        }
    }


    resetTable() {
        this.getList();
        this.visitActsToUpdate = [];
        this.isDisplaySaveButton = false;
    }

    saveTable() {
        this.updateConfirmationDialogVisible = true;
        this.isDisplaySaveButton = false;
    }

    confirmUpdate() {
        this.updateConfirmationDialogVisible = false;
        const subscription = this.invoiceService.updateVisitActsFromInvoice(AdditionalCostType.ACT, this.visitActsToUpdate, this.invoiceHhhId).subscribe(() => {
            this.sharedService.showSuccess();
            this.isDisplaySaveButton = false;
            this.getList();
            this.visitActsToUpdate = [];
        }, error => {
            this.sharedService.showFailure();
        });
        this.addSubscription(subscription);
    }

    selectAllData() {
        this.selectedInvoiceActList = [];
        this.invoiceActs.forEach(act => this.selectedInvoiceActList.push(act));
    }

    displayContent(col: any, colField: any, rowData: any): string {
        const translateService = this.translateService;
        let content = colField;
        if (isNullOrUndefined(rowData[col.field])) {
            content = '';
        } else if (col.translated) {
            content = colField ? translateService.getTranslationString('UI_DIALOG_BUTTON_YES') : translateService.getTranslationString('UI_DIALOG_BUTTON_NO');
        } else if (col.displayContent) {
            content = col.displayContent(rowData);
        } else if (col.dateFormatter) {
            content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
        } else if (col.stringToDate) {
            if (!isNullOrUndefined(colField)) {
                const date1 = colField.toString().split('/');
                if (!isDate(rowData[col.field]) && date1.length === 3) {
                    rowData[col.field] = new Date(parseInt(date1[2], 10), parseInt(date1[1], 10), parseInt(date1[0], 10));
                }
                content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
            }
        }
        return content;
    }

    onAllSelected(event: any) {
        this.isAllSelected = event.checked;
        if (this.isAllSelected) {
            console.log('all selected');
        }
    }


    displayContentAct(col: any, rowData: any): string {
        if (col.field === 'visitDate' && col.field != null) {
            return this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
        } else {
            return rowData[col.field];
        }
    }

    exportInvoiceActs(event: any): void {
        const request = new ExportRequestBuilder<SubInvoiceGlobalFilter>()
            .contextId(event.contextId)
            .socketId(AppData.socketId)
            .target(this.target)
            .exportType(event.exportType)
            .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
            .objectId(this.invoiceHhhId)
            .condition(this.retrocessionFees)
            .fromDate(this.fromDate != null ? this.datePipe.transform(this.fromDate, 'dd/MM/yyyy') : '')
            .toDate(this.toDate != null ? this.datePipe.transform(this.toDate, 'dd/MM/yyyy') : '')
            .sortBy(this.sortBy)
            .orderBy(this.orderBy)
            .filters(this.filters)
            .build();
        if (this.actState === AdditionalCostState.Invoiced) {
            this.invoiceActService.exportInvoicedActs(request).subscribe(
                (res) => {
                },
                (err) => {
                    console.error(err);
                });
        } else if (this.actState === AdditionalCostState.Pending) {
            this.invoiceActService.exportPendingActs(request).subscribe(
                () => {
                },
                (err) => {
                    console.error(err);
                }
            );
        }

    }

  buildFilterOptions() {
    if (Util.isNullOrUndefinedOrEmpty(this.invoiceActs)) return;
    const set1 = new Set(this.inclusionIdentifier1Items.map(item => item.value));
    const set2 = new Set(this.inclusionIdentifier2Items.map(item => item.value));
    const set3 = new Set(this.inclusionPreScreeningIdentifierItems.map(item => item.value));
    this.invoiceActs.forEach(act => {
      if (act.inclusionNumber) {
        set1.add(act.inclusionNumber);
      }
      if (act.inclusionScreeningIdentifier) {
        set2.add(act.inclusionScreeningIdentifier);
      }
      if (act.inclusionPreScreeningIdentifier) {
        set3.add(act.inclusionPreScreeningIdentifier);
      }
    });
    this.inclusionIdentifier1Items = Array.from(set1).map(elt => new LabelValue(elt, elt, false, false));
    this.inclusionIdentifier2Items = Array.from(set2).map(elt => new LabelValue(elt, elt, false, false));
    this.inclusionPreScreeningIdentifierItems = Array.from(set3).map(elt => new LabelValue(elt, elt, false, false));
    if (Util.isNullOrUndefinedOrEmpty(this.headers)) {
      return;
    }
    this.headers.find(header => header.field === 'inclusionPreScreeningIdentifier').options = this.inclusionPreScreeningIdentifierItems;
    this.headers.find(header => header.field === 'inclusionNumber').options = this.inclusionIdentifier1Items;
    this.headers.find(header => header.field === 'inclusionScreeningIdentifier').options = this.inclusionIdentifier2Items;
  }
}

import {FormConfig} from '../dynamic-config/entity/form-config';
import {ContainerConfig} from './entity/container-config';
import {TableConfig} from './entity/table-config';

export class GlobalConfigAppData {

  public static formConfig: Map<string, FormConfig> = new Map<string, FormConfig>();
  public static tableConfig: Map<string, TableConfig> = new Map<string, TableConfig>();
  public static containerConfig: Map<string, ContainerConfig> = new Map<string, ContainerConfig>();

  public static removeCache(type: string, target: string) {
    if (type === 'form') {
      GlobalConfigAppData.formConfig.delete(target);
    } else if (type === 'table') {
      GlobalConfigAppData.tableConfig.delete(target);
    } else if (type === 'container') {
      GlobalConfigAppData.containerConfig.delete(target);
    }
  }
}

import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';

export class AdverseEffect {
  public hhhId: number;
  public publicCommentCount: number;
  public inclusionHhhId: number;
  public name: string;
  public severity: string;
  public occurrenceDate: Date;
  public declarationDate: Date;
  public resolutionDate: Date;
  public visit: string;
  public visitHhhId: number;

  init(obj: any): AdverseEffect {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    const valid = true;
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return valid;
  }
}

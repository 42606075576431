import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Note} from '../models/note';

@Injectable({
  providedIn: 'root'
})
export class BlocNoteService {
  public properties: Properties = new Properties();
  public host = this.properties.host + '/note';

  constructor(private httpClient: HttpClient) {
  }

  getNoteList(): Observable<Note []> {
    return this.httpClient.get<Note []>(this.host + '/load-list');
  }

  addNote(note: Note): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', note);
  }

  deleteNote(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  checkNote(noteHhhId: number, checked: boolean): Observable<any> {
    return this.httpClient.put<any>(this.host + '/check/' + noteHhhId, {checked: checked});
  }
}

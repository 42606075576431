import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {OtherTheoreticalInvoiceDate} from '../models/other-theoretical-invoice-date';
import {Observable} from 'rxjs/index';

@Injectable({
  providedIn: 'root'
})
export class OtherTheoreticalInvoiceDateService {

  public host = new Properties().host + '/other-theoretical_invoice_date';

  constructor(private httpClient: HttpClient) {
  }

  saveOtherTheoreticalInvoiceDates(trialId: number, otherTheoreticalInvoiceDateList: OtherTheoreticalInvoiceDate[]): Observable<OtherTheoreticalInvoiceDate[]> {
    return this.httpClient.post<OtherTheoreticalInvoiceDate[]>(this.host + '/load-list/' + trialId, otherTheoreticalInvoiceDateList);

  }

  getOtherTheoreticalInvoiceDate(trialId: number): Observable<OtherTheoreticalInvoiceDate[]> {
    return this.httpClient.get<OtherTheoreticalInvoiceDate[]>(this.host + '/save/' + trialId);

  }

  delete(hhhId: number): Observable<number> {
    return this.httpClient.get<number>(this.host + '/delete/' + hhhId);
  }
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TrialDashboard} from '../entity/trial-dashboard';

@Injectable({
  providedIn: 'root'
})
export class TrialDashboardService {

  public host = new Properties().host + '/trial-dashboard';

  constructor(private httpClient: HttpClient) {}

  getTrialDashboard(hhhId: number): Observable<TrialDashboard> {
    return this.httpClient.get<TrialDashboard>(this.host + '/' + hhhId);
  }
}

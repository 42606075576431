import {Component, OnInit, ViewChild} from '@angular/core';
import {PatientTrial} from '../../patient/models/patient-trial';
import {SharedService} from '../../shared/services/shared.service';
import {TrialService} from '../../trial/services/trial.service';
import {Router} from '@angular/router';
import {PatientService} from '../../patient/services/patient.service';
import {NgForm} from '@angular/forms';
import {InclusionInfo} from '../../patient/models/inclusion-info';
import {Trial} from '../../trial/models/trial';
import {DataService} from '../../shared/services/data-service';
import {InclusionService} from '../../trial/service/inclusion.service';
import {Data} from '../../shared/entity/data';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-patient-add-to-trial-form',
  templateUrl: './patient-trial-form.component.html',
  styleUrls: ['./patient-trial-form.component.css']
})
export class PatientTrialFormComponent implements OnInit {
  @ViewChild('addPatientToTrialForm') addPatientToTrialForm: NgForm;

  patientAlreadyIncluded: boolean;
  display = false;
  patientTrial: PatientTrial = new PatientTrial();
  patientHhhId;
  trial: Trial;
  trials: Trial[] = [];
  submitted: boolean;
  disableAddInclusionButton = false;
  amendmentsEnabled = true;


  private callback: () => void;

  constructor(private sharedService: SharedService,
              private trialService: TrialService,
              private router: Router,
              private patientService: PatientService,
              private inclusionService: InclusionService,
              private dynamicConfigService: DynamicConfigService,
              private dataService: DataService) {
  }

  static exist(HhhId: number, inclusions: InclusionInfo[]) {
    for (const inclusion of inclusions) {
      if (HhhId === inclusion.trialId) {
        return true;
      }
    }
    return false;
  }

  ngOnInit() {
    this.display = true;
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
    });
  }

  navigateToTrialInclusionDetails(id: number) {
    const path = this.amendmentsEnabled ? '/trial-details/inclusion-details/inclusion-information-version-protocol' : '/trial-details/inclusion-details/inclusion-information-arm';
    this.router.navigate(
      [path],
      {queryParams: {inclusionId: id, hhhId: this.trial.hhhId}}
    ).then();
  }

  addPatientToTrial() {
    this.disableAddInclusionButton = true;
    this.patientTrial.trialHhhId = this.trial.hhhId;
    this.patientTrial.patientHhhId = this.patientHhhId;
    this.inclusionService.addInclusion(this.patientTrial).subscribe(inclusionId => {
      this.dataService.changeInclusionMenu('information');
      this.navigateToTrialInclusionDetails(inclusionId);
      this.sharedService.showSuccess();
      this.callback();
      this.patientAlreadyIncluded = false;
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  checkExistingInclusion() {
    this.display = false;
    this.submitted = true;
    if (this.addPatientToTrialForm.invalid) {
      return;
    }
    this.patientTrial.trialHhhId = this.trial.hhhId;
    this.patientTrial.patientHhhId = this.patientHhhId;
    this.patientService.getPatientInfo(this.patientHhhId).subscribe((patientInclusions: InclusionInfo[]) => {
      if (patientInclusions) {
        if (!PatientTrialFormComponent.exist(this.trial.hhhId, patientInclusions)) {
          this.addPatientToTrial();
        } else {
          this.patientAlreadyIncluded = true;
        }
      } else {
        this.addPatientToTrial();
      }
    });
  }

  search(event) {
    const query = event.query;
    this.trialService.getTrials().subscribe((res: Trial[]) => {
      this.trials = this.filterTrials(query, res);
    });
  }

  filterTrials(query: string, trials: Trial[]): Trial[] {
    const filtered: Trial[] = [];
    const searchString = query.toLowerCase();
    for (const trial of trials) {
      if (trial.protocolNumber) {
        if (trial.localIdentifier.toLowerCase().startsWith(searchString)
          || trial.protocolNumber.toLowerCase().startsWith(searchString)) {
          filtered.push(trial);
        }
      } else if (trial.localIdentifier.toLowerCase().startsWith(searchString)) {
        filtered.push(trial);
      }
    }
    return filtered;
  }

  onAdd(callback: () => void) {
    this.callback = callback;
  }
}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {HumanResource} from '../models/human-resource';
import {Router} from '@angular/router';
import {HumanResourcesService} from '../services/human-resources.service';
import {DataService} from '../../shared/services/data-service';
import {HumanResourceFormComponent} from '../human-resource-form/human-resource-form.component';
import {ResponsePage} from '../../shared/entity/response-page';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {HumanResourceGlobalFilter} from '../models/human-resource-global-filter';
import {EstablishmentService} from '../../establishment/services/establishment.service';
import {SharedService} from '../../shared/services/shared.service';
import {HR_READ_WRITE} from '../../login/services/authentication.service';
import {HumanResourceAppData} from '../human-resource-app-data';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {AppData} from '../../helpers/app-data';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {RhImportComponent} from '../rh-import/rh-import.component';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-human-resource-list',
  templateUrl: './human-resource-list.component.html',
  styleUrls: ['./human-resource-list.component.css']
})
export class HumanResourceListComponent extends PaginatorTableComponent<HumanResource> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.HumanResource;
  _target = DynamicDefinitionEnum.HUMAN_RESOURCE_MODULE;
  target = DynamicDefinitionEnum.HUMAN_RESOURCE_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];
  filters: HumanResourceGlobalFilter = new HumanResourceGlobalFilter();
  disableEdit = false;
  enableHumanResourceImport = false;
  parentEstablishment = false;

  constructor(
      private router: Router,
      private cfr: ComponentFactoryResolver,
      private humanResourceService: HumanResourcesService,
      private dataService: DataService,
      private translateService: InnohealthTranslateService,
      private establishmentService: EstablishmentService,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.disableEdit = localStorage.getItem(HR_READ_WRITE) !== 'READ_WRITE';
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.enableHumanResourceImport =  this.dynamicConfigService.getProperty(PropertyEnum.humanRessourceImportEnabled);
      this.parentEstablishment = this.dynamicConfigService.parent;
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, HumanResourceAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('establishment')
        .filterable()
        .filter(this.filters.establishment)
        .accessible(this.parentEstablishment)
        .filterType(FilterType.IH_DROPDOWN)
        .type('establishment-children')
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('title')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('lastName')
        .filterable()
        .filter(this.filters.lastName)
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('firstName')
        .filterable()
        .filter(this.filters.firstName)
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('concatenatedResponsabilities')
        .filterable()
        .filter(this.filters.concatenatedResponsabilities)
        .filterType(FilterType.IH_DROPDOWN)
        .type('responsibility')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('site')
      .filterable()
      .filter(this.filters.site)
      .filterType(FilterType.IH_DROPDOWN)
      .type('site')
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/human-resources/human-resource-list/human-resource-list.component';
  }

  getDefaultSortField(): string {
    return 'lastName';
  }

  getDeleteMessage(): string {
    return 'MODULE_HUMAN_RESOURCES_DIALOG_WARNING_HUMAN_RESOURCE_WILL_BE_DELETED';
  }

  getService(): any {
    return this.humanResourceService;
  }

  displayFormDialogue() {
    const formComponent = HumanResourceFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.onAdd(this.updateList());
  }

  displayImportRhs() {
    const formComponent = RhImportComponent.display(this.formDialogContainer, this.cfr);
    formComponent.importRhDialogueVisible = true;
  }

  updateList() {
    return (hr: HumanResource) => {
      hr.role = this.translateService.getTranslationString('MODULE_HUMAN_RESOURCES_FIELD_ROLE_VALUE_' + hr.role);
      if (hr.establishment) {
        this.establishmentService.getEstablishmentFullName(hr.establishment).subscribe(
            fullName => hr.establishment = fullName
        );
      }
      this.values.push(hr);
      this.values.sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()));
    };
  }

  getList(filters?: any) {
    if (filters) {
      this.filters = DynamicTableHeader.buildFilterData(this.headers);
    }
    this.humanResourceService
        .loadList(this.page, this.limit, this.sortBy, this.orderBy, this.filters)
        .subscribe((hrPage: ResponsePage<HumanResource>) => {
          this.values = hrPage.content;
          this.totalNumber = hrPage.totalElements;
          this.humanResourceService.setPaginationConfiguration(hrPage, this.sortBy, this.orderBy, filters);
        });
  }

  editHumanResource(hr: HumanResource) {
    this.dataService.setPageTopName((hr.title ? hr.title : '') + ' '
        + (hr.firstName ? hr.firstName : '') + ' ' + hr.lastName);
    this.router
        .navigate(['/human-resource-details'], {queryParams: {hhhId: hr.hhhId}})
        .then();
  }

  displayDeleteDialogue(hr: HumanResource) {
    this.displayDeleteDialogueFromInfos(hr.hhhId, hr.lastName, hr);
  }

  refreshAfterDelete(hr: HumanResource): void {
    const index = this.values.findIndex(
        (humanResource: HumanResource) => humanResource.hhhId === hr.hhhId
    );
    const name = hr.firstName + ' ' + hr.lastName;
    this.sharedService.setInformationInLocalStorage('Ressources humaines', 'Supprimer', name);
    this.values.splice(index, 1);
  }

  exportHumanResources(event: any): void {
    const request = new ExportRequestBuilder<HumanResourceGlobalFilter>()
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
        .sortBy(this.sortBy)
        .orderBy(this.orderBy)
        .filters(this.filters)
        .build();
    this.humanResourceService.exportHumanResources(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {TrialPageLockService} from '../trial-page-lock.service';
import {NgForm} from '@angular/forms';
import {SharedService} from '../../../../shared/services/shared.service';
import {TrialPageLock} from '../../../../shared/entity/trial-page-lock';

@Component({
  selector: 'ih-page-lock-password',
  templateUrl: './page-lock-password.component.html',
  styleUrls: ['./page-lock-password.component.css']
})
export class PageLockPasswordComponent implements OnInit {
  @ViewChild('passwordForm') passwordForm: NgForm;

  displayPassword: boolean;
  submitted = false;
  trialLockPage: TrialPageLock = new TrialPageLock();
  trialId: number;
  callback: (passwordVerified: boolean) => void;
  isPasswordInvalid = false;

  constructor(
      private pageLockService: TrialPageLockService,
      private sharedService: SharedService
  ) {
  }

  public static displayPasswordDialog(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(PageLockPasswordComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.displayPassword = true;
  }

  lock() {
    this.submitted = true;
    if (this.passwordForm.invalid) {
      return;
    }
    this.trialLockPage.userHhhId = localStorage.getItem('authenticatedUserID');
    this.trialLockPage.username = localStorage.getItem('authenticatedUser');
    this.trialLockPage.trialHhhId = this.trialId;
    this.pageLockService.lock(this.trialLockPage).subscribe((res) => {
      this.sharedService.showSuccess();
      this.callback(true);
      this.displayPassword = false;
    }, error => {
      console.error(error);
      this.isPasswordInvalid = true;
      // this.sharedService.showFailure();
    });
  }

  onPasswordVerified(callback: (passwordVerified: boolean) => void) {
    this.callback = callback;
  }

}

import {NgModule} from '@angular/core';
import {ProviderListComponent} from './provider-list/provider-list.component';
import {ProviderFormComponent} from './provider-form/provider-form.component';
import {SharedModule} from '../shared/shared.module';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';
import { ProviderImportComponent } from './provider-import/provider-import.component';

@NgModule({
  declarations: [ProviderListComponent, ProviderImportComponent],
  imports: [
    SharedModule,
    DynamicConfigModule
  ],
  entryComponents: [ProviderFormComponent, ProviderImportComponent]
})
export class ProviderModule {
}

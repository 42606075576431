import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig} from '../../entity/form-input-config';


export const DYNAMIC_CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicCheckboxComponent),
  multi: true
};
@Component({
  selector: 'ih-dynamic-checkbox',
  templateUrl: './dynamic-checkbox.component.html',
  styleUrls: ['./dynamic-checkbox.component.css'],
  providers: [DYNAMIC_CHECKBOX_VALUE_ACCESSOR]
})
export class DynamicCheckboxComponent implements ControlValueAccessor {

  constructor() { }

  @Input() config: FormInputConfig;
  @Input() requiredErrorMsg = 'FORM_REQUIRED_FIELD_ERROR';
  @Input() submitted: boolean;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() isBinary = true;
  @Input() defaultLabel: string;
  @Input() alignLabel = false;
  @Input() disabled = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueInput: EventEmitter<any> = new EventEmitter();

  private _value: any;

  get value(): any {

    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
      this.valueChange.emit(this._value);
      this.valueInput.emit(this._value);
    }
  }
  writeValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(this._value);
    }
  }
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onChange = (_) => {};

  onTouched = () => {};

}

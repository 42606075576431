import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {TrialReportFilter} from '../models/trial-report-filter';
import {Observable, throwError} from 'rxjs';
import {ReportTrialsTotalByMonth} from '../follow-trials/models/report-trials-total-by-month';
import {ReportInclusionsTotalByMonth} from '../follow-inclusions/models/report-inclusions-total-by-month';
import {InclusionReportFilter} from '../follow-inclusions/models/inclusion-report-filter';
import {ReportsRateToActiveInPatientFilter} from '../follow-active-file/models/reports-rate-to-active-in-patient-filter';
import {ReportsRateToActiveInpatients} from '../follow-active-file/models/reports-rate-to-active-inpatients';
import {ReportInclusionByTrialFilter} from '../follow-inclusions-by-trial/models/report-inclusion-by-trial-filter';
import {ReportsInclusionsByTrial} from '../follow-inclusions-by-trial/models/reports-inclusions-by-trial';
import {OrganFamily} from '../follow-inclusions-by-trial/models/organ-family';
import {InclusionEstablishmentDepartment} from '../follow-inclusions/models/inclusion-establishment-department';
import {ResponsePage} from '../../shared/entity/response-page';
import {IsoStats} from '../models/isoStats';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  public host = new Properties().host + '/reports';
  public trialReportFilter: TrialReportFilter = new TrialReportFilter();
  public inclusionsEstablishmentDepartment: InclusionEstablishmentDepartment [] = [];
  public reportsRateToActiveInpatients: ReportsRateToActiveInpatients = new ReportsRateToActiveInpatients();
  public dataPage: ResponsePage<ReportsInclusionsByTrial>;
  private _yearFilter = new Date().getFullYear();

  constructor(private httpClient: HttpClient) {
  }

  set yearFilter(value: number) {
    this._yearFilter = value;
  }

  getColor(i: number) {
    const randomColors = ['#6C077C', '#000C7A', '#CA0080', '#09A0DF', '#D8DE00', '#FCA100', '#009823', '#0073B7',
      '#F00084', '#FFCB0C', '#F6E8D7', '#9EC700', '#00A1C4', '#B55232', '#514810', '#5BC9E1', '#A17A94', '#F56666',
      '#3C0000', '#F192BF', '#D6C871', '#878787', '#BB0058', '#824737'];
    return randomColors[i % randomColors.length];
  }

  getReportTrialsTotalByMonth(trialReportFilter: TrialReportFilter): Observable<ReportTrialsTotalByMonth> {
    return this.httpClient.post<ReportTrialsTotalByMonth>(this.host + '/trials/home', trialReportFilter);
  }

  getReportInclusionsTotalByMonth(inclusionReportFilter: InclusionReportFilter): Observable<ReportInclusionsTotalByMonth> {
    return this.httpClient.post<ReportInclusionsTotalByMonth>(this.host + '/inclusions/home', inclusionReportFilter);
  }

  getReportsRateToActiveInpatients(reportsRateToActiveInPatientFilter: ReportsRateToActiveInPatientFilter):
    Observable<ReportsRateToActiveInpatients> {
    return this.httpClient.post<ReportsRateToActiveInpatients>(this.host + '/rate-to-active-inpatients/home',
      reportsRateToActiveInPatientFilter);
  }

  getReportsInclusionsByTrial(page: number, limit: number, sortBy: string, orderBy: string,
                              reportInclusionByTrialFilter: ReportInclusionByTrialFilter): Observable<ResponsePage<ReportsInclusionsByTrial>> {
    this.yearFilter = reportInclusionByTrialFilter.year;
    return this.httpClient.post<ResponsePage<ReportsInclusionsByTrial>>(
      this.host + `/inclusions-by-trial/home`,
      new RequestPageBuilder<ReportInclusionByTrialFilter>()
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(reportInclusionByTrialFilter)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(inclusionReports => new ReportsInclusionsByTrial().init(inclusionReports));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  getOrganFamilies(): Observable<OrganFamily[]> {
    return this.httpClient.get<OrganFamily[]>(this.host + '/inclusions-by-trial/organs');
  }

  exportFollowInclusionByTrial(request: ExportRequest<ReportInclusionByTrialFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/inclusions-by-trial/export-csv', request).pipe();
  }

  setTrialReportFilter(trialReportFilter) {
    this.trialReportFilter = trialReportFilter;
  }

  setPaginationConfiguration(dataPage: ResponsePage<ReportsInclusionsByTrial>) {
    this.dataPage = dataPage;
  }

  setInclusionsEstablishmentDepartmentData(inclusionsEstablishmentDepartment) {
    this.inclusionsEstablishmentDepartment = inclusionsEstablishmentDepartment;
  }

  setReportsRateToActiveInpatients(reportsRateToActiveInpatients) {
    this.reportsRateToActiveInpatients = reportsRateToActiveInpatients;
  }

  getIsoStatsData(): Observable<IsoStats> {
    return this.httpClient.get<IsoStats>(this.host + '/iso-stats/data');
  }

  getNumberOfReceivedFeasibility(year: number, phase: string): Observable<any> {
    return this.httpClient.post(this.host + '/iso-stats/number-of-received-feasibility',  {year,  phase});
  }

  getReportComplianceRateForInclusionCommitments(year: number): Observable<any> {
    return this.httpClient.get(this.host + '/iso-stats/compliance-rate-inclusions-commitments/' + year);
  }

  getAverageTimeBetweenMepDateAndActivationDate(year: number): Observable<any> {
    return this.httpClient.get(this.host + '/iso-stats/average-time-between-mep-date-and-activation-date/' + year);
  }

  getAverageTimeBetweenActivationDateAndFirstPatientDate(year: number): Observable<any> {
    return this.httpClient.get(this.host + '/iso-stats/average-time-between-activation-date-and-first-patient-date/' + year);

  }

  getTrialDestructionRate(year: number): Observable<any> {
    return this.httpClient.get(this.host + '/iso-stats/trial-destruction-rate/' + year);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

export class ReferenceAdditionalCost {
  hhhId: number;
  type: string;
  actCategoryName: string;
  description: string;
  onPrescription: boolean;
  externalFromCentre: boolean;
  invoiceable: boolean;
  pricePerUnit: number;

  init(obj: any): ReferenceAdditionalCost {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}

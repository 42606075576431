import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ih-administrative-date-icon',
  templateUrl: './administrative-date-icon.component.html',
  styleUrls: ['./administrative-date-icon.component.css']
})
export class AdministrativeDateIconComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }

}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'ih-trial-identification-icon',
  templateUrl: './trial-identification-icon.component.html',
  styleUrls: ['./trial-identification-icon.component.css']
})
export class TrialIdentificationIconComponent {

  @Input() title: string;

  constructor() { }

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {Invoice} from '../models/invoice';
import {InvoiceAdd} from '../models/invoice-add';
import {InvoiceState} from '../invoice-fixed-costs/models/invoice-state';
import {InvoiceGlobalFilter} from '../models/invoice-global-filter';
import {InvoiceHeader} from '../models/invoice-header';
import {ResponsePage} from '../../shared/entity/response-page';
import {IsDeletable} from '../../is-deletable';
import {IsStateSwitchable} from '../../trial/entity/inclusion/is-state-switchable';
import {Value} from '../../shared/entity/value';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {AdditionalCostType} from '../../shared/entity/additional-cost-type.enum';
import {VisitAct} from '../../trial/entity/inclusion/visit-act';
import {catchError, map} from 'rxjs/operators';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  public host = new Properties().host + '/invoice';
  public invoiceDataPage: ResponsePage<Invoice>;
  public limit = 50;
  public sortBy = 'hhhId';
  public page = 0;
  public orderBy = 'desc';
  public invoiceGlobalFilter: InvoiceGlobalFilter = new InvoiceGlobalFilter();

  public invoiceTotal = new BehaviorSubject<void>(undefined);
  total = this.invoiceTotal.asObservable();

  public hhhId = new BehaviorSubject<number>(0);
  invoiceHhhId = this.hhhId.asObservable();

  constructor(private httpClient: HttpClient) {
  }

  loadList(retrocessionFees: boolean, isStaticInvoice: boolean, page: number, limit: number, sortBy: string, orderBy: string, filters: InvoiceGlobalFilter): Observable<ResponsePage<Invoice>> {
    return this.httpClient.post<ResponsePage<Invoice>>(
      this.host + '/load-list',
      new RequestPageBuilder<InvoiceGlobalFilter>()
        .condition(retrocessionFees)
        .secondCondition(isStaticInvoice)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(filters)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(invoice => new Invoice().init(invoice));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  addInvoice(invoice: InvoiceAdd): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', invoice);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  getInvoiceHeader(hhhId: number): Observable<InvoiceHeader> {
    return this.httpClient.get<InvoiceHeader>(this.host + '/header/' + hhhId);
  }

  getInvoiceStates(): Observable<InvoiceState[]> {
    return this.httpClient.get<InvoiceState[]>(this.host + '/states');
  }

  createInvoiceForUninvoicedActs(visitHhhId, toStateHhhId: number) {
    return this.httpClient.post<IsStateSwitchable>(this.host + '/visit/realisation-state/' + visitHhhId + '/' + toStateHhhId, null);
  }

  createInvoiceFromInvoicedOrPaidVisit(request: any): Observable<string> {
    return this.httpClient.post<string>(this.host + '/visit/payment-state', request);
  }

  createInvoiceFromInvoicedOrPaidActs(request: any): Observable<number> {
    return this.httpClient.post<number>(this.host + '/state/visit-acts', request);
  }

  setPaginationConfiguration(invoiceDataPage: ResponsePage<Invoice>, limit, sortBy, orderBy, filters?) {
    this.invoiceDataPage = invoiceDataPage;
    this.limit = limit;
    this.sortBy = sortBy;
    this.orderBy = orderBy;
    if (filters) {
      this.invoiceGlobalFilter = filters;
    }
  }

  updateVisitActsFromInvoice(type: AdditionalCostType, visitActsToUpdate: Array<VisitAct>, invoiceHhhId: number): Observable<void> {
    const request = {
      invoiceId: invoiceHhhId,
      costType: type,
      invoiceActs: visitActsToUpdate
    };
    return this.httpClient.put<void>(this.host + '/update-invoice-additional-costs', request);
  }

  updateTotal() {
    this.invoiceTotal.next();
  }

  updateInvoiceHhhId(hhhId: number) {
    this.hhhId.next(hhhId);
  }

  getEstablishmentFullNameByInvoiceHhhId(invoiceHhhId: number): Observable<string> {
    const options = {responseType: 'text' as 'text'};
    return this.httpClient.get(this.host + '/establishment-name/' + invoiceHhhId, options);
  }

  isInvoiceNumberAvailable(number: string): Observable<Value<boolean>> {
    return this.httpClient.get<Value<boolean>>(this.host + '/is-number-available/' + number);
  }

  exportInvoiceList(request: ExportRequest<InvoiceGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request);
  }

  exportSingleInvoice(request: ExportRequest<void>, currentLang: string): Observable<void> {
    return this.httpClient.post<void>(`${this.host}/single-invoice/export-csv?currentLang=${currentLang}`, request);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

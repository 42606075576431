export class Account {
  public hhhId: number;
  public title: string;
  public userName: string;
  public password: string;
  public firstName: string;
  public lastName: string;
  public specialitiesHhhId: number[];
  public concatenatedSpecialities: string;
  public establishmentHhhId: number;
  public establishment: string;
  public active: boolean;
  public lastMobileConnection: string;
  public nbrOfConnectionPerMonth: number;
  public isMobileOnly: boolean;
}

import {Component, OnInit} from '@angular/core';
import {Arm} from '../../../../entity/inclusion/arm';
import {ArmService} from '../../../../services/arm.service';
import {SharedService} from '../../../../../shared/services/shared.service';

@Component({
  selector: 'ih-add-arm-form',
  templateUrl: './add-arm-form.component.html',
  styleUrls: ['./add-arm-form.component.css']
})
export class AddArmFormComponent implements OnInit {
  trialId: number;
  displayAddArm: boolean;
  arm: Arm;
  callback: (armName: Arm) => void;
  mode: string;
  armDialogHeader: string;

  constructor(
      private armService: ArmService,
      private sharedService: SharedService
  ) {
  }

  ngOnInit() {
    this.armDialogHeader = this.isModeEdit() && this.arm.hhhId
        ? 'MODULE_TRIALS_ARMS_GENERAL_MODIFY'
        : 'MODULE_TRIALS_ARMS_GENERAL_ADD_NEW';
    this.displayAddArm = true;
  }

  addArmConfirm(newArm: Arm) {
    if (this.isModeEdit()) {
      newArm.trialHhhId = this.trialId;
      this.armService.addArm(newArm).subscribe((armHhhId) => {
            newArm.hhhId = armHhhId;
            this.sharedService.showSuccess();
            this.callback(newArm);
          }, error => {
            this.sharedService.showFailure();
            console.error('An error occurred while saving Arm.' + error);
          }
      );
    } else {
      this.callback(newArm);
    }
    this.displayAddArm = false;
  }

  onAdd(callback: (armName: Arm) => void) {
    this.callback = callback;
  }

  isModeEdit() {
    return this.mode === 'edit';
  }
}

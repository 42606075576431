import {LabelValue} from './label-value';

export class Country {
  hhhId: number;
  nameFr: string;

  constructor() {
    this.hhhId = 66;
    this.nameFr = 'France';
  }

  convert(item: LabelValue): Country {
    this.hhhId = item.value;
    this.nameFr = item.label;
    return this;
  }
}

import {Injectable} from '@angular/core';

import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {InvoiceAct} from '../models/invoice-act';
import {InvoiceActs} from '../models/invoice-acts';
import {Properties} from '../../../helpers/properties';
import {DatePipe} from '@angular/common';
import {AdditionalCostState} from '../../../additional-cost-state.enum';
import {ResponsePage} from '../../../shared/entity/response-page';
import {SubInvoiceGlobalFilter} from '../../models/sub-invoice-global-filter';
import {ExportRequest} from '../../../dynamic-config/entity/export-request';
import {catchError, map} from 'rxjs/operators';
import {RequestPageBuilder} from '../../../dynamic-config/entity/request-page-builder';
import {RequestInvoiceAct} from '../../models/RequestInvoiceAct';

@Injectable({
  providedIn: 'root'
})
export class InvoiceActService {

  public properties: Properties = new Properties();
  public host = this.properties.host + '/invoice-act';
  public invoicedInvoiceAct: InvoiceAct[];
  public pendingInvoiceAct: InvoiceAct[];
  public invoiceName: string;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe) {
  }

  loadInvoicePendingActsList(hhhId: number, retrocession: boolean, page: number, limit: number, sortBy: string, orderBy: string, subInvoiceGlobalFilter: SubInvoiceGlobalFilter, fromDate: Date, toDate: Date): Observable<ResponsePage<InvoiceAct>> {
    return this.httpClient.post<ResponsePage<InvoiceAct>>(
      this.host + '/pending/load-list',
      new RequestPageBuilder<SubInvoiceGlobalFilter>()
        .objectId(hhhId)
        .condition(retrocession)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(subInvoiceGlobalFilter)
        .fromDate(fromDate != null ? this.datePipe.transform(fromDate, 'dd/MM/yyyy') : '')
        .toDate(toDate != null ? this.datePipe.transform(fromDate, 'dd/MM/yyyy') : '')
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(invoiceAct => new InvoiceAct().init(invoiceAct));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  loadInvoiceInvoicedActsList(invoiceHhhId: number, page: number, limit: number, sortBy: string, orderBy: string, subInvoiceGlobalFilter: SubInvoiceGlobalFilter, fromDate: Date, toDate: Date): Observable<ResponsePage<InvoiceAct>> {
    if (sortBy === 'pricePerUnit') {
      sortBy = 'va.pricePerUnit';
    }
    if (sortBy === 'numberOfUnits') {
      sortBy = 'va.numberOfUnits';
    }
    if (sortBy === 'priceTotal') {
      sortBy = 'va.priceTotal';
    }
    return this.httpClient.post<ResponsePage<InvoiceAct>>(
      this.host + '/invoiced/load-list',
      new RequestPageBuilder<SubInvoiceGlobalFilter>()
        .objectId(invoiceHhhId)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(subInvoiceGlobalFilter)
        .fromDate(fromDate != null ? this.datePipe.transform(fromDate, 'dd/MM/yyyy') : '')
        .toDate(toDate != null ? this.datePipe.transform(fromDate, 'dd/MM/yyyy') : '')
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(invoiceAct => new InvoiceAct().init(invoiceAct));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  addActsToInvoice(actsToInvoice: InvoiceActs, retrocession: boolean) {
    return this.httpClient.post(this.host + '/add/' + retrocession, actsToInvoice);
  }

  deleteActsFromInvoice(actsToInvoice: InvoiceActs, retrocession: boolean): Observable<void> {
    return this.httpClient.post<void>(this.host + '/delete/' + retrocession, actsToInvoice);
  }

  setExportConfiguration(state: string, invoiceActs: InvoiceAct[]) {
    if (state === AdditionalCostState.Invoiced) {
      this.invoicedInvoiceAct = invoiceActs;
    } else {
      this.pendingInvoiceAct = invoiceActs;
    }
  }

  setInvoiceName(invoiceName: string) {
    this.invoiceName = invoiceName;
  }

  deleteAllActsFromInvoice(invoiceHhhId: number, unselectedActs: number[], filters: SubInvoiceGlobalFilter, retrocessionFees: boolean) {
    const request = new RequestInvoiceAct<SubInvoiceGlobalFilter>(
      invoiceHhhId,
      unselectedActs,
      retrocessionFees,
      '', '',
      filters);
    return this.httpClient.put(this.host + '/delete-all', request);
  }

  addAllActsToInvoice(invoiceHhhId: number, unselectedActs: number[], retrocession: boolean, filters: SubInvoiceGlobalFilter, fromDate: Date, toDate: Date): Observable<void> {
    const request = new RequestInvoiceAct<SubInvoiceGlobalFilter>(
      invoiceHhhId,
      unselectedActs,
      retrocession,
      fromDate != null ? this.datePipe.transform(fromDate, 'dd/MM/yyyy') : '',
      toDate != null ? this.datePipe.transform(toDate, 'dd/MM/yyyy') : '',
      filters);
    return this.httpClient.post<void>(this.host + '/add-all', request);
  }

  exportInvoicedActs(request: ExportRequest<SubInvoiceGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/invoiced/export-csv', request).pipe();
  }

  exportPendingActs(request: ExportRequest<SubInvoiceGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/pending/export-csv', request).pipe();
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

}



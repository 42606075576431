import {Component, OnInit} from '@angular/core';
import {Note} from '../models/note';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {User} from '../../admin/models/user';
import {BlocNoteService} from '../services/bloc-note.service';
import {FormComponent} from '../../shared/component/form/form.component';

@Component({
  selector: 'ih-bloc-note',
  templateUrl: './bloc-note.component.html',
  styleUrls: ['./bloc-note.component.css']
})
export class BlocNoteComponent extends FormComponent<Note> implements OnInit {

  noteColumns: any[];
  noteList: Note[] = [];
  note: Note = new Note();
  ownerHhhId;
  user: User = new User();
  noteContent: string;

  constructor(private translateService: InnohealthTranslateService,
              private blocNoteService: BlocNoteService) {
    super();
    this.ownerHhhId = localStorage.getItem('authenticatedUserID');
  }

  ngOnInit() {
    this.loadNotes();
    this.noteColumns = [
      {field: 'checked', header: ''},
      {field: 'content', header: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NOTEPAD')}
    ];
  }

  loadNotes() {
    this.blocNoteService.getNoteList().subscribe(
      res => {
        this.noteList = res;
      }, error => {
        console.log('An error occurred while loading note list.' + error);
      }
    );
  }

  save(note: Note) {
    this.submitted = true;
    if (this.addForm.invalid) {
      return;
    }
    note.date = new Date();
    note.ownerHhhId = this.ownerHhhId;
    this.note.content = this.noteContent;
    this.blocNoteService.addNote(note).subscribe(id => {
        note.hhhId = id;
        this.noteList.push(note);
        this.note = new Note();
        this.noteContent = null;
      }, error => {
        console.error('An error occurred when adding a note.' + error);
      }
    );
  }

  deleteNote(note: Note) {
    this.blocNoteService.deleteNote(note.hhhId).subscribe(() => {
        this.noteList.splice(this.noteList.indexOf(note), 1);
      }, error => {
        console.log('An error occurred while deleting note.' + error);
      }
    );
  }

  changeCheck(note: Note) {
    this.blocNoteService.checkNote(note.hhhId, note.checked).subscribe(() => {
      }, error => {
        console.log('An error occurred while update note checked.' + error);
      }
    );
  }

}

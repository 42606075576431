import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {CounterpartCategory} from '../models/counterpart-category';
import {FormComponent} from '../../shared/component/form/form.component';
import {FixedCostCategoryService} from '../services/fixed-cost-category.service';
import {SharedService} from '../../shared/services/shared.service';
import {CounterpartCategoryService} from '../services/counterpart-category.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';

@Component({
  selector: 'ih-counter-part-category-form',
  templateUrl: './counter-part-category-form.component.html',
  styleUrls: ['./counter-part-category-form.component.css']
})
export class CounterPartCategoryFormComponent extends FormComponent<CounterpartCategory> implements OnInit {

  target = DynamicDefinitionEnum.ADDITIONAL_COSTS_COUNTER_PART_CATEGORY_ADD_FORM;
  config: FormConfig = new FormConfig();
  isAdditionalCostCodeNumeric = false;

  @ViewChild('categoryForm') categoryForm: NgForm;

  category: CounterpartCategory = new CounterpartCategory();
  counterPartCategoryList: CounterpartCategory[];
  existingCategory: boolean;

  constructor(
      private fixedCostCategoriesService: FixedCostCategoryService,
      private sharedService: SharedService,
      private counterPartsService: CounterpartCategoryService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super();
  }

  private _service: any;

  // service needs to be passed as a parameter.
  get service(): any {
    return this._service;
  }

  set service(value: any) {
    this._service = value;
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(CounterPartCategoryFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAdditionalCostCodeNumeric = this.dynamicConfigService.getProperty(PropertyEnum.additionalCostCodeNumeric);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, AdditionalCostsAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);


  submitCategory() {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      return;
    }
    this.doesCategoryAlreadyExist();
    if (this.existingCategory) {
      return;
    }
    if (!this.category.hhhId) {
      this.addCategory();
    } else {
      this.updateCategory();
    }
  }

  private addCategory() {
    this.counterPartsService.addCategory(this.category).subscribe(res => {
      this.category.hhhId = res;
      this.callback(this.category);
      this.display = false;
      this.sharedService.showSuccess();
      this.sharedService.setInformationInLocalStorage('Grille budgétaire: Catégories des contreparties', 'Ajouter', this.category.nameFr);
    }, error => {
      this.existingCategory = true;
      console.error(error);
    });
  }

  private updateCategory() {
    this.counterPartsService.updateCategory(this.category).subscribe(() => {
          this.sharedService.showSuccess();
          this.sharedService.setInformationInLocalStorage('Grille budgétaire: Catégories des contreparties', 'Éditer', this.category.nameFr);
          this.callback(this.category);
          this.display = false;
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        }
    );
  }

  doesCategoryAlreadyExist() {
    const categoryFilter = (category: CounterpartCategory) => category.nameFr === this.category.nameFr;
    this.existingCategory = this.counterPartCategoryList.some(categoryFilter);
  }
}

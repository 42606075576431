import {Phone} from './phone';
import {Fax} from './fax';
import {Email} from './email';
import {Address} from './address';

export class ContactSet {
  hhhId: any;
  phones: Phone[] = [];
  faxes: Fax[] = [];
  emails: Email[] = [];
  addresses: Address[] = [];
  constructor() {
  }
}

import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrialObservablesService {

  onArmSave: EventEmitter<void> = new EventEmitter<void>();
  onInclusionExclusionSave: EventEmitter<void> = new EventEmitter<void>();
  onTreatmentMethodologySave: EventEmitter<void> = new EventEmitter<void>();
  onProtocolSave: EventEmitter<void> = new EventEmitter<void>();
  onTheoreticalCalendarSave: EventEmitter<void> = new EventEmitter<void>();


  constructor() {
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit, Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {SharedService} from '../../../../shared/services/shared.service';
import {TrialService} from '../../../services/trial.service';
import {TrialListData} from '../../../models/trial-list-data';
import {TrialInformation} from '../../../models/trial-information';
import {isNumeric} from 'rxjs/internal-compatibility';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {TrialIdentInfoTemplate} from '../trial-ident-info-template';
import {TrialTreatmentMethodologyService} from '../../../services/trial-treatment-methodology.service';
import {TrialIdentificationInformationGeneralService} from '../../../services/trial-identification-information-general.service';
import {TrialPersonalInformationService} from '../../../../shared/services/trial-personal-information.service';
import {TrialDisciplineInformationService} from '../../../services/trial-discipline-information.service';
import {isNullOrUndefined} from 'util';
import {ArmService} from '../../../services/arm.service';
import {TrialIdentificationInformationComponent} from '../trial-identification-information/trial-identification-information.component';
import {TrialPersonnelInformationComponent} from '../personnel/trial-personnel-information/trial-personnel-information.component';
import {TrialPersonnelProviderInformationComponent} from '../personnel/trial-personnel-provider-information/trial-personnel-provider-information.component';
import {TrialTreatmentMethodologieComponent} from '../trial-treatment-methodologie/trial-treatment-methodologie.component';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {ContainerConfig} from '../../../../dynamic-config/entity/container-config';
import {TrialAppData} from '../../../trial-app-data';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {FormDefinitionFieldsEnum} from '../../../../dynamic-config/entity/form-definition-fields-enum';
import { DisciplineIdentificationInformationComponent } from '../discipline-identification-information/discipline-identification-information.component';
import { Util } from 'src/app/helpers/util';


@Component({
  selector: 'ih-trial-identification-dialog',
  templateUrl: './trial-identification-dialog.component.html',
  styleUrls: ['./trial-identification-dialog.component.css']
})
export class TrialIdentificationDialogComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_IDENTIFICATION_INFORMATION_CONTAINER;
  config: ContainerConfig = new ContainerConfig();
  isAmendmentsEnabled = false;
  isCsetEnabled = true;
  idrcbEnabled = false;
  protocolNumberMandatory = false;
  promoterMandatory = false;
  promoterTypeMandatory = false;
  localIdentifierMandatory = false;
  defaultTrialInMultiSite = false;
  isDisciplineEnabled = false;
  isDesignEnabled = true;
  titleMandatory = false;
  typeOfStudyLawMandatory = false;
  numberCentersAllocatedByPromoterMandatory = false;
  principalInvestigatorMandatory = false;
  arc1Mandatory = false;

  private currentTrialIdentificationInfo: any;
  private currentTrialPersonnelInfo: any;
  private currentTrialDisciplineInfo: any;

  public displayTrial = true;
  public displayPersonal = false;
  public displayPersonalProvider = false;
  public displayMethodology = false;
  public displayDiscipline = false;
  public displayInclusionExclusionCriteria = false;
  public displayInclusionExclusionDisipline = false;
  public displayArm = false;
  public armName: string;
  public actualRoute = '';
  public displayAddArm = false;
  public emptyFieldsInTrialDialog: boolean;
  public display = false;
  public callback: (trial: TrialListData) => void;
  public trial: TrialInformation = new TrialInformation();

  @ViewChild('trialIdentInfoAnchor', {read: ViewContainerRef}) trialIdentInfoContainerRef: ViewContainerRef;
  @ViewChild('trialPersoInfoAnchor', {read: ViewContainerRef}) trialPersoInfoContainerRef: ViewContainerRef;
  @ViewChild('trialPersoProviderInfoAnchor', {read: ViewContainerRef}) trialPersoProviderInfoContainerRef: ViewContainerRef;
  @ViewChild('trialTeatmentMethoInfoAnchor', {read: ViewContainerRef}) trialTreatmentMethoInfoContainerRef: ViewContainerRef;
  @ViewChild('trialDisciplineInfoAnchor', {read: ViewContainerRef}) trialDisciplineInfoAnchorContainerRef: ViewContainerRef;


  constructor(
      private sharedService: SharedService,
      private canDeactivateService: CanDeactivateService,
      private trialService: TrialService,
      private componentFactoryResolver: ComponentFactoryResolver,
      private trialTreatmentMethodologyService: TrialTreatmentMethodologyService,
      private trialIdentificationInformationService: TrialIdentificationInformationGeneralService,
      private trialPersonalInformationService: TrialPersonalInformationService,
      private trialDisciplineInformationService: TrialDisciplineInformationService,
      private armService: ArmService,
      private dynamicConfigService: DynamicConfigService,
      private _cdr: ChangeDetectorRef
  ) {
  }

  public static display(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(TrialIdentificationDialogComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.initProperties();
    this.createTrialIdentificationInformationComponent();
    this._cdr.detectChanges();
    this.resetTrialServices();
    this.trial.trialIdentificationInformationGeneral.manualState = false;
    this.trial.arms = [];
    if (this.defaultTrialInMultiSite) {
      this.trial.trialIdentificationInformationGeneral.multiSite = true;
    }
    this.trialService.addTrialIdentification(this.trial);
    this.actualRoute = 'trial';
    this.display = true;
    this.updateTrialMethodologyIdentification();
    this.updateTrialIdentificationInformation();
    this.updateTrialPersonalIdentificationInformation();
    this.updateTrialDisciplineIdentificationInformation();
    this.updateTrialArm();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAmendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.isDisciplineEnabled = this.dynamicConfigService.getProperty(PropertyEnum.disciplineEnabled);
      this.isDesignEnabled = this.dynamicConfigService.getProperty(PropertyEnum.designEnabled);
      this.isCsetEnabled = this.dynamicConfigService.getProperty(PropertyEnum.csetEnabled);
      this.defaultTrialInMultiSite = this.dynamicConfigService.getProperty(PropertyEnum.defaultTrialInMultiSite);
    });
    this.initContainerConfig();
  }

  initContainerConfig(): void {
    this.dynamicConfigService.getContainerConfig(this.target, TrialAppData.containerConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getCardConfig = (cardName: string) => this.config.getCardConfig(cardName);
  displayCard = (cardName: string) => this.config.displayCard(cardName);
  getCardOrder = (cardName: string) => this.config.getCardOrder(cardName);
  isFieldRequired = (config: FormConfig, fieldName: string) => config.isFieldRequired(fieldName);

  addTrial() {
    this.trialIdentificationInformationService.changeSubmit(true);
    this.trialPersonalInformationService.changeSubmit(true);
    if (this.isValid()) {
      if (this.isCsetEnabled) {
        this.trialService.checkCSETAvailable(this.trial.trialIdentificationInformationGeneral.localIdentifier).subscribe(res => {
          if (res.value) {
            this.saveTrialInformation();
          }
        });
      } else {
        this.saveTrialInformation();
      }
    } else {
      this.sharedService.showFailure('Un ou plusieurs champs obligatoires n\'ont pas été renseignés. Veuillez remplir tous les champs obligatoires de tous les onglets avant d\'enregistrer.');
    }
    this.canDeactivateService.canBeDeactivated = true;
  }

  updateTrialIdentificationInformation() {
    this.trialIdentificationInformationService.trialIdentificationInfoValue.subscribe(res => {
      if (res) {
        this.trial.trialIdentificationInformationGeneral = res;
      }
    });
  }

  updateTrialMethodologyIdentification() {
    this.trialTreatmentMethodologyService.trialIdentificationInfoValue.subscribe(res => {
      if (res) {
        this.trial.trialMethodologyIdentificationInformation = res;
      }
    });
  }

  updateTrialPersonalIdentificationInformation() {
    this.trialPersonalInformationService.trialIdentificationInfoValue.subscribe(res => {
      if (res) {
        this.trial.trialHumanResource = res;
      }
    });
  }

  updateTrialDisciplineIdentificationInformation() {
    this.trialDisciplineInformationService.trialIdentificationInfoValue.subscribe(res => {
      if (res) {
        this.trial.trialDiscipline = res;
      }
    });
  }

  updateTrialArm() {
    this.armService.armList.subscribe(res => {
      if (res) {
        this.trial.arms = res;
      }
    });
  }

  private resetTrialServices() {
    this.trialTreatmentMethodologyService.setTrialIdentificationInfo(null);
    this.trialIdentificationInformationService.setTrialIdentificationInfo(null);
    this.trialPersonalInformationService.setTrialIdentificationInfo(null);
    this.trialDisciplineInformationService.setTrialIdentificationInfo(null);
  }

  isValid(): boolean {
    const result = (
      (this.isCsetEnabled && this.checkCsetValidity(this.trial.trialIdentificationInformationGeneral.localIdentifier) && !this.idrcbEnabled && !this.promoterMandatory && !this.promoterTypeMandatory && !this.protocolNumberMandatory) ||
      (!this.isCsetEnabled && this.localIdentifierMandatory && !Util.isNullOrUndefinedOrEmpty(this.trial.trialIdentificationInformationGeneral.localIdentifier) && this.promoterMandatory && !Util.isNullOrUndefined(this.trial.trialIdentificationInformationGeneral.promoterHhhId) && !this.promoterTypeMandatory && !this.protocolNumberMandatory) ||
      (this.protocolNumberMandatory && !Util.isNullOrUndefinedOrEmpty(this.trial.trialIdentificationInformationGeneral.protocolNumber) && !this.localIdentifierMandatory && !this.promoterMandatory && !this.promoterTypeMandatory) ||
      (this.protocolNumberMandatory && this.promoterMandatory && !Util.isNullOrUndefinedOrEmpty(this.trial.trialIdentificationInformationGeneral.protocolNumber) && !Util.isNullOrUndefined(this.trial.trialIdentificationInformationGeneral.promoterHhhId) && !this.localIdentifierMandatory && !this.promoterTypeMandatory) ||
      (this.idrcbEnabled && !Util.isNullOrUndefinedOrEmpty(this.trial.trialIdentificationInformationGeneral.idRcb) && this.promoterMandatory && !Util.isNullOrUndefined(this.trial.trialIdentificationInformationGeneral.promoterHhhId) && this.promoterTypeMandatory && !Util.isNullOrUndefined(this.trial.trialIdentificationInformationGeneral.promoterTypeHhhId) && !this.localIdentifierMandatory && !this.protocolNumberMandatory) ||
      (this.protocolNumberMandatory && !Util.isNullOrUndefinedOrEmpty(this.trial.trialIdentificationInformationGeneral.protocolNumber) && this.localIdentifierMandatory && !Util.isNullOrUndefinedOrEmpty(this.trial.trialIdentificationInformationGeneral.localIdentifier) && this.promoterMandatory && !Util.isNullOrUndefined(this.trial.trialIdentificationInformationGeneral.promoterHhhId) && !this.promoterTypeMandatory) ||
      (this.localIdentifierMandatory && !Util.isNullOrUndefinedOrEmpty(this.trial.trialIdentificationInformationGeneral.localIdentifier) && this.promoterMandatory && !Util.isNullOrUndefined(this.trial.trialIdentificationInformationGeneral.promoterHhhId) && !this.promoterTypeMandatory && !this.protocolNumberMandatory) ||
      (this.promoterMandatory && !Util.isNullOrUndefined(this.trial.trialIdentificationInformationGeneral.promoterHhhId) && !this.localIdentifierMandatory && !this.protocolNumberMandatory && !this.promoterTypeMandatory) ||
      (this.protocolNumberMandatory && this.trial.trialIdentificationInformationGeneral.protocolNumber && this.promoterMandatory
        && !Util.isNullOrUndefined(this.trial.trialIdentificationInformationGeneral.promoterHhhId) && !this.localIdentifierMandatory && this.promoterTypeMandatory
        && this.trial.trialIdentificationInformationGeneral.promoterTypeHhhId
        && this.titleMandatory && !Util.isNullOrUndefinedOrEmpty(this.trial.trialIdentificationInformationGeneral.title)
        && this.numberCentersAllocatedByPromoterMandatory && !Util.isNullOrUndefinedOrEmpty(this.trial.trialIdentificationInformationGeneral.numberCentersAllocatedByPromoter)
        && this.typeOfStudyLawMandatory && !Util.isNullOrUndefined(this.trial.trialIdentificationInformationGeneral.typeOfStudyLawHhhId )
        && this.typeOfStudyLawMandatory && !Util.isNullOrUndefined(this.trial.trialIdentificationInformationGeneral.typeOfStudyLawHhhId )
        && this.principalInvestigatorMandatory && !Util.isNullOrUndefined(this.trial.trialHumanResource.principalInvestigatorHhhId)
        && this.arc1Mandatory && !Util.isNullOrUndefined(this.trial.trialHumanResource.arc1HhhId))
    ) || false;
    return result;
  }

  private saveTrialInformation() {
    this.trialService.addTrial(this.trial).subscribe((res: TrialListData) => {
      this.trialIdentificationInformationService.changeSubmit(false);
      this.sharedService.showSuccess();
      this.display = false;
      this.callback(res);
      this.sharedService.setInformationInLocalStorage('Etudes', 'Ajouter', this.trial.trialIdentificationInformationGeneral.localIdentifier);
      this.armService.setArmList(null);
      this.trial = new TrialInformation();
    }, error => {
      this.sharedService.showFailure(error.error);
    });
  }

  openModule(path: string) {
    this.actualRoute = path;
    this.displayArm = path === 'arm';
    this.displayDiscipline = path === 'discipline';
    this.displayMethodology = path === 'methodology';
    this.displayPersonal = path === 'personnel';
    this.displayPersonalProvider = path === 'personnel-provider';
    this.displayTrial = path === 'trial';
    this.displayInclusionExclusionCriteria = path === 'inclusion-exclusion-criteria';
    this.displayInclusionExclusionDisipline = path === 'inclusion-exclusion-discipline';
    this.createComponent();
  }

  createComponent() {
    this.clearAllComponents();
    if (this.displayTrial) {
      this.createTrialIdentificationInformationComponent();
    } else if (this.displayPersonal) {
      this.createTrialPersonnelInformationComponent();
    } else if (this.displayPersonalProvider) {
      this.createTrialPersonalProviderInformationComponent();
    } else if (this.displayMethodology) {
      this.createTrialTreatmentMethodologieComponent();
    } else if (this.displayDiscipline) {
      this.createDisciplineIdentificationInformationComponent();
    }
    this._cdr.detectChanges();
  }

  private createComponentRef(componentType: Type<any>, containerRef: ViewContainerRef): ComponentRef<any> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    containerRef.clear();
    return containerRef.createComponent(componentFactory);
  }
  private createTrialIdentificationInformationComponent() {
    const componentRef = this.createComponentRef(TrialIdentificationInformationComponent, this.trialIdentInfoContainerRef);
    (componentRef.instance as TrialIdentificationInformationComponent).trialInformation = this.trial.trialIdentificationInformationGeneral;
    (componentRef.instance as TrialIdentInfoTemplate).mode = 'add';
    this.handleConfigReady(componentRef, () => {
      this.updateTrialIdentificationInformationFields(componentRef.instance.config);
    });
  }
  private updateTrialIdentificationInformationFields(config: any) {
    this.promoterMandatory = config.isFieldRequired(FormDefinitionFieldsEnum.promoter);
    this.promoterTypeMandatory = config.isFieldRequired(FormDefinitionFieldsEnum.promoterType);
    this.protocolNumberMandatory = config.isFieldRequired(FormDefinitionFieldsEnum.protocolNumber);
    this.localIdentifierMandatory = config.isFieldRequired(FormDefinitionFieldsEnum.localIdentifier);
    this.idrcbEnabled = config.displayField(FormDefinitionFieldsEnum.idRcb);
    this.titleMandatory = config.isFieldRequired(FormDefinitionFieldsEnum.title);
    this.typeOfStudyLawMandatory = config.isFieldRequired(FormDefinitionFieldsEnum.typeOfStudyLaw);
    this.numberCentersAllocatedByPromoterMandatory = config.isFieldRequired(FormDefinitionFieldsEnum.numberCentersAllocatedByPromoter);
  }
  private createTrialPersonnelInformationComponent() {
    const componentRef = this.createComponentRef(TrialPersonnelInformationComponent, this.trialPersoInfoContainerRef);
    (componentRef.instance as TrialPersonnelProviderInformationComponent).trialInformation = this.trial.trialHumanResource;
    (componentRef.instance as TrialIdentInfoTemplate).mode = 'add';
    this.handleConfigReady(componentRef, () => {
      this.arc1Mandatory = componentRef.instance.config.isFieldRequired(FormDefinitionFieldsEnum.arc1);
      this.principalInvestigatorMandatory = componentRef.instance.config.isFieldRequired(FormDefinitionFieldsEnum.principalInvestigators);
    });
  }

  private createTrialPersonalProviderInformationComponent() {
    const componentRef = this.createComponentRef(TrialPersonnelProviderInformationComponent, this.trialPersoProviderInfoContainerRef);
    (componentRef.instance as TrialIdentInfoTemplate).mode = 'add';
    this.handleConfigReady(componentRef, () => {

    });
  }

  private createTrialTreatmentMethodologieComponent() {
    const componentRef = this.createComponentRef(TrialTreatmentMethodologieComponent, this.trialTreatmentMethoInfoContainerRef);
    (componentRef.instance as TrialIdentInfoTemplate).mode = 'add';
    this.handleConfigReady(componentRef, () => {

    });
  }

  private createDisciplineIdentificationInformationComponent() {
    const componentRef = this.createComponentRef(DisciplineIdentificationInformationComponent, this.trialDisciplineInfoAnchorContainerRef);
    (componentRef.instance as TrialIdentInfoTemplate).mode = 'add';
    this.handleConfigReady(componentRef, () => {

    });
  }
  private handleConfigReady(componentRef: ComponentRef<any>, callback: () => void) {
    componentRef.instance.configReady.subscribe((ready: boolean) => {
      if (ready) {
        callback();
      }
    });
  }

  clearAllComponents() {
    this.trialTreatmentMethoInfoContainerRef.clear();
    this.trialIdentInfoContainerRef.clear();
    this.trialDisciplineInfoAnchorContainerRef.clear();
    if (!isNullOrUndefined(this.trialPersoInfoContainerRef)) {
      this.trialPersoInfoContainerRef.clear();
    }
    if (!isNullOrUndefined(this.trialPersoProviderInfoContainerRef)) {
      this.trialPersoProviderInfoContainerRef.clear();
    }
  }

  isModuleActive(pathList: any): boolean {
    return this.actualRoute === pathList;
  }

  onAdd(callback: (trial: TrialListData) => void) {
    this.callback = callback;
  }

  addArmConfirm() {
    this.displayAddArm = false;
  }

  isLocalIdentifierValid(cset: string) {
    const localIdentifierLength = 9;
    return this.isEmpty(cset) || (cset.length === localIdentifierLength && isNumeric(cset));
  }

  isEmpty(value: string): boolean {
    return !value || value.trim() === '';
  }

  checkCsetValidity(cset: string) {
    const localIdentifierLength = 9;
    return this.isEmpty(cset) || (cset.length === localIdentifierLength && isNumeric(cset));
  }

  checkNCTGovInvalid(numberClinicalTrialsGov: string) {
    if (!this.isEmpty(numberClinicalTrialsGov) && numberClinicalTrialsGov.substring(0, 3) === 'NCT' && numberClinicalTrialsGov.length === 11 && isNumeric(numberClinicalTrialsGov.substring(3, 11))) {
      return true;
    }
    return false;
  }

  isProtocolNumberMandatoryAndLocalIdentifierMandatoryAndPromoterMandatory() {
    return this.protocolNumberMandatory
        && this.trial.trialIdentificationInformationGeneral.protocolNumber
        && this.localIdentifierMandatory
        && this.trial.trialIdentificationInformationGeneral.localIdentifier
        && this.promoterMandatory
        && this.trial.trialIdentificationInformationGeneral.promoterHhhId
        && !this.promoterTypeMandatory;
  }

  private isIdrcbEnabledAndPromoterMandatoryAndPromoterTypeMandatory() {
    return this.idrcbEnabled
        && this.trial.trialIdentificationInformationGeneral.idRcb
        && this.promoterMandatory
        && this.trial.trialIdentificationInformationGeneral.promoterHhhId
        && this.promoterTypeMandatory
        && this.trial.trialIdentificationInformationGeneral.promoterTypeHhhId
        && !this.localIdentifierMandatory
        && !this.protocolNumberMandatory;
  }

  private isProtocolNumberMandatory() {
    return this.protocolNumberMandatory
        && this.trial.trialIdentificationInformationGeneral.protocolNumber
        && !this.localIdentifierMandatory
        && !this.promoterMandatory
        && !this.promoterTypeMandatory;
  }

  private isCsetEnabledAndLocalIdentifierMandatory() {
    return this.isCsetEnabled
        && this.checkCsetValidity(this.trial.trialIdentificationInformationGeneral.localIdentifier)
        && !this.idrcbEnabled
        && !this.promoterMandatory
        && !this.promoterTypeMandatory
        && !this.protocolNumberMandatory;
  }

  private isCsetNotEnabledAndLocalIdentifierMandatoryAndPromoterMandatory() {
    return !this.isCsetEnabled
        && this.localIdentifierMandatory
        && this.trial.trialIdentificationInformationGeneral.localIdentifier
        && this.promoterMandatory
        && this.trial.trialIdentificationInformationGeneral.promoterHhhId
        && !this.promoterTypeMandatory
        && !this.protocolNumberMandatory;
  }

  private isIdLocalMandatoryAndPromoterMandatory() {
    return this.localIdentifierMandatory
        && this.trial.trialIdentificationInformationGeneral.localIdentifier
        && this.promoterMandatory
        && this.trial.trialIdentificationInformationGeneral.promoterHhhId
        && !this.promoterTypeMandatory
        && !this.protocolNumberMandatory;
  }

  private isPromoterMandatory() {
    return this.promoterMandatory
        && this.trial.trialIdentificationInformationGeneral.promoterHhhId
        && !this.localIdentifierMandatory
        && !this.protocolNumberMandatory
        && !this.promoterTypeMandatory;
  }

  private isProtocolNumberMandatoryAndPromoterMandatory() {
    return this.protocolNumberMandatory
      && this.promoterMandatory
      && this.trial.trialIdentificationInformationGeneral.protocolNumber
      && this.trial.trialIdentificationInformationGeneral.promoterHhhId
      && !this.localIdentifierMandatory
      && !this.promoterTypeMandatory;
  }

  private isProtocolNumberMandatoryAndPromoterMandatoryAndPromoterMandatoryType() {
    return this.protocolNumberMandatory
      && this.promoterMandatory
      && this.trial.trialIdentificationInformationGeneral.protocolNumber
      && this.trial.trialIdentificationInformationGeneral.promoterHhhId
      && !this.localIdentifierMandatory
      && this.promoterTypeMandatory;
  }
}

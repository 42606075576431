import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PatientListComponent} from './patient-list/patient-list.component';
import {PatientDetailsComponent} from './patient-details/patient-details.component';
import {TranslateModule} from '@ngx-translate/core';
import {AuthGuard} from '../helpers/auth-guard.service';
import {InclusionDetailsContainerComponent} from '../trial/component/inclusions/inclusion-details/inclusion-details-container/inclusion-details-container.component';
import {CanDeactivateGuard} from '../helpers/can-deactivate-guard';
import {RequirePasswordUpdateGuard} from '../helpers/require-password-update-guard';

const routes: Routes = [
  {path: '', redirectTo: 'patient-list', pathMatch: 'full', canActivate: [RequirePasswordUpdateGuard]},
  {
    path: 'patient-list', component: PatientListComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]
  },
  {
    path: 'patient-details', component: PatientDetailsComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard], canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'patient-inclusion-details', component: InclusionDetailsContainerComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    TranslateModule],
  exports: [RouterModule]
})
export class PatientRoutingModule {
}

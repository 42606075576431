import { Injectable } from '@angular/core';
import {Properties} from '../../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PowerBiService {
  host = new Properties().host;
  private api = this.host + '/token';

  constructor(private http: HttpClient) { }


  getConfig(): Observable<any> {
    return this.http.get<any>(this.api)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    console.error('An error occurred', errorResponse);
    return throwError(errorResponse.error);
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Trial} from '../models/trial';
import {ResponsePage} from '../../shared/entity/response-page';
import {TrialGlobalFilter} from '../models/trial-global-filter';
import {DoctorWithMessages} from '../models/doctor-with-messages';
import {Message} from '../models/message';
import {SuggestedPatient} from '../models/suggested-patient';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrialsManagementService {
  public host = new Properties().host + '/mobile-application';
  public dataPage: ResponsePage<Trial>;
  public sortBy = 'hhhId';
  public orderBy = 'desc';
  public trialGlobalFilter: TrialGlobalFilter = new TrialGlobalFilter();

  constructor(private httpClient: HttpClient) {}

  private _conversations: BehaviorSubject<DoctorWithMessages[]> = new BehaviorSubject<DoctorWithMessages[]>([]);

  get conversations(): BehaviorSubject<DoctorWithMessages[]> {
    return this._conversations;
  }


  sendMessage(message: Message): Observable<any> {
    return this.httpClient.post(this.host + '/trial-message/send', message);
  }

  refreshConversations(conversation: DoctorWithMessages[]) {
    this._conversations.next(conversation);
  }

  updateMessagesToSeen(trialId: number, doctorId: number): Observable<any> {
    return this.httpClient.get(this.host + '/trial-message/seen?trialId=' + trialId + '&doctorId=' + doctorId);
  }

  getTrials(page: number, limit: number, sortBy: string, orderBy: string, filter: TrialGlobalFilter): Observable<ResponsePage<Trial>> {
    return this.httpClient.post<ResponsePage<Trial>>(
      this.host + '/trial-management/load-list',
      new RequestPageBuilder<TrialGlobalFilter>()
        .page(page)
        .limit(limit)
        .sortBy(sortBy === '' ? 'lastname' : sortBy)
        .orderBy(orderBy)
        .filters(filter)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(trial => new Trial().init(trial));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  updateTrial(trial: Trial): Observable<any> {
    return this.httpClient.put(this.host + '/trial-management', trial);
  }

  setPaginationConfiguration(dataPage: ResponsePage<Trial>, sortBy, orderBy, filters?) {
    this.dataPage = dataPage;
    this.sortBy = sortBy;
    this.orderBy = orderBy;
    if (filters) {
      this.trialGlobalFilter = filters;
    }
  }

  getDoctorsAlongWithMessages(trialHhhId: number): Observable<DoctorWithMessages[]> {
    return this.httpClient.get<DoctorWithMessages[]>(this.host
      + '/trial-message/users-with-sent-messages?trialHhhId='
      + trialHhhId);
  }

  getSuggestedPatients(trialHhhId: number): Observable<SuggestedPatient[]> {
    return this.httpClient.get<SuggestedPatient[]>(this.host + '/trial-management/suggested-patients/' + trialHhhId);
  }

  setPatientSuggestionsAsSeen(trialHhhId: number) {
    return this.httpClient.put(this.host + '/trial-management/suggested-patients/', trialHhhId);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {SuggestedPatient} from '../models/suggested-patient';

@Component({
  selector: 'app-suggest-patients-dialog',
  templateUrl: './suggest-patients-dialog.component.html',
  styleUrls: ['./suggest-patients-dialog.component.css']
})
export class SuggestPatientsDialogComponent implements OnInit {

  trialHhhId: number;
  display = false;
  submitted = false;
  formHeader: string;
  suggestedPatients: SuggestedPatient[];

  constructor() {
  }

  public static display(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(SuggestPatientsDialogComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.display = true;
  }
}

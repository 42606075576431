import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {LabelValue} from '../../shared/entity/label-value';
import {Properties} from '../../helpers/properties';
import {TrialAdministrativeFeasibilityInformation} from '../models/trial-administrative-feasibility-information';
import {catchError, map} from 'rxjs/operators';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class TrialAdministrativeFeasibilityService {

  public host = new Properties().host + '/trial-administrative-feasibility';
  trialHhhId: number;

  constructor(private httpClient: HttpClient) {
  }

  getAdministrativeFeasibilityInformation(trialId: number, trialSiteLiaisonId?: number): Observable<TrialAdministrativeFeasibilityInformation> {
    return this.httpClient.get<TrialAdministrativeFeasibilityInformation>(this.host + '/load/' + trialId + '/' + (trialSiteLiaisonId || 0))
      .pipe(
        map(res => {
            return (new TrialAdministrativeFeasibilityInformation()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  updateAdministrativeFeasibilityInformation(trialAdministrativeFeasibilityInformation: TrialAdministrativeFeasibilityInformation): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update', trialAdministrativeFeasibilityInformation);
  }

  exportedAdministrativeFeasibility(request: ExportRequest<void>, trialSiteHhhId: number): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv/' + trialSiteHhhId, request);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ReferenceFixedCost} from '../models/referenceFixedCost';
import {Properties} from '../../helpers/properties';
import {IsDeletable} from '../../is-deletable';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {SelectItem} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ReferenceFixedCostService {
  host = new Properties().host + '/reference-fixed-cost';
  contractTypeHost = new Properties().host + '/data/items';
  private fixedCostReferenceList: ReferenceFixedCost[] = [];

  constructor(private httpClient: HttpClient) {

  }

  setFixedCostReferenceListToExport(fixedCostReferenceList: ReferenceFixedCost[]) {
    this.fixedCostReferenceList = fixedCostReferenceList;
  }

  loadList(): Observable<ReferenceFixedCost[]> {
    return this.httpClient.get<ReferenceFixedCost[]>(this.host + '/load-list');
  }

  getContractTypeList(): Observable<SelectItem[]> {
    return this.httpClient.get<SelectItem[]>(this.contractTypeHost + '/contract-type-selected');
  }

  getAttachedFixedCostReferenceForTrial(trial: number, attached: boolean = true): Observable<ReferenceFixedCost[]> {
    const body = {
      trialId: trial,
      filterAttached: attached
    };
    return this.httpClient.post<ReferenceFixedCost[]>(this.host + '/load-attached-list/for-trial', body);
  }

  addFixedCostReference(fixedCostReference: ReferenceFixedCost): Observable<ReferenceFixedCost> {
    return this.httpClient.post<ReferenceFixedCost>(this.host + '/add', fixedCostReference);
  }

  updateFixedCostReference(fixedCostReference: ReferenceFixedCost): Observable<ReferenceFixedCost> {
    return this.httpClient.put<ReferenceFixedCost>(this.host + '/update', fixedCostReference);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(fixedCostReferenceId: number) {
    return this.httpClient.delete(this.host + `/delete/${fixedCostReferenceId}`);
  }

  exportFixedCostReference(request: ExportRequest<any>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

}


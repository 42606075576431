export class PatientExportFilter {
  hhhId: number;
  establishment: number;
  site: number[];
  phase: number;
  patientOrgan: any;
  organ: number;
  organFamily: number;
  state: number;
  sex: string[];
  lifeState: number;
  addressedByExternalDoctor: boolean;
  promoterType: number[];

  PatientExportFilter() {
    this.establishment = -1;
    this.site = [];
    this.phase = -1;
    this.patientOrgan = -1;
    this.organ = -1;
    this.organFamily = -1;
    this.state = -1;
    this.sex = [];
    this.lifeState = -1;
    this.addressedByExternalDoctor = null;
    this.promoterType = [];
    return this;
  }
}

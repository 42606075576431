export class MinimizedTrialProtocol {
  public hhhId: number;
  public trialHhhId: number;
  public version: string;
  public protocolVersionDate: Date;
  public amendmentVersion: string;
  public amendmentVersionDate: Date;
  public consents: string[];
  public type: 'AMENDMENT' | 'PROTOCOL';
  public description: string;
  public amendmentDescription: string;

  constructor(id?: number) {
    this.hhhId = id;
  }
}

import {Injectable} from '@angular/core';
import {Router, RouterEvent} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Util} from '../../helpers/util';

@Injectable({
  providedIn: 'root'
})
export class InnohealthTranslateService {
  translatedString = '';

  constructor(private router: Router,
              private translate: TranslateService) {
  }

  getTranslationString(key: any, interpolateParams?: any, langChangeEvent?: LangChangeEvent, routerEvent?: RouterEvent): string {
    if (Util.isNullOrUndefinedOrEmpty(key)) {
      return '';
    }
    if (!langChangeEvent && !routerEvent) {
      this.translate.stream(key, interpolateParams).subscribe((res: string) => {
        this.translatedString = res;
      });
    }
    if (langChangeEvent || routerEvent) {
      this.translate.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.translatedString = event.translations[key];
        });
    }
    return (this.translatedString);
  }


}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../../helpers/properties';
import {InvoiceInformation} from '../models/invoice-information';
import {InvoiceState} from '../../invoice-fixed-costs/models/invoice-state';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoiceInformationService {

  host = new Properties().host + '/invoice/information';
  public invoiceId: number;

  constructor(private httpClient: HttpClient) {
  }

  getInvoiceInformation(hhhId: number): Observable<InvoiceInformation> {
    return this.httpClient.get<InvoiceInformation>(this.host + '/' + hhhId).pipe(
        map(res => {
              return (new InvoiceInformation()).init(res);
            }
        ));
  }

  getInvoiceStateCode(hhhId: number): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.httpClient.get<string>(this.host + '/state/' + hhhId, requestOptions);
  }

  updateInvoiceInformation(invoiceInformation: InvoiceInformation): Observable<InvoiceState> {
    return this.httpClient.put<InvoiceState>(this.host + '/update', invoiceInformation);
  }

  setInvoiceId(invoiceId) {
    this.invoiceId = invoiceId;
  }
}

import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {YearRecord} from '../models/year-record';
import {EstablishmentDepartment} from '../../shared/entity/establishment-department';
import {SharedService} from '../../shared/services/shared.service';
import {EstablishmentService} from '../services/establishment.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {EstablishmentAppData} from '../establishment-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import { EstablishmentDepartmentService } from '../services/establishment-department.service';

@Component({
  selector: 'ih-active-file-by-year-form',
  templateUrl: './active-file-by-year-form.component.html',
  styleUrls: ['./active-file-by-year-form.component.css']
})
export class ActiveFileByYearFormComponent implements OnInit {

  target = DynamicDefinitionEnum.ESTABLISHMENT_ACTIVE_FILE_BY_YEAR_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('activeFileByYearForm') activeFileByYearForm: NgForm;
  public display = false;
  public submitted = false;
  public year: YearRecord = new YearRecord();
  public establishmentEditRight: boolean;
  public services: EstablishmentDepartment[] = [];
  public service: EstablishmentDepartment = new EstablishmentDepartment();
  public establishmentServiceEmpty: boolean;
  public yearsValueEmpty: boolean;
  public singleYear: YearRecord = new YearRecord();
  public currentYearValue: any;
  public yearList: YearRecord[] = [];
  public existingYearRecord: boolean;
  public establishmentHhhId: number;

  private callback: () => void;

  constructor(
      private sharedService: SharedService,
      private establishmentService: EstablishmentService,
      private dynamicConfigService: DynamicConfigService,
      private establishmentDepartmentService:EstablishmentDepartmentService
  ) {
  }

  ngOnInit() {
    this.display = true;
    this.existingYearRecord = false;
    this.yearsValueEmpty = false;
    this.service = new EstablishmentDepartment();
    this.year = new YearRecord();
    this.singleYear = new YearRecord();
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, EstablishmentAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  submitActiveFile() {
    this.submitted = true;
    this.existingYearRecord = false;
    this.yearsValueEmpty = this.sharedService.isEmpty(this.year.value ? this.year.value.toString() : null);
    this.establishmentServiceEmpty = this.service.hhhId === undefined;
    if (this.activeFileByYearForm.invalid || this.yearsValueEmpty || this.establishmentServiceEmpty) {
      return;
    }
    if (!this.singleYear.year || this.singleYear.year.toString() === '') {
      this.singleYear.year = this.currentYearValue;
    }
    this.year.service = String(this.service.hhhId);
    this.year.year = this.singleYear.year;
    this.establishmentDepartmentService.addYearRecord(this.year).subscribe(res => {
      this.year.hhhId = res;
      this.sharedService.showSuccess();
      this.callback();
      this.display = false;
    }, error => {
      this.existingYearRecord = true;
      this.sharedService.showFailure();
      console.error(error);
    });
    this.submitted = false;
  }

  onAdd(callback: () => void) {
    this.callback = callback;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

}

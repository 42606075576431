import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Pharmacy} from '../entity/trial-pharmacy/pharmacy';
import {AUTHENTICATED_USER_ROLE, PHARMACY_READ_WRITE} from '../../login/services/authentication.service';
import {Role} from '../../login/models/role';
import {Permission} from '../../helpers/permission';

@Injectable({
  providedIn: 'root'
})
export class PharmacyService {

  public host = new Properties().host + '/pharmacy';
  public readOnlyMode = false;


  constructor(private httpClient: HttpClient) {
  }

  load(trialHhhId: number): Observable<Pharmacy> {
    return this.httpClient.get<Pharmacy>(this.host + '/load/' + trialHhhId);
  }

  getPharmacyIdByTrialId(trialId: number): Observable<number> {
    return this.httpClient.get<number>(this.host + '/get-id/' + trialId);
  }

  add(pharmacy: Pharmacy): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', pharmacy);
  }

  update(pharmacy: Pharmacy): Observable<number> {
    return this.httpClient.post<number>(this.host + '/update', pharmacy);
  }

  hasPharmacistValidation(pharmacyHhhId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(this.host + '/pharmacist-validation/' + pharmacyHhhId);
  }

  isPharmacyDoubleBlind(trialHhhId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(this.host + '/attribution-method/' + trialHhhId);
  }

  setPharmacyReadonlyMode() {
    if (localStorage.getItem(PHARMACY_READ_WRITE) !== 'READ_WRITE') {
      this.readOnlyMode = true;
    }
  }

  isPharmacist() {
    return localStorage.getItem(AUTHENTICATED_USER_ROLE) === Role.Pharmacist;
  }

  isPharmacyDisplayed() {
    return localStorage.getItem(PHARMACY_READ_WRITE) !== Permission.NO_RIGHT;
  }
}

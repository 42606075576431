import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {LazyLoadEvent} from 'primeng/components/common/lazyloadevent';
import {DataService} from '../../services/data-service';
import {SelectItem} from 'primeng/components/common/selectitem';
import {DeleteConfirmationComponent} from '../../../modals/delete-confirmation/delete-confirmation.component';
import {DeleteDeletableEntityConfirmationComponent} from '../../../modals/delete-deletable-entity-confirmation/delete-deletable-entity-confirmation.component';
import {Deletable} from '../../../modals/delete-confirmation/deletable';
import {Subscription} from 'rxjs';
import {DataCommentInfo} from '../../../data-comment/entity/data-comment';
import {DataCommentComponent} from '../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../data-comment/entity/DataCommentEvent';

@Component({
  selector: 'ih-paginator-table',
  templateUrl: './paginator-table.component.html',
  styleUrls: ['./paginator-table.component.css']
})
export class PaginatorTableComponent<T> implements OnInit, OnDestroy {

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) commentDialogContainer: ViewContainerRef;
  @ViewChild('deleteDialog', {read: ViewContainerRef}) deletePopupContainer: ViewContainerRef;
  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;

  values: T[];
  totalNumber;
  page = 0;
  limit = 50;
  sortBy = '';
  orderBy = 'desc';

  subscriptions: Subscription[] = [];

  constructor(
      private paginatorDataService: DataService,
      private factoryResolver: ComponentFactoryResolver,
  ) {
  }

  protected addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription);
  }

  ngOnInit() {
    this.sortBy = this.getDefaultSortField();
    this.paginatorDataService.setExportPageModule(this.getExportPageModule());
  }

  getPostitComment(): string {
    return '';
  }

  getExportPageModule(): string {
    return '';
  }

  getAddTitle(): string {
    return '';
  }

  getUpdateTitle(): string {
    return '';
  }

  getDeleteMessage(): string {
    return '';
  }

  getDefaultSortField(): string {
    return 'hhhId';
  }

  getService(): Deletable {
    return undefined;
  }

  getEntityName(): any {
    return undefined;
  }

  getSortedList(event: LazyLoadEvent) {
    this.sortBy = event.sortField === undefined ? this.getDefaultSortField() : event.sortField;
    this.orderBy = event.sortOrder === 1 ? 'asc' : 'desc';
    this.page = event.first / event.rows;
    this.limit = event.rows;
    this.getList();
  }

  getList(event?: any) {}

  getLimitedList(event: any) {
    this.limit = event;
    this.page = 0;
    this.getList();
  }

  displayDeleteDialogue(entity: any, entityParentHhhId?: any) {
    const deleteConfirmationComponent = DeleteConfirmationComponent.displayDeleteConfirmationDialogue(this.deletePopupContainer, this.factoryResolver);
    deleteConfirmationComponent.hhhId = entity.hhhId;
    deleteConfirmationComponent.name = entity.name;
    if (entityParentHhhId) {
      deleteConfirmationComponent.entityParentHhhId = entityParentHhhId;
      deleteConfirmationComponent.entityName = this.getEntityName();
    }
    deleteConfirmationComponent.popupMessage = this.getDeleteMessage();
    deleteConfirmationComponent.service = this.getService();
    deleteConfirmationComponent.onDelete(() => {
      this.refreshAfterDelete(entity);
    });
  }

  displayDeleteDialogueOfDeletableEntity(entity: any, entityParentHhhId?: any) {
    const deleteConfirmationComponent = DeleteDeletableEntityConfirmationComponent.displayDeleteConfirmationDialogue(this.deletePopupContainer, this.factoryResolver);
    deleteConfirmationComponent.hhhId = entity.hhhId;
    deleteConfirmationComponent.name = entity.name;
    if (entityParentHhhId) {
      deleteConfirmationComponent.entityParentHhhId = entityParentHhhId;
      deleteConfirmationComponent.entityName = this.getEntityName();
    }
    deleteConfirmationComponent.popupMessage = this.getDeleteMessage();
    deleteConfirmationComponent.service = this.getService();
    deleteConfirmationComponent.onDelete(() => {
      this.refreshAfterDelete(entity);
    });
  }

  displayDeleteDialogueFromInfos(hhhId: number, name: string, entity: any) {
    const deleteConfirmationComponent = DeleteConfirmationComponent.displayDeleteConfirmationDialogue(this.deletePopupContainer, this.factoryResolver);
    deleteConfirmationComponent.hhhId = hhhId;
    deleteConfirmationComponent.name = name;
    deleteConfirmationComponent.popupMessage = this.getDeleteMessage();
    deleteConfirmationComponent.service = this.getService();
    deleteConfirmationComponent.onDelete(() => {
      this.refreshAfterDelete(entity);
    });
  }

  displayDeleteDialogueOfDeletableEntityFromInfos(hhhId: number, name: string, entity: any) {
    const deleteConfirmationComponent = DeleteDeletableEntityConfirmationComponent.displayDeleteConfirmationDialogue(this.deletePopupContainer, this.factoryResolver);
    deleteConfirmationComponent.hhhId = hhhId;
    deleteConfirmationComponent.name = name;
    deleteConfirmationComponent.popupMessage = this.getDeleteMessage();
    deleteConfirmationComponent.service = this.getService();
    deleteConfirmationComponent.onDelete(() => {
      this.refreshAfterDelete(entity);
    });
  }

  refreshAfterDelete(entity?: any): void {
  }

  ngOnDestroy(): void {
    this.paginatorDataService.setExportPageModule('');
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  displayDataCommentDialog(entity: string, event: { attachedToId: number, attachedToField: string, isPrivate: boolean }) {
    const dataCommentInfo = new DataCommentInfo(entity, event.attachedToId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.commentDialogContainer, this.factoryResolver);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    const index = this.values.findIndex((entity: T) => entity['hhhId'] === dataCommentEvent.attachedToId);
    if (index !== -1) {
      this.values[index]['publicCommentCount'] = dataCommentEvent.publicCommentCount;
    }
  }

  initializeItems(emptyValue?): SelectItem[] {
    return [{label: ' ', value: (emptyValue ? emptyValue : null)}];
  }

  updateList(newEntity?: T) {
    if (newEntity) {
      const index = this.values.findIndex((entity: T) => entity['hhhId'] === newEntity['hhhId']);
      if (index === -1) {
        this.values.push(newEntity);
      } else {
        this.values[index] = newEntity;
      }
    }
    this.values.sort((a: any, b: any) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }

}

import {Injectable} from '@angular/core';
import {InnohealthTranslateService} from './innohealth-translate.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  stateMap = new Map<string, string>();

  constructor(private translateService: InnohealthTranslateService,
              private translate: TranslateService
  ) {
    this.translate.stream('GENERAL_LINGUISTICS_YES_UFCASE').subscribe(() => {
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_IN_FOLLOW_UP'), 'suivi');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_LOST_VIEW'), 'lostView');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_IN_TREATMENT_INCLUS'), 'traitmentInclus');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_PRE_SELECTED'), 'preSelected');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_CREATED'), 'created');
      this.stateMap.set(translateService.getTranslationString('SCREENED'), 'screening');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_SCREENING_FAILURE'), 'screeningFailure');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_INCLUDED'), 'inclus');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_PRE_INCLUDED'), 'preInclus');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_PRE_SCREENING_FAILURE'), 'preScreening');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_IN_TREATMENT'), 'preScreeningFailure');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_IN_TREATMENT'), 'traitement');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_IN_FOLLOW_UP'), 'suivi');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_IN_FOLLOW_UP_AFTER_PROGRESSION'), 'suiviAProgression');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_IN_FOLLOW_UP_WITHOUT_PROGRESSION'), 'suiviSProgression');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_IN_FOLLOW_UP_WITHOUT_PROGRESSION'), 'suiviSProgression');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_EXCLUDED'), 'sortieEtude');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_WITHDRAWAL_OF_CONSENT'), 'retraitConsentement');
      this.stateMap.set(translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_ENDED'), 'ended');
    });
  }

  get getStateMap() {
    return this.stateMap;
  }

}

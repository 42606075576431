import {NgModule} from '@angular/core';

import {RouterModule, Routes} from '@angular/router';
import {InvoiceInformationComponent} from './invoice-information/invoice-information.component';
import {InvoiceListComponent} from './invoice-list/invoice-list.component';
import {InvoiceDetailsComponent} from './invoice-details/invoice-details.component';
import {FollowInvoiceComponent} from './follow-invoice/follow-invoice/follow-invoice.component';
import {TranslateModule} from '@ngx-translate/core';
import {AuthGuard} from '../helpers/auth-guard.service';
import {InvoiceActsComponent} from './invoice-acts/invoice-acts.component';
import {InvoiceFixedCostsComponent} from './invoice-fixed-costs/invoice-fixed-costs.component';
import {InvoiceCounterPartsComponent} from './invoice-counter-parts/invoice-counter-parts.component';
import {StaticInvoiceListComponent} from './static-invoice-list/static-invoice-list.component';
import {InvoiceContainerComponent} from './invoice-container/invoice-container.component';
import {StaticInvoiceInformationComponent} from './static-invoice-information/static-invoice-information.component';
import {StaticInvoiceDetailsComponent} from './static-invoice-details/static-invoice-details.component';
import {OngoingInvoiceDetailsComponent} from './ongoing-invoice/ongoing-invoice-details/ongoing-invoice-details.component';
import {RequirePasswordUpdateGuard} from '../helpers/require-password-update-guard';

const routes: Routes = [
  {
    path: 'invoice-container', component: InvoiceContainerComponent, canActivate: [RequirePasswordUpdateGuard],
    children: [
      {path: '', redirectTo: 'invoice-list', pathMatch: 'full'},
      {path: 'invoice-list', component: InvoiceListComponent, canActivate: [AuthGuard]},
      {path: 'static-invoice-list', component: StaticInvoiceListComponent, canActivate: [AuthGuard]}

    ]
  },
  {
    path: 'static-invoice-details', component: StaticInvoiceDetailsComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard],
    children: [
      {path: '', redirectTo: 'static-invoice-information', pathMatch: 'full'},
      {path: 'static-invoice-information', component: StaticInvoiceInformationComponent, canActivate: [AuthGuard]}
    ]
  },
  {
    path: 'invoice-details', component: InvoiceDetailsComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard],
    children: [{path: '', redirectTo: 'invoice-information', pathMatch: 'full'},
      {path: 'invoice-information', component: InvoiceInformationComponent, canActivate: [AuthGuard]},
      {path: 'invoice-acts', component: InvoiceActsComponent, canActivate: [AuthGuard]},
      {path: 'invoice-fixed-costs', component: InvoiceFixedCostsComponent, canActivate: [AuthGuard]},
      {path: 'invoice-counter-parts', component: InvoiceCounterPartsComponent, canActivate: [AuthGuard]}]
  },
  {path: 'ongoing-invoice', component: OngoingInvoiceDetailsComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]},
  {path: 'follow-invoice', component: FollowInvoiceComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]}];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    TranslateModule
  ],
  exports: [RouterModule]
})
export class InvoiceRoutingModule {
}

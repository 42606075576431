import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Establishment} from '../models/establishment';
import {YearRecord} from '../models/year-record';
import {Properties} from '../../helpers/properties';
import {EstablishmentSelection} from '../../shared/entity/establishment-selection';
import {IsEstablishmentsDeletable} from '../../IsEstablishmentsDeletable';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentService {
  properties: Properties = new Properties();
  host = this.properties.host + '/establishment';
  private establishment: Subject<number> = new BehaviorSubject<number>(null);
  establishmentHhhId = this.establishment.asObservable();

  setEstablishment(hhhId) {
    this.establishment.next(hhhId);
  }

  constructor(private httpClient: HttpClient) {
  }

  getEstablishment(establishmentHhhId?: any): Observable<Establishment> {
    return this.httpClient.get<Establishment>(this.host + '/load/' + (establishmentHhhId ? establishmentHhhId : -1));
  }

  getChildEstablishments(): Observable<EstablishmentSelection []> {
    return this.httpClient.get<EstablishmentSelection []>(this.host + '/children');
  }

  getEstablishmentFullName(hhhId: number): Observable<string> {
    const options = {responseType: 'text' as 'text'};
    return this.httpClient.get(this.host + '/full-name/' + hhhId, options);
  }

  renameEstablishment(establishment: EstablishmentSelection) {
    return this.httpClient.put(this.host + '/rename', establishment);
  }

  isDeletable(hhhId: number): Observable<IsEstablishmentsDeletable> {
    return this.httpClient.get<IsEstablishmentsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + `/delete/${hhhId}`);
  }
}

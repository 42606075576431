export class TrialInclusion {
  hhhId: number;
  publicCommentCount: number;
  inclusionIdentifier1: string;
  inclusionIdentifier2: string;
  arm: string;
  armHhhId: number;
  manual: boolean;
  patientInternalIdentifier: string;
  patientLastName: string;
  patientFirstName: string;
  patientBirthName: string;
  calculatedState: string;
  state: string;
  multiSite: boolean;
  site: string;
  siteHhhId: number;
  inclusionDate: string;
  trialTreatmentEndDate: string;
  followupEndDate: string;
  lastRealizedVisitDate: string;
  referrerInvestigator: string;
  preScreeningSignatoryDoctor: string;
  patientBirthDate: string;
  preScreeningConsentSignatureDate: string;
  blocRequestDate: string;
  resultDate: string;
  initialConsentSignatureDate: string;
  initialConsent: string;
  disabled: boolean;
  patientSex: string;
  inclusionPreScreeningIdentifier: string;
  preScreeningDate: string;
  patientHhhId: number;
  organList: string;
  preScreeningConsent: string;
  result: string;
  preScreeningFailure: boolean;
  preScreeningFailureDate: string;
  preScreeningFailureReason: string;
  trialTreatmentEndReason: string;
  inclusionTrialEndReason: string;
  screeningFailure: boolean;
  screenFailureDate: string;
  screeningFailureCause: string;
  randomizationDate: string;
  inclusionRandomizationIdentifier: string;
  trialTreatmentStartDate: string;
  otherConsentDates: string;
  hasCrossoverDate: string;
  dispatchBlockDate: Date;
  inclusionTrialEndDate: Date;

  init(obj: any): TrialInclusion {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}

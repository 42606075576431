import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ArcVisit} from '../models/arc-visit';
import {ResponsePage} from '../../shared/entity/response-page';
import {VisitWorkload} from '../models/visit-workload';
import {WorkloadGlobalFilter} from '../models/workload-global-filter';
import {ArcVisitWorkloadFilter} from '../models/arc-visit-workload-filter';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {HumanResourceGlobalFilter} from '../../human-resources/models/human-resource-global-filter';
import {ExportTypesEnum} from '../../dynamic-config/exported/data-export/ExportTypesEnum';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {PharmacyStockGlobalFilter} from '../../pharmacy/model/pharmacyStockGlobalFilter';
import {catchError, map} from 'rxjs/operators';
import {Provider} from '../../provider/models/provider';
import {Invoice} from '../../invoice/models/invoice';

@Injectable({
  providedIn: 'root'
})
export class WorkloadService {

  public properties: Properties = new Properties();
  public host = this.properties.host + '/workload';

  constructor(private httpClient: HttpClient) {
  }

  loadArcVisits(page: number, limit: number, sortBy: string, orderBy: string, workloadGlobalFilter: WorkloadGlobalFilter): Observable<ResponsePage<ArcVisit>> {
    return this.httpClient.post<ResponsePage<ArcVisit>>(
      this.host + '/arc-visits',
      new RequestPageBuilder<WorkloadGlobalFilter>()
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(workloadGlobalFilter)
        .build()
    ).pipe(map(result => {
            try {
                result.content = result.content.map(arcVisit => new ArcVisit().init(arcVisit));
                return result;
            } catch (e) {
                console.log(e);
            }
        }),
        catchError(this.handleError)
    );
  }

  getArcVisitWorkload(arcVisitWorkloadFilter: ArcVisitWorkloadFilter): Observable<VisitWorkload> {
    return this.httpClient.post<VisitWorkload>(this.host + '/arc-visit-workload/', arcVisitWorkloadFilter);
  }

  editVisitDuration(visitWorkload: VisitWorkload): Observable<any> {
    return this.httpClient.put(this.host + '/arc-visit-workload/', visitWorkload);
  }

  exportWorkload(request: ExportRequest<WorkloadGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

    private handleError(errorResponse: HttpErrorResponse): Observable<any> {
        return throwError(errorResponse.error);
    }

}

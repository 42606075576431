import {Injectable} from '@angular/core';
import {Properties} from "../../helpers/properties";
import {HttpClient} from "@angular/common/http";
import {VisitDose} from "../entity/inclusion/visit-dose";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class VisitDoseService {

  public host = new Properties().host + '/visit/dose';

  constructor(private httpClient: HttpClient) {
  }

  getVisitDoses(visitHhhId: number): Observable<VisitDose[]> {
    return this.httpClient.get<VisitDose[]>(this.host  + '/load-list/' + visitHhhId);
  }

  addVisitDoses(visitDose: VisitDose): Observable<VisitDose> {
    return this.httpClient.post<VisitDose>(this.host + '/save', visitDose);
  }

  deleteVisitDoses(visitDoseHhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + visitDoseHhhId);
  }

  editDose(visitDose: VisitDose) {
    return this.httpClient.put(this.host + '/update' , visitDose);
  }
}

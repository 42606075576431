import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {TrialHumanResource} from '../../trial/models/trial-human-resource';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class TrialHumanResourceService {

  public trialSiteHhhId: number;
  host = new Properties().host + '/trial-human-resource';

  private trialIdentificationInfo: Subject<TrialHumanResource> = new BehaviorSubject<TrialHumanResource>(null);
  trialIdentificationInfoValue = this.trialIdentificationInfo.asObservable();

  constructor(private httpClient: HttpClient) {
  }

  load(trialId: number, trialSiteLiaisonId?: number): Observable<TrialHumanResource> {
    return this.httpClient.get<TrialHumanResource>(this.host + '/load/' + trialId + '/' + (trialSiteLiaisonId || 0))
      .pipe(
        map(res => {
            return (new TrialHumanResource()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  updatePersonalInformation(trialId: number, trialSiteLiaisonId: number, trialIdentification: TrialHumanResource): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update/' + trialId + '/' + (trialSiteLiaisonId || 0), trialIdentification);
  }

  deleteTrialCoInvestigator(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/trial-co-investigator/' + hhhId);
  }
  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

  export(request: ExportRequest<void>, trialSiteLiaisonHhhId: number): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv/' + trialSiteLiaisonHhhId, request);
  }

}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {NotificationNote} from '../models/notification-note';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationManagementService {

  private host = new Properties().host + '/mobile-application/push-notification';

  constructor(private httpClient: HttpClient) {
  }

  updatePushNotification(pushNotification: NotificationNote): Observable<any> {
    return this.httpClient.put(this.host, pushNotification);
  }

  getPushNotifications(): Observable<NotificationNote[]> {
    return this.httpClient.get<NotificationNote[]>(this.host);
  }

  sendCustomNotification(pushNotification: NotificationNote): Observable<any> {
    return this.httpClient.post<any>(this.host, pushNotification);
  }
}

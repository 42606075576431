import {AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {CalendarService} from '../../../../../service/calendar.service';
import {SharedService} from '../../../../../../shared/services/shared.service';
import {Arm} from '../../../../../entity/inclusion/arm';
import {TheoreticalCalendarService} from '../../../../../service/theoretical-calendar.service';
import {Router} from '@angular/router';
import {MenuItem, SelectItem} from 'primeng/api';
import {VisitService} from '../../../../../service/visit.service';
import {Data} from '../../../../../../shared/entity/data';
import {TrialAct} from '../../../../../entity/theoretical-calendar/trial-act';
import {VisitDuplicationFormComponent} from '../visit-duplication-form/visit-duplication-form.component';
import {DeleteConfirmationComponent} from '../../../../../../modals/delete-confirmation/delete-confirmation.component';
import {ConcomitantTreatmentFormComponent} from '../../../patient-follow-up/concomitant-treatment-form/concomitant-treatment-form.component';
import {AdverseEffectFormComponent} from '../../../patient-follow-up/adverse-effect-form/adverse-effect-form.component';
import {VitalSignsFormComponent} from '../../../patient-follow-up/vital-signs-form/vital-signs-form.component';
import {FR} from '../../../../../../shared/entity/calendar-language';
import {VisitAction} from '../../../../../../visit-action';
import {Visit} from '../../../../../entity/visit';
import {VisitStateUpdateConfirmationComponent} from '../visit-state-update-confirmation/visit-state-update-confirmation.component';
import {VisitActStateUpdateConfirmationComponent} from '../visit-act-state-update-confirmation/visit-act-state-update-confirmation.component';
import {VisitActService} from '../../../../../service/visit-act.service';
import {VisitActUpdateFormComponent} from '../visit-act-update-form/visit-act-update-form.component';
import {VisitAct} from '../../../../../entity/inclusion/visit-act';
import {UsersService} from '../../../../../../admin/services/users.service';
import {VisitStatesService} from '../../../../../service/visit-states.service';
import {VisitsPharmacyDispensationKitFormComponent} from '../visits-pharmacy-dispensation-kit-form/visits-pharmacy-dispensation-kit-form.component';
import {TrialService} from '../../../../../services/trial.service';
import {VisitDoseFormComponent} from '../visit-dose-form/visit-dose-form.component';
import {RealVisitDateUpdateConfirmationComponent} from '../../../../../../modals/real-visit-date-update-confirmation/real-visit-date-update-confirmation.component';
import {VisitUpdate} from '../../../../../entity/inclusion/visit-update';
import {VisitActUpdate} from '../../../../../entity/inclusion/visit-act-update';
import {VisitSharedService} from '../../../../../../shared/services/visit-shared.service';
import {TrialPageLockService} from '../../../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {ShiftingHistoryService} from '../../../../../service/shifting-history.service';
import {OperationalActUpdateFormComponent} from '../operational-act-update-form/operational-act-update-form.component';
import {TrialOperationalActService} from '../../../../../service/trial-operational-act.service';
import {PharmacyDispensationKit} from '../../../../../entity/trial-pharmacy/PharmacyDispensationKit';
import {PharmacyDispensationKitService} from '../../../../../service/pharmacy-dispensation-kit.service';
import {AuthenticationService} from '../../../../../../login/services/authentication.service';
import {Role} from '../../../../../../login/models/role';
import {PharmacyService} from '../../../../../service/pharmacy.service';
import {StateType} from '../../../../../../shared/entity/payment-type.enum';
import {DynamicConfigService} from '../../../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../../../dynamic-config/entity/property-enum';
import {SwitchState} from '../../../../../entity/inclusion/switch-state';
import {DataItemService} from '../../../../../../custom-field/service/data-item.service';
import {DataCommentInfo} from '../../../../../../data-comment/entity/data-comment';
import {DataCommentComponent} from '../../../../../../data-comment/component/data-comment/data-comment.component';
import {AttachedToEntityEnum} from '../../../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentEvent} from '../../../../../../data-comment/entity/DataCommentEvent';
import {VisitShiftingHistory} from '../../../../../entity/inclusion/visit-shifting-history';
import {Util} from '../../../../../../helpers/util';

@Component({
  selector: 'ih-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.css']
})
export class VisitComponent implements OnInit, AfterViewInit {

  @ViewChild('duplicationVisitPopup', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('deleteTheoreticalVisitPopup', {read: ViewContainerRef}) deletePopupContainer: ViewContainerRef;
  @ViewChild('concomitantTreatmentPopup', {read: ViewContainerRef}) popup2Container: ViewContainerRef;
  @ViewChild('visitStateUpdateConfirmationPopup', {read: ViewContainerRef}) visitStateUpdateContainer: ViewContainerRef;
  @ViewChild('visitActStateUpdateConfirmationPopup', {read: ViewContainerRef}) visitActStateUpdateContainer: ViewContainerRef;
  @ViewChild('updateVisitActDialog', {read: ViewContainerRef}) updateVisitActDialogContainer: ViewContainerRef;
  @ViewChild('updateOperationalActDialog', {read: ViewContainerRef}) updateOperationalActDialogContainer: ViewContainerRef;
  @ViewChild('updateVisitDateConfirmationDialog', {read: ViewContainerRef}) updateVisitDateConfirmationDialogContainer: ViewContainerRef;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) postitPopupContainer: ViewContainerRef;
  @ViewChild('displayDoseDialog', {read: ViewContainerRef}) dosePopupContainer: ViewContainerRef;

  @ViewChild('dispensationDetails', {read: ViewContainerRef}) dispensationDetails: ViewContainerRef;


  @Input() visit: Visit;
  @Input() isInclusionActive: boolean;
  @Input() displayToggleActs: boolean;
  @Input() additionalVisitsEnabled: boolean;
  @Input() instantlysaveChanges = false;
  @Input() arms: { label, value }[];
  @Input() from = '';
  @Output() updatedVisitEvent = new EventEmitter<VisitAction>();
  @Output() updatedVisit = new EventEmitter<Visit>();
  @Output() onVisitChange: EventEmitter<VisitUpdate> = new EventEmitter();
  @Output() onVisitActChange: EventEmitter<VisitActUpdate> = new EventEmitter<VisitActUpdate>();

  public visitHhhId: number;  // a revoir
  public visitActHhhIds: number[] = [];
  public trialHhhId: number;
  public inclusionHhhId: number;
  public visitActState: { id: number, type: StateType };
  public showInclusionActs = false;
  public displayShiftDaysDialog: boolean;
  public followUpPatientMenu: MenuItem[];
  public shiftingHistories: MenuItem[];
  public oldVisitStateHhhId: number;
  public trialActsSelected: TrialAct[] = [];
  public noYesOptions: SelectItem[] = Data.yesOrNo;
  public visitActPaymentStates: any = Data.visitActPaymentStates;
  public visitActRealisationStates: any = Data.visitActRealisationStates;
  public arm: Arm = new Arm();
  public locale = FR;
  public visitDisabled: boolean;
  public showEmptyActsMessage: boolean;

  displayPharmacyIcon: boolean;

  allVisitStates: SelectItem[] = [];
  maxRealDate: Date;
  oldVisitDate: Date;
  updatedVisitActs: number[] = [];

  visitActStates = Data.visitActStates;
  doNotShift = false;
  actualDateHasChanged = false;
  stateHasChanged = false;
  registrationDateHasChanged = false;
  pendingVisitUpdate: VisitUpdate;

  stateType = StateType;
  isInclusionCalendarSavingButtonApart = false;
  isShowDateOrPriceEnbaled = true;
  isVisitActRealisationDateEnabled = true;
  isOptionalEnabled = false;
  isTheoreticalVisitMarginEnabled = false;
  isInvoiceablePaidVisitStateDisabled = false;
  isPharmacyEnabled = false;
  isRetroCessionInvoiceEnabled = false;
  isPatientVisitDuplicationEnabled = false;
  shiftVisitsExceptAdditionalTestEnabled = false;
  isRotated = false;

  constructor(
    private sharedService: SharedService,
    private useService: UsersService,
    private router: Router,
    public trialService: TrialService,
    private visitService: VisitService,
    private visitActService: VisitActService,
    private dispensationKitService: PharmacyDispensationKitService,
    private authenticationService: AuthenticationService,
    public pharmacyService: PharmacyService,
    private shiftingHistoryService: ShiftingHistoryService,
    private cfr: ComponentFactoryResolver,
    public trialPageLockService: TrialPageLockService,
    private visitSharedService: VisitSharedService,
    private visitStateService: VisitStatesService,
    private dynamicConfigService: DynamicConfigService
  ) {
    this.maxRealDate = new Date(2100, 1, 1);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
      this.inclusionHhhId = url.queryParams.inclusionId;
    }
  }

  ngOnInit() {
    this.initProperties();
    this.visitHhhId = this.visit.hhhId;
    this.visitService.changesSaved.subscribe(() => {
      this.actualDateHasChanged = false;
      this.stateHasChanged = false;
      this.registrationDateHasChanged = false;
      this.updatedVisitActs = [];
    });
    this.getVisitActs();
    this.oldVisitStateHhhId = this.visit.visitStateHhhId;
    this.visit.visitDate = !this.visit.visitDate ? null : new Date(this.visit.visitDate);
    this.followUpPatientMenu = [
      {label: 'Traitements Concomitants', command: () => this.openFollowUpPatient('concomitantTreatment')},
      {label: 'EIG', command: () => this.openFollowUpPatient('eig')},
      {label: 'Signes vitaux', command: () => this.openFollowUpPatient('')}
    ];
    this.visitDisabled = this.visit.visitStateHhhId === 18 ||
      ((!this.isInclusionActive) && (this.visit.visitStateHhhId === 11 || this.visit.visitStateHhhId === 12));
    if (this.visitSharedService.getVisitHhhId() === this.visit.hhhId) {
      this.toggleActsDisplay(this.visitSharedService.getVisitHhhId());
    }
    this.initVisitStates();
    this.showPharmacyIcon();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isInclusionCalendarSavingButtonApart = this.dynamicConfigService.getProperty(PropertyEnum.inclusionCalendarSavingButtonApart);
      this.isShowDateOrPriceEnbaled = this.dynamicConfigService.getProperty(PropertyEnum.showDateOrPriceEnabled);
      this.isVisitActRealisationDateEnabled = this.dynamicConfigService.getProperty(PropertyEnum.visitActRealisationDateEnabled);
      this.isOptionalEnabled = this.dynamicConfigService.getProperty(PropertyEnum.optionalEnabled);
      this.isTheoreticalVisitMarginEnabled = this.dynamicConfigService.getProperty(PropertyEnum.theoreticalVisitMarginEnabled);
      this.isInvoiceablePaidVisitStateDisabled = this.dynamicConfigService.getProperty(PropertyEnum.invoiceablePaidVisitStateDisabled);
      this.isPharmacyEnabled = this.dynamicConfigService.getProperty(PropertyEnum.pharmacyEnabled);
      this.isRetroCessionInvoiceEnabled = this.dynamicConfigService.getProperty(PropertyEnum.retroCessionInvoiceEnabled);
      this.isPatientVisitDuplicationEnabled = this.dynamicConfigService.getProperty(PropertyEnum.patientVisitDuplicationEnabled);
      this.shiftVisitsExceptAdditionalTestEnabled = this.dynamicConfigService.getProperty(PropertyEnum.shiftVisitsExceptAdditionalTestEnabled);
    });
  }

  showPharmacyIcon() {
    this.pharmacyService.isPharmacyDoubleBlind(this.trialHhhId).subscribe(
      (isDoubleBlind) => {
        if (isDoubleBlind !== null) {
          if (
            (isDoubleBlind === true
              && ((this.authenticationService.currentUserRole === Role.Pharmacist) || this.authenticationService.currentUserRole === Role.Admin))
          ) { this.displayPharmacyIcon = true; }
        }
      }, (error) => {
        console.log(error);
      }
    );
  }

  ngAfterViewInit() {
    if (this.visitSharedService.getVisitHhhId() !== undefined && this.visitSharedService.getVisitHhhId() === this.visit.hhhId) {
      const elmnt = document.getElementById(this.visitSharedService.getVisitHhhId().toString());
      elmnt.scrollIntoView();
      this.visitSharedService.deleteVisitHhhId();
    }
  }

  verifyClient = (clients: string[]) => this.dynamicConfigService.verifyClient(clients);

  initVisitStates() {
    this.visitStateService.getVisitStates(result => {
      result.forEach(s => {
        this.allVisitStates.push({
          label: s.label,
          value: s.value,
          disabled: s.disabled
        });
      });
      if (this.isInvoiceablePaidVisitStateDisabled) {
        this.disableInvoiceablePaidVisitStates();
      }
    });
  }


  private disableInvoiceablePaidVisitStates() {
    const disableStates: number[] = [2, 3, 4, 5];
    for (const state of this.allVisitStates) {
      state.disabled = disableStates.includes(state.value);
    }
  }

  updateDisabledStates() {
    const realisedStates: number[] = [2, 3, 4, 5, 10, 13, 14, 15, 16];
    for (const state of this.allVisitStates) {
      state.disabled = (realisedStates.indexOf(state.value) >= 0
        && this.visit
        && this.visit.visitDate > new Date())
        || state.disabled;
    }
  }

  updateMaxDateInVisit() {
    const realisedStates: number[] = [2, 3, 4, 5, 10, 13, 14, 15, 16];
    if (this.visit && realisedStates.indexOf(this.visit.visitStateHhhId) >= 0) {
      this.maxRealDate = new Date();
    }
  }

  createVisitDuplicationComponent(hhhId: number, visitArmValue: string) {
    const duplicationDialogFactory = this.cfr.resolveComponentFactory(VisitDuplicationFormComponent);
    const duplicationDialogComponentRef = this.popupContainer.createComponent(duplicationDialogFactory);
    const visitDuplicationFormComponent = duplicationDialogComponentRef.instance;
    visitDuplicationFormComponent.displayDuplicateVisitDialog = true;
    visitDuplicationFormComponent.clonedVisit.visitHhhId = hhhId;
    visitDuplicationFormComponent.clonedVisit.inclusionHhhId = this.inclusionHhhId;
    visitDuplicationFormComponent.clonedVisit.selectedArm = visitArmValue;
    visitDuplicationFormComponent.arms = this.arms;
    visitDuplicationFormComponent.visitName = this.visit.visitName;
    visitDuplicationFormComponent.onClone((visitAction: VisitAction) => {
      this.updatedVisitEvent.emit(visitAction);
      if (this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges) {
        this.sharedService.showSuccess('MODULE_TRIALS_INCLUSION_DUPLICATE_VISIT');
      } else {
        this.sharedService.showSuccess();
      }
    });
  }

  displayDeleteDialogue(entity: any) {
    const deletePopupFactory = this.cfr.resolveComponentFactory(DeleteConfirmationComponent);
    const deletePopupComponentRef = this.deletePopupContainer.createComponent(deletePopupFactory);
    const deleteConfirmationComponent = deletePopupComponentRef.instance;
    deleteConfirmationComponent.hhhId = entity.hhhId;
    deleteConfirmationComponent.name = entity.visitName;
    deleteConfirmationComponent.isInclusionCalendarSavingButtonApart = this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges;
    deleteConfirmationComponent.popupMessage = 'MODULE_TRIALS_CALENDARS_VISITS_DIALOG_WARNING_VISIT_WILL_BE_DELETED';
    deleteConfirmationComponent.service = this.visitService;
    const visitAction = new VisitAction(entity, entity.clonedStateOfInclusion, 'delete');
    deleteConfirmationComponent.onDelete(() => {
      this.updatedVisitEvent.emit(visitAction);
    });
  }

  createConcomitantTreatmentFormComponent(hhhId: number) {
    const popupDialogFactory = this.cfr.resolveComponentFactory(ConcomitantTreatmentFormComponent);
    const popupDialogComponentRef = this.popup2Container.createComponent(popupDialogFactory);
    const concomitantTreatmentFormComponent = popupDialogComponentRef.instance;
    concomitantTreatmentFormComponent.display = true;
    concomitantTreatmentFormComponent.formHeader = 'MODULE_TRIALS_PATIENTS_CONCOMITANT_TREATMENT_GENERAL_ADD_NEW';
    concomitantTreatmentFormComponent.concomitantTreatment.visitHhhId = hhhId;
    concomitantTreatmentFormComponent.concomitantTreatment.inclusionHhhId = this.inclusionHhhId;
    concomitantTreatmentFormComponent.onAdd(() => {});
  }

  createAdverseEffectFormComponent(hhhId: number) {
    const popupDialogFactory = this.cfr.resolveComponentFactory(AdverseEffectFormComponent);
    const popupDialogComponentRef = this.popup2Container.createComponent(popupDialogFactory);
    const adverseEffectFormComponent = popupDialogComponentRef.instance;
    adverseEffectFormComponent.display = true;
    adverseEffectFormComponent.formHeader = 'MODULE_TRIALS_PATIENTS_ADVERSE_EFFECTS_GENERAL_ADD_NEW';
    adverseEffectFormComponent.adverseEffect.visitHhhId = hhhId;
    adverseEffectFormComponent.adverseEffect.inclusionHhhId = this.inclusionHhhId;
    adverseEffectFormComponent.onAdd(() => {});
  }

  createVitalSignFormComponent(hhhId: number) {
    const popupDialogFactory = this.cfr.resolveComponentFactory(VitalSignsFormComponent);
    const popupDialogComponentRef = this.popup2Container.createComponent(popupDialogFactory);
    const vitalSignFormComponent = popupDialogComponentRef.instance;
    vitalSignFormComponent.display = true;
    vitalSignFormComponent.formHeader = 'MODULE_TRIALS_PATIENTS_VITAL_SIGNS_GENERAL_ADD_NEW';
    vitalSignFormComponent.vitalSign.visitHhhId = hhhId;
    vitalSignFormComponent.vitalSign.inclusionHhhId = this.inclusionHhhId;
    vitalSignFormComponent.noYesOptions = this.noYesOptions;
    vitalSignFormComponent.onAdd(() => {});
  }

  createVisitStateUpdateConfirmationComponent(reason: string) {
    const popupDialogFactory = this.cfr.resolveComponentFactory(VisitStateUpdateConfirmationComponent);
    const popupDialogComponentRef = this.visitStateUpdateContainer.createComponent(popupDialogFactory);
    const visitStateUpdateConfirmationComponent = popupDialogComponentRef.instance;
    visitStateUpdateConfirmationComponent.visit = this.visit;
    visitStateUpdateConfirmationComponent.from = 'TRACKING_TABLE';
    visitStateUpdateConfirmationComponent.reason = reason;
    visitStateUpdateConfirmationComponent.oldVisitStateHhhId = this.oldVisitStateHhhId;
    visitStateUpdateConfirmationComponent.stateType = this.visitActState.type;
    visitStateUpdateConfirmationComponent.from = this.inclusionHhhId === undefined ? 'TRACKING_TABLE' : 'patient-calendar';
    visitStateUpdateConfirmationComponent.visitStateUpdatedEvent.subscribe(() => {
      this.updatedVisit.emit(this.visit);
    });
    visitStateUpdateConfirmationComponent.updatedVisitActsRealisationStateEvent.subscribe(actRealisationStateHhhId => {
      this.visit.visitActs.forEach(visitAct => visitAct.realisationStateHhhId = actRealisationStateHhhId);
      this.updateTotalPrice();
    });
    visitStateUpdateConfirmationComponent.updatedVisitActsPaymentStateEvent.subscribe(actPaymentStateHhhId => {
      this.visit.visitActs.forEach(visitAct => {
        if (visitAct.invoiceable) {
          visitAct.paymentStateHhhId = actPaymentStateHhhId;
        }
      });
    });
    visitStateUpdateConfirmationComponent.canceledUpdateOfVisitActStateEvent.subscribe(() => {
      // this.visit.visitStateHhhId = this.oldVisitStateHhhId;
      this.updateVisitState();
    });
    visitStateUpdateConfirmationComponent.pendingVisitUpdate.subscribe((visitUpdate) => {
      visitUpdate.updatedField.push('STATE');
      this.onVisitChange.emit(visitUpdate);
      this.stateHasChanged = true;
    });
  }

  createVisitActStateUpdateConfirmationComponent(reason: string) {
    const popupDialogFactory = this.cfr.resolveComponentFactory(VisitActStateUpdateConfirmationComponent);
    const popupDialogComponentRef = this.visitStateUpdateContainer.createComponent(popupDialogFactory);
    const visitActStateUpdateConfirmationComponent = popupDialogComponentRef.instance;
    visitActStateUpdateConfirmationComponent.reason = reason;
    visitActStateUpdateConfirmationComponent.visitActHhhIds = this.visitActHhhIds;
    visitActStateUpdateConfirmationComponent.visitHhhId = this.visitHhhId;
    visitActStateUpdateConfirmationComponent.paymentStateHhhId = this.visitActState.id;
    visitActStateUpdateConfirmationComponent.state = this.visitActState;
    visitActStateUpdateConfirmationComponent.onVisitActPaymentStateChange(res => {
      if (this.visitActState.type === StateType.PROVIDER_PAYMENT) {
        this.visit.visitProviderStateHhhId = res;
      } else {
        this.visit.visitStateHhhId = res;
      }
    });
    visitActStateUpdateConfirmationComponent.updatedSelectedVisitActsRealisationStateEvent.subscribe(actRealisationStateHhhId => {
      this.visit.visitActs.forEach(visitAct => {
        if (this.visitActHhhIds.indexOf(visitAct.hhhId) !== -1) {
          visitAct.realisationStateHhhId = actRealisationStateHhhId;
          visitAct.checked = false;
        }
      });
    });

    visitActStateUpdateConfirmationComponent.updatedSelectedVisitActsPaymentStateEvent.subscribe(actPaymentStateHhhId => {
      this.visit.visitActs.forEach(visitAct => {
        if (this.visitActHhhIds.indexOf(visitAct.hhhId) !== -1) {
          if (this.visitActState.type === StateType.PROMOTER_PAYMENT) {
            visitAct.paymentStateHhhId = actPaymentStateHhhId;
          } else {
            visitAct.providerPaymentStateHhhId = actPaymentStateHhhId;
          }
          visitAct.checked = false;
        }
      });
    });
  }

  selectVisitAct(actHhhId: number, checked: boolean) {
    if (checked) {
      this.visitActHhhIds.push(actHhhId);
    } else {
      const index = this.visitActHhhIds.indexOf(actHhhId);
      this.visitActHhhIds.splice(index, 1);
    }
  }

  tryVisitStateChange() {
    this.visitActState = {id: Data.visitActPaymentStates.PAID, type: this.stateType.PROMOTER_PAYMENT};
    const realisedStates: number[] = [2, 3, 4, 5, 10, 13, 14, 15, 16];
    if (this.visit.visitDate && Util.isDateGreaterThan(this.visit.visitDate, new Date()) && realisedStates.indexOf(this.visit.visitStateHhhId) >= 0) {
      this.sharedService.showFailure('CANNOT_SET_FUTURE_VISIT_DATE');
      return;
    }
    const stateSwitch = new SwitchState(this.visit.hhhId, StateType.PROVIDER_PAYMENT, this.oldVisitStateHhhId, this.visit.visitStateHhhId);
    this.visitService.isVisitStateSwitchable(stateSwitch)
      .subscribe(isStateSwitchable => {
        if (isStateSwitchable.switchable) {
          if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_PAID') {
            this.visitActState = {id: Data.visitActPaymentStates.PAID, type: this.stateType.PROMOTER_PAYMENT};
            this.visit.visitActs.forEach(visitAct => {
              this.visitActHhhIds.push(visitAct.hhhId);
            });
            if (this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges && this.from !== 'TRACKING-TABLE') {
              const visitUpdate: VisitUpdate = new VisitUpdate();
              visitUpdate.visitId = this.visit.hhhId;
              visitUpdate.updatedField.push('STATE');
              visitUpdate.state = this.visit.visitStateHhhId;
              visitUpdate.updateVisitActState = true;
              visitUpdate.visitActStateId = this.visitActState.id;
              visitUpdate.visitActStateTypeEnum = this.stateType.PROMOTER_PAYMENT;
              visitUpdate.providerState = this.visit.visitProviderStateHhhId;
              this.onVisitChange.emit(visitUpdate);
              this.stateHasChanged = true;
            } else {
              this.updateVisitActState(this.stateType.PROMOTER_PAYMENT);
              this.updateVisitState();
            }
          } if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_NOT_RELEASED') {
            this.createVisitStateUpdateConfirmationComponent('ALL_VISIT_ACTS_MUST_BE_NOT_RELEASED');
          } else {
            if (this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges && this.from !== 'TRACKING-TABLE') {
              const visitUpdate: VisitUpdate = new VisitUpdate();
              console.error(this.visit.visitStateHhhId);
              visitUpdate.updatedField.push('STATE');
              visitUpdate.visitId = this.visit.hhhId;
              visitUpdate.state = this.visit.visitStateHhhId;
              visitUpdate.providerState = this.visit.visitProviderStateHhhId;
              visitUpdate.visitActStateTypeEnum = this.stateType.PROMOTER_PAYMENT;
              this.onVisitChange.emit(visitUpdate);
              this.stateHasChanged = true;
            } else {
              this.updateVisitState();
            }
          }

        } else if (isStateSwitchable.why === 'CANT_SWITCH_TO_UNREALISED') {
          this.createVisitStateUpdateConfirmationComponent('CANT_SWITCH_TO_UNINVOICED');
        } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_COMPLETED') {
          this.createVisitStateUpdateConfirmationComponent('COMPLETED');
        } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED') {
          this.createVisitStateUpdateConfirmationComponent('INVOICED');
        } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_PAID_VISIT') {
          this.createVisitStateUpdateConfirmationComponent('INVOICED_FROM_PAID_VISIT');
        } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_INVOICED_VISIT') {
          this.createVisitStateUpdateConfirmationComponent('INVOICED_FROM_INVOICED_VISIT');
        } else if (isStateSwitchable.why === 'CANT_SWITCH_TO_UNINVOICED') {
          this.createVisitStateUpdateConfirmationComponent('CANT_SWITCH_TO_UNINVOICED');
        } else if (isStateSwitchable.why === 'ONLY_FINANCE_USERS_CAN_SWITCH_STATE') {
          this.sharedService.showFailure('MODULE_TRIAL_PATIENT_CALENDAR_ONLY_FINANCE_USERS_CAN_SWITCH_STATE');
        } else if (isStateSwitchable.why === 'AOP_EMPTY') {
          this.sharedService.showFailure('MODULE_TRIAL_PATIENT_CALENDAR_ONLY_FOR_NO_EMPTY_AOP_STATE_IS_SWITCHED');
        } else if (isStateSwitchable.why === 'MODULE_TRIAL_PATIENT_CALENDAR_NOT_APPLICABLE') {
          this.createVisitStateUpdateConfirmationComponent('MODULE_TRIAL_PATIENT_CALENDAR_NOT_APPLICABLE');
        }
      });
  }

  tryVisitActStateChange() {
    if ([StateType.PROMOTER_PAYMENT, StateType.PROVIDER_PAYMENT].includes(this.visitActState.type)) {
      this.visitActService.getIsVisitActStateSwitchable(this.visitActHhhIds, this.visitActState.id, this.visitActState.type)
        .subscribe(isStateSwitchable => {
          if (isStateSwitchable.switchable) {
            this.updateVisitActState(this.visitActState.type);
          } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_COMPLETED') {
            this.createVisitActStateUpdateConfirmationComponent('COMPLETED');
          } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_INVOICED_ACTS') {
            this.createVisitActStateUpdateConfirmationComponent('INVOICED_FROM_INVOICED_ACTS');
          } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_PAID_ACTS') {
            this.createVisitActStateUpdateConfirmationComponent('INVOICED_FROM_PAID_ACTS');
          } else if (isStateSwitchable.why === 'ONLY_FINANCE_USERS_CAN_SWITCH_STATE') {
            this.sharedService.showFailure('MODULE_TRIAL_PATIENT_CALENDAR_ONLY_FINANCE_USERS_CAN_SWITCH_STATE');
          } else if (isStateSwitchable.why === 'AOP_EMPTY') {
            this.sharedService.showFailure('MODULE_TRIAL_PATIENT_CALENDAR_ONLY_FOR_NO_EMPTY_AOP_STATE_IS_SWITCHED');
          }
        });
    } else if (this.visitActState.type === StateType.REALISATION) {
      this.updateVisitActState(StateType.REALISATION);
    }
  }

  updateVisitState() {
    const switchState = new SwitchState(this.visit.hhhId, null, this.oldVisitStateHhhId, this.visit.visitStateHhhId);
    this.visitService.updateVisitAndActsState(switchState)
      .subscribe(res => {
        this.sharedService.showSuccess();
        this.visit.visitStateName = res;
        this.updatedVisit.emit(this.visit);
        this.updateMaxDateInVisit();
      });
  }

  updateVisitActState(stateType: StateType) {
    this.visitActService.updateVisitActsState(this.visitActHhhIds, stateType, this.visitActState.id)
      .subscribe(() => {
        let amountTotalDue = 0;
        this.visit.visitActs.forEach(visitAct => {
          if (this.visitActHhhIds.indexOf(visitAct.hhhId) !== -1) {
            if (this.visitActState.type === StateType.REALISATION) {
              if (this.canSwitch(visitAct)) {
                visitAct.realisationStateHhhId = this.visitActState.id;
              }
            } else if (this.visitActState.type === StateType.PROMOTER_PAYMENT) {
              visitAct.paymentStateHhhId = this.visitActState.id;
            } else {
              visitAct.providerPaymentStateHhhId = this.visitActState.id;
            }
          }
          if (visitAct.realisationStateHhhId === 2 && !visitAct.operational) {
            amountTotalDue += visitAct.pricePerUnit * visitAct.numberOfUnits;
          }
          visitAct.checked = false;
        });
        this.visit.amountTotalDue = amountTotalDue;
        this.visitActState = null;
        this.visitActHhhIds = [];
        this.sharedService.showSuccess();
      });
  }

  toggleActsDisplay(hhhId: number) {
    this.visitHhhId = hhhId;
    this.showInclusionActs = !this.showInclusionActs;
    if (this.showInclusionActs) {
      this.getVisitActs();
    }
    this.toggleRotation();
  }

  getVisitActs() {
    if (this.showInclusionActs && this.visitHhhId !== undefined) {
      this.visitActService.getVisitActs(this.visitHhhId).subscribe(res => {
        this.visit.visitActs = res;
        this.visit.visitActs.length === 0 ? this.showEmptyActsMessage = true : this.showEmptyActsMessage = false;
      });
    }
  }

  downloadVisitDetails(visitHhhId: number) {
    this.visitService.downloadVisitDetails(visitHhhId, this.trialHhhId, this.inclusionHhhId);
  }

  showPatientFollowUpMenu(hhhId: number) {
    this.visitHhhId = hhhId;
  }

  openFollowUpPatient(value: string) {
    if (value === 'concomitantTreatment') {
      this.createConcomitantTreatmentFormComponent(this.visitHhhId);
    } else if (value === 'eig') {
      this.createAdverseEffectFormComponent(this.visitHhhId);
    } else {
      this.createVitalSignFormComponent(this.visitHhhId);
    }
  }

  addActs() {
    if (this.visitHhhId === undefined) {
      this.sharedService.showFailure('PATIENT_VISIT_ADD_AC_TO_UNSAVED_VISIT');
      return;
    }
    this.visitActService.addActsToVisit(this.visitHhhId, this.trialActsSelected).subscribe((visitActs) => {
      this.sharedService.showSuccess();
      this.showEmptyActsMessage = false;
      this.showInclusionActs = true;
      this.visit.visitActs = [...visitActs];
      const tmpSelectedTrialActs = [];
      this.trialActsSelected = [...tmpSelectedTrialActs];
      this.updateTotalPrice();
      this.updateVisitStateAfterChangeVisitActs();
    }, error => {
      console.error(error);
      this.sharedService.showFailure();
    });
  }

  updateVisitStateAfterChangeVisitActs() {
    this.visitService.updateVisitStateAfterChangeActs(this.visitHhhId).subscribe(res => {
      this.visit.visitStateHhhId = res;
    });
  }

  updateTotalPrice() {
    this.visit.amountTotalDue = 0;
    for (const theoreticalVisitAct of this.visit.visitActs) {
      if (theoreticalVisitAct.realisationStateHhhId === 2) {
        this.visit.amountTotalDue += theoreticalVisitAct.pricePerUnit * theoreticalVisitAct.numberOfUnits;
      }
    }
  }

  updateVisitExpectedDate() {
    const realisedStates: number[] = [2, 3, 4, 5, 10, 13, 14, 15, 16];
    this.visitHhhId = this.visit.hhhId;
    if (this.visit.visitDate && Util.isDateGreaterThan(this.visit.visitDate, new Date()) && realisedStates.indexOf(this.visit.visitStateHhhId) >= 0) {
      this.sharedService.showFailure('CANNOT_SET_FUTURE_VISIT_DATE');
      return;
    }
    if (this.visit.visitDate) {
      if (this.isTheoreticalVisitMarginEnabled) {
        this.checkDate();
      } else {
        this.updateExpectedVisitDate();
      }
      this.visitHhhId = this.visit.hhhId;
    }
  }

  checkDate() {
    if (this.visit.expectedVisitDate === null) {
      this.updateExpectedVisitDate();
      return;
    }
    const marginTime = this.visit.periodMargin * 24 * 60 * 60 * 1000;
    const visitDate = new Date(this.visit.visitDate.getFullYear(), this.visit.visitDate.getMonth(), this.visit.visitDate.getDate());

    const maxExpected = new Date(this.visit.expectedVisitDate.getTime() + marginTime);
    const minExpected = new Date(this.visit.expectedVisitDate.getTime() - marginTime);

    const maxExpectedVisitDate = new Date(maxExpected.getFullYear(), maxExpected.getMonth(), maxExpected.getDate());
    const minExpectedVisitDate = new Date(minExpected.getFullYear(), minExpected.getMonth(), minExpected.getDate());

    if ((visitDate > maxExpectedVisitDate
      || visitDate < minExpectedVisitDate)
      && !this.sharedService.areDateEquals(this.visit.visitDate, this.visit.expectedVisitDate) && marginTime !== 0) {
      this.confirmRealDateUpdate();
    } else {
      this.updateExpectedVisitDate();
    }
  }

  confirmRealDateUpdate() {
    const updateConfirmationComponent = RealVisitDateUpdateConfirmationComponent.displayFormDialogue(this.updateVisitDateConfirmationDialogContainer, this.cfr);
    updateConfirmationComponent.onConfirm(() => {
      this.updateExpectedVisitDate();
    });
    updateConfirmationComponent.onAbort(() => {
      this.visit.visitDate = this.oldVisitDate;
    });
  }

  updateExpectedVisitDate() {
    const visitUpdate: VisitUpdate = new VisitUpdate();
    visitUpdate.visitId = this.visit.hhhId;
    visitUpdate.realDate = this.visit.visitDate;
    visitUpdate.updatedField.push('REAL_DATE');
    visitUpdate.shiftVisit = false;
    this.pendingVisitUpdate = visitUpdate;
    if (this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges) {
      this.onVisitChange.emit(visitUpdate);
      this.updateDisabledStates();
      this.actualDateHasChanged = true;
      if (!this.sharedService.areDateEquals(this.visit.visitDate, this.visit.expectedVisitDate)) {
        this.displayShiftDaysDialog = true;
      }
      return;
    }
    this.visit.visitDate.setHours(12, 0, 0, 0);
    this.visitService.updateExpectedVisitDate(this.visit.hhhId, this.visit.visitDate).subscribe(isUpdated => {
      this.getVisitActs();
      this.sharedService.showSuccess();
      this.updateDisabledStates();
      this.updatedVisit.emit(this.visit);
      if (isUpdated && !Util.areDateEquals(this.visit.visitDate, this.visit.expectedVisitDate)) {
        this.displayShiftDaysDialog = true;
      }
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while update expected date.' + error);
    });
  }

  updateVisitRegistrationDate(registrationDate: Date) {
    if (registrationDate) {
      if (this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges) {
        const visitUpdate: VisitUpdate = new VisitUpdate();
        visitUpdate.visitId = this.visit.hhhId;
        visitUpdate.updatedField.push('REGISTRATION_DATE');
        visitUpdate.registrationDate = registrationDate;
        this.onVisitChange.emit(visitUpdate);
        this.registrationDateHasChanged = true;
        return;
      }
      this.visitHhhId = this.visit.hhhId;
      this.visitService.updateVisitRegistrationDate(this.visit.hhhId, registrationDate).subscribe(() => {
        this.getVisitActs();
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while update expected date.' + error);
      });
    }
  }

  deleteVisitRegistrationDate() {
    if (this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges) {
      const visitUpdate: VisitUpdate = new VisitUpdate();
      visitUpdate.visitId = this.visit.hhhId;
      visitUpdate.updatedField.push('REGISTRATION_DATE');
      this.onVisitChange.emit(visitUpdate);
      this.registrationDateHasChanged = true;
      return;
    }
    this.visitService.deleteVisitRegistrationDate(this.visit.hhhId).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while update expected date.' + error);
    });
  }

  deleteVisitExpectedDate() {
    if (this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges) {
      const visitUpdate: VisitUpdate = new VisitUpdate();
      visitUpdate.visitId = this.visit.hhhId;
      visitUpdate.realDate = this.visit.visitDate;
      visitUpdate.updatedField.push('REAL_DATE');
      this.onVisitChange.emit(visitUpdate);
      this.actualDateHasChanged = true;
      return;
    }
    this.visitService.deleteExpectedVisitDate(this.visit.hhhId).subscribe(() => {
      this.sharedService.showSuccess();
      this.updateDisabledStates();
      const visitUpdate: VisitUpdate = new VisitUpdate();
      visitUpdate.visitId = this.visit.hhhId;
      visitUpdate.realDate = this.visit.visitDate;
      visitUpdate.updatedField.push('REAL_DATE');
      this.updatedVisit.emit(this.visit);
      this.visitService.notifyUpdateCompleted();

    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while update expected date.' + error);
    });
  }

  updateExpectedShiftedVisitsDate() {
    this.pendingVisitUpdate.shiftVisit = true;
    if (this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges && this.pendingVisitUpdate) {
      this.pendingVisitUpdate.visitListNotToBeShifted = this.visitService.visitListNotToBeShifted;
      this.displayShiftDaysDialog = false;
      this.pendingVisitUpdate = null;
      return;
    }
    this.visitService.updateExpectedShiftedVisitsDate(this.visitHhhId).subscribe(() => {
        const visitAction = new VisitAction(null, '', 'update');
        this.updatedVisitEvent.emit(visitAction);
        this.visitService.notifyUpdateCompleted();
      }, error => {
        console.error('An error occurred while shifting expected visits dates.' + error);
      }
    );
    this.displayShiftDaysDialog = false;
  }

  openVisitDialog(visitHhhId: number) {
    this.visitHhhId = visitHhhId;
    this.router.navigate(['/trial-details/inclusion-details/visit-detail'], {
      queryParams: {
        hhhId: this.trialHhhId,
        inclusionId: this.inclusionHhhId,
        visitHhhId: this.visitHhhId
      }
    }).then();
  }

  getDaysBetween(date1, date2): number {
    const realVisit = new Date(date1);
    realVisit.setHours(12, 0, 0, 0);
    const expectedVisit = new Date(date2);
    expectedVisit.setHours(12, 0, 0, 0);
    return Math.round((realVisit.getTime() - expectedVisit.getTime()) / (24 * 3600 * 1000));
  }

  changeBulkCheck(checked) {
    for (const visitAct of this.visit.visitActs) {
      visitAct.checked = checked;
      if (visitAct.checked) {
        this.visitActHhhIds.push(visitAct.hhhId);
      } else {
        const index = this.visitActHhhIds.indexOf(visitAct.hhhId);
        this.visitActHhhIds.splice(index, 1);
      }
    }
  }

  createVisitActUpdateDialogComponent(visitAct) {
    const addDialogFactory = this.cfr.resolveComponentFactory(VisitActUpdateFormComponent);
    const addDialogComponentRef = this.updateVisitActDialogContainer.createComponent(addDialogFactory);
    const visitActUpdateForm = addDialogComponentRef.instance;
    const index = this.visit.visitActs.indexOf(visitAct);
    visitActUpdateForm.visitAct = visitAct;
    visitActUpdateForm.onUpdate((modifiedVisitAct: VisitAct) => {
      this.visit.visitActs[index] = modifiedVisitAct;
      this.updateTotalPrice();
    });
    visitActUpdateForm.deleteEvent.subscribe(deletedVisitAct => {
      const index1 = this.visit.visitActs.indexOf(deletedVisitAct);
      this.visit.visitActs.splice(index1, 1);
      const tmpVisitActs = this.visit.visitActs;
      this.visit.visitActs = [...tmpVisitActs];
      this.updateTotalPrice();
    });
  }

  displayUnitPrice(visitAct: VisitAct): string {
    if (!this.useService.canAccessFieldByValue(visitAct.type)) {
      return '-';
    }
    return visitAct.invoiceable ? visitAct.pricePerUnit.toFixed(2) + '€' : '-';
  }


  displayTotalPrice(visitAct: VisitAct): string {
    if (!this.useService.canAccessFieldByValue(visitAct.type)) {
      return '-';
    }
    return visitAct.invoiceable ? (visitAct.pricePerUnit * visitAct.numberOfUnits).toFixed(2) + '€' : '-';
  }

  isPriceAccessed(type: string) {
    return this.useService.canAccessFieldByValue(type);
  }

  doNotShiftVisitDate() {
    if (this.doNotShift) {
      this.visitService.addVisitToVisitListNotToBeShifted(this.visitHhhId);
    } else {
      this.visitService.removeVisitFromVisitListNotToBeShifted(this.visitHhhId);
    }
  }

  updateVisitActRealisationDateDate(visitAct: VisitAct) {
    if (this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges) {
      this.updatedVisitActs.push(visitAct.hhhId);
      const visitActUpdate: VisitActUpdate = new VisitActUpdate();
      visitActUpdate.visitActId = visitAct.hhhId;
      visitActUpdate.visitActDate = visitAct.realisationDate;
      this.onVisitActChange.emit(visitActUpdate);
      return;
    }
    this.visitActService.updateVisitActRealisationDate(visitAct.hhhId, visitAct.realisationDate).subscribe(res => {
      this.sharedService.showSuccess();
    }, error1 => this.sharedService.showFailure());
  }

  setOldVisitDate() {
    this.oldVisitDate = this.visit.visitDate;
  }

  displayDataCommentDialog() {
    if (!this.visit || !this.visit.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.Visit, this.visit.hhhId, 'trackingTableVisit', false);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  displayDoseDialogue(visitHhhId: number) {
    const formComponent = VisitDoseFormComponent.displayFormDialogue(this.dosePopupContainer, this.cfr);
    formComponent.visitHhhId = visitHhhId;
    formComponent.onAdd(() => {
      this.visit.numberOfDoses++;
    });
    formComponent.onDelete(() => {
      this.visit.numberOfDoses--;
    });
  }

  visitActHasChanged(visitAct: VisitAct): boolean {
    return this.updatedVisitActs.findIndex(v => v === visitAct.hhhId) >= 0 && this.isInclusionCalendarSavingButtonApart && !this.instantlysaveChanges;
  }

  private canSwitch(visitAct) {
    const pending = 1;
    const canSwitchToUnrealised: boolean = visitAct.paymentStateHhhId === pending;
    const canSwitchToRealised: boolean = this.visitActState.id === 2;
    return canSwitchToUnrealised || canSwitchToRealised;
  }

  public isVisitLocked(): boolean {
    const isPaidId = this.allVisitStates.findIndex(s => s.label === 'Payée');
    const isFacturedId = this.allVisitStates.findIndex(s => s.label === 'Facturée');
    return this.trialPageLockService.isLocked && (this.visit.visitStateHhhId === this.allVisitStates[isPaidId].value ||
      this.visit.visitStateHhhId === this.allVisitStates[isFacturedId].value);
  }

  public showShiftingHistory() {
    this.shiftingHistories = [];
    this.shiftingHistoryService.getShiftingHistories(this.visit.hhhId).subscribe(res => {
      res.forEach(shiftingHistory =>  {
        this.shiftingHistories.push({
          label: shiftingHistory.name,
          command: () => {
            this.displayDeleteShiftingHistoryDialogue(shiftingHistory);
          }
        });
      });
    });
  }

  createOperationalActUpdateDialogComponent(visitAct: VisitAct) {
    const addDFactory = this.cfr.resolveComponentFactory(OperationalActUpdateFormComponent);
    const addDComponentRef = this.updateOperationalActDialogContainer.createComponent(addDFactory);
    const operationalActUpdateForm = addDComponentRef.instance;
    operationalActUpdateForm.visitAct = visitAct;
    const index = this.visit.visitActs.indexOf(visitAct);
    operationalActUpdateForm.onUpdate((modifiedVisitAct: VisitAct) => {
      this.visit.visitActs[index] = modifiedVisitAct;
      this.updateTotalPrice();
    });
    operationalActUpdateForm.deleteEvent.subscribe(deletedVisitAct => {
      const index1 = this.visit.visitActs.indexOf(deletedVisitAct);
      this.visit.visitActs.splice(index1, 1);
      const tmpVisitActs = this.visit.visitActs;
      this.visit.visitActs = [...tmpVisitActs];
      this.updateTotalPrice();
    });
  }

  openKitDialog(pharmacyDispensationKit: PharmacyDispensationKit) {
    const formComponent = VisitsPharmacyDispensationKitFormComponent.displayFormDialogue(this.dispensationDetails, this.cfr);
    formComponent.pharmacyDispensationKit = pharmacyDispensationKit;
    formComponent.onAdd((newPharmacyDispensationKit: PharmacyDispensationKit) => {
      this.dispensationKitService.updateVisitKit(newPharmacyDispensationKit).subscribe(
        () => {
          this.sharedService.showSuccess();
          formComponent.display = false;
        }, error => {
          this.sharedService.showFailure();
          console.log(error);
        }
      );
    });
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.visit.publicCommentCount = dataCommentEvent.publicCommentCount;
  }


  displayDeleteShiftingHistoryDialogue(shiftingHistory: VisitShiftingHistory) {
    const deletePopupFactory = this.cfr.resolveComponentFactory(DeleteConfirmationComponent);
    const deletePopupComponentRef = this.deletePopupContainer.createComponent(deletePopupFactory);
    const deleteConfirmationComponent = deletePopupComponentRef.instance;
    deleteConfirmationComponent.hhhId = shiftingHistory.hhhId;
    deleteConfirmationComponent.name = shiftingHistory.name;
    deleteConfirmationComponent.popupMessage = 'Le décalage (' + shiftingHistory.name + ') va être supprimé';
    deleteConfirmationComponent.service = this.shiftingHistoryService;
    deleteConfirmationComponent.onDelete(() => {
    });
  }
  toggleRotation() {
    this.isRotated = !this.isRotated;
  }
}

export class InclusionCrossover {

  hhhId: number;
  publicCommentCount = 0;
  hasCrossoverDate = false;
  crossoverDate: Date;
  crossoverPhase = 0;
  crossoverEscalationExtension = 0;
  crossoverCohort = 0;
  crossoverDose = 0;
  arm2HhhId = 0;
  inclusionArm2HhhId = 0;

  init(obj: any): InclusionCrossover {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

}

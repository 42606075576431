import {Component, OnInit} from '@angular/core';
import {FR} from "../../shared/entity/calendar-language";
import {SharedService} from "../../shared/services/shared.service";
import {OtherLastCppAmendmentDate} from "../../trial/models/other-last-cpp-amendment-date";
import {OtherLastCppAmendmentDateService} from "../../trial/services/other-last-cpp-amendment-date.service";

@Component({
  selector: 'app-other-last-cpp-amendment-date',
  templateUrl: './other-last-cpp-amendment-date.component.html',
  styleUrls: ['./other-last-cpp-amendment-date.component.css']
})
export class OtherLastCppAmendmentDateComponent implements OnInit {

  display = true;
  otherLastCppAmendmentDateList: OtherLastCppAmendmentDate[] = [];
  locale = FR;
  callback: (amendedAgreementStream: string) => void;
  private trialId: number;

  constructor(private otherTheoreticalInvoiceDateService: OtherLastCppAmendmentDateService,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    this.getOtherLastCppAmendmentDate();
  }

  setTrialId(trialId: number) {
    this.trialId = trialId;
  }


  onAdd(callback: (amendedAgreementStream: string) => void) {
    this.callback = callback;
  }


  addDate() {
    const otherLastCppAmendmentDate = new OtherLastCppAmendmentDate();
    otherLastCppAmendmentDate.trialHhhId = this.trialId;
    this.otherLastCppAmendmentDateList.push(otherLastCppAmendmentDate);
  }

  saveOtherLastCppAmendmentDates() {
    this.otherTheoreticalInvoiceDateService.saveOtherLastCppAmendmentDates(this.trialId, this.otherLastCppAmendmentDateList).subscribe(
      (res) => {
        this.otherLastCppAmendmentDateList = res;
        this.display = false
      }
    )
  }

  getOtherLastCppAmendmentDate() {
    this.otherTheoreticalInvoiceDateService.loadList(this.trialId).subscribe(
      (res) => {
        this.otherLastCppAmendmentDateList = res;
        if (this.otherLastCppAmendmentDateList.length == 0)
          this.addDate();
      }
    )

  }

  deleteDate(hhhId: number) {
    this.otherTheoreticalInvoiceDateService.delete(hhhId).subscribe((res) => {
      const index = this.otherLastCppAmendmentDateList.findIndex((otherLastCppAmendmentDate: OtherLastCppAmendmentDate) => otherLastCppAmendmentDate.hhhId === res);
      this.otherLastCppAmendmentDateList.splice(index, 1);
    })
  }
}


import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Properties} from '../../../helpers/properties';
import {Observable, throwError} from 'rxjs';
import {InvoiceCounterPart} from '../models/invoice-counter-part';
import {InvoiceCounterParts} from '../models/invoice-counter-parts';
import {AdditionalCostState} from '../../../additional-cost-state.enum';
import {SubInvoiceGlobalFilter} from '../../models/sub-invoice-global-filter';
import {ResponsePage} from '../../../shared/entity/response-page';
import {ExportRequest} from '../../../dynamic-config/entity/export-request';
import {catchError, map} from 'rxjs/operators';
import {RequestPageBuilder} from '../../../dynamic-config/entity/request-page-builder';
import {RequestInvoiceAct} from '../../models/RequestInvoiceAct';

@Injectable({
  providedIn: 'root'
})
export class InvoiceCounterPartService {

  public host = new Properties().host + '/invoice-counter-part';
  public invoicedInvoiceCounterPart: InvoiceCounterPart[];
  public pendingInvoiceCounterPart: InvoiceCounterPart[];
  public invoiceId: number;

  constructor(private httpClient: HttpClient) {
  }

  getSortBy(sortBy: string) {
    switch (sortBy) {
      case 'type':
        return 'cp.type';
      case 'description':
        return 'description';
      case 'firstSegment':
        return 'cp.firstSegment';
      case 'secondSegment':
        return 'cp.secondSegment';
      case 'freeSS':
        return 'cp.freeSS';
      case 'numberOfUnits':
        return 'cp.numberOfUnits';
      case 'priceTotal':
        return 'cp.priceTotal';
      default:
        return sortBy;
    }
  }

  loadInvoicedCounterPartsList(invoiceHhhId: number, page: number, limit: number, sortBy: string, orderBy: string, subInvoiceGlobalFilter: SubInvoiceGlobalFilter): Observable<ResponsePage<InvoiceCounterPart>> {
    let secondSortBy = '';
    if (sortBy === 'pricePerUnit') {
      secondSortBy = 'cp.pricePerUnit';
    }
    if (sortBy === 'numberOfUnits') {
      secondSortBy = 'cp.numberOfUnits';
    }
    if (sortBy === 'priceTotal') {
      secondSortBy = 'cp.priceTotal';
    }
    sortBy = this.getSortBy(sortBy);

    return this.httpClient.post<ResponsePage<InvoiceCounterPart>>(
      this.host + '/invoiced/load-list',
      new RequestPageBuilder<SubInvoiceGlobalFilter>()
        .objectId(invoiceHhhId)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .secondSortBy(secondSortBy)
        .orderBy(orderBy)
        .filters(subInvoiceGlobalFilter)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(invoiceCounterPart => new InvoiceCounterPart().init(invoiceCounterPart));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  loadPendingCounterPartsList(hhhId: number, page: number, limit: number, sortBy: string, orderBy: string, retrocession: boolean, subInvoiceGlobalFilter: SubInvoiceGlobalFilter): Observable<ResponsePage<InvoiceCounterPart>> {
    let secondSortBy = '';
    if (sortBy === 'pricePerUnit') {
      secondSortBy = 'pricePerUnit';
    }
    if (sortBy === 'numberOfUnits') {
      secondSortBy = 'numberOfUnits';
    }
    if (sortBy === 'priceTotal') {
      secondSortBy = 'priceTotal';
    }
    sortBy = this.getSortBy(sortBy);

    return this.httpClient.post<ResponsePage<InvoiceCounterPart>>(
      this.host + '/pending/load-list',
      new RequestPageBuilder<SubInvoiceGlobalFilter>()
        .objectId(hhhId)
        .condition(retrocession)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .secondSortBy(secondSortBy)
        .orderBy(orderBy)
        .filters(subInvoiceGlobalFilter)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(invoiceCounterPart => new InvoiceCounterPart().init(invoiceCounterPart));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  addCounterPartsToInvoice(invoiceCounterParts: InvoiceCounterParts, retrocession: boolean) {
    return this.httpClient.post(this.host + '/add/' + retrocession, invoiceCounterParts);
  }

  deleteCounterPartsFromInvoice(invoiceCounterParts: InvoiceCounterParts, retrocession: boolean): Observable<void> {
    return this.httpClient.post<void>(this.host + '/delete/' + retrocession, invoiceCounterParts);
  }

  setInvoiceId(id: number) {
    this.invoiceId = id;
  }

  setExportConfiguration(state: string, invoiceCounterPartList: InvoiceCounterPart[]) {
    if (state === AdditionalCostState.Invoiced) {
      this.invoicedInvoiceCounterPart = invoiceCounterPartList;
    } else {
      this.pendingInvoiceCounterPart = invoiceCounterPartList;
    }
  }

  addAllCounterPartsToInvoice(invoiceHhhId: number, retrocession: boolean, filters: SubInvoiceGlobalFilter, toExcludeHhhIds: number[]) {
    const request = new RequestInvoiceAct<SubInvoiceGlobalFilter>(invoiceHhhId, toExcludeHhhIds, retrocession, '', '', filters);
    return this.httpClient.post(this.host + '/add-all', request);
  }

  deleteAllCounterPartsFromInvoice(invoiceHhhId: number, retrocession: boolean, filters: SubInvoiceGlobalFilter, toExcludeHhhIds) {
    const request = new RequestInvoiceAct<SubInvoiceGlobalFilter>(invoiceHhhId, toExcludeHhhIds, retrocession, '', '', filters);
    return this.httpClient.put(this.host + '/delete-all', request);

  }

  exportInvoicedCounterPartList(request: ExportRequest<SubInvoiceGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/invoiced/export-csv', request).pipe();
  }

  exportPendingCounterPartList(request: ExportRequest<SubInvoiceGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/invoices/invoiced-counter-parts/pending/export-csv', request);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {DropdownComponent} from '../../../../../shared/component/dropdown/dropdown.component';
import {MultiSelectComponent} from '../../../../../shared/component/multi-select/multi-select.component';
import {SelectItem} from 'primeng/api';
import {Data} from '../../../../../shared/entity/data';
import {SpecialityService} from '../../../../../human-resources/services/speciality.service';
import {Router} from '@angular/router';
import {SharedService} from '../../../../../shared/services/shared.service';
import {DataService} from '../../../../../shared/services/data-service';
import {CanDeactivateService} from '../../../../../shared/services/can-deactivate.service';
import {
  HumanResourceFormComponent
} from '../../../../../human-resources/human-resource-form/human-resource-form.component';
import {HumanResource} from '../../../../../human-resources/models/human-resource';
import {RoleValue} from '../../../../../human-resources/models/role-value';
import {TrialHumanResource} from '../../../../models/trial-human-resource';
import {TrialPersonalInformationService} from '../../../../../shared/services/trial-personal-information.service';
import {TrialInformationGenericComponent} from '../../trial-information-generic/trial-information-generic.component';
import {TrialService} from '../../../../services/trial.service';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../../trial-app-data';
import {Provider} from '../../../../../provider/models/provider';
import {PromoterType} from '../../../../../provider/models/promoter-type';
import {ProviderService} from '../../../../../provider/services/provider.service';
import {PropertyEnum} from '../../../../../dynamic-config/entity/property-enum';
import {FR} from '../../../../../shared/entity/calendar-language';
import {TrialCoInvestigator} from '../../../../models/TrialCoInvestigator';
import {TrialHumanResourceService} from 'src/app/human-resources/services/trial-human-resource.service';
import {PromoterService} from 'src/app/provider/services/promoter.service';
import {LabelValue} from '../../../../../shared/entity/label-value';
import {DataCommentInfo} from '../../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../../data-comment/entity/DataCommentEvent';
import {ExportRequestBuilder} from '../../../../../dynamic-config/entity/export-request-builder';
import {AppData} from '../../../../../helpers/app-data';
import {HumanResourcesService} from "../../../../../human-resources/services/human-resources.service";

@Component({
  selector: 'ih-trial-personnel-information',
  templateUrl: './trial-personnel-information.component.html',
  styleUrls: ['./trial-personnel-information.component.css']
})
export class TrialPersonnelInformationComponent extends TrialInformationGenericComponent<TrialHumanResource> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_PERSONNEL_INFORMATION_FORM;
  config: FormConfig = new FormConfig();
  isTrialCoInvestigatorsEnabled = false;
  displayTrialCoInvestigatorsDtos = false;
  submitted = false;

  @Input() mode: string;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('addHumanResourceDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;

  @ViewChild('piDropdown') piDropdown: DropdownComponent<any>;
  @ViewChild('arc1Dropdown') arc1Dropdown: DropdownComponent<any>;
  @ViewChild('arc2Dropdown') arc2Dropdown: MultiSelectComponent<any>;
  @ViewChild('tec1Dropdown') tec1Dropdown: DropdownComponent<any>;
  @ViewChild('tec2Dropdown') tec2Dropdown: MultiSelectComponent<any>;
  @ViewChild('dataManager1Dropdown') dataManager1Dropdown: DropdownComponent<any>;
  @ViewChild('dataManager2Dropdown') dataManager2Dropdown: MultiSelectComponent<any>;
  @ViewChild('arcManagerDropdown') arcManagerDropdown: DropdownComponent<any>;
  @ViewChild('arcManager2Dropdown') arcManager2Dropdown: DropdownComponent<any>;
  @ViewChild('referentDoctorDropdown') referentDoctorDropdown: DropdownComponent<any>;
  @ViewChild('vigilanceResDropdown') vigilanceResDropdown: DropdownComponent<any>;
  @ViewChild('qualityResDropdown') qualityResDropdown: DropdownComponent<any>;
  @ViewChild('coInvestigatorDropdown') coInvestigatorDropdown: MultiSelectComponent<any>;
  @ViewChild('amrDropdown') amrDropdown: DropdownComponent<any>;
  @ViewChild('ircResDropdown') ircResDropdown: DropdownComponent<any>;
  @ViewChild('ircBackupResDropdown') ircBackupResDropdown: DropdownComponent<any>;
  @ViewChild('referentPharmacistBackupDropdown') referentPharmacistBackupDropdown: DropdownComponent<any>;
  @ViewChild('referentPharmacistDropdown') referentPharmacistDropdown: DropdownComponent<any>;
  @ViewChild('gpDropdown') gpDropdown: DropdownComponent<any>;
  @ViewChild('projectManagerDropdown') projectManagerDropdown: MultiSelectComponent<any>;
  @ViewChild('assistant') assistant: MultiSelectComponent<any>;
  @ViewChild('promoterDropdown') promoterDropdown: DropdownComponent<Provider>;
  @ViewChild('referentLaboratoryDropdown') referentLaboratoryDropdown: DropdownComponent<any>;
  @ViewChild('projectManagerFrontDeskDropdown') projectManagerFrontDeskDropdown: DropdownComponent<any>;
  @ViewChild('contractManagerDropdown') contractManagerDropdown: DropdownComponent<any>;
  @ViewChild('invoicingManagerDropdown') invoicingManagerDropdown: DropdownComponent<any>;
  @ViewChild('ircsDropdown') ircsDropdown: DropdownComponent<any>;
  @ViewChild('medicalResearchAssistantDropdown') medicalResearchAssistantDropdown: DropdownComponent<any>;
  @ViewChild('sampleManagerDropdown') sampleManagerDropdown: DropdownComponent<any>;
  @ViewChild('researchClinicTec1Dropdown') researchClinicTec1Dropdown: DropdownComponent<any>;
  configReady = new EventEmitter<boolean>(false);


  noYesOptions: SelectItem[] = Data.yesOrNo;
  noYesNullOptions: SelectItem[] = Data.yesOrNoOrNull;
  trialSiteHhhId: number;
  trialHhhId: number;
  investigatorSpecialityOther = false;
  selectedInvestigators: number[] = [];
  selectedDataManagers: number[] = [];
  selectedArcs: number[] = [];
  selectedTecs: number[] = [];
  selectedIrcs: number[] = [];
  selectedPharmacists: number[] = [];
  promoterTypes: LabelValue[] = [];
  locale = FR;
  humanRessourceWithoutRoleEnabled = true;
  ircMultiSelect = true;
  humanResourceIdWithFirstnameNA:number|null=null;

  constructor(
    public trialPersonalInformationService: TrialPersonalInformationService,
    public trialHumanResourceService: TrialHumanResourceService,
    public trialService: TrialService,
    private specialityService: SpecialityService,
    private router: Router,
    private sharedService: SharedService,
    private humanResourcesService: HumanResourcesService,
    private cfr: ComponentFactoryResolver,
    private canDeactivateService: CanDeactivateService,
    private dynamicConfigService: DynamicConfigService,
    private providerService: ProviderService,
    private promoterService: PromoterService,
    private cdr: ChangeDetectorRef
  ) {
    super(new TrialHumanResource());
    this.canDeactivateService.canBeDeactivated = true;
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.mode = 'edit';
      this.trialSiteHhhId = url.queryParams.trialSiteLiaisonHhhId;
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    this.trialPersonalInformationService.submitted.subscribe(res => {
      this.submitted = res;
    });
    if (this.trialSiteHhhId) {
      this.trialPersonalInformationService.setTrialSiteLiaisonHhhId(this.trialSiteHhhId);
    }
    if (this.trialHhhId) {
      this.getTrialIdentificationInformation();
    } else {
      super.getUnsavedTrialInformationGeneral();
    }
    this.getHumanResourceIdWithFirstnameNA();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.humanRessourceWithoutRoleEnabled = this.dynamicConfigService.getProperty(PropertyEnum.humanRessourceWithoutRoleEnabled);
      this.isTrialCoInvestigatorsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.trialCoInvestigatorsEnabled);
      this.ircMultiSelect = this.dynamicConfigService.getProperty(PropertyEnum.ircMultiSelect);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
        this.configReady.emit(true);
        this.displayTrialCoInvestigatorsDtos = this.isTrialCoInvestigatorsEnabled;

      }
    );
  }

  getService() {
    return this.trialPersonalInformationService;
  }

  getTrialIdentificationInformation() {
    this.trialHumanResourceService.load(this.trialHhhId, this.trialSiteHhhId).subscribe((res: TrialHumanResource) => {
      this.trialInformation = res;
      this.trialInformation.trialSiteHhhId = this.trialSiteHhhId;
      this.initCoInvestigatorField();
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while loading trial.' + error);
    });
  }

  createAddHumanResourceDialogComponent(humanResourceType: string, responsibilityPath?: string) {
    const formComponent = HumanResourceFormComponent.displayFormDialogue(this.dialogContainer, this.cfr);
    if (responsibilityPath) {
      formComponent.responsibilitiesPath = responsibilityPath;
    }
    formComponent.onAdd((humanResource: HumanResource) => {
      const rh = new RoleValue();
      rh.name = humanResource.lastName;
      rh.hhhId = humanResource.hhhId;
      switch (humanResourceType) {
        case 'pi':
          this.piDropdown.onRefreshDrop();
          this.coInvestigatorDropdown.onRefreshDrop();
          this.referentDoctorDropdown.onRefreshDrop();
          this.trialInformation.principalInvestigatorHhhId = rh.hhhId;
          this.piDropdown.value = rh.hhhId;
          break;
        case 'arc1':
          this.arc1Dropdown.onRefreshDrop();
          this.arc2Dropdown.onRefreshDrop();
          this.trialInformation.arc1HhhId = rh.hhhId;
          this.arc1Dropdown.value = rh.hhhId;
          break;
        case 'arc2':
          this.arc2Dropdown.onRefreshDrop();
          this.arc1Dropdown.onRefreshDrop();
          this.trialInformation.arc2HhhId.push(rh.hhhId);
          this.arc2Dropdown.values = this.trialInformation.arc2HhhId;
          break;
        case 'tec1':
          this.tec1Dropdown.onRefreshDrop();
          this.tec2Dropdown.onRefreshDrop();
          this.trialInformation.tec1HhhId = rh.hhhId;
          this.tec1Dropdown.value = rh.hhhId;
          break;
        case 'tec2':
          this.tec2Dropdown.onRefreshDrop();
          this.tec1Dropdown.onRefreshDrop();
          this.trialInformation.tec2HhhId.push(rh.hhhId);
          this.tec2Dropdown.values = this.trialInformation.tec2HhhId;
          break;

        case 'dataManager1':
          this.dataManager1Dropdown.onRefreshDrop();
          this.dataManager2Dropdown.onRefreshDrop();
          this.trialInformation.dataManager1HhhId = rh.hhhId;
          this.dataManager1Dropdown.value = rh.hhhId;
          break;
        case 'dataManager2':
          this.dataManager2Dropdown.onRefreshDrop();
          this.dataManager1Dropdown.onRefreshDrop();
          this.trialInformation.dataManager2HhhId.push(rh.hhhId);
          this.dataManager2Dropdown.values = this.trialInformation.dataManager2HhhId;
          break;
        case 'arcManager':
          this.arcManagerDropdown.onRefreshDrop();
          this.trialInformation.arcManagerHhhId = rh.hhhId;
          break;
        case 'arcManager2':
          this.arcManager2Dropdown.onRefreshDrop();
          this.trialInformation.arcManager2HhhId.push(rh.hhhId);
          break;
        case 'referentDoctor':
          this.referentDoctorDropdown.onRefreshDrop();
          this.piDropdown.onRefreshDrop();
          this.coInvestigatorDropdown.onRefreshDrop();
          this.trialInformation.referentDoctorHhhId = rh.hhhId;
          this.referentDoctorDropdown.value = rh.hhhId;
          this.referentPharmacistBackupDropdown.onRefreshDrop();
          break;
        case 'vigilanceRes':
          this.vigilanceResDropdown.onRefreshDrop();
          this.trialInformation.vigilanceResponsibleHhhId = rh.hhhId;
          break;
        case 'qualityRes':
          this.qualityResDropdown.onRefreshDrop();
          this.trialInformation.qualityResponsibleHhhId = rh.hhhId;
          break;
        case 'coInvestigator':
          this.coInvestigatorDropdown.onRefreshDrop();
          this.piDropdown.onRefreshDrop();
          this.referentDoctorDropdown.onRefreshDrop();
          this.trialInformation.coInvestigatorsHhhId.push(rh.hhhId);
          this.coInvestigatorDropdown.values = this.trialInformation.coInvestigatorsHhhId;
          break;
        case 'amr':
          this.amrDropdown.onRefreshDrop();
          this.trialInformation.amrHhhId = rh.hhhId;
          break;
        case 'irc':
          this.ircResDropdown.onRefreshDrop();
          this.ircBackupResDropdown.onRefreshDrop();
          this.trialInformation.ircHhhId = rh.hhhId;
          break;
        case 'ircBackup':
          this.ircBackupResDropdown.onRefreshDrop();
          this.ircResDropdown.onRefreshDrop();
          this.trialInformation.ircBackupHhhIds.push(rh.hhhId);
          break;
        case 'gp':
          this.gpDropdown.onRefreshDrop();
          this.trialInformation.samplingManagersHhhId = rh.hhhId;
          break;
        case 'projectManager':
          this.projectManagerDropdown.onRefreshDrop();
          this.trialInformation.projectManagerHhhId.push(rh.hhhId);
          break;
        case 'assistant':
          this.assistant.onRefreshDrop();
          this.trialInformation.assistantsHhhId.push(rh.hhhId);
          break;
        case 'referentLaboratory':
          this.referentLaboratoryDropdown.onRefreshDrop();
          this.trialInformation.referentLaboratoryHhhId = rh.hhhId;
          break;
        case 'projectManagerFrontDesk':
          this.projectManagerFrontDeskDropdown.onRefreshDrop();
          this.trialInformation.projectManagerFrontDeskHHhIds.push(rh.hhhId);
          break;
        case 'contractManager':
          this.contractManagerDropdown.onRefreshDrop();
          this.trialInformation.contractManagersHhhIds.push(rh.hhhId);
          break;
        case 'invoicingManager':
          this.invoicingManagerDropdown.onRefreshDrop();
          this.trialInformation.invoicingManagersHhhIds.push(rh.hhhId);
          break;
        case 'ircs':
          this.ircsDropdown.onRefreshDrop();
          this.trialInformation.ircsHhhId.push(rh.hhhId);
          break;
        case 'medicalResearchAssistant':
          this.medicalResearchAssistantDropdown.onRefreshDrop();
          this.trialInformation.medicalResearchAssistantHhhId.push(rh.hhhId);
          break;
        case 'sampleManager':
          this.sampleManagerDropdown.onRefreshDrop();
          this.trialInformation.sampleManagerHhhId.push(rh.hhhId);
          break;
        case 'researchClinicTec1':
          this.researchClinicTec1Dropdown.onRefreshDrop();
          this.trialInformation.researchClinicTec1HhhId.push(rh.hhhId);
          break;
        default:
          this.trialInformation.qualityResponsibleHhhId = rh.hhhId;
          break;
      }
    });
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.trialInformation.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialHumanResource, this.trialInformation.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.trialInformation.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  savePersonalInformation() {
    this.submitted = true;
    if (!this.trialInformation.isValid(this.config, this.investigatorSpecialityOther, this.isTrialCoInvestigatorsEnabled, this.displayTrialCoInvestigatorsDtos)) {
      return;
    }
    this.trialInformation.trialHhhId = this.trialHhhId;
    this.trialHumanResourceService.updatePersonalInformation(this.trialHhhId, this.trialSiteHhhId, this.trialInformation).subscribe(() => {
        this.sharedService.showSuccess();
        this.submitted = false;
        this.canDeactivateService.canBeDeactivated = true;
      }, error => {
        console.error('An error occurred while update trial identification.' + error);
        this.sharedService.showFailure();
      }
    );
  }

  sendNewTrialIdentificationInfo() {
    this.trialPersonalInformationService.setTrialIdentificationInfo(this.trialInformation);
  }

  getPiSpeciality() {
    this.specialityService.getPiSpecialityId(this.trialInformation.principalInvestigatorHhhId).subscribe(res => {
      if (res != null) {
        this.trialInformation.investigatorSpecialityHhhId = res;
      }
    });
  }

  isEditMode() {
    return this.mode === 'edit';
  }

  displaySelectedInvestigators(selectedValues) {
    this.selectedInvestigators = selectedValues;
  }

  displaySelectedDataManagers(selectedValues) {
    this.selectedDataManagers = selectedValues;
  }

  notifyWhenInvestigatorSpecialityOther(otherValue) {
    this.investigatorSpecialityOther = otherValue;
  }

  displaySelectedArc(selectedValues) {
    this.selectedArcs = selectedValues;
  }

  displaySelectedTec(selectedValues) {
    this.selectedTecs = selectedValues;
  }

  displaySelectedIrcs(selectedValues): void {
    this.selectedIrcs = selectedValues;
  }

  displaySelectedPharmacists(selectedValues): void {
    this.selectedPharmacists = selectedValues;
  }

  principalInvestigatorChanged() {
    if (this.trialInformation.principalInvestigatorHhhId != null) {
      this.getPiSpeciality();
    }
    this.canDeactivateService.canBeDeactivated = false;
  }

  formHasBeenChanged() {
    this.sendNewTrialIdentificationInfo();
    this.canDeactivateService.canBeDeactivated = false;
    this.cdr.detectChanges();
  }

  changeTrialPromoter() {
    if (this.trialInformation.promoterHhhId == null) {
      return;
    }
    this.providerService.getPromoterTypeByProviderHhhId(this.trialInformation.promoterHhhId).subscribe((res: PromoterType) => {
      this.trialInformation.promoterTypeHhhId = res.hhhId;
    });
    this.formHasBeenChanged();
  }

  getPromoterType() {
    this.promoterService.getPromoterTypeDtosList().subscribe(res => {
      this.promoterTypes = res;
      this.promoterTypes.splice(0, 0, {value: 0, label: null});
    });
  }

  changeTypePromoter() {
    if (this.trialInformation.promoterTypeHhhId != null) {
      const foundLabelValue = this.promoterTypes.find(element => element.value === this.trialInformation.promoterTypeHhhId);
      const promoterTypeDto = new PromoterType(foundLabelValue.value, foundLabelValue.label);
      this.trialInformation.promoterType = promoterTypeDto;
    }
    this.formHasBeenChanged();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  changeSelectedInvestigators(coInvestigatorDropdown) {
    this.selectedInvestigators.splice(this.selectedInvestigators
      .indexOf(coInvestigatorDropdown.initialValue), 1);
    this.canDeactivateService.canBeDeactivated = false;
  }

  addCoInvestigatorField() {
    this.trialInformation.trialCoInvestigatorDtos.push(new TrialCoInvestigator());
  }

  deleteCoInvestigator(coInvestigatorDropdown, i: number) {
    this.trialHumanResourceService.deleteTrialCoInvestigator(this.trialInformation.trialCoInvestigatorDtos[i].hhhId).subscribe(() => {
      if (this.trialInformation.trialCoInvestigatorDtos.length > 1) {
        this.trialInformation.trialCoInvestigatorDtos.splice(i, 1);
      } else {
        this.trialInformation.trialCoInvestigatorDtos.splice(i, 1, new TrialCoInvestigator());
      }
      this.selectedInvestigators.splice(this.selectedInvestigators
        .indexOf(coInvestigatorDropdown.value), 1);
      this.sharedService.showSuccess();
    }, () => {
      this.sharedService.showFailure();
    });
  }

  initCoInvestigatorField() {
    if (!this.trialInformation.trialCoInvestigatorDtos || this.trialInformation.trialCoInvestigatorDtos.length === 0) {
      this.trialInformation.trialCoInvestigatorDtos.push(new TrialCoInvestigator());
    }
  }

  export(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.trialHhhId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .build();
    this.trialHumanResourceService.export(request, this.trialSiteHhhId).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.getFieldConfig(fieldName).display;
  verifyClient = (clients: string[]) => this.dynamicConfigService.verifyClient(clients);

  getHumanResourceIdWithFirstnameNA(): void {
    this.humanResourcesService.getHumanResourceIdWithFirstnameNA().subscribe(
      (data: number) => {
        this.humanResourceIdWithFirstnameNA = data;
      },
      (error) => {
        console.error('Erreur lors de la récupération de l\'ID:', error);
        this.sharedService.showFailure('Impossible de récupérer l\'ID de la ressource humaine.');
      }
    );
  }

  onResetToNAChanged(isChecked: boolean, nbr: number) {
   const id=this.humanResourceIdWithFirstnameNA;
    if (!isChecked) {
      switch (nbr) {
        case 1:
          this.trialInformation.principalInvestigatorHhhId = null;
          break;
        case 2:
          this.trialInformation.tec1HhhId=null;
          break;
        case 3:
          this.trialInformation.arcManagerHhhId=null;
          break;
        case 4:
          this.trialInformation.referentDoctorHhhId=null;
          break;
        case 5:
          this.trialInformation.amrHhhId=null;
          break;
        case 6:
          this.trialInformation.ircHhhId=null;
          break;
        case 7:
          this.trialInformation.samplingManagersHhhId=null;
          break;
        case 8:
          this.trialInformation.arcReferentHhhId=[];
          break;
        case 9:
          this.trialInformation.arc2HhhId=[];
          break;
        case 10:
          this.trialInformation.projectManagerHhhId=[];
          break;
        case 11:
          this.trialInformation.coInvestigatorsHhhId=[];
          break;
        case 12:
          this.trialInformation.projectManagerFrontDeskHHhIds=[];
          break;
        case 13:
          this.trialInformation.contractManagersHhhIds=[];
          break;
        case 14:
          this.trialInformation.invoicingManagersHhhIds=[];
          break;
        case 15:
          this.trialInformation.arcManager2HhhId=[];
          break;
        case 16:
          this.trialInformation.researchClinicTec1HhhId=[];
          break;
        case 17:
          this.trialInformation.medicalResearchAssistantHhhId=[];
          break;
        case 18:
          this.trialInformation.ircsHhhId=[];
          break;
        case 19:
          this.trialInformation.sampleManagerHhhId=[];
          break;

      }
    } else {

      switch (nbr) {
        case 1:
          this.trialInformation.principalInvestigatorHhhId = id;
          break;
        case 2:
          this.trialInformation.tec1HhhId=id;
          break;
        case 3:
          this.trialInformation.arcManagerHhhId=id;
          break;
        case 4:
          this.trialInformation.referentDoctorHhhId=id;
          break;
        case 5:
          this.trialInformation.amrHhhId=id;
          break;
        case 6:
          this.trialInformation.ircHhhId=id;
          break;
        case 7:
          this.trialInformation.samplingManagersHhhId=id;
          break;
        case 8:
          this.trialInformation.arcReferentHhhId=[id];
          break;
        case 9:
          this.trialInformation.arc2HhhId=[id];
          break;
        case 10:
          this.trialInformation.projectManagerHhhId=[id];
          break;
        case 11:
          this.trialInformation.coInvestigatorsHhhId=[id];
          break;
        case 12:
          this.trialInformation.projectManagerFrontDeskHHhIds=[id];
          break;
        case 13:
          this.trialInformation.contractManagersHhhIds=[id];
          break;
        case 14:
          this.trialInformation.invoicingManagersHhhIds=[id];
          break;
        case 15:
          this.trialInformation.arcManager2HhhId=[id];
          break;
        case 16:
          this.trialInformation.researchClinicTec1HhhId=[id];
          break;
        case 17:
          this.trialInformation.medicalResearchAssistantHhhId=[id];
          break;
        case 18:
          this.trialInformation.ircsHhhId=[id];
          break;
        case 19:
          this.trialInformation.sampleManagerHhhId=[id];
          break;

      }
    }
  }

}

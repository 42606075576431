import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Patient} from '../models/patient';
import {PatientService} from '../services/patient.service';
import {Router} from '@angular/router';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {AuthenticationService} from '../../login/services/authentication.service';
import {DataService} from '../../shared/services/data-service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {DataCommentInfo} from '../../data-comment/entity/data-comment';
import {DataCommentComponent} from '../../data-comment/component/data-comment/data-comment.component';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {DataCommentEvent} from '../../data-comment/entity/DataCommentEvent';

@Component({
  selector: 'ih-patient-detail',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.css']
})
export class PatientDetailsComponent implements OnInit, OnDestroy {

  _target = DynamicDefinitionEnum.PATIENT_MODULE;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;

  public patientPageTopName: string;
  patient: Patient = new Patient();
  patientHhhId: number;


  constructor(
      private authService: AuthenticationService,
      private patientService: PatientService,
      private cfr: ComponentFactoryResolver,
      private translateService: InnohealthTranslateService,
      private router: Router,
      private dataService: DataService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.patientHhhId = Number(url.queryParams.hhhId);
    }
  }

  ngOnInit() {
    this.getPatient(this.patientHhhId);
    this.dataService.currentPageTopName.subscribe(res => {
      this.patientPageTopName = res;
    });
  }

  ngOnDestroy(): void {
    this.dataService.setPageTopName(null);
  }

  getPatient(patientId: number) {
    this.patientService.load(patientId).subscribe(res => {
      this.patient = res;
      this.dataService.setPageTopName(res.fullName);
      if (this.patient.birthDate) {
        // this.patient.birthDate = new Date(this.patient.birthDate);
      }
      if (this.patient.deathDate) {
        this.patient.deathDate = new Date(this.patient.deathDate);
      }
    });
  }

  isPatientMan(patient: Patient): string {
    if (patient.sex === this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MR')) {
      return 'M.';
    } else if (patient.sex === this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MME')) {
      return 'MME';
    }
  }

  returnBack() {
    this.router.navigate(['patient-list']).then();
  }

  displayDataCommentDialog(isPrivate: boolean) {
    if (!this.patient || !this.patient.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.Patient, this.patient.hhhId, 'hhhId', isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.patient.publicCommentCount = dataCommentEvent.publicCommentCount;
  }

}

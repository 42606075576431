import {ChangeDetectorRef, Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {DataService} from '../../../../shared/services/data-service';
import {Router} from '@angular/router';
import {TableHeader} from '../../../../shared/entity/table-header';
import {SelectItem} from 'primeng/api';
import {TrialOperationalAct} from '../../../entity/trial-additionnal-costs/trial-operational-act';
import {TrialOperationalActService} from '../../../service/trial-operational-act.service';
import {ActCategoryService} from '../../../../additional-costs/services/act-category.service';
import {OperationalTrialActsComponent} from '../operational-trial-acts/operational-trial-acts.component';
import {DeleteMultipleComponent} from '../../../../modals/delete-multiple/delete-multiple.component';
import {TranslateService} from '@ngx-translate/core';
import {Data} from '../../../../shared/entity/data';
import {TrialService} from '../../../services/trial.service';
import {OPERATIONAL_ACT_READ_WRITE} from '../../../../login/services/authentication.service';
import {OperationalActFormComponent} from '../../../../additional-costs/operational-act-form/operational-act-form.component';
import {OperationalAct} from '../../../../additional-costs/models/operational-act';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicTableHeaderBuilder} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {AppData} from '../../../../helpers/app-data';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-trial-operational-acts',
  templateUrl: './trial-operational-acts.component.html',
  styleUrls: ['./trial-operational-acts.component.css']
})
export class TrialOperationalActsComponent extends PaginatorTableComponent<TrialOperationalAct> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.TrialOperationalAct;
  target = DynamicDefinitionEnum.TRIAL_ADDITIONAL_COSTS_OPERATIONAL_ACTS_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  trialHhhId: number;
  displayNoSelectedActDialog: boolean;
  trialOperationalActColumns: TableHeader[] = [];
  checkedList: TrialOperationalAct [] = [];
  noYesOptions: SelectItem[];
  userCanAccessActs = false;

  actCategoryFilterList: SelectItem[] = [];
  actDescriptionFilterList: SelectItem[] = [];
  rdvDescriptionFilterList: SelectItem[] = [];
  invoiceableFilterList: SelectItem[] = [];

  constructor(private cfr: ComponentFactoryResolver,
              private translateService: InnohealthTranslateService,
              private dataService: DataService,
              private router: Router,
              public trialService: TrialService,
              private translate: TranslateService,
              private actCategoryService: ActCategoryService,
              private trialOperationalActService: TrialOperationalActService,
              public trialPageLockService: TrialPageLockService,
              private dynamicConfigService: DynamicConfigService,
              private cdr: ChangeDetectorRef) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.userCanAccessActs = ['READ_ONLY', 'READ_WRITE'].indexOf(localStorage.getItem(OPERATIONAL_ACT_READ_WRITE)) >= 0;
    this.getNoYesOptions();
    this.getOperationalActList();
    this.initProperties();
    this.cdr.detectChanges();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('actCategoryName')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.actCategoryFilterList)
      .optionFilterable()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('description')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.actDescriptionFilterList)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('rdvDescription')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.rdvDescriptionFilterList)
      .optionFilterable()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('invoiceable')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.invoiceableFilterList)
      .sortable()
      .isTranslated()
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
    localStorage.setItem('trialHhhId', '0');
  }

  getNoYesOptions() {
    this.translate.stream('GENERAL_LINGUISTICS_YES_UFCASE').subscribe(() => {
      this.noYesOptions = Data.yesOrNoOrNeither;
    });
  }

  getOperationalActList() {
    this.trialOperationalActService.loadList(this.trialHhhId).subscribe(
      res => {
        this.values = res;
        this.updateDropdownOptions(res);
      });
  }

  updateDropdownOptions(event) {
    this.trialOperationalActColumns = [];
    for (const operationalAct of event) {
      const descItem = {label: operationalAct.description, value: operationalAct.description};
      const rdvDescItem = {label: operationalAct.rdvDescription, value: operationalAct.rdvDescription};
      const actCategoryItem = {label: operationalAct.actCategoryName, value: operationalAct.actCategoryName};
      const invoiceableItem = {
        label: operationalAct.invoiceable !== null ? this.translateService.getTranslationString(operationalAct.invoiceable.toString()) : '',
        value: operationalAct.invoiceable
      };

      if (operationalAct.actCategoryName !== null &&
        this.actCategoryFilterList.findIndex((item: SelectItem) => item.value === actCategoryItem.value) === -1) {
        this.actCategoryFilterList.push(actCategoryItem);
      }
      if (operationalAct.invoiceable !== null &&
        this.invoiceableFilterList.findIndex((item: SelectItem) => item.value === invoiceableItem.value) === -1) {
        this.invoiceableFilterList.push(invoiceableItem);
      }
      if (operationalAct.description !== null &&
        this.actDescriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
        this.actDescriptionFilterList.push(descItem);
      }
      if (operationalAct.rdvDescription !== null &&
        this.rdvDescriptionFilterList.findIndex((item: SelectItem) => item.value === rdvDescItem.value) === -1) {
        this.rdvDescriptionFilterList.push(rdvDescItem);
      }

    }

    this.actDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.rdvDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.actCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.invoiceableFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }


  addNewActs() {
    this.dataService.changeFromModule(this.trialHhhId);
    this.router
      .navigate(
        ['/additional-costs-container/operational-acts'],
        {queryParams: {actSelected: true, trialHhhId: this.trialHhhId}}
      ).then();
  }

  updateSelectedList(selectedList: any) {
    this.checkedList = selectedList;
  }

  openDeleteTrialOperationalActDialog() {
    if (this.checkedList.length === 0) {
      this.displayNoSelectedActDialog = true;
    } else {
      this.displayDeleteMultiple();
    }
  }

  displayDeleteMultiple() {
    const formComponent = DeleteMultipleComponent.displayFormDialogue(this.deletePopupContainer, this.cfr);
    formComponent.service = this.trialOperationalActService;
    formComponent.selectedList = this.checkedList;
    formComponent.navigationRoute = '/trial-details/theoretical-calendar';
    formComponent.optionalNavigationRoute = 'trial-details/inclusion-details/calendar';
    formComponent.popupMessage = 'MODULE_REFERENCE_ACTS_DIALOG_WARNING_SELECTED_REFERENCE_ACT_WILL_BE_DELETED';
    formComponent.hhhId = this.trialHhhId;
    formComponent.onDelete((deletedItem: number[]) => {
      for (const hhhId of deletedItem) {
        const index = this.values.findIndex((trialOperationalAct: TrialOperationalAct) => trialOperationalAct.hhhId === hhhId);
        this.values.splice(index, 1);
      }
    });
  }

  displayFormDialogue(trialOperationalAct?: TrialOperationalAct) {
    const formComponent = OperationalTrialActsComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.trialOperationalAct = new TrialOperationalAct().init(trialOperationalAct);
    formComponent.onUpdateCallback((entity: TrialOperationalAct) => {
      this.updateList(entity);
    });
  }

  displayAddNewOperationalActFormDialogue() {
    this.dataService.changeFromModule(this.trialHhhId);
    const formComponent = OperationalActFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.dialogReferenceActHeader = 'MODULE_OPERATIONAL_ACTS_GENERAL_ADD_NEW';
    formComponent.operationalAct = new OperationalAct();
    formComponent.saveOnlyTrialOperationalAct = true;
    formComponent.onAdd((newOperationalAct: OperationalAct) => {
      this.trialOperationalActService.createNew(this.trialHhhId, newOperationalAct).subscribe((res) => {
        this.values.push(res);
      });
    });
  }

  exportTrialOperationalActs(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.trialHhhId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .build();

    this.trialOperationalActService.exportTrialOperationalActs(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }

}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {HumanResourcesService} from '../services/human-resources.service';
import {Data} from '../../shared/entity/data';
import {Phone} from '../../shared/entity/phone';
import {Email} from '../../shared/entity/email';
import {Fax} from '../../shared/entity/fax';
import {SelectItem} from 'primeng/api';
import {Address} from '../../shared/entity/address';
import {Establishment} from '../../shared/entity/establishment';
import {DetailedHumanResource} from '../models/detailed-human-resource';
import {SharedService} from '../../shared/services/shared.service';
import {NgForm} from '@angular/forms';
import {ContactSet} from '../../shared/entity/contact-set';
import {DataService} from '../../shared/services/data-service';
import {HR_READ_WRITE} from '../../login/services/authentication.service';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {HumanResourceAppData} from '../human-resource-app-data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {HumanResourceContactService} from '../services/human-resource-contact.service';
import {DataCommentComponent} from '../../data-comment/component/data-comment/data-comment.component';
import {DataCommentInfo} from '../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {DataCommentEvent} from '../../data-comment/entity/DataCommentEvent';


@Component({
  selector: 'ih-human-resource-details',
  templateUrl: './human-resource-details.component.html',
  styleUrls: ['./human-resource-details.component.css']
})
export class HumanResourceDetailsComponent implements OnInit, OnDestroy {

  readonly _target = DynamicDefinitionEnum.HUMAN_RESOURCE_MODULE;
  readonly target = DynamicDefinitionEnum.HUMAN_RESOURCE_CONTACT_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('humanResourceDetailsForm') humanResourceDetailsForm: NgForm;

  public contactTypes: SelectItem[] = Data.contacts;
  public faxEmailAddressContactTypes: SelectItem[] = Data.faxEmailAddressContactTypes;
  public establishment: Establishment = new Establishment();
  public humanResource: DetailedHumanResource;
  public phoneEmpty = false;
  public faxEmpty = false;
  public emailEmpty = false;
  public addressEmpty = false;
  public checked = true;
  public editPhone = true;
  public editFax = true;
  public editEmail = true;
  public editAddress = true;
  public humanResourcePageTopName: string;
  disableEdit = false;

  constructor(
      private router: Router,
      private cfr: ComponentFactoryResolver,
      private sharedService: SharedService,
      private humanResourceService: HumanResourcesService,
      private humanResourceContactsService: HumanResourceContactService,
      private dataService: DataService,
      private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    this.initFormConfig();
    this.getHumanResource();
    this.disableEdit = localStorage.getItem(HR_READ_WRITE) !== 'READ_WRITE';
    this.dataService.currentPageTopName.subscribe(res => {
      this.humanResourcePageTopName = res;
    });
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, HumanResourceAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  ngOnDestroy(): void {
    this.dataService.setPageTopName(null);
  }

  getHumanResource() {
    const url = this.router.parseUrl(this.router.url);
    const id = url.queryParams.hhhId;
    this.humanResourceService.loadDetailed(id).subscribe(humanResource => {
      this.humanResource = humanResource;
      this.dataService.setPageTopName((humanResource.title ? humanResource.title : '') + ' '
          + (humanResource.firstName ? humanResource.firstName : '') + ' ' + humanResource.lastName);
      this.displayContactSet();
    });
  }

  changePosition(item: any, contacts: any) {
    for (const contact of contacts) {
      contact.position = 1;
    }
    item.position = 0;
  }

  addPhone() {
    this.humanResource.contactSetDto.phones.push(new Phone());
    this.editPhone = false;
    this.phoneEmpty = true;
  }

  savePhone(item: Phone) {
    if (item.value) {
      item.humanResourceHhhId = this.humanResource.hhhId;
      item.contactSetHhhId = this.humanResource.contactSetDto.hhhId;
      this.humanResourceContactsService.savePhone(item).subscribe(res => {
            item.hhhId = res;
            this.phoneEmpty = false;
            this.editPhone = true;
            this.sharedService.showSuccess();
          }, error => {
            this.sharedService.showFailure();
            console.error(error);
          }
      );
    }
  }

  updatePhone(item: Phone) {
    if (item.value) {
      item.contactSetHhhId = this.humanResource.contactSetDto.hhhId;
      this.humanResourceContactsService.updatePhone(item).subscribe(() => {
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  deletePhone(i: number, item: Phone) {
    this.humanResource.contactSetDto.phones.splice(i, 1);
    if (this.humanResource.contactSetDto.phones.length === 0) {
      this.addPhone();
    } else if (!item.hhhId) {
      this.editPhone = true;
      this.phoneEmpty = false;
    }
    if (item.hhhId) {
      this.humanResourceContactsService.deletePhone(item.hhhId).subscribe(() => {
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  addFax() {
    this.humanResource.contactSetDto.faxes.push(new Fax());
    this.editFax = false;
    this.faxEmpty = true;
  }

  saveFax(item: Fax) {
    if (item.value) {
      item.humanResourceHhhId = this.humanResource.hhhId;
      item.contactSetHhhId = this.humanResource.contactSetDto.hhhId;
      this.humanResourceContactsService.SaveFax(item).subscribe(res => {
            item.hhhId = res;
            this.faxEmpty = false;
            this.editFax = true;
            this.sharedService.showSuccess();
          }, error => {
            this.sharedService.showFailure();
            console.error(error);
          }
      );
    }
  }

  updateFax(item: Fax) {
    if (item.value) {
      this.humanResourceContactsService.updateFax(item).subscribe(() => {
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  deleteFax(i: number, item: Fax) {
    this.humanResource.contactSetDto.faxes.splice(i, 1);
    if (this.humanResource.contactSetDto.faxes.length === 0) {
      this.addFax();
    } else if (!item.hhhId) {
      this.editFax = true;
      this.faxEmpty = false;
    }
    if (item.hhhId) {
      this.humanResourceContactsService.deleteFax(item.hhhId).subscribe(() => {
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  addEmail() {
    this.humanResource.contactSetDto.emails.push(new Email());
    this.editEmail = false;
    this.emailEmpty = true;
  }

  saveEmail(item: Email) {
    if (item.value) {
      item.humanResourceHhhId = this.humanResource.hhhId;
      item.contactSetHhhId = this.humanResource.contactSetDto.hhhId;
      this.humanResourceContactsService.saveEmail(item).subscribe(res => {
            item.hhhId = res;
            this.emailEmpty = false;
            this.editEmail = true;
            this.sharedService.showSuccess();
          }, error => {
            this.sharedService.showFailure();
            console.error(error);
          }
      );
    }
  }

  updateEmail(item: Email) {
    if (item.value) {
      this.humanResourceContactsService.updateEmail(item).subscribe(() => {
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  deleteEmail(i: number, item: Email) {
    this.humanResource.contactSetDto.emails.splice(i, 1);
    if (this.humanResource.contactSetDto.emails.length === 0) {
      this.addEmail();
    } else if (!item.hhhId) {
      this.editEmail = true;
      this.emailEmpty = false;
    }
    if (item.hhhId) {
      this.humanResourceContactsService.deleteEmail(item.hhhId).subscribe(() => {
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  addAddress() {
    this.humanResource.contactSetDto.addresses.push(new Address());
    this.editAddress = false;
    this.addressEmpty = true;
  }

  saveAddress(item: Address) {
    if (item.line1 || item.line2 || item.line3 || item.city || item.postCode) {
      item.humanResourceHhhId = this.humanResource.hhhId;
      item.contactSetHhhId = this.humanResource.contactSetDto ? this.humanResource.contactSetDto.hhhId : null;
      this.humanResourceContactsService.saveAddress(item).subscribe(res => {
            item.hhhId = res;
            this.addressEmpty = false;
            this.editAddress = true;
            this.sharedService.showSuccess();
          }, error => {
            this.sharedService.showFailure();
            console.error(error);
          }
      );
    }
  }

  updateAddress(item: Address) {
    if (item.line1 || item.line2 || item.line3 || item.city || item.postCode) {
      this.humanResourceContactsService.updateAddress(item).subscribe(() => {
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  deleteAddress(i: number, item: Address) {
    this.humanResource.contactSetDto.addresses.splice(i, 1);
    if (this.humanResource.contactSetDto.addresses.length === 0) {
      this.addAddress();
    } else if (!item.hhhId) {
      this.editAddress = true;
      this.addressEmpty = false;
    }
    if (item.hhhId) {
      this.humanResourceContactsService.deleteAddress(item.hhhId).subscribe(() => {
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  displayDataCommentDialog(isPrivate: boolean) {
    if (!this.humanResource || !this.humanResource.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.HumanResource, this.humanResource.hhhId, 'hhhId', isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  private displayContactSet() {
    if (!this.humanResource.contactSetDto) {
      this.humanResource.contactSetDto = new ContactSet();
    }
    if (this.humanResource.contactSetDto.phones.length === 0) {
      this.addPhone();
    }
    if (this.humanResource.contactSetDto.faxes.length === 0) {
      this.addFax();
    }
    if (this.humanResource.contactSetDto.emails.length === 0) {
      this.addEmail();
    }
    if (this.humanResource.contactSetDto.addresses.length === 0) {
      this.addAddress();
    }
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  returnBack() {
    this.router.navigate(['human-resource-list']).then();
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.humanResource.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {Client} from '@stomp/stompjs';
import SockJs from 'sockjs-client';
import {StompSubscription} from '@stomp/stompjs/src/stomp-subscription';
import {Properties} from '../../helpers/properties';
import {AppData} from "../../helpers/app-data";

@Injectable({
  providedIn: 'root'
})
export class StompService {

  private serverUrl = new Properties().webSocketEndPoint;
  private _isConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private client: Client;

  constructor() {
  }

  public connect() {
    console.log('Connecting SOCKET [' + this.serverUrl + ']...');
    this._isConnected.next(false);
    this.client = new Client({
      debug: (str) => {
        this.debug(str);
      },
      heartbeatIncoming: 5000,
      heartbeatOutgoing: 5000,
      reconnectDelay: 10000,
      onDisconnect: () => {
        this._isConnected.next(false);
        console.log('SOCKET connection to [' + this.serverUrl + '] closed.');
      },
      webSocketFactory: () => {
        return new SockJs(this.serverUrl);
      },
      onConnect: () => {
        console.log('SOCKET connection to [' + this.serverUrl + '] established.');
        this._isConnected.next(true);
      },
      onStompError: (frame) => {
        console.log('Broker reported error: ' + frame.headers['message']);
        console.log('Additional details: ' + frame.body);
      }
    });
    this.client.activate();
  }

  subscribe(
    channel: string,
    onMessage: (msg: string) => void,
    onSubscription?: (subscription: StompSubscription) => void
  ) {
    if (this._isConnected) {
      this._isConnected.subscribe(connected => {
        if (connected) {
          this.debug('Subscribing to [' + this.serverUrl + channel + '] ...');
          const subscription = this.client.subscribe(channel, message => {
            onMessage(message.body);
          });
          this.debug('Subscription to [' + this.serverUrl + channel + '] established, subscription-id=[' + subscription.id + '].');
          if (onSubscription) {
            onSubscription(subscription);
          }
        }
      });
    }
  }

  unsubscribe(subscription: StompSubscription) {
    this.debug('Unsubscribing from socket [' + this.serverUrl + '] with subscription-id=[' + subscription.id + '].');
    subscription.unsubscribe();
  }

  public disconnect() {
    if (this._isConnected) {
      this._isConnected.subscribe(connected => {
        if (connected) {
          this.client.deactivate();
          this._isConnected.next(false);
        }
      });
    }
  }

  send(channel: string, message: string) {
    this.client.publish({destination: '/app/send/' + channel, body: message});
  }


  debug(msg: string): void {
    if (AppData.websocketLogging) {
      console.log(msg);
    }
  }

  get isConnected(): BehaviorSubject<boolean> {
    return this._isConnected;
  }
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LabelValue} from '../../shared/entity/label-value';
import {IsDeletable} from '../../is-deletable';
import {CounterpartCategory} from '../models/counterpart-category';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class CounterpartCategoryService {
  host = new Properties().host + '/counter-part-category';
  dataItemHost = new Properties().host + '/data/items';
  private counterPartListAllPage: CounterpartCategory[] = [];
  private counterPartListSinglePage: CounterpartCategory[] = [];

  constructor(private httpClient: HttpClient) {
  }

  setCounterPartsCategoryToExportAllPage(counterParts: CounterpartCategory[]) {
    this.counterPartListAllPage = counterParts;
  }

  setCounterPartsCategoryListToExportSinglePage(counterParts: CounterpartCategory[]) {
    this.counterPartListSinglePage = counterParts;
  }

  loadList(): Observable<CounterpartCategory[]> {
    return this.httpClient.get<CounterpartCategory[]>(this.host + '/load-list');
  }


  addCategory(counterPartCategory: CounterpartCategory): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', counterPartCategory);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(counterPartId: number) {
    return this.httpClient.get(this.host + `/delete/${counterPartId}`);
  }

  updateCategory(counterPart: CounterpartCategory) {
    return this.httpClient.put(this.host + `/update`, counterPart);
  }

  exportCounterPartCategories(request: ExportRequest<any>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

}

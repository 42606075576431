import {ChangeDetectorRef, Component, ComponentFactoryResolver, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Inclusion} from '../../../entity/inclusion/inclusion';
import {TrialHead} from '../../../models/trial-head';
import {FR} from '../../../../shared/entity/calendar-language';
import {SelectItem} from 'primeng/api';
import {Data} from '../../../../shared/entity/data';
import {InclusionDetailService} from '../../../service/inclusion-detail.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {OrganService} from '../../../services/organ.service';
import {DataService} from '../../../../shared/services/data-service';
import {TrialService} from '../../../services/trial.service';
import {Router} from '@angular/router';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {ConsentmentFormComponent} from '../consentment-form/consentment-form.component';
import {OtherConsent} from '../../../../other-consent';
import {BlocFieldsComponent} from '../../../../modals/bloc-date-form/bloc-fields.component';
import {BlocFields} from '../../../../bloc-fields';
import {isNullOrUndefined} from 'util';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {forkJoin, Observable, of} from 'rxjs';
import {Arm} from '../../../entity/inclusion/arm';
import {ArmService} from '../../../services/arm.service';
import {LabelValue} from '../../../../shared/entity/label-value';
import {map} from 'rxjs/operators';
import {DataItemService} from '../../../../custom-field/service/data-item.service';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';

@Component({
  selector: 'ih-inclusion-information-arm',
  templateUrl: './inclusion-information-arm.component.html',
  styleUrls: ['./inclusion-information-arm.component.css']
})
export class InclusionInformationArmComponent implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.TRIAL_INCLUSION_INFORMATION_ARM_FORM;
  config: FormConfig = new FormConfig();
  isAmendmentEnabled = false;
  isCoInvestigatorsFromPersonnelEnabled = false;
  isInvestigatorDoctors = false;
  isReferrerInvestigatorContainsSeveralResponsibilitiesEnabled = false;
  isSpecificReferrerInvestigatorEnabled = false;
  isReferrerInvestigatorIsPIEnabled = false;
  inclusionInformationArmEnabled = false;
  fetchAllArmsEnabled = false;
  isFetchAllDoctorsInInclusionFormEnabled = false;

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('inclusionForm') inclusionForm: NgForm;
  submitted = false;
  inclusion: Inclusion = new Inclusion();
  trialHhhId: number;
  trialHead: TrialHead = new TrialHead(null);
  inclusionId: number;
  locale = FR;
  selectedDoctors: number[] = [];
  countOtherConsent: number;
  public organsList: SelectItem[] = [];
  originalOrganList: string[] = [];
  inclusionOrganList: SelectItem[] = [];
  combinedOrgansList: SelectItem[] = [];
  maxSelectedLabels: number;
  countBlocFields: number;
  noYesOptions: SelectItem[] = Data.yesOrNo;
  trialArms: LabelValue[] = [];


  constructor(private inclusionService: InclusionDetailService,
              private cfr: ComponentFactoryResolver,
              private sharedService: SharedService,
              private organService: OrganService,
              private dataService: DataService,
              public trialService: TrialService,
              private armService: ArmService,
              private router: Router,
              private canDeactivateService: CanDeactivateService,
              private dynamicConfigService: DynamicConfigService,
              private dataItemService: DataItemService,
              private _cdr: ChangeDetectorRef
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
      this.inclusionId = url.queryParams.inclusionId;
    }
  }

  get form() {
    return this.inclusionForm.controls;
  }

  ngOnInit() {
    this.getTrialHead();
    this.initProperties();
    this.dataService.setExportPageModule('src/app/trial/inclusions/inclusion-details/inclusion-information/inclusion-information.component');
    this.inclusionService.setTrialAndInclusionId(this.trialHhhId);
    this.getInclusionInformation();
    this.getOrganList();
    this.getArmsList();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAmendmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.isCoInvestigatorsFromPersonnelEnabled = this.dynamicConfigService.getProperty(PropertyEnum.coInvestigatorsFromPersonnelEnabled);
      this.isInvestigatorDoctors = this.dynamicConfigService.getProperty(PropertyEnum.investigatorDoctors);
      this.isSpecificReferrerInvestigatorEnabled = this.dynamicConfigService.getProperty(PropertyEnum.specificReferrerInvestigatorEnabled);
      this.isReferrerInvestigatorContainsSeveralResponsibilitiesEnabled = this.dynamicConfigService.getProperty(PropertyEnum.referrerInvestigatorContainsSeveralResponsibilitiesEnabled);
      this.isReferrerInvestigatorIsPIEnabled = this.dynamicConfigService.getProperty(PropertyEnum.referrerInvestigatorIsPIEnabled);
      this.inclusionInformationArmEnabled = this.dynamicConfigService.getProperty(PropertyEnum.inclusionInformationArmEnabled);
      this.fetchAllArmsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.fetchAllArmsEnabled);
      this.isFetchAllDoctorsInInclusionFormEnabled = this.dynamicConfigService.getProperty(PropertyEnum.fetchAllDoctorsInInclusionFormEnabled);
      this._cdr.detectChanges();
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getArmsList(): void {
    forkJoin([
      this.getTrialArms(),
      (this.inclusion.armHhhId && this.inclusion.armHhhId !== 0) ? this.armService.getArmById(this.inclusion.armHhhId) : of<Arm>(void 0),
      this.inclusion.arm2HhhId ? this.armService.getArmById(this.inclusion.arm2HhhId) : of<Arm>(void 0)
    ]).subscribe(
      ([consentArms, inclusionArm1, inclusionArm2]) => {
        this.trialArms = consentArms;
        if (this.trialArms && inclusionArm1 && !this.trialArms.some(arm => arm.value === inclusionArm1.hhhId)) {
          this.trialArms.push(new LabelValue(inclusionArm1.getNameWithProtocolVersionAndPhase(), inclusionArm1.hhhId));
        }
        if (this.trialArms && inclusionArm2 && !this.trialArms.some(arm => arm.value === inclusionArm2.hhhId)) {
          this.trialArms.push(new LabelValue(inclusionArm2.getNameWithProtocolVersionAndPhase(), inclusionArm2.hhhId));
        }
      },
      error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading organ list.' + error);
      }
    );
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  getInclusionInformation() {
    this.inclusionService.getInclusionInformation(this.inclusionId).subscribe(res => {
      this.inclusion = res;
      this.originalOrganList = this.inclusion.organList;
      this.inclusionService.setInclusionInformationToExport(res);
      this.getArmsList();
    });
  }

  getTrialArms(): Observable<LabelValue[]> {
    if (this.fetchAllArmsEnabled && this.inclusion.consentHhhId) {
      return this.dataItemService.getItems('active-arm-having-theoretical-calendar-by-consent|' + this.trialHhhId + '|' + this.inclusion.consentHhhId);
    }
    if (!this.fetchAllArmsEnabled && !this.inclusion.consentHhhId) {
      return this.armService.getArmsByTrialId(String(this.trialHhhId)).pipe(
        map((arms: Arm[]) => arms.map(arm => new LabelValue(arm.getNameWithProtocolVersionAndPhase(), arm.hhhId)))
      );
    }
    return this.dataItemService.getItems('open-arm-by-trial' + '|' + this.trialHhhId);
  }

  getOrganList() {
    forkJoin([
      this.organService.getTrialOrgansAndOrganFamilies(this.trialHhhId),
      this.getInclusionOrganList()
    ]).subscribe(
      ([trialOrgans, inclusionOrgans]) => {
        this.organsList = trialOrgans;
        this.inclusionOrganList = inclusionOrgans;
        this.combinedOrgansList = this.combinedInclusionAndTrialOrgans(this.inclusionOrganList, this.organsList);
        this.maxSelectedLabels = this.combinedOrgansList.length;
      },
      error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading organ list.' + error);
      }
    );
  }

  getInclusionOrganList() {
    if (this.inclusionId) {
      return this.dataItemService.getItems('inclusion-organ-and-organ-family|' + this.inclusionId);
    }
    return of<LabelValue[]>(void 0);
  }

  combinedInclusionAndTrialOrgans(inclusionOrganList: SelectItem[], trialOrganList: SelectItem[]): SelectItem[] {
    // Remove duplicates from the inclusionOrganList
    const combinedArray = [...(inclusionOrganList || []), ...(trialOrganList || [])];
    return combinedArray.filter((organ, index, self) =>
      index === self.findIndex(item => item.value === organ.value)
    );
  }

  getTrialHead() {
    this.trialService.getTrialHead(this.trialHhhId).subscribe(res => {
      this.trialHead = res;
    });
  }

  updateInclusion() {
    this.submitted = true;
    if (this.trialHead.multiSite) {
      if (this.inclusion.trialSiteHhhId === 0) {
        return;
      }
    }
    if (!this.inclusion.isValid(this.config,this.trialHead.multiSite)) {
      return;
    }

    if (this.isTrialTreatmentStartNotValid()) {
      this.sharedService.showWarning('CANNOT_SAVE_WITH_TREATMENT_START_DATE_AND_WITHOUT_DESIGN');
      return;
    }
    this.inclusion.trialHhhId = this.trialHhhId;
    this.inclusion.hhhId = this.inclusionId;
    this.inclusionService.updateInclusion(this.inclusion).subscribe(updatedStateHhhId => {
      this.inclusion.stateHhhId = updatedStateHhhId;
      this.sharedService.showSuccess();
      this.canDeactivateService.canBeDeactivated = true;
      this.dataService.changeInclusionMenu('information-calendar-patientTracking');
    }, error => {
      if (error.status === 409) {
        this.sharedService.showFailure('GENERAL_LINGUISTICS_CHANGING_ARM_FAIL');
      } else {
        this.sharedService.showFailure();
        console.error(error);
      }
    });
  }

  displayConsentDates() {
    const showPopupFactory = this.cfr.resolveComponentFactory(ConsentmentFormComponent);
    const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
    const consentComponent = showPopupComponentRef.instance;
    consentComponent.trialHhhId = this.trialHhhId;
    consentComponent.inclusionHhhId = this.inclusion.hhhId;
    consentComponent.onAdd((newConsent: OtherConsent) => {
      this.countOtherConsent = consentComponent.countOtherConsent;
    });
  }

  displayBlocFields() {
    const showPopupFactory = this.cfr.resolveComponentFactory(BlocFieldsComponent);
    const showPopupComponentRef = this.popupContainer.createComponent(showPopupFactory);
    const consentComponent = showPopupComponentRef.instance;
    consentComponent.inclusionHhhId = this.inclusion.hhhId;
    consentComponent.onAdd((newblocDate: BlocFields) => {
      this.countBlocFields = consentComponent.countBlocFields;
    });
  }

  isTrialTreatmentStartNotValid(): boolean {
    return (this.inclusion.trialTreatmentStart != null && this.inclusion.trialTreatmentStart.date != null
      || this.inclusion.randomization != null && this.inclusion.randomization.date != null) && (this.inclusion.armHhhId == null || this.inclusion.armHhhId === 0);
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.inclusion.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.Inclusion, this.inclusion.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  goToPatientPage() {
    if (!isNullOrUndefined(this.inclusion.patientInternalIdentifier)) {
      this.router.navigateByUrl('/patient-details?hhhId=' + this.inclusion.patientHhhId).then();
    }
  }

  displaySelectedItems(selectedValues) {
    this.selectedDoctors = selectedValues;
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.inclusion.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  displayField = (fieldName: string) => this.config.displayField(fieldName);

  getReferrerInvestigatorType(): string {
    if (this.isFetchAllDoctorsInInclusionFormEnabled) {
      return 'doctor';
    }
    if (this.isInvestigatorDoctors) {
      return 'trial-included-doctor|' + this.trialHhhId;
    } else {
      if (this.isReferrerInvestigatorContainsSeveralResponsibilitiesEnabled) {
        return 'referrer-investigator';
      } else if (this.isSpecificReferrerInvestigatorEnabled) {
        return 'specific-referrer-investigator';
      } else {
        return this.isReferrerInvestigatorIsPIEnabled ? 'investigator' : 'trial-included-doctor|' + this.trialHhhId;
      }
    }
  }
}

import {DataCommentCount} from '../../data-comment/entity/data-comment-count';

export class TrialSite {
  hhhId: number;
  name: string;

  init(obj: any): TrialSite {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}

import {Component, ComponentFactoryResolver, HostListener, OnInit} from '@angular/core';
import {MessageService, SelectItem, TreeNode} from 'primeng/api';
import {Router} from '@angular/router';
import {TrialService} from '../../../../services/trial.service';
import {SharedService} from '../../../../../shared/services/shared.service';
import {PaginatorTableComponent} from '../../../../../shared/component/paginator-table/paginator-table.component';
import {DataService} from '../../../../../shared/services/data-service';
import {TrialDesignFormComponent} from '../trial-design-form/trial-design-form.component';
import {LabelValue} from '../../../../../shared/entity/label-value';
import {ArmService} from '../../../../services/arm.service';
import {Arm} from '../../../../entity/inclusion/arm';
import {ArmTree} from '../../../../models/arm-tree';
import {DesignStructureConfig} from '../../../../models/design-structure-config';
import {ArmPhaseDto} from '../../../../models/arm-phase-dto';
import {PhaseDto} from '../../../../models/phase-dto';
import {ArmCohortDto} from '../../../../models/arm-cohort-dto';
import {CohortDto} from '../../../../models/cohort-dto';
import {ArmEscalationExtensionDto} from '../../../../models/arm-escalation-extension-dto';
import {ArmInclusionArmDto} from '../../../../models/arm-inclusion-arm-dto';
import {InclusionArmDto} from '../../../../models/inclusion-arm-dto';
import {AbstractDesignLevelDto} from '../../../../models/abstract-design-level-dto';
import {TreatmentAdministrationRouteDto} from '../../../../models/treatment-administration-route-dto';
import {AdministrationRouteDto} from '../../../../models/administration-route-dto';
import {TreatmentStudiedMoleculeDto} from '../../../../models/treatment-studied-molecule-dto';
import {MoleculeStudiedDto} from '../../../../models/molecule-studied-dto';
import {TreatmentDoseDto} from '../../../../models/treatment-dose-dto';
import {MinimizedTrialProtocol} from '../../../../models/minimized-trial-protocol';
import {TrialProtocolService} from '../../../../services/trial-protocol.service';
import {ChooseProtocolVersionComponent} from '../../../modals/choose-protocol-version/choose-protocol-version.component';
import {TrialObservablesService} from '../../../../services/trial-observables.service';
import {TranslateService} from '@ngx-translate/core';
import {CanDeactivateService} from '../../../../../shared/services/can-deactivate.service';
import {ArmUtil} from '../../../../entity/inclusion/ArmUtil';
import {TrialPageLockService} from '../../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {AdministrationRouteService} from '../../../../services/administration-route.service';
import {DataItemService} from '../../../../../custom-field/service/data-item.service';
import {Util} from "../../../../../helpers/util";
import {DynamicConfigService} from "../../../../../dynamic-config/service/dynamic-config.service";

@Component({
  selector: 'ih-design-identification-information',
  templateUrl: './design-identification-information.component.html',
  styleUrls: ['./design-identification-information.component.css']
})
export class DesignIdentificationInformationComponent extends PaginatorTableComponent<Arm> implements OnInit {

  readonly trialHhhId: string;
  data: TreeNode[] = [];
  phases: LabelValue[] = [];
  armsTreeTodisplay: TreeNode[] = [];
  private inclusionArms = [];
  private cohorts = [];
  private studiedMolecules = [];

  private administrationRoutes = [];
  public armStructureConfig: DesignStructureConfig = new DesignStructureConfig();
  protocolHhhId: number;

  allArms: Array<Arm> = new Array<Arm>();
  protocol: MinimizedTrialProtocol = new MinimizedTrialProtocol();

  usedProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  usedProtocolsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  allProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  showEmptyProtocolWarning = false;
  trialId: number;

  constructor(private cfr: ComponentFactoryResolver,
              private dataService: DataService,
              private router: Router,
              private translate: TranslateService,
              public trialService: TrialService,
              private observablesService: TrialObservablesService,
              private sharedService: SharedService,
              private trialProtocolService: TrialProtocolService,
              private armService: ArmService,
              private administrationRouteService: AdministrationRouteService,
              private canDeactivateService: CanDeactivateService,
              private dynamicConfigService: DynamicConfigService,
              private dataItemService: DataItemService,
              public trialPageLockService: TrialPageLockService) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    this.trialHhhId = url.queryParams.hhhId;
    this.trialId = Number(this.trialHhhId);
    this.canDeactivateService.canBeDeactivated = true;
  }

  ngOnInit() {
    this.initDesignFormConfig();
    this.initData();
    this.getAllProtocols();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  private checkIfLastProtocolExists() {
    this.showEmptyProtocolWarning = this.trialProtocolService.isLastProtocolNotUsed(this.allProtocols, this.usedProtocols);
  }

  initDesignFormConfig(): void {
    this.armService.getDesignConfig().subscribe(result => {
      this.armStructureConfig = result;
      this.getUsedProtocols();
    });
  }

  initProtocolToLatest(trialProtocol?: MinimizedTrialProtocol) {
    if (this.usedProtocols && this.usedProtocols.length > 0) {
      if (!trialProtocol) {
        this.protocol = this.usedProtocols[this.usedProtocols.length - 1];
      }
      this.updateDesignListDependingOnProtocolHhhId();
    }
  }

  getUsedProtocols(trialProtocol?: MinimizedTrialProtocol) {
    this.trialProtocolService.getTrialProtocolListHavingDesign(Number(this.trialHhhId)).subscribe(result => {
      this.usedProtocols = result;
      this.usedProtocolsSelectItems = [];
      this.usedProtocols.forEach(up => this.usedProtocolsSelectItems.push({
        label: (up.version ? 'Version du protocole' : 'Version du l\'amendement') + ' : ' + (up.version ? up.version : up.amendmentVersion),
        value: up.hhhId
      }));
      this.initProtocolToLatest(trialProtocol);
      this.translate.stream('PROTOCOL_VERSION').subscribe(res => {
        // TODO needs work on i18n
      });
      this.checkIfLastProtocolExists();
    });
  }

  getAllProtocols() {
    this.trialProtocolService.getTrialProtocolList(Number(this.trialHhhId)).subscribe(result => {
      this.allProtocols = result;
      this.checkIfLastProtocolExists();
    });
  }

  add(nodeData?) {
    const formComponent = TrialDesignFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    // formComponent.initFormConfig(this.armStructureConfig);
    formComponent.formHeader = 'MODULE_TRIALS_CREATE_FIELD_DESIGN_ADD';
    formComponent.arm = this.initializeArmDesign();
    if (nodeData) {
      this.cloneArmByLevel(nodeData.arm, formComponent.arm, nodeData.level);
    }
    formComponent.onAdd((newlyAddedTrialDesignCalendar) => {
      this.canDeactivateService.canBeDeactivated = false;
      const armToSubmit: Arm = JSON.parse(JSON.stringify(newlyAddedTrialDesignCalendar));
      armToSubmit.protocol = this.protocol;
      this.prepareAmrBeforeSendingRequest(armToSubmit);
      this.prepareArmName(armToSubmit);
      this.allArms.push(armToSubmit);
      this.buildArmsTree();
    });
  }

  private cloneArmByLevel(initialArm: Arm, newArm: Arm, level: number) {
    if (this.armStructureConfig.phase.level <= level) {
      newArm.phase = initialArm.phase;
    }
    if (this.armStructureConfig.cohort.level <= level && initialArm.cohort.cohort) {
      newArm.cohort = JSON.parse(JSON.stringify(initialArm.cohort));
      if (newArm.cohort && newArm.cohort.cohort && newArm.cohort.cohort.id == null) {
        newArm.cohort.cohort.id = -1;
      }
    }
    if (this.armStructureConfig.escaladeExtension.level <= level && initialArm.escaladeExtension.escaladeExtension) {
      newArm.escaladeExtension = initialArm.escaladeExtension;
    }
    if (this.armStructureConfig.inclusionArm.level <= level && initialArm.inclusionArm.inclusionArm) {
      newArm.inclusionArm = JSON.parse(JSON.stringify(initialArm.inclusionArm));
      if (newArm.inclusionArm && newArm.inclusionArm.inclusionArm && newArm.inclusionArm.inclusionArm.hhhId == null) {
        newArm.inclusionArm.inclusionArm.hhhId = -1;
      }
    }
    let customIndex = 4;
    for (let i = 0; i < initialArm.designLevels.length; i++) {
      if (customIndex > level) {
        this.prepareBeforeEditing(newArm);
        return;
      }
      const designLevel = initialArm.designLevels[i];
      let newDesignLevel: AbstractDesignLevelDto = new AbstractDesignLevelDto();
      if (newArm.designLevels.length <= (i + 1) && newArm.designLevels[i]) {
        newDesignLevel = newArm.designLevels[i];
      } else {
        newArm.designLevels.push(newDesignLevel);
      }
      newDesignLevel.level = designLevel.level;
      if (designLevel.type === 'LIAISON') {
        newDesignLevel.type = 'LIAISON';
        newDesignLevel.fieldName = designLevel.fieldName;
        newDesignLevel.fieldValue = designLevel.fieldValue;
        newDesignLevel.active = designLevel.active;
        customIndex++;
      } else {
        newDesignLevel.type = 'TREATMENT';
        newDesignLevel.administrationRoute = new TreatmentAdministrationRouteDto();
        newDesignLevel.dose = new TreatmentDoseDto();
        newDesignLevel.studiedMolecule = JSON.parse(JSON.stringify(designLevel.studiedMolecule));
        customIndex++;
        if (customIndex <= level) {
          newDesignLevel.administrationRoute = JSON.parse(JSON.stringify(designLevel.administrationRoute));
          customIndex++;
        }
        if (customIndex <= level) {
          newDesignLevel.dose = JSON.parse(JSON.stringify(designLevel.dose));
          customIndex++;
        }
      }
    }
    this.prepareBeforeEditing(newArm);
  }

  clone(arm: Arm) {
    const armCloned: Arm = JSON.parse(JSON.stringify(arm));
    armCloned.status = arm.status;
    armCloned.hhhId = null;
    armCloned.deleted = false;
    armCloned.designLevels.forEach(dl => dl.hhhId = null);
    this.allArms.push(armCloned);
    this.buildArmsTree();
    this.canDeactivateService.canBeDeactivated = false;
  }

  edit(arm: Arm) {
    const armToEdit = JSON.parse(JSON.stringify(this.allArms.find(a => a.hhhId === arm.hhhId)));
    if (!armToEdit) {
      return;
    }
    const formComponent = TrialDesignFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    // formComponent.initFormConfig(this.armStructureConfig);
    formComponent.formHeader = 'MODULE_TRIALS_CREATE_FIELD_DESIGN_EDIT';
    this.prepareBeforeEditing(armToEdit);
    formComponent.arm = armToEdit;
    formComponent.onAdd((arm1) => {
      const armToSubmit: Arm = JSON.parse(JSON.stringify(armToEdit));
      this.allArms.splice(this.allArms.findIndex(a => armToEdit.hhhId === a.hhhId), 1, armToSubmit);
      if (armToSubmit.cohort.cohort.id == null)
        {
          armToSubmit.cohort.cohort =new CohortDto();
        }
      this.prepareAmrBeforeSendingRequest(armToSubmit);
      this.prepareArmName(armToSubmit);
      this.buildArmsTree();
      this.canDeactivateService.canBeDeactivated = false;
    });
  }

  private prepareBeforeEditing(arm: Arm): void {
    ArmUtil.initializeExistingArmFieldsFromConfig(arm, this.armStructureConfig);
  }

  generateCalendar(arm: Arm) {
    this.armService.generateTheoreticalCalendar(arm.hhhId, this.protocol.hhhId).subscribe((thId) => {
          arm.theoreticalCalendar = thId.value;
          this.sharedService.showSuccess();
        },
        error => {
          this.sharedService.showFailure('Merci d\'enregistrer la page avant de pouvoir générer le calendrier de l\'étude');
        });
  }

  delete(arm: Arm) {
    arm.deleted = !arm.deleted;
    this.canDeactivateService.canBeDeactivated = false;
  }

  initializeArmDesign(): Arm {
    const arm = new Arm();
    arm.trialHhhId = parseInt(this.trialHhhId, 10);
    arm.protocol = this.protocol;
    arm.status = null;
    arm.phase = new ArmPhaseDto(this.armStructureConfig.phase.shown);
    arm.phase.phase = new PhaseDto();
    arm.phase.enabled = this.armStructureConfig.phase.shown;

    arm.cohort = new ArmCohortDto(this.armStructureConfig.cohort.shown);
    arm.cohort.cohort = new CohortDto();
    arm.cohort.enabled = this.armStructureConfig.cohort.shown;

    arm.escaladeExtension = new ArmEscalationExtensionDto(this.armStructureConfig.escaladeExtension.shown);

    arm.inclusionArm = new ArmInclusionArmDto(this.armStructureConfig.inclusionArm.shown);
    arm.inclusionArm.inclusionArm = new InclusionArmDto();

    for (const level of this.armStructureConfig.levels) {
      const designLevel = new AbstractDesignLevelDto();
      if (level.treatment) {
        designLevel.administrationRoute = new TreatmentAdministrationRouteDto();
        designLevel.administrationRoute.administrationRoute = new AdministrationRouteDto();
        designLevel.studiedMolecule = new TreatmentStudiedMoleculeDto();
        designLevel.studiedMolecule.studiedMolecule = new MoleculeStudiedDto();
        designLevel.dose = new TreatmentDoseDto();
        designLevel.type = 'TREATMENT';
      } else {
        designLevel.fieldName = level.fieldName;
        designLevel.type = 'LIAISON';
      }
      designLevel.level = arm.designLevels.length;
      arm.designLevels.push(designLevel);
    }
    return arm;
  }

  private prepareAmrBeforeSendingRequest(arm: Arm) {
    ArmUtil.setConvenientEmptyFieldsToNull(arm);
    ArmUtil.setLabelForPhase(arm, this.phases);
    ArmUtil.setLabelForCohort(arm, this.cohorts);
    ArmUtil.setLabelForInclusionArm(arm, this.inclusionArms);
    ArmUtil.setLabelsForDesignLevels(arm, this.administrationRoutes, this.studiedMolecules);
  }

  initData(): void {
    this.getPhases();
    this.getStudiedMolecules();
    this.getCohorts();
    this.getAdministrationRoutes();
    this.getInclusionArms();
  }

  getInclusionArms() {
    const trialId = parseInt(this.trialHhhId, 10);
    this.dataItemService.getItems('inclusion-arm-by-trial|' + trialId + '|' + true).subscribe(result => {
      this.inclusionArms = result;
    });
  }

  getPhases() {
    this.dataItemService.getItems('phase-selected').subscribe(result => {
      this.phases = result;
    });
  }

  getStudiedMolecules() {
    this.dataItemService.getItems('molecule-studied-by-trial|' + this.trialHhhId).subscribe(result => {
      this.studiedMolecules = result;
    });
  }

  getAdministrationRoutes() {
    this.administrationRouteService.listAdministrationRoutes(parseInt(this.trialHhhId, 10)).subscribe(result => {
      this.administrationRoutes = result;
    });
  }

  getCohorts() {
    const trialId = parseInt(this.trialHhhId, 10);
    this.dataItemService.getItems('cohort|' + trialId).subscribe(result => {
      this.cohorts = result;
    });
  }

  private buildArmsTree(): void {
    const armTreeNodes = new ArmTree().buildFrom(this.allArms, this.armStructureConfig);
    this.armsTreeTodisplay = armTreeNodes.toTreeNodeArray();
  }

  updateDesignListDependingOnProtocolHhhId() {
    this.armService.loadListByTrialAndProtocolId(this.trialHhhId, this.protocol.hhhId).subscribe(result => {
      this.allArms = result;
      this.buildArmsTree();
    });
  }

  private prepareArmName(arm: Arm) {
    // TODO need to be modified later to read from config
    if (!this.armStructureConfig.name.shown) {
      arm.name = ArmUtil.generateArmName(arm);
    }
    if (!Util.isNullOrUndefined(arm.cohortArmPart) ) {
      arm.name = ArmUtil.generateArmNameWhenCohortArmPartEnabled(arm);
    }
  }

  saveDesigns() {
    const enableCurrentVersion = this.protocol != null && this.protocol.hhhId != null;
    const componentRef = ChooseProtocolVersionComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    componentRef.initializeProtocols(this.allProtocols, this.usedProtocols, this.protocol);
    componentRef.disableUpdateCurrentVersion = !enableCurrentVersion;
    componentRef.useCurrentProtocol = enableCurrentVersion;
    componentRef.onSave.subscribe(p => {
      this.processArmDesignsSaving(p);
    });
  }

  private processArmDesignsSaving(p: MinimizedTrialProtocol) {
    this.allArms = this.allArms.filter(arm => !arm.deleted);
    const protocolChanged: boolean = (this.protocol.hhhId == null) || (p.hhhId !== this.protocol.hhhId);
    if (protocolChanged) {
      const chosenProtocol = this.allProtocols.find(tp => p.hhhId === tp.hhhId);
      this.protocol = chosenProtocol;
      this.allArms.forEach(a => {
        a.protocol = chosenProtocol;
        a.hhhId = null;
        a.theoreticalCalendar = null;
        if (a.designLevels) {
          a.designLevels.forEach(dl => dl.hhhId = null);
        }
      });
    }
    this.allArms.forEach(a => {
      if (a.cohort && a.cohort.cohort != null && a.cohort.cohort.name === undefined) {
        a.cohort.cohort = null;
      }
      if (a.inclusionArm && a.inclusionArm.inclusionArm != null && a.inclusionArm.inclusionArm.name === undefined) {
        a.inclusionArm.inclusionArm = null;
      }
    });
    this.armService.saveAllArmDesigns(Number(this.trialHhhId), this.protocol.hhhId, this.allArms).subscribe(() => {
      this.observablesService.onArmSave.emit();
      this.canDeactivateService.canBeDeactivated = true;
      this.getInclusionArms();
      this.getCohorts();
      if (protocolChanged) {
        this.getUsedProtocols(this.protocol);
      } else {
        this.updateDesignListDependingOnProtocolHhhId();
      }
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
    });
  }

  onProtocolChange() {
    this.protocol = this.usedProtocols.find(p => p.hhhId === this.protocol.hhhId);
    this.updateDesignListDependingOnProtocolHhhId();
  }

  changeArmStatus(arm: Arm, status: 'OPEN_FOR_INCLUSIONS' | 'TEMPORARILY_SUSPENDED' | 'DEFINITELY_SUSPENDED') {
    arm.status = status;
    this.canDeactivateService.canBeDeactivated = false;
  }
  verifyClient = (clients: string[]) => this.dynamicConfigService.verifyClient(clients);
}

import {Component, ComponentFactoryResolver, HostListener, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MinimizedTrialProtocol} from '../../../models/minimized-trial-protocol';
import {SelectItem} from 'primeng/api';
import {Data} from '../../../../shared/entity/data';
import {SharedService} from '../../../../shared/services/shared.service';
import {TrialProtocolService} from '../../../services/trial-protocol.service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {Router} from '@angular/router';
import {TrialService} from '../../../services/trial.service';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {ChooseProtocolVersionComponent} from '../../modals/choose-protocol-version/choose-protocol-version.component';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {TrialAdministrativeComopInformation} from '../../../models/trial-administrative-comop-information';
import {
  TrialAdministrativeComopInformationService
} from '../../../services/trial-administrative-comop-information.service';
import {FR} from '../../../../shared/entity/calendar-language';

@Component({
  selector: 'ih-trial-administrative-comop',
  templateUrl: './trial-administrative-comop-information.component.html',
  styleUrls: ['./trial-administrative-comop-information.component.css']
})
export class TrialAdministrativeComopInformationComponent extends FormComponent<TrialAdministrativeComopInformation> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_COMOP_INFORMATION_FORM;
  config: FormConfig = new FormConfig();
  isAmendmentsEnabled = false;
  defaultContractStatus = 0;

  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;

  protocol: MinimizedTrialProtocol = new MinimizedTrialProtocol();
  usedProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  usedProtocolsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  allProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  comop: TrialAdministrativeComopInformation = new TrialAdministrativeComopInformation();
  showEmptyProtocolWarning = false;
  trialId: number;
  yesOrNo = Data.yesOrNo;
  yesOrNoOrNull = Data.yesOrNoOrNull;
  mode = 'edit';
  public readonly locale = FR;


  constructor(
    private cfr: ComponentFactoryResolver,
    private sharedService: SharedService, private trialProtocolService: TrialProtocolService,
    private canDeactivateService: CanDeactivateService,
    private router: Router,
    public trialService: TrialService,
    public trialPageLockService: TrialPageLockService,
    private dynamicConfigService: DynamicConfigService,
    private trialAdministrativeComopInformationService: TrialAdministrativeComopInformationService
  ) {
    super();
    this.canDeactivateService.canBeDeactivated = true;
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams && url.queryParams.hhhId) {
      this.trialId = url.queryParams.hhhId;
      this.mode = 'edit';
    }
  }

  ngOnInit() {
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAmendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.defaultContractStatus = this.dynamicConfigService.getProperty(PropertyEnum.defaultContractStatus);
      if (this.isAmendmentsEnabled) {
        this.getUsedProtocols();
        this.getAllProtocols();
      } else {
        this.getTrialAdministrativeComopByProtocol();
      }
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  checkIfLastProtocolExists() {
    this.showEmptyProtocolWarning = this.trialProtocolService.isLastProtocolNotUsed(this.allProtocols, this.usedProtocols);
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.comop.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialAdministrativeComop, this.comop.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.comop.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  initProtocolToLatest() {
    this.protocol = this.usedProtocols[this.usedProtocols.length - 1];
  }

  getUsedProtocols(targetProtocol?: MinimizedTrialProtocol) {
    this.trialAdministrativeComopInformationService.getRelatedProtocolsByTrial(this.trialId).subscribe(result => {
      this.usedProtocols = result.sort((a, b) => a.hhhId - b.hhhId);
      this.usedProtocolsSelectItems = [];
      this.usedProtocols.forEach(up => this.usedProtocolsSelectItems.push({
        label: (up.version ? ('Version du protocole : ' + up.version) : ('Version de l\'amendement : ' + up.amendmentVersion)),
        value: up.hhhId
      }));
      this.checkIfLastProtocolExists();
      if (result.length > 0) {
        if (!targetProtocol) {
          this.initProtocolToLatest();
        }
        this.getTrialAdministrativeComopByProtocol();
      }

    });
  }

  getAllProtocols() {
    this.trialProtocolService.getTrialProtocolList(this.trialId).subscribe(result => {
      this.allProtocols = result.sort((a, b) => a.hhhId - b.hhhId);
      this.checkIfLastProtocolExists();
    });
  }

  getTrialAdministrativeComopByProtocol() {
    if (this.mode === 'add') {
      return;
    }
    this.trialAdministrativeComopInformationService.load(this.trialId, this.protocol.hhhId).subscribe(result => {
      this.comop = result;
    });
  }

  setDefaultContractStatus(): void {
    if (!this.defaultContractStatus || this.defaultContractStatus === 0) return;
    if (!this.comop.ophthalmologyInstitutionContractStatusHhhId) {
      this.comop.ophthalmologyInstitutionContractStatusHhhId = this.defaultContractStatus;
    }
    if (!this.comop.otherInstitutionContractStatusHhhId) {
      this.comop.otherInstitutionContractStatusHhhId = this.defaultContractStatus;
    }
    if (!this.comop.respiratoryFunctionTestInstitutionContractStatusHhhId) {
      this.comop.respiratoryFunctionTestInstitutionContractStatusHhhId = this.defaultContractStatus;
    }
    if (!this.comop.cardiacEchoInstitutionContractStatusHhhId) {
      this.comop.cardiacEchoInstitutionContractStatusHhhId = this.defaultContractStatus;
    }
    if (!this.comop.cardiologyConsultationInstitutionContractStatusHhhId) {
      this.comop.cardiologyConsultationInstitutionContractStatusHhhId = this.defaultContractStatus;
    }
  }

  isModeEdit() {
    return this.mode === 'edit';
  }

  formHasBeenChanged() {
    this.canDeactivateService.canBeDeactivated = false;
  }

  onProtocolChange() {
    this.protocol = this.usedProtocols.find(p => p.hhhId === this.protocol.hhhId);
    this.getTrialAdministrativeComopByProtocol();
  }

  onSave() {
    this.submitted = true;
    if (!this.comop.isValid(this.config)) {
      return;
    }
    if (!this.isAmendmentsEnabled) {
      this.trialAdministrativeComopInformationService.save(this.comop).subscribe(() => {
        this.canDeactivateService.canBeDeactivated = true;
        this.submitted = false;
        this.sharedService.showSuccess();
      }, () => {
        this.sharedService.showFailure();
      });
    } else {
      const componentRef = ChooseProtocolVersionComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
      componentRef.initializeProtocols(this.allProtocols, this.usedProtocols, this.protocol);
      componentRef.disableUpdateCurrentVersion = this.comop.hhhId == null;
      componentRef.useCurrentProtocol = this.comop.hhhId != null;
      componentRef.onSave.subscribe((p: MinimizedTrialProtocol) => {
        this.protocol = this.allProtocols.find(tp => tp.hhhId === p.hhhId);
        this.comop.trialProtocolHhhId = p.hhhId;
        this.comop.trialHhhId = this.trialId;
        if (!this.usedProtocols.map(prt => prt.hhhId).includes(this.protocol.hhhId)) {
          this.comop.hhhId = 0;
          this.setDefaultContractStatus();
        }
        this.trialAdministrativeComopInformationService.save(this.comop).subscribe(() => {
          this.canDeactivateService.canBeDeactivated = true;
          this.getUsedProtocols(this.protocol);
          this.submitted = false;
          this.sharedService.showSuccess();
        }, () => {
          this.sharedService.showFailure();
        });
      });
    }

  }

  isEditMode() {
    return this.mode === 'edit';
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);

}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TheoreticalVisitAdd} from '../../../entity/theoretical-calendar/theoretical-visit-add';
import {Data} from '../../../../shared/entity/data';
import {SharedService} from '../../../../shared/services/shared.service';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {SelectItem, SelectItemGroup} from 'primeng/api';
import {TheoreticalCalendarService} from '../../../service/theoretical-calendar.service';
import {Router} from '@angular/router';
import {TheoreticalVisit} from '../../../entity/theoretical-calendar/theoretical-visit';
import {TheoreticalCalendar} from '../../../entity/theoretical-calendar/theoretical-calendar';
import {TheoreticalVisitInInclusion} from '../../../entity/theoretical-calendar/theoretical-visit-in-inclusion';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {TrialAppData} from '../../../trial-app-data';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {ArmService} from '../../../services/arm.service';
import {LabelValue} from '../../../../shared/entity/label-value';
import {DataItemService} from '../../../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-theoretical-visit-form',
  templateUrl: './theoretical-visit-form.component.html',
  styleUrls: ['./theoretical-visit-form.component.css']
})
export class TheoreticalVisitFormComponent implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_THEORETICAL_CALENDAR_VISIT_ADD_FORM;
  config = new FormConfig();
  amendmentsEnabled = false;
  enableRandomizationDateReference = false;
  isVisitValidPositivePeriodEnabled = true;
  visitReferenceEnabled = true;
  ghtMontBlancCustomizationEnabled = false;


  public displayTheoreticalVisitDialog: boolean;
  public theoreticalVisitAdd: TheoreticalVisitAdd = new TheoreticalVisitAdd();
  public shiftedUnits: SelectItem[] = Data.shiftedUnits;
  public dialogHeader: string;
  public trialHhhId: number;
  public theoreticalCalendar: TheoreticalCalendar;

  dateReferences: Array<SelectItemGroup> = new Array<SelectItemGroup>();
  public theoreticalVisits: Array<TheoreticalVisit> = new Array<TheoreticalVisit>();

  @Output() addSuccess: EventEmitter<any> = new EventEmitter<any>();
  targetTrialProtocol: number;
  inclusionArms: LabelValue[] = [];

  constructor(
    private sharedService: SharedService,
    private armService: ArmService,
    private translateService: InnohealthTranslateService,
    private router: Router,
    private dynamicConfigService: DynamicConfigService,
    private dataItemService: DataItemService,
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    this.dialogHeader = this.theoreticalVisitAdd.hhhId
      ? this.translateService.getTranslationString('MODULE_TRIALS_PATIENTS_VISITS_BUTTON_EDIT_HOVER')
      : this.translateService.getTranslationString('MODULE_TRIALS_THEORETICAL_CALENDARS_THEORETICAL_VISITS_GENERAL_ADD_NEW');
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.visitReferenceEnabled = this.dynamicConfigService.getProperty(PropertyEnum.visitReferenceEnabled);
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.enableRandomizationDateReference = this.dynamicConfigService.getProperty(PropertyEnum.enableRandomizationDateReference);
      this.isVisitValidPositivePeriodEnabled = this.dynamicConfigService.getProperty(PropertyEnum.visitValidPositivePeriodEnabled);
      this.ghtMontBlancCustomizationEnabled = this.dynamicConfigService.getProperty(PropertyEnum.ghtMontBlancCustomizationEnabled);
      this.getInclusionArms();
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getInclusionArms() {
    if (this.amendmentsEnabled) {
      this.dataItemService.getItems('arm-having-theoretical-calendar|' + this.trialHhhId).subscribe(res => {
        this.inclusionArms = res;
      });
    } else {
      this.dataItemService.getItems('trial-arm|' + this.trialHhhId).subscribe(res => {
        this.inclusionArms = res;
      });
    }
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  saveTheoreticalVisit() {
    if (!this.theoreticalVisitAdd.isValid(this.config, this.visitReferenceEnabled)) {
      return;
    }
    if (((!this.theoreticalVisitAdd.period && this.theoreticalVisitAdd.period !== 0) || !this.theoreticalVisitAdd.periodType) && !this.theoreticalVisitAdd.dateReferenceVisit) {
      this.theoreticalVisitAdd.periodType = '-1';
      this.theoreticalVisitAdd.displayHowManyDays = 'J+Aucun';
    }
    if (this.visitReferenceEnabled || !this.isNotValidPeriod()) {
      const referencedVisit = this.theoreticalVisits.find(value => value.hhhId === Number(this.theoreticalVisitAdd.dateReferenceVisit));
      this.theoreticalVisitAdd.displayHowManyDays = this.theoreticalVisitAdd.getHowManyDays(referencedVisit);
      const isTheoreticalVisitNew = !this.theoreticalVisitAdd.hhhId;
      if (isTheoreticalVisitNew) {
        this.theoreticalVisitAdd.total = 0;
        this.theoreticalVisitAdd.modificationType = 'add';
      } else {
        this.theoreticalVisitAdd.modificationType = 'edit';
      }
      this.theoreticalVisitAdd.periodMargin = this.theoreticalVisitAdd.periodMargin !== undefined
        ? this.theoreticalVisitAdd.periodMargin : 0;
      this.addSuccess.emit(this.theoreticalVisitAdd);
      this.displayTheoreticalVisitDialog = false;
      this.sharedService.showSuccess();
    }
  }

  isNotValidPeriod(): boolean {
    const invalidBeforePeriod = !this.isVisitValidPositivePeriodEnabled && this.theoreticalVisitAdd.period > 0 && this.theoreticalVisitAdd.type === 'BEFORE_INCLUSION';
    const invalidAfterPeriod = !this.isVisitValidPositivePeriodEnabled && this.theoreticalVisitAdd.period < 0 &&
      (this.theoreticalVisitAdd.type === 'IN_INCLUSION' || this.theoreticalVisitAdd.type === 'AFTER_INCLUSION');
    return invalidBeforePeriod || invalidAfterPeriod;
  }

  prepareDateReferences(): void {
    this.enableRandomizationDateReference = this.dynamicConfigService.getProperty(PropertyEnum.enableRandomizationDateReference);
    this.dateReferences = [
      {
        label: '',
        value: 'NO_REFERENCE_DATE',
        items: []
      }
    ];
    this.dateReferences[0].items = [
      {
        label: 'signature de consentement de pré-screening',
        value: 'PRE_SCREENING_CONSENT_SIGNATURE'
      },
      {
        label: this.ghtMontBlancCustomizationEnabled ? 'signature de consentement de screening initial' : 'signature de consentement de screening',
        value: 'INITIAL_CONSENT_SIGNATURE'
      },
      {
        label: 'Date Début de traitement',
        value: 'TREATMENT_START_DATE'
      },
      {
        label: 'Date fin de traitement',
        value: 'TREATMENT_END_DATE'
      },
      {
        label: 'Date fin de l\'étude',
        value: 'TRIAL_END_DATE'
      }
    ];
    if (!this.amendmentsEnabled) {
      this.dateReferences[0].items.push({
        label: 'Date d\'inclusion',
        value: 'INCLUSION_DATE'
      });
    }
    if (this.enableRandomizationDateReference) {
      this.dateReferences[0].items.push({
        label: 'Date de randomisation',
        value: 'RANDOMIZATION_DATE'
      });
    }
    if (!this.amendmentsEnabled) {
      this.dateReferences[0].items.push({
        label: 'Date de fin de suivi',
        value: 'FOLLOWUP_END_DATE'
      });
    }
    this.dateReferences.push(
      {
        label: 'Visites avant l\'inclusion',
        value: 'NO_REFERENCE_DATE',
        items: []
      });
    if (this.ghtMontBlancCustomizationEnabled) {
      this.dateReferences[0].items.push({
        label: 'date de randomisation',
        value: 'RANDOMIZATION_DATE'
      });
    }
    const indexBeforeInclusions = this.dateReferences.findIndex(v => v.label === 'Visites avant l\'inclusion');
    this.setDateReferencesVisitList(this.theoreticalCalendar.theoreticalVisitBeforeInclusionList, indexBeforeInclusions);


    this.setDateReferencesInInclusionVisit(this.theoreticalCalendar.theoreticalVisitInInclusionDtoList);
    this.dateReferences.push(
      {
        label: 'Visites de suivi',
        value: 'NO_REFERENCE_DATE',
        items: []
      });
    const indexAfterInclusions = this.dateReferences.findIndex(v => v.label === 'Visites de suivi');
    this.setDateReferencesVisitList(this.theoreticalCalendar.theoreticalVisitAfterInclusionList, indexAfterInclusions);

    this.updateExistingReference();
  }

  setDateReferencesVisitList(theoreticalVisits: TheoreticalVisit[], index) {
    for (const theoreticalVisit of theoreticalVisits) {
      if (theoreticalVisit.hhhId !== this.theoreticalVisitAdd.hhhId) {
        this.dateReferences[index].items.push({
          label: theoreticalVisit.name,
          value: theoreticalVisit.hhhId
        });
      }
    }
  }

  private updateExistingReference(): void {
    if (!this.theoreticalVisitAdd.expectedDateRelativeTo) {
      return;
    }
    if (this.theoreticalVisitAdd.expectedDateRelativeTo !== 'OTHER_VISIT') {
      return;
    }
    for (const dateReference of this.dateReferences) {
      if (this.theoreticalVisitAdd.dateReferenceVisit.hhhId === dateReference.value.hhhId) {
        this.theoreticalVisitAdd.expectedDateRelativeTo = dateReference.value;
        return;
      }
    }
  }

  updateExpectedDateReference() {
    if (!this.theoreticalVisitAdd.expectedDateRelativeTo && this.theoreticalVisitAdd.expectedDateRelativeTo !== '') {
      this.theoreticalVisitAdd.expectedDateReference = null;
      return;
    }
    if (typeof this.theoreticalVisitAdd.expectedDateRelativeTo === 'string') {
      this.theoreticalVisitAdd.expectedDateReference = this.theoreticalVisitAdd.expectedDateRelativeTo;
    } else {
      this.theoreticalVisitAdd.expectedDateReference = 'OTHER_VISIT';
      this.theoreticalVisitAdd.dateReferenceVisit = this.theoreticalVisitAdd.expectedDateRelativeTo;
    }
  }

  private setDateReferencesInInclusionVisit(theoreticalVisitInInclusions: TheoreticalVisitInInclusion[]) {
    for (const theoreticalVisitInInclusion of theoreticalVisitInInclusions) {
      if (theoreticalVisitInInclusion.armHhhId === this.theoreticalVisitAdd.armHhhId
        || this.theoreticalVisitAdd.armHhhId === 0
        || !this.theoreticalVisitAdd.armHhhId) {
        this.dateReferences.push(
          {
            label: theoreticalVisitInInclusion.arm,
            value: theoreticalVisitInInclusion.armHhhId,
            items: []
          });
        const indexInInclusions = this.dateReferences.findIndex(v => v.label === theoreticalVisitInInclusion.arm);
        this.setDateReferencesVisitList(theoreticalVisitInInclusion.theoreticalVisits, indexInInclusions);
      }
    }
  }
}

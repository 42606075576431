export class Message {
  public hhhId: number;
  public content: string;
  public emitterHhhId: number;
  public emitter: string;
  public receiverHhhId: number;
  public receiver: string;
  public trialHhhId: number;
  public isSeen: boolean;
  public creationDate: Date;


  constructor(text?: string) {
    this.content = text;
  }
}

import {JsonObject, JsonProperty} from 'json2typescript';

@JsonObject('DataFolder')
export class DataFolder {

  @JsonProperty('hhhId', Number)
  hhhId: number = undefined;

  @JsonProperty('name', String)
  name: string = undefined;

  @JsonProperty('attachedTo', String)
  attachedTo: string = undefined;

  @JsonProperty('attachedToId', Number)
  attachedToId: number = undefined;

  @JsonProperty('parent', Boolean)
  parent: DataFolder = undefined;

  @JsonProperty('folders', Boolean)
  folders: DataFolder = undefined;

  @JsonProperty('documents', Boolean)
  documents: Document[] = undefined;

  @JsonProperty('ownerHhhId', Number)
  ownerHhhId: number = undefined;

  @JsonProperty('ownerName', Number)
  ownerName: number = undefined;

  @JsonProperty('creationDate', Date)
  creationDate: Date = undefined;

  @JsonProperty('deletable', Boolean)
  deletable: boolean = undefined;


  constructor() {
  }
}

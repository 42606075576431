import {Component} from '@angular/core';

@Component({
  selector: 'ih-generate-password-icon',
  templateUrl: './generate-password-icon.component.html',
  styleUrls: ['./generate-password-icon.component.css']
})
export class GeneratePasswordIconComponent {

  constructor() { }

}

export class LastDateList {
  hhhId: number;
  commentaire: string;
  lastANSMDate: Date;
  lastCPPDate: Date;
  lastCSETAgreemetDate: Date;
  lastDRCAgreementDate: Date;
  trialHhhId: number;
  index: number;
  protocolVersionHhhId: number;
}

import {Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Trial} from '../../trial/models/trial';
import {Patient} from '../models/patient';
import {PatientService} from '../services/patient.service';
import {Router} from '@angular/router';
import {DataService} from '../../shared/services/data-service';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {ResponsePage} from '../../shared/entity/response-page';
import {PatientFormComponent} from '../patient-form/patient-form.component';
import {ContactSet} from '../../shared/entity/contact-set';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {InclusionInfo} from '../models/inclusion-info';
import {PatientGlobalFilter} from '../models/patient-global-filter';
import {PatientTrialFormComponent} from '../../modals/patient-add-to-trial-form/patient-trial-form.component';
import {AuthenticationService} from '../../login/services/authentication.service';
import {Role} from '../../login/models/role';
import {Permission} from '../../helpers/permission';
import {RollbackService} from '../../shared/services/rollback.service';
import {DataTransferService} from '../services/data-transfer.service';
import {SharedService} from '../../shared/services/shared.service';
import {Data} from '../../shared/entity/data';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {PatientAppData} from '../patient-app-data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {AppData} from '../../helpers/app-data';
import {ExportTypesEnum} from '../../dynamic-config/exported/data-export/ExportTypesEnum';
import {UsersService} from 'src/app/admin/services/users.service';
import {User} from 'src/app/admin/models/user';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent extends PaginatorTableComponent<Patient> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.Patient;
  _target = DynamicDefinitionEnum.PATIENT_MODULE;
  target = DynamicDefinitionEnum.PATIENT_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];
  currentUser = new User();
  addPatientEnabled = true;
  onlyTecCanAdd = false;
  patientMaritalNameEnabled = false;
  principalInvestigatorLabelKey = 'MODULE_TRIALS_PERSONNEL_PRINCIPAL_INVESTIGATOR';

  @ViewChild('displayAddToTrialForm', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;

  patients: Patient[];
  patient: Patient = new Patient();
  inclusionInfos: InclusionInfo[];
  contactSetDto: ContactSet = new ContactSet();
  trial: Trial = new Trial();
  patientClicked = false;
  totalNumber: number;
  displayAddBtn = false;
  isUserTec = true;
  filters: PatientGlobalFilter = new PatientGlobalFilter();
  isEstablishmentParent = true;
  multiEstablishmentEnabled:true;

  constructor(
      private patientService: PatientService,
      private cfr: ComponentFactoryResolver,
      private dataService: DataService,
      private translateService: InnohealthTranslateService,
      public router: Router,
      private authenticationService: AuthenticationService,
      private rollbackService: RollbackService<PatientGlobalFilter>,
      private dataTransferService: DataTransferService,
      private authService: AuthenticationService,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService,
      private userService: UsersService) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.rollbackService.hasGlobalFilter('patientGlobalFilter')) {
      this.filters = this.rollbackService.getGlobalFilter('patientGlobalFilter');
    } else {
      this.filters = new PatientGlobalFilter();
    }
    this.initProperties();
    this.orderBy = 'asc';
  }

  initProperties() {
    this.initTableConfig();
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.addPatientEnabled = this.dynamicConfigService.getProperty(PropertyEnum.addPatientEnabled);
      this.onlyTecCanAdd = this.dynamicConfigService.getProperty(PropertyEnum.onlyTecCanAdd);
      this.patientMaritalNameEnabled = this.dynamicConfigService.getProperty(PropertyEnum.patientMaritalNameEnabled);
      this.principalInvestigatorLabelKey = this.dynamicConfigService.getProperty(PropertyEnum.principalInvestigatorLabelKey);
      this.multiEstablishmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiEstablishmentEnabled);
      const roleIsNotEditor = this.authenticationService.currentUserRole !== Role.Editor;
      this.displayAddBtn = roleIsNotEditor && this.hasWritePermission() && (!(this.isEstablishmentParent && this.multiEstablishmentEnabled)) && this.addPatientEnabled;
      if (this.onlyTecCanAdd) {
        this.isUserTec = this.authenticationService.currentUserRole === 'TECHNICIAN';
      }
    });
  }

  GetCurrentUserEstablishmentName() {
    this.userService.getCurrentUser().subscribe(res => {
      this.currentUser = res;
      return this.currentUser.establishment;
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while loading user.' + error);
    });
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, PatientAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.isEstablishmentParent = this.dynamicConfigService.parent;
          this.buildTableHeaders();
        }
    );
    this.GetCurrentUserEstablishmentName();
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('establishment')
        .filterable()
        .sortable()
        .filter(this.filters.establishmentHhhId)
        .filterType(FilterType.IH_DROPDOWN)
        .options(null)
        .type('establishment-children')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('sex')
        .filterable()
        .sortable()
        .filterType(FilterType.DROPDOWN)
        .options(Data.genderWithNa)
        .cssClass((data: Patient) => this.getSexCssClass(data))
        .justifyContent('justify-center')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('internalIdentifier')
        .filterable()
        .sortable()
        .filter(this.filters.internalIdentifier)
        .filterType(FilterType.INPUT_TEXT)
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('lastName')
        .filterable()
        .sortable()
        .filter(this.filters.lastName)
        .filterType(FilterType.INPUT_TEXT)
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('birthName')
        .filterable()
        .sortable()
        .filter(this.filters.birthName)
        .filterType(FilterType.INPUT_TEXT)
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('firstName')
        .filterable()
        .sortable()
        .filter(this.filters.firstName)
        .filterType(FilterType.INPUT_TEXT)
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('birthDate')
        .withDateFormatter()
        .sortable()
        .build());
    this.headers = this.config.buildTable(availableHeaders);
    localStorage.setItem(this.target, this.headers.map(header => header.field).join(','));
  }

  getExportPageModule(): string {
    return 'src/app/patient/patient-list/patient-list.component';
  }

  getDefaultSortField(): string {
    return 'lastName';
  }

  getDeleteMessage(): string {
    return 'MODULE_PATIENTS_DIALOG_WARNING_PATIENT_WILL_BE_DELETED';
  }

  getService(): any {
    return this.patientService;
  }

  displayFormDialogue() {
    const formComponent = PatientFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.onAdd(this.updateList());
  }

  updateList() {
    return (patient: Patient) => {
      this.values.push(patient);
      this.values.sort((a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()));
    };
  }

  getList(filters?: any) {
    this.filters = DynamicTableHeader.buildFilterData(this.headers);
    this.rollbackService.saveGlobalFilter('patientGlobalFilter', this.filters);
    this.patientService.loadList(this.page, this.limit, this.sortBy, this.orderBy, this.filters)
        .subscribe((res: ResponsePage<Patient>) => {
          this.patientService.setPaginationConfiguration(res, this.sortBy, this.orderBy);
          this.values = res.content;
          this.totalNumber = res.totalElements;
          this.dataTransferService.getTransferredPatientsCount();
        });
  }

  getInclusion(hhhId: any) {
    this.patient.hhhId = hhhId;
    this.patientService.getPatientInfo(hhhId).subscribe((res: InclusionInfo[]) => {
      this.inclusionInfos = res;
      this.patientClicked = true;
    });
    this.patientService.getContactsOfPatient(hhhId).subscribe(res => {
      this.contactSetDto = res;
    });
  }

  edit(patient: Patient) {
    this.router.navigate(['/patient-details'], {queryParams: {hhhId: patient.hhhId}}).then();
  }

  displayPatientTrialFormDialogue() {
    const addDialogFactory = this.cfr.resolveComponentFactory(PatientTrialFormComponent);
    const addDialogComponentRef = this.dialogContainer.createComponent(addDialogFactory);
    const patientTrialFormComponent = addDialogComponentRef.instance;
    patientTrialFormComponent.patientHhhId = this.patient.hhhId;
    patientTrialFormComponent.onAdd(() => {
    });
  }

  displayDeleteDialogue(patient: Patient) {
    this.displayDeleteDialogueFromInfos(patient.hhhId, patient.firstName + ' ' + (this.patientMaritalNameEnabled ? patient.birthName : patient.lastName), patient);
  }

  refreshAfterDelete(entity?: Patient): void {
    this.patient = entity;
    this.sharedService.setInformationInLocalStorage('Patients', 'Supprimer', this.patient.internalIdentifier);
    const index = this.values.findIndex((patient: Patient) => patient.hhhId === this.patient.hhhId);
    this.values.splice(index, 1);
  }

  hasWritePermission() {
    return localStorage.getItem('Patient_READ_WRITE') === Permission.READ_WRITE;
  }

  isCurrentRoleEditor() {
    return this.authService.currentUserRole === Role.Editor;
  }

  getSexCssClass(data: Patient) {
    let sex = '';
    const man = this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MR');
    const woman = this.translateService.getTranslationString('MODULE_PATIENTS_FORM_SEX_OPTION_MME');
    if (data.sex === man || data.sex === 'H') {
      sex = 'man';
    } else if (data.sex === woman || data.sex === 'F') {
      sex = 'woman';
    }
    return sex;
  }

  exportPatients(event: any): void {
    console.log(event.filter);
    console.log(this.filters);
    const request = new ExportRequestBuilder<PatientGlobalFilter>()
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
        .sortBy(this.sortBy)
        .orderBy(this.orderBy)
        .filters(event.exportType === ExportTypesEnum.CUSTOM_EXPORT ? event.filter : this.filters)
        .build();
    this.patientService.exportPatientList(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }
}

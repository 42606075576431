import {JsonObject, JsonProperty} from 'json2typescript';
import {DynamicModuleDefinitionHeader} from './dynamic-module-definition-header';
import {DynamicModuleDefinitionProperty} from './dynamic-module-definition-property';

@JsonObject('DynamicModuleDefinition')
export class DynamicModuleDefinition {

  @JsonProperty('definition', String)
  definition: string = undefined;

  @JsonProperty('header', DynamicModuleDefinitionHeader)
  header: DynamicModuleDefinitionHeader = new DynamicModuleDefinitionHeader();

  @JsonProperty('properties', [DynamicModuleDefinitionProperty])
  properties: DynamicModuleDefinitionProperty[] = [];

}

export class NotificationNote {
  public header;
  public body;
  public enHeader;
  public enBody;

  public type;

  of(header, body, enHeader, enBody, type): NotificationNote {
    this.header = header;
    this.body = body;
    this.enHeader = enHeader;
    this.enBody = enBody;
    this.type = type;
    return this;
  }
}

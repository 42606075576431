import {Phone} from '../../../shared/entity/phone';
import {Fax} from '../../../shared/entity/fax';
import {Email} from '../../../shared/entity/email';
import {Address} from '../../../shared/entity/address';

export class Contact {
  hhhId: any;
  publicCommentCount: number;
  name:string;
  lastName: string;
  firstName: string;
  entityType: any;
  entityTypeHhhId: any;
  function: any;
  phones: Phone[] = [];
  faxes: Fax[] = [];
  emails: Email[] = [];
  addresses: Address[] = [];
  totalPhones: string;
  totalEmails: string;
  totalFaxes: string;
  totalAddresses: string;

  constructor() {
    this.phones = [];
    this.faxes = [];
    this.emails = [];
    this.addresses = [];
  }

  clone(copy: Contact): Contact {
    this.hhhId = copy.hhhId;
    this.name=copy.name;
    this.entityType = copy.entityType;
    this.entityTypeHhhId = copy.entityTypeHhhId;
    this.function = copy.function;
    this.phones = copy.phones;
    this.faxes = copy.faxes;
    this.emails = copy.emails;
    this.addresses = copy.addresses;
    this.totalPhones = copy.totalPhones;
    this.totalEmails = copy.totalEmails;
    this.totalFaxes = copy.totalFaxes;
    this.totalAddresses = copy.totalAddresses;
    this.publicCommentCount = copy.publicCommentCount;
    return this;
  }

  public removeNullContactEntries() {
    this.phones = this.phones.filter((p: Phone) => {
      return p && p.value != null;
    });
    this.faxes = this.faxes.filter((f: Fax) => {
      return f && f.value != null;
    });
    this.emails = this.emails.filter((e: Email) => {
      return e && e.value != null;
    });
    this.addresses = this.addresses.filter((a: Address) => {
      return a && a.country != null && a.country.hhhId;
    });
  }

  initTotalInformation() {
    this.totalPhones = this.phones.map((p: Phone) => {
      return p.value;
    }).join(' , ');
    this.totalEmails = this.emails.map((e: Email) => {
      return e.value;
    }).join(' , ');
    this.totalFaxes = this.faxes.map((f: Fax) => {
      return f.value;
    }).join(' , ');
    this.totalAddresses = this.addresses.map((a: Address) => {
      return [a.line1, a.line2, a.line3, a.postCode, a.city, a.country ? a.country.nameFr : ''].filter(Boolean).join(', ');
    }).join(' , ');
  }
  init(obj: any): Contact {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

}

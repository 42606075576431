export class PromoterType {
  hhhId: number;
  nameFr: string;

  constructor(hhhId: number, nameFr: string) {
    this.hhhId = hhhId;
    this.nameFr = nameFr;
  }

}

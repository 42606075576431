import {DesignStructureConfig} from '../../models/design-structure-config';
import {ArmPhaseDto} from '../../models/arm-phase-dto';
import {PhaseDto} from '../../models/phase-dto';
import {ArmEscalationExtensionDto} from '../../models/arm-escalation-extension-dto';
import {ArmInclusionArmDto} from '../../models/arm-inclusion-arm-dto';
import {InclusionArmDto} from '../../models/inclusion-arm-dto';
import {ArmCohortDto} from '../../models/arm-cohort-dto';
import {CohortDto} from '../../models/cohort-dto';
import {AdministrationRouteDto} from '../../models/administration-route-dto';
import {MoleculeStudiedDto} from '../../models/molecule-studied-dto';
import {Arm} from './arm';
import {Util} from '../../../helpers/util';
import {LabelValue} from '../../../shared/entity/label-value';

export class ArmUtil {
  public static initializeExistingArmFieldsFromConfig(arm: Arm, config: DesignStructureConfig): void {
    if (arm.phase == null) {
      arm.phase = new ArmPhaseDto(config.phase.shown);
    }
    if (arm.phase.phase == null) {
      arm.phase.phase = new PhaseDto();
    }
    if (arm.escaladeExtension == null) {
      arm.escaladeExtension = new ArmEscalationExtensionDto(config.escaladeExtension.shown);
    }

    if (arm.inclusionArm == null) {
      arm.inclusionArm = new ArmInclusionArmDto(config.inclusionArm.shown);
    }
    if (arm.inclusionArm.inclusionArm == null) {
      arm.inclusionArm.inclusionArm = new InclusionArmDto();
    }
    if (config.inclusionArm.shown) {
      const isUsingCreation = arm.inclusionArm.inclusionArm.hhhId === null
          && !Util.isStringNullOrEmpty(arm.inclusionArm.inclusionArm.name);
      if (isUsingCreation) {
        arm.inclusionArm.inclusionArm.hhhId = -1;
      }
    }

    if (arm.cohort == null) {
      arm.cohort = new ArmCohortDto(config.cohort.shown);
    }
    if (arm.cohort.cohort == null) {
      arm.cohort.cohort = new CohortDto();
    }
    if (config.cohort.shown) {
      const isUsingCreation = arm.cohort.cohort.id === null && !Util.isStringNullOrEmpty(arm.cohort.cohort.name);
      if (isUsingCreation) {
        arm.cohort.cohort.id = -1;
      }
    }
    for (const designLevel of arm.designLevels) {
      if (designLevel.type === 'TREATMENT') {
        if (designLevel.administrationRoute && designLevel.administrationRoute.administrationRoute == null) {
          designLevel.administrationRoute.administrationRoute = new AdministrationRouteDto();
        }
        if (designLevel.studiedMolecule && designLevel.studiedMolecule.studiedMolecule == null) {
          designLevel.studiedMolecule.studiedMolecule = new MoleculeStudiedDto();
        }
      }
    }
  }

  public static setConvenientEmptyFieldsToNull(arm: Arm): void {
    if (arm.phase && arm.phase.phase.hhhId == null) {
      arm.phase.phase = null;
    }
    if (arm.cohort && arm.cohort.cohort && arm.cohort.cohort.id === -1) {
      arm.cohort.cohort.id = null;
    } else if (arm.cohort && arm.cohort.cohort && arm.cohort.cohort.id == null && Util.isStringNullOrEmpty(arm.cohort.cohort.name)) {
      arm.cohort.cohort = null;
    }
    if (arm.inclusionArm && arm.inclusionArm.inclusionArm && arm.inclusionArm.inclusionArm.hhhId === -1) {
      arm.inclusionArm.inclusionArm.hhhId = null;
    } else if (arm.inclusionArm && arm.inclusionArm.inclusionArm && arm.inclusionArm.inclusionArm.hhhId == null && Util.isStringNullOrEmpty(arm.inclusionArm.inclusionArm.name)) {
      arm.inclusionArm.inclusionArm = null;
    }
    for (const designLevel of arm.designLevels) {
      if (designLevel.type === 'TREATMENT') {
        if (designLevel.studiedMolecule && designLevel.studiedMolecule.studiedMolecule && designLevel.studiedMolecule.studiedMolecule.hhhId == null) {
          designLevel.studiedMolecule.studiedMolecule = null;
        }
        if (designLevel.administrationRoute && designLevel.administrationRoute.administrationRoute && designLevel.administrationRoute.administrationRoute.hhhId == null) {
          designLevel.administrationRoute.administrationRoute = null;
        }
      }
    }
  }

  public static setLabelForPhase(arm: Arm, phases: Array<LabelValue>): void {
    if (arm.phase && arm.phase.phase && arm.phase.phase.hhhId) {
      const phaseFound = phases.find(p => p.value == arm.phase.phase.hhhId);
      if (phaseFound) {
        arm.phase.phase.nameFr = phaseFound.label;
      }
    }
  }

  public static setLabelForCohort(arm: Arm, cohorts: Array<LabelValue>): void {
    if (arm.cohort && arm.cohort.cohort && arm.cohort.cohort.id) {
      const cohortFound = cohorts.find(p => p.value == arm.cohort.cohort.id);
      if (cohortFound) {
        arm.cohort.cohort.name = cohortFound.label;
      }
    }
  }

  public static setLabelForInclusionArm(arm: Arm, inclusionArms: Array<LabelValue>): void {
    if (arm.inclusionArm && arm.inclusionArm.inclusionArm && arm.inclusionArm.inclusionArm.hhhId) {
      const inclusionArmFound = inclusionArms.find(p => p.value == arm.inclusionArm.inclusionArm.hhhId);
      if (inclusionArmFound) {
        arm.inclusionArm.inclusionArm.name = inclusionArmFound.label;
      }
    }
  }

  public static setLabelsForDesignLevels(arm: Arm, administrativeRoutes: Array<LabelValue>, studiedMolecules: Array<LabelValue>): void {
    for (const designLevel of arm.designLevels) {
      if (designLevel.type === 'TREATMENT') {
        if (designLevel.administrationRoute && designLevel.administrationRoute.administrationRoute && designLevel.administrationRoute.administrationRoute.hhhId) {
          const routeFound = administrativeRoutes.find(ad => ad.value == designLevel.administrationRoute.administrationRoute.hhhId);
          if (routeFound) {
            designLevel.administrationRoute.administrationRoute.name = routeFound.label;
          }
        }
        if (designLevel.studiedMolecule && designLevel.studiedMolecule.studiedMolecule && designLevel.studiedMolecule.studiedMolecule.hhhId) {
          const moleculeFound = studiedMolecules.find(ad => ad.value == designLevel.studiedMolecule.studiedMolecule.hhhId);
          if (moleculeFound) {
            designLevel.studiedMolecule.studiedMolecule.name = moleculeFound.label;
          }
        }
      }
    }
  }

  public static generateArmName(arm: Arm): string {
    let resultName = '';
    let notFirst = false;
    if (arm.phase && arm.phase.enabled && arm.phase.phase && arm.phase.phase.nameFr) {
      resultName += arm.phase.phase.nameFr;
      notFirst = true;
    }
    if (arm.escaladeExtension && arm.escaladeExtension.enabled && arm.escaladeExtension.escaladeExtension) {
      resultName += (notFirst ? ' - ' : '') + arm.escaladeExtension.escaladeExtension;
      notFirst = true;
    }
    if (arm.cohort && arm.cohort.enabled && arm.cohort.cohort && arm.cohort.cohort.name) {
      resultName += (notFirst ? ' - ' : '') + arm.cohort.cohort.name;
      notFirst = true;
    }
    if (arm.inclusionArm && arm.inclusionArm.enabled && arm.inclusionArm.inclusionArm && arm.inclusionArm.inclusionArm.name) {
      resultName += (notFirst ? ' - ' : '') + arm.inclusionArm.inclusionArm.name;
      notFirst = true;
    }
    for (const designLevel of arm.designLevels) {
      if (designLevel.type === 'LIAISON' && designLevel.fieldValue) {
        resultName += (notFirst ? ' - ' : '') + designLevel.fieldValue;
        notFirst = true;
      } else if (designLevel.type === 'TREATMENT') {
        if (designLevel.studiedMolecule && designLevel.studiedMolecule.enabled && designLevel.studiedMolecule.studiedMolecule
            && designLevel.studiedMolecule.studiedMolecule.name) {
          resultName += (notFirst ? ' - ' : '') + designLevel.studiedMolecule.studiedMolecule.name;
          notFirst = true;
        }
        if (designLevel.administrationRoute && designLevel.administrationRoute.enabled
            && designLevel.administrationRoute.administrationRoute && designLevel.administrationRoute.administrationRoute.name) {
          resultName += (notFirst ? ' - ' : '') + designLevel.administrationRoute.administrationRoute.name;
          notFirst = true;
        }
        if (designLevel.dose && designLevel.dose.dose && designLevel.dose.dose.length > 0) {
          resultName += (notFirst ? ' - ' : '') + designLevel.dose.dose;
          notFirst = true;
        }
      }
    }
    return resultName;
  }
  public static generateArmNameWhenCohortArmPartEnabled(arm: Arm): string {
    let resultName = '';
    let notFirst = false;
    if (arm.phase && arm.phase.enabled && arm.phase.phase && arm.phase.phase.nameFr) {
      resultName += arm.phase.phase.nameFr;
      notFirst = true;
    }
    if (arm.escaladeExtension && arm.escaladeExtension.enabled && arm.escaladeExtension.escaladeExtension) {
      resultName += (notFirst ? ' - ' : '') + arm.escaladeExtension.escaladeExtension;
      notFirst = true;
    }
    if (arm.cohortArmPart) {
      resultName += (notFirst ? ' - ' : '') + arm.cohortArmPart;

    }
    return resultName;
  }
}

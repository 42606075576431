import {ContactSet} from '../../shared/entity/contact-set';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {DataCommentCount} from '../../data-comment/entity/data-comment-count';

export class DetailedHumanResource extends DataCommentCount {
  public hhhId: any;
  public title: any;
  public firstName: any;
  public lastName: any;
  public role: string;
  public siteHhhId: any;
  public servicesHhhId: number[];
  public establishment: number;
  public specialityHhhId: any;
  public specialitiesHhhId: number[];
  public specialityOtherValue: string;
  public responsibilitiesHhhId: number[];
  public contactSetDto: ContactSet = new ContactSet();
  public rpps: number;
  public registrationNumber: number;
  public departmentsHhhId: number[];
  public committeesHhhId: number[];
  public trialCommittesHhhId: number[];
  public acronymSimbad: string;
  public acronymDepartmentHhhId: number;
  public entryDate: Date;
  public releaseDate: Date;
  public cnom: string;
  public adeli: string;
  public fieldAdded1: string;
  public fieldAdded2: string;
  public fieldAdded3: string;

  init(obj: any): DetailedHumanResource {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
         super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  isValid(config: FormConfig, isParent: boolean, isDoctorSelected: boolean): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'establishment') {
        if (isParent && required && Util.isNullOrUndefined(this.establishment)) {
          return false;
        }
      } else if (key === 'service') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.servicesHhhId)) {
          return false;
        }
      } else if (key === 'site') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.siteHhhId)) {
          return false;
        }
      } else if (key === 'responsibility') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.responsibilitiesHhhId)) {
          return false;
        }
      } else if (key === 'speciality') {
        if (isDoctorSelected && required && Util.isNullOrUndefinedOrEmpty(this.specialitiesHhhId)) {
          return false;
        }
      } else if (key === 'rpps') {
        if (isDoctorSelected && required && Util.isNullOrUndefined(this.rpps)) {
          return false;
        }
      } else if (key === 'department') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.departmentsHhhId)) {
          return false;
        }
      } else if (key === 'committee') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.committeesHhhId)) {
          return false;
        }
      } else if (key === 'acronymDepartment') {
        if (required && Util.isNullOrUndefined(this.acronymDepartmentHhhId)) {
          return false;
        }
      } else if (key === 'cnom') {
        if (isDoctorSelected && required && Util.isNullOrUndefinedOrEmpty(this.cnom)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }


}

import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {FormInputConfig} from '../../entity/form-input-config';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {PropertyEnum} from '../../entity/property-enum';
import {InitialFocusDirective} from '../../directive/initial-focus.directive';

export const DYNAMIC_INPUT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicInputComponent),
  multi: true
};

@Component({
  selector: 'ih-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.css'],
  providers: [DYNAMIC_INPUT_VALUE_ACCESSOR]
})
export class DynamicInputComponent implements OnInit, ControlValueAccessor {

  @Input() type = 'text';
  @Input() name: string;
  @Input() pattern: string;
  @Input() config: FormInputConfig;
  @Input() patternErrorMsg: string;
  @Input() requiredErrorMsg = 'FORM_REQUIRED_FIELD_ERROR';
  @Input() uniqueErrorMsg: string;
  @Input() hasMolecularPreScreening = true;
  @Input() MolecularPreScreeningDisabledErrorMsg = 'MOLECULAR_PRESCREENING_DISABLED_ERROR';
  @Input() outOfRangeErrorMsg: string;
  @Input() submitted: boolean;
  @Input() placeholder = '';
  @Input() hasComment = false;
  @Input() commentDisabled = false;
  @Input() publicCommentCount: number;
  @Input() isLoaderVisible = false;
  @Input() isInputExist = true;
  @Input() alignLabel = false;
  @Input() maxValue: number;
  @Input() minValue: number;
  @Input() disabled = false;
  @Input() showLabel = true;
  @Input() important = false;
  @Input() title: string;
  @Input() showAddBtn = false;
  @Input() disableAddBtn = false;
  @Input() addBtnCount: number;
  @Input() addBtnTitle: string;
  @Input() hasIcon = false;
  @Input() checkedInput = false;
  @Input() isInputInvalid = false;
  @Input() invalidErrorMsg: string;
  @Input() hasCLearButton = false;
  @Input() size = 200;
  @Input() currencyValidation = false;
  @Input() showToggleBtn = false;
  @Input() showEyeBtn = false;
  @Input() disableToggleBtn = false;
  @Input() disableEyeBtn = false;
  @Input() focus = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueInput: EventEmitter<any> = new EventEmitter();
  @Output() displayDataComment = new EventEmitter<{attachedToField: string, isPrivate: boolean}>();
  @Output() add = new EventEmitter();
  @Output() click = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Output() showDetails = new EventEmitter();
  @Output() toggle = new EventEmitter();


  hasPrivatePostIt = true;
  private _value: any;
  isOutOfRange = false;
  isToggled = false;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
      this.checkForRange();
      this.valueChange.emit(this._value);
      this.valueInput.emit(this._value);
    }
  }

  constructor(private dynamicConfigService: DynamicConfigService) {
  }

  ngOnInit(): void {
    this.dynamicConfigService.initProperties().subscribe(() =>
        this.hasPrivatePostIt = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled));
  }


  writeValue(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(this._value);
    }
  }

  emitDataCommentEvent(p: boolean) {
    this.displayDataComment.emit({attachedToField: this.name, isPrivate: p});
  }

  emitAddEvent() {
    this.add.emit();
  }

  emitClickEvent(event: any) {
    this.click.emit();
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onChange = (_) => {};

  onTouched = (_) => {};

  checkForRange() {
    if (this._value > this.maxValue || this._value < this.minValue) {
      this.isOutOfRange = true;
    } else {
      this.isOutOfRange = false;
    }
  }

  emitClearEvent() {
    this.clear.emit();
  }

  toggleIcon(): void {
    this.isToggled = !this.isToggled;
    this.emitToggleEvent();
  }

  emitEyeEvent() {
    this.showDetails.emit();
  }
  emitToggleEvent(){
    this.toggle.emit();
  }


}






import {Component, Input} from '@angular/core';

@Component({
  selector: 'ih-trial-design-icon',
  templateUrl: './trial-design-icon.component.html',
  styleUrls: ['./trial-design-icon.component.css']
})
export class TrialDesignIconComponent {

  @Input() title: string;

  constructor() { }

}

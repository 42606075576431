import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {IsDeletable} from '../../is-deletable';
import {PatientInclusion} from '../entity/inclusion/patient-inclusion';
import {PatientFileImport} from '../entity/inclusion/patient-file-import';
import {TrialInclusionGlobalFilter} from '../entity/inclusion/trial-inclusion-global-filter';
import {ResponsePage} from '../../shared/entity/response-page';
import {TrialInclusion} from '../entity/inclusion/trial-inclusion';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {IgCsvExport} from '../../shared/entity/ig-csv-export';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {catchError, map} from 'rxjs/operators';

class ImportedPatientInclusion {
  NIP: string;
  lastName: string;
  birthName: string;
  firstName: string;
  sex: string;
  status: string;
  birthDate: string;
  state: string;
  arm: string;
  site: string;
  referrerInvestigatorLastName: string;
  referrerInvestigatorFirstName: string;
  consentSignatureDate: string;
  inclusionNumber: string;
  inclusionDate: string;
  followupEndDate: string;
  patientComment: string;
  inclusionComment: string;
  consentPreScreeningDate: string;
  blocRequestDate: string;
  dispatchBlockDate: string;
  result: string;
  caseManagement:string;
  resultDate: string;
  preScreeningFailure: string;
  consentVersion: string;
  lastNameDoctorInclueur: string;
  firstNameDoctorInclueur: string;
  screeningNumber: string;
  screeningFailure: string;
  screeningFailureDate: string;
  preScreenFailureDate: string;
  randomizationDate: string;
  trialTreatmentStartDate: string;
  trialTreatmentEndDate: string;
  trialEndDate: string;
  preselectionDate: string;
  inclusionTrialEndDate: string;
  preScreeningConsentSignatureDate:string;
  preScreenFailure:string;
  inclusionConsentDate:string;
  consent:string;
  inclusionLastNamePI:string;
  inclusionFirstNamePI:string;
  inclusionIdentifier2:string;
  design:string;
}

@Injectable({
  providedIn: 'root'
})

export class InclusionService {

  public host = new Properties().host + '/inclusion';
  public trialInclusionHHhId: number;
  public dataPage: ResponsePage<TrialInclusion>;
  public page: number;
  public limit = 50;
  public sortBy = 'hhhId';
  public orderBy = 'desc';
  inclusionGlobalFilter = new TrialInclusionGlobalFilter();

  constructor(
    private translateService: InnohealthTranslateService,
    private datePipe: DatePipe,
    private httpClient: HttpClient
  ) {
  }

  getTrialInclusions(hhhId: number, page: number, limit: number, sortBy: string, orderBy: string,
                     trialInclusionGlobalFilter: TrialInclusionGlobalFilter
  ): Observable<ResponsePage<TrialInclusion>> {
    return this.httpClient.post<ResponsePage<TrialInclusion>>(
      this.host + '/load-list',
      new RequestPageBuilder<TrialInclusionGlobalFilter>()
        .objectId(hhhId)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(trialInclusionGlobalFilter)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(trialInclusion => new TrialInclusion().init(trialInclusion));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/is-deletable/' + hhhId);
  }

  isPatientInTrial(trialHhhId: number, patientHhhId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.host}/patient-in-trial/${trialHhhId}/${patientHhhId}`);
  }

  isUserCanAddInclusion(trialHhhId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this.host}/user-can-add-inclusion/${trialHhhId}`);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  addInclusion(patientInclusion: PatientInclusion): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', patientInclusion);
  }


  patientFileImport(patientFileImport: PatientFileImport): Observable<string[][]> {
    const formdata: FormData = new FormData();
    formdata.append('file', patientFileImport.file, 'patientList');
    return this.httpClient.post<string[][]>(this.host + '/import/csv/' + patientFileImport.trialHhhId,
      formdata,
      {
        responseType: 'json',
        reportProgress: true
      }
    );
  }

  patientFileImportIgrMode(patientFileImport: File): Observable<string[]> {
    const data: FormData = new FormData();
    data.append('file', patientFileImport);
    return this.httpClient.post<string[]>(this.host + '/import/csv/igr-mode', data);
  }

  createReportImportCSVFileForCjp(importedPatientInclusions: string [][]) {
    const importedPatientInclusionList = [];
    for (const importedPatientInclusion of importedPatientInclusions) {
      const importedPatientInclusionItem = new ImportedPatientInclusion();
      importedPatientInclusionItem.NIP = importedPatientInclusion[0];
      importedPatientInclusionItem.lastName = importedPatientInclusion[1];
      importedPatientInclusionItem.firstName = importedPatientInclusion[2];
      importedPatientInclusionItem.sex = importedPatientInclusion[3];
      importedPatientInclusionItem.status = importedPatientInclusion[4];
      importedPatientInclusionItem.birthDate = importedPatientInclusion[5];
      importedPatientInclusionItem.state = importedPatientInclusion[6];
      importedPatientInclusionItem.site = importedPatientInclusion[7];
      importedPatientInclusionItem.consentPreScreeningDate = importedPatientInclusion[8];
      importedPatientInclusionItem.preScreeningFailure = importedPatientInclusion[9];
      importedPatientInclusionItem.consentSignatureDate = importedPatientInclusion[10];
      importedPatientInclusionItem.consentVersion = importedPatientInclusion[11];
      importedPatientInclusionItem.lastNameDoctorInclueur = importedPatientInclusion[12];
      importedPatientInclusionItem.firstNameDoctorInclueur = importedPatientInclusion[13];
      importedPatientInclusionItem.screeningNumber = importedPatientInclusion[14];
      importedPatientInclusionItem.arm = importedPatientInclusion[15];
      importedPatientInclusionItem.screeningFailure = importedPatientInclusion[16];
      importedPatientInclusionItem.randomizationDate = importedPatientInclusion[17];
      importedPatientInclusionItem.inclusionNumber = importedPatientInclusion[18];
      importedPatientInclusionItem.inclusionDate = importedPatientInclusion[19];
      importedPatientInclusionItem.trialTreatmentStartDate = importedPatientInclusion[20];
      importedPatientInclusionItem.trialTreatmentEndDate = importedPatientInclusion[21];
      importedPatientInclusionItem.trialEndDate = importedPatientInclusion[22];
      importedPatientInclusionItem.preselectionDate = importedPatientInclusion[23];
      importedPatientInclusionItem.patientComment = importedPatientInclusion[24];
      importedPatientInclusionItem.inclusionComment = importedPatientInclusion[25];

      importedPatientInclusionList.push(importedPatientInclusionItem);
    }
    const date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const headers = [
      'NIP', 'Nom du patient', 'Prenom du Patient', 'Sexe', 'Statut du sujet',
      'Date de naissance', 'Etat', 'Site', 'Date de signature consentement prescreening', ' Pre-screening fail',
      'Date de signature du consentement initial', 'version du consentement', 'Nom du medecin inclueur', 'Prenom du medecin inclueur ', ' Numero de screening', 'design', ' Screening fail', ' Date de randomisation', 'Numero d\'inclusion', 'Date d\'inclusion', ' Date de debut de traitement a l\'etude ',
      'Date de fin de traitement a l\'etude', ' Date de fin d\'etude', ' Date de présélection', 'Commentaire1', 'Commentaire 2'
    ];
    new IgCsvExport(
      importedPatientInclusionList,
      headers,
      'rapport-import-inclusions-' + date
    ).convertToCsvAndDownload();
  }

  createReportImportCSVFileForHe(importedPatientInclusions: string [][]) {
    const importedPatientInclusionList = [];
    for (const importedPatientInclusion of importedPatientInclusions) {
      const importedPatientInclusionItem = new ImportedPatientInclusion();

      importedPatientInclusionItem.NIP = importedPatientInclusion[0];                            /*NIP*/
      importedPatientInclusionItem.lastName = importedPatientInclusion[1];                       /*Nom du patient*/
      importedPatientInclusionItem.birthName = importedPatientInclusion[2];                       /*Nom marital du patient*/
      importedPatientInclusionItem.firstName = importedPatientInclusion[3];                      /*Prenom du Patient*/
      importedPatientInclusionItem.sex = importedPatientInclusion[4];                            /*Sexe*/
      importedPatientInclusionItem.status = importedPatientInclusion[5];                         /*Statut du sujet*/
      importedPatientInclusionItem.birthDate = importedPatientInclusion[6];                      /*Date de naissance*/
      importedPatientInclusionItem.state = importedPatientInclusion[7];                          /*Etat*/
      importedPatientInclusionItem.arm = importedPatientInclusion[8];                            /*Bras d'inclusion*/
      importedPatientInclusionItem.site = importedPatientInclusion[9];                           /*Site*/
      importedPatientInclusionItem.inclusionNumber = importedPatientInclusion[10];                /*Numero d'inclusion*/
      importedPatientInclusionItem.inclusionDate = importedPatientInclusion[11];                 /*Date d'inclusion*/
      importedPatientInclusionItem.referrerInvestigatorLastName = importedPatientInclusion[12];   /*Nom du medecin inclueur*/
      importedPatientInclusionItem.referrerInvestigatorFirstName = importedPatientInclusion[13]; /*Prenom du medecin inclueur*/
      importedPatientInclusionItem.consentSignatureDate = importedPatientInclusion[14];          /*Date de signature du consentement initial*/
      importedPatientInclusionItem.followupEndDate = importedPatientInclusion[15];               /*Date de fin de suivi*/
      importedPatientInclusionItem.consentVersion = importedPatientInclusion[16];                /*Version du consentement*/
      importedPatientInclusionItem.trialTreatmentStartDate = importedPatientInclusion[17];       /*Date de début de TRT*/
      importedPatientInclusionItem.trialTreatmentEndDate = importedPatientInclusion[17];       /*Date de fin de TRT*/
      importedPatientInclusionItem.inclusionTrialEndDate = importedPatientInclusion[18];       /*Date d'arrêt d'étude'*/



      importedPatientInclusionItem.patientComment = importedPatientInclusion[34];
      importedPatientInclusionItem.inclusionComment = importedPatientInclusion[35];

      importedPatientInclusionList.push(importedPatientInclusionItem);
    }
    const date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const headers = [
      'NIP',
      'Nom du patient',
      'Nom marital du patient',
      'Prenom du Patient',
      'Sexe',
      'Statut du sujet',
      'Date de naissance',
      'Etat',
      'Bras d\'inclusion',
      'Site',
      'Nom du medecin inclueur',
      'Prenom du medecin inclueur',
      'Date de signature du consentement initial',
      'Numero d\'inclusion',
      'version consentement',
      'date de début de TRT',
      'Date d\'inclusion',
      'Date de fin de traitment',
      'Date d\'arret d\'étude',
      'Commentaire1',
      'Commentaire 2'
    ];


    new IgCsvExport(
      importedPatientInclusionList,
      headers,
      'rapport-import-inclusions-' + date
    ).convertToCsvAndDownload();
  }

  createReportImportCSVFile(importedPatientInclusions: string [][]) {
    const importedPatientInclusionList = [];
    for (const importedPatientInclusion of importedPatientInclusions) {
      const importedPatientInclusionItem = new ImportedPatientInclusion();

      importedPatientInclusionItem.NIP = importedPatientInclusion[0];                            /*NIP*/
      importedPatientInclusionItem.lastName = importedPatientInclusion[1];                       /*Nom du patient*/
      importedPatientInclusionItem.firstName = importedPatientInclusion[2];                      /*Prenom du Patient*/
      importedPatientInclusionItem.sex = importedPatientInclusion[3];                            /*Sexe*/
      importedPatientInclusionItem.status = importedPatientInclusion[4];                         /*Statut du sujet*/
      importedPatientInclusionItem.birthDate = importedPatientInclusion[5];                      /*Date de naissance*/
      importedPatientInclusionItem.state = importedPatientInclusion[6];                          /*Etat*/
      importedPatientInclusionItem.arm = importedPatientInclusion[7];                            /*Bras d'inclusion*/
      importedPatientInclusionItem.site = importedPatientInclusion[8];                           /*Site*/
      importedPatientInclusionItem.inclusionNumber = importedPatientInclusion[9];                /*Numero d'inclusion*/
      importedPatientInclusionItem.inclusionDate = importedPatientInclusion[10];                 /*Date d'inclusion*/
      importedPatientInclusionItem.referrerInvestigatorLastName = importedPatientInclusion[11];   /*Nom du medecin inclueur*/
      importedPatientInclusionItem.referrerInvestigatorFirstName = importedPatientInclusion[12]; /*Prenom du medecin inclueur*/
      importedPatientInclusionItem.consentSignatureDate = importedPatientInclusion[13];          /*Date de signature du consentement initial*/
      importedPatientInclusionItem.followupEndDate = importedPatientInclusion[14];               /*Date de fin de suivi*/
      importedPatientInclusionItem.consentVersion = importedPatientInclusion[15];                /*Version du consentement*/
      importedPatientInclusionItem.trialTreatmentStartDate = importedPatientInclusion[16];       /*Date de début de TRT*/


      importedPatientInclusionItem.patientComment = importedPatientInclusion[34];
      importedPatientInclusionItem.inclusionComment = importedPatientInclusion[35];

      importedPatientInclusionList.push(importedPatientInclusionItem);
    }
    const date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const headers = [
      'NIP',
      'Nom du patient',
      'Prenom du Patient',
      'Sexe',
      'Statut du sujet',
      'Date de naissance',
      'Etat',
      'Bras d\'inclusion',
      'Site',
      'Nom du medecin inclueur',
      'Prenom du medecin inclueur',
      'Date de signature du consentement initial',
      'Numero d\'inclusion',
      'version consentement',
      'date de début de TRT',
      'Date d\'inclusion',
      'Date de fin de suivi',
      'Commentaire1',
      'Commentaire 2'
    ];
    new IgCsvExport(
      importedPatientInclusionList,
      headers,
      'rapport-import-inclusions-' + date
    ).convertToCsvAndDownload();
  }
  createReportImportCSVFileIGR(importedPatientInclusions: string [][]) {
    const importedPatientInclusionList = [];
    for (const importedPatientInclusion of importedPatientInclusions) {
      const importedPatientInclusionItem = new ImportedPatientInclusion();

      importedPatientInclusionItem.NIP = importedPatientInclusion[0];
      importedPatientInclusionItem.lastName = importedPatientInclusion[1];
      importedPatientInclusionItem.firstName = importedPatientInclusion[2];
      importedPatientInclusionItem.sex = importedPatientInclusion[3];
      importedPatientInclusionItem.status = importedPatientInclusion[4];
      importedPatientInclusionItem.birthDate = importedPatientInclusion[5];
      importedPatientInclusionItem.state = importedPatientInclusion[6];
      importedPatientInclusionItem.preScreeningConsentSignatureDate = importedPatientInclusion[7];
      importedPatientInclusionItem.blocRequestDate = importedPatientInclusion[8];
      importedPatientInclusionItem.dispatchBlockDate = importedPatientInclusion[9];
      importedPatientInclusionItem.result = importedPatientInclusion[10];
      importedPatientInclusionItem.resultDate = importedPatientInclusion[11];
      importedPatientInclusionItem.preScreenFailure = importedPatientInclusion[12];
      importedPatientInclusionItem.preScreenFailureDate = importedPatientInclusion[13];
      importedPatientInclusionItem.inclusionConsentDate = importedPatientInclusion[14];
      importedPatientInclusionItem.consent = importedPatientInclusion[15];
      importedPatientInclusionItem.inclusionLastNamePI = importedPatientInclusion[16];
      importedPatientInclusionItem.inclusionFirstNamePI = importedPatientInclusion[17];
      importedPatientInclusionItem.inclusionIdentifier2 = importedPatientInclusion[18];
      importedPatientInclusionItem.caseManagement = importedPatientInclusion[19];
      importedPatientInclusionItem.design = importedPatientInclusion[20];
      importedPatientInclusionItem.screeningFailure = importedPatientInclusion[21];
      importedPatientInclusionItem.screeningFailureDate = importedPatientInclusion[22];
      importedPatientInclusionItem.randomizationDate = importedPatientInclusion[23];
      importedPatientInclusionItem.trialTreatmentStartDate = importedPatientInclusion[24];
      importedPatientInclusionItem.trialTreatmentEndDate = importedPatientInclusion[25];
      importedPatientInclusionItem.inclusionTrialEndDate = importedPatientInclusion[26];

      importedPatientInclusionItem.patientComment = importedPatientInclusion[34];
      importedPatientInclusionItem.inclusionComment = importedPatientInclusion[35];

      importedPatientInclusionList.push(importedPatientInclusionItem);
    }
    const date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const headers = [
      'NIP',
      'Nom du Patient',
      'Prenom du Patient',
      'Sexe',
      'Statut du sujet',
      'Date de naissance',
      'Etat',
      'Date de signature consentement prescreening',
      'Date de demande du bloc',
      'Date d\'envoi du bloc/lames/tubes sang',
      'Resultat',
      'Date du resultat',
      'Pre-screening fail',
      'Date de Pre-screening fail',
      'Date de signature du consentement initial',
      'Version du consentement',
      'Nom du médecin signataire (prescreening)',
      'Nom du médecin signataire (screening)',
      'Numero de screening',
      'Prise En Charge',
      'Design',
      'Screening fail',
      'Date de Screening fail',
      'Date de randomisation',
      'Date de debut de traitement a l\'etude',
      'Date de fin de traitement a l\'etude',
      'Date de fin d\'etude',
      'Commentaire1',
      'Commentaire 2'
    ];
    new IgCsvExport(
      importedPatientInclusionList,
      headers,
      'rapport-import-inclusions-IGR-' + date
    ).convertToCsvAndDownload();
  }


  createReportImportCSVFileCol(importedPatientInclusions: string [][]) {
    const importedPatientInclusionList = [];
    for (const importedPatientInclusion of importedPatientInclusions) {
      const importedPatientInclusionItem = new ImportedPatientInclusion();
      importedPatientInclusionItem.NIP = importedPatientInclusion[0];
      importedPatientInclusionItem.birthName = importedPatientInclusion[1];
      importedPatientInclusionItem.lastName = importedPatientInclusion[2];
      importedPatientInclusionItem.firstName = importedPatientInclusion[3];
      importedPatientInclusionItem.sex = importedPatientInclusion[4];
      importedPatientInclusionItem.status = importedPatientInclusion[5];
      importedPatientInclusionItem.birthDate = importedPatientInclusion[6];
      importedPatientInclusionItem.state = importedPatientInclusion[7];
      importedPatientInclusionItem.consentPreScreeningDate = importedPatientInclusion[8];
      importedPatientInclusionItem.blocRequestDate = importedPatientInclusion[9];
      importedPatientInclusionItem.dispatchBlockDate = importedPatientInclusion[10];
      importedPatientInclusionItem.result = importedPatientInclusion[11];
      importedPatientInclusionItem.resultDate = importedPatientInclusion[12];
      importedPatientInclusionItem.preScreeningFailure = importedPatientInclusion[13];
      importedPatientInclusionItem.consentSignatureDate = importedPatientInclusion[14];
      importedPatientInclusionItem.consentVersion = importedPatientInclusion[15];
      importedPatientInclusionItem.lastNameDoctorInclueur = importedPatientInclusion[16];
      importedPatientInclusionItem.firstNameDoctorInclueur = importedPatientInclusion[17];
      importedPatientInclusionItem.screeningNumber = importedPatientInclusion[18];
      importedPatientInclusionItem.arm = importedPatientInclusion[19];
      importedPatientInclusionItem.screeningFailure = importedPatientInclusion[20];
      importedPatientInclusionItem.screeningFailureDate = importedPatientInclusion[21];
      importedPatientInclusionItem.randomizationDate = importedPatientInclusion[22];
      importedPatientInclusionItem.inclusionNumber = importedPatientInclusion[23];
      importedPatientInclusionItem.trialTreatmentStartDate = importedPatientInclusion[24];
      importedPatientInclusionItem.trialTreatmentEndDate = importedPatientInclusion[25];
      importedPatientInclusionItem.trialEndDate = importedPatientInclusion[26];
      importedPatientInclusionItem.patientComment = importedPatientInclusion[27];
      importedPatientInclusionItem.inclusionComment = importedPatientInclusion[28];

      importedPatientInclusionList.push(importedPatientInclusionItem);
    }

    const date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const headers = [
      'NIP', 'Nom de naissance', 'Nom marital', 'Prenom du Patient', 'Sexe', 'Statut du sujet',
      'Date de naissance', 'Etat', 'Date de signature consentement prescreening', 'Date de demande du bloc', 'Date d\'envoi du bloc/lames/tubes sang', 'Resultat', 'Date du resultat', 'Pre-screening fail',
      'Date de signature du consentement initial', 'version du consentement', 'Nom du medecin inclueur',
      'Prenom du medecin inclueur', 'Numero de screening', 'Design', 'Screening fail', 'Date de screening failure', 'Date de randomisation', 'Numero d\'inclusion', 'Date de debut de traitement a l\'etude', 'Date de fin de traitement a l\'etude', 'Date de fin d\'etude', 'Commentaire1', 'Commentaire 2'
    ];
    new IgCsvExport(
      importedPatientInclusionList,
      headers,
      'rapport-import-inclusions-' + date
    ).convertToCsvAndDownload();
  }

  createReportImportCSVFileForSaintLuc(importedPatientInclusions: string [][]) {
    const importedPatientInclusionList = [];
    for (const importedPatientInclusion of importedPatientInclusions) {
      const importedPatientInclusionItem = new ImportedPatientInclusion();

      importedPatientInclusionItem.NIP = importedPatientInclusion[0];                            /*NIP*/
      importedPatientInclusionItem.birthName = importedPatientInclusion[1];
      importedPatientInclusionItem.lastName = importedPatientInclusion[2];
      importedPatientInclusionItem.firstName = importedPatientInclusion[3];
      importedPatientInclusionItem.sex = importedPatientInclusion[4];                            /*Sexe*/
      importedPatientInclusionItem.status = importedPatientInclusion[5];                         /*Statut du sujet*/
      importedPatientInclusionItem.birthDate = importedPatientInclusion[6];                      /*Date de naissance*/
      importedPatientInclusionItem.state = importedPatientInclusion[7];                          /*Etat*/
      importedPatientInclusionItem.arm = importedPatientInclusion[8];                            /*Bras d'inclusion*/
      importedPatientInclusionItem.site = importedPatientInclusion[9];                           /*Site*/
      importedPatientInclusionItem.referrerInvestigatorLastName = importedPatientInclusion[10];   /*Nom du medecin inclueur*/
      importedPatientInclusionItem.referrerInvestigatorFirstName = importedPatientInclusion[11]; /*Prenom du medecin inclueur*/
      importedPatientInclusionItem.consentSignatureDate = importedPatientInclusion[12];          /*Date de signature du consentement initial*/
      importedPatientInclusionItem.inclusionNumber = importedPatientInclusion[13];                /*Numero d'inclusion*/
      importedPatientInclusionItem.consentVersion = importedPatientInclusion[14];                /*Version du consentement*/
      importedPatientInclusionItem.trialTreatmentStartDate = importedPatientInclusion[15];       /*Date de début de TRT*/
      importedPatientInclusionItem.inclusionDate = importedPatientInclusion[16];                 /*Date d'inclusion*/
      importedPatientInclusionItem.followupEndDate = importedPatientInclusion[17];               /*Date de fin de suivi*/
      importedPatientInclusionItem.patientComment = importedPatientInclusion[34];
      importedPatientInclusionItem.inclusionComment = importedPatientInclusion[35];

      importedPatientInclusionList.push(importedPatientInclusionItem);
    }
    const date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const headers = [
      'NIP',
      'Nom de naissance',
      'Nom marital',
      'Prenom du Patient',
      'Sexe',
      'Statut du sujet',
      'Date de naissance',
      'Etat',
      'Bras d\'inclusion',
      'Site',
      'Nom du medecin inclueur',
      'Prenom du medecin inclueur',
      'Date de signature du consentement initial',
      'Numero d\'inclusion',
      'version consentement',
      'date de début de TRT',
      'Date d\'inclusion',
      'Date de fin de suivi',
      'Commentaire1',
      'Commentaire 2'
    ];
    new IgCsvExport(
      importedPatientInclusionList,
      headers,
      'rapport-import-inclusions-' + date
    ).convertToCsvAndDownload();
  }

  createReportImportCSVFileForGHPSJ(importedPatientInclusions: string [][]) {
    const importedPatientInclusionList = [];
    for (const importedPatientInclusion of importedPatientInclusions) {
      const importedPatientInclusionItem = new ImportedPatientInclusion();
      importedPatientInclusionItem.NIP = importedPatientInclusion[0];
      importedPatientInclusionItem.birthName = importedPatientInclusion[1];
      importedPatientInclusionItem.lastName = importedPatientInclusion[2];
      importedPatientInclusionItem.firstName = importedPatientInclusion[3];
      importedPatientInclusionItem.sex = importedPatientInclusion[4];
      importedPatientInclusionItem.status = importedPatientInclusion[5];
      importedPatientInclusionItem.birthDate = importedPatientInclusion[6];
      importedPatientInclusionItem.state = importedPatientInclusion[7];
      importedPatientInclusionItem.arm = importedPatientInclusion[8];
      importedPatientInclusionItem.site = importedPatientInclusion[9];
      importedPatientInclusionItem.lastNameDoctorInclueur = importedPatientInclusion[10];
      importedPatientInclusionItem.firstNameDoctorInclueur = importedPatientInclusion[11];
      importedPatientInclusionItem.consentSignatureDate = importedPatientInclusion[12];
      importedPatientInclusionItem.inclusionNumber = importedPatientInclusion[13];
      importedPatientInclusionItem.consentVersion = importedPatientInclusion[14];
      importedPatientInclusionItem.trialTreatmentStartDate = importedPatientInclusion[15];
      importedPatientInclusionItem.inclusionDate = importedPatientInclusion[16];
      importedPatientInclusionItem.followupEndDate = importedPatientInclusion[17];
      importedPatientInclusionItem.patientComment = importedPatientInclusion[18];
      importedPatientInclusionItem.inclusionComment = importedPatientInclusion[19];




      importedPatientInclusionList.push(importedPatientInclusionItem);
    }

    const date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    const headers = [
      'NIP',
      'Nom de naissance',
      'Nom marital',
      'Prenom du Patient',
      'Sexe',
      'Statut du sujet',
      'Date de naissance',
      'Etat',
      'Design',
      'Site',
      'Nom du medecin inclueur ',
      'Prenom du medecin inclueur',
      'Date de signature du consentement initial',
      'Numero d\'inclusion',
      'version du consentement',
      'Date de debut de traitement a l\'etude',
      'Date d\'inclusion ',
      'Date de fin de suivie',
      'Commentaire1',
      'Commentaire 2'
    ];
    new IgCsvExport(
      importedPatientInclusionList,
      headers,
      'rapport-import-inclusions-' + date
    ).convertToCsvAndDownload();
  }

  setPaginationConfiguration(dataPage: ResponsePage<TrialInclusion>, limit, sortBy, orderBy, filters: TrialInclusionGlobalFilter) {
    this.dataPage = dataPage;
    this.limit = limit;
    this.sortBy = sortBy;
    this.orderBy = orderBy;
    if (filters.hasOwnProperty('inclusionIdentifier1')) {
      this.inclusionGlobalFilter = filters;
    }
  }

  inclusionsImportGeneral(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.httpClient.post(this.host + '/import/trial/inclusions/general', formdata, {
      responseType: 'blob'
    });
  }

  exportInclusions(request: ExportRequest<TrialInclusionGlobalFilter>): Observable<any> {
    return this.httpClient.post<void>(this.host + '/trial/export-csv', request).pipe();
  }

  exportSingleInclusionVisits(request: ExportRequest<void>, trialHhhId: number): Observable<void> {
    return this.httpClient.post<void>(`${this.host}/${trialHhhId}/visit/export-csv`, request);
  }


  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}




import {Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {ReferenceActService} from '../../../../additional-costs/services/reference-act.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {TranslateService} from '@ngx-translate/core';
import {ActCategoryService} from '../../../../additional-costs/services/act-category.service';
import {Router} from '@angular/router';
import {TrialActService} from '../../../service/trial-act.service';
import {DataService} from '../../../../shared/services/data-service';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {VisitAdditionalCost} from '../../../entity/trial-additionnal-costs/visit-additional-cost';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';

@Component({
  selector: 'ih-theoretical-calendar-reference-acts',
  templateUrl: './theoretical-calendar-reference-acts.component.html',
  styleUrls: ['./theoretical-calendar-reference-acts.component.css']
})
export class TheoreticalCalendarReferenceActsComponent extends PaginatorTableComponent<VisitAdditionalCost> implements OnInit, OnDestroy {

  @Input() activeModule;
  @Input() values: VisitAdditionalCost[];

  @Input() headers: DynamicTableHeader[] = [];
  @Input() config: TableConfig;
  @Input() target: string;

  trialHhhId: number;
  actCategoryFilterList: SelectItem[] = [];
  noYesOptions: SelectItem[];

  @Output() rowClickEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    private referenceActService: ReferenceActService,
    private sharedService: SharedService,
    private translateService: InnohealthTranslateService,
    private translate: TranslateService,
    private actCategoryService: ActCategoryService,
    private router: Router,
    private trialActService: TrialActService,
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.actCategoryFilterList = [{label: '', value: null}];
    this.getActCategoryList();
    this.totalNumber = this.values.length;
    this.trialHhhId = +localStorage.getItem('trialHhhId');
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getAddTitle(): string {
    return 'MODULE_REFERENCE_ACTS_GENERAL_ADD_NEW';
  }

  getUpdateTitle(): string {
    return 'MODULE_REFERENCE_ACTS_GENERAL_UPDATE';
  }

  getDeleteMessage(): string {
    return 'MODULE_REFERENCE_ACTS_DIALOG_WARNING_SITE_WILL_BE_DELETED';
  }

  getService(): any {
    return this.referenceActService;
  }

  getActCategoryList() {
    this.actCategoryService.loadList().subscribe(res => {
      for (const category of res) {
        const item: SelectItem = {label: '', value: ''};
        item.label = category.nameFr;
        item.value = category.nameFr;
        this.actCategoryFilterList.push(item);
      }
    });
  }

  onRowSelect(data: number) {
    const act: VisitAdditionalCost = this.values.find(item => item.hhhId === data);
    this.rowClickEvent.emit(act);
  }

  getFilteredValue(event: any) {
    this.filterEvent.emit(event);
  }

}

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../services/users.service';
import {SharedService} from '../../shared/services/shared.service';
import {User} from '../models/user';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'ih-current-user-change-password-form',
  templateUrl: './current-user-change-password-form.component.html',
  styleUrls: ['./current-user-change-password-form.component.css']
})
export class CurrentUserChangePasswordFormComponent implements OnInit {

  @ViewChild('changePasswordForm') changePasswordForm: NgForm;

  public user: User = new User();
  public generatedPassword: string;
  public id: any;
  passwordScore = 0;
  isPasswordContainsUppercase = false;
  isPasswordContainsLowercase = false;
  isPasswordContainsNumeric = false;
  isPasswordContainsSpecialCharacter = false;
  isPasswordLengthValid = false;

  @Input() userNamePlaceholder: string;

  constructor(private router: Router,
              private userService: UsersService,
              private sharedService: SharedService) {
  }

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.id = url.queryParams.id;
    }
  }

  updatePassword() {
    this.user.hhhId = this.id;
    if (!this.isPasswordValid()) {
      return;
    }
    this.userService.changePassword(this.user).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  generatePass() {
    const passwordLength = 13;
    this.passwordScore = 0;
    while (!this.isPasswordValid()) {
      this.generatedPassword = '';
      let firstGeneratedPasswordPart = '';
      const keyList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789/*-+&é@_-';
      for (let i = 0; i < (passwordLength - 1); i++) {
        firstGeneratedPasswordPart += keyList.charAt(Math.floor(Math.random() * keyList.length));
      }
      this.generatedPassword = firstGeneratedPasswordPart.concat((Math.floor((Math.random() * 9) + 1)).toString());
      this.userNamePlaceholder = this.user.newPassword1 = this.user.newPassword2 = this.generatedPassword;
      this.passwordUpdated();
    }
  }

  private isPasswordValid() {
    return this.user.newPassword1 && this.user.newPassword2 && this.user.newPassword1 === this.user.newPassword2 && this.passwordScore === 100;
  }

  passwordUpdated() {
    this.passwordScore = this.userService.calculatePasswordStrength(this.user.newPassword1);
    this.isPasswordContainsUppercase = this.userService.isPasswordContainsUppercase(this.user.newPassword1);
    this.isPasswordContainsLowercase = this.userService.isPasswordContainsLowercase(this.user.newPassword1);
    this.isPasswordContainsNumeric = this.userService.isPasswordContainsNumeric(this.user.newPassword1);
    this.isPasswordContainsSpecialCharacter = this.userService.isPasswordContainsSpecialCharacter(this.user.newPassword1);
    this.isPasswordLengthValid = this.userService.isPasswordLengthValid(this.user.newPassword1);
  }
}

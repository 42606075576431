import {isUndefined} from 'util';

export class HumanResourceGlobalFilter {

  lastName: string;
  firstName: string;
  concatenatedResponsabilities: number;
  establishment: number;
  site: number;

  constructor(filters?: any) {
    if (!isUndefined(filters)) {
      this.lastName = filters[1];
      this.firstName = filters[2];
      this.establishment = filters[4];
      this.site = filters[5];
    }
  }

}

import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'ih-pharmacy',
  templateUrl: './pharmacy-container.component.html',
  styleUrls: ['./pharmacy-container.component.css']
})
export class PharmacyContainerComponent implements OnInit {

  items: MenuItem[];

  constructor() {
  }

  ngOnInit() {
    this.items = [{
      label: 'Stock',
      routerLink: ['/pharmacy/stock']
    },{
      label: 'Dispensation',
      routerLink: ['/pharmacy/dispensation']
    }];
  }

}

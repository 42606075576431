export enum FormDefinitionFieldsEnum {
  // TRIAL MODULE
  promoter = 'promoter',
  promoterType = 'promoterType',
  protocolNumber = 'protocolNumber',
  localIdentifier = 'localIdentifier',
  idRcb = 'idRcb',
  title = 'title',
  typeOfStudyLaw = 'typeOfStudyLaw',
  numberCentersAllocatedByPromoter = 'numberCentersAllocatedByPromoter',
  arc1 = 'arc1',
  principalInvestigators = 'principalInvestigars'
}

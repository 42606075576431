import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {ReferenceAct} from '../models/reference-act';
import {SelectItem} from 'primeng/api';
import {ReferenceActService} from '../services/reference-act.service';
import {SharedService} from '../../shared/services/shared.service';
import {FormComponent} from '../../shared/component/form/form.component';
import {CounterpartCategory} from '../models/counterpart-category';
import {Data} from '../../shared/entity/data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {TrialService} from 'src/app/trial/services/trial.service';
import {ReferenceFixedCostService} from '../services/reference-fixed-cost.service';
import { PropertyEnum } from 'src/app/dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-reference-act-form',
  templateUrl: './reference-act-form.component.html',
  styleUrls: ['./reference-act-form.component.css']
})
export class ReferenceActFormComponent extends FormComponent<ReferenceAct> implements OnInit {

  target = DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_ACTS_ADD_FORM;
  config: FormConfig = new FormConfig();

  constructor(
    private referenceActService: ReferenceActService,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService,
    private trialService: TrialService,
    private fixedCostReferenceService: ReferenceFixedCostService
  ) {
    super();
  }
  noYesOptions: SelectItem[];
  referenceAct: ReferenceAct = new ReferenceAct();
  actCategory: CounterpartCategory = new CounterpartCategory();
  oldReferenceAct: ReferenceAct = new ReferenceAct();
  dialogReferenceActHeader: string;
  isNumericSecondInternalAccountEnabled = false;
  isNumericFirstSegmentEnabled = false;
  yesOrNoOptions: SelectItem[] = Data.yesOrNo;
  test: SelectItem;


  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ReferenceActFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
  }


  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isNumericSecondInternalAccountEnabled = this.dynamicConfigService.getProperty(PropertyEnum.NumericSecondInternalAccountEnabled);
      this.isNumericFirstSegmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.NumericFirstSegmentEnabled);
      this.initFormConfig();
    });
  }


  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, AdditionalCostsAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }



  onSubmit() {
    this.submitted = true;
    if (this.addForm.invalid || !this.referenceAct.isValid(this.config)) {
      return;
    }
    if (this.referenceAct.actCategoryHhhId) {
      if (!this.referenceAct.hhhId) {
        this.referenceActService.addReferenceAct(this.referenceAct).subscribe(res => {
            this.callback(res);
            this.sharedService.showSuccess();
            this.sharedService.setInformationInLocalStorage('Grille budgétaire: Grille des actes', 'Ajouter', this.referenceAct.description);
            this.display = false;
          }, error => {
            this.sharedService.showFailure();
            console.error('An error occurred when trying to add a new ReferenceAct.' + error);
            this.display = false;
          }
        );
      } else {
        this.referenceActService.updateReferenceAct(this.referenceAct).subscribe(res => {
            this.callback(res);
            this.sharedService.showSuccess();
            this.sharedService.setInformationInLocalStorage('Grille budgétaire: Grille des actes', 'Éditer', this.referenceAct.description);
            this.display = false;
          }, error => {
            this.sharedService.showFailure();
            console.error('An error occurred when updating Reference Act.' + error);
            this.display = false;
          }
        );
      }
    }
  }

  onCancelClick() {
    this.display = false;
    this.initReferenceAct(this.referenceAct, this.oldReferenceAct);
  }

  initReferenceAct(referenceAct: ReferenceAct, oldReferenceAct: ReferenceAct) {
    referenceAct.contractType = oldReferenceAct.contractType;
    referenceAct.contractTypeHhhId = oldReferenceAct.contractTypeHhhId;
    referenceAct.typeHhhId = oldReferenceAct.typeHhhId;
    referenceAct.actCategoryName = oldReferenceAct.actCategoryName;
    referenceAct.actCategoryHhhId = oldReferenceAct.actCategoryHhhId;
    referenceAct.description = oldReferenceAct.description;
    referenceAct.onPrescription = oldReferenceAct.onPrescription;
    referenceAct.externalFromCentre = oldReferenceAct.externalFromCentre;
    referenceAct.invoiceable = oldReferenceAct.invoiceable;
    referenceAct.pricePerUnit = oldReferenceAct.pricePerUnit;
    referenceAct.internalAccountNumber = oldReferenceAct.internalAccountNumber;
    referenceAct.secondInternalAccountNumber = oldReferenceAct.secondInternalAccountNumber;
    referenceAct.firstSegment = oldReferenceAct.firstSegment;
    referenceAct.secondSegment = oldReferenceAct.secondSegment;
    referenceAct.nomenclature = oldReferenceAct.nomenclature;
    referenceAct.checked = oldReferenceAct.checked;
    referenceAct.publicCommentCount = oldReferenceAct.publicCommentCount;
    referenceAct.freeSS = oldReferenceAct.freeSS;
    referenceAct.optional = oldReferenceAct.optional;
  }

  onAdd(callback: (act: ReferenceAct) => void) {
    this.callback = callback;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

}

import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'ih-config-detail',
  templateUrl: './config-detail.component.html',
  styleUrls: ['./config-detail.component.css']
})
export class ConfigDetailComponent {

  _selectedConfig: any;

  @Input() set selectedConfig(v: any) {
    if (v === undefined) {
      return;
    }
    this._selectedConfig = v;
    if (v.title === 'global-config') {
      this.selectComponent(this._selectedConfig.components[0]);
      this.isGlobalConfigSelected = true;
    }
    this.selectComponent(this._selectedConfig.components[0]);
  }

  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();

  selectedComponent: { name: string, target: string, type: string }[] = [];
  isGlobalConfigSelected = false;
  hasSubComponents = false;
  name: string;

  constructor(private _cdr: ChangeDetectorRef) {
  }

  selectComponent(component: any) {
    this.selectedComponent = [];
    this.name = component.name;
    if (component.components) {
      this.hasSubComponents = true;
      component.components.forEach(c => {
        this.selectedComponent.push(
            {
              name: c.name,
              target: c.target,
              type: this.targetToType(c.target)
            }
        );
      });
      this._cdr.detectChanges();
    } else {
      this.hasSubComponents = false;
      this.selectedComponent.push(
          {
            name: component.name,
            target: component.target,
            type: this.targetToType(component.target)
          }
      );
      this._cdr.detectChanges();
    }
  }

  targetToType(target: string): string {
    const index = target.lastIndexOf('-');
    return index > -1 ? target.substring(index + 1) : undefined;
  }

  showTabView(): boolean {
    return this.selectedComponent && this.selectedComponent.length > 1;
  }

  destroy(): void {
    this.name = undefined;
    this.selectedComponent = [];
    this.isGlobalConfigSelected = false;
  }
}

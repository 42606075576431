import {Component} from '@angular/core';

@Component({
  selector: 'ih-human-resource-icon',
  templateUrl: './human-resource-icon.component.html',
  styleUrls: ['./human-resource-icon.component.css']
})
export class HumanResourceIconComponent {

  constructor() {}

}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {InvoiceState} from '../invoice-fixed-costs/models/invoice-state';
import {InvoiceInformation} from '../invoice-information/models/invoice-information';
import {FR} from '../../shared/entity/calendar-language';
import {DataService} from '../../shared/services/data-service';
import {InvoiceService} from '../services/invoice.service';
import {InvoiceInformationService} from '../invoice-information/services/invoice-information.service';
import {Router} from '@angular/router';
import {AdditionalCostState} from '../../additional-cost-state.enum';
import {NgForm} from '@angular/forms';
import {InvoiceAppData} from '../Invoice-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {AppData} from '../../helpers/app-data';
import {TranslateService} from '@ngx-translate/core';
import {StaticInvoiceService} from '../services/static-invoice.service';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {DataCommentInfo} from '../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../data-comment/entity/DataCommentEvent';

@Component({
  selector: 'ih-static-invoice-information',
  templateUrl: './static-invoice-information.component.html',
  styleUrls: ['./static-invoice-information.component.css']
})
export class StaticInvoiceInformationComponent implements OnInit {

  target = DynamicDefinitionEnum.INVOICE_STATIC_INFORMATION_FORM;
  config: FormConfig = new FormConfig();

  displayCounterParties: boolean;


  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('invoiceInformationForm') invoiceInformationForm: NgForm;

  invoiceHhhId: number;
  invoiceStatus: InvoiceState[] = [];
  invoiceInformation: InvoiceInformation;
  submitted = false;
  public locale = FR;

  constructor(
    private dataService: DataService,
    private staticInvoiceService: StaticInvoiceService,
    private invoiceService: InvoiceService,
    private invoiceInformationService: InvoiceInformationService,
    private cfr: ComponentFactoryResolver,
    private router: Router,
    private dynamicConfigService: DynamicConfigService,
    private translate: TranslateService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.invoiceHhhId = url.queryParams.invoiceHhhId;
      console.error(url);
    }
  }

  get additionalCostState() {
    return AdditionalCostState;
  }

  ngOnInit() {
    this.initProperties();
    this.dataService.setExportPageModule('src/app/invoice/invoice-information/invoice-information.component');
    this.dataService.setInvoiceTypeExportPage('static-invoice');
    this.invoiceInformationService.setInvoiceId(this.invoiceHhhId);
    this.getInvoiceInformation();
    this.getInvoiceStates();
    this.staticInvoiceService.updateInvoiceHhhId(this.invoiceHhhId);
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.displayCounterParties = this.dynamicConfigService.getProperty(PropertyEnum.counterPartEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, InvoiceAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  getInvoiceInformation() {
    this.invoiceInformation = new InvoiceInformation();
    this.invoiceInformationService.getInvoiceInformation(this.invoiceHhhId).subscribe(res => {
      this.invoiceInformation = res;
      this.dataService.setInvoiceState(this.invoiceInformation.invoiceState.code);
    });
  }

  getInvoiceStates() {
    this.invoiceService.getInvoiceStates().subscribe(res => {
      this.invoiceStatus = res;
    });
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.invoiceInformation || this.invoiceInformation.hhhId === undefined) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.Invoice, this.invoiceInformation.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }


  displayTrial() {
    this.router.navigate(
      ['/trial-details/trial-identification-information-container/trial-identification-information'],
      {queryParams: {hhhId: this.invoiceInformation.trialHhhId}}
    ).then();
  }

  updateInvoiceInformation() {
    // TODO : implement logic
    this.submitted = true;
    this.invoiceInformation.hhhId = this.invoiceHhhId;
    if (this.invoiceInformationForm.invalid) {
      return;
    }
    if (!(this.invoiceInformation.isValid(this.config))) {
      return;
    }
  }

  exportStaticInvoice(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.invoiceHhhId)
      .type('static-invoice')
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .build();
    this.invoiceService.exportSingleInvoice(request, this.translate.currentLang).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.invoiceInformation.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

}

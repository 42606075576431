export class TrialReportFilter {
  public fromDate: Date;
  public toDate: Date;
  public phaseHhhIds: number[];
  public specialityHhhIds: number[];
  public serviceHhhIds: number[];
  public illnessStateHhhIds: number[];
  public launchDateInter: string;
  public trialModeHhhId: number;
  public humanResourceHhhIds: number[];
  public siteHhhIds: number[];
  public moleculeStudied: string;
  public minimalAge: string;
  public maximalAge: string;
  public providerHhhIds: number[];
  public promoterTypeHhhIds: number[];
  public researchFieldHhhIds: number[];
  public establishmentHhhIds: number[];
  public moleculeStudiedHhhIds: number[];
  public trialCommitteesHhhIds: number[];
}

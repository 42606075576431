import {Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {SharedService} from '../../../shared/services/shared.service';
import {DataComment, DataCommentInfo} from '../../entity/data-comment';
import {DataCommentService} from '../../service/data-comment.service';
import {DataCommentRequestBuilder} from '../../entity/data-comment-request';
import {DataCommentEvent} from '../../entity/DataCommentEvent';

@Component({
  selector: 'ih-data-comment',
  templateUrl: './data-comment.component.html',
  styleUrls: ['./data-comment.component.css']
})
export class DataCommentComponent implements OnInit {

  public displayPublicCommentDialog: boolean;
  public displayPrivateCommentDialog: boolean;
  public comment: DataComment = new DataComment();
  public comments: DataComment[] = [];
  public updating = false;
  public loading: boolean;
  @ViewChild('publicComment') fieldPublicComment: ElementRef;
  @ViewChild('privateComment') fieldPrivateComment: ElementRef;

  callback: (entity: any) => void;


  dataCommentInfo: DataCommentInfo;

  constructor(
      private dataCommentService: DataCommentService,
      private sharedService: SharedService
  ) {
  }

  public static createDataComment(dataCommentInfo: DataCommentInfo, viewRef: ViewContainerRef, cfr: ComponentFactoryResolver): DataCommentComponent {
    const dialogFactory = cfr.resolveComponentFactory(DataCommentComponent);
    const dialogComponentRef = viewRef.createComponent(dialogFactory);
    const component = dialogComponentRef.instance;
    component.dataCommentInfo = dataCommentInfo;
    component.getComments();
    return component;
  }

  ngOnInit() {
  }

  getComments() {
    this.displayPublicCommentDialog = !this.dataCommentInfo.isPrivate;
    this.displayPrivateCommentDialog = this.dataCommentInfo.isPrivate;
    const request =  new DataCommentRequestBuilder()
      .setAttachedTo(this.dataCommentInfo.attachedTo)
      .setAttachedToId(this.dataCommentInfo.attachedToId)
      .setAttachedToField(this.dataCommentInfo.attachedToField)
      .setPrivateComment(this.dataCommentInfo.isPrivate)
      .build();
    this.dataCommentService.loadList(request
        ).subscribe(
        (response: DataComment[]) => {
          this.comments = response;
          this.focus();
        },
        (error) => {
          console.error(error);
        });
  }

  save() {
    if (!this.comment.content) {
      this.focus();
      this.displayPublicCommentDialog = false;
      return;
    }
    this.loading = true;
    if (!this.comment.hhhId) {
      this.comment.attachedTo = this.dataCommentInfo.attachedTo;
      this.comment.attachedToId = this.dataCommentInfo.attachedToId;
      this.comment.attachedToField = this.dataCommentInfo.attachedToField;
      this.comment.privateComment = this.dataCommentInfo.isPrivate;
      this.dataCommentService.add(this.comment).subscribe(
          res => {
            this.comment = res;
            this.comments.unshift(res);
            this.focus();
            this.comment = new DataComment();
            this.sharedService.showSuccess();
            this.updating = false;
            this.loading = false;
            if (!res.privateComment) {
              this.callback(new DataCommentEvent(res.attachedToId, res.attachedToField, this.comments.length));
            }
          },
          (error) => {
            console.error(error);
            this.updating = false;
            this.loading = false;
          }
      );
      this.displayPublicCommentDialog = false;
      this.displayPrivateCommentDialog = false;
    } else {
      this.updating = false;
      this.dataCommentService.update(this.comment.hhhId, this.comment.content).subscribe(
          () => {
            const index = this.comments.findIndex((c: DataComment) => c.hhhId === this.comment.hhhId);
            this.comments[index].content = this.comment.content;
            this.sharedService.showSuccess();
            this.comment = new DataComment();
            this.focus();
            this.updating = false;
            this.loading = false;
            if (!this.comments[index].privateComment) {
              this.callback(new DataCommentEvent(this.comments[index].attachedToId, this.comments[index].attachedToField, this.comments.length));
            }
          }, (error) => {
            console.error(error);
            this.updating = false;
            this.loading = false;
          }
      );
      this.displayPublicCommentDialog = false;
      this.displayPrivateCommentDialog = false;
    }
  }

  edit(c: DataComment) {
    this.updating = true;
    this.comment = c;
    this.focus();
  }

  delete(id: number) {
    this.dataCommentService.delete(id).subscribe(() => {
          const index = this.comments.findIndex((c: DataComment) => c.hhhId === id);
          const oldComment = this.comments[index];
          this.comments.splice(index, 1);
          this.sharedService.showSuccess();
          if (!oldComment.privateComment) {
            this.callback(new DataCommentEvent(oldComment.attachedToId, oldComment.attachedToField, this.comments.length));
          }
        },
        error => {
          this.sharedService.showFailure();
          console.error(error);
        });
  }

  focus(): void {
    this.dataCommentInfo.isPrivate ?
        this.fieldPrivateComment.nativeElement.focus() :
        this.fieldPublicComment.nativeElement.focus();
  }

  onSaveOrDelete(callback: (entity: any) => void) {
    this.callback = callback;
  }



}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Data} from '../../shared/entity/data';
import {HumanResourcesService} from '../../human-resources/services/human-resources.service';
import {HumanResource} from '../../human-resources/models/human-resource';
import {User} from '../models/user';
import {UsersService} from '../services/users.service';
import {RoleValue} from '../../human-resources/models/role-value';
import {SharedService} from '../../shared/services/shared.service';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {EstablishmentService} from '../../establishment/services/establishment.service';
import {EstablishmentSelection} from '../../shared/entity/establishment-selection';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {HumanResourceFormComponent} from '../../human-resources/human-resource-form/human-resource-form.component';
import {DropdownComponent} from '../../shared/component/dropdown/dropdown.component';
import {SelectItem} from 'primeng/components/common/api';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {HumanResourceAppData} from '../../human-resources/human-resource-app-data';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {DataItemService} from '../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

  target = DynamicDefinitionEnum.ADMIN_NEW_USER_FORM;
  config: FormConfig;

  linkResponsibilityWithRole = false;
  humanResourceMandatory = false;

  @ViewChild('addUserForm') addUserForm: NgForm;
  @ViewChild('addHumanResourceDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  @ViewChild('humanResourceDropdown') humanResourceDropdown: DropdownComponent<any>;

  submitted = false;
  civilities: any;
  active: any;
  humanResources: HumanResource[] = [];
  humanResource: HumanResource = new HumanResource();
  user: User = new User();
  roles: RoleValue[];
  establishmentsFilter: EstablishmentSelection[] = [];
  shownRoles: Array<SelectItem> = new Array<SelectItem>();
  allRoles: Array<SelectItem> = new Array<SelectItem>();
  errorMessage: string;

  constructor(
      private userService: UsersService,
      private sharedService: SharedService,
      private humanResourcesService: HumanResourcesService,
      private router: Router,
      private establishmentService: EstablishmentService,
      private cfr: ComponentFactoryResolver,
      private translateService: InnohealthTranslateService,
      private dataItemService: DataItemService,
      private dynamicConfigService: DynamicConfigService
  ) {
  }

  get form() {
    return this.addUserForm.controls;
  }

  ngOnInit() {
    this.user.active = true;
    this.civilities = Data.userTitles;
    this.active = Data.activeOptions;
    this.initProperties();
    this.getHumanResources();
    this.getRoles();
    this.getChildEstablishments();
    this.getAllRoles();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.linkResponsibilityWithRole = this.dynamicConfigService.getProperty(PropertyEnum.linkResponsibilityWithRole);
      this.humanResourceMandatory = this.dynamicConfigService.getProperty(PropertyEnum.humanResourceMandatory);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, HumanResourceAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getHumanResources() {
    this.humanResourcesService.loadAll().subscribe(res => {
      this.humanResources = res;
      this.humanResources.splice(0, 0, new HumanResource());
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while loading human resources.' + error);
    });
  }

  getRoles() {
    this.userService.getRoles().subscribe(roles => {
      this.roles = roles;
      for (const role of this.roles) {
        role.name = this.translateService.getTranslationString('MODULE_USER_ADMIN_ROLE_' + role.name);
      }
      this.roles.splice(0, 0, new RoleValue());
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred while loading roles.' + error);
    });
  }

  private getChildEstablishments() {
    this.establishmentService.getChildEstablishments().subscribe(res => {
      this.establishmentsFilter = res;
      this.establishmentsFilter.splice(0, 0, {hhhId: null, name: ''});

    }, error => {
      console.error('An error occurred while loading child establishments.' + error);
    });
  }

  addUser() {
    this.submitted = true;
    if (this.addUserForm.invalid) {
      return;
    }
    if (!this.isUserFormValid()) {
      return;
    }
    this.userService.isUsernameAvailable(this.user.userName).subscribe(res => {
      if (res.value) {
        this.userService.addUser(this.user).subscribe(
            result => {
              this.sharedService.showSuccess();
              this.router.navigate(['/access-rights'], {queryParams: {id: result.response, type: 'NEW'}}).then();
            }, error => {
              this.sharedService.showFailure(error.error);
              console.error('An error occurred while adding user.' + error);
            }
        );
      } else {
        this.errorMessage = 'USERNAME_NOT_AVAILABLE';
      }
    });
  }

  isUserFormValid(): boolean {
    if (!this.user.roleHhhId || this.user.roleHhhId === 0) {
      this.errorMessage = 'PLEASE_ENTRE_USER_ROLE';
      return false;
    }
    return true;
  }

  showAddDialog() {
  }

  isParent() {
    return this.dynamicConfigService.parent;
  }

  goToAccountList() {
    this.router.navigate(['/account-list']).then();
  }

  createAddHumanResourceDialogComponent() {
    const formComponent = HumanResourceFormComponent.displayFormDialogue(this.dialogContainer, this.cfr);
    formComponent.responsibilitiesPath = 'human-resource/load-responsibilities';
    formComponent.onAdd((humanResource: HumanResource) => {
      const rh = new RoleValue();
      rh.name = humanResource.lastName;
      rh.hhhId = humanResource.hhhId;
      this.humanResourceDropdown.onRefreshDrop();
      this.humanResourceDropdown.value = rh.hhhId;
    });
  }

  updateRoles() {
    if (this.linkResponsibilityWithRole) {
      this.userService.getRolesForResponsibilities(this.user.responsibilitiesHhhId).subscribe(res => {
        this.shownRoles = this.allRoles.filter(r => res.indexOf(r.value) >= 0);
      });
    }
  }

  getAllRoles() {
    this.dataItemService.getItems('role').subscribe(res => {
      this.allRoles = res;
      if (!this.linkResponsibilityWithRole) {
        this.shownRoles = res;
      }
    });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
}

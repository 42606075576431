import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {DataCommentCount} from '../../data-comment/entity/data-comment-count';

export class TrialDisciplineIdentification extends DataCommentCount {
  hhhId: number;
  trialHhhId: number;
  establishmentDepartmentHhhId: number;
  illnessClassification: string;
  interventionalProtocol: string;
  illnessStatesHhhId: number[] = [];
  illnessStateOtherValue: string;
  organFamilyHhhIds: number[] = [];
  organHhhIds: number[] = [];
  organOtherValue: string;
  organList: string[] = [];
  trialTypesHhhId: number[] = [];
  researchTypeHhhId: number;
  researchTypeOtherValue: string;
  researchFieldHhhId: number;
  researchFieldOtherValue: string;
  researchNatureHhhId: number;
  researchNatureOtherValue: string;
  interventionTypesHhhId: number[] = [];
  interventionTypeOtherValue: string;
  metastaticLineHhhId: number[] = [];
  researchObjectHhhIds: number[] = [];
  servicesHhhId: number[] = [];
  minAge: string;
  maxAge: string;
  dmTrial: string;

  init(obj: any): TrialDisciplineIdentification {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  isValid(config: FormConfig, researchTypeOther = false, researchFieldOther = false, researchNatureOther = false): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'service') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.establishmentDepartmentHhhId)) {
          return false;
        }
      } else if (key === 'illnessStates') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.illnessStatesHhhId)) {
          return false;
        }
      } else if (key === 'researchType') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.researchTypeHhhId)) {
          return false;
        }
      } else if (key === 'researchTypeOtherValue') {
        if (researchTypeOther && required && Util.isNullOrUndefinedOrEmpty(this.researchTypeOtherValue)) {
          return false;
        }
      } else if (key === 'minAge') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.minAge)) {
          return false;
        }
      } else if (key === 'maxAge') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.maxAge)) {
          return false;
        }
      } else if (key === 'dmTrial') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.dmTrial)) {
          return false;
        }
      } else if (key === 'trialTypeList') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.trialTypesHhhId)) {
          return false;
        }
      } else if (key === 'researchObject') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.researchObjectHhhIds)) {
          return false;
        }
      } else if (key === 'researchObjectOtherValue') {
        if (researchFieldOther && required && Util.isNullOrUndefinedOrEmpty(this.researchFieldOtherValue)) {
          return false;
        }
      } else if (key === 'researchNature') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.researchNatureHhhId)) {
          return false;
        }
      } else if (key === 'researchNatureOtherValue') {
        if (researchNatureOther && required && Util.isNullOrUndefinedOrEmpty(this.researchNatureOtherValue)) {
          return false;
        }
      } else if (key === 'interventionTypes') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.interventionTypesHhhId)) {
          return false;
        }
      } else if (key === 'metastaticLines') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.metastaticLineHhhId)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'eliminateNumbers'
})
export class EliminateNumbersPipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/\d/g, '');
  }
}


import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VisitAction} from '../../../../../../visit-action';
import {Visit} from '../../../../../entity/visit';
import {VisitUpdate} from '../../../../../entity/inclusion/visit-update';
import {VisitActUpdate} from '../../../../../entity/inclusion/visit-act-update';
import {PropertyEnum} from '../../../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-visit-accordion-tab',
  templateUrl: './visit-accordion-tab.component.html',
  styleUrls: ['./visit-accordion-tab.component.css']
})
export class VisitAccordionTabComponent implements OnInit {

  @Input() visits: Visit[];
  @Input() arms: { label, value }[];
  @Input() sectionTitle: string;
  @Input() isInclusionActive: boolean;
  @Input() additionalVisitsEnabled: boolean;
  @Output() updatedVisitEvent = new EventEmitter<boolean>();
  @Output() pendingUpdatedVisitEvent = new EventEmitter<any>();
  @Output() onVisitChange: EventEmitter<VisitUpdate> = new EventEmitter<VisitUpdate>();
  @Output() onVisitActChange: EventEmitter<VisitActUpdate> = new EventEmitter<VisitActUpdate>();

  isInclusionCalendarSavingButtonApart = false;

  constructor(private dynamicConfigService: DynamicConfigService) {
  }

  ngOnInit() {
    this.initProperties();
  }
  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isInclusionCalendarSavingButtonApart = this.dynamicConfigService.getProperty(PropertyEnum.inclusionCalendarSavingButtonApart);
    });
  }

  updateVisitState(visitAction: VisitAction) {
    this.pendingUpdatedVisitEvent.emit(visitAction);
    if (visitAction.action === 'clone') {
      this.updatedVisitEvent.emit(true);
    } else if (visitAction.action === 'delete' && !this.isInclusionCalendarSavingButtonApart) {
      this.removeElementFromList(this.visits, visitAction.data.hhhId);
    } else if (visitAction.action === 'delete' && this.isInclusionCalendarSavingButtonApart) {
      this.markVisitAsDeleted(this.visits, visitAction.data.hhhId);
    } else if (visitAction.action === 'update') {
      this.updatedVisitEvent.emit(true);
    }
  }

  removeElementFromList(list: any, hhhId) {
    const index = list.findIndex((visit: Visit) => visit.hhhId === hhhId);
    if (index === -1) {
      return -1;
    }
    list.splice(index, 1);
  }

  private markVisitAsDeleted(list: Visit[], hhhId) {
    const index = list.findIndex((visit: Visit) => visit.hhhId === hhhId);
    if (index === -1) {
      return -1;
    }
    list[index].toBeDeleted = true;
  }

  onVisitChangeEvent(visitUpdate: VisitUpdate) {
    this.onVisitChange.emit(visitUpdate);
  }

  onVisitActChangeEvent(visitActUpdate: VisitActUpdate) {
    this.onVisitActChange.emit(visitActUpdate);
  }
}

import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {ArcVisit} from '../models/arc-visit';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {DataService} from '../../shared/services/data-service';
import {WorkloadService} from '../services/workload.service';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {VisitWorkload} from '../models/visit-workload';
import {SharedService} from '../../shared/services/shared.service';
import {FR} from '../../shared/entity/calendar-language';
import {WorkloadGlobalFilter} from '../models/workload-global-filter';
import {Data} from '../../shared/entity/data';
import {ArcVisitWorkloadFilter} from '../models/arc-visit-workload-filter';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {GlobalConfigAppData} from '../../dynamic-config/gloabl-config-app-data';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {HumanResourceGlobalFilter} from '../../human-resources/models/human-resource-global-filter';
import {AppData} from '../../helpers/app-data';
import {ExportTypesEnum} from '../../dynamic-config/exported/data-export/ExportTypesEnum';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';

@Component({
  selector: 'ih-workload',
  templateUrl: './workload.component.html',
  styleUrls: ['./workload.component.css']
})
export class WorkloadComponent extends PaginatorTableComponent<ArcVisit> implements OnInit {

  target = DynamicDefinitionEnum.ADMIN_WORKLOAD_TABLE;
  config: TableConfig;

  headers: DynamicTableHeader[] = [];
  arcVisitsWorkload: VisitWorkload[];
  visitWorkload = new VisitWorkload();
  filters = new WorkloadGlobalFilter();

  startDate: Date;
  endDate: Date;
  selectedArcs: number[] = [];

  arcVisitWorkloadFilter: ArcVisitWorkloadFilter = new ArcVisitWorkloadFilter();
  locale = FR;

  constructor(
      private workloadService: WorkloadService,
      private translateService: InnohealthTranslateService,
      private sharedService: SharedService,
      private dataService: DataService,
      private cfr: ComponentFactoryResolver,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    this.getArcVisitsWorkload();
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, GlobalConfigAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  buildTableHeaders() {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('arcName')
        .filterable()
        .filterType(FilterType.IH_DROPDOWN)
        .filter(this.filters.arcName)
        .sortable()
        .type('clinical-research-assistant')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('trialName')
        .filterable()
        .filterType(FilterType.IH_DROPDOWN)
        .type('trial')
        .sortable()
        .sortField('trialName')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('patientName')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .filter(this.filters.patientName)
        .sortable()
        .sortField('patientName')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('expectedVisitDate')
        .filterable()
        .filterType(FilterType.CALENDAR)
        .filter(this.filters.expectedVisitDate)
        .withDateFormatter()
        .sortable()
        .sortField('expectedVisitDate')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('realVisitDate')
        .filterable()
        .filterType(FilterType.CALENDAR)
        .filter(this.filters.realVisitDate)
        .withDateFormatter()
        .sortable()
        .sortField('visit.realVisitDate')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('visitName')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .filter(this.filters.visitName)
        .sortable()
        .sortField('v.visitName')
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('visitType')
        .filterable()
        .filterType(FilterType.IH_DROPDOWN)
        .options(Data.VisitTypes)
        .filter(this.filters.visitType)
        .displayContent((data: ArcVisit) => {
          return this.translateService.getTranslationString('MODULE_TRIALS_ACTS_VISIT_' + data.visitType);
        })
        .sortable()
        .sortField('v.visitType')
        .build());
    this.headers = this.config.buildTable(availableHeaders);
    localStorage.setItem(this.target, this.headers.map(header => header.field).join(','));
  }

  getList() {
    const workloadGlobalFilter = new WorkloadGlobalFilter();
    Object.assign(workloadGlobalFilter, DynamicTableHeader.buildFilterData(this.headers));
    workloadGlobalFilter.startDate = this.startDate;
    workloadGlobalFilter.endDate = this.endDate;
    this.workloadService.loadArcVisits(this.page, this.limit, this.sortBy, this.orderBy, workloadGlobalFilter).subscribe(res => {
      this.values = res.content;
      this.totalNumber = res.totalElements;
    }, error1 => console.error(error1));
  }

  getArcVisitsWorkload() {
    this.workloadService.getArcVisitWorkload(this.arcVisitWorkloadFilter).subscribe(res => {
      this.arcVisitsWorkload = [];
      this.visitWorkload = res;
      this.arcVisitsWorkload.push(res);
    });
  }

  editWorkloadDuration(visitWorkload: VisitWorkload) {
    this.visitWorkload = visitWorkload;
    this.workloadService.editVisitDuration(visitWorkload).subscribe(res => {

    });
  }

  calculateTotal(): number {
    return this.visitWorkload.beforeInclusionVisitNumber * this.visitWorkload.visitDuration.beforeInclusionAverageDuration +
        this.visitWorkload.inInclusionVisitNumber * this.visitWorkload.visitDuration.inInclusionAverageDuration +
        this.visitWorkload.afterInclusionVisitNumber * this.visitWorkload.visitDuration.afterInclusionAverageDuration;
  }

  exportWorkloadList(event: any): void {
    const request = new ExportRequestBuilder<WorkloadGlobalFilter>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .sortBy(this.sortBy)
      .orderBy(this.orderBy)
      .filters(this.filters)
      .build();

    this.workloadService.exportWorkload(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }

}

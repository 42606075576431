export class ProviderGlobalFilter {
  providerTypeValue: string;
  name: string;
  totalContactableMemberNames: string;

  constructor() {
    this.providerTypeValue = '';
    this.name = '';
    this.totalContactableMemberNames = '';
  }
}

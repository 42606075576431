import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {TrialIdentificationInformationGeneral} from '../models/trial-identification-information-general';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrialIdentificationInformationGeneralService {

  private isCSETNumberInvalid = false;
  private isSubmitted: Subject<boolean> = new BehaviorSubject<boolean>(null);
  submitted = this.isSubmitted.asObservable();
  private isCsetEnabled: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private isLocalIdentifierInvalid: Subject<boolean> = new BehaviorSubject<boolean>(null);
  trialIdentificationInfo: Subject<TrialIdentificationInformationGeneral> = new BehaviorSubject<TrialIdentificationInformationGeneral>(null);
  trialIdentificationInfoValue = this.trialIdentificationInfo.asObservable();
  host = new Properties().host + '/trial-identification-information-general';

  constructor(private httpClient: HttpClient) {
  }

  setTrialIdentificationInfo(value) {
    this.trialIdentificationInfo.next(value);
  }

  getIsCSETNumberInvalid() {
    return this.isCSETNumberInvalid;
  }

  changeSubmit(isSubmit: boolean) {
    this.isSubmitted.next(isSubmit);
  }

  changeIsCsetEnabled(isCsetEnabled: boolean) {
    this.isCsetEnabled.next(isCsetEnabled);
  }

  getLocalIdentifier() {
    return this.isLocalIdentifierInvalid;
  }

  addIsLocalIdentifierInvalid(isLocalIdentifierInvalid: boolean) {
    this.isLocalIdentifierInvalid.next(isLocalIdentifierInvalid);
  }

  load(trialId: number): Observable<TrialIdentificationInformationGeneral> {
    return this.httpClient.get<TrialIdentificationInformationGeneral>(this.host + '/load/' + trialId).pipe(
      map(res => {
          return (new TrialIdentificationInformationGeneral()).init(res);
        }
      ),
      catchError(this.handleError));
  }

  saveTrialIdentificationInformation(trialIdentificationInformation: TrialIdentificationInformationGeneral): Observable<any> {
    return this.httpClient.post(this.host + '/save/', trialIdentificationInformation);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

}

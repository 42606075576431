import {Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {PageUnlockConfirmationComponent} from '../page-unlock-confirmation/page-unlock-confirmation.component';
import {TrialPageLockService} from '../trial-page-lock.service';
import {PageLockWebSocketService} from '../page-lock-web-socket-service';
import {PageLockConfirmationComponent} from '../page-lock-confirmation/page-lock-confirmation.component';
import {PageLockPasswordComponent} from '../page-lock-password/page-lock-password.component';
import {Role} from '../../../../login/models/role';
import {TrialService} from '../../../../trial/services/trial.service';
import {Router} from '@angular/router';
import {DynamicConfigService} from '../../../service/dynamic-config.service';
import {PropertyEnum} from '../../../entity/property-enum';
import {StompService} from '../../../../shared/services/stomp.service';

@Component({
  selector: 'ih-page-lock-navbar',
  templateUrl: './page-lock-navbar.component.html',
  styleUrls: ['./page-lock-navbar.component.css']
})
export class PageLockNavbarComponent implements OnInit, OnDestroy {
  @ViewChild('pageLockConfirmationDialog', {read: ViewContainerRef}) pageLockConfirmationContainer: ViewContainerRef;
  @ViewChild('pageLockPasswordDialog', {read: ViewContainerRef}) pageLockPasswordContainer: ViewContainerRef;
  @ViewChild('pageUnlockDialog', {read: ViewContainerRef}) pageUnlockContainer: ViewContainerRef;

  @Input() trialId: number;

  isPageLockEnabled = false;

  webSocketAPI: PageLockWebSocketService;
  greeting: any;
  name: string;
  hasRightToLock: boolean;

  constructor(
      private cfr: ComponentFactoryResolver,
      public trialPageLockService: TrialPageLockService,
      public trialService: TrialService,
      public dynamicConfigService: DynamicConfigService,
      public stompService: StompService,
      private router: Router
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isPageLockEnabled = this.dynamicConfigService.getProperty(PropertyEnum.pageLockEnabled);
      this.trialPageLockService.trialId = this.trialId;
      this.webSocketAPI = new PageLockWebSocketService(new PageLockNavbarComponent(this.cfr, this.trialPageLockService, this.trialService, this.dynamicConfigService, this.stompService, this.router));
      this.webSocketAPI.connect();
      this.hasRightToLock = localStorage.getItem('authenticatedUserRole') === Role.Admin;
      if (this.hasRightToLock) {
        this.getLockHistory();
      }
      this.getIsLocked();
    });
  }

  ngOnDestroy(): void {
    this.webSocketAPI.close();
  }

  unlock() {
    const formComponent = PageUnlockConfirmationComponent.displayUnlockDialog(this.pageUnlockContainer, this.cfr);
    formComponent.trialId = this.trialPageLockService.trialId;
    formComponent.onUnlock(() => {
      this.updateIsLocked();
    });
  }

  lock() {
    const formComponent = PageLockConfirmationComponent.displayFormDialog(this.pageLockConfirmationContainer, this.cfr);
    formComponent.onLock((isLocked: boolean) => {
      const passwordFormComponent = PageLockPasswordComponent.displayPasswordDialog(this.pageLockPasswordContainer, this.cfr);
      passwordFormComponent.trialId = this.trialPageLockService.trialId;
      passwordFormComponent.onPasswordVerified(() => {
        this.updateIsLocked();
      });
    });
  }

  updateIsLocked() {
    this.webSocketAPI.update(this.trialId);
    this.trialPageLockService.isLocked = !this.trialPageLockService.isLocked;
  }

  handleIsLockedUpdate(concernedTrialId) {
    if (this.trialPageLockService.trialId === concernedTrialId) {
      this.getIsLocked();
      this.getLockHistory();
    }
  }

  getIsLocked() {
    if (!this.isPageLockEnabled) {
      this.trialPageLockService.isLocked = false;
      return;
    }
    this.trialPageLockService.getIsLocked().subscribe((res) => {
      this.trialPageLockService.isLocked = res.value;
    });
  }

  private getLockHistory() {
    this.trialPageLockService.getHistory().subscribe((res) => {
      this.trialPageLockService.history = res;
      this.trialPageLockService.hasHistory = this.trialPageLockService.history.length > 0;
    }, error => {
      console.error(error);
    });
  }
}

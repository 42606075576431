export class DataCommentRequest {
  commentId: number = undefined;
  attachedTo: string = undefined;
  attachedToId: number = undefined;
  attachedToField: string = undefined;
  content: string = undefined;
  privateComment: boolean = undefined;

  constructor() {
  }

}

export class DataCommentRequestBuilder {

  _request: DataCommentRequest;

  constructor() {
    this._request = new DataCommentRequest();
  }

  setCommentId(id: number): DataCommentRequestBuilder {
    this._request.commentId = id;
    return this;
  }

  setAttachedTo(entity: string): DataCommentRequestBuilder {
    this._request.attachedTo = entity;
    return this;
  }

  setAttachedToId(attachedToId: number): DataCommentRequestBuilder {
    this._request.attachedToId = attachedToId;
    return this;
  }

  setAttachedToField(field: string): DataCommentRequestBuilder {
    this._request.attachedToField = field;
    return this;
  }

  setContent(content: string): DataCommentRequestBuilder {
    this._request.content = content;
    return this;
  }

  setPrivateComment(privateComment: boolean): DataCommentRequestBuilder {
    this._request.privateComment = privateComment;
    return this;
  }

  build(): DataCommentRequest {
    return this._request;
  }

}

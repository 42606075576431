import {JsonObject, JsonProperty} from 'json2typescript';
import {TableButtonConfig} from './table-button-config';

@JsonObject('DynamicTableDefinitionButton')
export class DynamicTableDefinitionButton {

  @JsonProperty('btnId', String)
  btnId: string = undefined;

  @JsonProperty('config', TableButtonConfig)
  config: TableButtonConfig = new TableButtonConfig();

  displayContent(field: string): string {
    switch (field) {
      case 'btnId':
        return this.btnId;
      default:
        return this.config[field];
    }
  }
}

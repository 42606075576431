import {Component, Input, OnInit} from '@angular/core';
import {DynamicTableDefinition} from '../../entity/dynamic-table-definition';
import {PropertyEnum} from '../../entity/property-enum';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {Util} from '../../../helpers/util';

@Component({
  selector: 'ih-table-preview',
  templateUrl: './table-preview.component.html',
  styleUrls: ['./table-preview.component.css']
})
export class TablePreviewComponent implements OnInit {

  @Input() tableDefinition: DynamicTableDefinition;

  hasPrivatePostIt: boolean;
  value = [1];

  constructor(private dynamicConfigService: DynamicConfigService) {
  }

  ngOnInit() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.hasPrivatePostIt = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled);
    });
  }

  displayBtn(btnId: string): boolean {
    if (this.tableDefinition) {
      const button = this.tableDefinition.buttons.find(value => value.btnId === btnId);
      if (Util.isNullOrUndefinedOrEmpty(button)) {
        return false;
      }
      return this.tableDefinition.buttons.find(value => value.btnId === btnId).config.display;
    }
    return false;
  }
}

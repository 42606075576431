import {Component, Input} from '@angular/core';

@Component({
  selector: 'ih-trial-discipline-icon',
  templateUrl: './trial-discipline-icon.component.html',
  styleUrls: ['./trial-discipline-icon.component.css']
})
export class TrialDisciplineIconComponent {

  @Input() title: string;

  constructor() { }

}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {TrialIdentificationInformationGeneral} from '../models/trial-identification-information-general';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {TrialInformation} from '../models/trial-information';
import {Arm} from '../entity/inclusion/arm';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {catchError, map} from 'rxjs/operators';
import {DetailedHumanResource} from '../../human-resources/models/detailed-human-resource';

@Injectable({
  providedIn: 'root'
})
export class TrialInformationService {

  trialIdentificationInfo: Subject<TrialIdentificationInformationGeneral> = new BehaviorSubject<TrialIdentificationInformationGeneral>(null);
  trialIdentificationInfoValue = this.trialIdentificationInfo.asObservable();
  host = new Properties().host + '/trial-information';

  constructor(private httpClient: HttpClient) {
  }

  setTrialIdentificationInfo(value) {
    this.trialIdentificationInfo.next(value);
  }

  getTrialIdentification(hhhId: number, trialSiteLiaisonHhhId?: number): Observable<TrialInformation> {
    const params = hhhId + '/' + (trialSiteLiaisonHhhId || 0);
    return this.httpClient.get<TrialInformation>(this.host + '/load/' + params)
        .pipe(
            map(res => {
                  return (new TrialInformation()).init(res);
                }
            ),
            catchError(this.handleError));
  }

  updateIdentificationInformation(trialIdentification: TrialInformation): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update', trialIdentification);
  }

  exportIdentificationInformation(request: ExportRequest<any>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {MinimizedTrialProtocol} from '../models/minimized-trial-protocol';
import {TrialAdministrativeComopInformation} from '../models/trial-administrative-comop-information';

@Injectable({
  providedIn: 'root'
})
export class TrialAdministrativeComopInformationService {

  private host = new Properties().host + '/trial-administrative-comop';

  constructor(private httpClient: HttpClient) {
  }

  load(trialId: number, protocolId: number): Observable<TrialAdministrativeComopInformation> {
    if (protocolId === undefined) {
      protocolId = -1;
    }
    return this.httpClient.get<TrialAdministrativeComopInformation>(this.host + '/load/' + trialId + '/' + protocolId).pipe(
      map(res => {
          return (new TrialAdministrativeComopInformation()).init(res);
        }
      ),
      catchError(this.handleError));
  }

  save(comop: TrialAdministrativeComopInformation): Observable<TrialAdministrativeComopInformation> {
    return this.httpClient.post<TrialAdministrativeComopInformation>(this.host + '/save', comop);
  }

  getRelatedProtocolsByTrial(trialId: number): Observable<MinimizedTrialProtocol[]> {
    return this.httpClient.get<MinimizedTrialProtocol[]>(this.host + '/load-used-protocols/' + trialId);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

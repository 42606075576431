import {AdditionalCostOaImage} from './additional-cost-oa-image';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';

export class ReferenceAct {

  public hhhId: number;
  public publicCommentCount: number;
  public contractType: string;
  public contractTypeHhhId: number;
  public typeHhhId: number;
  public typeName: string;
  public actCategoryName: string;
  public actCategoryHhhId: number;
  public description: string;
  public onPrescription: boolean;
  public externalFromCentre: boolean;
  public invoiceable: boolean;
  public pricePerUnit: number;
  public internalAccountNumber: string;
  public secondInternalAccountNumber: string;
  public firstSegment: string;
  public secondSegment: string;
  public temps: string;
  public nomenclature: string;

  public optional: boolean;
  public checked: boolean;
  public freeSS: boolean;

  public additionalCostType: string;

  public buildReferenceAct(additionalCostOaImage: AdditionalCostOaImage) {
    this.hhhId = additionalCostOaImage.hhhId;
    this.contractType = additionalCostOaImage.contractType;
    this.contractTypeHhhId = additionalCostOaImage.contractTypeHhhId;
    this.typeHhhId = additionalCostOaImage.typeHhhId;
    this.typeName = additionalCostOaImage.typeName;
    this.actCategoryName = additionalCostOaImage.actCategoryName;
    this.description = additionalCostOaImage.description;
    this.invoiceable = additionalCostOaImage.invoiceable;
    this.pricePerUnit = additionalCostOaImage.pricePerUnit;
    this.internalAccountNumber = additionalCostOaImage.internalAccountNumber;
    this.secondInternalAccountNumber = additionalCostOaImage.secondInternalAccountNumber;
    this.firstSegment = additionalCostOaImage.firstSegment;
    this.secondSegment = additionalCostOaImage.secondSegment;
    this.additionalCostType = additionalCostOaImage.additionalCostType;
    this.freeSS = additionalCostOaImage.freeSS;
    this.externalFromCentre = additionalCostOaImage.externalFromCentre;
    this.onPrescription = additionalCostOaImage.onPrescription;
    return this;
  }

  init(obj: any): ReferenceAct {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'actCategoryName') {
        if (required && Util.isNullOrUndefined(this.actCategoryHhhId)) {
          return false;
        }
      } else if (key === 'type') {
        if (required && Util.isNullOrUndefined(this.typeHhhId)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }

}

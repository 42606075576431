import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {FR} from '../../shared/entity/calendar-language';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';

@Component({
  selector: 'app-chat-dialog-single-message',
  templateUrl: './chat-dialog-single-message.component.html',
  styleUrls: ['./chat-dialog-single-message.component.css']
})
export class ChatDialogSingleMessageComponent implements OnInit {
  @Input() isConnectedUserTheSender: boolean;
  @Input() message: string;
  @Input() emitter: string;
  @Input() date: Date;
  sentDate: string;
  locale = FR;

  constructor(private datePipe: DatePipe,
              private translateService: InnohealthTranslateService) {
  }

  ngOnInit() {
    this.sentDate = this.datePipe.transform(this.date, 'dd/MM/yyyy HH:mm');
    this.emitter = this.isConnectedUserTheSender
      ? this.translateService.getTranslationString('MODULE_MOBILE_APPLICATION_SEND_BY') + this.emitter
      : '';
  }

}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TheoreticalCalendar} from '../entity/theoretical-calendar/theoretical-calendar';
import {ImportExport} from '../entity/theoretical-calendar/import-export';
import {MinimizedTrialProtocol} from '../models/minimized-trial-protocol';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})

export class TheoreticalCalendarService {
  amendmentsEnabled = false;
  public properties: Properties = new Properties();
  public host = this.properties.host + '/theoretical-calendar';
  public trialHhhId: number;

  constructor(
    private httpClient: HttpClient,
    private dynamicConfigService: DynamicConfigService
  ) {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
    });
  }

  getTheoreticalCalendarByTrial(hhhId: number, trialProtocolHhhId: number, fromSyntheticCalendar: boolean): Observable<TheoreticalCalendar> {
    return this.httpClient.post<TheoreticalCalendar>(this.host + '/load/' + hhhId , {trialProtocolHhhId, fromSyntheticCalendar});
  }

  importExportCalendar(importExport: ImportExport): Observable<ImportExport> {
    return this.httpClient.post<ImportExport>(this.host + '/importExport', importExport);
  }

  updateAmendmentVersion(updateType: string, theoreticalCalendar: TheoreticalCalendar) {
    return this.httpClient.post(this.host + '/update-amendment/' + updateType, theoreticalCalendar);
  }

  updateTheoreticalCalendarByProtocolHhhId(theoreticalCalendar: TheoreticalCalendar, trialHhhId: number, updateType?: string) {
    const obj = {trialHhhId, trialProtocolHhhId: theoreticalCalendar.trialProtocolHhhId, updateType, theoreticalCalendar};
    return this.httpClient.post(this.host + '/update-by-protocol', obj);
  }

  updateTheoreticalCalendarIVRS(active: boolean, theoreticalCalendarHhhId: number) {
    return this.httpClient.post(this.host + '/update/ivrs', {theoreticalCalendarHhhId, active});
  }

  exportTheoreticalCalendar(request: ExportRequest<void>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request);
  }

  getAmendmentByTrialHhhId(trialHhhId: number): Observable<string> {
    const options = {responseType: 'text' as 'text'};
    return this.httpClient.get(this.host + '/amendment/' + trialHhhId , options);
  }

  getRelatedProtocolsByTrial(trialId: number): Observable<MinimizedTrialProtocol[]> {
    return this.httpClient.get<MinimizedTrialProtocol[]>(this.host + '/used-protocols/' + trialId);
  }

  updateTheoreticalCalendarIWRS(active: boolean, theoreticalCalendarHhhId: number) {
    return this.httpClient.post(this.host + '/update/iwrs', {theoreticalCalendarHhhId, active});
  }
}

import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MinimizedTrialProtocol} from '../../../models/minimized-trial-protocol';
import {SelectItem} from 'primeng/api';
import {TrialMethodologyIdentificationInformation} from '../../../models/trial-methodology-identification-information';
import {Data} from '../../../../shared/entity/data';
import {TherapeuticClassForDrugsService} from '../../../services/therapeutic-class-for-drugs.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {TrialProtocolService} from '../../../services/trial-protocol.service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {TrialTreatmentMethodologyService} from '../../../services/trial-treatment-methodology.service';
import {TrialObservablesService} from '../../../services/trial-observables.service';
import {Router} from '@angular/router';
import {TrialService} from '../../../services/trial.service';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {isNullOrUndefined} from 'util';
import {ChooseProtocolVersionComponent} from '../../modals/choose-protocol-version/choose-protocol-version.component';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';
import {TrialInformationGenericComponent} from '../trial-information-generic/trial-information-generic.component';
import {Util} from '../../../../helpers/util';

@Component({
  selector: 'ih-trial-treatment-methodologie',
  templateUrl: './trial-treatment-methodologie.component.html',
  styleUrls: ['./trial-treatment-methodologie.component.css']
})
export class TrialTreatmentMethodologieComponent extends TrialInformationGenericComponent<TrialMethodologyIdentificationInformation> implements OnInit, AfterContentChecked {

  target = DynamicDefinitionEnum.TRIAL_TREATMENT_METHODOLOGIE_FORM;
  config: FormConfig = new FormConfig();
  isAmendmentsEnabled = false;
  maxAgeEnabled = true;

  @Input() mode: string;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;

  therapeuticClassAndSubClassForDrugs: { label, value }[];
  protocol: MinimizedTrialProtocol = new MinimizedTrialProtocol();
  usedProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  usedProtocolsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  allProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  trialMethodologyIdentificationInformation: TrialMethodologyIdentificationInformation = new TrialMethodologyIdentificationInformation();
  showEmptyProtocolWarning = false;
  isOtherSelected = false;
  researchObjectOther = false;
  researchNatureOther = false;
  technologicalMaturityLevelItems = Data.fromOneToNineItems;
  noYesEmptyOptions = Data.yesOrNoOrNeither;
  trialId: number;
  yesOrNo = Data.yesOrNo;
  yesOrNoOrNull = Data.yesOrNoOrNull;

  isOtherTreatmentMethodologySelected = false;
  isOtherAdministrationRouteSelected = false;
  isOtherTreatmentLineSelected = false;


  selectedTreatmentExprimentalDropdown: number[] = [];
  selectedTreatmentExprimentalMultiSelect: number[] = [];
  selectedTreatmentExprimental: number[] = [];

  submitted = false;
  isPopup = false;
  configReady = new EventEmitter<boolean>(false);
  researchObjectNA: number | null = null;
  trialTechniqueTypesNA: number | null = null;
  therapeuticClassForDrugsListNA: number;
  otherTreatmentExperimentalNA: number | null = null;
  targetOfTreatmentExperimentalNA: number | null = null;
  researchNatureNA: number | null = null;

  constructor(
      private cfr: ComponentFactoryResolver,
      private therapeuticClassForDrugsService: TherapeuticClassForDrugsService,
      private sharedService: SharedService, private trialProtocolService: TrialProtocolService,
      private canDeactivateService: CanDeactivateService,
      private trialTreatmentMethodologyService: TrialTreatmentMethodologyService,
      private trialObservablesService: TrialObservablesService,
      private router: Router,
      public trialService: TrialService,
      public trialPageLockService: TrialPageLockService,
      private dynamicConfigService: DynamicConfigService,
      private _cdr: ChangeDetectorRef
  ) {
    super(new TrialMethodologyIdentificationInformation());
    this.canDeactivateService.canBeDeactivated = true;
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams && url.queryParams.hhhId) {
      this.trialId = url.queryParams.hhhId;
      this.mode = 'edit';
    }
  }

  ngOnInit() {
    this.initProperties();
    if (!this.trialId) {
      super.getUnsavedTrialInformationGeneral();
      this.trialMethodologyIdentificationInformation = this.trialInformation;
    }
    this.getTherapeuticClassAndSubClassForDrugs();
    this.getNAValues();
  }

  ngAfterContentChecked() {
    this._cdr.detectChanges();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAmendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      if (this.isAmendmentsEnabled) {
        this.getUsedProtocols();
        this.getAllProtocols();
        this.getBlind();
      } else {
        this.getTreatmentMethodologyByProtocol();
      }
      this._cdr.detectChanges();
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
        config => {
          this.config = config;
          this.maxAgeEnabled = this.displayField('maxAge');
          this.configReady.emit(true);
        }
    );
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  checkIfLastProtocolExists() {
    this.showEmptyProtocolWarning = this.trialProtocolService.isLastProtocolNotUsed(this.allProtocols, this.usedProtocols);
  }

  displayDataCommentDialog(event: { attachedToField: string, isPrivate: boolean }) {
    if (!this.trialMethodologyIdentificationInformation.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialMethodology, this.trialMethodologyIdentificationInformation.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.trialMethodologyIdentificationInformation.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  initProtocolToLatest() {
    this.protocol = this.usedProtocols[this.usedProtocols.length - 1];
  }

  getUsedProtocols(targetProtocol?: MinimizedTrialProtocol) {
    this.trialTreatmentMethodologyService.getRelatedProtocolsByTrial(this.trialId).subscribe(result => {
      this.usedProtocols = result.sort((a, b) => a.hhhId - b.hhhId);
      this.usedProtocolsSelectItems = [];
      this.usedProtocols.forEach(up => this.usedProtocolsSelectItems.push({
        label: (up.version ? ('Version du protocole : ' + up.version) : ('Version de l\'amendement : ' + up.amendmentVersion)),
        value: up.hhhId
      }));
      this.checkIfLastProtocolExists();
      if (result.length > 0) {
        if (!targetProtocol) {
          this.initProtocolToLatest();
        }
        this.getTreatmentMethodologyByProtocol();
      }

    });
  }

  getAllProtocols() {
    this.trialProtocolService.getTrialProtocolList(this.trialId).subscribe(result => {
      this.allProtocols = result.sort((a, b) => a.hhhId - b.hhhId);
      this.checkIfLastProtocolExists();
    });
  }

  getTreatmentMethodologyByProtocol() {
    if (this.mode === 'add') {
      return;
    }
    this.trialTreatmentMethodologyService.load(this.trialId, this.protocol.hhhId).subscribe(result => {
      this.trialMethodologyIdentificationInformation = result;
    });
  }

  isModeEdit() {
    return this.mode === 'edit';
  }

  getService(): any {
    return this.trialTreatmentMethodologyService;
  }

  getTargetOfTreatment(treatmentPrincipalHhhId: number) {
    if (isNullOrUndefined(treatmentPrincipalHhhId)) {
      this.trialMethodologyIdentificationInformation.targetOfTreatmentExperimentalPrincipalHhhId = [];
    }
    if (!isNullOrUndefined(treatmentPrincipalHhhId)) {
      this.trialTreatmentMethodologyService.getTargetOfTreatmentPrincipalHhhIdByTreatmentPrincipal(treatmentPrincipalHhhId).subscribe(
          res => {
            if (!isNullOrUndefined(res)) {
              this.trialMethodologyIdentificationInformation.targetOfTreatmentExperimentalPrincipalHhhId = [];
              this.trialMethodologyIdentificationInformation.targetOfTreatmentExperimentalPrincipalHhhId.push(res);
            } else {
              return;
            }
            res = null;
          }, error => {
            this.sharedService.showFailure();
            console.error('An error occurred while loading organ list.' + error);
          }
      );
    }
  }

  getTargetOfTreatmentForOtherTreatmentExperimental() {
    if (this.trialMethodologyIdentificationInformation.otherTreatmentExperimentalHhhId.length === 0) {
      this.trialMethodologyIdentificationInformation.targetOfTreatmentExperimentalHhhId = [];
    } else {
      this.trialTreatmentMethodologyService.getTargetOfTreatmentHhhIdByTreatmentPrincipal(this.trialMethodologyIdentificationInformation.otherTreatmentExperimentalHhhId).subscribe(
          res => {
            if (!isNullOrUndefined(res)) {
              this.trialMethodologyIdentificationInformation.targetOfTreatmentExperimentalHhhId = [];
              this.trialMethodologyIdentificationInformation.targetOfTreatmentExperimentalHhhId = res;
            }
            this.trialMethodologyIdentificationInformation.targetOfTreatmentExperimentalHhhId = Object.assign([], this.trialMethodologyIdentificationInformation.targetOfTreatmentExperimentalHhhId);
          }, error => {
            this.sharedService.showFailure();
            console.error('An error occurred while loading organ list.' + error);
          }
      );
    }
  }

  formHasBeenChanged() {
    this.sendNewTrialMethodologyInfo();
    this.canDeactivateService.canBeDeactivated = false;
  }

  onProtocolChange() {
    this.protocol = this.usedProtocols.find(p => p.hhhId === this.protocol.hhhId);
    this.getTreatmentMethodologyByProtocol();
  }

  notifyWhenWantedValueSelected(isSelected: boolean) {
    this.isOtherSelected = isSelected;
  }

  getBlind(){
    this.trialTreatmentMethodologyService.getBlinded(this.trialId).subscribe((res) => {
      this.trialMethodologyIdentificationInformation.blinded=res
      });
  }

  onSave() {
    this.submitted = true;
    if (!(this.trialMethodologyIdentificationInformation.isValid(this.config, this.isOtherTreatmentMethodologySelected, this.isOtherSelected,
        this.isOtherAdministrationRouteSelected, this.isOtherTreatmentLineSelected, this.researchNatureOther, this.researchObjectOther))) {
      return;
    }
    // this.setNumberOfCentresHhhId();
    if (!this.isAmendmentsEnabled) {
      this.trialTreatmentMethodologyService.save(this.trialMethodologyIdentificationInformation).subscribe(() => {
        this.canDeactivateService.canBeDeactivated = true;
        this.trialObservablesService.onTreatmentMethodologySave.emit();
        this.submitted = false;
        this.sharedService.showSuccess();
      }, () => {
        this.sharedService.showFailure();
      });
    } else {
      const componentRef = ChooseProtocolVersionComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
      componentRef.initializeProtocols(this.allProtocols, this.usedProtocols, this.protocol);
      componentRef.disableUpdateCurrentVersion = this.trialMethodologyIdentificationInformation.hhhId == null;
      componentRef.useCurrentProtocol = this.trialMethodologyIdentificationInformation.hhhId != null;
      componentRef.onSave.subscribe((p: MinimizedTrialProtocol) => {
        this.protocol = this.allProtocols.find(tp => tp.hhhId === p.hhhId);
        this.trialMethodologyIdentificationInformation.protocolId = p.hhhId;
        this.trialMethodologyIdentificationInformation.trialId = this.trialId;
        this.trialTreatmentMethodologyService.save(this.trialMethodologyIdentificationInformation).subscribe(() => {
          this.canDeactivateService.canBeDeactivated = true;
          this.trialObservablesService.onTreatmentMethodologySave.emit();
          this.getUsedProtocols(this.protocol);
          this.submitted = false;
          this.sharedService.showSuccess();
        }, () => {
          this.sharedService.showFailure();
        });
      });
    }

  }

  notifyWhenResearchNatureOther(otherValue) {
    this.researchNatureOther = otherValue;
  }

  notifyWhenResearchObjectOther(otherValue) {
    this.researchObjectOther = otherValue;
  }

  sendNewTrialMethodologyInfo() {
    this.trialTreatmentMethodologyService.setTrialIdentificationInfo(this.trialMethodologyIdentificationInformation);
  }


  private getTherapeuticClassAndSubClassForDrugs() {
    this.therapeuticClassForDrugsService.getTherapeuticClassAndSubclassForDrugs().subscribe(
        res => {
          this.therapeuticClassAndSubClassForDrugs = res;
          console.log(this.therapeuticClassAndSubClassForDrugs )
        }, error => {
          this.sharedService.showFailure();
          console.error('An error occurred while loading organ list.' + error);
        }
    );
  }

  displaySelectedTreatmentExprimental(selectedValues) {
    this.selectedTreatmentExprimental = selectedValues;
  }

  setNumberOfCentresHhhId() {
    if (typeof this.trialMethodologyIdentificationInformation.numberOfCentresHhhId === 'number') {
      this.trialMethodologyIdentificationInformation.numberOfCentresHhhId = [this.trialMethodologyIdentificationInformation.numberOfCentresHhhId];
    } else if (this.trialMethodologyIdentificationInformation.numberOfCentresHhhId instanceof Array) {
      this.trialMethodologyIdentificationInformation.numberOfCentresHhhId = this.trialMethodologyIdentificationInformation.numberOfCentresHhhId.filter(elt => elt);
    }
  }

  notifyWhenOtherTreatmentMethodologySelected(isOtherTreatmentMethodologySelected: boolean) {
    this.isOtherTreatmentMethodologySelected = isOtherTreatmentMethodologySelected;
  }

  notifyWhenOtherAdministrationRouteSelected(isOtherAdministrationRouteSelected: boolean) {
    this.isOtherAdministrationRouteSelected = isOtherAdministrationRouteSelected;
  }

  notifyWhenOtherTreatmentLineSelected(isOtherTreatmentLineSelected: boolean) {
    this.isOtherTreatmentLineSelected = isOtherTreatmentLineSelected;
  }

  isEditMode() {
    return this.mode === 'edit';
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);

  displaySelectedTreatmentExprimentalDropdown(selectedValues: number[]) {
    this.selectedTreatmentExprimentalDropdown = selectedValues;
    this.updateSelectedTreatmentExprimental();
  }

  displaySelectedTreatmentExprimentalMultiSelect(selectedValues: number[]) {
    this.selectedTreatmentExprimentalMultiSelect = selectedValues;
    this.updateSelectedTreatmentExprimental();
  }

  updateSelectedTreatmentExprimental() {
    this.selectedTreatmentExprimental=[];
    this.selectedTreatmentExprimental = Array.from(
      new Set([
        ...this.selectedTreatmentExprimentalDropdown,
        ...this.selectedTreatmentExprimentalMultiSelect
      ])
    );
  }

  getNAValues(): void {
    this.trialTreatmentMethodologyService.getNAValues().subscribe(
      (data) => {
        this.researchObjectNA = data['researchObjectNA'];
        this.trialTechniqueTypesNA = data['trialTechniqueTypesNA'];
        this.therapeuticClassForDrugsListNA = data['therapeuticClassForDrugsListNA'];
        this.otherTreatmentExperimentalNA = data['otherTreatmentExperimentalNA'];
        this.targetOfTreatmentExperimentalNA = data['targetOfTreatmentExperimentalNA'];
        this.researchNatureNA = data['researchNatureNA'];
      },
      (error) => {
        console.error('Erreur lors de la récupération des valeurs NA:', error);
        this.sharedService.showFailure('Impossible de récupérer les valeurs NA.');
      }
    );
  }

    onResetToNAChanged(isChecked: boolean, nbr: number) {
      if (!isChecked) {
        switch (nbr) {
          case 1:
            this.trialMethodologyIdentificationInformation.researchObjectHhhIds = [];
            break;
          case 2:
            this.trialMethodologyIdentificationInformation.trialTechniqueTypesHhhId = [];
            break;
          case 3:
            this.trialMethodologyIdentificationInformation.therapeuticClassForDrugsList = [];
            break;
          case 4:
            this.trialMethodologyIdentificationInformation.otherTreatmentExperimentalHhhId = [];
            break;
          case 5:
            this.trialMethodologyIdentificationInformation.targetOfTreatmentExperimentalHhhId = [];
            break;
          case 6:
            this.trialMethodologyIdentificationInformation.researchNatureHhhId = null;
            break;

        }
      } else {

        switch (nbr) {
          case 1:
            this.trialMethodologyIdentificationInformation.researchObjectHhhIds = [this.researchObjectNA];
            break;
          case 2:
            this.trialMethodologyIdentificationInformation.trialTechniqueTypesHhhId = [this.trialTechniqueTypesNA];
            break;
          case 3:
            this.trialMethodologyIdentificationInformation.therapeuticClassForDrugsList = [this.therapeuticClassForDrugsListNA.toString(),"THC_37"];
            break;
          case 4:
            this.trialMethodologyIdentificationInformation.otherTreatmentExperimentalHhhId = [this.otherTreatmentExperimentalNA];
            break;
          case 5:
            this.trialMethodologyIdentificationInformation.targetOfTreatmentExperimentalHhhId = [this.targetOfTreatmentExperimentalNA];
            break;
          case 6:
            this.trialMethodologyIdentificationInformation.researchNatureHhhId = this.researchNatureNA;
            break;

        }
      }
    }


}

import {AfterViewChecked, ChangeDetectorRef, Component, Inject, InjectionToken, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {DataService} from '../../../shared/services/data-service';
import {TranslateService} from '@ngx-translate/core';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {InvoiceService} from '../../../invoice/services/invoice.service';
import {NavigationEnd, Router} from '@angular/router';
import {
  ACCOUNT_MANAGEMENT_EXPORT,
  ACCOUNT_MANAGEMENT_READ_WRITE,
  ACT_CATEGORY_EXPORT,
  ACT_GRID_EXPORT,
  AUTHENTICATED_USER,
  AuthenticationService,
  COUNTER_PART_CATEGORY_EXPORT,
  COUNTER_PART_REFERENCE_EXPORT,
  FIXED_COST_CATEGORY_EXPORT,
  FIXED_COST_EXPORT,
  HR_EXPORT,
  INVOICE_EXPORT,
  OPERATIONAL_ACT_EXPORT,
  PATIENT_EXPORT,
  PHARMACY_EXPORT,
  PROVIDER_EXPORT,
  TOKEN,
  TRIAL_EXPORT
} from '../../../login/services/authentication.service';
import {Properties} from '../../../helpers/properties';
import {LabelValue} from '../../../shared/entity/label-value';
import {Permission} from '../../../helpers/permission';
import {Role} from '../../../login/models/role';
import {Location as Loc} from '@angular/common';
import {DynamicDefinitionEnum} from '../../entity/dynamic-definition-enum';
import {PropertyEnum} from '../../entity/property-enum';
import {TrialAppData} from '../../../trial/trial-app-data';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {ContainerConfig} from '../../entity/container-config';
import {HeartbeatService} from '../../../heartbeat.service';

export const LOCATION_TOKEN = new InjectionToken<Location>('Window location object');

declare var $: any;

@Component({
  providers: [
    {provide: LOCATION_TOKEN, useValue: window.location}
  ],
  selector: 'ih-page-top',
  templateUrl: './page-top.component.html',
  styleUrls: ['./page-top.component.css']
})
export class PageTopComponent implements OnInit, AfterViewChecked {

  target = DynamicDefinitionEnum.PAGE_HEADER_CONTAINER;
  config: ContainerConfig = new ContainerConfig();
  isMultiSiteEnabled = false;
  isUseIGRTutorialVideosEnabled = false;

  downloadDialogueVisible = false;
  isDownloadButtonVisible = false;
  host = new Properties().host;
  items: MenuItem[] = [];
  exportOnePageVisibility: boolean;
  selectedSite = '-1';
  exportAllListVisibility: boolean;
  sites: LabelValue[] = [];
  option = 'all';
  exportAllTrialVisibility = false;
  exportAllPatientVisibility = false;
  exportInvoicePageVisibility = false;
  invoiceHhhId: number;
  actualRoute = '';
  currentPath: string;
  isModuleExportable = false;
  private readonly userRole: string;
  exportAllPharmacyVisibility = true;
  exporttrackinTableVisibility: boolean;
  pdfFilePath: string;
  showPdfReader = false;
  exportEstablishmentContacts: boolean;
  establishmentHhhId: number;

  constructor(
      private authService: AuthenticationService,
      @Inject(LOCATION_TOKEN) private location: Location,
      private translate: TranslateService,
      private translateService: InnohealthTranslateService,
      private dataService: DataService,
      private invoiceService: InvoiceService,
      private _loc: Loc,
      private router: Router,
      private _cdr: ChangeDetectorRef,
      private dynamicConfigService: DynamicConfigService,
      private heartbeatService: HeartbeatService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.invoiceHhhId = url.queryParams.invoiceHhhId;
    }
    this.userRole = localStorage.getItem('authenticatedUserRole');
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const currentUrl = val.urlAfterRedirects || val.url;
        if (currentUrl.indexOf('?') > 0) {
          this.actualRoute = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.indexOf('?'));
          console.log('route = ', this.actualRoute)
        } else {
          this.actualRoute = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.length);
          console.log('route = ', this.actualRoute)
        }
      }
    });
  }

  ngOnInit() {
    this.initProperties();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isMultiSiteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
      this.isUseIGRTutorialVideosEnabled = this.dynamicConfigService.getProperty(PropertyEnum.useIGRTutorialVideosEnabled);
      console.log('isUseIGRTutorialVideosEnabled = ', this.isUseIGRTutorialVideosEnabled)
    });
    this.initConfig();
  }

  initConfig(): void {
    this.dynamicConfigService.getContainerConfig(this.target, TrialAppData.containerConfig).subscribe(
        config => {
          this.config = config;
          $('.fr img').addClass('active-fr');
          this.translate.stream('UI_LOGIN_IDENTIFIER').subscribe(() => {
            this.setMenuItems(this.userRole === 'ADMIN' || this.userRole === 'EDITOR' || this.userRole===Role.Manager);
          });
          this.invoiceService.invoiceHhhId.subscribe(res => {
            this.invoiceHhhId = res;
          });
        }
    );
  }

  ngAfterViewChecked(): void {
    this.currentPath = this.actualRoute.substring(this.actualRoute.lastIndexOf('/') + 1, this.actualRoute.length);
    this.isModuleExportable = (this.currentPath.indexOf('trial') > -1
            && this.actualRoute.indexOf('inclusions-list') < 0
            && this.actualRoute.indexOf('inclusion-details') < 0
            && localStorage.getItem(TRIAL_EXPORT) !== Permission.NO_EXPORT)
        || ((this.currentPath.indexOf('patient-list') > -1
                || this.currentPath.indexOf('patient-details') > -1
                || this.actualRoute.indexOf('inclusions-list') > -1
                || this.actualRoute.indexOf('inclusion-details') > -1
                || this.currentPath.indexOf('theoretical-calendar') > -1
                || this.currentPath.indexOf('tracking-table') > -1)
            && localStorage.getItem(PATIENT_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('provider-list') > -1 && localStorage.getItem(PROVIDER_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('human-resource-list') > -1 && localStorage.getItem(HR_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('account-list') > -1 && localStorage.getItem(ACCOUNT_MANAGEMENT_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('acts-categories') > -1 && localStorage.getItem(ACT_CATEGORY_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('reference-acts') > -1 && localStorage.getItem(ACT_GRID_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('acts-grid') > -1 && localStorage.getItem(ACT_GRID_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('fixed-cost-categories') > -1 && localStorage.getItem(FIXED_COST_CATEGORY_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('fixed-cost-reference') > -1 && localStorage.getItem(FIXED_COST_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('fixed-costs') > -1 && localStorage.getItem(FIXED_COST_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('counter-part-categories') > -1 && localStorage.getItem(COUNTER_PART_CATEGORY_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('counter-parts') > -1 && localStorage.getItem(COUNTER_PART_REFERENCE_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('operational-acts') > -1 && localStorage.getItem(OPERATIONAL_ACT_EXPORT) !== Permission.NO_EXPORT)
        || (this.currentPath.indexOf('invoice') > -1 && localStorage.getItem(INVOICE_EXPORT) !== Permission.NO_EXPORT)
        || (this.actualRoute.indexOf('pharmacy') > -1 && localStorage.getItem(PHARMACY_EXPORT) !== Permission.NO_EXPORT);
    this.exportOnePageVisibility = !!this.dataService.getExportPageModule();
    this.exportAllTrialVisibility = this.dataService.getExportPageModule() === 'src/app/trial/trial-list/trial-list.component';
    this.exportInvoicePageVisibility = this.dataService.getExportPageModule() === 'src/app/invoice/invoice-information/invoice-information.component';
    this.exportAllPatientVisibility = this.dataService.getExportPageModule() === 'src/app/patient/patient-list/patient-list.component';
    this.exportAllPharmacyVisibility = (this.dataService.getExportPageModule() === 'src/app/trial/trial-pharmacy/trial-pharmacy-stock/trial-pharmacy-stock.component')
        || (this.dataService.getExportPageModule() === 'src/app/pharmacy/pharmacy-stock/pharmacy-stock.component')
        || (this.dataService.getExportPageModule() === 'src/app/trial/trial-pharmacy/trial-pharmacy-dispensation/trial-pharmacy-dispensation.component')
        || (this.dataService.getExportPageModule() === 'src/app/pharmacy/pharmacy-dispensation/pharmacy-dispensation.component');
    this.exporttrackinTableVisibility = this.dataService.getExportPageModule() === 'src/app/trial/tracking-table/tracking-table.component';
    this.exportEstablishmentContacts = this.dataService.getExportPageModule() === 'src/app/establishment/my-establishment/my-establishment.component';
    this.exportAllListVisibility = this.permitExportAllPage();
    this.initAdminMenu(this.userRole === 'ADMIN' || this.userRole === 'EDITOR');
    this._cdr.detectChanges();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  goBack() {
    this._loc.back();
  }

  goForward() {
    this._loc.forward();
  }

  goToLink() {
    window.open('https://innogec.freshdesk.com/support/tickets/new', '_blank');
  }

  loadCurrentUser(): string {
    return localStorage.getItem(AUTHENTICATED_USER);
  }

  print() {
    window.print();
  }


  export(option: string) {
    this.option = option;
    this.location.assign(new Properties().host + '/csv/trials/list/'
        + this.option + '/'
        + this.selectedSite + '/'
        + localStorage.getItem((TOKEN))
    );
  }

  permitExportAllPage() {
    const module = this.dataService.getExportPageModule();
    const modulesWithoutTotalExport = ['',
      'src/app/trial/trial-identification-information/trial-identification-information/trial-identification-information.component',
      'src/app/trial/trial-administrative-information',
      'src/app/trial/inclusions/inclusion-details/inclusion-information/inclusion-information.component',
      'src/app/trial/inclusions/inclusion-details/calendar-details/calendar/calendar.component',
      'src/app/statistics/follow-trials/follow-trials.component',
      'src/app/statistics/follow-inclusions/follow-inclusions.component',
      'src/app/statistics/follow-active-file/follow-active-file.component',
      'src/app/trial/tracking-table/tracking-table.component',
      'src/app/invoice/invoice-acts/invoice-acts.component',
      'src/app/invoice/invoice-counter-parts/invoice-counter-parts.component',
      'src/app/invoice/invoice-fixed-costs/invoice-fixed-costs.component',
      'src/app/invoice/invoice-information/invoice-information.component',
      'src/app/invoice/follow-invoice/follow-invoice/follow-invoice.component',
      'src/app/patient/patient-list/patient-list.component',
      'src/app/admin/account-list/account-list.component'
    ];
    if (modulesWithoutTotalExport.indexOf(module) === -1 && !this.exportAllTrialVisibility) {
      return true;
    }
  }

  isAccountManagementDisplayed() {
    return localStorage.getItem(ACCOUNT_MANAGEMENT_READ_WRITE) !== Permission.NO_RIGHT;
  }

  displayDownloadDialogue() {
    this.downloadDialogueVisible = true;
  }

  visualizeDocument(doc: string) {
    window.open(this.host + '/' + doc, '_blank');
    // this.location.assign(this.host + '/' + doc);
  }

  showDownloadButton() {
    if (this.isUseIGRTutorialVideosEnabled) {
      this.isDownloadButtonVisible = (this.displayField('calendarRealGuideFile') && this.actualRoute.indexOf('inclusion-details/calendar') > 0) ||
        (this.displayField('thCalendarGuideFile') && this.actualRoute.indexOf('theoretical-calendar') > 0)
        || (this.displayField('billingGuideFile') && (this.actualRoute.indexOf('invoice-information') > -1))
        || (this.actualRoute.includes('additional-costs') && (this.actualRoute.indexOf('operational-acts') > -1))
        || (this.actualRoute.includes('trial-details/fixed-costs'))
        || (this.actualRoute.includes('trial-details/operational-acts'))
        || (this.actualRoute.includes('trial-details/counter-parts'))
        || (this.actualRoute.includes('inclusions-list'))
        || (this.actualRoute.includes('amendment-identification-information'))
        || (this.actualRoute.includes('contact'))
        || (this.actualRoute.includes('provider-list'))
        || (this.actualRoute.includes('home'))
        || (this.actualRoute.includes('invoice-container/invoice-list'))
        || (this.actualRoute.includes('invoice-container/static-invoice-list'))
        || (this.actualRoute.includes('agenda'));
    } else {
      this.isDownloadButtonVisible = ((this.displayField('calendarRealGuideFile') && this.actualRoute.indexOf('inclusion-details/calendar') > 0) ||
          (this.displayField('thCalendarGuideFile') && this.actualRoute.indexOf('theoretical-calendar') > 0) ||
          (this.displayField('designGuideFile') && this.actualRoute.indexOf('design-identification-information') > 0) ||
          (this.displayField('billingGuideFile') && (this.actualRoute.indexOf('invoice') > -1)) ||
          (this.displayField('inclusionGuideFile') && ((this.actualRoute.indexOf('inclusions-list') > 0) || (this.actualRoute.indexOf('inclusion-information') > 0))) ||
          (this.displayField('providerAndHrFile') && ((this.actualRoute.indexOf('human-resource-list') > -1) || (this.actualRoute.indexOf('provider-list') > -1))) ||
          (this.displayField('trueOrFalseImportDataFile') && this.actualRoute.indexOf('data-import') > -1)) ||
        (this.actualRoute.includes('establishment')) ||
        (this.actualRoute.includes('patient-list')) ||
        (this.actualRoute.includes('additional-costs')) ||
        (this.actualRoute.includes('pharmacy')) ||
        (this.actualRoute.includes('trial-list')) ||
        (this.actualRoute.includes('trial-identification-information')) ||
        (this.actualRoute.includes('agenda')) ||
        (this.actualRoute.includes('account-list')) ||
        (this.actualRoute.includes('access-rights')) ||
        (this.actualRoute.includes('tracking-table')) ||
        (this.actualRoute.includes('trial-details/acts-grid')) ||
        (this.actualRoute.includes('inclusions-list'));
    }
    return this.isDownloadButtonVisible;
  }

  private setMenuItems(hasPermission: boolean) {
    this.items = [
      {
        label: this.translateService.getTranslationString('UI_LOGIN_IDENTIFIER') + this.loadCurrentUser(),
        items: [
          {
            label: this.translateService.getTranslationString('UI_GLOBAL_MY_ACCOUNT'),
            routerLink: '/auth/edit/'
          },
          {
            label: this.translateService.getTranslationString('UI_GLOBAL_USER_ADMINISTRATION'),
            routerLink: '/account-list',
            visible: hasPermission && this.isAccountManagementDisplayed()
          },
          {
            label: this.translateService.getTranslationString('UI_GLOBAL_LOG_OUT'), command: () => {
              this.heartbeatService.sendHeartbeat();
              console.log('last heartbeat sent before logout');
              this.heartbeatService.stopHeartbeats();
              this.authService.logout().subscribe(() => this.router.navigate(['/login']).then());
            }
          }
        ]
      }
    ];
    this.initAdminMenu(hasPermission);
  }

  private initAdminMenu(hasPermission: boolean) {
    if (this.items && this.items.length >= 1 && this.items[0].items.length < 5 && (this.userRole === (Role.Admin) || this.userRole===(Role.Manager))) {
      let i = 1;
      if (this.displayField('workloadEnabled')) {
        this.items[0].items.splice(++i, 0, {
          label: this.translateService.getTranslationString('UI_GLOBAL_USER_ADMIN_WORKLOAD'),
          routerLink: '/workload',
          visible: hasPermission
        });
      }

      this.items[0].items.splice(++i, 0, {
        label: this.translateService.getTranslationString('UI_GLOBAL_AUDIT_TRAIL'),
        routerLink: '/audit-trail/',
        visible: hasPermission
      });
    }
  }

  displayField = (fieldName: string) => this.config.displayCard(fieldName);
  getFieldConfig = (fieldName: string) => this.config.getCardConfig(fieldName);
}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ih-theoretical-calendar-act-visit-container',
  templateUrl: './theoretical-calendar-act-visit-container.component.html',
  styleUrls: ['./theoretical-calendar-act-visit-container.component.css']
})
export class TheoreticalCalendarActVisitContainerComponent implements OnInit {

  @Input() header: string;
  @Input() items: any;
  @Input() isInInclusion = false;

  constructor() {
  }

  ngOnInit() {
  }

}

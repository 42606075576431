import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig} from '../../entity/form-input-config';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {isNullOrUndefined, isUndefined} from 'util';
import {SelectItem} from 'primeng/components/common/api';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {PropertyEnum} from '../../entity/property-enum';
import {Util} from '../../../helpers/util';
import {DataItemService} from '../../../custom-field/service/data-item.service';

export const DYNAMIC_MULTISELECT_VALUE_ACESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicMultiselectComponent),
  multi: true
};

@Component({
  selector: 'ih-dynamic-multiselect',
  templateUrl: './dynamic-multiselect.component.html',
  styleUrls: ['./dynamic-multiselect.component.css'],
  providers: [DYNAMIC_MULTISELECT_VALUE_ACESSOR]
})
export class DynamicMultiselectComponent implements OnInit, ControlValueAccessor {

  @Input() config: FormInputConfig;
  @Input() name: string;
  @Input() errorMsg = 'FORM_REQUIRED_FIELD_ERROR';
  @Input() submitted: boolean;
  @Input() hasComment = false;
  @Input() commentDisabled = false;
  @Input() publicCommentCount: number;
  @Input() alignLabel = false;
  @Input() items: any[] = [];


  @Input() selectedValues: any[] = [];
  @Input() maxSelectedLabels = 10;
  @Input() defaultLabel: string;
  @Input() disableWhenSelected: boolean;
  @Input() specificValue: string;
  @Input() addToDropdown: boolean;
  @Input() buttonText: boolean;
  @Input() noCache = true;
  @Input() disabled = false;
  @Input() specialTemplating: { class: string, value: string } = undefined;
  @Input() optionLabel: string;
  @Input() disableEyeBtn = false;
  @Input() showEyeBtn: boolean;
  @Input() resetDropdownToNA: boolean=false;
  @Output() valueInput: EventEmitter<any[]> = new EventEmitter();
  @Output() valueChange: EventEmitter<any[]> = new EventEmitter();
  @Output() itemValuesChange: EventEmitter<boolean> = new EventEmitter();
  @Output() itemValuesInput: EventEmitter<any[]> = new EventEmitter();
  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();
  @Output() displaySelectedItems: EventEmitter<any> = new EventEmitter();
  @Output() notifyWhenSpecificValueSelected: EventEmitter<boolean> = new EventEmitter();
  @Output() onAddToDropdown: EventEmitter<any> = new EventEmitter();
  @Output() displayDataComment = new EventEmitter<{ attachedToField: string, isPrivate: boolean }>();
  @Output() showDetails = new EventEmitter();
  @Output() onResetDropDownToNa = new EventEmitter();
  @Output() resetToNAChanged = new EventEmitter<boolean>();  // Emit boolean

  @Input() resetDropdownToNAChecked = false;
  checked: boolean = false;

  hasPrivatePostIt = true;
  overlayVisible = false;
  lastDisabledItems: any[] = [];
  initialValues: any[];
  private _values: any[];
  private _type: string;

  constructor(
    private translateService: InnohealthTranslateService,
    private dynamicConfigService: DynamicConfigService,
    private dataItemService: DataItemService
  ) {
  }

  ngOnInit(): void {
    this.dynamicConfigService.initProperties().subscribe(() =>
      this.hasPrivatePostIt = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled));
  }

  get values(): any[] {
    if (!isNullOrUndefined(this._values)) {
      if (this.specificValue) {
        const wantedItem: SelectItem = this.items.find(item => item.label === this.translateService.getTranslationString(this.specificValue));
        this.notifyWhenSpecificValueSelected.emit(wantedItem && this._values.indexOf(wantedItem.value) !== -1);
      }
      if (this.disableWhenSelected) {
        let exists = null;
        if (!Util.isNullOrUndefinedOrEmpty(this.selectedValues)) {
          exists = this.selectedValues.find(item => this._values.indexOf(item) !== -1);
        }
        if (isNullOrUndefined(exists)) {
          for (const item of this._values) {
            this.lastDisabledItems.push(item);
            this.selectedValues.push(item);
          }
          this.displaySelectedItems.emit(this.selectedValues);
        }
      }
    }
    return this._values;
  }

  set values(v: any[]) {
    if (v !== this._values) {
      this._values = v;
      this.onChange(v);
      if (this.disableWhenSelected) {
        if (!isNullOrUndefined(this.lastDisabledItems)) {
          for (const lastDisabledItem of this.lastDisabledItems) {
            const index = this.selectedValues.findIndex(item => item === lastDisabledItem);
            this.selectedValues.splice(index, 1);
          }
        }
        for (const item of this._values) {
          this.lastDisabledItems.push(item);
          this.selectedValues.push(item);
        }
        this.displaySelectedItems.emit(this.selectedValues);
      }
      this.valueChange.emit(this._values);
      if (!this.overlayVisible) {
        this.valueInput.emit(this._values);
      }
    }
  }


  get type(): string {
    return this._type;
  }

  @Input()
  set type(v: string) {
    if (v !== this._type) {
      this._type = v;
      this.loadItems(v);
    }
  }
  @Input()
  set url(v: string) {
    if (v !== this._type) {
      this._type = v;
      this.getItems(v);
    }
  }

  onShowDo() {
    this.onShow.emit();
    this.items.forEach(item => {
      item.disabled = false;
    });
    if (this.disableWhenSelected && !isNullOrUndefined(this.items) && !isNullOrUndefined(this.selectedValues)) {
      for (const selectedValue of this.selectedValues) {
        if (!this._values || (this._values && this._values.indexOf(selectedValue) === -1)) {
          const index = this.items.findIndex(item => item.value === selectedValue);
          if (index > -1) {
            this.items[index].disabled = true;
          }
        }
      }
    }
    this.initialValues = this._values;
    this.overlayVisible = true;
  }

  onHideDo() {
    this.onHide.emit();
    this.overlayVisible = false;
    if (this.values !== this.initialValues) {
      this.valueInput.emit(this._values);
    }
  }

  writeValue(v: any[]) {
    if (v !== this._values) {
      this._values = v;
      this.onChange(this._values);
      this.valueChange.emit(this._values);
    }
  }

  loadItems(v: string, refreshCash?: boolean): void {
    if (Util.isNullOrUndefinedOrEmpty(v)) {
      return;
    }
    this.dataItemService.getItems(v).subscribe(res => {
      this.items = Util.clone(res);
      if (this.specificValue) {
        const wantedItem: SelectItem = this.items.find(item => item.label === this.translateService.getTranslationString(this.specificValue));
        if (!isNullOrUndefined(this._values)) {
          this.notifyWhenSpecificValueSelected.emit(wantedItem && this._values.indexOf(wantedItem.value) !== -1);
        }
      }
    });
  }
  getItems(v: string, sort?: boolean): void {
    if (Util.isNullOrUndefinedOrEmpty(v)) {
      return;
    }
    this.dataItemService.loadItems(v).subscribe(res => {
      this.items = Util.clone(res);
      if (this.specificValue) {
        const wantedItem: SelectItem = this.items.find(item => item.label === this.translateService.getTranslationString(this.specificValue));
        if (!isNullOrUndefined(this._values)) {
          this.notifyWhenSpecificValueSelected.emit(wantedItem && this._values.indexOf(wantedItem.value) !== -1);
        }
      }
    });
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  selectedValuesChanged() {
    this.itemValuesChange.emit(true);
  }

  addToDropDownEvent() {
    this.onAddToDropdown.emit();
  }

  onRefreshDrop(refreshCash?: boolean) {
    this.loadItems(this._type, !isUndefined(refreshCash) ? refreshCash : true);
  }

  emitDataCommentEvent(p: boolean) {
    this.displayDataComment.emit({attachedToField: this.name, isPrivate: p});
  }


  specialTemplatingDisabled(): boolean {
    return Util.isNullOrUndefined(this.specialTemplating);
  }


  onChange = (_) => {
  };
  onTouched = () => {
  };

  displaySelectedItemsInOrder(): string {
    if (Util.isNullOrUndefinedOrEmpty(this._values) || Util.isNullOrUndefinedOrEmpty(this.items)) {
      return '';
    }
    const result = this.items.filter(item => this._values.includes(item.value)).map(item => item.label);
    return result.toString();
  }

  emitEyeEvent(event) {
    this.showDetails.emit(event);
  }
  emitResetToNAEvent() {
    this.onResetDropDownToNa.emit();
  }

  onCheckboxChange(checked : boolean) {
    checked=this.resetDropdownToNAChecked
    console.log('Checkbox value:', checked);
    this.resetToNAChanged.emit(checked);
    this.checked=!this.checked;
  }
}

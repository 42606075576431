import {Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {VisitAct} from '../../../../../entity/inclusion/visit-act';
import {ReferenceAct} from '../../../../../../additional-costs/models/reference-act';
import {TrialAct} from '../../../../../entity/theoretical-calendar/trial-act';
import {Data} from '../../../../../../shared/entity/data';
import {SharedService} from '../../../../../../shared/services/shared.service';
import {UsersService} from '../../../../../../admin/services/users.service';
import {Router} from '@angular/router';
import {VisitActService} from '../../../../../service/visit-act.service';
import {isNullOrUndefined} from 'util';
import {DeleteConfirmationComponent} from '../../../../../../modals/delete-confirmation/delete-confirmation.component';
import {Visit} from '../../../../../entity/visit';
import {FR} from '../../../../../../shared/entity/calendar-language';
import {VisitActStateUpdateConfirmationComponent} from '../visit-act-state-update-confirmation/visit-act-state-update-confirmation.component';
import {StateType} from '../../../../../../shared/entity/payment-type.enum';
import {PropertyEnum} from '../../../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-operational-act-update-form',
  templateUrl: './operational-act-update-form.component.html',
  styleUrls: ['./operational-act-update-form.component.css']
})
export class OperationalActUpdateFormComponent implements OnInit {

  showDateOrPriceEnabled = true;
  visitActRealisationDateEnabled = true;

  @ViewChild('visitStateUpdateConfirmationPopup', {read: ViewContainerRef}) visitStateUpdateContainer: ViewContainerRef;

  @ViewChild('deleteVisitActPopup', {read: ViewContainerRef}) deletePopupContainer: ViewContainerRef;
  @Output() deleteEvent = new EventEmitter<VisitAct>();
  public displayVisitActUpdate = false;
  public visitAct: VisitAct = new VisitAct();
  public referenceAct: ReferenceAct = new ReferenceAct();
  public trialAct: TrialAct = new TrialAct();
  public noYesOptions = Data.yesOrNo;
  private callback: (visitAct: VisitAct) => void;

  public visitHhhId: number;
  public visitSubActsHhhIds: number[] = [];
  visit: Visit;
  public locale = FR;
  public visitActRealisationStates: any = Data.visitActRealisationStates;
  public visitActPaymentStates: any = Data.visitActPaymentStates;

  public visitActState: { id: number, type: StateType };
  public visitActStates = Data.visitActStates;
  public realisationStateHhhIdList: number[];

  constructor(
      private sharedService: SharedService,
      private useService: UsersService,
      private router: Router,
      private cfr: ComponentFactoryResolver,
      public dynamicConfigService: DynamicConfigService,
      private visitActService: VisitActService
  ) {
  }

  ngOnInit() {
    this.initProperties();
    this.displayVisitActUpdate = true;
    this.visitHhhId = this.visit.hhhId;
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.showDateOrPriceEnabled = this.dynamicConfigService.getProperty(PropertyEnum.showDateOrPriceEnabled);
      this.visitActRealisationDateEnabled = this.dynamicConfigService.getProperty(PropertyEnum.visitActRealisationDateEnabled);
    });
  }

  createVisitActStateUpdateConfirmationComponent(reason: string) {
    const popupDialogFactory = this.cfr.resolveComponentFactory(VisitActStateUpdateConfirmationComponent);
    const popupDialogComponentRef = this.visitStateUpdateContainer.createComponent(popupDialogFactory);
    const visitActStateUpdateConfirmationComponent = popupDialogComponentRef.instance;
    visitActStateUpdateConfirmationComponent.reason = reason;
    visitActStateUpdateConfirmationComponent.visitActHhhIds = this.visitSubActsHhhIds;
    visitActStateUpdateConfirmationComponent.visitHhhId = this.visitHhhId;
    visitActStateUpdateConfirmationComponent.paymentStateHhhId = this.visitActState.id;
    visitActStateUpdateConfirmationComponent.onVisitActPaymentStateChange(res => {
      this.visit.visitStateHhhId = res;
    });
    visitActStateUpdateConfirmationComponent.updatedSelectedVisitActsRealisationStateEvent.subscribe(actRealisationStateHhhId => {
      this.visitAct.childVisitActs.forEach(visitAct => {
        if (this.visitSubActsHhhIds.indexOf(visitAct.hhhId) !== -1) {
          visitAct.realisationStateHhhId = actRealisationStateHhhId;
          visitAct.checked = false;
        }
      });
    });

    visitActStateUpdateConfirmationComponent.updatedSelectedVisitActsPaymentStateEvent.subscribe(actPaymentStateHhhId => {
      this.visitAct.childVisitActs.forEach(visitAct => {
        if (this.visitSubActsHhhIds.indexOf(visitAct.hhhId) !== -1) {
          visitAct.paymentStateHhhId = actPaymentStateHhhId;
          visitAct.checked = false;
        }
      });
    });
  }

  tryVisitActStateChange() {
    if ([StateType.PROMOTER_PAYMENT, StateType.PROVIDER_PAYMENT].includes(this.visitActState.type)) {
      this.visitActService.getIsVisitActStateSwitchable(this.visitSubActsHhhIds, this.visitActState.id, this.visitActState.type)
          .subscribe(isStateSwitchable => {
            if (isStateSwitchable.switchable) {
              this.updateVisitActState(this.visitActState.type);
            } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_COMPLETED') {
              this.createVisitActStateUpdateConfirmationComponent('COMPLETED');
            } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_INVOICED_ACTS') {
              this.createVisitActStateUpdateConfirmationComponent('INVOICED_FROM_INVOICED_ACTS');
            } else if (isStateSwitchable.why === 'ALL_VISIT_ACTS_MUST_BE_INVOICED_FROM_PAID_ACTS') {
              this.createVisitActStateUpdateConfirmationComponent('INVOICED_FROM_PAID_ACTS');
            } else if (isStateSwitchable.why === 'ONLY_FINANCE_USERS_CAN_SWITCH_STATE') {
              this.sharedService.showFailure('MODULE_TRIAL_PATIENT_CALENDAR_ONLY_FINANCE_USERS_CAN_SWITCH_STATE');
            } else if (isStateSwitchable.why === 'AOP_EMPTY') {
              this.sharedService.showFailure('MODULE_TRIAL_PATIENT_CALENDAR_ONLY_FOR_NO_EMPTY_AOP_STATE_IS_SWITCHED');
            }
          });
    } else if (this.visitActState.type === StateType.REALISATION) {
      this.updateVisitActState(StateType.REALISATION);
    }
  }

  private canSwitch(visitAct) {
    const pending = 1;
    const canSwitchToUnrealised: boolean = visitAct.paymentStateHhhId === pending;
    const canSwitchToRealised: boolean = this.visitActState.id === 2;
    return canSwitchToUnrealised || canSwitchToRealised;
  }

  updateVisitActState(stateType: StateType) {
    this.visitActService.updateVisitActsState(this.visitSubActsHhhIds, stateType, this.visitActState.id)
        .subscribe(() => {
          this.visitAct.childVisitActs.forEach(visitAct => {
            if (this.visitSubActsHhhIds.indexOf(visitAct.hhhId) !== -1) {
              if (this.visitActState.type === StateType.REALISATION) {
                if (this.canSwitch(visitAct)) {
                  visitAct.realisationStateHhhId = this.visitActState.id;
                }
              } else {
                visitAct.paymentStateHhhId = this.visitActState.id;
              }
            }
            visitAct.checked = false;
          });
          this.visitActState = null;
          this.visitSubActsHhhIds = [];
          this.sharedService.showSuccess();
        });
  }

  onUpdate(callback: (visitAct: VisitAct) => void) {
    this.callback = callback;
  }

  selectVisitAct(actHhhId: number, checked: boolean) {
    if (checked) {
      this.visitSubActsHhhIds.push(actHhhId);
    } else {
      const index = this.visitSubActsHhhIds.indexOf(actHhhId);
      this.visitSubActsHhhIds.splice(index, 1);
    }
  }

  changeBulkCheck(checked) {
    for (const visitAct of this.visitAct.childVisitActs) {
      visitAct.checked = checked;
      if (visitAct.checked) {
        this.visitSubActsHhhIds.push(visitAct.hhhId);
      } else {
        const index = this.visitSubActsHhhIds.indexOf(visitAct.hhhId);
        this.visitSubActsHhhIds.splice(index, 1);
      }
    }
  }

  updateVisitOperationalSubActs() {
    this.visitActService.updateVisitOperationalSubActs(this.visitAct.childVisitActs)
        .subscribe(() => {
          this.displayVisitActUpdate = false;
          this.callback(this.visitAct);
          this.sharedService.showSuccess();
        });
  }

  deleteVisitAct() {
    if (isNullOrUndefined(this.visitAct.theoreticalVisitActHhhId)) {
      this.displayVisitActUpdate = false;
      this.displayActDeleteDialogue();
    }
  }

  displayActDeleteDialogue() {
    const deletePopupFactory = this.cfr.resolveComponentFactory(DeleteConfirmationComponent);
    const deletePopupComponentRef = this.deletePopupContainer.createComponent(deletePopupFactory);
    const deleteConfirmationComponent = deletePopupComponentRef.instance;
    deleteConfirmationComponent.hhhId = this.visitAct.hhhId;
    deleteConfirmationComponent.popupMessage = 'MODULE_TRIALS_ACTS_GENERAL_DELETE';
    deleteConfirmationComponent.service = this.visitActService;
    deleteConfirmationComponent.name = this.visitAct.description;
    deleteConfirmationComponent.onDelete(() => {
      this.deleteEvent.emit(this.visitAct);
    });
  }

  isPriceVisible(): boolean {
    return this.useService.canAccessFieldByValue(this.visitAct.type);
  }

  disableVisitAct(): boolean {
    this.realisationStateHhhIdList = (this.visitAct.childVisitActs.map(childVisitAct => childVisitAct.realisationStateHhhId));
    return (this.realisationStateHhhIdList.findIndex(realisationStateHhhId => realisationStateHhhId === 2) !== -1) || (this.visitAct.theoreticalVisitActHhhId != null) || (this.visitAct.realisationStateHhhId !== 1);
  }
}

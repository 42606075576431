import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-page-down',
  templateUrl: './page-down.component.html',
  styleUrls: ['./page-down.component.css']
})
export class PageDownComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  goToLink() {
    window.open('https://innogec.freshdesk.com/support/tickets/new', '_blank');
  }

}

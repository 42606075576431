import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {Observable, throwError} from 'rxjs';
import {MinimizedTrialProtocol} from '../models/minimized-trial-protocol';
import {TrialProtocol} from '../models/trialProtocol';
import {Deletable} from '../../modals/delete-confirmation/deletable';
import {IsDeletable} from '../../is-deletable';
import {LabelValue} from '../../shared/entity/label-value';
import {Value} from '../../shared/entity/value';
import {catchError, map} from 'rxjs/operators';
import {Patient} from '../../patient/models/patient';

@Injectable({
  providedIn: 'root'
})
export class TrialProtocolService implements Deletable {
  private _selectedTheoreticalCalendarProtocolHhhId;

  get selectedTheoreticalCalendarProtocolHhhId() {
    return this._selectedTheoreticalCalendarProtocolHhhId;
  }

  public host = new Properties().host + '/trial-protocol';

  set selectedTheoreticalCalendarProtocolHhhId(value) {
    this._selectedTheoreticalCalendarProtocolHhhId = value;
  }

  constructor(private httpClient: HttpClient) {
  }

  getTrialProtocolList(trialId: number): Observable<MinimizedTrialProtocol[]> {
    return this.httpClient.get<MinimizedTrialProtocol[]>(this.host + '/load-by-trial/' + trialId);
  }

  getTrialProtocolListHavingDesign(trialId: number): Observable<MinimizedTrialProtocol[]> {
    return this.httpClient.get<MinimizedTrialProtocol[]>(this.host + '/load-by-trial/' + trialId + '?having_design=1');
  }

  existsNotHavingDesignByTrial(trialId: number): Observable<Value<boolean>> {
    return this.httpClient.get<Value<boolean>>(this.host + '/exists-last-not-having-design/' + trialId);
  }

  getTrialProtocolById(trialAmendmentId: number): Observable<TrialProtocol> {
    return this.httpClient.get<TrialProtocol>(this.host + '/load/' + trialAmendmentId)
      .pipe(
        map(res => {
            return (new TrialProtocol()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  addTrialProtocol(amendment: TrialProtocol): Observable<MinimizedTrialProtocol> {
    return this.httpClient.post<MinimizedTrialProtocol>(this.host + '/add', amendment);
  }

  editTrialProtocol(amendment: TrialProtocol): Observable<MinimizedTrialProtocol> {
    return this.httpClient.put<MinimizedTrialProtocol>(this.host + '/update', amendment);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(trialAmendmentId: number): Observable<{}> {
    return this.httpClient.get(this.host + '/delete/' + trialAmendmentId);
  }

  getLatestByTrialId(trialId: number): Observable<MinimizedTrialProtocol> {
    return this.httpClient.get<MinimizedTrialProtocol>(this.host + '/latest/' + trialId);
  }

  isLastProtocolNotUsed(allProtocols: Array<MinimizedTrialProtocol>, usedProtocols: Array<MinimizedTrialProtocol>): boolean {
    if (allProtocols.length === 0 || usedProtocols.length === 0) {
      return true;
    }
    const lastProtocol = allProtocols[allProtocols.length - 1];
    return usedProtocols.findIndex(p => p.hhhId === lastProtocol.hhhId) < 0;
  }

  isLastProtocolHavingCalendarByTrial(trialId: number): Observable<Value<boolean>> {
    return this.httpClient.get<Value<boolean>>(this.host + '/is-last-having-theoretical-calendar/' + trialId);
  }

  existsByTrial(trialID: number): Observable<Value<boolean>> {
    return this.httpClient.get<Value<boolean>>(this.host + '/exists-by-trial/' + trialID);
  }

  getNonUnusedInTheoreticalCalendar(trialId: number): Observable<MinimizedTrialProtocol[]> {
    return this.httpClient.get<MinimizedTrialProtocol[]> (this.host + '/unused-in-tc/load-minimized?trial=' + trialId);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

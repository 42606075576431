import {Arm} from '../entity/inclusion/arm';
import {TrialMethodologyIdentificationInformation} from './trial-methodology-identification-information';
import {TrialIdentificationInformationGeneral} from './trial-identification-information-general';
import {TrialDisciplineIdentification} from './trial-discipline-identification';
import {TrialHumanResource} from './trial-human-resource';
import {PromoterType} from '../../provider/models/promoter-type';

export class TrialInformation {
  public hhhId: number;
  public trialSiteHhhId: number;
  public promoterType: PromoterType;
  public omsEcog: number[] = [];
  public indicatorsAndInclusionOrgans: number[] = [];
  public inclusionHistologies: number[] = [];
  public inclusionBiomarkers: number[] = [];
  public biopsies: number[] = [];
  public idRcb: string;
  public otherExclusionCriteria: number[] = [];
  public arms: Arm[] = [];
  public illnessClassification: string;
  public interventionalProtocol: string;
  public organList: string[] = [];
  public trialTypesHhhId: number[] = [];
  public targetsOfMoleculesHhhId: number[] = [];
  public researchObjectOtherValue: string;
  public trialIdentificationInformationGeneral: TrialIdentificationInformationGeneral = new TrialIdentificationInformationGeneral();
  public trialHumanResource: TrialHumanResource = new TrialHumanResource();
  public trialMethodologyIdentificationInformation: TrialMethodologyIdentificationInformation = new TrialMethodologyIdentificationInformation();
  public trialDiscipline: TrialDisciplineIdentification = new TrialDisciplineIdentification();

  init(obj: any): TrialInformation {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'trialIdentificationInformationGeneral') {
          this.trialIdentificationInformationGeneral.init(obj[prop]);
        } else if (prop === 'trialHumanResource') {
          this.trialHumanResource.init(obj[prop]);
        } else if (prop === 'trialMethodologyIdentificationInformation') {
          this.trialMethodologyIdentificationInformation.init(obj[prop]);
        } else if (prop === 'trialDiscipline') {
          this.trialDiscipline.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }
}


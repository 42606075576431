import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {LabelValue} from '../../shared/entity/label-value';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdministrationRouteService {

  public host = new Properties().host + '/administration-route';

  constructor(private httpClient: HttpClient) {}

  listAdministrationRoutes(trialId: number): Observable<LabelValue[]> {
    return this.httpClient.get<LabelValue[]>(this.host + `/get-list/trial/${trialId}`);
  }

}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {AuthGuard} from '../helpers/auth-guard.service';
import {MobileApplicationHeaderComponent} from './mobile-application-header/mobile-application-header.component';
import {AccountsManagementComponent} from './accounts-management/accounts-management.component';
import {TrialsComponent} from './trials/trials.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {AboutComponent} from './about/about.component';
import {PushNotificationManagementComponent} from './notifications/push-notification-management.component';
import {RequirePasswordUpdateGuard} from '../helpers/require-password-update-guard';

const routes: Routes = [
  {
    path: 'mobile-application', component: MobileApplicationHeaderComponent, canActivate: [RequirePasswordUpdateGuard],
    children: [
      {path: '', redirectTo: 'trials', pathMatch: 'full'},
      {path: 'trials', component: TrialsComponent, canActivate: [AuthGuard]},
      {path: 'accounts-management', component: AccountsManagementComponent, canActivate: [AuthGuard]},
      {path: 'terms-and-conditions', component: TermsAndConditionsComponent, canActivate: [AuthGuard]},
      {path: 'about', component: AboutComponent, canActivate: [AuthGuard]},
      {path: 'notifications', component: PushNotificationManagementComponent, canActivate: [AuthGuard]},

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), TranslateModule],
  exports: [RouterModule]
})
export class MobileRoutingModule {
}

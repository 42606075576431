import {Component, ComponentFactoryResolver, Inject, OnInit, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {RealDatesVisitsPatientsFileImport} from '../../../entity/RealDatesVisitsPatientsFileImport';
import {DynamicFilesService} from '../../../../shared/services/dynamic-files.service';
import {LOCATION_TOKEN} from '../../inclusions/import-patient-form/import-patient-form.component';
import {Properties} from '../../../../helpers/properties';
import {SelectItem} from 'primeng/api';
import {MinimizedTrialProtocol} from '../../../models/minimized-trial-protocol';
import {TrialProtocolService} from '../../../services/trial-protocol.service';
import {ArmService} from '../../../services/arm.service';
import {Arm} from '../../../entity/inclusion/arm';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {LabelValue} from "../../../../shared/entity/label-value";

@Component({
  providers: [
    {provide: LOCATION_TOKEN, useValue: window.location}
  ],
  selector: 'ih-import-real-dates-visits-patients-form',
  templateUrl: './import-real-dates-visits-patients-form.component.html',
  styleUrls: ['./import-real-dates-visits-patients-form.component.css']
})
export class ImportRealDatesVisitsPatientsFormComponent extends FormComponent<RealDatesVisitsPatientsFileImport> implements OnInit {

  amendmentsEnabled = false;

  realDatesVisitsPatientsFileImport: RealDatesVisitsPatientsFileImport = new RealDatesVisitsPatientsFileImport();
  host = new Properties().host;
  usedProtocolsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  armsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  usedProtocols: Array<MinimizedTrialProtocol> = new Array<MinimizedTrialProtocol>();
  protocolHhhId: number;
  allArms: Array<Arm> = new Array<Arm>();

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ImportRealDatesVisitsPatientsFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  constructor(
      public dynamicFiles: DynamicFilesService,
      @Inject(LOCATION_TOKEN) private location: Location,
      private trialProtocolService: TrialProtocolService,
      private dynamicConfigService: DynamicConfigService,
      private armService: ArmService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.getImportFileName();
    this.initProperties();
    this.getUsedProtocols();

  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      if (!this.amendmentsEnabled) {
        this.armService.getArmsByTrialId(String(this.realDatesVisitsPatientsFileImport.trialHhhId)).subscribe(res => {
          this.armsSelectItems = res.map(arm => new LabelValue(arm.name, arm.hhhId));
        });
      }
    });
  }

  getImportFileName(): void {
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.realDatesVisitsPatientsFileImport.file = event.target.files[0];
    }
  }

  save() {
    this.callback(this.realDatesVisitsPatientsFileImport);
  }

  importFile() {
    this.location.assign(this.host + '/' + this.dynamicFiles.importVisitRealDatesFileName);
  }

  getUsedProtocols() {
    this.trialProtocolService.getTrialProtocolListHavingDesign(Number(this.realDatesVisitsPatientsFileImport.trialHhhId)).subscribe(result => {
      this.usedProtocols = result;
      this.usedProtocolsSelectItems = [];
      this.usedProtocols.forEach(protocol => this.usedProtocolsSelectItems.push({
        label: (protocol.version ? 'Version du protocole' : 'Version du l\'amendement') + ' : ' + (protocol.version ? protocol.version : protocol.amendmentVersion),
        value: protocol.hhhId
      }));
    });
  }

  onProtocolChange() {
    this.updateDesignListDependingOnProtocolHhhId();
  }

  updateDesignListDependingOnProtocolHhhId() {
    this.armService.loadListByTrialAndProtocolId(String(this.realDatesVisitsPatientsFileImport.trialHhhId), this.protocolHhhId).subscribe(result => {
      this.allArms = result;
      this.armsSelectItems = [];
      this.allArms.forEach(arm => this.armsSelectItems.push({
        label: arm.name,
        value: arm.hhhId
      }));
    });
  }
}

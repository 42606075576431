import {NgModule} from '@angular/core';
import {DataCommentComponent} from './component/data-comment/data-comment.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({

  imports: [
    SharedModule,
  ],
  declarations: [
    DataCommentComponent
  ],
  entryComponents: [
    DataCommentComponent
  ]
})
export class DataCommentModule {}

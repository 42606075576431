import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationRequest} from '../../authentication-request';
import {AuthenticationService} from '../services/authentication.service';
import {ActivatedRoute, DefaultUrlSerializer, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {Message} from 'primeng/api';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {RoutingState} from '../services/routing-sate.service';
import {SharedService} from '../../shared/services/shared.service';
import {DataTransferService} from '../../patient/services/data-transfer.service';
import {Properties} from '../../helpers/properties';
import {Subscription, timer} from 'rxjs';
import {UsersService} from '../../admin/services/users.service';
import {HeartbeatService} from '../../heartbeat.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  @ViewChild('loginForm') loginForm: NgForm;
  public submitted = false;
  public unauthorized = true;
  public wrongRequest = false;
  public accountDisabled = false;
  public authenticationRequest: AuthenticationRequest = new AuthenticationRequest();
  public returnUrl: any;
  public currentUser = false;
  forgetPasswordSelected = false;
  password: any;
  email: string;
  resultMessage: Message[] = [];
  public versionNumber = new Properties().version;
  public currentYear = new Date().getFullYear();

  authenticationAttempts: number;
  countDown: Subscription;
  counter = 0;
  tick = 1000;
  isCounterEnabled = false;
  sendingCodeButtonEnabled: boolean = true;
  isPasswordHidden: boolean = true;

  constructor(private authenticationService: AuthenticationService,
              private sharedService: SharedService,
              private router: Router,
              private routingState: RoutingState,
              private activatedRoute: ActivatedRoute,
              private translateService: InnohealthTranslateService,
              private dataTransferService: DataTransferService,
              private userService: UsersService,
              private heartbeatService: HeartbeatService) {
    this.authenticationService.currentUser.subscribe(currentUser => this.currentUser = currentUser);
    if (this.currentUser) {
      this.router.navigate(['/home']).then();
    }
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.forgetPasswordSelected = url.queryParams.forgetPasswordSelected;
    }
  }

  get form() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    this.returnUrl = this.routingState.getPreviousUrl();
    this.authenticationAttempts = Number(localStorage.getItem('AUTHENTICATION_ATTEMPTS'));
  }

  ngOnDestroy() {
    this.countDown = null;
  }

  login() {
    localStorage.removeItem('path');
    this.submitted = true;
    this.wrongRequest = false;
    this.accountDisabled = false;

    if (this.loginForm.invalid) {
      return;
    }
    this.authenticationService
      .authenticateUser(this.authenticationRequest.username, this.authenticationRequest.password)
      .subscribe(
        () => {
          const url = new DefaultUrlSerializer().parse(this.returnUrl);
          this.router.navigate([(this.returnUrl.split('?')[0]).replace('login', 'home')], {queryParams: url.queryParams}
          ).then();
          this.heartbeatService.startHeartbeat();
          // Load patient list and transfer data
          this.dataTransferService.persistPatientList();
          localStorage.setItem('AUTHENTICATION_ATTEMPTS', '0');
          const isPasswordStrong = this.userService.calculatePasswordStrength(this.authenticationRequest.password) === 100;
          if (!isPasswordStrong) {
            this.sharedService.showWarning('UI_LOGIN_USER_PASSWORD_NOT_SECURE');
          }
        }, error => {
          this.authenticationAttempts++;
          localStorage.setItem('AUTHENTICATION_ATTEMPTS', String(this.authenticationAttempts));
          if (error.status === 401) {
            this.wrongRequest = true;
          }
          if (error.status === 403) {
            this.accountDisabled = true;
          }
        }
      );
  }

  forgetPassword() {
    this.authenticationService.isUserActivated(this.email).subscribe(isUserActive => {
      if (!isUserActive) {
        this.displayMessage(
          'error',
          'Votre compte est désactivé :',
          'Merci de contacter <a href="https://innogec.freshdesk.com/support/tickets/new" style="color: blue; text-decoration: underline;" title="https://innogec.freshdesk.com">le support de Innogec</a> pour réactiver votre compte.'
        );


        return;
      }else{
        this.sendingCodeButtonEnabled = false;
        this.router.navigate(['/request-password-reset'])
        this.authenticationService.forgetPassword(this.email).subscribe(isUserFound => {
          if (isUserFound) {
            this.displayMessage('success', 'GENERAL_LINGUISTICS_SUCCESS_UFCASE', 'UI_DIALOG_HEADER_PASSWORD_RESET_SUCCESS_2', true);
          } else {
            this.displayMessage('error', 'GENERAL_LINGUISTICS_FAILURE_UFCASE', 'UI_DIALOG_HEADER_PASSWORD_RESET_ERROR');
          }
        });
      }
    });

  }

  displayMessage(type: string, summaryKey: string, messageKey: string, withLink?: boolean) {
    this.resultMessage = [];
    this.resultMessage.push({
      severity: type,
      summary: this.translateService.getTranslationString(summaryKey),
      detail: withLink ?
        this.translateService.getTranslationString(messageKey) + '<a href="/#/request-password-reset" class="click-here">cliquer ici.</a>'
        : this.translateService.getTranslationString(messageKey)
    });
  }

  startTimer() {
    this.countDown = timer(0, this.tick)
      .subscribe(() => {
        --this.counter;
        if (this.counter === 0) {
          this.isCounterEnabled = false;
          this.authenticationAttempts = 0;
          localStorage.setItem('AUTHENTICATION_ATTEMPTS', '0');
          this.countDown = null;
        }
      });
  }

  goToLink() {
    window.open('https://innogec.freshdesk.com/support/tickets/new', '_blank');
  }

  togglePassword() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }
}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateService {


  constructor() {
  }

  private _canBeDeactivated = true;

  get canBeDeactivated(): boolean {
    return this._canBeDeactivated;
  }

  set canBeDeactivated(value: boolean) {
    this._canBeDeactivated = value;
  }
}

export class ReportsInclusionsByTrial {
  trial: string;
  organs: string[];
  organFamilies: string[];
  inclusionsPerTrialInCurrentYear: string;
  inclusionsPerYear: string[];
  total: string;
  numberPatientsPlanned: number;
  numberInclusionsInScreeningFailure: number;
  actualProductionLaunchDate: string;
  trialEndDate: string;

  init(obj: any): ReportsInclusionsByTrial {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }
}
export class ReportsInclusionsByTrialToExport {
  trial: string;
  organs: string;
  inclusionsPerTrialInJan: string;
  inclusionsPerTrialInFeb: string;
  inclusionsPerTrialInMar: string;
  inclusionsPerTrialInApr: string;
  inclusionsPerTrialInMai: string;
  inclusionsPerTrialInJun: string;
  inclusionsPerTrialInJul: string;
  inclusionsPerTrialInAug: string;
  inclusionsPerTrialInSep: string;
  inclusionsPerTrialInOct: string;
  inclusionsPerTrialInNov: string;
  inclusionsPerTrialInDec: string;
  inclusionsPerTrialInPreviousYear: string;
  inclusionsPerTrialInCurrentYear: string;
  total: string;
  numberPatientsPlanned: number;
  numberInclusionsInScreeningFailure: number;
  actualProductionLaunchDate: string;
  trialEndDate: string;
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'ih-trial-methodology-icon',
  templateUrl: './trial-methodology-icon.component.html',
  styleUrls: ['./trial-methodology-icon.component.css']
})
export class TrialMethodologyIconComponent {

  @Input() title: string;

  constructor() { }
}

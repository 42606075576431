import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageTopService {
  siteId: number;
  subject: Subject<number>;

  constructor() {
    this.subject = new Subject<number>();
  }

  sendNewFilter() {
    this.subject.next(this.siteId);
  }
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {PharmacyDispensationKit} from '../entity/trial-pharmacy/PharmacyDispensationKit';
import {Observable} from 'rxjs';
import {IsDeletable} from "../../is-deletable";

@Injectable({
  providedIn: 'root'
})
export class PharmacyDispensationKitService {

  public host = new Properties().host + '/pharmacy-dispensation-kit';

  constructor(private httpClient: HttpClient) {
  }

  add(kit: PharmacyDispensationKit): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', kit);
  }

  updateList(kits: PharmacyDispensationKit[]): Observable<void> {
    return this.httpClient.post<void>(this.host + '/update-all', kits);
  }

  updateStock(kit: PharmacyDispensationKit): Observable<void> {
    return this.httpClient.post<void>(this.host + '/update-stock', kit);
  }

  updateVisitKit(pharmacyDispensationKit: PharmacyDispensationKit): Observable<void> {
    return this.httpClient.post<void>(this.host + '/update-visit', pharmacyDispensationKit);
  }

  delete(kitHhhId: number): Observable<void> {
    return this.httpClient.get<void>(this.host + '/delete/' + kitHhhId);
  }
  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

}

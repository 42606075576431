import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {PromoterType} from '../../../provider/models/promoter-type';
import {TrialListData} from '../../models/trial-list-data';
import {Data} from '../../../shared/entity/data';
import {DataService} from '../../../shared/services/data-service';
import {TrialService} from '../../services/trial.service';
import {Router} from '@angular/router';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {TranslateService} from '@ngx-translate/core';
import {TrialGlobalFilter} from '../../models/trial-global-filter';
import {FR} from '../../../shared/entity/calendar-language';
import {
  TrialIdentificationDialogComponent
} from '../trial-identification/trial-identification-dialog/trial-identification-dialog.component';
import {TrialFixedCostService} from '../../service/trial-fixed-cost.service';
import {SharedService} from '../../../shared/services/shared.service';
import {PaginatorTableComponent} from '../../../shared/component/paginator-table/paginator-table.component';
import {DatePipe} from '@angular/common';
import {SelectItem} from 'primeng/components/common/selectitem';
import {OrganService} from '../../services/organ.service';
import {RollbackService} from '../../../shared/services/rollback.service';
import {TrialCounterpartService} from '../../service/trial-counterpart.service';
import {CalendarComponent} from '../../../shared/component/calendar/calendar.component';
import {LabelValue} from '../../../shared/entity/label-value';
import {isUndefined} from 'util';
import {Observable} from 'rxjs';
import {DataPageWithFilter} from '../../models/data-page-with-filter';
import {TrialImportComponent} from '../trial-import/trial-import.component';
import {MOBILE_APPLICATION_READ_WRITE, TRIAL_READ_WRITE} from '../../../login/services/authentication.service';
import {Permission} from '../../../helpers/permission';
import {PageTopService} from '../../../shared/services/page-top.service';
import {DynamicDefinitionEnum} from '../../../dynamic-config/entity/dynamic-definition-enum';
import {
  DynamicTableHeaderBuilder
} from '../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {TableConfig} from '../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../../dynamic-config/service/dynamic-config.service';
import {TrialInputType} from './trial-input-type';
import {PropertyEnum} from '../../../dynamic-config/entity/property-enum';
import {TrialAppData} from '../../trial-app-data';
import {AppData} from '../../../helpers/app-data';
import {ExportTypesEnum} from '../../../dynamic-config/exported/data-export/ExportTypesEnum';
import {ExportRequestBuilder} from '../../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../../data-comment/entity/attached-to-entity.enum';

declare var $: any;

@Component({
  selector: 'ih-trial-list',
  templateUrl: './trial-list.component.html',
  styleUrls: ['./trial-list.component.css']
})

export class TrialListComponent extends PaginatorTableComponent<TrialListData> implements OnInit, OnDestroy, AfterContentInit {

  _entity = AttachedToEntityEnum.Trial;
  readonly _target = DynamicDefinitionEnum.TRIAL_MODULE;
  readonly target = DynamicDefinitionEnum.TRIAL_LIST_TABLE;
  config: TableConfig;
  effectiveHeaders: DynamicTableHeader[] = [];
  possibleHeaders: DynamicTableHeader[] = [];
  inputType = TrialInputType;
  filterType = FilterType;

  isMobileApplicationEnabled = false;
  enableTrialImport = false;

  @ViewChild('displayAddTrialDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  @ViewChildren(CalendarComponent) calendarComponentList: QueryList<CalendarComponent>;

  filters: TrialGlobalFilter;
  yesOrNo: SelectItem[] = Data.yesOrNo;
  yesOrNoOrNeither = Data.yesOrNoOrNeither;
  noYesNoAnswerOptions = Data.yesOrNoOrNoAnswerEnum;
  locale = FR;
  promoterTypeOptions: SelectItem[];
  first = 0;
  organsList: SelectItem[] = [];
  maxSelectedLabels: number;
  // Table filters //
  establishmentFilter: LabelValue[];
  stateFilter: LabelValue[];
  promoterFilter: LabelValue[];
  promoterTypeFilter: LabelValue[];
  siteFilter: LabelValue[];
  phaseFilter: LabelValue[];
  researchTypeFilter: LabelValue[];
  researchNatureFilter: LabelValue[];
  illnessStateFilter: LabelValue[];
  serviceFilter: LabelValue[];
  specialityFilter: LabelValue[];
  blindedFilter: LabelValue[];
  ivrsFilter: LabelValue[];
  priorityNumberTypeFilter: LabelValue[];
  organFamilyAndOrganFilter: LabelValue[];
  molecularAnomalyTypeFilter: LabelValue[];
  private trialPageFetchObservable: Observable<DataPageWithFilter<TrialListData>>;
  isMobileBackOfficeActive: boolean;
  disableEdit = false;
  years: SelectItem[] = Data.getYearsTrialDate();
  headersReady = false;
  filtersReady = false;
  showButtons = false;
  escalationExtension: SelectItem[] = Data.escalationExtension;
  constructor(
    private trialService: TrialService,
    private cfr: ComponentFactoryResolver,
    private translate: TranslateService,
    private translateService: InnohealthTranslateService,
    private pageTopService: PageTopService,
    private router: Router,
    private dataService: DataService,
    private trialFixedCostService: TrialFixedCostService,
    private trialCounterPartService: TrialCounterpartService,
    private organService: OrganService,
    private sharedService: SharedService,
    private datePipe: DatePipe,
    private rollBack: RollbackService<TrialGlobalFilter>,
    private dynamicConfigService: DynamicConfigService,
    private cdr: ChangeDetectorRef
  ) {
    super(dataService, cfr);
    this.pageTopService.subject.subscribe((newBool: number) => {
      this.getFilteredList();
    });

  }

  ngOnInit() {
    this.disableEdit = localStorage.getItem(TRIAL_READ_WRITE) !== 'READ_WRITE';
    if ($(window).width() < 769) {
      $('.filter-form').toggleClass('filter-responsive');

    } else {
      $('.filter-form').removeClass('filter-responsive');
    }
    $('.fa-sliders').on('click', () => {
      $('.filter-collapse').toggleClass('active-filter').removeClass('close-filter');
      $('.left-overlay').toggleClass('display-overlay');
    });

    $('.fa-close').click(() => {
      $('.filter-collapse').removeClass('active-filter').toggleClass('close-filter');
      $('.left-overlay').removeClass('display-overlay');
    });

    $('.close-list-f').click(() => {

      $('.table-overlay.ui-overlaypanel').addClass('hidden-overlay');
      $('.panel-etude').css('display', 'none');
    });
    $('.fa-bars').click(() => {
      $('.panel-etude').css('display', 'block');
      $('.table-overlay.ui-overlaypanel').removeClass('hidden-overlay').toggleClass('show-filter');
    });


    // end of js
    this.initProperties();
    super.ngOnInit();
    this.getOrganList();
    if (this.rollBack.hasGlobalFilter('trialGlobalFilter')) {
      this.filters = this.rollBack.getGlobalFilter('trialGlobalFilter');
    } else {
      this.filters = new TrialGlobalFilter();
    }
    this.promoterTypeOptions = [];
    this.generatePromoterTypeOptions(Data.promoterType);
    this.translate.stream('MODULE_TRIALS_FIELD_ORGAN').subscribe(() => {
      this.extractNoYesOptions();
    });
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isMobileApplicationEnabled = this.dynamicConfigService.getProperty(PropertyEnum.mobileApplicationEnabled);
      this.enableTrialImport = this.dynamicConfigService.getProperty(PropertyEnum.enableTrialImport);
      this.isMobileBackOfficeActive = localStorage.getItem(MOBILE_APPLICATION_READ_WRITE) !== Permission.NO_RIGHT && this.isMobileApplicationEnabled;
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  ngAfterContentInit() {
    this.buildTableHeaders();
  }

  buildTableHeaders(): void {
    if (!this.filtersReady || this.headersReady) {
      return;
    }
    const availableHeaders = [];
    const userRole = localStorage.getItem('authenticatedUserRole');
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('establishmentName')
      .accessible(this.dynamicConfigService.parent)
      .filterable()
      .filter('establishment')
      .filterType(FilterType.DROPDOWN)
      .options(this.establishmentFilter)
      .sortable()
      .sortField('e.name')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('committees')
      .filterable()
      .filter('trialCommittee')
      .filterType(FilterType.IH_MULTISELECT)
      .type('trial-committee-selected')
      .sortable()
      .sortField('committe.nameFr')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('state')
      .inputType(TrialInputType.STATE)
      .filterable()
      .filter('state')
      .filterType(FilterType.IH_MULTISELECT)
      .type("state-selected")
      .sortable()
      .sortField('state')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('localIdentifier')
      .filterable()
      .filter('localIdentifier')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('localIdentifier')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('idRcb')
      .filterable()
      .filter('idRcb')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('idRcb')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('typeOfStudyLaw')
      .sortable()
      .filterable()
      .filter('typeOfStudyLaw')
      .type('type-of-study-law-selected')
      .filterType(FilterType.IH_MULTISELECT)
      .sortField('typeOfStudyLaw')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('typeOfStudyLaw2')
      .sortable()
      .filterable()
      .filter('typeOfStudyLaw2')
      .type('type-of-study-law-selected')
      .filterType(FilterType.IH_MULTISELECT)
      .sortField('typeOfStudyLaw2')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('researchType')
      .filterable()
      .filter('researchType')
      .filterType(FilterType.DROPDOWN)
      .options(this.researchTypeFilter)
      .sortable()
      .sortField('researchType')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('protocolNumber')
      .filterable()
      .filter('protocolNumber')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('protocolNumber')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('promoter1')
      .filterable()
      .filter('promoter')
      .filterType(FilterType.IH_MULTISELECT)
      .type('trial-promoter')
      .sortable()
      .sortField('promoter1')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('promoterType')
      .filterable()
      .filter('promoterType')
      .filterType(FilterType.DROPDOWN)
      .options(this.promoterTypeFilter)
      .optionFilterable()
      .sortable()
      .sortField('promoterType')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('principalInvestigator1')
      .inputType(TrialInputType.COMMA_SPLIT_ELIMINATE_NUMBER)
      .filterable()
      .filter('principalInvestigator1')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('principalInvestigator1')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('clinicalResearchAssistant1')
      .inputType(TrialInputType.COMMA_SPLIT_ELIMINATE_NUMBER)
      .filterable()
      .filter('clinicalResearchAssistant1')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('clinicalResearchAssistant1')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('arcReferent')
      .filterable()
      .filter('arcReferent')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('arcReferent')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientIncludedState')
      .filterable()
      .filter('patientIncludedState')
      .filterType(FilterType.INPUT_TEXT_NUMBER)
      .sortable()
      .sortField('patientIncludedState')
      .checked()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('tec1')
      .filterable()
      .filter('tec1')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('tec1')
      .build());
    this.effectiveHeaders = this.config.buildTable(availableHeaders);

    const possibleHeaders = [];
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('service')
      .filterable()
      .filter('establishmentDepartment')
      .filterType(FilterType.DROPDOWN)
      .options(this.serviceFilter)
      .sortable()
      .sortField('department.name')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('priorityNumbers')
      .inputType(TrialInputType.COMMA_SPLIT_STRING)
      .filterable()
      .filter('priorityNumber')
      .filterType(FilterType.DROPDOWN)
      .options(this.priorityNumberTypeFilter)
      .sortable()
      .sortField('priorityNumbers')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('organFamilies')
      .inputType(TrialInputType.COMMA_SPLIT_STRING)
      .filterable()
      .filter('trialOrgan')
      .filterType(FilterType.CUSTOM_DROPDOWN)
      .options(this.organFamilyAndOrganFilter)
      .sortable()
      .sortField('organFamilies')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('sites')
      .inputType(TrialInputType.UNDER_SCORE_SPLIT)
      .filterable()
      .filter('site')
      .filterType(FilterType.IH_MULTISELECT)
      .options(this.siteFilter)
      .sortable()
      .sortField('sites')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('coInvestigators')
      .inputType(TrialInputType.COL_INVESTIGATOR)
      .filterable()
      .filter('coInvestigators')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('coInvestigators')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('phase')
      .filterable()
      .filter('phase')
      .filterType(FilterType.IH_MULTISELECT)
      .options(this.phaseFilter)
      .sortable()
      .sortField('phase.nameFr')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('researchNature')
      .filterable()
      .filter('researchNature')
      .filterType(FilterType.DROPDOWN)
      .options(this.researchNatureFilter)
      .sortable()
      .sortField('researchNature')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('illnessStates')
      .filterable()
      .filter('illnessStates')
      .filterType(FilterType.DROPDOWN)
      .inputType(TrialInputType.ARRAY)
      .options(this.illnessStateFilter)
      .sortable()
      .sortField('illnessStates')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('moleculeStudied')
      .filterable()
      .filter('moleculeStudied')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('moleculeStudied')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('minimalAge')
      .filterable()
      .filter('minimalAge')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('minimalAge')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('maximalAge')
      .filterable()
      .filter('maximalAge')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('maximalAge')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('blinded')
      .filterable()
      .filter('blinded')
      .filterType(FilterType.DROPDOWN)
      .options(this.blindedFilter)
      .sortable()
      .sortField('blinded')
      .isTranslated()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('actualProductionLaunchDate')
      .inputType(TrialInputType.COMMA_SPLIT_DATE)
      .filterable()
      .filter('actualProductionLaunchYear')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('actualProductionLaunchDate')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionDate')
      .inputType(TrialInputType.COMMA_SPLIT_DATE)
      .filterable()
      .filter('inclusionYear')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('inclusionDate')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('actualClosingForInclusionsDate')
      .inputType(TrialInputType.COMMA_SPLIT_DATE)
      .filterable()
      .filter('actualClosingForInclusionsYear')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('actualClosingForInclusionsDate')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('actualFollowupEndDate')
      .inputType(TrialInputType.COMMA_SPLIT_DATE)
      .filterable()
      .filter('actualFollowupEndYear')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('actualFollowupEndDate')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialClosingDate')
      .inputType(TrialInputType.COMMA_SPLIT_DATE)
      .filterable()
      .filter('trialClosingYear')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('trialClosingDate')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('archiveDate')
      .inputType(TrialInputType.COMMA_SPLIT_DATE)
      .filterable()
      .filter('archiveYear')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('archiveDate')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('conventionTypes')
      .filterable()
      .filter('conventionTypes')
      .filterType(FilterType.IH_MULTISELECT)
      .inputType(TrialInputType.ARRAY)
      .type('convention-type-selected')
      .sortable()
      .sortField('conventionTypes')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('conventionSignatureByPromoterDate')
      .inputType(TrialInputType.COMMA_SPLIT_DATE)
      .filterable()
      .filter('conventionSignatureByPromoterYear')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('conventionSignatureByPromoterDate')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionsNumber')
      .filterable()
      .filter('inclusionsNumber')
      .filterType(FilterType.INPUT_TEXT_NUMBER)
      .sortable()
      .sortField('inclusionsNumbers.inclusionsNumber')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('nationalCoordinator')
      .filterable()
      .filter('nationalCoordinator')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('nationalCoordinator')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('cppSendingDate')
      .filterable()
      .filter('cppSendingYear')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('ari.CPPSendingDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('lastCPPAmendmentDate')
      .filterable()
      .filter('lastCPPAmendmentYear')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('lastCPPAmendmentDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ansmAuthorizationDate')
      .filterable()
      .filter('ansmAuthorizationYear')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('ari.ANSMAuthorizationDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('molecularAnomalyType')
      .filterable()
      .filter('molecularAnomalyType')
      .filterType(FilterType.DROPDOWN)
      .options(this.molecularAnomalyTypeFilter)
      .sortable()
      .sortField('molecularAnomalyTypes.code')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ivrs')
      .filterable()
      .filter('ivrs')
      .filterType(FilterType.DROPDOWN)
      .options(Data.yesOrNo)
      .sortable()
      .sortField('ivrs')
      .isTranslated()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialTreatments')
      .filterable()
      .filter('trialTreatment')
      .filterType(FilterType.IH_MULTISELECT)
      .type('trial-treatment')
      .sortable()
      .sortField('trialTreatments')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('researchObject')
      .filterable()
      .filter('researchObject')
      .filterType(FilterType.IH_MULTISELECT)
      .type('research-field-selected')
      .sortable()
      .sortField('researchObject')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialTechniqueTypes')
      .filterable()
      .filter('trialTechniqueType')
      .filterType(FilterType.IH_DROPDOWN)
      .type('trial-technique-type')
      .sortable()
      .sortField('trialTechniqueTypes')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('preInclusionNumber')
      .filterable()
      .filter('preInclusionNumber')
      .filterType(FilterType.INPUT_TEXT_NUMBER)
      .sortable()
      .sortField('preInclusionNumber')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialFollowUpDate')
      .inputType(TrialInputType.COMMA_SPLIT_DATE)
      .filterable()
      .filter('trialFollowUpDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('trialFollowUpDate')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialFollowUpComment')
      .filterable()
      .filter('trialFollowUpComment')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('trialFollowUpComment')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('speciality')
      .filterable()
      .filter('speciality')
      .filterType(FilterType.DROPDOWN)
      .options(this.specialityFilter)
      .sortable()
      .sortField('speciality')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('dataManager2')
      .filterable()
      .filter('dataManager2')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('dataManager2')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('projectManagerFrontDesk')
      .filterable()
      .filter('projectManagerFrontDesk')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('projectManagerFrontDesk')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('contractManager')
      .filterable()
      .filter('contractManager')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('contractManager')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('invoicingManager')
      .filterable()
      .filter('invoicingManager')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('invoicingManager')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ansmProvisionalSoumissionDate')
      .filterable()
      .filter('ansmProvisionalSoumissionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('ansmProvisionalSoumissionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ansmSoumissionDate')
      .filterable()
      .filter('ansmSoumissionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('ansmSoumissionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('cppProvisionalSoumissionDate')
      .filterable()
      .filter('cppProvisionalSoumissionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)      .sortable()
      .sortField('cppProvisionalSoumissionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('cppActualSoumissionDate')
      .filterable()
      .filter('cppActualSoumissionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)      .sortable()
      .sortField('cppActualSoumissionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('cppProvisionalAgreementDate')
      .filterable()
      .filter('cppProvisionalAgreementDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)      .sortable()
      .sortField('cppProvisionalAgreementDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('cppActualAgreementDate')
      .filterable()
      .filter('cppActualAgreementDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('cppActualAgreementDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('totalPreScreeningConsents')
      .filterable()
      .filter('totalPreScreeningConsents')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('totalPreScreeningConsents')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('totalTreatedPatients')
      .filterable()
      .filter('totalTreatedPatients')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('totalTreatedPatients')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('projectManager')
      .filterable()
      .filter('projectManager')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('projectManager')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('escalationExtension')
      .filterable()
      .filter('escalationExtension')
      .filterType(FilterType.DROPDOWN)
      .options(this.escalationExtension)
      .sortable()
      .sortField('escalationExtension')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstInHuman')
      .filterable()
      .filter('firstInHuman')
      .filterType(FilterType.DROPDOWN)
      .options(this.yesOrNo)
      .sortable()
      .sortField('firstInHuman')
      .isTranslated()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('hasMolecularPreScreening')
      .filterable()
      .filter('hasMolecularPreScreening')
      .filterType(FilterType.DROPDOWN)
      .options(this.yesOrNo)
      .sortable()
      .sortField('hasMolecularPreScreening')
      .isTranslated()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialTypesMethodologies')
      .filterable()
      .filter('trialTypesMethodologies')
      .filterType(FilterType.IH_MULTISELECT)
      .type('trial-types-methodology-selected')
      .sortable()
      .sortField('trialTypesMethodologies')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('provider')
      .filterable()
      .filter('provider')
      .filterType(FilterType.IH_DROPDOWN)
      .type('provider-cro')
      .sortable()
      .sortField('provider')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('numberCentersAllocatedByPromoter')
      .filterable()
      .filter('numberCentersAllocatedByPromoter')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('numberCentersAllocatedByPromoter')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('multiCentric')
      .filterable()
      .filter('multiCentric')
      .filterType(FilterType.IH_DROPDOWN)
      .type('multi-centric-selected')
      .sortable()
      .sortField('multiCentric')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('numberOfCenterAllocatedByPromoter')
      .filterable()
      .filter('numberOfCenterAllocatedByPromoter')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('numberOfCenterAllocatedByPromoter')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('numberClinicalTrialsGov')
      .filterable()
      .filter('numberClinicalTrialsGov')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('numberClinicalTrialsGov')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('referentDoctor')
      .filterable()
      .filter('referentDoctor')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('referentDoctor')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('arcManager')
      .filterable()
      .filter('arcManager')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('arcManager')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('arcManager2')
      .filterable()
      .filter('arcManager2')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('arcManager2')
      .build());

    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('arc2')
      .filterable()
      .filter('arc2')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('arc2')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('amr')
      .filterable()
      .filter('amr')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('amr')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('irc')
      .filterable()
      .filter('irc')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('irc')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ircs')
      .filterable()
      .filter('ircs')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('ircs')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('medicalResearchAssistant')
      .filterable()
      .filter('medicalResearchAssistant')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('medicalResearchAssistant')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('researchClinicTec1')
      .filterable()
      .filter('researchClinicTec1')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('researchClinicTec1')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('gp')
      .filterable()
      .filter('gp')
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('gp')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('targetOfTreatmentExperimental')
      .filterable()
      .filter('targetOfTreatmentExperimental')
      .filterType(FilterType.IH_MULTISELECT)
      .type('target-of-treatment-experimental-selected')
      .sortable()
      .sortField('targetOfTreatmentExperimental')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('therapeuticClassForDrugs')
      .filterable()
      .filter('therapeuticClassForDrugs')
      .filterType(FilterType.IH_MULTISELECT)
      .type('therapeuticClassForDrugs')
      .sortable()
      .sortField('therapeuticClassForDrugs')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ansmInformationDate')
      .filterable()
      .filter('ansmInformationDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('ansmInformationDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('initialEvaluationCppSessionDate')
      .filterable()
      .filter('initialEvaluationCppSessionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('initialEvaluationCppSessionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('insuranceExpiry')
      .filterable()
      .filter('insuranceExpiry')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .withDateFormatter()
      .filter('insuranceExpiry')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('preArchiveInvoicementIsValid')
      .filterable()
      .filter('preArchiveInvoicementIsValid')
      .filterType(FilterType.DROPDOWN)
      .options(this.noYesNoAnswerOptions)
      .isYesNoEnumTranslated()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('promoterFeasibilityQuestionnaireDespatchDate')
      .filterable()
      .filter('promoterFeasibilityQuestionnaireDespatchDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .withDateFormatter()
      .filter('promoterFeasibilityQuestionnaireDespatchDate')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('selectionCenterSelectedDate')
      .filterable()
      .filter('selectionCenterSelectedDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('selectionCenterSelectedDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('abandonedTrialDate')
      .filterable()
      .filter('abandonedTrialDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('abandonedTrialDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('committeeReunionDecisionDate')
      .filterable()
      .filter('committeeReunionDecisionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('committeeReunionDecisionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('cppAuthorizationMSDate')
      .filterable()
      .filter('cppAuthorizationMSDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('cppAuthorizationMSDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('substantialModification')
      .filterable()
      .filter('substantialModification')
      .filterType(FilterType.DROPDOWN)
      .options(this.yesOrNoOrNeither)
      .sortable()
      .sortField('substantialModification')
      .isTranslated()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ctisAuthorization')
      .filterable()
      .filter('ctisAuthorization')
      .filterType(FilterType.DROPDOWN)
      .options(this.yesOrNo)
      .sortable()
      .sortField('ctisAuthorization')
      .isTranslated()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ctisProvisionalSoumissionDate')
      .filterable()
      .filter('ctisProvisionalSoumissionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('ctisProvisionalSoumissionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ctisActualSoumissionDate')
      .filterable()
      .filter('ctisActualSoumissionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('ctisActualSoumissionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ctisProvisionalAgreementDate')
      .filterable()
      .filter('ctisProvisionalAgreementDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('ctisProvisionalAgreementDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('ctisActualAgreementDate')
      .filterable()
      .filter('ctisActualAgreementDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('ctisActualAgreementDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('nationalAgencyActualAgreementDate')
      .filterable()
      .filter('nationalAgencyActualAgreementDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('nationalAgencyActualAgreementDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('committeeEthicsActualAgreementDate')
      .filterable()
      .filter('committeeEthicsActualAgreementDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('committeeEthicsActualAgreementDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('csetCompleteFolderReceptionDate')
      .filterable()
      .filter('csetCompleteFolderReceptionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('csetCompleteFolderReceptionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('csetSoumissionDate')
      .filterable()
      .filter('csetSoumissionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('csetSoumissionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('drcAgreementDate')
      .filterable()
      .filter('drcAgreementDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('drcAgreementDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('provisionalProductionLaunch')
      .filterable()
      .filter('provisionalProductionLaunch')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('provisionalProductionLaunch')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('temporarySuspensionOfInclusions')
      .filterable()
      .filter('temporarySuspensionOfInclusions')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('temporarySuspensionOfInclusions')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('centerActivation')
      .filterable()
      .filter('centerActivation')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('centerActivation')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('audit')
      .filterable()
      .filter('audit')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('audit')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('inspection')
      .filterable()
      .filter('inspection')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('inspection')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('preArchiveDate')
      .filterable()
      .filter('preArchiveDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('preArchiveDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('receivedConventionDate')
      .filterable()
      .filter('receivedConventionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('receivedConventionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('revisedConventionDate')
      .filterable()
      .filter('revisedConventionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('revisedConventionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('conventionStatus')
      .filterable()
      .filter('conventionStatus')
      .filterType(FilterType.IH_MULTISELECT)
      .type('convention-status-selected')
      .sortable()
      .sortField('conventionStatus')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('completeReceipt')
      .filterable()
      .filter('completeReceipt')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('completeReceipt')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('sendingConventionDate')
      .filterable()
      .filter('sendingConventionDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('sendingConventionDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('preArchiveLastInvoicementDate')
      .filterable()
      .filter('preArchiveLastInvoicementDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('preArchiveLastInvoicementDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('coordoPrcDate')
      .filterable()
      .filter('coordoPrcDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('coordoPrcDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('comopAgreementDate')
      .filterable()
      .filter('comopAgreementDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('comopAgreementDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('otherTreatmentExperimental')
      .filterable()
      .filter('otherTreatmentExperimental')
      .filterType(FilterType.IH_MULTISELECT)
      .type('trial-treatment-experimental-selected')
      .sortable()
      .sortField('otherTreatmentExperimental')
      .withDateFormatter()
      .inputType(TrialInputType.ARRAY)
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('trialCommitteesBackup')
      .filterable()
      .filter('trialCommitteesBackup')
      .filterType(FilterType.IH_MULTISELECT)
      .type('trial-committee-selected')
      .sortable()
      .sortField('trialCommitteesBackup')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('promoter2')
      .filterable()
      .filter('promoter2')
      .filterType(FilterType.IH_DROPDOWN)
      .type('provider')
      .sortable()
      .sortField('promoter2')
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('csetAgreementDate')
      .filterable()
      .filter('csetAgreementDate')
      .filterType(FilterType.DROPDOWN)
      .options(this.years)
      .sortable()
      .sortField('csetAgreementDate')
      .withDateFormatter()
      .build());
    possibleHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionHistologies')
      .filterable()
      .filter('inclusionHistologies')
      .filterType(FilterType.IH_DROPDOWN)
      .type('inclusion-histology')
      .sortable()
      .sortField('inclusionHistologies')
      .build());
    this.possibleHeaders = this.config.buildTable(possibleHeaders);
    this.headersReady = true;
  }

  saveHeadersLayout() {
    localStorage.setItem(this.target, JSON.stringify(this.effectiveHeaders));
  }


  isTrialCheckable(entity: any) {
    if (entity.state === ('Ouvert aux inclusions') || entity.state === ('Suspendu')) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getExportPageModule(): string {
    return 'src/app/trial/trial-list/trial-list.component';
  }

  getDeleteMessage(): string {
    return 'MODULE_TRIALS_DIALOG_WARNING_TRIAL_WILL_BE_DELETED';
  }

  getService(): any {
    return this.trialService;
  }


  getFilteredList() {
    this.first = 0;
    this.page = 0;
    this.getList();
    this.rollBack.saveGlobalFilter('trialGlobalFilter', this.filters);
  }

  resetFilter() {
    this.filters = new TrialGlobalFilter();
    this.calendarComponentList.forEach(calendar => calendar.reset());
    this.getFilteredList();
  }

  getList() {
    if (this.trialPageFetchObservable == null) {
      this.trialPageFetchObservable = this.trialService.loadList(this.filters, this.sortBy, this.orderBy, this.page, this.limit);
      this.trialPageFetchObservable.subscribe(res => {
        this.values = res.content;
        this.totalNumber = res.totalElements;
        this.molecularAnomalyTypeFilter = res.molecularAnomalyTypeFilter;
        this.establishmentFilter = res.establishmentFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        this.stateFilter = this.getTranslatedFilterList(res.stateFilter);
        this.priorityNumberTypeFilter = res.priorityNumberTypeFilter;
        this.promoterFilter = res.promoterFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        this.promoterTypeFilter = this.getTranslatedFilterList(res.promoterTypeFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())));
        this.siteFilter = res.siteFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        this.phaseFilter = this.getTranslatedFilterList(res.phaseFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())));
        this.researchTypeFilter = this.getTranslatedFilterList(res.researchTypeFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())));
        this.researchNatureFilter = this.getTranslatedFilterList(res.researchNatureFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())));
        this.illnessStateFilter = res.illnessStateFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        this.serviceFilter = res.serviceFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        this.blindedFilter = this.getTranslatedFilterList(res.blindedFilter);
        this.ivrsFilter = this.getTranslatedFilterList(res.ivrsFilter);
        this.specialityFilter = res.specialityFilter.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
        this.organFamilyAndOrganFilter = res.organFamilyAndOrganFilter;
        this.filtersReady = true;
        this.trialService.setAllTrials(this.values);
        this.trialPageFetchObservable = null;
        if (this.values == null || this.values.length === 0) {
          $('#sectionToPrint > div > div > div > div > div > p-table > div > div > div > div.ui-table-scrollable-header.ui-widget-header').css('overflow', 'auto');
        } else {
          $('#sectionToPrint > div > div > div > div > div > p-table > div > div > div > div.ui-table-scrollable-header.ui-widget-header').css('overflow', 'visible');
        }
        this.buildTableHeaders();
        this.showButtons = true;
        this.cdr.detectChanges();
      }, error => {
        console.error('An error occurred while loading trial list.' + error);
      });
   }
  }

  getTranslatedFilterList(list): LabelValue[] {
    const listTranslated: LabelValue[] = [];
    for (const element of list) {
      const item = {
        label: this.translateService.getTranslationString(!isUndefined(element.label) ? element.label : element.toString()),
        value: !isUndefined(element.value) ? element.value : element
      };
      listTranslated.push(item);
    }
    return listTranslated.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  getFilteredListByOrganAndOrganFamily() {
    if (this.filters.trialOrgan !== null) {
      if (this.filters.trialOrgan.indexOf('OrganFamily') === -1) {
        this.filters.organ = this.filters.trialOrgan;
      } else {
        this.filters.organFamily = this.filters.trialOrgan.slice(12, this.filters.trialOrgan.length);
      }
    } else {
      this.filters.organ = -1;
      this.filters.organFamily = -1;
    }
    this.getList();
  }

  getOrganList() {
    this.organService.getOrgansAndOrganFamilies().subscribe(
      res => {
        this.organsList = res;
        this.organsList.splice(0, 0, {label: ' ', value: -1});
        this.maxSelectedLabels = res.length;
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading organ list.' + error);
      }
    );
  }


  editTrial(trial: TrialListData) {
    this.router.navigate(['/trial-details'], {queryParams: {hhhId: trial.hhhId}}).then();
    this.trialService.trialHhhId = trial.hhhId;
    this.trialFixedCostService.setIdentificationInformation(trial.localIdentifier, trial.promoter1, trial.protocolNumber);
    this.trialCounterPartService.setIdentificationInformation(trial.localIdentifier, trial.promoter1, trial.protocolNumber);
  }

  navigateToTrackingTable(trial: TrialListData) {
    this.router.navigate(['/trial-details/tracking-table'], {queryParams: {hhhId: trial.hhhId}}).then();
    this.trialService.trialHhhId = trial.hhhId;
  }

  displayAddTrialDialogue() {
    this.trialService.readOnlyMode = false;
    const formComponent = TrialIdentificationDialogComponent.display(this.formDialogContainer, this.cfr);
    formComponent.onAdd((newTrial: TrialListData) => {
      this.values.splice(0, 0, newTrial);
    });
  }

  updateFavorite(trial: TrialListData) {
    this.trialService.updateTrialFavorite(trial.hhhId).subscribe(() => {
        trial.favorite = !trial.favorite;
        this.values.sort((a, b) => (a.favorite < b.favorite) ? 1 : ((b.favorite < a.favorite) ? -1 : 0));
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      }
    );
  }

  generatePromoterTypeOptions(promoterTypeList: PromoterType[]) {
    for (const promoterType of promoterTypeList) {
      this.promoterTypeOptions.push({
        label: promoterType.nameFr,
        value: promoterType.hhhId
      });
    }
  }

  displayDeleteDialogue(trial: TrialListData) {
    this.displayDeleteDialogueFromInfos(trial.hhhId, '', trial);
  }

  refreshAfterDelete(entity: TrialListData): void {
    const index = this.values.findIndex((trial: TrialListData) => trial.hhhId === entity.hhhId);
    this.sharedService.setInformationInLocalStorage('Etudes', 'Supprimer', entity.localIdentifier);
    this.values.splice(index, 1);
    this.totalNumber--;
  }

  displayContent(col: DynamicTableHeader, value: any): string {
    let content = value;
    if (col.dateFormatter) {
      content = this.datePipe.transform(value, 'dd/MM/yyyy');
    }
    if (col.isTranslated) {
      if (value === null) {
        return null;
      }
      content = value
        ? this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE')
        : this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE');
    }
    if (col.isYesNoEnumTranslated) {
      if (value === null) {
        return null;
      }
      if (value === "YES") {
        return this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE');
      }
      if (value === "NO") {
        return this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE');
      }
      if (value === "NO_ANSWER") {
        return this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_ANSWER_UFCASE');
      }
    }
    return content;
  }

  siteHasChanged(data: string, counter: number) {
    if (data.indexOf(',') === -1) {
      return false;
    }
    if (counter === data.split(',').length) {
      return false;
    }
    if (data.split(',')[counter + 1] === undefined || data.split(',')[counter] === undefined) {
      return false;
    }

    return (+data.split(',')[counter + 1].split(' ')[0] !== +data.split(',')[counter].split(' ')[0]);
  }

  private extractNoYesOptions() {
    this.yesOrNoOrNeither = [
      {label: '', value: null},
      {
        label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE'),
        value: false
      },
      {
        label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE'),
        value: true
      }
    ];
  }


  displayImportTrials() {
    const formComponent = TrialImportComponent.display(this.formDialogContainer, this.cfr);
    formComponent.importTrialsDialogueVisible = true;
  }

  updateIsTrialSelectedForMobileApplication(trialHhhId: number) {
    this.trialService.updateIsTrialSelectedForMobileApplication(trialHhhId).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
    });
  }

  displayBtn(btnId: string): boolean {
    if (!this.config || !this.config.buttons) {
      return false;
    }
    return this.config.displayBtn(btnId);
  }

  exportTrials(event: any) {
    const siteId = (event.exportType === ExportTypesEnum.PIRAMIG_EXPORT || event.exportType === ExportTypesEnum.SIGREC_EXPORT) ? event.siteId : null;
    const request = new ExportRequestBuilder<any>()
      .objectId(siteId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.getOrderedColumns(this.target), this.limit, this.page)
      .sortBy(this.sortBy)
      .orderBy(this.orderBy)
      .filters(this.getFilterExport(event))
      .build();
    this.trialService.exportTrials(request).subscribe();
  }

  public getOrderedColumns(target: string): string[] {
    return JSON.parse(localStorage.getItem(target)).map(header => header.field);
  }

  getFilterExport(event: any): any {
    switch (event.exportType) {
      case ExportTypesEnum.CUSTOM_EXPORT:
      case ExportTypesEnum.SIGREC_EXPORT:
      case ExportTypesEnum.PIRAMIG_EXPORT:
      case ExportTypesEnum.GLOBAL_EXPORT:
        return event.filter;
      default :
        return this.filters;
    }
  }

}

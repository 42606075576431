import {RequestPage} from './request-page';

export class RequestPageBuilder<T> {

  _request: RequestPage<T>;

  constructor() {
    this._request = new RequestPage<T>();
  }

  page(page: number): RequestPageBuilder<T> {
    this._request.page = page;
    return this;
  }

  limit(limit: number): RequestPageBuilder<T> {
    this._request.limit = limit;
    return this;
  }

  sortBy(sortBy: string): RequestPageBuilder<T> {
    this._request.sortBy = sortBy;
    return this;
  }

  secondSortBy(secondSortBy: string): RequestPageBuilder<T> {
    this._request.secondSortBy = secondSortBy;
    return this;
  }

  orderBy(orderBy: string): RequestPageBuilder<T> {
    this._request.orderBy = orderBy;
    return this;
  }

  filters(filters: T): RequestPageBuilder<T> {
    this._request.filters = filters;
    return this;
  }

  objectId(hhhId: number): RequestPageBuilder<T> {
    this._request.objectId = hhhId;
    return this;
  }

  condition(c: boolean): RequestPageBuilder<T> {
    this._request.condition = c;
    return this;
  }
  secondCondition(secondCondition: boolean): RequestPageBuilder<T> {
    this._request.secondCondition = secondCondition;
    return this;
  }

  type(type: string): RequestPageBuilder<T> {
    this._request.type = type;
    return this;
  }

  fromDate(fromDate: string): RequestPageBuilder<T> {
    this._request.fromDate = fromDate;
    return this;
  }

  toDate(toDate: string): RequestPageBuilder<T> {
    this._request.toDate = toDate;
    return this;
  }

  build(): RequestPage<T> {
    return this._request;
  }

}

import {Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {TheoreticalVisit} from '../../../entity/theoretical-calendar/theoretical-visit';
import {TheoreticalVisitFormComponent} from '../theoretical-visit-form/theoretical-visit-form.component';
import {TheoreticalCalendar} from '../../../entity/theoretical-calendar/theoretical-calendar';
import {SharedService} from '../../../../shared/services/shared.service';
import {TheoreticalCalendarService} from '../../../service/theoretical-calendar.service';
import {DataService} from '../../../../shared/services/data-service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {AmendmentVerificationComponent} from '../../../../modals/amendment-verification/amendment-verification.component';
import {Router} from '@angular/router';
import {TrialProtocolService} from '../../../services/trial-protocol.service';
import {TrialObservablesService} from '../../../services/trial-observables.service';
import {isNullOrUndefined} from 'util';
import {LabelValue} from '../../../../shared/entity/label-value';
import {ArmService} from '../../../services/arm.service';
import {isNumeric} from 'rxjs/internal-compatibility';
import {TrialService} from '../../../services/trial.service';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {TheoreticalVisitAct} from '../../../entity/theoretical-calendar/theoretical-visit-act';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DataItemService} from '../../../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-theoretical-visit-accordion-tab',
  templateUrl: './theoretical-visit-accordion-tab.component.html',
  styleUrls: ['./theoretical-visit-accordion-tab.component.css'],
})
export class TheoreticalVisitAccordionTabComponent implements OnInit, OnDestroy {

  amendmentsEnabled = true;
  completedAndInvoiceableVisitsAndActsEnabled = true;
  additionalVisitsEnabled = true;
  saveNowSelectedByDefault = false;

  @ViewChild('addTheoreticalVisitDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;

  @Input() theoreticalVisits: TheoreticalVisit[];
  @Input() sectionTitle: string;
  @Input() calendar: TheoreticalCalendar;
  @Input() periodType: string;
  @Input() armHhhId: any;
  @Input() protocolId: number;
  @Input() targetTrialProtocol: number;
  @Input() disabledArms: Array<number> = new Array<number>();

  updateType: string;
  displayAmendmentDialog = false;
  displayVerification = false;
  trialId: number;
  disableUpdateNowRadioButton = false;
  availableTrialProtocols: LabelValue[] = [];

  @Output() amendmentSaveEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private theoreticalCalendarService: TheoreticalCalendarService,
              private sharedService: SharedService,
              private dataService: DataService,
              private cfr: ComponentFactoryResolver,
              private canDeactivateService: CanDeactivateService,
              private router: Router,
              public trialService: TrialService,
              private armService: ArmService,
              private trialProtocolService: TrialProtocolService,
              private trialObservablesService: TrialObservablesService,
              private dynamicConfigService: DynamicConfigService,
              private dataItemService: DataItemService,
              public trialPageLockService: TrialPageLockService) {
  }

  private static pushItemsIntoList(initialList: any, destinationList: any) {
    for (const trialActHhhId of initialList) {
      destinationList.push(trialActHhhId);
    }
  }

  private static getIndexByItemHhhId(list: any[], itemHhhId: number): number {
    for (let index = 0; index < list.length; index++) {
      if (list[index].hhhId === itemHhhId) {
        return index;
      }
    }
    return -1;
  }

  ngOnInit() {
    this.initProperties();
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
    this.initAvailableTrialProtocols();
    this.dataService.amendmentAction.subscribe(res => {
      if (res && this.periodType === 'BEFORE_INCLUSION') {
        this.getIsThereAnyUsedProtocol();
        this.displayAmendmentDialog = true;
        if (this.availableTrialProtocols.length === 0 || this.saveNowSelectedByDefault) {
          this.updateType = 'saveNow';
        } else {
          this.updateType = 'saveAfter';
          if (this.targetTrialProtocol) {
            this.calendar.trialProtocolHhhId = this.targetTrialProtocol;
          }
        }
      }
    });
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.completedAndInvoiceableVisitsAndActsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.completedAndInvoiceableVisitsAndActsEnabled);
      this.additionalVisitsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.additionalVisitsEnabled);
      this.saveNowSelectedByDefault = this.dynamicConfigService.getProperty(PropertyEnum.saveNowSelectedByDefault);
    });
  }

  ngOnDestroy(): void {
    if (this.periodType === 'BEFORE_INCLUSION') {
      this.dataService.saveAmendment(false);
    }
  }

  verifyClient = (clients: string[]) => this.dynamicConfigService.verifyClient(clients);

  createAddDialogComponent(armHhhId: any, $event: MouseEvent) {
    $event.stopPropagation();
    const addDialogFactory = this.cfr.resolveComponentFactory(TheoreticalVisitFormComponent);
    const addDialogComponentRef = this.dialogContainer.createComponent(addDialogFactory);
    const theoreticalVisitFormComponent = addDialogComponentRef.instance;
    theoreticalVisitFormComponent.displayTheoreticalVisitDialog = true;
    theoreticalVisitFormComponent.theoreticalVisitAdd.armHhhId = armHhhId;
    theoreticalVisitFormComponent.theoreticalVisitAdd.type = this.periodType;
    theoreticalVisitFormComponent.theoreticalVisitAdd.periodType = '0';
    theoreticalVisitFormComponent.theoreticalVisitAdd.armHhhId = this.armHhhId;
    theoreticalVisitFormComponent.theoreticalVisitAdd.theoreticalCalendarHhhId = this.calendar.hhhId;
    theoreticalVisitFormComponent.theoreticalCalendar = this.calendar;
    theoreticalVisitFormComponent.targetTrialProtocol = this.protocolId;
    theoreticalVisitFormComponent.prepareDateReferences();
    theoreticalVisitFormComponent.theoreticalVisits = this.calendar.theoreticalVisitBeforeInclusionList
      .concat(this.calendar.theoreticalVisitAfterInclusionList, ...this.calendar.theoreticalVisitInInclusionDtoList.map(value => value.theoreticalVisits));
    theoreticalVisitFormComponent.addSuccess.subscribe((theoreticalVisit) => {
      this.canDeactivateService.canBeDeactivated = false;
      this.theoreticalVisits.push(theoreticalVisit);
      this.theoreticalVisits.sort((theoreticalVisit1, theoreticalVisit2) => {
        return +theoreticalVisit1.displayHowManyDays.substring(1) > +theoreticalVisit2.displayHowManyDays.substring(1) ? 1 : -1;
      });
      theoreticalVisit.trialActsHhhIds = [];
      theoreticalVisit.trialActsToEdit = [];
      this.calendar.theoreticalVisitDtoList.push(theoreticalVisit);
    });
  }

  addToEditVisitList(editedTheoreticalVisit: TheoreticalVisit) {
    editedTheoreticalVisit.trialActsHhhIds = [];
    this.calendar.theoreticalVisitToEditDtoList.push(editedTheoreticalVisit);
  }

  addToCloneVisitList(object: any) {
    const firstPeriodInDays = this.calculatePeriodInDays(
      object.clonedTheoreticalVisit.firstPeriodType,
      object.clonedTheoreticalVisit.firstPeriod,
      object.originalVisitPeriodInDays
    );
    const periodInDays = this.calculatePeriodInDays(
      object.clonedTheoreticalVisit.periodType,
      object.clonedTheoreticalVisit.period,
      0
    );
    object.clonedTheoreticalVisit.theoreticalCalendarHhhId = this.calendar.hhhId;
    let clonedVisitPeriodInDays = firstPeriodInDays;
    for (let i = 0; i < object.clonedTheoreticalVisit.duplicateNumber; i++) {
      clonedVisitPeriodInDays += periodInDays;
      const theoreticalVisit: TheoreticalVisit = new TheoreticalVisit();
      theoreticalVisit.type = object.clonedTheoreticalVisit.selectedArm;
      if (isNumeric(theoreticalVisit.type)) {
        theoreticalVisit.type = 'IN_INCLUSION';
      }
      theoreticalVisit.hhhId = object.clonedTheoreticalVisit.theoreticalVisitHhhId;
      theoreticalVisit.name = object.clonedTheoreticalVisit.name + ' (' + (i + 1) + ')';
      if (isNumeric(object.clonedTheoreticalVisit.selectedArm)) {
        theoreticalVisit.armHhhId = object.clonedTheoreticalVisit.selectedArm;
      }
      theoreticalVisit.displayHowManyDays = clonedVisitPeriodInDays >= 0 ? 'J+' + clonedVisitPeriodInDays : 'J' + clonedVisitPeriodInDays;
      theoreticalVisit.modificationType = 'clone';
      theoreticalVisit.periodMargin = object.clonedTheoreticalVisit.periodMargin;
      let cloneSource = this.calendar.theoreticalVisitBeforeInclusionList.find(v => v.hhhId === object.clonedTheoreticalVisit.theoreticalVisitHhhId);
      if (!cloneSource) {
        cloneSource = this.calendar.theoreticalVisitAfterInclusionList.find(v => v.hhhId === object.clonedTheoreticalVisit.theoreticalVisitHhhId);
      }
      if (!cloneSource) {
        for (const theoreticalVisitInInclusion of this.calendar.theoreticalVisitInInclusionDtoList) {
          cloneSource = theoreticalVisitInInclusion.theoreticalVisits.find(v => v.hhhId === object.clonedTheoreticalVisit.theoreticalVisitHhhId);
          if (cloneSource) {
            break;
          }
        }
      }
      if (cloneSource) {
        if (!theoreticalVisit.visitActsToAdd) {
          theoreticalVisit.visitActsToAdd = [];
        }
        if (cloneSource.visitActsToAdd) {
          cloneSource.visitActsToAdd.forEach(act => theoreticalVisit.visitActsToAdd.push(act));
        }
        if (cloneSource.visitActs) {
          cloneSource.visitActs.forEach(act => theoreticalVisit.visitActsToAdd.push(act));
        }
      }
      if (theoreticalVisit.type === 'AFTER_INCLUSION') {
        this.calendar.theoreticalVisitAfterInclusionList.push(theoreticalVisit);
      } else if (theoreticalVisit.type === 'BEFORE_INCLUSION') {
        this.calendar.theoreticalVisitBeforeInclusionList.push(theoreticalVisit);
      } else {
        for (const theoreticalVisitInInclusion of this.calendar.theoreticalVisitInInclusionDtoList) {
          if (Number(object.clonedTheoreticalVisit.selectedArm) === theoreticalVisitInInclusion.armHhhId) {
            theoreticalVisitInInclusion.theoreticalVisits.push(theoreticalVisit);
            break;
          }
        }
      }
      this.theoreticalVisits.sort((theoreticalVisit1, theoreticalVisit2) => {
        return +theoreticalVisit1.displayHowManyDays.substring(1) > +theoreticalVisit2.displayHowManyDays.substring(1) ? 1 : -1;
      });
    }
    this.calendar.theoreticalVisitToCloneDtoList.push(object.clonedTheoreticalVisit);
  }

  addToDeletableList(object: any) {
    this.canDeactivateService.canBeDeactivated = false;
    if (object.entityClassName === 'TheoreticalVisit') {
      this.calendar.theoreticalVisitToDeleteDtoList.push(object.entityHhhId);
    } else {
      this.calendar.theoreticalVisitActsToDeleteHhhIds.push(object.entityHhhId);
    }
  }

  addToModifiedActsList(object: any) {
    if (object.visitModificationType === 'add') {
      const visitIndex = TheoreticalVisitAccordionTabComponent.getIndexByItemHhhId(this.calendar.theoreticalVisitDtoList, object.theoreticalVisitHhhId);
      if (visitIndex !== -1) {
        const visitActIndex = this.calendar.theoreticalVisitDtoList[visitIndex].trialActsHhhIds.indexOf(object.theoreticalVisitAct.hhhId);
        if (visitActIndex !== -1) {
          this.calendar.theoreticalVisitDtoList[visitIndex].trialActsHhhIds.splice(visitActIndex, 1);
          object.theoreticalVisitAct.visitHhhId = object.theoreticalVisitHhhId;
          this.calendar.theoreticalVisitDtoList[visitIndex].trialActsToEdit.push(object.theoreticalVisitAct);
        }
      }
    } else {
      const visitIndex = TheoreticalVisitAccordionTabComponent.getIndexByItemHhhId(this.calendar.theoreticalVisitToEditDtoList, object.theoreticalVisitHhhId);
      if (visitIndex !== -1) {
        const visitActIndex = this.calendar.theoreticalVisitToEditDtoList[visitIndex].trialActsHhhIds.indexOf(object.theoreticalVisitAct.hhhId);
        if (visitActIndex !== -1) {
          this.calendar.theoreticalVisitToEditDtoList[visitIndex].trialActsHhhIds.splice(visitActIndex, 1);
          object.theoreticalVisitAct.visitHhhId = object.theoreticalVisitHhhId;
          this.calendar.theoreticalVisitActsToAdd.push(object.theoreticalVisitAct);
        }
      } else {
        this.calendar.theoreticalVisitActsToEdit.push(object.theoreticalVisitAct);
      }
    }
  }

  addActsToVisitList(object: any) {
    if (object.theoreticalVisit.modificationType !== 'add') {
      const index = TheoreticalVisitAccordionTabComponent.getIndexByItemHhhId(this.calendar.theoreticalVisitToEditDtoList, object.theoreticalVisit.hhhId);
      if (index !== -1) {
        TheoreticalVisitAccordionTabComponent.pushItemsIntoList(object.trialActIds, this.calendar.theoreticalVisitToEditDtoList[index].trialActsHhhIds);
      } else {
        const newTheoreticalVisit: TheoreticalVisit = new TheoreticalVisit();
        newTheoreticalVisit.hhhId = object.theoreticalVisit.hhhId;
        newTheoreticalVisit.trialActsHhhIds = object.trialActIds;
        this.calendar.theoreticalVisitToEditDtoList.push(newTheoreticalVisit);
      }
    } else if (object.theoreticalVisit.modificationType === 'add') {
      const index = this.calendar.theoreticalVisitDtoList.indexOf(object.theoreticalVisit);
      TheoreticalVisitAccordionTabComponent.pushItemsIntoList(object.trialActIds, this.calendar.theoreticalVisitDtoList[index].trialActsHhhIds);
    }
  }

  spliceTemporaryVisitAct(object: any) {
    const visitIsNew = object.theoreticalVisit.modificationType === 'add' || (object.act.modificationType === 'edit' && !object.act.visitHhhId);
    if (visitIsNew) {
      this.spliceActFromNewTheoreticalVisit(object);
    } else {
      this.spliceActFromExistingTheoreticalVisit(object);
    }
  }

  addModifiedVisitToVisitList(object: any) {
    const index = this.calendar.theoreticalVisitDtoList.indexOf(object.oldVisit);
    this.calendar.theoreticalVisitDtoList[index].name = object.modifiedVisit.name;
    this.calendar.theoreticalVisitDtoList[index].comment = object.modifiedVisit.comment;
    this.calendar.theoreticalVisitDtoList[index].period = object.modifiedVisit.period;
    this.calendar.theoreticalVisitDtoList[index].periodType = object.modifiedVisit.periodType;
  }

  saveAmendment() {
    this.displayAmendmentDialog = false;
    if (this.amendmentsEnabled && this.updateType === 'saveAfter') {
      const usedArmsIds: number[] = this.calendar.theoreticalVisitInInclusionDtoList.map(arm => arm.armHhhId);
      if (usedArmsIds.length > 0) {
        this.armService.armsNamesRemainByProtocol(this.calendar.trialProtocolHhhId, usedArmsIds).subscribe(result => {
          if (result.value) {
            this.processSavingAmendment();
          } else {
            this.sharedService.showWarning('ARMS_NOT_EXISTING_IN_TC_PROTOCOL');
          }
        });
      } else {
        this.processSavingAmendment();
      }
    } else {
      this.processSavingAmendment();
    }
  }

  processSavingAmendment() {
    // this.amendmentSaveEvent.emit();
    this.displayAmendmentDialog = false;
    if (!this.amendmentsEnabled) {
      if (this.updateType === 'saveAfter'
        && (!this.calendar.amendment
          || this.calendar.amendment.trim() === '')) {
        this.displayVerification = true;
      } else {
        this.calendar.theoreticalVisitBeforeInclusionList = [];
        this.calendar.theoreticalVisitInInclusionDtoList = [];
        this.calendar.theoreticalVisitAfterInclusionList = [];
        this.theoreticalCalendarService.updateAmendmentVersion(this.updateType, this.calendar).subscribe(() => {
          this.amendmentSaveEvent.emit();
          this.calendar.theoreticalVisitDtoList = [];
          this.calendar.theoreticalVisitToCloneDtoList = [];
          this.calendar.theoreticalVisitToDeleteDtoList = [];
          this.calendar.theoreticalVisitToEditDtoList = [];
          this.calendar.theoreticalVisitActsToAdd = [];
          this.calendar.theoreticalVisitActsToDeleteHhhIds = [];
          this.calendar.theoreticalVisitActsToEdit = [];
          this.sharedService.showSuccess();
        }, error => {
          this.sharedService.showFailure(error.error.message);
          console.error(error);
        });
      }
    } else {
      if (isNullOrUndefined(this.calendar.trialProtocolHhhId)) {
        this.calendar.trialProtocolHhhId = this.trialProtocolService.selectedTheoreticalCalendarProtocolHhhId;
      }
      if (this.updateType === 'saveAfter') {
        this.calendar.theoreticalVisitDtoList = [];
        this.calendar.theoreticalVisitActsToEdit = [];
        this.calendar.theoreticalVisitBeforeInclusionList.forEach(v => {
          if (!this.calendar.theoreticalVisitToDeleteDtoList.find(id => id === v.hhhId)) {
            this.prepareVisitForProtocolMigration(v);
          }
        });
        this.calendar.theoreticalVisitInInclusionDtoList.forEach(arm => arm.theoreticalVisits
          .filter(v => !this.calendar.theoreticalVisitToDeleteDtoList.find(id => id === v.hhhId))
          .forEach(visit => {
            this.prepareVisitForProtocolMigration(visit);
          }));
        this.calendar.theoreticalVisitAfterInclusionList.forEach(v => {
          if (!this.calendar.theoreticalVisitToDeleteDtoList.find(id => id === v.hhhId)) {
            this.prepareVisitForProtocolMigration(v);
          }
        });
        this.calendar.theoreticalVisitToEditDtoList = [];
        this.calendar.theoreticalVisitToDeleteDtoList = [];
        this.calendar.theoreticalVisitActsToDeleteHhhIds = [];
        this.calendar.theoreticalVisitActsToEdit = [];
      }
      this.calendar.theoreticalVisitBeforeInclusionList = [];
      this.calendar.theoreticalVisitAfterInclusionList = [];
      this.calendar.theoreticalVisitInInclusionDtoList = [];
      this.theoreticalCalendarService.updateTheoreticalCalendarByProtocolHhhId(this.calendar, this.trialId, this.updateType).subscribe((res) => {
        this.trialObservablesService.onTheoreticalCalendarSave.emit();
        this.amendmentSaveEvent.emit(this.calendar.trialProtocolHhhId);
        this.targetTrialProtocol = null;
        this.calendar.theoreticalVisitDtoList = [];
        this.calendar.theoreticalVisitToCloneDtoList = [];
        this.calendar.theoreticalVisitToDeleteDtoList = [];
        this.calendar.theoreticalVisitToEditDtoList = [];
        this.calendar.theoreticalVisitActsToAdd = [];
        this.calendar.theoreticalVisitActsToDeleteHhhIds = [];
        this.calendar.theoreticalVisitActsToEdit = [];
        this.initAvailableTrialProtocols();
        this.sharedService.showSuccess();
      }, error => {
        this.sharedService.showFailure();
        console.error(error);
      });
    }
  }

  private prepareVisitForProtocolMigration(v: TheoreticalVisit) {
    if (v.modificationType !== 'clone') {
      v.parentId = v.hhhId;
    }
    this.calendar.theoreticalVisitDtoList.push(v);
    if (v.visitActs && v.visitActs.length > 0) {
      if (v.visitActsToAdd == null) {
        v.visitActsToAdd = [];
      }
      v.visitActs.filter(a => !this.calendar.theoreticalVisitActsToDeleteHhhIds.find(actId => actId === a.hhhId)).forEach(a => {
        a.trialActHhhId = a.trialActHhhId ? a.trialActHhhId : a.hhhId;
        a.hhhId = null;
        v.visitActsToAdd.push(a);
      });
    }
  }

  displayCalendar() {
    this.displayVerification = false;
    this.amendmentSaveEvent.emit();
  }

  spliceFromCloneVisitList(theoreticalVisit: TheoreticalVisit) {
    let index = -1;
    for (let i = 0; i < this.calendar.theoreticalVisitToCloneDtoList.length; i++) {
      if (this.calendar.theoreticalVisitToCloneDtoList[i].theoreticalVisitHhhId === theoreticalVisit.hhhId) {
        index = i;
      }
    }
    if (index !== -1) {
      const duplicateNumber = this.calendar.theoreticalVisitToCloneDtoList[index].duplicateNumber;
      if (duplicateNumber > 1) {
        this.calendar.theoreticalVisitToCloneDtoList[index].duplicateNumber -= 1;
      } else {
        this.calendar.theoreticalVisitToCloneDtoList.splice(index, 1);
      }
      const indexInTheoreticalVisits = this.theoreticalVisits.indexOf(theoreticalVisit);
      this.theoreticalVisits.splice(indexInTheoreticalVisits, 1);
    }
  }

  spliceFromAddVisitList(theoreticalVisit: TheoreticalVisit) {
    const indexInTheoreticalVisitDtoList = this.calendar.theoreticalVisitDtoList.indexOf(theoreticalVisit);
    this.calendar.theoreticalVisitDtoList.splice(indexInTheoreticalVisitDtoList, 1);
    const indexInTheoreticalVisits = this.theoreticalVisits.indexOf(theoreticalVisit);
    this.theoreticalVisits.splice(indexInTheoreticalVisits, 1);
  }

  displayAmendmentVerificationDialog() {
    const addDialogFactory = this.cfr.resolveComponentFactory(AmendmentVerificationComponent);
    this.dialogContainer.createComponent(addDialogFactory);
  }

  private spliceActFromExistingTheoreticalVisit(object: any) {
    const actIdentity = object.act.hhhId + '-' + object.act.type;
    const visitIndex = TheoreticalVisitAccordionTabComponent.getIndexByItemHhhId(this.calendar.theoreticalVisitToEditDtoList, object.theoreticalVisit.hhhId);
    const visitActIndexInAddedActList = this.calendar.theoreticalVisitToEditDtoList[visitIndex].trialActsHhhIds.indexOf(actIdentity);
    const visitActIndexInAddedAndModifiedActList = this.calendar.theoreticalVisitToEditDtoList[visitIndex].trialActsToEdit.indexOf(actIdentity);
    if (visitActIndexInAddedActList !== -1) {
      this.calendar.theoreticalVisitToEditDtoList[visitIndex].trialActsHhhIds.splice(visitActIndexInAddedActList, 1);
    } else if (visitActIndexInAddedAndModifiedActList !== -1) {
      this.calendar.theoreticalVisitToEditDtoList[visitIndex].trialActsToEdit.splice(visitActIndexInAddedActList, 1);
    }
  }

  private spliceActFromNewTheoreticalVisit(object: any) {
    const visitIndex = this.calendar.theoreticalVisitDtoList.indexOf(object.theoreticalVisit);
    const visitActIndex = this.calendar.theoreticalVisitDtoList[visitIndex].trialActsHhhIds.indexOf(object.act.hhhId);
    const modifiedVisitActIndex = TheoreticalVisitAccordionTabComponent.getIndexByItemHhhId(
      this.calendar.theoreticalVisitDtoList[visitIndex].trialActsToEdit, object.act.hhhId);
    if (visitActIndex !== -1) {
      this.calendar.theoreticalVisitDtoList[visitIndex].trialActsHhhIds.splice(visitActIndex, 1);
    } else if (modifiedVisitActIndex !== -1) {
      this.calendar.theoreticalVisitDtoList[visitIndex].trialActsToEdit.splice(modifiedVisitActIndex, 1);
    }
  }

  public calculatePeriodInDays(periodType: string, period: number, originalVisitPeriodInDays: number): number {
    let displayHowManyDays: number;
    originalVisitPeriodInDays = originalVisitPeriodInDays === -1 ? 0 : originalVisitPeriodInDays;
    switch (periodType) {
      case '0': {
        displayHowManyDays = originalVisitPeriodInDays + period;
        break;
      }
      case '1': {
        displayHowManyDays = originalVisitPeriodInDays + period * 7;
        break;
      }
      case '2': {
        displayHowManyDays = originalVisitPeriodInDays + period * 30;
        break;
      }
      case '3': {
        displayHowManyDays = originalVisitPeriodInDays + period * 365;
        break;
      }
    }
    return displayHowManyDays;
  }

  private getIsThereAnyUsedProtocol() {
    this.theoreticalCalendarService.getRelatedProtocolsByTrial(this.trialId).subscribe(res => {
      this.disableUpdateNowRadioButton = this.amendmentsEnabled && res.length === 0;
    });
  }

  private initAvailableTrialProtocols() {
    this.dataItemService.getItems('protocol-unused-in-tc|' + this.trialId).subscribe((res) => {
      this.availableTrialProtocols = res;
    });
  }

  revertProtocolVersion() {
    if (this.amendmentsEnabled) {
      this.calendar.trialProtocolHhhId = this.protocolId;
    }
  }

  getOptional(event: TheoreticalVisitAct) {
    this.calendar.theoreticalVisitActsToEdit.push(event);
  }
}

import {Injectable} from '@angular/core';
import {ResponsePage} from '../../shared/entity/response-page';
import {Invoice} from '../models/invoice';
import {InvoiceGlobalFilter} from '../models/invoice-global-filter';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Properties} from '../../helpers/properties';
import {catchError, map} from 'rxjs/operators';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';

@Injectable({
  providedIn: 'root'
})
export class StaticInvoiceService {

  public host = new Properties().host + '/static-invoice';

  public staticInvoiceDataPage: ResponsePage<Invoice>;
  public limit = 50;
  public sortBy = 'hhhId';
  public page = 0;
  public orderBy = 'desc';
  public invoiceGlobalFilter: InvoiceGlobalFilter = new InvoiceGlobalFilter();

  public staticInvoiceTotal = new BehaviorSubject<number>(0);
  staticTotal = this.staticInvoiceTotal.asObservable();

  public staticInvoiceActsTotal = new BehaviorSubject<number>(0);
  staticActsTotal = this.staticInvoiceActsTotal.asObservable();
  public staticInvoiceFixedCostsTotal = new BehaviorSubject<number>(0);
  staticFixedCostsTotal = this.staticInvoiceFixedCostsTotal.asObservable();
  public staticInvoiceCounterPartsTotal = new BehaviorSubject<number>(0);
  staticCounterPartsTotal = this.staticInvoiceCounterPartsTotal.asObservable();

  public staticCurrentVersionHhhId = new BehaviorSubject<number>(0);
  currentVersionHhhId = this.staticCurrentVersionHhhId.asObservable();

  public manualStaticInvoiceTotal = new BehaviorSubject<number>(0);
  manualStaticTotal = this.manualStaticInvoiceTotal.asObservable();

  public hhhId = new BehaviorSubject<number>(0);
  invoiceHhhId = this.hhhId.asObservable();

  constructor(private httpClient: HttpClient) {
  }

  loadList(page: number, limit: number, sortBy: string, orderBy: string, filters: InvoiceGlobalFilter): Observable<ResponsePage<Invoice>> {
    return this.httpClient.post<ResponsePage<Invoice>>(
      this.host + '/load-list',
      new RequestPageBuilder<InvoiceGlobalFilter>()
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(filters)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(invoice => new Invoice().init(invoice));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  setPaginationConfiguration(staticInvoiceDataPage: ResponsePage<Invoice>, limit, sortBy, orderBy, filters?) {
    this.staticInvoiceDataPage = staticInvoiceDataPage;
    this.limit = limit;
    this.sortBy = sortBy;
    this.orderBy = orderBy;
    if (filters) {
      this.invoiceGlobalFilter = filters;
    }
  }

  updateStaticActsTotal(totalDifference) {
    const oldActsTotal = this.staticInvoiceActsTotal.getValue();
    const oldTotal = this.staticInvoiceTotal.getValue();
    this.staticInvoiceTotal.next(oldTotal - oldActsTotal + totalDifference);
    this.staticInvoiceActsTotal.next(totalDifference);
  }

  updateStaticFixedCostsTotal(totalDifference) {
    const oldActsTotal = this.staticInvoiceFixedCostsTotal.getValue();
    const oldTotal = this.staticInvoiceTotal.getValue();
    this.staticInvoiceTotal.next(oldTotal - oldActsTotal + totalDifference);
    this.staticInvoiceFixedCostsTotal.next(totalDifference);
  }

  updateStaticCounterPartsTotal(totalDifference) {
    const oldActsTotal = this.staticInvoiceCounterPartsTotal.getValue();
    const oldTotal = this.staticInvoiceTotal.getValue();
    this.staticInvoiceTotal.next(oldTotal - oldActsTotal + totalDifference);
    this.staticInvoiceCounterPartsTotal.next(totalDifference);
  }

  updateInvoiceHhhId(hhhId: number) {
    this.hhhId.next(hhhId);
  }

  updateStaticCurrentVersionHhhId(hhhId: number) {
    this.staticCurrentVersionHhhId.next(hhhId);
  }

  updateStaticInvoiceAmountTotal(versionHhhId: number, amountTotal: number) {
    const request = {
      versionId: versionHhhId,
      totalAmount: amountTotal
    };
    return this.httpClient.post(this.host + '/amount-total', request);
  }

  updateManualStaticInvoiceAmountTotal(invoiceHhhId: number, amountTotal: number) {
    const request = {
      invoiceId: invoiceHhhId,
      totalAmount: amountTotal
    };
    return this.httpClient.post(this.host + '/manual-amount-total', request);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AgendaContainerComponent} from './agenda-container/agenda-container.component';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {AuthGuard} from '../helpers/auth-guard.service';
import {RequirePasswordUpdateGuard} from '../helpers/require-password-update-guard';

const routes: Routes = [
  {path: '', redirectTo: 'agenda-container', pathMatch: 'full'},
  {path: 'agenda-container', component: AgendaContainerComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    HttpClientModule,
    TranslateModule
  ],
  exports: [RouterModule]
})
export class AgendaRoutingModule {
}

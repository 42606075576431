import {Component} from '@angular/core';

@Component({
  selector: 'ih-inclusion-concomitant-treatment-icon',
  templateUrl: './inclusion-concomitant-treatment-icon.component.html',
  styleUrls: ['./inclusion-concomitant-treatment-icon.component.css']
})
export class InclusionConcomitantTreatmentIconComponent {

  constructor() { }

}

import {AbstractDesignLevelDto} from '../../models/abstract-design-level-dto';
import {ArmPhaseDto} from '../../models/arm-phase-dto';
import {ArmCohortDto} from '../../models/arm-cohort-dto';
import {ArmInclusionArmDto} from '../../models/arm-inclusion-arm-dto';
import {ArmEscalationExtensionDto} from '../../models/arm-escalation-extension-dto';
import {MinimizedTrialProtocol} from '../../models/minimized-trial-protocol';

export class Arm {
  public hhhId: any;
  public publicCommentCount: number;
  public name: string;
  public trialHhhId: number;
  public theoreticalCalendar: number;
  status: 'OPEN_FOR_INCLUSIONS'| 'TEMPORARILY_SUSPENDED' | 'DEFINITELY_SUSPENDED' | '';
  protocol: MinimizedTrialProtocol;
  phase: ArmPhaseDto;
  cohort: ArmCohortDto;
  inclusionArm: ArmInclusionArmDto;
  escaladeExtension: ArmEscalationExtensionDto;
  designLevels: Array<AbstractDesignLevelDto> = new Array<AbstractDesignLevelDto>();

  deleted: boolean; // frontend use only
  cohortArmPart:string;

  init(obj: any): Arm {
    if (!obj) {
      return new Arm();
    }
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  public getNameWithProtocolVersionAndPhase(): string {
    const parts = [
      this.protocol && this.protocol.version ? this.protocol.version : '',
      this.phase && this.phase.phase ? this.phase.phase.nameFr : '',
      this.name || ''
    ];
    return parts.filter(part => typeof part === 'string' && part.length > 0).join(' - ');
  }

  public getNameWithProtocolVersion(): string {
    const parts = [
      this.protocol && this.protocol.version ? this.protocol.version : '',
      this.name || ''
    ];
    return parts.filter(part => typeof part === 'string' && part.length > 0).join(' - ');
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule, Routes} from '@angular/router';
import {EstablishmentContainerComponent} from './establishment-container/establishment-container.component';
import {EstablishmentsComponent} from './establishments/establishments.component';
import {AuthGuard} from '../helpers/auth-guard.service';
import {MyEstablishmentComponent} from './my-establishment/my-establishment.component';
import {RequirePasswordUpdateGuard} from '../helpers/require-password-update-guard';

const routes: Routes = [
  {
    path: 'establishment', component: EstablishmentContainerComponent, canActivate: [RequirePasswordUpdateGuard],
    children: [
      {path: '', redirectTo: 'details', pathMatch: 'full'},
      {path: 'details', component: MyEstablishmentComponent, canActivate: [AuthGuard]},
      {path: 'establishments', component: EstablishmentsComponent, canActivate: [AuthGuard]}]
  }
];


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    HttpClientModule,
    TranslateModule
  ]
})
export class EstablishmentRoutingModule {
}

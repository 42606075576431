import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {CanDeactivateService} from '../shared/services/can-deactivate.service';
import {InnohealthTranslateService} from '../shared/services/innohealth-translate.service';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Promise<boolean> | Observable<boolean>;
}


@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  constructor(private translateService: InnohealthTranslateService,
              private canDeactivateService: CanDeactivateService) {
  }

  canDeactivate(): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      this.canDeactivateService.canBeDeactivated
        ? observer.next(true)
        : confirm(this.translateService.getTranslationString('UNSAVED_FORM_CHANGE_ALERT_MESSAGE')) ? this.moveToNext(observer) : this.cancel(observer);
    });
  }

  moveToNext(observer: Observer<boolean>) {
    observer.next(true);
    observer.complete();
    this.canDeactivateService.canBeDeactivated = true;
  }

  cancel(observer: Observer<boolean>) {
    observer.next(false);
    observer.complete();
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../../../../../shared/component/form/form.component';
import {PharmacyDispensationKit} from '../../../../../entity/trial-pharmacy/PharmacyDispensationKit';
import {FR} from '../../../../../../shared/entity/calendar-language';
import {NgForm} from '@angular/forms';
import {PharmacyService} from '../../../../../service/pharmacy.service';
import {AUTHENTICATED_USER_ROLE} from '../../../../../../login/services/authentication.service';
import {Role} from '../../../../../../login/models/role';

@Component({
  selector: 'ih-dispensation-details-form',
  templateUrl: './visits-pharmacy-dispensation-kit-form.component.html',
  styleUrls: ['./visits-pharmacy-dispensation-kit-form.component.css']
})
export class VisitsPharmacyDispensationKitFormComponent extends FormComponent<PharmacyDispensationKit> implements OnInit {

  constructor(public pharmacyService: PharmacyService) {
    super();
  }

  @ViewChild('visitDispensationKitForm') visitDispensationKitForm: NgForm;

  pharmacyDispensationKit: PharmacyDispensationKit = new PharmacyDispensationKit();
  locale = FR;

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(VisitsPharmacyDispensationKitFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.pharmacyService.setPharmacyReadonlyMode();
  }

  updateKit() {
    this.callback(this.pharmacyDispensationKit);
  }

  isAdmin() {
    return localStorage.getItem(AUTHENTICATED_USER_ROLE) === Role.Admin;
  }
}

import {InvoiceState} from '../../invoice-fixed-costs/models/invoice-state';
import {Util} from '../../../helpers/util';
import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {DataCommentCount} from '../../../data-comment/entity/data-comment-count';

export class InvoiceInformation extends DataCommentCount {
  hhhId: number;
  invoiceState: InvoiceState;
  number: string;
  address: string;
  issueDate: Date;
  validationDate: Date;
  despatchDate: Date;
  paymentDate: Date;
  managementFeesPercentage: number;
  trialName: string;
  trialHhhId: number;
  manualState: boolean;
  establishment: number;
  providerHhhId: number;
  financialEntityHhhId: number;
  trialPrincipalInvestigator: string;
  referencePromoter: string;
  centerNumber: string;
  tva: string;
  tvaIntraCommunity: string;
  debtorLocalCode: string;
  factureDescription: string;
  tvaRate: string;
  imputationAccount: string;

  init(obj: any): InvoiceInformation {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  public isValid(config: FormConfig): boolean {
    const valid = true;
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'state') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.invoiceState)) {
          return false;
        }
      } else if (key === 'number') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.number)) {
          return false;
        }
      } else if (key === 'trial') {
        if (required && !this.trialHhhId) {
          return false;
        }
      } else if (key === 'feesPercentage') {
        if (required && !this.managementFeesPercentage) {
          return false;
        }
      } else if (key === 'financialEntity') {
        if (required && !this.financialEntityHhhId) {
          return false;
        }
      } else if (key === 'manualState') {
        continue;
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    if (this.issueDate && this.validationDate && this.issueDate.getTime() > this.validationDate.getTime()) {
      return false;
    }
    if (this.issueDate && this.despatchDate && this.issueDate.getTime() > this.despatchDate.getTime()) {
      return false;
    }
    if (this.issueDate && this.paymentDate && this.issueDate.getTime() > this.paymentDate.getTime()) {
      return false;
    }
    if (this.validationDate && this.despatchDate && this.validationDate.getTime() > this.despatchDate.getTime()) {
      return false;
    }
    if (this.validationDate && this.paymentDate && this.validationDate.getTime() > this.paymentDate.getTime()) {
      return false;
    }
    if (this.despatchDate && this.paymentDate && this.despatchDate.getTime() > this.paymentDate.getTime()) {
      return false;
    }
    return valid;
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {isNullOrUndefined} from 'util';
import {InclusionTracking} from '../../../entity/trial-track/inclusion-tracking';

@Component({
  selector: 'ih-tracking-bloc',
  templateUrl: './tracking-bloc.component.html',
  styleUrls: ['./tracking-bloc.component.css']
})
export class TrackingBlocComponent implements OnInit {

  @Input() trialId: number;
  @Input() trackingDate: Date;
  @Input() beforeDate: boolean;
  @Input() isPatientDetails = false;
  carouselVisibleNumber: number;
  @Input() trackingList: InclusionTracking[];

  constructor() { }

  ngOnInit() {
    this.carouselVisibleNumber = isNullOrUndefined(this.trackingDate) ? 7 : 3;
  }

}

import {JsonObject, JsonProperty} from 'json2typescript';
import {FormTitleConfig} from './form-title-config';

@JsonObject('DynamicFormDefinitionTitle')
export class DynamicFormDefinitionTitle {

  @JsonProperty('defaultTitle', String, true)
  defaultTitle: string = undefined;

  @JsonProperty('config', FormTitleConfig)
  config: FormTitleConfig = new FormTitleConfig();


}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver, ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {TrialService} from '../../../services/trial.service';
import {PromoterType} from '../../../../provider/models/promoter-type';
import {Data} from '../../../../shared/entity/data';
import {DataService} from '../../../../shared/services/data-service';
import {Router} from '@angular/router';
import {TrialSite} from '../../../models/trial-site';
import {SharedService} from '../../../../shared/services/shared.service';
import {FR} from '../../../../shared/entity/calendar-language';
import {SelectItem} from 'primeng/api';
import {ProviderFormComponent} from '../../../../provider/provider-form/provider-form.component';
import {Provider} from '../../../../provider/models/provider';
import {ProviderService} from '../../../../provider/services/provider.service';
import {TrialSiteService} from '../../../../site/services/trial-site.service';
import {ProviderBasicInformation} from '../../../../provider/models/provider-basic-information';
import {DropdownComponent} from '../../../../shared/component/dropdown/dropdown.component';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {DropdownFieldFormComponent} from '../../../../modals/dropdown-field-form/dropdown-field-form.component';
import {DropdownField} from '../../../../admin/models/dropdown-field';
import {LabelValue} from '../../../../shared/entity/label-value';
import {TrialIdentInfoTemplate} from '../trial-ident-info-template';
import {TrialIdentificationInformationGeneral} from '../../../models/trial-identification-information-general';
import {
  TrialIdentificationInformationGeneralService
} from '../../../services/trial-identification-information-general.service';
import {TrialInformationGenericComponent} from '../trial-information-generic/trial-information-generic.component';
import {OrganService} from '../../../services/organ.service';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import {NgForm, NgModel} from '@angular/forms';
import {isNumeric} from 'rxjs/internal-compatibility';
import {TrialAppData} from '../../../trial-app-data';
import {AppData} from '../../../../helpers/app-data';
import {PromoterService} from 'src/app/provider/services/promoter.service';
import {TrialInformationService} from '../../../services/trial-information.service';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataItemService} from '../../../../custom-field/service/data-item.service';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';
import {
  DynamicDropdownComponent
} from '../../../../dynamic-config/exported/dynamic-dropdown/dynamic-dropdown.component';
import {AuthenticationService} from '../../../../login/services/authentication.service';
import {Role} from '../../../../login/models/role';

@Component({
  selector: 'ih-trial-identification-information',
  templateUrl: './trial-identification-information.component.html',
  styleUrls: ['./trial-identification-information.component.css']
})
export class TrialIdentificationInformationComponent extends TrialInformationGenericComponent<TrialIdentificationInformationGeneral> implements OnInit, OnDestroy, TrialIdentInfoTemplate {

  target = DynamicDefinitionEnum.TRIAL_IDENTIFICATION_INFORMATION_FORM;
  config: FormConfig = new FormConfig();
  isParent = false;
  isPrivatePostItEnabled = false;
  isTrialEstablishmentDisabled = false;
  isTrialServiceMandatory = false;
  displayExportBtn = false;
  ghtMontBlancCustomizationEnabled = false;
  forecastInclusionsEnabled = false;

  @ViewChild('trialIdentificationInformationForm') trialIdentificationInformationForm: NgForm;

  isOrganFamilyEnabled = false;
  isPhaseComplementEnabled = false;


  @ViewChild('providerFormDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('providerDropdown') providerDropdown: DynamicDropdownComponent;
  @ViewChild('promoterDropdown') promoterDropdown: DynamicDropdownComponent;
  @ViewChild('otherParticipatingCenterDropdown') otherParticipatingCenterDropdown: DropdownComponent<LabelValue>;

  @Input() mode: string;
  locale = FR;
  submitted: boolean;
  promoterTypes: LabelValue[] = [];
  trialSiteList: TrialSite[] = [];
  noYesOptions: SelectItem[] = Data.yesOrNo;
  noYesEmptyOptions: SelectItem[] = Data.yesOrNoOrNeither;
  escalationExtension: SelectItem[] = Data.escalationExtension;
  selectedTrialTypesMethodology: number[] = [];
  trialId: number;
  displayWarningManualStateCheckedDialog: boolean;
  localIdentifierExists: boolean;
  isCsetEnabled: boolean;
  readonlyMode = false;
  phasesWithEscalation: number[] = [];
  phaseList: LabelValue[];
  csetNotAvailable = false;
  organsList = [];
  maxSelectedLabels: number;
  researchNatureOther = false;

  configReady = new EventEmitter<boolean>(false);

  constructor(
    public trialService: TrialService,
    public trialIdentificationInformationService: TrialIdentificationInformationGeneralService,
    private trialSiteService: TrialSiteService,
    private router: Router,
    private dataItemService: DataItemService,
    private dataService: DataService,
    private cfr: ComponentFactoryResolver,
    private providerService: ProviderService,
    private promoterService: PromoterService,
    private sharedService: SharedService,
    private canDeactivateService: CanDeactivateService,
    public organService: OrganService,
    private translateService: InnohealthTranslateService,
    private dynamicConfigService: DynamicConfigService,
    private trialInformationService: TrialInformationService,
    private authenticationService: AuthenticationService,
    private _cdr: ChangeDetectorRef
  ) {
    super(new TrialIdentificationInformationGeneral());
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.mode = 'edit';
      this.trialId = url.queryParams.hhhId;
      this.displayExportBtn = !!this.trialId;
      if (this.dynamicConfigService.getProperty(PropertyEnum.defaultTrialInMultiSite)) {
        this.trialInformation.multiSite = true;
      }
    }
  }

  ngOnInit() {
    this.initProperties();
    this.getOrganList();
    this.trialIdentificationInformationService.submitted.subscribe(res => {
      this.submitted = res;
    });
    this.getPromoterType();
    this.trialIdentificationInformationService.getIsCSETNumberInvalid();
    this.readonlyMode = this.trialService.readOnlyMode;
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isParent = this.dynamicConfigService.parent;
      this.isPrivatePostItEnabled = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled);
      this.isTrialEstablishmentDisabled = this.dynamicConfigService.getProperty(PropertyEnum.trialEstablishmentDisabled);
      this.isOrganFamilyEnabled = this.dynamicConfigService.getProperty(PropertyEnum.organFamilyEnabled);
      this.isPhaseComplementEnabled = this.dynamicConfigService.getProperty(PropertyEnum.phaseComplementEnabled);
      this.isTrialServiceMandatory = this.dynamicConfigService.getProperty(PropertyEnum.trialServiceMandatory);
      this.isCsetEnabled = this.dynamicConfigService.getProperty(PropertyEnum.csetEnabled);
      this.ghtMontBlancCustomizationEnabled = this.dynamicConfigService.getProperty(PropertyEnum.ghtMontBlancCustomizationEnabled);
      this.forecastInclusionsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.forecastInclusionsEnabled);
      this.getTrialInformationGeneral();
      this._cdr.detectChanges();
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
        this.configReady.emit(true);
      }
    );
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  getService() {
    return this.trialIdentificationInformationService;
  }

  createAddOtherParticipatingCenter(selectedElement: string) {
    const formComponent = DropdownFieldFormComponent.displayFormDialogue(this.dialogContainer, this.cfr);
    formComponent.newField = new DropdownField();
    formComponent.selectedElement = selectedElement;
    formComponent.showSubClassSection = false;
    formComponent.onAdd((newItem: DropdownField) => {
      this.otherParticipatingCenterDropdown.onRefreshDrop();
    });
  }

  getPromoterType() {
    this.promoterService.getPromoterTypeDtosList().subscribe(res => {
      this.promoterTypes = res;
      this.promoterTypes.splice(0, 0, {value: 0, label: null});
    });
  }

  getTrialSiteList() {
    if (this.mode === 'edit') {
      this.trialSiteService.getTrialSites(this.trialId).subscribe((res: TrialSite[]) => {
        this.trialSiteList = res;
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading trial site list' + error);
      });
    }
  }

  displaySelectedTrialTypesMethodology(selectedValues) {
    this.selectedTrialTypesMethodology = selectedValues;
  }

  changeTrialPromoter() {
    if (this.trialInformation.promoterHhhId == null) {
      return;
    }
    this.providerService.getPromoterTypeByProviderHhhId(this.trialInformation.promoterHhhId).subscribe((res: PromoterType) => {
      this.trialInformation.promoterTypeHhhId = res.hhhId;
    });
    this.formHasBeenChanged();
  }

  createAddDialogComponent(providerType: string, providerTypePath?: string) {
    const formComponent = ProviderFormComponent.display(this.dialogContainer, this.cfr);
    formComponent.provider = new ProviderBasicInformation();
    formComponent.providerTypePath = providerTypePath;
    formComponent.onAdd((provider: Provider) => {
      if (providerType === 'provider') {
        this.providerDropdown.refreshDropdown();
        this.trialInformation.providerHhhId = provider.hhhId;
      } else {
        this.promoterDropdown.refreshDropdown();
        this.trialInformation.promoterHhhId = provider.hhhId;
        this.trialInformation.promoterTypeHhhId = provider.promoterType ? provider.promoterType.hhhId : null;
      }
    });
  }

  displayDataCommentDialog(event: {attachedToField: string, isPrivate: boolean}) {
    if (!this.trialInformation.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialIdentificationInformation, this.trialInformation.hhhId, event.attachedToField, event.isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.trialInformation.commentCount.set(dataCommentEvent.attachedToField, dataCommentEvent.publicCommentCount);
  }

  displayManualStateDataCommentDialog(isPrivate: boolean) {
    if (!this.trialInformation.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialIdentificationInformation, this.trialInformation.hhhId, 'manualState', isPrivate);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  checkManualState() {
    if (this.trialInformation.manualState) {
      this.displayWarningManualStateCheckedDialog = true;
    }
    this.canDeactivateService.canBeDeactivated = false;
  }

  initTrial() {
    this.trialIdentificationInformationService.load(this.trialId).subscribe(res => {
      this.trialInformation = res;
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred in setExportPageModule' + error);
    });
    this.trialIdentificationInformationService.getLocalIdentifier().subscribe(
      res => {
        this.localIdentifierExists = res;
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred IsLocalIdentifierInvalid.' + error);
      }
    );
  }

  verifyClientDisabledField(): boolean {
    return this.dynamicConfigService.verifyClient(['ico']) ;
    }

  isModeEdit(): boolean {
    return this.mode === 'edit';
  }

  getIsCsetDisabled() {
    const isAdmin = this.authenticationService.currentUserRole === Role.Admin;

    const userIGR = this.verifyClient(['igr'])
      ? `${this.authenticationService.currentUserFirstName} ${this.authenticationService.currentUserLastName}`
      : '';

    const authorizedUserOnlyForIGR = userIGR !== "Emily BOURREAU";

    const result = this.isModeEdit() && this.isCsetEnabled && !isAdmin && authorizedUserOnlyForIGR;
    return result;
  }


  formHasBeenChanged() {
    this.sendNewTrialIdentificationInfo();
    this.canDeactivateService.canBeDeactivated = false;
  }

  initPhasesWithoutEscalation() {
    this.dataItemService.getItems('phase-selected').subscribe(res => {
      this.phaseList = res;
      this.phasesWithEscalation = [];
      let phaseOneLabels = [];
      if (this.isPhaseComplementEnabled) {
        phaseOneLabels = ['Phase I', 'Phase I/II'];
      } else {
        phaseOneLabels = ['Phase I', 'Phase Ia', 'Phase Ib', 'Phase I/II'];
      }
      for (const phase of res) {
        if (phaseOneLabels.indexOf(phase.label) >= 0) {
          this.phasesWithEscalation.push(phase.value);
        }
      }

    });
  }

  onCSETChange() {
    if (this.mode !== 'edit' && this.trialInformation.localIdentifier && (this.isCsetEnabled && this.trialInformation.localIdentifier.length === 9)) {
      this.trialService.checkCSETAvailable(this.trialInformation.localIdentifier).subscribe(res => {
        this.csetNotAvailable = !res.value;
        this._cdr.detectChanges();
      });
    } else {
      this.csetNotAvailable = false;
    }
  }

  sendNewTrialIdentificationInfo() {
    this.trialIdentificationInformationService.setTrialIdentificationInfo(this.trialInformation);
  }

  saveIdentificationInformation() {
    this.submitted = true;
    if (this.trialIdentificationInformationForm.invalid || !this.trialInformation.isValid(this.config, this.phasesWithEscalation)) {
      return;
    }
    this.trialInformation.trialHhhId = this.trialId;
    this.trialIdentificationInformationService.saveTrialIdentificationInformation(this.trialInformation).subscribe(res => {
        this.sharedService.showSuccess();
        this.submitted = false;
        this.getTrialInformationGeneral();
        this.sendNewTrialIdentificationInfo();
        this.canDeactivateService.canBeDeactivated = true;
      }, error => {
        this.sharedService.showFailure();
      }
    );
  }

  notifyWhenResearchTypeOther(otherValue) {
    this.researchNatureOther = otherValue;
  }

  getTrialInformationGeneral() {
    if (this.trialId) {
      this.initTrial();
      this.getTrialSiteList();
    } else {
      this.getUnsavedTrialInformationGeneral();
    }

    this.initPhasesWithoutEscalation();
  }

  getOrganList() {
    this.organService.getOrgansAndOrganFamilies().subscribe(
      res => {
        this.organsList = res;
        this.maxSelectedLabels = res.length;
      }, error => {
        this.sharedService.showFailure();
        console.error('An error occurred while loading organ list.' + error);
      }
    );
  }

  inclusionObjectiveWarning() {
    if (isNaN(this.trialInformation.inclusionObjective)) {
      this.sharedService.showWarning(this.translateService.getTranslationString('MODULE_TRIALS_INFORMATION_FIELD_INCLUSION_OBJECTIVE_WARNING_MSG'));
    }
  }

  checkNCTGovInvalid(numberClinicalTrialsGov: NgModel) {
    const str = numberClinicalTrialsGov.toString();
    if (str.substring(0, 3) === 'NCT' && str.length === 11 && isNumeric(str.substring(3, 11))) {
      return false;
    }
    return true;
  }

  exportTrialIdentification(event: any): void {
    const request = new ExportRequestBuilder<void>()
      .objectId(this.trialId)
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .build();

    this.trialInformationService.exportIdentificationInformation(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  verifyClient = (clients: string[]) => this.dynamicConfigService.verifyClient(clients);

}

import {DesignStructureConfigSimpleElement} from './design-structure-config-simple-element';
import {DesignLevelStructureConfig} from './design-level-structure-config';

export class DesignStructureConfig {
  name: DesignStructureConfigSimpleElement;
  phase: DesignStructureConfigSimpleElement;
  escaladeExtension: DesignStructureConfigSimpleElement;
  cohort: DesignStructureConfigSimpleElement;
  inclusionArm: DesignStructureConfigSimpleElement;
  levels: Array<DesignLevelStructureConfig> = new Array<DesignLevelStructureConfig>();
}

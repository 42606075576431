export class TrialListData {
    public hhhId: number;
    public publicCommentCount: number;
    public state: string;
    public manual: boolean;
    public protocolNumber: string;
    public localIdentifier: string;
    public promoter1: string;
    public promoterType: string;
    public principalInvestigator1: string;
    public multiSite: boolean;
    public favorite: boolean;
    public patientIncludedState: number;
    public organFamilies: string;
    public sites: string;
    public coInvestigators: string;
    public clinicalResearchAssistant1: string;
    public phase: string;
    public researchType: string;
    public researchNature: string;
    public illnessStates: string[];
    public moleculeStudied: string;
    public minimalAge: number;
    public maximalAge: number;
    public establishmentName: string;
    public service: string;
    public blinded: boolean;
    public actualProductionLaunchDate: Date;
    public inclusionDate: Date;
    public actualClosingForInclusionsDate: Date;
    public actualFollowupEndDate: Date;
    public trialClosingDate: Date;
    public archiveDate: Date;
    public conventionTypes: string[];
    public trialConventionSignatureByPromoterDate: Date;
    public inclusionsNumber: number;
    public nationalCoordinator: string;
    public cppSendingDate: Date;
    public cppLastAmendmentDate: Date;
    public ansmAuthorizationDate: Date;
    public manualState: boolean;
    public priorityNumbers: string;
    public trialTreatments: string;
    public committees: string;
    public trialTechniqueTypes: string;
    public preInclusionNumber: number;
    public totalPreScreeningConsents: number;
    public totalTreatedPatients: number;
    public idRcb: string;
    public typeOfStudyLaw: string;
    public typeOfStudyLaw2: string;
    public isEligibleForMobileApplication: boolean;
    public dataManager2: string;
    public projectManagerFrontDesk: string;
    public projectManager: string;
    public contractManager: string;
    public invoicingManager: string;
    public ansmProvisionalSoumissionDate: Date;
    public ansmSoumissionDate: Date;
    public cppProvisionalSoumissionDate: Date;
    public cppActualSoumissionDate: Date;
    public cppProvisionalAgreementDate: Date;
    public cppActualAgreementDate: Date;
    public tec1: string;
    // new fields
    escalationExtension: string;
    firstInHuman: boolean;
    hasMolecularPreScreening: boolean;
    trialTypesMethodologies: string[] = [];
    provider: number;
    numberCentersAllocatedByPromoter: string;
    multiCentric: string;
    numberOfCenterAllocatedByPromoter: string;
    numberClinicalTrialsGov: string;
    referentDoctor: string;
    arcManager: string;
    arc2: string;
    arcReferent: string;
    amr: string;
    irc: string;
    gp: string;
    targetOfTreatmentExperimental: string[] = [];
    otherTreatmentExperimental: string[] = [];
    therapeuticClassForDrugs: number[] = [];
    ansmInformationDate: Date;
    initialEvaluationCppSessionDate: Date;
    promoterFeasibilityQuestionnaireDespatchDate: Date;
    insuranceExpiry: Date;
    selectionCenterSelectedDate: Date;
    abandonedTrialDate: string;
    committeeReunionDecisionDate: Date;
    cppAuthorizationMSDate: Date;
    substantialModification: boolean;
    ctisAuthorization: boolean;
    ctisProvisionalSoumissionDate: Date;
    ctisActualSoumissionDate: Date;
    ctisProvisionalAgreementDate: Date;
    ctisActualAgreementDate: Date;
    nationalAgencyActualAgreementDate: Date;
    committeeEthicsActualAgreementDate: Date;
    csetCompleteFolderReceptionDate: Date;
    csetSoumissionDate: Date;
    drcAgreementDate: Date;
    provisionalProductionLaunch: Date;
    temporarySuspensionOfInclusions: Date;
    centerActivation: string;
    audit: string;
    inspection: string;
    preArchiveDate: Date;
    receivedConventionDate: Date;
    revisedConventionDate: Date;
    conventionStatus: string;
    completeReceipt: string;
    sendingConventionDate: Date;
    preArchiveLastInvoicementDate: Date;
    coordoPrcDate: Date;
    comopAgreementDate: Date;
    trialCommitteesBackup: string;
    promoter2: string;
    csetAgreementDate: Date;
    inclusionHistologies: string[];
    preArchiveInvoicementIsValid: 'YES' | 'NO' | 'NO_ANSWER';

  init(obj: any): TrialListData {
        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                this[prop] = obj[prop];
            }
        }
        return this;
    }
}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UsersService} from '../services/users.service';
import {User} from '../models/user';
import {AUTHENTICATED_USER_ID, AuthenticationService} from '../../login/services/authentication.service';


@Component({
  selector: 'ih-access-rights',
  templateUrl: './access-rights.component.html',
  styleUrls: ['./access-rights.component.css']
})
export class AccessRightsComponent implements OnInit {

  public username;
  public displayInformation = true;
  public displayPasswordChange = false;
  public displayAccessRights = false;
  user: User = new User();
  private id: number;
  enabledPasswordEditing = false;
  type: string;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private userService: UsersService,
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.id = url.queryParams.id;
      this.type = url.queryParams.type;
    }
  }

  ngOnInit() {
    this.getUser();
  }

  getUsername(username: string) {
    this.username = username;
  }

  getUser() {
    this.userService.getUser(this.id).subscribe(res => {
      this.user = res;
      if (this.authenticationService.currentUserRole === 'ADMIN' || this.type === 'NEW' ) {
        this.enabledPasswordEditing = true;
      } else {
        this.enabledPasswordEditing = false;
      }
    });
  }

  goToAccountList() {
    this.router.navigate(['/account-list']).then();
  }
}

export class TableHeader {
  field: string;
  filterField?: string;
  header: string;
  width?: string;
  filterable?: boolean;
  optionFilterable?: boolean;
  inputType?: string;
  options?: any;
  type?: string;
  entityId?: number;
  sortable?: boolean;
  cssClass?: any;
  displayCssClassContent?: boolean;
  displayCheckBox?: boolean;
  sortField?: string;
  translated?: boolean;
  displayContent?: any;
  linkClickEvent?: any;
  cssClassForContent?: boolean;
  checked?: boolean;
  dateFormatter?: any;
  dateTimeFormatter?: any;
  display?: boolean;
  filter?: any;
  editable?: boolean;
  iconButton?: boolean;
  iconButtonEvent?: any;
  iconButtonCssClass?: any;
  moreFields?: boolean;
  displayFields?: any;
  hideFields?: any;
  onChange?: any;
  hideField?: any;
  onEdit?: any;
  removeRelatedAct?: any;
  currency?: boolean;
  cellAlign?: any;
  filterClass?: string;
  dynamicCssClass?: any;
  filterDateMonth?: any;
  filterDateYear?: any;
  filterFieldMonth?: any;
  filterFieldYear?: any;


  static buildFilterData(tableHeaders: TableHeader[]): any {
    const filterValues = [];
    for (const column of tableHeaders) {
      if (column.filterable) {
        if (column.filterField != null) {
          filterValues[column.filterField] = column.filter;
        } else if (column.filterFieldMonth && column.filterFieldYear) {
          filterValues[column.filterFieldMonth] = column.filterDateMonth;
          filterValues[column.filterFieldYear] = column.filterDateYear;
        } else {
          filterValues[column.field] = column.filter;
        }
      }
    }
    return Object.assign({}, filterValues);
  }

  constructor(headerFields: Map<string, any>, varMap: Map<string, any>) {
    Object.keys(headerFields).forEach((key, index) => {
      this[key] = headerFields[key].value || varMap.get(headerFields[key].variableKey);
    });
  }

}



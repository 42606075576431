export class AuditLog {
  public hhhId: number;
  public timestamp: Date;
  public content: string;
  public action: string;
  public ownerHhhId: number;
  public username: string;
  public trialHhhId: number;
  public trialIdentifier: string;
  public module: string;

  init(obj: any): AuditLog {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

}

import { Injectable } from '@angular/core';
import {Properties} from '../../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StaticInvoiceItem} from '../../models/static-invoice-item';

@Injectable({
  providedIn: 'root'
})
export class StaticInvoiceCounterPartService {

  public host = new Properties().host + '/static-invoice-counter-part';

  constructor(private httpClient: HttpClient) { }

  getStaticInvoiceCounterParts(invoiceHhhId: number, versionHhhId: number): Observable<StaticInvoiceItem> {
    return this.httpClient.get<StaticInvoiceItem>(this.host + '/load-list/' + invoiceHhhId + '/' + versionHhhId);
  }

  saveStaticInvoiceCounterParts(invoiceHhhId: number, staticInvoiceAct: StaticInvoiceItem): Observable<number> {
    return this.httpClient.post<number>(this.host + '/save/' + invoiceHhhId, staticInvoiceAct);
  }

}

import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';

export class DataDocumentRequest {
  attachedTo: AttachedToEntityEnum = undefined;
  attachedToId: number = undefined;
  root: any = undefined;

  constructor() {
  }

}

export class DataDocumentRequestBuilder {

  _request: DataDocumentRequest;

  constructor() {
    this._request = new DataDocumentRequest();
  }

  setAttachedTo(entity: AttachedToEntityEnum): DataDocumentRequestBuilder {
    this._request.attachedTo = entity;
    return this;
  }

  setAttachedToId(attachedToId: number): DataDocumentRequestBuilder {
    this._request.attachedToId = attachedToId ;
    return this;
  }

  setRoot(root: any): DataDocumentRequestBuilder {
    this._request.root = root;
    return this;
  }

  build(): DataDocumentRequest {
    return this._request;
  }

}

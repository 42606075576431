export class TrialCustomExportFilter {
  user = -1;
  state = -1;
  phases: number[];
  promoterType = -1;
  site = -1;
  pi1HhhId = -1;
  cra1HhhId = -1;
  tec1HhhId = -1;
  organ = -1;
  organFamily: any;
  trialOrgan: any;
  establishment = -1;
  actualProductionLaunchDate = -1;
  researchType = -1;
  researchField: number[];
  dataManager2: number[];
  projectManagerFrontDesk: number[];
  contractManager: number[];
  invoicingManager: number[];

  public constructor() {
  }
}

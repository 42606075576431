import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {Router} from '@angular/router';
import {SharedService} from '../../shared/services/shared.service';

@Component({
  selector: 'app-mobile-application-header',
  templateUrl: './mobile-application-header.component.html',
  styleUrls: ['./mobile-application-header.component.css']
})
export class MobileApplicationHeaderComponent implements OnInit {

  mobileApplicationMenu: MenuItem[];

  constructor(private sharedService: SharedService,
              private translateService: InnohealthTranslateService,
              private router: Router) {
  }

  ngOnInit() {
    this.setMobileApplicationMenu();
  }

  private setMobileApplicationMenu() {
    this.mobileApplicationMenu = [
      {
        label: this.translateService.getTranslationString('MODULE_MOBILE_APPLICATION_TRIALS'),
        command: () => this.router.navigate(['/mobile-application/trials']),
        routerLink: ['/mobile-application/trials'],
      },
      {
        label: this.translateService.getTranslationString('MODULE_MOBILE_APPLICATION_ACCOUNT_MANAGEMENT'),
        command: () => this.router.navigate(['/mobile-application/accounts-management']),
        routerLink: ['/mobile-application/accounts-management'],
      },
      {
        label: this.translateService.getTranslationString('MODULE_MOBILE_APPLICATION_ACCOUNT_TERMS_AND_CONDITIONS'),
        command: () => this.router.navigate(['/mobile-application/terms-and-conditions']),
        routerLink: ['/mobile-application/terms-and-conditions'],
      },
      {
        label: this.translateService.getTranslationString('MODULE_MOBILE_APPLICATION_ACCOUNT_ABOUT'),
        command: () => this.router.navigate(['/mobile-application/about']),
        routerLink: ['/mobile-application/about'],
      },
      {
        label: this.translateService.getTranslationString('MODULE_MOBILE_APPLICATION_ACCOUNT_NOTIFICATIONS'),
        command: () => this.router.navigate(['/mobile-application/notifications']),
        routerLink: ['/mobile-application/notifications'],
      }
    ];
  }
}

import {Util} from '../../helpers/util';
import {FormConfig} from '../../dynamic-config/entity/form-config';

export class Patient {
  hhhId: number;
  publicCommentCount: number;
  internalIdentifier: string;
  sex: string;
  lastName: string;
  firstName: string;
  birthName: string;
  birthDate: Date;
  status: string;
  deathDate: Date;
  lifeStateHhhId: number;
  addressedByExternalDoctor: boolean;
  establishment: string;
  externalDoctorEstablishment: string;
  externalDoctorName: string;
  establishmentHhhId: number;
  geneHhhIdList: number[] = [];
  hasMutation: boolean;
  hasForebodingTrial: boolean;
  forebodingTrialsHhhIds: number[] = [];
  fullName: string;


  init(obj: any): Patient {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    const valid = true;
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'lifeState') {
        if (required && !this.lifeStateHhhId) {
          return false;
        }
      } else if (key === 'forebodingTrials') {
        if (required && this.forebodingTrialsHhhIds.length === 0) {
          return false;
        }
      } else if (key === 'geneList') {
        if (required && this.geneHhhIdList.length === 0) {
          return false;
        }
      } else if (key === 'externalDoctor') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.externalDoctorName)) {
          return false;
        }
      } else if (key === 'externalDoctorEstablishmentReference') {
        if (required && !this.externalDoctorEstablishment) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return valid;
  }
}



import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ih-administrative-regulatory-icon',
  templateUrl: './administrative-regulatory-icon.component.html',
  styleUrls: ['./administrative-regulatory-icon.component.css']
})
export class AdministrativeRegulatoryIconComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }

}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'commaSplit'
})
export class CommaSplitPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value != null && value.length > 0 ? value.toString().split(',') : [];
  }
}

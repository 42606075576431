import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {TrialSite} from '../../../models/trial-site';
import {Router} from '@angular/router';
import {TrialSiteService} from '../../../../site/services/trial-site.service';
import {TrialHead} from '../../../models/trial-head';
import {TrialSiteFormComponent} from '../trial-site-form/trial-site-form.component';
import {TrialService} from '../../../services/trial.service';
import {DataService} from '../../../../shared/services/data-service';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {SharedService} from '../../../../shared/services/shared.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicTableHeaderBuilder} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {AppData} from '../../../../helpers/app-data';
import {ExportRequestBuilder} from '../../../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';

@Component({
  selector: 'ih-trial-site-list',
  templateUrl: './trial-site-list.component.html',
  styleUrls: ['./trial-site-list.component.css']
})
export class TrialSiteListComponent extends PaginatorTableComponent<TrialSite> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.TrialSiteLiaison;
  target = DynamicDefinitionEnum.TRIAL_SITE_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  trialHhhId: number;
  trialHead: TrialHead = new TrialHead(null);

  constructor(
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private dataService: DataService,
    private trialService: TrialService,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService,
    private trialSiteService: TrialSiteService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.trialSiteService.trialHhhId = this.trialHhhId;
    this.trialSiteService.getTrialSites(this.trialHhhId).subscribe((sites: TrialSite[]) => {
      this.values = sites;
      this.totalNumber = this.values.length;
      this.trialService.getTrialHead(this.trialHhhId).subscribe(res => {
        this.trialHead = res;
      });
    });
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('name')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/trial/trial-site/trial-site-list/trial-site-list.component';
  }

  getDeleteMessage(): string {
    return 'MODULE_TRIALS_SITES_DIALOG_WARNING_TRIAL_SITE_LIAISON_WILL_BE_DELETED';
  }

  getService(): any {
    return this.trialSiteService;
  }

  displayFormDialogue() {
    const formComponent = TrialSiteFormComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
    formComponent.trialSites = this.values;
    formComponent.onAdd(site => {
      this.updateList(site);
    });
  }

  editSite(trialSite: TrialSite) {
    this.router.navigate(['/trial-details/trial-site-details-container/personnel-identification-information'], {
      queryParams: {
        hhhId: this.trialHhhId,
        trialSiteLiaisonHhhId: trialSite.hhhId
      }
    }).then();
  }

  refreshAfterDelete(trialSite: TrialSite): void {
    const index = this.values.findIndex((site: TrialSite) => site.hhhId === trialSite.hhhId);
    this.values.splice(index, 1);
  }

  exportSites(event: any) {
    const request = new ExportRequestBuilder<null>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .exportType(event.exportType)
      .target(this.target)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .objectId(this.trialHhhId)
      .sortBy(this.sortBy)
      .orderBy(this.orderBy)
      .build();

    this.trialSiteService.exportTrialSites(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }
}

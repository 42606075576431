import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import {ReferenceActService} from '../services/reference-act.service';
import {SharedService} from '../../shared/services/shared.service';
import {FormComponent} from '../../shared/component/form/form.component';
import {ReferenceAct} from '../models/reference-act';
import {OperationalAct} from '../models/operational-act';
import {OperationalActService} from '../services/operational-act.service';
import {SelectItem} from 'primeng/api';
import {isNullOrUndefined} from 'util';
import {ActCategoryService} from '../services/act-category.service';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {OperationalAdditionalCost} from '../models/operational-additional-cost';
import {DetailedTrialAct} from '../../trial/entity/trial-additionnal-costs/detailed-trial-act';
import {TrialActService} from '../../trial/service/trial-act.service';
import {TrialFixedCostService} from '../../trial/service/trial-fixed-cost.service';
import {Router} from '@angular/router';
import {DataService} from '../../shared/services/data-service';
import {AdditionalCostType} from '../../shared/entity/additional-cost-type.enum';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {ReferenceFixedCost} from '../models/referenceFixedCost';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {ReferenceFixedCostService} from '../services/reference-fixed-cost.service';
import {Util} from '../../helpers/util';
import {DataItemService} from '../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-operational-act-form',
  templateUrl: './operational-act-form.component.html',
  styleUrls: ['./operational-act-form.component.css']
})
export class OperationalActFormComponent extends FormComponent<OperationalAct> implements OnInit {

  formTarget = DynamicDefinitionEnum.ADDITIONAL_COSTS_OPERATIONAL_ACTS_ADD_FORM;
  operationalTrialActsTableTarget = DynamicDefinitionEnum.ADDITIONAL_COSTS_OPERATIONAL_TRIAL_ACTS_LIST_TABLE;
  inclusionOperationalActsTableTarget = DynamicDefinitionEnum.ADDITIONAL_COSTS_INCLUSION_OPERATIONAL_ACTS_LIST_TABLE;
  @Output() cancelAction = new EventEmitter<void>();
  formConfig: FormConfig = new FormConfig();
  operationalTrialActsTableConfigs: TableConfig;
  inclusionOperationalActsTableConfigs: TableConfig;
  operationalTrialActsHeaders: DynamicTableHeader[] = [];
  inclusionOperationalActsHeaders: DynamicTableHeader[] = [];

  operationalActsContractTypeFilterList: SelectItem[] = [];
  operationalActsTypeFilterList: SelectItem[] = [];
  operationalActsCategoryFilterList: SelectItem[] = [];
  operationalActsDescriptionFilterList: SelectItem[] = [];
  operationalActsInvoiceableFilterList: SelectItem[] = [];
  contractTypeFilterList: SelectItem[] = [];

  typeFilterList: SelectItem[] = [];
  actCategoryFilterList: SelectItem[] = [];
  actDescriptionFilterList: SelectItem[] = [];
  externalFromCenterFilterList: SelectItem[] = [];
  invoiceableFilterList: SelectItem[] = [];
  onPrescriptionFilterList: SelectItem[] = [];

  trialHhhId: number;
  operationalAct: OperationalAct;
  operationalAdditioanlCosts: OperationalAdditionalCost[];
  totalNumber: number;
  dialogReferenceActHeader: string;

  selectedReferenceActs: ReferenceAct[];
  trialActs: DetailedTrialAct[] = [];
  selectedTrialActs: DetailedTrialAct[] = [];
  disabledSaveActsButton = false;
  showTrialActs = true;
  actSelected = false;
  saveOnlyTrialOperationalAct = false;

  contractTypes: SelectItem[] = [];

  constructor(
      private translateService: InnohealthTranslateService,
      private operationalActService: OperationalActService,
      private trialActService: TrialActService,
      private trialFixedCostService: TrialFixedCostService,
      private sharedService: SharedService,
      private router: Router,
      public dataService: DataService,
      private dynamicConfigService: DynamicConfigService,
      private cdr: ChangeDetectorRef,
      private dataItemService: DataItemService
  ) {
    super();
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.actSelected = url.queryParams.actSelected;
    }
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(OperationalActFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  async ngOnInit() {
    super.ngOnInit();
    this.initFormConfig();
    this.trialHhhId = +localStorage.getItem('trialHhhId');
    this.selectedReferenceActs = [];
    this.contractTypes = await this.getContractType().then();
    if (!isNullOrUndefined(this.operationalAct.hhhId)) {
      this.getOperationalReferenceActsByOperationalAct();
      this.initInclusionOperationalActsTableConfig();
    }
    if (this.trialHhhId) {
      this.getTrialActs().then(() => {
        this.prepareActColumns(this.trialActs);
        this.initOperationalTrialActsTableConfig();
      });
    } else {
      this.showTrialActs = false;
    }
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.formTarget, AdditionalCostsAppData.formConfig).subscribe(
        config => {
          this.formConfig = config;
        }
    );
  }

  getFieldConfig = (fieldName: string) => this.formConfig.getFieldConfig(fieldName);


  initOperationalTrialActsTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.operationalTrialActsTableTarget, AdditionalCostsAppData.tableConfig).subscribe(
        config => {
          this.operationalTrialActsTableConfigs = config;
          this.buildOperationalTrialActsTableHeaders();
        }
    );
  }

  getContractType(): Promise<SelectItem[]> {
    return this.dataItemService.getItems('contract-type-selected').toPromise();
  }

  buildOperationalTrialActsTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('contractType')
        .filterable()
      .filterType(FilterType.DROPDOWN)
      .filter('contractTypeHhhId')
      .options(this.contractTypes)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('actCategoryName')
        .withDisplayCssClassContent()
        .displayContent((data: DetailedTrialAct) => {
          return data.actCategory;
        })
        .filter('actCategory')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actCategoryFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('description')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.actDescriptionFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('invoiceable')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.invoiceableFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('internalAccountNumber')
        .withDisplayCssClassContent()
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('pricePerUnit')
        .withDisplayCssClassContent()
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .cssClass((() => {
          return 'right-align';
        }))
        .displayContent((data: OperationalAdditionalCost) => {
          return Number(data.pricePerUnit).toFixed(2) + ' €';
        })
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('numberOfUnits')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .cssClass((() => {
          return 'right-align';
        }))
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('totalPrice')
        .filterable()
        .filterType(FilterType.INPUT_TEXT)
        .cssClass((() => {
          return 'right-align';
        }))
        .displayContent((data: OperationalAdditionalCost) => {
          return Number(data.pricePerUnit).toFixed(2) + ' €';
        })
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('onPrescription')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.onPrescriptionFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('externalFromCentre')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.externalFromCenterFilterList)
        .sortable()
        .isTranslated()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('type')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .filter('typeHhhId')
        .options(this.typeFilterList)
        .displayContent((detailedTrialAct: DetailedTrialAct) => detailedTrialAct.typeName)
        .sortable()
        .build());
    this.operationalTrialActsHeaders = this.operationalTrialActsTableConfigs.buildTable(availableHeaders);
  }

  initInclusionOperationalActsTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.inclusionOperationalActsTableTarget, AdditionalCostsAppData.tableConfig).subscribe(
        config => {
          this.inclusionOperationalActsTableConfigs = config;
          this.buildInclusionOperationActsTableHeaders();
        }
    );
  }

  buildInclusionOperationActsTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('contractType')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.contractTypes)
        .filter('contractTypeHhhId')
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('actCategoryName')
      .displayContent((data) => {
        return data.categoryName;
      })
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(this.operationalActsCategoryFilterList)
      .optionFilterable()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('description')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.operationalActsDescriptionFilterList)
        .optionFilterable()
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('pricePerUnit')
        .withDisplayCssClassContent()
        .cssClass((() => {
          return 'right-align';
        }))
        .displayContent((data: OperationalAdditionalCost) => {
          return Number(data.pricePerUnit).toFixed(2) + ' €';
        })
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('type')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.operationalActsTypeFilterList)
        .displayContent((data: OperationalAdditionalCost) => data.type)
        .filter('typeHhhId')
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('invoiceable')
        .filterable()
        .filterType(FilterType.DROPDOWN)
        .options(this.operationalActsInvoiceableFilterList)
        .isTranslated()
        .sortable()
        .build());
    this.inclusionOperationalActsHeaders = this.inclusionOperationalActsTableConfigs.buildTable(availableHeaders);
    console.error(this.operationalActsTypeFilterList);
  }

  getOperationalReferenceActsByOperationalAct() {
    this.operationalActService.getOperationalReferenceActsByOperationalActId(this.operationalAct.hhhId).subscribe(
        res => {
          this.operationalAct.operationalAdditionalCostList = res;
          this.operationalAdditioanlCosts = res;
          this.totalNumber = this.operationalAdditioanlCosts.length;
          this.updateDropdownOptions(this.operationalAdditioanlCosts);
        });
  }

  getTrialActs(): Promise<any> {
    return new Promise((resolver: any) => {
      this.trialActService.getTrialActList(this.trialHhhId).subscribe(res => {
        this.trialActs = res;
        this.trialFixedCostService.getAttachedTrialFixedCosts(this.trialHhhId).subscribe(res2 => {
          for (const trialFixedCost of res2) {
            this.trialActs.push(new DetailedTrialAct().buidFromFixedCost(trialFixedCost));
          }
          resolver();
        });
      });
    });
  }


  prepareActColumns(event) {
    for (const operationalAdditionalCost of event) {
      this.prepareStanderColumnsFilterOptions(operationalAdditionalCost, this.contractTypeFilterList, this.typeFilterList, this.actCategoryFilterList, this.actDescriptionFilterList);
      const invoiceableItem = {
        label: operationalAdditionalCost.invoiceable != null ? this.translateService.getTranslationString(operationalAdditionalCost.invoiceable.toString()) : '',
        value: operationalAdditionalCost.invoiceable
      };
      const externalFromCenterItem = {
        label: operationalAdditionalCost.externalFromCentre != null ? this.translateService.getTranslationString(operationalAdditionalCost.externalFromCentre.toString()) : '',
        value: operationalAdditionalCost.externalFromCentre
      };
      const onPrescriptionItem = {
        label: operationalAdditionalCost.onPrescription != null ? this.translateService.getTranslationString(operationalAdditionalCost.onPrescription.toString()) : '',
        value: operationalAdditionalCost.onPrescription
      };
      if (operationalAdditionalCost.invoiceable != null &&
          this.invoiceableFilterList.findIndex((item: SelectItem) => item.value === invoiceableItem.value) === -1) {
        this.invoiceableFilterList.push(invoiceableItem);
      }
      if (operationalAdditionalCost.externalFromCentre != null &&
          this.externalFromCenterFilterList.findIndex((item: SelectItem) => item.value === externalFromCenterItem.value) === -1) {
        this.externalFromCenterFilterList.push(externalFromCenterItem);
      }
      if (operationalAdditionalCost.onPrescription != null &&
          this.onPrescriptionFilterList.findIndex((item: SelectItem) => item.value === onPrescriptionItem.value) === -1) {
        this.onPrescriptionFilterList.push(onPrescriptionItem);
      }
    }
    this.contractTypeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.typeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.actDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.actCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.externalFromCenterFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.invoiceableFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.onPrescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }

  updateDropdownOptions(event) {
    for (const operationalAdditionalCost of event) {
      this.prepareStanderColumnsFilterOptions(operationalAdditionalCost, this.operationalActsContractTypeFilterList, this.operationalActsTypeFilterList, this.operationalActsCategoryFilterList, this.operationalActsDescriptionFilterList);
    }
    this.operationalActsContractTypeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    console.error(this.operationalActsTypeFilterList);
    this.operationalActsTypeFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.operationalActsCategoryFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.operationalActsDescriptionFilterList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    this.cdr.detectChanges();
  }

  prepareStanderColumnsFilterOptions(
      operationalAdditionalCost: any,
      contractTypeFilterList: SelectItem[],
      typeFilterList: SelectItem[],
      actCategoryFilterList: SelectItem[],
      actDescriptionFilterList: SelectItem[]
  ) {
    const contractTypeItem = {label: operationalAdditionalCost.contractType, value: operationalAdditionalCost.contractTypeHhhId};
    if (operationalAdditionalCost.contractType != null &&
        contractTypeFilterList.findIndex((item: SelectItem) => item.label === contractTypeItem.label) === -1) {
      contractTypeFilterList.push(contractTypeItem);
    }
    const typeItem = {label: operationalAdditionalCost.typeName || operationalAdditionalCost.type,  value: operationalAdditionalCost.typeHhhId || operationalAdditionalCost.typeName};
    if ((operationalAdditionalCost.typeName || operationalAdditionalCost.type) != null &&
        typeFilterList.findIndex((item: SelectItem) => item.label === typeItem.label) === -1) {
      typeFilterList.push(typeItem);
    }
    const actCategoryItem = {
      label: operationalAdditionalCost.actCategory,
      value: operationalAdditionalCost.actCategory
    };
    if (operationalAdditionalCost.actCategory != null &&
        actCategoryFilterList.findIndex((item: SelectItem) => item.value === actCategoryItem.value) === -1) {
      actCategoryFilterList.push(actCategoryItem);
    }
    const descItem = {label: operationalAdditionalCost.description, value: operationalAdditionalCost.description};
    if (operationalAdditionalCost.description != null &&
        actDescriptionFilterList.findIndex((item: SelectItem) => item.value === descItem.value) === -1) {
      actDescriptionFilterList.push(descItem);
    }
  }

  displaySelectedActs(selectedList: ReferenceAct[]) {
    this.selectedReferenceActs = selectedList;
  }

  displaySelectedTrialActs(selectedList: DetailedTrialAct[]) {
    this.selectedTrialActs = selectedList;
  }

  cancel() {
    this.selectedReferenceActs = [];
    this.display = false;
    this.dataService.setExportPageModule('');
    this.cancelAction.emit();
  }

  saveActs() {
    this.submitted = true;
    if (this.addForm.invalid || !this.operationalAct.isValid(this.formConfig)) {
      return;
    }
    this.disabledSaveActsButton = true;
    for (const act of this.selectedReferenceActs) {
      const operationalAdditionalCost: OperationalAdditionalCost = new OperationalAdditionalCost();
      operationalAdditionalCost.hhhId = act.hhhId;
      operationalAdditionalCost.typeHhhId = act.typeHhhId;
      operationalAdditionalCost.contractType = act.contractType;
      operationalAdditionalCost.actCategoryName = act.actCategoryName;
      operationalAdditionalCost.pricePerUnit = act.pricePerUnit;
      operationalAdditionalCost.additionalCostType = act.additionalCostType;
      this.operationalAct.operationalAdditionalCostList.push(operationalAdditionalCost);
    }
    this.operationalAct.invoiceable = true;
    if (this.saveOnlyTrialOperationalAct) {
      this.callback(this.buildTrialActsAndTrialFixedCostsInOperationalAct(this.operationalAct));
      this.submitted = false;
      this.display = false;
      return;
    }
    this.operationalActService.addOperationalAct(this.operationalAct).subscribe((newOperationalAct: OperationalAct) => {
      this.sharedService.setInformationInLocalStorage('Grille budgétaire: Actes opérationnels', 'Ajouter', this.operationalAct.description);
      this.callback(this.buildTrialActsAndTrialFixedCostsInOperationalAct(newOperationalAct));
      this.submitted = false;
      this.display = false;
      this.sharedService.showSuccess();
    }, error => {
      console.error(error);
    });
  }

  buildTrialActsAndTrialFixedCostsInOperationalAct(op: OperationalAct): OperationalAct {
    op.trialActHhhIds = [];
    op.trialFixedCostHhhIds = [];
    this.selectedTrialActs.forEach((additionalCost: DetailedTrialAct) => {
      if (additionalCost.additionalCostType === AdditionalCostType.ACT) {
        op.trialActHhhIds.push(additionalCost.hhhId);
      } else {
        op.trialFixedCostHhhIds.push(additionalCost.hhhId);
      }
    });
    return op;
  }


  displayDeleteDialogue(operationalAdditionalCost: OperationalAdditionalCost) {
    const index = this.operationalAct.operationalAdditionalCostList.indexOf(operationalAdditionalCost);
    this.operationalAct.operationalAdditionalCostList.splice(index, 1);
  }

  addSelectedAct(data: any) {
    const operationalAdditionalCostToAdd = data;
    operationalAdditionalCostToAdd.categoryName = data.actCategoryName;
    this.operationalAct.operationalAdditionalCostList.push(operationalAdditionalCostToAdd);
    this.updateDropdownOptions(this.operationalAct.operationalAdditionalCostList);
  }

}

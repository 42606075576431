import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TableConfig} from '../../entity/table-config';
import {DynamicTableHeader} from '../dynamic-lazy-table/dynamic-table-header';
import {isNullOrUndefined} from 'util';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {FilterType} from '../dynamic-lazy-table/filter-type';
import {FR} from '../../../shared/entity/calendar-language';
import {Util} from '../../../helpers/util';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {PropertyEnum} from '../../entity/property-enum';
import {InputType} from '../../../input-type';

@Component({
  selector: 'ih-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.css']
})

export class DynamicTableComponent implements OnInit, AfterViewChecked {

  @Input() config: TableConfig;
  private _headers: DynamicTableHeader[];
  @Input() values;
  @Input() selectedList: any[] = [];

  @Input() limit = 50;
  @Input() styleClass: string;
  @Input() responsive: boolean;
  @Input() totalRecord: number;
  @Input() clickable: boolean;
  @Input() hasDetails: boolean;
  @Input() disabledDelete = false;
  @Input() disabledUpdate = false;
  @Input() disableComment = false;
  @Input() colorRowWhenNew = false;
  @Input() rowClickable = false;
  @Input() disabledCheck = false;
  @Input() pageable = true;
  @Input() scrollHeight = '566px';
  @Input() checkedTable: boolean;
  @Input() selectable = false;
  @Input() editableFields: string[] = [];
  @Input() summaryValue: any;
  @Input() hasSummary = false;
  @Input() paginatorRightLabel = 'MODULE_TRIALS_INCLUSIONS_VISITS_ACTS_FIELD_TOTAL';
  @Input() hasSaveButton = false;
  @Input() dataKey: string;
  @Input() enableEdit = false;
  @Input() deleteAll = false;
  @Input() target: string;
  @Input() displayButtons = true;
  @Input() expandable = false;
  @Input() exportFilename = '';
  @Input() exportDialogTitle = '';
  @Input() showEditBtn = true;
  @Input() virtualScroll = false;
  @Input() virtualScrollHeight = 10;


  @Output() preview = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() dataComment = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() clickEvent = new EventEmitter<any>();
  @Output() rowClick: EventEmitter<any> = new EventEmitter();
  @Output() checkEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editCompleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateTableEvent = new EventEmitter<any>();
  @Output() resetTableEvent = new EventEmitter<any>();
  @Output() saveTableEvent = new EventEmitter<any>();
  @Output() export = new EventEmitter<any>();

  hasPrivateComment = true;
  filterType = FilterType;
  locale = FR;
  filterSelectedItemList: any[] = [];

  inputType = InputType;


  get headers(): DynamicTableHeader[] {
    return this._headers;
  }

  @Input()
  set headers(headers: DynamicTableHeader[]) {
    if (Util.isNullOrUndefined(headers)) {
      return;
    }
    const savedColumnOrder = !Util.isNullOrUndefined(this.target) ? localStorage.getItem(this.target) : null;
    if (!savedColumnOrder) {
      this._headers = headers;
      this.saveColumnOrder({columns: this.headers});
      return;
    }
    const fields = savedColumnOrder.split(',');
    this._headers = [];
    for (const field of fields) {
      headers.map(header => {
        if (header.field === field) {
          this._headers.push(header);
        }
      });
    }
    for (const header of headers) {
      const index = this._headers.findIndex(h => header.field === h.field);
      if (index === -1) {
        this._headers.push(header);
      }
    }
    this.saveColumnOrder({columns: this.headers});

  }

  constructor(
      private translateService: InnohealthTranslateService,
      private dynamicConfigService: DynamicConfigService,
      private datePipe: DatePipe
  ) {
  }

  ngAfterViewChecked() {
    const tableHeader = document.querySelectorAll<HTMLElement>('.ui-table-scrollable-header');
    for (let i = 0; i < tableHeader.length; i++) {
      if (this.values && this.values.length > 0) {
        tableHeader[i].style.overflow = 'hidden';
      } else {
        tableHeader[i].style.overflow = 'auto';
      }
    }
  }

  ngOnInit(): void {
    this.dynamicConfigService.initProperties().subscribe(() =>
        this.hasPrivateComment = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled));
  }


  displayContent(col: DynamicTableHeader, colField: any, rowData: any): string {
    let content = colField;
    if (isNullOrUndefined(rowData[col.field]) && !col.displayContent) {
      content = '';
    } else if (col.isTranslated) {
      content = colField ?
          this.translateService.getTranslationString('UI_DIALOG_BUTTON_YES')
          : this.translateService.getTranslationString('UI_DIALOG_BUTTON_NO');
    } else if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (col.dateFormatter) {
      content = this.datePipe.transform(rowData[col.field], 'dd/MM/yyyy');
    }
    return content;
  }

  getRelatedData(data) {
    if (this.rowClickable) {
      this.rowClick.emit(data);
      return;
    }
    if (this.checkedTable) {
      this.checkEvent.emit(this.selectedList);
    }
    if (this.clickable) {
      this.clickEvent.emit(data.hhhId);
    }
  }

  selectAllData() {
    if (this.checkedTable) {
      this.checkEvent.emit(this.selectedList);
    }
  }

  emitPreview(entity: any) {
    this.preview.emit(entity);
  }

  emitUpdate(entity: any) {
    this.update.emit(entity);
  }

  emitDataComment(attachedToId: number, attachedToField: string, isPrivate: boolean) {
    this.dataComment.emit({attachedToId, attachedToField, isPrivate});
  }


  emitDelete(entity: any) {
    this.delete.emit(entity);
    if (entity instanceof Array) {
      this.selectedList = [];
    }
  }

  emitEditComplete(event: any) {
    this.editCompleteEvent.emit(event);
  }

  emitUpdateTable(event: any) {
    this.updateTableEvent.emit(event);
  }

  emitResetTable() {
    this.resetTableEvent.emit();
  }

  emitSaveTable() {
    this.saveTableEvent.emit();
  }

  emitExport(event) {
    this.export.emit(event);
  }

  getPageDisplayed(event) {
    this.onPageEvent.emit(event);
  }

  getFilteredValue(event: any) {
    this.filterEvent.emit(event.filteredValue);
  }

  getBtnClass(): string {
    return this.config.getBtnClass();
  }

  displayBtn(btnId: string): boolean {
    if (this.config) {
      return this.config.displayBtn(btnId);
    }
    return false;
  }

  isFieldEditable(name: string): boolean {
    return this.editableFields.includes(name);
  }

  saveColumnOrder(event: any) {
    const fields = event.columns.map(value => value.field);
    localStorage.setItem(this.target, fields);
  }


}

import {Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';
import {TheoreticalVisit} from '../../../entity/theoretical-calendar/theoretical-visit';
import {DeleteConfirmationComponent} from '../../../../modals/delete-confirmation/delete-confirmation.component';
import {TheoreticalVisitDuplicationFormComponent} from '../theoretical-visit-duplication-form/theoretical-visit-duplication-form.component';
import {TheoreticalVisitFormComponent} from '../theoretical-visit-form/theoretical-visit-form.component';
import {TheoreticalVisitActUpdateFormComponent} from '../theoretical-visit-act-update-form/update-theoretical-visit-act-form.component';
import {TheoreticalVisitAct} from '../../../entity/theoretical-calendar/theoretical-visit-act';
import {TheoreticalVisitActService} from '../../../service/theoretical-visit-act.service';
import {TheoreticalVisitService} from '../../../service/theoretical-visit.service';
import {ClonedTheoreticalVisit} from '../../../entity/cloned-theoretical-visit';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {TrialActService} from '../../../service/trial-act.service';
import {UsersService} from '../../../../admin/services/users.service';
import {TheoreticalCalendar} from '../../../entity/theoretical-calendar/theoretical-calendar';
import {SharedService} from '../../../../shared/services/shared.service';
import {InnohealthTranslateService} from '../../../../shared/services/innohealth-translate.service';
import {TrialService} from '../../../services/trial.service';
import {OperationalTrialActsComponent} from '../../trial-additionnal-costs/operational-trial-acts/operational-trial-acts.component';
import {TrialOperationalActService} from '../../../service/trial-operational-act.service';
import {TrialPageLockService} from '../../../../dynamic-config/exported/page-lock/trial-page-lock.service';
import {PaginatorTableComponent} from '../../../../shared/component/paginator-table/paginator-table.component';
import {DataService} from '../../../../shared/services/data-service';
import {DetailedTrialAct} from '../../../entity/trial-additionnal-costs/detailed-trial-act';
import {Util} from '../../../../helpers/util';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../../dynamic-config/entity/table-config';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicTableHeaderBuilder} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {DynamicTableHeader} from '../../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {isNullOrUndefined} from 'util';
import {InputType} from '../../../../input-type';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';
import {TrialOperationalAct} from '../../../entity/trial-additionnal-costs/trial-operational-act';

@Component({
  selector: 'ih-theoretical-visit',
  templateUrl: './theoretical-visit.component.html',
  styleUrls: ['./theoretical-visit.component.css']
})
export class TheoreticalVisitComponent extends PaginatorTableComponent<DetailedTrialAct> implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.TRIAL_THEORETICAL_VISIT_ACTS_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  visitReferenceDateEnabled = true;
  theoreticalVisitMarginEnabled = false;
  optionalEnabled = false;

  @ViewChild('deleteTheoreticalVisitPopup', {read: ViewContainerRef}) deletePopupContainer: ViewContainerRef;
  @ViewChild('duplicateTheoreticalVisitDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  @ViewChild('updateTheoreticalVisitDialog', {read: ViewContainerRef}) dialog2Container: ViewContainerRef;
  @ViewChild('updateTheoreticalVisitActDialog', {read: ViewContainerRef}) dialog3Container: ViewContainerRef;
  @ViewChild('displayFormDialog', {read: ViewContainerRef}) formDialogContainer: ViewContainerRef;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) commentDialogContainer: ViewContainerRef;

  @Input() theoreticalVisit: TheoreticalVisit = new TheoreticalVisit();
  @Input() protocolId: number;
  @Input() theoreticalCalendar: TheoreticalCalendar;
  @Input() additionalVisitsEnabled: boolean;

  @Output() getOptional = new EventEmitter<TheoreticalVisitAct>();
  @Output() theoreticalVisitResult = new EventEmitter<boolean>();
  @Output() activeVisit = new EventEmitter<number>();
  @Output() deleteEvent = new EventEmitter<any>();
  @Output() editedTheoreticalVisitEvent = new EventEmitter<any>();
  @Output() clonedTheoreticalVisitEvent = new EventEmitter<any>();
  @Output() deleteTemporaryTheoreticalVisit = new EventEmitter<any>();
  @Output() deleteTemporaryTheoreticalVisitAct = new EventEmitter<any>();
  @Output() deleteTemporaryClonedTheoreticalVisit = new EventEmitter<TheoreticalVisit>();
  @Output() addedTrialActs = new EventEmitter<any>();
  @Output() addedModifiedTrialActs = new EventEmitter<any>();
  @Output() editTemporaryTheoreticalVisitEvent = new EventEmitter<any>();
  trialActsSelected: number[] = [];
  trialId: number;
  total = 0;
  entityHhhId: any;
  publicCommentCount: number;
  public shiftUnits = new Map([
    [0, 'J'],
    [1, 'S'],
    [2, 'M'],
    [3, 'A']
  ]);

  constructor(
    private router: Router,
    private theoreticalVisitActService: TheoreticalVisitActService,
    private useService: UsersService,
    private trialActService: TrialActService,
    private theoreticalVisitService: TheoreticalVisitService,
    private cfr: ComponentFactoryResolver,
    public trialService: TrialService,
    private dataService: DataService,
    private trialOperationalActService: TrialOperationalActService,
    private sharedService: SharedService,
    private canDeactivateService: CanDeactivateService,
    private translateService: InnohealthTranslateService,
    private dynamicConfigService: DynamicConfigService,
    public trialPageLockService: TrialPageLockService
  ) {
    super(dataService, cfr);
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    if (this.theoreticalVisit.visitActs) {
      this.updateTotalPrice();
    }
    this.entityHhhId = localStorage.getItem('entityHhhId');
    this.publicCommentCount = +localStorage.getItem('publicCommentCount');
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.visitReferenceDateEnabled = this.dynamicConfigService.getProperty(PropertyEnum.visitReferenceEnabled);
      this.theoreticalVisitMarginEnabled = this.dynamicConfigService.getProperty(PropertyEnum.theoreticalVisitMarginEnabled);
      this.optionalEnabled = this.dynamicConfigService.getProperty(PropertyEnum.optionalEnabled);
    });
    this.initTableConfig();
  }

  initTableConfig() {
    this.dynamicConfigService.getTableConfig(this.target, TrialAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }


  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('description')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('pricePerUnit')
      .withDisplayCssClassContent()
      .displayContent((theoreticalVisitAct: TheoreticalVisitAct) => this.displayUnitPrice(theoreticalVisitAct))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('numberOfUnits')
      .withDisplayCssClassContent()
      .displayContent((theoreticalVisitAct: TheoreticalVisitAct) => {
        return theoreticalVisitAct.type === 'OPERATIONAL_ACT' ? '-' : theoreticalVisitAct.numberOfUnits;
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('total')
      .withDisplayCssClassContent()
      .displayContent((theoreticalVisitAct: TheoreticalVisitAct) => this.displayTotalPrice(theoreticalVisitAct))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('optional')
      .accessible(this.optionalEnabled)
      .inputType(InputType.checkbox)
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getBtnClass(): string {
    return this.config.getBtnClass();
  }

  displayContent(col: DynamicTableHeader, colField: any, rowData: any): string {
    let content = colField;
    if (col.displayContent) {
      content = col.displayContent(rowData);
    } else if (isNullOrUndefined(rowData[col.field])) {
      content = '';
    }
    return content;
  }

  displayBtn(btnId: string): boolean {
    if (this.config) {
      return this.config.displayBtn(btnId);
    }
    return false;
  }

  ngOnDestroy() {
  }

  createUpdateDialogComponent(theoreticalVisit: TheoreticalVisit) {
    const addDialogFactory = this.cfr.resolveComponentFactory(TheoreticalVisitFormComponent);
    const addDialogComponentRef = this.dialog2Container.createComponent(addDialogFactory);
    const theoreticalVisitFormComponent = addDialogComponentRef.instance;
    theoreticalVisitFormComponent.displayTheoreticalVisitDialog = true;
    theoreticalVisitFormComponent.theoreticalVisitAdd.hhhId = theoreticalVisit.hhhId;
    theoreticalVisitFormComponent.theoreticalVisitAdd.name = theoreticalVisit.name;
    theoreticalVisitFormComponent.theoreticalVisitAdd.comment = theoreticalVisit.comment;
    theoreticalVisitFormComponent.theoreticalVisitAdd.period = theoreticalVisit.period;
    theoreticalVisitFormComponent.theoreticalVisitAdd.periodMargin = theoreticalVisit.periodMargin;
    theoreticalVisitFormComponent.theoreticalVisitAdd.periodType = '' + theoreticalVisit.periodType;
    theoreticalVisitFormComponent.theoreticalVisitAdd.type = theoreticalVisit.type;
    theoreticalVisitFormComponent.theoreticalVisitAdd.displayHowManyDays = theoreticalVisit.displayHowManyDays;
    theoreticalVisitFormComponent.theoreticalVisitAdd.theoreticalCalendarHhhId = this.trialId;
    theoreticalVisitFormComponent.theoreticalVisitAdd.dateReferenceVisit = theoreticalVisit.dateReferenceVisit;
    theoreticalVisitFormComponent.theoreticalVisitAdd.expectedDateReference = theoreticalVisit.expectedDateReference;
    theoreticalVisitFormComponent.theoreticalVisitAdd.expectedDateRelativeTo =
      theoreticalVisit.expectedDateReference === 'OTHER_VISIT' ? theoreticalVisit.dateReferenceVisit.hhhId : theoreticalVisit.expectedDateReference;
    theoreticalVisitFormComponent.theoreticalVisitAdd.armHhhId = theoreticalVisit.armHhhId;
    theoreticalVisitFormComponent.theoreticalCalendar = this.theoreticalCalendar;
    theoreticalVisitFormComponent.prepareDateReferences();
    theoreticalVisitFormComponent.addSuccess.subscribe(editedTheoreticalVisit => {
      this.canDeactivateService.canBeDeactivated = false;
      if (this.theoreticalVisit.modificationType === 'add') {
        const oldTheoreticalVisit: TheoreticalVisit = this.theoreticalVisit;
        this.theoreticalVisit.name = editedTheoreticalVisit.name;
        this.theoreticalVisit.displayHowManyDays = editedTheoreticalVisit.getHowManyDays();
        this.theoreticalVisit.periodMargin = editedTheoreticalVisit.periodMargin;
        this.editTemporaryTheoreticalVisitEvent.emit({
          oldVisit: oldTheoreticalVisit,
          modifiedVisit: editedTheoreticalVisit
        });
      } else {
        this.theoreticalVisit.name = editedTheoreticalVisit.name;
        this.theoreticalVisit.period = editedTheoreticalVisit.period;
        this.theoreticalVisit.periodMargin = editedTheoreticalVisit.periodMargin;
        this.theoreticalVisit.modificationType = editedTheoreticalVisit.modificationType;
        this.theoreticalVisit.displayHowManyDays = editedTheoreticalVisit.getHowManyDays();
        this.editedTheoreticalVisitEvent.emit(editedTheoreticalVisit);
      }
    });
  }

  onDelete() {
    if (this.theoreticalVisit.modificationType === 'add') {
      this.deleteTemporaryTheoreticalVisit.emit(this.theoreticalVisit);
    } else if (this.theoreticalVisit.modificationType === 'clone') {
      this.deleteTemporaryClonedTheoreticalVisit.emit(this.theoreticalVisit);
    } else {
      this.displayDeleteDialogue(this.theoreticalVisit);
    }
  }

  onActDelete(entity: TheoreticalVisitAct) {
    const theoreticalVisitIsNew = entity.modificationType === 'add' || (entity.modificationType === 'edit' && !entity.visitHhhId);
    if (theoreticalVisitIsNew) {
      const visitActIndex = this.theoreticalVisit.visitActs.indexOf(entity);
      this.theoreticalVisit.visitActs.splice(visitActIndex, 1);
      const tmpVisitActs = this.theoreticalVisit.visitActs;
      this.theoreticalVisit.visitActs = [...tmpVisitActs];
      this.canDeactivateService.canBeDeactivated = false;
      this.deleteTemporaryTheoreticalVisitAct.emit({theoreticalVisit: this.theoreticalVisit, act: entity});
      this.updateTotalPrice();
    } else {
      this.displayActDeleteDialogue(entity);
    }
  }

  createTheoreticalVisitDuplicationComponent() {
    const duplicationDialogFactory = this.cfr.resolveComponentFactory(TheoreticalVisitDuplicationFormComponent);
    const duplicationDialogComponentRef = this.dialogContainer.createComponent(duplicationDialogFactory);
    const theoreticalVisitDuplicationFormComponent = duplicationDialogComponentRef.instance;
    theoreticalVisitDuplicationFormComponent.protocolId = this.protocolId;
    theoreticalVisitDuplicationFormComponent.clonedTheoreticalVisit.theoreticalVisitHhhId = this.theoreticalVisit.hhhId;
    theoreticalVisitDuplicationFormComponent.clonedTheoreticalVisit.name = this.theoreticalVisit.name;
    theoreticalVisitDuplicationFormComponent.clonedTheoreticalVisit.firstPeriodType =
      this.theoreticalVisit.periodType + '' === '-1' ? '0' : '' + this.theoreticalVisit.periodType;
    theoreticalVisitDuplicationFormComponent.clonedTheoreticalVisit.firstPeriod = this.theoreticalVisit.period;
    theoreticalVisitDuplicationFormComponent.originalTheoreticalVisit = this.theoreticalVisit;
    theoreticalVisitDuplicationFormComponent.clonedTheoreticalVisit.selectedArm =
      this.theoreticalVisit.type === 'IN_INCLUSION' ? '' + this.theoreticalVisit.armHhhId : this.theoreticalVisit.type;
    theoreticalVisitDuplicationFormComponent.clonedTheoreticalVisit.trialHhhId = this.trialId;
    theoreticalVisitDuplicationFormComponent.trialHhhId = this.trialId;
    theoreticalVisitDuplicationFormComponent.displayDuplicateTheoreticalVisitDialog = true;
    theoreticalVisitDuplicationFormComponent.clonedTheoreticalVisit.periodType= this.theoreticalVisit.periodType.toString();
    theoreticalVisitDuplicationFormComponent.onClone((clonedTheoreticalVisit: ClonedTheoreticalVisit) => {
      this.canDeactivateService.canBeDeactivated = false;
      this.theoreticalVisitResult.emit(true);
      this.clonedTheoreticalVisitEvent.emit({
        clonedTheoreticalVisit,
        originalVisitPeriodInDays: this.calculateHowManyDays(this.theoreticalVisit.periodType.toString(), this.theoreticalVisit.period)
      });
    });
  }

  createTheoreticalVisitActUpdateDialogComponent(theoreticalVisitAct: TheoreticalVisitAct) {

    if (theoreticalVisitAct.type === 'OPERATIONAL_ACT') {
      this.trialOperationalActService.getTrialOperationalActById(theoreticalVisitAct.trialActHhhId).subscribe(res => {
        const formComponent = OperationalTrialActsComponent.displayFormDialogue(this.formDialogContainer, this.cfr);
        formComponent.trialOperationalAct = Object.assign(new TrialOperationalAct(), res);
        formComponent.fromTheoreticalVisit = true;
      });
    } else {
      const addDialogFactory = this.cfr.resolveComponentFactory(TheoreticalVisitActUpdateFormComponent);
      const addDialogComponentRef = this.dialog3Container.createComponent(addDialogFactory);
      const theoreticalVisitActUpdateForm = addDialogComponentRef.instance;
      theoreticalVisitActUpdateForm.theoreticalVisitAct = theoreticalVisitAct;
      theoreticalVisitActUpdateForm.onUpdate((visitAct: TheoreticalVisitAct) => {
        this.canDeactivateService.canBeDeactivated = false;
        const index = this.theoreticalVisit.visitActs.indexOf(visitAct);
        this.theoreticalVisit.visitActs[index].modificationType = 'edit';
        this.updateTotalPrice();
        this.addedModifiedTrialActs.emit(
          {
            theoreticalVisitAct,
            visitModificationType: this.theoreticalVisit.modificationType,
            theoreticalVisitHhhId: this.theoreticalVisit.hhhId,
            optional: this.theoreticalVisit.optional
          }
        );
      });
    }
  }

  public calculateHowManyDays(periodType: string, period: number): number {
    // TODO this method is created because can't used theoreticalVisit.calculateHowManyDays method.
    let periodInDays = -1;
    switch (periodType) {
      case '0':
        periodInDays = period;
        break;
      case '1':
        periodInDays = period * 7;
        break;
      case '2':
        periodInDays = period * 30;
        break;
      case '3':
        periodInDays = period * 365;
        break;
    }
    return periodType ? periodInDays : 0;
  }

  toggleActsDisplay(hhhId: number) {
    this.theoreticalVisit.activeVisitHhhId = this.theoreticalVisit.activeVisitHhhId === hhhId ? 0 : hhhId;
  }

  addActs() {
    if (!this.theoreticalVisit.visitActs) {
      this.theoreticalVisit.visitActs = [];
    }
    if (this.trialActsSelected.length > 0) {
      this.trialActService.getTrialActsByHhhIds(this.trialActsSelected).subscribe(trialActs => {
        for (const trialAct of trialActs) {
          const visitAct: TheoreticalVisitAct = new TheoreticalVisitAct();
          visitAct.hhhId = trialAct.hhhId;
          visitAct.description = trialAct.name;
          visitAct.pricePerUnit = trialAct.pricePerUnit;
          visitAct.invoiceable = trialAct.invoiceable;
          visitAct.actCategory = trialAct.actCategory;
          visitAct.type = trialAct.type;
          visitAct.optional = trialAct.optional;
          visitAct.numberOfUnits = 1;
          visitAct.modificationType = 'add';
          this.theoreticalVisit.visitActs.push(visitAct);
          this.theoreticalVisitResult.emit(true);
          this.activeVisit.emit(this.theoreticalVisit.activeVisitHhhId);
          this.addedTrialActs.emit({trialActIds: this.trialActsSelected, theoreticalVisit: this.theoreticalVisit});
          const tmpVisitActs = this.theoreticalVisit.visitActs;
          this.theoreticalVisit.visitActs = [...tmpVisitActs];
          const tmpSelectedTrialActs = [];
          this.trialActsSelected = [...tmpSelectedTrialActs];
          this.updateTotalPrice();
        }
      });
    }
  }

  updateTotalPrice() {
    this.total = 0;
    for (const theoreticalVisitAct of this.theoreticalVisit.visitActs) {
      if (theoreticalVisitAct.amendmentModificationType !== 'delete'
        && theoreticalVisitAct.modificationType !== 'delete'
        && theoreticalVisitAct.invoiceable) {
        this.total += theoreticalVisitAct.pricePerUnit * theoreticalVisitAct.numberOfUnits;
      }
    }
  }

  displayDeleteDialogue(entity: any) {
    const deletePopupFactory = this.cfr.resolveComponentFactory(DeleteConfirmationComponent);
    const deletePopupComponentRef = this.deletePopupContainer.createComponent(deletePopupFactory);
    const deleteConfirmationComponent = deletePopupComponentRef.instance;
    deleteConfirmationComponent.entityName = 'TheoreticalVisit';
    deleteConfirmationComponent.hhhId = entity.hhhId;
    deleteConfirmationComponent.popupMessage =
      'MODULE_TRIALS_THEORETICAL_CALENDARS_THEORETICAL_VISITS_DIALOG_WARNING_THEORETICAL_VISIT_WILL_BE_DELETED';
    deleteConfirmationComponent.service = this.theoreticalVisitService;
    deleteConfirmationComponent.name = entity.name;
    this.activeVisit.emit(this.theoreticalVisit.activeVisitHhhId);
    deleteConfirmationComponent.onDelete(() => {
      this.canDeactivateService.canBeDeactivated = false;
      this.theoreticalVisit.modificationType = 'delete';
      this.deleteEvent.emit({entityHhhId: entity.hhhId, entityClassName: 'TheoreticalVisit'});
      this.theoreticalVisitResult.emit(true);
      this.activeVisit.emit(this.theoreticalVisit.activeVisitHhhId);
    });
  }

  displayActDeleteDialogue(entity: any) {
    const deletePopupFactory = this.cfr.resolveComponentFactory(DeleteConfirmationComponent);
    const deletePopupComponentRef = this.deletePopupContainer.createComponent(deletePopupFactory);
    const deleteConfirmationComponent = deletePopupComponentRef.instance;
    deleteConfirmationComponent.entityName = 'VisitAct';
    deleteConfirmationComponent.hhhId = entity.hhhId;
    deleteConfirmationComponent.popupMessage = 'MODULE_TRIALS_THEORETICAL_ACTS_DIALOG_WARNING_ACT_WILL_BE_DELETED';
    deleteConfirmationComponent.service = this.theoreticalVisitActService;
    deleteConfirmationComponent.name = entity.description;
    this.activeVisit.emit(this.theoreticalVisit.activeVisitHhhId);
    deleteConfirmationComponent.onDelete(() => {
      const index = this.theoreticalVisit.visitActs.indexOf(entity);
      this.theoreticalVisit.visitActs[index].modificationType = 'delete';
      this.updateTotalPrice();
      this.deleteEvent.emit({entityHhhId: entity.hhhId, entityClassName: 'VisitAct'});
      this.theoreticalVisitResult.emit(true);
      this.activeVisit.emit(this.theoreticalVisit.activeVisitHhhId);
    });
  }

  displayUnitPrice(theoreticalVisitAct: TheoreticalVisitAct): string {
    if (!this.useService.canAccessFieldByValue(theoreticalVisitAct.type)) {
      return '-';
    }
    return (theoreticalVisitAct.invoiceable ? (theoreticalVisitAct.pricePerUnit).toFixed(2) + '€' : '-');
  }

  displayTotalPrice(theoreticalVisitAct: TheoreticalVisitAct): string {
    if (!this.useService.canAccessFieldByValue(theoreticalVisitAct.type)) {
      return '-';
    }
    return theoreticalVisitAct.invoiceable ? (Util.multiply(theoreticalVisitAct.pricePerUnit, theoreticalVisitAct.numberOfUnits) + '€') : '-';
  }

  isPriceAccessed(type: string) {
    return this.useService.canAccessFieldByValue(type);
  }

  handleAdditionalVisitChanged() {
    this.editedTheoreticalVisitEvent.emit(this.theoreticalVisit);
    if (this.theoreticalVisit.visitIsAdditionalTest) {
      this.sharedService.showSuccess(this.translateService.getTranslationString('MODULE_TRIALS_PATIENTS_CALENDAR_VISIT_ADDITIONAL_TEST_CHANGED_TO_TRUE'));
    }
  }

  sendOptional(theoreticalVisitAct: TheoreticalVisitAct) {
    this.getOptional.emit(theoreticalVisitAct);
  }

  displayDataComment(theoreticalVisitAct: any) {
    if (!theoreticalVisitAct.trialActHhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.TrialAct, theoreticalVisitAct.trialActHhhId, 'hhhId', false);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.commentDialogContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateVisitActCommentCount(dataCommentEvent, theoreticalVisitAct));
  }

  updateVisitActCommentCount(dataCommentEvent: DataCommentEvent, visitAct: any): void {
    const act = this.theoreticalVisit.visitActs.find(act => act.trialActHhhId === visitAct.trialActHhhId);
    if (act) {
      act.countOfPublicComment = dataCommentEvent.publicCommentCount;
    }
  }
}

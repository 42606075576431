import {Component, OnDestroy, OnInit} from '@angular/core';
import {Arm} from '../../../../entity/inclusion/arm';
import {Doctor} from '../../../../../human-resources/models/doctor';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {InnohealthTranslateService} from '../../../../../shared/services/innohealth-translate.service';
import {MenuItem} from 'primeng/api';
import {InclusionDetailService} from '../../../../service/inclusion-detail.service';
import {DataService} from '../../../../../shared/services/data-service';
import {TranslateService} from '@ngx-translate/core';
import {TrialService} from '../../../../services/trial.service';
import {TrialHead} from '../../../../models/trial-head';
import {PropertyEnum} from '../../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-inclusion-details-container',
  templateUrl: './inclusion-details-container.component.html',
  styleUrls: ['./inclusion-details-container.component.css']
})
export class InclusionDetailsContainerComponent implements OnInit, OnDestroy {

  isInclusionNumberEnabled = false;
  isInclusionDetailPatientNameWithIppEnabled = false;
  amendmentsEnabled = false;
  isInclusionInformationArmEnabled = false;

  items: MenuItem[];
  arm: Arm = new Arm();
  referrerInvestigator: Doctor = new Doctor();
  patientName: string;
  patientIpp: string;
  hhhId: number;
  inclusionId: number;
  actualRoute = '';
  trialHead: TrialHead = new TrialHead(null);
  displayHeader: boolean;
  patientHhhId: number;
  inclusionNumber: string;

  constructor(
      private inclusionService: InclusionDetailService,
      private translate: TranslateService,
      private translateService: InnohealthTranslateService,
      private router: Router,
      private dataService: DataService,
      private activatedRoute: ActivatedRoute,
      private trialService: TrialService,
      private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    this.activatedRoute.queryParams.subscribe(params => {
          console.warn(params);
        }
    );
    if (url.queryParams) {
      this.inclusionId = url.queryParams.inclusionId;
      this.hhhId = url.queryParams.hhhId;
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const currentUrl = val.url;
        if (currentUrl.indexOf('?') > 0) {
          this.actualRoute = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.indexOf('?'));
        } else {
          this.actualRoute = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.length);
        }
      }
    });
  }

  ngOnInit() {
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.amendmentsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.amendmentsEnabled);
      this.isInclusionInformationArmEnabled = this.dynamicConfigService.getProperty(PropertyEnum.inclusionInformationArmEnabled);
      this.isInclusionNumberEnabled = this.dynamicConfigService.getProperty(PropertyEnum.inclusionNumberEnabled);
      this.isInclusionDetailPatientNameWithIppEnabled = this.dynamicConfigService.getProperty(PropertyEnum.inclusionDetailPatientNameWithIppEnabled);

      this.getPatientData();
      this.dataService.setExportPageModule('/trial-details/inclusion-details/inclusion-information');
      this.translate.stream('key').subscribe(() => {
        this.dataService.currentInclusionMenu.subscribe((menu: string) => {
          this.displayHeader = (menu === 'information-patientTracking-form-patient-inclusion' || menu === 'information-form-patient-inclusion');
          this.trialService.getTrialHead(this.hhhId).subscribe(trialHead => {
            this.trialHead = trialHead;
          });
          const queryParams = {
            hhhId: this.hhhId,
            inclusionId: this.inclusionId
          };
          this.items = [];
          const informationComponent = this.amendmentsEnabled && !this.isInclusionInformationArmEnabled ? 'inclusion-information-version-protocol' : 'inclusion-information-arm';
          if (menu === 'information-patientTracking-form-patient-inclusion' || menu === 'information-form-patient-inclusion') {
            this.items.push({
              label: this.translateService.getTranslationString('MODULE_PATIENTS_HORIZONTAL_MENU_TRIALS_ENTRY_HOME'),
              routerLink: ['/patient-inclusion-details/' + informationComponent],
              queryParams
            });
          } else {
            this.items.push({
              label: this.translateService.getTranslationString('MODULE_PATIENTS_HORIZONTAL_MENU_TRIALS_ENTRY_HOME'),
              routerLink: ['/trial-details/inclusion-details/' + informationComponent],
              queryParams
            });
          }
          if (menu.indexOf('information-calendar') !== -1) {
            this.items.push({
              label: this.translateService.getTranslationString('MODULE_PATIENTS_HORIZONTAL_MENU_TRIALS_ENTRY_VISITS'),
              routerLink: ['/trial-details/inclusion-details/calendar'],
              queryParams
            });
          }
          if (menu === 'information-patientTracking-form-patient-inclusion') {
            this.items.push({
              label: this.translateService.getTranslationString('MODULE_PATIENTS_HORIZONTAL_MENU_TRIALS_ENTRY_VISITS'),
              routerLink: ['/patient-inclusion-details/calendar'],
              queryParams
            });
          }
          if (menu === 'information-patientTracking-form-patient-inclusion') {
            this.items.push({
              label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_PATIENTS_ENTRY_PATIENT_FOLLOW_UP'),
              routerLink: ['/patient-inclusion-details/patient-follow-up-container'],
              queryParams
            });
          } else if (menu.indexOf('patientTracking') !== -1) {
            this.items.push({
              label: this.translateService.getTranslationString('MODULE_TRIALS_HORIZONTAL_MENU_PATIENTS_ENTRY_PATIENT_FOLLOW_UP'),
              routerLink: ['/trial-details/inclusion-details/patient-follow-up-container'],
              queryParams
            });
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  getPatientData() {
    this.inclusionService.getPatientData(this.inclusionId).subscribe(res => {
      this.patientName = res.name;
      this.inclusionNumber = res.inclusionNumber;
      this.patientHhhId = res.hhhId;
      this.patientIpp = res.ipp;
    });
  }

  goToTrialIdentificationInformation() {
    this.router
        .navigate(
            ['/trial-details/trial-identification-information-container/trial-identification-information'],
            {queryParams: {hhhId: this.trialHead.hhhId}}
        ).then();
  }

  goBackToPatientDetails() {
    this.router
        .navigate(
            ['/patient-details'],
            {queryParams: {hhhId: this.patientHhhId}}
        ).then();
  }
}

import {TheoreticalVisit} from './theoretical-visit';
import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';

export class TheoreticalVisitAdd {
  public hhhId: number;
  public theoreticalCalendarHhhId: number;
  public type: string;
  public armHhhId: number;
  public name: string;
  public comment: string;
  public periodType: string;
  public period: number=0;
  public periodMargin: number;
  public modificationType: string;
  public displayHowManyDays: string;
  public total: number;
  public expectedDateReference: string;
  public dateReferenceVisit: TheoreticalVisit;
  public expectedDateRelativeTo: any;


  init(obj: any): TheoreticalVisitAdd {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig, visitReferenceEnabled: boolean): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'armHhhId') {
        if (required && this.type === 'IN_INCLUSION' && Util.isNullOrUndefined(this.armHhhId)) {
          return false;
        }
      } else if (key === 'expectedDateRelativeTo') {
        if (required && visitReferenceEnabled && Util.isNullOrUndefinedOrEmpty(this.expectedDateRelativeTo)) {
          return false;
        }
      } else if (key === 'period') {
        if (required && this.periodType !== '-1' && Util.isNullOrUndefinedOrEmpty(this.period)) {
          return false;
        }
      } else if (key === 'periodMargin') {
        if (required && this.periodType !== '-1' && Util.isNullOrUndefinedOrEmpty(this.periodMargin)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }

  public calculateHowManyDays(): number {
    let periodInDays: number;
    switch (this.periodType) {
      case '0': {
        periodInDays = this.getPeriodInDaysFromDays(this.period);
        break;
      }
      case '1': {
        periodInDays = this.getPeriodInDaysFromWeeks(this.period);
        break;
      }
      case '2': {
        periodInDays = this.getPeriodInDaysFromMonths(this.period);
        break;
      }
      case '3': {
        periodInDays = this.getPeriodInDaysFromYears(this.period);
        break;
      }
    }
    return this.periodType ? periodInDays : 0;
  }

  public getHowManyDays(referenceVisit?: TheoreticalVisit): string {
    let periodInDays;
    if (referenceVisit === undefined || referenceVisit.displayHowManyDays === 'J+Aucun') {
      periodInDays = this.calculateHowManyDays();
    } else {
      if (this.periodType === '-1') {
        this.periodType = referenceVisit.periodType;
      }
      periodInDays = this.calculateHowManyDays() + Number(referenceVisit.displayHowManyDays.substring(1));
    }
    if (this.periodType !== '-1') {
      this.displayHowManyDays = 'J' + (periodInDays >= 0 ? '+' : '') + periodInDays;
    } else {
      this.periodType = '-1';
      this.displayHowManyDays = 'J+Aucun';
    }
    return this.displayHowManyDays;
  }

  getPeriodInDaysFromDays(period: number): number {
    return period ? period : 0;
  }

  getPeriodInDaysFromWeeks(period: number): number {
    return period ? period * 7 : 0;
  }

  getPeriodInDaysFromMonths(period: number): number {
    return period ? period * 30 : 0;
  }

  getPeriodInDaysFromYears(period: number): number {
    return period ? period * 365 : 0;
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DataImportComponent} from './data-import/data-import.component';
import {DataImportRoutingModule} from './data-import-routing.module';
import {ButtonModule, CardModule, DropdownModule, TabViewModule, ToolbarModule} from 'primeng/primeng';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {PapaParseModule} from 'ngx-papaparse';
import {ToastModule} from 'primeng/toast';
import {HttpClientModule} from '@angular/common/http';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';

@NgModule({
  declarations: [DataImportComponent],
  imports: [
    DataImportRoutingModule,
    ToolbarModule,
    CardModule,
    TabViewModule,
    ButtonModule,
    ToastModule,
    PapaParseModule,
    PdfViewerModule,
    CommonModule,
    HttpClientModule,
    TranslateModule,
    FormsModule,
    DropdownModule
  ]
})
export class DataImportModule {
}

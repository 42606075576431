import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {TrialAdministrativeDatesInformation} from '../models/trial-administrative-dates-information';
import {catchError, map} from 'rxjs/operators';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class TrialAdministrativeDatesService {

  host = new Properties().host + '/trial-administrative-dates';

  constructor(private httpClient: HttpClient) {
  }

  load(trialId: number, trialSiteLiaisonId?: number): Observable<TrialAdministrativeDatesInformation> {
    return this.httpClient.get<TrialAdministrativeDatesInformation>(this.host + '/load/' + trialId + '/' + (trialSiteLiaisonId || 0))
      .pipe(
        map(res => {
            return (new TrialAdministrativeDatesInformation()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  updateAdministrativeDatesInformation(trialAdministrativeDatesInformation: TrialAdministrativeDatesInformation): Observable<number> {
    return this.httpClient.put<number>(this.host + '/update', trialAdministrativeDatesInformation);
  }

  hasConventionTypes(trialHhhId: number): Observable<boolean> {
    return this.httpClient.get<boolean>(this.host + '/has-convention-types/' + trialHhhId);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

  exportAdministrativeDates(request: ExportRequest<void>, trialSiteLiaisonHhhId: number): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv/' + trialSiteLiaisonHhhId, request);
  }
  getConventionTypeNA(): Observable<number> {
    return this.httpClient.get<number>(this.host +'/loadNA');
  }

}

import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {LabelValue} from '../../shared/entity/label-value';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommitteeDecisionService {

  public host = new Properties().host + '/data/items/committee-decision';
  trialHhhId: number;

  constructor(private httpClient: HttpClient) {}

  getCommitteeDecisions(): Observable<LabelValue[]> {
    return this.httpClient.get<LabelValue[]>(this.host);
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Arm} from '../entity/inclusion/arm';
import {Properties} from '../../helpers/properties';
import {Deletable} from '../../modals/delete-confirmation/deletable';
import {IsDeletable} from '../../is-deletable';
import {DesignStructureConfig} from '../models/design-structure-config';
import {Value} from '../../shared/entity/value';
import {catchError, map} from 'rxjs/operators';
import {DetailedHumanResource} from '../../human-resources/models/detailed-human-resource';

@Injectable({
  providedIn: 'root'
})
export class ArmService implements Deletable {

  public host = new Properties().host + '/arms';

  private arms: Subject<Arm[]> = new BehaviorSubject<Arm[]>([]);
  armList = this.arms.asObservable();


  constructor(private httpClient: HttpClient) {
  }

  setArmList(value) {
    this.arms.next(value);
  }

  getArmById(armId: number): Observable<Arm> {
    return this.httpClient.get<Arm>(this.host + '/load/' + armId)
      .pipe(
        map(res => {
            return (new Arm()).init(res);
          }
        ),
        catchError(this.handleError));
  }

  addArm(arm: Arm): Observable<number> {
    return this.httpClient.post<number>(this.host + '/save', arm);
  }

  getArmsByTrialId(trialId: string): Observable<Arm[]> {
    return this.httpClient.get<Arm[]>(this.host + '/load-list/' + trialId + '/' + 0)
      .pipe(
        map(res => {
          return res.map(item => (new Arm()).init(item));
          }
        ),
        catchError(this.handleError));
  }

  loadListByTrialAndProtocolId(trialId: string, protocolId: number): Observable<Arm[]> {
    return this.httpClient.get<Arm[]>(this.host + '/load-list/' + trialId + '/' + (protocolId ? protocolId : 0));
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  generateTheoreticalCalendar(armId: number, protocolId: number): Observable<Value<number>> {
    const data = {armId, protocolId};
    return this.httpClient.put<Value<number>>(this.host + '/generate-theoretical-calendar', data);
  }

  getDesignConfig(): Observable<DesignStructureConfig> {
    return this.httpClient.get<DesignStructureConfig>(this.host + '/design-config');
  }

  saveAllArmDesigns(trialId: number, protocolId: number, designs: Array<Arm>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/design-arm/save-all/' + trialId + '/' + protocolId, designs);
  }

  armsNamesRemainByProtocol(protocolId: number, armIds: Array<number>): Observable<Value<boolean>> {
    return this.httpClient.post<Value<boolean>>(this.host + '/arms-names-exists/' + protocolId, armIds);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PharmacyDispensation} from '../entity/trial-pharmacy/pharmacyDispensation';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {PharmacyDispensationGlobalFilter} from '../../pharmacy/model/pharmacyDispensationGlobalFilter';
import {ResponsePage} from '../../shared/entity/response-page';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';

@Injectable({
  providedIn: 'root'
})
export class PharmacyDispensationService {

  public host = new Properties().host + '/pharmacy-dispensation';

  public pharmacyHhhId: number;
  public pharmacyDispensationDataPage: ResponsePage<PharmacyDispensation>;
  public page: number;
  public limit: number;
  public sortBy: string;
  public orderBy: string;
  public pharmacyDispensationGlobalFilter: PharmacyDispensationGlobalFilter = new PharmacyDispensationGlobalFilter();

  constructor(private httpClient: HttpClient) {
  }

  setPaginationConfiguration(dataPage, page, limit, sortBy, orderBy, filters: PharmacyDispensationGlobalFilter, pharmacyHhhId: number) {
    this.pharmacyDispensationDataPage = dataPage;
    this.page = page;
    this.limit = limit;
    this.sortBy = sortBy;
    this.orderBy = orderBy;
    this.pharmacyHhhId = pharmacyHhhId;
    this.pharmacyDispensationGlobalFilter = filters;
  }

  loadList(page: number,
           limit: number,
           sortBy: string,
           orderBy: string,
           pharmacyHhhId: number,
           filters: PharmacyDispensationGlobalFilter): Observable<ResponsePage<PharmacyDispensation>> {
    return this.httpClient.post<ResponsePage<PharmacyDispensation>>(
      this.host + '/load-list',
      new RequestPageBuilder<PharmacyDispensationGlobalFilter>()
        .objectId(pharmacyHhhId)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(filters)
        .build()
    );
  }

  update(pharmacyDispensation: PharmacyDispensation): Observable<void> {
    return this.httpClient.post<void>(this.host + '/update', pharmacyDispensation);
  }

  exportPharmacyDispensations(request: ExportRequest<PharmacyDispensationGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request);
  }

}

import {Component, OnInit} from '@angular/core';
import {LabelValue} from '../../../shared/entity/label-value';
import {InvoiceAct} from '../../invoice-acts/models/invoice-act';
import {HiddenFields} from '../../../admin/models/HiddenFields';
import {UsersService} from '../../../admin/services/users.service';
import {DataService} from '../../../shared/services/data-service';
import {Router} from '@angular/router';
import {TrialService} from '../../../trial/services/trial.service';
import {VisitActService} from '../../../trial/service/visit-act.service';
import {InvoiceFixedCost} from '../../models/invoice-fixed-cost';
import {InnohealthTranslateService} from '../../../shared/services/innohealth-translate.service';
import {DynamicDefinitionEnum} from '../../../dynamic-config/entity/dynamic-definition-enum';
import {TableConfig} from '../../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {PropertyEnum} from '../../../dynamic-config/entity/property-enum';
import {InvoiceAppData} from '../../Invoice-app-data';
import {DynamicConfigService} from '../../../dynamic-config/service/dynamic-config.service';
import {DynamicTableHeaderBuilder} from '../../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {Data} from '../../../shared/entity/data';

@Component({
  selector: 'ih-ongoing-invoice-details',
  templateUrl: './ongoing-invoice-details.component.html',
  styleUrls: ['./ongoing-invoice-details.component.css']
})
export class OngoingInvoiceDetailsComponent implements OnInit {

  counterpartTarget = DynamicDefinitionEnum.INVOICE_COUNTERPART_LIST_TABLE;
  actsTarget = DynamicDefinitionEnum.INVOICE_ACT_LIST_TABLE;
  fixedCostsTarget = DynamicDefinitionEnum.INVOICE_FIXED_COST_LIST_TABLE;
  isCsetEnabled = true;
  counterPartEnabled = true;
  csetLabelKey = 'MODULE_INVOICES_SELECT_THE_CSET_PROTOCOL_NUMBER_OF_THE_TRIAL';
  invoiceProtocolNumberEnabled = false;


  counterpartConfig: TableConfig;
  actsConfig: TableConfig;
  fixedCostsConfig: TableConfig;
  counterpartsHeaders: DynamicTableHeader[] = [];
  actsHeaders: DynamicTableHeader[] = [];
  fixedCostsHeaders: DynamicTableHeader[] = [];


  selectedTrial: number;
  public invoiceMenu: LabelValue[];
  activatedMenu: 'ACT' | 'FIXED_COST' | 'COUNTER_PART' = 'ACT';
  invoiceActs: InvoiceAct[] = [];
  invoiceFixedCosts: InvoiceFixedCost[] = [];
  billableCounterParts: InvoiceAct[] = [];

  constructor(
    private useService: UsersService,
    private dataService: DataService,
    private trialService: TrialService,
    private visitActService: VisitActService,
    private translateService: InnohealthTranslateService,
    private router: Router,
    private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    this.initProperties();
    this.getAllVisitActs();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isCsetEnabled = this.dynamicConfigService.getProperty(PropertyEnum.csetEnabled);
      this.csetLabelKey = this.dynamicConfigService.getProperty(PropertyEnum.csetLabelKey);
      this.counterPartEnabled = this.dynamicConfigService.getProperty(PropertyEnum.counterPartEnabled);
      this.invoiceProtocolNumberEnabled = this.dynamicConfigService.getProperty(PropertyEnum.invoiceProtocolNumberEnabled);
      this.setMenuItems();
    });
    this.initTableConfig(this.actsConfig, this.actsTarget);
    this.initTableConfig(this.fixedCostsConfig, this.fixedCostsTarget);
    this.initTableConfig(this.counterpartConfig, this.counterpartTarget);

  }

  initTableConfig(config: TableConfig, target: string): void {
    config = InvoiceAppData.tableConfig.get(target);
    if (config) {
      if (target === DynamicDefinitionEnum.INVOICE_ACT_LIST_TABLE) {
        this.buildActsTableTable(config);
      } else if (target === DynamicDefinitionEnum.INVOICE_FIXED_COST_LIST_TABLE) {
        this.buildFixedCostsTableHeaders(config);
      } else if (target === DynamicDefinitionEnum.INVOICE_COUNTERPART_LIST_TABLE) {
        this.buildCounterpartsTableHeaders(config);
      }
    } else {
      this.dynamicConfigService.getTableConfig(target, InvoiceAppData.tableConfig).subscribe(
        cf => {
          config = cf;
          if (target === DynamicDefinitionEnum.INVOICE_ACT_LIST_TABLE) {
            this.buildActsTableTable(config);
          } else if (target === DynamicDefinitionEnum.INVOICE_FIXED_COST_LIST_TABLE) {
            this.buildFixedCostsTableHeaders(config);
          } else if (target === DynamicDefinitionEnum.INVOICE_COUNTERPART_LIST_TABLE) {
            this.buildCounterpartsTableHeaders(config);
          }
        }
      );
    }
  }

  buildActsTableTable(config: TableConfig): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionScreeningIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionPreScreeningIdentifier')
      .filterType(FilterType.INPUT_TEXT)
      .filterable()
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitDate')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .withDateFormatter()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('actVisit')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('actCategoryName')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('act-category')
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('act')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.useService.canAccessField(HiddenFields.ACTE_PRICE))
      .field('unitPrice')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .displayContent(((data: InvoiceAct) => {
        return data.unitPrice.toFixed(2) + '€';
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('unitNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.useService.canAccessField(HiddenFields.ACTE_PRICE))
      .field('total')
      .filterable()
      .sortable()
      .displayContent(((data: InvoiceAct) => {
        return data.total.toFixed(2) + '€';
      }))
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('internalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('type')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('additional-cost-type-selected')
      .sortable()
      .displayContent((data: InvoiceAct) => {
        return this.translateService.getTranslationString(data.typeHhhId);
      })
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientInitials')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('site')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientNumber')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('freeSS')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(Data.yesOrNo)
      .isTranslated()
      .build());
    this.actsHeaders = config.buildTable(availableHeaders);
  }

  buildCounterpartsTableHeaders(config: TableConfig): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionScreeningIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionPreScreeningIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitDate')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .withDateFormatter()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('actVisit')
      .filterable()
      .filterType(this.dynamicConfigService.verifyClient(['col', 'vivalto']) ? FilterType.INPUT_TEXT : FilterType.IH_DROPDOWN)
      .sortable()
      .build());

    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('actCategoryName')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .sortable()
      .type('act-category')
      .linkClickEvent(((data: InvoiceAct) => {
      }))
      .build());

    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('act')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .linkClickEvent(((data: InvoiceAct) => {
      }))
      .build());

    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.useService.canAccessField(HiddenFields.ACTE_PRICE))
      .field('unitPrice')
      .filterable()
      .filter(FilterType.INPUT_TEXT)
      .sortable()
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('unitNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.useService.canAccessField(HiddenFields.COUNTER_PART_PRICE))
      .field('total')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .displayContent(((data: InvoiceAct) => {
        return data.total.toFixed(2) + '€';
      }))
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('internalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('type')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('additional-cost-type-selected')
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientInitials')
      .filterType(FilterType.INPUT_TEXT)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('site')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitName')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientNumber')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('freeSS')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(Data.yesOrNo)
      .isTranslated()
      .build());
    this.counterpartsHeaders = config.buildTable(availableHeaders);
  }

  buildFixedCostsTableHeaders(config: TableConfig): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionPreScreeningIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitDate')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitName')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('type')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .displayContent((data: InvoiceAct) => {
        return this.translateService.getTranslationString(data.typeHhhId);
      })
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('category')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('act-category')
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('description')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientInitials')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.useService.canAccessField(HiddenFields.COUNTER_PART_PRICE))
      .field('unitPrice')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .cssClass((() => {
        return 'right-align';
      }))
      .displayContent(((data: InvoiceAct) => {
        return data.unitPrice.toFixed(2) + '€';
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('unitNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.useService.canAccessField(HiddenFields.COUNTER_PART_PRICE))
      .field('total')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .displayContent(((data: InvoiceAct) => {
        return data.unitPrice.toFixed(2) + '€';
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('internalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    this.fixedCostsHeaders = config.buildTable(availableHeaders);
  }

  getAllVisitActs() {
    this.visitActService.getAllPendingActs(this.selectedTrial).subscribe(res => {
      this.invoiceActs = res;
      this.getAllFixedCosts();
    });

  }

  getAllFixedCosts() {
    this.visitActService.getAllPendingFixedCosts(this.selectedTrial).subscribe(res => {
      this.invoiceFixedCosts = res;
      this.getAllCounterParts();
    });
  }

  getAllCounterParts() {
    this.visitActService.getAllPendingCounterParts(this.selectedTrial).subscribe(res => {
      this.billableCounterParts = res;
    });
  }

  private setMenuItems() {
    this.invoiceMenu = [
      {
        label: 'MODULE_INVOICES_ACTS_FIELD_ACTS',
        value: 'ACT'
      },
      {
        label: 'MODULE_INVOICES_ACTS_FIELD_FIXED_COSTS',
        value: 'FIXED_COST'
      },
    ];
    if (this.counterPartEnabled === true) {
      this.invoiceMenu.push({
        label: 'MODULE_INVOICES_ACTS_FIELD_COUNTER_PARTS',
        value: 'COUNTER_PART'
      });
    }
  }


  changeActivatedMenu($event: MouseEvent, value: 'ACT' | 'FIXED_COST' | 'COUNTER_PART') {
    $event.preventDefault();
    this.activatedMenu = value;
  }

  displayProvider(invoiceAct: any) {
    this.dataService.setContactData(invoiceAct.promoter, 'Provider', '/assets/images/providers.png', invoiceAct.promoterHhhId, 0);
    this.router.navigate(['/contact/' + invoiceAct.promoterHhhId + '/Provider']).then();
  }

  displayTrialByLocalIdentifier(localIdentifier: string) {
    this.trialService.getTrialByLocalIdentifier(localIdentifier).subscribe((trialId: number) => {
      this.router.navigate(
        ['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId: trialId}}
      ).then();
    });
  }

  displayTrialByProtocolNumber(protocolNumber: string) {
    this.trialService.getTrialByProtocolNumber(protocolNumber).subscribe((trialId: number) => {
      this.router.navigate(
        ['/trial-details/trial-identification-information-container/trial-identification-information'],
        {queryParams: {hhhId: trialId}}
      ).then();
    });
  }

  displayInclusionByNumber(inclusionHhhId: number, trialHhhId: number) {
    this.router.navigate(['/trial-details/inclusion-details/inclusion-information'],
      {
        queryParams: {
          hhhId: trialHhhId,
          inclusionId: inclusionHhhId
        }
      }).then();
  }

  displayPatient(patientHhhId: number) {
    this.router.navigate(['/patient-details'],
      {queryParams: {hhhId: patientHhhId}}
    ).then();
  }

  displayInclusionCalendarByNumber(inclusionHhhId: number, trialHhhId: number) {
    this.router.navigate(['/trial-details/inclusion-details/calendar'],
      {
        queryParams: {
          hhhId: trialHhhId,
          inclusionId: inclusionHhhId
        }
      }).then();
  }

  displayFixedCostCategories() {
    this.router.navigate(['/additional-costs-container/fixed-cost-categories']).then();
  }

  displayFixedCost(localIdentifier
                     :
                     string
  ) {
    this.trialService.getTrialByLocalIdentifier(localIdentifier).subscribe((trialId: number) => {
      this.router.navigate(
        ['/trial-details/fixed-costs'],
        {queryParams: {hhhId: trialId}}
      ).then();
    });
  }

  private displayCounterPartCategories() {
    this.router.navigate(['/additional-costs-container/counter-part-categories']).then();
  }

  displayCounterPart(localIdentifier: string) {
    this.trialService.getTrialByLocalIdentifier(localIdentifier).subscribe((trialId: number) => {
      this.router.navigate(
        ['/trial-details/counter-parts'],
        {queryParams: {hhhId: trialId}}
      ).then();
    });
  }
}

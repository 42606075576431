import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActCategoryService} from '../services/act-category.service';
import {SharedService} from '../../shared/services/shared.service';
import {NgForm} from '@angular/forms';
import {FormComponent} from '../../shared/component/form/form.component';
import {CounterpartCategory} from '../models/counterpart-category';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {FormConfig} from '../../dynamic-config/entity/form-config';

@Component({
  selector: 'ih-act-category-form',
  templateUrl: './act-category-form.component.html',
  styleUrls: ['./act-category-form.component.css']
})
export class ActCategoryFormComponent extends FormComponent<CounterpartCategory> implements OnInit {

  target = DynamicDefinitionEnum.ADDITIONAL_COSTS_ACT_CATEGORY_ADD_FORM;
  config: FormConfig = new FormConfig();
  isAdditionalCostCodeNumeric = false;

  @ViewChild('actCategoryForm') actCategoryForm: NgForm;

  existingCategory: boolean;
  actCategory: CounterpartCategory = new CounterpartCategory();
  actCategoryList: CounterpartCategory[];

  constructor(
      private actCategoryService: ActCategoryService,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(ActCategoryFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.existingCategory = false;
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isAdditionalCostCodeNumeric = this.dynamicConfigService.getProperty(PropertyEnum.additionalCostCodeNumeric);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, AdditionalCostsAppData.formConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  onSubmit() {
    this.submitted = true;
    if (this.actCategoryForm.invalid) {
      return;
    }
    if (!this.actCategory.hhhId) {
      this.doesCategoryAlreadyExist();
      if (this.existingCategory) {
        return;
      }
      this.addActCategory();
    } else {
      this.updateActCategory();
    }
  }

  private updateActCategory() {
    this.actCategoryService.updateAct(this.actCategory).subscribe(() => {
      this.callback(this.actCategory);
      this.sharedService.showSuccess();
      this.sharedService.setInformationInLocalStorage('Grille budgétaire: Catégories des actes', 'Éditer', this.actCategory.nameFr);
      this.display = false;
    }, error => {
      this.sharedService.showFailure();
      console.error('An error occurred when updating an ActCategory.' + error);
    });
  }

  private addActCategory() {
    this.actCategoryService.addActCategory(this.actCategory).subscribe(res => {
      this.actCategory.hhhId = res;
      this.callback(this.actCategory);
      this.sharedService.showSuccess();
      this.sharedService.setInformationInLocalStorage('Grille budgétaire: Catégories des actes', 'Ajouter', this.actCategory.nameFr);
      this.display = false;
    }, error => {
      this.existingCategory = true;
      console.error('An error occurred when adding a new ActCategory.' + error);
    });
  }

  doesCategoryAlreadyExist() {
    const categoryFilter = (category: CounterpartCategory) => category.nameFr === this.actCategory.nameFr;
    this.existingCategory = this.actCategoryList.some(categoryFilter);
  }
}

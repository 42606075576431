import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {DetailedTrialAct} from '../../../entity/trial-additionnal-costs/detailed-trial-act';
import {SelectItem} from 'primeng/api';
import {Data} from '../../../../shared/entity/data';
import {Router} from '@angular/router';
import {TrialActService} from '../../../service/trial-act.service';
import {TheoreticalVisitService} from '../../../service/theoretical-visit.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {NgForm} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {TrialOperationalActService} from '../../../service/trial-operational-act.service';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../../../dynamic-config/entity/form-config';
import { ReferenceFixedCostService } from 'src/app/additional-costs/services/reference-fixed-cost.service';

@Component({
  selector: 'ih-operational-trial-act-form',
  templateUrl: './operational-trial-act-form.component.html',
  styleUrls: ['./operational-trial-act-form.component.css']
})
export class OperationalTrialActFormComponent extends FormComponent<DetailedTrialAct> implements OnInit {

  target = DynamicDefinitionEnum.TRIAL_OPERATIONAL_ACT_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('trialActForm') trialActForm: NgForm;

  trialHhhId: number;
  detailedTrialAct: DetailedTrialAct;
  noYesOptions: SelectItem[] = Data.yesOrNo;
  contractTypes: SelectItem[] = [];
  displayDelete = false;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private trialOperationalActService: TrialOperationalActService,
    private dynamicConfigService: DynamicConfigService,
    private fixedCostReferenceService: ReferenceFixedCostService
  ) {
    super();
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(OperationalTrialActFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.initProperties();
    this.getContractType();
    this.initTrialAct();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, TrialAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

  navigateToCategory() {
    this.router.navigate(
      ['/additional-costs-container/reference-acts'],
      {queryParams: {actCategory: this.detailedTrialAct.actCategory}}
    ).then();
  }

  getContractType() {
      this.fixedCostReferenceService.getContractTypeList().subscribe(res => {
        this.contractTypes = res;
      });
  }



  initTrialAct() {
    if (this.detailedTrialAct != null) {
      this.detailedTrialAct.contractType = !isNullOrUndefined(this.detailedTrialAct.contractType) ?
        (this.contractTypes[this.contractTypes.findIndex((contractType: SelectItem) => contractType.label === this.detailedTrialAct.contractType)].value) : null;
      // if (!isNullOrUndefined(this.detailedTrialAct.type)) {
      //   const type: SelectItem = this.referenceActTypes.find(t => t.label === this.detailedTrialAct.type);
      //   if (type) {
      //     this.detailedTrialAct.type = type.value;
      //   }
      // }
    }
  }

  editTrialAct() {
    this.submitted = true;
    this.updateDetailedTrialAct();
  }

  updateDetailedTrialAct() {
    if (!this.detailedTrialAct.isValid(this.config)) {
      return;
    }
    this.trialOperationalActService.updateOperationalTrialAct(this.detailedTrialAct).subscribe(res => {
      this.callback(res);
      this.sharedService.showSuccess();
    });
    this.display = false;
    this.displayDelete = false;
  }
}

import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';

export class ConcomitantTreatment {
  hhhId: number;
  publicCommentCount: number;
  inclusionHhhId: number;
  name: string;
  dosage: string;
  firstTakingDate: Date;
  lastTakingDate: Date;
  administered: boolean;
  visit: string;
  visitHhhId: number;

  init(obj: any): ConcomitantTreatment {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    const valid = true;
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return valid;
  }
}

import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'ih-amendment-identification-information',
  templateUrl: './amendment-identification-information.component.html',
  styleUrls: ['./amendment-identification-information.component.css']
})
export class AmendmentIdentificationInformationComponent implements OnInit {

  trialHhhId: number;
  items: MenuItem[];

  constructor(private router: Router) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    const queryParams = {
      hhhId: this.trialHhhId,
    };
    this.items = [];
    this.items.push({
      label: 'MODULE_TRIALS_AMENDMENT_PROTOCOLS',
      routerLink: ['/trial-details/trial-identification-information-container/amendment-identification-information/protocols'],
      queryParams
    });

    this.items.push({
      label: 'MODULE_TRIALS_AMENDMENT_CONSENTS',
      routerLink: ['/trial-details/trial-identification-information-container/amendment-identification-information/consents'],
      queryParams
    });
  }
}

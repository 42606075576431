import {Component, OnInit} from '@angular/core';
import {OtherAmendment} from '../../../models/other-amendment';
import {FR} from '../../../../shared/entity/calendar-language';
import {OtherAmendedService} from '../../../services/other-amended.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {SelectItem} from 'primeng/api';
import {TrialProtocolService} from '../../../services/trial-protocol.service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DataItemService} from '../../../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-other-amended-form',
  templateUrl: './other-amended-form.component.html',
  styleUrls: ['./other-amended-form.component.css']
})
export class OtherAmendedFormComponent implements OnInit {
  dropdownAmendmentListEnabled = false;
  textFieldAmendmentMotifEnabled = true;

  displayOtherAmendmentAgreement = true;
  otherAmendmentList: OtherAmendment[] = [];
  locale = FR;
  private trialId: number;
  trialSiteLiaisonId: number;
  callback: (callbackValue: {value: string, count: number}) => void;
  protocolsSelectItems: Array<SelectItem> = new Array<SelectItem>();
  unusedProtocolsCount = 0;


  constructor(
      private otherAmendedService: OtherAmendedService,
      private sharedService: SharedService,
      public dynamicConfigService: DynamicConfigService,
      private dataItemService: DataItemService
  ) {
  }

  ngOnInit() {
    this.initProperties();
    this.getOtherAmendments();
    this.addAmendment();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.dropdownAmendmentListEnabled = this.dynamicConfigService.getProperty(PropertyEnum.dropdownAmendmentListEnabled);
      this.textFieldAmendmentMotifEnabled = this.dynamicConfigService.getProperty(PropertyEnum.textFieldAmendmentMotifEnabled);
    });
  }

  setTrialId(trialId: number) {
    this.trialId = trialId;
    this.getProtocols();
  }

  saveOtherAmendmentAgreements() {
    this.otherAmendedService.saveModifications(this.otherAmendmentList, this.trialId ? this.trialId : 0, this.trialSiteLiaisonId ? this.trialSiteLiaisonId : 0).subscribe((res) => {
      this.displayOtherAmendmentAgreement = false;
      this.sharedService.showSuccess();
      this.callback({value: res.value, count: this.otherAmendmentList.length - 1});
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  addAmendment() {
    const otherAmendment = new OtherAmendment();
    otherAmendment.trialHhhId = this.trialId;
    otherAmendment.trialSiteLiaisonHhhId = this.trialSiteLiaisonId;
    this.otherAmendmentList.splice(0, 0, otherAmendment);
    this.refreshOtherAmendmentIndexes();
  }

  refreshOtherAmendmentIndexes() {
    for (let i = 0; i < this.otherAmendmentList.length; i++) {
      this.otherAmendmentList[i].index = i;
    }
  }

  deleteAmendment(index: number) {
    this.otherAmendmentList.splice(index, 1);
    this.refreshOtherAmendmentIndexes();
  }

  onAdd(callback: (callbackValue: {value: string, count: number}) => void) {
    this.callback = callback;
  }

  private getOtherAmendments() {
    this.otherAmendedService.loadList(this.trialId, this.trialSiteLiaisonId).subscribe(res => {
      this.otherAmendmentList = res;
      this.addAmendment();
      this.countUnusedProtocols();
    }, error => {
      console.error(error);
    });
  }

  getProtocols() {
    this.dataItemService.getItems('trial-protocol|' + this.trialId).subscribe(result => {
      this.protocolsSelectItems = [];
      result.forEach(p => this.protocolsSelectItems.push({
        label: p.label,
        value: p.value
      }));
      this.countUnusedProtocols();
    });
  }

  getElementProtocols(protocolId: number): Array<SelectItem> {
    const result: Array<SelectItem> = new Array<SelectItem>();
    for (const protocolsSelectItem of this.protocolsSelectItems) {
      if (protocolsSelectItem.value === protocolId) {
        result.push({
          label: protocolsSelectItem.label,
          value: protocolsSelectItem.value,
          disabled: false
        });
      } else {
        const existingAmendment = this.otherAmendmentList.find(am => am.protocolVersionHhhId === protocolsSelectItem.value);
        if (existingAmendment) {
          result.push({
            label: protocolsSelectItem.label,
            value: protocolsSelectItem.value,
            disabled: true
          });
        } else {
          result.push({
            label: protocolsSelectItem.label,
            value: protocolsSelectItem.value,
            disabled: false
          });
        }
      }
    }
    return result;
  }

  countUnusedProtocols(): void {
    this.unusedProtocolsCount = this.protocolsSelectItems.length - this.otherAmendmentList.filter(o => o.protocolVersionHhhId != null).length;
  }

}

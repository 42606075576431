import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CounterpartReference} from '../models/counterpart-reference';
import {IsDeletable} from '../../is-deletable';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})
export class CounterpartReferenceService {

  host = new Properties().host + '/counterpart-reference';
  private counterPartReferenceList: CounterpartReference[] = [];

  constructor(private httpClient: HttpClient) {
  }

  setCounterPartReferenceListToExport(counterPartReferenceList: CounterpartReference[]) {
    this.counterPartReferenceList = counterPartReferenceList;
  }

  loadList(): Observable<CounterpartReference[]> {
    return this.httpClient.get<CounterpartReference[]>(this.host + '/load-list');
  }

  loadAttachedList(): Observable<CounterpartReference[]> {
    return this.httpClient.get<CounterpartReference[]>(this.host + '/load-attached-list');
  }

  addReference(counterPartReference: CounterpartReference): Observable<CounterpartReference> {
    return this.httpClient.post<CounterpartReference>(this.host + '/add', counterPartReference);
  }

  updateReference(counterPartReference: CounterpartReference): Observable<string> {
    const requestOptions: object = {
      responseType: 'text'
    };
    return this.httpClient.put<string>(this.host + '/update', counterPartReference, requestOptions);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  delete(counterPartId: number) {
    return this.httpClient.get(this.host + `/delete/${counterPartId}`);
  }

  exportCounterPartReference(request: ExportRequest<any>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

}

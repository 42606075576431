import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {DataCommentCount} from '../../data-comment/entity/data-comment-count';

export class TrialIdentificationInformationGeneral extends DataCommentCount {

  public hhhId: number;
  public trialHhhId: number;
  public stateHhhId: number;
  public manualState: boolean;
  public establishmentHhhId: number;
  public localIdentifier: string;
  public protocolNumber: string;
  public title: string;
  public englishTitle: string;
  public phaseHhhId: number;
  public concurrentTrial: boolean;
  public concurrentTrialComment: string;
  public typeOfStudyLawHhhId: number;
  public numberClinicalTrialsGov: string;
  public researchObjectHhhIds: number[] = [];
  public inclusionObjective: any;
  public trialObjective: string;
  public numberCentersAllocatedByPromoter: string;
  public inclusionDuration: string;
  public pediatricTrial: boolean;
  public adultTrial: boolean;
  public firstInHuman: boolean;
  public escalationExtension: string;
  public establishmentRoleHhhId: number;
  public trialCommitteesHhhIds: number[] = [];
  public typeOfStudyLaw2HhhIds: number[] = [];
  public trialCommitteesBackupHhhIds: number[] = [];
  public poleHhhId: number;
  public newPoleHhhId: number;
  public establishmentDepartmentHhhId: number;
  public newEstablishmentDepartmentHhhId: number;
  public otherCodes: string;
  public numberOfCenterAllocatedByPromoter: string;
  public initials: string;
  public trlMaturityLevel: string;
  public researchTypeHhhId: number;
  public trialTypesMethodologyHhhIds: number[] = [];
  public promoterHhhId: number;
  public promoterTypeHhhId: number;
  public croPromoterHhhIds: number[] = [];
  public hasMolecularPreScreening: boolean;
  public dateOfProposalOfTheTrial: Date;
  public interventionResearch: boolean;
  public providerHhhId: number;
  public blinded: boolean;
  public multiSite: boolean;
  public multiCentricHhhId: number;
  public otherParticipatingCenterHhhIds: number[] = [];
  public nationalCoordinator: string;
  public recruitmentObjectives: string;
  public numberOfCentresHhhIds: number[] = [];
  public forecastInclusions: number;
  public trialTotalEffective: number;
  public investigatingCompanyHhhId: number;
  public organList: Array<string> = new Array<string>();
  public idRcb: string;
  public trialObjectiveComment: string;
  public abreviationSigapsSigrecHhhId: number;
  public designHhhId: number;
  public sigrecHhhId: number;
  public innovationBundle: boolean;
  public cancerResearchStudy: boolean;
  public researchTypeAndStudyLawOtherValue: string;

  init(obj: any): TrialIdentificationInformationGeneral {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else if (prop === 'dateOfProposalOfTheTrial') {
           this.dateOfProposalOfTheTrial = obj.dateOfProposalOfTheTrial != null ? new Date(obj.dateOfProposalOfTheTrial) : null;
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  isValid(config: FormConfig, phasesWithEscalation: number[]): boolean {
    const fieldValidations: Map<string, string> = new Map([
      ['state', 'stateHhhId'],
      ['promoterType', 'promoterTypeHhhId'],
      ['design', 'designHhhId'],
      ['sigrec', 'sigrecHhhId'],
      ['trialCommittees', 'trialCommitteesHhhIds'],
      ['pole', 'poleHhhId'],
      ['establishmentDepartment', 'establishmentDepartmentHhhId'],
      ['establishment', 'establishmentHhhId'],
      ['typeOfStudyLaw', 'typeOfStudyLawHhhId'],
      ['trialTypesMethodology', 'trialTypesMethodologyHhhIds'],
      ['promoter', 'promoterHhhId'],
      ['researchObject', 'researchObjectHhhIds'],
      ['croPromoter', 'croPromoterHhhIds'],
      ['provider', 'providerHhhId'],
      ['multiCentric', 'multiCentricHhhId'],
      ['numberOfCentres', 'numberOfCentresHhhIds'],
      ['otherParticipatingCenter', 'otherParticipatingCenterHhhIds'],
      ['phase', 'phaseHhhId'],
      ['numberCentersAllocatedByPromoter', 'numberCentersAllocatedByPromoter'],
      ['concurrentTrialComment', 'concurrentTrialComment'],
      ['researchType', 'researchTypeHhhId'],
      ['firstInHuman', 'firstInHuman'],
      ['escalationExtension', 'escalationExtension'],
      ['abreviationSigapsSigrec', 'abreviationSigapsSigrecHhhId'],
      ['englishTitle', 'englishTitle'],
      ['localIdentifier', 'localIdentifier'],
      ['protocolNumber', 'protocolNumber'],
      ['innovationBundle', 'innovationBundle'],
      ['cancerResearchStudy', 'cancerResearchStudy'],
      ['title', 'title']
    ]);
    for (const [key, field] of Array.from(fieldValidations.entries())) {
      const required = config.isFieldRequired(key);

      if (required && Util.isNullOrUndefinedOrEmpty(this[field])) {
        return false;
      }
    }

    if (config.isFieldRequired('typeOfStudyLaw') && Util.isNullOrUndefinedOrEmpty(this.typeOfStudyLawHhhId)) {
      return false;
    }

    if (config.isFieldRequired('researchType') && Util.isNullOrUndefinedOrEmpty(this.researchTypeHhhId)) {
        return false;
    }

    if (this.multiCentricHhhId === 1 && config.isFieldRequired('numberOfCentres') && Util.isNullOrUndefinedOrEmpty(this.numberOfCentresHhhIds)) {
      return false;
    }

    if (this.multiCentricHhhId !== 2 && config.isFieldRequired('otherParticipatingCenter') && Util.isNullOrUndefinedOrEmpty(this.otherParticipatingCenterHhhIds)) {
      return false;
    }

    if (config.isFieldRequired('numberCentersAllocatedByPromoter') && !this.multiSite && Util.isNullOrUndefinedOrEmpty(this.numberCentersAllocatedByPromoter)) {
      return false;
    }

    if (this.phaseHhhId && phasesWithEscalation.indexOf(this.phaseHhhId) >= 0) {
      if (config.isFieldRequired('firstInHuman') && Util.isNullOrUndefinedOrEmpty(this.firstInHuman)) {
        return false;
      }
      if (config.isFieldRequired('escalationExtension') && Util.isNullOrUndefinedOrEmpty(this.escalationExtension)) {
        return false;
      }
    }
    return true;
  }

}

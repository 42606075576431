import {Component} from '@angular/core';

@Component({
  selector: 'ih-additional-cost-icon',
  templateUrl: './additional-cost-icon.component.html',
  styleUrls: ['./additional-cost-icon.component.css']
})
export class AdditionalCostIconComponent {

  constructor() {}
}

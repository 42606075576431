import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ReferenceFixedCost} from '../models/referenceFixedCost';
import {SelectItem} from 'primeng/api';
import {ReferenceFixedCostService} from '../services/reference-fixed-cost.service';
import {SharedService} from '../../shared/services/shared.service';
import {FormComponent} from '../../shared/component/form/form.component';
import {Data} from '../../shared/entity/data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {AdditionalCostsAppData} from '../additional-costs-app-data';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-fixed-cost-reference-form',
  templateUrl: './fixed-cost-reference-form.component.html',
  styleUrls: ['./fixed-cost-reference-form.component.css']
})

export class FixedCostReferenceFormComponent extends FormComponent<ReferenceFixedCost> implements OnInit {

  target = DynamicDefinitionEnum.ADDITIONAL_COSTS_REFERENCE_FIXED_COST_ADD_FORM;
  config: FormConfig = new FormConfig();

  @ViewChild('fixedCostReferenceForm') fixedCostReferenceForm: NgForm;
  other: SelectItem = {value: 'OTHER', label: 'Autres'};
  noYesOptions: SelectItem[];
  fixedCostReference: ReferenceFixedCost = new ReferenceFixedCost();
  oldFixedCostReference: ReferenceFixedCost = new ReferenceFixedCost();
  fixedCostReferenceCategoryEmpty: boolean;
  yesOrNoOptions: SelectItem[] = Data.yesOrNo;
  isNumericSecondInternalAccountEnabled = false;
  isNumericFirstSegmentEnabled = false;

constructor(
    private fixedCostReferenceService: ReferenceFixedCostService,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService
  ) {
    super();
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(FixedCostReferenceFormComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.initProperties();
    this.display = true;
    this.submitted = false;
    this.fixedCostReferenceCategoryEmpty = false;
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isNumericSecondInternalAccountEnabled = this.dynamicConfigService.getProperty(PropertyEnum.NumericSecondInternalAccountEnabled);
      this.isNumericFirstSegmentEnabled = this.dynamicConfigService.getProperty(PropertyEnum.NumericFirstSegmentEnabled);
      this.initFormConfig();
    });
  }




  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, AdditionalCostsAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  submitFixedCostReference() {
    this.submitted = true;
    if (this.fixedCostReferenceForm.invalid || this.fixedCostReferenceCategoryEmpty || !this.fixedCostReference.isValid(this.config)) {
      return;
    }
    if (this.fixedCostReference.fixedCostCategoryHhhId) {
      if (!this.fixedCostReference.hhhId) {
        this.fixedCostReferenceService.addFixedCostReference(this.fixedCostReference).subscribe(fixedCostReference => {
          this.sharedService.showSuccess('UI_FORM_SUCCESS_SAVING_SUCCEEDED');
          this.sharedService.setInformationInLocalStorage('Grille budgétaire: Coûts / Surcoûts', 'Ajouter', this.fixedCostReference.description);
          this.callback(fixedCostReference);
          this.submitted = false;
        }, error => {
          this.sharedService.showFailure();
          this.submitted = false;
        });
      } else {
        this.fixedCostReferenceService.updateFixedCostReference(this.fixedCostReference).subscribe(res => {
          this.sharedService.showSuccess('UI_FORM_SUCCESS_SAVING_SUCCEEDED');
          this.sharedService.setInformationInLocalStorage('Grille budgétaire: Coûts / Surcoûts', 'Éditer', this.fixedCostReference.description);
          this.callback(res);
        }, error => {
          this.sharedService.showFailure();
          console.error(error);
        });
        this.submitted = false;
      }
    }
    this.display = false;
  }

  onAdd(callback: (fixedCostReference: ReferenceFixedCost) => void) {
    this.callback = callback;
  }


  onCancelClick() {
    this.display = false;
    this.initReferenceFixedCost(this.fixedCostReference, this.oldFixedCostReference);
  }

  initReferenceFixedCost(fixedCostReference: ReferenceFixedCost, oldFixedCostReference: ReferenceFixedCost) {
    fixedCostReference.contractType = oldFixedCostReference.contractType;
    fixedCostReference.contractTypeHhhId = oldFixedCostReference.contractTypeHhhId;
    fixedCostReference.typeHhhId = oldFixedCostReference.typeHhhId;
    fixedCostReference.description = oldFixedCostReference.description;
    fixedCostReference.invoiceable = oldFixedCostReference.invoiceable;
    fixedCostReference.attachedToVisit = oldFixedCostReference.attachedToVisit;
    fixedCostReference.annualInvoicing = oldFixedCostReference.annualInvoicing;
    fixedCostReference.fixedCostCategoryName = oldFixedCostReference.fixedCostCategoryName;
    fixedCostReference.fixedCostCategoryHhhId = oldFixedCostReference.fixedCostCategoryHhhId;
    fixedCostReference.pricePerUnit = oldFixedCostReference.pricePerUnit;
    fixedCostReference.internalAccountNumber = oldFixedCostReference.internalAccountNumber;
    fixedCostReference.secondInternalAccountNumber = oldFixedCostReference.secondInternalAccountNumber;
    fixedCostReference.firstSegment = oldFixedCostReference.firstSegment;
    fixedCostReference.secondSegment = oldFixedCostReference.secondSegment;
    fixedCostReference.checked = oldFixedCostReference.checked;
    fixedCostReference.publicCommentCount = oldFixedCostReference.publicCommentCount;
    fixedCostReference.freeSS = oldFixedCostReference.freeSS;
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);

}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PharmacyDoseService {

  public host = new Properties().host + '/pharmacy-dose';
  public readOnlyMode = false;


  constructor(private httpClient: HttpClient) {
  }

  add(dose: string) {
    return this.httpClient.post(this.host + '/add', dose);
  }

}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganService {
  public dataItemHost = new Properties().host + '/data/items';

  constructor(private httpClient: HttpClient) {
  }

  getOrgansAndOrganFamilies(): Observable<{ label, value }[]> {
    return this.httpClient.get<{ label, value }[]>(this.dataItemHost + '/organ-and-organ-family-selected');
  }

  getTrialOrgansAndOrganFamilies(trialHhhId: number): Observable<{ label, value }[]> {
    return this.httpClient.get<{ label, value }[]>(this.dataItemHost + '/trial-organ-and-organ-family|' + trialHhhId);
  }
}

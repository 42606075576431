import {CalendarCheckbox} from './calendar-checkbox';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';
import {DataCommentCount} from '../../data-comment/entity/data-comment-count';

export class TrialAdministrativeRegulatoryInformation extends DataCommentCount {
  hhhId: number;
  trialHhhId: number;
  numberCTTIRS: string;
  numberCNIL: string;
  numberHDH: string;
  cnilHhhId: number;
  numberClinicalTrialsGov: string;
  dgosNumber: string;
  financeHhhId: number[];
  medicoEconomic: boolean;
  numberEUDRACT: string;
  numberRecmed: string;
  numberRecdm: string;
  dateLastAmendmentStream: string;
  dateCPPAmendmentStream: string;
  csetAgreementDateStream: string;
  drcAgreementDateStream: string;
  numberANSM: string;
  ansmInformationDate: CalendarCheckbox;
  numberCPP: string;
  cppHhhId: string;
  comment: string;

  cppSendingDate: CalendarCheckbox;
  lastCPPAmendmentDate: CalendarCheckbox;
  csetAgreementDate: CalendarCheckbox;
  drcAgreementDate: CalendarCheckbox;
  substantialModification: boolean;
  numberMS: string;
  reasonMS: string;
  natureHhhId: number;

  phrcFinancement: boolean;
  ansmSoumissionDate: CalendarCheckbox;
  colChuCollaboration: boolean;
  colChuCollaborationComment: string;
  hasDASTRATreatmentForm: boolean;
  researchDuration: string;
  inclusionPeriod: string;
  inclusionFollowup: string;
  idRcb: string;
  opinionCERES: boolean;
  ctisAuthorization: boolean;
  opinionCPP: boolean;
  opinionANSM: boolean;

  cttirs: CalendarCheckbox;
  lastAuthorizationDate: CalendarCheckbox;
  initialEvaluationCppSessionDate: CalendarCheckbox;
  provisionalTrialStartingDate: CalendarCheckbox;
  firstInclusionDate: CalendarCheckbox;
  trialEnd: CalendarCheckbox;
  cnilCalendar: CalendarCheckbox;
  hdhCalendar: CalendarCheckbox;
  ansmAuthorization: CalendarCheckbox;
  lastANSMAmendment: CalendarCheckbox;
  cppSending: CalendarCheckbox;
  cppAuthorization: CalendarCheckbox;
  lastCPPAmendment: CalendarCheckbox;
  insuranceExpiry: CalendarCheckbox;
  cppFavorableOpinionMSDate: CalendarCheckbox;
  cppAuthorizationMSDate: CalendarCheckbox;
  ctisAuthorizationDate: CalendarCheckbox;
  conventionSignatureByCentre: CalendarCheckbox;
  abandonedTrialDate: CalendarCheckbox;
  ansmProvisionalSoumissionDate: CalendarCheckbox;
  ctisProvisionalSoumissionDate: CalendarCheckbox;
  ctisActualSoumissionDate: CalendarCheckbox;
  ctisProvisionalAgreementDate: CalendarCheckbox;
  ctisActualAgreementDate: CalendarCheckbox;
  nationalAgencyActualAgreementDate: CalendarCheckbox;
  committeeEthicsActualAgreementDate: CalendarCheckbox;
  ansmProvisionalAgreementDate: CalendarCheckbox;
  ansmActualAgreementDate: CalendarCheckbox;
  cppProvisionalSoumissionDate: CalendarCheckbox;
  cppActualSoumissionDate: CalendarCheckbox;
  cppProvisionalAgreementDate: CalendarCheckbox;
  cppActualAgreementDate: CalendarCheckbox;
  csetCompleteFolderReceptionDate: CalendarCheckbox;
  csetSoumissionDate: CalendarCheckbox;
  selectionCenterSelectedDate: CalendarCheckbox;
  promoterFeasibilityQuestionnaireDespatchDate: CalendarCheckbox;
  committeeReunionDecisionDate: CalendarCheckbox;

  init(obj: any): TrialAdministrativeRegulatoryInformation {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (prop === 'commentCount') {
          super.init(obj[prop]);
        } else {
          this[prop] = obj[prop];
        }
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'cttirsDate') {
        if (required && !this.cttirs) {
          return false;
        }
      } else if (key === 'cnilDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.cnilCalendar)) {
          return false;
        }
      } else if (key === 'cnil') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.cnilHhhId)) {
          return false;
        }
      } else if (key === 'hdhDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.hdhCalendar)) {
          return false;
        }
      } else if (key === 'finance') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.financeHhhId)) {
          return false;
        }
      } else if (key === 'RECMed') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.numberRecmed)) {
          return false;
        }
      } else if (key === 'RECDM') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.numberRecdm)) {
          return false;
        }
      } else if (key === 'ansmAuthorizationDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.ansmAuthorization)) {
          return false;
        }
      } else if (key === 'cpp') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.cppHhhId)) {
          return false;
        }
      } else if (key === 'numberCpp') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.numberCPP)) {
          return false;
        }
      } else if (key === 'cppSendingDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.cppSending)) {
          return false;
        }
      } else if (key === 'cppAuthorizationDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.cppAuthorization)) {
          return false;
        }
      } else if (key === 'nature') {
        if (this.colChuCollaboration && required && Util.isNullOrUndefinedOrEmpty(this.natureHhhId)) {
          return false;
        }
      } else if (key === 'insuranceExpiryDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.insuranceExpiry)) {
          return false;
        }
      } else if (key === 'trialEndDate') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.trialEnd)) {
          return false;
        }
      } else if (key === 'colChuCollaborationComment') {
        if (this.colChuCollaboration && required && Util.isNullOrUndefinedOrEmpty(this.colChuCollaborationComment)) {
          return false;
        }
      } else if (key === 'cppFavorableOpinionMSDate') {
        if (this.substantialModification && required && Util.isNullOrUndefinedOrEmpty(this.cppFavorableOpinionMSDate)) {
          return false;
        }
      } else if (key === 'reasonMS') {
        if (this.substantialModification && required && Util.isNullOrUndefinedOrEmpty(this.reasonMS)) {
          return false;
        }
      } else if (key === 'numberMS') {
        if (this.substantialModification && required && Util.isNullOrUndefinedOrEmpty(this.numberMS)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }

}

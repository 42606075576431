import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Provider} from '../models/provider';
import {Properties} from '../../helpers/properties';
import {IsDeletable} from '../../is-deletable';
import {ResponsePage} from '../../shared/entity/response-page';
import {ProviderBasicInformation} from '../models/provider-basic-information';
import {PromoterType} from '../models/promoter-type';
import {ProviderExtraInfos} from '../models/provider-extra-infos';
import {Value} from '../../shared/entity/value';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {ProviderGlobalFilter} from '../models/provider-global-filter';
import {catchError, map} from 'rxjs/operators';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';


@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  public host = new Properties().host + '/provider';
  public dataPage: ResponsePage<Provider>;

  public sortBy = 'hhhId';
  public orderBy = 'desc';

  constructor(private httpClient: HttpClient) {
  }

  setPaginationConfiguration(dataPage: ResponsePage<Provider>, sortBy: string, orderBy: string) {
    this.dataPage = dataPage;
    this.sortBy = sortBy;
    this.orderBy = orderBy;
  }

  loadList(page: number, limit: number, sortBy: string, orderBy: string, filters: ProviderGlobalFilter): Observable<ResponsePage<Provider>> {
    return this.httpClient.post<ResponsePage<Provider>>(
      this.host + '/load-list',
      new RequestPageBuilder<ProviderGlobalFilter>()
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(filters)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(p => new Provider().init(p));
          return result;
        } catch (e) {
          console.log(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  doesProviderExist(name: string): Observable<boolean> {
    return this.httpClient.post<boolean>(this.host + '/exist', new Value(name));
  }

  add(provider: ProviderBasicInformation): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', provider);
  }

  updateProvider(provider: ProviderBasicInformation): Observable<Provider> {
    return this.httpClient.put<Provider>(this.host + '/update', provider);
  }

  delete(hhhId: number) {
    return this.httpClient.get(this.host + '/delete/' + hhhId);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }

  getProviderTypes(): Observable<any []> {
    return this.httpClient.get<any []>(this.host + '/load-types')
      .pipe();
  }

  getPromoterTypeByProviderHhhId(providerHhhId: number): Observable<PromoterType> {
    return this.httpClient.get<PromoterType>(this.host + '/promoter-type-by-provider/' + providerHhhId);
  }


  getProviderExtraInfos(hhhId: number) {
    return this.httpClient.get<ProviderExtraInfos>(this.host + '/load-extra-infos/' + hhhId);
  }

  exportProviderList(request: ExportRequest<ProviderGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }

  importProviders(file: File): Observable<Array<string>> {
    const data: FormData = new FormData();
    data.append('file', file);
    return this.httpClient.post<any>(this.host + '/import', data);
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

}

import {VisitAdditionalCost} from './visit-additional-cost';
import {AdditionalCostType} from './additional-cost-type';
import {FormConfig} from '../../../dynamic-config/entity/form-config';
import {Util} from '../../../helpers/util';

export class DetailedTrialAct {
  hhhId: number;
  publicCommentCount: number;
  referenceActHhhId: number;
  contractType: string;
  contractTypeHhhId: number;
  typeHhhId: number;
  typeName: string;
  actCategory: string;
  actCategoryHhId: number;
  code: string;
  description: string;
  actReferenceDescription: string;
  onPrescription: boolean;
  externalFromCentre: boolean;
  invoiceable: boolean;
  optional: boolean;
  pricePerUnit: number;
  visitsHhhIds: number[];
  internalAccountNumber: string;
  secondInternalAccountNumber: string;
  firstSegment: string;
  secondSegment: string;
  temps: string;
  checked: boolean;
  selected: boolean;
  freeSS: boolean;
  additionalCostType: string;
  attachedToVisit: boolean;
  annualInvoicing: boolean;
  currencyCode: string;
  referenceFixedCostHhhId: number;
  isNew = false;
  editable = true;

  buidFromFixedCost(trialFixedCost: VisitAdditionalCost): DetailedTrialAct {
    this.hhhId = trialFixedCost.hhhId;
    this.referenceActHhhId = trialFixedCost.referenceActHhhId;
    this.contractType = trialFixedCost.contractType;
    this.typeHhhId = trialFixedCost.typeHhhId;
    this.typeName = trialFixedCost.type;
    this.actCategory = trialFixedCost.additionalCostCategoryName;
    this.actCategoryHhId = trialFixedCost.hhhId;
    this.description = trialFixedCost.additionalCostName;
    this.invoiceable = trialFixedCost.invoiceable;
    this.optional = trialFixedCost.optional;
    this.pricePerUnit = trialFixedCost.pricePerUnit;
    this.internalAccountNumber = trialFixedCost.internalAccountNumber;
    this.firstSegment = trialFixedCost.firstSegment;
    this.secondSegment = trialFixedCost.secondSegment;
    this.additionalCostType = AdditionalCostType.FIXED_COST;
    return this;
  }

  init(obj: any): DetailedTrialAct {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'contractType') {
        if (required && Util.isNullOrUndefined(this.contractTypeHhhId)) {
          return false;
        }
      } else if (key === 'type') {
        if (required && Util.isNullOrUndefined(this.typeHhhId)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

import {EventEmitter, Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {LabelValue} from '../../shared/entity/label-value';
import {Observable} from 'rxjs';
import {VisitStateColor} from '../entity/inclusion/visit-state-color';

@Injectable({
  providedIn: 'root'
})
export class VisitStatesService {
  public host = new Properties().host + '/visit/visit-states';
  public dataItemHost = new Properties().host + '/data/items';
  private statesObservable: EventEmitter<LabelValue[]>;
  private visitStates: LabelValue[];

  constructor(private httpClient: HttpClient) {
  }

  getVisitStates(consumer: (states: LabelValue[]) => void): void {
    if (this.visitStates) {
      consumer(this.visitStates);
    } else if (this.statesObservable) {
      this.statesObservable.subscribe(res => {
        consumer(res);
      });
    } else {
      this.statesObservable = new EventEmitter<LabelValue[]>();
      this.httpClient.get<Array<LabelValue>>(this.dataItemHost + '/visit-state').subscribe(res => {
        this.visitStates = res;
        this.statesObservable.emit(res);
        consumer(this.visitStates);
      });
    }
  }

  getVisitStateColor(): Observable<VisitStateColor[]> {
    return this.httpClient.get<VisitStateColor[]>(this.host + '/colors');
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {Data} from '../../../shared/entity/data';
import {SharedService} from '../../../shared/services/shared.service';
import {LabelValue} from '../../../shared/entity/label-value';
import {PromoterType} from '../../../provider/models/promoter-type';
import {DropdownField} from '../../../admin/models/dropdown-field';
import {DropdownFieldFormComponent} from '../../../modals/dropdown-field-form/dropdown-field-form.component';
import {Field} from '../../../admin/models/field';
import {VisitStateColor} from '../../../trial/entity/inclusion/visit-state-color';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {PropertyEnum} from '../../entity/property-enum';
import {Util} from '../../../helpers/util';
import {isUndefined} from 'util';
import {InnohealthTranslateService} from 'src/app/shared/services/innohealth-translate.service';
import {VisitStatesService} from '../../../trial/service/visit-states.service';
import {DataItemService} from '../../../custom-field/service/data-item.service';

@Component({
  selector: 'ih-data-management',
  templateUrl: './data-management.component.html',
  styleUrls: ['./data-management.component.css']
})
export class DataManagementComponent implements OnInit {

  administrationConsoleEnabled = false;

  @ViewChild('dropdownFieldFormDialog', {read: ViewContainerRef}) dialogContainer: ViewContainerRef;
  public dropdownList: Field[];
  public selectedDropdown: Field;
  public selectedElement: string;
  public sourceList: LabelValue[] = [];
  public targetList: LabelValue[] = [];
  public visitStates: VisitStateColor[];
  public selectedList: number[] = [];
  public display = false;
  public itemId: number;
  public listToUpdate: LabelValue[] = [];
  public item: LabelValue;
  public index: number;
  public showSubClassSection: boolean;
  public class: string;
  public authorized: boolean;
  private userRole: string;

  constructor(
    private translateService: InnohealthTranslateService,
    private sharedService: SharedService,
    private cfr: ComponentFactoryResolver,
    private dynamicConfigService: DynamicConfigService,
    private visitStateService: VisitStatesService,
    private dataItemService: DataItemService
  ) {
  }

  ngOnInit() {
    this.userRole = localStorage.getItem('authenticatedUserRole');
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.administrationConsoleEnabled = this.dynamicConfigService.getProperty(PropertyEnum.administrationConsoleEnabled);
      this.authorized = ((this.userRole === 'ADMIN' || this.userRole === 'EDITOR') && this.administrationConsoleEnabled);
      if (this.authorized) {
        this.getDropdownList();
        this.getVisitState();
      }
    });
  }

  getTranslatedFilterList(list: Field[]): Field[] {
    const listTranslated: Field[] = [];
    for (const element of list) {
      const translation = this.translateService.getTranslationString(
        !isUndefined(element.name) ? element.name : element.toString()
      );
      element.name = translation;
      listTranslated.push(element);
    }
    return listTranslated.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }

  getDropdownList() {
    this.dataItemService.getCustomFieldList().subscribe(res => {
      const sortedList = res.sort((a, b) => a.name.localeCompare(b.name));

      this.dropdownList = this.getTranslatedFilterList(sortedList);

      this.dropdownList.splice(0, 0, new Field());
    });
  }

  getList() {
    if (Util.isNullOrUndefined(this.selectedDropdown)) {
      return;
    }
    this.selectedElement = this.selectedDropdown.code;
    this.itemId = 0;
    this.sourceList = [];
    this.targetList = [];
    if (Util.isNullOrUndefined(this.selectedElement)) {
      return;
    }

    this.showSubClassSection = false;
    this.dataItemService.getItems(this.selectedElement).subscribe(res => {
      this.processElementList(res);
    });

  }

  refreshSelectedFields(event: any, listName: string) {
    if (listName === 'source') {
      this.listToUpdate = this.sourceList;
    } else {
      this.listToUpdate = this.targetList;
    }
    this.index = this.listToUpdate.indexOf(event.valueOf().items[0]);
    this.itemId = event.valueOf().items[0].value;
    if (String(event.valueOf().items[0].value).indexOf('OrganFamily') !== -1) {
      this.itemId = event.valueOf().items[0].value.slice(12, event.valueOf().items[0].value.length);
      this.class = 'organ-family';
    } else if (String(event.valueOf().items[0].value).indexOf('TherapeuticClass_') !== -1) {
      this.class = 'therapeutic-class';
      this.itemId = event.valueOf().items[0].value.slice(17, event.valueOf().items[0].value.length);
    } else {
      this.class = null;
    }
  }

  saveList() {
    this.selectedList = [];
    const selectedSubList = [];
    if (this.selectedElement === 'organ-and-organ-family') {
      for (const item of this.targetList) {
        if (item.value.indexOf('OrganFamily') !== -1) {
          selectedSubList.push(item.value.slice(12, item.value.length));
        } else {
          this.selectedList.push(item.value);
        }
      }
      this.dataItemService.updateSelectedList(this.selectedElement, this.selectedList, selectedSubList).subscribe(res => {
        this.sharedService.showSuccess();
      });

    } else if (this.selectedElement === 'therapeutic-class-for-drugs') {
      for (const item of this.targetList) {
        if (item.value.indexOf('TherapeuticClass_') !== -1) {
          selectedSubList.push(item.value.slice(17, item.value.length));
        } else {
          this.selectedList.push(item.value);
        }
      }
      this.dataItemService.updateSelectedList(this.selectedElement, this.selectedList, selectedSubList).subscribe(res => {
        this.sharedService.showSuccess();
      });

    } else {
      for (const item of this.targetList) {
        this.selectedList.push(item.value);
      }
      this.dataItemService.updateSelectedList(this.selectedElement, this.selectedList).subscribe(res => {
          this.sharedService.showSuccess();
          if (this.selectedElement === 'promoter-type') {
            for (const item of this.targetList) {
              const promoterTypeDto = new PromoterType(item.value, item.label);
              Data.promoterType.push(promoterTypeDto);
            }
          }
        }
      );
    }
  }

  displayAddDialog() {
    if (this.selectedElement) {
      const formComponent = DropdownFieldFormComponent.displayFormDialogue(this.dialogContainer, this.cfr);
      formComponent.newField = new DropdownField();
      formComponent.selectedElement = this.selectedElement;
      formComponent.showSubClassSection = this.showSubClassSection;
      formComponent.onAdd((newItem: DropdownField) => {
        for (let i = 0; i < newItem.dropdownFieldName.length; i++) {
          const item = new LabelValue();
          item.label = newItem.dropdownFieldName[i];
          item.value = newItem.dropdownType;
          item.selected = true;
          this.targetList.push(item);
        }
      });
    }
  }


  delete() {
    if (this.selectedElement) {
      let type = this.selectedElement;
      if (this.class === 'organ-family' || this.class === 'therapeutic-class') {
        type = this.class;
      }
      this.dataItemService.delete(type, this.itemId).subscribe(res => {
        if (res === true) {
          this.sharedService.showSuccess();
          this.listToUpdate.splice(this.index, 1);
        } else {
          this.sharedService.showFailure('GENERAL_LINGUISTICS_USED_FIELD');
        }
      }, error => {
        this.sharedService.showFailure('GENERAL_LINGUISTICS_USED_FIELD');
        console.error(error);
      });
    }
  }

  getVisitState() {
    this.visitStateService.getVisitStateColor().subscribe(res => {
      this.visitStates = res;
    }, error => {
      console.error(error);
    });
  }

  private processElementList(elementList: any[]) {
    for (const element of elementList) {
      if (element.selected) {
        this.targetList.push(element);
      } else {
        this.sourceList.push(element);
      }
    }
  }

}


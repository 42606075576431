import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {ResponsePage} from '../../shared/entity/response-page';
import {UserAttempt} from '../../user-attempts/user-attempt';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {UserAttemptGlobalFilter} from '../../user-attempts/user-attempt-global-filter';
import {Observable, throwError} from 'rxjs';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {catchError, map} from 'rxjs/operators';
import {ExportRequest} from '../../dynamic-config/entity/export-request';

@Injectable({
  providedIn: 'root'
})

export class UserAttemptsService {

  public host = new Properties().host + '/user/attempts';
  public dataPage: ResponsePage<UserAttempt>;

  constructor(private httpClient: HttpClient) {
  }

  loadList(page: number, limit: number, sortBy: string, orderBy: string, userAttemptGlobalFilter: UserAttemptGlobalFilter): Observable<ResponsePage<UserAttempt>> {
    if (sortBy === '') {
      sortBy = 'lastConnectionAttempt';
    }
    return this.httpClient.post<ResponsePage<UserAttempt>>(
      this.host + `/load-list`,
      new RequestPageBuilder<UserAttemptGlobalFilter>()
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(userAttemptGlobalFilter)
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(userAttempt => new UserAttempt().init(userAttempt));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  exportUserAttempts(request: ExportRequest<UserAttemptGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }


  setPaginationConfiguration(userAttempts: ResponsePage<UserAttempt>) {
    this.dataPage = userAttempts;
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

}

import {Component, OnInit} from '@angular/core';
import {AccessRight} from '../models/access-right';
import {UsersService} from '../services/users.service';
import {SharedService} from '../../shared/services/shared.service';
import {Router} from '@angular/router';
import {FieldAccessRight} from '../models/field-access-right';
import {GlobalAccessRight} from '../models/global-acces-right';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-access-rights-form',
  templateUrl: './access-rights-form.component.html',
  styleUrls: ['./access-rights-form.component.css']
})
export class AccessRightsFormComponent implements OnInit {

  isMobileApplicationEnabled = false;

  public accessRights: AccessRight[] = [];
  public fieldAccessRights: FieldAccessRight [] = [];
  public accessRightsUpdated: AccessRight[] = [];
  public globalAccessRight: GlobalAccessRight = new GlobalAccessRight();
  public id;

  constructor(
    private sharedService: SharedService,
    private userService: UsersService,
    private router: Router,
    private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.id = url.queryParams.id;
    }
    this.initProperties();
    this.getAccessRights();
    this.getFieldAccessRights();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isMobileApplicationEnabled = this.dynamicConfigService.getProperty(PropertyEnum.mobileApplicationEnabled);
    });
  }

  updateAccessRights() {
    for (const accessRight of this.accessRights) {
      accessRight.readOnly = false;
      accessRight.readWrite = false;
      accessRight.noRight = false;
      accessRight.export = false;
      accessRight.partiallyExport = false;
      accessRight.notExport = false;
      if (accessRight.accessType === 'READ_ONLY') {
        accessRight.readOnly = true; // todo review read only privilege.
      } else if (accessRight.accessType === 'WRITE') {
        accessRight.readWrite = true;
      } else {
        accessRight.noRight = true;
      }
      if (accessRight.exportType === 'TOTAL') {
        accessRight.export = true;
      } else if (accessRight.exportType === 'PARTIALLY_EXPORT') {
        accessRight.partiallyExport = true;
      } else {
        accessRight.notExport = true;
      }
      this.accessRightsUpdated.push(accessRight);
    }
    const fieldAccessRightList = Object.assign([], this.fieldAccessRights);
    for (const fieldAccessRight of fieldAccessRightList) {
      fieldAccessRight.canAccess = fieldAccessRight.canAccessString === 'OUI';
    }
    this.globalAccessRight.accessRightDtoList = this.accessRightsUpdated;
    this.globalAccessRight.fieldAccessRightDtoList = fieldAccessRightList;

    this.userService.changeAccessRights(this.globalAccessRight).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  getAccessRights() {
    this.userService.getModuleAccess(this.id).subscribe(res => {
      for (const accessRight of res) {
        if (accessRight.readOnly) {
          // TODO: read only should prevent users from editing data.
          accessRight.accessType = 'READ_ONLY';
        } else if (accessRight.readWrite) {
          accessRight.accessType = 'WRITE';
        } else {
          accessRight.accessType = 'MASKED';
        }
        if (accessRight.export) {
          accessRight.exportType = 'TOTAL';
        } else if (accessRight.partiallyExport) {
          accessRight.exportType = 'PARTIALLY_EXPORT';
        } else {
          accessRight.exportType = 'NOT_EXPORTABLE';
        }
        this.accessRights.push(accessRight);
      }
    });
  }

  getFieldAccessRights() {
    this.userService.getFieldAccess(this.id).subscribe(res => {
      for (const fieldAccessRight of res) {
        fieldAccessRight.canAccessString = fieldAccessRight.canAccess ? 'OUI' : 'NON';
      }
      this.fieldAccessRights = res;
    });
  }

}

import {Component, OnInit} from '@angular/core';
import {StatisticsService} from "../services/statistics.service";
import {SelectItem} from "primeng/api";
import {InnohealthTranslateService} from "../../shared/services/innohealth-translate.service";
import {Data} from "../../shared/entity/data";
import {IsoStats} from "../models/isoStats";

@Component({
  selector: 'app-follow-iso-stats',
  templateUrl: './follow-iso-stats.component.html',
  styleUrls: ['./follow-iso-stats.component.css']
})
export class FollowIsoStatsComponent implements OnInit {

  isoStats: IsoStats = new IsoStats();
  histogramType: any;
  options: SelectItem[] = [];
  years: SelectItem[] = Data.getYears();
  histogramData: any;
  barChartOptions: any;
  trimesters = ['Trimestre1', 'Trimestre2', 'Trimestre3', 'Trimestre4'];
  semesters = ['Semestre1', 'Semestre2'];
  labels: any;
  year: any = (new Date()).getFullYear();
  unity = '';
  phase = 1;

  constructor(private statisticsService: StatisticsService,
              private translateService: InnohealthTranslateService) {
  }

  ngOnInit() {
    this.initializeOptions();
    this.getIsoStatsData();
  }

  getHistogramToDisplay() {
    switch (this.histogramType) {
      case 'MODULE_FOLLOW_ISO_STATS_NUMBER_OF_FEASIBILITY_RECEIVED_PER_TRIMESTER' :
        this.getNumberOfReceivedFeasibilityHistogram('');
        break;
      case 'MODULE_FOLLOW_ISO_STATS_FIELD_NUMBER_OF_FEASIBILITY_RECEIVED_FOR_PHASE_I_TRIALS' :
        this.getNumberOfReceivedFeasibilityHistogram('PHASE_I');
        break;
      case 'MODULE_FOLLOW_ISO_STATS_FIELD_NUMBER_OF_FEASIBILITY_RECEIVED_FOR_PHASE_II_TRIALS' :
        this.getNumberOfReceivedFeasibilityHistogram('PHASE_II');
        break;
      case 'MODULE_FOLLOW_ISO_STATS_COMPLIANCE_RATE_FOR_INCLUSION_COMMITMENTS_WITH_PROMOTERS':
        this.getComplianceRateForInclusionCommitmentsHistogram();
        break;
      case 'MODULE_FOLLOW_ISO_STATS_AVERAGE_TIME_BETWEEN_MEP_DATE_AND_ACTIVATION_DATE':
        this.getAverageTimeBetweenMepDateAndActivationDateHistogram();
        break;
      case 'MODULE_FOLLOW_ISO_STATS_AVERAGE_TIME_BETWEEN_ACTIVATION_AND_FIRST_PATIENT':
        this.getAverageTimeBetweenActivationDateAndFirstPatientDateHistogram();
        break;
      case 'MODULE_FOLLOW_ISO_STATS_TRIAL_DESTRUCTION_RATE':
        this.getTrialDestructionRateHistogram();
        break;
      default:
        this.histogramType = 'MODULE_FOLLOW_ISO_STATS_NUMBER_OF_FEASIBILITY_RECEIVED_PER_TRIMESTER';
        this.getNumberOfReceivedFeasibilityHistogram('');
        break;
    }
  }


  private getIsoStatsData() {
    this.statisticsService.getIsoStatsData().subscribe(res => {
        this.isoStats = res;
        this.getHistogramToDisplay();
      }, error => {
        console.log(error);
      }
    );
  }

  private displayHistogram(labels, data) {
    this.barChartOptions = Data.barChartOptions;
    this.histogramData = {
      labels,
      datasets: [
        {
          backgroundColor: '#6495ED',
          borderColor: '#1E88E5',
          data
        }
      ]
    };
  }

  private initializeOptions() {
    this.options = [
      {
        value: 'MODULE_FOLLOW_ISO_STATS_NUMBER_OF_FEASIBILITY_RECEIVED_PER_TRIMESTER',
        label: this.translateService.getTranslationString('MODULE_FOLLOW_ISO_STATS_NUMBER_OF_FEASIBILITY_RECEIVED_PER_TRIMESTER')
      },
      {
        value: 'MODULE_FOLLOW_ISO_STATS_FIELD_NUMBER_OF_FEASIBILITY_RECEIVED_FOR_PHASE_I_TRIALS',
        label: this.translateService.getTranslationString('MODULE_FOLLOW_ISO_STATS_FIELD_NUMBER_OF_FEASIBILITY_RECEIVED_FOR_PHASE_I_TRIALS')
      },
      {
        value: 'MODULE_FOLLOW_ISO_STATS_FIELD_NUMBER_OF_FEASIBILITY_RECEIVED_FOR_PHASE_II_TRIALS',
        label: this.translateService.getTranslationString('MODULE_FOLLOW_ISO_STATS_FIELD_NUMBER_OF_FEASIBILITY_RECEIVED_FOR_PHASE_II_TRIALS')
      },
      {
        value: 'MODULE_FOLLOW_ISO_STATS_COMPLIANCE_RATE_FOR_INCLUSION_COMMITMENTS_WITH_PROMOTERS',
        label: this.translateService.getTranslationString('MODULE_FOLLOW_ISO_STATS_COMPLIANCE_RATE_FOR_INCLUSION_COMMITMENTS_WITH_PROMOTERS')
      },
      {
        value: 'MODULE_FOLLOW_ISO_STATS_AVERAGE_TIME_BETWEEN_MEP_DATE_AND_ACTIVATION_DATE',
        label: this.translateService.getTranslationString('MODULE_FOLLOW_ISO_STATS_AVERAGE_TIME_BETWEEN_MEP_DATE_AND_ACTIVATION_DATE')
      },
      {
        value: 'MODULE_FOLLOW_ISO_STATS_AVERAGE_TIME_BETWEEN_ACTIVATION_AND_FIRST_PATIENT',
        label: this.translateService.getTranslationString('MODULE_FOLLOW_ISO_STATS_AVERAGE_TIME_BETWEEN_ACTIVATION_AND_FIRST_PATIENT')
      },
      {
        value: 'MODULE_FOLLOW_ISO_STATS_TRIAL_DESTRUCTION_RATE',
        label: this.translateService.getTranslationString('MODULE_FOLLOW_ISO_STATS_TRIAL_DESTRUCTION_RATE')
      },
    ];
  }

  private getNumberOfReceivedFeasibilityHistogram(phase: string) {
    this.statisticsService.getNumberOfReceivedFeasibility(this.year, phase).subscribe(res => {
        this.displayHistogram(this.trimesters, res);
        this.unity = '';
      }, error => {
        console.log(error);
      }
    );
  }

  private getComplianceRateForInclusionCommitmentsHistogram() {
    this.statisticsService.getReportComplianceRateForInclusionCommitments(this.year).subscribe(res => {
        this.displayHistogram(this.semesters, res);
        this.unity = 'en %';
      }, error => {
        console.log(error);
      }
    );
  }

  private getAverageTimeBetweenMepDateAndActivationDateHistogram() {
    this.statisticsService.getAverageTimeBetweenMepDateAndActivationDate(this.year).subscribe(res => {
        this.displayHistogram(this.trimesters, res);
        this.unity = 'en jour';
      }, error => {
        console.log(error);
      }
    );
  }

  private getAverageTimeBetweenActivationDateAndFirstPatientDateHistogram() {
    this.statisticsService.getAverageTimeBetweenActivationDateAndFirstPatientDate(this.year).subscribe(res => {
        this.displayHistogram(this.trimesters, res);
        this.unity = 'en jour';
      }, error => {
        console.log(error);
      }
    );
  }

  private getTrialDestructionRateHistogram() {
    this.statisticsService.getTrialDestructionRate(this.year).subscribe(res => {
        this.displayHistogram(this.semesters, res);
        this.unity = 'en %';
      }, error => {
        console.log(error);
      }
    );
  }
}

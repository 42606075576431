export class User {
  public hhhId: number;
  public userName: string;
  public title: string;
  public lastName: string;
  public firstName: string;
  public duty: string;
  public roleHhhId: number;
  public humanResourceHhhId: number;
  public active: boolean;
  public newPassword1: string;
  public newPassword2: string;
  public establishmentHhhId: number;
  public role: string;
  public fieldsAccessed: string[];
  public establishment: string;
  public changedPassword: boolean;
  responsibilitiesHhhId: number[] = [];
  resetPasswordToken: string;
}

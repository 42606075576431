import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TrialListComponent} from './component/trial-list/trial-list.component';
import {TrialDetailsComponent} from './component/trial-details/trial-details.component';
import {TranslateModule} from '@ngx-translate/core';
import {TrialSiteListComponent} from './component/trial-site/trial-site-list/trial-site-list.component';
import {ContactListInformationComponent} from './component/trial-contact/contact-list-information/contact-list-information.component';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {InclusionListComponent} from './component/inclusions/inclusion-list/inclusion-list.component';
import {TrackingTableComponent} from './component/trial-track/tracking-table/tracking-table.component';
import {TheoreticalCalendarComponent} from './component/theoretical-calendar/theoretical-calendar/theoretical-calendar.component';
import {TrialActsGridComponent} from './component/trial-additionnal-costs/trial-acts-grid/trial-acts-grid.component';
import {TrialFixedCostsComponent} from './component/trial-additionnal-costs/trial-fixed-costs/trial-fixed-costs.component';
import {FileListComponent} from './component/file-list/file-list.component';
import {InclusionDetailsContainerComponent} from './component/inclusions/inclusion-details/inclusion-details-container/inclusion-details-container.component';
import {TrialIdentificationInformationContainerComponent} from './component/trial-identification/trial-identification-information-container/trial-identification-information-container.component';
import {TrialAdministrativeInformationContainerComponent} from './component/trial-administrative/trial-administrative-information-container/trial-administrative-information-container.component';
import {TrialSiteDetailsContainerComponent} from './component/trial-site/trial-site-details-container/trial-site-details-container.component';
import {AuthGuard} from '../helpers/auth-guard.service';
import {CanDeactivateGuard} from '../helpers/can-deactivate-guard';
import {TheoreticalCalendarContainerComponent} from './component/theoretical-calendar/theoretical-calendar-container/theoretical-calendar-container.component';
import {SyntheticTheoreticalCalendarComponent} from './component/theoretical-calendar/synthetic-theoretical-calendar/synthetic-theoretical-calendar.component';
import {TrialCounterpartComponent} from './component/trial-additionnal-costs/trial-counter-parts/trial-counterpart.component';
import {TheoreticalCalendarReferenceActsComponent} from './component/theoretical-calendar/theoretical-calendar-reference-acts/theoretical-calendar-reference-acts.component';
import {TheoreticalCalendarAdditionalCostComponent} from './component/theoretical-calendar/theoretical-calendar-additional-cost/theoretical-calendar-additional-cost.component';
import {TrialOperationalActsComponent} from './component/trial-additionnal-costs/trial-operational-acts/trial-operational-acts.component';
import {TrialPharmacyContainerComponent} from './component/trial-pharmacy/trial-pharmacy-container/trial-pharmacy-container.component';
import {TrialPharmacyInformationsComponent} from './component/trial-pharmacy/trial-pharmacy-informations/trial-pharmacy-informations.component';
import {TrialPharmacyFilesComponent} from './component/trial-pharmacy/trial-pharmacy-files/trial-pharmacy-files.component';
import {TrialPharmacyDispensationComponent} from './component/trial-pharmacy/trial-pharmacy-dispensation/trial-pharmacy-dispensation.component';
import {TrialPharmacyStockComponent} from './component/trial-pharmacy/trial-pharmacy-stock/trial-pharmacy-stock.component';
import {InclusionInformationArmComponent} from './component/inclusions/inclusion-information-arm/inclusion-information-arm.component';
import {TrialIdentificationInformationComponent} from './component/trial-identification/trial-identification-information/trial-identification-information.component';
import {TrialPersonnelInformationComponent} from './component/trial-identification/personnel/trial-personnel-information/trial-personnel-information.component';
import {TrialPersonnelProviderInformationComponent} from './component/trial-identification/personnel/trial-personnel-provider-information/trial-personnel-provider-information.component';
import {TrialTreatmentMethodologieComponent} from './component/trial-identification/trial-treatment-methodologie/trial-treatment-methodologie.component';
import {DisciplineIdentificationInformationComponent} from './component/trial-identification/discipline-identification-information/discipline-identification-information.component';
import {InclusionExclusionCriteriaComponent} from './component/trial-identification/inclusion-exclusion/inclusion-exclusion-criteria/inclusion-exclusion-criteria.component';
import {InclusionExclusionCriteriaDisciplineComponent} from './component/trial-identification/inclusion-exclusion/inclusion-exclusion-criteria-discipline/inclusion-exclusion-criteria-discipline.component';
import {ArmIdentificationInformationComponent} from './component/trial-identification/arm/arm-identification-information/arm-identification-information.component';
import {TrialAdministrativeRegulatoryInformationComponent} from './component/trial-administrative/trial-administrative-regulatory-information/trial-administrative-regulatory-information.component';
import {TrialAdministrativeDatesInformationComponent} from './component/trial-administrative/trial-administrative-dates-information/trial-administrative-dates-information.component';
import {TrialAdministrativeFeasibilityInformationComponent} from './component/trial-administrative/trial-administrative-feasibility-information/trial-administrative-feasibility-information.component';
import {TrialAdministrativeCecInformationComponent} from './component/trial-administrative/trial-administrative-cec-information/trial-administrative-cec-information.component';
import {InclusionInformationVersionProtocolComponent} from './component/inclusions/inclusion-information-version-protocol/inclusion-information-version-protocol.component';
import {CalendarInclusionComponent} from './component/inclusions/inclusion-details/calendar-details/calendar/calendar-inclusion.component';
import {FollowUpPatientContainerComponent} from './component/inclusions/patient-follow-up/follow-up-patient-container/follow-up-patient-container.component';
import {AmendmentIdentificationInformationComponent} from './component/trial-identification/version-protocol/amendment-identification-information/amendment-identification-information.component';
import {AmendmentConsentsComponent} from './component/trial-identification/version-protocol/amendment-consents/amendment-consents.component';
import {AmendmentProtocolsComponent} from './component/trial-identification/version-protocol/amendment-protocols/amendment-protocols.component';
import {DesignIdentificationInformationComponent} from './component/trial-identification/design/design-identification-information/design-identification-information.component';
import {
  TrialAdministrativeLogisticInformationComponent
} from './component/trial-administrative/trial-administrative-logistic-information/trial-administrative-logistic-information.component';
import {
  TrialAdministrativeFollowupInformationComponent
} from './component/trial-administrative/trial-administrative-follow-up-information/trial-administrative-follow-up-information.component';
import {InvoiceMonitorComponent} from './component/invoice-monitor/invoice-monitor/invoice-monitor.component';
import {
  TrialAdministrativeComopInformationComponent
} from './component/trial-administrative/trial-administrative-comop-information/trial-administrative-comop-information.component';
import {RequirePasswordUpdateGuard} from '../helpers/require-password-update-guard';

const routes: Routes = [
  {path: '', redirectTo: 'trial-list', pathMatch: 'full'},
  {path: 'trial-list', component: TrialListComponent, canActivate: [AuthGuard]},
  {
    path: 'trial-details', component: TrialDetailsComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard],
    children: [
      {path: '', redirectTo: 'trial-identification-information-container', pathMatch: 'full'},
      {
        path: 'trial-identification-information-container',
        component: TrialIdentificationInformationContainerComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
          {

            path: 'trial-identification-information',
            component: TrialIdentificationInformationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'personnel-identification-information',
            component: TrialPersonnelInformationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'personnel-provider-identification-information',
            component: TrialPersonnelProviderInformationComponent,
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'methodology-identification-information',
            component: TrialTreatmentMethodologieComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'discipline-identification-information',
            component: DisciplineIdentificationInformationComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'inclusion-exclusion-criteria',
            component: InclusionExclusionCriteriaComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'inclusion-exclusion-criteria-discipline',
            component: InclusionExclusionCriteriaDisciplineComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'arm-identification-information',
            component: ArmIdentificationInformationComponent,
            canActivate: [AuthGuard]
          },
          {
            path: 'design-identification-information',
            component: DesignIdentificationInformationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: 'amendment-identification-information',
            component: AmendmentIdentificationInformationComponent,
            canActivate: [AuthGuard],
            children: [
              {path: '', redirectTo: 'protocols', pathMatch: 'full'},
              {
                path: 'consents',
                component: AmendmentConsentsComponent,
                canActivate: [AuthGuard]
              },
              {
                path: 'protocols',
                component: AmendmentProtocolsComponent,
                canActivate: [AuthGuard]
              }
            ]
          }
        ]
      },
      {
        path: 'trial-administrative-information-container',
        component: TrialAdministrativeInformationContainerComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
          {
            path: 'trial-administrative-regulatory-information',
            component: TrialAdministrativeRegulatoryInformationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'trial-administrative-dates-information',
            component: TrialAdministrativeDatesInformationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'trial-administrative-feasibility-information',
            component: TrialAdministrativeFeasibilityInformationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'trial-administrative-cec-information',
            component: TrialAdministrativeCecInformationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'trial-administrative-logistic-information',
            component: TrialAdministrativeLogisticInformationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'trial-administrative-follow-up-information',
            component: TrialAdministrativeFollowupInformationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {
            path: 'trial-administrative-comop-information',
            component: TrialAdministrativeComopInformationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          }
        ]
      },
      {path: 'trial-site-list', component: TrialSiteListComponent},
      {
        path: 'trial-site-details-container',
        component: TrialSiteDetailsContainerComponent
      },
      {path: 'contact-list-information', component: ContactListInformationComponent, canActivate: [AuthGuard]},
      {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
      {
        path: 'inclusions-list', component: InclusionListComponent, canActivate: [AuthGuard]
      },
      {path: 'invoice-monitor', component: InvoiceMonitorComponent, canActivate: [AuthGuard]},
      {
        path: 'inclusion-details', component: InclusionDetailsContainerComponent, canActivate: [AuthGuard], children: [
          {path: 'inclusion-information-arm', component: InclusionInformationArmComponent},
          {path: 'calendar', component: CalendarInclusionComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard]},
          {path: 'inclusion-information-version-protocol', component: InclusionInformationVersionProtocolComponent, canActivate: [AuthGuard]},
          {path: 'patient-follow-up-container', component: FollowUpPatientContainerComponent, canActivate: [AuthGuard]}
        ]
      },
      {path: 'tracking-table', component: TrackingTableComponent, canActivate: [AuthGuard]},
      {
        path: 'theoretical-calendar',
        component: TheoreticalCalendarContainerComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
          {path: '', redirectTo: 'detailed-theoretical-calendar', pathMatch: 'full'},
          {
            path: 'synthetic-theoretical-calendar',
            component: SyntheticTheoreticalCalendarComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard],
            children: [{path: 'synthetic-reference-acts', component: TheoreticalCalendarReferenceActsComponent},
              {path: 'synthetic-reference-fixed-cost-acts', component: TheoreticalCalendarReferenceActsComponent},
              {path: 'synthetic-reference-counter-part-acts', component: TheoreticalCalendarReferenceActsComponent},
              {path: 'synthetic-trial-acts', component: TheoreticalCalendarAdditionalCostComponent},
              {path: 'synthetic--trial-fixed-cost-acts', component: TheoreticalCalendarAdditionalCostComponent},
              {path: 'synthetic-trial-counter-part-acts', component: TheoreticalCalendarAdditionalCostComponent}]
          },
          {
            path: 'detailed-theoretical-calendar',
            component: TheoreticalCalendarComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          }
        ]
      },
      {path: 'acts-grid', component: TrialActsGridComponent, canActivate: [AuthGuard]},
      {path: 'fixed-costs', component: TrialFixedCostsComponent, canActivate: [AuthGuard]},
      {path: 'counter-parts', component: TrialCounterpartComponent, canActivate: [AuthGuard]},
      {path: 'operational-acts', component: TrialOperationalActsComponent, canActivate: [AuthGuard]},
      {path: 'files', component: FileListComponent, canActivate: [AuthGuard]},
      {
        path: 'pharmacy',
        component: TrialPharmacyContainerComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
          {path: '', redirectTo: 'informations', pathMatch: 'full'},
          {path: 'informations', component: TrialPharmacyInformationsComponent, canActivate: [AuthGuard]},
          {path: 'stock', component: TrialPharmacyStockComponent, canActivate: [AuthGuard]},
          {
            path: 'dispensation',
            component: TrialPharmacyDispensationComponent,
            canActivate: [AuthGuard],
            canDeactivate: [CanDeactivateGuard]
          },
          {path: 'files', component: TrialPharmacyFilesComponent, canActivate: [AuthGuard]},
        ]
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),
    TranslateModule],
  exports: [RouterModule]
})

export class TrialRoutingModule {
}

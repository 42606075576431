import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectItem} from 'primeng/components/common/api';
import {SharedService} from '../../services/shared.service';
import {InnohealthTranslateService} from '../../services/innohealth-translate.service';
import {isNullOrUndefined, isUndefined} from 'util';
import {DataItemService} from '../../../custom-field/service/data-item.service';

export const COMBOBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownComponent),
  multi: true
};

@Component({
  selector: 'ih-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css'],
  providers: [COMBOBOX_VALUE_ACCESSOR]
})
export class DropdownComponent<T> implements OnInit, ControlValueAccessor {

  @Input() items: SelectItem[] = [];
  @Input() disabled: boolean;
  @Input() editable = false;
  @Input() showClear = true;
  @Input() optionLabel: string;
  @Input() name: string;
  @Input() placeHolderValue: string;
  @Input() disableWhenSelected: boolean;
  @Input() selectedValues: T[];
  @Input() otherValue: boolean;
  @Input() addToDropDown: boolean;
  @Input() buttonText: boolean;
  @Input() noCache = true;
  @Input() appendTo: any;
  @Input() class: string;
  @Input() optionFilterable = true;
  @Input() autoDisplayFirst = false;
  @Input() sortAlphabetically = true;

  @Output() valueChange: EventEmitter<T> = new EventEmitter();
  @Output() valueInput: EventEmitter<T> = new EventEmitter();
  @Output() onShow: EventEmitter<T> = new EventEmitter();
  @Output() onHide: EventEmitter<T> = new EventEmitter();
  @Output() onFocus: EventEmitter<void> = new EventEmitter();
  @Output() onBlur: EventEmitter<void> = new EventEmitter();
  @Output() itemValueChange: EventEmitter<any> = new EventEmitter();
  @Output() notifyWhenOther: EventEmitter<boolean> = new EventEmitter();
  @Output() displaySelectedItems: EventEmitter<T[]> = new EventEmitter();
  @Output() onAddToDropDown: EventEmitter<any> = new EventEmitter();
  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  initialValue: T;
  overlayVisible = false;
  lastDisabledItemValue: T;

  constructor(
      private sharedService: SharedService,
      private translateService: InnohealthTranslateService,
      private dataItemService: DataItemService
  ) {
  }

  private _value: T;

  get value(): T {
    if (!isNullOrUndefined(this._value)) {
      if (this.otherValue) {
        const translateService = this.translateService;
        const otherItem: SelectItem = this.items.find(item => item.label.toLowerCase() === translateService.getTranslationString('MODULE_CONTACTS_MGT_SECTION_OTHER').toLowerCase());
        this.notifyWhenOther.emit(otherItem && otherItem.value === this._value);
      }
      if (this.disableWhenSelected) {
        let exists = null;
        if (!isUndefined(this.selectedValues)) {
          exists = this.selectedValues.find(item => item === this._value);
        }
        if (isNullOrUndefined(exists)) {
          this.lastDisabledItemValue = this._value;
          this.selectedValues.push(this._value);
          this.displaySelectedItems.emit(this.selectedValues);
        }
      }
    }
    return this._value;
  }

  set value(v: T) {
    if (v !== this._value) {
      if (this.otherValue) {
        const translateService = this.translateService;
        const otherItem: SelectItem = this.items.find(item => item.label.toLowerCase() === translateService.getTranslationString('MODULE_CONTACTS_MGT_SECTION_OTHER').toLowerCase());
        this.notifyWhenOther.emit(otherItem && otherItem.value === v);
      }
      this._value = v;
      this.onChange(v);
      if (this.disableWhenSelected) {
        if (!isNullOrUndefined(this.lastDisabledItemValue)) {
          const index = this.selectedValues.findIndex(item => item === this.lastDisabledItemValue);
          this.selectedValues.splice(index, 1);
        }
        this.lastDisabledItemValue = this._value;
        this.selectedValues.push(this._value);
        this.displaySelectedItems.emit(this.selectedValues);
      }
      this.valueChange.emit(this._value);
      if (!this.overlayVisible) {
        this.valueInput.emit(this._value);
      }
    }
  }

  private _type: string;

  get type(): string {
    return this._type;
  }

  @Input()
  set type(v: string) {
    if (v !== this._type) {
      this._type = v;
      this.loadItems(v);
    }
  }

  onChange = (_) => {};

  onTouched = () => {};

  ngOnInit() {
  }

  writeValue(v: T) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(this._value);
    }
  }

  loadItems(v: string, refreshCash?: boolean): void {
    this.dataItemService.getItems(v, this.sortAlphabetically).subscribe(res => {
      this.items = res;
    });
  }

  onFocusDo(): void {
    this.onFocus.emit();
  }

  onBlurDo(): void {
    this.onBlur.emit();
  }

  onShowDo() {
    this.onShow.emit();
    if (this.disableWhenSelected && !isNullOrUndefined(this.items) && !isNullOrUndefined(this.selectedValues)) {
      for (const item of this.items) {
        if (item.disabled) {
          item.disabled = false;
        }
      }
      for (const selectedValue of this.selectedValues) {
        if (selectedValue !== this._value) {
          const index = this.items.findIndex(item => item.value === selectedValue);
          if (index > -1) {
            this.items[index].disabled = true;
          }
        }
      }
    }
    this.initialValue = this._value;
    this.overlayVisible = true;
  }

  onHideDo() {
    this.onHide.emit();
    this.overlayVisible = false;
    if (this.value !== this.initialValue) {
      this.valueInput.emit(this._value);
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  addToDropDownEvent() {
    this.onAddToDropDown.emit();
  }

  onRefreshDrop(refreshCash?: boolean) {
    this.loadItems(this._type, !isUndefined(refreshCash) ? refreshCash : true);
  }

  selectedItemChange(event?) {
    if (!isNullOrUndefined(event)) {
      this.itemValueChange.emit(this.items[this.items.findIndex((item) => item.value === event.value)]);
    } else {
      this.itemValueChange.emit(true);
    }
  }

  modelChanged() {
    this.ngModelChange.emit(this._value);
  }
}

import {Util} from '../../helpers/util';
import {FormConfig} from '../../dynamic-config/entity/form-config';

export class HumanResource {
  hhhId: any;
  publicCommentCount: number;
  establishment: any;
  title: any;
  lastName: any;
  firstName: any;
  role: any;
  servicesHhhId: any;
  site: any;
  specialityHhhId: any;
  responsibilitiesHhhId: number[];
  concatenatedResponsabilities: string;
  specialitiesHhhId: number[];
  specialityOtherValue: any;
  rpps: number;
  registrationNumber: number;
  departmentsHhhId: number[];
  committeesHhhId: number[];
  acronymDepartmentHhhId: number;
  acronymSimbad: number;
  entryDate: Date;
  releaseDate: Date;
  cnom: string;

  init(obj: any): HumanResource {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig, isDoctorSelected: boolean): boolean {
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'acronymDepartment') {
        if (required && !this.acronymDepartmentHhhId) {
          return false;
        }
      } else if (key === 'committee') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.committeesHhhId)) {
          return false;
        }
      }  else if (key === 'cnom') {
        if (isDoctorSelected && required && Util.isNullOrUndefinedOrEmpty(this.cnom)) {
          return false;
        }
      } else if (key === 'department') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.departmentsHhhId)) {
          return false;
        }
      } else if (key === 'speciality') {
        if (isDoctorSelected && required && Util.isNullOrUndefinedOrEmpty(this.specialitiesHhhId)) {
          return false;
        }
      } else if (key === 'responsibility') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.responsibilitiesHhhId)) {
          return false;
        }
      } else if (key === 'site') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.site)) {
          return false;
        }
      } else if (key === 'service') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.servicesHhhId)) {
          return false;
        }
      } else if (key === 'rpps') {
        if (isDoctorSelected && required && Util.isNullOrUndefinedOrEmpty(this.rpps)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return true;
  }
}

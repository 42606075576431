import {Component, Input, OnInit} from '@angular/core';
import {InputType} from '../../input-type';
import {AdditionalCostState} from '../../additional-cost-state.enum';
import {Router} from '@angular/router';
import {StaticInvoiceItem} from '../models/static-invoice-item';
import {SharedService} from '../../shared/services/shared.service';
import {InvoiceAct} from '../invoice-acts/models/invoice-act';
import {HiddenFields} from '../../admin/models/HiddenFields';
import {UsersService} from '../../admin/services/users.service';
import {isNullOrUndefined} from 'util';
import {FR} from '../../shared/entity/calendar-language';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {InvoiceAppData} from '../Invoice-app-data';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {Data} from '../../shared/entity/data';
import {StaticInvoiceActService} from '../invoice-acts/services/static-invoice-act.service';
import {StaticInvoiceService} from '../services/static-invoice.service';
import {InvoiceFixedCost} from '../models/invoice-fixed-cost';

@Component({
  selector: 'ih-static-invoice-act-list',
  templateUrl: './static-invoice-act-list.component.html',
  styleUrls: ['./static-invoice-act-list.component.css']
})
export class StaticInvoiceActListComponent implements OnInit {

  target = DynamicDefinitionEnum.INVOICE_STATIC_ACT_LIST_TABLE;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  staticInvoiceActs = new StaticInvoiceItem();
  originalBeforeUpdateStaticInvoiceActs = new StaticInvoiceItem();
  invoiceHhhId: number;
  totalInvoiceActList = 0;
  oldActTotal = 0;
  isDisplaySaveButton = false;
  fromDate: Date;
  toDate: Date;
  locale = FR;

  @Input() actState: string;

  constructor(
    private staticInvoiceService: StaticInvoiceService,
    private useService: UsersService,
    private router: Router,
    private sharedService: SharedService,
    private dynamicConfigService: DynamicConfigService,
    private staticInvoiceActService: StaticInvoiceActService
  ) {
    const url = this.router.parseUrl(this.router.url);
    this.invoiceHhhId = url.queryParams.invoiceHhhId;
  }

  get inputType() {
    return InputType;
  }

  ngOnInit() {
    this.initProperties();
    this.staticInvoiceActs.invoiceActs = [];
    this.getInvoiceActs(-1);
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.config = InvoiceAppData.tableConfig.get(this.target);
    if (this.config) {
      this.buildTableHeaders();
    } else {
      this.dynamicConfigService.getTableConfig(this.target, InvoiceAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
      );
    }
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionPreScreeningIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField(this.dynamicConfigService.verifyClient(['col', 'ccgm']) ? 'visit.calendar.inclusion.inclusionPreScreeningIdentifier' : 'inclusion.inclusionPreScreeningIdentifier')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionScreeningIdentifier')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('visitDate')
      .filterable((this.actState === AdditionalCostState.Pending || this.actState === AdditionalCostState.Invoiced))
      .filterType(FilterType.INPUT_TEXT)
      .sortable(this.actState === AdditionalCostState.Pending)
      .sortField('visit.visitDate')
      .withDateFormatter()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('actVisit')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField(this.dynamicConfigService.verifyClient(['col', 'ccgm']) ? 'visit.name' : 'theoreticalVisit.name')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('actCategory')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .type('act-category')
      .sortable(this.actState === AdditionalCostState.Pending)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('act')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.useService.canAccessField(HiddenFields.ACTE_PRICE))
      .field('unitPrice')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filterMatchType('equals')
      .sortable()
      .sortField('trialAct.description')
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .displayContent(((data: InvoiceAct) => {
        return Number(data.unitPrice).toFixed(2) + '€';
      }))
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('unitNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filterMatchType('equals')
      .sortable()
      .sortField('numberOfUnits')
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .accessible(this.useService.canAccessField(HiddenFields.ACTE_PRICE))
      .field('total')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filterMatchType('equals')
      .sortable()
      .sortField('priceTotal')
      .displayContent(((data: InvoiceAct) => {
        return data.total.toFixed(2) + '€';
      }))
      .cssClass((() => {
        return 'right-align';
      }))
      .withDisplayCssClassContent()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('internalAccountNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('trialAct.internalAccountNumber')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('firstSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('trialAct.firstSegment')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('secondSegment')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('trialAct.firstSegment')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('inclusionNumber')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable(this.actState === AdditionalCostState.Pending)
      .sortField('inclusion.inclusionIdentifier1')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('type')
      .filterable()
      .filterType(FilterType.IH_DROPDOWN)
      .optionFilterable()
      .filter('typeName')
      .type('additional-cost-type-selected')
      .sortable()
      .displayContent((data: InvoiceAct) => data.typeName)
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientInitials')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('site')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .sortable()
      .sortField('site.name')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('patientNumber')
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('freeSS')
      .filterable()
      .filterType(FilterType.DROPDOWN)
      .options(Data.yesOrNo)
      .isTranslated()
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }


  getInvoiceActs(versionHhhId: number) {
    this.staticInvoiceActService.getStaticInvoiceInvoicedActs(this.invoiceHhhId, versionHhhId, this.fromDate, this.toDate).subscribe(res => {
      this.staticInvoiceActs = res;
      this.staticInvoiceService.updateStaticCurrentVersionHhhId(res.currentVersionHhhId);
      this.originalBeforeUpdateStaticInvoiceActs = JSON.parse(JSON.stringify(res));
      this.displayTotalInvoiceActs();
      this.oldActTotal = this.calculateOldActTotal();
    });
  }

  displayTotalInvoiceActs() {
    this.totalInvoiceActList = 0;
    for (const invoiceAct of this.staticInvoiceActs.invoiceActs) {
      this.totalInvoiceActList += invoiceAct.total;
    }
    this.staticInvoiceService.updateStaticActsTotal(this.totalInvoiceActList);
  }

  onEditComplete() {
    this.isDisplaySaveButton = true;
  }

  resetTable() {
    this.staticInvoiceActs = JSON.parse(JSON.stringify(this.originalBeforeUpdateStaticInvoiceActs));
    this.isDisplaySaveButton = false;
  }

  saveTable() {
    this.staticInvoiceActService.saveStaticInvoiceInvoicedActs(this.invoiceHhhId, this.staticInvoiceActs).subscribe(hhhId => {
      this.sharedService.showSuccess();
      this.getInvoiceActs(-1);
      this.isDisplaySaveButton = false;
    }, error => {
      this.sharedService.showFailure();
    });
  }

  tableUpdated() {
    this.isDisplaySaveButton = true;
  }

  isHasNext() {
    if (this.staticInvoiceActs.nbrOfHistoryVersions < 2) {
      return false;
    }
    return (this.staticInvoiceActs.versionHhhIds[this.currentVersionIndex() + 1]) !== undefined;
  }

  isHasPrevious(): boolean {
    if (this.staticInvoiceActs.nbrOfHistoryVersions < 2) {
      return false;
    }
    return (this.staticInvoiceActs.versionHhhIds[this.currentVersionIndex() - 1]) !== undefined;
  }

  currentVersionIndex() {
    return this.staticInvoiceActs.versionHhhIds.indexOf(this.staticInvoiceActs.currentVersionHhhId);
  }

  updateTotal(filteredActs: InvoiceAct[]) {
    this.totalInvoiceActList = 0;
    for (const invoiceAct of filteredActs) {
      this.totalInvoiceActList += invoiceAct.total;
    }
    this.staticInvoiceService.updateStaticFixedCostsTotal(this.totalInvoiceActList);
  }


  getPrevious() {
    if (this.isHasPrevious()) {
      this.getInvoiceActs(this.staticInvoiceActs.versionHhhIds[this.currentVersionIndex() - 1]);
    }
  }

  getNext() {
    if (this.isHasNext()) {
      this.getInvoiceActs(this.staticInvoiceActs.versionHhhIds[this.currentVersionIndex() + 1]);
    }
  }

  calculateOldActTotal(): number {
    let sum = 0;
    if (!isNullOrUndefined(this.staticInvoiceActs.invoiceFixedCosts)) {
      this.staticInvoiceActs.invoiceFixedCosts.map(fixeddAct => sum += fixeddAct.total);
    }
    return sum;
  }

}

export enum ExportTypesEnum {
  FULL_EXPORT = 0,
  SINGLE_PAGE_EXPORT = 1,
  CUSTOM_EXPORT = 2,
  SIGREC_EXPORT = 3,
  PIRAMIG_EXPORT = 4,
  GLOBAL_EXPORT = 5,
  GLOBAL_SINGLE_PAGE_EXPORT = 6,
  SINGLE_INVOICE_EXPORT = 7,
  CUMULATIVE_EXPORT = 8,
  ANNEX_EXPORT = 9,
  TRACKING_TABLE_EXPORT = 10,
  TRACKING_TABLE_ADDITIONAL_TEST_EXPORT = 11,
  FORM_EXPORT = 12,
  RECIPES_EXPORT = 13

}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ACT_CATEGORY_READ_WRITE, AGENDA_READ_WRITE, AuthenticationService, CONFIGURATION_READ_WRITE, ESTABLISHMENT_READ_WRITE, FOLLOW_INVOICE_READ_WRITE, HR_READ_WRITE, INVOICE_READ_WRITE, MOBILE_APPLICATION_READ_WRITE, PATIENT_READ_WRITE, PROVIDER_READ_WRITE, REPORT_READ_WRITE, SITE_READ_WRITE, TRIAL_READ_WRITE} from '../../../login/services/authentication.service';
import {Permission} from '../../../helpers/permission';
import {PharmacyService} from '../../../trial/service/pharmacy.service';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {PropertyEnum} from '../../entity/property-enum';
import {DynamicDefinitionEnum} from '../../entity/dynamic-definition-enum';
import {ContainerConfig} from '../../entity/container-config';
import {GlobalConfigAppData} from '../../gloabl-config-app-data';

declare var $: any;
declare var $: any;

@Component({
  selector: 'ih-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {

  target = DynamicDefinitionEnum.SIDEBAR_MENU_CONTAINER;
  config: ContainerConfig = new ContainerConfig();
  isMobileApplicationEnabled = false;
  isPharmacyEnabled = false;
  isOngoingInvoicesEnabled = false;
  multiSiteEnabled = false;

  @Output() messageEvent = new EventEmitter<boolean>();
  actualRoute = '';
  actualPath = '';
  followedInvoice = false;
  followedRetrocessionFees = false;
  displaySideBar = true;
  isHomeActive = false;
  isAgendaActive = false;
  isEstablishmentActive = false;
  isConfigurationActive = false;
  isTrialListActive = false;
  isPatientActive = false;
  isProviderActive = false;
  isSiteActive = false;
  isHRActive = false;
  isAdditionalCostsActive = false;
  isInvoiceActive = false;
  isFollowInvoiceActive = false;
  isRetrocessionFeesActive = false;
  isFollowRetrocessionFeesActive = false;
  isOngoingInvoiceActive = false;
  isStatisticsActive = false;
  isDataImportActive = false;
  isMobileApplicationManagementActive = false;
  isPharmacyActive = false;
  userRole: string;

  constructor(
      private router: Router,
      public authenticationService: AuthenticationService,
      public pharmacyService: PharmacyService,
      private dynamicConfigService: DynamicConfigService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.detectWhichModuleActive(val.url, val.urlAfterRedirects);
      }
    });
    this.userRole = localStorage.getItem('authenticatedUserRole');
  }

  isMobileApplicationDisplayed() {
    return localStorage.getItem(MOBILE_APPLICATION_READ_WRITE) !== Permission.NO_RIGHT && this.isMobileApplicationEnabled;
  }

  isAdminConsoleDisplayed() {
    return this.userRole === 'ADMIN' || this.userRole === 'EDITOR';
  }

  ngOnInit() {
    if (window.innerWidth < 1300) {
      this.displaySideBar = false;
      this.messageEvent.emit(false);
    }

    $('.pi-bars').on('click', () => {
      $('.main-aside').toggleClass('active-menu');
      $('.fa-close, .menu-link, .m-aside-left-overlay').click(() => {
        $('.main-aside').removeClass('active-menu').addClass('close-aside');
        // e.stopPropagation();
      });
    });

    $('.fr').on('click', () => {
      $('.en img').removeClass('active-en');
      $('.fr img').addClass('active-fr');
    });
    $('.en').on('click', () => {
      $('.fr img').removeClass('active-fr');
      $('.en img').toggleClass('active-en');
    });


    this.initProperties();
    if (!localStorage.getItem('path')) {
      this.actualRoute = 'home';
      this.isHomeActive = true;
    } else {
      this.actualRoute = JSON.parse(localStorage.getItem('path'));
    }
  }


  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isMobileApplicationEnabled = this.dynamicConfigService.getProperty(PropertyEnum.mobileApplicationEnabled);
      this.isPharmacyEnabled = this.dynamicConfigService.getProperty(PropertyEnum.pharmacyEnabled);
      this.isOngoingInvoicesEnabled = this.dynamicConfigService.getProperty(PropertyEnum.enableOngoingInvoices);
      this.multiSiteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
    });
    this.initContainerConfig();
  }

  initContainerConfig(): void {
    this.dynamicConfigService.getContainerConfig(this.target, GlobalConfigAppData.containerConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getCardConfig = (cardName: string) => this.config.getCardConfig(cardName);
  displayCard = (cardName: string) => this.config.displayCard(cardName);
  getCardOrder = (cardName: string) => this.config.getCardOrder(cardName);

  goto(path: string) {
    localStorage.setItem('path', JSON.stringify(path));
    this.actualRoute = path;
    this.router.navigate([path]).then();
  }

  isModuleActive(pathList: any): boolean {
    if (this.actualPath.indexOf('trial-details') > -1) {
      this.actualRoute = 'trial-list';
    } else if (this.actualPath.indexOf('additional-costs-container') > -1) {
      this.actualRoute = 'additional-costs-container';
    }
    return this.actualRoute === pathList;
  }

  openFollowedInvoice() {
    this.followedInvoice = !this.followedInvoice;
  }

  openFollowedRetrocessionFees() {
    this.followedRetrocessionFees = !this.followedRetrocessionFees;
  }

  displayMenu() {
    this.displaySideBar = !this.displaySideBar;
    this.messageEvent.emit(this.displaySideBar);
  }

  isEstablishmentDisplayed() {
    return localStorage.getItem(ESTABLISHMENT_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isConfigurationDisplayed() {
    return localStorage.getItem(CONFIGURATION_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isTrialDisplayed() {
    return localStorage.getItem(TRIAL_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isAgendaDisplayed() {
    return localStorage.getItem(AGENDA_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isPatientDisplayed() {
    return localStorage.getItem(PATIENT_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isProviderDisplayed() {
    return localStorage.getItem(PROVIDER_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isSiteDisplayed() {
    return localStorage.getItem(SITE_READ_WRITE) !== Permission.NO_RIGHT && this.multiSiteEnabled;
  }

  isHRDisplayed() {
    return localStorage.getItem(HR_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isAdditionalCostsDisplayed() {
    return localStorage.getItem(ACT_CATEGORY_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isInvoiceDisplayed() {
    return localStorage.getItem(INVOICE_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isFollowInvoiceDisplayed() {
    return localStorage.getItem(FOLLOW_INVOICE_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isRetrocessionFeesDisplayed() {
    return localStorage.getItem(INVOICE_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isFollowRetrocessionFeesDisplayed() {
    return localStorage.getItem(INVOICE_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isStatisticsDisplayed() {
    return localStorage.getItem(REPORT_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isPharmacyDisplayed() {
    return (this.pharmacyService.isPharmacyDisplayed() && this.isPharmacyEnabled === true);
  }

  private detectWhichModuleActive(url: string, urlAfterRedirects: string) {
    if (url.indexOf('?') > 0) {
      this.actualPath = url === '/'
          ? urlAfterRedirects.substring(urlAfterRedirects.indexOf('/') + 1, urlAfterRedirects.indexOf('?'))
          : url.substring(url.indexOf('/') + 1, url.indexOf('?'));
    } else {
      this.actualPath = url === '/'
          ? urlAfterRedirects.substring(urlAfterRedirects.indexOf('/') + 1, urlAfterRedirects.length)
          : url.substring(url.indexOf('/') + 1, url.length);
    }

    this.isHomeActive = (this.actualPath === 'home' || (this.actualPath.indexOf('auth/edit') !== -1));
    this.isAgendaActive = (this.actualPath === 'agenda');
    this.isEstablishmentActive = (this.actualPath === 'establishment');
    this.isConfigurationActive = (this.actualPath === 'configuration');
    this.isTrialListActive = this.actualPath === 'trial-list' || (this.actualPath.indexOf('trial-details') !== -1);
    this.isPatientActive = (this.actualPath === 'patient-list' || (this.actualPath.indexOf('patient-details') !== -1)
        || (this.actualPath.indexOf('patient-inclusion-details') !== -1));
    this.isProviderActive = this.actualPath === 'provider-list' || ((this.actualPath.startsWith('contact')) &&
        (this.actualPath.endsWith('/Provider')));
    this.isSiteActive = this.actualPath === 'site-list' || ((this.actualPath.startsWith('contact/')) &&
        (this.actualPath.endsWith('/Site')));
    this.isHRActive = this.actualPath === 'human-resource-list' ||
        (this.actualPath.indexOf('human-resource-details') !== -1);
    this.isAdditionalCostsActive = this.actualPath.indexOf('additional-costs-container') !== -1;
    this.isInvoiceActive = this.actualPath.indexOf('invoice-container') !== -1;
    this.isFollowInvoiceActive = this.actualPath.startsWith('follow-invoice');
    this.isRetrocessionFeesActive = this.actualPath.indexOf('retrocession-fees-container') !== -1;
    this.isFollowRetrocessionFeesActive = this.actualPath.startsWith('follow-retrocession-fees');
    this.isOngoingInvoiceActive = this.actualPath.indexOf('ongoing-invoice') > -1;
    this.isStatisticsActive = this.actualPath.startsWith('statistics');
    this.isDataImportActive = this.actualPath.startsWith('data-import');
    this.isMobileApplicationManagementActive = this.actualPath.startsWith('mobile-application');
    this.isPharmacyActive = this.actualPath.startsWith('pharmacy');
  }

}

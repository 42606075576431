import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {SharedService} from '../../shared/services/shared.service';
import {Router} from '@angular/router';

@Component({
  selector: 'ih-delete-multiple',
  templateUrl: './delete-multiple.component.html',
  styleUrls: ['./delete-multiple.component.css']
})
export class DeleteMultipleComponent implements OnInit {
  displayDelete: boolean;
  popupMessage: string;
  selectedList: any;
  service: any;
  modal = true;
  navigationRoute: string;
  optionalNavigationRoute: string;
  callback: (deletedItem: number[]) => void;
  hhhId: number;
  dontDeleteWhenDeletable: boolean;
  name: string;

  constructor(private sharedService: SharedService,
              private router: Router) {
  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(DeleteMultipleComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    this.displayDelete = true;
  }

  confirmReferenceActDeletion() {
    this.displayDelete = false;
    const deletedItem = [];
    let i = 0;
    for (const item of this.selectedList) {
      this.service.isDeletable(item.hhhId).subscribe(res => {
        i++;
        if (!res.deletable) {
          if (res.why.includes('DEPENDENCY_THEORETICAL_VISIT_ACT')) {
            this.sharedService.showTrialActDeleteFailure(res.why,
              {name: item.description !== undefined ? item.description : item.additionalCostName},
              (res.object ? res.object.idsNames : null),
              () => this.displayReason(this.hhhId)
            );
          } else if (res.why.includes('DEPENDENCY_VISIT_ACT')) {
            this.sharedService.showTrialActDeleteFailure(res.why,
              {name: item.description !== undefined ? item.description : item.additionalCostName},
              (res.object ? res.object.idsNames : null),
              () => this.displayReason(this.hhhId, res.object.idsNames[0].value)
            );
          } else if ((res.why.includes('DEPENDENCY_TRIAL_FIXED_COST')) || (res.why.includes('DEPENDENCY_TRIAL_ACT')) || (res.why.includes('DEPENDENCY_COUNTER_PART')) || res.why.includes('DEPENDENCY_TRIAL_COUNTER_PART') || res.why.includes('DEPENDENCY_TRIAL_REFERENCE_ACT')) {
            this.sharedService.showDeleteFailure(res.why,
              {name: item.description !== undefined ? item.description : item.additionalCostName},
              (res.object ? res.object.fullName : null),
              () => this.displayReason(res.object.trialHhhId)
            );
          } else {
            this.sharedService.showDeleteFailure(res.why,
              {name: item.description},
              res.object ? res.object.fullName : null,
              () => this.displayReason(res.object.hhhId)
            );
          }
        } else {
          deletedItem.push(item.hhhId);
          if (!this.dontDeleteWhenDeletable) {
            this.deleteSinglesReferenceActConfirmation(item.hhhId);
          }
        }
        if (this.selectedList.length === i) {
          this.callback(deletedItem);
        }
      });
    }
  }

  displayReason(hhhId: number, optionalHhhId?: number) {
    if (optionalHhhId === undefined) {
      this.router.navigate(
        [this.navigationRoute],
        {queryParams: {hhhId: hhhId}}
      ).then();
    } else {
      this.router.navigate(
        [this.optionalNavigationRoute],
        {queryParams: {hhhId: hhhId, inclusionId: optionalHhhId}}
      ).then();
    }
  }

  deleteSinglesReferenceActConfirmation(hhhId: number) {
    this.service.delete(hhhId).subscribe(() => {
      this.sharedService.showSuccess();
    }, error => {
      this.sharedService.showFailure();
      console.error(error);
    });
  }

  onDelete(callback: (deletedItem: number[]) => void) {
    this.callback = callback;
  }

}

import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'ih-invoice-budget',
  templateUrl: './invoice-budget.component.html',
  styleUrls: ['./invoice-budget.component.css']
})
export class InvoiceBudgetComponent implements OnInit {
  @ViewChild('progressChart') progressChartRef: ElementRef;
  @Input() invoicedBudget: number;
  @Input() realisedBudget: number;
  @Input() toBeInvoicedBudget: number;
  @Input() nonRealisedBudget: number;
  @Input() notApplicableBudget: number;
  @Input() payedBudget: number;
  invoiceHhhId: number;
  trialHhhId: number;

  constructor(private router: Router) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
  }

  calculateBudgetPercentage(budget: number) {
    if (!budget) {
      return '0';
    }
    const minWidth = 20;
    const total = this.payedBudget + this.toBeInvoicedBudget + this.realisedBudget + this.nonRealisedBudget + this.invoicedBudget+this.notApplicableBudget;
    return (budget / total) * 100;
  }
}

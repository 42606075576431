import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../form/form.component';

@Component({
  selector: 'ih-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.css']
})
export class WarningDialogComponent extends FormComponent<any> implements OnInit {
  warningHeader = 'GENERAL_LINGUISTICS_CAUTION';
  content: string;

  constructor() {
    super();

  }

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(WarningDialogComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  save() {
    this.callback(true);
    this.display = false;

  }
}

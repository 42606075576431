import {Component, OnInit} from '@angular/core';
import {FR} from '../../shared/entity/calendar-language';
import {SharedService} from '../../shared/services/shared.service';
import {OtherTheoreticalInvoiceDate} from '../../trial/models/other-theoretical-invoice-date';
import {OtherTheoreticalInvoiceDateService} from '../../trial/services/other-theoretical-invoice-date.service';

@Component({
  selector: 'ih-other-theoretical-invoice-date',
  templateUrl: './other-theoretical-invoice-date.component.html',
  styleUrls: ['./other-theoretical-invoice-date.component.css']
})
export class OtherTheoreticalInvoiceDateComponent implements OnInit {

  display = true;
  otherTheoreticalInvoiceDateList: OtherTheoreticalInvoiceDate[] = [];
  locale = FR;
  callback: (amendedAgreementStream: string) => void;
  private trialId: number;

  constructor(private otherTheoreticalInvoiceDateService: OtherTheoreticalInvoiceDateService,
              private sharedService: SharedService) {
  }
  ngOnInit() {
    this.getOtherTheoreticalInvoiceDate();
  }

  setTrialId(trialId: number) {
    this.trialId = trialId;
  }


  onAdd(callback: (amendedAgreementStream: string) => void) {
    this.callback = callback;
  }


  addDate() {
    const otherTheoreticalInvoiceDate = new OtherTheoreticalInvoiceDate();
    otherTheoreticalInvoiceDate.trialHhhId = this.trialId;
    this.otherTheoreticalInvoiceDateList.push(otherTheoreticalInvoiceDate);
  }

  saveOtherTheoreticalInvoiceDates() {
    this.otherTheoreticalInvoiceDateService.saveOtherTheoreticalInvoiceDates(this.trialId, this.otherTheoreticalInvoiceDateList).subscribe(
      (res) => {
        this.otherTheoreticalInvoiceDateList = res;
        this.display = false;
      }
    );
  }

  getOtherTheoreticalInvoiceDate() {
    this.otherTheoreticalInvoiceDateService.getOtherTheoreticalInvoiceDate(this.trialId).subscribe(
      (res) => {
        this.otherTheoreticalInvoiceDateList = res;
        if (this.otherTheoreticalInvoiceDateList.length === 0) {
          this.addDate();
        }
      }
    );

  }

  deleteDate(hhhId: number) {
    this.otherTheoreticalInvoiceDateService.delete(hhhId).subscribe((res) => {
      const index = this.otherTheoreticalInvoiceDateList.findIndex((otherTheoreticalInvoiceDate: OtherTheoreticalInvoiceDate) => otherTheoreticalInvoiceDate.hhhId === res);
      this.otherTheoreticalInvoiceDateList.splice(index, 1);
    });
  }
}

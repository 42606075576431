import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  ButtonModule,
  CardModule,
  DialogModule,
  DropdownModule,
  EditorModule,
  FieldsetModule,
  FileUploadModule,
  InputSwitchModule,
  InputTextareaModule,
  InputTextModule,
  MenubarModule,
  MenuModule,
  MultiSelectModule,
  ProgressBarModule,
  ScrollPanelModule,
  SliderModule,
  TabMenuModule,
  TabViewModule,
  ToolbarModule
} from 'primeng/primeng';
import {MobileRoutingModule} from './mobile-routing.module';
import {AboutComponent} from './about/about.component';
import {AccountsManagementComponent} from './accounts-management/accounts-management.component';
import {MobileApplicationHeaderComponent} from './mobile-application-header/mobile-application-header.component';
import {TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import {TrialsComponent} from './trials/trials.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {MobileFrameComponent} from './mobile-frame/mobile-frame.component';
import {SharedModule} from '../shared/shared.module';
import {AccountManagementFormComponent} from './account-management-form/account-management-form.component';
import {EditTrialFormComponent} from './edit-trial-form/edit-trial-form.component';
import {ChatDialogComponent} from './chat-dialog/chat-dialog.component';
import {SuggestPatientsDialogComponent} from './suggest-patients-dialog/suggest-patients-dialog.component';
import {DoctorsSliderComponent} from './doctors-slider/doctors-slider.component';
import {ChatDialogFormComponent} from './chat-dialog-form/chat-dialog-form.component';
import {SuggestedPatientsFormComponent} from './suggested-patients-form/suggested-patients-form.component';
import {ChatDialogSingleMessageComponent} from './chat-dialog-single-message/chat-dialog-single-message.component';
import {MatBadgeModule, MatTabsModule} from '@angular/material';
import {PushNotificationManagementComponent} from './notifications/push-notification-management.component';
import {CustomNotificationComponent} from './custom-notification/custom-notification.component';
import {DynamicConfigModule} from '../dynamic-config/dynamic-config.module';

@NgModule({
  declarations: [AboutComponent,
    AccountsManagementComponent,
    MobileApplicationHeaderComponent,
    TermsAndConditionsComponent,
    TrialsComponent,
    MobileFrameComponent,
    AccountManagementFormComponent,
    EditTrialFormComponent,
    ChatDialogComponent,
    SuggestPatientsDialogComponent,
    DoctorsSliderComponent,
    ChatDialogFormComponent,
    SuggestedPatientsFormComponent,
    ChatDialogSingleMessageComponent,
    PushNotificationManagementComponent,
    CustomNotificationComponent],
  imports: [
    CommonModule,
    CardModule,
    MobileRoutingModule,
    MenubarModule,
    ToolbarModule,
    TranslateModule,
    EditorModule,
    FormsModule,
    ButtonModule,
    SharedModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    ProgressBarModule,
    FileUploadModule,
    TabViewModule,
    ScrollPanelModule,
    InputTextareaModule,
    FieldsetModule,
    MatBadgeModule,
    MultiSelectModule,
    MatTabsModule,
    InputSwitchModule,
    SliderModule,
    TabMenuModule,
    MenuModule,
    DynamicConfigModule
  ], entryComponents: [
    AccountManagementFormComponent,
    EditTrialFormComponent,
    SuggestPatientsDialogComponent,
    ChatDialogComponent,
    CustomNotificationComponent
  ]
})
export class MobileModule {
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PharmacyStock} from '../entity/trial-pharmacy/pharmacyStock';
import {HttpClient} from '@angular/common/http';
import {Properties} from '../../helpers/properties';
import {PharmacyStockGlobalFilter} from '../../pharmacy/model/pharmacyStockGlobalFilter';
import {ResponsePage} from '../../shared/entity/response-page';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {DatePipe} from '@angular/common';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {IsDeletable} from "../../is-deletable";

@Injectable({
  providedIn: 'root'
})
export class PharmacyStockService {

  public host = new Properties().host + '/pharmacy-stock';
  public pharmacyHhhId: number;
  public pharmacyStockDataPage: ResponsePage<PharmacyStock>;
  public page: number;
  public limit: number;
  public sortBy: string;
  public orderBy: string;
  public dateType: string;
  public pharmacyStockGlobalFilter: PharmacyStockGlobalFilter = new PharmacyStockGlobalFilter();


  constructor(
    private httpClient: HttpClient,
    private datePipe: DatePipe) {
  }

  setPaginationConfiguration(dataPage: ResponsePage<PharmacyStock>, dateType, limit, sortBy, orderBy, filters: PharmacyStockGlobalFilter, pharmacyHhhId: number) {
    this.pharmacyStockDataPage = dataPage;
    this.dateType = dateType;
    this.limit = limit;
    this.sortBy = sortBy;
    this.orderBy = orderBy;
    this.pharmacyHhhId = pharmacyHhhId;
    this.pharmacyStockGlobalFilter = filters;
  }


  loadList(pharmacyHhhId: number, dateType: string, fromDate: Date, toDate: Date, page: number, limit: number, sortBy: string, orderBy: string, filters: PharmacyStockGlobalFilter): Observable<ResponsePage<PharmacyStock>> {
    return this.httpClient.post<ResponsePage<PharmacyStock>>(
      this.host + '/load-list',
      new RequestPageBuilder<PharmacyStockGlobalFilter>()
        .objectId(pharmacyHhhId)
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(filters)
        .type(dateType)
        .fromDate(fromDate != null ? this.datePipe.transform(fromDate, 'dd/MM/yyyy') : '')
        .toDate(toDate != null ? this.datePipe.transform(toDate, 'dd/MM/yyyy') : '')
        .build()

    );
  }

  add(pharmacyStock: PharmacyStock): Observable<number> {
    return this.httpClient.post<number>(this.host + '/add', pharmacyStock);
  }

  update(newStock: PharmacyStock): Observable<number> {
    return this.httpClient.post<number>(this.host + '/update', newStock);
  }
  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/deletable/' + hhhId);
  }
  delete(lotHhhId: number): Observable<void> {
    return this.httpClient.get<void>(this.host + '/delete/' + lotHhhId);
  }

  exportPharmacyStocks(request: ExportRequest<PharmacyStockGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request);
  }

}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {ModuleHeaderConfig} from '../../entity/module-header-config';

@Component({
  selector: 'ih-dynamic-list-header',
  templateUrl: './dynamic-list-header.component.html',
  styleUrls: ['./dynamic-list-header.component.css']
})
export class DynamicListHeaderComponent {
  _target: string;
  header: ModuleHeaderConfig;
  @Input() title: string;
  @Input() displayBtn = true;
  @Input() icon: string;
  @Input() btnLabel: string;
  @Input() disabled = false;
  @Output() btnEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() extraBtnLabel: string;
  @Input() displayExtraButton = false;
  @Input() disabledExtraButton = false;
  @Output() extraBtnEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dynamicConfigService: DynamicConfigService) {
  }

  @Input()
  set target(v: string) {
    this._target = v;
    this.getModuleConfig();
  }

  get target(): string {
    return this._target;
  }

  getModuleConfig(): void {
    this.dynamicConfigService.getModuleConfig(this._target).subscribe(
      cf => {
        this.header = cf.header;
      }
    );
  }


  getTitle(): string {
    return (this._target && this.header) ? this.header.title : this.title;
  }

  emitBtnEvent(): void {
    this.disabled = true;
    this.btnEvent.emit();
    this.disabled = false;
  }

  emitExtraBtnEvent(): void {
    this.disabledExtraButton = true;
    this.extraBtnEvent.emit();
    this.disabledExtraButton = false;
  }
}

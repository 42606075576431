import {Component, Input} from '@angular/core';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {DynamicFormDefinition} from '../../entity/dynamic-form-definition';
import {Util} from '../../../helpers/util';
import {FormInputConfig} from '../../entity/form-input-config';
import {DynamicFormDefinitionField} from '../../entity/dynamic-form-definition-field';
import {DynamicFormDefinitionFieldset} from '../../entity/dynamic-form-definition-fieldset';
import {SelectItem} from 'primeng/api';
import {DynamicTranslatorPipe} from '../../pipe/dynamic-translator.pipe';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'ih-form-config-editor',
  templateUrl: './form-config-editor.component.html',
  styleUrls: ['./form-config-editor.component.css'],
  providers: [DynamicTranslatorPipe]
})
export class FormConfigEditorComponent {

  @Input() name: string;
  _target: string;

  formDefinition: DynamicFormDefinition;
  dbFormDefinition: DynamicFormDefinition;
  fieldset: DynamicFormDefinitionFieldset[] = [];
  cols: any[] = [];

  checked = false;
  hasFieldset = false;
  hasGroup = false;
  fieldsetOptions: SelectItem[] = [];
  fieldFilter = '';
  headerFilter = '';

  groupOptions: SelectItem[] = [{label: 'Aucune', value: undefined}, {label: 'gauche', value: 'leftGroup'}, {label: 'droite', value: 'rightGroup'}];
  widthOptions: SelectItem[] = [{label: 'Taille compléte', value: 'p-lg-12'}, {label: '1/2', value: 'p-lg-6'}, {label: '1/3', value: 'p-lg-4'}, {label: '1/4', value: 'p-lg-3'}, {label: '1/6', value: 'p-lg-2'}, {label: '1/8', value: 'p-lg-1'}];

  preview = false;
  previewDefinition: DynamicFormDefinition;

  constructor(
      private dynamicConfigService: DynamicConfigService,
      private dynamicTranslator: DynamicTranslatorPipe,
      private sharedService : SharedService
  ) {
  }

  @Input()
  set target(v: string) {
    this._target = v;
    this.getFormDefinition();
  }

  get target(): string {
    return this._target;
  }

  getFormDefinition(): void {
    this.dynamicConfigService.getAdminConsoleFormConfig(this._target).subscribe(
        form => {
          this.formDefinition = Util.clone(form);
          this.dbFormDefinition = Util.clone(form);
          this.buildData();
          this.buildCols();
        }, error => console.log(error.message)
    );
  }

  buildData(): void {
    this.hasFieldset = this.formDefinition.hasFieldset;
    this.hasGroup = this.formDefinition.hasGroup;
    if (!this.hasFieldset) {
      this.formDefinition.fields = this.formDefinition.sortFields(this.formDefinition.fields);
    } else {
      this.fieldsetOptions = [];
      this.fieldset = this.formDefinition.buildData();
      this.fieldset.forEach((f) => {
        this.fieldsetOptions.push({label: f.parent.field, value: f.parent.field});
      });
      this.fieldsetOptions[0] = {label: 'Non attaché', value: undefined};
    }
  }

  buildCols(): void {
    this.cols = this.formDefinition.hasPostIt() ?
        [
          {field: 'field', header: 'Champ', width: '130px'},
          {field: 'label', header: 'Intitulé', width: '130px', type: 'input'},
          {field: 'display', header: 'Activé', width: '50px', type: 'display'},
          {field: 'required', header: 'Obligatoire', width: '50px', type: 'boolean'},
          {field: 'disabled', header: 'Inactif', width: '50px', type: 'boolean'},
          {field: 'privateComment', header: 'Commentaire Privé', width: '70px', type: 'postIt'},
          {field: 'publicComment', header: 'Commentaire Public', width: '70px', type: 'postIt'},
        ] :
        [
          {field: 'field', header: 'Champ', width: '150px'},
          {field: 'label', header: 'Intitulé', width: '150px', type: 'input'},
          {field: 'display', header: 'Activé', width: '50px', type: 'display'},
          {field: 'required', header: 'Obligatoire', width: '50px', type: 'boolean'},
          {field: 'disabled', header: 'Inactif', width: '50px', type: 'boolean'},
        ];
    if (this.hasFieldset) {
      this.cols.push({field: 'attachedTo', header: 'Section', width: '100px', type: 'attachTo'});
    }
    if (this.hasGroup) {
      this.cols.push({field: 'group', header: 'Ordre', width: '50px', type: 'group'});
    }
    this.cols.push({field: 'width', header: 'Taille', width: '80px', type: 'width'});
    this.cols.push({field: 'type', header: 'Type de champ', width: '50px', type: 'type'});
    this.cols.push({field: 'important', header: 'Important', width: '50px', type: 'boolean'});
    this.cols.push({field: 'importantDescription', header: 'Texte d\'information', width: '100px', type: 'importantDescription'});
  }

  disableField(config: FormInputConfig) {
    if (config.display) {
      return;
    }
    config.label = undefined;
    config.required = false;
    config.disabled = false;
    config.order = undefined;
    config.privateComment = false;
    config.publicComment = false;
    config.important = false;
  }

  disableChildrenFields(parent: DynamicFormDefinitionField): void {
    if (parent.config.display) {
      return;
    }
    const index = this.fieldset.findIndex(f => f.parent.hhhId === parent.hhhId);
    if (index !== -1) {
      this.fieldset[index].children.forEach(f => {
        f.config.display = false;
        f.config.label = undefined;
        f.config.required = false;
        f.config.disabled = false;
        f.config.order = undefined;
        f.config.privateComment = false;
        f.config.publicComment = false;
      });
    }
  }

  dataChanged(): boolean {
    return !Util.equal(this.dbFormDefinition, this.formDefinition);
  }

  save(): void {
    if (this.hasFieldset) {
      this.formDefinition.fields = [];
      this.fieldset.forEach((fieldset, i) => {
        fieldset.parent.config.display = fieldset.children.length > 0;
        if (i !== 0) {
          this.formDefinition.fields.push(fieldset.parent);
        }
        fieldset.children.forEach(field => {
          this.formDefinition.fields.push(field);
        });
      });
    }
    this.dynamicConfigService.saveFormConfig(this.formDefinition).subscribe(
        (form) => {
          this.formDefinition = Util.clone(form);
          this.dbFormDefinition = Util.clone(form);
          this.buildData();
          this.sharedService.showSuccess();
        }, error => console.log(error.message)
    );
  }

  reset(): void {
    this.formDefinition = Util.clone(this.dbFormDefinition);
  }

  showTitleEditor(): boolean {
    if (this.formDefinition) {
      return !Util.isStringNullOrEmpty(this.formDefinition.defaultTitle.defaultTitle);
    }
  }

  isDisplayed(config: FormInputConfig): boolean {
    return config.display;
  }

  hasPostIt(config: FormInputConfig): boolean {
    return config.display;
  }


  getPlaceholder(type: string, field?: DynamicFormDefinitionField): string {
    if (type === 'Title' && Util.isStringNullOrEmpty(this.formDefinition.defaultTitle.config.title)) {
      return this.formDefinition.defaultTitle.defaultTitle;
    }
    if (type === 'Label' && Util.isStringNullOrEmpty(field.config.label)) {
      return field.defaultLabel;
    }
    if (type === 'importantDescription' && Util.isStringNullOrEmpty(field.config.importantDescription)) {
      return field.defaultImportantDescription;
    }
    return 'Default ' + type;
  }

  getTitle(): string {
    if (Util.isStringNullOrEmpty(this.formDefinition.defaultTitle.config.title)) {
      return this.formDefinition.defaultTitle.defaultTitle;
    }
    return this.formDefinition.defaultTitle.config.title;
  }

  getFieldType(type: string): string {
    switch (type) {
      case 'inputText':
        return 'Champs libre';
      case 'dropdown':
        return 'Menu déroulant';
      case 'calendar':
        return 'Calendrier';
      case 'multiSelect':
        return 'Choix multiples';
      case 'textArea':
        return 'Zone de texte';
      case 'fieldset':
        return 'Section';
      case 'crossover':
        return 'Crossover';
      case 'anchorLink':
        return 'Lien';
      case 'checkbox':
        return 'CheckBox';
      case 'button':
        return 'Boutton';
      default:
        return type;
    }
  }

  attachToFieldset(fieldset: DynamicFormDefinitionFieldset, field: DynamicFormDefinitionField): void {
    if (field.config.attachedTo === undefined) {
      const index = fieldset.children.findIndex(f => field.field === f.field);
      this.fieldset[0].children.push(field);
      fieldset.children.splice(index, 1);
    } else {
      const index = this.fieldset.findIndex(f => fieldset.parent.field === f.parent.field);
      const targetIndex = this.fieldset.findIndex(f => field.config.attachedTo === f.parent.field);
      if (index !== -1) {
        const fieldIndex = this.fieldset[index].children.findIndex(c => c.field === field.field);
        this.fieldset[targetIndex].children.push(this.fieldset[index].children[fieldIndex]);
        this.fieldset[index].children.splice(fieldIndex, 1);
        if (this.fieldset[index].children.length === 0) {
          this.fieldset[index].parent.config.display = false;
        }
        if (this.fieldset[targetIndex].children.length > 0) {
          this.fieldset[targetIndex].parent.config.display = true;
        }
      }
    }
  }

  filter(): void {
    if (Util.isStringNullOrEmpty(this.fieldFilter) && Util.isStringNullOrEmpty(this.headerFilter)) {
      this.formDefinition.fields.forEach(f => f.color = '');
      return;
    }
    this.formDefinition.fields.forEach(f => {
      const translatedHeader = this.dynamicTranslator.transform(Util.isStringNullOrEmpty(f.config.label) ? f.defaultLabel : f.config.label).toLowerCase();
      if (!Util.isStringNullOrEmpty(this.fieldFilter) && !Util.isStringNullOrEmpty(this.headerFilter)) {
        f.color = (f.field).toLowerCase().includes(this.fieldFilter.toLowerCase()) || translatedHeader.toLowerCase().includes(this.headerFilter) ? '#D6EAF5' : '';
      } else if (!Util.isStringNullOrEmpty(this.fieldFilter)) {
        f.color = (f.field).toLowerCase().includes(this.fieldFilter.toLowerCase()) ? '#D6EAF5' : '';

      } else if (!Util.isStringNullOrEmpty(this.headerFilter)) {
        f.color = translatedHeader.toLowerCase().includes(this.headerFilter) ? '#D6EAF5' : '';
      }
    });
  }

  startPreview(): void {
    this.previewDefinition = Util.clone(this.formDefinition);
    if (this.hasFieldset) {
      this.previewDefinition.fields = [];
      this.fieldset.forEach((fieldset, i) => {
        fieldset.parent.config.display = fieldset.children.length > 0;
        if (i !== 0) {
          this.previewDefinition.fields.push(fieldset.parent);
        }
        fieldset.children.forEach(field => {
          this.previewDefinition.fields.push(field);
        });
      });
      this.previewDefinition.fields = this.previewDefinition.sortFields(this.previewDefinition.fields);
    }
    let index = 1;
    for (const field of this.previewDefinition.fields) {
      if (Util.isStringNullOrEmpty(field.config.label)) {
        field.config.label = field.defaultLabel;
      }
      if (field.config.display) {
        field.config.order = index++;
      }
    }
    this.preview = true;
  }

  endPreview(): void {
    this.previewDefinition = new DynamicFormDefinition();
    this.preview = false;
  }
}

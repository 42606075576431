import { Component, OnInit } from '@angular/core';
import {PowerBiService} from '../service/power-bi.service';
import * as pbi from 'powerbi-client';
declare var powerbi: any;

@Component({
  selector: 'ih-power-bi-report',
  templateUrl: './power-bi-report.component.html',
  styleUrls: ['./power-bi-report.component.css']
})
export class PowerBiReportComponent implements OnInit {

  config: any;

  constructor(private configService: PowerBiService) { }

  ngOnInit(): void {
    this.configService.getConfig().subscribe(
      (config: any) => {
        this.config = config;
        this.embedReport();
      },
      (error: any) => {
        console.error('Failed to fetch config parameters:', error);
      }
    );
  }

  embedReport(): void {
    if (this.config) {
      const embedContainer = document.getElementById('reportContainer');
      const config: pbi.IEmbedConfiguration = {
        type: 'report',
        id: this.config.id,
        embedUrl: this.config.embedReports[0].embedUrl,
        accessToken: this.config.embedToken,
        tokenType: pbi.models.TokenType.Embed,
        permissions: pbi.models.Permissions.All,
      };
      const report = powerbi.embed(embedContainer, config);
    }
  }
}


export class InclusionReportFilter {
  public trialStateHhhIds: number[];
  public phaseHhhIds: number[];
  public illnessStateHhhIds: number[];
  public stateHhhIds: number[];
  public promoterTypeHhhId: number;
  public organHhhId: number;
  public referrerInvestigatorHhhId: number;
  public researchFieldHhhIds: number[];
  public researchTypeHhhIds: number[];
  public moleculeStudiedHhhId: string;
  public siteHhhId: number;
  public trials: number;
  public providerHhhId: number;
  public patientAge: string;
  public fromDate: Date;
  public toDate: Date;
  public humanResourceHhhId: number;
  public addressedByExternalDoctor: boolean;
  public inclusionsDateUnit: string;
  public localIdentifier: number;
  public establishmentHhhId: number;
  public establishmentDepartmentHhhId: number;
  public trialCommitteesHhhIds: number[];
  public referrerDoctorHhhIds: number[];

}

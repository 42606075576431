export class VisitActive {
  hhhId: number;
  isVisitAct: boolean;


  constructor(hhhId?: number, isVisitAct?: boolean) {
    this.hhhId = hhhId;
    this.isVisitAct = isVisitAct;
  }


}

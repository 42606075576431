import {LabelValue} from '../../shared/entity/label-value';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {Util} from '../../helpers/util';

export class TrialConsent {
  hhhId: number;
  documentType: LabelValue;
  documentVersion: string;
  documentName: string;
  documentVersionDate: Date;
  trialProtocol: LabelValue;
  trialAmendment: LabelValue;
  amendmentVersion: string;
  trialHhhId: number;


  constructor() {
    this.documentType = new LabelValue();
    this.trialProtocol = new LabelValue();
    this.trialAmendment = new LabelValue();
  }

  init(obj: any): TrialConsent {
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        this[prop] = obj[prop];
      }
    }
    return this;
  }

  isValid(config: FormConfig): boolean {
    const valid = true;
    for (const key of Array.from(config.fields.keys())) {
      const required = config.isFieldRequired(key);
      if (key === 'documentTypeValue') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.documentType.value)) {
          return false;
        }
      } else if (key === 'trialProtocolValue') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.trialProtocol.value) && Util.isNullOrUndefinedOrEmpty(this.trialAmendment.value)) {
          return false;
        }
      } else if (key === 'trialAmendmentValue') {
        if (required && Util.isNullOrUndefinedOrEmpty(this.trialAmendment.value) && Util.isNullOrUndefinedOrEmpty(this.trialProtocol.value)) {
          return false;
        }
      } else if (required && Util.isNullOrUndefinedOrEmpty(this[key])) {
        return false;
      }
    }
    return valid;
  }

  validate(): boolean {
    return !((this.documentType && Util.isNullOrUndefined(this.documentType.value) &&
        Util.isNullOrUndefinedOrEmpty(this.documentVersion) &&
        Util.isNullOrUndefinedOrEmpty(this.documentName)
      )
    )
  }
}

import {Component, Input} from '@angular/core';
import {DynamicFormDefinition} from '../../entity/dynamic-form-definition';
import {FormConfig} from '../../entity/form-config';
import {FormInputConfig} from '../../entity/form-input-config';
import {FormTitleConfig} from '../../entity/form-title-config';
import {Util} from '../../../helpers/util';

@Component({
  selector: 'ih-form-preview',
  templateUrl: './form-preview.component.html',
  styleUrls: ['./form-preview.component.css']
})
export class FormPreviewComponent {

  _formDefinition: DynamicFormDefinition = new DynamicFormDefinition();
  config: FormConfig = new FormConfig(new FormTitleConfig(), new Map<string, FormInputConfig>());
  fieldsets: any[] = [];

  @Input()
  set formDefinition(value: DynamicFormDefinition) {
    if (Util.isNullOrUndefined(value)) {
      return;
    }
    this._formDefinition = value;
    this.fieldsets = [];
    for (const f of this._formDefinition.fields) {
      this.config.fields.set(f.field, f.config);
      if (f.type === 'fieldset') {
        this.fieldsets.push(f);
      }
    }
  }

  constructor() {}

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);


}

import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared/services/shared.service';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {DataService} from '../../shared/services/data-service';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {TranslateService} from '@ngx-translate/core';
import {Permission} from '../../helpers/permission';
import {ACT_CATEGORY_READ_WRITE, ACT_GRID_READ_WRITE, COUNTER_PART_CATEGORY_READ_WRITE, COUNTER_PART_REFERENCE_READ_WRITE, FIXED_COST_CATEGORY_READ_WRITE, FIXED_COST_READ_WRITE, OPERATIONAL_ACT_READ_WRITE} from '../../login/services/authentication.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {ModuleConfig} from '../../dynamic-config/entity/module-config';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-additional-costs-container',
  templateUrl: './additional-costs-container.component.html',
  styleUrls: ['./additional-costs-container.component.css']
})
export class AdditionalCostsContainerComponent implements OnInit {

  _target = DynamicDefinitionEnum.ADDITIONAL_COSTS_MODULE;
  config: ModuleConfig = new ModuleConfig();
  isCounterPartEnabled = true;
  isOperationalActEnabled = true;

  selected: boolean;
  previousRoute: string;
  history: string[];
  fixedCostSelected: string;
  actSelected: string;
  actIsSelected: boolean;
  counterPartSelected: boolean;
  menuItems: MenuItem[] = [];

  constructor(
      private translate: TranslateService,
      private sharedService: SharedService,
      private translateService: InnohealthTranslateService,
      private dataService: DataService,
      private router: Router,
      private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.fixedCostSelected = url.queryParams.fixedCostSelected;
      this.actSelected = url.queryParams.actSelected;
      this.counterPartSelected = url.queryParams.counterPartSelected;
      translate.use(translate.currentLang);
    }
  }

  ngOnInit() {
    this.initProperties();
    this.translate.stream('UI_MENU_MAIN_ENTRY_ACT_CATEGORIES').subscribe(() => {
      this.extractItems();
    });

    this.previousRoute = this.sharedService.getPreviousUrl();
    this.history = this.sharedService.getHistory();
    if (this.previousRoute.substring(0, this.previousRoute.indexOf('?')) === '/trial-details' && this.fixedCostSelected === 'true') {
      this.selected = true;
    }
    if (this.previousRoute.substring(0, this.previousRoute.indexOf('?')) === '/trial-details' && this.actSelected === 'true') {
      this.actIsSelected = true;
    }
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.isCounterPartEnabled = this.dynamicConfigService.getProperty(PropertyEnum.counterPartEnabled);
      this.isOperationalActEnabled = this.dynamicConfigService.getProperty(PropertyEnum.operationalActEnabled);

      this.getModuleConfig();
    });
  }


  getModuleConfig(): void {
    this.dynamicConfigService.getModuleConfig(this._target).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  resetFilterVar() {
    this.dataService.changeFilterVar(null);
  }

  isActsCategoriesDisplayed() {
    return localStorage.getItem(ACT_CATEGORY_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isReferenceActsDisplayed() {
    return localStorage.getItem(ACT_GRID_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isFixedCostCategoriesDisplayed() {
    return localStorage.getItem(FIXED_COST_CATEGORY_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isFixedCostReferenceDisplayed() {
    return localStorage.getItem(FIXED_COST_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isCounterPartCategoriesDisplayed() {
    return localStorage.getItem(COUNTER_PART_CATEGORY_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isCounterPartsDisplayed() {
    return localStorage.getItem(COUNTER_PART_REFERENCE_READ_WRITE) !== Permission.NO_RIGHT;
  }

  isOperationalActsDisplayed() {
    return localStorage.getItem(OPERATIONAL_ACT_READ_WRITE) !== Permission.NO_RIGHT;
  }

  private extractItems() {
    this.menuItems = [];
    if (this.isActsCategoriesDisplayed()) {
      this.menuItems.push(
          {
            label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_ACT_CATEGORIES'),
            routerLink: ['/additional-costs-container/acts-categories']
          }
      );
    }
    if (this.isReferenceActsDisplayed()) {
      this.menuItems.push(
          {
            label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_REFERENCE_ACTS'),
            routerLink: ['/additional-costs-container/reference-acts']
          }
      );
    }
    if (this.isFixedCostCategoriesDisplayed()) {
      this.menuItems.push(
          {
            label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_FIXED_COST_CATEGORIES'),
            routerLink: ['/additional-costs-container/fixed-cost-categories']
          }
      );
    }
    if (this.isFixedCostReferenceDisplayed()) {
      this.menuItems.push(
          {
            label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_FIXED_COSTS'),
            routerLink: ['/additional-costs-container/fixed-cost-reference']
          }
      );
    }
    if (this.isCounterPartEnabled) {
      if (this.isCounterPartCategoriesDisplayed()) {
        this.menuItems.push(
            {
              label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_COUNTER_PART_CATEGORIES'),
              routerLink: ['/additional-costs-container/counter-part-categories']
            }
        );
      }
      if (this.isCounterPartsDisplayed()) {
        this.menuItems.push(
            {
              label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_COUNTER_PART'),
              routerLink: ['/additional-costs-container/counter-parts'],
            }
        );
      }
    }
    if (this.isOperationalActsDisplayed() && this.isOperationalActEnabled) {
      this.menuItems.push({
        label: this.translateService.getTranslationString('UI_MENU_MAIN_ENTRY_OPERATIONAL_ACT'),
        routerLink: ['/additional-costs-container/operational-acts']
      });
    }
  }
}


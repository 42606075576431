export class ClonedTheoreticalVisit {
  public trialHhhId: number;
  public theoreticalCalendarHhhId: number;
  public theoreticalVisitHhhId: number;
  public selectedArm: string;
  public duplicateNumber: number;
  public periodType: string;
  public period: number;
  public name: string;
  public firstPeriodType: string;
  public firstPeriod: number;
  public periodMargin: number;
}

import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Account} from '../models/account';
import {IsDeletable} from '../../is-deletable';

@Injectable({
  providedIn: 'root'
})
export class AccountsManagementService {
  public host = new Properties().host + '/mobile-application/user-accounts';

  constructor(private httpClient: HttpClient) {
  }

  getAccounts(): Observable<Account[]> {
    return this.httpClient.get<Account[]>(this.host);
  }

  addMobileUserAccount(account): Observable<Account> {
    return this.httpClient.post<Account>(this.host, account);
  }

  updateMobileUserAccount(account): Observable<void> {
    return this.httpClient.put<void>(this.host, account);
  }

  delete(hhhId: number): Observable<void> {
    return this.httpClient.delete<void>(this.host + '/' + hhhId);
  }

  isDeletable(hhhId: number): Observable<IsDeletable> {
    return this.httpClient.get<IsDeletable>(this.host + '/' + hhhId + '/is-deletable');
  }
}

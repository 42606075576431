import {Component, ComponentFactoryResolver, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {Provider} from '../models/provider';
import {DataService} from '../../shared/services/data-service';
import {Router} from '@angular/router';
import {ProviderService} from '../services/provider.service';
import {SelectItem} from 'primeng/api';
import {ProviderFormComponent} from '../provider-form/provider-form.component';
import {ProviderTypeDto} from '../models/provider-type-dto';
import {ResponsePage} from '../../shared/entity/response-page';
import {isNull, isNullOrUndefined} from 'util';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {ProviderGlobalFilter} from '../models/provider-global-filter';
import {Permission} from '../../helpers/permission';
import {SharedService} from '../../shared/services/shared.service';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {ProviderAppData} from '../provider-app-data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {AppData} from '../../helpers/app-data';
import {TrialContactsService} from 'src/app/trial/service/trial-contacts.service';
import {RollbackService} from '../../shared/services/rollback.service';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {AttachedToEntityEnum} from '../../data-comment/entity/attached-to-entity.enum';
import {RhImportComponent} from "../../human-resources/rh-import/rh-import.component";
import {PropertyEnum} from "../../dynamic-config/entity/property-enum";
import {ProviderImportComponent} from "../provider-import/provider-import.component";

@Component({
  selector: 'ih-provider-list',
  templateUrl: './provider-list.component.html',
  styleUrls: ['./provider-list.component.css']
})
export class ProviderListComponent extends PaginatorTableComponent<Provider> implements OnInit, OnDestroy {

  _entity = AttachedToEntityEnum.Provider;
  _target = DynamicDefinitionEnum.PROVIDER_MODULE;
  target = DynamicDefinitionEnum.PROVIDER_LIST_TABLE;

  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  providerTypesList: SelectItem[];
  filters: ProviderGlobalFilter = new ProviderGlobalFilter();
  enableProvidersImport = false;

  constructor(
      private trialContactService: TrialContactsService,
      private router: Router,
      private dataService: DataService,
      private providerService: ProviderService,
      private rollbackService: RollbackService<ProviderGlobalFilter>,
      private cfr: ComponentFactoryResolver,
      private sharedService: SharedService,
      private dynamicConfigService: DynamicConfigService
  ) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.rollbackService.hasGlobalFilter('providerGlobalFilter')) {
      this.filters = this.rollbackService.getGlobalFilter('providerGlobalFilter');
    } else {
      this.filters = new ProviderGlobalFilter();
    }
    this.providerTypesList = this.initializeItems();
    this.trialContactService.getTrialProviderTypesAndOthers().subscribe((providerTypesList: ProviderTypeDto[]) => {
      for (const element of providerTypesList) {
        this.providerTypesList.push({label: element.nameFr, value: element.code});
      }
    });
    this.initProperties();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.enableProvidersImport = this.dynamicConfigService.getProperty(PropertyEnum.providersImportEnabled);
    });
    this.initTableConfig();
  }

  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, ProviderAppData.tableConfig).subscribe(
        config => {
          this.config = config;
          this.buildTableHeaders();
        }
    );
  }

  displayImportProviders() {
    const formComponent = ProviderImportComponent.display(this.formDialogContainer, this.cfr);
    formComponent.importProviderDialogueVisible = true;
  }

  buildTableHeaders(): void {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('providerTypeValue')
        .filterable()
        .filter(this.filters.providerTypeValue)
        .filterType(FilterType.DROPDOWN)
        .options(this.providerTypesList)
        .sortable()
        .sortField('prom')
        .type(null)
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('name')
        .filterable()
        .filter(this.filters.name)
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('totalContactableMemberNames')
        .filterable()
        .filter(this.filters.totalContactableMemberNames)
        .filterType(FilterType.INPUT_TEXT)
        .sortable()
        .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
        .field('totalContactableMemberFunctions')
        .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  getExportPageModule(): string {
    return 'src/app/provider/provider-list/provider-list.component';
  }

  getDefaultSortField(): string {
    return 'name';
  }

  getAddTitle(): string {
    return 'MODULE_PROVIDERS_GENERAL_ADD_NEW';
  }

  getUpdateTitle(): string {
    return 'MODULE_PROVIDERS_GENERAL_UPDATE';
  }

  getDeleteMessage(): string {
    return 'MODULE_PROVIDERS_DIALOG_WARNING_PROVIDER_WILL_BE_DELETED';
  }

  getService(): any {
    return this.providerService;
  }

  getList(filters?: any) {
    this.filters = DynamicTableHeader.buildFilterData(this.headers);
    this.rollbackService.saveGlobalFilter('providerGlobalFilter', this.filters);
    this.providerService.loadList(this.page, this.limit, this.sortBy, this.orderBy, this.filters)
        .subscribe((providers: ResponsePage<Provider>) => {
          this.values = providers.content;
          this.totalNumber = providers.totalElements;
          this.providerService.setPaginationConfiguration(providers, this.sortBy, this.orderBy);
        });
  }

  displayFormDialogue(provider?: Provider) {
    let formComponent;
    formComponent = ProviderFormComponent.display(this.formDialogContainer, this.cfr);
    const providerToEdit: Provider = provider ? Object.assign({}, provider) : provider;
    formComponent.displayPromoterTypeFields = true;
    formComponent.setProvider(providerToEdit);
    formComponent.onAdd((newProvider: Provider) => {
      const index = this.values.indexOf(provider);
      if (index !== -1) {
        this.values[index].name = newProvider.name;
        this.values[index].providerTypeValue = newProvider.providerTypeValue;
        this.values[index].totalContactableMemberNames = newProvider.totalContactableMemberNames;
        if (!isNullOrUndefined(newProvider.promoterType)) {
          this.values[index].promoterType = newProvider.promoterType;
        }
      } else {
        this.changeProviderInfos(newProvider);
        this.values.push(newProvider);
      }
      this.values.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    });
  }

  changeProviderInfos(provider: Provider) {
    if (provider.providerType.nameFr === 'Promoteur' && !isNull(provider.promoterType)) {
      provider.providerTypeValue = provider.providerType.nameFr + ' (' + provider.promoterType.nameFr + ')';
    } else {
      provider.providerTypeValue = provider.providerType.nameFr;
    }
  }

  refreshAfterDelete(entity?: any): void {
    this.sharedService.setInformationInLocalStorage('Prestataires', 'Supprimer', entity.name);
    const index = this.values.findIndex((provider: Provider) => provider.hhhId === entity.hhhId);
    this.values.splice(index, 1);
  }

  getContacts(provider: Provider) {
    this.dataService.setContactData(provider.name, 'Provider', '/assets/images/providers.png', provider.hhhId, provider.publicCommentCount);
    this.router.navigate(['/contact/' + provider.hhhId + '/Provider']).then();
  }

  hasWritePermission() {
    return localStorage.getItem('Provider_READ_WRITE') === Permission.READ_WRITE;
  }


  exportProviderList(event: any): void {
    const request = new ExportRequestBuilder<ProviderGlobalFilter>()
        .contextId(event.contextId)
        .socketId(AppData.socketId)
        .target(this.target)
        .exportType(event.exportType)
        .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
        .sortBy(this.sortBy)
        .orderBy(this.orderBy)
        .filters(this.filters)
        .build();
    this.providerService.exportProviderList(request).subscribe(
        (res) => {
        },
        (err) => {
          console.error(err);
        });
  }


}

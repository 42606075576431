import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {DataService} from '../../../../shared/services/data-service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-theoretical-calendar-container',
  templateUrl: './theoretical-calendar-container.component.html',
  styleUrls: ['./theoretical-calendar-container.component.css']
})
export class TheoreticalCalendarContainerComponent implements OnInit, OnDestroy {

  syntheticTableEnabled = true;

  trialHhhId: number;
  items: MenuItem[];

  constructor(
    private router: Router,
    private dataService: DataService,
    private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialHhhId = url.queryParams.hhhId;
    }
  }

  ngOnInit() {
    this.initProperties();
    const queryParams = {
      hhhId: this.trialHhhId,
    };
    this.items = [];
    this.items.push({
      label: 'MODULE_TRIALS_THEORETICAL_CALENDARS_SYNTHETIC_CALENDAR',
      routerLink: ['/trial-details/theoretical-calendar/synthetic-theoretical-calendar'],
      queryParams
    });

    this.items.push({
      label: 'MODULE_TRIALS_THEORETICAL_CALENDARS_DETAILED_CALENDAR',
      routerLink: ['/trial-details/theoretical-calendar/detailed-theoretical-calendar'],
      queryParams
    });
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.syntheticTableEnabled = this.dynamicConfigService.getProperty(PropertyEnum.syntheticTableEnabled);
    });
  }

  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

}

export enum FilterType {
  INPUT_TEXT = 'inputText',
  INPUT_TEXT_NUMBER = 'inputTextNumber',
  DROPDOWN = 'dropdown',
  IH_DROPDOWN = 'ihDropdown',
  CUSTOM_DROPDOWN = 'customDropdown',
  CALENDAR = 'calendar',
  IH_CALENDAR = 'ihCalendar',
  CHECKBOX = 'checkbox',
  INPUT_SWITCH = 'inputSwitch',
  IH_MULTISELECT = 'ihMultiSelect',
  COMPOSED_DATE = 'composedDate'
}

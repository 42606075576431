export class PharmacyDispensationKit {
  hhhId: number;
  kit: string;
  schedulerNumber: number;
  dispensationDate: Date;
  returnDate: Date;
  prescriberHhhId?: number;
  prescriberName?: string;
  patientHhhId?: number;
  patientName?: string;
  visitName?: string;
  visitHhhId?: number;
  dispensedQuantity: string;
  returnedQuantity: string;
  lotHhhId?: number;
  lotNumber?: string;
  authorName?: string;
  authorHhhId?: number;
  destructionDate?: Date;
  initialQuantity?: string;
  processingUnit?: string;
  pharmaceuticalFormHhhId?: number;
  pharmaceuticalFormName?: string;

}

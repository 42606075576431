import {Component, ComponentFactoryResolver, OnDestroy, OnInit} from '@angular/core';
import {UsersService} from '../../admin/services/users.service';
import {UserAttempt} from '../user-attempt';
import {ResponsePage} from '../../shared/entity/response-page';
import {PaginatorTableComponent} from '../../shared/component/paginator-table/paginator-table.component';
import {Router} from '@angular/router';
import {DataService} from '../../shared/services/data-service';
import {UserAttemptGlobalFilter} from '../user-attempt-global-filter';
import {DynamicTableHeader} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header';
import {DynamicTableHeaderBuilder} from '../../dynamic-config/exported/dynamic-lazy-table/dynamic-table-header-builder';
import {FilterType} from '../../dynamic-config/exported/dynamic-lazy-table/filter-type';
import {AppData} from '../../helpers/app-data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {TableConfig} from '../../dynamic-config/entity/table-config';
import {GlobalConfigAppData} from '../../dynamic-config/gloabl-config-app-data';
import {ExportRequestBuilder} from '../../dynamic-config/entity/export-request-builder';
import {UserAttemptsService} from '../../admin/services/user-attempts.service';

@Component({
  selector: 'ih-user-attempt-list',
  templateUrl: './user-attempt-list.component.html',
  styleUrls: ['./user-attempt-list.component.css']
})
export class UserAttemptListComponent extends PaginatorTableComponent<UserAttempt> implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.USER_ATTEMPTS_LIST;
  config: TableConfig;
  headers: DynamicTableHeader[] = [];

  userAttempts: UserAttempt[];
  userAttemptGlobalFilter: UserAttemptGlobalFilter;

  constructor(private dataService: DataService,
              private usersService: UsersService,
              private cfr: ComponentFactoryResolver,
              private userAttemptsService: UserAttemptsService,
              private dynamicConfigService: DynamicConfigService) {
    super(dataService, cfr);
  }

  ngOnInit() {
    super.ngOnInit();
    this.userAttemptGlobalFilter = new UserAttemptGlobalFilter();
    this.initTableConfig();
  }


  initTableConfig(): void {
    this.dynamicConfigService.getTableConfig(this.target, GlobalConfigAppData.tableConfig).subscribe(
      config => {
        this.config = config;
        this.buildTableHeaders();
      }
    );
  }


  getList(filters?: any) {
    const userAttemptGlobalFilter: UserAttemptGlobalFilter = DynamicTableHeader.buildFilterData(this.headers);
    this.userAttemptsService.loadList(this.page, this.limit, this.sortBy, this.orderBy, userAttemptGlobalFilter).subscribe((userAttempts: ResponsePage<UserAttempt>) => {
      this.totalNumber = userAttempts.totalElements;
      this.userAttempts = userAttempts.content;
      this.userAttemptsService.setPaginationConfiguration(userAttempts);
    });
  }

  buildTableHeaders() {
    const availableHeaders = [];
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('username')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.userAttemptGlobalFilter.username)
      .sortable()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('lastConnectionAttemptDate')
      .withDateFormatter()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('successed')
      .isTranslated()
      .build());
    availableHeaders.push(new DynamicTableHeaderBuilder()
      .field('remoteAddress')
      .filterable()
      .filterType(FilterType.INPUT_TEXT)
      .filter(this.userAttemptGlobalFilter.remoteAddress)
      .sortable()
      .build());
    this.headers = this.config.buildTable(availableHeaders);
  }

  export(event: any): void {
    const request = new ExportRequestBuilder<UserAttemptGlobalFilter>()
      .contextId(event.contextId)
      .socketId(AppData.socketId)
      .target(this.target)
      .exportType(event.exportType)
      .paginationCriteria(event, this.dynamicConfigService.getOrderedColumns(this.target), this.limit, this.page)
      .sortBy(this.sortBy)
      .orderBy(this.orderBy)
      .filters(this.userAttemptGlobalFilter)
      .build();
    this.userAttemptsService.exportUserAttempts(request).subscribe(
      (res) => {
      },
      (err) => {
        console.error(err);
      });
  }

}

import {AfterViewInit, Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[initialFocus]'
})
export class InitialFocusDirective implements OnInit {
  @Input() initialFocus = false;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    if (!this.initialFocus) { return; }
    this.elementRef.nativeElement.focus();
    this.elementRef.nativeElement.dirty = true;
  }
}

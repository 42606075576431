export enum PropertyEnum {
  // Global-Properties
  privatePostItEnabled = 'privatePostItEnabled',
  amendmentsEnabled = 'amendmentsEnabled',
  multiSiteEnabled = 'multiSiteEnabled',
  siteEnabled = 'siteEnabled',
  mobileApplicationEnabled = 'mobileApplicationEnabled',
  retroCessionInvoiceEnabled = 'retroCessionInvoiceEnabled',
  pharmacyEnabled = 'pharmacyEnabled',
  administrationConsoleEnabled = 'administrationConsoleEnabled',
  pageLockEnabled = 'pageLockEnabled',
  agendaAllUserAccessRHList = 'agendaAllUserAccessRHList',
  csetEnabled = 'csetEnabled',
  preInvoiceEnabled = 'preInvoiceEnabled',
  trialSecondUfEnabled = 'trialSecondUfEnabled',
  optionalEnabled = 'optionalEnabled',
  attachedToVisit = 'attachedToVisit',
  enableOngoingInvoices = 'enableOngoingInvoices',
  enableTrialImport = 'enableTrialImport',
  linkResponsibilityWithRole = 'linkResponsibilityWithRole',
  humanResourceMandatory = 'humanResourceMandatory',
  inclusionImportIGRMode = 'inclusionImportIGRMode',
  inclusionImportColMode = 'inclusionImportColMode',
  inclusionImportCJPMode = 'inclusionImportCJPMode',
  inclusionImportHEMode = 'inclusionImportHEMode',
  inclusionImportGHPSJMode = 'inclusionImportGHPSJMode',
  inclusionImportIGRJMode = 'inclusionImportIGRJMode',
  inclusionImportSaintLucMode = 'inclusionImportSaintLucMode',
  sigrecExportEnabled = 'sigrecExportEnabled',
  piramigExportEnabled = 'piramigExportEnabled',
  useIGRTutorialVideosEnabled = 'useIGRTutorialVideosEnabled',

  investigatorSpecialityLabelKey = 'investigatorSpecialityLabelKey',
  principalInvestigatorLabelKey = 'principalInvestigatorLabelKey',
  nipLabelKey = 'nipLabelKey',


  // HUMAN-RESOURCES MODULE
  committeeToPole = 'committeeToPole',
  humanRessourceImportEnabled   = 'humanRessourceImportEnabled',
  humanRessourceWithoutRoleEnabled = 'humanRessourceWithoutRoleEnabled',
  multiEstablishmentEnabled = 'multiEstablishmentEnabled',

  // PROVIDER MODULE
  financialEntitiesEnabled = 'financialEntitiesEnabled',
  providersImportEnabled = 'providersImportEnabled',
  promoterTypeEnabled = 'promoterTypeEnabled',

  // PATIENT MODULE
  patientModificationRestrictedOnAdminsOnly = 'patientModificationRestrictedOnAdminsOnly',
  patientFilesEnabled = 'patientFilesEnabled',
  addPatientEnabled = 'addPatientEnabled',
  onlyTecCanAdd = 'onlyTecCanAdd',
  patientMaritalNameEnabled = 'patientMaritalNameEnabled',
  importPatientsFileName = 'importPatientsFileName',
  inclusionSiteIsTrialSiteForExportEnabled = 'inclusionSiteIsTrialSiteForExportEnabled',

    // INVOICE MODULE
  invoiceTrialByProtocolNumber = 'invoiceTrialByProtocolNumber',
  invoiceProtocolNumberEnabled = 'invoiceProtocolNumberEnabled',
  enablePreInvoicePriceEditing = 'enablePreInvoicePriceEditing',
  enableAddActFromValidatedInvoice = 'enableAddActFromValidatedInvoice',
  csetLabelKey = 'csetLabelKey',
  manualStaticInvoiceTotalAmountEnabled = 'manualStaticInvoiceTotalAmountEnabled',
  recipesExportEnabled = 'recipesExportEnabled',
  AnnexExportEnabled = 'AnnexExportEnabled',
  useTVAForTotalEnabled = 'useTVAForTotalEnabled',


  // ADDITIONAL COSTS
  additionalCostCodeEnabled = 'additionalCostCodeEnabled',
  additionalCostCodeNumeric = 'additionalCostCodeNumeric',
  counterPartEnabled = 'counterPartEnabled',
  operationalActEnabled = 'operationalActEnabled',
  contractTypes = 'contractTypes',
  NumericSecondInternalAccountEnabled = 'NumericSecondInternalAccountEnabled',
  NumericFirstSegmentEnabled = 'NumericFirstSegmentEnabled',

  // TRIAL MODULE
  organFamilyEnabled = 'organFamilyEnabled',
  trialEstablishmentDisabled = 'trialEstablishmentDisabled',
  phaseComplementEnabled = 'phaseComplementEnabled',
  trialServiceMandatory = 'trialServiceMandatory',
  trialCoInvestigatorsEnabled = 'trialCoInvestigatorsEnabled',
  multipleTheoreticalInvoiceDateEnabled = 'multipleTheoreticalInvoiceDateEnabled',
  designEnabled = 'designEnabled',
  coInvestigatorsFromPersonnelEnabled = 'coInvestigatorsFromPersonnelEnabled',
  investigatorDoctors = 'investigatorDoctors',
  specificReferrerInvestigatorEnabled = 'specificReferrerInvestigatorEnabled',
  referrerInvestigatorContainsSeveralResponsibilitiesEnabled = 'referrerInvestigatorContainsSeveralResponsibilitiesEnabled',
  referrerInvestigatorIsPIEnabled = 'referrerInvestigatorIsPIEnabled',
  dependsOnMolecularPreScreening = 'dependsOnMolecularPreScreening',
  separateInclusionPatientNameEnabled = 'separateInclusionPatientNameEnabled',
  defaultTrialInMultiSite = 'defaultTrialInMultiSite',
  disciplineEnabled = 'disciplineEnabled',
  linkMethodologyWithTrial = 'linkMethodologyWithTrial',
  versioningEnabled = 'versioningEnabled',
  trialCommon = 'trialCommon',
  visitReferenceEnabled = 'visitReferenceEnabled',
  enableRandomizationDateReference = 'enableRandomizationDateReference',
  visitValidPositivePeriodEnabled = 'visitValidPositivePeriodEnabled',
  designArmInputText = 'DesignArmInputText',
  ircMultiSelect= 'ircMultiSelect',
  visitStateList = 'visitStateList',
  updateStatusForMultipleVisits = 'updateStatusForMultipleVisits',
  trialActIsUsedOnlyOnce = 'trialActIsUsedOnlyOnce',
  financeViewEnabled = 'financeViewEnabled',
  ivrsEnabled = 'ivrsEnabled',
  iwrsEnabled = 'iwrsEnabled',
  completedAndInvoiceableVisitsAndActsEnabled = 'completedAndInvoiceableVisitsAndActsEnabled',
  additionalVisitsEnabled = 'additionalVisitsEnabled',
  saveNowSelectedByDefault = 'saveNowSelectedByDefault',
  syntheticTableEnabled = 'syntheticTableEnabled',
  canMatchOAwithVisit = 'canMatchOAwithVisit',
  useDropdownWhenSelectArms = 'useDropdownWhenSelectArms',
  theoreticalVisitMarginEnabled = 'theoreticalVisitMarginEnabled',
  inclusionInformationArmEnabled = 'inclusionInformationArmEnabled',
  additionalTestExportEnabled = 'additionalTestExportEnabled',
  inclusionCalendarSavingButtonApart = 'inclusionCalendarSavingButtonApart',
  showDateOrPriceEnabled = 'showDateOrPriceEnabled',
  visitActRealisationDateEnabled = 'visitActRealisationDateEnabled',
  invoiceablePaidVisitStateDisabled = 'invoiceablePaidVisitStateDisabled',
  inclusionNumberEnabled = 'inclusionNumberEnabled',
  inclusionDetailPatientNameWithIppEnabled = 'inclusionDetailPatientNameWithIppEnabled',
  idrcbDropdownListEnabled = 'idrcbDropdownListEnabled',
  importCalendarByProtocolNumberEnabled = 'importCalendarByProtocolNumberEnabled',
  dropdownAmendmentListEnabled = 'dropdownAmendmentListEnabled',
  textFieldAmendmentMotifEnabled = 'textFieldAmendmentMotifEnabled',
  displayConsentsInInclusionInfoEnabled = 'displayConsentsInInclusionInfoEnabled',
  fetchAllArmsEnabled = 'fetchAllArmsEnabled',
  multipleCrossoversEnabled = 'multipleCrossoversEnabled',
  patientVisitDuplicationEnabled = 'patientVisitDuplicationEnabled',
  shiftVisitsExceptAdditionalTestEnabled= 'shiftVisitsExceptAdditionalTestEnabled',
  fetchAllDoctorsInInclusionFormEnabled = 'fetchAllDoctorsInInclusionFormEnabled',
  ghtMontBlancCustomizationEnabled = 'ghtMontBlancCustomizationEnabled',
  invoiceMonitoringEnabled = 'invoiceMonitoringEnabled',
  forecastInclusionsEnabled = 'forecastInclusionsEnabled',
  defaultContractStatus = 'defaultContractStatus',
  allTecInIncludedTec = 'allTecInIncludedTec',
  specificCustomExportEnabled = 'specificCustomExportEnabled',
  conventionTypeIsRequiredToAddTrialFixedCostEnabled = 'conventionTypeIsRequiredToAddTrialFixedCostEnabled',

  // STATISTICS MODULE
  powerBiEnabled = 'powerBiEnabled',
  filterReportInclusionsPatientAgeColEnabled = 'filterReportInclusionsPatientAgeColEnabled',
  localIdentifierInIdentification = 'localIdentifierInIdentification',
  isoStatsEnabled = 'isoStatsEnabled'

}

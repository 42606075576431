import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {VisitTracking} from '../../../entity/trial-track/visit-tracking';
import {Router} from '@angular/router';
import {DataService} from '../../../../shared/services/data-service';
import {VisitTrackingFormComponent} from '../visit-tracking-form/visit-tracking-form.component';
import {VisitService} from '../../../service/visit.service';
import {Visit} from '../../../entity/visit';
import {TrialService} from '../../../services/trial.service';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../../../dynamic-config/entity/property-enum';
import {DataCommentInfo} from '../../../../data-comment/entity/data-comment';
import {AttachedToEntityEnum} from '../../../../data-comment/entity/attached-to-entity.enum';
import {DataCommentComponent} from '../../../../data-comment/component/data-comment/data-comment.component';
import {DataCommentEvent} from '../../../../data-comment/entity/DataCommentEvent';


@Component({
  selector: 'ih-visit-tracking',
  templateUrl: './visit-tracking.component.html',
  styleUrls: ['./visit-tracking.component.css']
})
export class VisitTrackingComponent implements OnInit {

  updateStatusForMultipleVisits = false;

  @ViewChild('popupDialog', {read: ViewContainerRef}) popupContainer: ViewContainerRef;
  @ViewChild('displayCommentDialog', {read: ViewContainerRef}) postitPopupContainer: ViewContainerRef;

  @Input() visit: VisitTracking;
  @Input() inclusionHhhId: number;
  visitDetails: Visit = new Visit();
  disabledVisitDetailButton = false;
  isVisitChecked = false;

  constructor(
      private router: Router,
      private cfr: ComponentFactoryResolver,
      private dataService: DataService,
      private visitService: VisitService,
      public trialService: TrialService,
      private dynamicConfigService: DynamicConfigService) {
  }

  ngOnInit() {
    this.initProperties();
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.updateStatusForMultipleVisits = this.dynamicConfigService.getProperty(PropertyEnum.updateStatusForMultipleVisits);
    });
  }

  navigateToVisit(item: VisitTracking) {
    const url = this.router.parseUrl(this.router.url);
    const trialHhhId = url.queryParams.hhhId;
    this.router.navigate(['/trial-details/inclusion-details/calendar'],
        {
          queryParams: {
            hhhId: trialHhhId,
            inclusionId: this.inclusionHhhId
          }
        }).then();
    this.dataService.changeVisit(item.hhhId);
  }

  getVisitDetails() {
    this.disabledVisitDetailButton = true;
    this.visitService.getVisitDetails(this.visit.hhhId).subscribe(res => {
      this.visitDetails = res;
      const popupDialogFactory = this.cfr.resolveComponentFactory(VisitTrackingFormComponent);
      const popupDialogComponentRef = this.popupContainer.createComponent(popupDialogFactory);
      const visitTrackingFormComponent = popupDialogComponentRef.instance;
      visitTrackingFormComponent.inclusionHhhId = this.inclusionHhhId;
      visitTrackingFormComponent.visit = this.visitDetails;
      this.disabledVisitDetailButton = false;
      visitTrackingFormComponent.onUpdate(visitUpdated => {
        this.visit.visitDate = visitUpdated.visitDate;
        this.visit.expectedVisitDate = visitUpdated.expectedVisitDate;
        this.visit.visitState.hhhId = visitUpdated.visitStateHhhId;
        this.visit.visitState.name = this.visitService.getVisitStateName(visitUpdated.visitStateHhhId);
      });
    }, error => {
      console.error(error);
      this.disabledVisitDetailButton = false;
    });
  }

  displayDataCommentDialog() {
    if (!this.visit || !this.visit.hhhId) {
      return;
    }
    const dataCommentInfo = new DataCommentInfo(AttachedToEntityEnum.Visit, this.visit.hhhId, 'trackingTableVisit', false);
    const dataCommentComponent = DataCommentComponent.createDataComment(dataCommentInfo, this.popupContainer, this.cfr);
    dataCommentComponent.onSaveOrDelete((dataCommentEvent) => this.updateCommentCount(dataCommentEvent));
  }

  updateCommentCount(dataCommentEvent: DataCommentEvent): void {
    this.visit.publicCommentCount = dataCommentEvent.publicCommentCount;
  }

  getColor(visitState: string) {
    switch (visitState) {
      case 'Visite à programmer':
        return '#ff4540'; // bleu
      case 'Visite programmée':
        return '#000000'; // bleu ciel
      case 'Visite réalisée à saisir':
      case 'Visite réalisée':
        return '#000000'; // vert
      case 'Visite saisie incomplète':
        return '#000000'; // vert bistache
      case 'Visite saisie':
        return '#000000'; // jaune
      case 'Visite monitorée':
        return '#000000'; // jaune  orange
      case 'Visite facturable':
        return '#000000'; // jaune  orange
      case 'Facturée partiellement':
        return '#000000'; // rouge
      case 'Facturée':
        return '#000000'; // rose fouchia
      case 'Payée partiellement':
        return '#000000'; // violet
      case 'Payée':
        return '#000000'; // violet  bleu
      case 'Visite non réalisée':
        return '#000000'; // grey
    }
  }

  getBackgroundColor(visitState: string) {
    switch (visitState) {
      case 'Visite à programmer':
        return '#eee'; // bleu
      case 'Visite programmée':
        return '#6aa0f3'; // bleu ciel
      case 'Visite réalisée à saisir':
      case 'Visite réalisée':
        return '#bc0c0c'; // vert
      case 'Visite saisie incomplète':
        return '#ffc000 '; // vert bistache
      case 'Visite saisie':
        return '#ccffcc'; // jaune
      case 'Visite monitorée':
        return '#9bc2e6'; // jaune  orange
      case 'Visite facturable':
        return '#eac1e1'; // orange
      case 'Facturée partiellement':
        return '#cc99ff'; // rouge
      case 'Facturée':
        return '#f772be'; // violet
      case 'Payée partiellement':
        return '#70d4a0'; // violet
      case 'Payée':
        return '#13b72c'; // violet  bleu
      case 'Visite non réalisée':
        return 'grey';
      case 'Non Applicable':
        return '#ede909';
    }


  }

  getBorderColor(visitState: string) {
    switch (visitState) {
      case 'Visite à programmer':
        return '#ff4540'; // bleu
      case 'Visite programmée':
        return '#6aa0f3'; // bleu ciel
      case 'Visite réalisée à saisir':
      case 'Visite réalisée':
        return '#bc0c0c'; // vert
      case 'Visite saisie incomplète':
        return '#ffc000'; // vert bistache
      case 'Visite saisie':
        return '#ccffcc'; // jaune
      case 'Visite monitorée':
        return '#9bc2e6'; // jaune  orange
      case 'Visite facturable':
        return '#eac1e1'; // orange
      case 'Facturée partiellement':
        return '#cc99ff'; // rouge
      case 'Facturée':
        return '#f772be'; // violet
      case 'Payée partiellement':
        return '#70d4a0'; // violet
      case 'Payée':
        return '#13b72c'; // violet  bleu
      case 'Visite non réalisée':
        return 'grey'; // grey
    }
  }

  updateCheckedVisits() {
    if (this.isVisitChecked) {
      this.visitService.addVisitToVisitListToBeUpdated(this.visit.hhhId);
    } else {
      this.visitService.removeVisitFromVisitListToBeUpdated(this.visit.hhhId);
    }
  }
}

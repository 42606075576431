import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Message} from '../models/message';
import {Doctor} from '../models/doctor';
import {TrialsManagementService} from '../services/trials-management.service';

@Component({
  selector: 'app-chat-dialog-form',
  templateUrl: './chat-dialog-form.component.html',
  styleUrls: ['./chat-dialog-form.component.css']
})
export class ChatDialogFormComponent implements OnInit, OnChanges {
  @Input() doctor: Doctor;
  @Input() messages: Message[];
  @Input() trialHhhId: number;
  messageToSend: string;
  connectedUserHhhId: string;

  nbrOfScrolls = 0;

  constructor(private trialsManagementService: TrialsManagementService) {
  }

  ngOnInit() {
    this.connectedUserHhhId = localStorage.getItem('authenticatedUserID');
  }

  submitMessage() {
    const message = new Message();
    message.content = this.messageToSend;
    message.emitterHhhId = Number(localStorage.getItem('authenticatedUserID'));
    message.emitter = localStorage.getItem('authenticatedUser');
    message.trialHhhId = this.trialHhhId;
    message.receiverHhhId = this.doctor.hhhId;
    message.creationDate = new Date();
    this.trialsManagementService.sendMessage(message).subscribe(() => {
      this.messageToSend = '';
    });
  }

  // this method needs to be fired only on the first discussion check.
  downToLastMessages(e) {
    if (this.nbrOfScrolls === 0) {
      e.target.scrollTop = e.target.scrollHeight;
      this.nbrOfScrolls++;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.nbrOfScrolls = 0;
  }
}

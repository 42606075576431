import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {ResponsePage} from '../../shared/entity/response-page';
import {UserAttempt} from '../../user-attempts/user-attempt';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {UserAttemptGlobalFilter} from '../../user-attempts/user-attempt-global-filter';
import {Observable, throwError} from 'rxjs';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {catchError, map} from 'rxjs/operators';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {AuditLog} from '../models/audit-log';
import {AuditLogGlobalFilter} from '../models/audit-log-global-filter';
import {DatePipe} from '@angular/common';
import {LabelValue} from "../../shared/entity/label-value";

@Injectable({
  providedIn: 'root'
})

export class AuditLogService {

  public host = new Properties().host + '/audit-log';
  public dataPage: ResponsePage<AuditLog>;

  constructor(private httpClient: HttpClient, private datePipe: DatePipe) {
  }

  loadList(page: number, limit: number, sortBy: string, orderBy: string, userAttemptGlobalFilter: AuditLogGlobalFilter, fromDate: Date, toDate: Date): Observable<ResponsePage<AuditLog>> {
    return this.httpClient.post<ResponsePage<AuditLog>>(
      this.host + `/load-list`,
      new RequestPageBuilder<AuditLogGlobalFilter>()
        .page(page)
        .limit(limit)
        .sortBy(sortBy)
        .orderBy(orderBy)
        .filters(userAttemptGlobalFilter)
        .fromDate(fromDate != null ? this.datePipe.transform(fromDate, 'dd/MM/yyyy') : '')
        .toDate(toDate != null ? this.datePipe.transform(toDate, 'dd/MM/yyyy') : '')
        .build()
    ).pipe(map(result => {
        try {
          result.content = result.content.map(log => new AuditLog().init(log));
          return result;
        } catch (e) {
          console.error(e);
        }
      }),
      catchError(this.handleError)
    );
  }

  exportLogs(request: ExportRequest<AuditLogGlobalFilter>): Observable<void> {
    return this.httpClient.post<void>(this.host + '/export-csv', request).pipe();
  }


  setPaginationConfiguration(userAttempts: ResponsePage<AuditLog>) {
    this.dataPage = userAttempts;
  }

  private handleError(errorResponse: HttpErrorResponse): Observable<any> {
    return throwError(errorResponse.error);
  }

}

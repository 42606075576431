import {Component, OnDestroy, OnInit} from '@angular/core';
import {StatisticsService} from '../services/statistics.service';
import {TrialReportFilter} from '../models/trial-report-filter';
import {SelectItem} from 'primeng/api';
import {Data} from '../../shared/entity/data';
import {DataService} from '../../shared/services/data-service';
import {FR} from '../../shared/entity/calendar-language';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {TranslateService} from '@ngx-translate/core';
import {TrialsCreation} from './models/trials-creation';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';
import {StatisticsAppData} from '../statistics-app-data';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {FormConfig} from '../../dynamic-config/entity/form-config';

@Component({
  selector: 'ih-follow-trials',
  templateUrl: './follow-trials.component.html',
  styleUrls: ['./follow-trials.component.css']
})
export class FollowTrialsComponent implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.STATISTICS_FOLLOW_TRIALS_FILTER_FORM;
  config: FormConfig = new FormConfig();
  multiSiteEnabled = false;

  stateTrials: any;
  organFamilyTrials: any;
  trialStartedData: any;
  trialCountStateLabels = [];
  trialCountStateBackgroundColor = [];
  trialOrganFamilyLabels = [];
  trialOrganFamilyBackgroundColor = [];
  blinded: SelectItem[] = Data.yesOrNo;
  trialReportFilter: TrialReportFilter = new TrialReportFilter();
  locale = FR;
  filterBy: SelectItem[];
  options: any = Data.pieAndDoughnutChartOptions;
  barChartOptions: any = Data.barChartOptions;

  constructor(
    private statisticsService: StatisticsService,
    private translate: TranslateService,
    private translateService: InnohealthTranslateService,
    private dataService: DataService,
    private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    this.initProperties();
    this.dataService.setExportPageModule('src/app/statistics/follow-trials/follow-trials.component');
    this.translate.stream('key').subscribe(() => {
      this.chooseFilterBy();
      this.getReportTrialsTotalByMonth();
    });
  }

  initProperties() {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.multiSiteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
    });
    this.initFormConfig();
  }

  initFormConfig() {
    this.dynamicConfigService.getFormConfig(this.target, StatisticsAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);


  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  resetFilter() {
    this.trialReportFilter = new TrialReportFilter();
    this.getReportTrialsTotalByMonth();
  }

  getReportTrialsTotalByMonth() {
    if (!this.trialReportFilter.launchDateInter) {
      this.trialReportFilter.launchDateInter = 'Month';
      this.trialReportFilter.fromDate = new Date(new Date().getFullYear(), 0, 1);
      this.trialReportFilter.toDate = new Date(new Date().getFullYear(), 11, 31);
    }
    this.statisticsService.getReportTrialsTotalByMonth(this.trialReportFilter).subscribe(report => {
      report.trialsCreation.sort((a, b) => (a.creationDate > b.creationDate ? 1 : -1));
      if (!report.trialCountState) {
        report.trialCountState = [];
      }
      this.trialCountStateBackgroundColor = [];
      this.trialCountStateLabels = [];
      const trialCountStateData = [];
      for (let i = 0; i < report.trialCountState.length; i++) {
        const trialCountState = report.trialCountState[i];
        this.trialCountStateLabels.push(this.translateService.getTranslationString(trialCountState.state));
        trialCountStateData.push(trialCountState.trialCount);
        this.trialCountStateBackgroundColor.push(this.statisticsService.getColor(i));
      }
      this.statisticsService.setTrialReportFilter(this.trialReportFilter);
      this.stateTrials = {
        labels: this.trialCountStateLabels,
        datasets: [
          {
            data: trialCountStateData,
            backgroundColor: this.trialCountStateBackgroundColor,
            hoverBackgroundColor: this.trialCountStateBackgroundColor
          }]
      };
      const trialCreationLabels = [];
      const trialCreationData = [];
      const trialCreationBackgroundColor = [];
      for (let i = 0; i < report.trialsCreation.length; i++) {
        let label = '';
        const trialCreation = report.trialsCreation[i];
        label = this.createLabel(trialCreation);
        console.log(label);
        trialCreationLabels.push(this.translateService.getTranslationString(label));
        trialCreationData.push(trialCreation.nbTrial);
        trialCreationBackgroundColor.push(this.statisticsService.getColor(i));
      }
      trialCreationData.push(0);
      this.trialStartedData = {
        labels: trialCreationLabels,
        datasets: [
          {
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: trialCreationData
          }
        ]
      };
      this.trialOrganFamilyLabels = [];
      const trialOrganFamilyData = [];
      this.trialOrganFamilyBackgroundColor = [];
      for (let i = 0; i < report.trialsOrganFamily.length; i++) {
        const trialOrganFamily = report.trialsOrganFamily[i];
        this.trialOrganFamilyLabels.push(trialOrganFamily.organFamily);
        trialOrganFamilyData.push(trialOrganFamily.trialCount);
        this.trialOrganFamilyBackgroundColor.push(this.statisticsService.getColor(i));
      }

      this.organFamilyTrials = {
        labels: this.trialOrganFamilyLabels,
        datasets: [
          {
            data: trialOrganFamilyData,
            backgroundColor: this.trialOrganFamilyBackgroundColor,
            hoverBackgroundColor: this.trialOrganFamilyBackgroundColor
          }]
      };
    });
  }

  createLabel(trialCreation: TrialsCreation) {
    const month = trialCreation.creationDate.substring(5, 7);
    let label = '';
    const year = trialCreation.creationDate.substring(2, 4);
    if (this.trialReportFilter.launchDateInter === 'Year') {
      label = year;
    } else if (this.trialReportFilter.launchDateInter === 'Month') {
      switch (month) {
        case '01':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_JANUARY') + '. ' +
            year;
          break;
        case '02':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_FEBRUARY') + '. ' +
            year;
          break;
        case '03':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_MARS') + '. ' +
            year;
          break;
        case '04':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_APRIL') + '. ' +
            year;
          break;
        case '05':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_MAY') + '. ' +
            year;
          break;
        case '06':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_JUNE') + '. ' +
            year;
          break;
        case '07':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_JULY') + '. ' +
            year;
          break;
        case '08':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_AUGUST') + '. ' +
            year;
          break;
        case '09':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_SEPTEMBER') + '. ' +
            year;
          break;
        case '10':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_OCTOBER') + '. ' +
            year;
          break;
        case '11':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_NOVEMBER') + '. ' +
            year;
          break;
        case '12':
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_DECEMBER') + '. ' +
            year;
          break;
      }
    } else {
      label = this.trialReportFilter.launchDateInter + ' ' + year;
    }
    return label;
  }

  isParent() {
    return this.dynamicConfigService.parent;
  }

  private chooseFilterBy() {
    this.filterBy = [
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_YEAR'), value: 'Year'},
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_MONTH'), value: 'Month'},
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_TRIMESTER_1'), value: 'T1'},
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_TRIMESTER_2'), value: 'T2'},
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_TRIMESTER_3'), value: 'T3'},
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_TRIMESTER_4'), value: 'T4'}
    ];
  }
}

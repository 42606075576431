import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {InvoiceListComponent} from "../invoice/invoice-list/invoice-list.component";
import {AuthGuard} from "../helpers/auth-guard.service";
import {InvoiceInformationComponent} from "../invoice/invoice-information/invoice-information.component";
import {InvoiceActsComponent} from "../invoice/invoice-acts/invoice-acts.component";
import {InvoiceFixedCostsComponent} from "../invoice/invoice-fixed-costs/invoice-fixed-costs.component";
import {InvoiceCounterPartsComponent} from "../invoice/invoice-counter-parts/invoice-counter-parts.component";
import {FollowInvoiceComponent} from "../invoice/follow-invoice/follow-invoice/follow-invoice.component";
import {TranslateModule} from "@ngx-translate/core";
import {RetrocessionFeesDetailsComponent} from "./retrocession-fees-details/retrocession-fees-details.component";
import {RetrocessionFeesContainerComponent} from "./retrocession-fees-container/retrocession-fees-container.component";
import {RequirePasswordUpdateGuard} from '../helpers/require-password-update-guard';


const routes: Routes = [
  {
    path: 'retrocession-fees-container', component: RetrocessionFeesContainerComponent, canActivate: [RequirePasswordUpdateGuard],
    children: [
      {path: '', redirectTo: 'retrocession-fees-list', pathMatch: 'full'},
      {path: 'retrocession-fees-list', component: InvoiceListComponent, canActivate: [AuthGuard]}
    ]
  },
  {
    path: 'retrocession-fees-details', component: RetrocessionFeesDetailsComponent, canActivate: [AuthGuard, RequirePasswordUpdateGuard],
    children: [{path: '', redirectTo: 'retrocession-fees-information', pathMatch: 'full'},
      {path: 'retrocession-fees-information', component: InvoiceInformationComponent, canActivate: [AuthGuard]},
      {path: 'retrocession-fees-acts', component: InvoiceActsComponent, canActivate: [AuthGuard]},
      {path: 'retrocession-fees-fixed-costs', component: InvoiceFixedCostsComponent, canActivate: [AuthGuard]},
      {path: 'retrocession-fees-counter-parts', component: InvoiceCounterPartsComponent, canActivate: [AuthGuard]}]
  },
  {path: 'follow-retrocession-fees', component: FollowInvoiceComponent, canActivate: [AuthGuard]}];

@NgModule({
  imports: [RouterModule.forChild(routes),
    TranslateModule],
  exports: [RouterModule]
})

export class RetrocessionFeesRoutingModule {
}

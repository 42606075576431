import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RollbackService<T> {

  constructor() {
  }

  private _isRollBack = false;

  get isRollBack(): boolean {
    return this._isRollBack;
  }

  set isRollBack(value: boolean) {
    this._isRollBack = value;
  }

  saveGlobalFilter(key: string, globalFilter: T) {
    localStorage.setItem(key, JSON.stringify(globalFilter));
  }

  getGlobalFilter(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  hasGlobalFilter(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }
}

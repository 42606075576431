import {JsonObject} from 'json2typescript';
import {FormInputConfig} from './form-input-config';
import {FormTitleConfig} from './form-title-config';

@JsonObject('FormConfig')
export class FormConfig {

  title: FormTitleConfig;
  fields: Map<string, FormInputConfig> = new Map<string, FormInputConfig>();

  constructor(title?: FormTitleConfig, fields?: Map<string, FormInputConfig>) {
    this.title = title;
    this.fields = fields;
  }

  getFieldConfig(fieldName: string): FormInputConfig {
    if (!this.fields || !this.fields.has(fieldName)) {
      return new FormInputConfig();
    }
    return this.fields.get(fieldName);
  }

  displayField(fieldName: string): boolean {
    if (!this.fields || !this.fields.has(fieldName)) {
      return false;
    }
    return this.fields.get(fieldName).display;
  }

  isFieldRequired(fieldName: string): boolean {
    if (!this.fields || !this.fields.has(fieldName)) {
      return false;
    }
    return this.fields.get(fieldName).required;
  }
}

import {Visit} from '../visit';
import {VisitInInclusion} from './visit-in-inclusion';

export class Calendar {
  public hhhId: number;
  public treatmentStartDate: Date;
  public visitsBeforeInclusion: Visit[] = [];
  public visitsInInclusion: VisitInInclusion[] = [];
  public visitsAfterInclusion: Visit[] = [];
  public isInclusionActive: boolean;
}

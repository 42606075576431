import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {ModuleHeaderConfig} from '../../entity/module-header-config';
import {PropertyEnum} from '../../entity/property-enum';

@Component({
  selector: 'ih-dynamic-toolbar',
  templateUrl: './dynamic-toolbar.component.html',
  styleUrls: ['./dynamic-toolbar.component.css']
})
export class DynamicToolbarComponent {

  _target: string;
  header: ModuleHeaderConfig;
  @Input() details: string;
  @Input() publicCommentCount: number;
  @Input() toolbarMode = true;

  @Output() returnBack = new EventEmitter<void>();
  @Output() displayDataCommentDialog = new EventEmitter<boolean>();

  hasPrivatePostIt = true;

  constructor(private dynamicConfigService: DynamicConfigService) {
  }

  @Input()
  set target(v: string) {
    this._target = v;
    this.initProperties();
  }

  get target(): string {
    return this._target;
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.hasPrivatePostIt = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled);
      this.getModuleConfig();
    });
  }


  getModuleConfig(): void {
    this.dynamicConfigService.getModuleConfig(this._target).subscribe(
        cf => {
          this.header = cf.header;
        }
    );
  }

  emitReturnBack(): void {
    this.returnBack.emit();
  }

  emitDataCommentEvent(isPrivate: boolean) {
    this.displayDataCommentDialog.emit(isPrivate);
  }
}

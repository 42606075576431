import {Injectable} from '@angular/core';
import {Properties} from '../../helpers/properties';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {User} from '../models/user';
import {RoleValue} from '../../human-resources/models/role-value';
import {AccessRight} from '../models/access-right';
import {NewPassword} from '../models/newPassword';
import {LabelValue} from '../../shared/entity/label-value';
import {UserAttempt} from '../../user-attempts/user-attempt';
import {ResponsePage} from '../../shared/entity/response-page';
import {UserAttemptGlobalFilter} from '../../user-attempts/user-attempt-global-filter';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {DatePipe} from '@angular/common';
import {FieldAccessRight} from '../models/field-access-right';
import {GlobalAccessRight} from '../models/global-acces-right';
import {HiddenFields} from '../models/HiddenFields';
import {SelectItem} from 'primeng/components/common/api';
import {Value} from '../../shared/entity/value';
import {ExportRequest} from '../../dynamic-config/entity/export-request';
import {Invoice} from '../../invoice/models/invoice';
import {RequestPageBuilder} from '../../dynamic-config/entity/request-page-builder';
import {InvoiceGlobalFilter} from '../../invoice/models/invoice-global-filter';
import {catchError, map} from 'rxjs/operators';
import {HumanResourceGlobalFilter} from '../../human-resources/models/human-resource-global-filter';

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    public properties: Properties = new Properties();
    public host = this.properties.host;
    public dataPage: ResponsePage<UserAttempt>;
    public exportedUsers: User[] = [];

    constructor(private httpClient: HttpClient) {
    }

    calculatePasswordStrength(password: string): number {
        let passwordScore = 0;
        if (/[A-Z]/.test(password)) {
            passwordScore += 20;
        }
        if (/[a-z]/.test(password)) {
            passwordScore += 20;
        }
        if (/\d/.test(password)) {
            passwordScore += 20;
        }
        if (/\W/.test(password)) {
            passwordScore += 20;
        }
        if (password.length >= 12) {
            passwordScore += 20;
        }
        return passwordScore;
    }

    isPasswordContainsUppercase(password: string): boolean {
      return /[A-Z]/.test(password);
    }

    isPasswordContainsLowercase(password: string): boolean {
      return /[a-z]/.test(password);
    }

    isPasswordContainsNumeric(password: string): boolean {
      return /\d/.test(password);
    }

    isPasswordContainsSpecialCharacter(password: string): boolean {
      return /\W/.test(password);
    }

    isPasswordLengthValid(password: string): boolean {
      return password.length >= 12;
    }


    getUsers(): Observable<User[]> {
        return this.httpClient.get<User[]>(this.host + '/admin/users');
    }

    addUser(user: User): Observable<any> {
        return this.httpClient.post<any>(this.host + '/admin/users/save ', user);
    }

    getRoles(): Observable<RoleValue[]> {
        return this.httpClient.get<RoleValue[]>(this.host + '/admin/roles');
    }

    changePassword(user: User): Observable<void> {
        return this.httpClient.post<void>(this.host + '/admin/users/savePassword', user);
    }

    getModuleAccess(hhhId: number): Observable<AccessRight[]> {
        return this.httpClient.get<AccessRight[]>(this.host + '/admin/users/accessRights/' + hhhId);
    }

    getFieldAccess(hhhId: number): Observable<FieldAccessRight[]> {
        return this.httpClient.get<FieldAccessRight[]>(this.host + '/field-access/user-access/' + hhhId);
    }

    getUser(hhhId: number): Observable<User> {
        return this.httpClient.get<User>(this.host + '/admin/user/' + hhhId);
    }

    getCurrentUser(): Observable<User> {
        return this.httpClient.get<User>(this.host + '/current-user');
    }


    updateUser(user: User) {
        return this.httpClient.put(this.host + '/admin/users/edit', user);
    }

    updateCurrentUser(user: User) {
        return this.httpClient.put(this.host + '/admin/current-user/edit', user);
    }

    changeCurrentUserPassword(password: NewPassword): Observable<void> {
        return this.httpClient.post<void>(this.host + '/admin/current-user/savePassword', password);
    }

    changeAccessRights(globalAccessRight: GlobalAccessRight): Observable<void> {
        return this.httpClient.post<void>(this.host + '/rest/admin/users/accessRights/change', globalAccessRight);
    }

    setExportedUser(users: User[]) {
        this.exportedUsers = users;
    }

    canAccessField(field: string): boolean {
        return localStorage.getItem('UserHidden_Fields_access').indexOf(field) !== -1;
    }

    canAccessFieldByValue(value: string): boolean {
        return localStorage.getItem('UserHidden_Fields_access').indexOf(this.getFieldNameForActiveModule(value)) !== -1;
    }

    getFieldNameForActiveModule(additionalCostType: string): string {
        switch (additionalCostType) {
            case 'ACT': {
                return HiddenFields.ACTE_PRICE;
            }
            case 'FIXED_COST' : {
                return HiddenFields.FIXED_COST_PRICE;
            }
            case 'COUNTER_PART': {
                return HiddenFields.COUNTER_PART_PRICE;
            }
            case 'OPERATIONAL_ACT': {
                return HiddenFields.OPERATIONAL_ACT_PRICE;
            }
        }
    }

    getRolesForResponsibilities(responabilities: number[]): Observable<Array<number>> {
        return this.httpClient.post<Array<number>>(this.host + '/admin/role-by-responsabilities', responabilities);
    }

    isUsernameAvailable(username: string): Observable<Value<boolean>> {
        return this.httpClient.post<Value<boolean>>(this.host + '/username-available' , username);
    }

    exportUsers(request: ExportRequest<void>): Observable<void> {
        return this.httpClient.post<void>(this.host + '/admin/users/export-csv', request).pipe();
    }

}

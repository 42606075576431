import {NgModule} from '@angular/core';
import {RetrocessionFeesContainerComponent} from './retrocession-fees-container/retrocession-fees-container.component';
import {RetrocessionFeesDetailsComponent} from './retrocession-fees-details/retrocession-fees-details.component';
import {RetrocessionFeesRoutingModule} from './retrocession-fees-routing.module';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  imports: [
    RetrocessionFeesRoutingModule,
    SharedModule,
  ],
  declarations: [
    RetrocessionFeesContainerComponent,
    RetrocessionFeesDetailsComponent
  ],

})
export class RetrocessionFeesModule {
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {StatisticsService} from '../services/statistics.service';
import {InclusionReportFilter} from './models/inclusion-report-filter';
import {ReportInclusionsTotalByMonth} from './models/report-inclusions-total-by-month';
import {TrialService} from '../../trial/services/trial.service';
import {SelectItem} from 'primeng/api';
import {DataService} from '../../shared/services/data-service';
import {Trial} from '../../trial/models/trial';
import {FR} from '../../shared/entity/calendar-language';
import {InnohealthTranslateService} from '../../shared/services/innohealth-translate.service';
import {TrialLocalIdentifier} from '../../trial/entity/theoretical-calendar/trial-local-identifier';
import {ProviderService} from '../../provider/services/provider.service';
import {Data} from '../../shared/entity/data';
import {TranslateService} from '@ngx-translate/core';
import {DynamicConfigService} from '../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../dynamic-config/entity/dynamic-definition-enum';
import {StatisticsAppData} from '../statistics-app-data';
import {FormConfig} from '../../dynamic-config/entity/form-config';
import {PropertyEnum} from '../../dynamic-config/entity/property-enum';

@Component({
  selector: 'ih-follow-inclusions',
  templateUrl: './follow-inclusions.component.html',
  styleUrls: ['./follow-inclusions.component.css']
})
export class FollowInclusionsComponent implements OnInit, OnDestroy {

  target = DynamicDefinitionEnum.STATISTICS_FOLLOW_INCLUSIONS_FILTER_FORM;
  config: FormConfig = new FormConfig();
  multiSiteEnabled = false;
  filterReportInclusionsPatientAgeColEnabled = false;
  localIdentifierInIdentification = false;


  inclusionsData: any;
  inclusionEstablishmentDepartmentData: any;
  inclusionsTrial: any;
  inclusionEstablishmentDepartmentLabels = [];
  inclusionEstablishmentDepartmentBackgroundColor = [];
  localIdentifier: TrialLocalIdentifier = new TrialLocalIdentifier();
  patientAge: SelectItem[];
  patientStates: SelectItem[];
  trial: Trial = new Trial();
  trials: Trial[] = [];
  yearMonth: SelectItem[] = [];
  options: any = Data.pieAndDoughnutChartOptions;
  moleculeStudied: string;
  locale = FR;
  inclusionReportFilter: InclusionReportFilter = new InclusionReportFilter();
  reportInclusionsTotalByMonth: ReportInclusionsTotalByMonth = new ReportInclusionsTotalByMonth();
  barChartOptions: any = Data.barChartOptions;
  noYesOptions: SelectItem[];

  constructor(
    private statisticsService: StatisticsService,
    private translateService: InnohealthTranslateService,
    private dataService: DataService,
    private providerService: ProviderService,
    private trialService: TrialService,
    private dynamicConfigService: DynamicConfigService,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.initProperties();
    this.dataService.setExportPageModule('src/app/statistics/follow-inclusions/follow-inclusions.component');
    this.getTrials();
    this.translate.stream('key').subscribe(() => {
      this.getPatientAge();
      this.getPatientStates();
      this.getYearMonth();
      this.getReportInclusionsTotalByMonth();
      this.initNoYesOptions();
    });
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.multiSiteEnabled = this.dynamicConfigService.getProperty(PropertyEnum.multiSiteEnabled);
      this.filterReportInclusionsPatientAgeColEnabled = this.dynamicConfigService.getProperty(PropertyEnum.filterReportInclusionsPatientAgeColEnabled);
      this.localIdentifierInIdentification = this.dynamicConfigService.getProperty(PropertyEnum.localIdentifierInIdentification);
    });
    this.initFormConfig();
  }

  initFormConfig(): void {
    this.dynamicConfigService.getFormConfig(this.target, StatisticsAppData.formConfig).subscribe(
      config => {
        this.config = config;
      }
    );
  }

  getFieldConfig = (fieldName: string) => this.config.getFieldConfig(fieldName);
  displayField = (fieldName: string) => this.config.displayField(fieldName);


  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  getPatientAge() {
    if (this.filterReportInclusionsPatientAgeColEnabled) {
      this.patientAge = [
        {label: '', value: ''},
        {label: this.translateService.getTranslationString('REPORTS_INCLUSIONS_TOTAL_BY_MONTH_CHILD'), value: '2'},
        {label: this.translateService.getTranslationString('REPORTS_INCLUSIONS_TOTAL_BY_MONTH_AJA'), value: '1'},
        {label: this.translateService.getTranslationString('REPORTS_INCLUSIONS_TOTAL_BY_MONTH_ADULT_2'), value: '3'},
        {label: this.translateService.getTranslationString('REPORTS_INCLUSIONS_TOTAL_BY_MONTH_GERIATRIC_2'), value: '4'}
      ];
    } else {
      this.patientAge = [
        {label: '', value: ''},
        {
          label: this.translateService.getTranslationString('REPORTS_INCLUSIONS_TOTAL_BY_MONTH_NEONATOLOGY'),
          value: '1'
        },
        {label: this.translateService.getTranslationString('REPORTS_INCLUSIONS_TOTAL_BY_MONTH_CHILD'), value: '2'},
        {label: this.translateService.getTranslationString('REPORTS_INCLUSIONS_TOTAL_BY_MONTH_ADULT'), value: '3'},
        {label: this.translateService.getTranslationString('REPORTS_INCLUSIONS_TOTAL_BY_MONTH_GERIATRIC'), value: '4'}
      ];
    }
  }

  getPatientStates() {
    this.patientStates = [
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_SCREENING'),
        value: 1
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_SCREENING_FAILURE'),
        value: 2
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_INCLUDED'),
        value: 3
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_ACTS_VISIT_AFTER_INCLUSION'),
        value: 4
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_FOLLOW_UP_END_TREATMENT_DATE'),
        value: 5
      },
      {
        label: this.translateService.getTranslationString('MODULE_TRIALS_DASHBOARD_STATE_IN_TREATMENT'),
        value: 6
      }
    ];
  }

  getTrials() {
    this.trialService.getTrials().subscribe((res: Trial[]) => {
      this.trials = [];
      this.trials.push({hhhId: 0, localIdentifier: '', protocolNumber: '', fullName: ''});
      for (const trial of res) {
        this.trials.push(trial);
      }
    });
  }

  getYearMonth() {
    this.yearMonth = [
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_YEAR'), value: 'Year'},
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_MONTH'), value: 'Month'},
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_TRIMESTER_1'), value: 'T1'},
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_TRIMESTER_2'), value: 'T2'},
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_TRIMESTER_3'), value: 'T3'},
      {label: this.translateService.getTranslationString('MODULE_SCHEDULE_TRIMESTER_4'), value: 'T4'}
    ];
  }

  getByFilter() {
    this.getReportInclusionsTotalByMonth();
  }

  resetFilter() {
    this.inclusionReportFilter = new InclusionReportFilter();
    this.trial = new Trial();
    this.getReportInclusionsTotalByMonth();
  }

  getReportInclusionsTotalByMonth() {
    this.inclusionReportFilter.trials = this.trial.hhhId;
    this.inclusionReportFilter.localIdentifier = this.localIdentifier.hhhId;
    if (!this.inclusionReportFilter.inclusionsDateUnit) {
      this.inclusionReportFilter.inclusionsDateUnit = 'Month';
      this.inclusionReportFilter.fromDate = new Date(new Date().getFullYear(), 0, 1);
      this.inclusionReportFilter.toDate = new Date(new Date().getFullYear(), 11, 31);
    }
    const inclusionsEstablishmentDepartmentData = [];
    const inclusionDateData = [];
    const inclusionPerTrialData = [];
    this.statisticsService.getReportInclusionsTotalByMonth(this.inclusionReportFilter).subscribe(res => {
      this.reportInclusionsTotalByMonth = res;
      const inclusionDateLabels = [];
      const inclusionDateBackgroundColor = [];
      const months = Data.monthList;
      let label = '';
      for (let i = 0; i < this.reportInclusionsTotalByMonth.inclusionsDate.length; i++) {
        const inclusionDate = this.reportInclusionsTotalByMonth.inclusionsDate[i];
        const month = +inclusionDate.inclusionDate.substring(5, 7) - 1; // i.e. 0-based month.
        if (this.inclusionReportFilter.inclusionsDateUnit === 'Year') {
          label = inclusionDate.inclusionDate.substring(2, 4);
        } else {
          label = this.translateService.getTranslationString('MODULE_REPORTS_INCLUSIONS_BY_TRIAL_' + months[month])
            + '. ' + inclusionDate.inclusionDate.substring(2, 4);
        }
        inclusionDateLabels.push(label);
        inclusionDateData.push(inclusionDate.nbInclusion);
        inclusionDateBackgroundColor.push(this.statisticsService.getColor(i));
      }
      inclusionDateData.push(0);
      this.inclusionsData = {
        labels: inclusionDateLabels,
        datasets: [
          {
            backgroundColor: '#42A5F5',
            borderColor: '#1E88E5',
            data: inclusionDateData
          }
        ]
      };
      let totalInclusionInService = 0;
      this.inclusionEstablishmentDepartmentLabels = [];
      for (let i = 0; i < this.reportInclusionsTotalByMonth.inclusionEstablishmentDepartment.length; i++) {
        const inclusionEstablishmentDepartment = this.reportInclusionsTotalByMonth.inclusionEstablishmentDepartment[i];
        this.inclusionEstablishmentDepartmentLabels.push(inclusionEstablishmentDepartment.service);
        inclusionsEstablishmentDepartmentData.push(inclusionEstablishmentDepartment.nbInclusions);
        totalInclusionInService = totalInclusionInService + inclusionEstablishmentDepartment.nbInclusions;
        this.inclusionEstablishmentDepartmentBackgroundColor.push(this.statisticsService.getColor(i));
      }
      const nbInclusionWithoutService = this.reportInclusionsTotalByMonth.inclusionsNumber - totalInclusionInService;
      if (nbInclusionWithoutService > 0) {
        this.inclusionEstablishmentDepartmentLabels.push(this.translateService.getTranslationString(
          'REPORT_INCLUSIONS_TOTAL_BY_MONTH_NO_ESTABLISHMENT_DEPARTMENT'));
        inclusionsEstablishmentDepartmentData.push(nbInclusionWithoutService);
        this.inclusionEstablishmentDepartmentBackgroundColor.push(this.statisticsService.getColor(
          this.reportInclusionsTotalByMonth.inclusionEstablishmentDepartment.length
        ));
      }
      this.statisticsService.setInclusionsEstablishmentDepartmentData(this.reportInclusionsTotalByMonth.inclusionEstablishmentDepartment);
      this.inclusionEstablishmentDepartmentData = {
        labels: this.inclusionEstablishmentDepartmentLabels,
        datasets: [
          {
            data: inclusionsEstablishmentDepartmentData,
            backgroundColor: this.inclusionEstablishmentDepartmentBackgroundColor,
            hoverBackgroundColor: this.inclusionEstablishmentDepartmentBackgroundColor
          }
        ]
      };
      const inclusionPerTrialLabels = [];
      const inclusionPerTrialBackgroundColor = [];
      if (this.reportInclusionsTotalByMonth.rateInclusionPerTrial) {
        for (let i = 0; i < this.reportInclusionsTotalByMonth.rateInclusionPerTrial.length; i++) {
          const inclusionPerTrial = this.reportInclusionsTotalByMonth.rateInclusionPerTrial[i];
          inclusionPerTrialLabels.push(inclusionPerTrial.trial);
          inclusionPerTrialData.push(inclusionPerTrial.rateInclusion);
          inclusionPerTrialBackgroundColor.push(this.statisticsService.getColor(i));
        }
        this.inclusionsTrial = {
          labels: inclusionPerTrialLabels,
          datasets: [
            {
              data: inclusionPerTrialData,
              backgroundColor: inclusionPerTrialBackgroundColor,
              hoverBackgroundColor: inclusionPerTrialBackgroundColor
            }]
        };
      }
    });
  }

  isParent() {
    return this.dynamicConfigService.parent;
  }

  initNoYesOptions() {
    this.noYesOptions = [
      {label: '', value: null},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_YES_UFCASE'), value: true},
      {label: this.translateService.getTranslationString('GENERAL_LINGUISTICS_NO_UFCASE'), value: false}];
  }
}

import {ExportTypesEnum} from '../exported/data-export/ExportTypesEnum';
import {RequestPage} from './request-page';

export class ExportRequest<T> extends RequestPage<T> {

  contextId: number;
  socketId: string;
  target: string;
  exportType: ExportTypesEnum;
  headers: string[];

  public constructor() {
    super();
  }

}

import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TrialService} from '../../../services/trial.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {TrialAdministrativeInformation} from '../../../models/trial-administrative-information';
import {DataService} from '../../../../shared/services/data-service';
import {CanDeactivateService} from '../../../../shared/services/can-deactivate.service';
import {CanComponentDeactivate} from '../../../../helpers/can-deactivate-guard';
import {TrialHead} from '../../../models/trial-head';
import {TrialAppData} from '../../../trial-app-data';
import {DynamicConfigService} from '../../../../dynamic-config/service/dynamic-config.service';
import {DynamicDefinitionEnum} from '../../../../dynamic-config/entity/dynamic-definition-enum';
import {ContainerConfig} from '../../../../dynamic-config/entity/container-config';
import {TrialAdministrativeInformationService} from '../../../services/trial-administrative-information.service';

@Component({
  selector: 'ih-trial-administrative-information-container',
  templateUrl: './trial-administrative-information-container.component.html',
  styleUrls: ['./trial-administrative-information-container.component.css']
})
export class TrialAdministrativeInformationContainerComponent implements OnInit, OnDestroy, CanComponentDeactivate {


  target = DynamicDefinitionEnum.TRIAL_ADMINISTRATIVE_INFORMATION_CONTAINER;
  config: ContainerConfig = new ContainerConfig();

  trialId: number;
  trialAdministrativeInformation: TrialAdministrativeInformation = new TrialAdministrativeInformation();
  actualRoute = '';
  currentPath: string;
  trialHead: TrialHead = new TrialHead(null);

  constructor(
      private router: Router,
      private dataService: DataService,
      public trialService: TrialService,
      private sharedService: SharedService,
      private canDeactivateService: CanDeactivateService,
      private trialAdministrativeInformationService: TrialAdministrativeInformationService,
      private dynamicConfigService: DynamicConfigService
  ) {
    const url = this.router.parseUrl(this.router.url);
    if (url.queryParams) {
      this.trialId = url.queryParams.hhhId;
    }
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const currentUrl = val.url;
        if (currentUrl.indexOf('?') > 0) {
          this.actualRoute = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.indexOf('?'));
          if (this.actualRoute === 'trial-details/trial-administrative-information-container') {
            this.currentPath = 'trial-administrative-information-container';
          }
        } else {
          this.actualRoute = currentUrl.substring(currentUrl.indexOf('/') + 1, currentUrl.length);
        }
      }
    });
  }

  ngOnInit() {
    this.initContainerConfig();
    this.currentPath = this.actualRoute.substring(this.actualRoute.lastIndexOf('/') + 1, this.actualRoute.length);
    this.dataService.setExportPageModule('src/app/trial/trial-administrative-information');
    this.trialService.trialHhhId = this.trialId;
    this.getTrialAdministrativeInformation();
    this.getTrialHead();
  }

  initContainerConfig(): void {
    this.dynamicConfigService.getContainerConfig(this.target, TrialAppData.containerConfig).subscribe(
        config => {
          this.config = config;
        }
    );
  }

  getCardConfig = (cardName: string) => this.config.getCardConfig(cardName);
  displayCard = (cardName: string) => this.config.displayCard(cardName);
  getCardOrder = (cardName: string) => this.config.getCardOrder(cardName);


  ngOnDestroy(): void {
    this.dataService.setExportPageModule('');
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return this.canDeactivateService.canBeDeactivated;
  }

  getTrialAdministrativeInformation() {
    this.trialAdministrativeInformationService.getAdministrativeInformation(this.trialId).subscribe(
        res => {
          this.trialAdministrativeInformation = res;
        }, error => {
          this.sharedService.showFailure();
          console.error('An error occurred while loading trial administrative information.' + error);
        }
    );
  }

  openTrialAdministrativeInformation(path: string) {
    this.currentPath = path;
    this.router.navigate(
        ['/trial-details/trial-administrative-information-container/' + path],
        {queryParams: {hhhId: this.trialId}}
    ).then();
  }

  isModuleActive(path): boolean {
    return this.currentPath === path
        || (path === 'trial-administrative-regulatory-information' && this.currentPath === 'trial-administrative-information-container');
  }

  getTrialHead() {
    this.trialService.getTrialHead(this.trialId).subscribe(trialHead => {
      this.trialHead = trialHead;
    });
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ih-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  displayReasonCallback(fn: () => void) {
    if (fn) {
      fn();
    }
  }
}

import {Component, ComponentFactoryResolver, OnInit, ViewContainerRef} from '@angular/core';
import {FormComponent} from '../../../../shared/component/form/form.component';
import {SharedService} from '../../../../shared/services/shared.service';
import {DataDocumentService} from '../../../../data-document/service/data-document.service';

@Component({
  selector: 'ih-delete-file-or-folder-confirmation',
  templateUrl: './delete-file-or-folder-confirmation.component.html',
  styleUrls: ['./delete-file-or-folder-confirmation.component.css']
})
export class DeleteFileOrFolderConfirmationComponent extends FormComponent<any> implements OnInit {

  constructor(private dataDocumentService: DataDocumentService,
              private sharedService: SharedService) {
    super();
  }
  attachedToId: number;
  documentId: number;
  type: string;
  pharmacyHhhId: number;
  path: string;
  deleteFileOrFolderDialogLabel: string;

  public static displayFormDialogue(viewRef: ViewContainerRef, cfr: ComponentFactoryResolver) {
    const addDialogFactory = cfr.resolveComponentFactory(DeleteFileOrFolderConfirmationComponent);
    const addDialogComponentRef = viewRef.createComponent(addDialogFactory);
    return addDialogComponentRef.instance;
  }

  ngOnInit() {
    super.ngOnInit();
    this.openDeleteDialog();
  }

  openDeleteDialog() {
    if (this.type === 'folder') {
      this.dataDocumentService.countDocumentsByFolder(this.documentId).subscribe(res => {
        if (res > 0) {
          this.deleteFileOrFolderDialogLabel = 'MODULE_TRIALS_CONFIRM_NON_EMPTY_FOLDER_DELETION';
        } else {
          this.deleteFileOrFolderDialogLabel = 'MODULE_TRIALS_FILES_DIALOG_QUESTION_FOLDER_DELETION';
        }
      });
    } else {
      this.deleteFileOrFolderDialogLabel = 'MODULE_TRIALS_FILES_DIALOG_QUESTION_FILE_DELETION';
    }
    this.display = true;
  }

  deleteFileAndFolder() {
    if (this.type === 'folder') {
      this.dataDocumentService.deleteFolder(this.documentId).subscribe(() => {
        this.sharedService.showSuccess('GENERAL_LINGUISTICS_DELETE_SUCCESS');
        this.callback(event);
      }, error => {
        this.sharedService.showFailure(error.error);
        console.error(error);
      });
    } else {
      this.dataDocumentService.deleteDocument(this.documentId).subscribe(() => {
        this.sharedService.showSuccess('GENERAL_LINGUISTICS_DELETE_SUCCESS');
        this.callback(event);
      }, error => {
        this.sharedService.showFailure(error.error);
        console.error(error);
      });
    }
    this.display = false;
  }
}

import {AdministrationRouteDto} from './administration-route-dto';

export class TreatmentAdministrationRouteDto {
  enabled: boolean;
  administrationRoute: AdministrationRouteDto;


  constructor() {
    this.enabled = true;
    this.administrationRoute = new AdministrationRouteDto();
  }
}

import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormInputConfig} from '../../entity/form-input-config';
import {FR} from '../../../shared/entity/calendar-language';
import {Calendar} from 'primeng/calendar';
import {isUndefined} from 'util';
import {DynamicConfigService} from '../../service/dynamic-config.service';
import {PropertyEnum} from '../../entity/property-enum';

export const DYNAMIC_CALENDAR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DynamicCalendarComponent),
  multi: true
};

@Component({
  selector: 'ih-dynamic-calendar',
  templateUrl: './dynamic-calendar.component.html',
  styleUrls: ['./dynamic-calendar.component.css'],
  providers: [DYNAMIC_CALENDAR_VALUE_ACCESSOR]
})
export class DynamicCalendarComponent implements OnInit, ControlValueAccessor {

  @ViewChild('calendar') calendar: Calendar;

  @Input() name: string;
  @Input() config: FormInputConfig;
  @Input() hasComment = false;
  @Input() title: string;
  @Input() commentDisabled = false;
  @Input() publicCommentCount: number;
  @Input() submitted = false;
  @Input() requiredErrorMsg = 'FORM_REQUIRED_FIELD_ERROR';
  @Input() notBeforeErrorMsg: string;
  @Input() notAfterErrorMsg: string;
  @Input() startBeforeEndDateValidator: Date[] = [];
  @Input() startAfterLastDateValidator: Date[] = [];
  @Input() alignLabel = false;
  @Input() showLabel = true;
  @Input() showAddBtn = false;
  @Input() disableAddBtn = false;
  @Input() addBtnTitle: string;
  @Input() addBtnCount: number;

  @Input() locale = FR;
  @Input() checkedCalendar: boolean;
  @Input() hideCheckbox: boolean = false;
  @Input() hasMolecularPreScreening = true;
  @Input() MolecularPreScreeningDisabledErrorMsg = 'MOLECULAR_PRESCREENING_DISABLED_ERROR';
  @Input() disabled = false;
  @Input() important = false;
  @Input() hasClearButton: boolean;
  @Input() selectionMode = 'single';
  @Input() appendTo: any;
  @Input() minDate: Date = null;
  @Input() maxDate: Date = null;
  @Input() monthNavigator = true;
  @Input() showButtonBar = true;
  @Input() showIcon = false;
  @Input() yearNavigator = true;
  @Input() placeholder = 'PLACEHOLDER_DATE_FORMAT';
  @Input() dateFormat = 'dd/mm/yy';
  @Input() yearRange = '1900:2030';
  @Input() defaultDate: Date;


  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() valueInput: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<void> = new EventEmitter<void>();
  @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClearEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() displayDataComment = new EventEmitter<{attachedToField: string, isPrivate: boolean}>();
  @Output() add = new EventEmitter();


  hasPrivateComment = true;
  private _value: any;

  constructor(private dynamicConfigService: DynamicConfigService) {
  }

  ngOnInit(): void {
    this.dynamicConfigService.initProperties().subscribe(() =>
      this.hasPrivateComment = this.dynamicConfigService.getProperty(PropertyEnum.privatePostItEnabled));
  }


  get dateValue(): Date {
    return (this.checkedCalendar && !isUndefined(this._value)) ? this._value.date : this._value;
  }

  set dateValue(v: Date) {
    if (this.checkedCalendar) {
      if (this._value && v !== this._value.date) {
        this._value.date = v;
        this.onChange(this._value);
      }
    } else {
      if (v !== this._value) {
        this._value = v;
        this.onChange(v);
      }
    }
  }

  get checkedValue(): boolean {
    return (this.checkedCalendar && !isUndefined(this._value)) ? this._value.checked : false;
  }

  set checkedValue(v: boolean) {
    if (this._value && v !== this._value.checked) {
      this._value.checked = v;
      this.onChange(this._value);
    }
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(v: any) {
    if (v) {
      this._value = v;
      this.onChange(v);
    }
  }

  onClearEventDo() {
    this.onClearEvent.emit();
  }

  onSelectDo() {
    if (this.dateValue) {
      this.dateValue.setHours(12, 0, 0, 0);
    }
    this.onSelect.emit();
  }

  onBlurDo() {
    if (this.dateValue) {
      this.dateValue.setHours(12, 0, 0, 0);
    }
    this.onBlur.emit();
  }

  onCloseDo() {
    if (this.dateValue) {
      this.dateValue.setHours(12, 0, 0, 0);
    }
    this.onClose.emit();
  }

  reset() {
    this.calendar.updateModel(null);
    this.calendar.updateInputfield();
  }

  clearDate() {
    this.dateValue = null;
    this.onClearEvent.emit();
  }

  emitDataCommentEvent(p: boolean) {
    this.displayDataComment.emit({attachedToField: this.name, isPrivate: p});
  }


  emitAddEvent() {
    this.add.emit();
  }

  onChange = (_) => {};

  onTouched = (_) => {};


  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (_: any) => void): void {
    this.onTouched = fn;
  }
}

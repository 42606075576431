import {Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {VisitAct} from '../../../../../entity/inclusion/visit-act';
import {Data} from '../../../../../../shared/entity/data';
import {SharedService} from '../../../../../../shared/services/shared.service';
import {Router} from '@angular/router';
import {TrialAct} from '../../../../../entity/theoretical-calendar/trial-act';
import {VisitActService} from '../../../../../service/visit-act.service';
import {DeleteConfirmationComponent} from '../../../../../../modals/delete-confirmation/delete-confirmation.component';
import {isNullOrUndefined} from 'util';
import {UsersService} from '../../../../../../admin/services/users.service';
import {ReferenceAdditionalCost} from '../../../../../../additional-costs/models/refrence-additional-cost';
import {PropertyEnum} from '../../../../../../dynamic-config/entity/property-enum';
import {DynamicConfigService} from '../../../../../../dynamic-config/service/dynamic-config.service';

@Component({
  selector: 'ih-visit-act-update-form',
  templateUrl: './visit-act-update-form.component.html',
  styleUrls: ['./visit-act-update-form.component.css']
})
export class VisitActUpdateFormComponent implements OnInit {
  @ViewChild('deleteVisitActPopup', {read: ViewContainerRef}) deletePopupContainer: ViewContainerRef;
  @Output() deleteEvent = new EventEmitter<VisitAct>();
  public displayVisitActUpdate = false;
  public visitAct: VisitAct = new VisitAct();
  public referenceAct: ReferenceAdditionalCost = new ReferenceAdditionalCost();
  public trialAct: TrialAct = new TrialAct();
  public noYesOptions = Data.yesOrNo;
  private callback: (visitAct: VisitAct) => void;
  IsCmpletedAndInvoiceableVisitsAndActsEnabled = false;

  constructor(
    private sharedService: SharedService,
    private useService: UsersService,
    private router: Router,
    private cfr: ComponentFactoryResolver,
    private visitActService: VisitActService,
    private dynamicConfigService: DynamicConfigService
  ) {
  }

  ngOnInit() {
    this.displayVisitActUpdate = true;
    this.getReferenceAct();
    this.getTrialAct();
  }

  initProperties(): void {
    this.dynamicConfigService.initProperties().subscribe(() => {
      this.IsCmpletedAndInvoiceableVisitsAndActsEnabled = this.dynamicConfigService.getProperty(PropertyEnum.completedAndInvoiceableVisitsAndActsEnabled);
    });
  }

  getReferenceAct() {
    this.visitActService.getReferenceActByVisitAct(this.visitAct.hhhId, this.visitAct.type)
      .subscribe(res => {
        this.referenceAct = res;
      });
  }

  getTrialAct() {
    this.visitActService.getTrialActByVisitAct(this.visitAct.hhhId)
      .subscribe(res => {
        this.trialAct = res;
      });
  }

  updateVisitAct() {
    this.visitActService.updateVisitAct(this.visitAct.hhhId, this.visitAct.invoiceable, this.visitAct.pricePerUnit, this.visitAct.numberOfUnits)
      .subscribe(() => {
        this.displayVisitActUpdate = false;
        this.callback(this.visitAct);
        this.sharedService.showSuccess();
      });
  }

  deleteVisitAct() {
    if (isNullOrUndefined(this.visitAct.theoreticalVisitActHhhId)) {
      this.displayVisitActUpdate = false;
      this.displayActDeleteDialogue();
    }
  }

  onUpdate(callback: (visitAct: VisitAct) => void) {
    this.callback = callback;
  }

  displayActDeleteDialogue() {
    const deletePopupFactory = this.cfr.resolveComponentFactory(DeleteConfirmationComponent);
    const deletePopupComponentRef = this.deletePopupContainer.createComponent(deletePopupFactory);
    const deleteConfirmationComponent = deletePopupComponentRef.instance;
    deleteConfirmationComponent.hhhId = this.visitAct.hhhId;
    deleteConfirmationComponent.popupMessage = 'MODULE_TRIALS_ACTS_GENERAL_DELETE';
    deleteConfirmationComponent.service = this.visitActService;
    deleteConfirmationComponent.name = this.visitAct.description;
    deleteConfirmationComponent.onDelete(() => {
      this.deleteEvent.emit(this.visitAct);
    });
  }

  isPriceVisible(): boolean {
    return this.useService.canAccessFieldByValue(this.visitAct.type);
  }

}
